import React, { useEffect, useRef, useState } from 'react'

import configs from '../../../configs/index'
import useRootData from '../../../hooks/useRootData'
import { s3 } from '../../../stores/auth'
import * as resizer from '../../../utils/imageResizer'
import styles from './index.module.scss'

// File extension for the created JPEG files.
const JPEG_EXTENSION = '.jpg'

export interface PropsUploadImage {
  onChangeHandler?: (
    // eslint-disable-next-line camelcase
    jpeg_aws: string,
    // eslint-disable-next-line camelcase
    thumb_aws: string,
    // eslint-disable-next-line camelcase
    original_aws: string,
  ) => void
  currentImage?: string
  setInvisible?: (data: boolean) => void
}

const App: React.FunctionComponent<PropsUploadImage> = ({
  onChangeHandler,
  currentImage,
  setInvisible,
}: PropsUploadImage) => {
  const { changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [imageUrl, setImageUrl] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [resizeStatus, setResizeStatus] = useState(false)
  const [awsOriginalDownloadURL, setAwsOriginalDownloadURL] = useState('')
  const [awsJpgDownloadURL, setAwsJpgDownloadURL] = useState('')
  const [awsThumbnailDownloadURL, setAwsThumbnailDownloadURL] = useState('')
  const fileInput = useRef(null)

  useEffect(() => {
    setImageUrl('')
    if (fileInput && fileInput.current) {
      fileInput.current.value = ''
    }
    setSelectedFile(null)
  }, [currentImage])

  async function loadImage(filelist: FileList): Promise<void> {
    setResizeStatus(false)

    const randomStr = Math.random().toString().substr(2, 5)

    const file = filelist[0]

    if (file) {
      const blob = file.slice(0, file.size, 'image/png')
      const newFile = new File([blob], `${randomStr}${file.name}`, { type: file.type })

      const url = URL.createObjectURL(file)
      const img = new Image()

      img.src = url

      img.onload = (): void => {
        const width = img.naturalWidth

        window.URL.revokeObjectURL(img.src)

        if (width > 1500) {
          setResizeStatus(true)
        }
      }

      setImageUrl(url)
      setSelectedFile(newFile)
      setUploadStatus(false)
    } else {
      changeSnackbarAlertContent({ severity: 'warning', content: '이미지를 다시 업로드 해주세요!' })
    }
  }

  async function upLoadImage(): Promise<void> {
    if (!selectedFile) {
      changeSnackbarAlertContent({ severity: 'warning', content: '이미지를 선택해주세요!' })
    }

    let improvedJpgFile: Blob | Uint8Array | ArrayBuffer

    if (resizeStatus) {
      improvedJpgFile = await resizer.resize(selectedFile, { width: 1500 })
    } else {
      improvedJpgFile = await resizer.resize(selectedFile, {})
    }

    const improvedThumbNailFile = await resizer.resize(selectedFile, { height: 500 })

    const randomStr = Math.random().toString().substr(2, 5)
    const file4AWSNameOriginal = `images/${randomStr}${selectedFile.name.replace(/[\\~!@#$%^&*()=+`"?/ ]/g, '_')}`
    const file4AWSNameJpg = `images/${randomStr}${selectedFile.name
      .split('.')[0]
      .replace(/[\\~!@#$%^&*()=+`"?/ ]/g, '_')}${JPEG_EXTENSION}`
    const file4AWSNameThumbnail = `images/${randomStr}${selectedFile.name
      .split('.')[0]
      .replace(/[\\~!@#$%^&*()=+`"?/ ]/g, '_')}_thumb${JPEG_EXTENSION}`
    let awsOriginURL = ''
    let awsJpgURL = ''
    let awsThumbURL = ''

    s3.putObject(
      {
        Key: file4AWSNameOriginal,
        Body: selectedFile,
        ACL: 'public-read',
        Bucket: configs.awsConfig.imageBucketName,
        ContentType: 'image/jpeg',
      },
      (errOriginal: { message: string }) => {
        if (errOriginal) {
          changeSnackbarAlertContent({ severity: 'error', content: errOriginal.message })
        } else {
          awsOriginURL = `${configs.awsConfig.databaseURL}/${file4AWSNameOriginal}`
          setAwsOriginalDownloadURL(`${configs.awsConfig.databaseURL}/${file4AWSNameOriginal}`)
          s3.putObject(
            {
              Key: file4AWSNameJpg,
              Body: improvedJpgFile,
              ACL: 'public-read',
              Bucket: configs.awsConfig.imageBucketName,
              ContentType: 'image/jpeg',
            },
            (errJpg: { message: string }) => {
              if (errJpg) {
                changeSnackbarAlertContent({ severity: 'error', content: errJpg.message })
              } else {
                awsJpgURL = `${configs.awsConfig.databaseURL}/${file4AWSNameJpg}`
                setAwsJpgDownloadURL(`${configs.awsConfig.databaseURL}/${file4AWSNameJpg}`)
                s3.putObject(
                  {
                    Key: file4AWSNameThumbnail,
                    Body: improvedThumbNailFile,
                    ACL: 'public-read',
                    Bucket: configs.awsConfig.imageBucketName,
                    ContentType: 'image/jpeg',
                  },
                  (errThumbnail: { message: string }) => {
                    if (errThumbnail) {
                      changeSnackbarAlertContent({ severity: 'error', content: errThumbnail.message })
                    } else {
                      awsThumbURL = `${configs.awsConfig.databaseURL}/${file4AWSNameThumbnail}`
                      setAwsThumbnailDownloadURL(`${configs.awsConfig.databaseURL}/${file4AWSNameThumbnail}`)
                      // move onChangeHandler from bottom to here for upload to hasura after aws upload success
                      // in hasura there's '' string in firebase storage URL

                      if (onChangeHandler) onChangeHandler(awsJpgURL, awsThumbURL, awsOriginURL)
                      // eslint-disable-next-line no-console
                      console.log(
                        JSON.stringify({
                          jpeg_aws: awsJpgURL,
                          thumb_aws: awsThumbURL,
                          original_aws: awsOriginURL,
                        }),
                      )
                    }
                  },
                )
              }
            },
          )
        }
      },
    )

    setUploadStatus(true)

    changeSnackbarAlertContent({ severity: 'info', content: '이미지 업로드 시작!' })
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <input
          ref={fileInput}
          type="file"
          id="avatar"
          name="avatar"
          accept="image/png, image/jpeg"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            const event = e.target

            loadImage(event.files)
          }}
        />
        {!uploadStatus && imageUrl && (
          <button
            onClick={(): void => {
              if (setInvisible) setInvisible(true)
              upLoadImage()
            }}
          >
            업로드
          </button>
        )}
        {currentImage && (
          <button
            onClick={(): void => {
              if (onChangeHandler) onChangeHandler('', '', '')

              setImageUrl('')
              changeSnackbarAlertContent({ severity: 'success', content: '저장된 이미지가 삭제되었습니다!' })
            }}
          >
            삭제
          </button>
        )}
      </div>
      {currentImage && (
        <div className={styles.middle}>
          현재 이미지
          <img src={currentImage}></img>
        </div>
      )}
      {imageUrl && (
        <div className={styles.bottom}>
          변경될 이미지
          <img src={imageUrl}></img>
          <div>
            AWS 원본 이미지 업로드 성공 여부
            <input type="checkbox" defaultChecked={!!awsOriginalDownloadURL}></input>
          </div>
          <div>
            AWS JPG 이미지 업로드 성공 여부
            <input type="checkbox" defaultChecked={!!awsJpgDownloadURL}></input>
          </div>
          <div>
            AWS 썸네일 이미지 업로드 성공 여부
            <input type="checkbox" defaultChecked={!!awsThumbnailDownloadURL}></input>
          </div>
        </div>
      )}
    </div>
  )
}
export default React.memo(App)
