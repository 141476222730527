// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__loginContainer___3fW4F {\n  width: 500px;\n  border: 1px solid #000;\n  padding: 20px; }\n  .index-module__loginContainer___3fW4F .index-module__title___1kyrk {\n    font-size: 32px;\n    text-align: center; }\n  .index-module__loginContainer___3fW4F .index-module__subTitle___2VwGX {\n    width: 150px;\n    border-bottom: 1px solid #000; }\n  .index-module__loginContainer___3fW4F input {\n    width: 150px;\n    margin: 20px 0px 20px 0px; }\n  .index-module__loginContainer___3fW4F button {\n    width: 60px; }\n\n.index-module__sendKaKaotalkContainer___141UZ select {\n  margin: 10px 0; }\n\n.index-module__sendKaKaotalkContainer___141UZ .index-module__title___1kyrk {\n  font-size: 13px; }\n\n.index-module__sendKaKaotalkContainer___141UZ .index-module__selectedTemplate___foRBD {\n  width: 40%; }\n\n.index-module__sendKaKaotalkContainer___141UZ button {\n  margin: 10px 0; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/Landing/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,aAAa,EAAA;EAHf;IAMI,eAAe;IACf,kBAAkB,EAAA;EAPtB;IAWI,YAAY;IACZ,6BAA6B,EAAA;EAZjC;IAgBI,YAAY;IACZ,yBAAyB,EAAA;EAjB7B;IAqBI,WAAW,EAAA;;AAIf;EAEI,cAAc,EAAA;;AAFlB;EAKI,eAAe,EAAA;;AALnB;EAQI,UAAU,EAAA;;AARd;EAWI,cAAc,EAAA","file":"index.module.scss","sourcesContent":[".loginContainer {\n  width: 500px;\n  border: 1px solid #000;\n  padding: 20px;\n\n  .title {\n    font-size: 32px;\n    text-align: center;\n  }\n\n  .subTitle {\n    width: 150px;\n    border-bottom: 1px solid #000;\n  }\n\n  input {\n    width: 150px;\n    margin: 20px 0px 20px 0px;\n  }\n\n  button {\n    width: 60px;\n  }\n}\n\n.sendKaKaotalkContainer {\n  select {\n    margin: 10px 0;\n  }\n  .title {\n    font-size: 13px;\n  }\n  .selectedTemplate {\n    width: 40%;\n  }\n  button {\n    margin: 10px 0;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"loginContainer": "index-module__loginContainer___3fW4F",
	"title": "index-module__title___1kyrk",
	"subTitle": "index-module__subTitle___2VwGX",
	"sendKaKaotalkContainer": "index-module__sendKaKaotalkContainer___141UZ",
	"selectedTemplate": "index-module__selectedTemplate___foRBD"
};
module.exports = exports;
