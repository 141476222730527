import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import Select, { TypeSelectBox } from '../../../components/atoms/Select'
import { SelectedValuesStructure } from '../index'

interface OtherProductSectionProps {
  dashcamProductItemId: string
  dashcamProductItemArray: Array<TypeSelectBox>
  ceramicCoatingProductItemId: string
  ceramicCoatingProductItemArray: Array<TypeSelectBox>
  ppfProductItemId: string
  ppfProductItemArray: Array<TypeSelectBox>
  newCarInspectionProductId: string
  newCarInspectionProductItemArray: Array<TypeSelectBox>
  setSeletedValeus: (object: SelectedValuesStructure) => void
}

const App: React.FunctionComponent<OtherProductSectionProps> = ({
  dashcamProductItemId,
  dashcamProductItemArray,
  ceramicCoatingProductItemId,
  ceramicCoatingProductItemArray,
  ppfProductItemId,
  ppfProductItemArray,
  newCarInspectionProductId,
  newCarInspectionProductItemArray,
  setSeletedValeus,
}: OtherProductSectionProps) => {
  return (
    <Table>
      <TableHead style={{ backgroundColor: 'gainsboro' }}>
        <TableRow>
          <TableCell>블랙박스 제품 선택</TableCell>
          <TableCell>세라믹 코팅 제품 선택</TableCell>
          <TableCell>PPF 제품 선택</TableCell>
          <TableCell>신차검수 선택</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Select
              value={dashcamProductItemId}
              label={'블랙박스'}
              options={dashcamProductItemArray}
              disabled={dashcamProductItemArray.length === 1}
              changeHandler={(event): void => setSeletedValeus({ dashcamProductItemId: event.target.value as string })}
            ></Select>
          </TableCell>
          <TableCell>
            <Select
              value={ceramicCoatingProductItemId}
              label={'세라믹 코팅'}
              options={ceramicCoatingProductItemArray}
              disabled={ceramicCoatingProductItemArray.length === 1}
              changeHandler={(event): void =>
                setSeletedValeus({ ceramicCoatingProductItemId: event.target.value as string })
              }
            ></Select>
          </TableCell>
          <TableCell>
            <Select
              value={ppfProductItemId}
              label={'PPF'}
              options={ppfProductItemArray}
              disabled={ppfProductItemArray.length === 1}
              changeHandler={(event): void => setSeletedValeus({ ppfProductItemId: event.target.value as string })}
            ></Select>
          </TableCell>
          <TableCell>
            <Select
              value={newCarInspectionProductId}
              label={'신차검수'}
              options={newCarInspectionProductItemArray}
              disabled={newCarInspectionProductItemArray.length === 1}
              changeHandler={(event): void =>
                setSeletedValeus({ newCarInspectionProductId: event.target.value as string })
              }
            ></Select>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
