import { TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'

import downward from '../../../../assets/images/arrow_downward.svg'
import upward from '../../../../assets/images/arrow_upward.svg'
import Image from '../../../../components/atoms/Image'
import Text from '../../../../components/atoms/Text'
import useRootData from '../../../../hooks/useRootData'
import { parseQueryString, storeToLocalStorage } from '../../../../utils/utility'
import { tableCol, tableColDefaultOrder } from '../../index'
import { mappingStyle } from '../LeadRow'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const {
    statusFilterValue,
    leadOrder,
    leadOrderBy,

    changeLeadOrder,
    changeLeadOrderBy,
  } = useRootData(({ leadStore }) => ({
    statusFilterValue: leadStore.statusFilterValue.get(),
    leadOrder: leadStore.leadOrder.get(),
    leadOrderBy: leadStore.leadOrderBy.get(),

    changeLeadOrder: leadStore.changeLeadOrder,
    changeLeadOrderBy: leadStore.changeLeadOrderBy,
  }))

  const { status } = parseQueryString()

  return (
    <TableRow className={styles.colNameRow}>
      <TableCell style={{ padding: 5, textAlign: 'center', minWidth: 50 }} className={styles.reset}>
        <Text
          text={'초기화'}
          clickHandler={(): void => {
            window.localStorage.removeItem('leadOrderBy')
            window.localStorage.removeItem('leadOrder')

            changeLeadOrder(tableColDefaultOrder[status].order)
            changeLeadOrderBy(tableColDefaultOrder[status].col)
          }}
        />
      </TableCell>

      {tableCol[statusFilterValue].map((col, index) => (
        <TableCell
          key={index}
          style={mappingStyle[col]}
          className={styles.col}
          onClick={() => {
            const isAsc = leadOrderBy !== col || leadOrder === 'asc'
            changeLeadOrder(isAsc ? 'desc' : 'asc')
            changeLeadOrderBy(col)

            storeToLocalStorage(col, 'leadOrderBy')
            storeToLocalStorage(isAsc ? 'desc' : 'asc', 'leadOrder')
          }}
        >
          <Typography style={{ ...mappingStyle[col], fontSize: 12 }} variant={'button'}>
            {col}
          </Typography>

          {leadOrderBy === col && <Image className={styles.arrow} src={leadOrder === 'asc' ? upward : downward} />}
        </TableCell>
      ))}
    </TableRow>
  )
}
export default React.memo(App)
