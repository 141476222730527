import { Button } from '@material-ui/core'
import React, { Fragment } from 'react'

import { Review, Review_Status_Enum as ReviewStatusEnum } from '../../../assets/graphql/graphql'

interface PropsButtons {
  review: Review
  clickHandler: (status: ReviewStatusEnum, data: Review) => Promise<void>
}

const updateReviewBtns = [
  {
    id: ReviewStatusEnum.Approved,
    label: '승인',
    color: 'primary',
  },
  {
    id: ReviewStatusEnum.Unapproved,
    label: '미승인',
    color: 'secondary',
  },
]

const App: React.FunctionComponent<PropsButtons> = ({ review, clickHandler }: PropsButtons) => {
  return (
    <Fragment>
      {updateReviewBtns.map((button, index) => {
        return (
          <Fragment key={index}>
            <Button
              variant="contained"
              color={button.color as 'primary' | 'secondary'}
              onClick={(): Promise<void> => clickHandler(button.id, review)}
              disabled={button.id === review.status}
            >
              {button.label}
            </Button>
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default React.memo(App)
