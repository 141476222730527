// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__calculatorTable___9OWSt .index-module__tableHeadContainer___1u9OI {\n  background-color: #b388ff; }\n  .index-module__calculatorTable___9OWSt .index-module__tableHeadContainer___1u9OI .index-module__cellText___E6EHF {\n    color: #ffffff;\n    font-weight: bold; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Quote/CalculatorTable/index.module.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAyB,EAAA;EAF7B;IAIM,cAAc;IACd,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".calculatorTable {\n  .tableHeadContainer {\n    background-color: #b388ff;\n    .cellText {\n      color: #ffffff;\n      font-weight: bold;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"calculatorTable": "index-module__calculatorTable___9OWSt",
	"tableHeadContainer": "index-module__tableHeadContainer___1u9OI",
	"cellText": "index-module__cellText___E6EHF"
};
module.exports = exports;
