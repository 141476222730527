import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import React from 'react'

import { _Get_Select_OptionsQuery as GetSelectOptionsQuery, Car } from '../../../assets/graphql/graphql'
import Select, { TypeSelectBox } from '../../../components/atoms/Select'
import Text from '../../../components/atoms/Text'
import { SearchKeywordStructure, SelectedValuesStructure } from '../index'

interface CarSectionProps {
  carSearchKeyword: string
  searchCarArray: Array<TypeSelectBox>
  carModelId: string
  carSunroofType: string
  carSunroofTypeArray: Array<TypeSelectBox>
  selectOptionsContents: GetSelectOptionsQuery
  setSearchKeyword: (object: SearchKeywordStructure) => void
  setSeletedValeus: (object: SelectedValuesStructure) => void
}

const App: React.FunctionComponent<CarSectionProps> = ({
  carSearchKeyword,
  searchCarArray,
  carModelId,
  carSunroofType,
  carSunroofTypeArray,
  selectOptionsContents,
  setSearchKeyword,
  setSeletedValeus,
}: CarSectionProps) => {
  const selectedCar = selectOptionsContents
    ? selectOptionsContents.carModels.filter((searchCar: Car) => searchCar.id === carModelId)[0]
    : null

  return (
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: 'gainsboro' }}>
        <TableRow>
          <TableCell>차량</TableCell>
          <TableCell>썬루프 타입</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <div style={{ marginBottom: 10, display: 'flex' }}>
              <TextField
                style={{ marginRight: 10 }}
                value={carSearchKeyword}
                label={'제조사 또는 모델명 검색'}
                variant={'outlined'}
                onChange={(e) =>
                  setSearchKeyword({
                    carSearchKeyword: e.target.value,
                  })
                }
              ></TextField>

              <Select
                value={carModelId}
                label={'검색 결과'}
                options={searchCarArray}
                changeHandler={(event): void => {
                  const carByCar = selectOptionsContents
                    ? selectOptionsContents.carModels.filter((car: Car) => car.id === event.target.value)[0]
                    : null

                  if (!carByCar) return

                  setSeletedValeus({
                    carOrigin: carByCar.car_maker.car_origin.name_us,
                    carModelId: carByCar.id,
                    carType: carByCar.type,
                    packageId: '',
                    frontTintingProductItemId: '',
                    sidebackTintingProductItemId: '',
                    sunroofTintingProductItemId: '',
                    dashcamProductItemId: '',
                    ceramicCoatingProductItemId: '',
                    ppfProductItemId: '',
                    newCarInspectionProductId: '',
                  })
                }}
              ></Select>
            </div>

            <Text
              text={selectedCar ? `[${selectedCar.car_maker.name_kr}] ${selectedCar.model}` : '차량을 선택하세요!'}
            ></Text>
          </TableCell>
          <TableCell>
            <Select
              value={carSunroofType}
              label={'썬루프 타입'}
              options={carSunroofTypeArray}
              changeHandler={(event): void =>
                setSeletedValeus({ carSunroofType: event.target.value as string, sunroofTintingProductItemId: '' })
              }
            ></Select>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
