import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import Select, { TypeSelectBox } from '../../../components/atoms/Select'
import { SelectedValuesStructure } from '../index'

interface TintingSectionProps {
  frontTintingProductItemId: string
  frontTintingProductItemArray: Array<TypeSelectBox>
  sidebackTintingProductItemId: string
  sidebackTintingProductItemArray: Array<TypeSelectBox>
  sunroofTintingProductItemId: string
  sunroofTintingProductItemArray: Array<TypeSelectBox>
  setSeletedValeus: (object: SelectedValuesStructure) => void
}

const App: React.FunctionComponent<TintingSectionProps> = ({
  frontTintingProductItemId,
  frontTintingProductItemArray,
  sidebackTintingProductItemId,
  sidebackTintingProductItemArray,
  sunroofTintingProductItemId,
  sunroofTintingProductItemArray,
  setSeletedValeus,
}: TintingSectionProps) => {
  return (
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: 'gainsboro' }}>
        <TableRow>
          <TableCell>전면 틴팅 제품 선택</TableCell>
          <TableCell>측후면 틴팅 제품 선택</TableCell>
          <TableCell>썬루프 틴팅 제품 선택</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Select
              style={{ marginRight: 10 }}
              value={frontTintingProductItemId}
              label={'전면 틴팅 제품'}
              options={frontTintingProductItemArray}
              disabled={frontTintingProductItemArray.length === 1}
              changeHandler={(event): void =>
                setSeletedValeus({ frontTintingProductItemId: event.target.value as string })
              }
            ></Select>
          </TableCell>
          <TableCell>
            <Select
              style={{ marginRight: 10 }}
              value={sidebackTintingProductItemId}
              label={'측후면 틴팅 제품'}
              options={sidebackTintingProductItemArray}
              disabled={sidebackTintingProductItemArray.length === 1}
              changeHandler={(event): void =>
                setSeletedValeus({ sidebackTintingProductItemId: event.target.value as string })
              }
            ></Select>
          </TableCell>
          <TableCell>
            <Select
              style={{ marginRight: 10 }}
              value={sunroofTintingProductItemId}
              label={'썬루프 틴팅 제품'}
              options={sunroofTintingProductItemArray}
              disabled={sunroofTintingProductItemArray.length === 1}
              changeHandler={(event): void =>
                setSeletedValeus({ sunroofTintingProductItemId: event.target.value as string })
              }
            ></Select>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
