// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__plot___3x3qi {\n  margin: auto; }\n\n.index-module__rankTable___1Z4tU {\n  float: left;\n  width: 100%;\n  height: 40%;\n  margin-bottom: 10px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/Analytics/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAA;;AAGd;EACE,WAAW;EACX,WAAW;EACX,WAAW;EACX,mBAAmB,EAAA","file":"index.module.scss","sourcesContent":[".plot {\n  margin: auto;\n}\n\n.rankTable {\n  float: left;\n  width: 100%;\n  height: 40%;\n  margin-bottom: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"plot": "index-module__plot___3x3qi",
	"rankTable": "index-module__rankTable___1Z4tU"
};
module.exports = exports;
