import React, { useState } from 'react'

import { Profile_Riderdash as ProfileRidereash } from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import Detail from './Detail'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const { signIn, isSignedIn, sendAxios, changeSnackbarAlertContent } = useRootData(({ authStore, appStore }) => ({
    signIn: authStore.signIn,
    isSignedIn: authStore.isSignedIn.get(),
    isVerified: authStore.isVerified.get(),
    sendAxios: appStore.sendAxios,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const startSignIn = async () => {
    const { data: profileList } = await sendAxios.get(`${config.backendEndPoint}/auth/get-profile-riderdash-by-email`, {
      params: {
        email,
      },
    })
    if (
      profileList
        .map((profile: ProfileRidereash) => {
          return profile.email
        })
        .find((profileEmail: string) => {
          return profileEmail === email
        }) === undefined
    ) {
      changeSnackbarAlertContent({ severity: 'warning', content: '아이디와 비밀번호를 다시 확인하세요.' })
    } else {
      signIn(email, password)
        .then(() => {
          setPassword('')
        })
        .catch(() => {
          changeSnackbarAlertContent({
            severity: 'warning',
            content: '아이디와 비밀번호를 다시 확인하세요.',
          })
        })
    }
  }

  if (!isSignedIn) {
    return (
      <div className={styles.loginContainer}>
        <div className={styles.title}>CARAMORA ADMIN</div>
        <div className={styles.subTitle}>아이디(이메일)</div>
        <input
          type={'text'}
          value={email}
          onChange={(e): void => {
            setEmail(e.target.value)
          }}
        />
        <div className={styles.subTitle}>비밀번호</div>
        <input
          type={'password'}
          value={password}
          onChange={(e): void => {
            setPassword(e.target.value)
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === 'Enter') {
              startSignIn()
            }
          }}
        />
        <button
          onClick={(): void => {
            startSignIn()
          }}
        >
          로그인
        </button>
      </div>
    )
  }

  return (
    <div className="body" style={{ flexDirection: 'column' }}>
      <Detail />
    </div>
  )
}
export default React.memo(App)
