import moment from 'moment'
import React from 'react'

import styles from './index.module.scss'

interface OrderVisualDateInputProps {
  tempStartDate: Array<string>
  setTempStartDate: React.Dispatch<React.SetStateAction<Array<string>>>
  startDate: Array<string>
  setStartDate: React.Dispatch<React.SetStateAction<Array<string>>>
  dateUnit: moment.unitOfTime.StartOf
}

const App: React.FunctionComponent<OrderVisualDateInputProps> = ({
  tempStartDate,
  setTempStartDate,
  startDate,
  setStartDate,
  dateUnit,
}: OrderVisualDateInputProps) => {
  let inputType = ''
  let setIndex = 0
  if (dateUnit === 'day') {
    inputType = 'date'
    setIndex = 0
  } else if (dateUnit === 'isoWeek') {
    inputType = 'week'
    setIndex = 1
  } else if (dateUnit === 'month') {
    inputType = 'month'
    setIndex = 2
  }

  return (
    <div className={styles.inputContainer}>
      {'시작 날짜: '}
      <input
        type={inputType}
        value={tempStartDate[setIndex]}
        onChange={(dateEvent) => {
          const temp = tempStartDate
          temp[setIndex] = dateEvent.target.value
          setTempStartDate([...temp])
        }}
      />
      <button
        onClick={(): void => {
          const temp = startDate
          temp[setIndex] = moment(tempStartDate[setIndex]).startOf(dateUnit).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
          setStartDate([...temp])
        }}
      >
        Render
      </button>
    </div>
  )
}
export default React.memo(App)
