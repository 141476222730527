import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const GET_PACKAGES = (): DocumentNode => {
  return gql`
    query GET_PACKAGES {
      normalPackages: package(
        where: { _and: [{ attribute: { _contains: { grade: "normal" } } }, { status: { _eq: true } }] }
      ) {
        id
        name
        product
        alias
        image
        name
        status
        price_product
        price_discount
        price_payout
        attribute
      }
      bestPackages: package(
        where: { _and: [{ attribute: { _contains: { grade: "best" } } }, { status: { _eq: true } }] }
      ) {
        id
        name
        product
        alias
        image
        name
        status
        price_product
        price_discount
        price_payout
        attribute
      }
      excellentPackages: package(
        where: { _and: [{ attribute: { _contains: { grade: "excellent" } } }, { status: { _eq: true } }] }
      ) {
        id
        name
        product
        alias
        image
        name
        status
        price_product
        price_discount
        price_payout
        attribute
      }
      economicPackages: package(
        where: { _and: [{ attribute: { _contains: { grade: "economic" } } }, { status: { _eq: true } }] }
      ) {
        id
        name
        product
        alias
        image
        name
        status
        price_product
        price_discount
        price_payout
        attribute
      }
      teslaPacakges: package(
        where: { _and: [{ attribute: { _contains: { grade: "tesla_premium" } } }, { status: { _eq: true } }] }
      ) {
        id
        name
        product
        alias
        image
        name
        status
        price_product
        price_discount
        price_payout
        attribute
      }
    }
  `
}

export const GET_PACKAGE_PRODUCT = (productsId: string): DocumentNode => {
  return gql`
    query GET_PACKAGE_PRODUCT {
      product( where: {
            _or: [
              ${productsId}
            ]
          }) {
        id
        name
        type
        product_brand {
          description
        }
      }
    }
  `
}
