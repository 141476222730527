// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___qYvqr .index-module__commentIcon___xwlK0 {\n  float: right;\n  right: 20px;\n  position: fixed;\n  height: 50px;\n  bottom: 30px; }\n\n.index-module__container___qYvqr .index-module__commentBox___3dW4Q {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  background-color: white;\n  border: 2px solid lightgray;\n  border-radius: 17px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/index.module.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY;EACZ,WAAW;EACX,eAAe;EACf,YAAY;EACZ,YAAY,EAAA;;AANhB;EAUI,eAAe;EACf,SAAS;EACT,QAAQ;EACR,uBAAuB;EACvB,2BAA2B;EAC3B,mBAAmB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  .commentIcon {\n    float: right;\n    right: 20px;\n    position: fixed;\n    height: 50px;\n    bottom: 30px;\n  }\n\n  .commentBox {\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    background-color: white;\n    border: 2px solid lightgray;\n    border-radius: 17px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___qYvqr",
	"commentIcon": "index-module__commentIcon___xwlK0",
	"commentBox": "index-module__commentBox___3dW4Q"
};
module.exports = exports;
