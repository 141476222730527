import gql from 'graphql-tag'

export const GET_QUOTE_REQUEST = gql`
  query GET_QUOTE_REQUEST($offset: Int!, $startDate: timestamptz, $endDate: timestamptz, $createdAt: order_by = desc) {
    quote_request: quote_request(
      where: { _and: { deleted_at: { _is_null: true }, created_at: { _gt: $startDate, _lt: $endDate } } }
      offset: $offset
      limit: 100
      order_by: { created_at: $createdAt }
    ) {
      id
      created_at
      session
      account
      content
      sent_at
      phone_number
    }
    request_count: quote_request_aggregate(
      where: { _and: { deleted_at: { _is_null: true }, created_at: { _gt: $startDate, _lt: $endDate } } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const UPDATE_QUOTE_REQUEST = gql`
  mutation UPDATE_QUOTE_REQUEST($phoneNumber: String, $date: timestamptz) {
    update_quote_request(where: { phone_number: { _eq: $phoneNumber } }, _set: { sent_at: $date }) {
      affected_rows
    }
  }
`
