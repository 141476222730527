// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___YlX5_ {\n  margin: 100px auto;\n  max-width: 1000px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadList/FilterDateTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin: 100px auto;\n  max-width: 1000px;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___YlX5_"
};
module.exports = exports;
