import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import axios from 'axios'
import _ from 'lodash'
import { observable, toJS } from 'mobx'
import moment from 'moment-timezone'
import { OptionsType } from 'react-select'

import {
  Account,
  Address_Sido as AddressSido,
  Address_Sigungu as AddressSigungu,
  Car,
  Car_Sunroof_Type as CarSunroofType,
  Car_Sunroof_Type_Enum as CarSunroofTypeEnum,
  Lead,
  Lead_Status_Enum as LeadStatusEnum,
} from '../../assets/graphql/graphql'
import { TypeSelectBox } from '../../components/atoms/Select'
import KakaoPixelType from '../../components/types/enums/kakaoPixelType.enum'
import config from '../../configs'
import { tableCol } from '../../pages/LeadList'
import { defaultDate } from '../../pages/LeadList/FilterDateTable'
import client from '../../utils/graphql'
import {
  autoHypenPhoneNumber,
  getLeadColValueByKey,
  getTheClosestWeekday,
  parseQueryString,
  searchChosung,
  setDueDate,
} from '../../utils/utility'
import appStore from '../app'
import authStore from '../auth'
import calculatorStore from '../calculator'
import orderStore from '../order'
import { pickedColumns as pickedColumnsAtOrder } from '../order/query'
import quoteStore from '../quote'
import { pickedColumns as pickedColumnsAtQuote, UPDATE_CART_ITEM_REMARK } from '../quote/query'
import {
  INSERT_LEAD,
  INSERT_LEAD_COMMENT,
  SUBSCRIPTION_LEAD,
  SUBSCRIPTION_LEADS,
  UPDATE_CHAT_AND_LEAD_AND_QUOTE,
  UPDATE_CHAT_AND_LEAD_AND_QUOTE_AND_ORDER,
  UPDATE_LEAD_AND_QUOTE,
  UPDATE_LEAD_AND_QUOTE_AND_ORDER,
  UPDATE_LEAD_BY_PK,
  UPDATE_LEAD_COMMENT,
  UPDATE_LEAD_STATUS,
} from './query'
import { initSearchInputValues, LEAD_COL, SearchInputValues, TypeDirection } from './type'

const createStore = (): typeof leadStore => {
  const leadStore = {
    lastUpdatedAt: moment(),
    currQuoteItemIds: null as string,
    currOrderId: null as string,

    // Initial state
    leadSubscription: null as ZenObservable.Subscription,
    leadsSubscription: null as ZenObservable.Subscription,

    statusFilterValue: observable.box<LeadStatusEnum>(LeadStatusEnum.Lead),
    selectedLead: observable.box<Lead>(null),
    leads: observable.box<Array<Lead>>([]),
    searchInputValues: observable.box<SearchInputValues>(initSearchInputValues),

    leadOrder: observable.box<TypeDirection>('desc'),
    leadOrderBy: observable.box<LEAD_COL>(LEAD_COL.UPDATED_AT),

    page: observable.box<number>(0),
    rowsPerPage: observable.box<number>(100),

    startCreatedAt: observable.box<moment.Moment>(moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(4, 'weeks')),
    endCreatedAt: observable.box<moment.Moment>(moment(moment(Date.now()).format('YYYY-MM-DD')).add(1, 'days')),
    endDueDate: observable.box<moment.Moment>(moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'weeks')),

    // Lead information
    selectedChatAssignee: observable.box<Account>(null),
    selectedSupportAssignee: observable.box<Account>(null),
    selectedMatchMaker: observable.box<Account>(null),
    selectedCustomer: observable.box<Account>(null),
    selectedStartDropOffDateTime: observable.box<string>(''),
    selectedEndDropOffDateTime: observable.box<string>(''),
    selectedDueDate: observable.box<string>(''),
    customerPhoneNumber: observable.box<string>(''),

    searchCarModel: observable.box<string>(''),
    selectedCar: observable.box<Car>(null),
    selectedCarSunroofType: observable.box<CarSunroofTypeEnum>(CarSunroofTypeEnum.None),
    selectedNewCar: observable.box<boolean>(null),
    selectedFilmNeedToRemoved: observable.box<boolean>(false),

    // Area information
    selectedAddressSido: observable.box<AddressSido>(null),
    selectedAddressSigungu: observable.box<AddressSigungu>(null),

    /* Getter */
    get addressSidoArray(): Array<TypeSelectBox> {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return [{ value: '', text: '선택 안함' }]

      return [
        { value: '', text: '선택 안함' },
        ...selectOptionsContent.address.map((sido) => {
          return {
            value: sido.id,
            text: sido.name,
          }
        }),
      ]
    },
    get addressSigunguArray(): Array<TypeSelectBox> {
      const selectedAddressSido = leadStore.selectedAddressSido.get()

      if (!selectedAddressSido) return [{ value: '', text: '선택 안함' }]

      return [
        { value: '', text: '선택 안함' },
        ...selectedAddressSido.address_sigungus.map((sigungu) => {
          return {
            value: sigungu.id,
            text: sigungu.name,
          }
        }),
      ]
    },
    get chatAsigneeArray(): Array<TypeSelectBox> {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return [{ value: '', text: '' }]

      return [
        { value: '', text: '선택 안함' },
        ...selectOptionsContent.chat_assignees.map((account: Account) => {
          return {
            value: account.id,
            text: account.profile_riderdash.name,
          }
        }),
      ]
    },
    get operatorArray(): Array<TypeSelectBox> {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return [{ value: '', text: '' }]

      return [
        { value: '', text: '선택 안함' },
        ...selectOptionsContent.operators.map((account: Account) => {
          return {
            value: account.id,
            text: account.profile_riderdash.name,
          }
        }),
      ]
    },
    get profileCustomerArray(): Array<{ value: string; label: string }> {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return [{ value: '', label: '' }]

      return [
        { value: '', label: '선택 안함' },
        ...selectOptionsContent.profileCustomers.map((account: Account) => {
          return {
            value: account.id,
            label: account.profile_kr_customer.email,
          }
        }),
      ]
    },
    get filterLeads(): Array<Lead> {
      const leads = leadStore.leads.get()
      const leadOrderBy = leadStore.leadOrderBy.get()
      const leadOrder = leadStore.leadOrder.get()
      const isASC = leadOrder === 'asc'

      if (leads.length === 0) return []
      const searchInputValues = leadStore.searchInputValues.get()

      let filterLeads: Array<Lead> = [...leads]

      Object.keys(searchInputValues).forEach((key: string) => {
        const inputValue = searchInputValues[key] as OptionsType<{ value: string; label: string }>

        if (typeof inputValue === 'string') {
          filterLeads = filterLeads.filter((lead) => {
            if (inputValue === '') return true

            return getLeadColValueByKey(lead, key as LEAD_COL).indexOf(inputValue) !== -1
          })
        }
        // OptionsType<{ value: string; label: string }>
        else {
          if (!inputValue) return true
          if (inputValue.length === 0) return true

          filterLeads = filterLeads.filter((lead) => {
            return (
              inputValue
                .map((selectValue) => selectValue.value)
                .indexOf(getLeadColValueByKey(lead, key as LEAD_COL)) !== -1
            )
          })

          return true
        }

        return true
      })

      filterLeads.sort((a, b) =>
        (getLeadColValueByKey(isASC ? a : b, leadOrderBy as LEAD_COL) || '').localeCompare(
          getLeadColValueByKey(isASC ? b : a, leadOrderBy as LEAD_COL) || '',
          undefined,
          {
            numeric: true,
            sensitivity: 'base',
          },
        ),
      )

      return filterLeads
    },
    get carModelsArray(): Array<TypeSelectBox> {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()
      let searchKeyword = leadStore.searchCarModel.get()

      searchKeyword = searchKeyword.toLowerCase()

      if (!selectOptionsContent) return [{ value: '', text: '' }]

      const dupCheck = new Set()

      return [
        { value: '', text: '선택 안함' },
        ...selectOptionsContent.carModels
          .map((car: Car) => {
            return {
              value: car.id,
              text: `[${car.car_maker.name_kr}] ${car.model}`,
            }
          })
          .filter((item) => {
            const { text } = item
            if (dupCheck.has(text)) return false
            dupCheck.add(text)

            return true
          })
          .filter((item) => searchChosung(searchKeyword, item.text))
          .sort((a, b) => {
            if (a.text > b.text) return 1

            return b.text > a.text ? -1 : 0
          }),
      ]
    },
    get carSunroofTypeArray(): Array<TypeSelectBox> {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return [{ value: '', text: '' }]

      return selectOptionsContent.carSunroofTypes.map((carSunroofTypeObject: CarSunroofType) => {
        return {
          value: carSunroofTypeObject.value,
          text: carSunroofTypeObject.description,
        }
      })
    },
    /* Setter */
    changeStatus: async (data: LeadStatusEnum) => {
      const { startCreatedAt, endCreatedAt, endDueDate } = defaultDate[data]
      leadStore.statusFilterValue.set(data)
      leadStore.page.set(0)
      leadStore.startCreatedAt.set(startCreatedAt)
      leadStore.endCreatedAt.set(endCreatedAt)
      leadStore.endDueDate.set(endDueDate)

      await leadStore.leadsSubscribe()
    },
    changeLeads(data: Array<Lead>) {
      leadStore.leads.set(data)
    },
    changeSearchInputValues(data: SearchInputValues) {
      const searchInputValues = leadStore.searchInputValues.get()

      leadStore.searchInputValues.set({
        ...searchInputValues,
        ...data,
      })
    },
    changeLeadOrder(data: TypeDirection) {
      leadStore.leadOrder.set(data)
    },
    changeLeadOrderBy(data: LEAD_COL) {
      leadStore.leadOrderBy.set(data)
    },
    changePage(data: number) {
      leadStore.page.set(data)
    },
    changeRowsPerPage(data: number) {
      leadStore.rowsPerPage.set(data)
    },
    changeStartCreatedAt(data: string) {
      leadStore.startCreatedAt.set(moment(moment(data).format('YYYY-MM-DD')))
    },
    changeEndCratedAt(data: string) {
      leadStore.endCreatedAt.set(moment(moment(data).format('YYYY-MM-DD')))
    },
    changeEndDueDate(data: string) {
      leadStore.endDueDate.set(moment(moment(data).format('YYYY-MM-DD')))
    },

    // Set lead data
    changeSelectedChatAssignee(id: string) {
      calculatorStore.getSelectedAccountObject('chat_assignees', id, (data: Account) =>
        leadStore.selectedChatAssignee.set(data),
      )
    },
    changeSelectedSupportAssignee(id: string) {
      calculatorStore.getSelectedAccountObject('operators', id, (data: Account) =>
        leadStore.selectedSupportAssignee.set(data),
      )
    },
    changeSelectedMatchMaker(id: string) {
      calculatorStore.getSelectedAccountObject('operators', id, (data: Account) =>
        leadStore.selectedMatchMaker.set(data),
      )
    },
    changeSelectedCustomer(data: { value: string; label: string }) {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return

      leadStore.selectedCustomer.set(
        selectOptionsContent.profileCustomers.filter((item) => item.id === data.value)[0] as Account,
      )
    },
    changeSelectedStartDropOffDateTime(date: string) {
      leadStore.selectedStartDropOffDateTime.set(date)
      leadStore.selectedDueDate.set(setDueDate(date)) // Set due date by start drop off date time
    },
    changeSelectedEndDropOffDateTime(date: string) {
      leadStore.selectedEndDropOffDateTime.set(date)
    },
    changeSelectedDueDate(date: string) {
      leadStore.selectedDueDate.set(date)
    },
    changeCustomerPhoneNumber(data: string) {
      leadStore.customerPhoneNumber.set(autoHypenPhoneNumber(data))
    },
    changeSearchCarModel(data: string) {
      leadStore.searchCarModel.set(data)
    },
    changeSelectedCar: (id: string) => {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return

      leadStore.selectedCar.set(selectOptionsContent.carModels.filter((item) => item.id === id)[0] as Car)

      calculatorStore.getSelectionProducts()
    },
    changeSelectedCarSunroofType: (data: CarSunroofTypeEnum) => {
      leadStore.selectedCarSunroofType.set(data)

      calculatorStore.getSelectionProducts()
    },
    changeSelectedNewCar(data: boolean) {
      leadStore.selectedNewCar.set(data)

      const selectedStartDropOffDateTime = leadStore.selectedStartDropOffDateTime.get()

      if (data === false && !selectedStartDropOffDateTime) {
        leadStore.changeSelectedStartDropOffDateTime(moment().format('YYYY-MM-DDT10:00'))
        leadStore.changeSelectedEndDropOffDateTime(moment().format('YYYY-MM-DDT10:00'))
      }
    },
    changeSelectedFilmNeedToRemoved(data: boolean) {
      leadStore.selectedFilmNeedToRemoved.set(data)
    },
    changeSelectedAddressSido(id: string) {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return

      leadStore.selectedAddressSigungu.set(null)
      leadStore.selectedAddressSido.set(selectOptionsContent.address.filter((item) => item.id === id)[0] as AddressSido)
    },
    changeSelectedAddressSigungu(id: string) {
      const selectedAddressSido = leadStore.selectedAddressSido.get()

      if (!selectedAddressSido) return

      leadStore.selectedAddressSigungu.set(
        selectedAddressSido.address_sigungus.filter((item) => item.id === id)[0] as AddressSigungu,
      )
    },
    /* Local Func */
    initializeLeadData: async (lead: Lead) => {
      /* eslint-disable camelcase */
      leadStore.selectedLead.set(lead)
      leadStore.statusFilterValue.set(lead?.status)

      const selectedCar = leadStore.selectedCar.get()
      const selectedCarSunroofType = leadStore.selectedCarSunroofType.get()
      const selectedAddressSido = leadStore.selectedAddressSido.get()
      const selectedAddressSigungu = leadStore.selectedAddressSigungu.get()

      // Initialize Lead data
      leadStore.selectedChatAssignee.set(
        lead?.chats && lead?.chats?.length > 0 ? lead?.chats?.[0]?.accountByChatAssignee : null,
      )
      leadStore.selectedSupportAssignee.set(lead?.accountBySupportAssignee)
      leadStore.selectedMatchMaker.set(lead?.accountByShopAssignee)
      leadStore.selectedCustomer.set(lead?.accountByCustomer)
      leadStore.customerPhoneNumber.set(lead?.phone ? new PhoneNumber(lead?.phone, 'KR').getNumber('national') : '')
      leadStore.selectedStartDropOffDateTime.set(
        lead?.start_drop_off_date_time
          ? moment(lead?.start_drop_off_date_time).locale('ko').format('YYYY-MM-DDTHH:00')
          : '',
      )
      leadStore.selectedEndDropOffDateTime.set(
        lead?.end_drop_off_date_time
          ? moment(lead?.end_drop_off_date_time).locale('ko').format('YYYY-MM-DDTHH:00')
          : '',
      )
      leadStore.selectedFilmNeedToRemoved.set(lead?.film_need_to_removed)

      leadStore.selectedNewCar.set(lead?.used_car_status === null ? null : !lead?.used_car_status)

      if (!selectedCar || selectedCar?.id !== lead?.carByCar?.id)
        leadStore.changeSelectedCar(lead?.carByCar ? lead?.carByCar?.id : '')

      if (selectedCarSunroofType !== lead?.car_sunroof_type)
        leadStore.changeSelectedCarSunroofType(lead?.car_sunroof_type)

      if (selectedAddressSido !== lead?.etc.address_sido)
        leadStore.changeSelectedAddressSido(lead?.etc?.address_sido ? lead?.etc?.address_sido : '')

      if (selectedAddressSigungu !== lead?.etc?.address_sigungu)
        leadStore.changeSelectedAddressSigungu(lead?.etc?.address_sigungu ? lead?.etc?.address_sigungu : '')

      let syncStoreStatus = true

      if (lead?.quotes?.[0]) {
        const { currQuoteItemIds } = leadStore
        const quoteItemIds = lead.quotes[0].quote_items.map((quoteItem) => quoteItem.id).toString()

        if (currQuoteItemIds !== quoteItemIds) syncStoreStatus = false
        quoteStore.setQuoteObject(lead.quotes[0])
        leadStore.currQuoteItemIds = quoteItemIds
      }

      if (lead?.orders && lead?.orders?.length > 0) {
        const { currOrderId } = leadStore
        const orderId = lead.orders[0].id

        if (currOrderId !== orderId) syncStoreStatus = false
        orderStore.setOrderObject(lead.orders[0])
        leadStore.currOrderId = orderId
      } else {
        orderStore.setOrderObject(null)
        leadStore.currOrderId = null
      }

      if (syncStoreStatus) {
        calculatorStore.calculatorFromQuoteAndOrder({
          prevFinalPrice: true,
          prevFinalPayoutPrice: true,
        })
      } else {
        calculatorStore.calculatorFromQuoteAndOrder()
      }

      /* eslint-enable camelcase */
    },

    /* Actions */
    insertLeadComment: async (comment: string) => {
      const selectedLead = leadStore.selectedLead.get()
      const { id } = parseQueryString()
      try {
        if (selectedLead.id !== id) throw Error('Different lead id in selectedLead and queryString')
        const account = authStore.accountId.get()

        const hasOrder = selectedLead.orders.length > 0

        const doNotUpdateCondition = authStore.isPartTimer.get() && hasOrder

        if (!doNotUpdateCondition) {
          await leadStore.updateLeadByPK()
        }

        await client.mutate({
          mutation: INSERT_LEAD_COMMENT,
          variables: {
            leadComment: {
              account,
              lead: selectedLead.id,
              comment,
            },
          },
          fetchPolicy: 'no-cache',
        })
      } catch (err) {
        Sentry.captureMessage(
          `insertLeadComment - ${err.message} - selectedLeadId = ${selectedLead.id}, queryString = ${id}`,
        )
      }
    },
    updateLeadComment: async (leadComentId: string, comment: string) => {
      const selectedLead = leadStore.selectedLead.get()
      const { id } = parseQueryString()

      try {
        if (selectedLead.id !== id) throw Error('Different lead id in selectedLead and queryString')

        const hasOrder = selectedLead.orders.length > 0

        const doNotUpdateCondition = authStore.isPartTimer.get() && hasOrder

        if (!doNotUpdateCondition) {
          await leadStore.updateLeadByPK()
        }

        await client.mutate({
          mutation: UPDATE_LEAD_COMMENT,
          variables: {
            id: leadComentId,
            comment,
          },
        })
      } catch (err) {
        Sentry.captureMessage(
          `updateLeadComment - ${err.message} - selectedLeadId = ${selectedLead.id}, queryString = ${id}`,
        )
      }
    },
    updateDueDateByUsedCarStatus: (): boolean => {
      const selectedLead = leadStore.selectedLead.get()
      if (!selectedLead) return false

      // The due date is specific data to be set after loading the lead detail page
      leadStore.selectedDueDate.set(selectedLead.due_date ? moment(selectedLead.due_date).format('YYYY-MM-DD') : '')

      if (
        selectedLead.status === LeadStatusEnum.SettlementPending ||
        selectedLead.status === LeadStatusEnum.VatRequested ||
        selectedLead.status === LeadStatusEnum.Done
      )
        return true

      if (!selectedLead.start_drop_off_date_time) return true

      const currentTime = Math.floor(new Date().getTime() / 1000)
      const passedStartDropOffDateTime = moment(selectedLead.start_drop_off_date_time).unix() <= currentTime

      if (!passedStartDropOffDateTime || authStore.isPartTimer.get()) return true

      // If start drop off date time already passed, set the due date to next day
      const date = getTheClosestWeekday(moment.unix(currentTime + 60 * 60 * 24).format('YYYY-MM-DD'))

      leadStore.selectedDueDate.set(date)
      appStore.changeSnackbarAlertContent({
        severity: 'warning',
        content: `Due Date가 ${date}로 설정됩니다.\n변경이 필요하면 Due Date을 직접 수정해주세요.`,
      })

      return true
    },
    updateLeadByPK: async (): Promise<boolean> => {
      const { id } = parseQueryString()
      const selectedLead = leadStore.selectedLead.get()

      try {
        appStore.changeLoadingSpinnerVisibility(true)

        if (selectedLead.id !== id) throw Error('Different lead id in selectedLead and queryString')

        const priceDiffDetected = calculatorStore.detectDiffPrice
          .filter((item) => item !== null)
          .reduce((prev, current) => prev || current, false)

        if (priceDiffDetected) {
          // eslint-disable-next-line no-alert
          const ret = window.confirm('지금 저장을 진행할 경우, 가격이 변동되는 견적이 존재합니다. 계속 하시겠습니까?')

          if (!ret) {
            appStore.changeLoadingSpinnerVisibility(false)

            return false
          }
        }

        if (quoteStore.fillQuoteFromCalculator()) {
          return false
        }

        if (selectedLead.orders.length > 0 && orderStore.fillOrderFromCalculator()) {
          return false
        }

        // Lead Data
        const accountId = authStore.accountId.get()
        const selectedChatAssignee = leadStore.selectedChatAssignee.get()
        const selectedSupportAssignee = leadStore.selectedSupportAssignee.get()
        const selectedMatchMaker = leadStore.selectedMatchMaker.get()
        const selectedCustomer = leadStore.selectedCustomer.get()
        const selectedStartDropOffDateTime = leadStore.selectedStartDropOffDateTime.get()
        const selectedEndDropOffDateTime = leadStore.selectedEndDropOffDateTime.get()
        const selectedDueDate = leadStore.selectedDueDate.get()
        const selectedCar = leadStore.selectedCar.get()
        const selectedNewCar = leadStore.selectedNewCar.get()
        const selectedCarSunroofType = leadStore.selectedCarSunroofType.get()
        const selectedFilmNeedToRemoved = leadStore.selectedFilmNeedToRemoved.get()
        const selectedAddressSido = leadStore.selectedAddressSido.get()
        const selectedAddressSigungu = leadStore.selectedAddressSigungu.get()

        // Order Data
        const orderObject = orderStore.orderObject.get()

        // Quote Data
        const quoteObject = quoteStore.quoteObject.get()

        if (!quoteObject) return false

        const hasChat = selectedLead.chats.length > 0
        const hasOrder = selectedLead.orders.length > 0

        let mutationQuery = hasOrder ? UPDATE_LEAD_AND_QUOTE_AND_ORDER : UPDATE_LEAD_AND_QUOTE

        if (hasChat) {
          mutationQuery = hasOrder ? UPDATE_CHAT_AND_LEAD_AND_QUOTE_AND_ORDER : UPDATE_CHAT_AND_LEAD_AND_QUOTE
        }

        await client.mutate({
          mutation: mutationQuery,
          variables: {
            ...(hasChat ? { chatId: selectedLead.chats[0].id } : {}),
            ...(hasChat
              ? {
                  chatObject: {
                    chat_assignee: selectedChatAssignee ? selectedChatAssignee.id : null,
                  },
                }
              : {}),
            leadId: selectedLead.id,
            leadObject: {
              used_car_status: selectedNewCar !== null ? !selectedNewCar : null,
              car_sunroof_type: selectedCarSunroofType,
              film_need_to_removed: selectedFilmNeedToRemoved,
              support_assignee: selectedSupportAssignee ? selectedSupportAssignee.id : null,
              shop_assignee: selectedMatchMaker ? selectedMatchMaker.id : null,
              customer: selectedCustomer ? selectedCustomer.id : null,
              ...(selectedStartDropOffDateTime
                ? { start_drop_off_date_time: moment(selectedStartDropOffDateTime).locale('ko') }
                : { start_drop_off_date_time: null }),
              ...(selectedEndDropOffDateTime
                ? { end_drop_off_date_time: moment(selectedEndDropOffDateTime).locale('ko') }
                : { end_drop_off_date_time: null }),
              ...(selectedDueDate ? { due_date: selectedDueDate } : { due_date: null }),
              car: selectedCar ? selectedCar.id : null,
              etc: {
                address_sido: selectedAddressSido ? selectedAddressSido.id : null,
                address_sigungu: selectedAddressSigungu ? selectedAddressSigungu.id : null,
              },
              updated_by: accountId,
            },
            ...(quoteObject
              ? {
                  quoteObject: {
                    id: quoteObject.id,
                    lead: selectedLead.id,
                    quote_items: {
                      data: toJS(quoteObject.quote_items).map((quoteItem) =>
                        _.pick(quoteItem, ...pickedColumnsAtQuote),
                      ),
                      on_conflict: {
                        constraint: 'quote_item_pkey',
                        update_columns: pickedColumnsAtQuote,
                      },
                    },
                  },
                }
              : {}),
            ...(hasOrder ? { orderId: orderObject.id } : {}),
            ...(hasOrder
              ? {
                  orderObject: {
                    ..._.pick(
                      {
                        ...orderObject,
                        ...(selectedCustomer ? { account: selectedCustomer.id } : {}),
                      },
                      ...pickedColumnsAtOrder,
                    ),
                  },
                }
              : {}),
          },
          fetchPolicy: 'no-cache',
        })

        // sync quoteItem and cartItem (only remark)
        client.mutate({
          mutation: UPDATE_CART_ITEM_REMARK(quoteObject),
          fetchPolicy: 'no-cache',
        })

        return true
      } catch (err) {
        Sentry.captureMessage(
          `updateLeadByPK - ${err.message} - selectedLeadId = ${selectedLead.id}, queryString = ${id}`,
        )

        return false
      }
    },
    giveLeadKey: async () => {
      const accountId = authStore.accountId.get()
      const selectedLead = leadStore.selectedLead.get()
      const customerPhoneNumber = leadStore.customerPhoneNumber.get()
      const phone = new PhoneNumber(customerPhoneNumber.replace(/-/gi, ''), 'KR').getNumber('e164')

      await leadStore.updateLeadByPK()

      await client.mutate({
        mutation: UPDATE_LEAD_BY_PK,
        variables: {
          leadId: selectedLead.id,
          leadObject: {
            phone,
          },
        },
      })

      if (
        selectedLead.status === LeadStatusEnum.Chat ||
        selectedLead.status === LeadStatusEnum.Missed ||
        selectedLead.status === LeadStatusEnum.Duplicate
      ) {
        await client.mutate({
          mutation: UPDATE_LEAD_STATUS(LeadStatusEnum.Lead),
          variables: {
            id: selectedLead.id,
            now: new Date().toUTCString(),
          },
        })

        await axios.post(`${config.backendEndPoint}/send/send-value-proposition`, {
          accountId,
          phoneNumber: phone,
          leadId: selectedLead.id,
        })

        appStore.sendAnalytics({
          kakaoPixel: KakaoPixelType.CREATE_LEAD,
        })
      }
    },
    leadSubscribe: async (leadId: string) => {
      const { id } = parseQueryString()

      try {
        if (leadId !== id) throw Error('Different lead id in selectedLead and queryString')

        appStore.changeLoadingSpinnerVisibility(true)

        calculatorStore.initStore()
        quoteStore.clearQuoteObject()
        orderStore.clearOrderObject()
        leadStore.currQuoteItemIds = null
        leadStore.currOrderId = null

        // To autosave...
        await leadStore.leadUnsubscribe()
        await new Promise((r) => setTimeout(r, 1000))

        leadStore.leadSubscription = await client
          .subscribe({
            query: SUBSCRIPTION_LEAD,
            fetchPolicy: 'no-cache',
            variables: {
              id: leadId,
            },
          })
          .subscribe(({ data: content }) => {
            const { id: currentLeadId } = parseQueryString()

            if (leadStore.leadSubscription && content.lead_by_pk.id === currentLeadId) {
              leadStore.initializeLeadData(content.lead_by_pk)
            }
          })
      } catch (err) {
        appStore.changeLoadingSpinnerVisibility(false)

        Sentry.captureMessage(`leadSubscribe - ${err.message} - leadId = ${leadId}, queryString = ${id}`)
        appStore.changeMainMenu(`/lead-list?status=${leadStore.statusFilterValue.get()}`)
        appStore.changeSnackbarAlertContent({
          severity: 'warning',
          content: `상세 정보를 가져오는데 실패했습니다. 개발팀에게 문의해주세요!`,
        })
      }
    },
    leadsSubscribe: async () => {
      const statusFilterValue = leadStore.statusFilterValue.get()
      const startCreatedAt = leadStore.startCreatedAt.get().toISOString()
      const endCreatedAt = leadStore.endCreatedAt.get().toISOString()
      const endDueDate = leadStore.endDueDate.get().toISOString()

      try {
        appStore.changeLoadingSpinnerVisibility(true)

        // To autosave...
        await leadStore.leadsUnsubscribe()
        await new Promise((r) => setTimeout(r, 1000))

        leadStore.leadsSubscription = await client
          .subscribe({
            query: SUBSCRIPTION_LEADS(
              statusFilterValue,
              startCreatedAt,
              endCreatedAt,
              endDueDate,
              tableCol[statusFilterValue],
            ),
            fetchPolicy: 'no-cache',
          })
          .subscribe(({ data: content }) => {
            if (leadStore.leadsSubscription) {
              leadStore.leads.set(content.lead)
              appStore.changeLoadingSpinnerVisibility(false)
            }
          })
      } catch (err) {
        Sentry.captureMessage(`leadsSubscribe - ${err.message}`)
        appStore.changeSnackbarAlertContent({
          severity: 'warning',
          content: `정보를 가져오는데 실패했습니다. 개발팀에게 문의해주세요!`,
        })
      }
    },
    leadUnsubscribe: async () => {
      if (!leadStore.leadSubscription) return

      leadStore.selectedLead.set(null)

      await leadStore.leadSubscription.unsubscribe()

      leadStore.leadSubscription = null
    },
    leadsUnsubscribe: async () => {
      if (!leadStore.leadsSubscription) return

      leadStore.leads.set([])

      await leadStore.leadsSubscription.unsubscribe()

      leadStore.leadsSubscription = null
    },
    createLead: async (phone: string) => {
      const leadObject = {
        lead_at: new Date().toUTCString(),
        status: LeadStatusEnum.Lead,
        phone: new PhoneNumber(phone.replace(/-/gi, ''), 'KR').getNumber('e164'),
        quotes: { data: { quote_items: { data: {} } } },
      }

      const {
        data: {
          insert_lead_one: { id: leadId },
        },
      } = await client.mutate({
        mutation: INSERT_LEAD,
        variables: {
          leadObject,
        },
      })

      appStore.changeMainMenu(`/lead-detail?id=${leadId}`)
    },
  }

  return leadStore
}

const store = createStore()
export default store
