import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const GET_PRODUCT_ITEMS_WITH_SKU = (productItemId: string): DocumentNode => {
  return gql`
    query GET_PRODUCT_ITEMS_WITH_SKU {
      productItems: product_item(
        where: {
          _or: [
            ${productItemId}
          ]
        }
      ) {
        attribute
        price
        sku
        productByProduct {
          id
          name
          type
          product_brand {
            description
          }
          attribute
        }
      }

    }
  `
}

export const GET_ORDERS = (sortMode: string, filterMode: string): DocumentNode => gql`
  query GET_ORDERS($page: Int!, $rowsPerPage: Int!) {
    order(limit: $rowsPerPage, offset: $page, order_by: [${sortMode}], where: { _and: [${filterMode}] }) {
      id
      status
      created_at
      account
      accountByAccount {
        created_at
        profile_kr_customer {
          stripe_customer_id
          rapyd_customer_id
        }
      }
      leadByLead {
      id
      status
      accountBySupportAssignee {
        profile_riderdashes {
          name
        }
      }
      accountByShopAssignee {
        profile_riderdashes {
          name
        }
      }
      chats {
        id
        accountByChatAssignee {
          profile_riderdashes {
            name
          }
        }
      }
    }
      is_777_order
      product_item
      car
      car_sunroof_type
      price_product
      price_final
      price_discount
      price_extra
      date_time
      date_time_option
      name
      email
      phone
      remark
      paid_at
      finished_at
      payment_link_sent_at
      refunded_at
      installed_at
      package_detail {
        name
        package_type {
          description
        }
      }
      product_item_detail {
        attribute
        price
        productByProduct {
          product_brand {
            description
          }
          name
          product_type {
            value
            description
          }
        }
      }
      price_payout
      price_payout_without_vat
      carByCar {
        car_maker {
          name_kr
        }
        model
      }
      carSunroofTypeByCarSunroofType {
        description
      }
      order_status {
        value
        description
      }
      storeByStore {
        id
        name
        address_detail
        location
        phone_business
        addressSigunguByAddressSigungu {
          name
          address_sido {
            name
          }
        }
      }
      order_and_transactions(where: { transactionByTransaction: { type: { _eq: purchase } } }) {
        transaction
        transactionByTransaction {
          stripe_payment_intent_id
          rapyd_payment_intent_id
          bootpay_payment_intent_id
        }
      }
    }

    order_aggregate(order_by: [${sortMode}], where: { _and: [${filterMode}] }) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ALL_STORES = gql`
  query GET_ALL_STORES {
    store {
      name
    }
  }
`

export const GET_ORDER_PRODUCT = gql`
  query GET_ORDER_PRODUCT($id: uuid!) {
    order(where: { id: { _eq: $id } }) {
      product_item
    }
  }
`

export const GET_PACKAGE_DETAIL = (): DocumentNode => {
  return gql`
    query GET_PACKAGE_DETAIL($newCarPackageId: uuid!) {
      package(where: { id: { _eq: $newCarPackageId } }) {
        id
        name
        product
        alias
        image
        name
        status
        price_product
        price_discount
        attribute
        productByAddOnProduct {
          product_items {
            id
            attribute
            price
          }
        }
      }
    }
  `
}

export const GET_ORDER_PRODUCT_ITEM = (productItemId: string): DocumentNode => {
  return gql`
    query GET_ORDER_PRODUCT_ITEM {
       product_item(
        where: {
          _or: [
            ${productItemId}
          ]
        }
      ) {
        attribute
        price
        productByProduct {
          id
          name
          product_brand {
            description
          }
        }
      }
    }
  `
}

export const UPDATE_ORDER = gql`
  mutation UPDATE_ORDER(
    $id: uuid!
    $status: order_status_enum!
    $accountsPayableAndPayOutObject: accounts_payable_and_payout_insert_input!
    $date: timestamptz!
  ) {
    update_order(where: { id: { _eq: $id } }, _set: { status: $status, finished_at: $date }) {
      affected_rows
    }
    insert_accounts_payable_and_payout_one(object: $accountsPayableAndPayOutObject) {
      accounts_payable
      payout
    }
  }
`

export const UPDATE_ORDER_SCHEDULE = gql`
  mutation UPDATE_ORDER_SCHEDULE($id: uuid!, $date: timestamptz!) {
    update_order(where: { id: { _eq: $id } }, _set: { date_time: $date }) {
      affected_rows
    }
  }
`
export const UPDATE_ORDER_STATUS = (status: string): DocumentNode => {
  const setter = `, _set: { status: ${status}, ${status}_at: $date }`

  return gql`
      mutation UPDATE_ORDER_STATUS($id: uuid!, $date: timestamptz!) {
        update_order(where: { id: { _eq: $id } } ${setter}) {
          affected_rows
        }
      }
    `
}
