import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect } from 'react'

import {
  Accounts_Payable as AccountsPayable,
  Get_Accounts_PayableQuery as GetAccountsPayableQuery,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import styles from './index.module.scss'

const GET_ACCOUNTS_PAYABLE = gql`
  query GET_ACCOUNTS_PAYABLE {
    accounts_payable(order_by: { created_at: desc }) {
      id
      created_at
      updated_at
      accounts_payable_and_payouts {
        payoutByPayout {
          order
          status
          finished_at
          reverted_at
          storeByStore {
            name
          }
          orderByOrder {
            price_payout
            name
            email
            commission_rate
          }
        }
      }
      accounts_payable_and_refunds {
        refundByRefund {
          order
          status

          orderByOrder {
            price_payout
            email
            name
          }
          transactionByTransaction {
            stripe_receipt_url
          }
        }
      }
      accounts_payable_and_adjustments {
        adjustmentByAdjustment {
          order

          status
          finished_at
          reverted_at
          orderByOrder {
            price_payout
            email
            name
          }
          storeByStore {
            name
          }
        }
      }
    }
  }
`
const UPDATE_ACCOUNTS_PAYABLE_AND_PAYOUT = gql`
  mutation UPDATE_ACCOUNTS_PAYABLE_AND_PAYOUT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
    update_accounts_payable(where: { id: { _eq: $accountPayableId } }, _set: { deleted_at: $timeStamp }) {
      affected_rows
    }
    update_accounts_payable_and_payout(
      where: { accounts_payable: { _eq: $accountPayableId } }
      _set: { deleted_at: $timeStamp }
    ) {
      affected_rows
    }
    update_payout(
      where: { accounts_payable_and_payouts: { accounts_payable: { _eq: $accountPayableId } } }
      _set: { status: finished, finished_at: $timeStamp }
    ) {
      affected_rows
    }
  }
`

const UPDATE_ACCOUNTS_PAYABLE_AND_ADJUSTMENT = gql`
  mutation UPDATE_ACCOUNTS_PAYABLE_AND_ADJUSTMENT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
    update_accounts_payable(where: { id: { _eq: $accountPayableId } }, _set: { deleted_at: $timeStamp }) {
      affected_rows
    }
    update_accounts_payable_and_adjustment(
      where: { accounts_payable: { _eq: $accountPayableId } }
      _set: { deleted_at: $timeStamp }
    ) {
      affected_rows
    }
    update_adjustment(
      where: { accounts_payable_and_adjustments: { accounts_payable: { _eq: $accountPayableId } } }
      _set: { status: finished, finished_at: $timeStamp }
    ) {
      affected_rows
    }
  }
`

const REVERT_ACCOUNTS_PAYABLE_AND_PAYOUT = gql`
  mutation REVERT_ACCOUNTS_PAYABLE_AND_PAYOUT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
    update_accounts_payable(where: { id: { _eq: $accountPayableId } }, _set: { deleted_at: null }) {
      affected_rows
    }
    update_accounts_payable_and_payout(
      where: { accounts_payable: { _eq: $accountPayableId } }
      _set: { deleted_at: null }
    ) {
      affected_rows
    }
    update_payout(
      where: { accounts_payable_and_payouts: { accounts_payable: { _eq: $accountPayableId } } }
      _set: { status: reverted, reverted_at: $timeStamp, finished_at: null }
    ) {
      affected_rows
    }
  }
`

const REVERT_ACCOUNTS_PAYABLE_AND_ADJUSTMENT = gql`
  mutation REVERT_ACCOUNTS_PAYABLE_AND_ADJUSTMENT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
    update_accounts_payable(where: { id: { _eq: $accountPayableId } }, _set: { deleted_at: null }) {
      affected_rows
    }
    update_accounts_payable_and_adjustment(
      where: { accounts_payable: { _eq: $accountPayableId } }
      _set: { deleted_at: null }
    ) {
      affected_rows
    }
    update_adjustment(
      where: { accounts_payable_and_adjustments: { accounts_payable: { _eq: $accountPayableId } } }
      _set: { status: reverted, reverted_at: $timeStamp, finished_at: null }
    ) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))
  const [getAccountsPayable, { data, loading, error }] = useLazyQuery<GetAccountsPayableQuery>(GET_ACCOUNTS_PAYABLE, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getAccountsPayable()
  }, [])

  if (loading) return null
  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>외상매입금 목록</div>
        <br />
        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th>타입</th>
              <th>상태</th>
              <th>시공결제 일자</th>
              <th>지불완료 일자</th>
              <th>지불취소 일자</th>
              <th>고객명</th>
              <th>고객 이메일</th>
              <th>금액 (VAT 포함)</th>
              <th>카라모라 마진율</th>
              <th>매장/영수증</th>
              <th>Order ID</th>
              <th>시공 상세보기</th>
              <th></th>
              <th></th>
            </tr>
            {data &&
              data.accounts_payable.map((item: AccountsPayable, index: number) => {
                let keys = ''
                let itemsByKeys = ['']

                type tables =
                  | 'accounts_payable_and_payouts'
                  | 'accounts_payable_and_refunds'
                  | 'accounts_payable_and_adjustments'

                Object.keys(item).forEach((key) => {
                  if (item[key as tables].length >= 1 && key === 'accounts_payable_and_payouts') {
                    keys = 'payout'
                    const itemData = item.accounts_payable_and_payouts[0].payoutByPayout
                    itemsByKeys = [
                      itemData.status,
                      itemData.order,
                      itemData.orderByOrder.name,
                      itemData.orderByOrder.email,
                      itemData.orderByOrder.price_payout,
                      itemData.storeByStore.name,
                      itemData.finished_at,
                      itemData.reverted_at,
                      itemData.orderByOrder.commission_rate,
                    ]
                  } else if (item[key as tables].length >= 1 && key === 'accounts_payable_and_refunds') {
                    keys = 'refund'
                    const itemData = item.accounts_payable_and_refunds[0].refundByRefund
                    itemsByKeys = [
                      itemData.status,
                      itemData.order,
                      itemData.orderByOrder.name,
                      itemData.orderByOrder.email,
                      itemData.orderByOrder.price_payout,
                      itemData.transactionByTransaction.stripe_receipt_url,
                    ]
                  } else if (item[key as tables].length >= 1 && key === 'accounts_payable_and_adjustments') {
                    keys = 'adjustment'
                    const itemData = item.accounts_payable_and_adjustments[0].adjustmentByAdjustment
                    itemsByKeys = [
                      itemData.status,
                      itemData.order,
                      itemData.orderByOrder.name,
                      itemData.orderByOrder.email,
                      itemData.orderByOrder.price_payout,
                      itemData.storeByStore.name,
                      itemData.finished_at,
                      itemData.reverted_at,
                    ]
                  }
                })

                return (
                  <tr key={index}>
                    <td>{data.accounts_payable.length - index}</td>
                    <td>{keys}</td>
                    <td>{itemsByKeys[0]}</td>
                    <td>{moment(item.created_at).format('YY/MM/DD HH:mm')}</td>
                    <td>{itemsByKeys[6] && moment(itemsByKeys[6]).format('YY/MM/DD HH:mm')}</td>
                    <td>{itemsByKeys[7] && moment(itemsByKeys[7]).format('YY/MM/DD HH:mm')}</td>
                    <td>{itemsByKeys[2]}</td>
                    <td>{itemsByKeys[3]}</td>
                    {(keys && (
                      <React.Fragment>
                        <td>{itemsByKeys[4]}</td>
                        <td>{`${itemsByKeys[8]}%`}</td>
                        <td>{keys === 'refund' ? <a href={itemsByKeys[5]}>영수증 보기</a> : itemsByKeys[5]}</td>
                        <td>{itemsByKeys[1]}</td>
                        <td>
                          {itemsByKeys[1] && (
                            <button
                              onClick={(): void => {
                                changeMainMenu(`/order-detail?id=${itemsByKeys[1]}`)
                              }}
                            >
                              시공 상세보기
                            </button>
                          )}
                        </td>

                        <td>
                          {keys !== 'refund' && itemsByKeys[0] !== 'finished' && (
                            <button
                              onClick={async (): Promise<void> => {
                                const timeStamp = moment().utc().format()

                                try {
                                  if (keys === 'adjustment') {
                                    await client.mutate({
                                      mutation: UPDATE_ACCOUNTS_PAYABLE_AND_ADJUSTMENT,
                                      variables: {
                                        accountPayableId: item.id,
                                        timeStamp,
                                      },
                                    })
                                  } else if (keys === 'payout') {
                                    await client.mutate({
                                      mutation: UPDATE_ACCOUNTS_PAYABLE_AND_PAYOUT,
                                      variables: {
                                        accountPayableId: item.id,
                                        timeStamp,
                                      },
                                    })
                                  }

                                  getAccountsPayable()

                                  changeSnackbarAlertContent({
                                    severity: 'success',
                                    content: '정상적으로 처리되었습니다.',
                                  })
                                } catch (err) {
                                  Sentry.captureException(err)

                                  changeSnackbarAlertContent({ severity: 'error', content: '실패하였습니다.' })
                                }
                              }}
                            >
                              지불 완료
                            </button>
                          )}
                        </td>
                        <td>
                          {keys !== 'refund' && itemsByKeys[0] === 'finished' && (
                            <button
                              onClick={async (): Promise<void> => {
                                const timeStamp = moment().format()

                                try {
                                  if (keys === 'adjustment') {
                                    await client.mutate({
                                      mutation: REVERT_ACCOUNTS_PAYABLE_AND_ADJUSTMENT,
                                      variables: {
                                        accountPayableId: item.id,
                                        timeStamp,
                                      },
                                    })
                                  } else if (keys === 'payout') {
                                    await client.mutate({
                                      mutation: REVERT_ACCOUNTS_PAYABLE_AND_PAYOUT,
                                      variables: {
                                        accountPayableId: item.id,
                                        timeStamp,
                                      },
                                    })
                                  }

                                  getAccountsPayable()

                                  changeSnackbarAlertContent({
                                    severity: 'success',
                                    content: '정상적으로 처리되었습니다.',
                                  })
                                } catch (err) {
                                  Sentry.captureException(err)

                                  changeSnackbarAlertContent({ severity: 'error', content: '실패하였습니다.' })
                                }
                              }}
                            >
                              지불 취소
                            </button>
                          )}
                        </td>
                      </React.Fragment>
                    )) || (
                      <React.Fragment>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
