import * as Sentry from '@sentry/react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { observable } from 'mobx'

import KakaoPixelType from '../../components/types/enums/kakaoPixelType.enum'
import { EnumNabusType, NabusType } from '../../components/types/enums/nabusType.enum'
import history from '../../utils/history'
import orderStore from '../order'

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>
    dataEventLayer: Array<Record<string, unknown>>
    _11h11m: {
      log: (_11h11mConv: NabusType, type: string) => void
    }
    kakaoPixel: (
      trackId: string,
    ) => {
      pageView: (tag?: string) => void
      participation: (tag?: string) => void
      signUp: (tag?: string) => void
      purchase: (tag?: string) => void
    }
    GA_TRACKING_ID: string
    // eslint-disable-next-line camelcase
    custom_map: string
  }
}

export interface SendAnalyticsDto {
  kakaoPixel?: KakaoPixelType
  nabus?: EnumNabusType
}

let theFirstURL = window.location.pathname.substring(1) + window.location.search
if (theFirstURL.indexOf('error') > -1) {
  theFirstURL = ''
}

const urlOrigin =
  window.location.origin ||
  `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`

const createStore = (): typeof appStore => {
  let timeOutId: NodeJS.Timeout

  const appStore = {
    // Initial state
    currentMainMenu: observable.box(theFirstURL),
    urlOrigin: observable.box(urlOrigin),
    snackbarAlertContent: observable.box<{ content: string; severity: 'error' | 'success' | 'info' | 'warning' }>(null),
    snackbarOpened: observable.box(true),
    ganBlog: observable.box(false),
    loadingSpinnerVisibility: observable.box(false),

    // Change menu
    changeMainMenu(data: string): void {
      appStore.currentMainMenu.set(data)
      history.push(data)
    },

    // Create GAN blog
    changeGanBlog(data: boolean): void {
      appStore.ganBlog.set(data)
    },
    changeLoadingSpinnerVisibility(data: boolean, delay?: boolean): void {
      if (!data && delay) {
        // Visual effect
        clearTimeout(timeOutId)
        timeOutId = setTimeout(() => {
          appStore.loadingSpinnerVisibility.set(false)
        }, 200)
      } else {
        appStore.loadingSpinnerVisibility.set(data)
      }
    },

    changeSnackbarVisibility(data: boolean): void {
      appStore.snackbarOpened.set(data)
    },

    changeSnackbarAlertContent(data: { content: string; severity: 'error' | 'success' | 'info' | 'warning' }): void {
      // eslint-disable-next-line no-console
      console.log('alert :', JSON.stringify(data))
      appStore.snackbarOpened.set(!!data)
      appStore.snackbarAlertContent.set(data)
    },

    sendNabusScript(data: { conversionTypeId: string }): void {
      const orderObject = orderStore.orderObject.get()

      const { conversionTypeId } = data

      // eslint-disable-next-line no-underscore-dangle
      const _11h11mConv: NabusType = {
        convTypeId: '',
        convKey: '',
        convCa: '',
        convDetName: '',
        convDetCost: '',
        convDetCnt: '',
        convDetCategory: '',
      }

      const convDetName: Array<string> = []
      const convDetCategory: Array<string> = []
      const convDetCnt: Array<string> = []
      const convDetCost: Array<string> = []

      _11h11mConv.convTypeId = conversionTypeId // DO NOT EDIT THIS LINE
      _11h11mConv.convKey = orderObject.id || ''
      _11h11mConv.convCa = Number(orderObject.price_final).toString() || ''

      orderObject.product_item_detail.forEach((item) => {
        convDetName.push(item.attribute.product_name)
        convDetCost.push(item.price)
        convDetCnt.push('1')
        convDetCategory.push(item.attribute.brand)
      })

      _11h11mConv.convDetName = convDetName.join(';')
      _11h11mConv.convDetCost = convDetCost.join(';')
      _11h11mConv.convDetCnt = convDetCnt.join(';')
      _11h11mConv.convDetCategory = convDetCategory.join(';')

      // console.log('_11h11mConv', _11h11mConv)

      // DO NOT EDIT BELOW THIS LINE
      try {
        // eslint-disable-next-line no-underscore-dangle
        window._11h11m.log(_11h11mConv, 'conv')
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    sendKakaoPixel(type: string): void {
      if (!window.kakaoPixel) return

      const kakaoPixel = window.kakaoPixel('6733912353870622846')

      /* 이벤트 정의
        participation : 잠재고객 이벤트
        signUp : 서비스신청 이벤트
        purchase : 구매 이벤트 
      */
      const kakaoPixelEvent = {
        createLead: (): void => {
          kakaoPixel.pageView()
          kakaoPixel.participation(KakaoPixelType.CREATE_LEAD)
        },
        placeOrder: (): void => {
          kakaoPixel.pageView()
          kakaoPixel.signUp(KakaoPixelType.PLACE_ORDER)
        },
        purchase: (): void => {
          kakaoPixel.pageView()
          kakaoPixel.purchase(KakaoPixelType.DONE)
        },
      }

      switch (type) {
        case KakaoPixelType.CREATE_LEAD:
          kakaoPixelEvent.createLead()
          break

        case KakaoPixelType.PLACE_ORDER:
          kakaoPixelEvent.placeOrder()
          break

        case KakaoPixelType.DONE:
          kakaoPixelEvent.purchase()
          break

        default:
          break
      }
    },

    /* Collect Event for Kakao pixel and Nabus */
    async sendAnalytics(data: SendAnalyticsDto) {
      const { kakaoPixel, nabus } = data

      // send kakao pixel
      if (kakaoPixel) {
        appStore.sendKakaoPixel(kakaoPixel)
      }

      if (nabus) {
        appStore.sendNabusScript({
          conversionTypeId: nabus,
        })
      }
    },

    sendAxios: {
      get: async (url: string, request?: AxiosRequestConfig): Promise<AxiosResponse> => {
        appStore.loadingSpinnerVisibility.set(true)
        try {
          const response = await axios.get(url, request)
          appStore.loadingSpinnerVisibility.set(false)

          return response
        } catch (err) {
          Sentry.captureException(err)

          appStore.loadingSpinnerVisibility.set(false)

          return null
        }
      },
      post: async (url: string, data?: unknown): Promise<AxiosResponse> => {
        appStore.loadingSpinnerVisibility.set(true)
        try {
          const returnValue = await axios.post(url, data)
          appStore.loadingSpinnerVisibility.set(false)

          return returnValue
        } catch (err) {
          Sentry.captureException(err)

          appStore.loadingSpinnerVisibility.set(false)

          return null
        }
      },
    },
  }

  return appStore
}

const store = createStore()
export default store
