// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__tableContainer___1R3c5 {\n  margin-bottom: 50px; }\n  .index-module__tableContainer___1R3c5 .index-module__colNameRow___3pzV4 {\n    background-color: #b388ff; }\n    .index-module__tableContainer___1R3c5 .index-module__colNameRow___3pzV4 .index-module__col___1oQmB {\n      color: #ffffff;\n      font-weight: bold; }\n      .index-module__tableContainer___1R3c5 .index-module__colNameRow___3pzV4 .index-module__col___1oQmB .index-module__arrow___2jv0D {\n        width: 5px;\n        height: 10px; }\n  .index-module__tableContainer___1R3c5 .index-module__row___3oNmq .index-module__col___1oQmB {\n    font-weight: 500; }\n    .index-module__tableContainer___1R3c5 .index-module__row___3oNmq .index-module__col___1oQmB .index-module__edit___3LsmJ {\n      width: 30px;\n      text-decoration: underline; }\n  .index-module__tableContainer___1R3c5 .index-module__row___3oNmq:nth-child(2n) {\n    background-color: rgba(0, 0, 0, 0.03); }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/PackageList/PackageTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;EADrB;IAII,yBAAyB,EAAA;IAJ7B;MAMM,cAAc;MACd,iBAAiB,EAAA;MAPvB;QAUQ,UAAU;QACV,YAAY,EAAA;EAXpB;IAiBM,gBAAgB,EAAA;IAjBtB;MAoBQ,WAAW;MACX,0BAA0B,EAAA;EArBlC;IA0BI,qCAAqC,EAAA","file":"index.module.scss","sourcesContent":[".tableContainer {\n  margin-bottom: 50px;\n\n  .colNameRow {\n    background-color: #b388ff;\n    .col {\n      color: #ffffff;\n      font-weight: bold;\n\n      .arrow {\n        width: 5px;\n        height: 10px;\n      }\n    }\n  }\n  .row {\n    .col {\n      font-weight: 500;\n\n      .edit {\n        width: 30px;\n        text-decoration: underline;\n      }\n    }\n  }\n  .row:nth-child(2n) {\n    background-color: rgba(0, 0, 0, 0.03);\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"tableContainer": "index-module__tableContainer___1R3c5",
	"colNameRow": "index-module__colNameRow___3pzV4",
	"col": "index-module__col___1oQmB",
	"arrow": "index-module__arrow___2jv0D",
	"row": "index-module__row___3oNmq",
	"edit": "index-module__edit___3LsmJ"
};
module.exports = exports;
