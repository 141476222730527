import {
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

interface PropsWillNotDoAnalysisPopup {
  value: { selected: string; input: string }
  setValue: (data: { selected: string; input: string }) => void
  modalStatusHandler: (data: boolean) => void
  submitHandler: () => void
}

const reasonArray = [
  '가격이 비싸서 망설여져요',
  '원하는 브랜드가 없어요',
  '이미 시공을 예약했어요',
  '썬팅이 필요하지 않아요',
  '시간이 없어요',
]

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}))

const App: React.FunctionComponent<PropsWillNotDoAnalysisPopup> = ({
  value,
  modalStatusHandler,
  setValue,
  submitHandler,
}: PropsWillNotDoAnalysisPopup) => {
  const classes = useStyles()

  const radioHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ selected: (event.target as HTMLInputElement).value, input: '' })
  }
  const inputHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ selected: '', input: (event.target as HTMLInputElement).value })
  }

  return (
    <DialogContent>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel
          style={{ marginBottom: 20 }}
          component="legend"
        >{`Won't Do로 넘어가는 이유를 선택해주세요.`}</FormLabel>
        <RadioGroup aria-label="quiz" name="quiz" value={value.selected} onChange={radioHandleChange}>
          {reasonArray.map((item, index) => {
            return <FormControlLabel key={index} value={item} control={<Radio />} label={item} />
          })}
        </RadioGroup>
        <TextField
          label="기타"
          value={value.input}
          onChange={inputHandleChange}
          helperText="기타 이유를 입력해주세요"
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={(): void => modalStatusHandler(false)}
          >
            취소
          </Button>
          <Button type="submit" variant="outlined" color="primary" className={classes.button} onClick={submitHandler}>
            확인
          </Button>
        </div>
      </FormControl>
    </DialogContent>
  )
}

export default React.memo(App)
