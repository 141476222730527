import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { isNumber } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import { Config_Type_Enum as ConfigTypeEnum } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import {
  _GET_CONFIG_RATE as GET_CONFIG_RATE,
  _INSERT_NEW_CONFIG_RATE as INSERT_NEW_COMMISSION_RATE,
} from '../../stores/calculator/query'
import client from '../../utils/graphql'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const { accountId, changeSnackbarAlertContent, getDefaultRates } = useRootData(
    ({ appStore, authStore, calculatorStore }) => ({
      accountId: authStore.accountId.get(),
      changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
      getDefaultRates: calculatorStore.getDefaultRates,
    }),
  )

  const [commissionRate, setCommissionRate] = useState(null)
  const [updatedAt, setUpdatedAt] = useState(null)

  useEffect(() => {
    ;(async () => {
      const { csv_content: commissionRateObject, updated_at: updatedAtData } = (
        await client.query({
          query: GET_CONFIG_RATE(ConfigTypeEnum.DefaultCommissionRate),
        })
      ).data.config[0]

      setUpdatedAt(updatedAtData)

      setCommissionRate(commissionRateObject)
    })()
  }, [])

  const saveNewCommissionRate = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('새로운 마진율을 저장하겠습니까?')) return

    try {
      const {
        insert_config_one: { updated_at: updatedAtData },
      } = (
        await client.mutate({
          mutation: INSERT_NEW_COMMISSION_RATE,
          variables: {
            object: {
              account: accountId,
              type: ConfigTypeEnum.DefaultCommissionRate,
              csv_content: {
                maximum_discount_rate: commissionRate.maximum_discount_rate,
                default_commission_rate: commissionRate.default_commission_rate,
                maximum_commission_rate: commissionRate.maximum_commission_rate,
                minimum_commission_rate: commissionRate.minimum_commission_rate,
              },
            },
          },
        })
      ).data

      setUpdatedAt(updatedAtData)

      changeSnackbarAlertContent({ severity: 'success', content: '저장 성공' })
      getDefaultRates()
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '저장(수정) 실패' })
    }
  }

  if (!commissionRate) return null

  return (
    <div>
      <Button variant="contained" color="primary" onClick={async (): Promise<void> => saveNewCommissionRate()}>
        새로운 마진율 저장
      </Button>
      <p>편집할 cell을 선택하세요</p>
      <p>마지막 업데이트: {moment(updatedAt).toLocaleString()}</p>
      <TableContainer className={styles.container} component={Paper}>
        <Table className={styles.table} size="small" aria-label="a dense table">
          <TableHead className={styles.tableHeadContainer}>
            <TableRow className={styles.rowTitle}>
              {['최대 추가 할인율', '기본 정산 마진율', '최대 정산 마진율', '최소 정산 마진율'].map((col, index) => {
                return (
                  <TableCell className={styles.col} key={index} align="center">
                    {col}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBodyContainer}>
            <TableRow className={styles.row}>
              {[
                'maximum_discount_rate',
                'default_commission_rate',
                'maximum_commission_rate',
                'minimum_commission_rate',
              ].map((col, index) => {
                return (
                  <TableCell
                    key={index}
                    className={styles.col}
                    align="center"
                    onClick={() => {
                      // eslint-disable-next-line no-alert
                      const ret = prompt('마진율 수정', commissionRate[col])
                      if (ret && isNumber(parseFloat(ret))) {
                        setCommissionRate({
                          ...commissionRate,
                          [col]: parseFloat(ret),
                        })
                      }
                    }}
                  >
                    {`${commissionRate[col]}%`}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p>※ 최대 추가 할인율이란?</p>
      <p>각 견적가(프리미엄가, 패키지가, 최저가)의 최종 합계에서 추가적으로 할인 할 수 있는 비율</p>
      <p>{`※ 고객이 100만원 결제하면 매장은 최소*${
        (1 - commissionRate.maximum_commission_rate / 100) * 100
      }만원* 최대*${(1 - commissionRate.minimum_commission_rate / 100) * 100}만원*을 정산 받을 수 있음.`}</p>
      <p>최대 매장 이체액 = 고객 결제가 x (1 - 최소 마진율(%))</p>
      <p>최소 매장 이체액 = 고객 결제가 x (1 - 최대 마진율(%))</p>
    </div>
  )
}
export default React.memo(App)
