// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__quoteContainer___1Rb38 {\n  margin-bottom: 20px; }\n  .index-module__quoteContainer___1Rb38 .index-module__quoteCardContainer___2dDvs {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px; }\n    .index-module__quoteContainer___1Rb38 .index-module__quoteCardContainer___2dDvs .index-module__quoteCard___1vKBP {\n      margin-bottom: 20px; }\n      .index-module__quoteContainer___1Rb38 .index-module__quoteCardContainer___2dDvs .index-module__quoteCard___1vKBP .index-module__containerQuote___2Lv1K {\n        position: relative; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Quote/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;EADrB;IAII,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAA;IANvB;MASM,mBAAmB,EAAA;MATzB;QAYQ,kBAAkB,EAAA","file":"index.module.scss","sourcesContent":[".quoteContainer {\n  margin-bottom: 20px;\n\n  .quoteCardContainer {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px;\n\n    .quoteCard {\n      margin-bottom: 20px;\n\n      .containerQuote {\n        position: relative;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"quoteContainer": "index-module__quoteContainer___1Rb38",
	"quoteCardContainer": "index-module__quoteCardContainer___2dDvs",
	"quoteCard": "index-module__quoteCard___1vKBP",
	"containerQuote": "index-module__containerQuote___2Lv1K"
};
module.exports = exports;
