import './editor.scss'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'

import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { Gallery } from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import styles from './index.module.scss'

const GET_GALLERY = (filter: boolean): DocumentNode => {
  const query = filter ? `{ status: { _eq: false } }` : ''

  return gql`
    query GET_GALLERY($offset: Int!, $limit: Int!, $keyword: String!) {
      gallery: gallery(
        where: {
          _and: [
            { deleted_at: { _is_null: true } }
            ${query}
            {
              _or: [
                { blogPostByBlogPost: { title: { _like: $keyword } } }
                { storeByStore: { name: { _like: $keyword } } }
                { storeByStore: { llumar_store_name: { _like: $keyword } } }
                { carByCar: { model: { _like: $keyword } } }
                { carByCar: { car_maker: { name_kr: { _like: $keyword } } } }
                { carByCar: { car_maker: { name_us: { _like: $keyword } } } }
              ]
            }
          ]
        }
        order_by: { created_at: desc }
        offset: $offset
        limit: $limit
      ) {
        id
        created_at
        deleted_at
        status
        carByCar {
          car_maker {
            name_kr
            car_origin {
              name_kr
            }
          }
          model
        }
        store
        storeByStore {
          name
          phone_business
        }
        price
        blogPostByBlogPost {
          title
        }
        sent_at
      }
      gallerys_count: gallery_aggregate {
        aggregate {
          count
        }
      }
    }
  `
}

const DELETE_GALLERY = gql`
  mutation DELETE_GALLERY($id: uuid!, $now: timestamptz) {
    update_gallery_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $now }) {
      id
    }
  }
`

const UPDATE_GALLERY_SENT_AT = gql`
  mutation UPDATE_GALLERY_SENT_AT($id: uuid!, $now: timestamptz) {
    update_gallery_by_pk(pk_columns: { id: $id }, _set: { sent_at: $now }) {
      id
    }
  }
`
const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeGanBlog, sendAxios, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeGanBlog: appStore.changeGanBlog,
    sendAxios: appStore.sendAxios,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [currentIndex, setCurrentIndex] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [status, setStatus] = useState(false)

  const [getGallery, { data: galleryContent, error }] = useLazyQuery(GET_GALLERY(status), {
    fetchPolicy: 'no-cache',
  })

  if (error) {
    Sentry.captureException(error)
  }

  useEffect(() => {
    getGallery({
      variables: {
        offset: (currentIndex - 1) * 100,
        limit: 100,
        keyword: `%${keyword}%`,
      },
    })
  }, [])

  const deleteGallery = async (id: string): Promise<void> => {
    try {
      // eslint-disable-next-line no-alert
      if (window.confirm('정말 블로그를 삭제하시겠습니까?')) {
        const mutationObject = {
          mutation: DELETE_GALLERY,
          variables: {
            id,
            now: new Date().toUTCString(),
          },
        }
        await client.mutate(mutationObject)

        changeSnackbarAlertContent({
          severity: 'success',
          content: '블로그를 성공적으로 삭제했습니다. 페이지를 다시 로딩해주세요.',
        })
      } else {
        changeSnackbarAlertContent({ severity: 'info', content: '취소했습니다.' })
      }
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '블로그 삭제에 실패했습니다.' })
    }
  }

  const sendNewBlogHandler = async (item: Gallery) => {
    if (!item.store) {
      changeSnackbarAlertContent({ severity: 'warning', content: '선택된 매장이 없습니다.' })

      return
    }

    if (!item.status) {
      changeSnackbarAlertContent({ severity: 'warning', content: '숨김 해제를 먼저 진행해주세요!' })

      return
    }
    // eslint-disable-next-line no-alert
    const confirmRequest = window.confirm('해당 매장에게 알림톡을 발송하시겠습니까?')

    if (confirmRequest) {
      // eslint-disable-next-line no-alert
      const comment = window.prompt('추가 멘트가 필요하면 입력해주세요! (없으면 바로 확인)')

      const result = await sendAxios.get(`${config.backendEndPoint}/send/kakaotalk`, {
        params: {
          phoneNumber: item.storeByStore.phone_business,
          templateCode: 'new_gallery_001',
          comment: comment || '안녕하세요!',
          galleryId: item.id,
          storeId: item.store,
          storeName: item.storeByStore && item.storeByStore.name ? item.storeByStore.name : '',
        },
      })

      if (result.status === 200) {
        await client.mutate({
          mutation: UPDATE_GALLERY_SENT_AT,
          variables: {
            id: item.id,
            now: new Date().toUTCString(),
          },
        })

        getGallery({
          variables: {
            offset: (currentIndex - 1) * 100,
            limit: 100,
            keyword: `%${keyword}%`,
          },
        })

        changeSnackbarAlertContent({ severity: 'success', content: '알림이 정상적으로 발송 되었습니다.' })
      } else {
        changeSnackbarAlertContent({ severity: 'error', content: '알림톡 발송 실패!!' })
      }
    }
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>블로그 목록</div>
        <button
          className={styles.addButton}
          onClick={(): void => {
            changeMainMenu(`/gallery-detail`)
          }}
        >
          {'블로그 추가'}
        </button>
        <button
          className={styles.addGANButton}
          onClick={(): void => {
            changeGanBlog(true)
            changeMainMenu(`/gallery-detail`)
          }}
        >
          {'GAN 블로그 생성'}
        </button>
        <input
          className={styles.inputBox}
          value={keyword}
          placeholder={'제목|차량|매장'}
          onChange={(e): void => {
            setKeyword(e.target.value)
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === 'Enter') {
              setCurrentIndex(1)
              getGallery({
                variables: {
                  offset: 0,
                  limit: 100,
                  keyword: `%${keyword}%`,
                },
              })
            }
          }}
        ></input>
        <button
          className={styles.searchButton}
          onClick={(): void => {
            setCurrentIndex(1)
            getGallery({
              variables: {
                offset: 0,
                limit: 100,
                keyword: `%${keyword}%`,
              },
            })
          }}
        >
          {'검색'}
        </button>
        <button
          className={styles.initButton}
          onClick={(): void => {
            setKeyword('')
            setCurrentIndex(1)
            getGallery({
              variables: {
                offset: 0,
                limit: 100,
              },
            })
          }}
        >
          {'검색초기화'}
        </button>
        {status ? '전체 보기' : '숨김만 보기'}
        <input
          type="checkbox"
          defaultChecked={status}
          onChange={(e): void => {
            setCurrentIndex(1)
            getGallery({
              variables: {
                offset: 0,
                limit: 100,
                keyword: `%${keyword}%`,
              },
            })
            setStatus(e.target.checked)
          }}
        />
        <table>
          <tbody>
            <tr>
              <th>No.</th>
              <th>uuid</th>
              <th>업데이트된 날짜</th>
              <th>매장명</th>
              <th>시공 차량</th>
              <th>블로그 제목</th>
              <th>시공 가격</th>
              <th>숨김 여부</th>
              <th>마지막 알림톡 전송일(KST)</th>
              <th>알람톡 전송</th>
              <th>수정</th>
              <th>삭제</th>
            </tr>
            {galleryContent &&
              galleryContent.gallery.map((item: Gallery, index: number) => (
                <tr key={index}>
                  <td>{galleryContent.gallery.length - index}</td>
                  <td>{item.id}</td>
                  <td>{item.created_at.split('T')[0].replace(/-/g, '.')}</td>
                  <td>{item.storeByStore && item.storeByStore.name ? item.storeByStore.name : ''}</td>
                  <td>
                    {`${item.carByCar && item.carByCar.car_maker ? item.carByCar.car_maker.name_kr : ''} ・ ${
                      item.carByCar && item.carByCar.model ? item.carByCar.model : ''
                    }`}
                  </td>
                  <td>{item.blogPostByBlogPost.title}</td>
                  <td>{`${item.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 원`}</td>
                  <td>{item.status ? '' : '숨김'}</td>
                  <td>{item.sent_at ? moment(item.sent_at).local().format('YYYY-MM-DD A h:mm') : ''}</td>
                  <td>
                    <button
                      onClick={async (): Promise<void> => {
                        sendNewBlogHandler(item)
                      }}
                    >
                      전송
                    </button>
                  </td>

                  <td>
                    <button
                      onClick={(): void => {
                        changeMainMenu(`/gallery-detail?id=${item.id}`)
                      }}
                    >
                      수정
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={async (): Promise<void> => {
                        deleteGallery(item.id)
                      }}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className={styles.pagenation}>
          <div
            onClick={() => {
              const tempCurrentIndex = currentIndex
              if (tempCurrentIndex === 1) {
                changeSnackbarAlertContent({ severity: 'info', content: '첫 페이지입니다!' })
              } else {
                getGallery({
                  variables: {
                    offset: (tempCurrentIndex - 2) * 100,
                    limit: 100,
                    keyword: `%${keyword}%`,
                  },
                })
                setCurrentIndex(tempCurrentIndex - 1)
              }
            }}
          >
            {'<<'}
          </div>
          <div className={styles.currentIndex}>{currentIndex}</div>
          <div
            onClick={() => {
              const tempCurrentIndex = currentIndex
              getGallery({
                variables: {
                  offset: tempCurrentIndex * 100,
                  limit: 100,
                  keyword: `%${keyword}%`,
                },
              })
              setCurrentIndex(tempCurrentIndex + 1)
            }}
          >
            {'>>'}
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
