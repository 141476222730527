import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'

import { Gallery, Get_Page_IdQuery as GetPageIdQuery, Product, Store } from '../../assets/graphql/graphql'

const GET_PAGE_ID = gql`
  query GET_PAGE_ID {
    gallery {
      id
    }
    store {
      id
    }
    product {
      id
    }
  }
`

const App: React.FunctionComponent = () => {
  const [getData, { data, error }] = useLazyQuery<GetPageIdQuery>(GET_PAGE_ID, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getData()
  }, [])

  if (error) {
    Sentry.captureException(error)
  }
  if (!data) return null

  const downloadSiteMap = () => {
    let sitemap = `<?xml version="1.0" encoding="UTF-8"?><urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`
    sitemap += `<url><loc>https://caramora.com/</loc></url>`
    sitemap += `<url><loc>https://caramora.com/product</loc></url>`
    sitemap += `<url><loc>https://caramora.com/product?=category=promotion</loc></url>`

    data.product.forEach((prod: Product) => {
      sitemap += `<url><loc>https://caramora.com/product-detail?id=${prod.id}</loc></url>`
    })

    sitemap += `<url><loc>https://caramora.com/gallery</loc></url>`

    data.gallery.forEach((gal: Gallery) => {
      sitemap += `<url><loc>https://caramora.com/gallery-detail?id=${gal.id}</loc></url>`
    })

    sitemap += `<url><loc>https://caramora.com/store</loc></url>`

    data.store.forEach((sto: Store) => {
      sitemap += `<url><loc>https://caramora.com/store-detail?id=${sto.id}</loc></url>`
    })

    sitemap += `<url><loc>https://caramora.com/about-caramora</loc></url>`
    sitemap += `<url><loc>https://caramora.com/product-guide</loc></url>`
    sitemap += `<url><loc>https://caramora.com/terms</loc></url>`
    sitemap += `<url><loc>https://caramora.com/privacy</loc></url>`

    sitemap += '</urlset>'

    const element = document.createElement('a')
    const file = new Blob([sitemap], { type: 'text/plain' })

    element.href = URL.createObjectURL(file)
    element.download = 'sitemap.xml'

    document.body.appendChild(element)
    element.click()
  }

  return (
    <div className="body">
      <div>
        <div>Press button below to download sitemap.xml</div>
        <div>
          Currently the sitemap generates pages for landing, product, promotion, product-detail, gallery,
          gallery-detail, store, and store-detail.
        </div>
        <div>
          Keep in mind that this is just a quick hack to be used on the fly. Once Next.js is implemented we can
          dynamically generate sitemap.xml on the fly.
        </div>
        <button onClick={downloadSiteMap}>Download xml file</button>
      </div>
    </div>
  )
}
export default React.memo(App)
