import React from 'react'

import { Get_All_SidoQuery as GetAllSidoQuery } from '../../../assets/graphql/graphql'
import styles from './index.module.scss'

interface LocObjectType {
  [name: string]: boolean
}

interface FilterProps {
  sidoData: GetAllSidoQuery
  tempSido: string
  sigunguObj: LocObjectType
  setTempSido: (variable: string) => void
  setSigunguObj: (variable: LocObjectType) => void
  setSido: (variable: string) => void
  setSigungu: (variable: string) => void
}

const App: React.FunctionComponent<FilterProps> = ({
  sidoData,
  tempSido,
  sigunguObj,
  setSido,
  setTempSido,
  setSigungu,
  setSigunguObj,
}: FilterProps) => {
  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>, mode: string) => {
    event.preventDefault()

    if (mode === 'sido') {
      setSido(tempSido || '%')
      setSigungu('%')
    } else {
      let outputSigungu = ''
      Object.entries(sigunguObj).forEach(([key, value]) => {
        if (value) {
          if (outputSigungu) outputSigungu += '|'
          outputSigungu += `${key}`
        }
      })

      if (outputSigungu === '') outputSigungu = '%'
      setSido(tempSido)
      setSigungu(outputSigungu)
    }
  }

  return (
    <div>
      <div className={styles.title}>{'도시별 필터'}</div>
      <div className={styles.inputContainer}>
        <form onSubmit={(e) => handleSubmitForm(e, 'sido')}>
          <div className={styles.sidoContainer}>
            <div className={styles.sidoInput}>
              <input
                type="radio"
                name={'si[]'}
                defaultChecked={true}
                onChange={(e) => {
                  setTempSido(e.target.value)
                }}
                value={''}
              />
              <label htmlFor={''}>{'전체'}</label>
            </div>
            {sidoData.address_sido.map((si, index) => {
              return (
                <div className={styles.sidoInput} key={index}>
                  <input
                    type="radio"
                    name={'si[]'}
                    onChange={(e) => {
                      setTempSido(e.target.value)
                    }}
                    value={si.name}
                  />
                  <label htmlFor={si.name}>{si.name}</label>
                </div>
              )
            })}
          </div>
          <input type="submit" value="도시별로 렌더링" />
        </form>
      </div>
      {tempSido && (
        <div>
          <div className={styles.title}>{`시군구 필터`}</div>
          <div className={styles.inputContainer}>
            <form onSubmit={(e) => handleSubmitForm(e, 'sigungu')}>
              <div className={styles.title}>{`${tempSido} 필터`}</div>
              <div className={styles.sidoContainer}>
                {sidoData.address_sido
                  .find((s) => s.name === tempSido)
                  .address_sigungus.map((sgg, index) => {
                    return (
                      <div className={styles.sidoInput} key={index}>
                        <input
                          type="checkbox"
                          name={sgg.name}
                          onChange={(e) => {
                            const { target } = e
                            const { value, checked } = target

                            setSigunguObj({
                              ...sigunguObj,
                              [value]: checked,
                            })
                          }}
                          value={sgg.name}
                        />
                        <label htmlFor={sgg.name}>{sgg.name ? sgg.name : '전체'}</label>
                      </div>
                    )
                  })}
              </div>

              <input type="submit" value="군구별로 렌더링" />
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
export default React.memo(App)
