import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Modal } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import React, { useState } from 'react'

import { Lead_Status_Enum as LeadStatusEnum } from '../../../assets/graphql/graphql'
import SaveIcon from '../../../assets/images/save.svg'
import SearchIcon from '../../../assets/images/searchIcon.png'
import Image from '../../../components/atoms/Image'
import Text from '../../../components/atoms/Text'
import KakaoPixelType from '../../../components/types/enums/kakaoPixelType.enum'
import { EnumNabusType } from '../../../components/types/enums/nabusType.enum'
import config from '../../../configs'
import useRootData from '../../../hooks/useRootData'
import { UPDATE_LEAD_STATUS } from '../../../stores/lead/query'
import { LEAD_COL } from '../../../stores/lead/type'
import client from '../../../utils/graphql'
import { getLeadColValueByKey } from '../../../utils/utility'
import styles from './index.module.scss'
import LeadSearch from './LeadSearch'
import ReservationPopupStatus from './ReservationPopupStatus'
import WillNotDoAnalysisPopup from './WillNotDoAnalysisPopup'

export enum AVAILABLE_MENU {
  SEND_QUOTE_AT_CUSTOMER = '고객에게 견적 발송',
  SEND_QUOTE_AT_STORE = '매장에게 견적 발송',
  LET_US_MATCH = "Let's Match!",
  WILL_NOT_DO = "Won't Doㅠ",
  ORDER_PENDING = 'Order Pending!',
  BACK_TO_MATCH = 'Match로 빠꾸',
  CAN_NOT_MATCH = "Can't Match...",
  RESERVATION = 'Reservation!',
  RESERVATION_CONFIRMED = '예약 컨펌!',
  BACK_TO_ORDER = 'Order로 빠꾸',
  CANCEL = 'Cancel ㅠㅠ',
  INSTALLED = '시공 완료!',
  SEND_REVISED_PRICE = '결제 링크 전송',
  SEND_REVISED_PRICE_WITH_MATERIAL_PRICE = '재료비 or 현장 결제 링크 전송',
  DONE = 'DONE',
  /* TODO : 매장 이체 모두 끝나면 삭제 */
  SETTLEMENT_PENDING = 'Settlement Pending!',
  PAYOUT_COMPLETED = '이체 완료!',
  SEND_ETAX_INVOICE = '세금계산서 전송',
  /* */
  REFUND = '전액 확불',
  BACK_TO_LEAD = 'Lead로 빠꾸',
}

const currentlyAvailableMenus: {
  [key: string]: Array<AVAILABLE_MENU>
} = {
  [LeadStatusEnum.Duplicate]: [],
  [LeadStatusEnum.Missed]: [],
  [LeadStatusEnum.Chat]: [],
  [LeadStatusEnum.Lead]: [AVAILABLE_MENU.WILL_NOT_DO],
  [LeadStatusEnum.Quote]: [AVAILABLE_MENU.LET_US_MATCH, AVAILABLE_MENU.WILL_NOT_DO],
  [LeadStatusEnum.Match]: [AVAILABLE_MENU.ORDER_PENDING, AVAILABLE_MENU.CAN_NOT_MATCH],
  [LeadStatusEnum.OrderPending]: [AVAILABLE_MENU.RESERVATION, AVAILABLE_MENU.BACK_TO_MATCH, AVAILABLE_MENU.WILL_NOT_DO],
  [LeadStatusEnum.RsvPending]: [
    AVAILABLE_MENU.RESERVATION_CONFIRMED,
    AVAILABLE_MENU.BACK_TO_ORDER,
    AVAILABLE_MENU.CANCEL,
  ],
  [LeadStatusEnum.RsvConfirmed]: [
    AVAILABLE_MENU.INSTALLED,
    AVAILABLE_MENU.DONE,
    AVAILABLE_MENU.BACK_TO_ORDER,
    AVAILABLE_MENU.CANCEL,
  ],
  [LeadStatusEnum.PaymentPending]: [
    AVAILABLE_MENU.SEND_REVISED_PRICE,
    AVAILABLE_MENU.SEND_REVISED_PRICE_WITH_MATERIAL_PRICE,
    AVAILABLE_MENU.DONE,
    AVAILABLE_MENU.CANCEL,
  ],
  /* TODO : 매장 이체 모두 끝나면 삭제 */
  [LeadStatusEnum.VatRequested]: [AVAILABLE_MENU.SETTLEMENT_PENDING, AVAILABLE_MENU.SEND_ETAX_INVOICE],
  [LeadStatusEnum.SettlementPending]: [AVAILABLE_MENU.PAYOUT_COMPLETED, AVAILABLE_MENU.REFUND],
  /* */
  [LeadStatusEnum.Done]: [
    /* AVAILABLE_MENU.REFUND */
  ],
  [LeadStatusEnum.Refunded]: [],
  [LeadStatusEnum.Cancel]: [AVAILABLE_MENU.BACK_TO_ORDER],
  [LeadStatusEnum.WillNotDo]: [AVAILABLE_MENU.BACK_TO_LEAD],
}

const App: React.FunctionComponent = () => {
  const {
    accountId,
    selectedLead,
    selectedStartDropOffDateTime,
    selectedEndDropOffDateTime,
    quoteObject,

    updateLeadByPK,
    changeSnackbarAlertContent,
    changeMainMenu,
    changeLoadingSpinnerVisibility,
    sendAnalytics,
  } = useRootData(({ appStore, authStore, leadStore, quoteStore }) => ({
    accountId: authStore.accountId.get(),
    selectedLead: leadStore.selectedLead.get(),
    selectedStartDropOffDateTime: leadStore.selectedStartDropOffDateTime.get(),
    selectedEndDropOffDateTime: leadStore.selectedEndDropOffDateTime.get(),
    quoteObject: quoteStore.quoteObject.get(),

    updateLeadByPK: leadStore.updateLeadByPK,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    changeMainMenu: appStore.changeMainMenu,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    sendAnalytics: appStore.sendAnalytics,
  }))

  const [leadSearchPopupStatus, setLeadSearchPopupStatus] = useState<boolean>(false)
  const [reservationPopupStatus, setReservationPopupStatus] = useState<boolean>(false)
  const [availableBtnPopupStatus, setAvailableBtnPopupStatus] = useState<boolean>(false)
  const [selectedButton, setSelectedButton] = useState<number>(null)
  const [wontDoPopupStatus, setWontDoPopupStatus] = useState<boolean>(false)
  const [willNotDoReason, setWillNotDoReason] = useState<{ selected: string; input: string }>({
    selected: '',
    input: '',
  })

  const saveClickHandler = async (): Promise<void> => {
    if (
      selectedStartDropOffDateTime === '00' ||
      selectedEndDropOffDateTime === '00' ||
      selectedStartDropOffDateTime.substr(0, 4) === '2000' ||
      selectedEndDropOffDateTime.substr(0, 4) === '2000'
    ) {
      changeSnackbarAlertContent({
        severity: 'error',
        content: '잘못된 Drop off date이 입력되어 있습니다. 확인해주세요! ',
      })

      return
    }

    try {
      if (await updateLeadByPK()) changeSnackbarAlertContent({ severity: 'success', content: '저장 성공.' })
      else {
        setTimeout(() => {
          changeSnackbarAlertContent({ severity: 'warning', content: '저장이 중단되었습니다.' })
          changeLoadingSpinnerVisibility(false)
        }, 2000)
      }
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const installedClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/installed`, {
        leadId: selectedLead.id,
        accountId,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `시공 완료 처리 완료.` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const reservationClickHandler = async () => {
    setReservationPopupStatus(true)
  }

  const reservationConfirmedClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/reservation-confirmed`, {
        leadId: selectedLead.id,
        accountId,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `시공 예약 확정 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const backToMatchClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/back-to-match`, {
        leadId: selectedLead.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const backToOrderClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/back-to-order`, {
        leadId: selectedLead.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const cancelClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/cancel`, {
        leadId: selectedLead.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `주문 취소 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const sendRevisedPriceClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/send-revised-price`, {
        leadId: selectedLead.id,
        accountId,
        /* TODO : 매장 이체 모두 끝나면 삭제 */
        isOnlyMaterialPrice: false,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `결제 링크 전송 완료` })
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  /* TODO : 매장 이체 모두 끝나면 삭제 */
  const sendMaterialPriceClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/send-revised-price`, {
        leadId: selectedLead.id,
        accountId,
        isOnlyMaterialPrice: true,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `재료비 결제 링크 전송 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  /* TODO : 매장 이체 모두 끝나면 삭제 */
  const payoutCompletedClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/payout-completed`, {
        leadId: selectedLead.id,
      })

      sendAnalytics({
        kakaoPixel: KakaoPixelType.DONE,
        nabus: EnumNabusType.DONE,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const refundClickHandler = async () => {
    try {
      await updateLeadByPK()

      const order = selectedLead.orders[0]
      let paymentGateway = ''
      if (order.order_and_transactions[0].transactionByTransaction.stripe_payment_intent_id) {
        paymentGateway = 'stripe'
      } else if (order.order_and_transactions[0].transactionByTransaction.rapyd_payment_intent_id) {
        paymentGateway = 'rapyd'
      } else if (order.order_and_transactions[0].transactionByTransaction.bootpay_payment_intent_id) {
        paymentGateway = 'bootpay'
      }

      // eslint-disable-next-line no-alert
      const reason = prompt('환불 이유를 입력해주세요.', '')

      const result = await axios.post(`${config.backendEndPoint}/lead/refund`, {
        leadId: selectedLead.id,
        orderId: order.id,
        paymentGateway,
        reason,
      })

      if (!result) throw Error('No result')

      changeSnackbarAlertContent({ severity: 'success', content: '성공적으로 환불처리 되었습니다.' })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      if (err.response.data.code === 'charge_already_refunded') {
        changeSnackbarAlertContent({ severity: 'info', content: '이미 환불처리된 결제건입니다.' })
      } else {
        changeSnackbarAlertContent({ severity: 'error', content: err })
      }
    }
  }

  const orderPendingClickHandler = async () => {
    try {
      const quoteItems = quoteObject.quote_items.filter((item) => !item.deleted_at && item.is_active)

      if (!selectedLead.accountByShopAssignee) {
        changeSnackbarAlertContent({ severity: 'error', content: 'Match Maker를 선택해주세요!' })

        return
      }

      let didNotSetPrice = false
      let didNotSend = false

      quoteItems.some((quoteItem) => {
        if (!quoteItem.price_product.price) {
          didNotSetPrice = true
        }

        return didNotSetPrice
      })

      if (didNotSetPrice) {
        changeSnackbarAlertContent({ severity: 'error', content: '가격이 설정되지 않았습니다!' })

        return
      }

      quoteItems.some((quoteItem) => {
        if (!Object.keys(quoteItem.sent_at)[0]) {
          didNotSend = true
        }

        return didNotSend
      })

      if (didNotSend) {
        changeSnackbarAlertContent({ severity: 'error', content: '견적서를 보내야 합니다.' })

        return
      }

      await updateLeadByPK()

      await client.mutate({
        mutation: UPDATE_LEAD_STATUS(LeadStatusEnum.OrderPending),
        variables: {
          id: selectedLead.id,
          now: new Date().toUTCString(),
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: 'Order Pending 처리 완료' })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const letUsMatchClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/let-us-match`, {
        leadId: selectedLead.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `Let's Match 처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const canNotMatchClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/can-not-match`, {
        leadId: selectedLead.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `Can't Match 처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const willNotDoClickHandler = async () => {
    try {
      const previousLeadStatus = selectedLead.status

      const [reason] = Object.values(willNotDoReason).filter((value) => value)

      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/will-not-do`, {
        leadId: selectedLead.id,
        reason,
      })

      changeMainMenu(`/lead-list?status=${previousLeadStatus}`)

      changeSnackbarAlertContent({ severity: 'success', content: `Won't do 처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const openWillNotDoAnalysisPopupHandler = (): void => {
    setWontDoPopupStatus(true)
  }

  const backToLeadClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/back-to-lead`, {
        leadId: selectedLead.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  /* TODO : 매장 이체 모두 끝나면 삭제 */
  const settlementPendingClickHandler = async () => {
    try {
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/settlement-pending`, {
        leadId: selectedLead.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: 'Settlement Pending 처리 완료' })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  /* TODO : 매장 이체 모두 끝나면 삭제 */
  const sendETaxInvoiceClickHandler = async () => {
    try {
      const order = selectedLead.orders[0]

      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/send/send-etax-invoice-to-store`, {
        leadId: selectedLead.id,
        accountId,
        orderId: order.id,
      })

      changeSnackbarAlertContent({ severity: 'success', content: '전송 완료' })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const doneClickHandler = async () => {
    try {
      if (
        selectedLead.status === LeadStatusEnum.RsvConfirmed &&
        getLeadColValueByKey(selectedLead, LEAD_COL.MATERIAL_PRICE) !== '0원'
      ) {
        changeSnackbarAlertContent({ severity: 'error', content: '재료비가 있습니다.' })

        return
      }
      await updateLeadByPK()

      await axios.post(`${config.backendEndPoint}/lead/done`, {
        leadId: selectedLead.id,
      })

      sendAnalytics({
        kakaoPixel: KakaoPixelType.DONE,
        nabus: EnumNabusType.DONE,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `처리 완료` })
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }
  }

  const mappingFunc: {
    [key: string]: () => Promise<void> | void
  } = {
    [AVAILABLE_MENU.SEND_QUOTE_AT_CUSTOMER]: null,
    [AVAILABLE_MENU.SEND_QUOTE_AT_STORE]: null,
    [AVAILABLE_MENU.LET_US_MATCH]: letUsMatchClickHandler,
    [AVAILABLE_MENU.WILL_NOT_DO]: openWillNotDoAnalysisPopupHandler,
    [AVAILABLE_MENU.BACK_TO_MATCH]: backToMatchClickHandler,
    [AVAILABLE_MENU.ORDER_PENDING]: orderPendingClickHandler,
    [AVAILABLE_MENU.CAN_NOT_MATCH]: canNotMatchClickHandler,
    [AVAILABLE_MENU.RESERVATION]: reservationClickHandler,
    [AVAILABLE_MENU.RESERVATION_CONFIRMED]: reservationConfirmedClickHandler,
    [AVAILABLE_MENU.BACK_TO_ORDER]: backToOrderClickHandler,
    [AVAILABLE_MENU.CANCEL]: cancelClickHandler,
    [AVAILABLE_MENU.INSTALLED]: installedClickHandler,
    [AVAILABLE_MENU.SETTLEMENT_PENDING]: settlementPendingClickHandler,
    [AVAILABLE_MENU.SEND_REVISED_PRICE]: sendRevisedPriceClickHandler,
    [AVAILABLE_MENU.SEND_REVISED_PRICE_WITH_MATERIAL_PRICE]: sendMaterialPriceClickHandler,
    [AVAILABLE_MENU.PAYOUT_COMPLETED]: payoutCompletedClickHandler,
    [AVAILABLE_MENU.REFUND]: refundClickHandler,
    [AVAILABLE_MENU.BACK_TO_LEAD]: backToLeadClickHandler,
    [AVAILABLE_MENU.SEND_ETAX_INVOICE]: sendETaxInvoiceClickHandler,
    [AVAILABLE_MENU.DONE]: doneClickHandler,
  }

  return (
    <div className={styles.container}>
      <Modal
        open={leadSearchPopupStatus}
        onClose={(): void => setLeadSearchPopupStatus(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <LeadSearch />
      </Modal>
      <Modal
        open={reservationPopupStatus}
        onClose={(): void => setReservationPopupStatus(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ReservationPopupStatus modalStatusHandler={() => setReservationPopupStatus(!reservationPopupStatus)} />
      </Modal>
      {/* Update status button Dialog Modal */}
      <Dialog
        open={availableBtnPopupStatus}
        onClose={(): void => setAvailableBtnPopupStatus(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            정말로 {currentlyAvailableMenus[selectedLead.status][selectedButton]}를 진행하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(): void => setAvailableBtnPopupStatus(false)} color="secondary" variant="contained">
            취소
          </Button>
          <Button
            onClick={(): void => {
              mappingFunc[currentlyAvailableMenus[selectedLead.status][selectedButton]]()
              setAvailableBtnPopupStatus(false)
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {/* To analyze why customers don't. */}
      <Dialog
        open={wontDoPopupStatus}
        onClose={(): void => setWontDoPopupStatus(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <WillNotDoAnalysisPopup
          value={willNotDoReason}
          setValue={setWillNotDoReason}
          modalStatusHandler={setWontDoPopupStatus}
          submitHandler={willNotDoClickHandler}
        />
      </Dialog>
      <div className={styles.menuContainer}>
        {currentlyAvailableMenus[selectedLead.status].length > 0 && (
          <div>
            {currentlyAvailableMenus[selectedLead.status].map((item, index) => {
              return (
                <Button
                  key={index}
                  variant="contained"
                  className={styles.btn}
                  onClick={(): void => {
                    setSelectedButton(index)
                    setAvailableBtnPopupStatus(!availableBtnPopupStatus)
                  }}
                >
                  {item}
                </Button>
              )
            })}
          </div>
        )}
      </div>

      <div className={styles.controlBtnArea}>
        <div className={styles.searchBtnArea} onClick={(): void => setLeadSearchPopupStatus(true)}>
          <IconButton edge="start" color="inherit" aria-label="save-icon">
            <Image className={styles.image} src={SearchIcon} />
          </IconButton>
          <Text text={'재방문 고객 검색'} />
        </div>
        <div className={styles.saveBtnArea} onClick={saveClickHandler}>
          <IconButton edge="start" color="inherit" aria-label="save-icon">
            <Image src={SaveIcon} />
          </IconButton>
          <Text text={'저장'} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(App)
