import appStore from './app'
import authStore from './auth'
import calculatorStore from './calculator'
import graphStore from './graph'
import leadStore from './lead'
import orderStore from './order'
import quoteStore from './quote'

export interface TypeRootStore {
  appStore: typeof appStore
  authStore: typeof authStore
  graphStore: typeof graphStore
  quoteStore: typeof quoteStore
  calculatorStore: typeof calculatorStore
  leadStore: typeof leadStore
  orderStore: typeof orderStore
}

const createRootStore = (): TypeRootStore => {
  return {
    appStore,
    authStore,
    graphStore,
    quoteStore,
    calculatorStore,
    leadStore,
    orderStore,
  }
}

export default createRootStore
