// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__tableContainer___xN7of tr td:nth-child(2) {\n  width: 300px; }\n  .index-module__tableContainer___xN7of tr td:nth-child(2) div {\n    width: 100%; }\n\n.index-module__submitButton___1FsqH {\n  margin-top: 30px; }\n  .index-module__submitButton___1FsqH button {\n    width: 250px;\n    height: 50px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/Request777Order/index.module.scss"],"names":[],"mappings":"AAAA;EAGM,YAAY,EAAA;EAHlB;IAKQ,WAAW,EAAA;;AAMnB;EACE,gBAAgB,EAAA;EADlB;IAGI,YAAY;IACZ,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".tableContainer {\n  tr {\n    td:nth-child(2) {\n      width: 300px;\n      div {\n        width: 100%;\n      }\n    }\n  }\n}\n\n.submitButton {\n  margin-top: 30px;\n  button {\n    width: 250px;\n    height: 50px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"tableContainer": "index-module__tableContainer___xN7of",
	"submitButton": "index-module__submitButton___1FsqH"
};
module.exports = exports;
