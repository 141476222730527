// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__tableHeadContainer___3POuF .index-module__row___m4NEK {\n  background-color: #b388ff; }\n  .index-module__tableHeadContainer___3POuF .index-module__row___m4NEK .index-module__col___1dlq1 {\n    color: #ffffff;\n    font-weight: bold; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Quote/RemarkTable/index.module.scss"],"names":[],"mappings":"AAAA;EAMI,yBAAyB,EAAA;EAN7B;IAGM,cAAc;IACd,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".tableHeadContainer {\n  .row {\n    .col {\n      color: #ffffff;\n      font-weight: bold;\n    }\n    background-color: #b388ff;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"tableHeadContainer": "index-module__tableHeadContainer___3POuF",
	"row": "index-module__row___m4NEK",
	"col": "index-module__col___1dlq1"
};
module.exports = exports;
