import { Button, TextField } from '@material-ui/core'
import React, { useState } from 'react'

import Text from '../../components/atoms/Text'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import {
  _CHECK_CART_EXISTENCE as CHECK_CART_EXISTENCE,
  _CREATE_CART as CREATE_CART,
  CartSearchKeywordType,
} from './query'

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [cartSearchKeyword, setCartSearchKeyword] = useState<CartSearchKeywordType>({
    zendeskChatName: '',
    cartId: '',
    userEmail: '',
  })

  const cartSearchButtonClickHandler = async () => {
    const { data: cartContent } = await client.query({
      query: CHECK_CART_EXISTENCE(cartSearchKeyword),
      fetchPolicy: 'no-cache',
    })

    if (!cartContent.n_cart.length) {
      changeSnackbarAlertContent({
        severity: 'error',
        content: '검색되는 카트가 없습니다. 다시 확인해주세요',
      })

      return
    }

    changeMainMenu(`/cart-detail?cartId=${cartContent.n_cart[0].id}`)
  }

  const createCartButtonClickHandler = async () => {
    const { data: createdCartContent } = await client.mutate({
      mutation: CREATE_CART,
      variables: {
        cartObject: {},
      },
    })

    changeSnackbarAlertContent({ severity: 'success', content: '생성 성공' })

    changeMainMenu(`/cart-detail?cartId=${createdCartContent.insert_n_cart_one.id}`)
  }

  return (
    <div>
      <Text style={{ fontSize: 16 }} text={'팔로우업 카트 생성 하기'}></Text>

      <Button
        style={{ marginTop: 10, marginBottom: 20 }}
        onClick={(): Promise<void> => createCartButtonClickHandler()}
        variant="contained"
      >
        생성
      </Button>

      <Text style={{ fontSize: 16 }} text={'카트 검색 하기'}></Text>

      <div style={{ marginTop: 10, display: 'flex' }}>
        <TextField
          onKeyPress={(e) => {
            if (e.key === 'Enter') cartSearchButtonClickHandler()
          }}
          style={{ width: 700 }}
          value={cartSearchKeyword.zendeskChatName}
          label={'Zendesk Chat Name으로 검색'}
          variant={'outlined'}
          onChange={(e) =>
            setCartSearchKeyword({
              zendeskChatName: e.target.value,
              cartId: '',
              userEmail: '',
            })
          }
        ></TextField>
        <Button style={{ height: 54, width: 66 }} variant={'contained'} onClick={cartSearchButtonClickHandler}>
          검색
        </Button>
      </div>

      <div style={{ marginTop: 10, display: 'flex' }}>
        <TextField
          onKeyPress={(e) => {
            if (e.key === 'Enter') cartSearchButtonClickHandler()
          }}
          style={{ width: 700 }}
          value={cartSearchKeyword.cartId}
          label={'Cart Id으로 검색'}
          variant={'outlined'}
          onChange={(e) =>
            setCartSearchKeyword({
              zendeskChatName: '',
              cartId: e.target.value,
              userEmail: '',
            })
          }
        ></TextField>
        <Button style={{ height: 54, width: 66 }} variant={'contained'} onClick={cartSearchButtonClickHandler}>
          검색
        </Button>
      </div>

      <div style={{ marginTop: 10, display: 'flex' }}>
        <TextField
          onKeyPress={(e) => {
            if (e.key === 'Enter') cartSearchButtonClickHandler()
          }}
          style={{ width: 700 }}
          value={cartSearchKeyword.userEmail}
          label={'User Email으로 검색'}
          variant={'outlined'}
          onChange={(e) =>
            setCartSearchKeyword({
              zendeskChatName: '',
              cartId: '',
              userEmail: e.target.value,
            })
          }
        ></TextField>
        <Button style={{ height: 54, width: 66 }} variant={'contained'} onClick={cartSearchButtonClickHandler}>
          검색
        </Button>
      </div>
    </div>
  )
}

export default React.memo(App)
