import { Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useState } from 'react'

import { Lead_Status_Enum as LeadStatusEnum } from '../../../../assets/graphql/graphql'
import useRootData from '../../../../hooks/useRootData'
import {
  CalculatedPrices,
  CalculatedProductPrices,
  PriceKey,
  PriceObject,
  ProductCategory,
  ProductDisplayNames,
} from '../../../../stores/calculator/type'
import CalculatorRow, { RowType } from './CalculatorRow'
import FinalPriceRows from './FinalPriceRows'
import styles from './index.module.scss'
import PaymentPriceRows from './PaymentPriceRows'
import PayoutPriceRows from './PayoutPriceRows'
import SelectProductItemModal from './SelectProductItemModal'

const App: React.FunctionComponent<{ idx: number; isOrder: boolean }> = ({
  idx,
  isOrder,
}: {
  idx: number
  isOrder: boolean
}) => {
  const {
    calculatedPrices: {
      productPrices = {} as CalculatedProductPrices,

      totalPrice = {} as PriceObject,
      finalPrice = {} as PriceObject,
      basicPayoutPrice = {} as PriceObject,
      finalPayoutPrice = {} as PriceObject,
      materialPrice = {} as PriceObject,
    },
    floorPrice,
    ceilPrice,
    extraDiscountPrice,
    extraPrice,

    // values
    selectedProductIds,

    customProducts,

    selectedPackageObject,

    quoteObject,

    orderObject,

    selectedLead,

    // options
    productItemArrays,

    // setter
    changeSelectedProductId,
    appendCustomProduct,
    deleteCustomProduct,
    changeCustomProductName,
    changeCustomProductPrice,

    changeExtraDiscountPrice,
    changeExtraPrice,

    // Temp
    extraPayoutPrice,
    changeExtraPayoutPrice,
    extraDiscountPayoutPrice,
    changeExtraDiscountPayoutPrice,

    prevFinalPrice,
    prevFinalPayoutPrice,
  } = useRootData(({ calculatorStore, quoteStore, orderStore, leadStore }) => ({
    calculatedPrices: calculatorStore.calculatedPrices[idx] || ({} as Partial<CalculatedPrices>),
    floorPrice: calculatorStore.floorPrice.get()[idx],
    ceilPrice: calculatorStore.ceilPrice.get()[idx],
    extraDiscountPrice: calculatorStore.extraDiscountPrice.get(),
    extraPrice: calculatorStore.extraPrice.get(),

    selectedProductIds: calculatorStore.selectedProductIds.get()[idx],

    customProducts: calculatorStore.customProducts.get()[idx],

    selectedPackageObject: calculatorStore.selectedPackageObject.get()[idx],
    quoteObject: quoteStore.quoteObject.get(),
    orderObject: orderStore.orderObject.get(),
    selectedLead: leadStore.selectedLead.get(),

    productItemArrays: calculatorStore.productItemArrays,
    changeSelectedProductId: calculatorStore.changeSelectedProductId,
    appendCustomProduct: calculatorStore.appendCustomProduct,
    deleteCustomProduct: calculatorStore.deleteCustomProduct,
    changeCustomProductName: calculatorStore.changeCustomProductName,
    changeCustomProductPrice: calculatorStore.changeCustomProductPrice,

    changeExtraDiscountPrice: calculatorStore.changeExtraDiscountPrice,
    changeExtraPrice: calculatorStore.changeExtraPrice,

    // Temp
    extraPayoutPrice: calculatorStore.extraPayoutPrice.get(),
    changeExtraPayoutPrice: calculatorStore.changeExtraPayoutPrice,
    extraDiscountPayoutPrice: calculatorStore.extraDiscountPayoutPrice.get(),
    changeExtraDiscountPayoutPrice: calculatorStore.changeExtraDiscountPayoutPrice,

    prevFinalPrice: calculatorStore.prevFinalPrice.get()[idx],
    prevFinalPayoutPrice: calculatorStore.prevFinalPayoutPrice.get()[idx],
  }))

  const [modalProductCategory, setModalProductCategory] = useState<ProductCategory>(null)
  const hideModalHandler = () => {
    setModalProductCategory(null)
  }
  const forkProductToCustomProductHandler = (productCategory: ProductCategory) => {
    setModalProductCategory(productCategory)
  }

  const productRows = Object.values(ProductCategory).map((key: ProductCategory) => ({
    rowTitle: ProductDisplayNames[key],
    value: selectedProductIds[key],
    productOptions: productItemArrays[key][idx],
    changeHandler: (id: string, idx_: number) => changeSelectedProductId(key, id, idx_),
    forkProductToCustomProductHandler: () => forkProductToCustomProductHandler(key),
    prices: productPrices[key] ? productPrices[key] : ({} as PriceObject),
    isPackage: selectedPackageObject && true,
  }))

  const quoteItem = !isOrder && quoteObject.quote_items.length > 0 && quoteObject.quote_items[idx - 1]

  let sentAt =
    !isOrder && quoteItem && quoteItem.sent_at
      ? quoteItem.sent_at
      : { price: undefined, premium: undefined, package: undefined, lowest: undefined }

  if (isOrder) {
    sentAt =
      orderObject && orderObject.quoteItemByQuoteItem && orderObject.quoteItemByQuoteItem.sent_at
        ? orderObject.quoteItemByQuoteItem.sent_at
        : { price: true, premium: undefined, package: undefined, lowest: undefined }
  }

  const isAvalableSentQuote =
    Object.keys(sentAt)
      .map((key) => sentAt[key])
      .filter((value) => !!value === true).length > 0

  return (
    <>
      <div>
        {!!modalProductCategory && (
          <Modal
            open={!!modalProductCategory}
            onClose={(): void => hideModalHandler()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <SelectProductItemModal
              hideModalHandler={hideModalHandler}
              quoteIdx={idx}
              productCategory={modalProductCategory}
            />
          </Modal>
        )}
      </div>
      <Table className={styles.calculatorTable} size="small" aria-label="a dense table">
        <TableHead className={styles.tableHeadContainer}>
          <TableRow>
            {[
              {
                displayName: '카테고리',
                align: 'left',
                isEditable: true,
              },
              {
                displayName: '제품 선택',
                align: isOrder ? 'left' : 'right',
                isEditable: true,
              },
              {
                displayName: isOrder
                  ? `주문가 (${Object.keys(sentAt)
                      .filter((key) => !!sentAt[key as PriceKey])
                      .map((key) => {
                        switch (key) {
                          case 'price':
                            return '정가'
                          case 'premium':
                            return '현재 프리미엄가'
                          case 'package':
                            return '현재 패키지가'
                          case 'lowest':
                            return '현재 최저가가'
                          default:
                            return ''
                        }
                      })
                      .pop()})`
                  : '정가',
                align: isOrder ? 'left' : 'right',
                isEditable: isOrder || sentAt.price,
              },
              {
                displayName: `프리미엄가`,
                align: 'right',
                isEditable: sentAt.premium,
              },
              {
                displayName: `패키지가`,
                align: 'right',
                isEditable: sentAt.package,
              },
              {
                displayName: `최저가`,
                align: 'right',
                isEditable: sentAt.lowest,
              },
            ]
              .slice(0, isOrder ? 3 : 6)
              .map(({ displayName, align, isEditable }, index) => (
                <TableCell
                  key={index}
                  style={isAvalableSentQuote ? { backgroundColor: isEditable ? '' : 'gray' } : {}}
                  className={styles.cellText}
                  align={align as 'left' | 'right'}
                >
                  {displayName}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBodyContainer}>
          {/* 제품 선택 */}
          {productRows.map((row, index) => (
            <CalculatorRow
              key={index}
              rowType={RowType.ProductRow}
              {...row}
              idx={idx}
              sentAt={sentAt}
              isOrder={isOrder}
              isAvalableSentQuote={isAvalableSentQuote}
            />
          ))}
          {/* 기타 제품 */}
          {customProducts.map((customProduct, index) => (
            <CalculatorRow
              key={customProduct.id}
              rowType={RowType.CustomProductRow}
              rowTitle={'기타 제품'}
              customProductIdx={index}
              value={customProduct.productName}
              prices={customProduct.productPrice}
              changeCustomProductName={changeCustomProductName}
              changeCustomProductPrice={changeCustomProductPrice}
              deleteCustomProductHandler={deleteCustomProduct}
              idx={idx}
              sentAt={sentAt}
              isOrder={isOrder}
              isAvalableSentQuote={isAvalableSentQuote}
            />
          ))}
          <CalculatorRow
            rowType={RowType.AppendCustomProductRow}
            rowTitle={'기타 제품 추가'}
            appendCustomProductHandler={appendCustomProduct}
            idx={idx}
            sentAt={sentAt}
            isOrder={isOrder}
            isAvalableSentQuote={isAvalableSentQuote}
          />
          {/* 절시금액 */}
          <CalculatorRow
            rowType={RowType.ReadOnlyRow}
            rowTitle={'➖ (절사 감액)'}
            prices={floorPrice}
            sentAt={sentAt}
            isOrder={isOrder}
            isAvalableSentQuote={isAvalableSentQuote}
          />
          <CalculatorRow
            rowType={RowType.ReadOnlyRow}
            rowTitle={'➕ (절사 증액)'}
            prices={ceilPrice}
            sentAt={sentAt}
            isOrder={isOrder}
            isAvalableSentQuote={isAvalableSentQuote}
          />
          {/* 합계 */}
          <CalculatorRow
            rowType={RowType.TotalRow}
            rowTitle={'합계'}
            prices={totalPrice}
            sentAt={sentAt}
            isOrder={isOrder}
            isAvalableSentQuote={isAvalableSentQuote}
          />
          {/* 최종금액 관련 */}
          <FinalPriceRows
            idx={idx}
            extraDiscountPrice={extraDiscountPrice}
            extraPrice={extraPrice}
            totalPrice={totalPrice}
            finalPrice={finalPrice}
            prevFinalPrice={prevFinalPrice}
            sentAt={sentAt}
            isOrder={isOrder}
            isAvalableSentQuote={isAvalableSentQuote}
            changeExtraDiscountPrice={changeExtraDiscountPrice}
            changeExtraPrice={changeExtraPrice}
          />
          {[LeadStatusEnum.Missed, LeadStatusEnum.Chat, LeadStatusEnum.Duplicate].indexOf(selectedLead.status) ===
            -1 && (
            <>
              {/* 정산 관련 */}
              <PayoutPriceRows
                idx={idx}
                basicPayoutPrice={basicPayoutPrice}
                extraDiscountPayoutPrice={extraDiscountPayoutPrice}
                extraPayoutPrice={extraPayoutPrice}
                finalPayoutPrice={finalPayoutPrice}
                prevFinalPayoutPrice={prevFinalPayoutPrice}
                isAvalableSentQuote={isAvalableSentQuote}
                sentAt={sentAt}
                isOrder={isOrder}
                changeExtraDiscountPayoutPrice={changeExtraDiscountPayoutPrice}
                changeExtraPayoutPrice={changeExtraPayoutPrice}
              />

              {/* 결제 관련 */}
              <PaymentPriceRows
                materialPrice={materialPrice}
                isAvalableSentQuote={isAvalableSentQuote}
                sentAt={sentAt}
                isOrder={isOrder}
              />
            </>
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default React.memo(App)
