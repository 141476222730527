import gql from 'graphql-tag'
import React from 'react'
import CSVReader from 'react-csv-reader'

import {
  Get_Stores_And_SolesQuery as GetStoresAndSolesQuery,
  Sole,
  Store,
  Update_StoresMutation as UpdateStoresMutation,
  Update_StoresMutationVariables as UpdateStoresMutationVariables,
  Upsert_Store_And_SoleMutation as UpsertStoreAndSoleMutation,
  Upsert_Store_And_SoleMutationVariables as UpsertStoreAndSoleMutationVariables,
} from '../../assets/graphql/graphql'
import HowToUploadCSVFile from '../../assets/images/how_to_upload_csv_file.png'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'

interface TypeCSVObject {
  name: string
  type: string
}

const GET_STORES_AND_SOLES = gql`
  query GET_STORES_AND_SOLES {
    stores: store {
      id
      email
      name
      name_business
      llumar_store_name
      store_and_accounts {
        accountByAccount {
          profile_kr_partner {
            email
          }
        }
      }
    }

    sole {
      id
      name
    }
  }
`

const UPDATE_STORES = gql`
  mutation UPDATE_STORES($objects: [store_insert_input!]!) {
    insert_store(
      objects: $objects
      on_conflict: { constraint: store_pkey, update_columns: [llumar_store_name, llumar_store_type] }
    ) {
      affected_rows
    }
  }
`

const INSERT_SOLE_ONE = gql`
  mutation INSERT_SOLE_ONE($name: String) {
    sole: insert_sole_one(object: { name: $name }) {
      id
    }
  }
`

const UPSERT_STORE_AND_SOLE = gql`
  mutation UPSERT_STORE_AND_SOLE($objects: [store_and_sole_insert_input!]!) {
    insert_store_and_sole(
      objects: $objects
      on_conflict: { constraint: store_and_sole_pkey, update_columns: [store, sole] }
    ) {
      affected_rows
    }
  }
`

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, '_'),
}

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  // eslint-disable-next-line camelcase
  const updateStoreTable = async (data: { partner_id: string; riderdash_store_name: string; type: string }[]) => {
    changeSnackbarAlertContent({ severity: 'info', content: 'upload 시작' })

    const csvObject: {
      [key: string]: TypeCSVObject
    } = {}

    // eslint-disable-next-line camelcase
    data.forEach((store: { partner_id: string; riderdash_store_name: string; type: string }) => {
      if (store.partner_id) {
        csvObject[store.partner_id.trim()] = {
          name: store.riderdash_store_name.trim(),
          type: store.type.trim(),
        }
      }
    })

    const storesContents = await client.query<GetStoresAndSolesQuery>({
      query: GET_STORES_AND_SOLES,
    })

    const newStores: Array<{
      id: string
      name: string
      // eslint-disable-next-line camelcase
      name_business: string
      email: string
      // eslint-disable-next-line camelcase
      llumar_store_name?: string
      // eslint-disable-next-line camelcase
      llumar_store_type: string
    }> = []

    storesContents.data.stores.forEach((store) => {
      if (
        csvObject[store.email] ||
        (store.store_and_accounts.length &&
          store.store_and_accounts[0].accountByAccount.profile_kr_partner &&
          store.store_and_accounts[0].accountByAccount.profile_kr_partner.email &&
          csvObject[store.store_and_accounts[0].accountByAccount.profile_kr_partner.email])
      ) {
        const newStore = {
          id: store.id,
          name: store.name,
          name_business: store.name_business,
          email: store.email,
          llumar_store_name: csvObject[store.email].name,
          llumar_store_type: csvObject[store.email].type === '버텍스' ? 'vertex' : 'certified',
        }
        newStores.push(newStore)
      }
    })

    await client.mutate<UpdateStoresMutation, UpdateStoresMutationVariables>({
      mutation: UPDATE_STORES,
      variables: {
        objects: newStores,
      },
    })

    changeSnackbarAlertContent({ severity: 'success', content: 'upload 끝' })
  }

  // eslint-disable-next-line camelcase
  const updateStoreTableForSole = async (data: { name__riderdash____: string; sole_name: string }[]) => {
    changeSnackbarAlertContent({ severity: 'info', content: 'upload 시작' })

    // get stores
    const { data: storesContents } = await client.query<GetStoresAndSolesQuery>({
      query: GET_STORES_AND_SOLES,
    })

    // get soles from data
    const uniqueSoles = data
      .map((value) => {
        return value.sole_name
      })
      .filter((value, index, self) => {
        return self.indexOf(value) === index && value !== null
      })

    // create a dictionary of sole names and ids.
    const soles: { [key: string]: string } = {}
    uniqueSoles.forEach(async (name: string) => {
      const matchedSole = storesContents.sole.find((sole: Sole) => sole.name === name)
      if (!matchedSole) {
        const soleId = (
          await client.mutate({
            mutation: INSERT_SOLE_ONE,
            variables: {
              name,
            },
          })
        ).data.sole.id

        soles[name] = soleId
      } else {
        soles[name] = matchedSole.id
      }
    })

    const newStoresAndSoles: Array<{
      store: string
      sole: string
    }> = []

    // match store names with store id and sole id
    // eslint-disable-next-line camelcase
    data.forEach(async (piece: { name__riderdash____: string; sole_name: string }) => {
      const foundStore = storesContents.stores.find((store: Store) => {
        // eslint-disable-next-line no-underscore-dangle
        return store.llumar_store_name === piece.name__riderdash____
      })

      if (foundStore && soles[piece.sole_name]) {
        newStoresAndSoles.push({ store: foundStore.id, sole: soles[piece.sole_name] })
      }
    })

    // Upsert list of store_and_sole
    await client.mutate<UpsertStoreAndSoleMutation, UpsertStoreAndSoleMutationVariables>({
      mutation: UPSERT_STORE_AND_SOLE,
      variables: {
        objects: newStoresAndSoles.filter((storeAndSole, index, self) => {
          return (
            // remove duplicates
            index ===
            self.findIndex((selfStoreAndSole) => {
              return selfStoreAndSole.sole === storeAndSole.sole && selfStoreAndSole.store === storeAndSole.store
            })
          )
        }),
      },
    })

    changeSnackbarAlertContent({ severity: 'success', content: 'upload 끝' })
  }

  return (
    <div>
      <p>카라모라 파트너 csv 파일 업데이트</p>
      <img src={HowToUploadCSVFile} style={{ width: '400px', height: '300px' }}></img>

      <CSVReader
        cssClass="react-csv-input"
        label="Caramora Partner Sales Status"
        onFileLoaded={updateStoreTable}
        parserOptions={papaparseOptions}
      />
      <br />
      <CSVReader
        cssClass="react-csv-input"
        label="Caramora Partner Sole for Store (Account List, for deployment <-- remove first line in csv!)"
        onFileLoaded={updateStoreTableForSole}
        parserOptions={papaparseOptions}
      />
    </div>
  )
}

export default React.memo(App)
