import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'

import {
  Get_Profile_CustomerQuery as GetProfileCustomerQuery,
  Get_Profile_CustomerQueryVariables as GetProfileCustomerQueryVariables,
  Profile_Kr_Customer as ProfileKrCustomer,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

const GET_PROFILE_CUSTOMER = (filter: string): DocumentNode => {
  const where =
    filter ||
    `_and: [
{ deleted_at: { _is_null: true } }
{ created_at: { _lt: "${moment(moment(Date.now()).format('YYYY-MM-DD')).add(1, 'days').toISOString()}" } }]
`

  return gql`
  query GET_PROFILE_CUSTOMER($offset: Int = 0) {
    profile_customer: profile_kr_customer( where: {${where}}, order_by: { created_at: desc },offset: $offset) {
      id
      name
      email
      created_at
      phone_mobile
      account
      accountByAccount {
        account_type {
          value
        }
      }
    }
  }
`
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [currentIndex, setCurrentIndex] = useState(0)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [filter, setFilter] = useState('')

  const [getData, { data, error }] = useLazyQuery<GetProfileCustomerQuery, GetProfileCustomerQueryVariables>(
    GET_PROFILE_CUSTOMER(filter),
    {
      fetchPolicy: 'no-cache',
      variables: { offset: currentIndex * 100 },
    },
  )

  useEffect(() => {
    getData()
  }, [])

  if (error) {
    Sentry.captureException(error)
  }

  if (!data) return null

  const headers = [
    { label: 'uuid', key: 'id' },
    { label: 'account id', key: 'account' },
    { label: '생성일자(UTC)', key: 'created_at' },
    { label: '생성일자(KST)', key: 'created_at_kst' },
    { label: '가입방법', key: 'provider' },
    { label: '이름', key: 'name' },
    { label: '이메일', key: 'email' },
    { label: '휴대폰 번호', key: 'phone_mobile' },
  ]

  return (
    <div className="body">
      <div className={styles.container}>
        <div>고객 목록</div>
        <br />
        <input
          type={'date'}
          value={startDate}
          onChange={(e): void => {
            setStartDate(e.target.value)
          }}
        />
        &nbsp;~&nbsp;
        <input
          type={'date'}
          value={endDate}
          onChange={(e): void => {
            setEndDate(e.target.value)
          }}
        />
        <button
          className={styles.addButton}
          onClick={async (): Promise<void> => {
            if (!startDate || !endDate) {
              changeSnackbarAlertContent({ severity: 'warning', content: '기간을 정확히 입력해주세요' })
            } else if (moment(startDate).toISOString() > moment(endDate).toISOString()) {
              changeSnackbarAlertContent({ severity: 'warning', content: '기간을 잘못 입력했습니다.' })
            } else {
              setCurrentIndex(0)
              setFilter(`
                _and: [
                { deleted_at: { _is_null: true } }
                { created_at: { _gt: "${moment(startDate).toISOString()}" } }
                { created_at: { _lt: "${moment(endDate).add(1, 'days').toISOString()}" } }]
                `)

              getData()
            }
          }}
        >
          기간 검색
        </button>
        <br />
        <br />
        <CSVLink
          data={data.profile_customer.map((customer: ProfileKrCustomer) => {
            return {
              id: customer.id,
              account: customer.account,
              created_at: moment(customer.created_at).format('YYYY-MM-DD HH:mm'),
              created_at_kst: moment(customer.created_at).add(9, 'hours').format('YYYY-MM-DD HH:mm'),
              provider: customer.accountByAccount.account_type.value,
              name: customer.name,
              email: customer.email,
              phone_mobile: customer.phone_mobile ? new PhoneNumber(customer.phone_mobile).getNumber('national') : '',
            }
          })}
          filename={`고객 목록 리스트(
            ${startDate ? moment(startDate).format('YYYY.MM.DD') : ''}-
            ${endDate ? moment(endDate).format('YYYY.MM.DD') : ''}
            ).csv`.replace(/[\t\n ]+/gi, '')}
          headers={headers}
        >
          엑셀 파일로 다운로드
        </CSVLink>
        <br />
        <br />
        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>uuid</th>
              <th className={styles.uuid}>account id</th>
              <th>생성일자</th>
              <th>가입 방법</th>
              <th>이름</th>
              <th>이메일</th>
              <th>휴대폰 번호</th>
              <th></th>
            </tr>
            {data.profile_customer.map((item: ProfileKrCustomer, index: number) => (
              <tr key={index}>
                <td>{data.profile_customer.length - index}</td>
                <td>{item.id}</td>
                <td>{item.account}</td>
                <td>{moment(item.created_at).format()}</td>
                <td>{item.accountByAccount.account_type.value}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.phone_mobile}</td>
                <td>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/profile-customer-detail?id=${item.id}`)
                    }}
                  >
                    상세보기
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
