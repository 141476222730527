import { QueryLazyOptions } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'

import { Order } from '../assets/graphql/graphql'
import config from '../configs'
import { UPDATE_ORDER_SCHEDULE, UPDATE_ORDER_STATUS } from '../pages/Order/query'
import client, { getValidIdToken } from '../utils/graphql'
import useRootData from './useRootData'

export default (
  getData: (options?: QueryLazyOptions<Record<string, unknown>>) => void,
): {
  changeToNewSchedule: (item: Order, newSchedule: string) => Promise<void>
  confirmDate: (item: Order) => Promise<void>
  sendOrderConfirmKakao: (item: Order, to: string) => Promise<void>
  cancelOrder: (item: Order) => Promise<void>
  installOrder: (orderId: string) => Promise<void>
  finishOrder: (orderId: string) => Promise<void>
  sendPaymentLink: (orderId: string, gateway: 'stripe' | 'rapyd' | 'bootpay') => Promise<void>
  refundOrder: (item: Order, reason: string) => Promise<void>
} => {
  const { changeSnackbarAlertContent, sendAxios } = useRootData(({ appStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    sendAxios: appStore.sendAxios,
  }))

  return {
    changeToNewSchedule: async (item: Order, newSchedule: string) => {
      try {
        moment.locale('ko')
        // eslint-disable-next-line no-alert
        const changeSchedule = window.confirm(
          `해당 시공의 예약 일자를 ${moment(newSchedule).local().format('YYYY-MM-DD A hh시')}로 변경하시겠습니까?`,
        )
        if (changeSchedule) {
          await client.mutate({
            mutation: UPDATE_ORDER_SCHEDULE,
            variables: {
              id: item.id,
              date: moment(newSchedule).utc(),
            },
          })

          // TODO : 통합 어드민에서 예약 일자 확정 메일을 보낼 수 있을 때 지우기
          // eslint-disable-next-line max-depth
          if (item.date_time) {
            await sendAxios.post(`${config.backendEndPoint}/send/send-change-schedule-email`, {
              name: item.name,
              email: item.email,
              phoneNumber: new PhoneNumber(item.storeByStore.phone_business, 'KR').getNumberFrom('KR'),
              storeName: item.storeByStore.name,
              dateTime: moment(item.date_time).local().format('YYYY-MM-DD A hh시'),
              newDateTime: moment(newSchedule).local().format('YYYY-MM-DD A hh시'),
            })
          }

          changeSnackbarAlertContent({ severity: 'success', content: '예약 일자 변경 성공' })
        }

        getData()
      } catch (err) {
        changeSnackbarAlertContent({ severity: 'error', content: `예약 일자 변경 실패\n${err}` })
        Sentry.captureException(err)
      }
    },

    confirmDate: async (item: Order): Promise<void> => {
      try {
        moment.locale('ko')
        const dateTime = moment(Date.now()).format()
        // insert to accounts_payable_and_payout
        await client.mutate({
          mutation: UPDATE_ORDER_STATUS('confirmed'),
          variables: {
            id: item.id,
            date: moment(`${dateTime.substring(0, dateTime.length - 2)}00`)
              .utc()
              .toString(),
          },
        })

        getData()
        changeSnackbarAlertContent({ severity: 'success', content: '예약 확정 성공' })
      } catch (err) {
        Sentry.captureException(err)
        changeSnackbarAlertContent({ severity: 'error', content: `예약 확정 실패\n${err}` })
      }
    },

    sendOrderConfirmKakao: async (item: Order, to: string): Promise<void> => {
      try {
        if (to === 'customer') {
          await sendAxios.post(`${config.backendEndPoint}/send/send-confirmed-to-customer-kakao`, {
            orderId: item.id,
          })
        } else {
          await sendAxios.post(`${config.backendEndPoint}/send/send-confirmed-to-store-kakao`, {
            orderId: item.id,
          })
        }

        changeSnackbarAlertContent({ severity: 'success', content: '카카오톡 전송을 성공하였습니다.' })
      } catch (err) {
        changeSnackbarAlertContent({ severity: 'error', content: `카카오톡 전송 실패.\n ${err}` })
      }
    },

    cancelOrder: async (item: Order): Promise<void> => {
      try {
        moment.locale('ko')
        // insert to accounts_payable_and_payout
        await client.mutate({
          mutation: UPDATE_ORDER_STATUS('canceled'),
          variables: {
            id: item.id,
            date: moment().format(),
          },
        })

        getData()
      } catch (err) {
        changeSnackbarAlertContent({ severity: 'error', content: `예약 취소 실패\n${err}` })
      }
    },

    installOrder: async (orderId: string): Promise<void> => {
      try {
        await client.mutate({
          mutation: UPDATE_ORDER_STATUS('installed'),
          variables: {
            id: orderId,
            date: moment().format(),
          },
        })

        getData()
        changeSnackbarAlertContent({ severity: 'success', content: '시공 완료 성공' })
      } catch (err) {
        Sentry.captureException(err)
        changeSnackbarAlertContent({ severity: 'error', content: `시공 완료를 실패하였습니다.\n${err}` })
      }
    },
    sendPaymentLink: async (orderId: string, gateway: 'stripe' | 'rapyd' | 'bootpay'): Promise<void> => {
      try {
        const result = await sendAxios.post(`${config.backendEndPoint}/order/send-payment-link`, {
          id: orderId,
          idToken: await getValidIdToken(),
          gateway,
        })

        if (!result) throw Error('No result')

        getData()
        changeSnackbarAlertContent({ severity: 'success', content: '재전송 성공' })
      } catch (err) {
        Sentry.captureException(err)
        changeSnackbarAlertContent({ severity: 'error', content: `재전송을 실패하였습니다.\n${err}` })
      }
    },
    finishOrder: async (orderId: string): Promise<void> => {
      try {
        await client.mutate({
          mutation: UPDATE_ORDER_STATUS('finished'),
          variables: {
            id: orderId,
            date: moment().format(),
          },
        })

        await sendAxios.post(`${config.backendEndPoint}/order/send-order-installed`, {
          id: orderId,
        })

        getData()
        changeSnackbarAlertContent({ severity: 'success', content: '현장 결제 처리 성공' })
      } catch (err) {
        Sentry.captureException(err)
        changeSnackbarAlertContent({ severity: 'error', content: `현장 결제 처리를 실패하였습니다.\n${err}` })
      }
    },

    refundOrder: async (item: Order, reason: string): Promise<void> => {
      try {
        let paymentGateway = ''
        if (item.order_and_transactions[0].transactionByTransaction.stripe_payment_intent_id) {
          paymentGateway = 'stripe'
        } else if (item.order_and_transactions[0].transactionByTransaction.rapyd_payment_intent_id) {
          paymentGateway = 'rapyd'
        } else if (item.order_and_transactions[0].transactionByTransaction.bootpay_payment_intent_id) {
          paymentGateway = 'bootpay'
        }

        const result = await sendAxios.post(`${config.backendEndPoint}/order/refund-order`, {
          orderId: item.id,
          paymentGateway,
          reason,
          idToken: await getValidIdToken(),
        })

        if (!result) throw Error('No result')

        getData()
        changeSnackbarAlertContent({ severity: 'success', content: '성공적으로 환불처리 되었습니다.' })
      } catch (err) {
        Sentry.captureException(err)
        if (err.response.data.code === 'charge_already_refunded') {
          changeSnackbarAlertContent({ severity: 'info', content: '이미 환불처리된 결제건입니다.' })
        } else {
          changeSnackbarAlertContent({ severity: 'error', content: err })
        }
      }
    },
  }
}
