// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___toK_k {\n  margin-top: 15px;\n  position: relative;\n  max-width: 1750px; }\n  .index-module__container___toK_k .index-module__tableHeadContainer___u1iqp .index-module__rowTitle___pvW8E {\n    background-color: #b388ff; }\n    .index-module__container___toK_k .index-module__tableHeadContainer___u1iqp .index-module__rowTitle___pvW8E .index-module__col___2IFuF {\n      color: #ffffff;\n      font-weight: bold;\n      white-space: pre-wrap;\n      text-align: left;\n      max-width: 250px; }\n  .index-module__container___toK_k .index-module__tableBodyContainer___3Km7- .index-module__row___3XlQj .index-module__col___2IFuF {\n    cursor: pointer;\n    white-space: pre-wrap;\n    text-align: left;\n    max-width: 250px;\n    min-width: 300px;\n    overflow: scroll;\n    vertical-align: top; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/ConfigMessageTemplate/index.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB,EAAA;EAHnB;IAMM,yBAAyB,EAAA;IAN/B;MAQQ,cAAc;MACd,iBAAiB;MACjB,qBAAqB;MACrB,gBAAgB;MAChB,gBAAgB,EAAA;EAZxB;IAmBQ,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin-top: 15px;\n  position: relative;\n  max-width: 1750px;\n  .tableHeadContainer {\n    .rowTitle {\n      background-color: #b388ff;\n      .col {\n        color: #ffffff;\n        font-weight: bold;\n        white-space: pre-wrap;\n        text-align: left;\n        max-width: 250px;\n      }\n    }\n  }\n  .tableBodyContainer {\n    .row {\n      .col {\n        cursor: pointer;\n        white-space: pre-wrap;\n        text-align: left;\n        max-width: 250px;\n        min-width: 300px;\n        overflow: scroll;\n        vertical-align: top;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___toK_k",
	"tableHeadContainer": "index-module__tableHeadContainer___u1iqp",
	"rowTitle": "index-module__rowTitle___pvW8E",
	"col": "index-module__col___2IFuF",
	"tableBodyContainer": "index-module__tableBodyContainer___3Km7-",
	"row": "index-module__row___3XlQj"
};
module.exports = exports;
