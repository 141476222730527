import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect } from 'react'

import {
  Get_Subscription_And_TransactionQuery as GetSubscriptionAndTransactionQuery,
  Get_Subscription_And_TransactionQueryVariables as GetSubscriptionAndTransactionQueryVariables,
  Subscription_And_Transaction as SubscriptionAndTransaction,
} from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

const GET_SUBSCRIPTION_AND_TRANSACTION = gql`
  query GET_SUBSCRIPTION_AND_TRANSACTION {
    subscription_and_transaction(order_by: { created_at: desc }) {
      created_at
      subscriptionBySubscription {
        id
        storeByStore {
          name
          phone_business
          email
        }
      }
      transactionByTransaction {
        id
        amount
        stripe_payment_intent_id
        rapyd_payment_intent_id
        transaction_type {
          value
          description
        }
        refund {
          id
        }
      }
    }
  }
`

const App: React.FunctionComponent = () => {
  const { accountId, authorization, changeSnackbarAlertContent } = useRootData(({ authStore, appStore }) => ({
    accountId: authStore.accountId.get(),
    authorization: authStore.authorization.get(),
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [getData, { data, error }] = useLazyQuery<
    GetSubscriptionAndTransactionQuery,
    GetSubscriptionAndTransactionQueryVariables
  >(GET_SUBSCRIPTION_AND_TRANSACTION, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getData()
  }, [])

  if (error) {
    Sentry.captureException(error)
  }
  if (!data) return null

  return (
    <div className="body">
      <div className={styles.container}>
        <div>입점료 결제 목록</div>
        <br />

        <div>
          <table>
            <tbody>
              <tr>
                <th className={styles.number}>No.</th>
                <th className={styles.uuid}>uuid</th>
                <th>날짜</th>
                <th>매장이름</th>
                <th>전화번호</th>
                <th>이메일</th>
                <th>가격</th>
                <th>거래 종류</th>
                <th></th>
              </tr>
              {data.subscription_and_transaction.map((item: SubscriptionAndTransaction, index: number) => (
                <tr key={index}>
                  <td>{data.subscription_and_transaction.length - index}</td>
                  <td>{item.subscriptionBySubscription.id}</td>
                  <td>{moment(item.created_at).format()}</td>
                  <td>{item.subscriptionBySubscription.storeByStore.name}</td>
                  <td>{item.subscriptionBySubscription.storeByStore.phone_business}</td>
                  <td>{item.subscriptionBySubscription.storeByStore.email}</td>
                  <td>{item.transactionByTransaction.amount}</td>
                  <td>{item.transactionByTransaction.transaction_type.description}</td>
                  <td>
                    {item.transactionByTransaction.transaction_type.value === 'purchase' &&
                      item.transactionByTransaction.amount > 0 &&
                      item.transactionByTransaction.refund === null &&
                      authorization && (
                        <button
                          onClick={(): void => {
                            // eslint-disable-next-line no-alert
                            const reason = prompt('환불 이유를 입력해주세요.', '')

                            if (reason && authorization) {
                              let paymentGateway = ''
                              let id = ''

                              if (item.transactionByTransaction.stripe_payment_intent_id) {
                                paymentGateway = 'stripe'
                                id = item.transactionByTransaction.stripe_payment_intent_id
                              } else if (item.transactionByTransaction.rapyd_payment_intent_id) {
                                paymentGateway = 'rapyd'
                                id = item.transactionByTransaction.rapyd_payment_intent_id
                              }

                              axios
                                .get(`${config.backendEndPoint}/${paymentGateway}/refund-subscription`, {
                                  params: {
                                    paymentIntentId: id,
                                    subscriptionId: item.subscriptionBySubscription.id,
                                    transactionId: item.transactionByTransaction.id,
                                    account: accountId,
                                    reason,
                                  },
                                })
                                .then(() => {
                                  changeSnackbarAlertContent({
                                    severity: 'success',
                                    content: '성공적으로 환불처리 되었습니다.',
                                  })
                                  getData()
                                })
                                .catch((err) => {
                                  if (err.response.data.code === 'charge_already_refunded') {
                                    changeSnackbarAlertContent({
                                      severity: 'info',
                                      content: '이미 환불처리된 결제건입니다.',
                                    })
                                  } else {
                                    changeSnackbarAlertContent({ severity: 'error', content: err })
                                  }
                                })
                            }
                          }}
                        >
                          환불
                        </button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
