import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import {
  Get_Product_Item_DetailQuery as GetProductItemDetailQuery,
  Get_Product_Item_DetailQueryVariables as GetProductItemDetailQueryVariables,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const GET_PRODUCT_ITEM_DETAIL = gql`
  query GET_PRODUCT_ITEM_DETAIL($productItemId: uuid!) {
    product_item(where: { id: { _eq: $productItemId } }) {
      id
      sku
      price
      commission_rate
      productByProduct {
        name
        brand
        type
      }
    }
  }
`

const UPDATE_PRODUCT_ITEM_DETAIL = gql`
  mutation UPDATE_PRODUCT_ITEM_DETAIL($productItemId: uuid!, $price: numeric!, $commissionRate: numeric!) {
    update_product_item(
      where: { id: { _eq: $productItemId } }
      _set: { price: $price, commission_rate: $commissionRate }
    ) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
  }))

  const { id } = parseQueryString()

  const { data, loading, error } = useQuery<GetProductItemDetailQuery, GetProductItemDetailQueryVariables>(
    GET_PRODUCT_ITEM_DETAIL,
    {
      fetchPolicy: 'no-cache',
      variables: { productItemId: id },
    },
  )

  const [editClicked, setEditClicked] = useState(!id)
  const [newProductItem, setNewProductItem] = useState(null)

  useEffect(() => {
    if (!loading) {
      setNewProductItem({ ...data.product_item[0] })
    }
  }, [loading])

  if (error) {
    Sentry.captureException(error)
  }
  if (loading || !data) return null

  const productItem = data.product_item[0]

  return (
    <div className="body">
      <div className={styles.container}>
        <div className={styles.editButtonArea}>
          {!editClicked ? (
            <button
              className={styles.button}
              onClick={(): void => {
                setEditClicked(true)
              }}
            >
              제품 정보 수정
            </button>
          ) : null}

          {editClicked ? (
            <div>
              <button
                className={styles.button}
                onClick={async (): Promise<void> => {
                  try {
                    await client.mutate({
                      mutation: UPDATE_PRODUCT_ITEM_DETAIL,
                      variables: {
                        productItemId: id,
                        price: newProductItem.price,
                        commissionRate: newProductItem.commission_rate,
                      },
                    })
                    changeMainMenu('/product-item')
                  } catch (err) {
                    Sentry.captureException(err)
                  }
                }}
              >
                저장하기
              </button>
            </div>
          ) : null}
        </div>
        {editClicked && <div className={styles.editing}>가격정보 수정중</div>}
        <div className={styles.container}>
          {`[${productItem.productByProduct.type}-${productItem.productByProduct.brand}-${productItem.productByProduct.name}]`}
          <br></br>
          {`SKU : ${productItem.sku}`}
          <div className={styles.dataField}></div>
          가격 :
          <input
            className={editClicked ? styles.inputEditing : styles.inputDefault}
            defaultValue={!editClicked ? productItem.price : newProductItem.price}
            disabled={!editClicked}
            onChange={(e): void => {
              setNewProductItem({ ...newProductItem, price: e.target.value })
            }}
          />
          원<div className={styles.dataField}></div>
          수수료 :
          <input
            className={editClicked ? styles.inputEditing : styles.inputDefault}
            defaultValue={!editClicked ? productItem.commission_rate : newProductItem.commission_rate}
            disabled={!editClicked}
            onChange={(e): void => {
              setNewProductItem({ ...newProductItem, commission_rate: e.target.value })
            }}
          />
          %
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
