/* eslint-disable no-underscore-dangle */
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const _GET_CART = gql`
  query _GET_CART($cartId: uuid!) {
    n_cart_by_pk(id: $cartId) {
      id
      session
      account
      cart_items(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
        id
        created_at
        updated_at
        car
        carByCar {
          model
          car_maker {
            name_kr
          }
        }
        car_sunroof_type
        checked
        commission_rate
        date_time
        deleted_at
        price_discount
        price_extra
        price_final
        price_payout
        price_payout_without_vat
        price_product
        product_item
        remark
        store
        storeByStore {
          name
          phone_business
        }
        updated_by
        package_detail {
          name
          package_type {
            description
          }
        }
        product_item_detail {
          attribute
          price
          productByProduct {
            product_brand {
              description
            }
            name
            product_type {
              value
              description
            }
          }
        }
      }
    }
  }
`

// 카트 아이템 삭제 시 카트 아이템에 delete_at 추가
export const _REMOVE_CART_ITEM_FROM_CART = (query: string): DocumentNode => {
  return gql`
    mutation _REMOVE_CART_ITEM_FROM_CART($deletedAt: timestamptz!) {
      update_cart_item(where: { _or: [ ${query} ] }, _set: { deleted_at: $deletedAt } ) {
        affected_rows
      }
    }
  `
}
