// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__root___2ApFK {\n  display: 'flex'; }\n\n.index-module__appBar___t9ApT {\n  background-color: #742dd2 !important;\n  color: white !important;\n  max-width: calc(100% - 205px);\n  margin-left: 205px; }\n  .index-module__appBar___t9ApT .index-module__title___3kG_C {\n    cursor: pointer; }\n\n.index-module__content___2CHTk {\n  height: calc(100% - 64px);\n  margin-left: 205px;\n  padding: 10px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/layouts/DefaultLayout/index.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAe,EAAA;;AAGjB;EACE,oCAA4C;EAC5C,uBAAuB;EACvB,6BAA6B;EAC7B,kBAAkB,EAAA;EAJpB;IAMI,eAAe,EAAA;;AAInB;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa,EAAA","file":"index.module.scss","sourcesContent":["@import '../../assets/scsses/config.scss';\n\n.root {\n  display: 'flex';\n}\n\n.appBar {\n  background-color: $primary-purple !important;\n  color: white !important;\n  max-width: calc(100% - 205px);\n  margin-left: 205px;\n  .title {\n    cursor: pointer;\n  }\n}\n\n.content {\n  height: calc(100% - 64px);\n  margin-left: 205px;\n  padding: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"balloonBlack": "#101010",
	"black": "#000000",
	"blueLightGrey1": "#f6f8f9",
	"blueLightGrey2": "#eff1f4",
	"blueLightGrey3": "#e9ecef",
	"blueLightGrey4": "#e6e7ee",
	"blueLightGrey5": "#dadce5",
	"bodyBlack": "#333333",
	"bodyGrey": "#acacac",
	"charcoal": "#545454",
	"darkCharcoal": "#4c4c4c",
	"darkGrey": "#6d7278",
	"deepPurple": "#673ab7",
	"deepPurple2": "#b388ff",
	"favouriteYellow": "#ffd54f",
	"grey1": "#efefef",
	"grey2": "#e1e1e1",
	"grey3": "#d8d8d8",
	"grey4": "#adadad",
	"greyshWhite": "#fcfcfc",
	"hashtagPurple": "#f6f1ff",
	"headerBottom": "#ededed",
	"lightGreen": "#13c4a3",
	"lightGrey": "#f8f8f8",
	"neonPurple": "#7c4dff",
	"placeholder": "#b7b7b7",
	"primaryPurple": "#742dd2",
	"white": "#ffffff",
	"root": "index-module__root___2ApFK",
	"appBar": "index-module__appBar___t9ApT",
	"title": "index-module__title___3kG_C",
	"content": "index-module__content___2CHTk"
};
module.exports = exports;
