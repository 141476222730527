import React from 'react'

import Detail from './Detail'

const App: React.FunctionComponent = () => {
  return (
    <div className="body" style={{ flexDirection: 'column' }}>
      <Detail />
    </div>
  )
}
export default React.memo(App)
