import { TableCell, TableRow, TextField } from '@material-ui/core'
import React from 'react'

import Text from '../../../../components/atoms/Text'
import useRootData from '../../../../hooks/useRootData'
import { initSearchInputValues, LEAD_COL } from '../../../../stores/lead/type'
import { tableCol } from '../../index'
import { mappingStyle } from '../LeadRow'
import DateFilter from './DateFilter'
import styles from './index.module.scss'
import SelectFilter from './SelectFilter'

const App: React.FunctionComponent = () => {
  const {
    statusFilterValue,
    searchInputValues,
    chatAsigneeArray,
    operatorArray,
    storeArray,

    _changeSearchInputValues,
  } = useRootData(({ leadStore, calculatorStore }) => ({
    statusFilterValue: leadStore.statusFilterValue.get(),
    searchInputValues: leadStore.searchInputValues.get(),
    chatAsigneeArray: leadStore.chatAsigneeArray,
    operatorArray: leadStore.operatorArray,
    storeArray: calculatorStore.storeArray,

    _changeSearchInputValues: leadStore.changeSearchInputValues,
  }))

  const changeSearchInputValues = (
    event: React.ChangeEvent<
      | HTMLTextAreaElement
      | HTMLInputElement
      | {
          name?: string
          value: unknown
        }
    >,
    col: LEAD_COL,
  ) => {
    _changeSearchInputValues({ [col]: event.target.value as string })
  }

  const resetButtonClickHandler = () => {
    _changeSearchInputValues(initSearchInputValues)
  }

  return (
    <TableRow>
      <TableCell style={{ padding: 5, textAlign: 'center', minWidth: 50 }} className={styles.reset}>
        <Text text={'초기화'} clickHandler={(): void => resetButtonClickHandler()} />
      </TableCell>

      {tableCol[statusFilterValue].map((col, index) => {
        if (searchInputValues[col] !== undefined) {
          switch (col) {
            case LEAD_COL.START_DROP_OFF_DATE:
            case LEAD_COL.END_DROP_OFF_DATE:
            case LEAD_COL.DUE_DATE:
            case LEAD_COL.CREATED_AT:
            case LEAD_COL.UPDATED_AT:
            case LEAD_COL.PAYMENT_LINK_SENT_AT:
            case LEAD_COL.VAT_REQUESTED_AT:
            case LEAD_COL.PAID_AT:
            case LEAD_COL.FINISHED_AT:
            case LEAD_COL.RSV_COMFIRMED_AT:
              return <DateFilter key={index} col={col}></DateFilter>
            case LEAD_COL.CHAT_ASSIGNEE:
              return (
                <SelectFilter
                  key={index}
                  col={col}
                  options={chatAsigneeArray.map((option) => {
                    // TODO: Need to refactor
                    return {
                      label: option.text,
                      value: option.text,
                    }
                  })}
                />
              )
            case LEAD_COL.SUPPORT_ASSIGNEE:
            case LEAD_COL.MATCH_MAKER:
              return (
                <SelectFilter
                  key={index}
                  col={col}
                  options={operatorArray.map((option) => {
                    // TODO: Need to refactor
                    return {
                      label: option.text,
                      value: option.text,
                    }
                  })}
                />
              )
            case LEAD_COL.SHOP:
              return (
                <SelectFilter
                  key={index}
                  col={col}
                  options={storeArray[0].slice(1).map((option) => {
                    return {
                      label: option.text,
                      value: option.text,
                    }
                  })}
                ></SelectFilter>
              )

            default:
              return (
                <TableCell key={index} style={{ padding: 0, textAlign: 'center' }}>
                  <TextField
                    style={mappingStyle[col]}
                    value={searchInputValues[col]}
                    variant="outlined"
                    onChange={(event): void => changeSearchInputValues(event, col)}
                  />
                </TableCell>
              )
          }
        }

        return <TableCell key={index} style={{ padding: 5 }} />
      })}
    </TableRow>
  )
}
export default React.memo(App)
