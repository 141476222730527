import {
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'
import React, { useState } from 'react'
import ReactSelect from 'react-select'

import config from '../../../../configs'
import useRootData from '../../../../hooks/useRootData'
import { CHECK_LEAD_EXISTENCE } from '../../../../stores/lead/query'
import { LEAD_COL } from '../../../../stores/lead/type'
import client from '../../../../utils/graphql'
import { autoHypenPhoneNumber, checkIsValiedPhoneNumber, getTheClosestWeekday } from '../../../../utils/utility'
import LeadSearch from '../../Menu/LeadSearch'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const {
    sendAxios,
    accountId,
    isPartTimer,
    phoneMobile,

    selectedLead,
    selectedCustomer,
    selectedStartDropOffDateTime,
    selectedEndDropOffDateTime,
    selectedDueDate,
    customerPhoneNumber,
    profileCustomerArray,

    changeSnackbarAlertContent,
    changeSelectedCustomer,
    changeSelectedStartDropOffDateTime,
    changeSelectedEndDropOffDateTime,
    changeSelectedDueDate,
    changeCustomerPhoneNumber,
    giveLeadKey,
    updateLeadByPK,
  } = useRootData(({ appStore, authStore, leadStore }) => ({
    sendAxios: appStore.sendAxios,
    accountId: authStore.accountId.get(),
    isPartTimer: authStore.isPartTimer.get(),
    phoneMobile: authStore.phoneMobile.get(),

    selectedLead: leadStore.selectedLead.get(),
    selectedCustomer: leadStore.selectedCustomer.get(),
    selectedStartDropOffDateTime: leadStore.selectedStartDropOffDateTime.get(),
    selectedEndDropOffDateTime: leadStore.selectedEndDropOffDateTime.get(),
    selectedDueDate: leadStore.selectedDueDate.get(),
    customerPhoneNumber: leadStore.customerPhoneNumber.get(),
    profileCustomerArray: leadStore.profileCustomerArray,

    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    changeSelectedCustomer: leadStore.changeSelectedCustomer,
    changeSelectedStartDropOffDateTime: leadStore.changeSelectedStartDropOffDateTime,
    changeSelectedEndDropOffDateTime: leadStore.changeSelectedEndDropOffDateTime,
    changeSelectedDueDate: leadStore.changeSelectedDueDate,
    changeCustomerPhoneNumber: leadStore.changeCustomerPhoneNumber,
    giveLeadKey: leadStore.giveLeadKey,
    updateLeadByPK: leadStore.updateLeadByPK,
  }))

  const [leadSearchPopupStatus, setLeadSearchPopupStatus] = useState<boolean>(false)
  const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const sendMissedCallCustomerButtonClickHandler = async () => {
    if (!checkIsValiedPhoneNumber(customerPhoneNumber)) {
      changeSnackbarAlertContent({ severity: 'error', content: '고객 전화번호가 올바르지 않습니다!' })

      return
    }

    let inAssigneePhoneNumber

    if (phoneMobile) {
      inAssigneePhoneNumber = phoneMobile
    } else {
      // eslint-disable-next-line no-alert
      inAssigneePhoneNumber = prompt('고객에게 전송할 상담원 전화번호를 입력하세요', '')
    }

    if (inAssigneePhoneNumber === null) return

    const assigneePhoneNumber = new PhoneNumber(inAssigneePhoneNumber.replace(/-/gi, ''), 'KR').getNumberFrom('KR')

    if (!assigneePhoneNumber || !checkIsValiedPhoneNumber(assigneePhoneNumber)) {
      changeSnackbarAlertContent({ severity: 'error', content: '상담원 전화번호가 올바르지 않습니다!' })

      return
    }

    try {
      await updateLeadByPK()

      await sendAxios.post(`${config.backendEndPoint}/send/send-remind-quote`, {
        leadId: selectedLead.id,
        assigneePhoneNumber,
        accountId,
      })

      const currentTime = Math.floor(new Date().getTime() / 1000)

      const nextDueDate = getTheClosestWeekday(moment.unix(currentTime + 60 * 60 * 24).format('YYYY-MM-DD'))

      changeSelectedDueDate(nextDueDate)

      changeSnackbarAlertContent({ severity: 'success', content: '카카오톡 전송을 성공하였습니다.' })
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: `카카오톡 전송 실패.\n ${err}` })
    }
  }

  const editPhoneNumberButtonClickHandler = async () => {
    if (editPhoneNumber) {
      if (!checkIsValiedPhoneNumber(customerPhoneNumber)) {
        changeSnackbarAlertContent({ severity: 'error', content: '올바른 번호가 아닙니다!' })

        return
      }

      const { data: leadContent } = await client.query({
        query: CHECK_LEAD_EXISTENCE,
        variables: {
          phone: new PhoneNumber(customerPhoneNumber.replace(/-/gi, ''), 'KR').getNumber('e164'),
        },
        fetchPolicy: 'no-cache',
      })

      if (leadContent.lead.length > 0) {
        changeSnackbarAlertContent({ severity: 'error', content: '중복된 번호가 있습니다!' })
        setLeadSearchPopupStatus(true)

        return
      }

      await giveLeadKey()
      setEditPhoneNumber(false)

      changeSnackbarAlertContent({ severity: 'success', content: '새로운 Lead Id 생성 완료!' })
    } else {
      setEditPhoneNumber(true)
      setPhoneNumber(customerPhoneNumber)
    }
  }

  const changeSelectedStartDropOffDateTimeHandler = (datetime: string) => {
    changeSelectedStartDropOffDateTime(datetime)

    if (!selectedEndDropOffDateTime) {
      changeSelectedEndDropOffDateTime(datetime)
    }

    if (moment(datetime).unix() > moment(selectedEndDropOffDateTime).unix()) {
      changeSelectedEndDropOffDateTime(datetime)
    }
  }

  const changeSelectedEndDropOffDateTimeHandler = (datetime: string) => {
    if (moment(selectedStartDropOffDateTime).unix() > moment(datetime).unix()) {
      changeSnackbarAlertContent({
        severity: 'warning',
        content: 'Start Drop off Date보다 미래로 설정해주세요.',
      })
    }

    changeSelectedEndDropOffDateTime(datetime)
  }

  return (
    <>
      <Modal
        open={leadSearchPopupStatus}
        onClose={(): void => setLeadSearchPopupStatus(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <LeadSearch option={{ phone: phoneNumber }} />
      </Modal>
      <TableContainer className={styles.container} component={Paper}>
        <Table className={styles.table} size="small" aria-label="a dense table">
          <TableHead className={styles.head}>
            <TableRow className={styles.row}>
              {[
                LEAD_COL.CUSTOMER_PHONE,
                LEAD_COL.CARAMORA_ID,
                LEAD_COL.START_DROP_OFF_DATE,
                LEAD_COL.END_DROP_OFF_DATE,
                LEAD_COL.DUE_DATE,
              ].map((col, index) => {
                return (
                  <TableCell className={styles.col} key={index}>
                    {col}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  style={{ minWidth: 150 }}
                  size="small"
                  disabled={!editPhoneNumber}
                  variant="outlined"
                  value={editPhoneNumber ? phoneNumber : customerPhoneNumber}
                  onChange={(event): void => {
                    setPhoneNumber(autoHypenPhoneNumber(event.target.value))
                    changeCustomerPhoneNumber(event.target.value)
                  }}
                />
                <Button variant="contained" onClick={async (): Promise<void> => editPhoneNumberButtonClickHandler()}>
                  {editPhoneNumber ? '저장' : '수정'}
                </Button>
                {!isPartTimer && (
                  <Button
                    variant="contained"
                    onClick={async (): Promise<void> => sendMissedCallCustomerButtonClickHandler()}
                  >
                    {'전화요청'}
                  </Button>
                )}
              </TableCell>
              <TableCell style={{ minWidth: 240 }}>
                <ReactSelect
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  value={
                    selectedCustomer
                      ? { value: selectedCustomer.id, label: selectedCustomer.profile_kr_customer.email }
                      : {
                          value: '',
                          label: '',
                        }
                  }
                  onChange={changeSelectedCustomer}
                  isSearchable
                  options={profileCustomerArray}
                />
              </TableCell>

              <TableCell>
                <TextField
                  type="date"
                  value={
                    selectedStartDropOffDateTime && selectedStartDropOffDateTime.indexOf('T') !== -1
                      ? selectedStartDropOffDateTime.split('T')[0]
                      : ''
                  }
                  onChange={(event): void => {
                    const time =
                      selectedStartDropOffDateTime.indexOf('T') === -1
                        ? '10:00'
                        : selectedStartDropOffDateTime.split('T')[1]

                    const datetime = `${event.target.value}T${time}`

                    changeSelectedStartDropOffDateTimeHandler(datetime)
                  }}
                />
                <TextField
                  type="time"
                  value={
                    selectedStartDropOffDateTime && selectedStartDropOffDateTime.indexOf('T') !== -1
                      ? selectedStartDropOffDateTime.split('T')[1]
                      : '10:00'
                  }
                  disabled={!selectedStartDropOffDateTime || selectedStartDropOffDateTime.indexOf('T') === -1}
                  onChange={(event): void => {
                    const date = selectedStartDropOffDateTime.split('T')[0]

                    const datetime = `${date}T${event.target.value}`

                    changeSelectedStartDropOffDateTimeHandler(datetime)
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={
                    selectedEndDropOffDateTime && selectedEndDropOffDateTime.indexOf('T') !== -1
                      ? selectedEndDropOffDateTime.split('T')[0]
                      : ''
                  }
                  onChange={(event): void => {
                    const time =
                      selectedEndDropOffDateTime.indexOf('T') === -1
                        ? '10:00'
                        : selectedEndDropOffDateTime.split('T')[1]

                    const datetime = `${event.target.value}T${time}`

                    changeSelectedEndDropOffDateTimeHandler(datetime)
                  }}
                />
                <TextField
                  type="time"
                  value={
                    selectedEndDropOffDateTime && selectedEndDropOffDateTime.indexOf('T') !== -1
                      ? selectedEndDropOffDateTime.split('T')[1]
                      : '10:00'
                  }
                  disabled={!selectedEndDropOffDateTime || selectedEndDropOffDateTime.indexOf('T') === -1}
                  onChange={(event): void => {
                    const date = selectedEndDropOffDateTime.split('T')[0]

                    const datetime = `${date}T${event.target.value}`

                    changeSelectedEndDropOffDateTimeHandler(datetime)
                  }}
                />
              </TableCell>

              <TableCell>
                <TextField
                  type="date"
                  value={selectedDueDate}
                  onChange={(event): void => changeSelectedDueDate(event.target.value)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default React.memo(App)
