/* eslint-disable no-underscore-dangle */
import gql from 'graphql-tag'

export const _GET_ORDER = gql`
  query _GET_ORDER($orderId: uuid!) {
    order_by_pk(id: $orderId) {
      id
      created_at
      updated_at
      account
      name
      email
      phone
      status
      car
      carByCar {
        id
        model
        type
        car_maker {
          name_kr
          name_us
          car_origin {
            name_kr
            name_us
          }
        }
      }
      car_sunroof_type
      commission_rate
      date_time
      deleted_at
      price_discount
      price_extra
      price_final
      price_payout
      price_payout_without_vat
      price_product
      product_item
      remark
      store
      storeByStore {
        name
      }
      package_detail {
        id
        name
        package_type {
          description
        }
      }
      product_item_detail {
        id
        attribute
        price
        productByProduct {
          product_brand {
            description
          }
          name
          product_type {
            value
            description
          }
        }
      }
    }
  }
`

export const _UPDATE_ORDER = gql`
  mutation _UPDATE_ORDER($orderObject: order_insert_input!) {
    insert_order_one(
      object: $orderObject
      on_conflict: {
        constraint: order_pkey
        update_columns: [
          car
          car_sunroof_type
          store
          price_product
          price_discount
          price_extra
          price_final
          price_payout
          price_payout_without_vat
          commission_rate
          product_item
          date_time
          remark
        ]
      }
    ) {
      id
    }
  }
`
