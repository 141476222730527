import React from 'react'

import { PriceObject, PriceUpdateObject } from '../../../../../stores/calculator/type'
import CalculatorRow, { RowType } from '../CalculatorRow'

export interface FinalPriceRowsProps {
  idx: number
  basicPayoutPrice: Partial<PriceObject>
  extraDiscountPayoutPrice: Partial<PriceObject>[]
  extraPayoutPrice: Partial<PriceObject>[]
  finalPayoutPrice: Partial<PriceObject>
  prevFinalPayoutPrice: Partial<PriceObject>
  isAvalableSentQuote: boolean
  sentAt: {
    price: boolean
    premium: boolean
    package: boolean
    lowest: boolean
  }
  isOrder: boolean
  changeExtraDiscountPayoutPrice: (object: PriceUpdateObject, idx: number) => void
  changeExtraPayoutPrice: (object: PriceUpdateObject, idx: number) => void
}

const App: React.FunctionComponent<FinalPriceRowsProps> = ({
  idx,
  basicPayoutPrice,
  extraDiscountPayoutPrice,
  extraPayoutPrice,
  finalPayoutPrice,
  prevFinalPayoutPrice,
  isAvalableSentQuote,
  sentAt,
  isOrder,
  changeExtraDiscountPayoutPrice,
  changeExtraPayoutPrice,
}: FinalPriceRowsProps) => {
  return (
    <>
      {/* 정산 기본 금액 */}
      <CalculatorRow
        rowType={RowType.PayoutRow}
        rowTitle={'정산 기본 금액'}
        prices={basicPayoutPrice}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
      />
      {/* 정산 차감 */}
      <CalculatorRow
        rowType={RowType.InputRow}
        rowTitle={'➖ (정산 차감)'}
        prices={extraDiscountPayoutPrice[idx]}
        changeExtraPricesHandler={changeExtraDiscountPayoutPrice}
        idx={idx}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
      />
      {/* 정산 증액 */}
      <CalculatorRow
        rowType={RowType.InputRow}
        rowTitle={'➕ (정산 증액)'}
        prices={extraPayoutPrice[idx]}
        changeExtraPricesHandler={changeExtraPayoutPrice}
        idx={idx}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
      />
      {/* 최종 정산 금액 */}
      <CalculatorRow
        rowType={RowType.FinalPayoutRow}
        rowTitle={'최종 정산 금액 (현장 결제)'}
        prices={finalPayoutPrice}
        prevFinalPrice={prevFinalPayoutPrice}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
      />
    </>
  )
}

export default React.memo(App)
