import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const GET_STORE_AND_PACKAGE = (): DocumentNode => {
  return gql`
    query GET_STORE_AND_PACKAGE($id: uuid!) {
      package(where: { id: { _eq: $id } }) {
        name
      }
      store_add_on_product_item: product_item(
        where: { sku: { _like: "%package_add_on_store%" } }
        order_by: { price: desc }
      ) {
        id
        price
        commission_rate
      }
      store_and_package(where: { package: { _eq: $id } }) {
        storeByStore {
          id
        }
      }
      stores: store(
        where: { deleted_at: { _is_null: true } }
        order_by: { addressSigunguByAddressSigungu: { address_sido: { name: asc } } }
      ) {
        id
        name
        llumar_store_name
        address_detail
        addressSigunguByAddressSigungu {
          name
          address_sido {
            name
          }
        }
        store_and_packages(where: { package: { _eq: $id } }) {
          add_on_product_item
        }
      }
      address: address_sido {
        id
        name
        address_sigungus {
          id
          name
        }
      }
    }
  `
}

export const DELETE_ALL_AND_INSERT_STORE_AND_PACKAGE = (): DocumentNode => {
  return gql`
    mutation DELETE_ALL_AND_INSERT_STORE_AND_PACKAGE(
      $packageId: uuid!
      $selectedStore: [store_and_package_insert_input!]!
    ) {
      delete_store_and_package(where: { package: { _eq: $packageId } }) {
        affected_rows
      }
      insert_store_and_package(objects: $selectedStore) {
        affected_rows
      }
    }
  `
}
