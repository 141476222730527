import { AppBar, Toolbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Lead_Status_Enum as LeadStatusEnum } from '../../assets/graphql/graphql'
import Text from '../../components/atoms/Text'
import useRootData from '../../hooks/useRootData'
import { deleteQueryString, parseQueryString } from '../../utils/utility'
import ChatInfoCompontent from './ChatInfo'
import CommentComponent from './Comment'
import styles from './index.module.scss'
import LeadInfoComponent from './LeadInfo'
import MenuComponent from './Menu'
import QuoteTabs from './QuoteTabs'

let setDueDateOnce = false
let timeOutId: NodeJS.Timeout

const App: React.FunctionComponent = () => {
  const {
    isPartTimer,
    selectedLead,
    changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility,
    leadSubscribe,
    updateDueDateByUsedCarStatus,
  } = useRootData(({ appStore, authStore, leadStore }) => ({
    isPartTimer: authStore.isPartTimer.get(),
    selectedLead: leadStore.selectedLead.get(),
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    leadSubscribe: leadStore.leadSubscribe,
    updateDueDateByUsedCarStatus: leadStore.updateDueDateByUsedCarStatus,
  }))

  const { id, fromLeadSearch } = parseQueryString()

  const [deleyRenderFlag, setDeleyRenderFlag] = useState(true)

  useEffect(() => {
    changeLoadingSpinnerVisibility(deleyRenderFlag)
  }, [deleyRenderFlag])

  useEffect(() => {
    leadSubscribe(id)

    return (): void => {
      setDueDateOnce = false
      clearTimeout(timeOutId)
    }
  }, [])

  useEffect(() => {
    if (selectedLead && !setDueDateOnce) {
      setDueDateOnce = updateDueDateByUsedCarStatus()
    }

    if (fromLeadSearch && selectedLead) {
      deleteQueryString('fromLeadSearch')
      if (
        isPartTimer &&
        selectedLead.status !== LeadStatusEnum.Duplicate &&
        selectedLead.status !== LeadStatusEnum.Missed &&
        selectedLead.status !== LeadStatusEnum.Chat &&
        selectedLead.status !== LeadStatusEnum.Lead &&
        selectedLead.status !== LeadStatusEnum.Quote
      ) {
        changeSnackbarAlertContent({ severity: 'success', content: '코멘트만 수정 가능합니다!' })
      }
    }

    clearTimeout(timeOutId)
    setDeleyRenderFlag(true)

    // Delays due to rendering issues
    timeOutId = setTimeout(() => {
      setDeleyRenderFlag(false)
    }, 1000)
  }, [selectedLead])

  if (!selectedLead) return <Text text={'DB 접근중... 잠시만 기다려주세요!!'}></Text>

  return (
    <div className={styles.container}>
      <div
        style={
          isPartTimer &&
          selectedLead.status !== LeadStatusEnum.Duplicate &&
          selectedLead.status !== LeadStatusEnum.Missed &&
          selectedLead.status !== LeadStatusEnum.Chat &&
          selectedLead.status !== LeadStatusEnum.Lead &&
          selectedLead.status !== LeadStatusEnum.Quote
            ? {
                pointerEvents: 'none',
                opacity: 0.4,
              }
            : {}
        }
      >
        <AppBar position="sticky" className={styles.appBar}>
          <Toolbar>
            <MenuComponent />
          </Toolbar>
        </AppBar>

        {selectedLead.chats && selectedLead.chats.length > 0 && <ChatInfoCompontent></ChatInfoCompontent>}
        <LeadInfoComponent />
        <QuoteTabs />
      </div>

      <CommentComponent />
    </div>
  )
}

export default React.memo(App)
