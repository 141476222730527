import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'

import { Get_Store_And_PackageQuery as GetStoreAndPackageQuery } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'
import { DELETE_ALL_AND_INSERT_STORE_AND_PACKAGE, GET_STORE_AND_PACKAGE } from './query'

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,

    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id, type } = parseQueryString()

  const [storeArrayIndex, setStoreArrayIndex] = useState([])
  const [addOnProductItem, setAddOnProductItem] = useState([])

  // local selection function
  const [shortcutOpenStatus, setShortcutOpenStatus] = useState(false)
  const [addStatus, setAddStatus] = useState('매장 추가')
  const [addressSido, setAddressSido] = useState('전체')
  const [addressSidoIndex, setAddressSidoIndex] = useState(0)
  const [addressSigungu, setAddressSigungu] = useState('전체')
  const [shortcutPrice, setShortCutPrice] = useState('')

  const [getStoreAndPackage, { data: storeAndPackageContents, error }] = useLazyQuery<GetStoreAndPackageQuery>(
    GET_STORE_AND_PACKAGE(),
    {
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    },
  )

  const stores = storeAndPackageContents ? storeAndPackageContents.stores : []

  const shortcutHandler = () => {
    const newStoreArrayIndex = stores.map((item, index) => {
      const selectStore =
        item.addressSigunguByAddressSigungu &&
        (addressSido === '전체' ||
          (addressSido === item.addressSigunguByAddressSigungu.address_sido.name &&
            (addressSigungu === '전체' || addressSigungu === item.addressSigunguByAddressSigungu.name)))

      if (selectStore) {
        return addStatus === '매장 추가'
      }

      return storeArrayIndex[index]
    })

    const newAddOnProductItem = stores.map((item, index) => {
      const selectStore =
        item.addressSigunguByAddressSigungu &&
        (addressSido === '전체' ||
          (addressSido === item.addressSigunguByAddressSigungu.address_sido.name &&
            (addressSigungu === '전체' || addressSigungu === item.addressSigunguByAddressSigungu.name)))

      if (selectStore) {
        return addStatus === '매장 추가' ? shortcutPrice : null
      }

      return addOnProductItem[index]
    })

    setStoreArrayIndex(newStoreArrayIndex)
    setAddOnProductItem(newAddOnProductItem)
    setShortcutOpenStatus(false)
    setAddStatus('매장 추가')
    setAddressSido('전체')
    setAddressSidoIndex(0)
    setAddressSigungu('전체')
    setShortCutPrice('')
    changeSnackbarAlertContent({
      severity: 'success',
      content: '적용 완료 (모든 수정이 끝나면 꼭 "저장하기" 버튼울 눌러주세요!',
    })
  }

  useEffect(() => {
    getStoreAndPackage()
  }, [])

  useEffect(() => {
    if (stores) {
      setStoreArrayIndex(
        stores.map((item) => {
          for (let i = 0; i < storeAndPackageContents.store_and_package.length; i += 1) {
            if (storeAndPackageContents.store_and_package[i].storeByStore.id === item.id) {
              return true
            }
          }

          return false
        }),
      )

      setAddOnProductItem(
        stores.map((item) => {
          if (item.store_and_packages.length > 0 && item.store_and_packages[0].add_on_product_item) {
            return item.store_and_packages[0].add_on_product_item
          }

          return null
        }),
      )
    }
  }, [storeAndPackageContents])

  if (error) {
    Sentry.captureException(error)
  }

  if (!storeAndPackageContents)
    return (
      <div className="body">
        <div className={styles.container}>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  return (
    <div className="body">
      <div className={styles.container}>
        <div className={styles.packageName}>
          [{parseInt(type, 10) - 1}종] {storeAndPackageContents.package[0].name}
        </div>

        {!shortcutOpenStatus && (
          <div>
            <button onClick={() => setShortcutOpenStatus(!shortcutOpenStatus)}>지역별로 매장 및 가격 수정하기</button>
          </div>
        )}

        {shortcutOpenStatus && (
          <div className={styles.shortcutBox}>
            <div className={styles.status}>
              {['매장 추가', '매장 삭제'].map((status, index) => {
                return (
                  <div key={index}>
                    <input
                      value={status}
                      checked={addStatus === status}
                      onChange={() => {
                        setAddStatus(status)
                      }}
                      type="radio"
                    />
                    {status}
                  </div>
                )
              })}
            </div>

            {addStatus === '매장 추가' && (
              <div>
                <div className={styles.title}>• 추가 금액 선택</div>
                <select
                  value={shortcutPrice}
                  onChange={(e): void => {
                    setShortCutPrice(e.target.value)
                  }}
                >
                  <option value={''}>추가 금액 없음</option>
                  {storeAndPackageContents.store_add_on_product_item.map((productItem, storeAddOnIndex) => {
                    return (
                      <option key={storeAddOnIndex} value={productItem.id}>
                        {`• 고객에 붙는 추가금 : ${productItem.price} • 매장에 주는 이체 추가금 : ${
                          productItem.price * (1 - productItem.commission_rate / 100)
                        }`}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <div className={styles.title}>• 시도 선택</div>
            <div className={styles.address}>
              <div>
                <input
                  value={'전체'}
                  checked={addressSido === '전체'}
                  onChange={() => {
                    setAddressSido('전체')
                  }}
                  type="radio"
                />
                {'전체'}
              </div>
              {storeAndPackageContents.address.map((item, index) => {
                return (
                  <div key={index}>
                    <input
                      value={item.name}
                      checked={item.name === addressSido}
                      onChange={() => {
                        setAddressSido(item.name)
                        setAddressSidoIndex(index)
                      }}
                      type="radio"
                    />
                    {item.name}
                  </div>
                )
              })}
            </div>
            {addressSido !== '전체' && (
              <div>
                <div className={styles.title}>• 시군구 선택</div>
                <div className={styles.address}>
                  <div>
                    <input
                      value={'전체'}
                      checked={addressSigungu === '전체'}
                      onChange={() => {
                        setAddressSigungu('전체')
                      }}
                      type="radio"
                    />
                    {'전체'}
                  </div>
                  {storeAndPackageContents.address[addressSidoIndex].address_sigungus.map((item, index) => {
                    return (
                      <div key={index}>
                        <input
                          value={item.name}
                          checked={item.name === addressSigungu}
                          onChange={() => {
                            setAddressSigungu(item.name)
                          }}
                          type="radio"
                        />
                        {item.name}
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            <div>
              <button onClick={() => shortcutHandler()}>시공 여부 적용하기</button>
            </div>
          </div>
        )}

        <button
          className={styles.button}
          onClick={async (): Promise<void> => {
            try {
              const selectedStoreAndPackage: Array<{
                store: string
                package: string
                // eslint-disable-next-line camelcase
                add_on_product_item: string | null
              }> = []

              storeArrayIndex.forEach((item, index) => {
                if (item) {
                  selectedStoreAndPackage.push({
                    store: storeAndPackageContents.stores[index].id,
                    package: id,
                    add_on_product_item: addOnProductItem[index] ? addOnProductItem[index] : null,
                  })
                }
              })

              // Delete all store_and_package table rows, including this package and then, insert selected store
              await client.mutate({
                mutation: DELETE_ALL_AND_INSERT_STORE_AND_PACKAGE(),
                variables: {
                  packageId: id,
                  selectedStore: selectedStoreAndPackage,
                },
              })

              changeSnackbarAlertContent({ severity: 'success', content: '신차패키지 매장들 업데이트 성공' })
            } catch (e) {
              changeSnackbarAlertContent({ severity: 'error', content: '저장에 실패했습니다.' })
              Sentry.captureException(e)
            }
          }}
        >
          저장하기
        </button>
        <table>
          <tbody>
            <tr>
              <th>No.</th>
              <th>시공 여부</th>
              <th>지역</th>
              <th>매장명</th>
              <th>루마 매장명</th>
              <th>지역별 추가 금액</th>
            </tr>
            {stores.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1} </td>
                  <td>
                    <input
                      type={'checkbox'}
                      checked={storeArrayIndex[index] || false}
                      onChange={(): void => {
                        const tempArray = storeArrayIndex
                        tempArray[index] = !tempArray[index]
                        setStoreArrayIndex([...tempArray])
                      }}
                    />
                  </td>
                  <td>
                    {item.addressSigunguByAddressSigungu
                      ? `${item.addressSigunguByAddressSigungu.address_sido.name} ${item.addressSigunguByAddressSigungu.name} (${item.address_detail})`
                      : ''}
                  </td>
                  <td>{item.name}</td>
                  <td>{item.llumar_store_name}</td>
                  <td>
                    <select
                      value={addOnProductItem[index] ? addOnProductItem[index] : ''}
                      onChange={(e): void => {
                        const tempArray = addOnProductItem
                        tempArray[index] = e.target.value

                        setAddOnProductItem([...tempArray])
                      }}
                    >
                      <option value={''}>추가 금액 없음</option>
                      {storeAndPackageContents.store_add_on_product_item.map((productItem, storeAddOnIndex) => {
                        return (
                          <option key={storeAddOnIndex} value={productItem.id}>
                            {`• 고객에 붙는 추가금 : ${productItem.price} • 매장에 주는 이체 추가금 : ${
                              productItem.price * (1 - productItem.commission_rate / 100)
                            }`}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
