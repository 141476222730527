// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___9hFjI {\n  padding: 20px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/AccountsPayable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___9hFjI"
};
module.exports = exports;
