import { Package, Store } from '../../assets/graphql/graphql'

export type PriceKey = 'price' | 'premium' | 'package' | 'lowest'

export type PriceObject = {
  [key: string]: number
  price: number
  premium: number
  package: number
  lowest: number
}
export type PriceUpdateObject = {
  [key: string]: number
  price?: number
  premium?: number
  package?: number
  lowest?: number
}
export type CustomProductObject = {
  id: string
  productName: string
  productPrice: PriceObject
}

export enum ProductCategory {
  FrontTintingProduct = 'front_tinting_product_items',
  SidebackTintingProduct = 'sideback_tinting_product_items',
  SideTintingProduct = 'side_tinting_product_items',
  BackTintingProduct = 'back_tinting_product_items',
  SunroofTintingProduct = 'sunroof_tinting_product_items',
  FrontFilmRemoveProduct = 'front_film_remove_product_items',
  SideFilmRemoveProduct = 'side_film_remove_product_items',
  BackFilmRemoveProduct = 'back_film_remove_product_items',
  SunroofFilmRemoveProduct = 'sunroof_film_remove_product_items',
  DashcamProduct = 'dashcam_product_items',
  CeramicCoatingProduct = 'ceramic_coating_product_items',
  PpfProduct = 'ppf_product_items',
  ConnectedProduct = 'connected_product_items',
  AuxiliaryBatteryProduct = 'auxiliary_battery_product_items',
  HipassProduct = 'hipass_product_items',
  AfterBlowProduct = 'after_blow_product_items',
  UndercoatingProduct = 'undercoating_product_items',
  LeatherCoatingProduct = 'leather_coating_product_items',
  SoundproofingProduct = 'soundproofing_product_items',
  NewCarInspectionProduct = 'new_car_inspection_product_items',
  CamoKitProduct = 'camo_kit_product_items',
  DeliveryFeeProduct = 'delivery_fee_product_items',
}
export const ProductDisplayNames = {
  [ProductCategory.FrontTintingProduct]: '썬팅 - 전면',
  [ProductCategory.SidebackTintingProduct]: '썬팅 - 측후면',
  [ProductCategory.SideTintingProduct]: '썬팅 - 측면',
  [ProductCategory.BackTintingProduct]: '썬팅 - 후면',
  [ProductCategory.SunroofTintingProduct]: '썬팅 - 썬루프',
  [ProductCategory.FrontFilmRemoveProduct]: '필름제거 - 전면',
  [ProductCategory.SideFilmRemoveProduct]: '필름제거 - 측면',
  [ProductCategory.BackFilmRemoveProduct]: '필름제거 - 후면',
  [ProductCategory.SunroofFilmRemoveProduct]: '필름제거 - 썬루프',
  [ProductCategory.DashcamProduct]: '블랙박스',
  [ProductCategory.CeramicCoatingProduct]: '유리막코팅',
  [ProductCategory.PpfProduct]: 'PPF',
  [ProductCategory.ConnectedProduct]: '커넥티드',
  [ProductCategory.AuxiliaryBatteryProduct]: '보조배터리',
  [ProductCategory.HipassProduct]: '하이패스',
  [ProductCategory.AfterBlowProduct]: '애프터블로우',
  [ProductCategory.UndercoatingProduct]: '언더코팅',
  [ProductCategory.LeatherCoatingProduct]: '가죽코팅',
  [ProductCategory.SoundproofingProduct]: '방음',
  [ProductCategory.NewCarInspectionProduct]: '신차검수',
  [ProductCategory.CamoKitProduct]: '카모키트',
  [ProductCategory.DeliveryFeeProduct]: '배송비',
}

export type SelectedProductIds = {
  [key in ProductCategory]: string
}

export type CalculatedProductPrices = {
  [key in ProductCategory]: PriceObject
}
export type CalculatedPrices = {
  productPrices: CalculatedProductPrices
  totalPrice: PriceObject
  finalPrice: PriceObject
  basicPayoutPrice: PriceObject
  finalPayoutPrice: PriceObject
  // 재료비 (우리가 먹는 수수료)
  materialPrice: PriceObject
}

export type Inventory = {
  store: string
  // eslint-disable-next-line camelcase
  caramora_id: string
  // VERTEX
  '버텍스 1100-15': string
  '버텍스 1100-30': string
  '버텍스 900-5': string
  '버텍스 900-15': string
  '버텍스 900-30': string
  '버텍스 900-40': string
  '버텍스 900-50': string
  '버텍스 900-80': string
  '버텍스 700-5': string
  '버텍스 700-15': string
  '버텍스 700-35': string
  '버텍스 700-45': string
  '버텍스 700-50': string
  '버텍스 650-10': string
  '버텍스 650-20': string
  '버텍스 650-35': string
  '버텍스 650-네이쳐': string
  '버텍스 600-15': string
  '버텍스 600-30': string
  '버텍스 500-5': string
  '버텍스 500-15': string
  '버텍스 500-35': string
  '버텍스 500-50': string
  '버텍스 300-5': string
  '버텍스 300-15': string
  '버텍스 300-35': string
  '버텍스 300-50': string
  // SEASON 2
  '비잔티-5': string
  '비잔티-15': string
  '비잔티-35': string
  '비잔티-50': string
  '래티튜드-5': string
  '래티튜드-15': string
  '래티튜드-35': string
  '래티튜드-50': string
  '파워-5': string
  '파워-15': string
  '파워-30': string
  '티엔티-10': string
  '티엔티-20': string
  '티엔티-35': string
  '유니큐-5': string
  '유니큐-15': string
  '유니큐-30': string
  '씨러스-65': string
  '씨러스-75': string
  '씨러스-80': string
  '클라쎄-20': string
  '클라쎄-30': string
  '클라쎄-35': string
  '아티젠 IB-5': string
  '아티젠 IB-10': string
  '아티젠 IB-15': string
  '아티젠 IB-35': string
  '아티젠 IB-45': string
  '슈퍼-5': string
  '슈퍼-15': string
  '슈퍼-35': string
  '슈퍼-50': string
  '프로-5': string
  '프로-15': string
  '프로-35': string
  '프로-50': string
  '버텍스-5': string
  '버텍스-15': string
  '버텍스-30': string
  '버텍스-40': string
  '버텍스-50': string
  // SEASON 1
  '피너클-5': string
  '피너클-15': string
  '피너클-35': string
  '피너클-50': string
  '스타-5': string
  '스타-15': string
  '스타-35': string
  '스타-50': string
  // 가져온 시간
  time: string
  // 매장 id
  id: string
}

export interface DefaultCommissionRate {
  /* eslint-disable camelcase */
  default_commission_rate: number
  maximum_commission_rate: number
  minimum_commission_rate: number
  maximum_discount_rate: number
  /* eslint-enable camelcase */
}

export type TypeSelectOptionsProductItemContentsKey =
  | 'front_tinting_product_items'
  | 'sideback_tinting_product_items'
  | 'side_tinting_product_items'
  | 'back_tinting_product_items'
  | 'sunroof_tinting_product_items'
  | 'front_film_remove_product_items'
  | 'side_film_remove_product_items'
  | 'back_film_remove_product_items'
  | 'sunroof_film_remove_product_items'
  | 'dashcam_product_items'
  | 'ceramic_coating_product_items'
  | 'ppf_product_items'
  | 'connected_product_items'
  | 'auxiliary_battery_product_items'
  | 'hipass_product_items'
  | 'after_blow_product_items'
  | 'undercoating_product_items'
  | 'leather_coating_product_items'
  | 'soundproofing_product_items'
  | 'new_car_inspection_product_items'
  | 'camo_kit_product_items'
  | 'delivery_fee_product_items'

export type TypeSelectOptionsAccountContentsKey = 'chat_assignees' | 'operators'

export interface CalculatorValues {
  searchStore?: string[]
  selectedStore?: Store[]
  selectedPackageObject?: Package[]
  selectedRecentPackageObject?: Package[]
  selectedProductIds?: SelectedProductIds[]
  customProducts?: CustomProductObject[][]
  floorPrice?: PriceObject[]
  ceilPrice?: PriceObject[]
  extraDiscountPrice?: PriceObject[]
  extraPrice?: PriceObject[]
  extraDiscountPayoutPrice?: PriceObject[]
  extraPayoutPrice?: PriceObject[]
  prevFinalPrice?: PriceObject[]
  prevFinalPayoutPrice?: PriceObject[]
}

/* eslint-disable camelcase */
export interface PriceByCarType {
  [key: string]: number
  korea_coupe: number
  korea_midLargeSUV: number
  korea_sedan: number
  korea_smallSUV: number
  korea_van: number
  other_coupe: number
  other_midLargeSUV: number
  other_sedan: number
  other_smallSUV: number
  other_van: number
}
