import {
  Button,
  MenuItem,
  Paper,
  Select as SelectM,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

import { Car_Sunroof_Type_Enum as CarSunroofTypeEnum } from '../../../../assets/graphql/graphql'
import Select from '../../../../components/atoms/Select'
import useRootData from '../../../../hooks/useRootData'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const {
    selectedCar,
    selectedNewCar,
    selectedFilmNeedToRemoved,
    selectedCarSunroofType,
    selectedAddressSido,
    selectedAddressSigungu,

    carModelsArray,
    carSunroofTypeArray,
    addressSidoArray,
    addressSigunguArray,

    changeSearchCarModel,
    changeSelectedCar,
    changeSelectedNewCar,
    changeSelectedFilmNeedToRemoved,
    changeSelectedCarSunroofType,
    changeSelectedAddressSido,
    changeSelectedAddressSigungu,
  } = useRootData(({ leadStore }) => ({
    selectedCar: leadStore.selectedCar.get(),
    selectedNewCar: leadStore.selectedNewCar.get(),
    selectedFilmNeedToRemoved: leadStore.selectedFilmNeedToRemoved.get(),
    selectedCarSunroofType: leadStore.selectedCarSunroofType.get(),
    selectedAddressSido: leadStore.selectedAddressSido.get(),
    selectedAddressSigungu: leadStore.selectedAddressSigungu.get(),

    carModelsArray: leadStore.carModelsArray,
    carSunroofTypeArray: leadStore.carSunroofTypeArray,
    addressSidoArray: leadStore.addressSidoArray,
    addressSigunguArray: leadStore.addressSigunguArray,

    changeSearchCarModel: leadStore.changeSearchCarModel,
    changeSelectedCar: leadStore.changeSelectedCar,
    changeSelectedNewCar: leadStore.changeSelectedNewCar,
    changeSelectedFilmNeedToRemoved: leadStore.changeSelectedFilmNeedToRemoved,
    changeSelectedCarSunroofType: leadStore.changeSelectedCarSunroofType,
    changeSelectedAddressSido: leadStore.changeSelectedAddressSido,
    changeSelectedAddressSigungu: leadStore.changeSelectedAddressSigungu,
  }))

  const [isCarModelOpen, setIsCarModelOpen] = useState(false)
  const [query, setQuery] = useState('')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      changeSearchCarModel(query)
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [query])

  return (
    <TableContainer className={styles.container} component={Paper}>
      <Table className={styles.table} size="small" aria-label="a dense table">
        <TableHead className={styles.head}>
          <TableRow className={styles.row}>
            {['차종', '신차여부', '썬루프 타입', '필름제거 여부', '지역 (시도 및 시군구)'].map((col, index) => {
              return (
                <TableCell className={styles.col} key={index}>
                  {col}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ display: 'flex', border: 'none' }}>
              <TextField
                style={{ marginRight: 10, minWidth: 150 }}
                size="small"
                variant="outlined"
                onChange={(e) => setQuery(e.target.value)}
                onKeyPress={(e) => {
                  const { key } = e
                  const handler = () => {
                    setIsCarModelOpen(true)
                  }

                  if (key === 'Enter') setTimeout(handler, 300)
                }}
              />
              <SelectM
                style={{ minWidth: 200 }}
                value={selectedCar ? (selectedCar.id as string) : ''}
                onChange={(e): void => {
                  changeSelectedCar(e.target.value as string)
                  setIsCarModelOpen(false)
                }}
                open={isCarModelOpen}
                onClick={() => setIsCarModelOpen(!isCarModelOpen)}
              >
                {carModelsArray.map((carModel, index) => (
                  <MenuItem key={index} value={carModel.value}>
                    {carModel.text}
                  </MenuItem>
                ))}
              </SelectM>
            </TableCell>
            <TableCell>
              <Select
                value={`${selectedNewCar}`}
                options={[
                  { value: 'null', text: '선택 안함' },
                  { value: 'true', text: '신차' },
                  { value: 'false', text: '재시공' },
                ]}
                changeHandler={(e): void =>
                  changeSelectedNewCar(e.target.value === 'null' ? null : e.target.value === 'true')
                }
              ></Select>
            </TableCell>
            <TableCell align="left">
              <Select
                value={selectedCarSunroofType}
                options={carSunroofTypeArray}
                disabled={carSunroofTypeArray.length === 1}
                changeHandler={(event): void => changeSelectedCarSunroofType(event.target.value as CarSunroofTypeEnum)}
              ></Select>
            </TableCell>
            <TableCell>
              <Select
                value={`${selectedFilmNeedToRemoved}`}
                options={[
                  { value: 'true', text: '필름제거 필요함' },
                  { value: 'false', text: '필요없음' },
                ]}
                changeHandler={(e): void => changeSelectedFilmNeedToRemoved(e.target.value === 'true')}
              ></Select>
            </TableCell>
            <TableCell style={{ display: 'flex', ...(selectedAddressSigungu ? {} : { border: '2px solid red' }) }}>
              <div style={{ minWidth: 140 }}>
                <ReactSelect
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  value={
                    selectedAddressSido
                      ? { value: selectedAddressSido.id, label: selectedAddressSido.name }
                      : {
                          value: '',
                          label: '',
                        }
                  }
                  onChange={({ value }) => changeSelectedAddressSido(value as string)}
                  isSearchable
                  options={addressSidoArray.map(({ text, value }) => ({
                    value,
                    label: text,
                  }))}
                />
              </div>
              <div style={{ minWidth: 140 }}>
                <ReactSelect
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  value={
                    selectedAddressSigungu
                      ? { value: selectedAddressSigungu.id, label: selectedAddressSigungu.name }
                      : {
                          value: '',
                          label: '',
                        }
                  }
                  onChange={({ value }) => changeSelectedAddressSigungu(value as string)}
                  isSearchable
                  options={addressSigunguArray.map(({ text, value }) => ({
                    value,
                    label: text || selectedAddressSido.name,
                  }))}
                />
                {!selectedAddressSigungu && <span style={{ color: 'red' }}>반드시 선택하세요!!!</span>}
              </div>
              <Button
                onClick={() =>
                  window.open(
                    `https://www.caramora.com/store?area=${
                      selectedAddressSido ? selectedAddressSido.name : ''
                    }&borough=${selectedAddressSigungu ? selectedAddressSigungu.name : ''}`,
                  )
                }
                variant={'outlined'}
              >
                시공점 찾기
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(App)
