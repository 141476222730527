import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import { Product_Type as ProductType } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'

export const GET_PRODUCT_TYPE = gql`
  query GET_PRODUCT_TYPE {
    product_type {
      value
      description
    }
  }
`

const INSERT_PRODUCT_TYPE = gql`
  mutation INSERT_PRODUCT_TYPE($productTypeObject: product_type_insert_input!) {
    insert_product_type_one(object: $productTypeObject) {
      value
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [value, setValue] = useState('')
  const [description, setDescription] = useState('')

  const [getData, { data, loading, error }] = useLazyQuery(GET_PRODUCT_TYPE, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getData()
  }, [])

  if (!data || loading)
    return (
      <div className="body">
        <div>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  if (error) {
    Sentry.captureException(error)
  }

  const buttonClickHandler = async () => {
    try {
      await client.mutate({
        mutation: INSERT_PRODUCT_TYPE,
        variables: {
          productTypeObject: {
            value,
            description,
          },
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '추가 성공' })
      getData()
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: `추가 실패\n${err}` })
      Sentry.captureException(err)
    }
  }

  return (
    <div>
      <TextField id="standard-basic" label="Value" value={value} onChange={(e) => setValue(e.target.value)}></TextField>
      <TextField
        id="standard-basic"
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></TextField>
      <Button onClick={() => buttonClickHandler()}>추가</Button>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: '#efefef' }}>
            <TableRow>
              {['value', 'description'].map((col, index) => {
                return <TableCell key={index}>{col}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.product_type.map((productType: ProductType, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>{productType.value}</TableCell>
                  <TableCell>{productType.description}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default React.memo(App)
