// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__inputContainer___1pz2u {\n  transition: box-shadow 0.3s;\n  margin: 25px 5% 25px 5%;\n  padding: 12px;\n  display: inline-block;\n  background-color: #f0efeb;\n  border: 1px solid #8d99ae;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }\n\n.index-module__inputContainer___1pz2u:hover {\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.2); }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/components/molecule/OrderVisualDateInput/index.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,uBAAuB;EACvB,aAAa;EACb,qBAAqB;EACrB,yBAAyB;EACzB,yBAAyB;EACzB,4EAA4E,EAAA;;AAG9E;EACE,2EAA2E,EAAA","file":"index.module.scss","sourcesContent":[".inputContainer {\n  transition: box-shadow 0.3s;\n  margin: 25px 5% 25px 5%;\n  padding: 12px;\n  display: inline-block;\n  background-color: #f0efeb;\n  border: 1px solid #8d99ae;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n\n.inputContainer:hover {\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.2);\n}\n"]}]);
// Exports
exports.locals = {
	"inputContainer": "index-module__inputContainer___1pz2u"
};
module.exports = exports;
