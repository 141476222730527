import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import Phonenumber from 'awesome-phonenumber'
import axios from 'axios'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Get_Partner_ProfilesQuery as GetPartnerProfilesQuery,
  Get_Partner_ProfilesQueryVariables as GetPartnerProfilesQueryVariables,
  Get_Profile_Customer_DetailQuery as GetProfileCustomerDetailQuery,
  Get_Profile_Customer_DetailQueryVariables as GetProfileCustomerDetailQueryVariables,
  Order,
  Session,
} from '../../assets/graphql/graphql'
import Cytoscape from '../../components/organism/Cytoscape'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const GET_PROFILE_CUSTOMER_DETAIL = gql`
  query GET_PROFILE_CUSTOMER_DETAIL($id: uuid!) {
    profile_customer: profile_kr_customer(where: { id: { _eq: $id } }) {
      created_at
      name
      email
      email_verified_at
      phone_mobile
      birthday
      account
      accountByAccount {
        firebase_user_uid
        account_type {
          value
        }
        orders {
          id
          status
          product_item
          car_sunroof_type
          price_final
          date_time
          carSunroofTypeByCarSunroofType {
            description
          }
          order_status {
            value
            description
          }
          storeByStore {
            name
          }
        }
        sessions(where: { activities: { rank: { _eq: 1 } } }, order_by: { created_at: desc }) {
          id
          activities(order_by: { created_at: asc }, where: { rank: { _eq: 1 } }) {
            action
            context
            rank
          }
        }
      }
    }
  }
`

const GET_PARTNER_PROFILES = gql`
  query GET_PARTNER_PROFILES($uid: String!) {
    profile_kr_partner(where: { accountByAccount: { firebase_user_uid: { _eq: $uid } } }) {
      id
    }
  }
`

const DELETE_CUSTOMER_ACCOUNT = gql`
  mutation DELETE_CUSTOMER_ACCOUNT($profileId: uuid!, $accountId: uuid!, $now: timestamptz) {
    update_profile_kr_customer_by_pk(pk_columns: { id: $profileId }, _set: { deleted_at: $now }) {
      id
    }
    update_account_by_pk(pk_columns: { id: $accountId }, _set: { deleted_at: $now }) {
      id
    }
  }
`

const DELETE_CUSTOMER_AND_PARTNER_ACCOUNT = gql`
  mutation DELETE_CUSTOMER_AND_PARTNER_ACCOUNT(
    $profileId: uuid!
    $partnerProfileId: uuid!
    $accountId: uuid!
    $now: timestamptz
  ) {
    update_profile_kr_customer_by_pk(pk_columns: { id: $profileId }, _set: { deleted_at: $now }) {
      id
    }
    update_profile_kr_partner_by_pk(pk_columns: { id: $partnerProfileId }, _set: { deleted_at: $now }) {
      id
    }
    update_account_by_pk(pk_columns: { id: $accountId }, _set: { deleted_at: $now }) {
      id
    }
  }
`

const UPDATE_CUSTOMER_NAME_PHONE_NUMBER = gql`
  mutation UPDATE_CUSTOMER_NAME_PHONE_NUMBER($profileId: uuid!, $newName: String, $newPhoneNumber: String) {
    update_profile_kr_customer_by_pk(
      pk_columns: { id: $profileId }
      _set: { name: $newName, phone_mobile: $newPhoneNumber }
    ) {
      name
      phone_mobile
    }
  }
`

// delete firebae account
const deleteFirebaseAccount = async (uid: string): Promise<void> => {
  return axios.post(`${config.backendEndPoint}/auth/delete-firebase-account`, {
    uid,
  })
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id } = parseQueryString()

  const [getData, { data, error: getProfileCustomer }] = useLazyQuery<
    GetProfileCustomerDetailQuery,
    GetProfileCustomerDetailQueryVariables
  >(GET_PROFILE_CUSTOMER_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: { id },
  })

  const [getPartnerProfile, { data: partnerProfile, error: getProfilePartner }] = useLazyQuery<
    GetPartnerProfilesQuery,
    GetPartnerProfilesQueryVariables
  >(GET_PARTNER_PROFILES, {
    fetchPolicy: 'no-cache',
    variables: { uid: data ? data.profile_customer[0].accountByAccount.firebase_user_uid : '' },
  })

  const [inputName, setInputName] = useState(null)
  const [inputPhoneNumber, setInputPhoneNumber] = useState(null)
  const [editCustomerClicked, setEditCustomerClicked] = useState(false)
  const [editDisable, setEditDisable] = useState(true)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    getPartnerProfile()
  }, [data])

  if (getProfileCustomer || getProfilePartner) {
    const error = getProfileCustomer || getProfilePartner
    Sentry.captureException(error)
  }
  if (!data) return null

  const profileCustomerDetails = data.profile_customer[0]

  const clientMutate = async () => {
    try {
      const profileQuery = UPDATE_CUSTOMER_NAME_PHONE_NUMBER
      const newName = inputName === null ? profileCustomerDetails.name : inputName
      const newPhoneNumber = inputPhoneNumber === null ? profileCustomerDetails.phone_mobile : inputPhoneNumber
      await client.mutate({
        mutation: profileQuery,
        variables: { profileId: id, newName, newPhoneNumber },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '수정되었습니다.' })
    } catch (err) {
      Sentry.captureException(err)

      changeSnackbarAlertContent({ severity: 'error', content: '수정을 실패하였습니다.' })
    }
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>
          고객 상세
          <div className={styles.editButtonArea}>
            {!editCustomerClicked && (
              <button
                className={styles.editCustomerButton}
                onClick={(): void => {
                  setEditCustomerClicked(true)
                  setEditDisable(false)
                }}
              >
                고객 정보 수정
              </button>
            )}
            {editCustomerClicked && (
              <div>
                <button
                  className={styles.button}
                  onClick={async (): Promise<void> => {
                    if (inputName || inputPhoneNumber) {
                      clientMutate()
                      setEditDisable(true)
                      setEditCustomerClicked(false)
                    } else {
                      changeSnackbarAlertContent({ severity: 'info', content: '수정을 아무것도 안하셨네요' })
                    }
                  }}
                >
                  저장하기
                </button>
              </div>
            )}
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <td>가입 날짜</td>
              <td>{moment(profileCustomerDetails.created_at).format()}</td>
            </tr>
            <tr>
              <td>가입 방법</td>
              <td>{profileCustomerDetails.accountByAccount.account_type.value}</td>
            </tr>
            <tr>
              <td>이름</td>
              <td>
                <input
                  defaultValue={profileCustomerDetails.name}
                  disabled={editDisable}
                  onChange={(changedName): void => {
                    setInputName(changedName.target.value)
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td>
                <input defaultValue={profileCustomerDetails.email} disabled={true}></input>
              </td>
            </tr>
            <tr>
              <td>이메일 인증 상태</td>
              <td>{profileCustomerDetails.email_verified_at ? '인증 완료' : '미인증'}</td>
            </tr>
            <tr>
              <td>휴대폰</td>
              <td>
                <input
                  defaultValue={
                    profileCustomerDetails.phone_mobile
                      ? new Phonenumber(profileCustomerDetails.phone_mobile).getNumber('national')
                      : ''
                  }
                  disabled={editDisable}
                  onChange={(changedPhoneNumber): void => {
                    const tempPhoneNumber = new Phonenumber(changedPhoneNumber.target.value, 'KR')
                    setInputPhoneNumber(tempPhoneNumber.getNumber('e164'))
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td rowSpan={profileCustomerDetails.accountByAccount.orders.length + 1}>시공 예약 내역</td>
            </tr>
            {profileCustomerDetails.accountByAccount.orders.map((order: Order, index: number) => {
              let packageId
              if (order.product_item.package) {
                ;[packageId] = Object.keys(order.product_item.package)
              }

              return (
                <tr key={index}>
                  <td>
                    <input defaultValue={order.order_status.description} disabled={true} />
                  </td>
                  <td>
                    <input
                      defaultValue={moment(order.date_time).format('YYYY-MM-DD (ddd) ・ hh:mm A')}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input defaultValue={order.storeByStore.name} disabled={true} />
                  </td>
                  <td>
                    <input
                      defaultValue={`[${order.product_item.tinting ? '썬팅 시공' : '패키지'}] ${
                        order.product_item.tinting && order.product_item.tinting.front ? '전면, ' : ''
                      }${order.product_item.tinting && order.product_item.tinting.sideback ? '측후면, ' : ''}${
                        order.product_item.tinting &&
                        order.product_item.tinting.sunroof &&
                        order.carSunroofTypeByCarSunroofType.description !== '없음'
                          ? order.carSunroofTypeByCarSunroofType.description
                          : ''
                      }${packageId && order.product_item.package[packageId].tinting.front ? '전면, ' : ''}${
                        packageId && order.product_item.package[packageId].tinting.sideback ? '측후면, ' : ''
                      }${packageId && order.product_item.package[packageId].ppf ? 'PPF, ' : ''}${
                        packageId && order.product_item.package[packageId].dashcam ? '블랙박스, ' : ''
                      }${packageId && order.product_item.package[packageId].ceramic_coating ? '유리막, ' : ''}
                      `}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input defaultValue={`${order.price_final}원`} disabled={true} />
                  </td>
                  <td>
                    <button
                      onClick={(): void => {
                        changeMainMenu(`/order-detail?id=${order.id}`)
                      }}
                    >
                      시공 예약 상세 보기
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <button
          className={styles.deleteAccountButton}
          onClick={async (): Promise<void> => {
            try {
              // eslint-disable-next-line no-alert
              if (window.confirm('정말 회원을 탈퇴시키겠습니까?')) {
                const accountChecker =
                  partnerProfile && partnerProfile.profile_kr_partner && partnerProfile.profile_kr_partner.length > 0
                await deleteFirebaseAccount(profileCustomerDetails.accountByAccount.firebase_user_uid)
                const mutaionObject = {
                  mutation: DELETE_CUSTOMER_ACCOUNT,
                  variables: {
                    profileId: id,
                    accountId: profileCustomerDetails.account,
                    now: new Date().toUTCString(),
                  },
                }
                const mutaionAllObject = {
                  mutation: DELETE_CUSTOMER_AND_PARTNER_ACCOUNT,
                  variables: {
                    profileId: id,
                    accountId: profileCustomerDetails.account,
                    now: new Date().toUTCString(),
                    partnerProfileId: accountChecker ? partnerProfile.profile_kr_partner[0].id : '',
                  },
                }
                await client.mutate(accountChecker ? mutaionAllObject : mutaionObject)

                changeSnackbarAlertContent({ severity: 'success', content: '회원 탈퇴를 성공적으로 완료했습니다.' })
                changeMainMenu('/profile-customer')
              } else {
                changeSnackbarAlertContent({ severity: 'info', content: '취소했습니다.' })
              }
            } catch (err) {
              Sentry.captureException(err)

              changeSnackbarAlertContent({ severity: 'error', content: '회원 탈퇴를 실패했습니다.' })
            }
          }}
        >
          회원 탈퇴
        </button>
        <Cytoscape list={profileCustomerDetails.accountByAccount.sessions as Session[]} />
      </div>
    </div>
  )
}
export default React.memo(App)
