// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container____Mzkd {\n  padding-top: 20px; }\n  .index-module__container____Mzkd .index-module__deleteAccountButton___DXuSI {\n    margin: 20px 0; }\n  .index-module__container____Mzkd .index-module__editButtonArea___1cGfo {\n    text-align: right;\n    margin-right: 50px; }\n    .index-module__container____Mzkd .index-module__editButtonArea___1cGfo .index-module__cancelButton___2JFQv {\n      margin-left: 10px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/ProfileCustomerDetail/index.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAA;EADnB;IAGI,cAAc,EAAA;EAHlB;IAMI,iBAAiB;IACjB,kBAAkB,EAAA;IAPtB;MASM,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding-top: 20px;\n  .deleteAccountButton {\n    margin: 20px 0;\n  }\n  .editButtonArea {\n    text-align: right;\n    margin-right: 50px;\n    .cancelButton {\n      margin-left: 10px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container____Mzkd",
	"deleteAccountButton": "index-module__deleteAccountButton___DXuSI",
	"editButtonArea": "index-module__editButtonArea___1cGfo",
	"cancelButton": "index-module__cancelButton___2JFQv"
};
module.exports = exports;
