import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { Get_HashtagQuery as GetHashtagQuery, Hashtag } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import styles from './index.module.scss'

interface TypeHashtag {
  name: string
  account: string
}

const GET_HASHTAG = gql`
  query GET_HASHTAG {
    hashtags: hashtag(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
      id
      name
      account
    }
  }
`

const INSERT_HASHTAG = gql`
  mutation INSERT_HASHTAG($newHashtagObject: [hashtag_insert_input!]!) {
    insert_hashtag(objects: $newHashtagObject) {
      affected_rows
    }
  }
`

const UPDATE_HASHTAG = gql`
  mutation UPDATE_HASHTAG($id: uuid!, $newHashtagName: String!) {
    update_hashtag(where: { id: { _eq: $id } }, _set: { name: $newHashtagName }) {
      affected_rows
    }
  }
`

// update deleted_at column
const DELETE_HASHTAG = gql`
  mutation DELETE_HASHTAG($id: uuid!, $deletedAt: timestamptz!) {
    update_hashtag(where: { id: { _eq: $id } }, _set: { deleted_at: $deletedAt }) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { accountId, changeSnackbarAlertContent } = useRootData(({ authStore, appStore }) => ({
    accountId: authStore.accountId.get(),
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [hashtagArray, setHashtagArray] = useState<Array<Hashtag>>()
  const [newHashtagObject, setNewHashtagObject] = useState<TypeHashtag>({ name: '', account: '' })

  const [getData, { data: hashtagContents, error }] = useLazyQuery<GetHashtagQuery>(GET_HASHTAG, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (hashtagContents) {
      setHashtagArray(hashtagContents.hashtags as Hashtag[])
    }
  }, [hashtagContents])

  if (error) {
    Sentry.captureException(error)
  }
  if (!hashtagArray) return null

  return (
    <div className="body">
      <div className={styles.container}>
        <div>해쉬태그 목록</div>
        <br />

        <div>추가할 해쉬태그를 입력해주세요</div>

        <input
          placeholder={'ex) 10년노하우'}
          value={newHashtagObject.name}
          onChange={(e): void => {
            setNewHashtagObject({ name: e.target.value, account: accountId })
          }}
        />

        <button
          className={styles.addButton}
          onClick={async (): Promise<void> => {
            try {
              await client.mutate({
                mutation: INSERT_HASHTAG,
                variables: { newHashtagObject },
              })

              getData()

              changeSnackbarAlertContent({ severity: 'success', content: '해쉬태그 추가 완료!' })
            } catch (err) {
              Sentry.captureException(err)
              changeSnackbarAlertContent({ severity: 'error', content: '해쉬태그 추가 실패!' })
            }
          }}
        >
          해쉬태그 추가
        </button>

        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>uuid</th>
              <th className={styles.hashtagName}>해쉬태그 종류</th>
              <th />
            </tr>
            {hashtagArray.map((item: Hashtag, index: number) => (
              <tr key={index}>
                <td>{hashtagArray.length - index}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>
                  <button
                    onClick={async (): Promise<void> => {
                      try {
                        // eslint-disable-next-line no-alert
                        const newHashtagName = prompt(
                          `'${item.name}'에 대하여 수정하고 있습니다. \n 수정할 이름를 입력해주세요.`,
                          '',
                        )

                        if (newHashtagName) {
                          await client.mutate({
                            mutation: UPDATE_HASHTAG,
                            variables: { id: item.id, newHashtagName },
                          })

                          getData()
                        }
                      } catch (err) {
                        Sentry.captureException(err)

                        changeSnackbarAlertContent({ severity: 'error', content: `해쉬태그 수정 실패 \n ${err}` })
                      }
                    }}
                  >
                    수정하기
                  </button>
                  <button
                    onClick={async (): Promise<void> => {
                      try {
                        const timeStamp = moment().utc().format()

                        // eslint-disable-next-line no-alert
                        const deleteHashtag = window.confirm(
                          `  모든 매장에 대한 해쉬태그 '${item.name}'가 삭제됩니다. \n 삭제하시겠습니까?`,
                        )

                        if (deleteHashtag) {
                          await client.mutate({
                            mutation: DELETE_HASHTAG,
                            variables: { id: item.id, deletedAt: timeStamp },
                          })

                          getData()
                        }
                      } catch (err) {
                        Sentry.captureException(err)
                        changeSnackbarAlertContent({ severity: 'error', content: `해쉬태그 삭제 실패 \n ${err}` })
                      }
                    }}
                  >
                    삭제하기
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
