// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3IldU {\n  padding: 20px;\n  margin-bottom: 40px; }\n  .index-module__container___3IldU .index-module__addButton___2GKaP {\n    font-weight: bold;\n    margin: 20px 0px 40px 20px; }\n  .index-module__container___3IldU .index-module__number___1NnaY {\n    width: 30px; }\n  .index-module__container___3IldU .index-module__uuid___lpl6m {\n    width: 300px; }\n  .index-module__container___3IldU .index-module__hashtagName___1z87g {\n    width: 200px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/Hashtag/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAII,iBAAiB;IACjB,0BAA0B,EAAA;EAL9B;IAQI,WAAW,EAAA;EARf;IAWI,YAAY,EAAA;EAXhB;IAcI,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  margin-bottom: 40px;\n  .addButton {\n    font-weight: bold;\n    margin: 20px 0px 40px 20px;\n  }\n  .number {\n    width: 30px;\n  }\n  .uuid {\n    width: 300px;\n  }\n  .hashtagName {\n    width: 200px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3IldU",
	"addButton": "index-module__addButton___2GKaP",
	"number": "index-module__number___1NnaY",
	"uuid": "index-module__uuid___lpl6m",
	"hashtagName": "index-module__hashtagName___1z87g"
};
module.exports = exports;
