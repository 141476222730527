import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React from 'react'

import {
  Get_Product_ItemsQuery as GetProductItemsQuery,
  Product_Item as ProductItem,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

const GET_PRODUCT_ITEMS = gql`
  query GET_PRODUCT_ITEMS {
    productItems: product_item(order_by: { sku: asc }) {
      id
      sku
      price
      commission_rate
      productByProduct {
        name
        brand
        type
      }
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeLoadingSpinnerVisibility } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
  }))

  const { data, loading, error } = useQuery<GetProductItemsQuery>(GET_PRODUCT_ITEMS, {
    fetchPolicy: 'no-cache',
  })

  if (loading) {
    changeLoadingSpinnerVisibility(true)

    return null
  }

  changeLoadingSpinnerVisibility(false)

  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>SKU 목록</div>
        <br />

        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>uuid</th>
              <th className={styles.headerText}>타입</th>
              <th className={styles.headerText}>브랜드</th>
              <th className={styles.headerText}>제품명</th>
              <th className={styles.headerText}>SKU</th>
              <th className={styles.headerText}>가격</th>
              <th className={styles.headerText}>수수료</th>
              <th></th>
            </tr>
            {data.productItems.map((item: ProductItem, index: number) => (
              <tr key={index}>
                <td>
                  <input defaultValue={data.productItems.length - index} disabled={true} />
                </td>
                <td>
                  <input defaultValue={item.id} disabled={true} />
                </td>
                <td>
                  <input defaultValue={item.productByProduct.type} disabled={true} />
                </td>
                <td>
                  <input defaultValue={item.productByProduct.brand} disabled={true} />
                </td>
                <td>
                  <input defaultValue={item.productByProduct.name} disabled={true} />
                </td>
                <td>
                  <input defaultValue={item.sku} disabled={true} />
                </td>
                <td>
                  <input defaultValue={item.price} disabled={true} />
                </td>
                <td>
                  <input defaultValue={item.commission_rate} disabled={true} />
                </td>
                <td>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/product-item-detail?id=${item.id}`)
                    }}
                  >
                    수정
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
