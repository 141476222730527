import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'
import React from 'react'

import { Review, Review_Status_Enum as ReviewStatusEnum } from '../../../assets/graphql/graphql'
import Buttons from '../ButtonsComponent'

interface PropsReviewRow {
  index: number
  length: number
  review: Review
  isSelected: (id: string) => boolean
  clickHandler: {
    checkboxClickHandler: (id: string) => void
    updateReviewStatusClickHandler: (status: ReviewStatusEnum, data: Review) => Promise<void>
  }
}

const App: React.FunctionComponent<PropsReviewRow> = ({
  index,
  length,
  review,
  isSelected,
  clickHandler,
}: PropsReviewRow) => {
  const {
    id,
    review_status: status,
    rating,
    comment,
    store_and_reviews: storeAndReviews,
    order_and_reviews: orderAndReviews,
    created_at: createdAt,
  } = review
  const { checkboxClickHandler, updateReviewStatusClickHandler } = clickHandler

  const isItemSelected = isSelected(id)
  const labelId = `enhanced-table-checkbox-${index}`

  return (
    <TableRow key={id} role="checkbox" aria-checked={isItemSelected} onClick={() => checkboxClickHandler(id)}>
      <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell>{length - index}</TableCell>
      <TableCell style={{ maxWidth: 100 }}>{moment(createdAt).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
      <TableCell style={{ maxWidth: 100 }}>{id}</TableCell>
      <TableCell>{status.description}</TableCell>
      <TableCell>{storeAndReviews[0].storeByStore.name}</TableCell>
      <TableCell>
        {new PhoneNumber(
          orderAndReviews[0]?.orderByOrder?.leadByLead?.phone || orderAndReviews[0]?.orderByOrder?.phone,
          'KR',
        ).getNumber('national')}
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>{rating}</TableCell>
      <TableCell style={{ maxWidth: 500 }}>{comment}</TableCell>
      <TableCell style={{ textAlign: 'center', display: 'grid' }}>
        <Buttons review={review} clickHandler={updateReviewStatusClickHandler} />
      </TableCell>
    </TableRow>
  )
}

export default React.memo(App)
