import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import Select from '../../../../components/atoms/Select'
import useRootData from '../../../../hooks/useRootData'
import { LEAD_COL } from '../../../../stores/lead/type'
import { getLeadColValueByKey } from '../../../../utils/utility'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const {
    isPartTimer,
    accountId,
    selectedLead,
    selectedSupportAssignee,
    selectedMatchMaker,
    operatorArray,

    changeSelectedSupportAssignee,
    changeSelectedMatchMaker,
  } = useRootData(({ authStore, leadStore }) => ({
    isPartTimer: authStore.isPartTimer.get(),
    accountId: authStore.accountId.get(),
    selectedLead: leadStore.selectedLead.get(),
    selectedSupportAssignee: leadStore.selectedSupportAssignee.get(),
    selectedMatchMaker: leadStore.selectedMatchMaker.get(),
    operatorArray: leadStore.operatorArray,

    changeSelectedSupportAssignee: leadStore.changeSelectedSupportAssignee,
    changeSelectedMatchMaker: leadStore.changeSelectedMatchMaker,
  }))

  return (
    <TableContainer className={styles.container} component={Paper}>
      <Table className={styles.table} size="small" aria-label="a dense table">
        <TableHead className={styles.head}>
          <TableRow className={styles.row}>
            {[
              LEAD_COL.LEAD_ID,
              LEAD_COL.SUPPORT_ASSIGNEE,
              LEAD_COL.MATCH_MAKER,
              LEAD_COL.CREATED_AT,
              LEAD_COL.UPDATED_AT,
              LEAD_COL.STATUS,
            ].map((col, index) => {
              return (
                <TableCell className={styles.col} key={index}>
                  {col}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {[
              LEAD_COL.LEAD_ID,
              LEAD_COL.SUPPORT_ASSIGNEE,
              LEAD_COL.MATCH_MAKER,
              LEAD_COL.CREATED_AT,
              LEAD_COL.UPDATED_AT,
              LEAD_COL.STATUS,
            ].map((col, index) => {
              if (col === LEAD_COL.SUPPORT_ASSIGNEE) {
                return (
                  <TableCell key={index}>
                    <div style={{ display: 'flex' }}>
                      <Select
                        disabled={isPartTimer}
                        value={selectedSupportAssignee ? selectedSupportAssignee.id : ''}
                        options={operatorArray}
                        changeHandler={(event): void => changeSelectedSupportAssignee(event.target.value as string)}
                      />
                      {!isPartTimer && (
                        <Button variant="contained" onClick={() => changeSelectedSupportAssignee(accountId)}>
                          {'본인'}
                        </Button>
                      )}
                    </div>
                  </TableCell>
                )
              }
              if (col === LEAD_COL.MATCH_MAKER) {
                return (
                  <TableCell key={index}>
                    <div style={{ display: 'flex' }}>
                      <Select
                        disabled={isPartTimer}
                        value={selectedMatchMaker ? selectedMatchMaker.id : ''}
                        options={operatorArray}
                        changeHandler={(event): void => changeSelectedMatchMaker(event.target.value as string)}
                      />
                      {!isPartTimer && (
                        <Button variant="contained" onClick={() => changeSelectedMatchMaker(accountId)}>
                          {'본인'}
                        </Button>
                      )}
                    </div>
                  </TableCell>
                )
              }

              return <TableCell key={index}>{getLeadColValueByKey(selectedLead, col)}</TableCell>
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(App)
