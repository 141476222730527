import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import React from 'react'

import { RemarkValuesStructure } from '../index'

interface PriceSectionProps {
  productRemark?: string
  dateTimeRemark?: string
  priceRemark?: string
  customerRemark?: string
  setRemarkValues: (object: RemarkValuesStructure) => void
}

const App: React.FunctionComponent<PriceSectionProps> = ({
  productRemark,
  dateTimeRemark,
  priceRemark,
  customerRemark,
  setRemarkValues,
}: PriceSectionProps) => {
  return (
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: 'gainsboro' }}>
        <TableRow>
          <TableCell>시공 제품 비고란</TableCell>
          <TableCell>예상 시공일 비고란</TableCell>
          <TableCell>예상 견적가 비고란</TableCell>
          <TableCell>고객한테 보이는 비고란</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <TextField
              label="시공 제품 비고란"
              multiline
              rows={6}
              value={productRemark}
              onChange={(e) => setRemarkValues({ productRemark: e.target.value })}
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <TextField
              label="예상 시공일 비고란"
              multiline
              rows={6}
              value={dateTimeRemark}
              onChange={(e) => setRemarkValues({ dateTimeRemark: e.target.value })}
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <TextField
              label="예상 견적가 비고란"
              multiline
              rows={6}
              value={priceRemark}
              onChange={(e) => setRemarkValues({ priceRemark: e.target.value })}
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <TextField
              label="고객한테 보이는 비고란"
              multiline
              rows={6}
              value={customerRemark}
              onChange={(e) => setRemarkValues({ customerRemark: e.target.value })}
              variant="outlined"
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
