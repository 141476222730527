import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Account_Performance as AccountPerformance,
  Account_Throughput as AccountThrouput,
  Lead_Status_Enum as LeadStatusEnum,
  Profile_Riderdash_Role_Enum as ProfileRiderdashRoleEnum,
} from '../../../assets/graphql/graphql'
import ExportLeadButton from '../../../components/molecule/ExportLeadButton'
import config from '../../../configs'
import useRootData from '../../../hooks/useRootData'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const { sendAxios } = useRootData(({ appStore }) => ({
    sendAxios: appStore.sendAxios,
  }))
  const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const startDateTime = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD 15:00:00')

  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const endDateTime = moment(endDate).format('YYYY-MM-DD 14:59:59')

  const [accountThroughput, setAccountThroughput] = useState(null)

  useEffect(() => {
    ;(async () => {
      const { data } =
        (await sendAxios.get(`${config.backendEndPoint}/admin/get-account-throughput`, {
          params: {
            startDateTime,
            endDateTime,
          },
        })) || {}

      if (!data) {
        setStartDate(moment(new Date()).format('YYYY-MM-DD'))
        setEndDate(moment(new Date()).format('YYYY-MM-DD'))
      }

      setAccountThroughput(data)
    })()
  }, [startDateTime, endDateTime])

  const [accountPerformanceForChat, setAccountPerformanceForChat] = useState(null)
  const [accountPerformanceForSupport, setAccountPerformanceForSupport] = useState(null)
  const [accountPerformanceForMatch, setAccountPerformanceForMatch] = useState(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let chat: any = { sum: { [LeadStatusEnum.Chat]: 0, others: 0, total: 0 } }
    // eslint-disable-next-line camelcase
    if (accountThroughput?.get_account_performance_for_chat) {
      // For base
      accountThroughput.get_account_performance_for_chat
        .filter((item: AccountPerformance) => item.role === ProfileRiderdashRoleEnum.Parttimer)
        .forEach((item: AccountPerformance) => {
          const { email, name, role } = item
          chat = { ...chat, [item.account]: { email, name, role, [LeadStatusEnum.Chat]: 0, others: 0, total: 0 } }
        })
      // For chat
      accountThroughput.get_account_performance_for_chat
        .filter((item: AccountPerformance) => item.role === ProfileRiderdashRoleEnum.Parttimer)
        .filter((item: AccountPerformance) => item.status === LeadStatusEnum.Chat)
        .forEach((item: AccountPerformance) => {
          chat = {
            ...chat,
            [item.account]: {
              ...chat[item.account],
              [LeadStatusEnum.Chat]: item.count,
              total: item.count,
            },
            sum: {
              ...chat.sum,
              [LeadStatusEnum.Chat]: chat.sum[LeadStatusEnum.Chat] + item.count,
              total: chat.sum.total + item.count,
            },
          }
        })
      // For others
      accountThroughput.get_account_performance_for_chat
        .filter((item: AccountPerformance) => item.role === ProfileRiderdashRoleEnum.Parttimer)
        .filter((item: AccountPerformance) => item.status !== LeadStatusEnum.Chat)
        .forEach((item: AccountPerformance) => {
          chat = {
            ...chat,
            [item.account]: {
              ...chat[item.account],
              others: chat[item.account].others + item.count,
              total: chat[item.account].total + item.count,
            },
            sum: {
              ...chat.sum,
              others: chat.sum.others + item.count,
              total: chat.sum.total + item.count,
            },
          }
        })

      setAccountPerformanceForChat(chat)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let support: any = { sum: { good: 0, bad: 0, total: 0 } }
    // eslint-disable-next-line camelcase
    if (accountThroughput?.get_account_performance_for_support) {
      // For base
      accountThroughput.get_account_performance_for_support
        .filter(
          (item: AccountPerformance) =>
            item.role === ProfileRiderdashRoleEnum.User || item.role === ProfileRiderdashRoleEnum.Administrator,
        )
        .forEach((item: AccountPerformance) => {
          const { email, name, role } = item
          support = { ...support, [item.account]: { email, name, role, good: 0, bad: 0, total: 0 } }
        })
      // For others
      accountThroughput.get_account_performance_for_support
        .filter(
          (item: AccountPerformance) =>
            item.role === ProfileRiderdashRoleEnum.User || item.role === ProfileRiderdashRoleEnum.Administrator,
        )
        .forEach((item: AccountPerformance) => {
          let bad = 0
          let good = 0

          if (
            item.status === LeadStatusEnum.RsvConfirmed ||
            item.status === LeadStatusEnum.PaymentPending ||
            item.status === LeadStatusEnum.VatRequested ||
            item.status === LeadStatusEnum.SettlementPending ||
            item.status === LeadStatusEnum.Done ||
            item.status === LeadStatusEnum.Refunded ||
            item.status === LeadStatusEnum.Cancel
          ) {
            good = item.count
          } else {
            bad = item.count
          }

          support = {
            ...support,
            [item.account]: {
              ...support[item.account],
              good: support[item.account].good + good,
              bad: support[item.account].bad + bad,
              total: support[item.account].total + item.count,
            },
            sum: {
              good: support.sum.good + good,
              bad: support.sum.bad + bad,
              total: support.sum.total + item.count,
            },
          }
        })

      setAccountPerformanceForSupport(support)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let match: any = { sum: { good: 0, bad: 0, total: 0 } }
    // eslint-disable-next-line camelcase
    if (accountThroughput?.get_account_performance_for_match) {
      // For base
      accountThroughput.get_account_performance_for_match
        .filter(
          (item: AccountPerformance) =>
            item.role === ProfileRiderdashRoleEnum.User || item.role === ProfileRiderdashRoleEnum.Administrator,
        )
        .forEach((item: AccountPerformance) => {
          const { email, name, role } = item
          match = { ...match, [item.account]: { email, name, role, good: 0, bad: 0, total: 0 } }
        })
      // For others
      accountThroughput.get_account_performance_for_match
        .filter(
          (item: AccountPerformance) =>
            item.role === ProfileRiderdashRoleEnum.User || item.role === ProfileRiderdashRoleEnum.Administrator,
        )
        .forEach((item: AccountPerformance) => {
          let bad = 0
          let good = 0

          if (
            item.status === LeadStatusEnum.RsvConfirmed ||
            item.status === LeadStatusEnum.PaymentPending ||
            item.status === LeadStatusEnum.VatRequested ||
            item.status === LeadStatusEnum.SettlementPending ||
            item.status === LeadStatusEnum.Done ||
            item.status === LeadStatusEnum.Refunded ||
            item.status === LeadStatusEnum.Cancel
          ) {
            good = item.count
          } else {
            bad = item.count
          }

          match = {
            ...match,
            [item.account]: {
              ...match[item.account],
              good: match[item.account].good + good,
              bad: match[item.account].bad + bad,
              total: match[item.account].total + item.count,
            },
            sum: {
              good: match.sum.good + good,
              bad: match.sum.bad + bad,
              total: match.sum.total + item.count,
            },
          }
        })

      setAccountPerformanceForMatch(match)
    }
  }, [accountThroughput])

  return (
    accountThroughput &&
    accountPerformanceForChat && (
      <div>
        <div className={styles.alertText}>한국 시간 기준!!!</div>
        <ExportLeadButton></ExportLeadButton>
        <div className={styles.date}>
          <div className={styles.shortcut}>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).subtract(1, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).subtract(1, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3c;
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(new Date()).format('YYYY-MM-DD'))
                setEndDate(moment(new Date()).format('YYYY-MM-DD'))
              }}
            >
              오늘로
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).add(1, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).add(1, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3e;
            </Button>
          </div>
          <div className={styles.shortcut}>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).subtract(7, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).subtract(7, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3c;&#x3c;
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                const isFridayAndLater = moment(new Date()).day() >= 5 ? 7 : 0
                setStartDate(
                  moment(new Date())
                    .day(-2 + isFridayAndLater)
                    .format('YYYY-MM-DD'),
                )
                setEndDate(
                  moment(new Date())
                    .day(4 + isFridayAndLater)
                    .format('YYYY-MM-DD'),
                )
              }}
            >
              이번 스프린트
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).add(7, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).add(7, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3e;&#x3e;
            </Button>
          </div>
          <div className={styles.startDate}>
            <div className={styles.startText}>시작일</div>
            <TextField
              type="date"
              value={startDate}
              onChange={(event): void => {
                setStartDate(event.target.value)
              }}
            />
          </div>
          <div className={styles.endDate}>
            <div className={styles.endText}>종료일</div>
            <TextField
              type="date"
              value={endDate}
              onChange={(event): void => {
                setEndDate(event.target.value)
              }}
            />
          </div>
        </div>
        <div className={styles.tableTitle}>처리 개수</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>name</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountThroughput.get_account_throughput
                ?.sort((x: AccountThrouput, y: AccountThrouput) => y.count - x.count)
                .map((item: AccountThrouput, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={styles.cell}>{item.name}</TableCell>
                    <TableCell className={styles.cell}>{item.email}</TableCell>
                    <TableCell className={styles.cell}>{item.role}</TableCell>
                    <TableCell className={styles.cell}>{item.count}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <div className={styles.tableTitle}>채팅 처리 현황 (created_at 기준)</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>chat assignee</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>total</TableCell>
                <TableCell className={styles.header}>Lead 이후 비율</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(accountPerformanceForChat)
                .filter((item) => item !== 'sum')
                .sort((x: string, y: string) => accountPerformanceForChat[y].total - accountPerformanceForChat[x].total)
                .map((item: string, index: number) => {
                  const successRate =
                    (accountPerformanceForChat[item].others / accountPerformanceForChat[item].total) * 100

                  return (
                    <TableRow key={index}>
                      <TableCell className={styles.cell}>{accountPerformanceForChat[item].name}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForChat[item].email}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForChat[item].role}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForChat[item].total}</TableCell>
                      <TableCell
                        className={styles.cell}
                        style={{ color: successRate <= 35 ? 'red' : 'rgba(0, 0, 0, 0.87)' }}
                      >
                        {accountPerformanceForChat[item].others} ({successRate.toFixed(2)}%)
                      </TableCell>
                    </TableRow>
                  )
                })}
              <TableRow>
                <TableCell className={styles.cell} colSpan={2}></TableCell>
                <TableCell className={styles.cell}>합계</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForChat?.sum?.total}</TableCell>
                <TableCell className={styles.cell}>
                  {accountPerformanceForChat?.sum?.others} (
                  {((accountPerformanceForChat?.sum?.others / accountPerformanceForChat?.sum?.total) * 100).toFixed(2)}%
                  )
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={styles.tableTitle}>고객 상담사 처리 현황 (updated_at 기준)</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>support assignee</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>total</TableCell>
                <TableCell className={styles.header}>reservation confirmed 이후 비율(환불과 취소 포함)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(accountPerformanceForSupport)
                .filter((item) => item !== 'sum')
                .sort(
                  (x: string, y: string) =>
                    accountPerformanceForSupport[y].total - accountPerformanceForSupport[x].total,
                )
                .map((item: string, index: number) => {
                  const successRate =
                    (accountPerformanceForSupport[item].good / accountPerformanceForSupport[item].total) * 100

                  return (
                    <TableRow key={index}>
                      <TableCell className={styles.cell}>{accountPerformanceForSupport[item].name}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForSupport[item].email}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForSupport[item].role}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForSupport[item].total}</TableCell>
                      <TableCell
                        className={styles.cell}
                        style={{ color: successRate <= 9 ? 'red' : 'rgba(0, 0, 0, 0.87)' }}
                      >
                        {accountPerformanceForSupport[item].good} ({successRate.toFixed(2)}%)
                      </TableCell>
                    </TableRow>
                  )
                })}
              <TableRow>
                <TableCell className={styles.cell} colSpan={2}></TableCell>
                <TableCell className={styles.cell}>합계</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForSupport?.sum?.total}</TableCell>
                <TableCell className={styles.cell}>
                  {accountPerformanceForSupport?.sum?.good} (
                  {((accountPerformanceForSupport?.sum?.good / accountPerformanceForSupport?.sum?.total) * 100).toFixed(
                    2,
                  )}
                  % )
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={styles.tableTitle}>매장 상담사 처리 현황 (updated_at 기준)</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>match maker</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>total</TableCell>
                <TableCell className={styles.header}>reservation confirmed 이후 비율(환불과 취소 포함)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(accountPerformanceForMatch)
                .filter((item) => item !== 'sum')
                .sort(
                  (x: string, y: string) => accountPerformanceForMatch[y].total - accountPerformanceForMatch[x].total,
                )
                .map((item: string, index: number) => {
                  const successRate =
                    (accountPerformanceForMatch[item].good / accountPerformanceForMatch[item].total) * 100

                  return (
                    <TableRow key={index}>
                      <TableCell className={styles.cell}>{accountPerformanceForMatch[item].name}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForMatch[item].email}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForMatch[item].role}</TableCell>
                      <TableCell className={styles.cell}>{accountPerformanceForMatch[item].total}</TableCell>
                      <TableCell
                        className={styles.cell}
                        style={{ color: successRate <= 30 ? 'red' : 'rgba(0, 0, 0, 0.87)' }}
                      >
                        {accountPerformanceForMatch[item].good} ({successRate.toFixed(2)}%)
                      </TableCell>
                    </TableRow>
                  )
                })}
              <TableRow>
                <TableCell className={styles.cell} colSpan={2}></TableCell>
                <TableCell className={styles.cell}>합계</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForMatch?.sum?.total}</TableCell>
                <TableCell className={styles.cell}>
                  {accountPerformanceForMatch?.sum?.good} (
                  {((accountPerformanceForMatch?.sum?.good / accountPerformanceForMatch?.sum?.total) * 100).toFixed(2)}%
                  )
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    )
  )
}
export default React.memo(App)
