import React, { useEffect } from 'react'

import stylesDesktopDefault from './DesktopDefault.module.scss'
import stylesMobileDefault from './MobileDefault.module.scss'

export interface PropsSpinner {
  screenClass: string
  children?: React.ReactNode
}

const App: React.FunctionComponent<PropsSpinner> = ({ screenClass, children }: PropsSpinner) => {
  const styles = screenClass === 'xl' ? stylesDesktopDefault : stylesMobileDefault

  const modalOpenClass = styles.scrollNone.toString()

  useEffect(() => {
    document.body.classList.add(modalOpenClass)

    return (): void => {
      document.body.classList.remove(modalOpenClass)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.half}>
        <div className={styles.skChase}>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default React.memo(App)
