/* eslint-disable no-underscore-dangle */
import gql from 'graphql-tag'

export const _GET_PACKAGE_CONTENTS = gql`
  query _GET_PACKAGE_CONTENTS($packageId: uuid!) {
    package(where: { id: { _eq: $packageId } }) {
      id
      name
      price_discount
      price_payout
      price_product
      product
    }
    car_type {
      value
      description
    }
    car_origin(where: { _or: [{ name_us: { _eq: "korea" } }, { name_us: { _eq: "other" } }] }) {
      name_kr
      name_us
    }
  }
`

export const _UPDATE_PACKAGE_BY_PK = gql`
  mutation _UPDATE_PACKAGE_BY_PK(
    $id: uuid!
    $priceDiscount: jsonb
    $pricePayout: jsonb
    $priceProduct: jsonb
    $product: jsonb
  ) {
    update_package_by_pk(
      pk_columns: { id: $id }
      _set: {
        price_discount: $priceDiscount
        price_payout: $pricePayout
        price_product: $priceProduct
        product: $product
      }
    ) {
      id
      price_discount
      price_payout
      price_product
      product
    }
  }
`
