import React from 'react'

export interface PropsText {
  style?: React.CSSProperties
  className?: string
  text: string
  id?: string
  disabled?: boolean
  clickHandler?: () => void
}

const buttonStyle: React.CSSProperties = {
  cursor: 'pointer',
}

const defaultStyle: React.CSSProperties = {
  fontFamily: 'NotoSansKR, AppleSDGothicNeo, sans-serif',
}

const disableStyle: React.CSSProperties = {
  opacity: '0.3',
}

const App: React.FunctionComponent<PropsText> = ({ style, className, text, id, disabled, clickHandler }: PropsText) => {
  const clickStyle = disabled
    ? { ...disableStyle, ...style, ...defaultStyle }
    : { ...buttonStyle, ...style, ...defaultStyle }
  const newStyle = clickHandler ? clickStyle : { ...style, ...defaultStyle }

  return (
    <div
      id={id}
      style={newStyle}
      className={className}
      onClick={() => {
        if (clickHandler) clickHandler()
      }}
    >
      {text || <br />}
    </div>
  )
}

export default React.memo(App)
