/* eslint-disable camelcase */
import { useLazyQuery } from '@apollo/react-hooks'
import { Button } from '@material-ui/core'
import gql from 'graphql-tag'
import React from 'react'
import { CSVLink } from 'react-csv'

import { Lead } from '../../../assets/graphql/graphql'

const headers = [
  { label: 'uuid', key: 'id' },
  { label: 'leadId', key: 'key' },
  { label: '생성일자', key: 'created_at' },
  { label: '출처', key: 'created_from' },
  { label: 'utm_term', key: 'utm_term' },
  { label: 'utm_medium', key: 'utm_medium' },
  { label: 'utm_source', key: 'utm_source' },
  { label: 'utm_content', key: 'utm_content' },
  { label: 'utm_campaign', key: 'utm_campaign' },
  { label: 'status', key: 'status' },
]

const GET_LEAD_WITH_UTM = gql`
  query GET_LEAD_WITH_UTM {
    lead(where: { lead_at: { _is_null: false }, utm: { _neq: {} } }, order_by: { created_at: desc }) {
      id
      key
      created_at
      utm
      status
      created_from
    }
  }
`

const ExportLeadButton: React.FunctionComponent = () => {
  const [getData, { data: leadData }] = useLazyQuery(GET_LEAD_WITH_UTM, {
    fetchPolicy: 'no-cache',
  })

  return (
    <div>
      <Button variant="contained" onClick={(): void => getData()}>
        Export Lead
      </Button>
      {leadData && (
        <CSVLink
          data={leadData.lead.map((item: Lead) => {
            return {
              id: item?.id,
              key: item?.key,
              created_at: item?.created_at,
              created_from: item?.created_from,
              utm_term: item?.utm.utm_term,
              utm_medium: item?.utm.utm_medium,
              utm_source: item?.utm.utm_source,
              utm_content: item?.utm.utm_content,
              utm_campaign: item?.utm.utm_campaign,
              status: item?.status,
            }
          })}
          filename={'Lead'}
          headers={headers}
        >
          엑셀 파일로 다운로드
        </CSVLink>
      )}
    </div>
  )
}

export default React.memo(ExportLeadButton)
