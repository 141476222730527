import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import React, { useState } from 'react'

import config from '../../../configs'
import useRootData from '../../../hooks/useRootData'
import { checkIsValidEmail } from '../../../utils/utility'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent, changeLoadingSpinnerVisibility, changeMainMenu } = useRootData(
    ({ appStore }) => ({
      changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
      changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
      changeMainMenu: appStore.changeMainMenu,
    }),
  )

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const createAccountClickHandler = async () => {
    if (!checkIsValidEmail(email)) {
      changeSnackbarAlertContent({
        severity: 'error',
        content: '유효하지 않은 이메일 형식입니다.',
      })

      return
    }

    const urlOrigin =
      window.location.origin ||
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`
    try {
      changeLoadingSpinnerVisibility(true)
      const account = await axios.get(`${config.backendEndPoint}/auth/check-account`, {
        params: { email, clientReqURL: urlOrigin },
      })

      const { resultQuery, partnerAccountExistence } = account.data

      if (partnerAccountExistence || resultQuery) {
        changeSnackbarAlertContent({
          severity: 'error',
          content: '이미 계정이 존재합니다.',
        })

        return
      }

      await axios.post(`${config.backendEndPoint}/auth/create-user`, {
        userName: name,
        email,
        password: '357999',
      })

      const {
        data: {
          accountByAccount: { id: accountId },
        },
      } = await axios.post(`${config.backendEndPoint}/auth/check-token`, {
        tempName: name,
        clientReqURL: urlOrigin,
        email,
      })

      changeLoadingSpinnerVisibility(false)

      changeSnackbarAlertContent({
        severity: 'success',
        content: '생성 완료!',
      })
      changeMainMenu(`/store-detail?accountId=${accountId}`)
    } catch (err) {
      changeLoadingSpinnerVisibility(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `생성 실패\n${err}` })
    }
  }

  return (
    <TableContainer className={styles.container} component={Paper}>
      <Table className={styles.table} size="small" aria-label="a dense table">
        <TableHead className={styles.header}>
          <TableRow className={styles.rowTitle}>
            <TableCell className={styles.col} colSpan={5}>
              생성하실 계정의 이메일을 입력하시오. (비밀번호는 357999로 설정됩니다.)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">사장님 성함</TableCell>
            <TableCell>
              <TextField
                className={styles.inputTextField}
                value={name}
                variant="outlined"
                size="small"
                onChange={(event): void => setName(event.target.value)}
              />
            </TableCell>
            <TableCell align="center">이메일</TableCell>
            <TableCell>
              <TextField
                className={styles.inputTextField}
                value={email}
                variant="outlined"
                size="small"
                onChange={(event): void => setEmail(event.target.value)}
              />
            </TableCell>
            <TableCell>
              <Button variant="outlined" onClick={(): Promise<void> => createAccountClickHandler()}>
                생성하기!
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(App)
