/* eslint-disable no-underscore-dangle */
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

import { Config_Type_Enum as ConfigTypeEnum } from '../../assets/graphql/graphql'

export const _GET_SEARCH_STORES = gql`
  query _GET_SEARCH_STORES($storeSearchKeyword: String!) {
    searchStores: store(
      order_by: { address_detail: asc }
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          {
            _or: [
              { name: { _ilike: $storeSearchKeyword } }
              { llumar_store_name: { _ilike: $storeSearchKeyword } }
              { address_detail: { _ilike: $storeSearchKeyword } }
              { addressSigunguByAddressSigungu: { name: { _ilike: $storeSearchKeyword } } }
              { addressSigunguByAddressSigungu: { address_sido: { name: { _ilike: $storeSearchKeyword } } } }
            ]
          }
        ]
      }
    ) {
      id
      status
      name
      location
      address_detail
      subscriptions {
        status
      }
    }
  }
`

export const _GET_SEARCH_CARS = gql`
  query _GET_SEARCH_CARS($carSearchKeyword: String!) {
    searchCars: car(
      where: {
        _or: [{ car_maker: { name_kr: { _ilike: $carSearchKeyword } } }, { model: { _ilike: $carSearchKeyword } }]
      }
      distinct_on: model
    ) {
      id
      model
      type
      car_maker {
        name_kr
        name_us
        car_origin {
          name_kr
          name_us
        }
      }
    }
  }
`

// film_remove sku e.g. llumar_film_remove_side_one_piece_korea_sedan

export const _GET_SELECT_PRODUCTS = gql`
  query _GET_SELECT_PRODUCTS($carOrigin: String!, $carType: String!, $carSunroofType: String!) {
    packages: package(where: { package_type: { value: { _neq: "custom_package" } } }) {
      id
      name
      product
      description
      add_on_product
      price_product
      price_discount
      price_payout
      attribute
    }
    custom_package: package(where: { package_type: { value: { _eq: "custom_package" } } }) {
      id
    }
    front_tinting_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { attribute: { _contains: { part: "front" } } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { productByProduct: { status: { _eq: true } } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    sideback_tinting_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { attribute: { _contains: { part: "all" } } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { productByProduct: { status: { _eq: true } } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    side_tinting_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "tinting" } } } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { sku: { _ilike: "%_piece%" } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    back_tinting_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "tinting" } } } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { sku: { _ilike: "%_back_%" } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    sunroof_tinting_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { sku: { _ilike: $carSunroofType } }
          { sku: { _ilike: $carOrigin } }
          { productByProduct: { status: { _eq: true } } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    front_film_remove_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { sku: { _ilike: "%front%" } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { productByProduct: { product_type: { value: { _eq: "film_remove" } } } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    side_film_remove_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { sku: { _ilike: "%_side_%" } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { productByProduct: { product_type: { value: { _eq: "film_remove" } } } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    back_film_remove_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { sku: { _ilike: "%_back_%" } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { productByProduct: { product_type: { value: { _eq: "film_remove" } } } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    sunroof_film_remove_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { sku: { _ilike: $carSunroofType } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
          { productByProduct: { product_type: { value: { _eq: "film_remove" } } } }
        ]
      }
      order_by: { sku: desc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    dashcam_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "dashcam" } } } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    ceramic_coating_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "ceramic_coating" } } } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    ppf_product_items: product_item(
      where: {
        _and: [{ deleted_at: { _is_null: true } }, { productByProduct: { product_type: { value: { _eq: "ppf" } } } }]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    connected_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "connected" } } } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    auxiliary_battery_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "auxiliary_battery" } } } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    hipass_product_items: product_item(
      where: {
        _and: [{ deleted_at: { _is_null: true } }, { productByProduct: { product_type: { value: { _eq: "hipass" } } } }]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    after_blow_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "after_blow" } } } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    undercoating_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "undercoating" } } } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    leather_coating_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "leather_coating" } } } }
          { sku: { _ilike: $carType } }
          { sku: { _ilike: $carOrigin } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    soundproofing_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "soundproofing" } } } }
        ]
      }
      order_by: { sku: asc }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    new_car_inspection_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "new_car_inspection" } } } }
        ]
      }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    camo_kit_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "camo_kit" } } } }
        ]
      }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
    delivery_fee_product_items: product_item(
      where: {
        _and: [
          { deleted_at: { _is_null: true } }
          { productByProduct: { product_type: { value: { _eq: "delivery_fee" } } } }
        ]
      }
    ) {
      id
      price
      sku
      attribute
      productByProduct {
        id
        name
        product_brand {
          description
        }
        product_type {
          value
          description
        }
      }
    }
  }
`

export const _GET_SELECT_OPTIONS = gql`
  query _GET_SELECT_OPTIONS {
    address: address_sido(order_by: { name: asc }) {
      id
      name
      address_sigungus(order_by: { name: asc }) {
        id
        name
      }
    }
    carSunroofTypes: car_sunroof_type {
      value
      description
    }
    carModels: car(where: { model: { _neq: "해당없음" } }) {
      id
      model
      type
      car_maker {
        name_kr
        name_us
        car_origin {
          name_kr
          name_us
        }
      }
    }
    stores: store(where: { deleted_at: { _is_null: true } }, order_by: { address_detail: asc }) {
      id
      status
      name
      location
      address_detail
      addressSigunguByAddressSigungu {
        name
        address_sido {
          name
        }
      }
      subscriptions {
        status
      }
    }
    chat_assignees: account(where: { profile_riderdash: { deleted_at: { _is_null: true } } }) {
      id
      profile_riderdash {
        id
        name
        email
        role
      }
    }
    operators: account(where: { profile_riderdash: { role: { _neq: parttimer }, deleted_at: { _is_null: true } } }) {
      id
      profile_riderdash {
        id
        name
        role
      }
    }
    profileCustomers: account(where: { profile_kr_customers: { id: { _is_null: false } } }) {
      id
      profile_kr_customer {
        id
        name
        email
      }
    }
  }
`

export const _GET_PRODUCT_ITEM_PRICE_BY_STORE_ID = gql`
  query _GET_PRODUCT_ITEM_PRICE_BY_STORE($storeId: uuid!) @cached {
    product_item_price_by_store(where: { store_id: { _eq: $storeId } }) {
      id
      product_item_id
      store_id
      prices
      default_prices
    }
  }
`

export const _GET_CONFIG_RATE = (configType: ConfigTypeEnum): DocumentNode => {
  const where = `type: { _eq: ${configType} }`

  return gql`
    query GET_DEFAULT_DISCOUNT_RATE_TABLE {
      config(where: {${where}}, order_by: { created_at: desc }, limit: 1) {
        id
        csv_content
        updated_at
      }
    }
  `
}

export const _INSERT_NEW_CONFIG_RATE = gql`
  mutation INSERT_NEW_CONFIG_RATE($object: config_insert_input!) {
    insert_config_one(object: $object) {
      id
      updated_at
    }
  }
`
