import { useLazyQuery } from '@apollo/react-hooks'
import { Button } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Get_Store_SearchQuery as GetStoreSearchQuery,
  Get_Store_SearchQueryVariables as GetStoreSearchQueryVariables,
  Store,
  Store_Status_Enum as StoreStatusEnum,
  Subscription_Status_Enum as SubscriptionStatusEnum,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import styles from './index.module.scss'

export interface StoreObject {
  status: StoreStatusEnum | string
  name: string // eslint-disable-next-line camelcase
  address_detail: string
  description: string
  location: {
    type: string
    crs: {
      type: string
      properties: {
        name: string
      }
    }
    coordinates: [number, number]
  }
  attribute: {
    hours: {
      /* eslint-disable camelcase */
      weekday_open_hour: number
      weekday_open_minute: number
      weekday_close_hour: number
      weekday_close_minute: number
      weekday_day_off: boolean
      weekday_only_reservation: boolean
      saturday_open_hour: number
      saturday_open_minute: number
      saturday_close_hour: number
      saturday_close_minute: number
      saturday_day_off: boolean
      saturday_only_reservation: boolean
      sunday_open_hour: number
      sunday_open_minute: number
      sunday_close_hour: number
      sunday_close_minute: number
      sunday_day_off: boolean
      sunday_only_reservation: boolean
      holiday_open_hour: number
      holiday_open_minute: number
      holiday_close_hour: number
      holiday_close_minute: number
      holiday_day_off: boolean
      holiday_only_reservation: boolean
      /* eslint-enable camelcase */
    }
    maestro: boolean
    promotion: boolean
    grandOpening: boolean
    electronicWarranty: boolean
  }
  email: string
  image: Array<Record<string, string>>
  address_sigungu: string // eslint-disable-line camelcase
  name_business: string // eslint-disable-line camelcase
  phone_business: string // eslint-disable-line camelcase
}

export const tempStoreObject: StoreObject = {
  status: '',
  name: '',
  address_detail: '',
  description: '',
  location: {
    type: 'Point',
    crs: {
      type: 'name',
      properties: {
        name: 'urn:ogc:def:crs:EPSG::4326',
      },
    },
    coordinates: [0, 0],
  },
  attribute: {
    hours: {
      weekday_open_hour: 0,
      weekday_open_minute: 0,
      weekday_close_hour: 0,
      weekday_close_minute: 0,
      weekday_day_off: false,
      weekday_only_reservation: false,
      saturday_open_hour: 0,
      saturday_open_minute: 0,
      saturday_close_hour: 0,
      saturday_close_minute: 0,
      saturday_day_off: false,
      saturday_only_reservation: false,
      sunday_open_hour: 0,
      sunday_open_minute: 0,
      sunday_close_hour: 0,
      sunday_close_minute: 0,
      sunday_day_off: true,
      sunday_only_reservation: false,
      holiday_open_hour: 0,
      holiday_open_minute: 0,
      holiday_close_hour: 0,
      holiday_close_minute: 0,
      holiday_day_off: true,
      holiday_only_reservation: false,
    },
    maestro: false,
    promotion: false,
    grandOpening: false,
    electronicWarranty: false,
  },
  email: '',
  image: [
    {
      jpeg: '',
      thumb: '',
      jpeg_aws: '',
      thumb_aws: '',
    },
  ],
  address_sigungu: '',
  name_business: '',
  phone_business: '',
}

const GET_STORE = (
  query: string,
  membershipType: string,
  storeStatus: string,
  parameters: string,
  subscriptionStatus: SubscriptionStatusEnum | 'all',
): DocumentNode => {
  const where = `_and: [
      { deleted_at: { _is_null: true } }
      ${query}
      ${membershipType !== 'all' ? `{ membership: { membership_type: { value: { _eq: "${membershipType}" } } } }` : ``}
      ${storeStatus !== 'all' ? `{ status: { _eq: ${storeStatus} } }` : ``}
      ${subscriptionStatus !== 'all' ? `{ subscriptions: { status: { _eq: ${subscriptionStatus} } } }` : ``}
    ]`

  return gql`
      query GET_STORE_SEARCH (
        $limit: Int = 20
        $offset: Int = 0
        ${parameters}
      ){
        stores: store(
          where: {${where}}
          order_by: { created_at: desc }      
          limit: $limit
          offset: $offset
        ) {
          status
          id
          name
          email
          llumar_store_name
          address_detail
          addressSigunguByAddressSigungu {
            id
            name
            address_sido {
              name
            }
          }
          membership {
            id
            store
            type
            start_date
            end_date
            price
            membership_type {
              value
            }
          }
          subscriptions {
            status
          }
        }
        stores_count: store_aggregate(
          where: { ${where} }
        ) {
          aggregate {
          count
          }
        }
      }
    `
}

// update deleted_at column
const DELETE_STORE = gql`
  mutation DELETE_STORE($id: uuid!, $deletedAt: timestamptz!) {
    update_store(where: { id: { _eq: $id } }, _set: { deleted_at: $deletedAt }) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))
  const [membershipType, setMembershipType] = useState('all')
  const [storeStatus, setStoreStatus] = useState('all')
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatusEnum | 'all'>('all')
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [keywords, setKeywords] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState('')

  let parameters = ''
  let query = ''

  if (keywords) {
    for (let i = 0; i < 10; i += 1) {
      parameters += `$keyword${i}: String = "%"
      `
    }

    for (let i = 0; i < 10; i += 1) {
      query += `     {_or: [
        { name: {_like: $keyword${i}}}
        { addressSigunguByAddressSigungu: { address_sido:  { name: {_like:$keyword${i}}}}}
        { addressSigunguByAddressSigungu:  { name: {_like: $keyword${i}}}}
        { address_detail: { _like: $keyword${i}}} 
        { email: { _like: $keyword${i}} }
        { llumar_store_name: { _like: $keyword${i}} }
        ]}  `
    }
  }

  const [getStore, { data: storeContents, error }] = useLazyQuery<GetStoreSearchQuery, GetStoreSearchQueryVariables>(
    GET_STORE(query, membershipType, storeStatus, parameters, subscriptionStatus),
    {
      fetchPolicy: 'no-cache',
    },
  )

  useEffect(() => {
    getStore()
  }, [])

  if (error) {
    Sentry.captureException(error)
  }
  if (!storeContents) return null
  const totalNum = storeContents.stores_count.aggregate.count

  function onInput(pageOffset: number, pageNumber?: number) {
    getStore({
      variables: {
        limit: pageNumber || numberOfItemsPerPage,
        ...keywords,
        offset: pageOffset,
      },
    })
  }
  const pageList = Array.from(Array(Math.ceil(totalNum / numberOfItemsPerPage)), (_, i) => i + 1)
  let start = currentPage - 1
  let end = currentPage + 3

  if (pageList.length <= 5) {
    start = 1
    end = pageList.length
  } else if (currentPage - 2 <= 0) {
    start = 1
    end = 5
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>매장 목록</div>
        <Button variant="contained" color="primary" onClick={() => changeMainMenu('/store-detail')}>
          매장 수동 추가
        </Button>

        <br />
        <label>
          한 페이지당 아이템 수&nbsp;
          <select
            value={numberOfItemsPerPage}
            name="perPage"
            id="perPage"
            onChange={(event) => {
              setNumberOfItemsPerPage(parseInt(event.target.value, 10))
              setCurrentPage(0)
              onInput(0, parseInt(event.target.value, 10))
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </label>
        <label> | </label>
        <label>
          멤버쉽
          <select
            value={membershipType}
            onChange={(event) => {
              setMembershipType(event.target.value)
              onInput(0)
            }}
          >
            <option value="all">All</option>
            <option value="gold">Gold</option>
            <option value="silver">Silver</option>
            <option value="bronze">Bronze</option>
          </select>
        </label>
        <label> | </label>
        <label>
          승인 여부
          <select
            value={storeStatus}
            onChange={(event) => {
              setStoreStatus(event.target.value)
              onInput(0)
            }}
          >
            <option value="all">All</option>
            <option value="created">created</option>
            <option value="approved">approved</option>
            <option value="unapproved">unapproved</option>
          </select>
        </label>
        <label> | </label>
        <label>
          구독 여부
          <select
            value={subscriptionStatus}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              setSubscriptionStatus(event.target.value as SubscriptionStatusEnum)
              onInput(0)
            }}
          >
            <option value="all">All</option>
            <option value={SubscriptionStatusEnum.Active}>active</option>
            <option value={SubscriptionStatusEnum.Unpaid}>unpaid</option>
            <option value={SubscriptionStatusEnum.Canceled}>canceled</option>
            <option value={SubscriptionStatusEnum.None}>none</option>
          </select>
        </label>
        <label> | </label>
        <label>
          <input
            defaultValue={searchKeyword}
            placeholder="매장명|지역|주소|이메일"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>): void => {
              if (e.key === 'Enter') {
                setCurrentPage(0)
                onInput(0)
              }
            }}
            onChange={(event) => {
              let tempKeywords = {}
              event.target.value.split(' ').forEach((item, index) => {
                const name = `keyword`.concat(index.toString())
                tempKeywords = { ...tempKeywords, [name]: `%${item}%` }
              })

              setSearchKeyword(event.target.value)
              setKeywords(tempKeywords)
            }}
          />
        </label>
        <button
          onClick={() => {
            setCurrentPage(0)
            onInput(0)
          }}
        >
          찾기
        </button>
        <label> | 총 검색 수 {totalNum}</label>
        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>uuid</th>
              <th className={styles.membership}>멤버쉽 여부</th>
              <th className={styles.storeStatus}>승인 여부</th>
              <th className={styles.subscriptionStatus}>구독 여부</th>
              <th className={styles.storeName}>상호명</th>
              <th className={styles.storeName}>루마 매장명</th>
              <th className={styles.area}>지역</th>
              <th>주소</th>
              <th className={styles.storeName}>이메일</th>
              <th></th>
            </tr>
            {storeContents.stores.map((item: Store, index: number) => (
              <tr key={index}>
                <td>
                  <input
                    className={styles.inputArea}
                    defaultValue={totalNum - currentPage * numberOfItemsPerPage - index}
                    disabled={true}
                  />
                </td>
                <td>
                  <input className={styles.inputArea} defaultValue={item.id} disabled={true} />
                </td>
                <td className={styles.inputArea}>{item.membership ? item.membership.membership_type.value : ''}</td>
                <td>
                  <input className={styles.inputArea} defaultValue={item.status} disabled={true} />
                </td>
                <td>
                  <input
                    className={styles.inputArea}
                    defaultValue={item.subscriptions[0] && item.subscriptions[0].status}
                    disabled={true}
                  />
                </td>
                <td>
                  <input className={styles.inputArea} defaultValue={item.name} disabled={true} />
                </td>
                <td>
                  <input className={styles.inputArea} defaultValue={item.llumar_store_name} disabled={true} />
                </td>
                <td>
                  <input
                    className={styles.inputArea}
                    defaultValue={
                      item.addressSigunguByAddressSigungu
                        ? `${item.addressSigunguByAddressSigungu.address_sido.name} ${item.addressSigunguByAddressSigungu.name}`
                        : ''
                    }
                    disabled={true}
                  />
                </td>
                <td>
                  <input className={styles.inputArea} defaultValue={item.address_detail} disabled={true} />
                </td>
                <td>
                  <input className={styles.inputArea} defaultValue={item.email} disabled={true} />
                </td>

                <td>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/store-detail?id=${item.id}`)
                    }}
                  >
                    상세보기
                  </button>
                  <button
                    onClick={async (): Promise<void> => {
                      try {
                        const timeStamp = moment().format()

                        // eslint-disable-next-line no-alert
                        const deleteStore = window.confirm(`'${item.name}' 삭제됩니다. \n 삭제하시겠습니까?`)

                        if (deleteStore) {
                          await client.mutate({
                            mutation: DELETE_STORE,
                            variables: { id: item.id, deletedAt: timeStamp },
                          })

                          getStore()
                        }
                      } catch (err) {
                        Sentry.captureException(err)

                        changeSnackbarAlertContent({ severity: 'error', content: `매장 삭제 실패 \n ${err}` })
                      }
                    }}
                  >
                    삭제하기
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.paginationWrapper}>
          <div className={styles.pagination}>
            <a
              onClick={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onInput((currentPage - 1) * numberOfItemsPerPage)
                }
              }}
            >
              &nbsp; &lt;
            </a>
            {pageList.slice(start - 1, end).map((item) => {
              return (
                <a
                  key={item - 1}
                  className={currentPage === item - 1 ? styles.active : undefined}
                  onClick={() => {
                    setCurrentPage(item - 1)
                    onInput((item - 1) * numberOfItemsPerPage)
                  }}
                >
                  &nbsp;{item}&nbsp;
                </a>
              )
            })}
            <a
              onClick={() => {
                if (currentPage < Math.ceil(totalNum / numberOfItemsPerPage) - 1) {
                  setCurrentPage(currentPage + 1)
                  onInput((currentPage + 1) * numberOfItemsPerPage)
                }
              }}
            >
              &gt;&nbsp;
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
