import { Button, MenuItem, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import useRootData from '../../../../hooks/useRootData'
import { PriceKey } from '../../../../stores/calculator/type'
import styles from './index.module.scss'

const selectPrice = [
  {
    value: 'price',
    label: '정가',
  },
  {
    value: 'premium',
    label: '프리미엄가',
  },
  {
    value: 'package',
    label: '패키지가',
  },
  {
    value: 'lowest',
    label: '최저가',
  },
]

interface PropsSelectQuotePriceModal {
  modalStatusHandler: () => void
  sendQuoteHandler: (selectedPrice: string) => void
  isAvalableSentQuote: boolean
  sentAt: {
    price: boolean
    premium: boolean
    package: boolean
    lowest: boolean
  }
  quoteIdx: number
}

const App: React.FunctionComponent<PropsSelectQuotePriceModal> = ({
  modalStatusHandler,
  sendQuoteHandler,
  isAvalableSentQuote,
  sentAt,
  quoteIdx,
}: PropsSelectQuotePriceModal) => {
  const { selectedPackageObject } = useRootData(({ calculatorStore }) => ({
    selectedPackageObject: calculatorStore.selectedPackageObject.get()[quoteIdx],
  }))

  const [selectedPrice, setSelectedPrice] = useState<string>('')

  const selectPriceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPrice(event.target.value)
  }

  useEffect(() => {
    setSelectedPrice(Object.keys(sentAt)[0])
  }, [])

  return (
    <div className={styles.container}>
      <TextField
        className={styles.selectBox}
        select
        label="Select"
        value={selectedPrice}
        onChange={selectPriceHandler}
        helperText="전송할 가격을 선택해주세요"
        variant="filled"
      >
        {selectPrice
          .filter((item) => (selectedPackageObject ? item.value === 'package' : true))
          .filter((item) => (isAvalableSentQuote ? sentAt[item.value as PriceKey] : true))
          .map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>

      <div className={styles.btnArea}>
        <div className={styles.btnFrame}>
          <Button variant="contained" color="primary" onClick={(): void => sendQuoteHandler(selectedPrice)}>
            보내기
          </Button>
          <Button variant="contained" color="secondary" onClick={modalStatusHandler}>
            취소
          </Button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(App)
