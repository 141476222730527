import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import {
  Car_Origin as CarOrigin,
  Get_Car_Maker_DetailQuery as GetCarMakerDetailQuery,
  Get_Car_Maker_DetailQueryVariables as GetCarMakerDetailQueryVariables,
  Get_Car_OriginQuery as GetCarOriginQuery,
  Get_Car_OriginQueryVariables as GetCarOriginQueryVariables,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const GET_CAR_MAKER_DETAIL = gql`
  query GET_CAR_MAKER_DETAIL($id: uuid) {
    car_maker(where: { id: { _eq: $id } }) {
      id
      name_kr
      name_us
      origin
      car_origin {
        name_kr
        name_us
      }
    }
  }
`
const GET_CAR_ORIGIN = gql`
  query GET_CAR_ORIGIN {
    car_origin {
      id
      name_kr
      name_us
    }
  }
`

const UPDATE_CAR_MAKER = gql`
  mutation UPDATE_CAR_MAKER($carMakerId: uuid!, $newKrBrand: String, $newUsBrand: String, $newOrigin: uuid) {
    update_car_maker_by_pk(
      pk_columns: { id: $carMakerId }
      _set: { name_kr: $newKrBrand, name_us: $newUsBrand, origin: $newOrigin }
    ) {
      name_kr
      name_us
      origin
    }
  }
`

const INSERT_CAR_MAKER = gql`
  mutation INSERT_CAR_MAKER($newKrBrand: String, $newUsBrand: String, $newOrigin: uuid) {
    insert_car_maker_one(object: { name_kr: $newKrBrand, name_us: $newUsBrand, origin: $newOrigin }) {
      id
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))
  const { id } = parseQueryString()

  const [getData, { data, loading, error: getCarMakerError }] = useLazyQuery<
    GetCarMakerDetailQuery,
    GetCarMakerDetailQueryVariables
  >(GET_CAR_MAKER_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: { id },
  })

  const [carMakerObject, setCarMakerObject] = useState(
    id
      ? null
      : {
          name_kr: '',
          name_us: '',
          origin: '',
          car_origin: {
            name_kr: '',
            name_us: '',
          },
        },
  )

  const [editCarMakerClicked, setEditCarMakerClicked] = useState(!id)

  const [getOrigin, { data: origins, error: getCarOriginError }] = useLazyQuery<
    GetCarOriginQuery,
    GetCarOriginQueryVariables
  >(GET_CAR_ORIGIN, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getData()
    getOrigin()
  }, [])

  useEffect(() => {
    if (data && origins) {
      if (id) {
        const carMaker = data.car_maker[0]

        // eslint-disable-next-line no-underscore-dangle
        delete carMaker.__typename

        setCarMakerObject(carMaker)
      } else {
        setCarMakerObject({
          ...carMakerObject,
          origin: origins.car_origin[0].id,
          car_origin: {
            name_kr: origins.car_origin[0].name_kr,
            name_us: origins.car_origin[0].name_us,
          },
        })
      }
    }
  }, [data, origins])

  if (getCarMakerError || getCarOriginError) {
    const error = getCarMakerError || getCarOriginError
    Sentry.captureException(error)
  }

  if (loading || !data || !origins || !carMakerObject)
    return (
      <div className="body">
        <div className={styles.container}>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  const clientMutate = async () => {
    try {
      const carMakerQuery = id ? UPDATE_CAR_MAKER : INSERT_CAR_MAKER
      const newKrBrand = carMakerObject.name_kr
      const newUsBrand = carMakerObject.name_us
      const newOrigin = carMakerObject.origin
      if (!id && (!newKrBrand || !newUsBrand || !newOrigin)) {
        changeSnackbarAlertContent({
          severity: 'error',
          content: '저장을 실패하였습니다. (사유: 제조사 이름/국가가 비어있음)',
        })

        return
      }
      await client.mutate({
        mutation: carMakerQuery,
        variables: { ...(id && { carMakerId: id }), newKrBrand, newUsBrand, newOrigin },
      })
      changeSnackbarAlertContent({
        severity: 'success',
        content: id ? '수정되었습니다.' : '저장되었습니다.',
      })

      if (!id) changeMainMenu('/car-maker')
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({
        severity: 'error',
        content: id ? '수정을 실패하였습니다.' : '저장을 실패하였습니다.',
      })
    }
  }

  const originHandler = (changedOriginKr: string) => {
    const newOriginInfo = origins.car_origin.filter(
      // eslint-disable-next-line camelcase
      (ele: { id: string; name_kr: string; name_us: string }) => {
        return ele.name_kr === changedOriginKr
      },
    )
    setCarMakerObject({
      ...carMakerObject,
      origin: newOriginInfo[0].id,
      car_origin: {
        name_kr: newOriginInfo[0].name_kr,
        name_us: newOriginInfo[0].name_us,
      },
    })
  }

  return (
    <div className="body">
      <div className={styles.container}>
        제조사 상세
        <div className={styles.editButtonArea}>
          <button
            className={styles.button}
            onClick={(): void => {
              if (editCarMakerClicked) {
                clientMutate()
              }

              if (id) setEditCarMakerClicked(!editCarMakerClicked)
            }}
          >
            {editCarMakerClicked ? '저장하기' : '제조사 정보 수정'}
          </button>
        </div>
        <table>
          <tbody>
            <tr>
              <td>국가</td>
              <td>
                <select
                  defaultValue={carMakerObject.car_origin.name_kr}
                  onChange={(e): void => {
                    originHandler(e.target.value)
                  }}
                  disabled={!editCarMakerClicked}
                >
                  {origins.car_origin.map((origin: CarOrigin, index: number) => {
                    return (
                      <option key={index} value={origin.name_kr}>
                        {origin.name_kr}
                      </option>
                    )
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>Nation</td>
              <td>{carMakerObject.car_origin.name_us}</td>
            </tr>
            <tr>
              <td>브랜드</td>
              <td>
                <input
                  defaultValue={carMakerObject.name_kr}
                  disabled={!editCarMakerClicked}
                  onChange={(changedKrBrand) => {
                    setCarMakerObject({
                      ...carMakerObject,
                      name_kr: changedKrBrand.target.value,
                    })
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Brand</td>
              <td>
                <input
                  defaultValue={carMakerObject.name_us}
                  disabled={!editCarMakerClicked}
                  onChange={(changedUsBrand) => {
                    setCarMakerObject({
                      ...carMakerObject,
                      name_us: changedUsBrand.target.value,
                    })
                  }}
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
