import appStore from '../app'
import calculatorStore from '../calculator'
import { ProductCategory, ProductDisplayNames } from '../calculator/type'
import orderStore from '.'

export default (): boolean => {
  let orderObject = orderStore.orderObject.get()

  if (!orderObject) return true

  let error = false

  const { calculatedPrices, productItemArrays } = calculatorStore
  const selectedProductIds = calculatorStore.selectedProductIds.get()[0]
  const customProducts = calculatorStore.customProducts.get()[0]

  const selectedPackageObject = calculatorStore.selectedPackageObject.get()[0]
  const customPackageId = calculatorStore.selectProductsContent.get().custom_package[0].id
  const selectedStore = calculatorStore.selectedStore.get()[0]
  const extraDiscountPrice = calculatorStore.extraDiscountPrice.get()[0].price
  const extraPrice = calculatorStore.extraPrice.get()[0].price
  const extraDiscountPayoutPrice = calculatorStore.extraDiscountPayoutPrice.get()[0].price
  const extraPayoutPrice = calculatorStore.extraPayoutPrice.get()[0].price

  const priceProduct = (calculatedPrices[0] && calculatedPrices[0].totalPrice.price) || 0
  const priceFinal = (calculatedPrices[0] && calculatedPrices[0].finalPrice.price) || 0
  const pricePayout = (calculatedPrices[0] && calculatedPrices[0].finalPayoutPrice.price) || 0
  const commissionRate = parseFloat((((priceFinal - pricePayout) * 100) / priceFinal).toFixed(2)) || 0

  let productItem = {}

  if (selectedPackageObject) {
    const errors = Object.values(ProductCategory)
      .filter(
        (key) =>
          ['side_tinting_product_items', 'back_tinting_product_items', '_film_remove_product_items'].indexOf(key) ===
          -1,
      )
      .map((key: ProductCategory) => {
        if (productItemArrays[key][0].length > 1 && !selectedProductIds[key]) {
          return `${ProductDisplayNames[key]} 제품을 골라주세요!`
        }

        return null
      })
      .filter((item) => item !== null)

    if (errors.length) {
      appStore.changeSnackbarAlertContent({ severity: 'error', content: errors[0] })

      error = true

      return error
    }
  }

  const tempTintingObject = {
    tinting: {
      ...(selectedProductIds[ProductCategory.FrontTintingProduct]
        ? { front: selectedProductIds[ProductCategory.FrontTintingProduct] }
        : {}),
      ...(selectedProductIds[ProductCategory.SidebackTintingProduct]
        ? { sideback: selectedProductIds[ProductCategory.SidebackTintingProduct] }
        : {}),
      ...(selectedProductIds[ProductCategory.SunroofTintingProduct]
        ? { sunroof: selectedProductIds[ProductCategory.SunroofTintingProduct] }
        : {}),
    },
  }

  const otherProductObject = Object.assign(
    {},
    ...Object.values(ProductCategory)
      .filter(
        (key) =>
          ['front_tinting_product_items', 'sideback_tinting_product_items', 'sunroof_tinting_product_items'].indexOf(
            key,
          ) === -1,
      )
      .map((key) =>
        selectedProductIds[key] ? { [key.replace(/_product_items$/g, '')]: selectedProductIds[key] } : {},
      ),
  )

  const customProductObject = {
    customProducts: [...customProducts],
  }

  // package
  if (selectedPackageObject) {
    const tempProductItem = {
      package: {
        [selectedPackageObject.id]: {
          ...tempTintingObject,
          ...otherProductObject,
        },
      },
      ...customProductObject,
    }

    productItem = tempProductItem
  }
  // custom package
  else if (
    Object.values(ProductCategory)
      .filter(
        (key) =>
          ['front_tinting_product_items', 'sideback_tinting_product_items', 'sunroof_tinting_product_items'].indexOf(
            key,
          ) === -1,
      )
      .reduce((isSelected: boolean, key: ProductCategory): boolean => {
        return isSelected || !!selectedProductIds[key]
      }, false) ||
    !!customProducts
  ) {
    const tempProductItem = {
      package: {
        [customPackageId]: {
          ...tempTintingObject,
          ...otherProductObject,
        },
      },
      ...customProductObject,
    }

    productItem = tempProductItem
  }
  // tinting
  else {
    const tempProductItem = {
      ...tempTintingObject,
    }

    productItem = tempProductItem
  }

  orderObject = {
    ...orderObject,
    store: selectedStore ? selectedStore.id : null,
    product_item: productItem,
    price_extra: extraPrice,
    price_discount: extraDiscountPrice,
    price_product: priceProduct,
    price_final: priceFinal,
    price_payout: pricePayout,
    commission_rate: commissionRate,
    price_payout_discount: extraDiscountPayoutPrice,
    price_payout_extra: extraPayoutPrice,
  }

  orderStore.orderObject.set(orderObject)

  return error
}
