// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__title___2Ntg5 {\n  font-size: 18px; }\n\n.index-module__text___1VF80 {\n  width: 100%;\n  height: 150px; }\n\n.index-module__tvs___1nIRZ {\n  padding: 10px;\n  border: 1px solid gray; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/components/organism/TableValueSetter/index.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAA;;AAGjB;EACE,WAAW;EACX,aAAa,EAAA;;AAGf;EACE,aAAa;EACb,sBAAsB,EAAA","file":"index.module.scss","sourcesContent":[".title {\n  font-size: 18px;\n}\n\n.text {\n  width: 100%;\n  height: 150px;\n}\n\n.tvs {\n  padding: 10px;\n  border: 1px solid gray;\n}\n"]}]);
// Exports
exports.locals = {
	"title": "index-module__title___2Ntg5",
	"text": "index-module__text___1VF80",
	"tvs": "index-module__tvs___1nIRZ"
};
module.exports = exports;
