import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'

import { Lead, Lead_Status_Enum as LeadStatusEnum } from '../../../../assets/graphql/graphql'
import Text from '../../../../components/atoms/Text'
import config from '../../../../configs'
import useRootData from '../../../../hooks/useRootData'
import { LEAD_COL } from '../../../../stores/lead/type'
import { getLeadColValueByKey } from '../../../../utils/utility'
import { AVAILABLE_MENU } from '../../../LeadDetail/Menu'
import { tableCol } from '../../index'
import styles from './index.module.scss'

const currentlyAvailableMenus: {
  [key: string]: Array<AVAILABLE_MENU>
} = {
  [LeadStatusEnum.Duplicate]: [],
  [LeadStatusEnum.Missed]: [],
  [LeadStatusEnum.Chat]: [],
  [LeadStatusEnum.Lead]: [],
  [LeadStatusEnum.Quote]: [AVAILABLE_MENU.LET_US_MATCH],
  [LeadStatusEnum.Match]: [AVAILABLE_MENU.CAN_NOT_MATCH],
  [LeadStatusEnum.OrderPending]: [AVAILABLE_MENU.BACK_TO_MATCH],
  [LeadStatusEnum.RsvPending]: [
    AVAILABLE_MENU.RESERVATION_CONFIRMED,
    AVAILABLE_MENU.BACK_TO_ORDER,
    AVAILABLE_MENU.CANCEL,
  ],
  [LeadStatusEnum.RsvConfirmed]: [AVAILABLE_MENU.INSTALLED, AVAILABLE_MENU.BACK_TO_ORDER, AVAILABLE_MENU.CANCEL],
  [LeadStatusEnum.PaymentPending]: [AVAILABLE_MENU.CANCEL],
  /* TODO : 매장 이체 모두 끝나면 삭제 */
  [LeadStatusEnum.VatRequested]: [AVAILABLE_MENU.SETTLEMENT_PENDING, AVAILABLE_MENU.SEND_ETAX_INVOICE],
  [LeadStatusEnum.SettlementPending]: [AVAILABLE_MENU.PAYOUT_COMPLETED],
  /* */
  [LeadStatusEnum.Done]: [
    /* AVAILABLE_MENU.REFUND */
  ],
  [LeadStatusEnum.Refunded]: [],
  [LeadStatusEnum.Cancel]: [AVAILABLE_MENU.BACK_TO_ORDER],
  [LeadStatusEnum.WillNotDo]: [AVAILABLE_MENU.BACK_TO_LEAD],
}

const mappingRequestUrl: {
  [key: string]: string
} = {
  [AVAILABLE_MENU.LET_US_MATCH]: `${config.backendEndPoint}/lead/let-us-match`,
  [AVAILABLE_MENU.WILL_NOT_DO]: `${config.backendEndPoint}/lead/will-not-do`,
  [AVAILABLE_MENU.BACK_TO_MATCH]: `${config.backendEndPoint}/lead/back-to-match`,
  [AVAILABLE_MENU.CAN_NOT_MATCH]: `${config.backendEndPoint}/lead/can-not-match`,
  [AVAILABLE_MENU.RESERVATION_CONFIRMED]: `${config.backendEndPoint}/lead/reservation-confirmed`,
  [AVAILABLE_MENU.BACK_TO_ORDER]: `${config.backendEndPoint}/lead/back-to-order`,
  [AVAILABLE_MENU.CANCEL]: `${config.backendEndPoint}/lead/cancel`,
  [AVAILABLE_MENU.INSTALLED]: `${config.backendEndPoint}/lead/installed`,
  [AVAILABLE_MENU.SETTLEMENT_PENDING]: `${config.backendEndPoint}/lead/settlement-pending`,
  [AVAILABLE_MENU.SEND_ETAX_INVOICE]: `${config.backendEndPoint}/send/send-etax-invoice-to-store`,
  [AVAILABLE_MENU.PAYOUT_COMPLETED]: `${config.backendEndPoint}/lead/payout-completed`,
  [AVAILABLE_MENU.BACK_TO_LEAD]: `${config.backendEndPoint}/lead/back-to-lead`,
}

export interface LeadRowProps {
  row: Lead
}

export const mappingStyle: {
  [key: string]: React.CSSProperties
} = {
  [LEAD_COL.CHAT_ID]: { maxWidth: '80px', minWidth: '40px', paddingRight: '5px' },
  [LEAD_COL.LEAD_ID]: { maxWidth: '80px', minWidth: '40px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.ZENDESK_CHAT_NAME]: { maxWidth: '120px', marginRight: '5px' },
  [LEAD_COL.CHAT_ASSIGNEE]: { minWidth: '150px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.SUPPORT_ASSIGNEE]: { minWidth: '150px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.SELECTED_NEW_CAR]: { maxWidth: '80px', minWidth: '70px', paddingRight: '5px' },
  [LEAD_COL.START_DROP_OFF_DATE]: { width: '150px', paddingLeft: '0px', paddingRight: '5px' },
  [LEAD_COL.END_DROP_OFF_DATE]: { width: '150px', paddingLeft: '0px', paddingRight: '5px' },
  [LEAD_COL.SHOP]: { minWidth: '130px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.CAR]: { minWidth: '130px', paddingLeft: '0px', paddingRight: '5px' },
  [LEAD_COL.CARAMORA_ID]: { minWidth: '140px', paddingLeft: '0px', paddingRight: '5px', marginRight: '5px' },
  [LEAD_COL.CUSTOMER_NAME]: { minWidth: '100px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.CUSTOMER_PHONE]: { maxWidth: '130px', minWidth: '130px', paddingLeft: '0px', paddingRight: '5px' },
  [LEAD_COL.CREATED_AT]: { width: '150px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.UPDATED_AT]: { width: '150px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.STATUS]: { minWidth: '10px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.MATCH_MAKER]: { minWidth: '150px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.DUE_DATE]: { width: '150px', paddingLeft: '0px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.SHOP_PHONE]: { minWidth: '140px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.CAN_NOT_MATCH]: { minWidth: '10px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.PAYMENT_LINK_SENT_AT]: { minWidth: '80px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.PAID_AT]: { width: '150px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.VAT_REQUESTED_AT]: { width: '150px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.PRICE_PAYOUT]: { minWidth: '100px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.MATERIAL_PRICE]: { minWidth: '100px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.PRICE_PAYOUT_WITHOUT_VAT]: { minWidth: '80px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.FINISHED_AT]: { width: '150px', paddingRight: '5px', textAlign: 'center' },
  [LEAD_COL.STATUS]: { minWidth: '10px', textAlign: 'center' },
}

const warningStyle: React.CSSProperties = { color: 'red', fontWeight: 'bold' }

const App: React.FunctionComponent<LeadRowProps> = ({ row }: LeadRowProps) => {
  const {
    accountId,
    statusFilterValue,
    changeMainMenu,
    changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility,
  } = useRootData(({ appStore, authStore, leadStore }) => ({
    accountId: authStore.accountId.get(),

    statusFilterValue: leadStore.statusFilterValue.get(),
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
  }))

  const [availableBtnPopupStatus, setAvailableBtnPopupStatus] = useState<boolean>(false)
  const [selectedButton, setSelectedButton] = useState<number>(-1)

  return (
    <>
      {/* Update status button Dialog Modal */}
      <Dialog
        open={availableBtnPopupStatus}
        onClose={(): void => setAvailableBtnPopupStatus(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            정말로 {currentlyAvailableMenus[statusFilterValue][selectedButton]}를 진행하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(): void => setAvailableBtnPopupStatus(false)} color="secondary" variant="contained">
            취소
          </Button>
          <Button
            onClick={async (): Promise<void> => {
              try {
                changeLoadingSpinnerVisibility(true)

                await axios.post(mappingRequestUrl[currentlyAvailableMenus[statusFilterValue][selectedButton]], {
                  leadId: row.id,
                  accountId,
                })

                changeSnackbarAlertContent({
                  severity: 'success',
                  content: `${currentlyAvailableMenus[statusFilterValue][selectedButton]} 처리 완료.`,
                })
              } catch (err) {
                changeLoadingSpinnerVisibility(false)

                Sentry.captureException(err)
                changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
              }

              setAvailableBtnPopupStatus(false)
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow
        style={{
          backgroundColor:
            row.status === LeadStatusEnum.Lead && getLeadColValueByKey(row, LEAD_COL.CALL_REQUEST_SAME_DAY_ORDER)
              ? 'salmon'
              : '',
        }}
        className={styles.row}
      >
        <TableCell className={styles.col}>
          <div style={{ display: 'flex' }}>
            <Text
              className={styles.edit}
              text={'수정'}
              clickHandler={(): void => changeMainMenu(`/lead-detail?id=${row.id}`)}
            />
            <IconButton
              style={{ padding: '0px' }}
              size="small"
              color="primary"
              onClick={() => {
                window.open(`/lead-detail?id=${row.id}`)
              }}
            >
              <OpenInNew style={{ width: '15px' }} />
            </IconButton>
          </div>
        </TableCell>
        {tableCol[statusFilterValue].map((col, colIndex) => {
          let style = mappingStyle[col]
          if (col === LEAD_COL.DUE_DATE && moment(getLeadColValueByKey(row, col)) < moment()) {
            style = { ...style, ...warningStyle }
          }

          return (
            <TableCell key={colIndex} style={style} className={styles.col}>
              {getLeadColValueByKey(row, col)}
            </TableCell>
          )
        })}

        {currentlyAvailableMenus[statusFilterValue].map((item, index) => {
          return (
            <TableCell align={'center'} key={index}>
              <Button
                size="small"
                variant="contained"
                className={styles.btn}
                onClick={(): void => {
                  setSelectedButton(index)
                  setAvailableBtnPopupStatus(!availableBtnPopupStatus)
                }}
              >
                {item}
              </Button>
            </TableCell>
          )
        })}
      </TableRow>
    </>
  )
}
export default React.memo(App)
