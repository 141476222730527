// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__chatContainer___3Ej79 {\n  margin-bottom: 10px; }\n  .index-module__chatContainer___3Ej79 .index-module__chatTable___27mCD th {\n    color: #ffffff;\n    background-color: #742dd2; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/ChatInfo/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;EADrB;IAKM,cAAc;IACd,yBAAyB,EAAA","file":"index.module.scss","sourcesContent":[".chatContainer {\n  margin-bottom: 10px;\n\n  .chatTable {\n    th {\n      color: #ffffff;\n      background-color: #742dd2;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"chatContainer": "index-module__chatContainer___3Ej79",
	"chatTable": "index-module__chatTable___27mCD"
};
module.exports = exports;
