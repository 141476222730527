import { TableCell } from '@material-ui/core'
import React from 'react'
import Select, { OptionsType } from 'react-select'

import useRootData from '../../../../../hooks/useRootData'
import { LEAD_COL } from '../../../../../stores/lead/type'
import { mappingStyle } from '../../LeadRow'

export interface SelectFilterProps {
  col: LEAD_COL
  options: OptionsType<{
    label: string
    value: string
  }>
}

const App: React.FunctionComponent<SelectFilterProps> = ({ col, options }: SelectFilterProps) => {
  const { searchInputValues, _changeSearchInputValues } = useRootData(({ leadStore }) => ({
    searchInputValues: leadStore.searchInputValues.get(),
    _changeSearchInputValues: leadStore.changeSearchInputValues,
  }))

  const changeSearchSelectValues = (
    event: OptionsType<{
      label: string
      value: string
    }>,
    key: LEAD_COL,
  ) => {
    _changeSearchInputValues({ [key]: event })
  }

  return (
    <TableCell style={mappingStyle[col]}>
      <Select
        placeholder={'(다중선택)'}
        value={searchInputValues[col] as OptionsType<{ value: string; label: string }>}
        isClearable
        isMulti
        options={options}
        onChange={(event): void => changeSearchSelectValues(event, col)}
        clearValue={() => changeSearchSelectValues([], col)}
      />
    </TableCell>
  )
}
export default React.memo(App)
