import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core'
import moment from 'moment-timezone'
import React from 'react'

import { Lead_Status_Enum as LeadStatusEnum } from '../../../assets/graphql/graphql'
import Text from '../../../components/atoms/Text'
import useRootData from '../../../hooks/useRootData'
import styles from './index.module.scss'

export const defaultDate: {
  [key: string]: { startCreatedAt: moment.Moment; endCreatedAt: moment.Moment; endDueDate: moment.Moment }
} = {
  [LeadStatusEnum.Duplicate]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(2, 'days'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'weeks'),
  },
  [LeadStatusEnum.Missed]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(2, 'days'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'weeks'),
  },
  [LeadStatusEnum.Chat]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(2, 'days'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'weeks'),
  },
  [LeadStatusEnum.Lead]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(24, 'weeks'),
  },
  [LeadStatusEnum.Quote]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(24, 'weeks'),
  },
  [LeadStatusEnum.Match]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(24, 'weeks'),
  },
  [LeadStatusEnum.OrderPending]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.RsvPending]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.RsvConfirmed]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.PaymentPending]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.VatRequested]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.SettlementPending]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.Done]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.Cancel]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.Refunded]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
  [LeadStatusEnum.WillNotDo]: {
    startCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(100, 'year'),
    endCreatedAt: moment(moment(Date.now()).format('YYYY-MM-DD')).add(2, 'days'),
    endDueDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(100, 'year'),
  },
}

const App: React.FunctionComponent = () => {
  const {
    startCreatedAt,
    endCreatedAt,
    endDueDate,
    changeStartCreatedAt,
    changeEndCratedAt,
    changeEndDueDate,
    leadsSubscribe,
  } = useRootData(({ leadStore }) => ({
    startCreatedAt: leadStore.startCreatedAt.get(),
    endCreatedAt: leadStore.endCreatedAt.get(),
    endDueDate: leadStore.endDueDate.get(),

    changeStartCreatedAt: leadStore.changeStartCreatedAt,
    changeEndCratedAt: leadStore.changeEndCratedAt,
    changeEndDueDate: leadStore.changeEndDueDate,
    leadsSubscribe: leadStore.leadsSubscribe,
  }))

  return (
    <TableContainer className={styles.container} component={Paper}>
      <Table className={styles.table} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell>
              <div style={{ display: 'flex' }}>
                <Text style={{ marginRight: 10 }} text={'Created_At 범위'}></Text>
                <TextField
                  type="date"
                  value={moment(startCreatedAt).format('YYYY-MM-DD')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event): void => changeStartCreatedAt(event.target.value)}
                />
                <Text style={{ marginRight: 10 }} text={'~부터'}></Text>

                <TextField
                  type="date"
                  value={moment(endCreatedAt).format('YYYY-MM-DD')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event): void => changeEndCratedAt(event.target.value)}
                />
                <Text text={'~까지'}></Text>
                <Button style={{ marginLeft: 150 }} variant="outlined" onClick={(): Promise<void> => leadsSubscribe()}>
                  날짜 범위 적용
                </Button>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <div style={{ display: 'flex' }}>
                <Text style={{ marginRight: 10 }} text={'Due Date 범위'}></Text>

                <TextField
                  type="date"
                  value={moment(endDueDate).format('YYYY-MM-DD')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event): void => changeEndDueDate(event.target.value)}
                />
                <Text text={'~까지'}></Text>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default React.memo(App)
