import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Get_Store_Op_DataQuery as GetStoreOpDataQuery,
  Get_Store_Op_DataQueryVariables as GetStoreOpDataQueryVariables,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const GET_STORE_OP_DATA = gql`
  query GET_STORE_OP_DATA($id: uuid!) {
    store(where: { id: { _eq: $id } }) {
      name
      operation_memo
      operation_last_contact
      operation_gallery_link
    }
  }
`

const UPDATE_STORE_OP_DATA = gql`
  mutation UPDATE_STORE_OP_DATA_MEMO($id: uuid!, $memo: String, $date: timestamptz, $link: String) {
    update_store(
      where: { id: { _eq: $id } }
      _set: { operation_memo: $memo, operation_last_contact: $date, operation_gallery_link: $link }
    ) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { id } = parseQueryString()

  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [getStoreOpData, { data: storeOpData }] = useLazyQuery<GetStoreOpDataQuery, GetStoreOpDataQueryVariables>(
    GET_STORE_OP_DATA,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    },
  )

  const [memo, setMemo] = useState('')
  const [contactDate, setContactDate] = useState('')
  const [blogLink, setBlogLink] = useState('')

  useEffect(() => {
    getStoreOpData()
  }, [])

  useEffect(() => {
    if (storeOpData) {
      setMemo(storeOpData.store[0].operation_memo || '')
      setContactDate(storeOpData.store[0].operation_last_contact)
      setBlogLink(storeOpData.store[0].operation_gallery_link || '')
    }
  }, [storeOpData])

  if (!storeOpData) {
    return (
      <div className="body">
        <div className={styles.container}>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )
  }

  const sendData = async () => {
    try {
      await client.mutate({
        mutation: UPDATE_STORE_OP_DATA,
        fetchPolicy: 'no-cache',
        variables: {
          id,
          memo,
          date: contactDate || null,
          link: !blogLink.startsWith('http') && blogLink ? `http://${blogLink}` : blogLink,
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: `변경 성공.` })
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: `변경 실패.\n${err}` })
      Sentry.captureException(err)
    }
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <h1>{`매장: ${storeOpData.store[0].name}`}</h1>
        <div className={styles.inputBox}>
          <div>메모</div>
          <div>
            <textarea
              className={styles.largeInut}
              defaultValue={storeOpData.store[0].operation_memo}
              onChange={(e) => setMemo(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <div>연락일</div>
          <div>
            <input
              type={'date'}
              defaultValue={
                storeOpData.store[0].operation_last_contact
                  ? moment(storeOpData.store[0].operation_last_contact).local().format('YYYY-MM-DD')
                  : ''
              }
              onChange={(e) => setContactDate(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <div>매장 블로그 링크</div>
          <div>
            <input
              type={'url'}
              defaultValue={storeOpData.store[0].operation_gallery_link}
              onChange={(e) => setBlogLink(e.currentTarget.value)}
            />
          </div>
        </div>
        <button onClick={() => sendData()}>데이터 저장</button>
        <button onClick={() => changeMainMenu('/gallery-display')}>목록으로 돌아가기</button>
      </div>
    </div>
  )
}

export default React.memo(App)
