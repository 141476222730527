import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import { Package, Product } from '../../../assets/graphql/graphql'
import useRootData from '../../../hooks/useRootData'
import styles from './index.module.scss'

export interface PackageCardObject {
  id: string
  name?: string
  product?: Array<Product>
  brand?: string
  alias?: string
  price?: { discountPrice: number; originalPrice: number; totalPrice: number }
}

export interface PropsPackageList {
  packageCardArray: PackageCardObject[]
}

const App: React.FunctionComponent<PropsPackageList> = ({ packageCardArray }: PropsPackageList) => {
  const { changeMainMenu } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
  }))

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead className={styles.header}>
          <TableRow className={styles.colNameRow}>
            <TableCell className={styles.col}>이름</TableCell>
            <TableCell className={styles.col}>제품</TableCell>
            <TableCell className={styles.col}>정가 가격(국산 세단)</TableCell>
            <TableCell className={styles.col}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.body}>
          {packageCardArray
            .sort((a: Package, b: Package) => {
              if (a.name < b.name) {
                return -1
              }

              return 1
            })
            .map((_package, index) => {
              return (
                <TableRow className={styles.row} key={index}>
                  <TableCell className={styles.col}>{_package.name}</TableCell>
                  <TableCell className={styles.col} style={{ display: 'flex' }}>
                    {_package.product.map((_product, productIndex) => {
                      const isLastIndex = _package.product.length - 1

                      return (
                        <p key={productIndex}>
                          {_product.name} {productIndex !== isLastIndex && ' + '}
                        </p>
                      )
                    })}
                  </TableCell>
                  <TableCell className={styles.col}>{_package.price.originalPrice} 원</TableCell>
                  <TableCell className={styles.col}>
                    <button
                      onClick={(): void => {
                        changeMainMenu(`/package-detail?id=${_package.id}`)
                      }}
                    >
                      가격 편집
                    </button>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default React.memo(App)
