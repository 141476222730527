/* eslint-disable no-underscore-dangle */
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export interface CartSearchKeywordType {
  zendeskChatName: string
  cartId: string
  userEmail: string
}

// 카트 존재하는지 체크하고 id 가져오기
export const _CHECK_CART_EXISTENCE = (cartSearchKeyword: CartSearchKeywordType): DocumentNode => {
  const { zendeskChatName, cartId, userEmail } = cartSearchKeyword

  let query = ''

  if (zendeskChatName) {
    query = `(where: {sessionBySession: {chats: {zendesk_chat_name: {_eq: "${zendeskChatName}"}}}})`
  } else if (cartId) {
    query = `(where: {id: {_eq: "${cartId}"}})`
  } else if (userEmail) {
    query = `(where: {accountByAccount: {profile_kr_customer: {email: {_eq: "${userEmail}"}}}})`
  }

  return gql`
    query _CHECK_CART_EXISTENCE {
      n_cart ${query} {
        id
      }
    }
  `
}

// follow up용 카트 생성
export const _CREATE_CART = gql`
  mutation _CREATE_CART($cartObject: n_cart_insert_input!) {
    insert_n_cart_one(object: $cartObject) {
      id
    }
  }
`
