import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import Phonenumber from 'awesome-phonenumber'
import axios from 'axios'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Get_Customer_ProfileQuery as GetCustomerProfileQuery,
  Get_Customer_ProfileQueryVariables as GetCustomerProfileQueryVariables,
  Get_Profile_Partner_DetailQuery as GetProfilePartnerDetailQuery,
  Get_Profile_Partner_DetailQueryVariables as GetProfilePartnerDetailQueryVariables,
  Subscription,
} from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { checkIsValiedPhoneNumber, parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const GET_PROFILE_PARTNER_DETAIL = gql`
  query GET_PROFILE_PARTNER_DETAIL($id: uuid!) {
    profile_partner: profile_kr_partner(where: { id: { _eq: $id } }) {
      created_at
      name
      email
      email_verified_at
      phone_mobile
      email_verified_at
      stripe_customer_id
      rapyd_customer_id
      account
      accountByAccount {
        firebase_user_uid
        account_type {
          value
        }
        subscriptions {
          storeByStore {
            id
            email
            phone_business
            name
          }
        }
      }
    }
  }
`
const GET_CUSTOMER_PROFILE = gql`
  query GET_CUSTOMER_PROFILE($uid: String!) {
    profile_kr_customer(where: { accountByAccount: { firebase_user_uid: { _eq: $uid } } }) {
      id
    }
  }
`

const DELETE_PARTNER_ACCOUNT = gql`
  mutation DELETE_PARTNER_ACCOUNT($profileId: uuid!, $accountId: uuid!, $now: timestamptz) {
    update_profile_kr_partner_by_pk(pk_columns: { id: $profileId }, _set: { deleted_at: $now }) {
      id
    }
    update_account_by_pk(pk_columns: { id: $accountId }, _set: { deleted_at: $now }) {
      id
    }
  }
`

const DELETE_PARTNER_AND_CUSTOMER_ACCOUNT = gql`
  mutation DELETE_PARTNER_AND_CUSTOMER_ACCOUNT(
    $profileId: uuid!
    $customerProfileId: uuid!
    $accountId: uuid!
    $now: timestamptz
  ) {
    update_profile_kr_partner_by_pk(pk_columns: { id: $profileId }, _set: { deleted_at: $now }) {
      id
    }
    update_profile_kr_customer_by_pk(pk_columns: { id: $customerProfileId }, _set: { deleted_at: $now }) {
      id
    }
    update_account_by_pk(pk_columns: { id: $accountId }, _set: { deleted_at: $now }) {
      id
    }
  }
`

const UPDATE_PROFILE_PARTNER = gql`
  mutation UPDATE_PROFILE_PARTNER($account: uuid!, $newEmail: String!, $newName: String!, $phoneMobile: String!) {
    update_profile_kr_partner(
      where: { account: { _eq: $account } }
      _set: { email: $newEmail, name: $newName, phone_mobile: $phoneMobile }
    ) {
      affected_rows
    }
  }
`

const UPDATE_ALL_PROFILE = gql`
  mutation UPDATE_ALL_PROFILE($account: uuid!, $newEmail: String!, $newName: String!, $phoneMobile: String!) {
    update_profile_kr_partner(
      where: { account: { _eq: $account } }
      _set: { email: $newEmail, name: $newName, phone_mobile: $phoneMobile }
    ) {
      affected_rows
    }
    update_profile_kr_customer(
      where: { account: { _eq: $account } }
      _set: { email: $newEmail, name: $newName, phone_mobile: $phoneMobile }
    ) {
      affected_rows
    }
  }
`

// update firebase email
const updateFirebaseEmail = async (uid: string, email: string, newEmail: string): Promise<void> => {
  return axios.post(`${config.backendEndPoint}/auth/update-firebase-email`, {
    uid,
    email,
    newEmail,
  })
}

// delete firebae account
const deleteFirebaseAccount = async (uid: string, stripeCustomerId: string): Promise<void> => {
  return axios.post(`${config.backendEndPoint}/auth/delete-firebase-account`, {
    uid,
    stripeCustomerId,
  })
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id } = parseQueryString()

  const [editProfilePartnerClicked, setEditProfilePartnerClicked] = useState(!id)
  const [newEmail, setNewEmail] = useState('')
  const [newName, setNewName] = useState('')
  const [newPhoneMobile, setNewPhoneMobile] = useState('')

  const [getData, { data: profilePartnerContents, error: getProfilePartner }] = useLazyQuery<
    GetProfilePartnerDetailQuery,
    GetProfilePartnerDetailQueryVariables
  >(GET_PROFILE_PARTNER_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: { id },
  })

  const [getCustomerProfile, { data: customerProfile, error: getProfileCustomer }] = useLazyQuery<
    GetCustomerProfileQuery,
    GetCustomerProfileQueryVariables
  >(GET_CUSTOMER_PROFILE, {
    fetchPolicy: 'no-cache',
    variables: {
      uid: profilePartnerContents ? profilePartnerContents.profile_partner[0].accountByAccount.firebase_user_uid : '',
    },
  })

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (profilePartnerContents) {
      setNewEmail(profilePartnerContents.profile_partner[0].email)
      setNewName(profilePartnerContents.profile_partner[0].name)
      setNewPhoneMobile(
        profilePartnerContents.profile_partner[0].phone_mobile
          ? new Phonenumber(profilePartnerContents.profile_partner[0].phone_mobile).getNumber('national')
          : '',
      )
    }
    getCustomerProfile()
  }, [profilePartnerContents])

  if (getProfilePartner || getProfileCustomer) {
    const error = getProfilePartner || getProfileCustomer
    Sentry.captureException(error)
  }
  if (!profilePartnerContents) return null

  const profilePartnerDetails = profilePartnerContents.profile_partner[0]

  return (
    <div className="body">
      <div className={styles.container}>
        <div>파트너 고객 상세</div>

        <div className={styles.editButtonArea}>
          {!editProfilePartnerClicked ? (
            <button
              className={styles.button}
              onClick={async (): Promise<void> => {
                setEditProfilePartnerClicked(true)
              }}
            >
              수정하기
            </button>
          ) : (
            <div>
              <button
                className={styles.button}
                onClick={async (): Promise<void> => {
                  try {
                    if (!checkIsValiedPhoneNumber(newPhoneMobile)) {
                      changeSnackbarAlertContent({ severity: 'error', content: '고객 전화번호가 올바르지 않습니다!' })

                      return
                    }

                    const phoneMobile = new Phonenumber(newPhoneMobile.replace(/-/gi, ''), 'KR').getNumber('e164')

                    setEditProfilePartnerClicked(false)

                    const profileQuery =
                      customerProfile.profile_kr_customer.length > 0 ? UPDATE_ALL_PROFILE : UPDATE_PROFILE_PARTNER
                    // update firebase email

                    await updateFirebaseEmail(
                      profilePartnerDetails.accountByAccount.firebase_user_uid,
                      profilePartnerDetails.email,
                      newEmail,
                    )

                    // update partner user email
                    await client.mutate({
                      mutation: profileQuery,
                      variables: { account: profilePartnerDetails.account, newEmail, newName, phoneMobile },
                    })

                    // update stripe email
                    if (profilePartnerDetails.stripe_customer_id) {
                      await axios.post(`${config.backendEndPoint}/stripe/update-user-email`, {
                        customerId: profilePartnerDetails.stripe_customer_id,
                        email: newEmail,
                      })
                    }

                    // update rapyd email
                    if (profilePartnerDetails.rapyd_customer_id) {
                      await axios.post(`${config.backendEndPoint}/rapyd/update-user-email`, {
                        customerId: profilePartnerDetails.rapyd_customer_id,
                        email: newEmail,
                      })
                    }

                    changeSnackbarAlertContent({ severity: 'success', content: '저장 완료' })
                  } catch (err) {
                    Sentry.captureException(err)

                    changeSnackbarAlertContent({ severity: 'error', content: `${err}저장 실패` })

                    // TODO: Revert mutaion query if this process failed
                  }
                }}
              >
                저장하기
              </button>
            </div>
          )}
        </div>

        <table>
          <tbody>
            <tr>
              <td>가입 날짜</td>
              <td className={editProfilePartnerClicked ? styles.data : null}>
                {moment(profilePartnerDetails.created_at).format()}
              </td>
            </tr>
            <tr>
              <td>가입 방법</td>
              <td className={editProfilePartnerClicked ? styles.data : null}>
                {profilePartnerDetails.accountByAccount.account_type.value}
              </td>
            </tr>
            <tr>
              <td>이름</td>
              <td className={editProfilePartnerClicked ? styles.data : null}>
                <input
                  style={!editProfilePartnerClicked ? { border: 'none' } : { border: 'purple' }}
                  defaultValue={!editProfilePartnerClicked ? profilePartnerDetails.name : newName}
                  onChange={(e): void => {
                    setNewName(e.target.value)
                  }}
                  disabled={!editProfilePartnerClicked}
                ></input>
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td className={editProfilePartnerClicked ? styles.data : null}>
                <input
                  style={!editProfilePartnerClicked ? { border: 'none' } : { border: 'purple' }}
                  defaultValue={!editProfilePartnerClicked ? profilePartnerDetails.email : newEmail}
                  onChange={(e): void => {
                    setNewEmail(e.target.value)
                  }}
                  disabled={!editProfilePartnerClicked}
                />
              </td>
            </tr>
            <tr>
              <td>이메일 인증 상태</td>
              <td className={editProfilePartnerClicked ? styles.data : null}>
                {profilePartnerDetails.email_verified_at ? '인증 완료' : '미인증'}
              </td>
            </tr>
            <tr>
              <td>휴대폰</td>
              <td className={editProfilePartnerClicked ? styles.data : null}>
                <input
                  style={!editProfilePartnerClicked ? { border: 'none' } : { border: 'purple' }}
                  defaultValue={newPhoneMobile}
                  onChange={(e): void => {
                    setNewPhoneMobile(e.target.value)
                  }}
                  disabled={!editProfilePartnerClicked}
                ></input>
              </td>
            </tr>
            <tr>
              <td>stripe customer id</td>
              <td className={editProfilePartnerClicked ? styles.data : null}>
                {profilePartnerDetails.stripe_customer_id}
              </td>
            </tr>
            <tr>
              <td
                className={editProfilePartnerClicked ? styles.data : null}
                rowSpan={profilePartnerDetails.accountByAccount.subscriptions.length + 1}
              >
                입점 신청 내역
              </td>
            </tr>
            {profilePartnerDetails.accountByAccount.subscriptions.map((subscription: Subscription, index: number) => (
              <tr key={index}>
                <td className={editProfilePartnerClicked ? styles.data : null}>
                  {`${subscription.storeByStore.name} / ${
                    subscription.storeByStore.phone_business
                      ? new Phonenumber(subscription.storeByStore.phone_business).getNumber('national')
                      : null
                  }`}
                </td>
                <td>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/store-detail?id=${subscription.storeByStore.id}`)
                    }}
                  >
                    매장 상세 보기
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className={styles.deleteAccountButton}
          onClick={async (): Promise<void> => {
            try {
              // eslint-disable-next-line no-alert
              if (window.confirm('정말 회원을 탈퇴시키겠습니까?')) {
                const accountChecker =
                  customerProfile &&
                  customerProfile.profile_kr_customer &&
                  customerProfile.profile_kr_customer.length > 0
                await deleteFirebaseAccount(
                  profilePartnerDetails.accountByAccount.firebase_user_uid,
                  profilePartnerDetails.stripe_customer_id,
                )

                const mutaionObject = {
                  mutation: DELETE_PARTNER_ACCOUNT,
                  variables: {
                    profileId: id,
                    accountId: profilePartnerDetails.account,
                    now: new Date().toUTCString(),
                  },
                }

                const mutaionAllObject = {
                  mutation: DELETE_PARTNER_AND_CUSTOMER_ACCOUNT,
                  variables: {
                    profileId: id,
                    accountId: profilePartnerDetails.account,
                    now: new Date().toUTCString(),
                    customerProfileId: accountChecker ? customerProfile.profile_kr_customer[0].id : '',
                  },
                }

                await client.mutate(accountChecker ? mutaionAllObject : mutaionObject)

                changeSnackbarAlertContent({
                  severity: 'success',
                  content: `회원 탈퇴를 성공적으로 완료했습니다.\n${
                    profilePartnerDetails.stripe_customer_id
                      ? '계정과 연결된 stripe subscription도 cancel처리 되었습니다. 확인해주세요.'
                      : ''
                  }`,
                })
                changeMainMenu('/profile-partner')
              } else {
                changeSnackbarAlertContent({ severity: 'info', content: '취소했습니다.' })
              }
            } catch (err) {
              Sentry.captureException(err)

              changeSnackbarAlertContent({ severity: 'error', content: '회원 탈퇴를 실패했습니다.' })
            }
          }}
        >
          회원 탈퇴
        </button>
      </div>
    </div>
  )
}
export default React.memo(App)
