import { Button, ButtonGroup, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import * as Sentry from '@sentry/react'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

import {
  Config_Type_Enum as ConfigTypeEnum,
  Product,
  Product_Item as ProductItem,
  Product_Type as ProductType,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import {
  _GET_CONFIG_RATE as GET_CONFIG_RATE,
  _INSERT_NEW_CONFIG_RATE as INSERT_NEW_DISCOUNT_RATE_TABLE,
} from '../../stores/calculator/query'
import client from '../../utils/graphql'
import { parseCSVString } from '../../utils/utility'
import ALL_PRODUCT_AND_PRODUCT_ITEMS from './query'
import TableEditor from './TableEditor'

type ReactSelectOption = {
  value: string
  label: string
}
type CSVTable = Array<Array<string>>

const App: React.FunctionComponent = () => {
  const {
    snackbarOpened,
    accountId,
    changeSnackbarVisibility,
    changeSnackbarAlertContent,
    getDefaultRates,
  } = useRootData(({ appStore, authStore, calculatorStore }) => ({
    snackbarOpened: appStore.snackbarOpened.get(),
    accountId: authStore.accountId.get(),
    changeSnackbarVisibility: appStore.changeSnackbarVisibility,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    getDefaultRates: calculatorStore.getDefaultRates,
  }))
  const [csvTable, setCsvTable] = useState<CSVTable>(null)
  const [skuLevelCsvTable, setSkuLevelCsvTable] = useState<CSVTable>(null)
  const [productTypes, setProductTypes] = useState(null)
  const [productById, setProductById] = useState(null)
  const [productItemById, setProductItemById] = useState(null)
  const [updatedAt, setUpdatedAt] = useState(null)
  const [skuArray, setSkuArray] = useState<Array<ReactSelectOption>>(null)
  const [selectedSku, setSelectedSku] = useState<ProductItem>(null)

  const transposeTable = (table: Array<Array<string>>) => {
    return table[0].map((_, c) => table.map((row) => row[c]))
  }

  useEffect(() => {
    changeSnackbarVisibility(false)
    ;(async () => {
      const { csv_content: defaultDiscountRateTable, updated_at: updatedAtData } = (
        await client.query({
          query: GET_CONFIG_RATE(ConfigTypeEnum.DefaultDiscountRate),
        })
      ).data.config[0]

      setUpdatedAt(updatedAtData)

      const {
        data: { product: allProducts, product_type: productType, product_item: productItems },
      } = await client.query({
        query: ALL_PRODUCT_AND_PRODUCT_ITEMS,
      })

      setProductById(new Map<string, Product>(allProducts.map((product: Product) => [product.id, product])))
      setProductItemById(
        new Map<string, Product>(productItems.map((productItem: ProductItem) => [productItem.id, productItem])),
      )
      setSkuArray([
        {
          value: '',
          label: '선택 안함',
        },
        ...productItems.map((productItem: ProductItem) => ({
          value: productItem.id,
          label: productItem.sku,
        })),
      ])

      setProductTypes(productType)

      const parsedCsvTable = transposeTable(parseCSVString(defaultDiscountRateTable.csv))
      const parsedSkuLevelCsvTable = transposeTable(parseCSVString(defaultDiscountRateTable.sku_level_csv))

      const convertedTable = [
        parsedCsvTable[0],
        parsedCsvTable[1],
        ...allProducts.map((product: Product) => {
          const existRow = parsedCsvTable.filter((row) => row[0] === product.name || row[0] === product.id)

          return [
            product.id,
            ...(existRow.length ? existRow[0].slice(1) : Array(parsedCsvTable[0].length - 1).fill('0:0')),
          ]
        }),
      ]

      setCsvTable(convertedTable)
      setSkuLevelCsvTable(parsedSkuLevelCsvTable)
    })()
  }, [])

  const addNewProductItem = () => {
    if (!selectedSku) {
      // eslint-disable-next-line no-alert
      alert('추가할 sku를 먼저 선택해 주세요!')

      return
    }
    setSkuLevelCsvTable([...skuLevelCsvTable, [selectedSku.id, ...Array(skuLevelCsvTable[0].length - 1).fill('0:0')]])
  }

  const changeDiscountRate = (
    setter: (value: CSVTable) => void,
    table: CSVTable,
    soleName: string,
    areaName: string,
    itemId: string,
    newRate: string,
  ) => {
    setter(
      table.map((rowItem: Array<string>) =>
        rowItem.map((item: string, index: number) => {
          if (rowItem[0] === itemId && soleName === csvTable[0][index] && areaName === csvTable[1][index]) {
            return newRate
          }

          return item
        }),
      ),
    )
  }

  const saveNewDefaultPriceTable = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('새로운 할인율 테이블을 추가하겠습니까?')) return

    try {
      const {
        insert_config_one: { updated_at: updatedAtData },
      } = (
        await client.mutate({
          mutation: INSERT_NEW_DISCOUNT_RATE_TABLE,
          variables: {
            object: {
              account: accountId,
              type: ConfigTypeEnum.DefaultDiscountRate,
              csv_content: {
                csv: transposeTable(csvTable)
                  .map((rowItem: Array<string>) => rowItem.join(','))
                  .join('\n'),
                sku_level_csv: transposeTable(skuLevelCsvTable)
                  .map((rowItem: Array<string>) => rowItem.join(','))
                  .join('\n'),
              },
            },
          },
        })
      ).data

      setUpdatedAt(updatedAtData)

      changeSnackbarVisibility(true)
      getDefaultRates()
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '저장(수정) 실패' })
    }
  }

  const cellClickHandler = (
    setter: (value: CSVTable) => void,
    table: CSVTable,
    isLowest: boolean,
    item: string,
    index: number,
    productId: string,
    productName: string,
  ) => {
    const soleName = csvTable[0][index + 1]
    const areaName = csvTable[1][index + 1]

    const lrate = item.split(':')[0]
    const prate = item.split(':')[1]

    // eslint-disable-next-line no-alert
    const ret = prompt(
      `"${soleName}" 총판의 "${areaName}" 지역 제품 "${productName}" 기본 할인가 수정`,
      isLowest ? lrate : prate,
    )
    if (ret && Number.isInteger(parseInt(ret, 10))) {
      changeDiscountRate(
        setter,
        table,
        soleName,
        areaName,
        productId,
        isLowest ? `${parseFloat(ret)}:${prate}` : `${lrate}:${parseFloat(ret)}`,
      )
    } else if (ret) {
      // eslint-disable-next-line no-alert
      alert('값이 잘못되었습니다.')
    }
  }

  return (
    <div>
      <Snackbar
        open={snackbarOpened}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={10000}
        onClose={() => changeSnackbarVisibility(false)}
      >
        <Alert variant="filled" onClose={() => changeSnackbarVisibility(false)} severity="success">
          저장되었습니다.
        </Alert>
      </Snackbar>
      <ButtonGroup size="large" color="primary" aria-label="primary button group">
        <Button onClick={async (): Promise<void> => saveNewDefaultPriceTable()}>새로운 할인율 테이블 저장</Button>
      </ButtonGroup>
      <h4>분류</h4>
      <ul>
        <li>L: Lowest Price Discount Rate</li>
        <li>P: Premium Price Discount Rate</li>
      </ul>
      <p>편집할 cell을 선택하세요</p>
      <p>마지막 업데이트: {moment(updatedAt).toLocaleString()}</p>

      <h3>sku 레벨 할인율</h3>
      <ReactSelect
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        value={
          selectedSku
            ? { value: selectedSku.id, label: selectedSku.sku }
            : {
                value: '',
                label: '',
              }
        }
        onChange={({ value }): void => setSelectedSku(productItemById.get(value as string))}
        isSearchable
        options={skuArray}
      />
      <ButtonGroup size="small" color="primary" aria-label="primary button group">
        <Button onClick={() => addNewProductItem()}>sku 단위 기본 할인율 추가</Button>
      </ButtonGroup>
      {skuLevelCsvTable && (
        <TableEditor
          soleNames={skuLevelCsvTable[0].slice(1)}
          areaNames={skuLevelCsvTable[1].slice(1)}
          discountRateRowsWithId={skuLevelCsvTable.slice(2)}
          displayNameById={(productItemId: string): string => {
            return productItemById.get(productItemId).sku
          }}
          cellClickHandler={(isLowest: boolean, item: string, index: number, productId: string, productName: string) =>
            cellClickHandler(setSkuLevelCsvTable, skuLevelCsvTable, isLowest, item, index, productId, productName)
          }
          removeHandler={(productItemId: string) => {
            setSkuLevelCsvTable([...skuLevelCsvTable.filter((row) => row[0] !== productItemId)])
          }}
        />
      )}

      {productTypes &&
        productTypes.map((productType: ProductType, tableIndex: number) => (
          <div key={tableIndex}>
            <h3>{productType.description}</h3>
            {csvTable && productById && (
              <TableEditor
                soleNames={csvTable[0].slice(1)}
                areaNames={csvTable[1].slice(1)}
                discountRateRowsWithId={csvTable
                  .slice(2)
                  .filter(
                    (tableRow: Array<string>) => productById.get(tableRow[0]).product_type.value === productType.value,
                  )}
                displayNameById={(productId: string): string => {
                  const product = productById.get(productId)

                  return `[${product.product_brand.description}] ${product.name}`
                }}
                cellClickHandler={(
                  isLowest: boolean,
                  item: string,
                  index: number,
                  productId: string,
                  productName: string,
                ) => cellClickHandler(setCsvTable, csvTable, isLowest, item, index, productId, productName)}
              />
            )}
          </div>
        ))}
    </div>
  )
}
export default React.memo(App)
