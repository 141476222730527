import axios from 'axios'

import leadStore from '../stores/lead'

const config = {
  headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' },
}

export interface Version {
  hash: string
}

let version: Version = {
  hash: null,
}

const checkVersionAndRefresh = async (): Promise<Version> => {
  const { data: versionData } = await axios.get('/version.json', config)

  if (version.hash) {
    if (versionData.hash !== version.hash) {
      await leadStore.leadUnsubscribe()
      await leadStore.leadsSubscribe()
      await new Promise((r) => setTimeout(r, 1000))

      window.location.reload()
    }
  }

  version = versionData

  return versionData
}

export default checkVersionAndRefresh
