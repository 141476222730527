import { Button, TextField } from '@material-ui/core'
import React, { useState } from 'react'

import Select from '../../../../components/atoms/Select'
import useRootData from '../../../../hooks/useRootData'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const { email, insertLeadComment, chatAsigneeArray, getSelectedAccountObject } = useRootData(
    ({ authStore, leadStore, calculatorStore }) => ({
      email: authStore.email.get(),
      insertLeadComment: leadStore.insertLeadComment,
      chatAsigneeArray: leadStore.chatAsigneeArray,
      getSelectedAccountObject: calculatorStore.getSelectedAccountObject,
    }),
  )

  const [newComment, setNewComment] = useState('')
  const [selectedMentionUser, setSelectedMentionUser] = useState('')

  const insertCommentHandler = async () => {
    if (!newComment.trim()) return

    await insertLeadComment(
      `${
        selectedMentionUser
          ? `@From ${email}\n@To ${
              getSelectedAccountObject('chat_assignees', selectedMentionUser, null).profile_riderdash.email
            }\n`
          : ''
      }${newComment}`,
    )
    setNewComment('')
    setSelectedMentionUser('')
  }

  return (
    <div className={styles.commentInputArea}>
      <Select
        label={'@멘션하기'}
        style={{ marginBottom: 5 }}
        value={selectedMentionUser}
        options={chatAsigneeArray}
        changeHandler={(event): void => setSelectedMentionUser(event.target.value as string)}
      />
      <TextField
        label="COMMENT 입력"
        multiline
        rows={4}
        className={styles.commentInput}
        value={newComment}
        variant="outlined"
        onChange={(e) => setNewComment(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            insertCommentHandler()
          }
        }}
      />
      <Button variant="contained" color="primary" className={styles.commentSubmit} onClick={insertCommentHandler}>
        저장
      </Button>
    </div>
  )
}

export default React.memo(App)
