// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___mf1pf {\n  padding: 20px;\n  margin-bottom: 40px; }\n  .index-module__container___mf1pf .index-module__tableContainer___1YJ7b {\n    margin-bottom: 50px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/StoreAndPackage/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAKI,mBAAmB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  margin-bottom: 40px;\n\n  .tableContainer {\n    margin-bottom: 50px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___mf1pf",
	"tableContainer": "index-module__tableContainer___1YJ7b"
};
module.exports = exports;
