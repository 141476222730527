import React from 'react'

import CarInfoAndAreaTable from './CarInfoAndAreaTable'
import CustomerInfoTable from './CustomerInfoTable'
import LeadDetailAndAdministratorTable from './LeadDetailAndAdministratorTable'

const App: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <CarInfoAndAreaTable></CarInfoAndAreaTable>
      <LeadDetailAndAdministratorTable></LeadDetailAndAdministratorTable>
      <CustomerInfoTable></CustomerInfoTable>
    </React.Fragment>
  )
}

export default React.memo(App)
