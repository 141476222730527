// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body {\n  margin: 0px;\n  min-width: 750px;\n  font-family: Arial, Helvetica, sans-serif; }\n\n.body {\n  display: flex;\n  flex-direction: row; }\n  .body .rstm-tree-item-level1 {\n    padding-left: 0.5em !important; }\n  .body ul {\n    list-style-type: none;\n    padding-inline-start: 0px; }\n  .body li > div:first-child {\n    float: left;\n    margin-right: 10px; }\n  .body li {\n    border: 1px solid #777777;\n    border-radius: 5px;\n    height: 30px;\n    line-height: 30px; }\n  .body table {\n    border-collapse: collapse;\n    width: 100%;\n    font-size: 14px; }\n    .body table td,\n    .body table th {\n      border: 1px solid #555555; }\n    .body table tr:nth-child(2n) {\n      background-color: #efefef; }\n      .body table tr:nth-child(2n) input {\n        background-color: #efefef; }\n\ntextarea {\n  overflow: auto;\n  outline: none;\n  -webkit-box-shadow: none;\n  -moz-box-shadow: none;\n  box-shadow: none;\n  resize: none;\n  /*remove the resize handle on the bottom right*/ }\n", ""]);
// Exports
exports.locals = {
	"balloonBlack": "#101010",
	"black": "#000000",
	"blueLightGrey1": "#f6f8f9",
	"blueLightGrey2": "#eff1f4",
	"blueLightGrey3": "#e9ecef",
	"blueLightGrey4": "#e6e7ee",
	"blueLightGrey5": "#dadce5",
	"bodyBlack": "#333333",
	"bodyGrey": "#acacac",
	"charcoal": "#545454",
	"darkCharcoal": "#4c4c4c",
	"darkGrey": "#6d7278",
	"deepPurple": "#673ab7",
	"deepPurple2": "#b388ff",
	"favouriteYellow": "#ffd54f",
	"grey1": "#efefef",
	"grey2": "#e1e1e1",
	"grey3": "#d8d8d8",
	"grey4": "#adadad",
	"greyshWhite": "#fcfcfc",
	"hashtagPurple": "#f6f1ff",
	"headerBottom": "#ededed",
	"lightGreen": "#13c4a3",
	"lightGrey": "#f8f8f8",
	"neonPurple": "#7c4dff",
	"placeholder": "#b7b7b7",
	"primaryPurple": "#742dd2",
	"white": "#ffffff"
};
module.exports = exports;
