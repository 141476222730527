// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3Jml6 {\n  margin-bottom: 10px; }\n  .index-module__container___3Jml6 .index-module__table___3IjE_ .index-module__head___2KU2N .index-module__row___3jcUq {\n    background-color: #b388ff; }\n    .index-module__container___3Jml6 .index-module__table___3IjE_ .index-module__head___2KU2N .index-module__row___3jcUq .index-module__col___2NbJ1 {\n      color: #ffffff;\n      font-weight: bold; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/LeadInfo/LeadDetailAndAdministratorTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;EADrB;IAMQ,yBAAyB,EAAA;IANjC;MAQU,cAAc;MACd,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin-bottom: 10px;\n\n  .table {\n    .head {\n      .row {\n        background-color: #b388ff;\n        .col {\n          color: #ffffff;\n          font-weight: bold;\n        }\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3Jml6",
	"table": "index-module__table___3IjE_",
	"head": "index-module__head___2KU2N",
	"row": "index-module__row___3jcUq",
	"col": "index-module__col___2NbJ1"
};
module.exports = exports;
