import { Button, TextField } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import qs from 'qs'
import React, { useEffect, useState } from 'react'

import { Cart_Item as CartItem } from '../../assets/graphql/graphql'
import Text from '../../components/atoms/Text'
import CartItemBox from '../../components/molecule/ProductItemBox'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { autoHypenPhoneNumber, parseQueryString } from '../../utils/utility'
import { _GET_CART as GET_CART, _REMOVE_CART_ITEM_FROM_CART as REMOVE_CART_ITEM_FROM_CART } from './query'

const App: React.FunctionComponent = () => {
  const { sendAxios, changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    sendAxios: appStore.sendAxios,
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { cartId } = parseQueryString()

  const [phone, setPhone] = useState<string>('')
  const [cartItems, setCartItems] = useState<Array<CartItem>>([])
  const [isAccountCart, setIsAccountCart] = useState<boolean>(false)

  const isEmpty = cartItems.length === 0

  const getCart = () => {
    client
      .query({
        query: GET_CART,
        variables: {
          cartId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: content }) => {
        setIsAccountCart(!!content.n_cart_by_pk.account)
        setCartItems(content.n_cart_by_pk.cart_items)
      })
  }

  useEffect(() => {
    getCart()
  }, [])

  const createCartItemButtonClickHandler = () => {
    changeMainMenu(`/cart-item-detail?cartId=${cartId}`)
  }

  const sendFollowUpCartButtonClickHandler = async () => {
    try {
      await sendAxios.get(`${config.backendEndPoint}/send/follow-up-cart`, {
        params: {
          phoneNumber: phone,
          cartId,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params)
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '전송 성공' })
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: '전송 실패' })
      Sentry.captureException(err)
    }
  }

  const detailButtonClickHandler = (index: number) => {
    changeMainMenu(`/cart-item-detail?cartId=${cartId}&cartItemId=${cartItems[index].id}`)
  }

  const deleteButtonClickHandler = async (index: number) => {
    try {
      const query = `{id : { _eq: "${cartItems[index].id}"}}`

      await client.mutate({
        mutation: REMOVE_CART_ITEM_FROM_CART(query),
        variables: {
          deletedAt: new Date().toUTCString(),
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '삭제 성공' })
      getCart()
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '삭제 실패' })
    }
  }

  const sendQuoteButtonClickHandler = async (index: number) => {
    try {
      await sendAxios.post(`${config.backendEndPoint}/send/send-quote-to-store-kakao`, {
        data: cartItems[index],
      })
      changeSnackbarAlertContent({ severity: 'success', content: '전송 성공' })
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: '전송 실패' })
      Sentry.captureException(err)
    }
  }

  return (
    <div>
      <Text style={{ fontSize: 16, borderBottom: '1px solid', width: 400 }} text={`Cart ID : ${cartId}`}></Text>

      {isEmpty && <Text style={{ fontSize: 16, marginTop: 10 }} text={'장바구니가 비어있습니다.'}></Text>}

      {!isEmpty && !isAccountCart && (
        <div>
          <Text style={{ fontSize: 16, marginTop: 10 }} text={`유저에게 follow up cart 알람톡 전송`}></Text>

          <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
            <TextField
              onKeyPress={(e) => {
                if (e.key === 'Enter') sendFollowUpCartButtonClickHandler()
              }}
              style={{ width: 400 }}
              value={phone}
              label={'유저 핸드폰 번호'}
              variant={'outlined'}
              placeholder={'010-xxxx-xxxx'}
              onChange={(e) => setPhone(autoHypenPhoneNumber(e.target.value))}
            ></TextField>
            <Button onClick={(): Promise<void> => sendFollowUpCartButtonClickHandler()} variant="contained">
              전송
            </Button>
          </div>
        </div>
      )}

      <Button
        style={{ marginTop: 10, marginBottom: 20 }}
        onClick={(): void => createCartItemButtonClickHandler()}
        variant="contained"
      >
        카트 아이템 생성
      </Button>

      {cartItems.map((cartItem, index) => {
        return (
          <div style={{ marginTop: 20 }} key={index}>
            <Text text={`Cart Item ID : ${cartItem.id}`}></Text>
            <CartItemBox
              cartItemObject={cartItem}
              detailButtonClickHandler={(): void => detailButtonClickHandler(index)}
              deleteButtonClickHandler={(): Promise<void> => deleteButtonClickHandler(index)}
              sendQuoteButtonClickHandler={(): Promise<void> => sendQuoteButtonClickHandler(index)}
            ></CartItemBox>
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(App)
