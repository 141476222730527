// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__commentsContainer___1IJap {\n  width: 600px;\n  height: 870px; }\n  .index-module__commentsContainer___1IJap .index-module__header___3cTSh {\n    width: 600px;\n    display: flex;\n    justify-content: space-between; }\n    .index-module__commentsContainer___1IJap .index-module__header___3cTSh .index-module__title___3OvfR {\n      font-size: 24px;\n      font-weight: bold; }\n  .index-module__commentsContainer___1IJap .index-module__scrollArea___1Oe0t {\n    overflow-y: auto;\n    height: 640px;\n    margin-top: 10px;\n    background-color: #efefef; }\n    .index-module__commentsContainer___1IJap .index-module__scrollArea___1Oe0t .index-module__scrollableArea___3bz3D {\n      display: flex;\n      flex-direction: column;\n      padding: 15px;\n      overflow-y: scroll; }\n  .index-module__commentsContainer___1IJap .index-module__noCommentResult___2Nakq {\n    text-align: center;\n    padding: 295px 0px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Comment/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa,EAAA;EAFf;IAKI,YAAY;IACZ,aAAa;IACb,8BAA8B,EAAA;IAPlC;MASM,eAAe;MACf,iBAAiB,EAAA;EAVvB;IAcI,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,yBAAyB,EAAA;IAjB7B;MAmBM,aAAa;MACb,sBAAsB;MACtB,aAAa;MACb,kBAAkB,EAAA;EAtBxB;IA0BI,kBAAkB;IAClB,kBAAkB,EAAA","file":"index.module.scss","sourcesContent":[".commentsContainer {\n  width: 600px;\n  height: 870px;\n\n  .header {\n    width: 600px;\n    display: flex;\n    justify-content: space-between;\n    .title {\n      font-size: 24px;\n      font-weight: bold;\n    }\n  }\n  .scrollArea {\n    overflow-y: auto;\n    height: 640px;\n    margin-top: 10px;\n    background-color: #efefef;\n    .scrollableArea {\n      display: flex;\n      flex-direction: column;\n      padding: 15px;\n      overflow-y: scroll;\n    }\n  }\n  .noCommentResult {\n    text-align: center;\n    padding: 295px 0px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"commentsContainer": "index-module__commentsContainer___1IJap",
	"header": "index-module__header___3cTSh",
	"title": "index-module__title___3OvfR",
	"scrollArea": "index-module__scrollArea___1Oe0t",
	"scrollableArea": "index-module__scrollableArea___3bz3D",
	"noCommentResult": "index-module__noCommentResult___2Nakq"
};
module.exports = exports;
