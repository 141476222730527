import pica from 'pica'

const p = pica()

const blobToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    reader.readAsDataURL(file)
  })
}
const base64ToImage = async (base64data: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image()

    image.src = base64data

    image.onload = () => {
      resolve(image)
    }
  })
}

export const resize = async (original: Blob, newSize: { width?: number; height?: number }): Promise<Blob> => {
  const { width, height } = newSize
  const from = document.createElement('canvas')
  const to = document.createElement('canvas')

  const context = from.getContext('2d')
  const image = await base64ToImage(await blobToBase64(original))
  from.width = image.width
  from.height = image.height
  context.drawImage(image, 0, 0)

  if (width && height) {
    to.width = width
    to.height = height
  } else if (height) {
    to.width = Math.floor(image.width * (height / image.height))
    to.height = height
  } else if (width) {
    to.width = width
    to.height = Math.floor(image.height * (width / image.width))
  } else {
    // original image
    to.width = image.width
    to.height = image.height
  }

  const converted = await p.toBlob(
    await p.resize(from, to, {
      quality: 3,
    }),
    'image/jpeg',
  )

  return converted
}

export default resize
