import _ from 'lodash'
import { observable } from 'mobx'

import { Order } from '../../assets/graphql/graphql'
import client from '../../utils/graphql'
import { Remark } from '../quote'
import fillOrderFromCalculator from './orderFromCalculator'
import { pickedColumns, UPDATE_ORDER_WITH_LEAD } from './query'

const createStore = (): typeof orderStore => {
  const orderStore = {
    // Order information
    orderObject: observable.box<Order>(null),
    clearOrderObject: () => orderStore.orderObject.set(null),
    setOrderObject: (data: Order) => orderStore.orderObject.set(data),
    writeRemarkOrder: async (remark: Remark) => {
      const orderObject = orderStore.orderObject.get()

      orderObject.remark = {
        ...orderObject.remark,
        ...remark,
      }

      orderStore.orderObject.set(orderObject)
    },

    fillOrderFromCalculator,
    // Update to order table
    updateOrder: async () => {
      const orderObject = orderStore.orderObject.get()
      const { id: orderId } = orderStore.orderObject.get()

      await client.mutate({
        mutation: UPDATE_ORDER_WITH_LEAD,
        variables: {
          orderId,
          orderObject: {
            ..._.pick(orderObject, ...pickedColumns),
          },
        },
      })
    },
  }

  return orderStore
}

const store = createStore()
export default store
