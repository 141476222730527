/* eslint-disable no-underscore-dangle */
import gql from 'graphql-tag'

export const _GET_CART_ITEM = gql`
  query _GET_CART_ITEM($cartItemId: uuid!) {
    cart_item_by_pk(id: $cartItemId) {
      id
      created_at
      updated_at
      car
      carByCar {
        id
        model
        type
        car_maker {
          name_kr
          name_us
          car_origin {
            name_kr
            name_us
          }
        }
      }
      car_sunroof_type
      checked
      commission_rate
      date_time
      deleted_at
      price_discount
      price_extra
      price_final
      price_payout
      price_payout_without_vat
      price_product
      product_item
      remark
      store
      storeByStore {
        name
      }
      updated_by
      package_detail {
        id
        name
        package_type {
          description
        }
      }
      product_item_detail {
        id
        attribute
        price
        productByProduct {
          product_brand {
            description
          }
          name
          product_type {
            value
            description
          }
        }
      }
    }
  }
`

export const _UPDATE_CART_ITEM_TO_CART = gql`
  mutation _UPDATE_CART_ITEM_TO_CART($cartItemObject: cart_item_insert_input!) {
    insert_cart_item_one(
      object: $cartItemObject
      on_conflict: {
        constraint: cart_item_pkey
        update_columns: [
          car
          car_sunroof_type
          store
          price_product
          price_discount
          price_extra
          price_final
          price_payout
          price_payout_without_vat
          commission_rate
          product_item
          updated_by
          date_time
          quantity
          remark
        ]
      }
    ) {
      id
    }
  }
`
/// insert cart_item
export const _ADD_CART_ITEM_TO_CART = gql`
  mutation _ADD_CART_ITEM_TO_CART($cartItemObject: cart_item_insert_input!) {
    insert_cart_item_one(object: $cartItemObject) {
      id
    }
  }
`
