import { InputLabel, Select } from '@material-ui/core'
import React from 'react'

export interface TypeSelectBox {
  value: string
  text: string
}

export interface PropsSelect {
  placeholder?: string
  style?: React.CSSProperties
  className?: string
  label?: string
  value?: string
  options: Array<TypeSelectBox>
  disabled?: boolean
  minWidth?: number
  changeHandler?: (
    event: React.ChangeEvent<{
      name?: string
      value: unknown
    }>,
    child: React.ReactNode,
  ) => void
}

const defaultStyle: React.CSSProperties = {
  cursor: 'pointer',
}

const App: React.FunctionComponent<PropsSelect> = ({
  placeholder,
  style,
  className,
  label,
  value,
  options,
  disabled,
  minWidth = 150,
  changeHandler,
}: PropsSelect) => {
  const styles = disabled ? null : { ...style, ...defaultStyle }

  return (
    <div style={styles}>
      <InputLabel htmlFor="age-native-simple">{label}</InputLabel>

      <Select
        style={{ minWidth }}
        native
        disabled={disabled}
        value={value}
        className={className}
        onChange={changeHandler}
      >
        {placeholder && (
          <option value="" disabled hidden data-default>
            {placeholder}
          </option>
        )}
        {options &&
          options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.text}
            </option>
          ))}
      </Select>
    </div>
  )
}

export default React.memo(App)
