import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import Base64 from 'crypto-js/enc-base64'
import sha256 from 'crypto-js/sha256'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect } from 'react'

import { Car, Get_CarsQuery as GetCarsQuery } from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const GET_CARS = gql`
  query GET_CARS {
    cars: car(order_by: { car_maker: { name_kr: asc }, model: asc }, where: { deleted_at: { _is_null: true } }) {
      id
      model
      row
      type
      prefix
      postfix
      year
      maker
      llumar_car_type
      car_maker {
        name_kr
        name_us
        origin
        car_origin {
          name_kr
          name_us
        }
      }
      car_type {
        value
        description
      }
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent, changeLoadingSpinnerVisibility } = useRootData(
    ({ appStore }) => ({
      changeMainMenu: appStore.changeMainMenu,
      changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
      changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    }),
  )

  let { alert } = parseQueryString()

  useEffect(() => {
    if (alert !== undefined) {
      alert = undefined
      changeSnackbarAlertContent({
        severity: 'warning',
        content: '중복되는 차량이 없는지 확인하세요',
      })
    }
  }, [])

  const { data, loading, error } = useQuery<GetCarsQuery>(GET_CARS, {
    fetchPolicy: 'no-cache',
  })

  const generateToken = () => {
    const token = 'Riderdash1'
    const formattedDate = moment().utc().format('YYYY-MM-DD')

    return Base64.stringify(sha256(`${token}-${formattedDate}`))
  }

  const convertToCSVAndUploadToLlumarGo = async () => {
    changeLoadingSpinnerVisibility(true)
    try {
      const {
        data: { cars },
      } = await client.query({
        query: GET_CARS,
        fetchPolicy: 'no-cache',
      })

      // caramora_car_type: coupe / sedan / smallSUV / midLargeSUV / van

      const columns = [
        'origin',
        'maker',
        'model',
        'side_windows',
        'type',
        'prefix',
        'postfix',
        'year',
        'caramora_car_type',
      ]

      const csv = `${columns.join(',')}\n${cars
        .filter((row: Car) => row.row !== -1)
        .map((row: Car) => {
          const [sYear, eYear] = row.year
            .slice(1, -1)
            .split(',')
            .map((item: string) => parseInt(item, 10))
            .map((item: number) => (item > 2500 ? '현재' : `${item}`))
          const values: { [key: string]: string } = {
            origin: row.car_maker.car_origin.name_kr,
            maker: row.car_maker.name_kr,
            model: row.model,
            side_windows: `${row.row * 2}`,
            type: row.llumar_car_type,
            prefix: row.prefix,
            postfix: row.postfix,
            year: sYear > 1000 ? `${sYear}~${eYear}` : '',
            caramora_car_type: row.type,
          }

          return columns.map((key) => values[key]).join(',')
        })
        .join('\r\n')}`

      await axios.post(`${config.msgCenterURL}/db/car/${encodeURIComponent(generateToken())}`, csv, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })

      changeSnackbarAlertContent({
        severity: 'info',
        content: '업로드 완료!',
      })
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({
        severity: 'warning',
        content: `업로드 실패\n${err}`,
      })
    }
    changeLoadingSpinnerVisibility(false)
  }

  if (loading)
    return (
      <div className="body">
        <div className={styles.container}>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>자동차 목록</div>
        <button
          onClick={async (): Promise<void> => {
            changeMainMenu(`/car-detail?insert_car`)
          }}
        >
          추가하기
        </button>
        <button onClick={() => convertToCSVAndUploadToLlumarGo()}>CSV 변환 및 llumar go 업로드</button>
        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>uuid</th>
              <th className={styles.headerText}>제조사</th>
              <th className={styles.headerText}>세부 모델명</th>
              <th className={styles.headerText}>열</th>
              <th className={styles.headerText}>타입</th>
              <th className={styles.headerText}>루마고 타입</th>
              <th className={styles.headerText}>연식</th>
              <th></th>
            </tr>
            {data.cars.map((item, index: number) => (
              <tr key={index}>
                <td className={styles.inputCenter}>{data.cars.length - index}</td>
                <td className={styles.inputCenter}>{item.id}</td>
                <td className={styles.inputCenter}>{item.car_maker.name_kr}</td>
                <td className={styles.inputCenter}>{`${item.prefix || ''} ${item.model} ${item.postfix || ''}`}</td>
                <td className={styles.inputCenter}>{item.row}</td>
                <td className={styles.inputCenter}>{item.car_type.description}</td>
                <td className={styles.inputCenter}>{item.llumar_car_type}</td>
                <td className={styles.inputCenter}>{item.year}</td>
                <td>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/car-detail?id=${item.id}`)
                    }}
                  >
                    상세보기
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
