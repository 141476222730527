import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  Get_All_SidoQuery as GetAllSidoQuery,
  Get_All_SidoQueryVariables as GetAllSidoQueryVariables,
  Get_Order_PricesQuery as GetOrderPricesQuery,
  Get_Order_PricesQueryVariables as GetOrderPricesQueryVariables,
} from '../../assets/graphql/graphql'
import OrderVisualDateInput from '../../components/molecule/OrderVisualDateInput'
import OrderVisualFilter from '../../components/molecule/OrderVisualFilter'
import styles from './index.module.scss'

interface LocObjectType {
  [name: string]: boolean
}

interface DataObject {
  date: string
  discount: number
  paidDiscount: number
  cancelledDiscount: number
  confirmedSize: number
  expectedSize: number
  cancelledSize: number
  refundedSize: number
  paidSize: number
}

// queries
const GET_ALL_SIDO = gql`
  query GET_ALL_SIDO {
    address_sido {
      name
      address_sigungus {
        name
      }
    }
  }
`

const GET_ORDER_PRICES = gql`
  query GET_ORDER_PRICES($startDate: timestamptz, $sido: String, $sigungu: String) {
    order(
      where: {
        _or: [
          { date_time: { _gte: $startDate } }
          { confirmed_at: { _gte: $startDate } }
          { finished_at: { _gte: $startDate } }
          { canceled_at: { _gte: $startDate } }
          { refunded_at: { _gte: $startDate } }
        ]
        storeByStore: {
          addressSigunguByAddressSigungu: { name: { _similar: $sigungu }, address_sido: { name: { _similar: $sido } } }
        }
      }
    ) {
      price_discount
      price_extra
      price_final
      price_product
      status
      date_time
      confirmed_at
      finished_at
      canceled_at
      refunded_at
    }
  }
`

const bucketer = (data: GetOrderPricesQuery, start: string, unit: moment.unitOfTime.StartOf, length: number) => {
  const buckets: Array<DataObject> = Array.from({ length }, (_, i) => {
    return {
      date: moment(start)
        .add(i, unit as moment.unitOfTime.DurationAs)
        .format('YYYY-M-D'),
      discount: 0,
      paidDiscount: 0,
      cancelledDiscount: 0,
      confirmedSize: 0,
      expectedSize: 0,
      cancelledSize: 0,
      refundedSize: 0,
      paidSize: 0,
    }
  })

  data.order.forEach((order) => {
    const visited: Array<boolean> = []
    visited.length = buckets.length
    ;['finished_at', 'refunded_at', 'canceled_at', 'date_time', 'confirmed_at'].forEach((property) => {
      const index = buckets.findIndex((bucket) =>
        moment(order[property as keyof typeof order])
          .utcOffset(9 * 60)
          .isSame(moment(bucket.date, 'YYYY-M-D'), unit),
      )

      if (index > -1 && order.price_product + order.price_extra) {
        if (property === 'date_time' && order.status === 'confirmed' && unit !== 'day') {
          buckets[index].paidDiscount += 1 - order.price_final / (order.price_product + order.price_extra)
          buckets[index].expectedSize += 1
        }
        if (property === 'confirmed_at') {
          buckets[index].discount += 1 - order.price_final / (order.price_product + order.price_extra)
          buckets[index].confirmedSize += 1
        }
        if (property === 'finished_at') {
          buckets[index].paidDiscount += 1 - order.price_final / (order.price_product + order.price_extra)
          buckets[index].paidSize += 1
        }
        if (property === 'canceled_at') {
          buckets[index].cancelledDiscount += 1 - order.price_final / (order.price_product + order.price_extra)
          buckets[index].cancelledSize += 1
        }
        if (property === 'refunded_at') {
          buckets[index].cancelledDiscount += 1 - order.price_final / (order.price_product + order.price_extra)
          buckets[index].refundedSize += 1
        }
      }
    })
  })

  for (let i = 0; i < buckets.length; i += 1) {
    if (buckets[i].confirmedSize) {
      buckets[i].discount /= buckets[i].confirmedSize
    }
    if (buckets[i].paidSize || buckets[i].expectedSize) {
      buckets[i].paidDiscount /= buckets[i].paidSize + buckets[i].expectedSize
    }
    if (buckets[i].cancelledSize || buckets[i].refundedSize) {
      buckets[i].cancelledDiscount /= buckets[i].cancelledSize + buckets[i].refundedSize
    }
  }

  return buckets
}

const App: React.FunctionComponent = () => {
  const [sido, setSido] = useState('%')
  const [tempSido, setTempSido] = useState('')

  const [sigungu, setSigungu] = useState('%')
  const [sigunguObj, setSigunguObj] = useState<LocObjectType>({})

  const [startDate, setStartDate] = useState<Array<string>>([
    moment().startOf('day').subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
    moment().startOf('isoWeek').subtract(5, 'weeks').format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
    moment().startOf('month').subtract(4, 'month').format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
  ])

  const [tempStartDate, setTempStartDate] = useState(['', '', ''])

  const [dateUnit, setDateUnit] = useState<moment.unitOfTime.StartOf>('day')

  const [bucketedData, setBucketedData] = useState<Array<DataObject>>(null)

  const [getSidoData, { data: sidoData }] = useLazyQuery<GetAllSidoQuery, GetAllSidoQueryVariables>(GET_ALL_SIDO, {
    fetchPolicy: 'no-cache',
  })

  const [getDateData, { data: dateData }] = useLazyQuery<GetOrderPricesQuery, GetOrderPricesQueryVariables>(
    GET_ORDER_PRICES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        // eslint-disable-next-line no-nested-ternary
        startDate: dateUnit === 'day' ? startDate[0] : dateUnit === 'isoWeek' ? startDate[1] : startDate[2],
        sido,
        sigungu,
      },
    },
  )

  useEffect(() => {
    getSidoData()
    getDateData()
  }, [])

  useEffect(() => {
    if (dateData) {
      if (dateUnit === 'day') setBucketedData(bucketer(dateData, startDate[0], dateUnit, 7))
      if (dateUnit === 'isoWeek') setBucketedData(bucketer(dateData, startDate[1], dateUnit, 6))
      if (dateUnit === 'month') setBucketedData(bucketer(dateData, startDate[2], dateUnit, 5))
    }
  }, [dateData, startDate, dateUnit])

  if (!dateData || !sidoData)
    return (
      <div className="body">
        <div className={styles.container}>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  return (
    <div className="body">
      <div className={styles.container} style={{ height: '250%' }}>
        <div className={styles.selectInputContainer}>
          {'Date Format: '}
          <select
            value={dateUnit}
            onChange={(e) => {
              setDateUnit(e.currentTarget.value as moment.unitOfTime.StartOf)
            }}
          >
            <option value="day">Daily</option>
            <option value="isoWeek">Weekly</option>
            <option value="month">Monthly</option>
          </select>
        </div>

        <OrderVisualDateInput
          tempStartDate={tempStartDate}
          setTempStartDate={setTempStartDate}
          startDate={startDate}
          setStartDate={setStartDate}
          dateUnit={dateUnit}
        />

        <div className={styles.title}>{'예약 & 할인률 그래프 (KST)'}</div>
        <ResponsiveContainer className={styles.plot} width="90%" height={400}>
          <ComposedChart
            data={bucketedData}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

            <XAxis dataKey="date">
              <Label value="날짜" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              yAxisId="left"
              domain={[0, 1]}
              tickFormatter={(val) => {
                return `${Math.round(val * 100)}%`
              }}
            >
              <Label value="할인률 (%)" angle={-90} position="insideLeft" />
            </YAxis>
            <YAxis yAxisId="right" orientation="right" allowDecimals={false}>
              <Label value="판매 수량" angle={-90} position="right" />
            </YAxis>

            <Tooltip
              formatter={(val: number, name: string) => {
                if (name === '평균 할인률') {
                  return `${Math.round(val * 100)}%`
                }

                return val
              }}
            />
            <Legend verticalAlign="top" height={36} />
            <Bar name="예약 확정" stackId="a" yAxisId="right" dataKey="confirmedSize" barSize={40} fill="#52B788" />
            <Line name="평균 할인률" yAxisId="left" connectNulls dataKey="discount" stroke="#277DA1" fill="#277DA1" />
          </ComposedChart>
        </ResponsiveContainer>

        <div className={styles.title}>{'결제 & 할인률 그래프 (KST)'}</div>
        <ResponsiveContainer className={styles.plot} width="90%" height={400}>
          <ComposedChart
            data={bucketedData}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

            <XAxis dataKey="date">
              <Label value="날짜" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              yAxisId="left"
              domain={[0, 1]}
              tickFormatter={(val) => {
                return `${Math.round(val * 100)}%`
              }}
            >
              <Label value="할인률 (%)" angle={-90} position="insideLeft" />
            </YAxis>
            <YAxis yAxisId="right" orientation="right" allowDecimals={false}>
              <Label value="판매 수량" angle={-90} position="right" />
            </YAxis>

            <Tooltip
              formatter={(val: number, name: string) => {
                if (name === '평균 할인률') {
                  return `${Math.round(val * 100)}%`
                }

                return val
              }}
            />
            <Legend verticalAlign="top" height={36} />
            <Bar name="결제 완료" stackId="a" yAxisId="right" dataKey="paidSize" barSize={40} fill="#F9C74F" />
            {dateUnit === 'day' ? null : (
              <Bar name="결제 예정" stackId="a" yAxisId="right" dataKey="expectedSize" barSize={40} fill="#2274A5" />
            )}
            <Line
              name="평균 할인률"
              yAxisId="left"
              connectNulls
              dataKey="paidDiscount"
              stroke="#277DA1"
              fill="#277DA1"
            />
          </ComposedChart>
        </ResponsiveContainer>

        <div className={styles.title}>{'취소/환불 & 할인률 그래프 (KST)'}</div>
        <ResponsiveContainer className={styles.plot} width="90%" height={400}>
          <ComposedChart
            data={bucketedData}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

            <XAxis dataKey="date">
              <Label value="날짜" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              yAxisId="left"
              domain={[0, 1]}
              tickFormatter={(val) => {
                return `${Math.round(val * 100)}%`
              }}
            >
              <Label value="할인률 (%)" angle={-90} position="insideLeft" />
            </YAxis>
            <YAxis yAxisId="right" orientation="right" allowDecimals={false}>
              <Label value="판매 수량" angle={-90} position="right" />
            </YAxis>

            <Tooltip
              formatter={(val: number, name: string) => {
                if (name === '평균 할인률') {
                  return `${Math.round(val * 100)}%`
                }

                return val
              }}
            />
            <Legend verticalAlign="top" height={36} />
            <Bar name="예약 취소" stackId="a" yAxisId="right" dataKey="cancelledSize" barSize={40} fill="#F94144" />
            <Bar name="환불" stackId="a" yAxisId="right" dataKey="refundedSize" barSize={40} fill="#4F000B" />
            <Line
              name="평균 할인률"
              yAxisId="left"
              connectNulls
              dataKey="cancelledDiscount"
              stroke="#277DA1"
              fill="#277DA1"
            />
          </ComposedChart>
        </ResponsiveContainer>

        <OrderVisualFilter
          sidoData={sidoData}
          tempSido={tempSido}
          sigunguObj={sigunguObj}
          setSido={setSido}
          setSigungu={setSigungu}
          setTempSido={setTempSido}
          setSigunguObj={setSigunguObj}
        />
      </div>
    </div>
  )
}
export default React.memo(App)
