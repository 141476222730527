import { useQuery } from '@apollo/react-hooks'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import { isNull } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Account_Performance as AccountPerformance,
  Lead_Status_Enum as LeadStatusEnum,
  Profile_Riderdash_Role_Enum as ProfileRiderdashRoleEnum,
} from '../../../assets/graphql/graphql'
import config from '../../../configs'
import useRootData from '../../../hooks/useRootData'
import {
  GET_ACCOUNT_PERFORMANCE_BY_USED_CAR_STATUS,
  GET_ACCOUNT_PERFORMANCE_FOR_FIRST_CALL,
  GET_ACCOUNT_PERFORMANCE_FOR_RESERVATION,
} from '../query'
import styles from './index.module.scss'

interface statusResult {
  newUsers: number
  chatTriggered: number
  chatAndLater: number
  leadAndLater: number
  rsvPendingAndLater: number
  rsvConfirmedAndLater: number
  done: number
}

const App: React.FunctionComponent = () => {
  // Common variables
  const { sendAxios } = useRootData(({ appStore }) => ({
    sendAxios: appStore.sendAxios,
  }))
  const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const startDateTime = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD 15:00:00')

  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const endDateTime = moment(endDate).format('YYYY-MM-DD 14:59:59')

  // Account performance
  const [accountPerformanceForReservation, setAccountPerformanceForReservation] = useState(null)
  const { data: performanceForReservation, loading: loadingForReservation } = useQuery(
    GET_ACCOUNT_PERFORMANCE_FOR_RESERVATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        start_date: startDateTime,
        end_date: endDateTime,
      },
    },
  )
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reservation: any = { sum: { total: 0 } }
    // eslint-disable-next-line camelcase
    if (performanceForReservation?.get_account_performance_for_reservation) {
      // For base
      performanceForReservation.get_account_performance_for_reservation.forEach((item: AccountPerformance) => {
        const { email, name, role } = item
        reservation = { ...reservation, [item.account]: { email, name, role, total: 0 } }
      })
      // For others
      performanceForReservation.get_account_performance_for_reservation.forEach((item: AccountPerformance) => {
        reservation = {
          ...reservation,
          [item.account]: {
            ...reservation[item.account],
            total: reservation[item.account].total + item.count,
          },
          sum: {
            total: reservation.sum.total + item.count,
          },
        }
      })

      setAccountPerformanceForReservation(reservation)
    }
  }, [performanceForReservation])

  const [accountPerformanceForFirstCall, setAccountPerformanceForFirstCall] = useState(null)
  const { data: performanceForFirstCall, loading: loadingForCall } = useQuery(GET_ACCOUNT_PERFORMANCE_FOR_FIRST_CALL, {
    fetchPolicy: 'no-cache',
    variables: {
      start_date: startDateTime,
      end_date: endDateTime,
    },
  })
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let firstCall: any = { sum: { total: 0, rsvPending: 0, rsvConfirmed: 0, done: 0 } }
    // eslint-disable-next-line camelcase
    if (performanceForFirstCall?.get_account_performance_for_first_call) {
      // For base
      performanceForFirstCall.get_account_performance_for_first_call.forEach((item: AccountPerformance) => {
        const { email, name, role } = item
        firstCall = {
          ...firstCall,
          [item.account]: { email, name, role, total: 0, rsvPending: 0, rsvConfirmed: 0, done: 0 },
        }
      })
      // For others
      performanceForFirstCall.get_account_performance_for_first_call.forEach((item: AccountPerformance) => {
        firstCall = {
          ...firstCall,
          [item.account]: {
            ...firstCall[item.account],
            total: firstCall[item.account].total + item.count,
            rsvPending:
              firstCall[item.account].rsvPending +
              (item.status === LeadStatusEnum.RsvPending ||
              item.status === LeadStatusEnum.RsvConfirmed ||
              item.status === LeadStatusEnum.PaymentPending ||
              item.status === LeadStatusEnum.VatRequested ||
              item.status === LeadStatusEnum.SettlementPending ||
              item.status === LeadStatusEnum.Done ||
              item.status === LeadStatusEnum.Refunded ||
              item.status === LeadStatusEnum.Cancel
                ? item.count
                : 0),
            rsvConfirmed:
              firstCall[item.account].rsvConfirmed +
              (item.status === LeadStatusEnum.RsvConfirmed ||
              item.status === LeadStatusEnum.PaymentPending ||
              item.status === LeadStatusEnum.VatRequested ||
              item.status === LeadStatusEnum.SettlementPending ||
              item.status === LeadStatusEnum.Done ||
              item.status === LeadStatusEnum.Refunded ||
              item.status === LeadStatusEnum.Cancel
                ? item.count
                : 0),
            done: firstCall[item.account].done + (item.status === LeadStatusEnum.Done ? item.count : 0),
          },
          sum: {
            total: firstCall.sum.total + item.count,
            rsvPending:
              firstCall.sum.rsvPending +
              (item.status === LeadStatusEnum.RsvPending ||
              item.status === LeadStatusEnum.RsvConfirmed ||
              item.status === LeadStatusEnum.PaymentPending ||
              item.status === LeadStatusEnum.VatRequested ||
              item.status === LeadStatusEnum.SettlementPending ||
              item.status === LeadStatusEnum.Done ||
              item.status === LeadStatusEnum.Refunded ||
              item.status === LeadStatusEnum.Cancel
                ? item.count
                : 0),
            rsvConfirmed:
              firstCall.sum.rsvConfirmed +
              (item.status === LeadStatusEnum.RsvConfirmed ||
              item.status === LeadStatusEnum.PaymentPending ||
              item.status === LeadStatusEnum.VatRequested ||
              item.status === LeadStatusEnum.SettlementPending ||
              item.status === LeadStatusEnum.Done ||
              item.status === LeadStatusEnum.Refunded ||
              item.status === LeadStatusEnum.Cancel
                ? item.count
                : 0),
            done: firstCall.sum.done + (item.status === LeadStatusEnum.Done ? item.count : 0),
          },
        }
      })

      setAccountPerformanceForFirstCall(firstCall)
    }
  }, [performanceForFirstCall])

  const [accountPerformanceByUsedCarStatus, setAccountPerformanceByUsedCarStatus] = useState(null)
  const { data: performanceByUsedCarStatus, loading: loadingByUsedCarStatus } = useQuery(
    GET_ACCOUNT_PERFORMANCE_BY_USED_CAR_STATUS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        start_date: startDateTime,
        end_date: endDateTime,
      },
    },
  )
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let doneByUsedCarStatus: any = {
      sum: { total: 0, others: 0, newCar: 0, usedCar: 0, othersDone: 0, newCarDone: 0, usedCarDone: 0, done: 0 },
    }

    // eslint-disable-next-line camelcase
    if (performanceByUsedCarStatus?.get_account_performance_by_used_car_status) {
      //
      performanceByUsedCarStatus.get_account_performance_by_used_car_status.forEach((item: AccountPerformance) => {
        // eslint-disable-next-line camelcase
        const { email, name, role } = item
        doneByUsedCarStatus = {
          ...doneByUsedCarStatus,
          [item.account]: {
            email,
            name,
            role,
            others: 0,
            newCar: 0,
            usedCar: 0,
            othersDone: 0,
            newCarDone: 0,
            usedCarDone: 0,
            total: 0,
            done: 0,
          },
        }
      })

      performanceByUsedCarStatus.get_account_performance_by_used_car_status.forEach((item: AccountPerformance) => {
        doneByUsedCarStatus = {
          ...doneByUsedCarStatus,
          [item.account]: {
            ...doneByUsedCarStatus[item.account],
            total: doneByUsedCarStatus[item.account].total + item.count,
            others: doneByUsedCarStatus[item.account].others + (isNull(item.used_car_status) ? item.count : 0),
            newCar:
              doneByUsedCarStatus[item.account].newCar +
              (!isNull(item.used_car_status) && !item.used_car_status ? item.count : 0),
            usedCar:
              doneByUsedCarStatus[item.account].usedCar +
              (!isNull(item.used_car_status) && item.used_car_status ? item.count : 0),
            newCarDone:
              doneByUsedCarStatus[item.account].newCarDone +
              (!isNull(item.used_car_status) && !item.used_car_status && item.status === LeadStatusEnum.Done
                ? item.count
                : 0),
            usedCarDone:
              doneByUsedCarStatus[item.account].usedCarDone +
              (!isNull(item.used_car_status) && item.used_car_status && item.status === LeadStatusEnum.Done
                ? item.count
                : 0),
            done: doneByUsedCarStatus[item.account].done + (item.status === LeadStatusEnum.Done ? item.count : 0),
          },
          sum: {
            total: doneByUsedCarStatus.sum.total + item.count,
            others: doneByUsedCarStatus.sum.others + (isNull(item.used_car_status) ? item.count : 0),
            newCar:
              doneByUsedCarStatus.sum.newCar +
              (!isNull(item.used_car_status) && !item.used_car_status ? item.count : 0),
            usedCar: doneByUsedCarStatus.sum.usedCar + (item.used_car_status ? item.count : 0),
            othersDone:
              doneByUsedCarStatus.sum.othersDone +
              (isNull(item.used_car_status) && item.status === LeadStatusEnum.Done ? item.count : 0),
            newCarDone:
              doneByUsedCarStatus.sum.newCarDone +
              (!isNull(item.used_car_status) && !item.used_car_status && item.status === LeadStatusEnum.Done
                ? item.count
                : 0),
            usedCarDone:
              doneByUsedCarStatus.sum.usedCarDone +
              (!isNull(item.used_car_status) && item.used_car_status && item.status === LeadStatusEnum.Done
                ? item.count
                : 0),
            done: doneByUsedCarStatus.sum.done + (item.status === LeadStatusEnum.Done ? item.count : 0),
          },
        }
      })

      setAccountPerformanceByUsedCarStatus(doneByUsedCarStatus)
    }
  }, [performanceByUsedCarStatus])

  const [accountThroughput, setAccountThroughput] = useState(null)
  useEffect(() => {
    ;(async () => {
      const { data } =
        (await sendAxios.get(`${config.backendEndPoint}/admin/get-account-throughput`, {
          params: {
            startDateTime,
            endDateTime,
          },
        })) || {}

      if (!data) {
        setStartDate(moment(new Date()).format('YYYY-MM-DD'))
        setEndDate(moment(new Date()).format('YYYY-MM-DD'))
      }

      let result = {}

      data.get_account_throughput
        .filter(
          (item: AccountPerformance) =>
            item.role === ProfileRiderdashRoleEnum.User || item.role === ProfileRiderdashRoleEnum.Administrator,
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .forEach((item: any) => {
          result = {
            ...result,
            [item.account]: item.count,
          }
        })

      setAccountThroughput(result)
    })()
  }, [startDateTime, endDateTime])

  // Lead Status Statistics
  const [leadStatusStatistics, setLeadStatusStatistics] = useState(null)
  const [gaStatistics, setGAStatistics] = useState(null)
  const [statusResult, setStatusResult] = useState<statusResult>(null)

  useEffect(() => {
    ;(async () => {
      const { data: leadData } = await sendAxios.get(`${config.backendEndPoint}/admin/get-lead-status-statistics`, {
        params: {
          startDateTime,
          endDateTime,
        },
      })
      setLeadStatusStatistics(leadData)

      if (moment(startDate) <= moment(endDate)) {
        const {
          data: { uaData },
        } = await sendAxios.get(`${config.backendEndPoint}/admin/get-ga-new-users`, {
          params: {
            startDate,
            endDate,
          },
        })
        setGAStatistics(uaData)
      }
    })()
  }, [startDateTime, endDateTime])

  useEffect(() => {
    if (!gaStatistics?.rows || !leadStatusStatistics) return

    // 채팅 트리거 발동 - 전부 다 더함 => 1-16
    // chat and later - missed 빼고 다 => 1-14, 16
    // lead and later - 1, 12-16 빼고 다 => 2-11
    // rsv_pending and later -  1-5, 12-16 빼고 다 => 6-11
    // done - done 만 => 11
    const result: statusResult = {
      newUsers: gaStatistics.rows[0],
      chatTriggered: 0,
      chatAndLater: 0,
      leadAndLater: 0,
      rsvPendingAndLater: 0,
      rsvConfirmedAndLater: 0,
      done: 0,
    }

    // eslint-disable-next-line camelcase
    leadStatusStatistics?.get_lead_status_statistics.forEach((item: { count: number; status: LeadStatusEnum }) => {
      switch (item.status) {
        case LeadStatusEnum.Missed:
          result.chatTriggered += item.count
          break
        case LeadStatusEnum.Chat:
        case LeadStatusEnum.Duplicate:
          result.chatTriggered += item.count
          result.chatAndLater += item.count
          break
        case LeadStatusEnum.Lead:
        case LeadStatusEnum.Quote:
        case LeadStatusEnum.Match:
        case LeadStatusEnum.OrderPending:
          result.chatTriggered += item.count
          result.chatAndLater += item.count
          result.leadAndLater += item.count
          break
        case LeadStatusEnum.RsvPending:
        case LeadStatusEnum.WillNotDo:
          result.chatTriggered += item.count
          result.chatAndLater += item.count
          result.leadAndLater += item.count
          result.rsvPendingAndLater += item.count
          break
        case LeadStatusEnum.RsvConfirmed:
        case LeadStatusEnum.PaymentPending:
        case LeadStatusEnum.VatRequested:
        case LeadStatusEnum.SettlementPending:
        case LeadStatusEnum.Refunded:
        case LeadStatusEnum.Cancel:
          result.chatTriggered += item.count
          result.chatAndLater += item.count
          result.leadAndLater += item.count
          result.rsvPendingAndLater += item.count
          result.rsvConfirmedAndLater += item.count
          break
        case LeadStatusEnum.Done:
          result.chatTriggered += item.count
          result.chatAndLater += item.count
          result.leadAndLater += item.count
          result.rsvPendingAndLater += item.count
          result.rsvConfirmedAndLater += item.count
          result.done += item.count
          break
        default:
      }
    })

    setStatusResult(result)
  }, [leadStatusStatistics, gaStatistics])

  if (loadingForReservation || loadingForCall || loadingByUsedCarStatus) return null

  let sumOfThroughput = 0

  return (
    leadStatusStatistics &&
    accountPerformanceForReservation &&
    accountPerformanceForFirstCall &&
    accountPerformanceByUsedCarStatus && (
      <div>
        <div className={styles.alertText}>한국 시간 기준!!!</div>
        <div className={styles.date}>
          <div className={styles.shortcut}>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).subtract(1, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).subtract(1, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3c;
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(new Date()).format('YYYY-MM-DD'))
                setEndDate(moment(new Date()).format('YYYY-MM-DD'))
              }}
            >
              오늘로
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).add(1, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).add(1, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3e;
            </Button>
          </div>
          <div className={styles.shortcut}>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).subtract(7, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).subtract(7, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3c;&#x3c;
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                const isFridayAndLater = moment(new Date()).day() >= 5 ? 7 : 0
                setStartDate(
                  moment(new Date())
                    .day(-2 + isFridayAndLater)
                    .format('YYYY-MM-DD'),
                )
                setEndDate(
                  moment(new Date())
                    .day(4 + isFridayAndLater)
                    .format('YYYY-MM-DD'),
                )
              }}
            >
              이번 스프린트
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                setStartDate(moment(startDate).add(7, 'day').format('YYYY-MM-DD'))
                setEndDate(moment(endDate).add(7, 'day').format('YYYY-MM-DD'))
              }}
            >
              &#x3e;&#x3e;
            </Button>
          </div>
          <div className={styles.startDate}>
            <div className={styles.startText}>시작일</div>
            <TextField
              type="date"
              value={startDate}
              onChange={(event): void => {
                setStartDate(event.target.value)
              }}
            />
          </div>
          <div className={styles.endDate}>
            <div className={styles.endText}>종료일</div>
            <TextField
              type="date"
              value={endDate}
              onChange={(event): void => {
                setEndDate(event.target.value)
              }}
            />
          </div>
        </div>
        <div className={styles.tableTitle}>
          고객 상담사 시공타입별 전환율 (quote_at 기준 - 현재 리드 상태/기간내 첫통화 리드수)
        </div>
        <div className={styles.tableDescription}>첫통화 = 처음으로 quote를 보낸 것</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>support assignee</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>total</TableCell>
                <TableCell className={styles.header}>Others</TableCell>
                <TableCell className={styles.header}>Others done</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
                <TableCell className={styles.header}>신차</TableCell>
                <TableCell className={styles.header}>신차 done</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
                <TableCell className={styles.header}>재시공</TableCell>
                <TableCell className={styles.header}>재시공 done</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(accountPerformanceByUsedCarStatus)
                .filter((item) => item !== 'sum')
                .sort(
                  (x: string, y: string) =>
                    accountPerformanceByUsedCarStatus[y].total - accountPerformanceByUsedCarStatus[x].total,
                )
                .map((item: string, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].name}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].email}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].role}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].total}</TableCell>
                    {/* others */}
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].others}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].othersDone}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceByUsedCarStatus[item].othersDone /
                          Math.max(1, accountPerformanceByUsedCarStatus[item].others)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>

                    {/* new car */}
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].newCar}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].newCarDone}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceByUsedCarStatus[item].newCarDone /
                          Math.max(1, accountPerformanceByUsedCarStatus[item].newCar)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>

                    {/* used car */}
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].usedCar}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus[item].usedCarDone}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceByUsedCarStatus[item].usedCarDone /
                          Math.max(1, accountPerformanceByUsedCarStatus[item].usedCar)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell className={styles.cell} colSpan={2}></TableCell>
                <TableCell className={styles.cell}>합계</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus?.sum?.total}</TableCell>

                {/* others */}
                <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus?.sum?.others}</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus?.sum?.othersDone}</TableCell>
                <TableCell className={styles.cell}>
                  {(
                    (accountPerformanceByUsedCarStatus?.sum?.othersDone /
                      Math.max(1, accountPerformanceByUsedCarStatus?.sum?.others)) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                {/* new car */}
                <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus?.sum?.newCar}</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus?.sum?.newCarDone}</TableCell>
                <TableCell className={styles.cell}>
                  {(
                    (accountPerformanceByUsedCarStatus?.sum?.newCarDone /
                      Math.max(1, accountPerformanceByUsedCarStatus?.sum?.newCar)) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                {/* used car */}
                <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus.sum.usedCar}</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceByUsedCarStatus?.sum?.usedCarDone}</TableCell>
                <TableCell className={styles.cell}>
                  {(
                    (accountPerformanceByUsedCarStatus?.sum?.usedCarDone /
                      Math.max(1, accountPerformanceByUsedCarStatus?.sum?.usedCar)) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div className={styles.tableTitle}>
          고객 상담사 첫 통화 현황 (quote_at 기준 - 현재 리드 상태/기간내 첫통화 리드수)
        </div>
        <div className={styles.tableDescription}>첫통화 = 처음으로 quote를 보낸 것</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>support assignee</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>첫콜수</TableCell>
                <TableCell className={styles.header}>예약대기</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
                <TableCell className={styles.header}>예약확정</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
                <TableCell className={styles.header}>done</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(accountPerformanceForFirstCall)
                .filter((item) => item !== 'sum')
                .sort(
                  (x: string, y: string) =>
                    accountPerformanceForFirstCall[y].total - accountPerformanceForFirstCall[x].total,
                )
                .map((item: string, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].name}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].email}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].role}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].total}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].rsvPending}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceForFirstCall[item].rsvPending / accountPerformanceForFirstCall[item].total) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].rsvConfirmed}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceForFirstCall[item].rsvConfirmed /
                          accountPerformanceForFirstCall[item].total) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].done}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceForFirstCall[item].done / accountPerformanceForFirstCall[item].total) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell className={styles.cell} colSpan={2}></TableCell>
                <TableCell className={styles.cell}>합계</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForFirstCall?.sum?.total}</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForFirstCall?.sum?.rsvPending}</TableCell>
                <TableCell className={styles.cell}>
                  {(
                    (accountPerformanceForFirstCall?.sum?.rsvPending / accountPerformanceForFirstCall?.sum?.total) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForFirstCall?.sum?.rsvConfirmed}</TableCell>
                <TableCell className={styles.cell}>
                  {(
                    (accountPerformanceForFirstCall?.sum?.rsvConfirmed / accountPerformanceForFirstCall?.sum?.total) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForFirstCall?.sum?.done}</TableCell>
                <TableCell className={styles.cell}>
                  {(
                    (accountPerformanceForFirstCall?.sum?.done / accountPerformanceForFirstCall?.sum?.total) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div className={styles.tableTitle}>
          고객 상담사 리드처리 현황 (updated_at/quote_at 기준 - 현재 리드 상태/기간내 처리한 리드수)
        </div>
        <div className={styles.tableDescription}>처리한 리드 = 리드의 상태를 변경 or comment에 뭐라도 남김</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>support assignee</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>리드수</TableCell>
                <TableCell className={styles.header}>예약대기</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
                <TableCell className={styles.header}>예약확정</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
                <TableCell className={styles.header}>done</TableCell>
                <TableCell className={styles.header}>비율</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(accountPerformanceForFirstCall)
                .filter((item) => item !== 'sum')
                .sort(
                  (x: string, y: string) =>
                    accountPerformanceForFirstCall[y].total - accountPerformanceForFirstCall[x].total,
                )
                .map((item: string, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].name}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].email}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].role}</TableCell>
                    <TableCell className={styles.cell}>
                      {(() => {
                        sumOfThroughput += accountThroughput?.[item] ? accountThroughput?.[item] : 0

                        return accountThroughput?.[item] ? accountThroughput?.[item] : 0
                      })()}
                    </TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].rsvPending}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceForFirstCall[item].rsvPending /
                          (accountThroughput?.[item] ? accountThroughput?.[item] : 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].rsvConfirmed}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceForFirstCall[item].rsvConfirmed /
                          (accountThroughput?.[item] ? accountThroughput?.[item] : 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForFirstCall[item].done}</TableCell>
                    <TableCell className={styles.cell}>
                      {(
                        (accountPerformanceForFirstCall[item].done /
                          (accountThroughput?.[item] ? accountThroughput?.[item] : 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell className={styles.cell} colSpan={2}></TableCell>
                <TableCell className={styles.cell}>합계</TableCell>
                <TableCell className={styles.cell}>{sumOfThroughput}</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForFirstCall?.sum?.rsvPending}</TableCell>
                <TableCell className={styles.cell}>
                  {((accountPerformanceForFirstCall?.sum?.rsvPending / sumOfThroughput) * 100).toFixed(2)}%
                </TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForFirstCall?.sum?.rsvConfirmed}</TableCell>
                <TableCell className={styles.cell}>
                  {((accountPerformanceForFirstCall?.sum?.rsvConfirmed / sumOfThroughput) * 100).toFixed(2)}%
                </TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForFirstCall?.sum?.done}</TableCell>
                <TableCell className={styles.cell}>
                  {((accountPerformanceForFirstCall?.sum?.done / sumOfThroughput) * 100).toFixed(2)}%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div className={styles.tableTitle}>
          고객 상담사 예약 대기 현황 (rsv_pending_at 기준 - 해당일에 몇개 예약을 만들었는가)
        </div>
        <div className={styles.tableDescription}>예약 대기 = rsv_pending 상태로 변경</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>support assignee</TableCell>
                <TableCell className={styles.header}>email</TableCell>
                <TableCell className={styles.header}>role</TableCell>
                <TableCell className={styles.header}>total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(accountPerformanceForReservation)
                .filter((item) => item !== 'sum')
                .sort(
                  (x: string, y: string) =>
                    accountPerformanceForReservation[y].total - accountPerformanceForReservation[x].total,
                )
                .map((item: string, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={styles.cell}>{accountPerformanceForReservation[item].name}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForReservation[item].email}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForReservation[item].role}</TableCell>
                    <TableCell className={styles.cell}>{accountPerformanceForReservation[item].total}</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell className={styles.cell} colSpan={2}></TableCell>
                <TableCell className={styles.cell}>합계</TableCell>
                <TableCell className={styles.cell}>{accountPerformanceForReservation?.sum?.total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={styles.tableTitle}>Lead Funnel(created_at 기준)</div>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.header}>Status</TableCell>
                <TableCell className={styles.header}>Count</TableCell>
                <TableCell className={styles.header}>Ratio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusResult &&
                Object.keys(statusResult).map((item: keyof statusResult, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={styles.cell}>{item}</TableCell>
                    <TableCell className={styles.cell}>{Number(statusResult[item]).toLocaleString()}</TableCell>
                    <TableCell className={styles.cell}>
                      {((statusResult[item] / statusResult.newUsers) * 100).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    )
  )
}
export default React.memo(App)
