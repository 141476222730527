import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  Product_Brand as ProductBrand,
  Product_Insert_Input as Product,
  Product_Select_Column as ProductSelectColumn,
  Product_Type as ProductType,
} from '../../assets/graphql/graphql'
import Select, { TypeSelectBox } from '../../components/atoms/Select'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import { GET_PRODUCT_BRAND } from '../ProductBrand'
import { GET_PRODUCT_TYPE } from '../ProductType'

const pickedColumns: ProductSelectColumn[] = [
  ProductSelectColumn.Id,
  ProductSelectColumn.Name,
  ProductSelectColumn.Type,
  ProductSelectColumn.Brand,
  ProductSelectColumn.Description,
  ProductSelectColumn.Status,
  ProductSelectColumn.Alias,
  ProductSelectColumn.Account,
]

const GET_PRODUCT_BY_PK = gql`
  query GET_PRODUCT_BY_PK($id: uuid!) {
    product_by_pk(id: $id) {
      id
      name
      type
      brand
      description
      status
      alias
      account
    }
  }
`

const UPSERT_PRODUCT = gql`
  mutation UPSERT_PRODUCT($productObject: product_insert_input!) {
    insert_product_one(
      object: $productObject
      on_conflict: {
        constraint: product_pkey
        update_columns: [account, brand, type, name, description, status, alias]
      }
    ) {
      id
    }
  }
`

const App: React.FunctionComponent = () => {
  const { accountId, changeSnackbarAlertContent } = useRootData(({ appStore, authStore }) => ({
    accountId: authStore.accountId.get(),
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id } = parseQueryString()

  const [product, setProduct] = useState<Product>({
    [ProductSelectColumn.Id]: undefined,
    [ProductSelectColumn.Account]: undefined,
    [ProductSelectColumn.Brand]: undefined,
    [ProductSelectColumn.Type]: undefined,
    [ProductSelectColumn.Name]: undefined,
    [ProductSelectColumn.Description]: undefined,
    [ProductSelectColumn.Status]: false,
    [ProductSelectColumn.Alias]: undefined,
  })

  const onChangeHandler = (key: ProductSelectColumn, data: string | boolean) => {
    setProduct({
      ...product,
      [key]: data,
    })
  }

  const [getProductBrand, { data: productBrandData }] = useLazyQuery(GET_PRODUCT_BRAND, {
    fetchPolicy: 'no-cache',
  })

  const [getProductType, { data: productTypeData }] = useLazyQuery(GET_PRODUCT_TYPE, {
    fetchPolicy: 'no-cache',
  })

  const [getProduct, { data: productData }] = useLazyQuery(GET_PRODUCT_BY_PK, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })

  useEffect(() => {
    getProductBrand()
    getProductType()
    if (id) {
      getProduct()
    }
  }, [])

  useEffect(() => {
    if (productData) {
      setProduct(_.pick(productData.product_by_pk, ...pickedColumns))
    }
  }, [productData])

  if ((!productData && id) || !productBrandData || !productTypeData)
    return (
      <div className="body">
        <div>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  const buttonClickHandler = async () => {
    try {
      await client.mutate({
        mutation: UPSERT_PRODUCT,
        variables: {
          productObject: { ..._.pick(product, ...pickedColumns), account: accountId },
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '성공' })
      getProduct()
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: `실패\n${err}` })
      Sentry.captureException(err)
    }
  }

  return (
    <div>
      <Button variant="contained" onClick={() => buttonClickHandler()}>
        {id ? '수정하기' : '추가하기'}
      </Button>
      <TableContainer>
        <Table>
          <TableBody>
            {[ProductSelectColumn.Brand, ProductSelectColumn.Type].map((col: string, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell style={{ backgroundColor: '#efefef' }}>{col}</TableCell>
                  <TableCell>
                    <Select
                      value={product[col as ProductSelectColumn]}
                      options={[
                        { value: undefined, text: '선택 안함' },
                        ...(col === ProductSelectColumn.Brand
                          ? productBrandData.product_brand
                          : productTypeData.product_type),
                      ].map(
                        (productBrand: ProductBrand | ProductType): TypeSelectBox => {
                          return {
                            value: productBrand.value,
                            text: productBrand.description,
                          }
                        },
                      )}
                      changeHandler={(e) => onChangeHandler(col as ProductSelectColumn, e.target.value as string)}
                    ></Select>
                  </TableCell>
                </TableRow>
              )
            })}
            {[ProductSelectColumn.Name, ProductSelectColumn.Description, ProductSelectColumn.Alias].map(
              (col: string, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ backgroundColor: '#efefef' }}>{col}</TableCell>
                    <TableCell>
                      <TextField
                        style={{ width: 800 }}
                        value={product[col as ProductSelectColumn]}
                        onChange={(e) => onChangeHandler(col as ProductSelectColumn, e.target.value)}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                )
              },
            )}

            <TableRow>
              <TableCell style={{ backgroundColor: '#efefef' }}>{ProductSelectColumn.Status}</TableCell>
              <TableCell>
                <Checkbox
                  checked={product[ProductSelectColumn.Status]}
                  onChange={(e) => onChangeHandler(ProductSelectColumn.Status, e.target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default React.memo(App)
