import { useLazyQuery } from '@apollo/react-hooks'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import PhoneNumber from 'awesome-phonenumber'
import React, { useEffect, useState } from 'react'

import { Lead, Lead_Status_Enum as LeadStatusEnum } from '../../../../assets/graphql/graphql'
import useRootData from '../../../../hooks/useRootData'
import { SEARCH_LEAD, UPDATE_LEAD_STATUS } from '../../../../stores/lead/query'
import { LEAD_COL } from '../../../../stores/lead/type'
import client from '../../../../utils/graphql'
import { autoHypenPhoneNumber, getLeadColValueByKey, parseQueryString } from '../../../../utils/utility'
import styles from './index.module.scss'

interface LeadSearchProps {
  option?: {
    phone?: string
  }
}

const App: React.FunctionComponent<LeadSearchProps> = ({ option }: LeadSearchProps) => {
  const {
    isPartTimer,
    selectedLead,
    changeMainMenu,
    changeSnackbarAlertContent,
    leadSubscribe,
    updateLeadByPK,
  } = useRootData(({ appStore, authStore, leadStore }) => ({
    isPartTimer: authStore.isPartTimer.get(),
    selectedLead: leadStore.selectedLead.get(),

    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    leadSubscribe: leadStore.leadSubscribe,
    updateLeadByPK: leadStore.updateLeadByPK,
  }))

  const [chatId, setChatId] = useState<string>('')
  const [leadId, setLeadId] = useState<string>('')
  const [phone, setPhone] = useState<string>(option ? option.phone : '')
  const [email, setEmail] = useState<string>('')
  const [zendeskChatName, setZendeskChatName] = useState<string>('')

  const [getSearchLeadByPhoneOrEmail, { data: resultContents }] = useLazyQuery(SEARCH_LEAD, {
    fetchPolicy: 'no-cache',
    variables: {
      chatId: chatId ? parseInt(chatId, 10) : -1,
      leadId: leadId ? parseInt(leadId, 10) : -1,
      phone: phone ? `%${new PhoneNumber(phone.replace(/-/gi, ''), 'KR').getNumber('e164')}%` : '',
      email: email ? `%${email}%` : '☎',
      zendeskChatName: zendeskChatName ? `%${zendeskChatName}%` : '',
    },
  })

  const leads = (resultContents && resultContents.leads) || []

  const importLeadButtonClickHandler = async (lead: Lead, newTab = false): Promise<void> => {
    const { id } = parseQueryString()

    if (selectedLead && selectedLead.id === id && selectedLead.status === LeadStatusEnum.Chat) {
      // eslint-disable-next-line no-alert
      if (window.confirm('해당 chat을 재방문 고객으로 처리하겠습니까?')) {
        await updateLeadByPK()

        await client.mutate({
          mutation: UPDATE_LEAD_STATUS(LeadStatusEnum.Duplicate),
          variables: {
            id: selectedLead.id,
            now: new Date().toUTCString(),
          },
        })
      }

      await new Promise((r) => setTimeout(r, 500))

      changeSnackbarAlertContent({ severity: 'success', content: '재방문 고객 처리 완료' })
    }

    if (newTab) {
      window.open(`/lead-detail?id=${lead.id}&fromLeadSearch`)

      return
    }

    changeMainMenu(`/lead-detail?id=${lead.id}`)

    await new Promise((r) => setTimeout(r, 100))

    leadSubscribe(lead.id)

    if (
      isPartTimer &&
      selectedLead &&
      selectedLead.status !== LeadStatusEnum.Duplicate &&
      selectedLead.status !== LeadStatusEnum.Missed &&
      selectedLead.status !== LeadStatusEnum.Chat &&
      selectedLead.status !== LeadStatusEnum.Lead &&
      selectedLead.status !== LeadStatusEnum.Quote
    ) {
      changeSnackbarAlertContent({ severity: 'success', content: '코멘트만 수정 가능합니다!' })
    }
  }

  useEffect(() => {
    getSearchLeadByPhoneOrEmail()
  }, [])

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table className={styles.table} size="small" aria-label="a dense table">
        <TableHead className={styles.header}>
          <TableRow>
            <TableCell className={styles.searchTitle} align="center">
              Chat ID
            </TableCell>
            <TableCell colSpan={5}>
              <TextField
                className={styles.inputTextField}
                value={chatId}
                variant="outlined"
                size="small"
                onChange={(event): void => setChatId(event.target.value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.searchTitle} align="center">
              Lead ID
            </TableCell>
            <TableCell colSpan={5}>
              <TextField
                className={styles.inputTextField}
                value={leadId}
                variant="outlined"
                size="small"
                onChange={(event): void => setLeadId(event.target.value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.searchTitle} align="center">
              고객 전화번호
            </TableCell>
            <TableCell colSpan={5}>
              <TextField
                className={styles.inputTextField}
                value={phone}
                variant="outlined"
                size="small"
                onChange={(event): void => setPhone(autoHypenPhoneNumber(event.target.value))}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.searchTitle} align="center">
              고객 이메일
            </TableCell>
            <TableCell colSpan={5}>
              <TextField
                className={styles.inputTextField}
                value={email}
                variant="outlined"
                size="small"
                onChange={(event): void => setEmail(event.target.value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.searchTitle} align="center">
              Zendesk Chat Name
            </TableCell>
            <TableCell colSpan={5}>
              <TextField
                className={styles.inputTextField}
                value={zendeskChatName}
                variant="outlined"
                size="small"
                onChange={(event): void => setZendeskChatName(event.target.value)}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.body}>
          <TableRow className={styles.rowTitle}>
            {[
              LEAD_COL.CHAT_ID,
              LEAD_COL.LEAD_ID,
              LEAD_COL.CUSTOMER_PHONE,
              LEAD_COL.CARAMORA_ID,
              LEAD_COL.DUE_DATE,
              LEAD_COL.STATUS,
              '',
            ].map((col, index) => {
              return (
                <TableCell key={index} className={styles.col}>
                  {col}
                </TableCell>
              )
            })}
          </TableRow>
          {leads.map((lead: Lead, rowIndex: number) => {
            return (
              <TableRow key={rowIndex} className={styles.leadRow}>
                {[
                  LEAD_COL.CHAT_ID,
                  LEAD_COL.LEAD_ID,
                  LEAD_COL.CUSTOMER_PHONE,
                  LEAD_COL.CARAMORA_ID,
                  LEAD_COL.DUE_DATE,
                  LEAD_COL.STATUS,
                ].map((col, colIndex) => {
                  return <TableCell key={colIndex}>{getLeadColValueByKey(lead, col)}</TableCell>
                })}
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(): Promise<void> => importLeadButtonClickHandler(lead)}
                  >
                    가져오기
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(): Promise<void> => importLeadButtonClickHandler(lead, true)}
                  >
                    새탭으로
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(App)
