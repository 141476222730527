// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__tableContainer___2xdr4 {\n  min-height: 400px;\n  zoom: 0.97; }\n  .index-module__tableContainer___2xdr4 .index-module__header___27Tma {\n    position: relative; }\n  .index-module__tableContainer___2xdr4 .index-module__body___3ZXJ6 {\n    position: relative; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadList/LeadListTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU,EAAA;EAFZ;IAII,kBAAkB,EAAA;EAJtB;IAOI,kBAAkB,EAAA","file":"index.module.scss","sourcesContent":[".tableContainer {\n  min-height: 400px;\n  zoom: 0.97;\n  .header {\n    position: relative;\n  }\n  .body {\n    position: relative;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"tableContainer": "index-module__tableContainer___2xdr4",
	"header": "index-module__header___27Tma",
	"body": "index-module__body___3ZXJ6"
};
module.exports = exports;
