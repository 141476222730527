// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___utip1 {\n  position: relative; }\n  .index-module__container___utip1 .index-module__tableHeadContainer___NNCk2 {\n    background-color: #b388ff; }\n    .index-module__container___utip1 .index-module__tableHeadContainer___NNCk2 .index-module__cellText___1dYXU {\n      color: #ffffff;\n      font-weight: bold;\n      font-size: 12px; }\n  .index-module__container___utip1 .index-module__tableBodyContainer___P3ALv .index-module__row___ZYh8C .index-module__col___3DV2K {\n    font-weight: 500; }\n    .index-module__container___utip1 .index-module__tableBodyContainer___P3ALv .index-module__row___ZYh8C .index-module__col___3DV2K .index-module__edit___mN8KF {\n      width: 30px;\n      text-decoration: underline; }\n  .index-module__container___utip1 .index-module__tableBodyContainer___P3ALv .index-module__row___ZYh8C:nth-child(2n) {\n    background-color: rgba(0, 0, 0, 0.03); }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/ConfigDiscountRate/TableEditor/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;EADpB;IAGI,yBAAyB,EAAA;IAH7B;MAKM,cAAc;MACd,iBAAiB;MACjB,eAAe,EAAA;EAPrB;IAaQ,gBAAgB,EAAA;IAbxB;MAgBU,WAAW;MACX,0BAA0B,EAAA;EAjBpC;IAsBM,qCAAqC,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  position: relative;\n  .tableHeadContainer {\n    background-color: #b388ff;\n    .cellText {\n      color: #ffffff;\n      font-weight: bold;\n      font-size: 12px;\n    }\n  }\n  .tableBodyContainer {\n    .row {\n      .col {\n        font-weight: 500;\n\n        .edit {\n          width: 30px;\n          text-decoration: underline;\n        }\n      }\n    }\n    .row:nth-child(2n) {\n      background-color: rgba(0, 0, 0, 0.03);\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___utip1",
	"tableHeadContainer": "index-module__tableHeadContainer___NNCk2",
	"cellText": "index-module__cellText___1dYXU",
	"tableBodyContainer": "index-module__tableBodyContainer___P3ALv",
	"row": "index-module__row___ZYh8C",
	"col": "index-module__col___3DV2K",
	"edit": "index-module__edit___mN8KF"
};
module.exports = exports;
