import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import React, { useState } from 'react'

import useRootData from '../../../hooks/useRootData'
import { CHECK_LEAD_EXISTENCE } from '../../../stores/lead/query'
import client from '../../../utils/graphql'
import { autoHypenPhoneNumber, checkIsValiedPhoneNumber } from '../../../utils/utility'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent, createLead } = useRootData(({ appStore, leadStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    createLead: leadStore.createLead,
  }))

  const [phone, setPhone] = useState<string>('')

  const createLeadClickHandler = async (): Promise<void> => {
    try {
      if (!checkIsValiedPhoneNumber(phone)) {
        changeSnackbarAlertContent({ severity: 'error', content: '올바른 번호가 아닙니다!' })

        return
      }

      const { data: leadContent } = await client.query({
        query: CHECK_LEAD_EXISTENCE,
        variables: {
          phone: new PhoneNumber(phone.replace(/-/gi, ''), 'KR').getNumber('e164'),
        },
        fetchPolicy: 'no-cache',
      })

      if (leadContent.lead.length > 0) {
        changeSnackbarAlertContent({ severity: 'error', content: '중복된 번호가 있습니다!' })

        return
      }

      createLead(phone)

      changeSnackbarAlertContent({ severity: 'success', content: '생성 성공!!!' })
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: err })
    }
  }

  return (
    <TableContainer className={styles.container} component={Paper}>
      <Table className={styles.table} size="small" aria-label="a dense table">
        <TableHead className={styles.header}>
          <TableRow className={styles.rowTitle}>
            <TableCell className={styles.col} colSpan={3}>
              수동 추가 (RSV_PENDING 전까지 같은 고객 번호가 있으면 생성이 불가능합니다.)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">고객 전화번호</TableCell>
            <TableCell>
              <TextField
                className={styles.inputTextField}
                value={phone}
                variant="outlined"
                size="small"
                onChange={(event): void => setPhone(autoHypenPhoneNumber(event.target.value))}
              />
            </TableCell>
            <TableCell>
              <Button variant="outlined" onClick={(): Promise<void> => createLeadClickHandler()}>
                생성하기!
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(App)
