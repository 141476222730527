import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'

import useRootData from '../../../hooks/useRootData'

const App: React.FunctionComponent = () => {
  const { snackbarOpened, snackbarAlertContent, changeSnackbarVisibility } = useRootData(({ appStore }) => ({
    snackbarOpened: appStore.snackbarOpened.get(),
    snackbarAlertContent: appStore.snackbarAlertContent.get(),
    changeSnackbarVisibility: appStore.changeSnackbarVisibility,
  }))

  if (!snackbarAlertContent) return null

  const contentTextArray = snackbarAlertContent.content.split('\n')

  return (
    <Snackbar
      open={snackbarOpened}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={10000}
      onClose={() => changeSnackbarVisibility(false)}
    >
      <Alert variant="filled" onClose={() => changeSnackbarVisibility(false)} severity={snackbarAlertContent.severity}>
        {contentTextArray.length > 1
          ? contentTextArray.map((text, index) => <div key={index}>{text}</div>)
          : snackbarAlertContent.content}
      </Alert>
    </Snackbar>
  )
}

export default React.memo(App)
