// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___1JWPO {\n  margin: 100px auto;\n  max-width: 800px; }\n  .index-module__container___1JWPO .index-module__header___2zBPI .index-module__rowTitle___2zvo7 {\n    background-color: #b388ff; }\n    .index-module__container___1JWPO .index-module__header___2zBPI .index-module__rowTitle___2zvo7 .index-module__col___1Tujn {\n      color: #ffffff;\n      font-weight: bold;\n      font-size: 20px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadList/CreateLeadPopup/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB,EAAA;EAFlB;IAKM,yBAAyB,EAAA;IAL/B;MAOQ,cAAc;MACd,iBAAiB;MACjB,eAAe,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin: 100px auto;\n  max-width: 800px;\n  .header {\n    .rowTitle {\n      background-color: #b388ff;\n      .col {\n        color: #ffffff;\n        font-weight: bold;\n        font-size: 20px;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___1JWPO",
	"header": "index-module__header___2zBPI",
	"rowTitle": "index-module__rowTitle___2zvo7",
	"col": "index-module__col___1Tujn"
};
module.exports = exports;
