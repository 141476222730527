import './assets/scsses/global.scss'
import './assets/scsses/jsonEditor.scss'

import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import axios from 'axios'
import moment from 'moment-timezone'
import React from 'react'
import * as ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import App from './app'
import { StoreProvider } from './utils/context'
import client from './utils/graphql'
import history from './utils/history'
import initVersionChecker, { Version } from './utils/versionChecker'

moment.tz.setDefault('Asia/Seoul')

// version check
initVersionChecker().then((version: Version) => {
  // Sentry
  Sentry.init({
    dsn: 'https://9e4a027983ed4e7bae8c053632cbed65@o199723.ingest.sentry.io/5428963',
    release: version.hash,
    integrations: [new Integrations.BrowserTracing()],
    // If it's not in prouction, do not send Sentry!
    beforeSend(event) {
      if (window.location.hostname.indexOf('caramora.com') === -1) {
        try {
          // eslint-disable-next-line
        // console.log(JSON.stringify(event, null, 2))
        } catch {
          // eslint-disable-next-line
        // console.log(event)
        }

        return null
      }

      return event
    },
  })
})

// Axios
axios.defaults.withCredentials = true
// axios.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     Sentry.captureMessage(`method: ${error.config.method} - url: ${error.config.url}`)

//     return Promise.reject(error)
//   },
// )

ReactDOM.render(
  <Router history={history}>
    <StoreProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </StoreProvider>
  </Router>,
  document.getElementById('app'),
)

module.hot.accept()
