import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React from 'react'

import styles from './index.module.scss'

export interface TableEditorProps {
  soleNames: Array<string>
  areaNames: Array<string>
  discountRateRowsWithId: Array<Array<string>>
  displayNameById: (id: string) => string
  cellClickHandler: (isLowest: boolean, item: string, index: number, productId: string, productName: string) => void
  removeHandler?: (id: string) => void
}

const App: React.FunctionComponent<TableEditorProps> = ({
  soleNames,
  areaNames,
  discountRateRowsWithId,
  displayNameById,
  cellClickHandler,
  removeHandler,
}: TableEditorProps) => {
  return (
    <div>
      <TableContainer className={styles.container} component={Paper}>
        <Table>
          <TableHead className={styles.tableHeadContainer}>
            <TableRow>
              <TableCell className={styles.cellText}>총판</TableCell>
              <TableCell rowSpan={2} className={styles.cellText}>
                분류
              </TableCell>
              {soleNames &&
                soleNames.map((item: string, index: number) => (
                  <TableCell key={`soleNameHeader-${index}`} className={styles.cellText}>
                    {item}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow>
              <TableCell className={styles.cellText}>지역</TableCell>
              {areaNames &&
                areaNames.map((item: string, index: number) => (
                  <TableCell key={`areaNameHeader-${index}`} className={styles.cellText}>
                    {item}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBodyContainer}>
            {discountRateRowsWithId &&
              discountRateRowsWithId.map((tableRow: Array<string>, rowIndex: number) => {
                const productId = tableRow[0]
                const productDisplayName = displayNameById(productId)

                return (
                  <React.Fragment key={rowIndex}>
                    <TableRow className={styles.row}>
                      <TableCell rowSpan={2} className={styles.col}>
                        {productDisplayName}
                        {removeHandler && (
                          <IconButton aria-label="delete" color="secondary" onClick={() => removeHandler(productId)}>
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell className={styles.col}>L</TableCell>
                      {tableRow.slice(1).map((item: string, index: number) => (
                        <TableCell
                          key={index}
                          className={styles.col}
                          onClick={() => cellClickHandler(true, item, index, productId, productDisplayName)}
                        >
                          {item.split(':')[0]}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow className={styles.row}>
                      <TableCell className={styles.col}>P</TableCell>
                      {tableRow.slice(1).map((item: string, index: number) => (
                        <TableCell
                          key={index}
                          className={styles.col}
                          onClick={() => cellClickHandler(false, item, index, productId, productDisplayName)}
                        >
                          {item.split(':')[1]}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default React.memo(App)
