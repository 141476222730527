import { Button, MenuItem, TextField } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import React, { useState } from 'react'

import KakaoPixelType from '../../../../components/types/enums/kakaoPixelType.enum'
import { EnumNabusType } from '../../../../components/types/enums/nabusType.enum'
import config from '../../../../configs'
import useRootData from '../../../../hooks/useRootData'
import { PriceKey } from '../../../../stores/calculator/type'
import { getValidIdToken } from '../../../../utils/graphql'
import styles from './index.module.scss'

const selectPrice = [
  {
    value: 'price',
    label: '정가',
  },
  {
    value: 'premium',
    label: '프리미엄가',
  },
  {
    value: 'package',
    label: '패키지가',
  },
  {
    value: 'lowest',
    label: '최저가',
  },
]

interface PropsSelectQuotePriceModal {
  modalStatusHandler: () => void
}

const App: React.FunctionComponent<PropsSelectQuotePriceModal> = ({
  modalStatusHandler,
}: PropsSelectQuotePriceModal) => {
  const {
    selectedPackageObject,
    selectedStore,
    selectedLead,
    quoteObject,
    selectedCustomer,
    customerPhoneNumber,

    sendAnalytics,
    changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility,
    updateLeadByPK,
  } = useRootData(({ appStore, calculatorStore, quoteStore, leadStore }) => ({
    selectedPackageObject: calculatorStore.selectedPackageObject.get(),
    selectedStore: calculatorStore.selectedStore.get(),
    selectedLead: leadStore.selectedLead.get(),
    quoteObject: quoteStore.quoteObject.get(),
    selectedCustomer: leadStore.selectedCustomer.get(),
    customerPhoneNumber: leadStore.customerPhoneNumber.get(),
    sendAnalytics: appStore.sendAnalytics,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    updateLeadByPK: leadStore.updateLeadByPK,
  }))

  const [selectedPrice, setSelectedPrice] = useState<string>('')
  const [customerName, setCustomerName] = useState<string>('')

  const activeQuoteItemIndices = quoteObject.quote_items
    .map((item, index) => ({
      quoteItem: item,
      index,
    }))
    .filter(({ quoteItem }) => quoteItem.is_active || quoteItem.is_active === undefined)
    .map(({ index }) => index)

  if (activeQuoteItemIndices.length !== 1) {
    changeSnackbarAlertContent({ severity: 'error', content: `활성화된 견적이 하나만 존재해야 합니다` })

    modalStatusHandler()

    return null
  }

  const quoteIdx = activeQuoteItemIndices[0]

  const quoteItem = quoteObject.quote_items[quoteIdx]

  const sentAt =
    quoteItem && quoteItem.sent_at
      ? quoteItem.sent_at
      : { price: undefined, premium: undefined, package: undefined, lowest: undefined }

  const isAvalableSentQuote =
    Object.keys(sentAt)
      .map((key) => sentAt[key])
      .filter((value) => !!value === true).length > 0

  const selectPriceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPrice(event.target.value)
  }

  const reservationClickHandler = async () => {
    if (!customerName) {
      changeSnackbarAlertContent({ severity: 'error', content: '고객 이름 미입력!' })

      return
    }
    if (!selectedPrice) {
      changeSnackbarAlertContent({ severity: 'error', content: '전송할 가격을 선택해주세요' })

      return
    }
    if (!selectedStore[quoteIdx + 1]) {
      changeSnackbarAlertContent({ severity: 'error', content: '매장을 선택해주세요' })

      return
    }
    if (!selectedCustomer || !selectedCustomer.profile_kr_customer || !selectedCustomer.profile_kr_customer.email) {
      changeSnackbarAlertContent({
        severity: 'error',
        content: '고객 이메일을 찾을 수 없습니다! (카라모라 ID 확인 필요)',
      })

      return
    }

    await updateLeadByPK()

    modalStatusHandler()

    changeLoadingSpinnerVisibility(true)

    try {
      const { data: cartItemObject } = await axios.post(`${config.backendEndPoint}/lead/create-cart-from-admin`, {
        leadId: selectedLead.id,
        quoteItemId: quoteItem.id,
        selectedPrice,
      })

      const userInputValues = {
        name: customerName,
        email: selectedCustomer.profile_kr_customer.email,
        phoneNumber: customerPhoneNumber,
      }

      await axios.post(`${config.backendEndPoint}/order/create-order`, {
        idToken: await getValidIdToken(),
        data: cartItemObject,
        userInfo: {
          name: userInputValues.name,
          email: userInputValues.email,
          phoneNumber: userInputValues.phoneNumber,
          dateTime: cartItemObject.date_time,
          store: cartItemObject.store,
        },
        accountId: selectedCustomer.id,
      })

      sendAnalytics({
        kakaoPixel: KakaoPixelType.PLACE_ORDER,
        nabus: EnumNabusType.RESERVATION,
      })

      changeSnackbarAlertContent({ severity: 'success', content: `수동 주문 완료` })
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: `처리 실패\n${err}` })
    }

    changeLoadingSpinnerVisibility(false)
  }

  return (
    <div className={styles.container}>
      <TextField
        className={styles.textBox}
        label="고객 이름"
        value={customerName}
        onChange={(e) => {
          setCustomerName(e.target.value)
        }}
        helperText="고객 이름을 입력해 주세요"
      />
      <br />
      <TextField
        className={styles.selectBox}
        select
        label="Select"
        value={selectedPrice}
        onChange={selectPriceHandler}
        helperText="전송할 가격을 선택해주세요"
        variant="filled"
      >
        {selectPrice
          .filter((item) => (selectedPackageObject[quoteIdx + 1] ? item.value === 'package' : true))
          .filter((item) => (isAvalableSentQuote ? sentAt[item.value as PriceKey] : true))
          .map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>

      <div className={styles.btnArea}>
        <div className={styles.btnFrame}>
          <Button variant="contained" color="primary" onClick={reservationClickHandler}>
            예약 진행
          </Button>
          <Button variant="contained" color="secondary" onClick={modalStatusHandler}>
            취소
          </Button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(App)
