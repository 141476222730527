import JSONEditor, { JSONEditorOptions } from 'jsoneditor'
import React, { useEffect, useRef, useState } from 'react'

interface JsonEditorProps {
  value: unknown
  onChange: (data: unknown) => void
}

const App: React.FunctionComponent<JsonEditorProps> = ({ value, onChange }: JsonEditorProps) => {
  const ref = useRef()
  const [editor, setEditor] = useState(null)

  const options: JSONEditorOptions = {
    mode: 'tree',
    onChangeJSON: onChange,
  }

  useEffect(() => {
    const jsonEditor = new JSONEditor(ref.current, options)
    setEditor(jsonEditor)
    jsonEditor.set(value)
  }, [])

  useEffect(() => {
    if (editor) {
      editor.update(value)
    }
  }, [value])

  return <div ref={ref}></div>
}

export default React.memo(App)
