import { useLazyQuery } from '@apollo/react-hooks'
import { Button } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Car,
  Car_Sunroof_Type as CarSunroofType,
  Car_Sunroof_Type_Enum as CarSunroofTypeEnum,
  Order_Insert_Input as OrderInsertInput,
  Package,
  Product_Item as ProductItem,
  Store,
} from '../../assets/graphql/graphql'
import Text from '../../components/atoms/Text'
import useRootData from '../../hooks/useRootData'
import {
  _GET_SEARCH_CARS as GET_SEARCH_CARS,
  _GET_SEARCH_STORES as GET_SEARCH_STORES,
  _GET_SELECT_OPTIONS as GET_SELECT_OPTIONS,
  _GET_SELECT_PRODUCTS as GET_SELECT_PRODUCTS,
} from '../../stores/calculator/query'
import client from '../../utils/graphql'
import { formatDate, parseQueryString } from '../../utils/utility'
import {
  PriceValuesStructure,
  RemarkValuesStructure,
  SearchKeywordStructure,
  SelectedValuesStructure,
} from '../CartItemDetail'
import CarSection from '../CartItemDetail/CarSection'
import OtherProductSection from '../CartItemDetail/OtherProductSection'
import PackageSection from '../CartItemDetail/PackageSection'
import PriceSection from '../CartItemDetail/PriceSection'
import RemarkSection from '../CartItemDetail/RemarkSection'
import StoreSection from '../CartItemDetail/StoreSection'
import TintingSection from '../CartItemDetail/TintingSection'
import { _GET_ORDER as GET_ORDER, _UPDATE_ORDER as UPDATE_ORDER } from './query'

export interface UserValuesStructure {
  account: string
  name: string
  email: string
  phone: string
  status: string
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { orderId } = parseQueryString()

  const [userValues, setUserValues] = useState<UserValuesStructure>({
    account: '',
    name: '',
    email: '',
    phone: '',
    status: '',
  })

  const [searchKeyword, _setSearchKeyword] = useState<SearchKeywordStructure>({
    carSearchKeyword: '',
    storeSearchKeyword: '',
  })

  const [selectedValues, _setSeletedValeus] = useState<SelectedValuesStructure>({
    carOrigin: '',
    carModelId: '',
    carType: '',
    carSunroofType: 'none',
    packageId: '',
    frontTintingProductItemId: '',
    sidebackTintingProductItemId: '',
    sunroofTintingProductItemId: '',
    dashcamProductItemId: '',
    ceramicCoatingProductItemId: '',
    ppfProductItemId: '',
    newCarInspectionProductId: '',
    storeId: '',
    dateTime: formatDate(),
  })

  const [priceValues, _setPriceValues] = useState<PriceValuesStructure>({
    priceProduct: 0,
    priceExtra: 0,
    priceDiscount: 0,
    pricePayout: 0,
  })

  const [remarkValues, _setRemarkValues] = useState<RemarkValuesStructure>({
    productRemark: '',
    dateTimeRemark: '',
    priceRemark: '',
  })

  const setSearchKeyword = useCallback(
    (object: SearchKeywordStructure) => {
      _setSearchKeyword({
        ...searchKeyword,
        ...object,
      })
    },
    [searchKeyword],
  )

  const setSeletedValeus = useCallback(
    (object: SelectedValuesStructure) => {
      _setSeletedValeus({
        ...selectedValues,
        ...object,
      })
    },
    [selectedValues],
  )

  const setPriceValues = useCallback(
    (object: PriceValuesStructure) => {
      _setPriceValues({
        ...priceValues,
        ...object,
      })
    },
    [priceValues],
  )

  const setRemarkValues = useCallback(
    (object: RemarkValuesStructure) => {
      _setRemarkValues({
        ...remarkValues,
        ...object,
      })
    },
    [remarkValues],
  )

  // 랜더링 최적화 (searchKeyword)
  const carSearchKeyword = useMemo(() => searchKeyword.carSearchKeyword, [searchKeyword.carSearchKeyword])
  const storeSearchKeyword = useMemo(() => searchKeyword.storeSearchKeyword, [searchKeyword.storeSearchKeyword])

  // 렌더링 최적화 (selectedValues)
  const carOrigin = useMemo(() => selectedValues.carOrigin, [selectedValues.carOrigin])
  const carModelId = useMemo(() => selectedValues.carModelId, [selectedValues.carModelId])
  const carType = useMemo(() => selectedValues.carType, [selectedValues.carType])
  const carSunroofType = useMemo(() => selectedValues.carSunroofType, [selectedValues.carSunroofType])
  const packageId = useMemo(() => selectedValues.packageId, [selectedValues.packageId])
  const frontTintingProductItemId = useMemo(() => selectedValues.frontTintingProductItemId, [
    selectedValues.frontTintingProductItemId,
  ])
  const sidebackTintingProductItemId = useMemo(() => selectedValues.sidebackTintingProductItemId, [
    selectedValues.sidebackTintingProductItemId,
  ])
  const sunroofTintingProductItemId = useMemo(() => selectedValues.sunroofTintingProductItemId, [
    selectedValues.sunroofTintingProductItemId,
  ])
  const dashcamProductItemId = useMemo(() => selectedValues.dashcamProductItemId, [selectedValues.dashcamProductItemId])
  const ceramicCoatingProductItemId = useMemo(() => selectedValues.ceramicCoatingProductItemId, [
    selectedValues.ceramicCoatingProductItemId,
  ])
  const ppfProductItemId = useMemo(() => selectedValues.ppfProductItemId, [selectedValues.ppfProductItemId])
  const newCarInspectionProductId = useMemo(() => selectedValues.newCarInspectionProductId, [
    selectedValues.newCarInspectionProductId,
  ])
  const storeId = useMemo(() => selectedValues.storeId, [selectedValues.storeId])
  const dateTime = useMemo(() => selectedValues.dateTime, [selectedValues.dateTime])

  // 렌더링 최적화 (priceValues)
  const priceProduct = useMemo(() => priceValues.priceProduct, [priceValues.priceProduct])
  const priceExtra = useMemo(() => priceValues.priceExtra, [priceValues.priceExtra])
  const priceDiscount = useMemo(() => priceValues.priceDiscount, [priceValues.priceDiscount])
  const pricePayout = useMemo(() => priceValues.pricePayout, [priceValues.pricePayout])
  const pricePayoutWithoutVat = useMemo(() => Math.floor(pricePayout * 0.91) || 0, [pricePayout])
  const priceFinal = useMemo(() => priceProduct + priceExtra - priceDiscount, [priceProduct, priceExtra, priceDiscount])
  const commissionRate = useMemo(() => parseFloat((((priceFinal - pricePayout) * 100) / priceFinal).toFixed(2)) || 0, [
    priceFinal,
    pricePayout,
  ])

  // 랜더링 최적화 (priceValues)
  const productRemark = useMemo(() => remarkValues.productRemark, [remarkValues.productRemark])
  const dateTimeRemark = useMemo(() => remarkValues.dateTimeRemark, [remarkValues.dateTimeRemark])
  const priceRemark = useMemo(() => remarkValues.priceRemark, [remarkValues.priceRemark])
  const customerRemark = useMemo(() => remarkValues.customerRemark, [remarkValues.customerRemark])

  const [getSelectOptions, { data: selectOptionsContents }] = useLazyQuery(GET_SELECT_OPTIONS, {
    fetchPolicy: 'no-cache',
  })
  const [getSelectProducts, { data: selectProductsContents }] = useLazyQuery(GET_SELECT_PRODUCTS, {
    variables: {
      carOrigin: carOrigin === 'korea' ? '%korea%' : '%other%',
      carType: `%${carType}%`,
      carSunroofType: `%${carSunroofType}%`,
    },
    fetchPolicy: 'no-cache',
  })

  const [getSearchCars, { data: searchCarsContents }] = useLazyQuery(GET_SEARCH_CARS, {
    variables: {
      carSearchKeyword: `%${carSearchKeyword}%`,
    },
    fetchPolicy: 'no-cache',
  })

  const [getSearchStores, { data: searchStoresContents }] = useLazyQuery(GET_SEARCH_STORES, {
    variables: {
      storeSearchKeyword: `%${storeSearchKeyword}%`,
    },
    fetchPolicy: 'no-cache',
  })

  // 선택된 product item들의 객체
  const selectedPackageObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.packages
        ? selectProductsContents.packages.filter((item: Package) => item.id === packageId)[0]
        : null,
    [selectProductsContents, packageId],
  )

  const frontTintingProductItemObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.front_tinting_product_items
        ? selectProductsContents.front_tinting_product_items.filter(
            (item: ProductItem) => item.id === frontTintingProductItemId,
          )[0]
        : null,
    [selectProductsContents, frontTintingProductItemId],
  )

  const sidebackTintingProductItemObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.sideback_tinting_product_items
        ? selectProductsContents.sideback_tinting_product_items.filter(
            (item: ProductItem) => item.id === sidebackTintingProductItemId,
          )[0]
        : null,
    [selectProductsContents, sidebackTintingProductItemId],
  )

  const sunroofTintingProductItemObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.sunroof_tinting_product_items
        ? selectProductsContents.sunroof_tinting_product_items.filter(
            (item: ProductItem) => item.id === sunroofTintingProductItemId,
          )[0]
        : null,
    [selectProductsContents, sunroofTintingProductItemId],
  )

  const dashcamProductItemObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.dashcam_product_items
        ? selectProductsContents.dashcam_product_items.filter(
            (item: ProductItem) => item.id === dashcamProductItemId,
          )[0]
        : null,
    [selectProductsContents, dashcamProductItemId],
  )

  const ceramicCoatingProductItemObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.ceramic_coating_product_items
        ? selectProductsContents.ceramic_coating_product_items.filter(
            (item: ProductItem) => item.id === ceramicCoatingProductItemId,
          )[0]
        : null,
    [selectProductsContents, ceramicCoatingProductItemId],
  )

  const ppfProductItemObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.ppf_product_items
        ? selectProductsContents.ppf_product_items.filter((item: ProductItem) => item.id === ppfProductItemId)[0]
        : null,
    [selectProductsContents, ppfProductItemId],
  )

  const newCarInspectionProductObject = useMemo(
    () =>
      selectProductsContents && selectProductsContents.new_car_inspection_product_items
        ? selectProductsContents.new_car_inspection_product_items.filter(
            (item: ProductItem) => item.id === newCarInspectionProductId,
          )[0]
        : null,
    [selectProductsContents, newCarInspectionProductId],
  )

  const searchCarArray = useMemo(
    () =>
      searchCarsContents && searchCarsContents.searchCars
        ? [
            { value: '', text: '검색 결과' },
            ...searchCarsContents.searchCars.map((searchCar: Car) => {
              return {
                value: searchCar.id,
                text: `[${searchCar.car_maker.name_kr}] ${searchCar.model}`,
              }
            }),
          ]
        : [{ value: '', text: '' }],

    [searchCarsContents],
  )

  const carSunroofTypeArray = useMemo(
    () =>
      selectOptionsContents && selectOptionsContents.carSunroofTypes
        ? selectOptionsContents &&
          selectOptionsContents.carSunroofTypes.map((carSunroofTypeObject: CarSunroofType) => {
            return {
              value: carSunroofTypeObject.value,
              text: carSunroofTypeObject.description,
            }
          })
        : [{ value: '', text: '' }],
    [selectOptionsContents],
  )

  const packageArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.packages
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.packages.map((packageObject: Package) => {
              return {
                value: packageObject.id,
                text: packageObject.name,
              }
            }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents],
  )

  const frontTintingProductItemArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.front_tinting_product_items
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.front_tinting_product_items
              .filter((item: ProductItem) => {
                if (selectedPackageObject) {
                  if (selectedPackageObject.product.package.tinting.front) {
                    return item.productByProduct.id === selectedPackageObject.product.package.tinting.front.product_id
                  }

                  return false
                }

                return true
              })
              .map((productItemObject: ProductItem) => {
                const { id: productItemId, productByProduct, attribute } = productItemObject

                return {
                  value: productItemId,
                  text: `[${productByProduct.product_brand.description}] ${productByProduct.name}-${attribute.density}%`,
                }
              }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents, selectedPackageObject],
  )

  const sidebackTintingProductItemArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.sideback_tinting_product_items
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.sideback_tinting_product_items
              .filter((item: ProductItem) => {
                if (selectedPackageObject) {
                  if (selectedPackageObject.product.package.tinting.sideback) {
                    return (
                      item.productByProduct.id === selectedPackageObject.product.package.tinting.sideback.product_id
                    )
                  }

                  return false
                }

                return true
              })
              .map((productItemObject: ProductItem) => {
                const { id: productItemId, productByProduct, attribute } = productItemObject

                return {
                  value: productItemId,
                  text: `[${productByProduct.product_brand.description}] ${productByProduct.name}-${attribute.density}%`,
                }
              }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents, selectedPackageObject],
  )

  const sunroofTintingProductItemArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.sunroof_tinting_product_items
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.sunroof_tinting_product_items
              .filter((item: ProductItem) => {
                if (selectedPackageObject) {
                  if (selectedPackageObject.product.package.tinting.sunroof) {
                    return item.productByProduct.id === selectedPackageObject.product.package.tinting.sunroof.product_id
                  }

                  return false
                }

                return true
              })
              .map((productItemObject: ProductItem) => {
                const { id: productItemId, productByProduct, attribute } = productItemObject

                return {
                  value: productItemId,
                  text: `[${productByProduct.product_brand.description}] ${productByProduct.name}-${attribute.density}%`,
                }
              }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents, selectedPackageObject],
  )

  const dashcamProductItemArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.dashcam_product_items
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.dashcam_product_items
              .filter((item: ProductItem) => {
                if (selectedPackageObject) {
                  if (selectedPackageObject.attribute.selection.dashcam) {
                    return item.id === selectedPackageObject.attribute.selection.dashcam
                  }

                  return false
                }

                return true
              })
              .map((productItemObject: ProductItem) => {
                const { id: productItemId, productByProduct, attribute } = productItemObject

                return {
                  value: productItemId,
                  text: `[${productByProduct.product_brand.description}] ${productByProduct.name} / ${attribute.storage} / ${attribute.channel}`,
                }
              }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents, selectedPackageObject],
  )

  const ceramicCoatingProductItemArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.ceramic_coating_product_items
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.ceramic_coating_product_items
              .filter((item: ProductItem) => {
                if (selectedPackageObject) {
                  if (selectedPackageObject.product.package.ceramic_coating) {
                    return item.productByProduct.id === selectedPackageObject.product.package.ceramic_coating.product_id
                  }

                  return false
                }

                return true
              })
              .map((productItemObject: ProductItem) => {
                const { id: productItemId, productByProduct } = productItemObject

                return {
                  value: productItemId,
                  text: `[${productByProduct.product_brand.description}] ${productByProduct.name}`,
                }
              }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents, selectedPackageObject],
  )

  const ppfProductItemArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.ppf_product_items
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.ppf_product_items
              .filter((item: ProductItem) => {
                if (selectedPackageObject) {
                  if (selectedPackageObject.attribute.selection.ppf) {
                    return item.id === selectedPackageObject.attribute.selection.ppf
                  }

                  return false
                }

                return true
              })
              .map((productItemObject: ProductItem) => {
                const { id: productItemId, productByProduct, attribute } = productItemObject

                return {
                  value: productItemId,
                  text: `[${productByProduct.product_brand.description}] ${productByProduct.name} ${
                    attribute.product_name.split('_')[1]
                  }종`,
                }
              }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents, selectedPackageObject],
  )

  const newCarInspectionProductItemArray = useMemo(
    () =>
      selectProductsContents && selectProductsContents.new_car_inspection_product_items
        ? [
            { value: '', text: '선택 안함' },
            ...selectProductsContents.new_car_inspection_product_items
              .filter((item: ProductItem) => {
                if (selectedPackageObject) {
                  if (selectedPackageObject.attribute.selection.new_car_inspection) {
                    return item.id === selectedPackageObject.attribute.selection.new_car_inspection
                  }

                  return false
                }

                return true
              })
              .map((productItemObject: ProductItem) => {
                return {
                  value: productItemObject.id,
                  text: `[서비스 항목] 신차검수 ${productItemObject.price}`,
                }
              }),
          ]
        : [{ value: '', text: '' }],
    [selectProductsContents, selectedPackageObject],
  )

  const searchStoreArray = useMemo(
    () =>
      searchStoresContents && searchStoresContents.searchStores
        ? [
            { value: '', text: '검색 결과' },
            ...searchStoresContents.searchStores.map((searchStore: Store) => {
              return {
                value: searchStore.id,
                text: `[${searchStore.subscriptions.length > 0 ? searchStore.subscriptions[0].status : ''}] ${
                  searchStore.name
                } (${searchStore.address_detail})`,
              }
            }),
          ]
        : [{ value: '', text: '' }],

    [searchStoresContents],
  )

  const storeArray = useMemo(
    () =>
      selectOptionsContents && selectOptionsContents.stores
        ? [
            { value: '', text: '선택 안함' },
            ...selectOptionsContents.stores.map((storeObject: Store) => {
              return {
                value: storeObject.id,
                text: `[${storeObject.subscriptions.length > 0 ? storeObject.subscriptions[0].status : ''}] ${
                  storeObject.name
                } (${storeObject.address_detail})`,
              }
            }),
          ]
        : [{ value: '', text: '' }],
    [selectOptionsContents],
  )

  const createOrderInsertInputObject = (): OrderInsertInput => {
    if (!selectOptionsContents) return null

    const cartItemInsertInputObject: OrderInsertInput = {
      id: orderId,
      car: carModelId,
      car_sunroof_type: carSunroofType as CarSunroofTypeEnum,
      store: storeId,
      price_product: priceProduct,
      price_discount: priceDiscount,
      price_extra: priceExtra,
      price_final: priceFinal,
      price_payout: pricePayout,
      price_payout_without_vat: pricePayoutWithoutVat,
      commission_rate: commissionRate,
      product_item: {},
      date_time: moment(dateTime).locale('ko'),
      remark: {
        price_remark: priceRemark,
        product_remark: productRemark,
        date_time_remark: dateTimeRemark,
        customer_remark: customerRemark,
      },
    }

    const tempTintingObject = {
      tinting: {
        ...(frontTintingProductItemId ? { front: frontTintingProductItemId } : {}),
        ...(sidebackTintingProductItemId ? { sideback: sidebackTintingProductItemId } : {}),
        ...(sunroofTintingProductItemId ? { sunroof: sunroofTintingProductItemId } : {}),
      },
    }

    const otherProductObject = {
      ...(dashcamProductItemId ? { dashcam: dashcamProductItemId } : {}),
      ...(ceramicCoatingProductItemId ? { ceramic_coating: ceramicCoatingProductItemId } : {}),
      ...(ppfProductItemId ? { ppf: ppfProductItemId } : {}),
      ...(newCarInspectionProductId ? { new_car_inspection: newCarInspectionProductId } : {}),
    }

    // package
    if (packageId && selectedPackageObject) {
      const tempProductItem = {
        package: {
          [packageId]: {
            ...tempTintingObject,
            ...otherProductObject,
          },
        },
      }

      cartItemInsertInputObject.product_item = tempProductItem
    }
    // custom package
    else if (dashcamProductItemId || ceramicCoatingProductItemId || ppfProductItemId || newCarInspectionProductId) {
      const tempProductItem = {
        package: {
          [selectProductsContents.custom_package[0].id]: {
            ...tempTintingObject,
            ...otherProductObject,
          },
        },
      }

      cartItemInsertInputObject.product_item = tempProductItem
    }
    // tinting
    else {
      const tempProductItem = {
        ...tempTintingObject,
      }

      cartItemInsertInputObject.product_item = tempProductItem
    }

    return cartItemInsertInputObject
  }

  const upsertButtonClickHandler = async () => {
    try {
      // 패키지면 예외처리
      if (selectedPackageObject) {
        if (frontTintingProductItemArray.length > 1 && !frontTintingProductItemObject) {
          changeSnackbarAlertContent({ severity: 'error', content: '전면 썬팅 제품을 골라주세요!' })

          return
        }
        if (sidebackTintingProductItemArray.length > 1 && !sidebackTintingProductItemObject) {
          changeSnackbarAlertContent({ severity: 'error', content: '측후면 썬팅 제품을 골라주세요!' })

          return
        }
        if (sunroofTintingProductItemArray.length > 1 && !sunroofTintingProductItemObject) {
          changeSnackbarAlertContent({ severity: 'error', content: '썬루프 썬팅 제품을 골라주세요!' })

          return
        }
        if (dashcamProductItemArray.length > 1 && !dashcamProductItemObject) {
          changeSnackbarAlertContent({ severity: 'error', content: '블랙박스 제품을 골라주세요!' })

          return
        }
        if (ceramicCoatingProductItemArray.length > 1 && !ceramicCoatingProductItemObject) {
          changeSnackbarAlertContent({ severity: 'error', content: '유리막 제품을 골라주세요!' })

          return
        }
        if (ppfProductItemArray.length > 1 && !ppfProductItemObject) {
          changeSnackbarAlertContent({ severity: 'error', content: 'PPF 제품을 골라주세요!' })

          return
        }
        if (newCarInspectionProductItemArray.length > 1 && !newCarInspectionProductObject) {
          changeSnackbarAlertContent({ severity: 'error', content: '신차검수 제품을 골라주세요!' })

          return
        }
      }

      if (!priceProduct) {
        changeSnackbarAlertContent({ severity: 'error', content: '제품을 하나 이상 골라주세요!' })

        return
      }

      if (!pricePayout) {
        changeSnackbarAlertContent({ severity: 'error', content: '매장 이체 금액을 입력해주세요!' })

        return
      }

      if (!storeId) {
        changeSnackbarAlertContent({ severity: 'error', content: '매장을 골라주세요!' })

        return
      }

      if (!dateTime) {
        changeSnackbarAlertContent({ severity: 'error', content: '시공 날짜를 골라주세요!' })

        return
      }

      await client.mutate({
        mutation: UPDATE_ORDER,
        variables: {
          orderObject: {
            ...userValues,
            ...createOrderInsertInputObject(),
          },
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '저장(수정) 성공' })

      changeMainMenu(`/order`)
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '저장(수정) 실패' })
    }
  }

  useEffect(() => {
    getSelectOptions()
    getSelectProducts()
    getSearchCars()
    getSearchStores()

    if (!orderId) return

    client
      .query({
        query: GET_ORDER,
        variables: {
          orderId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { order_by_pk: content } }) => {
        const {
          car,
          carByCar,
          car_sunroof_type: currentCarSunroofType,
          store,
          package_detail: packageDetail,
          product_item_detail: productItemDetail,
          date_time: selectedDateTime,
          price_discount: currentPriceDiscount,
          price_extra: currentPriceExtra,
          price_payout: currentPricePayout,
          price_product: currentPriceProduct,
          account,
          name,
          email,
          phone,
          status,
          remark,
        } = content

        const packageObject = packageDetail.length > 0 ? packageDetail[0] : null

        const tempSeletedValues = {
          carOrigin: car ? carByCar.car_maker.car_origin.name_us : '',
          carModelId: car || '',
          carType: car ? carByCar.type : '',
          carSunroofType: currentCarSunroofType || 'none',
          packageId: (packageObject && packageObject.id) || '',
          frontTintingProductItemId: '',
          sidebackTintingProductItemId: '',
          sunroofTintingProductItemId: '',
          dashcamProductItemId: '',
          ceramicCoatingProductItemId: '',
          ppfProductItemId: '',
          newCarInspectionProductId: '',
          storeId: store || '',
          dateTime: moment(selectedDateTime).locale('ko').format('YYYY-MM-DDTHH:00'),
        }

        const tempPriceValues = {
          priceProduct: currentPriceProduct,
          priceExtra: currentPriceExtra,
          priceDiscount: currentPriceDiscount,
          pricePayout: currentPricePayout,
        }

        productItemDetail.forEach((productItem: ProductItem) => {
          const { id: productItemId, attribute, productByProduct } = productItem
          switch (productByProduct.product_type.value) {
            case 'tinting':
              switch (attribute.part) {
                case 'front':
                  tempSeletedValues.frontTintingProductItemId = productItemId
                  break
                case 'all':
                  tempSeletedValues.sidebackTintingProductItemId = productItemId
                  break
                case 'sunroof_normal':
                case 'sunroof_panorama':
                  tempSeletedValues.sunroofTintingProductItemId = productItemId
                  break
                default:
              }
              break
            case 'dashcam':
              tempSeletedValues.dashcamProductItemId = productItemId
              break
            case 'ceramic_coating':
              tempSeletedValues.ceramicCoatingProductItemId = productItemId
              break
            case 'ppf':
              tempSeletedValues.ppfProductItemId = productItemId
              break
            case 'new_car_inspection':
              tempSeletedValues.newCarInspectionProductId = productItemId
              break
            default:
          }
        })

        _setPriceValues(tempPriceValues)

        _setRemarkValues({
          productRemark: remark.product_remark || '',
          dateTimeRemark: remark.dateTime_remark || '',
          priceRemark: remark.price_remark || '',
          customerRemark: remark.customer_remark || '',
        })

        setUserValues({
          account,
          name,
          email,
          phone,
          status,
        })

        _setSeletedValeus(tempSeletedValues)
      })
  }, [])

  useEffect(() => {
    if ((packageId && selectedPackageObject) || !carModelId || !selectOptionsContents) return
    const tempProductPrice = [
      frontTintingProductItemObject,
      sidebackTintingProductItemObject,
      sunroofTintingProductItemObject,
      dashcamProductItemObject,
      ceramicCoatingProductItemObject,
      ppfProductItemObject,
      newCarInspectionProductObject,
    ]
      .map((item: ProductItem) => {
        return (item && item.price) || 0
      })
      .reduce((a, b) => a + b)

    setPriceValues({
      priceProduct: tempProductPrice,
    })
  }, [
    carModelId,
    packageId,
    selectOptionsContents,
    frontTintingProductItemId,
    sidebackTintingProductItemId,
    sunroofTintingProductItemId,
    dashcamProductItemId,
    ceramicCoatingProductItemId,
    ppfProductItemId,
    newCarInspectionProductId,
  ])

  return (
    <div style={{ paddingBottom: 80 }}>
      {orderId && (
        <Text
          style={{ fontSize: 16, borderBottom: '1px solid', width: 'fit-content', marginBottom: 20 }}
          text={`Order ID : ${orderId}`}
        ></Text>
      )}

      {/* 차량관련 */}
      <CarSection
        carSearchKeyword={carSearchKeyword}
        searchCarArray={searchCarArray}
        carModelId={carModelId}
        carSunroofType={carSunroofType}
        carSunroofTypeArray={carSunroofTypeArray}
        selectOptionsContents={selectOptionsContents}
        setSearchKeyword={setSearchKeyword}
        setSeletedValeus={setSeletedValeus}
      ></CarSection>

      {/* 패키지관련 */}
      {carModelId && (
        <PackageSection
          carOrigin={carOrigin}
          carType={carType}
          packageId={packageId}
          packageArray={packageArray}
          selectProductsContents={selectProductsContents}
          setSeletedValeus={setSeletedValeus}
          setPriceValues={setPriceValues}
        ></PackageSection>
      )}

      {/* 제품관련 - 1 */}
      {carModelId && (
        <TintingSection
          frontTintingProductItemId={frontTintingProductItemId}
          frontTintingProductItemArray={frontTintingProductItemArray}
          sidebackTintingProductItemId={sidebackTintingProductItemId}
          sidebackTintingProductItemArray={sidebackTintingProductItemArray}
          sunroofTintingProductItemId={sunroofTintingProductItemId}
          sunroofTintingProductItemArray={sunroofTintingProductItemArray}
          setSeletedValeus={setSeletedValeus}
        ></TintingSection>
      )}

      {/* 제품관련 - 2 */}
      {carModelId && (
        <OtherProductSection
          dashcamProductItemId={dashcamProductItemId}
          dashcamProductItemArray={dashcamProductItemArray}
          ceramicCoatingProductItemId={ceramicCoatingProductItemId}
          ceramicCoatingProductItemArray={ceramicCoatingProductItemArray}
          ppfProductItemId={ppfProductItemId}
          ppfProductItemArray={ppfProductItemArray}
          newCarInspectionProductId={newCarInspectionProductId}
          newCarInspectionProductItemArray={newCarInspectionProductItemArray}
          setSeletedValeus={setSeletedValeus}
        ></OtherProductSection>
      )}

      {/* 매장, 시공날짜 */}
      {carModelId && (
        <StoreSection
          storeSearchKeyword={storeSearchKeyword}
          searchStoreArray={searchStoreArray}
          storeId={storeId}
          storeArray={storeArray}
          dateTime={dateTime}
          setSearchKeyword={setSearchKeyword}
          setSeletedValeus={setSeletedValeus}
        ></StoreSection>
      )}

      {/* 가격관련 */}
      {carModelId && (
        <PriceSection
          priceProduct={priceProduct}
          priceExtra={priceExtra}
          priceDiscount={priceDiscount}
          pricePayout={pricePayout}
          pricePayoutWithoutVat={pricePayoutWithoutVat}
          priceFinal={priceFinal}
          commissionRate={commissionRate}
          setPriceValues={setPriceValues}
        ></PriceSection>
      )}

      {/* 비고란 관련 */}
      {carModelId && (
        <RemarkSection
          productRemark={productRemark}
          dateTimeRemark={dateTimeRemark}
          priceRemark={priceRemark}
          customerRemark={customerRemark}
          setRemarkValues={setRemarkValues}
        ></RemarkSection>
      )}

      {carModelId && (
        <Button
          style={{
            color: 'white',
            position: 'fixed',
            bottom: 0,
            width: '84%',
            backgroundColor: 'black',
            height: 78,
            fontSize: 20,
          }}
          onClick={(): Promise<void> => upsertButtonClickHandler()}
          variant="contained"
        >
          저장하기
        </Button>
      )}
    </div>
  )
}

export default React.memo(App)
