import gql from 'graphql-tag'

import { Order_Select_Column as OrderSelectColumn } from '../../assets/graphql/graphql'

export const pickedColumns: OrderSelectColumn[] = [
  OrderSelectColumn.Id,
  OrderSelectColumn.Store,
  OrderSelectColumn.PriceDiscount,
  OrderSelectColumn.PriceExtra,
  OrderSelectColumn.PriceFinal,
  OrderSelectColumn.PriceProduct,
  OrderSelectColumn.ProductItem,
  OrderSelectColumn.PricePayout,
  OrderSelectColumn.PricePayoutExtra,
  OrderSelectColumn.PricePayoutDiscount,
  OrderSelectColumn.CommissionRate,
  OrderSelectColumn.Remark,
  OrderSelectColumn.Account,
]

export const UPDATE_ORDER_WITH_LEAD = gql`
  mutation UPDATE_ORDER_WITH_LEAD($orderId: uuid!, $orderObject: order_set_input!) {
    update_order_by_pk(pk_columns: { id: $orderId }, _set: $orderObject) {
      id
    }
  }
`
