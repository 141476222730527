// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___348vZ {\n  margin-top: 15px;\n  position: relative;\n  max-width: 800px; }\n  .index-module__container___348vZ .index-module__tableHeadContainer___3pYzC .index-module__rowTitle___1gLXx {\n    background-color: #b388ff; }\n    .index-module__container___348vZ .index-module__tableHeadContainer___3pYzC .index-module__rowTitle___1gLXx .index-module__col___1_OWn {\n      color: #ffffff;\n      font-weight: bold; }\n  .index-module__container___348vZ .index-module__tableBodyContainer___3QvnZ .index-module__row___UYyQX .index-module__col___1_OWn {\n    cursor: pointer; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/ConfigCommissionRate/index.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB,EAAA;EAHlB;IAMM,yBAAyB,EAAA;IAN/B;MAQQ,cAAc;MACd,iBAAiB,EAAA;EATzB;IAgBQ,eAAe,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin-top: 15px;\n  position: relative;\n  max-width: 800px;\n  .tableHeadContainer {\n    .rowTitle {\n      background-color: #b388ff;\n      .col {\n        color: #ffffff;\n        font-weight: bold;\n      }\n    }\n  }\n  .tableBodyContainer {\n    .row {\n      .col {\n        cursor: pointer;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___348vZ",
	"tableHeadContainer": "index-module__tableHeadContainer___3pYzC",
	"rowTitle": "index-module__rowTitle___1gLXx",
	"col": "index-module__col___1_OWn",
	"tableBodyContainer": "index-module__tableBodyContainer___3QvnZ",
	"row": "index-module__row___UYyQX"
};
module.exports = exports;
