import { AppBar, CssBaseline, Toolbar, Typography } from '@material-ui/core'
import React from 'react'

import AlertSnackbar from '../../components/molecule/AlertSnackbar'
import Menu from '../../components/organism/Menu'
import styles from './index.module.scss'

const noHomeBarLocation = ['/lead-detail', '/integrated-admin-list']

export interface PropsDefaultLayout {
  children: React.ReactNode
  className?: string
  isManagementWeb?: boolean
  titleClickHandler?: () => void
}

const App: React.FunctionComponent<PropsDefaultLayout> = ({
  children,
  className,
  titleClickHandler,
}: PropsDefaultLayout) => {
  const HomeBar = noHomeBarLocation.indexOf(window.location.pathname) === -1

  return (
    <div className={styles.root}>
      <CssBaseline />
      {HomeBar && (
        <AppBar color="transparent" position="sticky" className={styles.appBar}>
          <Toolbar>
            <div className={styles.title} onClick={titleClickHandler}>
              <Typography variant="h6" noWrap>
                카라모라 어드민
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
      )}
      <nav>
        <Menu />
      </nav>
      <main className={styles.content}>
        <div className={styles.toolBar} />
        <div className={className}>{children}</div>
        <AlertSnackbar />
      </main>
    </div>
  )
}

export default React.memo(App)
