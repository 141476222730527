/* eslint-disable no-nested-ternary */
import { configure } from 'mobx'
import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import Spinner from './components/molecule/Spinner'
import UploadImage from './components/organism/UploadImage'
import useNotSignIn from './hooks/useNotSignIn'
import useRootData from './hooks/useRootData'
import DefaultLayout from './layouts/DefaultLayout'
import AccountsPayable from './pages/AccountsPayable'
import AccountsReceivable from './pages/AccountsReceivable'
import Analytics from './pages/Analytics'
import Car from './pages/Car'
import CarDetail from './pages/CarDetail'
import CarMaker from './pages/CarMaker'
import CarMakerDetail from './pages/CarMakerDetail'
import Cart from './pages/Cart'
import CartDetail from './pages/CartDetail'
import CartItemDetail from './pages/CartItemDetail'
import ConfigCommissionRate from './pages/ConfigCommissionRate'
import ConfigDiscountRate from './pages/ConfigDiscountRate'
import ConfigMessageTemplate from './pages/ConfigMessageTemplate'
import LandingCuration from './pages/Curation'
import DashboardFunnel from './pages/DashboardFunnel'
import EditOrder from './pages/EditOrder'
import Gallery from './pages/Gallery'
import GalleryDetail from './pages/GalleryDetail'
import GalleryDisplay from './pages/GalleryDisplay'
import GalleryDisplayDetail from './pages/GalleryDisplayDetail'
import Hashtag from './pages/Hashtag'
import Kibana from './pages/Kibana'
import Landing from './pages/Landing'
import LeadDetail from './pages/LeadDetail'
import LeadList from './pages/LeadList'
import Order from './pages/Order'
import OrderDetail from './pages/OrderDetail'
import OrderVisual from './pages/OrderVisual'
import PackageDetail from './pages/PackageDetail'
import PackageList from './pages/PackageList'
import Parttimer from './pages/ParttimerAccount'
import Product from './pages/Product'
import ProductBrand from './pages/ProductBrand'
import ProductDetail from './pages/ProductDetail'
import ProductItem from './pages/ProductItem'
import ProductItemDetail from './pages/ProductItemDetail'
import ProductItemList from './pages/ProductItemList'
import ProductType from './pages/ProductType'
import ProfileCustomer from './pages/ProfileCustomer'
import ProfileCustomerDetail from './pages/ProfileCustomerDetail'
import ProfilePartner from './pages/ProfilePartner'
import ProfilePartnerDetail from './pages/ProfilePartnerDetail'
import QuoteRequest from './pages/QuoteRequest'
import Request777Order from './pages/Request777Order'
import Review from './pages/Review'
import Sitemap from './pages/Sitemap'
import Store from './pages/Store'
import StoreAndPackage from './pages/StoreAndPackage'
import StoreAndPackageDetail from './pages/StoreAndPackageDetail'
import StoreDetail from './pages/StoreDetail'
import Subscription from './pages/Subscription'
import TintingProductDetail from './pages/TintingProductDetail'
import UploadStoreCSV from './pages/UploadStoreCSV'
import checkVersionAndRefresh from './utils/versionChecker'

configure({
  enforceActions: 'never',
})

const App: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const {
    loadingSpinnerVisibility,
    isSignedIn,
    isPartTimer,
    idToken,
    selectOptionsContent,

    changeMainMenu,
    updateAuthInfo,
    updateSession,
    getSelectionOptions,
    getSelectionProducts,
    getFlimStock,
    leadUnsubscribe,
    leadsUnsubscribe,
  } = useRootData(({ appStore, authStore, calculatorStore, leadStore }) => ({
    loadingSpinnerVisibility: appStore.loadingSpinnerVisibility.get(),
    isSignedIn: authStore.isSignedIn.get(),
    isPartTimer: authStore.isPartTimer.get(),
    idToken: authStore.idToken.get(),
    selectOptionsContent: calculatorStore.selectOptionsContent.get(),

    changeMainMenu: appStore.changeMainMenu,
    updateAuthInfo: authStore.updateAuthInfo,
    updateSession: authStore.updateSession,
    getSelectionOptions: calculatorStore.getSelectionOptions,
    getSelectionProducts: calculatorStore.getSelectionProducts,
    getFlimStock: calculatorStore.getFlimStock,
    leadUnsubscribe: leadStore.leadUnsubscribe,
    leadsUnsubscribe: leadStore.leadsUnsubscribe,
  }))

  useNotSignIn()
  useEffect(() => {
    getFlimStock()
    updateSession()
    updateAuthInfo()

    return () => {
      leadUnsubscribe()
      leadsUnsubscribe()
    }
  }, [])

  useEffect(() => {
    if (idToken && !selectOptionsContent) {
      getSelectionOptions()
      getSelectionProducts()
    }
  }, [idToken])

  // Run this code whenever do re-route. You might send a pageview of GA here!
  useEffect(() => {
    // scroll up to top when switching pages
    window.scrollTo(0, 0)
    checkVersionAndRefresh()
  }, [history, location])

  // if (!isReady) return null

  const returnComponent = []

  if (loadingSpinnerVisibility) {
    returnComponent.push(<Spinner key="spinner" screenClass={'xl'} />)
  }

  const titleClickHandler = () => {
    changeMainMenu('/')
  }

  if (isPartTimer) {
    returnComponent.push(
      <Switch key="part">
        <Route path="/store" component={isSignedIn ? Store : Landing} />

        <Route path="/gallery" component={isSignedIn ? Gallery : Landing} />
        <Route path="/gallery-detail" component={isSignedIn ? GalleryDetail : Landing} />
        <Route path="/gallery-display" component={isSignedIn ? GalleryDisplay : Landing} />
        <Route path="/gallery-display-detail" component={isSignedIn ? GalleryDisplayDetail : Landing} />

        <Route path="/cart" component={isSignedIn ? Cart : Landing} />
        <Route path="/cart-detail" component={isSignedIn ? CartDetail : Landing} />
        <Route path="/cart-item-detail" component={isSignedIn ? CartItemDetail : Landing} />

        <Route path="/quote_request" component={isSignedIn ? QuoteRequest : Landing} />

        <Route path="/order" component={isSignedIn ? Order : Landing} />
        <Route path="/order-detail" component={isSignedIn ? OrderDetail : Landing} />

        <Route path="/lead-detail" component={isSignedIn ? LeadDetail : Landing} />

        <Route path="/landing-curation" component={isSignedIn ? LandingCuration : Landing} />
        <Route path="/lead-list" component={isSignedIn ? LeadList : Landing} />

        <Route path="/" component={Landing} />
      </Switch>,
    )
  } else {
    returnComponent.push(
      <Switch key="admin">
        <Route path="/landing-curation" component={isSignedIn ? LandingCuration : Landing} />
        <Route path="/kibana" component={isSignedIn ? Kibana : Landing} />
        <Route path="/analytics" component={isSignedIn ? Analytics : Landing} />
        <Route path="/sitemap-generator" component={isSignedIn ? Sitemap : Landing} />

        <Route path="/profile-partner" component={isSignedIn ? ProfilePartner : Landing} />
        <Route path="/profile-partner-detail" component={isSignedIn ? ProfilePartnerDetail : Landing} />
        <Route path="/profile-customer" component={isSignedIn ? ProfileCustomer : Landing} />
        <Route path="/profile-customer-detail" component={isSignedIn ? ProfileCustomerDetail : Landing} />

        <Route path="/product" component={isSignedIn ? Product : Landing} />
        <Route path="/tinting-product-detail" component={isSignedIn ? TintingProductDetail : Landing} />
        <Route path="/product-detail" component={isSignedIn ? ProductDetail : Landing} />
        <Route path="/product-item-list" component={isSignedIn ? ProductItemList : Landing} />

        <Route path="/product-type" component={isSignedIn ? ProductType : Landing} />
        <Route path="/product-brand" component={isSignedIn ? ProductBrand : Landing} />

        <Route path="/package-list" component={isSignedIn ? PackageList : Landing} />
        <Route path="/package-detail" component={isSignedIn ? PackageDetail : Landing} />

        <Route path="/product-item" component={isSignedIn ? ProductItem : Landing} />
        <Route path="/product-item-detail" component={isSignedIn ? ProductItemDetail : Landing} />

        <Route path="/car" component={isSignedIn ? Car : Landing} />
        <Route path="/car-detail" component={isSignedIn ? CarDetail : Landing} />

        <Route path="/car-maker" component={isSignedIn ? CarMaker : Landing} />
        <Route path="/car-maker-detail" component={isSignedIn ? CarMakerDetail : Landing} />

        <Route path="/store-detail" component={isSignedIn ? StoreDetail : Landing} />
        <Route path="/store" component={isSignedIn ? Store : Landing} />

        <Route path="/hashtag" component={isSignedIn ? Hashtag : Landing} />

        <Route path="/gallery" component={isSignedIn ? Gallery : Landing} />
        <Route path="/gallery-detail" component={isSignedIn ? GalleryDetail : Landing} />
        <Route path="/gallery-display" component={isSignedIn ? GalleryDisplay : Landing} />
        <Route path="/gallery-display-detail" component={isSignedIn ? GalleryDisplayDetail : Landing} />

        <Route path="/store-and-package" component={isSignedIn ? StoreAndPackage : Landing} />
        <Route path="/store-and-package-detail" component={isSignedIn ? StoreAndPackageDetail : Landing} />

        <Route path="/review" component={isSignedIn ? Review : Landing} />

        <Route path="/cart" component={isSignedIn ? Cart : Landing} />
        <Route path="/cart-detail" component={isSignedIn ? CartDetail : Landing} />
        <Route path="/cart-item-detail" component={isSignedIn ? CartItemDetail : Landing} />

        <Route path="/quote_request" component={isSignedIn ? QuoteRequest : Landing} />

        <Route path="/order" component={isSignedIn ? Order : Landing} />
        <Route path="/request-777-order" component={isSignedIn ? Request777Order : Landing} />
        <Route path="/order-detail" component={isSignedIn ? OrderDetail : Landing} />
        <Route path="/order-visual" component={isSignedIn ? OrderVisual : Landing} />
        <Route path="/edit-order" component={isSignedIn ? EditOrder : Landing} />

        <Route path="/subscription" component={isSignedIn ? Subscription : Landing} />

        <Route path="/accounts-receivable" component={isSignedIn ? AccountsReceivable : Landing} />
        <Route path="/accounts-payable" component={isSignedIn ? AccountsPayable : Landing} />

        <Route path="/parttimer" component={isSignedIn ? Parttimer : Landing} />

        <Route path="/s3-image" component={isSignedIn ? UploadImage : Landing} />

        <Route path="/lead-list" component={isSignedIn ? LeadList : Landing} />
        <Route path="/lead-detail" component={isSignedIn ? LeadDetail : Landing} />
        <Route path="/config-discount-rate" component={isSignedIn ? ConfigDiscountRate : Landing} />
        <Route path="/config-commission-rate" component={isSignedIn ? ConfigCommissionRate : Landing} />
        <Route path="/config-message-template" component={isSignedIn ? ConfigMessageTemplate : Landing} />

        <Route path="/dashboard-funnel" component={isSignedIn ? DashboardFunnel : Landing} />

        <Route path="/upload-store-csv" component={isSignedIn ? UploadStoreCSV : Landing} />

        <Route path="/" component={Landing} />
      </Switch>,
    )
  }

  return (
    <DefaultLayout titleClickHandler={titleClickHandler}>
      {/* CSS style "min-width" should be applied under the componentRef; Otherwise, screenClass couldn't be smaller than min-width for desktop(=1080px) -> fixed in 'xl' */}
      {returnComponent}
    </DefaultLayout>
  )
}

export default React.memo(App)
