// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__drawerPaper___37AaU {\n  width: 210px;\n  margin: 0px; }\n\n.index-module__divider___2fxuO {\n  min-height: 64px; }\n\n.index-module__listItem___RCQl2 {\n  padding: 0;\n  margin: 0; }\n  .index-module__listItem___RCQl2 .index-module__nested___-9Smz {\n    margin-top: 0px;\n    margin-bottom: 0px; }\n    .index-module__listItem___RCQl2 .index-module__nested___-9Smz span {\n      padding: 10px 0px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/components/organism/Menu/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW,EAAA;;AAGb;EACE,gBAAgB,EAAA;;AAGlB;EACE,UAAU;EACV,SAAS,EAAA;EAFX;IAII,eAAe;IACf,kBAAkB,EAAA;IALtB;MAOM,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".drawerPaper {\n  width: 210px;\n  margin: 0px;\n}\n\n.divider {\n  min-height: 64px;\n}\n\n.listItem {\n  padding: 0;\n  margin: 0;\n  .nested {\n    margin-top: 0px;\n    margin-bottom: 0px;\n    span {\n      padding: 10px 0px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"drawerPaper": "index-module__drawerPaper___37AaU",
	"divider": "index-module__divider___2fxuO",
	"listItem": "index-module__listItem___RCQl2",
	"nested": "index-module__nested___-9Smz"
};
module.exports = exports;
