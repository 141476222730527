/* eslint-disable no-template-curly-in-string */
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  Product_Item as ProductItem,
  Product_Item_Insert_Input as ProductItemInsertInput,
  Product_Item_Select_Column as ProductItemSelectColumn,
  Product_Type_Enum as ProductTypeEnum,
} from '../../assets/graphql/graphql'
import JsonEditor from '../../components/molecule/JsonEditor'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { getProductDetailTypeAndText, parseQueryString } from '../../utils/utility'
import { initAttrObject } from './type'

const productItemNameGuide: {
  [key: string]: string
} = {
  [ProductTypeEnum.AddOn]: '',
  [ProductTypeEnum.AfterBlow]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME}',
  [ProductTypeEnum.AuxiliaryBattery]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME}',
  [ProductTypeEnum.CamoKit]: `[서비스 항목] 카모 키트`,
  [ProductTypeEnum.CeramicCoating]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME}',
  [ProductTypeEnum.Connected]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME}',
  [ProductTypeEnum.Dashcam]:
    '[${PRODUCT.BRAND}] ${PRODUCT.NAME} / ${PRODUCT_ITEM.ATTRIBUTE.STORAGE} / ${PRODUCT_ITEM.ATTRIBUTE.CHANNEL}',
  [ProductTypeEnum.DeliveryFee]: '[서비스 항목] 배송비',
  [ProductTypeEnum.FilmRemove]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME} / ${PRODUCT_ITEM.ATTRIBUTE.NUM_OF_PRECES} 장',
  [ProductTypeEnum.Hipass]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME}',
  [ProductTypeEnum.LeatherCoating]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME}',
  [ProductTypeEnum.NewCarInspection]: '[서비스 항목] 신차검수',
  [ProductTypeEnum.Ppf]: "[${PRODUCT.BRAND}] ${PRODUCT.NAME} ${PRODUCT_ITEM.ATTRIBUTE.PRODUCT_NAME.split('_')[1]}종",
  [ProductTypeEnum.Soundproofing]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME} / ${PRODUCT_ITEM.ATTRIBUTE.PART}',
  [ProductTypeEnum.Tinting]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME}-${PRODUCT_ITEM.ATTRIBUTE.DENSITY}%',
  [ProductTypeEnum.Undercoating]: '[${PRODUCT.BRAND}] ${PRODUCT.NAME} / ${PRODUCT_ITEM.ATTRIBUTE.TYPE}',
}

const pickedColumns: ProductItemSelectColumn[] = [
  ProductItemSelectColumn.Id,
  ProductItemSelectColumn.Sku,
  ProductItemSelectColumn.Price,
  ProductItemSelectColumn.CommissionRate,
  ProductItemSelectColumn.Product,
  ProductItemSelectColumn.Attribute,
]

const GET_PRODUCT_ITEMS_BY_PRODUCT_ID = gql`
  query GET_PRODUCT_ITEMS_BY_PRODUCT_ID($id: uuid!) {
    product_by_pk(id: $id) {
      type
      product_items {
        id
        attribute
        product
        price
        sku
        commission_rate
        productByProduct {
          product_brand {
            description
          }
          name
          product_type {
            value
            description
          }
        }
      }
    }
  }
`

const UPSERT_PRODUCT_ITEMS = gql`
  mutation UPSERT_PRODUCT_ITEMS($productObject: [product_item_insert_input!]!) {
    insert_product_item(
      objects: $productObject
      on_conflict: { constraint: product_item_pkey, update_columns: [sku, price, commission_rate, attribute] }
    ) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id } = parseQueryString()

  const [productItems, setProductItems] = useState([])
  const [productItemInsertInput, _setProductItemInsertInput] = useState<ProductItemInsertInput>({})

  const setProductItemInsertInput = (data: ProductItemInsertInput) => {
    _setProductItemInsertInput({
      ...productItemInsertInput,
      ...data,
    })
  }

  const onChangeHandler = (key: ProductItemSelectColumn, data: ProductItemInsertInput, idx: number) => {
    const tempProductItems = [...productItems]

    let tempProductItem: ProductItemInsertInput = tempProductItems[idx]

    tempProductItem = {
      ...tempProductItem,
      ...data,
    }

    tempProductItems[idx] = tempProductItem

    setProductItems(tempProductItems)
  }

  const [getProductItmes, { data: productItemData }] = useLazyQuery(GET_PRODUCT_ITEMS_BY_PRODUCT_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (id) {
      getProductItmes()
    }
  }, [])

  useEffect(() => {
    if (productItemData) {
      setProductItems(productItemData.product_by_pk.product_items)
      setProductItemInsertInput({
        [ProductItemSelectColumn.Attribute]: initAttrObject[productItemData.product_by_pk.type],
      })
    }
  }, [productItemData])

  if (!productItemData)
    return (
      <div className="body">
        <div>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  const addButtonClickHandler = async () => {
    try {
      await client.mutate({
        mutation: UPSERT_PRODUCT_ITEMS,
        variables: {
          productObject: [
            {
              ...productItemInsertInput,
              product: id,
            },
          ],
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '성공' })
      getProductItmes()
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: `실패\n${err}` })
      Sentry.captureException(err)
    }
  }

  const updateButtonClickHandler = async () => {
    try {
      await client.mutate({
        mutation: UPSERT_PRODUCT_ITEMS,
        variables: {
          productObject: productItems.map((productItem) => _.pick(productItem, ...pickedColumns)),
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '성공' })
      getProductItmes()
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: `실패\n${err}` })
      Sentry.captureException(err)
    }
  }

  return (
    <div>
      <Button variant="contained" onClick={() => addButtonClickHandler()}>
        sku 추가
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[
                '상세 제품명 예시',
                ProductItemSelectColumn.Sku,
                ProductItemSelectColumn.Price,
                ProductItemSelectColumn.CommissionRate,
                ProductItemSelectColumn.Attribute,
              ].map((col: string, index: number) => {
                return <TableCell key={index}>{col}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{productItemNameGuide[productItemData ? productItemData.product_by_pk.type : null]}</TableCell>

              {[ProductItemSelectColumn.Sku, ProductItemSelectColumn.Price, ProductItemSelectColumn.CommissionRate].map(
                (col: string, colIndex: number) => {
                  return (
                    <TableCell key={colIndex}>
                      <TextField
                        style={col === ProductItemSelectColumn.Sku ? { minWidth: 400 } : { width: 100 }}
                        onChange={(e) =>
                          setProductItemInsertInput({ [col as ProductItemSelectColumn]: e.target.value })
                        }
                      ></TextField>
                    </TableCell>
                  )
                },
              )}
              <TableCell>
                <JsonEditor
                  value={
                    productItemInsertInput[ProductItemSelectColumn.Attribute]
                      ? productItemInsertInput[ProductItemSelectColumn.Attribute]
                      : {}
                  }
                  onChange={(data) => setProductItemInsertInput({ [ProductItemSelectColumn.Attribute]: data })}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" onClick={() => updateButtonClickHandler()}>
        변경사항 저장
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[
                '상세 제품명',
                ProductItemSelectColumn.Sku,
                ProductItemSelectColumn.Price,
                ProductItemSelectColumn.CommissionRate,
                ProductItemSelectColumn.Attribute,
              ].map((col: string, index: number) => {
                return <TableCell key={index}>{col}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {productItems.map((productItem: ProductItem, rowIndex: number) => {
              return (
                <TableRow key={rowIndex}>
                  <TableCell>{getProductDetailTypeAndText(productItem)[1]}</TableCell>
                  {[
                    ProductItemSelectColumn.Sku,
                    ProductItemSelectColumn.Price,
                    ProductItemSelectColumn.CommissionRate,
                  ].map((col: string, colIndex: number) => {
                    return (
                      <TableCell key={colIndex}>
                        <TextField
                          style={col === ProductItemSelectColumn.Sku ? { minWidth: 400 } : { width: 100 }}
                          value={productItem[col as ProductItemSelectColumn]}
                          onChange={(e) =>
                            onChangeHandler(
                              col as ProductItemSelectColumn,
                              {
                                [col]: e.target.value,
                              },
                              rowIndex,
                            )
                          }
                        ></TextField>
                      </TableCell>
                    )
                  })}
                  <TableCell>
                    <JsonEditor
                      value={productItem[ProductItemSelectColumn.Attribute]}
                      onChange={(data) =>
                        onChangeHandler(
                          ProductItemSelectColumn.Attribute,
                          {
                            [ProductItemSelectColumn.Attribute]: data,
                          },
                          rowIndex,
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default React.memo(App)
