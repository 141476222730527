import { Paper, Table, TableBody, TableContainer, TableHead, TablePagination } from '@material-ui/core'
import React from 'react'

import useRootData from '../../../hooks/useRootData'
import styles from './index.module.scss'
import LeadFilterRow from './LeadFilterRow'
import LeadOrderByRow from './LeadOrderByRow'
import LeadRow from './LeadRow'

const App: React.FunctionComponent = () => {
  const { page, rowsPerPage, filterLeads, changePage, changeRowsPerPage } = useRootData(({ leadStore }) => ({
    page: leadStore.page.get(),
    rowsPerPage: leadStore.rowsPerPage.get(),

    filterLeads: leadStore.filterLeads,
    changePage: leadStore.changePage,
    changeRowsPerPage: leadStore.changeRowsPerPage,
  }))

  return (
    <div>
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead className={styles.header}>
            <LeadFilterRow></LeadFilterRow>
            <LeadOrderByRow></LeadOrderByRow>
          </TableHead>
          <TableBody className={styles.body}>
            {filterLeads.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row, index) => {
              return <LeadRow key={index} row={row}></LeadRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 300, 500, 1000]}
        component="div"
        count={filterLeads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event: unknown, newPage: number) => {
          changePage(newPage)
        }}
        onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
          changeRowsPerPage(+event.target.value)
          changePage(0)
        }}
      />
    </div>
  )
}
export default React.memo(App)
