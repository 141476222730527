// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__Table___2Sovk .index-module__head___11Che {\n  background-color: #b388ff; }\n  .index-module__Table___2Sovk .index-module__head___11Che .index-module__cell___1BuWT {\n    color: #ffffff;\n    font-weight: bold; }\n\n.index-module__Table___2Sovk .index-module__body___CRtiA .index-module__storeCell___3-cnm {\n  display: flex; }\n  .index-module__Table___2Sovk .index-module__body___CRtiA .index-module__storeCell___3-cnm .index-module__input___3pZay {\n    min-width: 200px;\n    margin-right: 10px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Quote/StoreAndPackagePresetTable/index.module.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAyB,EAAA;EAF7B;IAIM,cAAc;IACd,iBAAiB,EAAA;;AALvB;EAUM,aAAa,EAAA;EAVnB;IAYQ,gBAAgB;IAChB,kBAAkB,EAAA","file":"index.module.scss","sourcesContent":[".Table {\n  .head {\n    background-color: #b388ff;\n    .cell {\n      color: #ffffff;\n      font-weight: bold;\n    }\n  }\n  .body {\n    .storeCell {\n      display: flex;\n      .input {\n        min-width: 200px;\n        margin-right: 10px;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Table": "index-module__Table___2Sovk",
	"table": "index-module__Table___2Sovk",
	"head": "index-module__head___11Che",
	"cell": "index-module__cell___1BuWT",
	"body": "index-module__body___CRtiA",
	"storeCell": "index-module__storeCell___3-cnm",
	"input": "index-module__input___3pZay"
};
module.exports = exports;
