// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2dBtJ {\n  padding: 20px; }\n  .index-module__container___2dBtJ .index-module__editButtonArea___3ciVq {\n    text-align: right;\n    margin-right: 50px; }\n  .index-module__container___2dBtJ .index-module__button___3xbhN {\n    position: relative;\n    left: 5px;\n    font-size: 16px;\n    padding: 5px 20px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/CarMaker/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAGI,iBAAiB;IACjB,kBAAkB,EAAA;EAJtB;IAOI,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  .editButtonArea {\n    text-align: right;\n    margin-right: 50px;\n  }\n  .button {\n    position: relative;\n    left: 5px;\n    font-size: 16px;\n    padding: 5px 20px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2dBtJ",
	"editButtonArea": "index-module__editButtonArea___3ciVq",
	"button": "index-module__button___3xbhN"
};
module.exports = exports;
