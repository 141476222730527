import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'

import { Get_PackagesQuery as GetPackagesQuery, Package } from '../../assets/graphql/graphql'
import { packageToPackageCardArray } from '../../utils/utility'
import { GET_PACKAGES } from '../StoreAndPackage/query'
import styles from './index.module.scss'
import PackageTable, { PackageCardObject } from './PackageTable'

const App: React.FunctionComponent = () => {
  const [normalPackageCardArray, setNomalPackageCardArray] = useState<PackageCardObject[]>([])
  const [excellentPackageCardArray, setExcellentPackageCardArray] = useState<PackageCardObject[]>([])
  const [bestPackageCardArray, setBestPackageCardArray] = useState<PackageCardObject[]>([])
  const [economicPackageCardArray, setEconomicPackageCardArray] = useState<PackageCardObject[]>([])
  const [teslaPackageArray, setTeslaPackageArray] = useState<PackageCardObject[]>([])

  const [getPackages, { data: packageContents, error }] = useLazyQuery<GetPackagesQuery>(GET_PACKAGES(), {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getPackages()
  }, [])

  useEffect(() => {
    if (packageContents) {
      ;(async () => {
        const tempNormalPackagesArray = await packageToPackageCardArray(
          packageContents.normalPackages as Array<Package>,
        )
        const tempExcellentPackagesArray = await packageToPackageCardArray(
          packageContents.excellentPackages as Array<Package>,
        )
        const tempBestPackagesArray = await packageToPackageCardArray(packageContents.bestPackages as Array<Package>)
        const tempEconomicPackagesArray = await packageToPackageCardArray(
          packageContents.economicPackages as Array<Package>,
        )
        const tempTeslaPacakgesArray = await packageToPackageCardArray(packageContents.teslaPacakges as Array<Package>)

        setNomalPackageCardArray(tempNormalPackagesArray)
        setExcellentPackageCardArray(tempExcellentPackagesArray)
        setBestPackageCardArray(tempBestPackagesArray)
        setEconomicPackageCardArray(tempEconomicPackagesArray)
        setTeslaPackageArray(tempTeslaPacakgesArray)
      })()
    }
  }, [packageContents])

  if (error) {
    Sentry.captureException(error)
  }

  if (!packageContents) return null

  return (
    <div className="body">
      <div className={styles.container}>
        <div style={{ marginBottom: 40 }}>패키지 제품 목록</div>

        <div>실속 모라줌 패키지</div>
        <PackageTable packageCardArray={normalPackageCardArray} />

        <div>고급 모라줌 패키지</div>
        <PackageTable packageCardArray={bestPackageCardArray} />

        <div>럭셔리 모라줌 패키지</div>
        <PackageTable packageCardArray={excellentPackageCardArray} />

        <div>갓성비 패키지</div>
        <PackageTable packageCardArray={economicPackageCardArray} />

        <div>테슬라 모델 3 패키지</div>
        <PackageTable packageCardArray={teslaPackageArray} />
      </div>
    </div>
  )
}
export default React.memo(App)
