import { useLazyQuery } from '@apollo/react-hooks'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import * as Sentry from '@sentry/react'
import React, { useEffect, useRef, useState } from 'react'

import {
  _Get_Package_ContentsQuery as GetPackageContentsQuery,
  _Update_Package_By_PkMutation as UpdatePackageByPkMutation,
  Car_Origin as CarOrigin,
  Car_Type as CarType,
  Get_Package_ProductQuery as GetPackageProductQuery,
  Product,
  Scalars,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import { PriceByCarType } from '../../stores/calculator/type'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import { GET_PACKAGE_PRODUCT } from '../StoreAndPackage/query'
import styles from './index.module.scss'
import { _GET_PACKAGE_CONTENTS as GET_PACKAGE_CONTENTS, _UPDATE_PACKAGE_BY_PK as UPDATE_PACKAGE_BY_PK } from './query'

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id: packageId } = parseQueryString()

  const [editMode, setEditMode] = useState<boolean>(false)

  const [carOrigins, setCarOrigins] = useState<Array<CarOrigin>>(null)
  const [carTypes, setCarTypes] = useState<Array<CarType>>(null)
  const [selectedProducts, setSelectedProducts] = useState<Array<Product>>(null)

  const [productObject, setProductObject] = useState<Scalars['jsonb']>(null)
  const [priceProductObject, setPriceProductObject] = useState<Scalars['jsonb']>(null)
  const [pricePayoutObject, setPricePayoutObject] = useState<Scalars['jsonb']>(null)
  const [priceDiscountObject, setPriceDiscountObject] = useState<Scalars['jsonb']>(null)
  const [priceFinalObject, setPriceFinalObject] = useState<Scalars['jsonb']>(null)

  const [floorPriceObject, setFloorPriceObject] = useState<Scalars['jsonb']>(null)
  const [ceilPriceObject, setCeilPriceObject] = useState<Scalars['jsonb']>(null)

  const timeout = useRef(null)

  const [getPackageContents, { data: packageContents, error }] = useLazyQuery<GetPackageContentsQuery>(
    GET_PACKAGE_CONTENTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        packageId,
      },
    },
  )

  useEffect(() => {
    getPackageContents()
  }, [])

  const calculateFinalPrices = () => {
    if (packageContents && productObject && priceProductObject && floorPriceObject && ceilPriceObject) {
      const finalObject: Partial<PriceByCarType> = {}
      const discountObject: Partial<PriceByCarType> = {}

      const skuKeys = packageContents.car_origin
        .map((carOrigin) => packageContents.car_type.map((carType) => `${carOrigin.name_us}_${carType.value}`))
        .reduce((prev, current) => [...prev, ...current], [])

      skuKeys.forEach((skuKey) => {
        finalObject[skuKey] = 0
        discountObject[skuKey] = 0
      })

      Object.keys(productObject.package).forEach((productKey) => {
        if (productKey === 'tinting') {
          Object.keys(productObject.package[productKey]).forEach((partKey) =>
            skuKeys.forEach((skuKey) => {
              finalObject[skuKey] += productObject.package[productKey][partKey][skuKey]
            }),
          )
        } else {
          skuKeys.forEach((skuKey) => {
            finalObject[skuKey] += productObject.package[productKey][skuKey]
          })
        }
      })

      skuKeys.forEach((skuKey) => {
        finalObject[skuKey] +=
          (skuKey in ceilPriceObject ? ceilPriceObject[skuKey] : 0) -
          (skuKey in floorPriceObject ? floorPriceObject[skuKey] : 0)
      })

      skuKeys.forEach((skuKey) => {
        discountObject[skuKey] = priceProductObject[skuKey] - finalObject[skuKey]
      })

      setPriceFinalObject(finalObject)
      setPriceDiscountObject(discountObject)

      return discountObject
    }

    return null
  }

  useEffect(() => {
    if (editMode) {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => calculateFinalPrices(), 500)
    } else {
      calculateFinalPrices()
    }
  }, [productObject, priceProductObject, floorPriceObject, ceilPriceObject])

  useEffect(() => {
    ;(async () => {
      if (packageContents) {
        const packageObject = packageContents.package[0]
        setCarOrigins(packageContents.car_origin as Array<CarOrigin>)
        setCarTypes(packageContents.car_type as Array<CarType>)

        let productIds = ''

        Object.keys(packageObject.product.package).forEach((productKey) => {
          if (productKey === 'tinting') {
            Object.keys(packageObject.product.package[productKey]).forEach((partKey) => {
              if (packageObject.product.package[productKey][partKey].product_id) {
                productIds += `{id: { _eq: "${packageObject.product.package[productKey][partKey].product_id}"}}`
              }
            })
          } else {
            productIds += `{id: { _eq: "${packageObject.product.package[productKey].product_id}"}}`
          }
        })

        const {
          data: { product: products },
        } = await client.query<GetPackageProductQuery>({
          query: GET_PACKAGE_PRODUCT(productIds),
          fetchPolicy: 'no-cache',
        })
        setSelectedProducts(products as Array<Product>)

        setProductObject(packageObject.product)
        setPriceProductObject(packageObject.price_product)
        setPricePayoutObject(packageObject.price_payout)

        if (!packageObject.price_discount.floor_price) {
          packageObject.price_discount.floor_price = {}
        }
        if (!packageObject.price_discount.ceil_price) {
          packageObject.price_discount.ceil_price = {}
        }

        setFloorPriceObject(packageObject.price_discount.floor_price)
        setCeilPriceObject(packageObject.price_discount.ceil_price)
      }
    })()
  }, [packageContents])

  if (error) {
    Sentry.captureException(error)
  }

  const updatePackage = async () => {
    try {
      const discountObject = calculateFinalPrices()

      if (!discountObject) return

      await client.query<UpdatePackageByPkMutation>({
        query: UPDATE_PACKAGE_BY_PK,
        fetchPolicy: 'no-cache',
        variables: {
          id: packageId,
          priceDiscount: { ...discountObject, floor_price: floorPriceObject, ceil_price: ceilPriceObject },
          pricePayout: pricePayoutObject,
          priceProduct: priceProductObject,
          product: productObject,
        },
      })

      changeSnackbarAlertContent({
        severity: 'success',
        content: '업데이트 완료',
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const skuCell = (
    productItem: Scalars['jsonb'],
    changeHandler: (value: number, skuKey: string) => void,
    disabled?: boolean,
  ) => {
    return (
      carTypes &&
      carOrigins &&
      carOrigins.map((carOrigin) => {
        return carTypes.map((carType) => {
          const skuKey = `${carOrigin.name_us}_${carType.value}`

          return (
            <TableCell align="right" key={skuKey} className={styles.col}>
              {editMode ? (
                <TextField
                  style={{ backgroundColor: '#ffffff' }}
                  value={(Number(productItem[skuKey]) || 0).toLocaleString()}
                  onChange={(e) => {
                    changeHandler(
                      (parseInt(e.target.value.replace(new RegExp(',', 'g'), ''), 10) as number) || 0,
                      skuKey,
                    )
                  }}
                  variant="outlined"
                  size="small"
                  disabled={disabled}
                />
              ) : (
                <>
                  {skuKey in productItem && `${Number(productItem[skuKey]).toLocaleString()}원`}
                  {!(skuKey in productItem) && '-'}
                </>
              )}
            </TableCell>
          )
        })
      })
    )
  }

  const productRow = (productItem: Scalars['jsonb'], key: string, isTinting = false) => {
    const productId = productItem.product_id

    return (
      <TableRow key={productId} className={styles.row}>
        <TableCell className={styles.col}>
          {`${selectedProducts.filter((product) => product.id === productId)[0].name} 소비자가`}
        </TableCell>
        {skuCell(productItem, (value: number, skuKey: string) => {
          const newProductObject = { ...productObject }
          if (isTinting) newProductObject.package.tinting[key][skuKey] = value
          else newProductObject.package[key][skuKey] = value
          setProductObject(newProductObject)
        })}
      </TableRow>
    )
  }

  if (!packageContents) return null

  return (
    <div className="body">
      <div className={styles.container}>
        <h2>패키지 가격 편집</h2>
        <h3>{packageContents.package[0].name}</h3>
        <TableContainer className={styles.tableContainer} component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead className={styles.header}>
              <TableRow className={styles.colNameRow}>
                <TableCell className={styles.col}>항목</TableCell>
                {carTypes &&
                  carOrigins &&
                  carOrigins.map((carOrigin) => {
                    return carTypes.map((carType) => {
                      return (
                        <TableCell
                          key={`${carType.value}_${carOrigin.name_us}`}
                          className={styles.col}
                        >{`${carType.description} (${carOrigin.name_kr})`}</TableCell>
                      )
                    })
                  })}
              </TableRow>
            </TableHead>
            <TableBody className={styles.body}>
              {productObject &&
                productObject.package &&
                selectedProducts &&
                Object.keys(productObject.package).map((productKey) => {
                  if (productKey === 'tinting') {
                    return Object.keys(productObject.package[productKey]).map((partKey) =>
                      productRow(productObject.package[productKey][partKey], partKey, true),
                    )
                  }

                  return productRow(productObject.package[productKey], productKey)
                })}

              {floorPriceObject && (
                <TableRow className={styles.row}>
                  <TableCell className={styles.col}>절사 감액</TableCell>
                  {skuCell(floorPriceObject, (value: number, skuKey: string) => {
                    const newFloorPriceObject = { ...floorPriceObject }
                    newFloorPriceObject[skuKey] = value
                    setFloorPriceObject(newFloorPriceObject)
                  })}
                </TableRow>
              )}
              {ceilPriceObject && (
                <TableRow className={styles.row}>
                  <TableCell className={styles.col}>절사 증액</TableCell>
                  {skuCell(ceilPriceObject, (value: number, skuKey: string) => {
                    const newCeilPriceObject = { ...ceilPriceObject }
                    newCeilPriceObject[skuKey] = value
                    setCeilPriceObject(newCeilPriceObject)
                  })}
                </TableRow>
              )}

              {priceProductObject && (
                <TableRow className={styles.row}>
                  <TableCell className={styles.col}>패키지 정가</TableCell>
                  {skuCell(priceProductObject, (value: number, skuKey: string) => {
                    const newPriceProductObject = { ...priceProductObject }
                    newPriceProductObject[skuKey] = value
                    setPriceProductObject(newPriceProductObject)
                  })}
                </TableRow>
              )}
              {priceDiscountObject && (
                <TableRow className={styles.totalPriceRow}>
                  <TableCell className={styles.col}>패키지 할인금액</TableCell>
                  {skuCell(
                    priceDiscountObject,
                    (value: number, skuKey: string) => {
                      const newPriceDiscountObject = { ...priceDiscountObject }
                      newPriceDiscountObject[skuKey] = value
                      setPriceDiscountObject(newPriceDiscountObject)
                    },
                    true,
                  )}
                </TableRow>
              )}
              {priceFinalObject && (
                <TableRow className={styles.totalPriceRow}>
                  {' '}
                  <TableCell className={styles.col}>패키지 소비자가</TableCell>
                  {skuCell(
                    priceFinalObject,
                    (value: number, skuKey: string) => {
                      const newPriceFinalObject = { ...priceFinalObject }
                      newPriceFinalObject[skuKey] = value
                      setPriceDiscountObject(newPriceFinalObject)
                    },
                    true,
                  )}
                </TableRow>
              )}
              {pricePayoutObject && (
                <TableRow className={styles.row}>
                  <TableCell className={styles.col}>패키지 정산가</TableCell>
                  {skuCell(pricePayoutObject, (value: number, skuKey: string) => {
                    const newPricePayoutObject = { ...pricePayoutObject }
                    newPricePayoutObject[skuKey] = value
                    setPricePayoutObject(newPricePayoutObject)
                  })}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {editMode ? (
          <Button
            onClick={() => {
              setEditMode(false)
              updatePackage()
            }}
            variant={'contained'}
          >
            저장하기
          </Button>
        ) : (
          <Button
            onClick={() => {
              setEditMode(true)
            }}
            variant={'contained'}
          >
            편집하기
          </Button>
        )}
      </div>
    </div>
  )
}
export default React.memo(App)
