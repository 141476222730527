// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___rnoUN {\n  padding: 20px; }\n  .index-module__container___rnoUN .index-module__editButtonArea___3JyxD {\n    text-align: right;\n    margin-bottom: 30px; }\n  .index-module__container___rnoUN .index-module__deleteAccountButton___3lRch {\n    margin: 20px 0; }\n  .index-module__container___rnoUN .index-module__data___Qo1MS {\n    background-color: #efefef; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/ProfilePartnerDetail/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAGI,iBAAiB;IACjB,mBAAmB,EAAA;EAJvB;IAOI,cAAc,EAAA;EAPlB;IAUI,yBAAyB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  .editButtonArea {\n    text-align: right;\n    margin-bottom: 30px;\n  }\n  .deleteAccountButton {\n    margin: 20px 0;\n  }\n  .data {\n    background-color: #efefef;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___rnoUN",
	"editButtonArea": "index-module__editButtonArea___3JyxD",
	"deleteAccountButton": "index-module__deleteAccountButton___3lRch",
	"data": "index-module__data___Qo1MS"
};
module.exports = exports;
