// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__cy___xw01I {\n  height: 90%;\n  width: 100%;\n  display: 'block';\n  background-color: #fff0e1;\n  border-style: double; }\n\n.index-module__select___1gPlN {\n  width: 100%;\n  height: 5%; }\n\n.index-module__button___13_Ru {\n  margin: auto;\n  font-size: 14px;\n  padding: 5px 20px;\n  width: 25%;\n  height: 5%; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/components/organism/Cytoscape/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,yBAAoC;EACpC,oBAAoB,EAAA;;AAGtB;EACE,WAAW;EACX,UAAU,EAAA;;AAGZ;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,UAAU;EACV,UAAU,EAAA","file":"index.module.scss","sourcesContent":[".cy {\n  height: 90%;\n  width: 100%;\n  display: 'block';\n  background-color: rgb(255, 240, 225);\n  border-style: double;\n}\n\n.select {\n  width: 100%;\n  height: 5%;\n}\n\n.button {\n  margin: auto;\n  font-size: 14px;\n  padding: 5px 20px;\n  width: 25%;\n  height: 5%;\n}\n"]}]);
// Exports
exports.locals = {
	"cy": "index-module__cy___xw01I",
	"select": "index-module__select___1gPlN",
	"button": "index-module__button___13_Ru"
};
module.exports = exports;
