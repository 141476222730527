import { ApolloClient, from, HttpLink, split } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'
import * as firebase from 'firebase/app'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import config from '../configs'
import authStore from '../stores/auth'

export const getValidIdToken = async (): Promise<string> => {
  // Try maximum 3 times when it fails
  let count = 0
  const maxTries = 3

  do {
    try {
      let idToken = authStore.idToken.get()

      // If signed-in, but no idToken, try to get a new token
      if (!idToken && authStore.isSignedIn.get()) {
        // eslint-disable-next-line no-await-in-loop
        idToken = await firebase.default.auth().currentUser?.getIdToken(true)
      }

      if (!idToken) return idToken

      const { exp } = jwtDecode<JwtPayload>(idToken)

      // If it's expired...
      if (Date.now() >= exp * 1000 - 5 * 10 * 60000) {
        // eslint-disable-next-line no-await-in-loop
        idToken = await firebase.default.auth().currentUser?.getIdToken(true)
      }

      return idToken
    } catch (err) {
      // Send sentry log as a message when it's failed
      Sentry.captureMessage(err)

      // If it hits the maximum tries, throw err
      count += 1
      if (count === maxTries) {
        throw err
      }
    }
    // eslint-disable-next-line no-constant-condition
  } while (true)
}

// Configuration for Apollo
const httpLink = new HttpLink({
  uri: config.graphqlEndPointHTTPS,
})

const wsLink = new WebSocketLink({
  uri: config.graphqlEndPointWS,
  options: {
    reconnect: true,
    reconnectionAttempts: 5,
    lazy: true,
    connectionParams: async () => {
      const validToken = await getValidIdToken()

      if (!validToken) return {}

      return {
        headers: {
          Authorization: `Bearer ${validToken}`,
        },
      }
    },
  },
})

const authLink = setContext(async (_, { headers }) => {
  const validToken = await getValidIdToken()

  if (!validToken) return { headers }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${validToken}`,
    },
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
})

const client = new ApolloClient({
  link: from([retryLink, authLink, link]),
  cache: new InMemoryCache(),
})

export default client
