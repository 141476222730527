import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const GET_REVIEW = gql`
  query GET_REVIEW {
    review(order_by: { created_at: desc }) {
      id
      created_at
      status
      review_status {
        description
      }
      comment
      rating
      store_and_reviews {
        storeByStore {
          name
        }
      }
      order_and_reviews {
        orderByOrder {
          phone
          leadByLead {
            phone
          }
        }
      }
    }
  }
`

export const UPDATE_REVIEW_STATUS = gql`
  mutation UPDATE_REVIEW_STATUS($reviewId: uuid!, $status: review_status_enum!) {
    update_review(where: { id: { _eq: $reviewId } }, _set: { status: $status }) {
      affected_rows
    }
  }
`

export const UPDATE_SELECT_REVIEW_STATUS = (reviewId: string): DocumentNode => {
  return gql`
    mutation UPDATE_SELECT_REVIEW_STATUS($status: review_status_enum!) {
      update_review(where: {
          _or: [
            ${reviewId}
          ]
        }, _set: { status: $status }) {
        affected_rows
      }
    }
  `
}
