// Development

export default {
  // Firebase
  firebaseConfig: {
    apiKey: 'AIzaSyCGtYdRV6kWyQUb2-H5JdD64-7dujm4MQo',
    authDomain: 'caramora-web-v1-dev.firebaseapp.com',
    databaseURL: 'https://caramora-web-v1-dev.firebaseio.com',
    projectId: 'caramora-web-v1-dev',
    storageBucket: 'caramora-web-v1-dev.appspot.com',
    messagingSenderId: '1004591536927',
    appId: '1:1004591536927:web:d73d2faa1aa3d90ae82e2a',
    measurementId: 'G-13YPJPX9V6',
  },
  // AWS
  awsConfig: {
    accessKeyId: 'AKIAUGXAMOK7JJIEFCML',
    secretAccessKeyId: '3St2dYn2Dpl7ggJDAXvJpn1BNt6GsmgneXtAvUEh',
    imageBucketName: 'caramora-web-images-dev',
    regionName: 'ap-northeast-2',
    databaseURL: 'https://idev.riderdash.com',
  },
  // Stripe
  stripePublicApiKey: 'pk_test_7Aeuli4FFMeXdBPmrVZMk4sp00TDHNaQuq',
  // Zendesk
  zendeskConfig: {
    account_key: 'F6QSy30CSi1rLtDYZ4mx5umt67yxAy5s',
  },
  // Hasura
  hasuraURL: 'https://api.riderdash.com/v1/graphql',
  // GraphQL
  graphqlEndPointHTTPS: 'https://api.riderdash.com/v1/graphql',
  graphqlEndPointWS: 'wss://api.riderdash.com/v1/graphql',
  /* MsgCenter */
  msgCenterSecureKey: 'JdxFo39uhZRm6v65krtVS7pH65lhxcyT',
  // msgCenterURL: 'http://localhost:3000',
  msgCenterURL: 'https://api.riderdash.com',
  /* Functions */
  // Firebase
  // backendEndPoint: 'https://asia-northeast1-caramora-web-v1-dev.cloudfunctions.net/backend',
  // backendEndPoint: 'http://localhost:5001/caramora-web-v1-dev/asia-northeast1/backend',
  // AWS
  // backendEndPoint: 'https://u05ny2go22.execute-api.ap-northeast-2.amazonaws.com/dev',
  // backendEndPoint: 'http://localhost:5001',
  /* ENDPOINT */
  // LOCAL
  // backendEndPoint: 'http://localhost:5001/caramora-web-v1-dev/asia-northeast1/backend',
  // DEV
  backendEndPoint: 'https://u05ny2go22.execute-api.ap-northeast-2.amazonaws.com/dev',
  frontendUrl: 'https://localhost:8079',
  camoKitProductItemId: '9e311d23-d63e-4aa0-a8ef-ad2b4224433b',
  deliveryFeeProductItemId: '5bfcc6e2-4639-4348-9fea-eea7e890fbdf',
}
