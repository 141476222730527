import { OptionsType } from 'react-select'

export enum LEAD_COL {
  CHAT_ID = 'Chat ID',
  LEAD_ID = 'Lead ID',
  ZENDESK_CHAT_NAME = 'Zendesk Chat Name',
  CHAT_ASSIGNEE = 'Chat Assignee',
  SUPPORT_ASSIGNEE = 'Support Assignee',
  MATCH_MAKER = 'Match Maker',
  SELECTED_NEW_CAR = '신차여부',
  START_DROP_OFF_DATE = 'Start Drop-off Date (in KST)',
  END_DROP_OFF_DATE = 'End Drop-off Date (in KST)',
  DUE_DATE = 'Due Date (in KST)',
  SHOP = 'Shop',
  SHOP_PHONE = 'Shop Phone',
  CARAMORA_ID = '카라모라 ID',
  CAR = '차량',
  CUSTOMER_NAME = '고객 이름',
  CUSTOMER_PHONE = '고객 전화번호',
  CREATED_AT = 'Created at (in KST)',
  UPDATED_AT = 'Updated at (in KST)',
  CAN_NOT_MATCH = "Can't Match",
  PAYMENT_LINK_SENT_AT = '결제 링크 전달 시각',
  PAID_AT = '결제 완료 시각',
  VAT_REQUESTED_AT = '세금계산서 요청 시각',
  PRICE_PAYOUT = '매장 정산 금액(현장 결제)',
  MATERIAL_PRICE = '재료비',
  PRICE_PAYOUT_WITHOUT_VAT = '영세율 적용 금액',
  FINISHED_AT = '이체 완료 시각',
  RSV_COMFIRMED_AT = '예약 완료 시각(KST)',
  STATUS = 'Status',
  CREATED_FROM = 'Created from',
  CALL_REQUEST_SAME_DAY_ORDER = '당일 시공 요청',
}

export type TypeDirection = 'desc' | 'asc'

export interface SearchInputValues {
  [key: string]: string | boolean | OptionsType<{ value: string; label: string }>
  [LEAD_COL.CHAT_ID]?: string
  [LEAD_COL.LEAD_ID]?: string
  [LEAD_COL.ZENDESK_CHAT_NAME]?: string
  [LEAD_COL.SELECTED_NEW_CAR]?: boolean
  [LEAD_COL.START_DROP_OFF_DATE]?: string
  [LEAD_COL.END_DROP_OFF_DATE]?: string
  [LEAD_COL.DUE_DATE]?: string
  [LEAD_COL.SHOP_PHONE]?: string
  [LEAD_COL.CAR]?: string
  [LEAD_COL.CARAMORA_ID]?: string
  [LEAD_COL.CUSTOMER_NAME]?: string
  [LEAD_COL.CUSTOMER_PHONE]?: string
  [LEAD_COL.CREATED_AT]?: string
  [LEAD_COL.UPDATED_AT]?: string
  [LEAD_COL.PAYMENT_LINK_SENT_AT]?: string
  [LEAD_COL.PAID_AT]?: string
  [LEAD_COL.VAT_REQUESTED_AT]?: string
  [LEAD_COL.FINISHED_AT]?: string
  [LEAD_COL.RSV_COMFIRMED_AT]?: string
  [LEAD_COL.CREATED_FROM]?: string
  [LEAD_COL.CHAT_ASSIGNEE]?: OptionsType<{ value: string; label: string }>
  [LEAD_COL.SUPPORT_ASSIGNEE]?: OptionsType<{ value: string; label: string }>
  [LEAD_COL.MATCH_MAKER]?: OptionsType<{ value: string; label: string }>
  [LEAD_COL.SHOP]?: OptionsType<{ value: string; label: string }>
}

export const initSearchInputValues = {
  [LEAD_COL.CHAT_ID]: '',
  [LEAD_COL.LEAD_ID]: '',
  [LEAD_COL.ZENDESK_CHAT_NAME]: '',
  [LEAD_COL.START_DROP_OFF_DATE]: '',
  [LEAD_COL.END_DROP_OFF_DATE]: '',
  [LEAD_COL.DUE_DATE]: '',
  [LEAD_COL.SHOP_PHONE]: '',
  [LEAD_COL.CAR]: '',
  [LEAD_COL.CARAMORA_ID]: '',
  [LEAD_COL.CUSTOMER_NAME]: '',
  [LEAD_COL.CUSTOMER_PHONE]: '',
  [LEAD_COL.CREATED_AT]: '',
  [LEAD_COL.UPDATED_AT]: '',
  [LEAD_COL.PAYMENT_LINK_SENT_AT]: '',
  [LEAD_COL.PAID_AT]: '',
  [LEAD_COL.VAT_REQUESTED_AT]: '',
  [LEAD_COL.FINISHED_AT]: '',
  [LEAD_COL.RSV_COMFIRMED_AT]: '',
  [LEAD_COL.CREATED_FROM]: '',
  [LEAD_COL.CHAT_ASSIGNEE]: [] as OptionsType<{ value: string; label: string }>,
  [LEAD_COL.SUPPORT_ASSIGNEE]: [] as OptionsType<{ value: string; label: string }>,
  [LEAD_COL.MATCH_MAKER]: [] as OptionsType<{ value: string; label: string }>,
  [LEAD_COL.SHOP]: [] as OptionsType<{ value: string; label: string }>,
}
