// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__commentInputArea___2JZMs {\n  display: grid;\n  padding: 15px;\n  background-color: white; }\n  .index-module__commentInputArea___2JZMs .index-module__commentSubmit___M8A76 {\n    margin-top: 20px;\n    height: 70px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Comment/InputArea/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB,EAAA;EAHzB;IAMI,gBAAgB;IAChB,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".commentInputArea {\n  display: grid;\n  padding: 15px;\n  background-color: white;\n\n  .commentSubmit {\n    margin-top: 20px;\n    height: 70px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"commentInputArea": "index-module__commentInputArea___2JZMs",
	"commentSubmit": "index-module__commentSubmit___M8A76"
};
module.exports = exports;
