import { Divider, Drawer, ListItem, ListItemText } from '@material-ui/core'
import React, { Fragment } from 'react'

import { Lead_Status_Enum as LeadStatusEnum } from '../../../assets/graphql/graphql'
import useRootData from '../../../hooks/useRootData'
import { tableCol } from '../../../pages/LeadList'
import styles from './index.module.scss'

const menus = [
  ...Object.keys(tableCol).map((key, index) => {
    return {
      key: `lead-list?status=${key}`,
      label: `${index + 1}. ${key.toUpperCase()}`,
      forPartTimer:
        [LeadStatusEnum.Chat, LeadStatusEnum.Lead, LeadStatusEnum.Quote].indexOf(key as LeadStatusEnum) !== -1,
      needAuthorization: false,
    }
  }),
  {
    key: 'dashboard-funnel',
    label: 'Funnel 통계',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'order',
    label: '시공 예약 목록',
    forPartTimer: true,
    needAuthorization: false,
  },
  {
    key: 'landing-curation',
    label: '랜딩 큐레이션',
    forPartTimer: true,
    needAuthorization: false,
  },
  {
    key: 'profile-partner',
    label: '파트너 고객 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'profile-customer',
    label: '고객 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'store',
    label: '매장 목록',
    forPartTimer: true,
    needAuthorization: false,
  },
  {
    key: 'hashtag',
    label: '해쉬태그',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'product-type',
    label: '제품 타입',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'product-brand',
    label: '제품 브랜드',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'product',
    label: '제품 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'car',
    label: '자동차 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'car-maker',
    label: '제조사 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'product-item',
    label: 'SKU',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'gallery',
    label: '블로그 목록',
    forPartTimer: true,
    needAuthorization: false,
  },
  {
    key: 'gallery-display',
    label: '매장별 블로그 현황',
    forPartTimer: true,
    needAuthorization: false,
  },
  {
    key: 'store-and-package',
    label: '패캐지-매장 매칭',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'package-list',
    label: '패키지 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'review',
    label: '리뷰 관리',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'cart',
    label: '고객 카트 수정',
    forPartTimer: true,
    needAuthorization: false,
  },

  {
    key: 'request-777-order',
    label: '[777] 결제 요청',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'quote_request',
    label: '#marketplace_request 목록',
    forPartTimer: true,
    needAuthorization: false,
  },
  {
    key: 'order-visual',
    label: '시공 결제 시각화',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'request',
    label: '견적 전송 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'subscription',
    label: '입점료 결제 목록',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'accounts-receivable',
    label: '미수금',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'accounts-payable',
    label: '외상매입금',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'kibana',
    label: '키바나',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'analytics',
    label: 'Analytics',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'sitemap-generator',
    label: 'sitemap.xml',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'parttimer',
    label: '파트타이머 계정 전환',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 's3-image',
    label: 'Image upload in s3',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'upload-store-csv',
    label: '파트너 csv 파일 업로드',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'config-message-template',
    label: '[통합어드민] 메세지 템플릿',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'config-discount-rate',
    label: '[통합어드민] 할인율 편집기',
    forPartTimer: false,
    needAuthorization: false,
  },
  {
    key: 'config-commission-rate',
    label: '[통합어드민] 마진율 조정',
    forPartTimer: false,
    needAuthorization: true,
  },
  {
    key: 'sign-out',
    label: '로그아웃',
    forPartTimer: true,
    needAuthorization: false,
  },
]

const App: React.FunctionComponent = () => {
  const {
    currentMainMenu,
    changeMainMenu,
    changeSnackbarAlertContent,
    signOut,
    isVerified,
    updateIsPartTimer,
    isPartTimer,
    isSignedIn,
    authorization,
    statusFilterValue,
  } = useRootData(({ appStore, authStore, leadStore }) => ({
    currentMainMenu: appStore.currentMainMenu.get(),
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    signOut: authStore.signOut,
    isVerified: authStore.isVerified.get(),
    isPartTimer: authStore.isPartTimer.get(),
    isSignedIn: authStore.isSignedIn.get(),
    updateIsPartTimer: authStore.updateIsPartTimer,
    authorization: authStore.authorization.get(),
    statusFilterValue: leadStore.statusFilterValue.get(),
  }))

  return (
    <Drawer
      classes={{
        paper: styles.drawerPaper,
      }}
      variant="permanent"
      anchor={'left'}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className={styles.divider} />
      <Divider />
      {menus.map((menuItem) => {
        if ((isPartTimer && !menuItem.forPartTimer) || (!authorization && menuItem.needAuthorization) || !isSignedIn)
          return null

        const selected =
          currentMainMenu === `/${menuItem.key}` ||
          currentMainMenu === menuItem.key ||
          (currentMainMenu.indexOf('lead-detail') !== -1 &&
            currentMainMenu.indexOf('lead-detail') < 2 &&
            `lead-list?status=${statusFilterValue}` === menuItem.key)

        return (
          <Fragment key={menuItem.key}>
            <ListItem
              dense
              color={'primary'}
              selected={selected}
              button
              className={styles.listItem}
              style={
                selected
                  ? { backgroundColor: 'purple', color: 'white', paddingLeft: '4px', paddingRight: 0 }
                  : { paddingLeft: '4px', paddingRight: 0 }
              }
            >
              <ListItemText
                className={styles.nested}
                primary={menuItem.label}
                onClick={async (): Promise<void> => {
                  if (!isVerified) {
                    if (menuItem.key === 'sign-out') {
                      await signOut()
                      updateIsPartTimer(false)
                      changeMainMenu(`/`)
                    } else {
                      changeSnackbarAlertContent({
                        severity: 'warning',
                        content: '이메일 인증을 해주세요!',
                      })
                    }
                  } else {
                    changeMainMenu(`/${menuItem.key}`)
                    if (menuItem.key === 'sign-out') {
                      await signOut()
                      updateIsPartTimer(false)
                      changeMainMenu(`/`)
                    }
                  }
                }}
              />
            </ListItem>
            <Divider />
          </Fragment>
        )
      })}
    </Drawer>
  )
}
export default React.memo(App)
