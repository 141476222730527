// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2YwjQ {\n  width: 600px;\n  border: black 1px solid; }\n  .index-module__container___2YwjQ .index-module__top___2Jo_Z {\n    padding: 10px; }\n    .index-module__container___2YwjQ .index-module__top___2Jo_Z label {\n      font-size: 16px; }\n  .index-module__container___2YwjQ .index-module__middle___3aGeQ {\n    padding: 10px;\n    display: inline-grid; }\n    .index-module__container___2YwjQ .index-module__middle___3aGeQ img {\n      max-width: 100%;\n      height: 150px; }\n  .index-module__container___2YwjQ .index-module__bottom___2c1Sm {\n    padding: 10px;\n    display: inline-grid; }\n    .index-module__container___2YwjQ .index-module__bottom___2c1Sm img {\n      max-width: 100%;\n      height: 150px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/components/organism/UploadImage/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB,EAAA;EAFzB;IAII,aAAa,EAAA;IAJjB;MAMM,eAAe,EAAA;EANrB;IAUI,aAAa;IACb,oBAAoB,EAAA;IAXxB;MAaM,eAAe;MACf,aAAa,EAAA;EAdnB;IAkBI,aAAa;IACb,oBAAoB,EAAA;IAnBxB;MAqBM,eAAe;MACf,aAAa,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  width: 600px;\n  border: black 1px solid;\n  .top {\n    padding: 10px;\n    label {\n      font-size: 16px;\n    }\n  }\n  .middle {\n    padding: 10px;\n    display: inline-grid;\n    img {\n      max-width: 100%;\n      height: 150px;\n    }\n  }\n  .bottom {\n    padding: 10px;\n    display: inline-grid;\n    img {\n      max-width: 100%;\n      height: 150px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2YwjQ",
	"top": "index-module__top___2Jo_Z",
	"middle": "index-module__middle___3aGeQ",
	"bottom": "index-module__bottom___2c1Sm"
};
module.exports = exports;
