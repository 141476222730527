import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import Phonenumber from 'awesome-phonenumber'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'

import {
  Get_Profile_SearchQuery as GetProfileSearchQuery,
  Get_Profile_SearchQueryVariables as GetProfileSearchQueryVariables,
  Profile_Kr_Partner as ProfileKrPartner,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

const GET_PROFILE_SEARCH = (subscript: boolean, filter: string): DocumentNode => {
  const where = `_and: [
    { deleted_at: { _is_null: true } }
    {
      _or: [
        { name: { _like: $searchKeyword } }
        { phone_mobile: { _like: $searchKeyword } }
        { email: { _like: $searchKeyword } }
      ]
    }
    ${filter || ''}
    ${
      subscript
        ? `{
        accountByAccount: {
          subscriptions: {
            storeByStore: {
              _or: [
                { email: { _is_null: false } }
                { name: { _is_null: false } }
                { phone_business: { _is_null: false } }
              ]
            }
          }
        }
      }`
        : ''
    }
  ]`

  return gql`
  query GET_PROFILE_SEARCH(
    $limit: Int = 1000000
    $offset: Int = 0
    $searchKeyword: String = "%"
  ) {
    profile_partner: profile_kr_partner(
      where: { ${where} }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      name
      email
      phone_mobile
      account
      accountByAccount {
        account_type {
          value
        }
        store_and_accounts{
          storeByStore {
            email
            phone_business
            name
          }
        }
      }
    }
    profile_partner_count: profile_kr_partner_aggregate(
      where: { ${where} }
    ) {
      aggregate {
        count
      }
    }
  }
`
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
  }))

  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(20)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [checkSubscriptions, setCheckSubscriptions] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [offset, setOffset] = useState(0)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [filter, setFilter] = useState('')

  const [getSearchData, { data: searchData, error }] = useLazyQuery<
    GetProfileSearchQuery,
    GetProfileSearchQueryVariables
  >(GET_PROFILE_SEARCH(checkSubscriptions, filter), {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getSearchData()
  }, [])

  if (error) {
    Sentry.captureException(error)
  }
  if (!searchData) return null

  const headers = [
    { label: 'uuid', key: 'id' },
    { label: 'account id', key: 'account' },
    { label: '생성일자(UTC)', key: 'created_at' },
    { label: '생성일자(KST)', key: 'created_at_kst' },
    { label: '가입방법', key: 'provider' },
    { label: '이름', key: 'name' },
    { label: '이메일', key: 'email' },
    { label: '휴대폰 번호', key: 'phone_mobile' },
    { label: '입점신청 내역', key: 'application_detail' },
  ]

  const totalNum = searchData.profile_partner_count.aggregate.count

  return (
    <div className="body">
      <div className={styles.container}>
        <div>파트너 고객 목록</div>
        <br />
        <input
          type={'date'}
          value={startDate}
          onChange={(e): void => {
            setStartDate(e.target.value)
          }}
        />
        &nbsp;~&nbsp;
        <input
          type={'date'}
          value={endDate}
          onChange={(e): void => {
            setEndDate(e.target.value)
          }}
        />
        <button
          className={styles.addButton}
          onClick={async (): Promise<void> => {
            setCurrentPage(0)
            setFilter(`
              { created_at: { _gt: "${moment(startDate).toISOString()}" } }
              { created_at: { _lt: "${moment(endDate).add(1, 'days').toISOString()}" } }
              `)

            getSearchData()
          }}
        >
          기간 검색
        </button>
        <br />
        <br />
        <CSVLink
          data={searchData.profile_partner.map((partner: ProfileKrPartner) => {
            return {
              id: partner.id,
              account: partner.account,
              created_at: moment(partner.created_at).format('YYYY-MM-DD HH:mm'),
              created_at_kst: moment(partner.created_at).add(9, 'hours').format('YYYY-MM-DD HH:mm'),
              provider: partner.accountByAccount.account_type.value,
              name: partner.name,
              email: partner.email,
              phone_mobile: partner.phone_mobile ? new Phonenumber(partner.phone_mobile).getNumber('national') : '',
              application_detail: partner.accountByAccount.store_and_accounts.map((subscription) => {
                return `${subscription.storeByStore.name} / ${
                  subscription.storeByStore.phone_business
                    ? new Phonenumber(subscription.storeByStore.phone_business).getNumber('national')
                    : null
                }`
              }),
            }
          })}
          filename={`파트너 목록 리스트(
            ${startDate ? moment(startDate).format('YYYY.MM.DD') : ''}-
            ${endDate ? moment(endDate).format('YYYY.MM.DD') : ''}
            ).csv`.replace(/[\t\n ]+/gi, '')}
          headers={headers}
        >
          엑셀 파일로 다운로드
        </CSVLink>
        <br />
        <br />
        <div style={{ marginBottom: 10 }}>
          <label>
            한 페이지당 아이템 수&nbsp;
            <select
              value={numberOfItemsPerPage}
              name="perPage"
              id="perPage"
              onChange={(event) => {
                setNumberOfItemsPerPage(parseInt(event.target.value, 10))
                setCurrentPage(0)
                setOffset(0)
                getSearchData({
                  variables: {
                    searchKeyword: `%${searchKeyword}%`,
                    limit: parseInt(event.target.value, 10),
                    offset: 0,
                  },
                })
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </label>
          <label> | </label>
          <label>
            <input
              placeholder="이름|이메일|전화번호"
              defaultValue={searchKeyword}
              value={searchKeyword}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>): void => {
                if (e.key === 'Enter') {
                  getSearchData({
                    variables: {
                      searchKeyword: `%${searchKeyword}%`,
                      limit: numberOfItemsPerPage,
                      offset,
                    },
                  })
                }
              }}
              onChange={(e): void => {
                setSearchKeyword(e.target.value)
              }}
            ></input>
          </label>

          <button
            onClick={() => {
              getSearchData({
                variables: {
                  searchKeyword: `%${searchKeyword}%`,
                  limit: numberOfItemsPerPage,
                  offset,
                },
              })
            }}
          >
            찾기
          </button>
          <label> | </label>
          <input
            type="checkbox"
            defaultChecked={checkSubscriptions}
            onChange={(e) => {
              setCheckSubscriptions(e.target.checked)
            }}
          />
          <label> 입점신청 내역 </label>
          <label> | 총 검색 수 {totalNum}</label>
          <br />
        </div>
        <table>
          <tbody>
            <tr>
              <th>No.</th>
              <th>uuid</th>
              <th>account id</th>
              <th>생성일자</th>
              <th>가입 방법</th>
              <th>이름</th>
              <th>이메일</th>
              <th>휴대폰 번호</th>
              <th style={{ width: 300 }}>입점신청 내역</th>
              <th></th>
            </tr>
            {searchData.profile_partner.map((item: ProfileKrPartner, index: number) => (
              <tr key={index}>
                <td>{totalNum - currentPage * numberOfItemsPerPage - index}</td>
                <td>{item.id}</td>
                <td>{item.account}</td>
                <td>{moment(item.created_at).format()}</td>
                <td>{item.accountByAccount.account_type.value}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.phone_mobile ? new Phonenumber(item.phone_mobile).getNumber('national') : ''}</td>
                <td>
                  {item.accountByAccount.store_and_accounts.map((subscription, subIndex) => (
                    <div key={subIndex}>
                      {`${subscription.storeByStore.name} / ${
                        subscription.storeByStore.phone_business
                          ? new Phonenumber(subscription.storeByStore.phone_business).getNumber('national')
                          : null
                      }`}
                    </div>
                  ))}
                </td>
                <td>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/profile-partner-detail?id=${item.id}`)
                    }}
                  >
                    상세보기
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.paginationWrapper}>
          <div className={styles.pagination}>
            <a
              onClick={() => {
                if (currentPage > 0) {
                  setOffset((currentPage - 1) * numberOfItemsPerPage)
                  setCurrentPage(currentPage - 1)
                  getSearchData({
                    variables: {
                      searchKeyword: `%${searchKeyword}%`,
                      limit: numberOfItemsPerPage,
                      offset: (currentPage - 1) * numberOfItemsPerPage,
                    },
                  })
                }
              }}
            >
              &lt;
            </a>
            {new Array(Math.ceil(totalNum / numberOfItemsPerPage)).fill(0).map((item, index) => {
              return (
                <a
                  key={index}
                  className={currentPage === index ? styles.active : undefined}
                  onClick={() => {
                    setCurrentPage(index)
                    setOffset(index * numberOfItemsPerPage)
                    getSearchData({
                      variables: {
                        searchKeyword: `%${searchKeyword}%`,
                        limit: numberOfItemsPerPage,
                        offset: index * numberOfItemsPerPage,
                      },
                    })
                  }}
                >
                  {index + 1}
                </a>
              )
            })}
            <a
              onClick={() => {
                if (currentPage < Math.ceil(totalNum / numberOfItemsPerPage)) {
                  setOffset((currentPage + 1) * numberOfItemsPerPage)
                  setCurrentPage(currentPage + 1)
                  getSearchData({
                    variables: {
                      searchKeyword: `%${searchKeyword}%`,
                      limit: numberOfItemsPerPage,
                      offset: (currentPage + 1) * numberOfItemsPerPage,
                    },
                  })
                }
              }}
            >
              &gt;
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
