// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__reset___25_M7 {\n  max-width: 30px;\n  min-width: 40px;\n  text-decoration: underline; }\n\n.index-module__reset___25_M7:hover {\n  max-width: 15px;\n  background-color: #efefef; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadList/LeadListTable/LeadFilterRow/index.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,0BAA0B,EAAA;;AAG5B;EACE,eAAe;EACf,yBAAyB,EAAA","file":"index.module.scss","sourcesContent":[".reset {\n  max-width: 30px;\n  min-width: 40px;\n  text-decoration: underline;\n}\n\n.reset:hover {\n  max-width: 15px;\n  background-color: #efefef;\n}\n"]}]);
// Exports
exports.locals = {
	"reset": "index-module__reset___25_M7"
};
module.exports = exports;
