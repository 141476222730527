import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _bool: any;
  _float8: any;
  _text: any;
  bigint: any;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  int4range: any;
  jsonb: any;
  name: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "_bool". All fields are combined with logical 'AND'. */
export type _Bool_Comparison_Exp = {
  _eq?: Maybe<Scalars['_bool']>;
  _gt?: Maybe<Scalars['_bool']>;
  _gte?: Maybe<Scalars['_bool']>;
  _in?: Maybe<Array<Scalars['_bool']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_bool']>;
  _lte?: Maybe<Scalars['_bool']>;
  _neq?: Maybe<Scalars['_bool']>;
  _nin?: Maybe<Array<Scalars['_bool']>>;
};


/** Boolean expression to compare columns of type "_float8". All fields are combined with logical 'AND'. */
export type _Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['_float8']>;
  _gt?: Maybe<Scalars['_float8']>;
  _gte?: Maybe<Scalars['_float8']>;
  _in?: Maybe<Array<Scalars['_float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_float8']>;
  _lte?: Maybe<Scalars['_float8']>;
  _neq?: Maybe<Scalars['_float8']>;
  _nin?: Maybe<Array<Scalars['_float8']>>;
};


/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};

/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type Account = {
  __typename?: 'account';
  /** An object relationship */
  account_type?: Maybe<Account_Type>;
  /** An array relationship */
  adjustments: Array<Adjustment>;
  /** An aggregate relationship */
  adjustments_aggregate: Adjustment_Aggregate;
  /** An array relationship */
  blog_posts: Array<Blog_Post>;
  /** An aggregate relationship */
  blog_posts_aggregate: Blog_Post_Aggregate;
  /** An array relationship */
  carInspectionsByAccountCustomer: Array<Car_Inspection>;
  /** An aggregate relationship */
  carInspectionsByAccountCustomer_aggregate: Car_Inspection_Aggregate;
  /** An array relationship */
  car_inspections: Array<Car_Inspection>;
  /** An aggregate relationship */
  car_inspections_aggregate: Car_Inspection_Aggregate;
  /** An array relationship */
  cars: Array<Car>;
  /** An aggregate relationship */
  cars_aggregate: Car_Aggregate;
  /** An array relationship */
  cart_items: Array<Cart_Item>;
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate;
  /** An array relationship */
  chats: Array<Chat>;
  /** An aggregate relationship */
  chats_aggregate: Chat_Aggregate;
  /** An array relationship */
  coupon_and_accounts: Array<Coupon_And_Account>;
  /** An aggregate relationship */
  coupon_and_accounts_aggregate: Coupon_And_Account_Aggregate;
  /** An array relationship */
  coupons: Array<Coupon>;
  /** An aggregate relationship */
  coupons_aggregate: Coupon_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  curations: Array<Curation>;
  /** An aggregate relationship */
  curations_aggregate: Curation_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  firebase_user_uid: Scalars['String'];
  /** An array relationship */
  galleries: Array<Gallery>;
  /** An aggregate relationship */
  galleries_aggregate: Gallery_Aggregate;
  /** An array relationship */
  hashtags: Array<Hashtag>;
  /** An aggregate relationship */
  hashtags_aggregate: Hashtag_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  /** An array relationship */
  kakao_and_accounts: Array<Kakao_And_Account>;
  /** An aggregate relationship */
  kakao_and_accounts_aggregate: Kakao_And_Account_Aggregate;
  /** An array relationship */
  lead_comments: Array<Lead_Comment>;
  /** An aggregate relationship */
  lead_comments_aggregate: Lead_Comment_Aggregate;
  /** An array relationship */
  leads: Array<Lead>;
  /** An array relationship */
  leadsByShopAssignee: Array<Lead>;
  /** An aggregate relationship */
  leadsByShopAssignee_aggregate: Lead_Aggregate;
  /** An array relationship */
  leadsBySupportAssignee: Array<Lead>;
  /** An aggregate relationship */
  leadsBySupportAssignee_aggregate: Lead_Aggregate;
  /** An aggregate relationship */
  leads_aggregate: Lead_Aggregate;
  /** An array relationship */
  likes: Array<Like>;
  /** An aggregate relationship */
  likes_aggregate: Like_Aggregate;
  /** An array relationship */
  n_carts: Array<N_Cart>;
  /** An aggregate relationship */
  n_carts_aggregate: N_Cart_Aggregate;
  /** An array relationship */
  n_orders: Array<N_Order>;
  /** An aggregate relationship */
  n_orders_aggregate: N_Order_Aggregate;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  /** An array relationship */
  packages: Array<Package>;
  /** An aggregate relationship */
  packages_aggregate: Package_Aggregate;
  /** An array relationship */
  payouts: Array<Payout>;
  /** An aggregate relationship */
  payouts_aggregate: Payout_Aggregate;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  /** An object relationship */
  profile_kr_customer?: Maybe<Profile_Kr_Customer>;
  /** An array relationship */
  profile_kr_customers: Array<Profile_Kr_Customer>;
  /** An aggregate relationship */
  profile_kr_customers_aggregate: Profile_Kr_Customer_Aggregate;
  /** An object relationship */
  profile_kr_partner?: Maybe<Profile_Kr_Partner>;
  /** An array relationship */
  profile_kr_partners: Array<Profile_Kr_Partner>;
  /** An aggregate relationship */
  profile_kr_partners_aggregate: Profile_Kr_Partner_Aggregate;
  /** An object relationship */
  profile_riderdash?: Maybe<Profile_Riderdash>;
  /** An array relationship */
  profile_riderdashes: Array<Profile_Riderdash>;
  /** An aggregate relationship */
  profile_riderdashes_aggregate: Profile_Riderdash_Aggregate;
  /** An object relationship */
  profile_us_customer?: Maybe<Profile_Us_Customer>;
  /** An array relationship */
  profile_us_customers: Array<Profile_Us_Customer>;
  /** An aggregate relationship */
  profile_us_customers_aggregate: Profile_Us_Customer_Aggregate;
  /** An object relationship */
  profile_us_partner?: Maybe<Profile_Us_Partner>;
  /** An array relationship */
  profile_us_partners: Array<Profile_Us_Partner>;
  /** An aggregate relationship */
  profile_us_partners_aggregate: Profile_Us_Partner_Aggregate;
  /** An array relationship */
  refunds: Array<Refund>;
  /** An aggregate relationship */
  refunds_aggregate: Refund_Aggregate;
  /** An array relationship */
  reviews: Array<Review>;
  /** An aggregate relationship */
  reviews_aggregate: Review_Aggregate;
  /** An array relationship */
  sessions: Array<Session>;
  /** An aggregate relationship */
  sessions_aggregate: Session_Aggregate;
  /** An array relationship */
  store_and_accounts: Array<Store_And_Account>;
  /** An aggregate relationship */
  store_and_accounts_aggregate: Store_And_Account_Aggregate;
  /** An array relationship */
  store_and_products: Array<Store_And_Product>;
  /** An aggregate relationship */
  store_and_products_aggregate: Store_And_Product_Aggregate;
  /** An array relationship */
  subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscription_Aggregate;
  type?: Maybe<Account_Type_Enum>;
  updated_at: Scalars['timestamptz'];
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountAdjustmentsArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountAdjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountBlog_PostsArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountBlog_Posts_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCarInspectionsByAccountCustomerArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCarInspectionsByAccountCustomer_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCar_InspectionsArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCar_Inspections_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCarsArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCars_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountChatsArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountChats_AggregateArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCoupon_And_AccountsArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCoupon_And_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCouponsArgs = {
  distinct_on?: Maybe<Array<Coupon_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Order_By>>;
  where?: Maybe<Coupon_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCoupons_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Order_By>>;
  where?: Maybe<Coupon_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCurationsArgs = {
  distinct_on?: Maybe<Array<Curation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Curation_Order_By>>;
  where?: Maybe<Curation_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountCurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Curation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Curation_Order_By>>;
  where?: Maybe<Curation_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountGalleriesArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountGalleries_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountHashtagsArgs = {
  distinct_on?: Maybe<Array<Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hashtag_Order_By>>;
  where?: Maybe<Hashtag_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountHashtags_AggregateArgs = {
  distinct_on?: Maybe<Array<Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hashtag_Order_By>>;
  where?: Maybe<Hashtag_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountKakao_And_AccountsArgs = {
  distinct_on?: Maybe<Array<Kakao_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kakao_And_Account_Order_By>>;
  where?: Maybe<Kakao_And_Account_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountKakao_And_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Kakao_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kakao_And_Account_Order_By>>;
  where?: Maybe<Kakao_And_Account_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLead_CommentsArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLead_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLeadsArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLeadsByShopAssigneeArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLeadsByShopAssignee_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLeadsBySupportAssigneeArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLeadsBySupportAssignee_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLeads_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLikesArgs = {
  distinct_on?: Maybe<Array<Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Like_Order_By>>;
  where?: Maybe<Like_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountLikes_AggregateArgs = {
  distinct_on?: Maybe<Array<Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Like_Order_By>>;
  where?: Maybe<Like_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountN_CartsArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountN_Carts_AggregateArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountN_OrdersArgs = {
  distinct_on?: Maybe<Array<N_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Order_Order_By>>;
  where?: Maybe<N_Order_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountN_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<N_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Order_Order_By>>;
  where?: Maybe<N_Order_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountPackagesArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountPackages_AggregateArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountPayoutsArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountPayouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProductsArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Kr_CustomersArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Kr_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Kr_PartnersArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Kr_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_RiderdashesArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Riderdashes_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Us_CustomersArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Us_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Us_PartnersArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountProfile_Us_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountRefundsArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountRefunds_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountReviewsArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountReviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountSessionsArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountStore_And_AccountsArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountStore_And_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountStore_And_ProductsArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountStore_And_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountSubscriptionsArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


/**
 * Registered user account
 *
 *
 * columns and relationships of "account"
 */
export type AccountSubscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};

/** aggregated selection of "account" */
export type Account_Aggregate = {
  __typename?: 'account_aggregate';
  aggregate?: Maybe<Account_Aggregate_Fields>;
  nodes: Array<Account>;
};

/** aggregate fields of "account" */
export type Account_Aggregate_Fields = {
  __typename?: 'account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Max_Fields>;
  min?: Maybe<Account_Min_Fields>;
};


/** aggregate fields of "account" */
export type Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account" */
export type Account_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Account_Max_Order_By>;
  min?: Maybe<Account_Min_Order_By>;
};

/** input type for inserting array relation for remote table "account" */
export type Account_Arr_Rel_Insert_Input = {
  data: Array<Account_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Account_On_Conflict>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type Account_Bool_Exp = {
  _and?: Maybe<Array<Account_Bool_Exp>>;
  _not?: Maybe<Account_Bool_Exp>;
  _or?: Maybe<Array<Account_Bool_Exp>>;
  account_type?: Maybe<Account_Type_Bool_Exp>;
  adjustments?: Maybe<Adjustment_Bool_Exp>;
  blog_posts?: Maybe<Blog_Post_Bool_Exp>;
  carInspectionsByAccountCustomer?: Maybe<Car_Inspection_Bool_Exp>;
  car_inspections?: Maybe<Car_Inspection_Bool_Exp>;
  cars?: Maybe<Car_Bool_Exp>;
  cart_items?: Maybe<Cart_Item_Bool_Exp>;
  chats?: Maybe<Chat_Bool_Exp>;
  coupon_and_accounts?: Maybe<Coupon_And_Account_Bool_Exp>;
  coupons?: Maybe<Coupon_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  curations?: Maybe<Curation_Bool_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  firebase_user_uid?: Maybe<String_Comparison_Exp>;
  galleries?: Maybe<Gallery_Bool_Exp>;
  hashtags?: Maybe<Hashtag_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoices?: Maybe<Invoice_Bool_Exp>;
  kakao_and_accounts?: Maybe<Kakao_And_Account_Bool_Exp>;
  lead_comments?: Maybe<Lead_Comment_Bool_Exp>;
  leads?: Maybe<Lead_Bool_Exp>;
  leadsByShopAssignee?: Maybe<Lead_Bool_Exp>;
  leadsBySupportAssignee?: Maybe<Lead_Bool_Exp>;
  likes?: Maybe<Like_Bool_Exp>;
  n_carts?: Maybe<N_Cart_Bool_Exp>;
  n_orders?: Maybe<N_Order_Bool_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  packages?: Maybe<Package_Bool_Exp>;
  payouts?: Maybe<Payout_Bool_Exp>;
  products?: Maybe<Product_Bool_Exp>;
  profile_kr_customer?: Maybe<Profile_Kr_Customer_Bool_Exp>;
  profile_kr_customers?: Maybe<Profile_Kr_Customer_Bool_Exp>;
  profile_kr_partner?: Maybe<Profile_Kr_Partner_Bool_Exp>;
  profile_kr_partners?: Maybe<Profile_Kr_Partner_Bool_Exp>;
  profile_riderdash?: Maybe<Profile_Riderdash_Bool_Exp>;
  profile_riderdashes?: Maybe<Profile_Riderdash_Bool_Exp>;
  profile_us_customer?: Maybe<Profile_Us_Customer_Bool_Exp>;
  profile_us_customers?: Maybe<Profile_Us_Customer_Bool_Exp>;
  profile_us_partner?: Maybe<Profile_Us_Partner_Bool_Exp>;
  profile_us_partners?: Maybe<Profile_Us_Partner_Bool_Exp>;
  refunds?: Maybe<Refund_Bool_Exp>;
  reviews?: Maybe<Review_Bool_Exp>;
  sessions?: Maybe<Session_Bool_Exp>;
  store_and_accounts?: Maybe<Store_And_Account_Bool_Exp>;
  store_and_products?: Maybe<Store_And_Product_Bool_Exp>;
  subscriptions?: Maybe<Subscription_Bool_Exp>;
  type?: Maybe<Account_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint */
  AccountFirebaseUserUidKey = 'account_firebase_user_uid_key',
  /** unique or primary key constraint */
  AccountPkey = 'account_pkey'
}

/** input type for inserting data into table "account" */
export type Account_Insert_Input = {
  account_type?: Maybe<Account_Type_Obj_Rel_Insert_Input>;
  adjustments?: Maybe<Adjustment_Arr_Rel_Insert_Input>;
  blog_posts?: Maybe<Blog_Post_Arr_Rel_Insert_Input>;
  carInspectionsByAccountCustomer?: Maybe<Car_Inspection_Arr_Rel_Insert_Input>;
  car_inspections?: Maybe<Car_Inspection_Arr_Rel_Insert_Input>;
  cars?: Maybe<Car_Arr_Rel_Insert_Input>;
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>;
  chats?: Maybe<Chat_Arr_Rel_Insert_Input>;
  coupon_and_accounts?: Maybe<Coupon_And_Account_Arr_Rel_Insert_Input>;
  coupons?: Maybe<Coupon_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  curations?: Maybe<Curation_Arr_Rel_Insert_Input>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  firebase_user_uid?: Maybe<Scalars['String']>;
  galleries?: Maybe<Gallery_Arr_Rel_Insert_Input>;
  hashtags?: Maybe<Hashtag_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  invoices?: Maybe<Invoice_Arr_Rel_Insert_Input>;
  kakao_and_accounts?: Maybe<Kakao_And_Account_Arr_Rel_Insert_Input>;
  lead_comments?: Maybe<Lead_Comment_Arr_Rel_Insert_Input>;
  leads?: Maybe<Lead_Arr_Rel_Insert_Input>;
  leadsByShopAssignee?: Maybe<Lead_Arr_Rel_Insert_Input>;
  leadsBySupportAssignee?: Maybe<Lead_Arr_Rel_Insert_Input>;
  likes?: Maybe<Like_Arr_Rel_Insert_Input>;
  n_carts?: Maybe<N_Cart_Arr_Rel_Insert_Input>;
  n_orders?: Maybe<N_Order_Arr_Rel_Insert_Input>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  packages?: Maybe<Package_Arr_Rel_Insert_Input>;
  payouts?: Maybe<Payout_Arr_Rel_Insert_Input>;
  products?: Maybe<Product_Arr_Rel_Insert_Input>;
  profile_kr_customer?: Maybe<Profile_Kr_Customer_Obj_Rel_Insert_Input>;
  profile_kr_customers?: Maybe<Profile_Kr_Customer_Arr_Rel_Insert_Input>;
  profile_kr_partner?: Maybe<Profile_Kr_Partner_Obj_Rel_Insert_Input>;
  profile_kr_partners?: Maybe<Profile_Kr_Partner_Arr_Rel_Insert_Input>;
  profile_riderdash?: Maybe<Profile_Riderdash_Obj_Rel_Insert_Input>;
  profile_riderdashes?: Maybe<Profile_Riderdash_Arr_Rel_Insert_Input>;
  profile_us_customer?: Maybe<Profile_Us_Customer_Obj_Rel_Insert_Input>;
  profile_us_customers?: Maybe<Profile_Us_Customer_Arr_Rel_Insert_Input>;
  profile_us_partner?: Maybe<Profile_Us_Partner_Obj_Rel_Insert_Input>;
  profile_us_partners?: Maybe<Profile_Us_Partner_Arr_Rel_Insert_Input>;
  refunds?: Maybe<Refund_Arr_Rel_Insert_Input>;
  reviews?: Maybe<Review_Arr_Rel_Insert_Input>;
  sessions?: Maybe<Session_Arr_Rel_Insert_Input>;
  store_and_accounts?: Maybe<Store_And_Account_Arr_Rel_Insert_Input>;
  store_and_products?: Maybe<Store_And_Product_Arr_Rel_Insert_Input>;
  subscriptions?: Maybe<Subscription_Arr_Rel_Insert_Input>;
  type?: Maybe<Account_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Max_Fields = {
  __typename?: 'account_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  firebase_user_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account" */
export type Account_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  firebase_user_uid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Min_Fields = {
  __typename?: 'account_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  firebase_user_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account" */
export type Account_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  firebase_user_uid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "account" */
export type Account_Mutation_Response = {
  __typename?: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
};

/** input type for inserting object relation for remote table "account" */
export type Account_Obj_Rel_Insert_Input = {
  data: Account_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Account_On_Conflict>;
};

/** on conflict condition type for table "account" */
export type Account_On_Conflict = {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: Maybe<Account_Bool_Exp>;
};

/** Ordering options when selecting data from "account". */
export type Account_Order_By = {
  account_type?: Maybe<Account_Type_Order_By>;
  adjustments_aggregate?: Maybe<Adjustment_Aggregate_Order_By>;
  blog_posts_aggregate?: Maybe<Blog_Post_Aggregate_Order_By>;
  carInspectionsByAccountCustomer_aggregate?: Maybe<Car_Inspection_Aggregate_Order_By>;
  car_inspections_aggregate?: Maybe<Car_Inspection_Aggregate_Order_By>;
  cars_aggregate?: Maybe<Car_Aggregate_Order_By>;
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>;
  chats_aggregate?: Maybe<Chat_Aggregate_Order_By>;
  coupon_and_accounts_aggregate?: Maybe<Coupon_And_Account_Aggregate_Order_By>;
  coupons_aggregate?: Maybe<Coupon_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  curations_aggregate?: Maybe<Curation_Aggregate_Order_By>;
  deleted_at?: Maybe<Order_By>;
  firebase_user_uid?: Maybe<Order_By>;
  galleries_aggregate?: Maybe<Gallery_Aggregate_Order_By>;
  hashtags_aggregate?: Maybe<Hashtag_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoice_Aggregate_Order_By>;
  kakao_and_accounts_aggregate?: Maybe<Kakao_And_Account_Aggregate_Order_By>;
  lead_comments_aggregate?: Maybe<Lead_Comment_Aggregate_Order_By>;
  leadsByShopAssignee_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  leadsBySupportAssignee_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  leads_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  likes_aggregate?: Maybe<Like_Aggregate_Order_By>;
  n_carts_aggregate?: Maybe<N_Cart_Aggregate_Order_By>;
  n_orders_aggregate?: Maybe<N_Order_Aggregate_Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  packages_aggregate?: Maybe<Package_Aggregate_Order_By>;
  payouts_aggregate?: Maybe<Payout_Aggregate_Order_By>;
  products_aggregate?: Maybe<Product_Aggregate_Order_By>;
  profile_kr_customer?: Maybe<Profile_Kr_Customer_Order_By>;
  profile_kr_customers_aggregate?: Maybe<Profile_Kr_Customer_Aggregate_Order_By>;
  profile_kr_partner?: Maybe<Profile_Kr_Partner_Order_By>;
  profile_kr_partners_aggregate?: Maybe<Profile_Kr_Partner_Aggregate_Order_By>;
  profile_riderdash?: Maybe<Profile_Riderdash_Order_By>;
  profile_riderdashes_aggregate?: Maybe<Profile_Riderdash_Aggregate_Order_By>;
  profile_us_customer?: Maybe<Profile_Us_Customer_Order_By>;
  profile_us_customers_aggregate?: Maybe<Profile_Us_Customer_Aggregate_Order_By>;
  profile_us_partner?: Maybe<Profile_Us_Partner_Order_By>;
  profile_us_partners_aggregate?: Maybe<Profile_Us_Partner_Aggregate_Order_By>;
  refunds_aggregate?: Maybe<Refund_Aggregate_Order_By>;
  reviews_aggregate?: Maybe<Review_Aggregate_Order_By>;
  sessions_aggregate?: Maybe<Session_Aggregate_Order_By>;
  store_and_accounts_aggregate?: Maybe<Store_And_Account_Aggregate_Order_By>;
  store_and_products_aggregate?: Maybe<Store_And_Product_Aggregate_Order_By>;
  subscriptions_aggregate?: Maybe<Subscription_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "account_performance" */
export type Account_Performance = {
  __typename?: 'account_performance';
  account?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  used_car_status?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "account_performance" */
export type Account_Performance_Aggregate = {
  __typename?: 'account_performance_aggregate';
  aggregate?: Maybe<Account_Performance_Aggregate_Fields>;
  nodes: Array<Account_Performance>;
};

/** aggregate fields of "account_performance" */
export type Account_Performance_Aggregate_Fields = {
  __typename?: 'account_performance_aggregate_fields';
  avg?: Maybe<Account_Performance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Performance_Max_Fields>;
  min?: Maybe<Account_Performance_Min_Fields>;
  stddev?: Maybe<Account_Performance_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Performance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Performance_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Performance_Sum_Fields>;
  var_pop?: Maybe<Account_Performance_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Performance_Var_Samp_Fields>;
  variance?: Maybe<Account_Performance_Variance_Fields>;
};


/** aggregate fields of "account_performance" */
export type Account_Performance_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Performance_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Performance_Avg_Fields = {
  __typename?: 'account_performance_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account_performance". All fields are combined with a logical 'AND'. */
export type Account_Performance_Bool_Exp = {
  _and?: Maybe<Array<Account_Performance_Bool_Exp>>;
  _not?: Maybe<Account_Performance_Bool_Exp>;
  _or?: Maybe<Array<Account_Performance_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  used_car_status?: Maybe<Boolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type Account_Performance_Max_Fields = {
  __typename?: 'account_performance_max_fields';
  account?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Performance_Min_Fields = {
  __typename?: 'account_performance_min_fields';
  account?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "account_performance". */
export type Account_Performance_Order_By = {
  account?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  used_car_status?: Maybe<Order_By>;
};

/** select columns of table "account_performance" */
export enum Account_Performance_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Count = 'count',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UsedCarStatus = 'used_car_status'
}

/** aggregate stddev on columns */
export type Account_Performance_Stddev_Fields = {
  __typename?: 'account_performance_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Performance_Stddev_Pop_Fields = {
  __typename?: 'account_performance_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Performance_Stddev_Samp_Fields = {
  __typename?: 'account_performance_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Account_Performance_Sum_Fields = {
  __typename?: 'account_performance_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Account_Performance_Var_Pop_Fields = {
  __typename?: 'account_performance_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Performance_Var_Samp_Fields = {
  __typename?: 'account_performance_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Performance_Variance_Fields = {
  __typename?: 'account_performance_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: account */
export type Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirebaseUserUid = 'firebase_user_uid',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account" */
export type Account_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  firebase_user_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Account_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "account_throughput" */
export type Account_Throughput = {
  __typename?: 'account_throughput';
  account?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account_throughput" */
export type Account_Throughput_Aggregate = {
  __typename?: 'account_throughput_aggregate';
  aggregate?: Maybe<Account_Throughput_Aggregate_Fields>;
  nodes: Array<Account_Throughput>;
};

/** aggregate fields of "account_throughput" */
export type Account_Throughput_Aggregate_Fields = {
  __typename?: 'account_throughput_aggregate_fields';
  avg?: Maybe<Account_Throughput_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Throughput_Max_Fields>;
  min?: Maybe<Account_Throughput_Min_Fields>;
  stddev?: Maybe<Account_Throughput_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Throughput_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Throughput_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Throughput_Sum_Fields>;
  var_pop?: Maybe<Account_Throughput_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Throughput_Var_Samp_Fields>;
  variance?: Maybe<Account_Throughput_Variance_Fields>;
};


/** aggregate fields of "account_throughput" */
export type Account_Throughput_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Throughput_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Throughput_Avg_Fields = {
  __typename?: 'account_throughput_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account_throughput". All fields are combined with a logical 'AND'. */
export type Account_Throughput_Bool_Exp = {
  _and?: Maybe<Array<Account_Throughput_Bool_Exp>>;
  _not?: Maybe<Account_Throughput_Bool_Exp>;
  _or?: Maybe<Array<Account_Throughput_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Account_Throughput_Max_Fields = {
  __typename?: 'account_throughput_max_fields';
  account?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Throughput_Min_Fields = {
  __typename?: 'account_throughput_min_fields';
  account?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "account_throughput". */
export type Account_Throughput_Order_By = {
  account?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** select columns of table "account_throughput" */
export enum Account_Throughput_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Count = 'count',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role'
}

/** aggregate stddev on columns */
export type Account_Throughput_Stddev_Fields = {
  __typename?: 'account_throughput_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Throughput_Stddev_Pop_Fields = {
  __typename?: 'account_throughput_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Throughput_Stddev_Samp_Fields = {
  __typename?: 'account_throughput_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Account_Throughput_Sum_Fields = {
  __typename?: 'account_throughput_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Account_Throughput_Var_Pop_Fields = {
  __typename?: 'account_throughput_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Throughput_Var_Samp_Fields = {
  __typename?: 'account_throughput_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Throughput_Variance_Fields = {
  __typename?: 'account_throughput_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/**
 * Sign up method
 *
 *
 * columns and relationships of "account_type"
 */
export type Account_Type = {
  __typename?: 'account_type';
  /** An array relationship */
  accounts: Array<Account>;
  /** An aggregate relationship */
  accounts_aggregate: Account_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};


/**
 * Sign up method
 *
 *
 * columns and relationships of "account_type"
 */
export type Account_TypeAccountsArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


/**
 * Sign up method
 *
 *
 * columns and relationships of "account_type"
 */
export type Account_TypeAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};

/** aggregated selection of "account_type" */
export type Account_Type_Aggregate = {
  __typename?: 'account_type_aggregate';
  aggregate?: Maybe<Account_Type_Aggregate_Fields>;
  nodes: Array<Account_Type>;
};

/** aggregate fields of "account_type" */
export type Account_Type_Aggregate_Fields = {
  __typename?: 'account_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Type_Max_Fields>;
  min?: Maybe<Account_Type_Min_Fields>;
};


/** aggregate fields of "account_type" */
export type Account_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account_type". All fields are combined with a logical 'AND'. */
export type Account_Type_Bool_Exp = {
  _and?: Maybe<Array<Account_Type_Bool_Exp>>;
  _not?: Maybe<Account_Type_Bool_Exp>;
  _or?: Maybe<Array<Account_Type_Bool_Exp>>;
  accounts?: Maybe<Account_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_type" */
export enum Account_Type_Constraint {
  /** unique or primary key constraint */
  AccountTypePkey = 'account_type_pkey'
}

export enum Account_Type_Enum {
  /** 직접 가입 */
  Caramora = 'caramora',
  /** Facebook */
  Facebook = 'facebook',
  /** Google */
  Google = 'google',
  /** Kakao */
  Kakao = 'kakao',
  /** Naver */
  Naver = 'naver',
  /** Phone */
  Phone = 'phone'
}

/** Boolean expression to compare columns of type "account_type_enum". All fields are combined with logical 'AND'. */
export type Account_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Account_Type_Enum>;
  _in?: Maybe<Array<Account_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Account_Type_Enum>;
  _nin?: Maybe<Array<Account_Type_Enum>>;
};

/** input type for inserting data into table "account_type" */
export type Account_Type_Insert_Input = {
  accounts?: Maybe<Account_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Type_Max_Fields = {
  __typename?: 'account_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Type_Min_Fields = {
  __typename?: 'account_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account_type" */
export type Account_Type_Mutation_Response = {
  __typename?: 'account_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Type>;
};

/** input type for inserting object relation for remote table "account_type" */
export type Account_Type_Obj_Rel_Insert_Input = {
  data: Account_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Account_Type_On_Conflict>;
};

/** on conflict condition type for table "account_type" */
export type Account_Type_On_Conflict = {
  constraint: Account_Type_Constraint;
  update_columns?: Array<Account_Type_Update_Column>;
  where?: Maybe<Account_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "account_type". */
export type Account_Type_Order_By = {
  accounts_aggregate?: Maybe<Account_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: account_type */
export type Account_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "account_type" */
export enum Account_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "account_type" */
export type Account_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "account_type" */
export enum Account_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirebaseUserUid = 'firebase_user_uid',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Accounts - paid customers
 *
 *
 * columns and relationships of "accounts_payable"
 */
export type Accounts_Payable = {
  __typename?: 'accounts_payable';
  /** An array relationship */
  accounts_payable_and_adjustments: Array<Accounts_Payable_And_Adjustment>;
  /** An aggregate relationship */
  accounts_payable_and_adjustments_aggregate: Accounts_Payable_And_Adjustment_Aggregate;
  /** An array relationship */
  accounts_payable_and_payouts: Array<Accounts_Payable_And_Payout>;
  /** An aggregate relationship */
  accounts_payable_and_payouts_aggregate: Accounts_Payable_And_Payout_Aggregate;
  /** An array relationship */
  accounts_payable_and_refunds: Array<Accounts_Payable_And_Refund>;
  /** An aggregate relationship */
  accounts_payable_and_refunds_aggregate: Accounts_Payable_And_Refund_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/**
 * Accounts - paid customers
 *
 *
 * columns and relationships of "accounts_payable"
 */
export type Accounts_PayableAccounts_Payable_And_AdjustmentsArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


/**
 * Accounts - paid customers
 *
 *
 * columns and relationships of "accounts_payable"
 */
export type Accounts_PayableAccounts_Payable_And_Adjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


/**
 * Accounts - paid customers
 *
 *
 * columns and relationships of "accounts_payable"
 */
export type Accounts_PayableAccounts_Payable_And_PayoutsArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


/**
 * Accounts - paid customers
 *
 *
 * columns and relationships of "accounts_payable"
 */
export type Accounts_PayableAccounts_Payable_And_Payouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


/**
 * Accounts - paid customers
 *
 *
 * columns and relationships of "accounts_payable"
 */
export type Accounts_PayableAccounts_Payable_And_RefundsArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};


/**
 * Accounts - paid customers
 *
 *
 * columns and relationships of "accounts_payable"
 */
export type Accounts_PayableAccounts_Payable_And_Refunds_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};

/** aggregated selection of "accounts_payable" */
export type Accounts_Payable_Aggregate = {
  __typename?: 'accounts_payable_aggregate';
  aggregate?: Maybe<Accounts_Payable_Aggregate_Fields>;
  nodes: Array<Accounts_Payable>;
};

/** aggregate fields of "accounts_payable" */
export type Accounts_Payable_Aggregate_Fields = {
  __typename?: 'accounts_payable_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Payable_Max_Fields>;
  min?: Maybe<Accounts_Payable_Min_Fields>;
};


/** aggregate fields of "accounts_payable" */
export type Accounts_Payable_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Payable_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/**
 * Accounts - paid customers with price adjustment
 *
 *
 * columns and relationships of "accounts_payable_and_adjustment"
 */
export type Accounts_Payable_And_Adjustment = {
  __typename?: 'accounts_payable_and_adjustment';
  /** An object relationship */
  accountsPayableByAccountsPayable: Accounts_Payable;
  accounts_payable: Scalars['uuid'];
  adjustment: Scalars['uuid'];
  /** An object relationship */
  adjustmentByAdjustment?: Maybe<Adjustment>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Aggregate = {
  __typename?: 'accounts_payable_and_adjustment_aggregate';
  aggregate?: Maybe<Accounts_Payable_And_Adjustment_Aggregate_Fields>;
  nodes: Array<Accounts_Payable_And_Adjustment>;
};

/** aggregate fields of "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Aggregate_Fields = {
  __typename?: 'accounts_payable_and_adjustment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Payable_And_Adjustment_Max_Fields>;
  min?: Maybe<Accounts_Payable_And_Adjustment_Min_Fields>;
};


/** aggregate fields of "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Accounts_Payable_And_Adjustment_Max_Order_By>;
  min?: Maybe<Accounts_Payable_And_Adjustment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Payable_And_Adjustment_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Payable_And_Adjustment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts_payable_and_adjustment". All fields are combined with a logical 'AND'. */
export type Accounts_Payable_And_Adjustment_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Payable_And_Adjustment_Bool_Exp>>;
  _not?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Payable_And_Adjustment_Bool_Exp>>;
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Bool_Exp>;
  accounts_payable?: Maybe<Uuid_Comparison_Exp>;
  adjustment?: Maybe<Uuid_Comparison_Exp>;
  adjustmentByAdjustment?: Maybe<Adjustment_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_payable_and_adjustment" */
export enum Accounts_Payable_And_Adjustment_Constraint {
  /** unique or primary key constraint */
  AccountsPayableAndAdjustmentPkey = 'accounts_payable_and_adjustment_pkey'
}

/** input type for inserting data into table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Insert_Input = {
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Obj_Rel_Insert_Input>;
  accounts_payable?: Maybe<Scalars['uuid']>;
  adjustment?: Maybe<Scalars['uuid']>;
  adjustmentByAdjustment?: Maybe<Adjustment_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Payable_And_Adjustment_Max_Fields = {
  __typename?: 'accounts_payable_and_adjustment_max_fields';
  accounts_payable?: Maybe<Scalars['uuid']>;
  adjustment?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Max_Order_By = {
  accounts_payable?: Maybe<Order_By>;
  adjustment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Payable_And_Adjustment_Min_Fields = {
  __typename?: 'accounts_payable_and_adjustment_min_fields';
  accounts_payable?: Maybe<Scalars['uuid']>;
  adjustment?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Min_Order_By = {
  accounts_payable?: Maybe<Order_By>;
  adjustment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Mutation_Response = {
  __typename?: 'accounts_payable_and_adjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Payable_And_Adjustment>;
};

/** on conflict condition type for table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_On_Conflict = {
  constraint: Accounts_Payable_And_Adjustment_Constraint;
  update_columns?: Array<Accounts_Payable_And_Adjustment_Update_Column>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_payable_and_adjustment". */
export type Accounts_Payable_And_Adjustment_Order_By = {
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Order_By>;
  accounts_payable?: Maybe<Order_By>;
  adjustment?: Maybe<Order_By>;
  adjustmentByAdjustment?: Maybe<Adjustment_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_payable_and_adjustment */
export type Accounts_Payable_And_Adjustment_Pk_Columns_Input = {
  accounts_payable: Scalars['uuid'];
  adjustment: Scalars['uuid'];
};

/** select columns of table "accounts_payable_and_adjustment" */
export enum Accounts_Payable_And_Adjustment_Select_Column {
  /** column name */
  AccountsPayable = 'accounts_payable',
  /** column name */
  Adjustment = 'adjustment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_payable_and_adjustment" */
export type Accounts_Payable_And_Adjustment_Set_Input = {
  accounts_payable?: Maybe<Scalars['uuid']>;
  adjustment?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts_payable_and_adjustment" */
export enum Accounts_Payable_And_Adjustment_Update_Column {
  /** column name */
  AccountsPayable = 'accounts_payable',
  /** column name */
  Adjustment = 'adjustment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Accounts - paid customers who got serviced
 *
 *
 * columns and relationships of "accounts_payable_and_payout"
 */
export type Accounts_Payable_And_Payout = {
  __typename?: 'accounts_payable_and_payout';
  /** An object relationship */
  accountsPayableByAccountsPayable: Accounts_Payable;
  accounts_payable: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout: Scalars['uuid'];
  /** An object relationship */
  payoutByPayout: Payout;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Aggregate = {
  __typename?: 'accounts_payable_and_payout_aggregate';
  aggregate?: Maybe<Accounts_Payable_And_Payout_Aggregate_Fields>;
  nodes: Array<Accounts_Payable_And_Payout>;
};

/** aggregate fields of "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Aggregate_Fields = {
  __typename?: 'accounts_payable_and_payout_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Payable_And_Payout_Max_Fields>;
  min?: Maybe<Accounts_Payable_And_Payout_Min_Fields>;
};


/** aggregate fields of "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Accounts_Payable_And_Payout_Max_Order_By>;
  min?: Maybe<Accounts_Payable_And_Payout_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Payable_And_Payout_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Payable_And_Payout_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts_payable_and_payout". All fields are combined with a logical 'AND'. */
export type Accounts_Payable_And_Payout_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Payable_And_Payout_Bool_Exp>>;
  _not?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Payable_And_Payout_Bool_Exp>>;
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Bool_Exp>;
  accounts_payable?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  payout?: Maybe<Uuid_Comparison_Exp>;
  payoutByPayout?: Maybe<Payout_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_payable_and_payout" */
export enum Accounts_Payable_And_Payout_Constraint {
  /** unique or primary key constraint */
  AccountsPayablePayoutPkey = 'accounts_payable_payout_pkey'
}

/** input type for inserting data into table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Insert_Input = {
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Obj_Rel_Insert_Input>;
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  payoutByPayout?: Maybe<Payout_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Payable_And_Payout_Max_Fields = {
  __typename?: 'accounts_payable_and_payout_max_fields';
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Max_Order_By = {
  accounts_payable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  payout?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Payable_And_Payout_Min_Fields = {
  __typename?: 'accounts_payable_and_payout_min_fields';
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Min_Order_By = {
  accounts_payable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  payout?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Mutation_Response = {
  __typename?: 'accounts_payable_and_payout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Payable_And_Payout>;
};

/** on conflict condition type for table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_On_Conflict = {
  constraint: Accounts_Payable_And_Payout_Constraint;
  update_columns?: Array<Accounts_Payable_And_Payout_Update_Column>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_payable_and_payout". */
export type Accounts_Payable_And_Payout_Order_By = {
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Order_By>;
  accounts_payable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  payout?: Maybe<Order_By>;
  payoutByPayout?: Maybe<Payout_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_payable_and_payout */
export type Accounts_Payable_And_Payout_Pk_Columns_Input = {
  accounts_payable: Scalars['uuid'];
  payout: Scalars['uuid'];
};

/** select columns of table "accounts_payable_and_payout" */
export enum Accounts_Payable_And_Payout_Select_Column {
  /** column name */
  AccountsPayable = 'accounts_payable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Payout = 'payout',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_payable_and_payout" */
export type Accounts_Payable_And_Payout_Set_Input = {
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts_payable_and_payout" */
export enum Accounts_Payable_And_Payout_Update_Column {
  /** column name */
  AccountsPayable = 'accounts_payable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Payout = 'payout',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Accounts - paid customers who refunded
 *
 *
 * columns and relationships of "accounts_payable_and_refund"
 */
export type Accounts_Payable_And_Refund = {
  __typename?: 'accounts_payable_and_refund';
  /** An object relationship */
  accountsPayableByAccountsPayable: Accounts_Payable;
  accounts_payable: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund: Scalars['uuid'];
  /** An object relationship */
  refundByRefund: Refund;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Aggregate = {
  __typename?: 'accounts_payable_and_refund_aggregate';
  aggregate?: Maybe<Accounts_Payable_And_Refund_Aggregate_Fields>;
  nodes: Array<Accounts_Payable_And_Refund>;
};

/** aggregate fields of "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Aggregate_Fields = {
  __typename?: 'accounts_payable_and_refund_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Payable_And_Refund_Max_Fields>;
  min?: Maybe<Accounts_Payable_And_Refund_Min_Fields>;
};


/** aggregate fields of "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Accounts_Payable_And_Refund_Max_Order_By>;
  min?: Maybe<Accounts_Payable_And_Refund_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Payable_And_Refund_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Payable_And_Refund_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts_payable_and_refund". All fields are combined with a logical 'AND'. */
export type Accounts_Payable_And_Refund_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Payable_And_Refund_Bool_Exp>>;
  _not?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Payable_And_Refund_Bool_Exp>>;
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Bool_Exp>;
  accounts_payable?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  refund?: Maybe<Uuid_Comparison_Exp>;
  refundByRefund?: Maybe<Refund_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_payable_and_refund" */
export enum Accounts_Payable_And_Refund_Constraint {
  /** unique or primary key constraint */
  AccountsPayableAndRefundPkey = 'accounts_payable_and_refund_pkey'
}

/** input type for inserting data into table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Insert_Input = {
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Obj_Rel_Insert_Input>;
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  refundByRefund?: Maybe<Refund_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Payable_And_Refund_Max_Fields = {
  __typename?: 'accounts_payable_and_refund_max_fields';
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Max_Order_By = {
  accounts_payable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Payable_And_Refund_Min_Fields = {
  __typename?: 'accounts_payable_and_refund_min_fields';
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Min_Order_By = {
  accounts_payable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Mutation_Response = {
  __typename?: 'accounts_payable_and_refund_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Payable_And_Refund>;
};

/** on conflict condition type for table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_On_Conflict = {
  constraint: Accounts_Payable_And_Refund_Constraint;
  update_columns?: Array<Accounts_Payable_And_Refund_Update_Column>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_payable_and_refund". */
export type Accounts_Payable_And_Refund_Order_By = {
  accountsPayableByAccountsPayable?: Maybe<Accounts_Payable_Order_By>;
  accounts_payable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  refundByRefund?: Maybe<Refund_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_payable_and_refund */
export type Accounts_Payable_And_Refund_Pk_Columns_Input = {
  accounts_payable: Scalars['uuid'];
  refund: Scalars['uuid'];
};

/** select columns of table "accounts_payable_and_refund" */
export enum Accounts_Payable_And_Refund_Select_Column {
  /** column name */
  AccountsPayable = 'accounts_payable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Refund = 'refund',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_payable_and_refund" */
export type Accounts_Payable_And_Refund_Set_Input = {
  accounts_payable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts_payable_and_refund" */
export enum Accounts_Payable_And_Refund_Update_Column {
  /** column name */
  AccountsPayable = 'accounts_payable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Refund = 'refund',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Boolean expression to filter rows from the table "accounts_payable". All fields are combined with a logical 'AND'. */
export type Accounts_Payable_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Payable_Bool_Exp>>;
  _not?: Maybe<Accounts_Payable_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Payable_Bool_Exp>>;
  accounts_payable_and_adjustments?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
  accounts_payable_and_payouts?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
  accounts_payable_and_refunds?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_payable" */
export enum Accounts_Payable_Constraint {
  /** unique or primary key constraint */
  AccountsPayablePkey = 'accounts_payable_pkey'
}

/** input type for inserting data into table "accounts_payable" */
export type Accounts_Payable_Insert_Input = {
  accounts_payable_and_adjustments?: Maybe<Accounts_Payable_And_Adjustment_Arr_Rel_Insert_Input>;
  accounts_payable_and_payouts?: Maybe<Accounts_Payable_And_Payout_Arr_Rel_Insert_Input>;
  accounts_payable_and_refunds?: Maybe<Accounts_Payable_And_Refund_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Payable_Max_Fields = {
  __typename?: 'accounts_payable_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Accounts_Payable_Min_Fields = {
  __typename?: 'accounts_payable_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "accounts_payable" */
export type Accounts_Payable_Mutation_Response = {
  __typename?: 'accounts_payable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Payable>;
};

/** input type for inserting object relation for remote table "accounts_payable" */
export type Accounts_Payable_Obj_Rel_Insert_Input = {
  data: Accounts_Payable_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Payable_On_Conflict>;
};

/** on conflict condition type for table "accounts_payable" */
export type Accounts_Payable_On_Conflict = {
  constraint: Accounts_Payable_Constraint;
  update_columns?: Array<Accounts_Payable_Update_Column>;
  where?: Maybe<Accounts_Payable_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_payable". */
export type Accounts_Payable_Order_By = {
  accounts_payable_and_adjustments_aggregate?: Maybe<Accounts_Payable_And_Adjustment_Aggregate_Order_By>;
  accounts_payable_and_payouts_aggregate?: Maybe<Accounts_Payable_And_Payout_Aggregate_Order_By>;
  accounts_payable_and_refunds_aggregate?: Maybe<Accounts_Payable_And_Refund_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_payable */
export type Accounts_Payable_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "accounts_payable" */
export enum Accounts_Payable_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_payable" */
export type Accounts_Payable_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts_payable" */
export enum Accounts_Payable_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Accounts - pending payment
 *
 *
 * columns and relationships of "accounts_receivable"
 */
export type Accounts_Receivable = {
  __typename?: 'accounts_receivable';
  /** An array relationship */
  accounts_receivable_and_invoices: Array<Accounts_Receivable_And_Invoice>;
  /** An aggregate relationship */
  accounts_receivable_and_invoices_aggregate: Accounts_Receivable_And_Invoice_Aggregate;
  /** An array relationship */
  accounts_receivable_and_memberships: Array<Accounts_Receivable_And_Membership>;
  /** An aggregate relationship */
  accounts_receivable_and_memberships_aggregate: Accounts_Receivable_And_Membership_Aggregate;
  /** An array relationship */
  accounts_receivable_and_subscriptions: Array<Accounts_Receivable_And_Subscription>;
  /** An aggregate relationship */
  accounts_receivable_and_subscriptions_aggregate: Accounts_Receivable_And_Subscription_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/**
 * Accounts - pending payment
 *
 *
 * columns and relationships of "accounts_receivable"
 */
export type Accounts_ReceivableAccounts_Receivable_And_InvoicesArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


/**
 * Accounts - pending payment
 *
 *
 * columns and relationships of "accounts_receivable"
 */
export type Accounts_ReceivableAccounts_Receivable_And_Invoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


/**
 * Accounts - pending payment
 *
 *
 * columns and relationships of "accounts_receivable"
 */
export type Accounts_ReceivableAccounts_Receivable_And_MembershipsArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};


/**
 * Accounts - pending payment
 *
 *
 * columns and relationships of "accounts_receivable"
 */
export type Accounts_ReceivableAccounts_Receivable_And_Memberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};


/**
 * Accounts - pending payment
 *
 *
 * columns and relationships of "accounts_receivable"
 */
export type Accounts_ReceivableAccounts_Receivable_And_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};


/**
 * Accounts - pending payment
 *
 *
 * columns and relationships of "accounts_receivable"
 */
export type Accounts_ReceivableAccounts_Receivable_And_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};

/** aggregated selection of "accounts_receivable" */
export type Accounts_Receivable_Aggregate = {
  __typename?: 'accounts_receivable_aggregate';
  aggregate?: Maybe<Accounts_Receivable_Aggregate_Fields>;
  nodes: Array<Accounts_Receivable>;
};

/** aggregate fields of "accounts_receivable" */
export type Accounts_Receivable_Aggregate_Fields = {
  __typename?: 'accounts_receivable_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Receivable_Max_Fields>;
  min?: Maybe<Accounts_Receivable_Min_Fields>;
};


/** aggregate fields of "accounts_receivable" */
export type Accounts_Receivable_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Receivable_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/**
 * Accounts with pending payments with regards to invoice
 *
 *
 * columns and relationships of "accounts_receivable_and_invoice"
 */
export type Accounts_Receivable_And_Invoice = {
  __typename?: 'accounts_receivable_and_invoice';
  /** An object relationship */
  accountsReceivableByAccountsReceivable: Accounts_Receivable;
  accounts_receivable: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice: Scalars['uuid'];
  /** An object relationship */
  invoiceByInvoice: Invoice;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Aggregate = {
  __typename?: 'accounts_receivable_and_invoice_aggregate';
  aggregate?: Maybe<Accounts_Receivable_And_Invoice_Aggregate_Fields>;
  nodes: Array<Accounts_Receivable_And_Invoice>;
};

/** aggregate fields of "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Aggregate_Fields = {
  __typename?: 'accounts_receivable_and_invoice_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Receivable_And_Invoice_Max_Fields>;
  min?: Maybe<Accounts_Receivable_And_Invoice_Min_Fields>;
};


/** aggregate fields of "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Accounts_Receivable_And_Invoice_Max_Order_By>;
  min?: Maybe<Accounts_Receivable_And_Invoice_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Receivable_And_Invoice_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Receivable_And_Invoice_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts_receivable_and_invoice". All fields are combined with a logical 'AND'. */
export type Accounts_Receivable_And_Invoice_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Receivable_And_Invoice_Bool_Exp>>;
  _not?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Receivable_And_Invoice_Bool_Exp>>;
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Bool_Exp>;
  accounts_receivable?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  invoice?: Maybe<Uuid_Comparison_Exp>;
  invoiceByInvoice?: Maybe<Invoice_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_receivable_and_invoice" */
export enum Accounts_Receivable_And_Invoice_Constraint {
  /** unique or primary key constraint */
  AccountsReceivableAndInvoicePkey = 'accounts_receivable_and_invoice_pkey'
}

/** input type for inserting data into table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Insert_Input = {
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Obj_Rel_Insert_Input>;
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  invoiceByInvoice?: Maybe<Invoice_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Receivable_And_Invoice_Max_Fields = {
  __typename?: 'accounts_receivable_and_invoice_max_fields';
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Max_Order_By = {
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Receivable_And_Invoice_Min_Fields = {
  __typename?: 'accounts_receivable_and_invoice_min_fields';
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Min_Order_By = {
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Mutation_Response = {
  __typename?: 'accounts_receivable_and_invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Receivable_And_Invoice>;
};

/** on conflict condition type for table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_On_Conflict = {
  constraint: Accounts_Receivable_And_Invoice_Constraint;
  update_columns?: Array<Accounts_Receivable_And_Invoice_Update_Column>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_receivable_and_invoice". */
export type Accounts_Receivable_And_Invoice_Order_By = {
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Order_By>;
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  invoiceByInvoice?: Maybe<Invoice_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_receivable_and_invoice */
export type Accounts_Receivable_And_Invoice_Pk_Columns_Input = {
  accounts_receivable: Scalars['uuid'];
  invoice: Scalars['uuid'];
};

/** select columns of table "accounts_receivable_and_invoice" */
export enum Accounts_Receivable_And_Invoice_Select_Column {
  /** column name */
  AccountsReceivable = 'accounts_receivable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_receivable_and_invoice" */
export type Accounts_Receivable_And_Invoice_Set_Input = {
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts_receivable_and_invoice" */
export enum Accounts_Receivable_And_Invoice_Update_Column {
  /** column name */
  AccountsReceivable = 'accounts_receivable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Accounts with pending payments with regards to membership
 *
 *
 * columns and relationships of "accounts_receivable_and_membership"
 */
export type Accounts_Receivable_And_Membership = {
  __typename?: 'accounts_receivable_and_membership';
  /** An object relationship */
  accountsReceivableByAccountsReceivable: Accounts_Receivable;
  accounts_receivable: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  membership: Scalars['uuid'];
  /** An object relationship */
  membershipByMembership: Membership;
  price?: Maybe<Scalars['numeric']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Aggregate = {
  __typename?: 'accounts_receivable_and_membership_aggregate';
  aggregate?: Maybe<Accounts_Receivable_And_Membership_Aggregate_Fields>;
  nodes: Array<Accounts_Receivable_And_Membership>;
};

/** aggregate fields of "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Aggregate_Fields = {
  __typename?: 'accounts_receivable_and_membership_aggregate_fields';
  avg?: Maybe<Accounts_Receivable_And_Membership_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Accounts_Receivable_And_Membership_Max_Fields>;
  min?: Maybe<Accounts_Receivable_And_Membership_Min_Fields>;
  stddev?: Maybe<Accounts_Receivable_And_Membership_Stddev_Fields>;
  stddev_pop?: Maybe<Accounts_Receivable_And_Membership_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Accounts_Receivable_And_Membership_Stddev_Samp_Fields>;
  sum?: Maybe<Accounts_Receivable_And_Membership_Sum_Fields>;
  var_pop?: Maybe<Accounts_Receivable_And_Membership_Var_Pop_Fields>;
  var_samp?: Maybe<Accounts_Receivable_And_Membership_Var_Samp_Fields>;
  variance?: Maybe<Accounts_Receivable_And_Membership_Variance_Fields>;
};


/** aggregate fields of "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Aggregate_Order_By = {
  avg?: Maybe<Accounts_Receivable_And_Membership_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Accounts_Receivable_And_Membership_Max_Order_By>;
  min?: Maybe<Accounts_Receivable_And_Membership_Min_Order_By>;
  stddev?: Maybe<Accounts_Receivable_And_Membership_Stddev_Order_By>;
  stddev_pop?: Maybe<Accounts_Receivable_And_Membership_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Accounts_Receivable_And_Membership_Stddev_Samp_Order_By>;
  sum?: Maybe<Accounts_Receivable_And_Membership_Sum_Order_By>;
  var_pop?: Maybe<Accounts_Receivable_And_Membership_Var_Pop_Order_By>;
  var_samp?: Maybe<Accounts_Receivable_And_Membership_Var_Samp_Order_By>;
  variance?: Maybe<Accounts_Receivable_And_Membership_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Receivable_And_Membership_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Receivable_And_Membership_On_Conflict>;
};

/** aggregate avg on columns */
export type Accounts_Receivable_And_Membership_Avg_Fields = {
  __typename?: 'accounts_receivable_and_membership_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Avg_Order_By = {
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "accounts_receivable_and_membership". All fields are combined with a logical 'AND'. */
export type Accounts_Receivable_And_Membership_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Receivable_And_Membership_Bool_Exp>>;
  _not?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Receivable_And_Membership_Bool_Exp>>;
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Bool_Exp>;
  accounts_receivable?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  membership?: Maybe<Uuid_Comparison_Exp>;
  membershipByMembership?: Maybe<Membership_Bool_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  stripe_invoice_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_receivable_and_membership" */
export enum Accounts_Receivable_And_Membership_Constraint {
  /** unique or primary key constraint */
  AccountsReceivableAndMembershipPkey = 'accounts_receivable_and_membership_pkey'
}

/** input type for incrementing numeric columns in table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Insert_Input = {
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Obj_Rel_Insert_Input>;
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  membership?: Maybe<Scalars['uuid']>;
  membershipByMembership?: Maybe<Membership_Obj_Rel_Insert_Input>;
  price?: Maybe<Scalars['numeric']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Accounts_Receivable_And_Membership_Max_Fields = {
  __typename?: 'accounts_receivable_and_membership_max_fields';
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  membership?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Max_Order_By = {
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  membership?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  stripe_invoice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Receivable_And_Membership_Min_Fields = {
  __typename?: 'accounts_receivable_and_membership_min_fields';
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  membership?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Min_Order_By = {
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  membership?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  stripe_invoice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Mutation_Response = {
  __typename?: 'accounts_receivable_and_membership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Receivable_And_Membership>;
};

/** on conflict condition type for table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_On_Conflict = {
  constraint: Accounts_Receivable_And_Membership_Constraint;
  update_columns?: Array<Accounts_Receivable_And_Membership_Update_Column>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_receivable_and_membership". */
export type Accounts_Receivable_And_Membership_Order_By = {
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Order_By>;
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  membership?: Maybe<Order_By>;
  membershipByMembership?: Maybe<Membership_Order_By>;
  price?: Maybe<Order_By>;
  stripe_invoice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_receivable_and_membership */
export type Accounts_Receivable_And_Membership_Pk_Columns_Input = {
  accounts_receivable: Scalars['uuid'];
  membership: Scalars['uuid'];
};

/** select columns of table "accounts_receivable_and_membership" */
export enum Accounts_Receivable_And_Membership_Select_Column {
  /** column name */
  AccountsReceivable = 'accounts_receivable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Membership = 'membership',
  /** column name */
  Price = 'price',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Set_Input = {
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  membership?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Accounts_Receivable_And_Membership_Stddev_Fields = {
  __typename?: 'accounts_receivable_and_membership_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Stddev_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Accounts_Receivable_And_Membership_Stddev_Pop_Fields = {
  __typename?: 'accounts_receivable_and_membership_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Accounts_Receivable_And_Membership_Stddev_Samp_Fields = {
  __typename?: 'accounts_receivable_and_membership_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Accounts_Receivable_And_Membership_Sum_Fields = {
  __typename?: 'accounts_receivable_and_membership_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Sum_Order_By = {
  price?: Maybe<Order_By>;
};

/** update columns of table "accounts_receivable_and_membership" */
export enum Accounts_Receivable_And_Membership_Update_Column {
  /** column name */
  AccountsReceivable = 'accounts_receivable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Membership = 'membership',
  /** column name */
  Price = 'price',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Accounts_Receivable_And_Membership_Var_Pop_Fields = {
  __typename?: 'accounts_receivable_and_membership_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Accounts_Receivable_And_Membership_Var_Samp_Fields = {
  __typename?: 'accounts_receivable_and_membership_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Accounts_Receivable_And_Membership_Variance_Fields = {
  __typename?: 'accounts_receivable_and_membership_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "accounts_receivable_and_membership" */
export type Accounts_Receivable_And_Membership_Variance_Order_By = {
  price?: Maybe<Order_By>;
};

/**
 * Accounts with pending payments with regards to subscription
 *
 *
 * columns and relationships of "accounts_receivable_and_subscription"
 */
export type Accounts_Receivable_And_Subscription = {
  __typename?: 'accounts_receivable_and_subscription';
  /** An object relationship */
  accountsReceivableByAccountsReceivable: Accounts_Receivable;
  accounts_receivable: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription: Scalars['uuid'];
  /** An object relationship */
  subscriptionBySubscription: Subscription;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Aggregate = {
  __typename?: 'accounts_receivable_and_subscription_aggregate';
  aggregate?: Maybe<Accounts_Receivable_And_Subscription_Aggregate_Fields>;
  nodes: Array<Accounts_Receivable_And_Subscription>;
};

/** aggregate fields of "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Aggregate_Fields = {
  __typename?: 'accounts_receivable_and_subscription_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Receivable_And_Subscription_Max_Fields>;
  min?: Maybe<Accounts_Receivable_And_Subscription_Min_Fields>;
};


/** aggregate fields of "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Accounts_Receivable_And_Subscription_Max_Order_By>;
  min?: Maybe<Accounts_Receivable_And_Subscription_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Receivable_And_Subscription_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Receivable_And_Subscription_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts_receivable_and_subscription". All fields are combined with a logical 'AND'. */
export type Accounts_Receivable_And_Subscription_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Receivable_And_Subscription_Bool_Exp>>;
  _not?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Receivable_And_Subscription_Bool_Exp>>;
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Bool_Exp>;
  accounts_receivable?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  subscription?: Maybe<Uuid_Comparison_Exp>;
  subscriptionBySubscription?: Maybe<Subscription_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_receivable_and_subscription" */
export enum Accounts_Receivable_And_Subscription_Constraint {
  /** unique or primary key constraint */
  AccountsReceivableAndSubscriptionPkey = 'accounts_receivable_and_subscription_pkey'
}

/** input type for inserting data into table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Insert_Input = {
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Obj_Rel_Insert_Input>;
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  subscriptionBySubscription?: Maybe<Subscription_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Receivable_And_Subscription_Max_Fields = {
  __typename?: 'accounts_receivable_and_subscription_max_fields';
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Max_Order_By = {
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Receivable_And_Subscription_Min_Fields = {
  __typename?: 'accounts_receivable_and_subscription_min_fields';
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Min_Order_By = {
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Mutation_Response = {
  __typename?: 'accounts_receivable_and_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Receivable_And_Subscription>;
};

/** on conflict condition type for table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_On_Conflict = {
  constraint: Accounts_Receivable_And_Subscription_Constraint;
  update_columns?: Array<Accounts_Receivable_And_Subscription_Update_Column>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_receivable_and_subscription". */
export type Accounts_Receivable_And_Subscription_Order_By = {
  accountsReceivableByAccountsReceivable?: Maybe<Accounts_Receivable_Order_By>;
  accounts_receivable?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  subscriptionBySubscription?: Maybe<Subscription_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_receivable_and_subscription */
export type Accounts_Receivable_And_Subscription_Pk_Columns_Input = {
  accounts_receivable: Scalars['uuid'];
  subscription: Scalars['uuid'];
};

/** select columns of table "accounts_receivable_and_subscription" */
export enum Accounts_Receivable_And_Subscription_Select_Column {
  /** column name */
  AccountsReceivable = 'accounts_receivable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_receivable_and_subscription" */
export type Accounts_Receivable_And_Subscription_Set_Input = {
  accounts_receivable?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts_receivable_and_subscription" */
export enum Accounts_Receivable_And_Subscription_Update_Column {
  /** column name */
  AccountsReceivable = 'accounts_receivable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Boolean expression to filter rows from the table "accounts_receivable". All fields are combined with a logical 'AND'. */
export type Accounts_Receivable_Bool_Exp = {
  _and?: Maybe<Array<Accounts_Receivable_Bool_Exp>>;
  _not?: Maybe<Accounts_Receivable_Bool_Exp>;
  _or?: Maybe<Array<Accounts_Receivable_Bool_Exp>>;
  accounts_receivable_and_invoices?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
  accounts_receivable_and_memberships?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
  accounts_receivable_and_subscriptions?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts_receivable" */
export enum Accounts_Receivable_Constraint {
  /** unique or primary key constraint */
  AccountsReceivablePkey = 'accounts_receivable_pkey'
}

/** input type for inserting data into table "accounts_receivable" */
export type Accounts_Receivable_Insert_Input = {
  accounts_receivable_and_invoices?: Maybe<Accounts_Receivable_And_Invoice_Arr_Rel_Insert_Input>;
  accounts_receivable_and_memberships?: Maybe<Accounts_Receivable_And_Membership_Arr_Rel_Insert_Input>;
  accounts_receivable_and_subscriptions?: Maybe<Accounts_Receivable_And_Subscription_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Receivable_Max_Fields = {
  __typename?: 'accounts_receivable_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Accounts_Receivable_Min_Fields = {
  __typename?: 'accounts_receivable_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "accounts_receivable" */
export type Accounts_Receivable_Mutation_Response = {
  __typename?: 'accounts_receivable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Receivable>;
};

/** input type for inserting object relation for remote table "accounts_receivable" */
export type Accounts_Receivable_Obj_Rel_Insert_Input = {
  data: Accounts_Receivable_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Accounts_Receivable_On_Conflict>;
};

/** on conflict condition type for table "accounts_receivable" */
export type Accounts_Receivable_On_Conflict = {
  constraint: Accounts_Receivable_Constraint;
  update_columns?: Array<Accounts_Receivable_Update_Column>;
  where?: Maybe<Accounts_Receivable_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts_receivable". */
export type Accounts_Receivable_Order_By = {
  accounts_receivable_and_invoices_aggregate?: Maybe<Accounts_Receivable_And_Invoice_Aggregate_Order_By>;
  accounts_receivable_and_memberships_aggregate?: Maybe<Accounts_Receivable_And_Membership_Aggregate_Order_By>;
  accounts_receivable_and_subscriptions_aggregate?: Maybe<Accounts_Receivable_And_Subscription_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts_receivable */
export type Accounts_Receivable_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "accounts_receivable" */
export enum Accounts_Receivable_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts_receivable" */
export type Accounts_Receivable_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts_receivable" */
export enum Accounts_Receivable_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "activity" */
export type Activity = {
  __typename?: 'activity';
  action: Scalars['String'];
  context: Scalars['jsonb'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  rank: Scalars['Int'];
  session: Scalars['uuid'];
  /** An object relationship */
  sessionBySession?: Maybe<Session>;
};


/** columns and relationships of "activity" */
export type ActivityContextArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "activity" */
export type Activity_Aggregate = {
  __typename?: 'activity_aggregate';
  aggregate?: Maybe<Activity_Aggregate_Fields>;
  nodes: Array<Activity>;
};

/** aggregate fields of "activity" */
export type Activity_Aggregate_Fields = {
  __typename?: 'activity_aggregate_fields';
  avg?: Maybe<Activity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Activity_Max_Fields>;
  min?: Maybe<Activity_Min_Fields>;
  stddev?: Maybe<Activity_Stddev_Fields>;
  stddev_pop?: Maybe<Activity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Activity_Stddev_Samp_Fields>;
  sum?: Maybe<Activity_Sum_Fields>;
  var_pop?: Maybe<Activity_Var_Pop_Fields>;
  var_samp?: Maybe<Activity_Var_Samp_Fields>;
  variance?: Maybe<Activity_Variance_Fields>;
};


/** aggregate fields of "activity" */
export type Activity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Activity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "activity" */
export type Activity_Aggregate_Order_By = {
  avg?: Maybe<Activity_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Activity_Max_Order_By>;
  min?: Maybe<Activity_Min_Order_By>;
  stddev?: Maybe<Activity_Stddev_Order_By>;
  stddev_pop?: Maybe<Activity_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Activity_Stddev_Samp_Order_By>;
  sum?: Maybe<Activity_Sum_Order_By>;
  var_pop?: Maybe<Activity_Var_Pop_Order_By>;
  var_samp?: Maybe<Activity_Var_Samp_Order_By>;
  variance?: Maybe<Activity_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Activity_Append_Input = {
  context?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "activity" */
export type Activity_Arr_Rel_Insert_Input = {
  data: Array<Activity_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Activity_On_Conflict>;
};

/** aggregate avg on columns */
export type Activity_Avg_Fields = {
  __typename?: 'activity_avg_fields';
  rank?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "activity" */
export type Activity_Avg_Order_By = {
  rank?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "activity". All fields are combined with a logical 'AND'. */
export type Activity_Bool_Exp = {
  _and?: Maybe<Array<Activity_Bool_Exp>>;
  _not?: Maybe<Activity_Bool_Exp>;
  _or?: Maybe<Array<Activity_Bool_Exp>>;
  action?: Maybe<String_Comparison_Exp>;
  context?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  rank?: Maybe<Int_Comparison_Exp>;
  session?: Maybe<Uuid_Comparison_Exp>;
  sessionBySession?: Maybe<Session_Bool_Exp>;
};

/** unique or primary key constraints on table "activity" */
export enum Activity_Constraint {
  /** unique or primary key constraint */
  ActivityPkey = 'activity_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Activity_Delete_At_Path_Input = {
  context?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Activity_Delete_Elem_Input = {
  context?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Activity_Delete_Key_Input = {
  context?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "activity" */
export type Activity_Inc_Input = {
  rank?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "activity" */
export type Activity_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['Int']>;
  session?: Maybe<Scalars['uuid']>;
  sessionBySession?: Maybe<Session_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Activity_Max_Fields = {
  __typename?: 'activity_max_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['Int']>;
  session?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "activity" */
export type Activity_Max_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Activity_Min_Fields = {
  __typename?: 'activity_min_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['Int']>;
  session?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "activity" */
export type Activity_Min_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
};

/** response of any mutation on the table "activity" */
export type Activity_Mutation_Response = {
  __typename?: 'activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Activity>;
};

/** on conflict condition type for table "activity" */
export type Activity_On_Conflict = {
  constraint: Activity_Constraint;
  update_columns?: Array<Activity_Update_Column>;
  where?: Maybe<Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "activity". */
export type Activity_Order_By = {
  action?: Maybe<Order_By>;
  context?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  sessionBySession?: Maybe<Session_Order_By>;
};

/** primary key columns input for table: activity */
export type Activity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Activity_Prepend_Input = {
  context?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "activity" */
export enum Activity_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rank = 'rank',
  /** column name */
  Session = 'session'
}

/** input type for updating data in table "activity" */
export type Activity_Set_Input = {
  action?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['Int']>;
  session?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Activity_Stddev_Fields = {
  __typename?: 'activity_stddev_fields';
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "activity" */
export type Activity_Stddev_Order_By = {
  rank?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Activity_Stddev_Pop_Fields = {
  __typename?: 'activity_stddev_pop_fields';
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "activity" */
export type Activity_Stddev_Pop_Order_By = {
  rank?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Activity_Stddev_Samp_Fields = {
  __typename?: 'activity_stddev_samp_fields';
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "activity" */
export type Activity_Stddev_Samp_Order_By = {
  rank?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Activity_Sum_Fields = {
  __typename?: 'activity_sum_fields';
  rank?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "activity" */
export type Activity_Sum_Order_By = {
  rank?: Maybe<Order_By>;
};

/** update columns of table "activity" */
export enum Activity_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rank = 'rank',
  /** column name */
  Session = 'session'
}

/** aggregate var_pop on columns */
export type Activity_Var_Pop_Fields = {
  __typename?: 'activity_var_pop_fields';
  rank?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "activity" */
export type Activity_Var_Pop_Order_By = {
  rank?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Activity_Var_Samp_Fields = {
  __typename?: 'activity_var_samp_fields';
  rank?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "activity" */
export type Activity_Var_Samp_Order_By = {
  rank?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Activity_Variance_Fields = {
  __typename?: 'activity_variance_fields';
  rank?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "activity" */
export type Activity_Variance_Order_By = {
  rank?: Maybe<Order_By>;
};

/**
 * Sido id and name
 *
 *
 * columns and relationships of "address_sido"
 */
export type Address_Sido = {
  __typename?: 'address_sido';
  /** An array relationship */
  address_sigungus: Array<Address_Sigungu>;
  /** An aggregate relationship */
  address_sigungus_aggregate: Address_Sigungu_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  leads: Array<Lead>;
  /** An aggregate relationship */
  leads_aggregate: Lead_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/**
 * Sido id and name
 *
 *
 * columns and relationships of "address_sido"
 */
export type Address_SidoAddress_SigungusArgs = {
  distinct_on?: Maybe<Array<Address_Sigungu_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sigungu_Order_By>>;
  where?: Maybe<Address_Sigungu_Bool_Exp>;
};


/**
 * Sido id and name
 *
 *
 * columns and relationships of "address_sido"
 */
export type Address_SidoAddress_Sigungus_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Sigungu_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sigungu_Order_By>>;
  where?: Maybe<Address_Sigungu_Bool_Exp>;
};


/**
 * Sido id and name
 *
 *
 * columns and relationships of "address_sido"
 */
export type Address_SidoLeadsArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Sido id and name
 *
 *
 * columns and relationships of "address_sido"
 */
export type Address_SidoLeads_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Sido id and name
 *
 *
 * columns and relationships of "address_sido"
 */
export type Address_SidoSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Sido id and name
 *
 *
 * columns and relationships of "address_sido"
 */
export type Address_SidoSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};

/** aggregated selection of "address_sido" */
export type Address_Sido_Aggregate = {
  __typename?: 'address_sido_aggregate';
  aggregate?: Maybe<Address_Sido_Aggregate_Fields>;
  nodes: Array<Address_Sido>;
};

/** aggregate fields of "address_sido" */
export type Address_Sido_Aggregate_Fields = {
  __typename?: 'address_sido_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Sido_Max_Fields>;
  min?: Maybe<Address_Sido_Min_Fields>;
};


/** aggregate fields of "address_sido" */
export type Address_Sido_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Address_Sido_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "address_sido". All fields are combined with a logical 'AND'. */
export type Address_Sido_Bool_Exp = {
  _and?: Maybe<Array<Address_Sido_Bool_Exp>>;
  _not?: Maybe<Address_Sido_Bool_Exp>;
  _or?: Maybe<Array<Address_Sido_Bool_Exp>>;
  address_sigungus?: Maybe<Address_Sigungu_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  leads?: Maybe<Lead_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "address_sido" */
export enum Address_Sido_Constraint {
  /** unique or primary key constraint */
  AddressKoreaSidoPkey = 'address_korea_sido_pkey'
}

/** input type for inserting data into table "address_sido" */
export type Address_Sido_Insert_Input = {
  address_sigungus?: Maybe<Address_Sigungu_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leads?: Maybe<Lead_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Address_Sido_Max_Fields = {
  __typename?: 'address_sido_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Address_Sido_Min_Fields = {
  __typename?: 'address_sido_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "address_sido" */
export type Address_Sido_Mutation_Response = {
  __typename?: 'address_sido_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address_Sido>;
};

/** input type for inserting object relation for remote table "address_sido" */
export type Address_Sido_Obj_Rel_Insert_Input = {
  data: Address_Sido_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Address_Sido_On_Conflict>;
};

/** on conflict condition type for table "address_sido" */
export type Address_Sido_On_Conflict = {
  constraint: Address_Sido_Constraint;
  update_columns?: Array<Address_Sido_Update_Column>;
  where?: Maybe<Address_Sido_Bool_Exp>;
};

/** Ordering options when selecting data from "address_sido". */
export type Address_Sido_Order_By = {
  address_sigungus_aggregate?: Maybe<Address_Sigungu_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  leads_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: address_sido */
export type Address_Sido_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "address_sido" */
export enum Address_Sido_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "address_sido" */
export type Address_Sido_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "address_sido" */
export enum Address_Sido_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Sigungu id and name with sido id
 *
 *
 * columns and relationships of "address_sigungu"
 */
export type Address_Sigungu = {
  __typename?: 'address_sigungu';
  /** An object relationship */
  address_sido: Address_Sido;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  leads: Array<Lead>;
  /** An aggregate relationship */
  leads_aggregate: Lead_Aggregate;
  name: Scalars['String'];
  sido: Scalars['uuid'];
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  /** An array relationship */
  stores: Array<Store>;
  /** An aggregate relationship */
  stores_aggregate: Store_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/**
 * Sigungu id and name with sido id
 *
 *
 * columns and relationships of "address_sigungu"
 */
export type Address_SigunguLeadsArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Sigungu id and name with sido id
 *
 *
 * columns and relationships of "address_sigungu"
 */
export type Address_SigunguLeads_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/**
 * Sigungu id and name with sido id
 *
 *
 * columns and relationships of "address_sigungu"
 */
export type Address_SigunguSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Sigungu id and name with sido id
 *
 *
 * columns and relationships of "address_sigungu"
 */
export type Address_SigunguSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Sigungu id and name with sido id
 *
 *
 * columns and relationships of "address_sigungu"
 */
export type Address_SigunguStoresArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};


/**
 * Sigungu id and name with sido id
 *
 *
 * columns and relationships of "address_sigungu"
 */
export type Address_SigunguStores_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};

/** aggregated selection of "address_sigungu" */
export type Address_Sigungu_Aggregate = {
  __typename?: 'address_sigungu_aggregate';
  aggregate?: Maybe<Address_Sigungu_Aggregate_Fields>;
  nodes: Array<Address_Sigungu>;
};

/** aggregate fields of "address_sigungu" */
export type Address_Sigungu_Aggregate_Fields = {
  __typename?: 'address_sigungu_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Sigungu_Max_Fields>;
  min?: Maybe<Address_Sigungu_Min_Fields>;
};


/** aggregate fields of "address_sigungu" */
export type Address_Sigungu_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Address_Sigungu_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "address_sigungu" */
export type Address_Sigungu_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Address_Sigungu_Max_Order_By>;
  min?: Maybe<Address_Sigungu_Min_Order_By>;
};

/** input type for inserting array relation for remote table "address_sigungu" */
export type Address_Sigungu_Arr_Rel_Insert_Input = {
  data: Array<Address_Sigungu_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Address_Sigungu_On_Conflict>;
};

/** Boolean expression to filter rows from the table "address_sigungu". All fields are combined with a logical 'AND'. */
export type Address_Sigungu_Bool_Exp = {
  _and?: Maybe<Array<Address_Sigungu_Bool_Exp>>;
  _not?: Maybe<Address_Sigungu_Bool_Exp>;
  _or?: Maybe<Array<Address_Sigungu_Bool_Exp>>;
  address_sido?: Maybe<Address_Sido_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  leads?: Maybe<Lead_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sido?: Maybe<Uuid_Comparison_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  stores?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "address_sigungu" */
export enum Address_Sigungu_Constraint {
  /** unique or primary key constraint */
  AddressKoreaSigunguPkey = 'address_korea_sigungu_pkey'
}

/** input type for inserting data into table "address_sigungu" */
export type Address_Sigungu_Insert_Input = {
  address_sido?: Maybe<Address_Sido_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leads?: Maybe<Lead_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  sido?: Maybe<Scalars['uuid']>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  stores?: Maybe<Store_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Address_Sigungu_Max_Fields = {
  __typename?: 'address_sigungu_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sido?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "address_sigungu" */
export type Address_Sigungu_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sido?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Sigungu_Min_Fields = {
  __typename?: 'address_sigungu_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sido?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "address_sigungu" */
export type Address_Sigungu_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sido?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "address_sigungu" */
export type Address_Sigungu_Mutation_Response = {
  __typename?: 'address_sigungu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address_Sigungu>;
};

/** input type for inserting object relation for remote table "address_sigungu" */
export type Address_Sigungu_Obj_Rel_Insert_Input = {
  data: Address_Sigungu_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Address_Sigungu_On_Conflict>;
};

/** on conflict condition type for table "address_sigungu" */
export type Address_Sigungu_On_Conflict = {
  constraint: Address_Sigungu_Constraint;
  update_columns?: Array<Address_Sigungu_Update_Column>;
  where?: Maybe<Address_Sigungu_Bool_Exp>;
};

/** Ordering options when selecting data from "address_sigungu". */
export type Address_Sigungu_Order_By = {
  address_sido?: Maybe<Address_Sido_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  leads_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  sido?: Maybe<Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  stores_aggregate?: Maybe<Store_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: address_sigungu */
export type Address_Sigungu_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "address_sigungu" */
export enum Address_Sigungu_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sido = 'sido',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "address_sigungu" */
export type Address_Sigungu_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sido?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "address_sigungu" */
export enum Address_Sigungu_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sido = 'sido',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Amount to be adjusted to the store
 *
 *
 * columns and relationships of "adjustment"
 */
export type Adjustment = {
  __typename?: 'adjustment';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  accounts_payable_and_adjustments: Array<Accounts_Payable_And_Adjustment>;
  /** An aggregate relationship */
  accounts_payable_and_adjustments_aggregate: Accounts_Payable_And_Adjustment_Aggregate;
  /** An array relationship */
  adjustment_and_transfers: Array<Adjustment_And_Transfer>;
  /** An aggregate relationship */
  adjustment_and_transfers_aggregate: Adjustment_And_Transfer_Aggregate;
  /** An object relationship */
  adjustment_status: Adjustment_Status;
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  order: Scalars['uuid'];
  /** An object relationship */
  orderByOrder: Order;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  status: Adjustment_Status_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  tax_invoice_issued?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  written_off_at?: Maybe<Scalars['timestamptz']>;
};


/**
 * Amount to be adjusted to the store
 *
 *
 * columns and relationships of "adjustment"
 */
export type AdjustmentAccounts_Payable_And_AdjustmentsArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


/**
 * Amount to be adjusted to the store
 *
 *
 * columns and relationships of "adjustment"
 */
export type AdjustmentAccounts_Payable_And_Adjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


/**
 * Amount to be adjusted to the store
 *
 *
 * columns and relationships of "adjustment"
 */
export type AdjustmentAdjustment_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};


/**
 * Amount to be adjusted to the store
 *
 *
 * columns and relationships of "adjustment"
 */
export type AdjustmentAdjustment_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};

/** aggregated selection of "adjustment" */
export type Adjustment_Aggregate = {
  __typename?: 'adjustment_aggregate';
  aggregate?: Maybe<Adjustment_Aggregate_Fields>;
  nodes: Array<Adjustment>;
};

/** aggregate fields of "adjustment" */
export type Adjustment_Aggregate_Fields = {
  __typename?: 'adjustment_aggregate_fields';
  avg?: Maybe<Adjustment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Adjustment_Max_Fields>;
  min?: Maybe<Adjustment_Min_Fields>;
  stddev?: Maybe<Adjustment_Stddev_Fields>;
  stddev_pop?: Maybe<Adjustment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Adjustment_Stddev_Samp_Fields>;
  sum?: Maybe<Adjustment_Sum_Fields>;
  var_pop?: Maybe<Adjustment_Var_Pop_Fields>;
  var_samp?: Maybe<Adjustment_Var_Samp_Fields>;
  variance?: Maybe<Adjustment_Variance_Fields>;
};


/** aggregate fields of "adjustment" */
export type Adjustment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Adjustment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "adjustment" */
export type Adjustment_Aggregate_Order_By = {
  avg?: Maybe<Adjustment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Adjustment_Max_Order_By>;
  min?: Maybe<Adjustment_Min_Order_By>;
  stddev?: Maybe<Adjustment_Stddev_Order_By>;
  stddev_pop?: Maybe<Adjustment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Adjustment_Stddev_Samp_Order_By>;
  sum?: Maybe<Adjustment_Sum_Order_By>;
  var_pop?: Maybe<Adjustment_Var_Pop_Order_By>;
  var_samp?: Maybe<Adjustment_Var_Samp_Order_By>;
  variance?: Maybe<Adjustment_Variance_Order_By>;
};

/** columns and relationships of "adjustment_and_transfer" */
export type Adjustment_And_Transfer = {
  __typename?: 'adjustment_and_transfer';
  adjustment: Scalars['uuid'];
  /** An object relationship */
  adjustmentByAdjustment: Adjustment;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  transfer: Scalars['uuid'];
  /** An object relationship */
  transferByTransfer: Transfer;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Aggregate = {
  __typename?: 'adjustment_and_transfer_aggregate';
  aggregate?: Maybe<Adjustment_And_Transfer_Aggregate_Fields>;
  nodes: Array<Adjustment_And_Transfer>;
};

/** aggregate fields of "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Aggregate_Fields = {
  __typename?: 'adjustment_and_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Adjustment_And_Transfer_Max_Fields>;
  min?: Maybe<Adjustment_And_Transfer_Min_Fields>;
};


/** aggregate fields of "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Adjustment_And_Transfer_Max_Order_By>;
  min?: Maybe<Adjustment_And_Transfer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Arr_Rel_Insert_Input = {
  data: Array<Adjustment_And_Transfer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Adjustment_And_Transfer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "adjustment_and_transfer". All fields are combined with a logical 'AND'. */
export type Adjustment_And_Transfer_Bool_Exp = {
  _and?: Maybe<Array<Adjustment_And_Transfer_Bool_Exp>>;
  _not?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
  _or?: Maybe<Array<Adjustment_And_Transfer_Bool_Exp>>;
  adjustment?: Maybe<Uuid_Comparison_Exp>;
  adjustmentByAdjustment?: Maybe<Adjustment_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  transfer?: Maybe<Uuid_Comparison_Exp>;
  transferByTransfer?: Maybe<Transfer_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "adjustment_and_transfer" */
export enum Adjustment_And_Transfer_Constraint {
  /** unique or primary key constraint */
  AdjustmentAndTransferPkey = 'adjustment_and_transfer_pkey'
}

/** input type for inserting data into table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Insert_Input = {
  adjustment?: Maybe<Scalars['uuid']>;
  adjustmentByAdjustment?: Maybe<Adjustment_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  transfer?: Maybe<Scalars['uuid']>;
  transferByTransfer?: Maybe<Transfer_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Adjustment_And_Transfer_Max_Fields = {
  __typename?: 'adjustment_and_transfer_max_fields';
  adjustment?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Max_Order_By = {
  adjustment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Adjustment_And_Transfer_Min_Fields = {
  __typename?: 'adjustment_and_transfer_min_fields';
  adjustment?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Min_Order_By = {
  adjustment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Mutation_Response = {
  __typename?: 'adjustment_and_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Adjustment_And_Transfer>;
};

/** on conflict condition type for table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_On_Conflict = {
  constraint: Adjustment_And_Transfer_Constraint;
  update_columns?: Array<Adjustment_And_Transfer_Update_Column>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "adjustment_and_transfer". */
export type Adjustment_And_Transfer_Order_By = {
  adjustment?: Maybe<Order_By>;
  adjustmentByAdjustment?: Maybe<Adjustment_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  transferByTransfer?: Maybe<Transfer_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: adjustment_and_transfer */
export type Adjustment_And_Transfer_Pk_Columns_Input = {
  adjustment: Scalars['uuid'];
  transfer: Scalars['uuid'];
};

/** select columns of table "adjustment_and_transfer" */
export enum Adjustment_And_Transfer_Select_Column {
  /** column name */
  Adjustment = 'adjustment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "adjustment_and_transfer" */
export type Adjustment_And_Transfer_Set_Input = {
  adjustment?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "adjustment_and_transfer" */
export enum Adjustment_And_Transfer_Update_Column {
  /** column name */
  Adjustment = 'adjustment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting array relation for remote table "adjustment" */
export type Adjustment_Arr_Rel_Insert_Input = {
  data: Array<Adjustment_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Adjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type Adjustment_Avg_Fields = {
  __typename?: 'adjustment_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "adjustment" */
export type Adjustment_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "adjustment". All fields are combined with a logical 'AND'. */
export type Adjustment_Bool_Exp = {
  _and?: Maybe<Array<Adjustment_Bool_Exp>>;
  _not?: Maybe<Adjustment_Bool_Exp>;
  _or?: Maybe<Array<Adjustment_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  accounts_payable_and_adjustments?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
  adjustment_and_transfers?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
  adjustment_status?: Maybe<Adjustment_Status_Bool_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Uuid_Comparison_Exp>;
  orderByOrder?: Maybe<Order_Bool_Exp>;
  reverted_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Adjustment_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  tax_invoice_issued?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  written_off_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "adjustment" */
export enum Adjustment_Constraint {
  /** unique or primary key constraint */
  AdjustmentOrderKey = 'adjustment_order_key',
  /** unique or primary key constraint */
  AdjustmentPkey = 'adjustment_pkey'
}

/** input type for incrementing numeric columns in table "adjustment" */
export type Adjustment_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "adjustment" */
export type Adjustment_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  accounts_payable_and_adjustments?: Maybe<Accounts_Payable_And_Adjustment_Arr_Rel_Insert_Input>;
  adjustment_and_transfers?: Maybe<Adjustment_And_Transfer_Arr_Rel_Insert_Input>;
  adjustment_status?: Maybe<Adjustment_Status_Obj_Rel_Insert_Input>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  orderByOrder?: Maybe<Order_Obj_Rel_Insert_Input>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Adjustment_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  tax_invoice_issued?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Adjustment_Max_Fields = {
  __typename?: 'adjustment_max_fields';
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  tax_invoice_issued?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "adjustment" */
export type Adjustment_Max_Order_By = {
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  reverted_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  tax_invoice_issued?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  written_off_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Adjustment_Min_Fields = {
  __typename?: 'adjustment_min_fields';
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  tax_invoice_issued?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "adjustment" */
export type Adjustment_Min_Order_By = {
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  reverted_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  tax_invoice_issued?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  written_off_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "adjustment" */
export type Adjustment_Mutation_Response = {
  __typename?: 'adjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Adjustment>;
};

/** input type for inserting object relation for remote table "adjustment" */
export type Adjustment_Obj_Rel_Insert_Input = {
  data: Adjustment_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Adjustment_On_Conflict>;
};

/** on conflict condition type for table "adjustment" */
export type Adjustment_On_Conflict = {
  constraint: Adjustment_Constraint;
  update_columns?: Array<Adjustment_Update_Column>;
  where?: Maybe<Adjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "adjustment". */
export type Adjustment_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  accounts_payable_and_adjustments_aggregate?: Maybe<Accounts_Payable_And_Adjustment_Aggregate_Order_By>;
  adjustment_and_transfers_aggregate?: Maybe<Adjustment_And_Transfer_Aggregate_Order_By>;
  adjustment_status?: Maybe<Adjustment_Status_Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  orderByOrder?: Maybe<Order_Order_By>;
  reverted_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  tax_invoice_issued?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  written_off_at?: Maybe<Order_By>;
};

/** primary key columns input for table: adjustment */
export type Adjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "adjustment" */
export enum Adjustment_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RevertedAt = 'reverted_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  TaxInvoiceIssued = 'tax_invoice_issued',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WrittenOffAt = 'written_off_at'
}

/** input type for updating data in table "adjustment" */
export type Adjustment_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Adjustment_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  tax_invoice_issued?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "adjustment_status" */
export type Adjustment_Status = {
  __typename?: 'adjustment_status';
  /** An array relationship */
  adjustments: Array<Adjustment>;
  /** An aggregate relationship */
  adjustments_aggregate: Adjustment_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "adjustment_status" */
export type Adjustment_StatusAdjustmentsArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


/** columns and relationships of "adjustment_status" */
export type Adjustment_StatusAdjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};

/** aggregated selection of "adjustment_status" */
export type Adjustment_Status_Aggregate = {
  __typename?: 'adjustment_status_aggregate';
  aggregate?: Maybe<Adjustment_Status_Aggregate_Fields>;
  nodes: Array<Adjustment_Status>;
};

/** aggregate fields of "adjustment_status" */
export type Adjustment_Status_Aggregate_Fields = {
  __typename?: 'adjustment_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Adjustment_Status_Max_Fields>;
  min?: Maybe<Adjustment_Status_Min_Fields>;
};


/** aggregate fields of "adjustment_status" */
export type Adjustment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Adjustment_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "adjustment_status". All fields are combined with a logical 'AND'. */
export type Adjustment_Status_Bool_Exp = {
  _and?: Maybe<Array<Adjustment_Status_Bool_Exp>>;
  _not?: Maybe<Adjustment_Status_Bool_Exp>;
  _or?: Maybe<Array<Adjustment_Status_Bool_Exp>>;
  adjustments?: Maybe<Adjustment_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "adjustment_status" */
export enum Adjustment_Status_Constraint {
  /** unique or primary key constraint */
  AdjustmentStatusPkey = 'adjustment_status_pkey'
}

export enum Adjustment_Status_Enum {
  /** 조정건 발생 */
  Created = 'created',
  /** 정산 완료 */
  Finished = 'finished',
  /** 정산 취소 */
  Reverted = 'reverted',
  /** 세금계산서 발급 완료 */
  TaxInvoiceIssued = 'tax_invoice_issued',
  /** 부도 */
  WrittenOff = 'written_off'
}

/** Boolean expression to compare columns of type "adjustment_status_enum". All fields are combined with logical 'AND'. */
export type Adjustment_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Adjustment_Status_Enum>;
  _in?: Maybe<Array<Adjustment_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Adjustment_Status_Enum>;
  _nin?: Maybe<Array<Adjustment_Status_Enum>>;
};

/** input type for inserting data into table "adjustment_status" */
export type Adjustment_Status_Insert_Input = {
  adjustments?: Maybe<Adjustment_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Adjustment_Status_Max_Fields = {
  __typename?: 'adjustment_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Adjustment_Status_Min_Fields = {
  __typename?: 'adjustment_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "adjustment_status" */
export type Adjustment_Status_Mutation_Response = {
  __typename?: 'adjustment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Adjustment_Status>;
};

/** input type for inserting object relation for remote table "adjustment_status" */
export type Adjustment_Status_Obj_Rel_Insert_Input = {
  data: Adjustment_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Adjustment_Status_On_Conflict>;
};

/** on conflict condition type for table "adjustment_status" */
export type Adjustment_Status_On_Conflict = {
  constraint: Adjustment_Status_Constraint;
  update_columns?: Array<Adjustment_Status_Update_Column>;
  where?: Maybe<Adjustment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "adjustment_status". */
export type Adjustment_Status_Order_By = {
  adjustments_aggregate?: Maybe<Adjustment_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: adjustment_status */
export type Adjustment_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "adjustment_status" */
export enum Adjustment_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "adjustment_status" */
export type Adjustment_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "adjustment_status" */
export enum Adjustment_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Adjustment_Stddev_Fields = {
  __typename?: 'adjustment_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "adjustment" */
export type Adjustment_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Adjustment_Stddev_Pop_Fields = {
  __typename?: 'adjustment_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "adjustment" */
export type Adjustment_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Adjustment_Stddev_Samp_Fields = {
  __typename?: 'adjustment_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "adjustment" */
export type Adjustment_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Adjustment_Sum_Fields = {
  __typename?: 'adjustment_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "adjustment" */
export type Adjustment_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "adjustment" */
export enum Adjustment_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RevertedAt = 'reverted_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  TaxInvoiceIssued = 'tax_invoice_issued',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WrittenOffAt = 'written_off_at'
}

/** aggregate var_pop on columns */
export type Adjustment_Var_Pop_Fields = {
  __typename?: 'adjustment_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "adjustment" */
export type Adjustment_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Adjustment_Var_Samp_Fields = {
  __typename?: 'adjustment_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "adjustment" */
export type Adjustment_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Adjustment_Variance_Fields = {
  __typename?: 'adjustment_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "adjustment" */
export type Adjustment_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_Post = {
  __typename?: 'blog_post';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  blog_post_and_hashtags: Array<Blog_Post_And_Hashtag>;
  /** An aggregate relationship */
  blog_post_and_hashtags_aggregate: Blog_Post_And_Hashtag_Aggregate;
  /** An array relationship */
  blog_post_and_views: Array<Blog_Post_And_View>;
  /** An aggregate relationship */
  blog_post_and_views_aggregate: Blog_Post_And_View_Aggregate;
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  galleries: Array<Gallery>;
  /** An aggregate relationship */
  galleries_aggregate: Gallery_Aggregate;
  /** An object relationship */
  gallery?: Maybe<Gallery>;
  id: Scalars['uuid'];
  image: Scalars['jsonb'];
  posting?: Maybe<Scalars['jsonb']>;
  store?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  storeByStore?: Maybe<Store>;
  thumbnail?: Maybe<Scalars['jsonb']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostBlog_Post_And_HashtagsArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostBlog_Post_And_Hashtags_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostBlog_Post_And_ViewsArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostBlog_Post_And_Views_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostGalleriesArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostGalleries_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostImageArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostPostingArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Blog post contents - Relationship with gallery
 *
 *
 * columns and relationships of "blog_post"
 */
export type Blog_PostThumbnailArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "blog_post" */
export type Blog_Post_Aggregate = {
  __typename?: 'blog_post_aggregate';
  aggregate?: Maybe<Blog_Post_Aggregate_Fields>;
  nodes: Array<Blog_Post>;
};

/** aggregate fields of "blog_post" */
export type Blog_Post_Aggregate_Fields = {
  __typename?: 'blog_post_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blog_Post_Max_Fields>;
  min?: Maybe<Blog_Post_Min_Fields>;
};


/** aggregate fields of "blog_post" */
export type Blog_Post_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blog_Post_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog_post" */
export type Blog_Post_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Blog_Post_Max_Order_By>;
  min?: Maybe<Blog_Post_Min_Order_By>;
};

/**
 * Hashtag of blog post - Relationship with blog_post, hashtag
 *
 *
 * columns and relationships of "blog_post_and_hashtag"
 */
export type Blog_Post_And_Hashtag = {
  __typename?: 'blog_post_and_hashtag';
  /** An object relationship */
  blogPostByBlogPost: Blog_Post;
  blog_post: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag: Scalars['uuid'];
  /** An object relationship */
  hashtagByHashtag: Hashtag;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Aggregate = {
  __typename?: 'blog_post_and_hashtag_aggregate';
  aggregate?: Maybe<Blog_Post_And_Hashtag_Aggregate_Fields>;
  nodes: Array<Blog_Post_And_Hashtag>;
};

/** aggregate fields of "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Aggregate_Fields = {
  __typename?: 'blog_post_and_hashtag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blog_Post_And_Hashtag_Max_Fields>;
  min?: Maybe<Blog_Post_And_Hashtag_Min_Fields>;
};


/** aggregate fields of "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Blog_Post_And_Hashtag_Max_Order_By>;
  min?: Maybe<Blog_Post_And_Hashtag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Arr_Rel_Insert_Input = {
  data: Array<Blog_Post_And_Hashtag_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Blog_Post_And_Hashtag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "blog_post_and_hashtag". All fields are combined with a logical 'AND'. */
export type Blog_Post_And_Hashtag_Bool_Exp = {
  _and?: Maybe<Array<Blog_Post_And_Hashtag_Bool_Exp>>;
  _not?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
  _or?: Maybe<Array<Blog_Post_And_Hashtag_Bool_Exp>>;
  blogPostByBlogPost?: Maybe<Blog_Post_Bool_Exp>;
  blog_post?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  hashtag?: Maybe<Uuid_Comparison_Exp>;
  hashtagByHashtag?: Maybe<Hashtag_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "blog_post_and_hashtag" */
export enum Blog_Post_And_Hashtag_Constraint {
  /** unique or primary key constraint */
  BlogPostHashtagPkey = 'blog_post_hashtag_pkey'
}

/** input type for inserting data into table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Insert_Input = {
  blogPostByBlogPost?: Maybe<Blog_Post_Obj_Rel_Insert_Input>;
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  hashtagByHashtag?: Maybe<Hashtag_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Blog_Post_And_Hashtag_Max_Fields = {
  __typename?: 'blog_post_and_hashtag_max_fields';
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Max_Order_By = {
  blog_post?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  hashtag?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Post_And_Hashtag_Min_Fields = {
  __typename?: 'blog_post_and_hashtag_min_fields';
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Min_Order_By = {
  blog_post?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  hashtag?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Mutation_Response = {
  __typename?: 'blog_post_and_hashtag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog_Post_And_Hashtag>;
};

/** on conflict condition type for table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_On_Conflict = {
  constraint: Blog_Post_And_Hashtag_Constraint;
  update_columns?: Array<Blog_Post_And_Hashtag_Update_Column>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};

/** Ordering options when selecting data from "blog_post_and_hashtag". */
export type Blog_Post_And_Hashtag_Order_By = {
  blogPostByBlogPost?: Maybe<Blog_Post_Order_By>;
  blog_post?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  hashtag?: Maybe<Order_By>;
  hashtagByHashtag?: Maybe<Hashtag_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: blog_post_and_hashtag */
export type Blog_Post_And_Hashtag_Pk_Columns_Input = {
  blog_post: Scalars['uuid'];
  hashtag: Scalars['uuid'];
};

/** select columns of table "blog_post_and_hashtag" */
export enum Blog_Post_And_Hashtag_Select_Column {
  /** column name */
  BlogPost = 'blog_post',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Hashtag = 'hashtag',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "blog_post_and_hashtag" */
export type Blog_Post_And_Hashtag_Set_Input = {
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "blog_post_and_hashtag" */
export enum Blog_Post_And_Hashtag_Update_Column {
  /** column name */
  BlogPost = 'blog_post',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Hashtag = 'hashtag',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * View of blog post - Relationship with blog_post, view
 *
 *
 * columns and relationships of "blog_post_and_view"
 */
export type Blog_Post_And_View = {
  __typename?: 'blog_post_and_view';
  /** An object relationship */
  blogPostByBlogPost: Blog_Post;
  blog_post: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  view: Scalars['uuid'];
  /** An object relationship */
  viewByView: View;
};

/** aggregated selection of "blog_post_and_view" */
export type Blog_Post_And_View_Aggregate = {
  __typename?: 'blog_post_and_view_aggregate';
  aggregate?: Maybe<Blog_Post_And_View_Aggregate_Fields>;
  nodes: Array<Blog_Post_And_View>;
};

/** aggregate fields of "blog_post_and_view" */
export type Blog_Post_And_View_Aggregate_Fields = {
  __typename?: 'blog_post_and_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blog_Post_And_View_Max_Fields>;
  min?: Maybe<Blog_Post_And_View_Min_Fields>;
};


/** aggregate fields of "blog_post_and_view" */
export type Blog_Post_And_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog_post_and_view" */
export type Blog_Post_And_View_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Blog_Post_And_View_Max_Order_By>;
  min?: Maybe<Blog_Post_And_View_Min_Order_By>;
};

/** input type for inserting array relation for remote table "blog_post_and_view" */
export type Blog_Post_And_View_Arr_Rel_Insert_Input = {
  data: Array<Blog_Post_And_View_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Blog_Post_And_View_On_Conflict>;
};

/** Boolean expression to filter rows from the table "blog_post_and_view". All fields are combined with a logical 'AND'. */
export type Blog_Post_And_View_Bool_Exp = {
  _and?: Maybe<Array<Blog_Post_And_View_Bool_Exp>>;
  _not?: Maybe<Blog_Post_And_View_Bool_Exp>;
  _or?: Maybe<Array<Blog_Post_And_View_Bool_Exp>>;
  blogPostByBlogPost?: Maybe<Blog_Post_Bool_Exp>;
  blog_post?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  view?: Maybe<Uuid_Comparison_Exp>;
  viewByView?: Maybe<View_Bool_Exp>;
};

/** unique or primary key constraints on table "blog_post_and_view" */
export enum Blog_Post_And_View_Constraint {
  /** unique or primary key constraint */
  BlogPostViewPkey = 'blog_post_view_pkey'
}

/** input type for inserting data into table "blog_post_and_view" */
export type Blog_Post_And_View_Insert_Input = {
  blogPostByBlogPost?: Maybe<Blog_Post_Obj_Rel_Insert_Input>;
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view?: Maybe<Scalars['uuid']>;
  viewByView?: Maybe<View_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Blog_Post_And_View_Max_Fields = {
  __typename?: 'blog_post_and_view_max_fields';
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "blog_post_and_view" */
export type Blog_Post_And_View_Max_Order_By = {
  blog_post?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  view?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Post_And_View_Min_Fields = {
  __typename?: 'blog_post_and_view_min_fields';
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "blog_post_and_view" */
export type Blog_Post_And_View_Min_Order_By = {
  blog_post?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  view?: Maybe<Order_By>;
};

/** response of any mutation on the table "blog_post_and_view" */
export type Blog_Post_And_View_Mutation_Response = {
  __typename?: 'blog_post_and_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog_Post_And_View>;
};

/** on conflict condition type for table "blog_post_and_view" */
export type Blog_Post_And_View_On_Conflict = {
  constraint: Blog_Post_And_View_Constraint;
  update_columns?: Array<Blog_Post_And_View_Update_Column>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};

/** Ordering options when selecting data from "blog_post_and_view". */
export type Blog_Post_And_View_Order_By = {
  blogPostByBlogPost?: Maybe<Blog_Post_Order_By>;
  blog_post?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  view?: Maybe<Order_By>;
  viewByView?: Maybe<View_Order_By>;
};

/** primary key columns input for table: blog_post_and_view */
export type Blog_Post_And_View_Pk_Columns_Input = {
  blog_post: Scalars['uuid'];
  view: Scalars['uuid'];
};

/** select columns of table "blog_post_and_view" */
export enum Blog_Post_And_View_Select_Column {
  /** column name */
  BlogPost = 'blog_post',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  View = 'view'
}

/** input type for updating data in table "blog_post_and_view" */
export type Blog_Post_And_View_Set_Input = {
  blog_post?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view?: Maybe<Scalars['uuid']>;
};

/** update columns of table "blog_post_and_view" */
export enum Blog_Post_And_View_Update_Column {
  /** column name */
  BlogPost = 'blog_post',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  View = 'view'
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Blog_Post_Append_Input = {
  image?: Maybe<Scalars['jsonb']>;
  posting?: Maybe<Scalars['jsonb']>;
  thumbnail?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "blog_post" */
export type Blog_Post_Arr_Rel_Insert_Input = {
  data: Array<Blog_Post_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Blog_Post_On_Conflict>;
};

/** Boolean expression to filter rows from the table "blog_post". All fields are combined with a logical 'AND'. */
export type Blog_Post_Bool_Exp = {
  _and?: Maybe<Array<Blog_Post_Bool_Exp>>;
  _not?: Maybe<Blog_Post_Bool_Exp>;
  _or?: Maybe<Array<Blog_Post_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  blog_post_and_hashtags?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
  blog_post_and_views?: Maybe<Blog_Post_And_View_Bool_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  galleries?: Maybe<Gallery_Bool_Exp>;
  gallery?: Maybe<Gallery_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<Jsonb_Comparison_Exp>;
  posting?: Maybe<Jsonb_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  thumbnail?: Maybe<Jsonb_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "blog_post" */
export enum Blog_Post_Constraint {
  /** unique or primary key constraint */
  BlogPostPkey = 'blog_post_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Blog_Post_Delete_At_Path_Input = {
  image?: Maybe<Array<Scalars['String']>>;
  posting?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Blog_Post_Delete_Elem_Input = {
  image?: Maybe<Scalars['Int']>;
  posting?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Blog_Post_Delete_Key_Input = {
  image?: Maybe<Scalars['String']>;
  posting?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "blog_post" */
export type Blog_Post_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  blog_post_and_hashtags?: Maybe<Blog_Post_And_Hashtag_Arr_Rel_Insert_Input>;
  blog_post_and_views?: Maybe<Blog_Post_And_View_Arr_Rel_Insert_Input>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  galleries?: Maybe<Gallery_Arr_Rel_Insert_Input>;
  gallery?: Maybe<Gallery_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  posting?: Maybe<Scalars['jsonb']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  thumbnail?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Blog_Post_Max_Fields = {
  __typename?: 'blog_post_max_fields';
  account?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "blog_post" */
export type Blog_Post_Max_Order_By = {
  account?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Post_Min_Fields = {
  __typename?: 'blog_post_min_fields';
  account?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "blog_post" */
export type Blog_Post_Min_Order_By = {
  account?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "blog_post" */
export type Blog_Post_Mutation_Response = {
  __typename?: 'blog_post_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog_Post>;
};

/** input type for inserting object relation for remote table "blog_post" */
export type Blog_Post_Obj_Rel_Insert_Input = {
  data: Blog_Post_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Blog_Post_On_Conflict>;
};

/** on conflict condition type for table "blog_post" */
export type Blog_Post_On_Conflict = {
  constraint: Blog_Post_Constraint;
  update_columns?: Array<Blog_Post_Update_Column>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};

/** Ordering options when selecting data from "blog_post". */
export type Blog_Post_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  blog_post_and_hashtags_aggregate?: Maybe<Blog_Post_And_Hashtag_Aggregate_Order_By>;
  blog_post_and_views_aggregate?: Maybe<Blog_Post_And_View_Aggregate_Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  galleries_aggregate?: Maybe<Gallery_Aggregate_Order_By>;
  gallery?: Maybe<Gallery_Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  posting?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  thumbnail?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: blog_post */
export type Blog_Post_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Blog_Post_Prepend_Input = {
  image?: Maybe<Scalars['jsonb']>;
  posting?: Maybe<Scalars['jsonb']>;
  thumbnail?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "blog_post" */
export enum Blog_Post_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Posting = 'posting',
  /** column name */
  Store = 'store',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "blog_post" */
export type Blog_Post_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  posting?: Maybe<Scalars['jsonb']>;
  store?: Maybe<Scalars['uuid']>;
  thumbnail?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "blog_post" */
export enum Blog_Post_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Posting = 'posting',
  /** column name */
  Store = 'store',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "car" */
export type Car = {
  __typename?: 'car';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  car_inspections: Array<Car_Inspection>;
  /** An aggregate relationship */
  car_inspections_aggregate: Car_Inspection_Aggregate;
  /** An object relationship */
  car_maker: Car_Maker;
  /** An object relationship */
  car_type: Car_Type;
  /** An array relationship */
  cart_items: Array<Cart_Item>;
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  fake_reviews: Array<Fake_Review>;
  /** An aggregate relationship */
  fake_reviews_aggregate: Fake_Review_Aggregate;
  /** An array relationship */
  galleries: Array<Gallery>;
  /** An aggregate relationship */
  galleries_aggregate: Gallery_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  leads: Array<Lead>;
  /** An aggregate relationship */
  leads_aggregate: Lead_Aggregate;
  llumar_car_type?: Maybe<Scalars['String']>;
  maker: Scalars['uuid'];
  model: Scalars['String'];
  /** An array relationship */
  order_items: Array<Order_Item>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Item_Aggregate;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  postfix?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  row: Scalars['Int'];
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  type: Car_Type_Enum;
  updated_at: Scalars['timestamptz'];
  year: Scalars['int4range'];
};


/** columns and relationships of "car" */
export type CarCar_InspectionsArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarCar_Inspections_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarFake_ReviewsArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarFake_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarGalleriesArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarGalleries_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarLeadsArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarLeads_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/** columns and relationships of "car" */
export type CarSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};

/** aggregated selection of "car" */
export type Car_Aggregate = {
  __typename?: 'car_aggregate';
  aggregate?: Maybe<Car_Aggregate_Fields>;
  nodes: Array<Car>;
};

/** aggregate fields of "car" */
export type Car_Aggregate_Fields = {
  __typename?: 'car_aggregate_fields';
  avg?: Maybe<Car_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Car_Max_Fields>;
  min?: Maybe<Car_Min_Fields>;
  stddev?: Maybe<Car_Stddev_Fields>;
  stddev_pop?: Maybe<Car_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Car_Stddev_Samp_Fields>;
  sum?: Maybe<Car_Sum_Fields>;
  var_pop?: Maybe<Car_Var_Pop_Fields>;
  var_samp?: Maybe<Car_Var_Samp_Fields>;
  variance?: Maybe<Car_Variance_Fields>;
};


/** aggregate fields of "car" */
export type Car_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Car_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "car" */
export type Car_Aggregate_Order_By = {
  avg?: Maybe<Car_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Car_Max_Order_By>;
  min?: Maybe<Car_Min_Order_By>;
  stddev?: Maybe<Car_Stddev_Order_By>;
  stddev_pop?: Maybe<Car_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Car_Stddev_Samp_Order_By>;
  sum?: Maybe<Car_Sum_Order_By>;
  var_pop?: Maybe<Car_Var_Pop_Order_By>;
  var_samp?: Maybe<Car_Var_Samp_Order_By>;
  variance?: Maybe<Car_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "car" */
export type Car_Arr_Rel_Insert_Input = {
  data: Array<Car_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Car_On_Conflict>;
};

/** aggregate avg on columns */
export type Car_Avg_Fields = {
  __typename?: 'car_avg_fields';
  row?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "car" */
export type Car_Avg_Order_By = {
  row?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "car". All fields are combined with a logical 'AND'. */
export type Car_Bool_Exp = {
  _and?: Maybe<Array<Car_Bool_Exp>>;
  _not?: Maybe<Car_Bool_Exp>;
  _or?: Maybe<Array<Car_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  car_inspections?: Maybe<Car_Inspection_Bool_Exp>;
  car_maker?: Maybe<Car_Maker_Bool_Exp>;
  car_type?: Maybe<Car_Type_Bool_Exp>;
  cart_items?: Maybe<Cart_Item_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  fake_reviews?: Maybe<Fake_Review_Bool_Exp>;
  galleries?: Maybe<Gallery_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  leads?: Maybe<Lead_Bool_Exp>;
  llumar_car_type?: Maybe<String_Comparison_Exp>;
  maker?: Maybe<Uuid_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  order_items?: Maybe<Order_Item_Bool_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  postfix?: Maybe<String_Comparison_Exp>;
  prefix?: Maybe<String_Comparison_Exp>;
  row?: Maybe<Int_Comparison_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  type?: Maybe<Car_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  year?: Maybe<Int4range_Comparison_Exp>;
};

/** unique or primary key constraints on table "car" */
export enum Car_Constraint {
  /** unique or primary key constraint */
  CarPkey = 'car_pkey'
}

/** input type for incrementing numeric columns in table "car" */
export type Car_Inc_Input = {
  row?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "car" */
export type Car_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  car_inspections?: Maybe<Car_Inspection_Arr_Rel_Insert_Input>;
  car_maker?: Maybe<Car_Maker_Obj_Rel_Insert_Input>;
  car_type?: Maybe<Car_Type_Obj_Rel_Insert_Input>;
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  fake_reviews?: Maybe<Fake_Review_Arr_Rel_Insert_Input>;
  galleries?: Maybe<Gallery_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  leads?: Maybe<Lead_Arr_Rel_Insert_Input>;
  llumar_car_type?: Maybe<Scalars['String']>;
  maker?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  order_items?: Maybe<Order_Item_Arr_Rel_Insert_Input>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  postfix?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  type?: Maybe<Car_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['int4range']>;
};

/** columns and relationships of "car_inspection" */
export type Car_Inspection = {
  __typename?: 'car_inspection';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An object relationship */
  accountByAccountCustomer: Account;
  account_customer: Scalars['uuid'];
  began_at?: Maybe<Scalars['timestamptz']>;
  car: Scalars['uuid'];
  /** An object relationship */
  carByCar: Car;
  /** An object relationship */
  car_inspection_status: Car_Inspection_Status;
  car_number: Scalars['String'];
  car_sunroof: Scalars['String'];
  car_vin: Scalars['String'];
  completed_at?: Maybe<Scalars['timestamptz']>;
  content: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  status: Car_Inspection_Status_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "car_inspection" */
export type Car_InspectionContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "car_inspection" */
export type Car_Inspection_Aggregate = {
  __typename?: 'car_inspection_aggregate';
  aggregate?: Maybe<Car_Inspection_Aggregate_Fields>;
  nodes: Array<Car_Inspection>;
};

/** aggregate fields of "car_inspection" */
export type Car_Inspection_Aggregate_Fields = {
  __typename?: 'car_inspection_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Car_Inspection_Max_Fields>;
  min?: Maybe<Car_Inspection_Min_Fields>;
};


/** aggregate fields of "car_inspection" */
export type Car_Inspection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Car_Inspection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "car_inspection" */
export type Car_Inspection_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Car_Inspection_Max_Order_By>;
  min?: Maybe<Car_Inspection_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Car_Inspection_Append_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "car_inspection" */
export type Car_Inspection_Arr_Rel_Insert_Input = {
  data: Array<Car_Inspection_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Car_Inspection_On_Conflict>;
};

/** Boolean expression to filter rows from the table "car_inspection". All fields are combined with a logical 'AND'. */
export type Car_Inspection_Bool_Exp = {
  _and?: Maybe<Array<Car_Inspection_Bool_Exp>>;
  _not?: Maybe<Car_Inspection_Bool_Exp>;
  _or?: Maybe<Array<Car_Inspection_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  accountByAccountCustomer?: Maybe<Account_Bool_Exp>;
  account_customer?: Maybe<Uuid_Comparison_Exp>;
  began_at?: Maybe<Timestamptz_Comparison_Exp>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  car_inspection_status?: Maybe<Car_Inspection_Status_Bool_Exp>;
  car_number?: Maybe<String_Comparison_Exp>;
  car_sunroof?: Maybe<String_Comparison_Exp>;
  car_vin?: Maybe<String_Comparison_Exp>;
  completed_at?: Maybe<Timestamptz_Comparison_Exp>;
  content?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  discarded_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Car_Inspection_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "car_inspection" */
export enum Car_Inspection_Constraint {
  /** unique or primary key constraint */
  CarInspectionPkey = 'car_inspection_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Car_Inspection_Delete_At_Path_Input = {
  content?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Car_Inspection_Delete_Elem_Input = {
  content?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Car_Inspection_Delete_Key_Input = {
  content?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "car_inspection" */
export type Car_Inspection_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  accountByAccountCustomer?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_customer?: Maybe<Scalars['uuid']>;
  began_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  car_inspection_status?: Maybe<Car_Inspection_Status_Obj_Rel_Insert_Input>;
  car_number?: Maybe<Scalars['String']>;
  car_sunroof?: Maybe<Scalars['String']>;
  car_vin?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Car_Inspection_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Car_Inspection_Max_Fields = {
  __typename?: 'car_inspection_max_fields';
  account?: Maybe<Scalars['uuid']>;
  account_customer?: Maybe<Scalars['uuid']>;
  began_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  car_number?: Maybe<Scalars['String']>;
  car_sunroof?: Maybe<Scalars['String']>;
  car_vin?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "car_inspection" */
export type Car_Inspection_Max_Order_By = {
  account?: Maybe<Order_By>;
  account_customer?: Maybe<Order_By>;
  began_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  car_number?: Maybe<Order_By>;
  car_sunroof?: Maybe<Order_By>;
  car_vin?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  discarded_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Car_Inspection_Min_Fields = {
  __typename?: 'car_inspection_min_fields';
  account?: Maybe<Scalars['uuid']>;
  account_customer?: Maybe<Scalars['uuid']>;
  began_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  car_number?: Maybe<Scalars['String']>;
  car_sunroof?: Maybe<Scalars['String']>;
  car_vin?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "car_inspection" */
export type Car_Inspection_Min_Order_By = {
  account?: Maybe<Order_By>;
  account_customer?: Maybe<Order_By>;
  began_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  car_number?: Maybe<Order_By>;
  car_sunroof?: Maybe<Order_By>;
  car_vin?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  discarded_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "car_inspection" */
export type Car_Inspection_Mutation_Response = {
  __typename?: 'car_inspection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Car_Inspection>;
};

/** on conflict condition type for table "car_inspection" */
export type Car_Inspection_On_Conflict = {
  constraint: Car_Inspection_Constraint;
  update_columns?: Array<Car_Inspection_Update_Column>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};

/** Ordering options when selecting data from "car_inspection". */
export type Car_Inspection_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  accountByAccountCustomer?: Maybe<Account_Order_By>;
  account_customer?: Maybe<Order_By>;
  began_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  car_inspection_status?: Maybe<Car_Inspection_Status_Order_By>;
  car_number?: Maybe<Order_By>;
  car_sunroof?: Maybe<Order_By>;
  car_vin?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  discarded_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: car_inspection */
export type Car_Inspection_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Car_Inspection_Prepend_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "car_inspection" */
export enum Car_Inspection_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  AccountCustomer = 'account_customer',
  /** column name */
  BeganAt = 'began_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarNumber = 'car_number',
  /** column name */
  CarSunroof = 'car_sunroof',
  /** column name */
  CarVin = 'car_vin',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "car_inspection" */
export type Car_Inspection_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  account_customer?: Maybe<Scalars['uuid']>;
  began_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  car_number?: Maybe<Scalars['String']>;
  car_sunroof?: Maybe<Scalars['String']>;
  car_vin?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Car_Inspection_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "car_inspection_status" */
export type Car_Inspection_Status = {
  __typename?: 'car_inspection_status';
  /** An array relationship */
  car_inspections: Array<Car_Inspection>;
  /** An aggregate relationship */
  car_inspections_aggregate: Car_Inspection_Aggregate;
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


/** columns and relationships of "car_inspection_status" */
export type Car_Inspection_StatusCar_InspectionsArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/** columns and relationships of "car_inspection_status" */
export type Car_Inspection_StatusCar_Inspections_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};

/** aggregated selection of "car_inspection_status" */
export type Car_Inspection_Status_Aggregate = {
  __typename?: 'car_inspection_status_aggregate';
  aggregate?: Maybe<Car_Inspection_Status_Aggregate_Fields>;
  nodes: Array<Car_Inspection_Status>;
};

/** aggregate fields of "car_inspection_status" */
export type Car_Inspection_Status_Aggregate_Fields = {
  __typename?: 'car_inspection_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Car_Inspection_Status_Max_Fields>;
  min?: Maybe<Car_Inspection_Status_Min_Fields>;
};


/** aggregate fields of "car_inspection_status" */
export type Car_Inspection_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Car_Inspection_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "car_inspection_status". All fields are combined with a logical 'AND'. */
export type Car_Inspection_Status_Bool_Exp = {
  _and?: Maybe<Array<Car_Inspection_Status_Bool_Exp>>;
  _not?: Maybe<Car_Inspection_Status_Bool_Exp>;
  _or?: Maybe<Array<Car_Inspection_Status_Bool_Exp>>;
  car_inspections?: Maybe<Car_Inspection_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "car_inspection_status" */
export enum Car_Inspection_Status_Constraint {
  /** unique or primary key constraint */
  CarInspectionStatusPkey = 'car_inspection_status_pkey'
}

export enum Car_Inspection_Status_Enum {
  /** 검수 진행 중 */
  Began = 'began',
  /** 검수서 발급 완료 */
  Completed = 'completed',
  /** 검수서 폐기 */
  Discarded = 'discarded'
}

/** Boolean expression to compare columns of type "car_inspection_status_enum". All fields are combined with logical 'AND'. */
export type Car_Inspection_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Car_Inspection_Status_Enum>;
  _in?: Maybe<Array<Car_Inspection_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Car_Inspection_Status_Enum>;
  _nin?: Maybe<Array<Car_Inspection_Status_Enum>>;
};

/** input type for inserting data into table "car_inspection_status" */
export type Car_Inspection_Status_Insert_Input = {
  car_inspections?: Maybe<Car_Inspection_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Car_Inspection_Status_Max_Fields = {
  __typename?: 'car_inspection_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Car_Inspection_Status_Min_Fields = {
  __typename?: 'car_inspection_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "car_inspection_status" */
export type Car_Inspection_Status_Mutation_Response = {
  __typename?: 'car_inspection_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Car_Inspection_Status>;
};

/** input type for inserting object relation for remote table "car_inspection_status" */
export type Car_Inspection_Status_Obj_Rel_Insert_Input = {
  data: Car_Inspection_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Car_Inspection_Status_On_Conflict>;
};

/** on conflict condition type for table "car_inspection_status" */
export type Car_Inspection_Status_On_Conflict = {
  constraint: Car_Inspection_Status_Constraint;
  update_columns?: Array<Car_Inspection_Status_Update_Column>;
  where?: Maybe<Car_Inspection_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "car_inspection_status". */
export type Car_Inspection_Status_Order_By = {
  car_inspections_aggregate?: Maybe<Car_Inspection_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: car_inspection_status */
export type Car_Inspection_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "car_inspection_status" */
export enum Car_Inspection_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "car_inspection_status" */
export type Car_Inspection_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "car_inspection_status" */
export enum Car_Inspection_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "car_inspection" */
export enum Car_Inspection_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  AccountCustomer = 'account_customer',
  /** column name */
  BeganAt = 'began_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarNumber = 'car_number',
  /** column name */
  CarSunroof = 'car_sunroof',
  /** column name */
  CarVin = 'car_vin',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "car_maker" */
export type Car_Maker = {
  __typename?: 'car_maker';
  /** An object relationship */
  car_origin: Car_Origin;
  /** An array relationship */
  cars: Array<Car>;
  /** An aggregate relationship */
  cars_aggregate: Car_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name_kr: Scalars['String'];
  name_us: Scalars['String'];
  origin: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "car_maker" */
export type Car_MakerCarsArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


/** columns and relationships of "car_maker" */
export type Car_MakerCars_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};

/** aggregated selection of "car_maker" */
export type Car_Maker_Aggregate = {
  __typename?: 'car_maker_aggregate';
  aggregate?: Maybe<Car_Maker_Aggregate_Fields>;
  nodes: Array<Car_Maker>;
};

/** aggregate fields of "car_maker" */
export type Car_Maker_Aggregate_Fields = {
  __typename?: 'car_maker_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Car_Maker_Max_Fields>;
  min?: Maybe<Car_Maker_Min_Fields>;
};


/** aggregate fields of "car_maker" */
export type Car_Maker_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Car_Maker_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "car_maker" */
export type Car_Maker_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Car_Maker_Max_Order_By>;
  min?: Maybe<Car_Maker_Min_Order_By>;
};

/** input type for inserting array relation for remote table "car_maker" */
export type Car_Maker_Arr_Rel_Insert_Input = {
  data: Array<Car_Maker_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Car_Maker_On_Conflict>;
};

/** Boolean expression to filter rows from the table "car_maker". All fields are combined with a logical 'AND'. */
export type Car_Maker_Bool_Exp = {
  _and?: Maybe<Array<Car_Maker_Bool_Exp>>;
  _not?: Maybe<Car_Maker_Bool_Exp>;
  _or?: Maybe<Array<Car_Maker_Bool_Exp>>;
  car_origin?: Maybe<Car_Origin_Bool_Exp>;
  cars?: Maybe<Car_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name_kr?: Maybe<String_Comparison_Exp>;
  name_us?: Maybe<String_Comparison_Exp>;
  origin?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "car_maker" */
export enum Car_Maker_Constraint {
  /** unique or primary key constraint */
  CarMakerPkey = 'car_maker_pkey'
}

/** input type for inserting data into table "car_maker" */
export type Car_Maker_Insert_Input = {
  car_origin?: Maybe<Car_Origin_Obj_Rel_Insert_Input>;
  cars?: Maybe<Car_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Car_Maker_Max_Fields = {
  __typename?: 'car_maker_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "car_maker" */
export type Car_Maker_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_kr?: Maybe<Order_By>;
  name_us?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Car_Maker_Min_Fields = {
  __typename?: 'car_maker_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "car_maker" */
export type Car_Maker_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_kr?: Maybe<Order_By>;
  name_us?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "car_maker" */
export type Car_Maker_Mutation_Response = {
  __typename?: 'car_maker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Car_Maker>;
};

/** input type for inserting object relation for remote table "car_maker" */
export type Car_Maker_Obj_Rel_Insert_Input = {
  data: Car_Maker_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Car_Maker_On_Conflict>;
};

/** on conflict condition type for table "car_maker" */
export type Car_Maker_On_Conflict = {
  constraint: Car_Maker_Constraint;
  update_columns?: Array<Car_Maker_Update_Column>;
  where?: Maybe<Car_Maker_Bool_Exp>;
};

/** Ordering options when selecting data from "car_maker". */
export type Car_Maker_Order_By = {
  car_origin?: Maybe<Car_Origin_Order_By>;
  cars_aggregate?: Maybe<Car_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_kr?: Maybe<Order_By>;
  name_us?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: car_maker */
export type Car_Maker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "car_maker" */
export enum Car_Maker_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameKr = 'name_kr',
  /** column name */
  NameUs = 'name_us',
  /** column name */
  Origin = 'origin',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "car_maker" */
export type Car_Maker_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "car_maker" */
export enum Car_Maker_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameKr = 'name_kr',
  /** column name */
  NameUs = 'name_us',
  /** column name */
  Origin = 'origin',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate max on columns */
export type Car_Max_Fields = {
  __typename?: 'car_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_car_type?: Maybe<Scalars['String']>;
  maker?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  postfix?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "car" */
export type Car_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  llumar_car_type?: Maybe<Order_By>;
  maker?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  postfix?: Maybe<Order_By>;
  prefix?: Maybe<Order_By>;
  row?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Car_Min_Fields = {
  __typename?: 'car_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_car_type?: Maybe<Scalars['String']>;
  maker?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  postfix?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "car" */
export type Car_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  llumar_car_type?: Maybe<Order_By>;
  maker?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  postfix?: Maybe<Order_By>;
  prefix?: Maybe<Order_By>;
  row?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "car" */
export type Car_Mutation_Response = {
  __typename?: 'car_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Car>;
};

/** input type for inserting object relation for remote table "car" */
export type Car_Obj_Rel_Insert_Input = {
  data: Car_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Car_On_Conflict>;
};

/** on conflict condition type for table "car" */
export type Car_On_Conflict = {
  constraint: Car_Constraint;
  update_columns?: Array<Car_Update_Column>;
  where?: Maybe<Car_Bool_Exp>;
};

/** Ordering options when selecting data from "car". */
export type Car_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  car_inspections_aggregate?: Maybe<Car_Inspection_Aggregate_Order_By>;
  car_maker?: Maybe<Car_Maker_Order_By>;
  car_type?: Maybe<Car_Type_Order_By>;
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  fake_reviews_aggregate?: Maybe<Fake_Review_Aggregate_Order_By>;
  galleries_aggregate?: Maybe<Gallery_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  leads_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  llumar_car_type?: Maybe<Order_By>;
  maker?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  order_items_aggregate?: Maybe<Order_Item_Aggregate_Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  postfix?: Maybe<Order_By>;
  prefix?: Maybe<Order_By>;
  row?: Maybe<Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "car_origin" */
export type Car_Origin = {
  __typename?: 'car_origin';
  /** An array relationship */
  car_makers: Array<Car_Maker>;
  /** An aggregate relationship */
  car_makers_aggregate: Car_Maker_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name_kr: Scalars['String'];
  name_us: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "car_origin" */
export type Car_OriginCar_MakersArgs = {
  distinct_on?: Maybe<Array<Car_Maker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Maker_Order_By>>;
  where?: Maybe<Car_Maker_Bool_Exp>;
};


/** columns and relationships of "car_origin" */
export type Car_OriginCar_Makers_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Maker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Maker_Order_By>>;
  where?: Maybe<Car_Maker_Bool_Exp>;
};

/** aggregated selection of "car_origin" */
export type Car_Origin_Aggregate = {
  __typename?: 'car_origin_aggregate';
  aggregate?: Maybe<Car_Origin_Aggregate_Fields>;
  nodes: Array<Car_Origin>;
};

/** aggregate fields of "car_origin" */
export type Car_Origin_Aggregate_Fields = {
  __typename?: 'car_origin_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Car_Origin_Max_Fields>;
  min?: Maybe<Car_Origin_Min_Fields>;
};


/** aggregate fields of "car_origin" */
export type Car_Origin_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Car_Origin_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "car_origin". All fields are combined with a logical 'AND'. */
export type Car_Origin_Bool_Exp = {
  _and?: Maybe<Array<Car_Origin_Bool_Exp>>;
  _not?: Maybe<Car_Origin_Bool_Exp>;
  _or?: Maybe<Array<Car_Origin_Bool_Exp>>;
  car_makers?: Maybe<Car_Maker_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name_kr?: Maybe<String_Comparison_Exp>;
  name_us?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "car_origin" */
export enum Car_Origin_Constraint {
  /** unique or primary key constraint */
  CarOriginPkey = 'car_origin_pkey'
}

/** input type for inserting data into table "car_origin" */
export type Car_Origin_Insert_Input = {
  car_makers?: Maybe<Car_Maker_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Car_Origin_Max_Fields = {
  __typename?: 'car_origin_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Car_Origin_Min_Fields = {
  __typename?: 'car_origin_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "car_origin" */
export type Car_Origin_Mutation_Response = {
  __typename?: 'car_origin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Car_Origin>;
};

/** input type for inserting object relation for remote table "car_origin" */
export type Car_Origin_Obj_Rel_Insert_Input = {
  data: Car_Origin_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Car_Origin_On_Conflict>;
};

/** on conflict condition type for table "car_origin" */
export type Car_Origin_On_Conflict = {
  constraint: Car_Origin_Constraint;
  update_columns?: Array<Car_Origin_Update_Column>;
  where?: Maybe<Car_Origin_Bool_Exp>;
};

/** Ordering options when selecting data from "car_origin". */
export type Car_Origin_Order_By = {
  car_makers_aggregate?: Maybe<Car_Maker_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_kr?: Maybe<Order_By>;
  name_us?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: car_origin */
export type Car_Origin_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "car_origin" */
export enum Car_Origin_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameKr = 'name_kr',
  /** column name */
  NameUs = 'name_us',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "car_origin" */
export type Car_Origin_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_kr?: Maybe<Scalars['String']>;
  name_us?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "car_origin" */
export enum Car_Origin_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameKr = 'name_kr',
  /** column name */
  NameUs = 'name_us',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** primary key columns input for table: car */
export type Car_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "car" */
export enum Car_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LlumarCarType = 'llumar_car_type',
  /** column name */
  Maker = 'maker',
  /** column name */
  Model = 'model',
  /** column name */
  Postfix = 'postfix',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  Row = 'row',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "car" */
export type Car_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_car_type?: Maybe<Scalars['String']>;
  maker?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  postfix?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  type?: Maybe<Car_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['int4range']>;
};

/** aggregate stddev on columns */
export type Car_Stddev_Fields = {
  __typename?: 'car_stddev_fields';
  row?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "car" */
export type Car_Stddev_Order_By = {
  row?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Car_Stddev_Pop_Fields = {
  __typename?: 'car_stddev_pop_fields';
  row?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "car" */
export type Car_Stddev_Pop_Order_By = {
  row?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Car_Stddev_Samp_Fields = {
  __typename?: 'car_stddev_samp_fields';
  row?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "car" */
export type Car_Stddev_Samp_Order_By = {
  row?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Car_Sum_Fields = {
  __typename?: 'car_sum_fields';
  row?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "car" */
export type Car_Sum_Order_By = {
  row?: Maybe<Order_By>;
};

/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_Type = {
  __typename?: 'car_sunroof_type';
  /** An array relationship */
  cart_items: Array<Cart_Item>;
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  galleries: Array<Gallery>;
  /** An aggregate relationship */
  galleries_aggregate: Gallery_Aggregate;
  /** An array relationship */
  leads: Array<Lead>;
  /** An aggregate relationship */
  leads_aggregate: Lead_Aggregate;
  /** An array relationship */
  order_items: Array<Order_Item>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Item_Aggregate;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeGalleriesArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeGalleries_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeLeadsArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeLeads_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/** columns and relationships of "car_sunroof_type" */
export type Car_Sunroof_TypeSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};

/** aggregated selection of "car_sunroof_type" */
export type Car_Sunroof_Type_Aggregate = {
  __typename?: 'car_sunroof_type_aggregate';
  aggregate?: Maybe<Car_Sunroof_Type_Aggregate_Fields>;
  nodes: Array<Car_Sunroof_Type>;
};

/** aggregate fields of "car_sunroof_type" */
export type Car_Sunroof_Type_Aggregate_Fields = {
  __typename?: 'car_sunroof_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Car_Sunroof_Type_Max_Fields>;
  min?: Maybe<Car_Sunroof_Type_Min_Fields>;
};


/** aggregate fields of "car_sunroof_type" */
export type Car_Sunroof_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Car_Sunroof_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "car_sunroof_type". All fields are combined with a logical 'AND'. */
export type Car_Sunroof_Type_Bool_Exp = {
  _and?: Maybe<Array<Car_Sunroof_Type_Bool_Exp>>;
  _not?: Maybe<Car_Sunroof_Type_Bool_Exp>;
  _or?: Maybe<Array<Car_Sunroof_Type_Bool_Exp>>;
  cart_items?: Maybe<Cart_Item_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  galleries?: Maybe<Gallery_Bool_Exp>;
  leads?: Maybe<Lead_Bool_Exp>;
  order_items?: Maybe<Order_Item_Bool_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "car_sunroof_type" */
export enum Car_Sunroof_Type_Constraint {
  /** unique or primary key constraint */
  CarSunroofTypePkey = 'car_sunroof_type_pkey'
}

export enum Car_Sunroof_Type_Enum {
  /** 없음 */
  None = 'none',
  /** 썬루프 */
  Normal = 'normal',
  /** 파노라마 썬루프 */
  Panorama = 'panorama'
}

/** Boolean expression to compare columns of type "car_sunroof_type_enum". All fields are combined with logical 'AND'. */
export type Car_Sunroof_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Car_Sunroof_Type_Enum>;
  _in?: Maybe<Array<Car_Sunroof_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Car_Sunroof_Type_Enum>;
  _nin?: Maybe<Array<Car_Sunroof_Type_Enum>>;
};

/** input type for inserting data into table "car_sunroof_type" */
export type Car_Sunroof_Type_Insert_Input = {
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  galleries?: Maybe<Gallery_Arr_Rel_Insert_Input>;
  leads?: Maybe<Lead_Arr_Rel_Insert_Input>;
  order_items?: Maybe<Order_Item_Arr_Rel_Insert_Input>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Car_Sunroof_Type_Max_Fields = {
  __typename?: 'car_sunroof_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Car_Sunroof_Type_Min_Fields = {
  __typename?: 'car_sunroof_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "car_sunroof_type" */
export type Car_Sunroof_Type_Mutation_Response = {
  __typename?: 'car_sunroof_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Car_Sunroof_Type>;
};

/** input type for inserting object relation for remote table "car_sunroof_type" */
export type Car_Sunroof_Type_Obj_Rel_Insert_Input = {
  data: Car_Sunroof_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Car_Sunroof_Type_On_Conflict>;
};

/** on conflict condition type for table "car_sunroof_type" */
export type Car_Sunroof_Type_On_Conflict = {
  constraint: Car_Sunroof_Type_Constraint;
  update_columns?: Array<Car_Sunroof_Type_Update_Column>;
  where?: Maybe<Car_Sunroof_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "car_sunroof_type". */
export type Car_Sunroof_Type_Order_By = {
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  galleries_aggregate?: Maybe<Gallery_Aggregate_Order_By>;
  leads_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  order_items_aggregate?: Maybe<Order_Item_Aggregate_Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: car_sunroof_type */
export type Car_Sunroof_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "car_sunroof_type" */
export enum Car_Sunroof_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "car_sunroof_type" */
export type Car_Sunroof_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "car_sunroof_type" */
export enum Car_Sunroof_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "car_type" */
export type Car_Type = {
  __typename?: 'car_type';
  /** An array relationship */
  cars: Array<Car>;
  /** An aggregate relationship */
  cars_aggregate: Car_Aggregate;
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


/** columns and relationships of "car_type" */
export type Car_TypeCarsArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


/** columns and relationships of "car_type" */
export type Car_TypeCars_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};

/** aggregated selection of "car_type" */
export type Car_Type_Aggregate = {
  __typename?: 'car_type_aggregate';
  aggregate?: Maybe<Car_Type_Aggregate_Fields>;
  nodes: Array<Car_Type>;
};

/** aggregate fields of "car_type" */
export type Car_Type_Aggregate_Fields = {
  __typename?: 'car_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Car_Type_Max_Fields>;
  min?: Maybe<Car_Type_Min_Fields>;
};


/** aggregate fields of "car_type" */
export type Car_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Car_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "car_type". All fields are combined with a logical 'AND'. */
export type Car_Type_Bool_Exp = {
  _and?: Maybe<Array<Car_Type_Bool_Exp>>;
  _not?: Maybe<Car_Type_Bool_Exp>;
  _or?: Maybe<Array<Car_Type_Bool_Exp>>;
  cars?: Maybe<Car_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "car_type" */
export enum Car_Type_Constraint {
  /** unique or primary key constraint */
  CarTypePkey = 'car_type_pkey'
}

export enum Car_Type_Enum {
  /** 쿠페 */
  Coupe = 'coupe',
  /** 중대형 SUV */
  MidLargeSuv = 'midLargeSUV',
  /** 세단 */
  Sedan = 'sedan',
  /** 소형 SUV */
  SmallSuv = 'smallSUV',
  /** 밴 */
  Van = 'van'
}

/** Boolean expression to compare columns of type "car_type_enum". All fields are combined with logical 'AND'. */
export type Car_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Car_Type_Enum>;
  _in?: Maybe<Array<Car_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Car_Type_Enum>;
  _nin?: Maybe<Array<Car_Type_Enum>>;
};

/** input type for inserting data into table "car_type" */
export type Car_Type_Insert_Input = {
  cars?: Maybe<Car_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Car_Type_Max_Fields = {
  __typename?: 'car_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Car_Type_Min_Fields = {
  __typename?: 'car_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "car_type" */
export type Car_Type_Mutation_Response = {
  __typename?: 'car_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Car_Type>;
};

/** input type for inserting object relation for remote table "car_type" */
export type Car_Type_Obj_Rel_Insert_Input = {
  data: Car_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Car_Type_On_Conflict>;
};

/** on conflict condition type for table "car_type" */
export type Car_Type_On_Conflict = {
  constraint: Car_Type_Constraint;
  update_columns?: Array<Car_Type_Update_Column>;
  where?: Maybe<Car_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "car_type". */
export type Car_Type_Order_By = {
  cars_aggregate?: Maybe<Car_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: car_type */
export type Car_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "car_type" */
export enum Car_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "car_type" */
export type Car_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "car_type" */
export enum Car_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "car" */
export enum Car_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LlumarCarType = 'llumar_car_type',
  /** column name */
  Maker = 'maker',
  /** column name */
  Model = 'model',
  /** column name */
  Postfix = 'postfix',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  Row = 'row',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Car_Var_Pop_Fields = {
  __typename?: 'car_var_pop_fields';
  row?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "car" */
export type Car_Var_Pop_Order_By = {
  row?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Car_Var_Samp_Fields = {
  __typename?: 'car_var_samp_fields';
  row?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "car" */
export type Car_Var_Samp_Order_By = {
  row?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Car_Variance_Fields = {
  __typename?: 'car_variance_fields';
  row?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "car" */
export type Car_Variance_Order_By = {
  row?: Maybe<Order_By>;
};

/** columns and relationships of "cart_item" */
export type Cart_Item = {
  __typename?: 'cart_item';
  /** An object relationship */
  account?: Maybe<Account>;
  basic_price_payout: Scalars['numeric'];
  car?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  carByCar?: Maybe<Car>;
  /** An object relationship */
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  cart: Scalars['uuid'];
  checked: Scalars['Boolean'];
  commission_rate: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  lead?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  n_cart: N_Cart;
  /** A computed field, executes function "cart_item_package_info" */
  package_detail?: Maybe<Array<Package>>;
  price_discount: Scalars['numeric'];
  price_extra: Scalars['numeric'];
  price_final: Scalars['numeric'];
  price_payout: Scalars['numeric'];
  price_payout_discount: Scalars['numeric'];
  price_payout_extra: Scalars['numeric'];
  price_payout_without_vat: Scalars['numeric'];
  price_product: Scalars['numeric'];
  product_item: Scalars['jsonb'];
  /** A computed field, executes function "cart_item_detail" */
  product_item_detail?: Maybe<Array<Product_Item>>;
  quantity: Scalars['numeric'];
  /** An object relationship */
  quoteItemByQuoteItem?: Maybe<Quote_Item>;
  quote_item?: Maybe<Scalars['uuid']>;
  remark: Scalars['jsonb'];
  store?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  storeByStore?: Maybe<Store>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "cart_item" */
export type Cart_ItemPackage_DetailArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/** columns and relationships of "cart_item" */
export type Cart_ItemProduct_ItemArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "cart_item" */
export type Cart_ItemProduct_Item_DetailArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


/** columns and relationships of "cart_item" */
export type Cart_ItemRemarkArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "cart_item" */
export type Cart_Item_Aggregate = {
  __typename?: 'cart_item_aggregate';
  aggregate?: Maybe<Cart_Item_Aggregate_Fields>;
  nodes: Array<Cart_Item>;
};

/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_Fields = {
  __typename?: 'cart_item_aggregate_fields';
  avg?: Maybe<Cart_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cart_Item_Max_Fields>;
  min?: Maybe<Cart_Item_Min_Fields>;
  stddev?: Maybe<Cart_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Cart_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cart_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Cart_Item_Sum_Fields>;
  var_pop?: Maybe<Cart_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Cart_Item_Var_Samp_Fields>;
  variance?: Maybe<Cart_Item_Variance_Fields>;
};


/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cart_item" */
export type Cart_Item_Aggregate_Order_By = {
  avg?: Maybe<Cart_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cart_Item_Max_Order_By>;
  min?: Maybe<Cart_Item_Min_Order_By>;
  stddev?: Maybe<Cart_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Cart_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cart_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Cart_Item_Sum_Order_By>;
  var_pop?: Maybe<Cart_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Cart_Item_Var_Samp_Order_By>;
  variance?: Maybe<Cart_Item_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Append_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "cart_item" */
export type Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Cart_Item_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Cart_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Cart_Item_Avg_Fields = {
  __typename?: 'cart_item_avg_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cart_item" */
export type Cart_Item_Avg_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cart_item". All fields are combined with a logical 'AND'. */
export type Cart_Item_Bool_Exp = {
  _and?: Maybe<Array<Cart_Item_Bool_Exp>>;
  _not?: Maybe<Cart_Item_Bool_Exp>;
  _or?: Maybe<Array<Cart_Item_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  basic_price_payout?: Maybe<Numeric_Comparison_Exp>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Bool_Exp>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum_Comparison_Exp>;
  cart?: Maybe<Uuid_Comparison_Exp>;
  checked?: Maybe<Boolean_Comparison_Exp>;
  commission_rate?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_time?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lead?: Maybe<Uuid_Comparison_Exp>;
  n_cart?: Maybe<N_Cart_Bool_Exp>;
  package_detail?: Maybe<Package_Bool_Exp>;
  price_discount?: Maybe<Numeric_Comparison_Exp>;
  price_extra?: Maybe<Numeric_Comparison_Exp>;
  price_final?: Maybe<Numeric_Comparison_Exp>;
  price_payout?: Maybe<Numeric_Comparison_Exp>;
  price_payout_discount?: Maybe<Numeric_Comparison_Exp>;
  price_payout_extra?: Maybe<Numeric_Comparison_Exp>;
  price_payout_without_vat?: Maybe<Numeric_Comparison_Exp>;
  price_product?: Maybe<Numeric_Comparison_Exp>;
  product_item?: Maybe<Jsonb_Comparison_Exp>;
  product_item_detail?: Maybe<Product_Item_Bool_Exp>;
  quantity?: Maybe<Numeric_Comparison_Exp>;
  quoteItemByQuoteItem?: Maybe<Quote_Item_Bool_Exp>;
  quote_item?: Maybe<Uuid_Comparison_Exp>;
  remark?: Maybe<Jsonb_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "cart_item" */
export enum Cart_Item_Constraint {
  /** unique or primary key constraint */
  CartItemPkey = 'cart_item_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cart_Item_Delete_At_Path_Input = {
  product_item?: Maybe<Array<Scalars['String']>>;
  remark?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cart_Item_Delete_Elem_Input = {
  product_item?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cart_Item_Delete_Key_Input = {
  product_item?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "cart_item" */
export type Cart_Item_Inc_Input = {
  basic_price_payout?: Maybe<Scalars['numeric']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "cart_item" */
export type Cart_Item_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  basic_price_payout?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Obj_Rel_Insert_Input>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  cart?: Maybe<Scalars['uuid']>;
  checked?: Maybe<Scalars['Boolean']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  n_cart?: Maybe<N_Cart_Obj_Rel_Insert_Input>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quantity?: Maybe<Scalars['numeric']>;
  quoteItemByQuoteItem?: Maybe<Quote_Item_Obj_Rel_Insert_Input>;
  quote_item?: Maybe<Scalars['uuid']>;
  remark?: Maybe<Scalars['jsonb']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Cart_Item_Max_Fields = {
  __typename?: 'cart_item_max_fields';
  basic_price_payout?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  cart?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  quote_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "cart_item" */
export type Cart_Item_Max_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  cart?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  quote_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cart_Item_Min_Fields = {
  __typename?: 'cart_item_min_fields';
  basic_price_payout?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  cart?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  quote_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "cart_item" */
export type Cart_Item_Min_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  cart?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  quote_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "cart_item" */
export type Cart_Item_Mutation_Response = {
  __typename?: 'cart_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item>;
};

/** on conflict condition type for table "cart_item" */
export type Cart_Item_On_Conflict = {
  constraint: Cart_Item_Constraint;
  update_columns?: Array<Cart_Item_Update_Column>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "cart_item". */
export type Cart_Item_Order_By = {
  account?: Maybe<Account_Order_By>;
  basic_price_payout?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Order_By>;
  car_sunroof_type?: Maybe<Order_By>;
  cart?: Maybe<Order_By>;
  checked?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  n_cart?: Maybe<N_Cart_Order_By>;
  package_detail_aggregate?: Maybe<Package_Aggregate_Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  product_item_detail_aggregate?: Maybe<Product_Item_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  quoteItemByQuoteItem?: Maybe<Quote_Item_Order_By>;
  quote_item?: Maybe<Order_By>;
  remark?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** primary key columns input for table: cart_item */
export type Cart_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Prepend_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "cart_item" */
export enum Cart_Item_Select_Column {
  /** column name */
  BasicPricePayout = 'basic_price_payout',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  Cart = 'cart',
  /** column name */
  Checked = 'checked',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutDiscount = 'price_payout_discount',
  /** column name */
  PricePayoutExtra = 'price_payout_extra',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuoteItem = 'quote_item',
  /** column name */
  Remark = 'remark',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "cart_item" */
export type Cart_Item_Set_Input = {
  basic_price_payout?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  cart?: Maybe<Scalars['uuid']>;
  checked?: Maybe<Scalars['Boolean']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quantity?: Maybe<Scalars['numeric']>;
  quote_item?: Maybe<Scalars['uuid']>;
  remark?: Maybe<Scalars['jsonb']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Cart_Item_Stddev_Fields = {
  __typename?: 'cart_item_stddev_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cart_item" */
export type Cart_Item_Stddev_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'cart_item_stddev_pop_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cart_item" */
export type Cart_Item_Stddev_Pop_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'cart_item_stddev_samp_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cart_item" */
export type Cart_Item_Stddev_Samp_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cart_Item_Sum_Fields = {
  __typename?: 'cart_item_sum_fields';
  basic_price_payout?: Maybe<Scalars['numeric']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "cart_item" */
export type Cart_Item_Sum_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** update columns of table "cart_item" */
export enum Cart_Item_Update_Column {
  /** column name */
  BasicPricePayout = 'basic_price_payout',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  Cart = 'cart',
  /** column name */
  Checked = 'checked',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutDiscount = 'price_payout_discount',
  /** column name */
  PricePayoutExtra = 'price_payout_extra',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuoteItem = 'quote_item',
  /** column name */
  Remark = 'remark',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Cart_Item_Var_Pop_Fields = {
  __typename?: 'cart_item_var_pop_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cart_item" */
export type Cart_Item_Var_Pop_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cart_Item_Var_Samp_Fields = {
  __typename?: 'cart_item_var_samp_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cart_item" */
export type Cart_Item_Var_Samp_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cart_Item_Variance_Fields = {
  __typename?: 'cart_item_variance_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cart_item" */
export type Cart_Item_Variance_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** columns and relationships of "chat" */
export type Chat = {
  __typename?: 'chat';
  /** An object relationship */
  accountByChatAssignee?: Maybe<Account>;
  button: Scalars['String'];
  chat_assignee?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  key: Scalars['Int'];
  lead: Scalars['uuid'];
  /** An object relationship */
  leadByLead: Lead;
  session: Scalars['uuid'];
  /** An object relationship */
  sessionBySession: Session;
  ticket?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
  zendesk_chat_name: Scalars['String'];
};

/** aggregated selection of "chat" */
export type Chat_Aggregate = {
  __typename?: 'chat_aggregate';
  aggregate?: Maybe<Chat_Aggregate_Fields>;
  nodes: Array<Chat>;
};

/** aggregate fields of "chat" */
export type Chat_Aggregate_Fields = {
  __typename?: 'chat_aggregate_fields';
  avg?: Maybe<Chat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Chat_Max_Fields>;
  min?: Maybe<Chat_Min_Fields>;
  stddev?: Maybe<Chat_Stddev_Fields>;
  stddev_pop?: Maybe<Chat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Chat_Stddev_Samp_Fields>;
  sum?: Maybe<Chat_Sum_Fields>;
  var_pop?: Maybe<Chat_Var_Pop_Fields>;
  var_samp?: Maybe<Chat_Var_Samp_Fields>;
  variance?: Maybe<Chat_Variance_Fields>;
};


/** aggregate fields of "chat" */
export type Chat_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Chat_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat" */
export type Chat_Aggregate_Order_By = {
  avg?: Maybe<Chat_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Chat_Max_Order_By>;
  min?: Maybe<Chat_Min_Order_By>;
  stddev?: Maybe<Chat_Stddev_Order_By>;
  stddev_pop?: Maybe<Chat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Chat_Stddev_Samp_Order_By>;
  sum?: Maybe<Chat_Sum_Order_By>;
  var_pop?: Maybe<Chat_Var_Pop_Order_By>;
  var_samp?: Maybe<Chat_Var_Samp_Order_By>;
  variance?: Maybe<Chat_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chat" */
export type Chat_Arr_Rel_Insert_Input = {
  data: Array<Chat_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Chat_On_Conflict>;
};

/** aggregate avg on columns */
export type Chat_Avg_Fields = {
  __typename?: 'chat_avg_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "chat" */
export type Chat_Avg_Order_By = {
  key?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chat". All fields are combined with a logical 'AND'. */
export type Chat_Bool_Exp = {
  _and?: Maybe<Array<Chat_Bool_Exp>>;
  _not?: Maybe<Chat_Bool_Exp>;
  _or?: Maybe<Array<Chat_Bool_Exp>>;
  accountByChatAssignee?: Maybe<Account_Bool_Exp>;
  button?: Maybe<String_Comparison_Exp>;
  chat_assignee?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<Int_Comparison_Exp>;
  lead?: Maybe<Uuid_Comparison_Exp>;
  leadByLead?: Maybe<Lead_Bool_Exp>;
  session?: Maybe<Uuid_Comparison_Exp>;
  sessionBySession?: Maybe<Session_Bool_Exp>;
  ticket?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  zendesk_chat_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat" */
export enum Chat_Constraint {
  /** unique or primary key constraint */
  ChatPkey = 'chat_pkey',
  /** unique or primary key constraint */
  ChatZendeskChatNameKey = 'chat_zendesk_chat_name_key'
}

/** input type for incrementing numeric columns in table "chat" */
export type Chat_Inc_Input = {
  key?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "chat" */
export type Chat_Insert_Input = {
  accountByChatAssignee?: Maybe<Account_Obj_Rel_Insert_Input>;
  button?: Maybe<Scalars['String']>;
  chat_assignee?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['uuid']>;
  leadByLead?: Maybe<Lead_Obj_Rel_Insert_Input>;
  session?: Maybe<Scalars['uuid']>;
  sessionBySession?: Maybe<Session_Obj_Rel_Insert_Input>;
  ticket?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  zendesk_chat_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chat_Max_Fields = {
  __typename?: 'chat_max_fields';
  button?: Maybe<Scalars['String']>;
  chat_assignee?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['uuid']>;
  session?: Maybe<Scalars['uuid']>;
  ticket?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  zendesk_chat_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "chat" */
export type Chat_Max_Order_By = {
  button?: Maybe<Order_By>;
  chat_assignee?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  zendesk_chat_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Min_Fields = {
  __typename?: 'chat_min_fields';
  button?: Maybe<Scalars['String']>;
  chat_assignee?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['uuid']>;
  session?: Maybe<Scalars['uuid']>;
  ticket?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  zendesk_chat_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "chat" */
export type Chat_Min_Order_By = {
  button?: Maybe<Order_By>;
  chat_assignee?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  zendesk_chat_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "chat" */
export type Chat_Mutation_Response = {
  __typename?: 'chat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat>;
};

/** on conflict condition type for table "chat" */
export type Chat_On_Conflict = {
  constraint: Chat_Constraint;
  update_columns?: Array<Chat_Update_Column>;
  where?: Maybe<Chat_Bool_Exp>;
};

/** Ordering options when selecting data from "chat". */
export type Chat_Order_By = {
  accountByChatAssignee?: Maybe<Account_Order_By>;
  button?: Maybe<Order_By>;
  chat_assignee?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  leadByLead?: Maybe<Lead_Order_By>;
  session?: Maybe<Order_By>;
  sessionBySession?: Maybe<Session_Order_By>;
  ticket?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  zendesk_chat_name?: Maybe<Order_By>;
};

/** primary key columns input for table: chat */
export type Chat_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat" */
export enum Chat_Select_Column {
  /** column name */
  Button = 'button',
  /** column name */
  ChatAssignee = 'chat_assignee',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Lead = 'lead',
  /** column name */
  Session = 'session',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  ZendeskChatName = 'zendesk_chat_name'
}

/** input type for updating data in table "chat" */
export type Chat_Set_Input = {
  button?: Maybe<Scalars['String']>;
  chat_assignee?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['uuid']>;
  session?: Maybe<Scalars['uuid']>;
  ticket?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  zendesk_chat_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Chat_Stddev_Fields = {
  __typename?: 'chat_stddev_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "chat" */
export type Chat_Stddev_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Chat_Stddev_Pop_Fields = {
  __typename?: 'chat_stddev_pop_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "chat" */
export type Chat_Stddev_Pop_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Chat_Stddev_Samp_Fields = {
  __typename?: 'chat_stddev_samp_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "chat" */
export type Chat_Stddev_Samp_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Chat_Sum_Fields = {
  __typename?: 'chat_sum_fields';
  key?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "chat" */
export type Chat_Sum_Order_By = {
  key?: Maybe<Order_By>;
};

/** update columns of table "chat" */
export enum Chat_Update_Column {
  /** column name */
  Button = 'button',
  /** column name */
  ChatAssignee = 'chat_assignee',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Lead = 'lead',
  /** column name */
  Session = 'session',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  ZendeskChatName = 'zendesk_chat_name'
}

/** aggregate var_pop on columns */
export type Chat_Var_Pop_Fields = {
  __typename?: 'chat_var_pop_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "chat" */
export type Chat_Var_Pop_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Chat_Var_Samp_Fields = {
  __typename?: 'chat_var_samp_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "chat" */
export type Chat_Var_Samp_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Chat_Variance_Fields = {
  __typename?: 'chat_variance_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "chat" */
export type Chat_Variance_Order_By = {
  key?: Maybe<Order_By>;
};

/** columns and relationships of "config" */
export type Config = {
  __typename?: 'config';
  account: Scalars['uuid'];
  /** An object relationship */
  config_type: Config_Type;
  created_at: Scalars['timestamptz'];
  csv_content: Scalars['jsonb'];
  id: Scalars['uuid'];
  type: Config_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "config" */
export type ConfigCsv_ContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "config" */
export type Config_Aggregate = {
  __typename?: 'config_aggregate';
  aggregate?: Maybe<Config_Aggregate_Fields>;
  nodes: Array<Config>;
};

/** aggregate fields of "config" */
export type Config_Aggregate_Fields = {
  __typename?: 'config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Config_Max_Fields>;
  min?: Maybe<Config_Min_Fields>;
};


/** aggregate fields of "config" */
export type Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Config_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "config" */
export type Config_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Config_Max_Order_By>;
  min?: Maybe<Config_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Config_Append_Input = {
  csv_content?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "config" */
export type Config_Arr_Rel_Insert_Input = {
  data: Array<Config_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "config". All fields are combined with a logical 'AND'. */
export type Config_Bool_Exp = {
  _and?: Maybe<Array<Config_Bool_Exp>>;
  _not?: Maybe<Config_Bool_Exp>;
  _or?: Maybe<Array<Config_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  config_type?: Maybe<Config_Type_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  csv_content?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Config_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "config" */
export enum Config_Constraint {
  /** unique or primary key constraint */
  ConfigPkey = 'config_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Config_Delete_At_Path_Input = {
  csv_content?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Config_Delete_Elem_Input = {
  csv_content?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Config_Delete_Key_Input = {
  csv_content?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "config" */
export type Config_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  config_type?: Maybe<Config_Type_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  csv_content?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Config_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Config_Max_Fields = {
  __typename?: 'config_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "config" */
export type Config_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Config_Min_Fields = {
  __typename?: 'config_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "config" */
export type Config_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "config" */
export type Config_Mutation_Response = {
  __typename?: 'config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Config>;
};

/** on conflict condition type for table "config" */
export type Config_On_Conflict = {
  constraint: Config_Constraint;
  update_columns?: Array<Config_Update_Column>;
  where?: Maybe<Config_Bool_Exp>;
};

/** Ordering options when selecting data from "config". */
export type Config_Order_By = {
  account?: Maybe<Order_By>;
  config_type?: Maybe<Config_Type_Order_By>;
  created_at?: Maybe<Order_By>;
  csv_content?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: config */
export type Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Config_Prepend_Input = {
  csv_content?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "config" */
export enum Config_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CsvContent = 'csv_content',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "config" */
export type Config_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  csv_content?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Config_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "config_type" */
export type Config_Type = {
  __typename?: 'config_type';
  /** An array relationship */
  configs: Array<Config>;
  /** An aggregate relationship */
  configs_aggregate: Config_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "config_type" */
export type Config_TypeConfigsArgs = {
  distinct_on?: Maybe<Array<Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Order_By>>;
  where?: Maybe<Config_Bool_Exp>;
};


/** columns and relationships of "config_type" */
export type Config_TypeConfigs_AggregateArgs = {
  distinct_on?: Maybe<Array<Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Order_By>>;
  where?: Maybe<Config_Bool_Exp>;
};

/** aggregated selection of "config_type" */
export type Config_Type_Aggregate = {
  __typename?: 'config_type_aggregate';
  aggregate?: Maybe<Config_Type_Aggregate_Fields>;
  nodes: Array<Config_Type>;
};

/** aggregate fields of "config_type" */
export type Config_Type_Aggregate_Fields = {
  __typename?: 'config_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Config_Type_Max_Fields>;
  min?: Maybe<Config_Type_Min_Fields>;
};


/** aggregate fields of "config_type" */
export type Config_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Config_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "config_type". All fields are combined with a logical 'AND'. */
export type Config_Type_Bool_Exp = {
  _and?: Maybe<Array<Config_Type_Bool_Exp>>;
  _not?: Maybe<Config_Type_Bool_Exp>;
  _or?: Maybe<Array<Config_Type_Bool_Exp>>;
  configs?: Maybe<Config_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config_type" */
export enum Config_Type_Constraint {
  /** unique or primary key constraint */
  ConfigTypePkey = 'config_type_pkey'
}

export enum Config_Type_Enum {
  /** caramora address를 area로 매칭하는 테이블 */
  AreaBySidoSigungu = 'area_by_sido_sigungu',
  /** 기본 카라모라 수수료율 */
  DefaultCommissionRate = 'default_commission_rate',
  /** 기본 소비자 할인률 */
  DefaultDiscountRate = 'default_discount_rate',
  /** 최신 메세지 템플릿 */
  RecentMessageTemplate = 'recent_message_template',
  /** area를 총판명에 매칭하는 테이블 */
  SoleNameByArea = 'sole_name_by_area'
}

/** Boolean expression to compare columns of type "config_type_enum". All fields are combined with logical 'AND'. */
export type Config_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Config_Type_Enum>;
  _in?: Maybe<Array<Config_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Config_Type_Enum>;
  _nin?: Maybe<Array<Config_Type_Enum>>;
};

/** input type for inserting data into table "config_type" */
export type Config_Type_Insert_Input = {
  configs?: Maybe<Config_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Config_Type_Max_Fields = {
  __typename?: 'config_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Config_Type_Min_Fields = {
  __typename?: 'config_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "config_type" */
export type Config_Type_Mutation_Response = {
  __typename?: 'config_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Config_Type>;
};

/** input type for inserting object relation for remote table "config_type" */
export type Config_Type_Obj_Rel_Insert_Input = {
  data: Config_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Config_Type_On_Conflict>;
};

/** on conflict condition type for table "config_type" */
export type Config_Type_On_Conflict = {
  constraint: Config_Type_Constraint;
  update_columns?: Array<Config_Type_Update_Column>;
  where?: Maybe<Config_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config_type". */
export type Config_Type_Order_By = {
  configs_aggregate?: Maybe<Config_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: config_type */
export type Config_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config_type" */
export enum Config_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config_type" */
export type Config_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "config_type" */
export enum Config_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "config" */
export enum Config_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CsvContent = 'csv_content',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "coupon" */
export type Coupon = {
  __typename?: 'coupon';
  /** An account who created this coupon */
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  amount: Scalars['numeric'];
  code: Scalars['String'];
  /** An array relationship */
  coupon_and_accounts: Array<Coupon_And_Account>;
  /** An aggregate relationship */
  coupon_and_accounts_aggregate: Coupon_And_Account_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "coupon" */
export type CouponCoupon_And_AccountsArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


/** columns and relationships of "coupon" */
export type CouponCoupon_And_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};

/** aggregated selection of "coupon" */
export type Coupon_Aggregate = {
  __typename?: 'coupon_aggregate';
  aggregate?: Maybe<Coupon_Aggregate_Fields>;
  nodes: Array<Coupon>;
};

/** aggregate fields of "coupon" */
export type Coupon_Aggregate_Fields = {
  __typename?: 'coupon_aggregate_fields';
  avg?: Maybe<Coupon_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coupon_Max_Fields>;
  min?: Maybe<Coupon_Min_Fields>;
  stddev?: Maybe<Coupon_Stddev_Fields>;
  stddev_pop?: Maybe<Coupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coupon_Stddev_Samp_Fields>;
  sum?: Maybe<Coupon_Sum_Fields>;
  var_pop?: Maybe<Coupon_Var_Pop_Fields>;
  var_samp?: Maybe<Coupon_Var_Samp_Fields>;
  variance?: Maybe<Coupon_Variance_Fields>;
};


/** aggregate fields of "coupon" */
export type Coupon_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Coupon_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coupon" */
export type Coupon_Aggregate_Order_By = {
  avg?: Maybe<Coupon_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Coupon_Max_Order_By>;
  min?: Maybe<Coupon_Min_Order_By>;
  stddev?: Maybe<Coupon_Stddev_Order_By>;
  stddev_pop?: Maybe<Coupon_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Coupon_Stddev_Samp_Order_By>;
  sum?: Maybe<Coupon_Sum_Order_By>;
  var_pop?: Maybe<Coupon_Var_Pop_Order_By>;
  var_samp?: Maybe<Coupon_Var_Samp_Order_By>;
  variance?: Maybe<Coupon_Variance_Order_By>;
};

/** columns and relationships of "coupon_and_account" */
export type Coupon_And_Account = {
  __typename?: 'coupon_and_account';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  coupon: Scalars['uuid'];
  /** An object relationship */
  couponByCoupon: Coupon;
  /** An object relationship */
  coupon_status: Coupon_Status;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  status: Coupon_Status_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "coupon_and_account" */
export type Coupon_And_Account_Aggregate = {
  __typename?: 'coupon_and_account_aggregate';
  aggregate?: Maybe<Coupon_And_Account_Aggregate_Fields>;
  nodes: Array<Coupon_And_Account>;
};

/** aggregate fields of "coupon_and_account" */
export type Coupon_And_Account_Aggregate_Fields = {
  __typename?: 'coupon_and_account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Coupon_And_Account_Max_Fields>;
  min?: Maybe<Coupon_And_Account_Min_Fields>;
};


/** aggregate fields of "coupon_and_account" */
export type Coupon_And_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coupon_and_account" */
export type Coupon_And_Account_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Coupon_And_Account_Max_Order_By>;
  min?: Maybe<Coupon_And_Account_Min_Order_By>;
};

/** input type for inserting array relation for remote table "coupon_and_account" */
export type Coupon_And_Account_Arr_Rel_Insert_Input = {
  data: Array<Coupon_And_Account_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Coupon_And_Account_On_Conflict>;
};

/** Boolean expression to filter rows from the table "coupon_and_account". All fields are combined with a logical 'AND'. */
export type Coupon_And_Account_Bool_Exp = {
  _and?: Maybe<Array<Coupon_And_Account_Bool_Exp>>;
  _not?: Maybe<Coupon_And_Account_Bool_Exp>;
  _or?: Maybe<Array<Coupon_And_Account_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  coupon?: Maybe<Uuid_Comparison_Exp>;
  couponByCoupon?: Maybe<Coupon_Bool_Exp>;
  coupon_status?: Maybe<Coupon_Status_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Coupon_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon_and_account" */
export enum Coupon_And_Account_Constraint {
  /** unique or primary key constraint */
  CouponAccountPkey = 'coupon_account_pkey'
}

/** input type for inserting data into table "coupon_and_account" */
export type Coupon_And_Account_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  coupon?: Maybe<Scalars['uuid']>;
  couponByCoupon?: Maybe<Coupon_Obj_Rel_Insert_Input>;
  coupon_status?: Maybe<Coupon_Status_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Coupon_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Coupon_And_Account_Max_Fields = {
  __typename?: 'coupon_and_account_max_fields';
  account?: Maybe<Scalars['uuid']>;
  coupon?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coupon_and_account" */
export type Coupon_And_Account_Max_Order_By = {
  account?: Maybe<Order_By>;
  coupon?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coupon_And_Account_Min_Fields = {
  __typename?: 'coupon_and_account_min_fields';
  account?: Maybe<Scalars['uuid']>;
  coupon?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coupon_and_account" */
export type Coupon_And_Account_Min_Order_By = {
  account?: Maybe<Order_By>;
  coupon?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "coupon_and_account" */
export type Coupon_And_Account_Mutation_Response = {
  __typename?: 'coupon_and_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon_And_Account>;
};

/** on conflict condition type for table "coupon_and_account" */
export type Coupon_And_Account_On_Conflict = {
  constraint: Coupon_And_Account_Constraint;
  update_columns?: Array<Coupon_And_Account_Update_Column>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "coupon_and_account". */
export type Coupon_And_Account_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  coupon?: Maybe<Order_By>;
  couponByCoupon?: Maybe<Coupon_Order_By>;
  coupon_status?: Maybe<Coupon_Status_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: coupon_and_account */
export type Coupon_And_Account_Pk_Columns_Input = {
  account: Scalars['uuid'];
  coupon: Scalars['uuid'];
};

/** select columns of table "coupon_and_account" */
export enum Coupon_And_Account_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Coupon = 'coupon',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coupon_and_account" */
export type Coupon_And_Account_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  coupon?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Coupon_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "coupon_and_account" */
export enum Coupon_And_Account_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Coupon = 'coupon',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting array relation for remote table "coupon" */
export type Coupon_Arr_Rel_Insert_Input = {
  data: Array<Coupon_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Coupon_On_Conflict>;
};

/** aggregate avg on columns */
export type Coupon_Avg_Fields = {
  __typename?: 'coupon_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coupon" */
export type Coupon_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "coupon". All fields are combined with a logical 'AND'. */
export type Coupon_Bool_Exp = {
  _and?: Maybe<Array<Coupon_Bool_Exp>>;
  _not?: Maybe<Coupon_Bool_Exp>;
  _or?: Maybe<Array<Coupon_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  coupon_and_accounts?: Maybe<Coupon_And_Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon" */
export enum Coupon_Constraint {
  /** unique or primary key constraint */
  CouponPkey = 'coupon_pkey'
}

/** input type for incrementing numeric columns in table "coupon" */
export type Coupon_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "coupon" */
export type Coupon_Insert_Input = {
  /** An account who created this coupon */
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  amount?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  coupon_and_accounts?: Maybe<Coupon_And_Account_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Coupon_Max_Fields = {
  __typename?: 'coupon_max_fields';
  /** An account who created this coupon */
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coupon" */
export type Coupon_Max_Order_By = {
  /** An account who created this coupon */
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coupon_Min_Fields = {
  __typename?: 'coupon_min_fields';
  /** An account who created this coupon */
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coupon" */
export type Coupon_Min_Order_By = {
  /** An account who created this coupon */
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "coupon" */
export type Coupon_Mutation_Response = {
  __typename?: 'coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon>;
};

/** input type for inserting object relation for remote table "coupon" */
export type Coupon_Obj_Rel_Insert_Input = {
  data: Coupon_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Coupon_On_Conflict>;
};

/** on conflict condition type for table "coupon" */
export type Coupon_On_Conflict = {
  constraint: Coupon_Constraint;
  update_columns?: Array<Coupon_Update_Column>;
  where?: Maybe<Coupon_Bool_Exp>;
};

/** Ordering options when selecting data from "coupon". */
export type Coupon_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  coupon_and_accounts_aggregate?: Maybe<Coupon_And_Account_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: coupon */
export type Coupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "coupon" */
export enum Coupon_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coupon" */
export type Coupon_Set_Input = {
  /** An account who created this coupon */
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "coupon_status" */
export type Coupon_Status = {
  __typename?: 'coupon_status';
  /** An array relationship */
  coupon_and_accounts: Array<Coupon_And_Account>;
  /** An aggregate relationship */
  coupon_and_accounts_aggregate: Coupon_And_Account_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "coupon_status" */
export type Coupon_StatusCoupon_And_AccountsArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


/** columns and relationships of "coupon_status" */
export type Coupon_StatusCoupon_And_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};

/** aggregated selection of "coupon_status" */
export type Coupon_Status_Aggregate = {
  __typename?: 'coupon_status_aggregate';
  aggregate?: Maybe<Coupon_Status_Aggregate_Fields>;
  nodes: Array<Coupon_Status>;
};

/** aggregate fields of "coupon_status" */
export type Coupon_Status_Aggregate_Fields = {
  __typename?: 'coupon_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Coupon_Status_Max_Fields>;
  min?: Maybe<Coupon_Status_Min_Fields>;
};


/** aggregate fields of "coupon_status" */
export type Coupon_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Coupon_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "coupon_status". All fields are combined with a logical 'AND'. */
export type Coupon_Status_Bool_Exp = {
  _and?: Maybe<Array<Coupon_Status_Bool_Exp>>;
  _not?: Maybe<Coupon_Status_Bool_Exp>;
  _or?: Maybe<Array<Coupon_Status_Bool_Exp>>;
  coupon_and_accounts?: Maybe<Coupon_And_Account_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon_status" */
export enum Coupon_Status_Constraint {
  /** unique or primary key constraint */
  CouponStatusPkey = 'coupon_status_pkey'
}

export enum Coupon_Status_Enum {
  /** 발급됨 */
  Issued = 'issued',
  /** 사용됨 */
  Used = 'used'
}

/** Boolean expression to compare columns of type "coupon_status_enum". All fields are combined with logical 'AND'. */
export type Coupon_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Coupon_Status_Enum>;
  _in?: Maybe<Array<Coupon_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Coupon_Status_Enum>;
  _nin?: Maybe<Array<Coupon_Status_Enum>>;
};

/** input type for inserting data into table "coupon_status" */
export type Coupon_Status_Insert_Input = {
  coupon_and_accounts?: Maybe<Coupon_And_Account_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Coupon_Status_Max_Fields = {
  __typename?: 'coupon_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Coupon_Status_Min_Fields = {
  __typename?: 'coupon_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "coupon_status" */
export type Coupon_Status_Mutation_Response = {
  __typename?: 'coupon_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon_Status>;
};

/** input type for inserting object relation for remote table "coupon_status" */
export type Coupon_Status_Obj_Rel_Insert_Input = {
  data: Coupon_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Coupon_Status_On_Conflict>;
};

/** on conflict condition type for table "coupon_status" */
export type Coupon_Status_On_Conflict = {
  constraint: Coupon_Status_Constraint;
  update_columns?: Array<Coupon_Status_Update_Column>;
  where?: Maybe<Coupon_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "coupon_status". */
export type Coupon_Status_Order_By = {
  coupon_and_accounts_aggregate?: Maybe<Coupon_And_Account_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: coupon_status */
export type Coupon_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "coupon_status" */
export enum Coupon_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "coupon_status" */
export type Coupon_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "coupon_status" */
export enum Coupon_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Coupon_Stddev_Fields = {
  __typename?: 'coupon_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coupon" */
export type Coupon_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Coupon_Stddev_Pop_Fields = {
  __typename?: 'coupon_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coupon" */
export type Coupon_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Coupon_Stddev_Samp_Fields = {
  __typename?: 'coupon_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coupon" */
export type Coupon_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Coupon_Sum_Fields = {
  __typename?: 'coupon_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "coupon" */
export type Coupon_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "coupon" */
export enum Coupon_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Coupon_Var_Pop_Fields = {
  __typename?: 'coupon_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coupon" */
export type Coupon_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Coupon_Var_Samp_Fields = {
  __typename?: 'coupon_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coupon" */
export type Coupon_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Coupon_Variance_Fields = {
  __typename?: 'coupon_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coupon" */
export type Coupon_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/**
 * Array of Gallery, Store id for Landing page
 *
 *
 * columns and relationships of "curation"
 */
export type Curation = {
  __typename?: 'curation';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** All list of curated objects / Fixed list */
  list: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/**
 * Array of Gallery, Store id for Landing page
 *
 *
 * columns and relationships of "curation"
 */
export type CurationListArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "curation" */
export type Curation_Aggregate = {
  __typename?: 'curation_aggregate';
  aggregate?: Maybe<Curation_Aggregate_Fields>;
  nodes: Array<Curation>;
};

/** aggregate fields of "curation" */
export type Curation_Aggregate_Fields = {
  __typename?: 'curation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Curation_Max_Fields>;
  min?: Maybe<Curation_Min_Fields>;
};


/** aggregate fields of "curation" */
export type Curation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Curation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "curation" */
export type Curation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Curation_Max_Order_By>;
  min?: Maybe<Curation_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Curation_Append_Input = {
  /** All list of curated objects / Fixed list */
  list?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "curation" */
export type Curation_Arr_Rel_Insert_Input = {
  data: Array<Curation_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Curation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "curation". All fields are combined with a logical 'AND'. */
export type Curation_Bool_Exp = {
  _and?: Maybe<Array<Curation_Bool_Exp>>;
  _not?: Maybe<Curation_Bool_Exp>;
  _or?: Maybe<Array<Curation_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  list?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "curation" */
export enum Curation_Constraint {
  /** unique or primary key constraint */
  CurationPkey = 'curation_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Curation_Delete_At_Path_Input = {
  /** All list of curated objects / Fixed list */
  list?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Curation_Delete_Elem_Input = {
  /** All list of curated objects / Fixed list */
  list?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Curation_Delete_Key_Input = {
  /** All list of curated objects / Fixed list */
  list?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "curation" */
export type Curation_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** All list of curated objects / Fixed list */
  list?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Curation_Max_Fields = {
  __typename?: 'curation_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "curation" */
export type Curation_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Curation_Min_Fields = {
  __typename?: 'curation_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "curation" */
export type Curation_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "curation" */
export type Curation_Mutation_Response = {
  __typename?: 'curation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Curation>;
};

/** on conflict condition type for table "curation" */
export type Curation_On_Conflict = {
  constraint: Curation_Constraint;
  update_columns?: Array<Curation_Update_Column>;
  where?: Maybe<Curation_Bool_Exp>;
};

/** Ordering options when selecting data from "curation". */
export type Curation_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  list?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: curation */
export type Curation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Curation_Prepend_Input = {
  /** All list of curated objects / Fixed list */
  list?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "curation" */
export enum Curation_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  List = 'list',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "curation" */
export type Curation_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** All list of curated objects / Fixed list */
  list?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "curation" */
export enum Curation_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  List = 'list',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "fake_review" */
export type Fake_Review = {
  __typename?: 'fake_review';
  car: Scalars['uuid'];
  /** An object relationship */
  carByCar: Car;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** A computed field, executes function "fake_review_package_info" */
  package_detail?: Maybe<Array<Package>>;
  product_item: Scalars['jsonb'];
  /** A computed field, executes function "fake_review_detail" */
  product_item_detail?: Maybe<Array<Product_Item>>;
  rating: Scalars['numeric'];
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "fake_review" */
export type Fake_ReviewPackage_DetailArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/** columns and relationships of "fake_review" */
export type Fake_ReviewProduct_ItemArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "fake_review" */
export type Fake_ReviewProduct_Item_DetailArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};

/** aggregated selection of "fake_review" */
export type Fake_Review_Aggregate = {
  __typename?: 'fake_review_aggregate';
  aggregate?: Maybe<Fake_Review_Aggregate_Fields>;
  nodes: Array<Fake_Review>;
};

/** aggregate fields of "fake_review" */
export type Fake_Review_Aggregate_Fields = {
  __typename?: 'fake_review_aggregate_fields';
  avg?: Maybe<Fake_Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fake_Review_Max_Fields>;
  min?: Maybe<Fake_Review_Min_Fields>;
  stddev?: Maybe<Fake_Review_Stddev_Fields>;
  stddev_pop?: Maybe<Fake_Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fake_Review_Stddev_Samp_Fields>;
  sum?: Maybe<Fake_Review_Sum_Fields>;
  var_pop?: Maybe<Fake_Review_Var_Pop_Fields>;
  var_samp?: Maybe<Fake_Review_Var_Samp_Fields>;
  variance?: Maybe<Fake_Review_Variance_Fields>;
};


/** aggregate fields of "fake_review" */
export type Fake_Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fake_Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fake_review" */
export type Fake_Review_Aggregate_Order_By = {
  avg?: Maybe<Fake_Review_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Fake_Review_Max_Order_By>;
  min?: Maybe<Fake_Review_Min_Order_By>;
  stddev?: Maybe<Fake_Review_Stddev_Order_By>;
  stddev_pop?: Maybe<Fake_Review_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Fake_Review_Stddev_Samp_Order_By>;
  sum?: Maybe<Fake_Review_Sum_Order_By>;
  var_pop?: Maybe<Fake_Review_Var_Pop_Order_By>;
  var_samp?: Maybe<Fake_Review_Var_Samp_Order_By>;
  variance?: Maybe<Fake_Review_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fake_Review_Append_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "fake_review" */
export type Fake_Review_Arr_Rel_Insert_Input = {
  data: Array<Fake_Review_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Fake_Review_On_Conflict>;
};

/** aggregate avg on columns */
export type Fake_Review_Avg_Fields = {
  __typename?: 'fake_review_avg_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fake_review" */
export type Fake_Review_Avg_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fake_review". All fields are combined with a logical 'AND'. */
export type Fake_Review_Bool_Exp = {
  _and?: Maybe<Array<Fake_Review_Bool_Exp>>;
  _not?: Maybe<Fake_Review_Bool_Exp>;
  _or?: Maybe<Array<Fake_Review_Bool_Exp>>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  package_detail?: Maybe<Package_Bool_Exp>;
  product_item?: Maybe<Jsonb_Comparison_Exp>;
  product_item_detail?: Maybe<Product_Item_Bool_Exp>;
  rating?: Maybe<Numeric_Comparison_Exp>;
  rating_customer_service?: Maybe<Numeric_Comparison_Exp>;
  rating_facility?: Maybe<Numeric_Comparison_Exp>;
  rating_installation?: Maybe<Numeric_Comparison_Exp>;
  rating_price?: Maybe<Numeric_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "fake_review" */
export enum Fake_Review_Constraint {
  /** unique or primary key constraint */
  FakeReviewPkey = 'fake_review_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fake_Review_Delete_At_Path_Input = {
  product_item?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fake_Review_Delete_Elem_Input = {
  product_item?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fake_Review_Delete_Key_Input = {
  product_item?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "fake_review" */
export type Fake_Review_Inc_Input = {
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "fake_review" */
export type Fake_Review_Insert_Input = {
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_item?: Maybe<Scalars['jsonb']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Fake_Review_Max_Fields = {
  __typename?: 'fake_review_max_fields';
  car?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "fake_review" */
export type Fake_Review_Max_Order_By = {
  car?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fake_Review_Min_Fields = {
  __typename?: 'fake_review_min_fields';
  car?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "fake_review" */
export type Fake_Review_Min_Order_By = {
  car?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "fake_review" */
export type Fake_Review_Mutation_Response = {
  __typename?: 'fake_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fake_Review>;
};

/** on conflict condition type for table "fake_review" */
export type Fake_Review_On_Conflict = {
  constraint: Fake_Review_Constraint;
  update_columns?: Array<Fake_Review_Update_Column>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "fake_review". */
export type Fake_Review_Order_By = {
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  package_detail_aggregate?: Maybe<Package_Aggregate_Order_By>;
  product_item?: Maybe<Order_By>;
  product_item_detail_aggregate?: Maybe<Product_Item_Aggregate_Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: fake_review */
export type Fake_Review_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fake_Review_Prepend_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "fake_review" */
export enum Fake_Review_Select_Column {
  /** column name */
  Car = 'car',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCustomerService = 'rating_customer_service',
  /** column name */
  RatingFacility = 'rating_facility',
  /** column name */
  RatingInstallation = 'rating_installation',
  /** column name */
  RatingPrice = 'rating_price',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "fake_review" */
export type Fake_Review_Set_Input = {
  car?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_item?: Maybe<Scalars['jsonb']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Fake_Review_Stddev_Fields = {
  __typename?: 'fake_review_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fake_review" */
export type Fake_Review_Stddev_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fake_Review_Stddev_Pop_Fields = {
  __typename?: 'fake_review_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fake_review" */
export type Fake_Review_Stddev_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fake_Review_Stddev_Samp_Fields = {
  __typename?: 'fake_review_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fake_review" */
export type Fake_Review_Stddev_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Fake_Review_Sum_Fields = {
  __typename?: 'fake_review_sum_fields';
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "fake_review" */
export type Fake_Review_Sum_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** update columns of table "fake_review" */
export enum Fake_Review_Update_Column {
  /** column name */
  Car = 'car',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCustomerService = 'rating_customer_service',
  /** column name */
  RatingFacility = 'rating_facility',
  /** column name */
  RatingInstallation = 'rating_installation',
  /** column name */
  RatingPrice = 'rating_price',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Fake_Review_Var_Pop_Fields = {
  __typename?: 'fake_review_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fake_review" */
export type Fake_Review_Var_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fake_Review_Var_Samp_Fields = {
  __typename?: 'fake_review_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fake_review" */
export type Fake_Review_Var_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Fake_Review_Variance_Fields = {
  __typename?: 'fake_review_variance_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fake_review" */
export type Fake_Review_Variance_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/**
 * Information of the completed order
 *
 *
 * columns and relationships of "gallery"
 */
export type Gallery = {
  __typename?: 'gallery';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An object relationship */
  blogPostByBlogPost?: Maybe<Blog_Post>;
  blog_post?: Maybe<Scalars['uuid']>;
  car?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  carByCar?: Maybe<Car>;
  /** An object relationship */
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "gallery_product_item_detail" */
  gallery_product_item?: Maybe<Array<Product_Item>>;
  id: Scalars['uuid'];
  price: Scalars['numeric'];
  product_item: Scalars['jsonb'];
  scraped: Scalars['Boolean'];
  sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['Boolean']>;
  store?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  storeByStore?: Maybe<Store>;
  updated_at: Scalars['timestamptz'];
};


/**
 * Information of the completed order
 *
 *
 * columns and relationships of "gallery"
 */
export type GalleryGallery_Product_ItemArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


/**
 * Information of the completed order
 *
 *
 * columns and relationships of "gallery"
 */
export type GalleryProduct_ItemArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "gallery" */
export type Gallery_Aggregate = {
  __typename?: 'gallery_aggregate';
  aggregate?: Maybe<Gallery_Aggregate_Fields>;
  nodes: Array<Gallery>;
};

/** aggregate fields of "gallery" */
export type Gallery_Aggregate_Fields = {
  __typename?: 'gallery_aggregate_fields';
  avg?: Maybe<Gallery_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gallery_Max_Fields>;
  min?: Maybe<Gallery_Min_Fields>;
  stddev?: Maybe<Gallery_Stddev_Fields>;
  stddev_pop?: Maybe<Gallery_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gallery_Stddev_Samp_Fields>;
  sum?: Maybe<Gallery_Sum_Fields>;
  var_pop?: Maybe<Gallery_Var_Pop_Fields>;
  var_samp?: Maybe<Gallery_Var_Samp_Fields>;
  variance?: Maybe<Gallery_Variance_Fields>;
};


/** aggregate fields of "gallery" */
export type Gallery_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gallery_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gallery" */
export type Gallery_Aggregate_Order_By = {
  avg?: Maybe<Gallery_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Gallery_Max_Order_By>;
  min?: Maybe<Gallery_Min_Order_By>;
  stddev?: Maybe<Gallery_Stddev_Order_By>;
  stddev_pop?: Maybe<Gallery_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Gallery_Stddev_Samp_Order_By>;
  sum?: Maybe<Gallery_Sum_Order_By>;
  var_pop?: Maybe<Gallery_Var_Pop_Order_By>;
  var_samp?: Maybe<Gallery_Var_Samp_Order_By>;
  variance?: Maybe<Gallery_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Gallery_Append_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "gallery" */
export type Gallery_Arr_Rel_Insert_Input = {
  data: Array<Gallery_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Gallery_On_Conflict>;
};

/** aggregate avg on columns */
export type Gallery_Avg_Fields = {
  __typename?: 'gallery_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gallery" */
export type Gallery_Avg_Order_By = {
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gallery". All fields are combined with a logical 'AND'. */
export type Gallery_Bool_Exp = {
  _and?: Maybe<Array<Gallery_Bool_Exp>>;
  _not?: Maybe<Gallery_Bool_Exp>;
  _or?: Maybe<Array<Gallery_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  blogPostByBlogPost?: Maybe<Blog_Post_Bool_Exp>;
  blog_post?: Maybe<Uuid_Comparison_Exp>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Bool_Exp>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  gallery_product_item?: Maybe<Product_Item_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  product_item?: Maybe<Jsonb_Comparison_Exp>;
  scraped?: Maybe<Boolean_Comparison_Exp>;
  sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Boolean_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gallery" */
export enum Gallery_Constraint {
  /** unique or primary key constraint */
  GalleryBlogPostKey = 'gallery_blog_post_key',
  /** unique or primary key constraint */
  GalleryPkey = 'gallery_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Gallery_Delete_At_Path_Input = {
  product_item?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Gallery_Delete_Elem_Input = {
  product_item?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Gallery_Delete_Key_Input = {
  product_item?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "gallery" */
export type Gallery_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "gallery" */
export type Gallery_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  blogPostByBlogPost?: Maybe<Blog_Post_Obj_Rel_Insert_Input>;
  blog_post?: Maybe<Scalars['uuid']>;
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Obj_Rel_Insert_Input>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  scraped?: Maybe<Scalars['Boolean']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['Boolean']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gallery_Max_Fields = {
  __typename?: 'gallery_max_fields';
  account?: Maybe<Scalars['uuid']>;
  blog_post?: Maybe<Scalars['uuid']>;
  car?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "gallery" */
export type Gallery_Max_Order_By = {
  account?: Maybe<Order_By>;
  blog_post?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Gallery_Min_Fields = {
  __typename?: 'gallery_min_fields';
  account?: Maybe<Scalars['uuid']>;
  blog_post?: Maybe<Scalars['uuid']>;
  car?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "gallery" */
export type Gallery_Min_Order_By = {
  account?: Maybe<Order_By>;
  blog_post?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "gallery" */
export type Gallery_Mutation_Response = {
  __typename?: 'gallery_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gallery>;
};

/** input type for inserting object relation for remote table "gallery" */
export type Gallery_Obj_Rel_Insert_Input = {
  data: Gallery_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Gallery_On_Conflict>;
};

/** on conflict condition type for table "gallery" */
export type Gallery_On_Conflict = {
  constraint: Gallery_Constraint;
  update_columns?: Array<Gallery_Update_Column>;
  where?: Maybe<Gallery_Bool_Exp>;
};

/** Ordering options when selecting data from "gallery". */
export type Gallery_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  blogPostByBlogPost?: Maybe<Blog_Post_Order_By>;
  blog_post?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Order_By>;
  car_sunroof_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gallery_product_item_aggregate?: Maybe<Product_Item_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  scraped?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: gallery */
export type Gallery_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Gallery_Prepend_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "gallery" */
export enum Gallery_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  BlogPost = 'blog_post',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Scraped = 'scraped',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gallery" */
export type Gallery_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  blog_post?: Maybe<Scalars['uuid']>;
  car?: Maybe<Scalars['uuid']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  scraped?: Maybe<Scalars['Boolean']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['Boolean']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gallery_Stddev_Fields = {
  __typename?: 'gallery_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gallery" */
export type Gallery_Stddev_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Gallery_Stddev_Pop_Fields = {
  __typename?: 'gallery_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gallery" */
export type Gallery_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Gallery_Stddev_Samp_Fields = {
  __typename?: 'gallery_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gallery" */
export type Gallery_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Gallery_Sum_Fields = {
  __typename?: 'gallery_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "gallery" */
export type Gallery_Sum_Order_By = {
  price?: Maybe<Order_By>;
};

/** update columns of table "gallery" */
export enum Gallery_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  BlogPost = 'blog_post',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Scraped = 'scraped',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Gallery_Var_Pop_Fields = {
  __typename?: 'gallery_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gallery" */
export type Gallery_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Gallery_Var_Samp_Fields = {
  __typename?: 'gallery_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gallery" */
export type Gallery_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Gallery_Variance_Fields = {
  __typename?: 'gallery_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gallery" */
export type Gallery_Variance_Order_By = {
  price?: Maybe<Order_By>;
};


export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>;
};

/** columns and relationships of "geography_columns" */
export type Geography_Columns = {
  __typename?: 'geography_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geography_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['name']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geography_columns" */
export type Geography_Columns_Aggregate = {
  __typename?: 'geography_columns_aggregate';
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_Fields = {
  __typename?: 'geography_columns_aggregate_fields';
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
};


/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Geography_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geography_Columns_Avg_Fields = {
  __typename?: 'geography_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type Geography_Columns_Bool_Exp = {
  _and?: Maybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: Maybe<Geography_Columns_Bool_Exp>;
  _or?: Maybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: Maybe<Int_Comparison_Exp>;
  f_geography_column?: Maybe<Name_Comparison_Exp>;
  f_table_catalog?: Maybe<Name_Comparison_Exp>;
  f_table_name?: Maybe<Name_Comparison_Exp>;
  f_table_schema?: Maybe<Name_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Geography_Columns_Max_Fields = {
  __typename?: 'geography_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geography_Columns_Min_Fields = {
  __typename?: 'geography_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "geography_columns". */
export type Geography_Columns_Order_By = {
  coord_dimension?: Maybe<Order_By>;
  f_geography_column?: Maybe<Order_By>;
  f_table_catalog?: Maybe<Order_By>;
  f_table_name?: Maybe<Order_By>;
  f_table_schema?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "geography_columns" */
export enum Geography_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeographyColumn = 'f_geography_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Geography_Columns_Stddev_Fields = {
  __typename?: 'geography_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geography_Columns_Stddev_Pop_Fields = {
  __typename?: 'geography_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geography_Columns_Stddev_Samp_Fields = {
  __typename?: 'geography_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Geography_Columns_Sum_Fields = {
  __typename?: 'geography_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Geography_Columns_Var_Pop_Fields = {
  __typename?: 'geography_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geography_Columns_Var_Samp_Fields = {
  __typename?: 'geography_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geography_Columns_Variance_Fields = {
  __typename?: 'geography_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>;
};

/** columns and relationships of "geometry_columns" */
export type Geometry_Columns = {
  __typename?: 'geometry_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geometry_columns" */
export type Geometry_Columns_Aggregate = {
  __typename?: 'geometry_columns_aggregate';
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_Fields = {
  __typename?: 'geometry_columns_aggregate_fields';
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
};


/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geometry_Columns_Avg_Fields = {
  __typename?: 'geometry_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type Geometry_Columns_Bool_Exp = {
  _and?: Maybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: Maybe<Geometry_Columns_Bool_Exp>;
  _or?: Maybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: Maybe<Int_Comparison_Exp>;
  f_geometry_column?: Maybe<Name_Comparison_Exp>;
  f_table_catalog?: Maybe<String_Comparison_Exp>;
  f_table_name?: Maybe<Name_Comparison_Exp>;
  f_table_schema?: Maybe<Name_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type Geometry_Columns_Inc_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "geometry_columns" */
export type Geometry_Columns_Insert_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Geometry_Columns_Max_Fields = {
  __typename?: 'geometry_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geometry_Columns_Min_Fields = {
  __typename?: 'geometry_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "geometry_columns" */
export type Geometry_Columns_Mutation_Response = {
  __typename?: 'geometry_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type Geometry_Columns_Order_By = {
  coord_dimension?: Maybe<Order_By>;
  f_geometry_column?: Maybe<Order_By>;
  f_table_catalog?: Maybe<Order_By>;
  f_table_name?: Maybe<Order_By>;
  f_table_schema?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "geometry_columns" */
export enum Geometry_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeometryColumn = 'f_geometry_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "geometry_columns" */
export type Geometry_Columns_Set_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Geometry_Columns_Stddev_Fields = {
  __typename?: 'geometry_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geometry_Columns_Stddev_Pop_Fields = {
  __typename?: 'geometry_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geometry_Columns_Stddev_Samp_Fields = {
  __typename?: 'geometry_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Geometry_Columns_Sum_Fields = {
  __typename?: 'geometry_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Geometry_Columns_Var_Pop_Fields = {
  __typename?: 'geometry_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geometry_Columns_Var_Samp_Fields = {
  __typename?: 'geometry_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geometry_Columns_Variance_Fields = {
  __typename?: 'geometry_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: Maybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};

export type Get_Account_Performance_By_Used_Car_Status_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

export type Get_Account_Performance_For_Chat_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

export type Get_Account_Performance_For_First_Call_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

export type Get_Account_Performance_For_Match_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

export type Get_Account_Performance_For_Reservation_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

export type Get_Account_Performance_For_Support_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

export type Get_Account_Throughput_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

export type Get_Lead_Status_Statistics_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  start_date?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "hashtag" */
export type Hashtag = {
  __typename?: 'hashtag';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  blog_post_and_hashtags: Array<Blog_Post_And_Hashtag>;
  /** An aggregate relationship */
  blog_post_and_hashtags_aggregate: Blog_Post_And_Hashtag_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  store_and_hashtags: Array<Store_And_Hashtag>;
  /** An aggregate relationship */
  store_and_hashtags_aggregate: Store_And_Hashtag_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "hashtag" */
export type HashtagBlog_Post_And_HashtagsArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


/** columns and relationships of "hashtag" */
export type HashtagBlog_Post_And_Hashtags_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


/** columns and relationships of "hashtag" */
export type HashtagStore_And_HashtagsArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};


/** columns and relationships of "hashtag" */
export type HashtagStore_And_Hashtags_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};

/** aggregated selection of "hashtag" */
export type Hashtag_Aggregate = {
  __typename?: 'hashtag_aggregate';
  aggregate?: Maybe<Hashtag_Aggregate_Fields>;
  nodes: Array<Hashtag>;
};

/** aggregate fields of "hashtag" */
export type Hashtag_Aggregate_Fields = {
  __typename?: 'hashtag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hashtag_Max_Fields>;
  min?: Maybe<Hashtag_Min_Fields>;
};


/** aggregate fields of "hashtag" */
export type Hashtag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hashtag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hashtag" */
export type Hashtag_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hashtag_Max_Order_By>;
  min?: Maybe<Hashtag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hashtag" */
export type Hashtag_Arr_Rel_Insert_Input = {
  data: Array<Hashtag_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Hashtag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hashtag". All fields are combined with a logical 'AND'. */
export type Hashtag_Bool_Exp = {
  _and?: Maybe<Array<Hashtag_Bool_Exp>>;
  _not?: Maybe<Hashtag_Bool_Exp>;
  _or?: Maybe<Array<Hashtag_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  blog_post_and_hashtags?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  store_and_hashtags?: Maybe<Store_And_Hashtag_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hashtag" */
export enum Hashtag_Constraint {
  /** unique or primary key constraint */
  HashtagPkey = 'hashtag_pkey'
}

/** input type for inserting data into table "hashtag" */
export type Hashtag_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  blog_post_and_hashtags?: Maybe<Blog_Post_And_Hashtag_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  store_and_hashtags?: Maybe<Store_And_Hashtag_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Hashtag_Max_Fields = {
  __typename?: 'hashtag_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hashtag" */
export type Hashtag_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hashtag_Min_Fields = {
  __typename?: 'hashtag_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hashtag" */
export type Hashtag_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "hashtag" */
export type Hashtag_Mutation_Response = {
  __typename?: 'hashtag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hashtag>;
};

/** input type for inserting object relation for remote table "hashtag" */
export type Hashtag_Obj_Rel_Insert_Input = {
  data: Hashtag_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Hashtag_On_Conflict>;
};

/** on conflict condition type for table "hashtag" */
export type Hashtag_On_Conflict = {
  constraint: Hashtag_Constraint;
  update_columns?: Array<Hashtag_Update_Column>;
  where?: Maybe<Hashtag_Bool_Exp>;
};

/** Ordering options when selecting data from "hashtag". */
export type Hashtag_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  blog_post_and_hashtags_aggregate?: Maybe<Blog_Post_And_Hashtag_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  store_and_hashtags_aggregate?: Maybe<Store_And_Hashtag_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: hashtag */
export type Hashtag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hashtag" */
export enum Hashtag_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "hashtag" */
export type Hashtag_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "hashtag" */
export enum Hashtag_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "int4range". All fields are combined with logical 'AND'. */
export type Int4range_Comparison_Exp = {
  _eq?: Maybe<Scalars['int4range']>;
  _gt?: Maybe<Scalars['int4range']>;
  _gte?: Maybe<Scalars['int4range']>;
  _in?: Maybe<Array<Scalars['int4range']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['int4range']>;
  _lte?: Maybe<Scalars['int4range']>;
  _neq?: Maybe<Scalars['int4range']>;
  _nin?: Maybe<Array<Scalars['int4range']>>;
};

/** columns and relationships of "inventory" */
export type Inventory = {
  __typename?: 'inventory';
  attribute: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  productItemByProductItem: Product_Item;
  product_item: Scalars['uuid'];
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "inventory" */
export type InventoryAttributeArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "inventory" */
export type Inventory_Aggregate = {
  __typename?: 'inventory_aggregate';
  aggregate?: Maybe<Inventory_Aggregate_Fields>;
  nodes: Array<Inventory>;
};

/** aggregate fields of "inventory" */
export type Inventory_Aggregate_Fields = {
  __typename?: 'inventory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Inventory_Max_Fields>;
  min?: Maybe<Inventory_Min_Fields>;
};


/** aggregate fields of "inventory" */
export type Inventory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inventory" */
export type Inventory_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Max_Order_By>;
  min?: Maybe<Inventory_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Inventory_Append_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "inventory" */
export type Inventory_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Inventory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "inventory". All fields are combined with a logical 'AND'. */
export type Inventory_Bool_Exp = {
  _and?: Maybe<Array<Inventory_Bool_Exp>>;
  _not?: Maybe<Inventory_Bool_Exp>;
  _or?: Maybe<Array<Inventory_Bool_Exp>>;
  attribute?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  delivered_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  productItemByProductItem?: Maybe<Product_Item_Bool_Exp>;
  product_item?: Maybe<Uuid_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory" */
export enum Inventory_Constraint {
  /** unique or primary key constraint */
  InventoryPkey = 'inventory_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Inventory_Delete_At_Path_Input = {
  attribute?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Inventory_Delete_Elem_Input = {
  attribute?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Inventory_Delete_Key_Input = {
  attribute?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "inventory" */
export type Inventory_Insert_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productItemByProductItem?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Inventory_Max_Fields = {
  __typename?: 'inventory_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "inventory" */
export type Inventory_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  delivered_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Min_Fields = {
  __typename?: 'inventory_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "inventory" */
export type Inventory_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  delivered_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory" */
export type Inventory_Mutation_Response = {
  __typename?: 'inventory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inventory>;
};

/** on conflict condition type for table "inventory" */
export type Inventory_On_Conflict = {
  constraint: Inventory_Constraint;
  update_columns?: Array<Inventory_Update_Column>;
  where?: Maybe<Inventory_Bool_Exp>;
};

/** Ordering options when selecting data from "inventory". */
export type Inventory_Order_By = {
  attribute?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  delivered_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productItemByProductItem?: Maybe<Product_Item_Order_By>;
  product_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: inventory */
export type Inventory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Inventory_Prepend_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "inventory" */
export enum Inventory_Select_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliveredAt = 'delivered_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "inventory" */
export type Inventory_Set_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  delivered_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "inventory" */
export enum Inventory_Update_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliveredAt = 'delivered_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "invoice" */
export type Invoice = {
  __typename?: 'invoice';
  /** Account who created this row */
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  accounts_receivable_and_invoices: Array<Accounts_Receivable_And_Invoice>;
  /** An aggregate relationship */
  accounts_receivable_and_invoices_aggregate: Accounts_Receivable_And_Invoice_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  invoice_and_transactions: Array<Invoice_And_Transaction>;
  /** An aggregate relationship */
  invoice_and_transactions_aggregate: Invoice_And_Transaction_Aggregate;
  /** An array relationship */
  invoice_and_transfers: Array<Invoice_And_Transfer>;
  /** An aggregate relationship */
  invoice_and_transfers_aggregate: Invoice_And_Transfer_Aggregate;
  /** An object relationship */
  invoice_status: Invoice_Status;
  item: Scalars['jsonb'];
  price: Scalars['numeric'];
  status: Invoice_Status_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "invoice" */
export type InvoiceAccounts_Receivable_And_InvoicesArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceAccounts_Receivable_And_Invoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoice_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoice_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoice_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoice_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceItemArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "invoice" */
export type Invoice_Aggregate = {
  __typename?: 'invoice_aggregate';
  aggregate?: Maybe<Invoice_Aggregate_Fields>;
  nodes: Array<Invoice>;
};

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_Fields = {
  __typename?: 'invoice_aggregate_fields';
  avg?: Maybe<Invoice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Max_Fields>;
  min?: Maybe<Invoice_Min_Fields>;
  stddev?: Maybe<Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Sum_Fields>;
  var_pop?: Maybe<Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Variance_Fields>;
};


/** aggregate fields of "invoice" */
export type Invoice_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice" */
export type Invoice_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Max_Order_By>;
  min?: Maybe<Invoice_Min_Order_By>;
  stddev?: Maybe<Invoice_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Variance_Order_By>;
};

/** columns and relationships of "invoice_and_transaction" */
export type Invoice_And_Transaction = {
  __typename?: 'invoice_and_transaction';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice: Scalars['uuid'];
  /** An object relationship */
  invoiceByInvoice: Invoice;
  transaction: Scalars['uuid'];
  /** An object relationship */
  transactionByTransaction: Transaction;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "invoice_and_transaction" */
export type Invoice_And_Transaction_Aggregate = {
  __typename?: 'invoice_and_transaction_aggregate';
  aggregate?: Maybe<Invoice_And_Transaction_Aggregate_Fields>;
  nodes: Array<Invoice_And_Transaction>;
};

/** aggregate fields of "invoice_and_transaction" */
export type Invoice_And_Transaction_Aggregate_Fields = {
  __typename?: 'invoice_and_transaction_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Invoice_And_Transaction_Max_Fields>;
  min?: Maybe<Invoice_And_Transaction_Min_Fields>;
};


/** aggregate fields of "invoice_and_transaction" */
export type Invoice_And_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_and_transaction" */
export type Invoice_And_Transaction_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_And_Transaction_Max_Order_By>;
  min?: Maybe<Invoice_And_Transaction_Min_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_and_transaction" */
export type Invoice_And_Transaction_Arr_Rel_Insert_Input = {
  data: Array<Invoice_And_Transaction_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Invoice_And_Transaction_On_Conflict>;
};

/** Boolean expression to filter rows from the table "invoice_and_transaction". All fields are combined with a logical 'AND'. */
export type Invoice_And_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Invoice_And_Transaction_Bool_Exp>>;
  _not?: Maybe<Invoice_And_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Invoice_And_Transaction_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  invoice?: Maybe<Uuid_Comparison_Exp>;
  invoiceByInvoice?: Maybe<Invoice_Bool_Exp>;
  transaction?: Maybe<Uuid_Comparison_Exp>;
  transactionByTransaction?: Maybe<Transaction_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_and_transaction" */
export enum Invoice_And_Transaction_Constraint {
  /** unique or primary key constraint */
  TransactionInvoicePkey = 'transaction_invoice_pkey'
}

/** input type for inserting data into table "invoice_and_transaction" */
export type Invoice_And_Transaction_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  invoiceByInvoice?: Maybe<Invoice_Obj_Rel_Insert_Input>;
  transaction?: Maybe<Scalars['uuid']>;
  transactionByTransaction?: Maybe<Transaction_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoice_And_Transaction_Max_Fields = {
  __typename?: 'invoice_and_transaction_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoice_and_transaction" */
export type Invoice_And_Transaction_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_And_Transaction_Min_Fields = {
  __typename?: 'invoice_and_transaction_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoice_and_transaction" */
export type Invoice_And_Transaction_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoice_and_transaction" */
export type Invoice_And_Transaction_Mutation_Response = {
  __typename?: 'invoice_and_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_And_Transaction>;
};

/** on conflict condition type for table "invoice_and_transaction" */
export type Invoice_And_Transaction_On_Conflict = {
  constraint: Invoice_And_Transaction_Constraint;
  update_columns?: Array<Invoice_And_Transaction_Update_Column>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_and_transaction". */
export type Invoice_And_Transaction_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  invoiceByInvoice?: Maybe<Invoice_Order_By>;
  transaction?: Maybe<Order_By>;
  transactionByTransaction?: Maybe<Transaction_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invoice_and_transaction */
export type Invoice_And_Transaction_Pk_Columns_Input = {
  invoice: Scalars['uuid'];
  transaction: Scalars['uuid'];
};

/** select columns of table "invoice_and_transaction" */
export enum Invoice_And_Transaction_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoice_and_transaction" */
export type Invoice_And_Transaction_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "invoice_and_transaction" */
export enum Invoice_And_Transaction_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "invoice_and_transfer" */
export type Invoice_And_Transfer = {
  __typename?: 'invoice_and_transfer';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice: Scalars['uuid'];
  /** An object relationship */
  invoiceByInvoice: Invoice;
  transfer: Scalars['uuid'];
  /** An object relationship */
  transferByTransfer: Transfer;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "invoice_and_transfer" */
export type Invoice_And_Transfer_Aggregate = {
  __typename?: 'invoice_and_transfer_aggregate';
  aggregate?: Maybe<Invoice_And_Transfer_Aggregate_Fields>;
  nodes: Array<Invoice_And_Transfer>;
};

/** aggregate fields of "invoice_and_transfer" */
export type Invoice_And_Transfer_Aggregate_Fields = {
  __typename?: 'invoice_and_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Invoice_And_Transfer_Max_Fields>;
  min?: Maybe<Invoice_And_Transfer_Min_Fields>;
};


/** aggregate fields of "invoice_and_transfer" */
export type Invoice_And_Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_and_transfer" */
export type Invoice_And_Transfer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_And_Transfer_Max_Order_By>;
  min?: Maybe<Invoice_And_Transfer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_and_transfer" */
export type Invoice_And_Transfer_Arr_Rel_Insert_Input = {
  data: Array<Invoice_And_Transfer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Invoice_And_Transfer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "invoice_and_transfer". All fields are combined with a logical 'AND'. */
export type Invoice_And_Transfer_Bool_Exp = {
  _and?: Maybe<Array<Invoice_And_Transfer_Bool_Exp>>;
  _not?: Maybe<Invoice_And_Transfer_Bool_Exp>;
  _or?: Maybe<Array<Invoice_And_Transfer_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  invoice?: Maybe<Uuid_Comparison_Exp>;
  invoiceByInvoice?: Maybe<Invoice_Bool_Exp>;
  transfer?: Maybe<Uuid_Comparison_Exp>;
  transferByTransfer?: Maybe<Transfer_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_and_transfer" */
export enum Invoice_And_Transfer_Constraint {
  /** unique or primary key constraint */
  TransferInvoicePkey = 'transfer_invoice_pkey'
}

/** input type for inserting data into table "invoice_and_transfer" */
export type Invoice_And_Transfer_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  invoiceByInvoice?: Maybe<Invoice_Obj_Rel_Insert_Input>;
  transfer?: Maybe<Scalars['uuid']>;
  transferByTransfer?: Maybe<Transfer_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoice_And_Transfer_Max_Fields = {
  __typename?: 'invoice_and_transfer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoice_and_transfer" */
export type Invoice_And_Transfer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_And_Transfer_Min_Fields = {
  __typename?: 'invoice_and_transfer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoice_and_transfer" */
export type Invoice_And_Transfer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoice_and_transfer" */
export type Invoice_And_Transfer_Mutation_Response = {
  __typename?: 'invoice_and_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_And_Transfer>;
};

/** on conflict condition type for table "invoice_and_transfer" */
export type Invoice_And_Transfer_On_Conflict = {
  constraint: Invoice_And_Transfer_Constraint;
  update_columns?: Array<Invoice_And_Transfer_Update_Column>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_and_transfer". */
export type Invoice_And_Transfer_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invoice?: Maybe<Order_By>;
  invoiceByInvoice?: Maybe<Invoice_Order_By>;
  transfer?: Maybe<Order_By>;
  transferByTransfer?: Maybe<Transfer_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invoice_and_transfer */
export type Invoice_And_Transfer_Pk_Columns_Input = {
  invoice: Scalars['uuid'];
  transfer: Scalars['uuid'];
};

/** select columns of table "invoice_and_transfer" */
export enum Invoice_And_Transfer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoice_and_transfer" */
export type Invoice_And_Transfer_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invoice?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "invoice_and_transfer" */
export enum Invoice_And_Transfer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Invoice_Append_Input = {
  item?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "invoice" */
export type Invoice_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Avg_Fields = {
  __typename?: 'invoice_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice" */
export type Invoice_Avg_Order_By = {
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Bool_Exp>>;
  _not?: Maybe<Invoice_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  accounts_receivable_and_invoices?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoice_and_transactions?: Maybe<Invoice_And_Transaction_Bool_Exp>;
  invoice_and_transfers?: Maybe<Invoice_And_Transfer_Bool_Exp>;
  invoice_status?: Maybe<Invoice_Status_Bool_Exp>;
  item?: Maybe<Jsonb_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  status?: Maybe<Invoice_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint */
  InvoicePkey = 'invoice_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Invoice_Delete_At_Path_Input = {
  item?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Invoice_Delete_Elem_Input = {
  item?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Invoice_Delete_Key_Input = {
  item?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  accounts_receivable_and_invoices?: Maybe<Accounts_Receivable_And_Invoice_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_and_transactions?: Maybe<Invoice_And_Transaction_Arr_Rel_Insert_Input>;
  invoice_and_transfers?: Maybe<Invoice_And_Transfer_Arr_Rel_Insert_Input>;
  invoice_status?: Maybe<Invoice_Status_Obj_Rel_Insert_Input>;
  item?: Maybe<Scalars['jsonb']>;
  price?: Maybe<Scalars['numeric']>;
  status?: Maybe<Invoice_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoice_Max_Fields = {
  __typename?: 'invoice_max_fields';
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoice" */
export type Invoice_Max_Order_By = {
  /** Account who created this row */
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Min_Fields = {
  __typename?: 'invoice_min_fields';
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoice" */
export type Invoice_Min_Order_By = {
  /** Account who created this row */
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoice" */
export type Invoice_Mutation_Response = {
  __typename?: 'invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>;
};

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Invoice_On_Conflict>;
};

/** on conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint;
  update_columns?: Array<Invoice_Update_Column>;
  where?: Maybe<Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  accounts_receivable_and_invoices_aggregate?: Maybe<Accounts_Receivable_And_Invoice_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_and_transactions_aggregate?: Maybe<Invoice_And_Transaction_Aggregate_Order_By>;
  invoice_and_transfers_aggregate?: Maybe<Invoice_And_Transfer_Aggregate_Order_By>;
  invoice_status?: Maybe<Invoice_Status_Order_By>;
  item?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Invoice_Prepend_Input = {
  item?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Item = 'item',
  /** column name */
  Price = 'price',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item?: Maybe<Scalars['jsonb']>;
  price?: Maybe<Scalars['numeric']>;
  status?: Maybe<Invoice_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "invoice_status" */
export type Invoice_Status = {
  __typename?: 'invoice_status';
  description: Scalars['String'];
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "invoice_status" */
export type Invoice_StatusInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "invoice_status" */
export type Invoice_StatusInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};

/** aggregated selection of "invoice_status" */
export type Invoice_Status_Aggregate = {
  __typename?: 'invoice_status_aggregate';
  aggregate?: Maybe<Invoice_Status_Aggregate_Fields>;
  nodes: Array<Invoice_Status>;
};

/** aggregate fields of "invoice_status" */
export type Invoice_Status_Aggregate_Fields = {
  __typename?: 'invoice_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Invoice_Status_Max_Fields>;
  min?: Maybe<Invoice_Status_Min_Fields>;
};


/** aggregate fields of "invoice_status" */
export type Invoice_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "invoice_status". All fields are combined with a logical 'AND'. */
export type Invoice_Status_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Status_Bool_Exp>>;
  _not?: Maybe<Invoice_Status_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  invoices?: Maybe<Invoice_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_status" */
export enum Invoice_Status_Constraint {
  /** unique or primary key constraint */
  InvoiceStatusPkey = 'invoice_status_pkey'
}

export enum Invoice_Status_Enum {
  /** 인보이스 발급 */
  Created = 'created',
  /** 부도 */
  Default = 'default',
  /** 결제 완료 */
  Finished = 'finished'
}

/** Boolean expression to compare columns of type "invoice_status_enum". All fields are combined with logical 'AND'. */
export type Invoice_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Invoice_Status_Enum>;
  _in?: Maybe<Array<Invoice_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Invoice_Status_Enum>;
  _nin?: Maybe<Array<Invoice_Status_Enum>>;
};

/** input type for inserting data into table "invoice_status" */
export type Invoice_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  invoices?: Maybe<Invoice_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Invoice_Status_Max_Fields = {
  __typename?: 'invoice_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Invoice_Status_Min_Fields = {
  __typename?: 'invoice_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invoice_status" */
export type Invoice_Status_Mutation_Response = {
  __typename?: 'invoice_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Status>;
};

/** input type for inserting object relation for remote table "invoice_status" */
export type Invoice_Status_Obj_Rel_Insert_Input = {
  data: Invoice_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Invoice_Status_On_Conflict>;
};

/** on conflict condition type for table "invoice_status" */
export type Invoice_Status_On_Conflict = {
  constraint: Invoice_Status_Constraint;
  update_columns?: Array<Invoice_Status_Update_Column>;
  where?: Maybe<Invoice_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_status". */
export type Invoice_Status_Order_By = {
  description?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoice_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: invoice_status */
export type Invoice_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "invoice_status" */
export enum Invoice_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "invoice_status" */
export type Invoice_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "invoice_status" */
export enum Invoice_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Invoice_Stddev_Fields = {
  __typename?: 'invoice_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice" */
export type Invoice_Stddev_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Stddev_Pop_Fields = {
  __typename?: 'invoice_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice" */
export type Invoice_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Stddev_Samp_Fields = {
  __typename?: 'invoice_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice" */
export type Invoice_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Invoice_Sum_Fields = {
  __typename?: 'invoice_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "invoice" */
export type Invoice_Sum_Order_By = {
  price?: Maybe<Order_By>;
};

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Item = 'item',
  /** column name */
  Price = 'price',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Invoice_Var_Pop_Fields = {
  __typename?: 'invoice_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice" */
export type Invoice_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Var_Samp_Fields = {
  __typename?: 'invoice_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice" */
export type Invoice_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Variance_Fields = {
  __typename?: 'invoice_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice" */
export type Invoice_Variance_Order_By = {
  price?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "kakao_and_account" */
export type Kakao_And_Account = {
  __typename?: 'kakao_and_account';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  kakao_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "kakao_and_account" */
export type Kakao_And_Account_Aggregate = {
  __typename?: 'kakao_and_account_aggregate';
  aggregate?: Maybe<Kakao_And_Account_Aggregate_Fields>;
  nodes: Array<Kakao_And_Account>;
};

/** aggregate fields of "kakao_and_account" */
export type Kakao_And_Account_Aggregate_Fields = {
  __typename?: 'kakao_and_account_aggregate_fields';
  avg?: Maybe<Kakao_And_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Kakao_And_Account_Max_Fields>;
  min?: Maybe<Kakao_And_Account_Min_Fields>;
  stddev?: Maybe<Kakao_And_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Kakao_And_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kakao_And_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Kakao_And_Account_Sum_Fields>;
  var_pop?: Maybe<Kakao_And_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Kakao_And_Account_Var_Samp_Fields>;
  variance?: Maybe<Kakao_And_Account_Variance_Fields>;
};


/** aggregate fields of "kakao_and_account" */
export type Kakao_And_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kakao_And_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kakao_and_account" */
export type Kakao_And_Account_Aggregate_Order_By = {
  avg?: Maybe<Kakao_And_Account_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kakao_And_Account_Max_Order_By>;
  min?: Maybe<Kakao_And_Account_Min_Order_By>;
  stddev?: Maybe<Kakao_And_Account_Stddev_Order_By>;
  stddev_pop?: Maybe<Kakao_And_Account_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kakao_And_Account_Stddev_Samp_Order_By>;
  sum?: Maybe<Kakao_And_Account_Sum_Order_By>;
  var_pop?: Maybe<Kakao_And_Account_Var_Pop_Order_By>;
  var_samp?: Maybe<Kakao_And_Account_Var_Samp_Order_By>;
  variance?: Maybe<Kakao_And_Account_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kakao_and_account" */
export type Kakao_And_Account_Arr_Rel_Insert_Input = {
  data: Array<Kakao_And_Account_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Kakao_And_Account_On_Conflict>;
};

/** aggregate avg on columns */
export type Kakao_And_Account_Avg_Fields = {
  __typename?: 'kakao_and_account_avg_fields';
  kakao_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Avg_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kakao_and_account". All fields are combined with a logical 'AND'. */
export type Kakao_And_Account_Bool_Exp = {
  _and?: Maybe<Array<Kakao_And_Account_Bool_Exp>>;
  _not?: Maybe<Kakao_And_Account_Bool_Exp>;
  _or?: Maybe<Array<Kakao_And_Account_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  kakao_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "kakao_and_account" */
export enum Kakao_And_Account_Constraint {
  /** unique or primary key constraint */
  KakaoAndAccountIdKey = 'kakao_and_account_id_key',
  /** unique or primary key constraint */
  KakaoAndAccountPkey = 'kakao_and_account_pkey'
}

/** input type for incrementing numeric columns in table "kakao_and_account" */
export type Kakao_And_Account_Inc_Input = {
  kakao_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "kakao_and_account" */
export type Kakao_And_Account_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kakao_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Kakao_And_Account_Max_Fields = {
  __typename?: 'kakao_and_account_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kakao_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kakao_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kakao_And_Account_Min_Fields = {
  __typename?: 'kakao_and_account_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kakao_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kakao_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "kakao_and_account" */
export type Kakao_And_Account_Mutation_Response = {
  __typename?: 'kakao_and_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kakao_And_Account>;
};

/** on conflict condition type for table "kakao_and_account" */
export type Kakao_And_Account_On_Conflict = {
  constraint: Kakao_And_Account_Constraint;
  update_columns?: Array<Kakao_And_Account_Update_Column>;
  where?: Maybe<Kakao_And_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "kakao_and_account". */
export type Kakao_And_Account_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kakao_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: kakao_and_account */
export type Kakao_And_Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kakao_and_account" */
export enum Kakao_And_Account_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  KakaoId = 'kakao_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "kakao_and_account" */
export type Kakao_And_Account_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kakao_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Kakao_And_Account_Stddev_Fields = {
  __typename?: 'kakao_and_account_stddev_fields';
  kakao_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Stddev_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kakao_And_Account_Stddev_Pop_Fields = {
  __typename?: 'kakao_and_account_stddev_pop_fields';
  kakao_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Stddev_Pop_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kakao_And_Account_Stddev_Samp_Fields = {
  __typename?: 'kakao_and_account_stddev_samp_fields';
  kakao_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Stddev_Samp_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kakao_And_Account_Sum_Fields = {
  __typename?: 'kakao_and_account_sum_fields';
  kakao_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Sum_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** update columns of table "kakao_and_account" */
export enum Kakao_And_Account_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  KakaoId = 'kakao_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Kakao_And_Account_Var_Pop_Fields = {
  __typename?: 'kakao_and_account_var_pop_fields';
  kakao_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Var_Pop_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kakao_And_Account_Var_Samp_Fields = {
  __typename?: 'kakao_and_account_var_samp_fields';
  kakao_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Var_Samp_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kakao_And_Account_Variance_Fields = {
  __typename?: 'kakao_and_account_variance_fields';
  kakao_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "kakao_and_account" */
export type Kakao_And_Account_Variance_Order_By = {
  kakao_id?: Maybe<Order_By>;
};

/** columns and relationships of "lead" */
export type Lead = {
  __typename?: 'lead';
  /** An object relationship */
  accountByCustomer?: Maybe<Account>;
  /** An object relationship */
  accountByShopAssignee?: Maybe<Account>;
  /** An object relationship */
  accountBySupportAssignee?: Maybe<Account>;
  /** An object relationship */
  addressSidoByAddressSido?: Maybe<Address_Sido>;
  /** An object relationship */
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu>;
  address_sido?: Maybe<Scalars['uuid']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  cancel_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  carByCar?: Maybe<Car>;
  /** An object relationship */
  carSunroofTypeByCarSunroofType: Car_Sunroof_Type;
  car_sunroof_type: Car_Sunroof_Type_Enum;
  chat_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  chats: Array<Chat>;
  /** An aggregate relationship */
  chats_aggregate: Chat_Aggregate;
  created_at: Scalars['timestamptz'];
  created_from?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  done_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  duplicate_at?: Maybe<Scalars['timestamptz']>;
  end_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  etc: Scalars['jsonb'];
  film_need_to_removed: Scalars['Boolean'];
  id: Scalars['uuid'];
  key?: Maybe<Scalars['Int']>;
  lead_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  lead_comments: Array<Lead_Comment>;
  /** An aggregate relationship */
  lead_comments_aggregate: Lead_Comment_Aggregate;
  /** An object relationship */
  lead_status: Lead_Status;
  match_at?: Maybe<Scalars['timestamptz']>;
  order_pending_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  payment_pending_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  quote_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  quotes: Array<Quote>;
  /** An aggregate relationship */
  quotes_aggregate: Quote_Aggregate;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rsv_confirmed_at?: Maybe<Scalars['timestamptz']>;
  rsv_pending_at?: Maybe<Scalars['timestamptz']>;
  settlement_pending_at?: Maybe<Scalars['timestamptz']>;
  shop_assignee?: Maybe<Scalars['uuid']>;
  start_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  status: Lead_Status_Enum;
  support_assignee?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
  used_car_status?: Maybe<Scalars['Boolean']>;
  utm?: Maybe<Scalars['jsonb']>;
  vat_requested_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_reason?: Maybe<Scalars['String']>;
};


/** columns and relationships of "lead" */
export type LeadChatsArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadChats_AggregateArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadEtcArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "lead" */
export type LeadLead_CommentsArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadLead_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadQuotesArgs = {
  distinct_on?: Maybe<Array<Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Order_By>>;
  where?: Maybe<Quote_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadQuotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Order_By>>;
  where?: Maybe<Quote_Bool_Exp>;
};


/** columns and relationships of "lead" */
export type LeadUtmArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lead" */
export type Lead_Aggregate = {
  __typename?: 'lead_aggregate';
  aggregate?: Maybe<Lead_Aggregate_Fields>;
  nodes: Array<Lead>;
};

/** aggregate fields of "lead" */
export type Lead_Aggregate_Fields = {
  __typename?: 'lead_aggregate_fields';
  avg?: Maybe<Lead_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lead_Max_Fields>;
  min?: Maybe<Lead_Min_Fields>;
  stddev?: Maybe<Lead_Stddev_Fields>;
  stddev_pop?: Maybe<Lead_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lead_Stddev_Samp_Fields>;
  sum?: Maybe<Lead_Sum_Fields>;
  var_pop?: Maybe<Lead_Var_Pop_Fields>;
  var_samp?: Maybe<Lead_Var_Samp_Fields>;
  variance?: Maybe<Lead_Variance_Fields>;
};


/** aggregate fields of "lead" */
export type Lead_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lead_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lead" */
export type Lead_Aggregate_Order_By = {
  avg?: Maybe<Lead_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lead_Max_Order_By>;
  min?: Maybe<Lead_Min_Order_By>;
  stddev?: Maybe<Lead_Stddev_Order_By>;
  stddev_pop?: Maybe<Lead_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lead_Stddev_Samp_Order_By>;
  sum?: Maybe<Lead_Sum_Order_By>;
  var_pop?: Maybe<Lead_Var_Pop_Order_By>;
  var_samp?: Maybe<Lead_Var_Samp_Order_By>;
  variance?: Maybe<Lead_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lead_Append_Input = {
  etc?: Maybe<Scalars['jsonb']>;
  utm?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "lead" */
export type Lead_Arr_Rel_Insert_Input = {
  data: Array<Lead_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Lead_On_Conflict>;
};

/** aggregate avg on columns */
export type Lead_Avg_Fields = {
  __typename?: 'lead_avg_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lead" */
export type Lead_Avg_Order_By = {
  key?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lead". All fields are combined with a logical 'AND'. */
export type Lead_Bool_Exp = {
  _and?: Maybe<Array<Lead_Bool_Exp>>;
  _not?: Maybe<Lead_Bool_Exp>;
  _or?: Maybe<Array<Lead_Bool_Exp>>;
  accountByCustomer?: Maybe<Account_Bool_Exp>;
  accountByShopAssignee?: Maybe<Account_Bool_Exp>;
  accountBySupportAssignee?: Maybe<Account_Bool_Exp>;
  addressSidoByAddressSido?: Maybe<Address_Sido_Bool_Exp>;
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Bool_Exp>;
  address_sido?: Maybe<Uuid_Comparison_Exp>;
  address_sigungu?: Maybe<Uuid_Comparison_Exp>;
  cancel_at?: Maybe<Timestamptz_Comparison_Exp>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Bool_Exp>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum_Comparison_Exp>;
  chat_at?: Maybe<Timestamptz_Comparison_Exp>;
  chats?: Maybe<Chat_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_from?: Maybe<String_Comparison_Exp>;
  customer?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  done_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_date?: Maybe<Date_Comparison_Exp>;
  duplicate_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_drop_off_date_time?: Maybe<Timestamptz_Comparison_Exp>;
  etc?: Maybe<Jsonb_Comparison_Exp>;
  film_need_to_removed?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<Int_Comparison_Exp>;
  lead_at?: Maybe<Timestamptz_Comparison_Exp>;
  lead_comments?: Maybe<Lead_Comment_Bool_Exp>;
  lead_status?: Maybe<Lead_Status_Bool_Exp>;
  match_at?: Maybe<Timestamptz_Comparison_Exp>;
  order_pending_at?: Maybe<Timestamptz_Comparison_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  payment_pending_at?: Maybe<Timestamptz_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  quote_at?: Maybe<Timestamptz_Comparison_Exp>;
  quotes?: Maybe<Quote_Bool_Exp>;
  refunded_at?: Maybe<Timestamptz_Comparison_Exp>;
  rsv_confirmed_at?: Maybe<Timestamptz_Comparison_Exp>;
  rsv_pending_at?: Maybe<Timestamptz_Comparison_Exp>;
  settlement_pending_at?: Maybe<Timestamptz_Comparison_Exp>;
  shop_assignee?: Maybe<Uuid_Comparison_Exp>;
  start_drop_off_date_time?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Lead_Status_Enum_Comparison_Exp>;
  support_assignee?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Uuid_Comparison_Exp>;
  used_car_status?: Maybe<Boolean_Comparison_Exp>;
  utm?: Maybe<Jsonb_Comparison_Exp>;
  vat_requested_at?: Maybe<Timestamptz_Comparison_Exp>;
  will_not_do_at?: Maybe<Timestamptz_Comparison_Exp>;
  will_not_do_reason?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "lead_comment" */
export type Lead_Comment = {
  __typename?: 'lead_comment';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  comment: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  lead: Scalars['uuid'];
  /** An object relationship */
  leadByLead: Lead;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "lead_comment" */
export type Lead_Comment_Aggregate = {
  __typename?: 'lead_comment_aggregate';
  aggregate?: Maybe<Lead_Comment_Aggregate_Fields>;
  nodes: Array<Lead_Comment>;
};

/** aggregate fields of "lead_comment" */
export type Lead_Comment_Aggregate_Fields = {
  __typename?: 'lead_comment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lead_Comment_Max_Fields>;
  min?: Maybe<Lead_Comment_Min_Fields>;
};


/** aggregate fields of "lead_comment" */
export type Lead_Comment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lead_Comment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lead_comment" */
export type Lead_Comment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Lead_Comment_Max_Order_By>;
  min?: Maybe<Lead_Comment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lead_comment" */
export type Lead_Comment_Arr_Rel_Insert_Input = {
  data: Array<Lead_Comment_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Lead_Comment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lead_comment". All fields are combined with a logical 'AND'. */
export type Lead_Comment_Bool_Exp = {
  _and?: Maybe<Array<Lead_Comment_Bool_Exp>>;
  _not?: Maybe<Lead_Comment_Bool_Exp>;
  _or?: Maybe<Array<Lead_Comment_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lead?: Maybe<Uuid_Comparison_Exp>;
  leadByLead?: Maybe<Lead_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lead_comment" */
export enum Lead_Comment_Constraint {
  /** unique or primary key constraint */
  LeadCommentPkey = 'lead_comment_pkey'
}

/** input type for inserting data into table "lead_comment" */
export type Lead_Comment_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  leadByLead?: Maybe<Lead_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lead_Comment_Max_Fields = {
  __typename?: 'lead_comment_max_fields';
  account?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lead_comment" */
export type Lead_Comment_Max_Order_By = {
  account?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lead_Comment_Min_Fields = {
  __typename?: 'lead_comment_min_fields';
  account?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lead_comment" */
export type Lead_Comment_Min_Order_By = {
  account?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lead_comment" */
export type Lead_Comment_Mutation_Response = {
  __typename?: 'lead_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lead_Comment>;
};

/** on conflict condition type for table "lead_comment" */
export type Lead_Comment_On_Conflict = {
  constraint: Lead_Comment_Constraint;
  update_columns?: Array<Lead_Comment_Update_Column>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "lead_comment". */
export type Lead_Comment_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  leadByLead?: Maybe<Lead_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lead_comment */
export type Lead_Comment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lead_comment" */
export enum Lead_Comment_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lead_comment" */
export type Lead_Comment_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "lead_comment" */
export enum Lead_Comment_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** unique or primary key constraints on table "lead" */
export enum Lead_Constraint {
  /** unique or primary key constraint */
  LeadPkey = 'Lead_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lead_Delete_At_Path_Input = {
  etc?: Maybe<Array<Scalars['String']>>;
  utm?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lead_Delete_Elem_Input = {
  etc?: Maybe<Scalars['Int']>;
  utm?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lead_Delete_Key_Input = {
  etc?: Maybe<Scalars['String']>;
  utm?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "lead" */
export type Lead_Inc_Input = {
  key?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lead" */
export type Lead_Insert_Input = {
  accountByCustomer?: Maybe<Account_Obj_Rel_Insert_Input>;
  accountByShopAssignee?: Maybe<Account_Obj_Rel_Insert_Input>;
  accountBySupportAssignee?: Maybe<Account_Obj_Rel_Insert_Input>;
  addressSidoByAddressSido?: Maybe<Address_Sido_Obj_Rel_Insert_Input>;
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Obj_Rel_Insert_Input>;
  address_sido?: Maybe<Scalars['uuid']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  cancel_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Obj_Rel_Insert_Input>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  chat_at?: Maybe<Scalars['timestamptz']>;
  chats?: Maybe<Chat_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_from?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  done_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  duplicate_at?: Maybe<Scalars['timestamptz']>;
  end_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  etc?: Maybe<Scalars['jsonb']>;
  film_need_to_removed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead_at?: Maybe<Scalars['timestamptz']>;
  lead_comments?: Maybe<Lead_Comment_Arr_Rel_Insert_Input>;
  lead_status?: Maybe<Lead_Status_Obj_Rel_Insert_Input>;
  match_at?: Maybe<Scalars['timestamptz']>;
  order_pending_at?: Maybe<Scalars['timestamptz']>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  payment_pending_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  quote_at?: Maybe<Scalars['timestamptz']>;
  quotes?: Maybe<Quote_Arr_Rel_Insert_Input>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rsv_confirmed_at?: Maybe<Scalars['timestamptz']>;
  rsv_pending_at?: Maybe<Scalars['timestamptz']>;
  settlement_pending_at?: Maybe<Scalars['timestamptz']>;
  shop_assignee?: Maybe<Scalars['uuid']>;
  start_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Lead_Status_Enum>;
  support_assignee?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  used_car_status?: Maybe<Scalars['Boolean']>;
  utm?: Maybe<Scalars['jsonb']>;
  vat_requested_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_reason?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lead_Max_Fields = {
  __typename?: 'lead_max_fields';
  address_sido?: Maybe<Scalars['uuid']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  cancel_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  chat_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_from?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  done_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  duplicate_at?: Maybe<Scalars['timestamptz']>;
  end_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead_at?: Maybe<Scalars['timestamptz']>;
  match_at?: Maybe<Scalars['timestamptz']>;
  order_pending_at?: Maybe<Scalars['timestamptz']>;
  payment_pending_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  quote_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rsv_confirmed_at?: Maybe<Scalars['timestamptz']>;
  rsv_pending_at?: Maybe<Scalars['timestamptz']>;
  settlement_pending_at?: Maybe<Scalars['timestamptz']>;
  shop_assignee?: Maybe<Scalars['uuid']>;
  start_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  support_assignee?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  vat_requested_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_reason?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lead" */
export type Lead_Max_Order_By = {
  address_sido?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  cancel_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  chat_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_from?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  done_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  duplicate_at?: Maybe<Order_By>;
  end_drop_off_date_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  lead_at?: Maybe<Order_By>;
  match_at?: Maybe<Order_By>;
  order_pending_at?: Maybe<Order_By>;
  payment_pending_at?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  quote_at?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  rsv_confirmed_at?: Maybe<Order_By>;
  rsv_pending_at?: Maybe<Order_By>;
  settlement_pending_at?: Maybe<Order_By>;
  shop_assignee?: Maybe<Order_By>;
  start_drop_off_date_time?: Maybe<Order_By>;
  support_assignee?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  vat_requested_at?: Maybe<Order_By>;
  will_not_do_at?: Maybe<Order_By>;
  will_not_do_reason?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lead_Min_Fields = {
  __typename?: 'lead_min_fields';
  address_sido?: Maybe<Scalars['uuid']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  cancel_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  chat_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_from?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  done_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  duplicate_at?: Maybe<Scalars['timestamptz']>;
  end_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead_at?: Maybe<Scalars['timestamptz']>;
  match_at?: Maybe<Scalars['timestamptz']>;
  order_pending_at?: Maybe<Scalars['timestamptz']>;
  payment_pending_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  quote_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rsv_confirmed_at?: Maybe<Scalars['timestamptz']>;
  rsv_pending_at?: Maybe<Scalars['timestamptz']>;
  settlement_pending_at?: Maybe<Scalars['timestamptz']>;
  shop_assignee?: Maybe<Scalars['uuid']>;
  start_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  support_assignee?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  vat_requested_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_reason?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lead" */
export type Lead_Min_Order_By = {
  address_sido?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  cancel_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  chat_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_from?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  done_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  duplicate_at?: Maybe<Order_By>;
  end_drop_off_date_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  lead_at?: Maybe<Order_By>;
  match_at?: Maybe<Order_By>;
  order_pending_at?: Maybe<Order_By>;
  payment_pending_at?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  quote_at?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  rsv_confirmed_at?: Maybe<Order_By>;
  rsv_pending_at?: Maybe<Order_By>;
  settlement_pending_at?: Maybe<Order_By>;
  shop_assignee?: Maybe<Order_By>;
  start_drop_off_date_time?: Maybe<Order_By>;
  support_assignee?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  vat_requested_at?: Maybe<Order_By>;
  will_not_do_at?: Maybe<Order_By>;
  will_not_do_reason?: Maybe<Order_By>;
};

/** response of any mutation on the table "lead" */
export type Lead_Mutation_Response = {
  __typename?: 'lead_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lead>;
};

/** input type for inserting object relation for remote table "lead" */
export type Lead_Obj_Rel_Insert_Input = {
  data: Lead_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Lead_On_Conflict>;
};

/** on conflict condition type for table "lead" */
export type Lead_On_Conflict = {
  constraint: Lead_Constraint;
  update_columns?: Array<Lead_Update_Column>;
  where?: Maybe<Lead_Bool_Exp>;
};

/** Ordering options when selecting data from "lead". */
export type Lead_Order_By = {
  accountByCustomer?: Maybe<Account_Order_By>;
  accountByShopAssignee?: Maybe<Account_Order_By>;
  accountBySupportAssignee?: Maybe<Account_Order_By>;
  addressSidoByAddressSido?: Maybe<Address_Sido_Order_By>;
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Order_By>;
  address_sido?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  cancel_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Order_By>;
  car_sunroof_type?: Maybe<Order_By>;
  chat_at?: Maybe<Order_By>;
  chats_aggregate?: Maybe<Chat_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_from?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  done_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  duplicate_at?: Maybe<Order_By>;
  end_drop_off_date_time?: Maybe<Order_By>;
  etc?: Maybe<Order_By>;
  film_need_to_removed?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  lead_at?: Maybe<Order_By>;
  lead_comments_aggregate?: Maybe<Lead_Comment_Aggregate_Order_By>;
  lead_status?: Maybe<Lead_Status_Order_By>;
  match_at?: Maybe<Order_By>;
  order_pending_at?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  payment_pending_at?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  quote_at?: Maybe<Order_By>;
  quotes_aggregate?: Maybe<Quote_Aggregate_Order_By>;
  refunded_at?: Maybe<Order_By>;
  rsv_confirmed_at?: Maybe<Order_By>;
  rsv_pending_at?: Maybe<Order_By>;
  settlement_pending_at?: Maybe<Order_By>;
  shop_assignee?: Maybe<Order_By>;
  start_drop_off_date_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  support_assignee?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  used_car_status?: Maybe<Order_By>;
  utm?: Maybe<Order_By>;
  vat_requested_at?: Maybe<Order_By>;
  will_not_do_at?: Maybe<Order_By>;
  will_not_do_reason?: Maybe<Order_By>;
};

/** primary key columns input for table: lead */
export type Lead_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lead_Prepend_Input = {
  etc?: Maybe<Scalars['jsonb']>;
  utm?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "lead" */
export enum Lead_Select_Column {
  /** column name */
  AddressSido = 'address_sido',
  /** column name */
  AddressSigungu = 'address_sigungu',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  ChatAt = 'chat_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedFrom = 'created_from',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DoneAt = 'done_at',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  DuplicateAt = 'duplicate_at',
  /** column name */
  EndDropOffDateTime = 'end_drop_off_date_time',
  /** column name */
  Etc = 'etc',
  /** column name */
  FilmNeedToRemoved = 'film_need_to_removed',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LeadAt = 'lead_at',
  /** column name */
  MatchAt = 'match_at',
  /** column name */
  OrderPendingAt = 'order_pending_at',
  /** column name */
  PaymentPendingAt = 'payment_pending_at',
  /** column name */
  Phone = 'phone',
  /** column name */
  QuoteAt = 'quote_at',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  RsvConfirmedAt = 'rsv_confirmed_at',
  /** column name */
  RsvPendingAt = 'rsv_pending_at',
  /** column name */
  SettlementPendingAt = 'settlement_pending_at',
  /** column name */
  ShopAssignee = 'shop_assignee',
  /** column name */
  StartDropOffDateTime = 'start_drop_off_date_time',
  /** column name */
  Status = 'status',
  /** column name */
  SupportAssignee = 'support_assignee',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UsedCarStatus = 'used_car_status',
  /** column name */
  Utm = 'utm',
  /** column name */
  VatRequestedAt = 'vat_requested_at',
  /** column name */
  WillNotDoAt = 'will_not_do_at',
  /** column name */
  WillNotDoReason = 'will_not_do_reason'
}

/** input type for updating data in table "lead" */
export type Lead_Set_Input = {
  address_sido?: Maybe<Scalars['uuid']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  cancel_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  chat_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_from?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  done_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  duplicate_at?: Maybe<Scalars['timestamptz']>;
  end_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  etc?: Maybe<Scalars['jsonb']>;
  film_need_to_removed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['Int']>;
  lead_at?: Maybe<Scalars['timestamptz']>;
  match_at?: Maybe<Scalars['timestamptz']>;
  order_pending_at?: Maybe<Scalars['timestamptz']>;
  payment_pending_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  quote_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rsv_confirmed_at?: Maybe<Scalars['timestamptz']>;
  rsv_pending_at?: Maybe<Scalars['timestamptz']>;
  settlement_pending_at?: Maybe<Scalars['timestamptz']>;
  shop_assignee?: Maybe<Scalars['uuid']>;
  start_drop_off_date_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Lead_Status_Enum>;
  support_assignee?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  used_car_status?: Maybe<Scalars['Boolean']>;
  utm?: Maybe<Scalars['jsonb']>;
  vat_requested_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_at?: Maybe<Scalars['timestamptz']>;
  will_not_do_reason?: Maybe<Scalars['String']>;
};

/** columns and relationships of "lead_status" */
export type Lead_Status = {
  __typename?: 'lead_status';
  description: Scalars['String'];
  /** An array relationship */
  leads: Array<Lead>;
  /** An aggregate relationship */
  leads_aggregate: Lead_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "lead_status" */
export type Lead_StatusLeadsArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


/** columns and relationships of "lead_status" */
export type Lead_StatusLeads_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};

/** aggregated selection of "lead_status" */
export type Lead_Status_Aggregate = {
  __typename?: 'lead_status_aggregate';
  aggregate?: Maybe<Lead_Status_Aggregate_Fields>;
  nodes: Array<Lead_Status>;
};

/** aggregate fields of "lead_status" */
export type Lead_Status_Aggregate_Fields = {
  __typename?: 'lead_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lead_Status_Max_Fields>;
  min?: Maybe<Lead_Status_Min_Fields>;
};


/** aggregate fields of "lead_status" */
export type Lead_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lead_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lead_status". All fields are combined with a logical 'AND'. */
export type Lead_Status_Bool_Exp = {
  _and?: Maybe<Array<Lead_Status_Bool_Exp>>;
  _not?: Maybe<Lead_Status_Bool_Exp>;
  _or?: Maybe<Array<Lead_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  leads?: Maybe<Lead_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lead_status" */
export enum Lead_Status_Constraint {
  /** unique or primary key constraint */
  LeadStatusPkey = 'lead_status_pkey'
}

export enum Lead_Status_Enum {
  /** 고객 변심 등 여러가지 사유로 인해 6. Reservation 및 7. Payment 단계에서 취소가 된 경우 */
  Cancel = 'cancel',
  /** 채팅 상담원이 챗봇으로부터 인계를 받아서 상담이 진행되었으나 전번을 못받으면 여기서 끝남 */
  Chat = 'chat',
  /** 시공점에 정산을 해주고 모든 프로세스가 종료된 상태 */
  Done = 'done',
  /** 기존 방문자가 새로이 들어와서 다른 Lead와 병합된 경우 */
  Duplicate = 'duplicate',
  /** 채팅 상담원이 전번을 받아서 입력해주면 자동으로 이동되는 상태로 F/U 상담원이 아직 추가 견적을 내지 못한 상태 */
  Lead = 'lead',
  /** 고객이 예약 진행을 원하는 상태이나 아직 그 가격과 일정에 받아줄 수 있는 매장을 확보하지 못한 상태 */
  Match = 'match',
  /** 챗봇과 대화가 시작되면 부여되는 기본 Status로 채팅 상담원이 넘겨받지 못하면 여기서 끝남 */
  Missed = 'missed',
  /** 매장이 확보되어 고객에게 예약(Order) 확인 요청을 넣었으나 아직 고객이 컨펌을 하지 않은 상태 */
  OrderPending = 'order_pending',
  /** 시공점에서 시공이 완료되었으나 고객은 아직 결제를 하지 않은 상태 */
  PaymentPending = 'payment_pending',
  /** F/U 상담원이 추가 견적을 제시하였으나 고객이 아직 예약 진행을 원하지 않는 상태 */
  Quote = 'quote',
  /** 고객 클레임 등 여러가지 사유로 인해 10. Settlement 및 11. Done 단계에서 전액 환볼이 이루어진 경우 */
  Refunded = 'refunded',
  /** 매장에서 예약 인지되어 예약이 확정되었으나 아직 시공은 완료되지 않은 상태 */
  RsvConfirmed = 'rsv_confirmed',
  /** 고객이 예약을 컨펌하였으나 매장에서 아직 예약 인지가 되지 않은 상태 */
  RsvPending = 'rsv_pending',
  /** 매장으로부터 전자세금계산서를 받았으나 시공점에 정산을 아직 하지 않은 상태  */
  SettlementPending = 'settlement_pending',
  /** 고객이 결제를 완료하고 매장에 전자세금계산서를 요청했으나 매장으로부터 아직 전자세금계산서를 받지 못한 상태 */
  VatRequested = 'vat_requested',
  /** 5. Order 포함 그 이전 단계에서 고객 잡기를 포기한 경우 */
  WillNotDo = 'will_not_do'
}

/** Boolean expression to compare columns of type "lead_status_enum". All fields are combined with logical 'AND'. */
export type Lead_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Lead_Status_Enum>;
  _in?: Maybe<Array<Lead_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Lead_Status_Enum>;
  _nin?: Maybe<Array<Lead_Status_Enum>>;
};

/** input type for inserting data into table "lead_status" */
export type Lead_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  leads?: Maybe<Lead_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lead_Status_Max_Fields = {
  __typename?: 'lead_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lead_Status_Min_Fields = {
  __typename?: 'lead_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lead_status" */
export type Lead_Status_Mutation_Response = {
  __typename?: 'lead_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lead_Status>;
};

/** input type for inserting object relation for remote table "lead_status" */
export type Lead_Status_Obj_Rel_Insert_Input = {
  data: Lead_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Lead_Status_On_Conflict>;
};

/** on conflict condition type for table "lead_status" */
export type Lead_Status_On_Conflict = {
  constraint: Lead_Status_Constraint;
  update_columns?: Array<Lead_Status_Update_Column>;
  where?: Maybe<Lead_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "lead_status". */
export type Lead_Status_Order_By = {
  description?: Maybe<Order_By>;
  leads_aggregate?: Maybe<Lead_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: lead_status */
export type Lead_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "lead_status" */
export enum Lead_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "lead_status" */
export type Lead_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** columns and relationships of "lead_status_statistics" */
export type Lead_Status_Statistics = {
  __typename?: 'lead_status_statistics';
  count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lead_status_statistics" */
export type Lead_Status_Statistics_Aggregate = {
  __typename?: 'lead_status_statistics_aggregate';
  aggregate?: Maybe<Lead_Status_Statistics_Aggregate_Fields>;
  nodes: Array<Lead_Status_Statistics>;
};

/** aggregate fields of "lead_status_statistics" */
export type Lead_Status_Statistics_Aggregate_Fields = {
  __typename?: 'lead_status_statistics_aggregate_fields';
  avg?: Maybe<Lead_Status_Statistics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lead_Status_Statistics_Max_Fields>;
  min?: Maybe<Lead_Status_Statistics_Min_Fields>;
  stddev?: Maybe<Lead_Status_Statistics_Stddev_Fields>;
  stddev_pop?: Maybe<Lead_Status_Statistics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lead_Status_Statistics_Stddev_Samp_Fields>;
  sum?: Maybe<Lead_Status_Statistics_Sum_Fields>;
  var_pop?: Maybe<Lead_Status_Statistics_Var_Pop_Fields>;
  var_samp?: Maybe<Lead_Status_Statistics_Var_Samp_Fields>;
  variance?: Maybe<Lead_Status_Statistics_Variance_Fields>;
};


/** aggregate fields of "lead_status_statistics" */
export type Lead_Status_Statistics_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lead_Status_Statistics_Avg_Fields = {
  __typename?: 'lead_status_statistics_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lead_status_statistics". All fields are combined with a logical 'AND'. */
export type Lead_Status_Statistics_Bool_Exp = {
  _and?: Maybe<Array<Lead_Status_Statistics_Bool_Exp>>;
  _not?: Maybe<Lead_Status_Statistics_Bool_Exp>;
  _or?: Maybe<Array<Lead_Status_Statistics_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lead_Status_Statistics_Max_Fields = {
  __typename?: 'lead_status_statistics_max_fields';
  count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lead_Status_Statistics_Min_Fields = {
  __typename?: 'lead_status_statistics_min_fields';
  count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lead_status_statistics". */
export type Lead_Status_Statistics_Order_By = {
  count?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** select columns of table "lead_status_statistics" */
export enum Lead_Status_Statistics_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type Lead_Status_Statistics_Stddev_Fields = {
  __typename?: 'lead_status_statistics_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lead_Status_Statistics_Stddev_Pop_Fields = {
  __typename?: 'lead_status_statistics_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lead_Status_Statistics_Stddev_Samp_Fields = {
  __typename?: 'lead_status_statistics_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Lead_Status_Statistics_Sum_Fields = {
  __typename?: 'lead_status_statistics_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Lead_Status_Statistics_Var_Pop_Fields = {
  __typename?: 'lead_status_statistics_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lead_Status_Statistics_Var_Samp_Fields = {
  __typename?: 'lead_status_statistics_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lead_Status_Statistics_Variance_Fields = {
  __typename?: 'lead_status_statistics_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** update columns of table "lead_status" */
export enum Lead_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Lead_Stddev_Fields = {
  __typename?: 'lead_stddev_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lead" */
export type Lead_Stddev_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lead_Stddev_Pop_Fields = {
  __typename?: 'lead_stddev_pop_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lead" */
export type Lead_Stddev_Pop_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lead_Stddev_Samp_Fields = {
  __typename?: 'lead_stddev_samp_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lead" */
export type Lead_Stddev_Samp_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lead_Sum_Fields = {
  __typename?: 'lead_sum_fields';
  key?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lead" */
export type Lead_Sum_Order_By = {
  key?: Maybe<Order_By>;
};

/** update columns of table "lead" */
export enum Lead_Update_Column {
  /** column name */
  AddressSido = 'address_sido',
  /** column name */
  AddressSigungu = 'address_sigungu',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  ChatAt = 'chat_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedFrom = 'created_from',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DoneAt = 'done_at',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  DuplicateAt = 'duplicate_at',
  /** column name */
  EndDropOffDateTime = 'end_drop_off_date_time',
  /** column name */
  Etc = 'etc',
  /** column name */
  FilmNeedToRemoved = 'film_need_to_removed',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LeadAt = 'lead_at',
  /** column name */
  MatchAt = 'match_at',
  /** column name */
  OrderPendingAt = 'order_pending_at',
  /** column name */
  PaymentPendingAt = 'payment_pending_at',
  /** column name */
  Phone = 'phone',
  /** column name */
  QuoteAt = 'quote_at',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  RsvConfirmedAt = 'rsv_confirmed_at',
  /** column name */
  RsvPendingAt = 'rsv_pending_at',
  /** column name */
  SettlementPendingAt = 'settlement_pending_at',
  /** column name */
  ShopAssignee = 'shop_assignee',
  /** column name */
  StartDropOffDateTime = 'start_drop_off_date_time',
  /** column name */
  Status = 'status',
  /** column name */
  SupportAssignee = 'support_assignee',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UsedCarStatus = 'used_car_status',
  /** column name */
  Utm = 'utm',
  /** column name */
  VatRequestedAt = 'vat_requested_at',
  /** column name */
  WillNotDoAt = 'will_not_do_at',
  /** column name */
  WillNotDoReason = 'will_not_do_reason'
}

/** aggregate var_pop on columns */
export type Lead_Var_Pop_Fields = {
  __typename?: 'lead_var_pop_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lead" */
export type Lead_Var_Pop_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lead_Var_Samp_Fields = {
  __typename?: 'lead_var_samp_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lead" */
export type Lead_Var_Samp_Order_By = {
  key?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lead_Variance_Fields = {
  __typename?: 'lead_variance_fields';
  key?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lead" */
export type Lead_Variance_Order_By = {
  key?: Maybe<Order_By>;
};

/**
 * A like row is created when a user presses any like button
 *
 *
 * columns and relationships of "like"
 */
export type Like = {
  __typename?: 'like';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  product_and_likes: Array<Product_And_Like>;
  /** An aggregate relationship */
  product_and_likes_aggregate: Product_And_Like_Aggregate;
  /** An array relationship */
  store_and_likes: Array<Store_And_Like>;
  /** An aggregate relationship */
  store_and_likes_aggregate: Store_And_Like_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/**
 * A like row is created when a user presses any like button
 *
 *
 * columns and relationships of "like"
 */
export type LikeProduct_And_LikesArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


/**
 * A like row is created when a user presses any like button
 *
 *
 * columns and relationships of "like"
 */
export type LikeProduct_And_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


/**
 * A like row is created when a user presses any like button
 *
 *
 * columns and relationships of "like"
 */
export type LikeStore_And_LikesArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};


/**
 * A like row is created when a user presses any like button
 *
 *
 * columns and relationships of "like"
 */
export type LikeStore_And_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};

/** aggregated selection of "like" */
export type Like_Aggregate = {
  __typename?: 'like_aggregate';
  aggregate?: Maybe<Like_Aggregate_Fields>;
  nodes: Array<Like>;
};

/** aggregate fields of "like" */
export type Like_Aggregate_Fields = {
  __typename?: 'like_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Like_Max_Fields>;
  min?: Maybe<Like_Min_Fields>;
};


/** aggregate fields of "like" */
export type Like_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Like_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "like" */
export type Like_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Like_Max_Order_By>;
  min?: Maybe<Like_Min_Order_By>;
};

/** input type for inserting array relation for remote table "like" */
export type Like_Arr_Rel_Insert_Input = {
  data: Array<Like_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Like_On_Conflict>;
};

/** Boolean expression to filter rows from the table "like". All fields are combined with a logical 'AND'. */
export type Like_Bool_Exp = {
  _and?: Maybe<Array<Like_Bool_Exp>>;
  _not?: Maybe<Like_Bool_Exp>;
  _or?: Maybe<Array<Like_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  product_and_likes?: Maybe<Product_And_Like_Bool_Exp>;
  store_and_likes?: Maybe<Store_And_Like_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "like" */
export enum Like_Constraint {
  /** unique or primary key constraint */
  LikePkey = 'like_pkey'
}

/** input type for inserting data into table "like" */
export type Like_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_and_likes?: Maybe<Product_And_Like_Arr_Rel_Insert_Input>;
  store_and_likes?: Maybe<Store_And_Like_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Like_Max_Fields = {
  __typename?: 'like_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "like" */
export type Like_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Like_Min_Fields = {
  __typename?: 'like_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "like" */
export type Like_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "like" */
export type Like_Mutation_Response = {
  __typename?: 'like_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Like>;
};

/** input type for inserting object relation for remote table "like" */
export type Like_Obj_Rel_Insert_Input = {
  data: Like_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Like_On_Conflict>;
};

/** on conflict condition type for table "like" */
export type Like_On_Conflict = {
  constraint: Like_Constraint;
  update_columns?: Array<Like_Update_Column>;
  where?: Maybe<Like_Bool_Exp>;
};

/** Ordering options when selecting data from "like". */
export type Like_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_and_likes_aggregate?: Maybe<Product_And_Like_Aggregate_Order_By>;
  store_and_likes_aggregate?: Maybe<Store_And_Like_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: like */
export type Like_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "like" */
export enum Like_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "like" */
export type Like_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "like" */
export enum Like_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Membership information about a store
 *
 *
 * columns and relationships of "membership"
 */
export type Membership = {
  __typename?: 'membership';
  /** An array relationship */
  accounts_receivable_and_memberships: Array<Accounts_Receivable_And_Membership>;
  /** An aggregate relationship */
  accounts_receivable_and_memberships_aggregate: Accounts_Receivable_And_Membership_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  membership_type: Membership_Type;
  price?: Maybe<Scalars['numeric']>;
  start_date: Scalars['timestamptz'];
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  type: Membership_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/**
 * Membership information about a store
 *
 *
 * columns and relationships of "membership"
 */
export type MembershipAccounts_Receivable_And_MembershipsArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};


/**
 * Membership information about a store
 *
 *
 * columns and relationships of "membership"
 */
export type MembershipAccounts_Receivable_And_Memberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};

/** aggregated selection of "membership" */
export type Membership_Aggregate = {
  __typename?: 'membership_aggregate';
  aggregate?: Maybe<Membership_Aggregate_Fields>;
  nodes: Array<Membership>;
};

/** aggregate fields of "membership" */
export type Membership_Aggregate_Fields = {
  __typename?: 'membership_aggregate_fields';
  avg?: Maybe<Membership_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Membership_Max_Fields>;
  min?: Maybe<Membership_Min_Fields>;
  stddev?: Maybe<Membership_Stddev_Fields>;
  stddev_pop?: Maybe<Membership_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Membership_Stddev_Samp_Fields>;
  sum?: Maybe<Membership_Sum_Fields>;
  var_pop?: Maybe<Membership_Var_Pop_Fields>;
  var_samp?: Maybe<Membership_Var_Samp_Fields>;
  variance?: Maybe<Membership_Variance_Fields>;
};


/** aggregate fields of "membership" */
export type Membership_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Membership_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "membership" */
export type Membership_Aggregate_Order_By = {
  avg?: Maybe<Membership_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Membership_Max_Order_By>;
  min?: Maybe<Membership_Min_Order_By>;
  stddev?: Maybe<Membership_Stddev_Order_By>;
  stddev_pop?: Maybe<Membership_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Membership_Stddev_Samp_Order_By>;
  sum?: Maybe<Membership_Sum_Order_By>;
  var_pop?: Maybe<Membership_Var_Pop_Order_By>;
  var_samp?: Maybe<Membership_Var_Samp_Order_By>;
  variance?: Maybe<Membership_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "membership" */
export type Membership_Arr_Rel_Insert_Input = {
  data: Array<Membership_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Membership_On_Conflict>;
};

/** aggregate avg on columns */
export type Membership_Avg_Fields = {
  __typename?: 'membership_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "membership" */
export type Membership_Avg_Order_By = {
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "membership". All fields are combined with a logical 'AND'. */
export type Membership_Bool_Exp = {
  _and?: Maybe<Array<Membership_Bool_Exp>>;
  _not?: Maybe<Membership_Bool_Exp>;
  _or?: Maybe<Array<Membership_Bool_Exp>>;
  accounts_receivable_and_memberships?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  membership_type?: Maybe<Membership_Type_Bool_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  start_date?: Maybe<Timestamptz_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  type?: Maybe<Membership_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "membership" */
export enum Membership_Constraint {
  /** unique or primary key constraint */
  MembershipPkey = 'membership_pkey'
}

/** input type for incrementing numeric columns in table "membership" */
export type Membership_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "membership" */
export type Membership_Insert_Input = {
  accounts_receivable_and_memberships?: Maybe<Accounts_Receivable_And_Membership_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membership_type?: Maybe<Membership_Type_Obj_Rel_Insert_Input>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  type?: Maybe<Membership_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "membership_log" */
export type Membership_Log = {
  __typename?: 'membership_log';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  membership: Scalars['uuid'];
  price?: Maybe<Scalars['numeric']>;
  start_date: Scalars['timestamptz'];
  store: Scalars['uuid'];
  subscription?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "membership_log" */
export type Membership_Log_Aggregate = {
  __typename?: 'membership_log_aggregate';
  aggregate?: Maybe<Membership_Log_Aggregate_Fields>;
  nodes: Array<Membership_Log>;
};

/** aggregate fields of "membership_log" */
export type Membership_Log_Aggregate_Fields = {
  __typename?: 'membership_log_aggregate_fields';
  avg?: Maybe<Membership_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Membership_Log_Max_Fields>;
  min?: Maybe<Membership_Log_Min_Fields>;
  stddev?: Maybe<Membership_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Membership_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Membership_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Membership_Log_Sum_Fields>;
  var_pop?: Maybe<Membership_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Membership_Log_Var_Samp_Fields>;
  variance?: Maybe<Membership_Log_Variance_Fields>;
};


/** aggregate fields of "membership_log" */
export type Membership_Log_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Membership_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Membership_Log_Avg_Fields = {
  __typename?: 'membership_log_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "membership_log". All fields are combined with a logical 'AND'. */
export type Membership_Log_Bool_Exp = {
  _and?: Maybe<Array<Membership_Log_Bool_Exp>>;
  _not?: Maybe<Membership_Log_Bool_Exp>;
  _or?: Maybe<Array<Membership_Log_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  membership?: Maybe<Uuid_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  start_date?: Maybe<Timestamptz_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  subscription?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "membership_log" */
export enum Membership_Log_Constraint {
  /** unique or primary key constraint */
  MembershipLogPkey = 'membership_log_pkey'
}

/** input type for incrementing numeric columns in table "membership_log" */
export type Membership_Log_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "membership_log" */
export type Membership_Log_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membership?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  subscription?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Membership_Log_Max_Fields = {
  __typename?: 'membership_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membership?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  subscription?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Membership_Log_Min_Fields = {
  __typename?: 'membership_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membership?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  subscription?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "membership_log" */
export type Membership_Log_Mutation_Response = {
  __typename?: 'membership_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Membership_Log>;
};

/** on conflict condition type for table "membership_log" */
export type Membership_Log_On_Conflict = {
  constraint: Membership_Log_Constraint;
  update_columns?: Array<Membership_Log_Update_Column>;
  where?: Maybe<Membership_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "membership_log". */
export type Membership_Log_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membership?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: membership_log */
export type Membership_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "membership_log" */
export enum Membership_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Membership = 'membership',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Store = 'store',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "membership_log" */
export type Membership_Log_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membership?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  subscription?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Membership_Log_Stddev_Fields = {
  __typename?: 'membership_log_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Membership_Log_Stddev_Pop_Fields = {
  __typename?: 'membership_log_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Membership_Log_Stddev_Samp_Fields = {
  __typename?: 'membership_log_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Membership_Log_Sum_Fields = {
  __typename?: 'membership_log_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "membership_log" */
export enum Membership_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Membership = 'membership',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Store = 'store',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Membership_Log_Var_Pop_Fields = {
  __typename?: 'membership_log_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Membership_Log_Var_Samp_Fields = {
  __typename?: 'membership_log_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Membership_Log_Variance_Fields = {
  __typename?: 'membership_log_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Membership_Max_Fields = {
  __typename?: 'membership_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "membership" */
export type Membership_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Membership_Min_Fields = {
  __typename?: 'membership_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "membership" */
export type Membership_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "membership" */
export type Membership_Mutation_Response = {
  __typename?: 'membership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Membership>;
};

/** input type for inserting object relation for remote table "membership" */
export type Membership_Obj_Rel_Insert_Input = {
  data: Membership_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Membership_On_Conflict>;
};

/** on conflict condition type for table "membership" */
export type Membership_On_Conflict = {
  constraint: Membership_Constraint;
  update_columns?: Array<Membership_Update_Column>;
  where?: Maybe<Membership_Bool_Exp>;
};

/** Ordering options when selecting data from "membership". */
export type Membership_Order_By = {
  accounts_receivable_and_memberships_aggregate?: Maybe<Accounts_Receivable_And_Membership_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membership_type?: Maybe<Membership_Type_Order_By>;
  price?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: membership */
export type Membership_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "membership" */
export enum Membership_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Store = 'store',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "membership" */
export type Membership_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  type?: Maybe<Membership_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Membership_Stddev_Fields = {
  __typename?: 'membership_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "membership" */
export type Membership_Stddev_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Membership_Stddev_Pop_Fields = {
  __typename?: 'membership_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "membership" */
export type Membership_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Membership_Stddev_Samp_Fields = {
  __typename?: 'membership_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "membership" */
export type Membership_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Membership_Sum_Fields = {
  __typename?: 'membership_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "membership" */
export type Membership_Sum_Order_By = {
  price?: Maybe<Order_By>;
};

/** columns and relationships of "membership_type" */
export type Membership_Type = {
  __typename?: 'membership_type';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  memberships: Array<Membership>;
  /** An aggregate relationship */
  memberships_aggregate: Membership_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "membership_type" */
export type Membership_TypeMembershipsArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};


/** columns and relationships of "membership_type" */
export type Membership_TypeMemberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};

/** aggregated selection of "membership_type" */
export type Membership_Type_Aggregate = {
  __typename?: 'membership_type_aggregate';
  aggregate?: Maybe<Membership_Type_Aggregate_Fields>;
  nodes: Array<Membership_Type>;
};

/** aggregate fields of "membership_type" */
export type Membership_Type_Aggregate_Fields = {
  __typename?: 'membership_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Membership_Type_Max_Fields>;
  min?: Maybe<Membership_Type_Min_Fields>;
};


/** aggregate fields of "membership_type" */
export type Membership_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Membership_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "membership_type". All fields are combined with a logical 'AND'. */
export type Membership_Type_Bool_Exp = {
  _and?: Maybe<Array<Membership_Type_Bool_Exp>>;
  _not?: Maybe<Membership_Type_Bool_Exp>;
  _or?: Maybe<Array<Membership_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  memberships?: Maybe<Membership_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "membership_type" */
export enum Membership_Type_Constraint {
  /** unique or primary key constraint */
  MembershipTypePkey = 'membership_type_pkey'
}

export enum Membership_Type_Enum {
  /** 무료 */
  Bronze = 'bronze',
  /** 프리미엄 */
  Gold = 'gold',
  /** 유료 */
  Silver = 'silver'
}

/** Boolean expression to compare columns of type "membership_type_enum". All fields are combined with logical 'AND'. */
export type Membership_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Membership_Type_Enum>;
  _in?: Maybe<Array<Membership_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Membership_Type_Enum>;
  _nin?: Maybe<Array<Membership_Type_Enum>>;
};

/** input type for inserting data into table "membership_type" */
export type Membership_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  memberships?: Maybe<Membership_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Membership_Type_Max_Fields = {
  __typename?: 'membership_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Membership_Type_Min_Fields = {
  __typename?: 'membership_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "membership_type" */
export type Membership_Type_Mutation_Response = {
  __typename?: 'membership_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Membership_Type>;
};

/** input type for inserting object relation for remote table "membership_type" */
export type Membership_Type_Obj_Rel_Insert_Input = {
  data: Membership_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Membership_Type_On_Conflict>;
};

/** on conflict condition type for table "membership_type" */
export type Membership_Type_On_Conflict = {
  constraint: Membership_Type_Constraint;
  update_columns?: Array<Membership_Type_Update_Column>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "membership_type". */
export type Membership_Type_Order_By = {
  description?: Maybe<Order_By>;
  memberships_aggregate?: Maybe<Membership_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: membership_type */
export type Membership_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "membership_type" */
export enum Membership_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "membership_type" */
export type Membership_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "membership_type" */
export enum Membership_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "membership" */
export enum Membership_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Store = 'store',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Membership_Var_Pop_Fields = {
  __typename?: 'membership_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "membership" */
export type Membership_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Membership_Var_Samp_Fields = {
  __typename?: 'membership_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "membership" */
export type Membership_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Membership_Variance_Fields = {
  __typename?: 'membership_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "membership" */
export type Membership_Variance_Order_By = {
  price?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "account" */
  delete_account?: Maybe<Account_Mutation_Response>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<Account>;
  /** delete data from the table: "account_type" */
  delete_account_type?: Maybe<Account_Type_Mutation_Response>;
  /** delete single row from the table: "account_type" */
  delete_account_type_by_pk?: Maybe<Account_Type>;
  /** delete data from the table: "accounts_payable" */
  delete_accounts_payable?: Maybe<Accounts_Payable_Mutation_Response>;
  /** delete data from the table: "accounts_payable_and_adjustment" */
  delete_accounts_payable_and_adjustment?: Maybe<Accounts_Payable_And_Adjustment_Mutation_Response>;
  /** delete single row from the table: "accounts_payable_and_adjustment" */
  delete_accounts_payable_and_adjustment_by_pk?: Maybe<Accounts_Payable_And_Adjustment>;
  /** delete data from the table: "accounts_payable_and_payout" */
  delete_accounts_payable_and_payout?: Maybe<Accounts_Payable_And_Payout_Mutation_Response>;
  /** delete single row from the table: "accounts_payable_and_payout" */
  delete_accounts_payable_and_payout_by_pk?: Maybe<Accounts_Payable_And_Payout>;
  /** delete data from the table: "accounts_payable_and_refund" */
  delete_accounts_payable_and_refund?: Maybe<Accounts_Payable_And_Refund_Mutation_Response>;
  /** delete single row from the table: "accounts_payable_and_refund" */
  delete_accounts_payable_and_refund_by_pk?: Maybe<Accounts_Payable_And_Refund>;
  /** delete single row from the table: "accounts_payable" */
  delete_accounts_payable_by_pk?: Maybe<Accounts_Payable>;
  /** delete data from the table: "accounts_receivable" */
  delete_accounts_receivable?: Maybe<Accounts_Receivable_Mutation_Response>;
  /** delete data from the table: "accounts_receivable_and_invoice" */
  delete_accounts_receivable_and_invoice?: Maybe<Accounts_Receivable_And_Invoice_Mutation_Response>;
  /** delete single row from the table: "accounts_receivable_and_invoice" */
  delete_accounts_receivable_and_invoice_by_pk?: Maybe<Accounts_Receivable_And_Invoice>;
  /** delete data from the table: "accounts_receivable_and_membership" */
  delete_accounts_receivable_and_membership?: Maybe<Accounts_Receivable_And_Membership_Mutation_Response>;
  /** delete single row from the table: "accounts_receivable_and_membership" */
  delete_accounts_receivable_and_membership_by_pk?: Maybe<Accounts_Receivable_And_Membership>;
  /** delete data from the table: "accounts_receivable_and_subscription" */
  delete_accounts_receivable_and_subscription?: Maybe<Accounts_Receivable_And_Subscription_Mutation_Response>;
  /** delete single row from the table: "accounts_receivable_and_subscription" */
  delete_accounts_receivable_and_subscription_by_pk?: Maybe<Accounts_Receivable_And_Subscription>;
  /** delete single row from the table: "accounts_receivable" */
  delete_accounts_receivable_by_pk?: Maybe<Accounts_Receivable>;
  /** delete data from the table: "activity" */
  delete_activity?: Maybe<Activity_Mutation_Response>;
  /** delete single row from the table: "activity" */
  delete_activity_by_pk?: Maybe<Activity>;
  /** delete data from the table: "address_sido" */
  delete_address_sido?: Maybe<Address_Sido_Mutation_Response>;
  /** delete single row from the table: "address_sido" */
  delete_address_sido_by_pk?: Maybe<Address_Sido>;
  /** delete data from the table: "address_sigungu" */
  delete_address_sigungu?: Maybe<Address_Sigungu_Mutation_Response>;
  /** delete single row from the table: "address_sigungu" */
  delete_address_sigungu_by_pk?: Maybe<Address_Sigungu>;
  /** delete data from the table: "adjustment" */
  delete_adjustment?: Maybe<Adjustment_Mutation_Response>;
  /** delete data from the table: "adjustment_and_transfer" */
  delete_adjustment_and_transfer?: Maybe<Adjustment_And_Transfer_Mutation_Response>;
  /** delete single row from the table: "adjustment_and_transfer" */
  delete_adjustment_and_transfer_by_pk?: Maybe<Adjustment_And_Transfer>;
  /** delete single row from the table: "adjustment" */
  delete_adjustment_by_pk?: Maybe<Adjustment>;
  /** delete data from the table: "adjustment_status" */
  delete_adjustment_status?: Maybe<Adjustment_Status_Mutation_Response>;
  /** delete single row from the table: "adjustment_status" */
  delete_adjustment_status_by_pk?: Maybe<Adjustment_Status>;
  /** delete data from the table: "blog_post" */
  delete_blog_post?: Maybe<Blog_Post_Mutation_Response>;
  /** delete data from the table: "blog_post_and_hashtag" */
  delete_blog_post_and_hashtag?: Maybe<Blog_Post_And_Hashtag_Mutation_Response>;
  /** delete single row from the table: "blog_post_and_hashtag" */
  delete_blog_post_and_hashtag_by_pk?: Maybe<Blog_Post_And_Hashtag>;
  /** delete data from the table: "blog_post_and_view" */
  delete_blog_post_and_view?: Maybe<Blog_Post_And_View_Mutation_Response>;
  /** delete single row from the table: "blog_post_and_view" */
  delete_blog_post_and_view_by_pk?: Maybe<Blog_Post_And_View>;
  /** delete single row from the table: "blog_post" */
  delete_blog_post_by_pk?: Maybe<Blog_Post>;
  /** delete data from the table: "car" */
  delete_car?: Maybe<Car_Mutation_Response>;
  /** delete single row from the table: "car" */
  delete_car_by_pk?: Maybe<Car>;
  /** delete data from the table: "car_inspection" */
  delete_car_inspection?: Maybe<Car_Inspection_Mutation_Response>;
  /** delete single row from the table: "car_inspection" */
  delete_car_inspection_by_pk?: Maybe<Car_Inspection>;
  /** delete data from the table: "car_inspection_status" */
  delete_car_inspection_status?: Maybe<Car_Inspection_Status_Mutation_Response>;
  /** delete single row from the table: "car_inspection_status" */
  delete_car_inspection_status_by_pk?: Maybe<Car_Inspection_Status>;
  /** delete data from the table: "car_maker" */
  delete_car_maker?: Maybe<Car_Maker_Mutation_Response>;
  /** delete single row from the table: "car_maker" */
  delete_car_maker_by_pk?: Maybe<Car_Maker>;
  /** delete data from the table: "car_origin" */
  delete_car_origin?: Maybe<Car_Origin_Mutation_Response>;
  /** delete single row from the table: "car_origin" */
  delete_car_origin_by_pk?: Maybe<Car_Origin>;
  /** delete data from the table: "car_sunroof_type" */
  delete_car_sunroof_type?: Maybe<Car_Sunroof_Type_Mutation_Response>;
  /** delete single row from the table: "car_sunroof_type" */
  delete_car_sunroof_type_by_pk?: Maybe<Car_Sunroof_Type>;
  /** delete data from the table: "car_type" */
  delete_car_type?: Maybe<Car_Type_Mutation_Response>;
  /** delete single row from the table: "car_type" */
  delete_car_type_by_pk?: Maybe<Car_Type>;
  /** delete data from the table: "cart_item" */
  delete_cart_item?: Maybe<Cart_Item_Mutation_Response>;
  /** delete single row from the table: "cart_item" */
  delete_cart_item_by_pk?: Maybe<Cart_Item>;
  /** delete data from the table: "chat" */
  delete_chat?: Maybe<Chat_Mutation_Response>;
  /** delete single row from the table: "chat" */
  delete_chat_by_pk?: Maybe<Chat>;
  /** delete data from the table: "config" */
  delete_config?: Maybe<Config_Mutation_Response>;
  /** delete single row from the table: "config" */
  delete_config_by_pk?: Maybe<Config>;
  /** delete data from the table: "config_type" */
  delete_config_type?: Maybe<Config_Type_Mutation_Response>;
  /** delete single row from the table: "config_type" */
  delete_config_type_by_pk?: Maybe<Config_Type>;
  /** delete data from the table: "coupon" */
  delete_coupon?: Maybe<Coupon_Mutation_Response>;
  /** delete data from the table: "coupon_and_account" */
  delete_coupon_and_account?: Maybe<Coupon_And_Account_Mutation_Response>;
  /** delete single row from the table: "coupon_and_account" */
  delete_coupon_and_account_by_pk?: Maybe<Coupon_And_Account>;
  /** delete single row from the table: "coupon" */
  delete_coupon_by_pk?: Maybe<Coupon>;
  /** delete data from the table: "coupon_status" */
  delete_coupon_status?: Maybe<Coupon_Status_Mutation_Response>;
  /** delete single row from the table: "coupon_status" */
  delete_coupon_status_by_pk?: Maybe<Coupon_Status>;
  /** delete data from the table: "curation" */
  delete_curation?: Maybe<Curation_Mutation_Response>;
  /** delete single row from the table: "curation" */
  delete_curation_by_pk?: Maybe<Curation>;
  /** delete data from the table: "fake_review" */
  delete_fake_review?: Maybe<Fake_Review_Mutation_Response>;
  /** delete single row from the table: "fake_review" */
  delete_fake_review_by_pk?: Maybe<Fake_Review>;
  /** delete data from the table: "gallery" */
  delete_gallery?: Maybe<Gallery_Mutation_Response>;
  /** delete single row from the table: "gallery" */
  delete_gallery_by_pk?: Maybe<Gallery>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "hashtag" */
  delete_hashtag?: Maybe<Hashtag_Mutation_Response>;
  /** delete single row from the table: "hashtag" */
  delete_hashtag_by_pk?: Maybe<Hashtag>;
  /** delete data from the table: "inventory" */
  delete_inventory?: Maybe<Inventory_Mutation_Response>;
  /** delete single row from the table: "inventory" */
  delete_inventory_by_pk?: Maybe<Inventory>;
  /** delete data from the table: "invoice" */
  delete_invoice?: Maybe<Invoice_Mutation_Response>;
  /** delete data from the table: "invoice_and_transaction" */
  delete_invoice_and_transaction?: Maybe<Invoice_And_Transaction_Mutation_Response>;
  /** delete single row from the table: "invoice_and_transaction" */
  delete_invoice_and_transaction_by_pk?: Maybe<Invoice_And_Transaction>;
  /** delete data from the table: "invoice_and_transfer" */
  delete_invoice_and_transfer?: Maybe<Invoice_And_Transfer_Mutation_Response>;
  /** delete single row from the table: "invoice_and_transfer" */
  delete_invoice_and_transfer_by_pk?: Maybe<Invoice_And_Transfer>;
  /** delete single row from the table: "invoice" */
  delete_invoice_by_pk?: Maybe<Invoice>;
  /** delete data from the table: "invoice_status" */
  delete_invoice_status?: Maybe<Invoice_Status_Mutation_Response>;
  /** delete single row from the table: "invoice_status" */
  delete_invoice_status_by_pk?: Maybe<Invoice_Status>;
  /** delete data from the table: "kakao_and_account" */
  delete_kakao_and_account?: Maybe<Kakao_And_Account_Mutation_Response>;
  /** delete single row from the table: "kakao_and_account" */
  delete_kakao_and_account_by_pk?: Maybe<Kakao_And_Account>;
  /** delete data from the table: "lead" */
  delete_lead?: Maybe<Lead_Mutation_Response>;
  /** delete single row from the table: "lead" */
  delete_lead_by_pk?: Maybe<Lead>;
  /** delete data from the table: "lead_comment" */
  delete_lead_comment?: Maybe<Lead_Comment_Mutation_Response>;
  /** delete single row from the table: "lead_comment" */
  delete_lead_comment_by_pk?: Maybe<Lead_Comment>;
  /** delete data from the table: "lead_status" */
  delete_lead_status?: Maybe<Lead_Status_Mutation_Response>;
  /** delete single row from the table: "lead_status" */
  delete_lead_status_by_pk?: Maybe<Lead_Status>;
  /** delete data from the table: "like" */
  delete_like?: Maybe<Like_Mutation_Response>;
  /** delete single row from the table: "like" */
  delete_like_by_pk?: Maybe<Like>;
  /** delete data from the table: "membership" */
  delete_membership?: Maybe<Membership_Mutation_Response>;
  /** delete single row from the table: "membership" */
  delete_membership_by_pk?: Maybe<Membership>;
  /** delete data from the table: "membership_log" */
  delete_membership_log?: Maybe<Membership_Log_Mutation_Response>;
  /** delete single row from the table: "membership_log" */
  delete_membership_log_by_pk?: Maybe<Membership_Log>;
  /** delete data from the table: "membership_type" */
  delete_membership_type?: Maybe<Membership_Type_Mutation_Response>;
  /** delete single row from the table: "membership_type" */
  delete_membership_type_by_pk?: Maybe<Membership_Type>;
  /** delete data from the table: "n_cart" */
  delete_n_cart?: Maybe<N_Cart_Mutation_Response>;
  /** delete single row from the table: "n_cart" */
  delete_n_cart_by_pk?: Maybe<N_Cart>;
  /** delete data from the table: "n_order" */
  delete_n_order?: Maybe<N_Order_Mutation_Response>;
  /** delete single row from the table: "n_order" */
  delete_n_order_by_pk?: Maybe<N_Order>;
  /** delete data from the table: "order" */
  delete_order?: Maybe<Order_Mutation_Response>;
  /** delete data from the table: "order_and_review" */
  delete_order_and_review?: Maybe<Order_And_Review_Mutation_Response>;
  /** delete single row from the table: "order_and_review" */
  delete_order_and_review_by_pk?: Maybe<Order_And_Review>;
  /** delete data from the table: "order_and_transaction" */
  delete_order_and_transaction?: Maybe<Order_And_Transaction_Mutation_Response>;
  /** delete single row from the table: "order_and_transaction" */
  delete_order_and_transaction_by_pk?: Maybe<Order_And_Transaction>;
  /** delete data from the table: "order_and_transfer" */
  delete_order_and_transfer?: Maybe<Order_And_Transfer_Mutation_Response>;
  /** delete single row from the table: "order_and_transfer" */
  delete_order_and_transfer_by_pk?: Maybe<Order_And_Transfer>;
  /** delete single row from the table: "order" */
  delete_order_by_pk?: Maybe<Order>;
  /** delete data from the table: "order_from_store" */
  delete_order_from_store?: Maybe<Order_From_Store_Mutation_Response>;
  /** delete single row from the table: "order_from_store" */
  delete_order_from_store_by_pk?: Maybe<Order_From_Store>;
  /** delete data from the table: "order_from_store_status" */
  delete_order_from_store_status?: Maybe<Order_From_Store_Status_Mutation_Response>;
  /** delete single row from the table: "order_from_store_status" */
  delete_order_from_store_status_by_pk?: Maybe<Order_From_Store_Status>;
  /** delete data from the table: "order_item" */
  delete_order_item?: Maybe<Order_Item_Mutation_Response>;
  /** delete single row from the table: "order_item" */
  delete_order_item_by_pk?: Maybe<Order_Item>;
  /** delete data from the table: "order_status" */
  delete_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** delete single row from the table: "order_status" */
  delete_order_status_by_pk?: Maybe<Order_Status>;
  /** delete data from the table: "package" */
  delete_package?: Maybe<Package_Mutation_Response>;
  /** delete single row from the table: "package" */
  delete_package_by_pk?: Maybe<Package>;
  /** delete data from the table: "package_type" */
  delete_package_type?: Maybe<Package_Type_Mutation_Response>;
  /** delete single row from the table: "package_type" */
  delete_package_type_by_pk?: Maybe<Package_Type>;
  /** delete data from the table: "payout" */
  delete_payout?: Maybe<Payout_Mutation_Response>;
  /** delete data from the table: "payout_and_transfer" */
  delete_payout_and_transfer?: Maybe<Payout_And_Transfer_Mutation_Response>;
  /** delete single row from the table: "payout_and_transfer" */
  delete_payout_and_transfer_by_pk?: Maybe<Payout_And_Transfer>;
  /** delete single row from the table: "payout" */
  delete_payout_by_pk?: Maybe<Payout>;
  /** delete data from the table: "payout_status" */
  delete_payout_status?: Maybe<Payout_Status_Mutation_Response>;
  /** delete single row from the table: "payout_status" */
  delete_payout_status_by_pk?: Maybe<Payout_Status>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete data from the table: "product_and_like" */
  delete_product_and_like?: Maybe<Product_And_Like_Mutation_Response>;
  /** delete single row from the table: "product_and_like" */
  delete_product_and_like_by_pk?: Maybe<Product_And_Like>;
  /** delete data from the table: "product_brand" */
  delete_product_brand?: Maybe<Product_Brand_Mutation_Response>;
  /** delete single row from the table: "product_brand" */
  delete_product_brand_by_pk?: Maybe<Product_Brand>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_item" */
  delete_product_item?: Maybe<Product_Item_Mutation_Response>;
  /** delete data from the table: "product_item_and_review" */
  delete_product_item_and_review?: Maybe<Product_Item_And_Review_Mutation_Response>;
  /** delete single row from the table: "product_item_and_review" */
  delete_product_item_and_review_by_pk?: Maybe<Product_Item_And_Review>;
  /** delete single row from the table: "product_item" */
  delete_product_item_by_pk?: Maybe<Product_Item>;
  /** delete data from the table: "product_item_price_by_store" */
  delete_product_item_price_by_store?: Maybe<Product_Item_Price_By_Store_Mutation_Response>;
  /** delete single row from the table: "product_item_price_by_store" */
  delete_product_item_price_by_store_by_pk?: Maybe<Product_Item_Price_By_Store>;
  /** delete data from the table: "product_type" */
  delete_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** delete single row from the table: "product_type" */
  delete_product_type_by_pk?: Maybe<Product_Type>;
  /** delete data from the table: "profile_kr_customer" */
  delete_profile_kr_customer?: Maybe<Profile_Kr_Customer_Mutation_Response>;
  /** delete single row from the table: "profile_kr_customer" */
  delete_profile_kr_customer_by_pk?: Maybe<Profile_Kr_Customer>;
  /** delete data from the table: "profile_kr_partner" */
  delete_profile_kr_partner?: Maybe<Profile_Kr_Partner_Mutation_Response>;
  /** delete single row from the table: "profile_kr_partner" */
  delete_profile_kr_partner_by_pk?: Maybe<Profile_Kr_Partner>;
  /** delete data from the table: "profile_language" */
  delete_profile_language?: Maybe<Profile_Language_Mutation_Response>;
  /** delete single row from the table: "profile_language" */
  delete_profile_language_by_pk?: Maybe<Profile_Language>;
  /** delete data from the table: "profile_riderdash" */
  delete_profile_riderdash?: Maybe<Profile_Riderdash_Mutation_Response>;
  /** delete single row from the table: "profile_riderdash" */
  delete_profile_riderdash_by_pk?: Maybe<Profile_Riderdash>;
  /** delete data from the table: "profile_riderdash_role" */
  delete_profile_riderdash_role?: Maybe<Profile_Riderdash_Role_Mutation_Response>;
  /** delete single row from the table: "profile_riderdash_role" */
  delete_profile_riderdash_role_by_pk?: Maybe<Profile_Riderdash_Role>;
  /** delete data from the table: "profile_us_customer" */
  delete_profile_us_customer?: Maybe<Profile_Us_Customer_Mutation_Response>;
  /** delete single row from the table: "profile_us_customer" */
  delete_profile_us_customer_by_pk?: Maybe<Profile_Us_Customer>;
  /** delete data from the table: "profile_us_partner" */
  delete_profile_us_partner?: Maybe<Profile_Us_Partner_Mutation_Response>;
  /** delete single row from the table: "profile_us_partner" */
  delete_profile_us_partner_by_pk?: Maybe<Profile_Us_Partner>;
  /** delete data from the table: "promotion" */
  delete_promotion?: Maybe<Promotion_Mutation_Response>;
  /** delete single row from the table: "promotion" */
  delete_promotion_by_pk?: Maybe<Promotion>;
  /** delete data from the table: "quote" */
  delete_quote?: Maybe<Quote_Mutation_Response>;
  /** delete single row from the table: "quote" */
  delete_quote_by_pk?: Maybe<Quote>;
  /** delete data from the table: "quote_item" */
  delete_quote_item?: Maybe<Quote_Item_Mutation_Response>;
  /** delete single row from the table: "quote_item" */
  delete_quote_item_by_pk?: Maybe<Quote_Item>;
  /** delete data from the table: "quote_request" */
  delete_quote_request?: Maybe<Quote_Request_Mutation_Response>;
  /** delete single row from the table: "quote_request" */
  delete_quote_request_by_pk?: Maybe<Quote_Request>;
  /** delete data from the table: "refund" */
  delete_refund?: Maybe<Refund_Mutation_Response>;
  /** delete data from the table: "refund_and_transaction" */
  delete_refund_and_transaction?: Maybe<Refund_And_Transaction_Mutation_Response>;
  /** delete single row from the table: "refund_and_transaction" */
  delete_refund_and_transaction_by_pk?: Maybe<Refund_And_Transaction>;
  /** delete data from the table: "refund_and_transfer" */
  delete_refund_and_transfer?: Maybe<Refund_And_Transfer_Mutation_Response>;
  /** delete single row from the table: "refund_and_transfer" */
  delete_refund_and_transfer_by_pk?: Maybe<Refund_And_Transfer>;
  /** delete single row from the table: "refund" */
  delete_refund_by_pk?: Maybe<Refund>;
  /** delete data from the table: "refund_status" */
  delete_refund_status?: Maybe<Refund_Status_Mutation_Response>;
  /** delete single row from the table: "refund_status" */
  delete_refund_status_by_pk?: Maybe<Refund_Status>;
  /** delete data from the table: "review" */
  delete_review?: Maybe<Review_Mutation_Response>;
  /** delete single row from the table: "review" */
  delete_review_by_pk?: Maybe<Review>;
  /** delete data from the table: "review_status" */
  delete_review_status?: Maybe<Review_Status_Mutation_Response>;
  /** delete single row from the table: "review_status" */
  delete_review_status_by_pk?: Maybe<Review_Status>;
  /** delete data from the table: "session" */
  delete_session?: Maybe<Session_Mutation_Response>;
  /** delete single row from the table: "session" */
  delete_session_by_pk?: Maybe<Session>;
  /** delete data from the table: "slot" */
  delete_slot?: Maybe<Slot_Mutation_Response>;
  /** delete single row from the table: "slot" */
  delete_slot_by_pk?: Maybe<Slot>;
  /** delete data from the table: "sole" */
  delete_sole?: Maybe<Sole_Mutation_Response>;
  /** delete single row from the table: "sole" */
  delete_sole_by_pk?: Maybe<Sole>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "store" */
  delete_store?: Maybe<Store_Mutation_Response>;
  /** delete data from the table: "store_account_role" */
  delete_store_account_role?: Maybe<Store_Account_Role_Mutation_Response>;
  /** delete single row from the table: "store_account_role" */
  delete_store_account_role_by_pk?: Maybe<Store_Account_Role>;
  /** delete data from the table: "store_and_account" */
  delete_store_and_account?: Maybe<Store_And_Account_Mutation_Response>;
  /** delete single row from the table: "store_and_account" */
  delete_store_and_account_by_pk?: Maybe<Store_And_Account>;
  /** delete data from the table: "store_and_hashtag" */
  delete_store_and_hashtag?: Maybe<Store_And_Hashtag_Mutation_Response>;
  /** delete single row from the table: "store_and_hashtag" */
  delete_store_and_hashtag_by_pk?: Maybe<Store_And_Hashtag>;
  /** delete data from the table: "store_and_like" */
  delete_store_and_like?: Maybe<Store_And_Like_Mutation_Response>;
  /** delete single row from the table: "store_and_like" */
  delete_store_and_like_by_pk?: Maybe<Store_And_Like>;
  /** delete data from the table: "store_and_package" */
  delete_store_and_package?: Maybe<Store_And_Package_Mutation_Response>;
  /** delete single row from the table: "store_and_package" */
  delete_store_and_package_by_pk?: Maybe<Store_And_Package>;
  /** delete data from the table: "store_and_product" */
  delete_store_and_product?: Maybe<Store_And_Product_Mutation_Response>;
  /** delete single row from the table: "store_and_product" */
  delete_store_and_product_by_pk?: Maybe<Store_And_Product>;
  /** delete data from the table: "store_and_product_item" */
  delete_store_and_product_item?: Maybe<Store_And_Product_Item_Mutation_Response>;
  /** delete single row from the table: "store_and_product_item" */
  delete_store_and_product_item_by_pk?: Maybe<Store_And_Product_Item>;
  /** delete data from the table: "store_and_review" */
  delete_store_and_review?: Maybe<Store_And_Review_Mutation_Response>;
  /** delete single row from the table: "store_and_review" */
  delete_store_and_review_by_pk?: Maybe<Store_And_Review>;
  /** delete data from the table: "store_and_sole" */
  delete_store_and_sole?: Maybe<Store_And_Sole_Mutation_Response>;
  /** delete single row from the table: "store_and_sole" */
  delete_store_and_sole_by_pk?: Maybe<Store_And_Sole>;
  /** delete single row from the table: "store" */
  delete_store_by_pk?: Maybe<Store>;
  /** delete data from the table: "store_status" */
  delete_store_status?: Maybe<Store_Status_Mutation_Response>;
  /** delete single row from the table: "store_status" */
  delete_store_status_by_pk?: Maybe<Store_Status>;
  /** delete data from the table: "subscription" */
  delete_subscription?: Maybe<Subscription_Mutation_Response>;
  /** delete data from the table: "subscription_and_transaction" */
  delete_subscription_and_transaction?: Maybe<Subscription_And_Transaction_Mutation_Response>;
  /** delete single row from the table: "subscription_and_transaction" */
  delete_subscription_and_transaction_by_pk?: Maybe<Subscription_And_Transaction>;
  /** delete data from the table: "subscription_and_transfer" */
  delete_subscription_and_transfer?: Maybe<Subscription_And_Transfer_Mutation_Response>;
  /** delete single row from the table: "subscription_and_transfer" */
  delete_subscription_and_transfer_by_pk?: Maybe<Subscription_And_Transfer>;
  /** delete single row from the table: "subscription" */
  delete_subscription_by_pk?: Maybe<Subscription>;
  /** delete data from the table: "subscription_status" */
  delete_subscription_status?: Maybe<Subscription_Status_Mutation_Response>;
  /** delete single row from the table: "subscription_status" */
  delete_subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** delete data from the table: "transaction" */
  delete_transaction?: Maybe<Transaction_Mutation_Response>;
  /** delete single row from the table: "transaction" */
  delete_transaction_by_pk?: Maybe<Transaction>;
  /** delete data from the table: "transaction_type" */
  delete_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** delete single row from the table: "transaction_type" */
  delete_transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** delete data from the table: "transfer" */
  delete_transfer?: Maybe<Transfer_Mutation_Response>;
  /** delete single row from the table: "transfer" */
  delete_transfer_by_pk?: Maybe<Transfer>;
  /** delete data from the table: "transfer_type" */
  delete_transfer_type?: Maybe<Transfer_Type_Mutation_Response>;
  /** delete single row from the table: "transfer_type" */
  delete_transfer_type_by_pk?: Maybe<Transfer_Type>;
  /** delete data from the table: "user_actions_history" */
  delete_user_actions_history?: Maybe<User_Actions_History_Mutation_Response>;
  /** delete single row from the table: "user_actions_history" */
  delete_user_actions_history_by_pk?: Maybe<User_Actions_History>;
  /** delete data from the table: "view" */
  delete_view?: Maybe<View_Mutation_Response>;
  /** delete single row from the table: "view" */
  delete_view_by_pk?: Maybe<View>;
  /** delete data from the table: "view_package_store" */
  delete_view_package_store?: Maybe<View_Package_Store_Mutation_Response>;
  /** delete single row from the table: "view_package_store" */
  delete_view_package_store_by_pk?: Maybe<View_Package_Store>;
  /** delete data from the table: "view_store" */
  delete_view_store?: Maybe<View_Store_Mutation_Response>;
  /** delete single row from the table: "view_store" */
  delete_view_store_by_pk?: Maybe<View_Store>;
  /** delete data from the table: "view_store_gallery" */
  delete_view_store_gallery?: Maybe<View_Store_Gallery_Mutation_Response>;
  /** delete single row from the table: "view_store_gallery" */
  delete_view_store_gallery_by_pk?: Maybe<View_Store_Gallery>;
  /** insert data into the table: "account" */
  insert_account?: Maybe<Account_Mutation_Response>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<Account>;
  /** insert data into the table: "account_type" */
  insert_account_type?: Maybe<Account_Type_Mutation_Response>;
  /** insert a single row into the table: "account_type" */
  insert_account_type_one?: Maybe<Account_Type>;
  /** insert data into the table: "accounts_payable" */
  insert_accounts_payable?: Maybe<Accounts_Payable_Mutation_Response>;
  /** insert data into the table: "accounts_payable_and_adjustment" */
  insert_accounts_payable_and_adjustment?: Maybe<Accounts_Payable_And_Adjustment_Mutation_Response>;
  /** insert a single row into the table: "accounts_payable_and_adjustment" */
  insert_accounts_payable_and_adjustment_one?: Maybe<Accounts_Payable_And_Adjustment>;
  /** insert data into the table: "accounts_payable_and_payout" */
  insert_accounts_payable_and_payout?: Maybe<Accounts_Payable_And_Payout_Mutation_Response>;
  /** insert a single row into the table: "accounts_payable_and_payout" */
  insert_accounts_payable_and_payout_one?: Maybe<Accounts_Payable_And_Payout>;
  /** insert data into the table: "accounts_payable_and_refund" */
  insert_accounts_payable_and_refund?: Maybe<Accounts_Payable_And_Refund_Mutation_Response>;
  /** insert a single row into the table: "accounts_payable_and_refund" */
  insert_accounts_payable_and_refund_one?: Maybe<Accounts_Payable_And_Refund>;
  /** insert a single row into the table: "accounts_payable" */
  insert_accounts_payable_one?: Maybe<Accounts_Payable>;
  /** insert data into the table: "accounts_receivable" */
  insert_accounts_receivable?: Maybe<Accounts_Receivable_Mutation_Response>;
  /** insert data into the table: "accounts_receivable_and_invoice" */
  insert_accounts_receivable_and_invoice?: Maybe<Accounts_Receivable_And_Invoice_Mutation_Response>;
  /** insert a single row into the table: "accounts_receivable_and_invoice" */
  insert_accounts_receivable_and_invoice_one?: Maybe<Accounts_Receivable_And_Invoice>;
  /** insert data into the table: "accounts_receivable_and_membership" */
  insert_accounts_receivable_and_membership?: Maybe<Accounts_Receivable_And_Membership_Mutation_Response>;
  /** insert a single row into the table: "accounts_receivable_and_membership" */
  insert_accounts_receivable_and_membership_one?: Maybe<Accounts_Receivable_And_Membership>;
  /** insert data into the table: "accounts_receivable_and_subscription" */
  insert_accounts_receivable_and_subscription?: Maybe<Accounts_Receivable_And_Subscription_Mutation_Response>;
  /** insert a single row into the table: "accounts_receivable_and_subscription" */
  insert_accounts_receivable_and_subscription_one?: Maybe<Accounts_Receivable_And_Subscription>;
  /** insert a single row into the table: "accounts_receivable" */
  insert_accounts_receivable_one?: Maybe<Accounts_Receivable>;
  /** insert data into the table: "activity" */
  insert_activity?: Maybe<Activity_Mutation_Response>;
  /** insert a single row into the table: "activity" */
  insert_activity_one?: Maybe<Activity>;
  /** insert data into the table: "address_sido" */
  insert_address_sido?: Maybe<Address_Sido_Mutation_Response>;
  /** insert a single row into the table: "address_sido" */
  insert_address_sido_one?: Maybe<Address_Sido>;
  /** insert data into the table: "address_sigungu" */
  insert_address_sigungu?: Maybe<Address_Sigungu_Mutation_Response>;
  /** insert a single row into the table: "address_sigungu" */
  insert_address_sigungu_one?: Maybe<Address_Sigungu>;
  /** insert data into the table: "adjustment" */
  insert_adjustment?: Maybe<Adjustment_Mutation_Response>;
  /** insert data into the table: "adjustment_and_transfer" */
  insert_adjustment_and_transfer?: Maybe<Adjustment_And_Transfer_Mutation_Response>;
  /** insert a single row into the table: "adjustment_and_transfer" */
  insert_adjustment_and_transfer_one?: Maybe<Adjustment_And_Transfer>;
  /** insert a single row into the table: "adjustment" */
  insert_adjustment_one?: Maybe<Adjustment>;
  /** insert data into the table: "adjustment_status" */
  insert_adjustment_status?: Maybe<Adjustment_Status_Mutation_Response>;
  /** insert a single row into the table: "adjustment_status" */
  insert_adjustment_status_one?: Maybe<Adjustment_Status>;
  /** insert data into the table: "blog_post" */
  insert_blog_post?: Maybe<Blog_Post_Mutation_Response>;
  /** insert data into the table: "blog_post_and_hashtag" */
  insert_blog_post_and_hashtag?: Maybe<Blog_Post_And_Hashtag_Mutation_Response>;
  /** insert a single row into the table: "blog_post_and_hashtag" */
  insert_blog_post_and_hashtag_one?: Maybe<Blog_Post_And_Hashtag>;
  /** insert data into the table: "blog_post_and_view" */
  insert_blog_post_and_view?: Maybe<Blog_Post_And_View_Mutation_Response>;
  /** insert a single row into the table: "blog_post_and_view" */
  insert_blog_post_and_view_one?: Maybe<Blog_Post_And_View>;
  /** insert a single row into the table: "blog_post" */
  insert_blog_post_one?: Maybe<Blog_Post>;
  /** insert data into the table: "car" */
  insert_car?: Maybe<Car_Mutation_Response>;
  /** insert data into the table: "car_inspection" */
  insert_car_inspection?: Maybe<Car_Inspection_Mutation_Response>;
  /** insert a single row into the table: "car_inspection" */
  insert_car_inspection_one?: Maybe<Car_Inspection>;
  /** insert data into the table: "car_inspection_status" */
  insert_car_inspection_status?: Maybe<Car_Inspection_Status_Mutation_Response>;
  /** insert a single row into the table: "car_inspection_status" */
  insert_car_inspection_status_one?: Maybe<Car_Inspection_Status>;
  /** insert data into the table: "car_maker" */
  insert_car_maker?: Maybe<Car_Maker_Mutation_Response>;
  /** insert a single row into the table: "car_maker" */
  insert_car_maker_one?: Maybe<Car_Maker>;
  /** insert a single row into the table: "car" */
  insert_car_one?: Maybe<Car>;
  /** insert data into the table: "car_origin" */
  insert_car_origin?: Maybe<Car_Origin_Mutation_Response>;
  /** insert a single row into the table: "car_origin" */
  insert_car_origin_one?: Maybe<Car_Origin>;
  /** insert data into the table: "car_sunroof_type" */
  insert_car_sunroof_type?: Maybe<Car_Sunroof_Type_Mutation_Response>;
  /** insert a single row into the table: "car_sunroof_type" */
  insert_car_sunroof_type_one?: Maybe<Car_Sunroof_Type>;
  /** insert data into the table: "car_type" */
  insert_car_type?: Maybe<Car_Type_Mutation_Response>;
  /** insert a single row into the table: "car_type" */
  insert_car_type_one?: Maybe<Car_Type>;
  /** insert data into the table: "cart_item" */
  insert_cart_item?: Maybe<Cart_Item_Mutation_Response>;
  /** insert a single row into the table: "cart_item" */
  insert_cart_item_one?: Maybe<Cart_Item>;
  /** insert data into the table: "chat" */
  insert_chat?: Maybe<Chat_Mutation_Response>;
  /** insert a single row into the table: "chat" */
  insert_chat_one?: Maybe<Chat>;
  /** insert data into the table: "config" */
  insert_config?: Maybe<Config_Mutation_Response>;
  /** insert a single row into the table: "config" */
  insert_config_one?: Maybe<Config>;
  /** insert data into the table: "config_type" */
  insert_config_type?: Maybe<Config_Type_Mutation_Response>;
  /** insert a single row into the table: "config_type" */
  insert_config_type_one?: Maybe<Config_Type>;
  /** insert data into the table: "coupon" */
  insert_coupon?: Maybe<Coupon_Mutation_Response>;
  /** insert data into the table: "coupon_and_account" */
  insert_coupon_and_account?: Maybe<Coupon_And_Account_Mutation_Response>;
  /** insert a single row into the table: "coupon_and_account" */
  insert_coupon_and_account_one?: Maybe<Coupon_And_Account>;
  /** insert a single row into the table: "coupon" */
  insert_coupon_one?: Maybe<Coupon>;
  /** insert data into the table: "coupon_status" */
  insert_coupon_status?: Maybe<Coupon_Status_Mutation_Response>;
  /** insert a single row into the table: "coupon_status" */
  insert_coupon_status_one?: Maybe<Coupon_Status>;
  /** insert data into the table: "curation" */
  insert_curation?: Maybe<Curation_Mutation_Response>;
  /** insert a single row into the table: "curation" */
  insert_curation_one?: Maybe<Curation>;
  /** insert data into the table: "fake_review" */
  insert_fake_review?: Maybe<Fake_Review_Mutation_Response>;
  /** insert a single row into the table: "fake_review" */
  insert_fake_review_one?: Maybe<Fake_Review>;
  /** insert data into the table: "gallery" */
  insert_gallery?: Maybe<Gallery_Mutation_Response>;
  /** insert a single row into the table: "gallery" */
  insert_gallery_one?: Maybe<Gallery>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "hashtag" */
  insert_hashtag?: Maybe<Hashtag_Mutation_Response>;
  /** insert a single row into the table: "hashtag" */
  insert_hashtag_one?: Maybe<Hashtag>;
  /** insert data into the table: "inventory" */
  insert_inventory?: Maybe<Inventory_Mutation_Response>;
  /** insert a single row into the table: "inventory" */
  insert_inventory_one?: Maybe<Inventory>;
  /** insert data into the table: "invoice" */
  insert_invoice?: Maybe<Invoice_Mutation_Response>;
  /** insert data into the table: "invoice_and_transaction" */
  insert_invoice_and_transaction?: Maybe<Invoice_And_Transaction_Mutation_Response>;
  /** insert a single row into the table: "invoice_and_transaction" */
  insert_invoice_and_transaction_one?: Maybe<Invoice_And_Transaction>;
  /** insert data into the table: "invoice_and_transfer" */
  insert_invoice_and_transfer?: Maybe<Invoice_And_Transfer_Mutation_Response>;
  /** insert a single row into the table: "invoice_and_transfer" */
  insert_invoice_and_transfer_one?: Maybe<Invoice_And_Transfer>;
  /** insert a single row into the table: "invoice" */
  insert_invoice_one?: Maybe<Invoice>;
  /** insert data into the table: "invoice_status" */
  insert_invoice_status?: Maybe<Invoice_Status_Mutation_Response>;
  /** insert a single row into the table: "invoice_status" */
  insert_invoice_status_one?: Maybe<Invoice_Status>;
  /** insert data into the table: "kakao_and_account" */
  insert_kakao_and_account?: Maybe<Kakao_And_Account_Mutation_Response>;
  /** insert a single row into the table: "kakao_and_account" */
  insert_kakao_and_account_one?: Maybe<Kakao_And_Account>;
  /** insert data into the table: "lead" */
  insert_lead?: Maybe<Lead_Mutation_Response>;
  /** insert data into the table: "lead_comment" */
  insert_lead_comment?: Maybe<Lead_Comment_Mutation_Response>;
  /** insert a single row into the table: "lead_comment" */
  insert_lead_comment_one?: Maybe<Lead_Comment>;
  /** insert a single row into the table: "lead" */
  insert_lead_one?: Maybe<Lead>;
  /** insert data into the table: "lead_status" */
  insert_lead_status?: Maybe<Lead_Status_Mutation_Response>;
  /** insert a single row into the table: "lead_status" */
  insert_lead_status_one?: Maybe<Lead_Status>;
  /** insert data into the table: "like" */
  insert_like?: Maybe<Like_Mutation_Response>;
  /** insert a single row into the table: "like" */
  insert_like_one?: Maybe<Like>;
  /** insert data into the table: "membership" */
  insert_membership?: Maybe<Membership_Mutation_Response>;
  /** insert data into the table: "membership_log" */
  insert_membership_log?: Maybe<Membership_Log_Mutation_Response>;
  /** insert a single row into the table: "membership_log" */
  insert_membership_log_one?: Maybe<Membership_Log>;
  /** insert a single row into the table: "membership" */
  insert_membership_one?: Maybe<Membership>;
  /** insert data into the table: "membership_type" */
  insert_membership_type?: Maybe<Membership_Type_Mutation_Response>;
  /** insert a single row into the table: "membership_type" */
  insert_membership_type_one?: Maybe<Membership_Type>;
  /** insert data into the table: "n_cart" */
  insert_n_cart?: Maybe<N_Cart_Mutation_Response>;
  /** insert a single row into the table: "n_cart" */
  insert_n_cart_one?: Maybe<N_Cart>;
  /** insert data into the table: "n_order" */
  insert_n_order?: Maybe<N_Order_Mutation_Response>;
  /** insert a single row into the table: "n_order" */
  insert_n_order_one?: Maybe<N_Order>;
  /** insert data into the table: "order" */
  insert_order?: Maybe<Order_Mutation_Response>;
  /** insert data into the table: "order_and_review" */
  insert_order_and_review?: Maybe<Order_And_Review_Mutation_Response>;
  /** insert a single row into the table: "order_and_review" */
  insert_order_and_review_one?: Maybe<Order_And_Review>;
  /** insert data into the table: "order_and_transaction" */
  insert_order_and_transaction?: Maybe<Order_And_Transaction_Mutation_Response>;
  /** insert a single row into the table: "order_and_transaction" */
  insert_order_and_transaction_one?: Maybe<Order_And_Transaction>;
  /** insert data into the table: "order_and_transfer" */
  insert_order_and_transfer?: Maybe<Order_And_Transfer_Mutation_Response>;
  /** insert a single row into the table: "order_and_transfer" */
  insert_order_and_transfer_one?: Maybe<Order_And_Transfer>;
  /** insert data into the table: "order_from_store" */
  insert_order_from_store?: Maybe<Order_From_Store_Mutation_Response>;
  /** insert a single row into the table: "order_from_store" */
  insert_order_from_store_one?: Maybe<Order_From_Store>;
  /** insert data into the table: "order_from_store_status" */
  insert_order_from_store_status?: Maybe<Order_From_Store_Status_Mutation_Response>;
  /** insert a single row into the table: "order_from_store_status" */
  insert_order_from_store_status_one?: Maybe<Order_From_Store_Status>;
  /** insert data into the table: "order_item" */
  insert_order_item?: Maybe<Order_Item_Mutation_Response>;
  /** insert a single row into the table: "order_item" */
  insert_order_item_one?: Maybe<Order_Item>;
  /** insert a single row into the table: "order" */
  insert_order_one?: Maybe<Order>;
  /** insert data into the table: "order_status" */
  insert_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** insert a single row into the table: "order_status" */
  insert_order_status_one?: Maybe<Order_Status>;
  /** insert data into the table: "package" */
  insert_package?: Maybe<Package_Mutation_Response>;
  /** insert a single row into the table: "package" */
  insert_package_one?: Maybe<Package>;
  /** insert data into the table: "package_type" */
  insert_package_type?: Maybe<Package_Type_Mutation_Response>;
  /** insert a single row into the table: "package_type" */
  insert_package_type_one?: Maybe<Package_Type>;
  /** insert data into the table: "payout" */
  insert_payout?: Maybe<Payout_Mutation_Response>;
  /** insert data into the table: "payout_and_transfer" */
  insert_payout_and_transfer?: Maybe<Payout_And_Transfer_Mutation_Response>;
  /** insert a single row into the table: "payout_and_transfer" */
  insert_payout_and_transfer_one?: Maybe<Payout_And_Transfer>;
  /** insert a single row into the table: "payout" */
  insert_payout_one?: Maybe<Payout>;
  /** insert data into the table: "payout_status" */
  insert_payout_status?: Maybe<Payout_Status_Mutation_Response>;
  /** insert a single row into the table: "payout_status" */
  insert_payout_status_one?: Maybe<Payout_Status>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "product_and_like" */
  insert_product_and_like?: Maybe<Product_And_Like_Mutation_Response>;
  /** insert a single row into the table: "product_and_like" */
  insert_product_and_like_one?: Maybe<Product_And_Like>;
  /** insert data into the table: "product_brand" */
  insert_product_brand?: Maybe<Product_Brand_Mutation_Response>;
  /** insert a single row into the table: "product_brand" */
  insert_product_brand_one?: Maybe<Product_Brand>;
  /** insert data into the table: "product_item" */
  insert_product_item?: Maybe<Product_Item_Mutation_Response>;
  /** insert data into the table: "product_item_and_review" */
  insert_product_item_and_review?: Maybe<Product_Item_And_Review_Mutation_Response>;
  /** insert a single row into the table: "product_item_and_review" */
  insert_product_item_and_review_one?: Maybe<Product_Item_And_Review>;
  /** insert a single row into the table: "product_item" */
  insert_product_item_one?: Maybe<Product_Item>;
  /** insert data into the table: "product_item_price_by_store" */
  insert_product_item_price_by_store?: Maybe<Product_Item_Price_By_Store_Mutation_Response>;
  /** insert a single row into the table: "product_item_price_by_store" */
  insert_product_item_price_by_store_one?: Maybe<Product_Item_Price_By_Store>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "product_type" */
  insert_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** insert a single row into the table: "product_type" */
  insert_product_type_one?: Maybe<Product_Type>;
  /** insert data into the table: "profile_kr_customer" */
  insert_profile_kr_customer?: Maybe<Profile_Kr_Customer_Mutation_Response>;
  /** insert a single row into the table: "profile_kr_customer" */
  insert_profile_kr_customer_one?: Maybe<Profile_Kr_Customer>;
  /** insert data into the table: "profile_kr_partner" */
  insert_profile_kr_partner?: Maybe<Profile_Kr_Partner_Mutation_Response>;
  /** insert a single row into the table: "profile_kr_partner" */
  insert_profile_kr_partner_one?: Maybe<Profile_Kr_Partner>;
  /** insert data into the table: "profile_language" */
  insert_profile_language?: Maybe<Profile_Language_Mutation_Response>;
  /** insert a single row into the table: "profile_language" */
  insert_profile_language_one?: Maybe<Profile_Language>;
  /** insert data into the table: "profile_riderdash" */
  insert_profile_riderdash?: Maybe<Profile_Riderdash_Mutation_Response>;
  /** insert a single row into the table: "profile_riderdash" */
  insert_profile_riderdash_one?: Maybe<Profile_Riderdash>;
  /** insert data into the table: "profile_riderdash_role" */
  insert_profile_riderdash_role?: Maybe<Profile_Riderdash_Role_Mutation_Response>;
  /** insert a single row into the table: "profile_riderdash_role" */
  insert_profile_riderdash_role_one?: Maybe<Profile_Riderdash_Role>;
  /** insert data into the table: "profile_us_customer" */
  insert_profile_us_customer?: Maybe<Profile_Us_Customer_Mutation_Response>;
  /** insert a single row into the table: "profile_us_customer" */
  insert_profile_us_customer_one?: Maybe<Profile_Us_Customer>;
  /** insert data into the table: "profile_us_partner" */
  insert_profile_us_partner?: Maybe<Profile_Us_Partner_Mutation_Response>;
  /** insert a single row into the table: "profile_us_partner" */
  insert_profile_us_partner_one?: Maybe<Profile_Us_Partner>;
  /** insert data into the table: "promotion" */
  insert_promotion?: Maybe<Promotion_Mutation_Response>;
  /** insert a single row into the table: "promotion" */
  insert_promotion_one?: Maybe<Promotion>;
  /** insert data into the table: "quote" */
  insert_quote?: Maybe<Quote_Mutation_Response>;
  /** insert data into the table: "quote_item" */
  insert_quote_item?: Maybe<Quote_Item_Mutation_Response>;
  /** insert a single row into the table: "quote_item" */
  insert_quote_item_one?: Maybe<Quote_Item>;
  /** insert a single row into the table: "quote" */
  insert_quote_one?: Maybe<Quote>;
  /** insert data into the table: "quote_request" */
  insert_quote_request?: Maybe<Quote_Request_Mutation_Response>;
  /** insert a single row into the table: "quote_request" */
  insert_quote_request_one?: Maybe<Quote_Request>;
  /** insert data into the table: "refund" */
  insert_refund?: Maybe<Refund_Mutation_Response>;
  /** insert data into the table: "refund_and_transaction" */
  insert_refund_and_transaction?: Maybe<Refund_And_Transaction_Mutation_Response>;
  /** insert a single row into the table: "refund_and_transaction" */
  insert_refund_and_transaction_one?: Maybe<Refund_And_Transaction>;
  /** insert data into the table: "refund_and_transfer" */
  insert_refund_and_transfer?: Maybe<Refund_And_Transfer_Mutation_Response>;
  /** insert a single row into the table: "refund_and_transfer" */
  insert_refund_and_transfer_one?: Maybe<Refund_And_Transfer>;
  /** insert a single row into the table: "refund" */
  insert_refund_one?: Maybe<Refund>;
  /** insert data into the table: "refund_status" */
  insert_refund_status?: Maybe<Refund_Status_Mutation_Response>;
  /** insert a single row into the table: "refund_status" */
  insert_refund_status_one?: Maybe<Refund_Status>;
  /** insert data into the table: "review" */
  insert_review?: Maybe<Review_Mutation_Response>;
  /** insert a single row into the table: "review" */
  insert_review_one?: Maybe<Review>;
  /** insert data into the table: "review_status" */
  insert_review_status?: Maybe<Review_Status_Mutation_Response>;
  /** insert a single row into the table: "review_status" */
  insert_review_status_one?: Maybe<Review_Status>;
  /** insert data into the table: "session" */
  insert_session?: Maybe<Session_Mutation_Response>;
  /** insert a single row into the table: "session" */
  insert_session_one?: Maybe<Session>;
  /** insert data into the table: "slot" */
  insert_slot?: Maybe<Slot_Mutation_Response>;
  /** insert a single row into the table: "slot" */
  insert_slot_one?: Maybe<Slot>;
  /** insert data into the table: "sole" */
  insert_sole?: Maybe<Sole_Mutation_Response>;
  /** insert a single row into the table: "sole" */
  insert_sole_one?: Maybe<Sole>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "store" */
  insert_store?: Maybe<Store_Mutation_Response>;
  /** insert data into the table: "store_account_role" */
  insert_store_account_role?: Maybe<Store_Account_Role_Mutation_Response>;
  /** insert a single row into the table: "store_account_role" */
  insert_store_account_role_one?: Maybe<Store_Account_Role>;
  /** insert data into the table: "store_and_account" */
  insert_store_and_account?: Maybe<Store_And_Account_Mutation_Response>;
  /** insert a single row into the table: "store_and_account" */
  insert_store_and_account_one?: Maybe<Store_And_Account>;
  /** insert data into the table: "store_and_hashtag" */
  insert_store_and_hashtag?: Maybe<Store_And_Hashtag_Mutation_Response>;
  /** insert a single row into the table: "store_and_hashtag" */
  insert_store_and_hashtag_one?: Maybe<Store_And_Hashtag>;
  /** insert data into the table: "store_and_like" */
  insert_store_and_like?: Maybe<Store_And_Like_Mutation_Response>;
  /** insert a single row into the table: "store_and_like" */
  insert_store_and_like_one?: Maybe<Store_And_Like>;
  /** insert data into the table: "store_and_package" */
  insert_store_and_package?: Maybe<Store_And_Package_Mutation_Response>;
  /** insert a single row into the table: "store_and_package" */
  insert_store_and_package_one?: Maybe<Store_And_Package>;
  /** insert data into the table: "store_and_product" */
  insert_store_and_product?: Maybe<Store_And_Product_Mutation_Response>;
  /** insert data into the table: "store_and_product_item" */
  insert_store_and_product_item?: Maybe<Store_And_Product_Item_Mutation_Response>;
  /** insert a single row into the table: "store_and_product_item" */
  insert_store_and_product_item_one?: Maybe<Store_And_Product_Item>;
  /** insert a single row into the table: "store_and_product" */
  insert_store_and_product_one?: Maybe<Store_And_Product>;
  /** insert data into the table: "store_and_review" */
  insert_store_and_review?: Maybe<Store_And_Review_Mutation_Response>;
  /** insert a single row into the table: "store_and_review" */
  insert_store_and_review_one?: Maybe<Store_And_Review>;
  /** insert data into the table: "store_and_sole" */
  insert_store_and_sole?: Maybe<Store_And_Sole_Mutation_Response>;
  /** insert a single row into the table: "store_and_sole" */
  insert_store_and_sole_one?: Maybe<Store_And_Sole>;
  /** insert a single row into the table: "store" */
  insert_store_one?: Maybe<Store>;
  /** insert data into the table: "store_status" */
  insert_store_status?: Maybe<Store_Status_Mutation_Response>;
  /** insert a single row into the table: "store_status" */
  insert_store_status_one?: Maybe<Store_Status>;
  /** insert data into the table: "subscription" */
  insert_subscription?: Maybe<Subscription_Mutation_Response>;
  /** insert data into the table: "subscription_and_transaction" */
  insert_subscription_and_transaction?: Maybe<Subscription_And_Transaction_Mutation_Response>;
  /** insert a single row into the table: "subscription_and_transaction" */
  insert_subscription_and_transaction_one?: Maybe<Subscription_And_Transaction>;
  /** insert data into the table: "subscription_and_transfer" */
  insert_subscription_and_transfer?: Maybe<Subscription_And_Transfer_Mutation_Response>;
  /** insert a single row into the table: "subscription_and_transfer" */
  insert_subscription_and_transfer_one?: Maybe<Subscription_And_Transfer>;
  /** insert a single row into the table: "subscription" */
  insert_subscription_one?: Maybe<Subscription>;
  /** insert data into the table: "subscription_status" */
  insert_subscription_status?: Maybe<Subscription_Status_Mutation_Response>;
  /** insert a single row into the table: "subscription_status" */
  insert_subscription_status_one?: Maybe<Subscription_Status>;
  /** insert data into the table: "transaction" */
  insert_transaction?: Maybe<Transaction_Mutation_Response>;
  /** insert a single row into the table: "transaction" */
  insert_transaction_one?: Maybe<Transaction>;
  /** insert data into the table: "transaction_type" */
  insert_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** insert a single row into the table: "transaction_type" */
  insert_transaction_type_one?: Maybe<Transaction_Type>;
  /** insert data into the table: "transfer" */
  insert_transfer?: Maybe<Transfer_Mutation_Response>;
  /** insert a single row into the table: "transfer" */
  insert_transfer_one?: Maybe<Transfer>;
  /** insert data into the table: "transfer_type" */
  insert_transfer_type?: Maybe<Transfer_Type_Mutation_Response>;
  /** insert a single row into the table: "transfer_type" */
  insert_transfer_type_one?: Maybe<Transfer_Type>;
  /** insert data into the table: "user_actions_history" */
  insert_user_actions_history?: Maybe<User_Actions_History_Mutation_Response>;
  /** insert a single row into the table: "user_actions_history" */
  insert_user_actions_history_one?: Maybe<User_Actions_History>;
  /** insert data into the table: "view" */
  insert_view?: Maybe<View_Mutation_Response>;
  /** insert a single row into the table: "view" */
  insert_view_one?: Maybe<View>;
  /** insert data into the table: "view_package_store" */
  insert_view_package_store?: Maybe<View_Package_Store_Mutation_Response>;
  /** insert a single row into the table: "view_package_store" */
  insert_view_package_store_one?: Maybe<View_Package_Store>;
  /** insert data into the table: "view_store" */
  insert_view_store?: Maybe<View_Store_Mutation_Response>;
  /** insert data into the table: "view_store_gallery" */
  insert_view_store_gallery?: Maybe<View_Store_Gallery_Mutation_Response>;
  /** insert a single row into the table: "view_store_gallery" */
  insert_view_store_gallery_one?: Maybe<View_Store_Gallery>;
  /** insert a single row into the table: "view_store" */
  insert_view_store_one?: Maybe<View_Store>;
  /** update data of the table: "account" */
  update_account?: Maybe<Account_Mutation_Response>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<Account>;
  /** update data of the table: "account_type" */
  update_account_type?: Maybe<Account_Type_Mutation_Response>;
  /** update single row of the table: "account_type" */
  update_account_type_by_pk?: Maybe<Account_Type>;
  /** update data of the table: "accounts_payable" */
  update_accounts_payable?: Maybe<Accounts_Payable_Mutation_Response>;
  /** update data of the table: "accounts_payable_and_adjustment" */
  update_accounts_payable_and_adjustment?: Maybe<Accounts_Payable_And_Adjustment_Mutation_Response>;
  /** update single row of the table: "accounts_payable_and_adjustment" */
  update_accounts_payable_and_adjustment_by_pk?: Maybe<Accounts_Payable_And_Adjustment>;
  /** update data of the table: "accounts_payable_and_payout" */
  update_accounts_payable_and_payout?: Maybe<Accounts_Payable_And_Payout_Mutation_Response>;
  /** update single row of the table: "accounts_payable_and_payout" */
  update_accounts_payable_and_payout_by_pk?: Maybe<Accounts_Payable_And_Payout>;
  /** update data of the table: "accounts_payable_and_refund" */
  update_accounts_payable_and_refund?: Maybe<Accounts_Payable_And_Refund_Mutation_Response>;
  /** update single row of the table: "accounts_payable_and_refund" */
  update_accounts_payable_and_refund_by_pk?: Maybe<Accounts_Payable_And_Refund>;
  /** update single row of the table: "accounts_payable" */
  update_accounts_payable_by_pk?: Maybe<Accounts_Payable>;
  /** update data of the table: "accounts_receivable" */
  update_accounts_receivable?: Maybe<Accounts_Receivable_Mutation_Response>;
  /** update data of the table: "accounts_receivable_and_invoice" */
  update_accounts_receivable_and_invoice?: Maybe<Accounts_Receivable_And_Invoice_Mutation_Response>;
  /** update single row of the table: "accounts_receivable_and_invoice" */
  update_accounts_receivable_and_invoice_by_pk?: Maybe<Accounts_Receivable_And_Invoice>;
  /** update data of the table: "accounts_receivable_and_membership" */
  update_accounts_receivable_and_membership?: Maybe<Accounts_Receivable_And_Membership_Mutation_Response>;
  /** update single row of the table: "accounts_receivable_and_membership" */
  update_accounts_receivable_and_membership_by_pk?: Maybe<Accounts_Receivable_And_Membership>;
  /** update data of the table: "accounts_receivable_and_subscription" */
  update_accounts_receivable_and_subscription?: Maybe<Accounts_Receivable_And_Subscription_Mutation_Response>;
  /** update single row of the table: "accounts_receivable_and_subscription" */
  update_accounts_receivable_and_subscription_by_pk?: Maybe<Accounts_Receivable_And_Subscription>;
  /** update single row of the table: "accounts_receivable" */
  update_accounts_receivable_by_pk?: Maybe<Accounts_Receivable>;
  /** update data of the table: "activity" */
  update_activity?: Maybe<Activity_Mutation_Response>;
  /** update single row of the table: "activity" */
  update_activity_by_pk?: Maybe<Activity>;
  /** update data of the table: "address_sido" */
  update_address_sido?: Maybe<Address_Sido_Mutation_Response>;
  /** update single row of the table: "address_sido" */
  update_address_sido_by_pk?: Maybe<Address_Sido>;
  /** update data of the table: "address_sigungu" */
  update_address_sigungu?: Maybe<Address_Sigungu_Mutation_Response>;
  /** update single row of the table: "address_sigungu" */
  update_address_sigungu_by_pk?: Maybe<Address_Sigungu>;
  /** update data of the table: "adjustment" */
  update_adjustment?: Maybe<Adjustment_Mutation_Response>;
  /** update data of the table: "adjustment_and_transfer" */
  update_adjustment_and_transfer?: Maybe<Adjustment_And_Transfer_Mutation_Response>;
  /** update single row of the table: "adjustment_and_transfer" */
  update_adjustment_and_transfer_by_pk?: Maybe<Adjustment_And_Transfer>;
  /** update single row of the table: "adjustment" */
  update_adjustment_by_pk?: Maybe<Adjustment>;
  /** update data of the table: "adjustment_status" */
  update_adjustment_status?: Maybe<Adjustment_Status_Mutation_Response>;
  /** update single row of the table: "adjustment_status" */
  update_adjustment_status_by_pk?: Maybe<Adjustment_Status>;
  /** update data of the table: "blog_post" */
  update_blog_post?: Maybe<Blog_Post_Mutation_Response>;
  /** update data of the table: "blog_post_and_hashtag" */
  update_blog_post_and_hashtag?: Maybe<Blog_Post_And_Hashtag_Mutation_Response>;
  /** update single row of the table: "blog_post_and_hashtag" */
  update_blog_post_and_hashtag_by_pk?: Maybe<Blog_Post_And_Hashtag>;
  /** update data of the table: "blog_post_and_view" */
  update_blog_post_and_view?: Maybe<Blog_Post_And_View_Mutation_Response>;
  /** update single row of the table: "blog_post_and_view" */
  update_blog_post_and_view_by_pk?: Maybe<Blog_Post_And_View>;
  /** update single row of the table: "blog_post" */
  update_blog_post_by_pk?: Maybe<Blog_Post>;
  /** update data of the table: "car" */
  update_car?: Maybe<Car_Mutation_Response>;
  /** update single row of the table: "car" */
  update_car_by_pk?: Maybe<Car>;
  /** update data of the table: "car_inspection" */
  update_car_inspection?: Maybe<Car_Inspection_Mutation_Response>;
  /** update single row of the table: "car_inspection" */
  update_car_inspection_by_pk?: Maybe<Car_Inspection>;
  /** update data of the table: "car_inspection_status" */
  update_car_inspection_status?: Maybe<Car_Inspection_Status_Mutation_Response>;
  /** update single row of the table: "car_inspection_status" */
  update_car_inspection_status_by_pk?: Maybe<Car_Inspection_Status>;
  /** update data of the table: "car_maker" */
  update_car_maker?: Maybe<Car_Maker_Mutation_Response>;
  /** update single row of the table: "car_maker" */
  update_car_maker_by_pk?: Maybe<Car_Maker>;
  /** update data of the table: "car_origin" */
  update_car_origin?: Maybe<Car_Origin_Mutation_Response>;
  /** update single row of the table: "car_origin" */
  update_car_origin_by_pk?: Maybe<Car_Origin>;
  /** update data of the table: "car_sunroof_type" */
  update_car_sunroof_type?: Maybe<Car_Sunroof_Type_Mutation_Response>;
  /** update single row of the table: "car_sunroof_type" */
  update_car_sunroof_type_by_pk?: Maybe<Car_Sunroof_Type>;
  /** update data of the table: "car_type" */
  update_car_type?: Maybe<Car_Type_Mutation_Response>;
  /** update single row of the table: "car_type" */
  update_car_type_by_pk?: Maybe<Car_Type>;
  /** update data of the table: "cart_item" */
  update_cart_item?: Maybe<Cart_Item_Mutation_Response>;
  /** update single row of the table: "cart_item" */
  update_cart_item_by_pk?: Maybe<Cart_Item>;
  /** update data of the table: "chat" */
  update_chat?: Maybe<Chat_Mutation_Response>;
  /** update single row of the table: "chat" */
  update_chat_by_pk?: Maybe<Chat>;
  /** update data of the table: "config" */
  update_config?: Maybe<Config_Mutation_Response>;
  /** update single row of the table: "config" */
  update_config_by_pk?: Maybe<Config>;
  /** update data of the table: "config_type" */
  update_config_type?: Maybe<Config_Type_Mutation_Response>;
  /** update single row of the table: "config_type" */
  update_config_type_by_pk?: Maybe<Config_Type>;
  /** update data of the table: "coupon" */
  update_coupon?: Maybe<Coupon_Mutation_Response>;
  /** update data of the table: "coupon_and_account" */
  update_coupon_and_account?: Maybe<Coupon_And_Account_Mutation_Response>;
  /** update single row of the table: "coupon_and_account" */
  update_coupon_and_account_by_pk?: Maybe<Coupon_And_Account>;
  /** update single row of the table: "coupon" */
  update_coupon_by_pk?: Maybe<Coupon>;
  /** update data of the table: "coupon_status" */
  update_coupon_status?: Maybe<Coupon_Status_Mutation_Response>;
  /** update single row of the table: "coupon_status" */
  update_coupon_status_by_pk?: Maybe<Coupon_Status>;
  /** update data of the table: "curation" */
  update_curation?: Maybe<Curation_Mutation_Response>;
  /** update single row of the table: "curation" */
  update_curation_by_pk?: Maybe<Curation>;
  /** update data of the table: "fake_review" */
  update_fake_review?: Maybe<Fake_Review_Mutation_Response>;
  /** update single row of the table: "fake_review" */
  update_fake_review_by_pk?: Maybe<Fake_Review>;
  /** update data of the table: "gallery" */
  update_gallery?: Maybe<Gallery_Mutation_Response>;
  /** update single row of the table: "gallery" */
  update_gallery_by_pk?: Maybe<Gallery>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update data of the table: "hashtag" */
  update_hashtag?: Maybe<Hashtag_Mutation_Response>;
  /** update single row of the table: "hashtag" */
  update_hashtag_by_pk?: Maybe<Hashtag>;
  /** update data of the table: "inventory" */
  update_inventory?: Maybe<Inventory_Mutation_Response>;
  /** update single row of the table: "inventory" */
  update_inventory_by_pk?: Maybe<Inventory>;
  /** update data of the table: "invoice" */
  update_invoice?: Maybe<Invoice_Mutation_Response>;
  /** update data of the table: "invoice_and_transaction" */
  update_invoice_and_transaction?: Maybe<Invoice_And_Transaction_Mutation_Response>;
  /** update single row of the table: "invoice_and_transaction" */
  update_invoice_and_transaction_by_pk?: Maybe<Invoice_And_Transaction>;
  /** update data of the table: "invoice_and_transfer" */
  update_invoice_and_transfer?: Maybe<Invoice_And_Transfer_Mutation_Response>;
  /** update single row of the table: "invoice_and_transfer" */
  update_invoice_and_transfer_by_pk?: Maybe<Invoice_And_Transfer>;
  /** update single row of the table: "invoice" */
  update_invoice_by_pk?: Maybe<Invoice>;
  /** update data of the table: "invoice_status" */
  update_invoice_status?: Maybe<Invoice_Status_Mutation_Response>;
  /** update single row of the table: "invoice_status" */
  update_invoice_status_by_pk?: Maybe<Invoice_Status>;
  /** update data of the table: "kakao_and_account" */
  update_kakao_and_account?: Maybe<Kakao_And_Account_Mutation_Response>;
  /** update single row of the table: "kakao_and_account" */
  update_kakao_and_account_by_pk?: Maybe<Kakao_And_Account>;
  /** update data of the table: "lead" */
  update_lead?: Maybe<Lead_Mutation_Response>;
  /** update single row of the table: "lead" */
  update_lead_by_pk?: Maybe<Lead>;
  /** update data of the table: "lead_comment" */
  update_lead_comment?: Maybe<Lead_Comment_Mutation_Response>;
  /** update single row of the table: "lead_comment" */
  update_lead_comment_by_pk?: Maybe<Lead_Comment>;
  /** update data of the table: "lead_status" */
  update_lead_status?: Maybe<Lead_Status_Mutation_Response>;
  /** update single row of the table: "lead_status" */
  update_lead_status_by_pk?: Maybe<Lead_Status>;
  /** update data of the table: "like" */
  update_like?: Maybe<Like_Mutation_Response>;
  /** update single row of the table: "like" */
  update_like_by_pk?: Maybe<Like>;
  /** update data of the table: "membership" */
  update_membership?: Maybe<Membership_Mutation_Response>;
  /** update single row of the table: "membership" */
  update_membership_by_pk?: Maybe<Membership>;
  /** update data of the table: "membership_log" */
  update_membership_log?: Maybe<Membership_Log_Mutation_Response>;
  /** update single row of the table: "membership_log" */
  update_membership_log_by_pk?: Maybe<Membership_Log>;
  /** update data of the table: "membership_type" */
  update_membership_type?: Maybe<Membership_Type_Mutation_Response>;
  /** update single row of the table: "membership_type" */
  update_membership_type_by_pk?: Maybe<Membership_Type>;
  /** update data of the table: "n_cart" */
  update_n_cart?: Maybe<N_Cart_Mutation_Response>;
  /** update single row of the table: "n_cart" */
  update_n_cart_by_pk?: Maybe<N_Cart>;
  /** update data of the table: "n_order" */
  update_n_order?: Maybe<N_Order_Mutation_Response>;
  /** update single row of the table: "n_order" */
  update_n_order_by_pk?: Maybe<N_Order>;
  /** update data of the table: "order" */
  update_order?: Maybe<Order_Mutation_Response>;
  /** update data of the table: "order_and_review" */
  update_order_and_review?: Maybe<Order_And_Review_Mutation_Response>;
  /** update single row of the table: "order_and_review" */
  update_order_and_review_by_pk?: Maybe<Order_And_Review>;
  /** update data of the table: "order_and_transaction" */
  update_order_and_transaction?: Maybe<Order_And_Transaction_Mutation_Response>;
  /** update single row of the table: "order_and_transaction" */
  update_order_and_transaction_by_pk?: Maybe<Order_And_Transaction>;
  /** update data of the table: "order_and_transfer" */
  update_order_and_transfer?: Maybe<Order_And_Transfer_Mutation_Response>;
  /** update single row of the table: "order_and_transfer" */
  update_order_and_transfer_by_pk?: Maybe<Order_And_Transfer>;
  /** update single row of the table: "order" */
  update_order_by_pk?: Maybe<Order>;
  /** update data of the table: "order_from_store" */
  update_order_from_store?: Maybe<Order_From_Store_Mutation_Response>;
  /** update single row of the table: "order_from_store" */
  update_order_from_store_by_pk?: Maybe<Order_From_Store>;
  /** update data of the table: "order_from_store_status" */
  update_order_from_store_status?: Maybe<Order_From_Store_Status_Mutation_Response>;
  /** update single row of the table: "order_from_store_status" */
  update_order_from_store_status_by_pk?: Maybe<Order_From_Store_Status>;
  /** update data of the table: "order_item" */
  update_order_item?: Maybe<Order_Item_Mutation_Response>;
  /** update single row of the table: "order_item" */
  update_order_item_by_pk?: Maybe<Order_Item>;
  /** update data of the table: "order_status" */
  update_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** update single row of the table: "order_status" */
  update_order_status_by_pk?: Maybe<Order_Status>;
  /** update data of the table: "package" */
  update_package?: Maybe<Package_Mutation_Response>;
  /** update single row of the table: "package" */
  update_package_by_pk?: Maybe<Package>;
  /** update data of the table: "package_type" */
  update_package_type?: Maybe<Package_Type_Mutation_Response>;
  /** update single row of the table: "package_type" */
  update_package_type_by_pk?: Maybe<Package_Type>;
  /** update data of the table: "payout" */
  update_payout?: Maybe<Payout_Mutation_Response>;
  /** update data of the table: "payout_and_transfer" */
  update_payout_and_transfer?: Maybe<Payout_And_Transfer_Mutation_Response>;
  /** update single row of the table: "payout_and_transfer" */
  update_payout_and_transfer_by_pk?: Maybe<Payout_And_Transfer>;
  /** update single row of the table: "payout" */
  update_payout_by_pk?: Maybe<Payout>;
  /** update data of the table: "payout_status" */
  update_payout_status?: Maybe<Payout_Status_Mutation_Response>;
  /** update single row of the table: "payout_status" */
  update_payout_status_by_pk?: Maybe<Payout_Status>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update data of the table: "product_and_like" */
  update_product_and_like?: Maybe<Product_And_Like_Mutation_Response>;
  /** update single row of the table: "product_and_like" */
  update_product_and_like_by_pk?: Maybe<Product_And_Like>;
  /** update data of the table: "product_brand" */
  update_product_brand?: Maybe<Product_Brand_Mutation_Response>;
  /** update single row of the table: "product_brand" */
  update_product_brand_by_pk?: Maybe<Product_Brand>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "product_item" */
  update_product_item?: Maybe<Product_Item_Mutation_Response>;
  /** update data of the table: "product_item_and_review" */
  update_product_item_and_review?: Maybe<Product_Item_And_Review_Mutation_Response>;
  /** update single row of the table: "product_item_and_review" */
  update_product_item_and_review_by_pk?: Maybe<Product_Item_And_Review>;
  /** update single row of the table: "product_item" */
  update_product_item_by_pk?: Maybe<Product_Item>;
  /** update data of the table: "product_item_price_by_store" */
  update_product_item_price_by_store?: Maybe<Product_Item_Price_By_Store_Mutation_Response>;
  /** update single row of the table: "product_item_price_by_store" */
  update_product_item_price_by_store_by_pk?: Maybe<Product_Item_Price_By_Store>;
  /** update data of the table: "product_type" */
  update_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** update single row of the table: "product_type" */
  update_product_type_by_pk?: Maybe<Product_Type>;
  /** update data of the table: "profile_kr_customer" */
  update_profile_kr_customer?: Maybe<Profile_Kr_Customer_Mutation_Response>;
  /** update single row of the table: "profile_kr_customer" */
  update_profile_kr_customer_by_pk?: Maybe<Profile_Kr_Customer>;
  /** update data of the table: "profile_kr_partner" */
  update_profile_kr_partner?: Maybe<Profile_Kr_Partner_Mutation_Response>;
  /** update single row of the table: "profile_kr_partner" */
  update_profile_kr_partner_by_pk?: Maybe<Profile_Kr_Partner>;
  /** update data of the table: "profile_language" */
  update_profile_language?: Maybe<Profile_Language_Mutation_Response>;
  /** update single row of the table: "profile_language" */
  update_profile_language_by_pk?: Maybe<Profile_Language>;
  /** update data of the table: "profile_riderdash" */
  update_profile_riderdash?: Maybe<Profile_Riderdash_Mutation_Response>;
  /** update single row of the table: "profile_riderdash" */
  update_profile_riderdash_by_pk?: Maybe<Profile_Riderdash>;
  /** update data of the table: "profile_riderdash_role" */
  update_profile_riderdash_role?: Maybe<Profile_Riderdash_Role_Mutation_Response>;
  /** update single row of the table: "profile_riderdash_role" */
  update_profile_riderdash_role_by_pk?: Maybe<Profile_Riderdash_Role>;
  /** update data of the table: "profile_us_customer" */
  update_profile_us_customer?: Maybe<Profile_Us_Customer_Mutation_Response>;
  /** update single row of the table: "profile_us_customer" */
  update_profile_us_customer_by_pk?: Maybe<Profile_Us_Customer>;
  /** update data of the table: "profile_us_partner" */
  update_profile_us_partner?: Maybe<Profile_Us_Partner_Mutation_Response>;
  /** update single row of the table: "profile_us_partner" */
  update_profile_us_partner_by_pk?: Maybe<Profile_Us_Partner>;
  /** update data of the table: "promotion" */
  update_promotion?: Maybe<Promotion_Mutation_Response>;
  /** update single row of the table: "promotion" */
  update_promotion_by_pk?: Maybe<Promotion>;
  /** update data of the table: "quote" */
  update_quote?: Maybe<Quote_Mutation_Response>;
  /** update single row of the table: "quote" */
  update_quote_by_pk?: Maybe<Quote>;
  /** update data of the table: "quote_item" */
  update_quote_item?: Maybe<Quote_Item_Mutation_Response>;
  /** update single row of the table: "quote_item" */
  update_quote_item_by_pk?: Maybe<Quote_Item>;
  /** update data of the table: "quote_request" */
  update_quote_request?: Maybe<Quote_Request_Mutation_Response>;
  /** update single row of the table: "quote_request" */
  update_quote_request_by_pk?: Maybe<Quote_Request>;
  /** update data of the table: "refund" */
  update_refund?: Maybe<Refund_Mutation_Response>;
  /** update data of the table: "refund_and_transaction" */
  update_refund_and_transaction?: Maybe<Refund_And_Transaction_Mutation_Response>;
  /** update single row of the table: "refund_and_transaction" */
  update_refund_and_transaction_by_pk?: Maybe<Refund_And_Transaction>;
  /** update data of the table: "refund_and_transfer" */
  update_refund_and_transfer?: Maybe<Refund_And_Transfer_Mutation_Response>;
  /** update single row of the table: "refund_and_transfer" */
  update_refund_and_transfer_by_pk?: Maybe<Refund_And_Transfer>;
  /** update single row of the table: "refund" */
  update_refund_by_pk?: Maybe<Refund>;
  /** update data of the table: "refund_status" */
  update_refund_status?: Maybe<Refund_Status_Mutation_Response>;
  /** update single row of the table: "refund_status" */
  update_refund_status_by_pk?: Maybe<Refund_Status>;
  /** update data of the table: "review" */
  update_review?: Maybe<Review_Mutation_Response>;
  /** update single row of the table: "review" */
  update_review_by_pk?: Maybe<Review>;
  /** update data of the table: "review_status" */
  update_review_status?: Maybe<Review_Status_Mutation_Response>;
  /** update single row of the table: "review_status" */
  update_review_status_by_pk?: Maybe<Review_Status>;
  /** update data of the table: "session" */
  update_session?: Maybe<Session_Mutation_Response>;
  /** update single row of the table: "session" */
  update_session_by_pk?: Maybe<Session>;
  /** update data of the table: "slot" */
  update_slot?: Maybe<Slot_Mutation_Response>;
  /** update single row of the table: "slot" */
  update_slot_by_pk?: Maybe<Slot>;
  /** update data of the table: "sole" */
  update_sole?: Maybe<Sole_Mutation_Response>;
  /** update single row of the table: "sole" */
  update_sole_by_pk?: Maybe<Sole>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update data of the table: "store" */
  update_store?: Maybe<Store_Mutation_Response>;
  /** update data of the table: "store_account_role" */
  update_store_account_role?: Maybe<Store_Account_Role_Mutation_Response>;
  /** update single row of the table: "store_account_role" */
  update_store_account_role_by_pk?: Maybe<Store_Account_Role>;
  /** update data of the table: "store_and_account" */
  update_store_and_account?: Maybe<Store_And_Account_Mutation_Response>;
  /** update single row of the table: "store_and_account" */
  update_store_and_account_by_pk?: Maybe<Store_And_Account>;
  /** update data of the table: "store_and_hashtag" */
  update_store_and_hashtag?: Maybe<Store_And_Hashtag_Mutation_Response>;
  /** update single row of the table: "store_and_hashtag" */
  update_store_and_hashtag_by_pk?: Maybe<Store_And_Hashtag>;
  /** update data of the table: "store_and_like" */
  update_store_and_like?: Maybe<Store_And_Like_Mutation_Response>;
  /** update single row of the table: "store_and_like" */
  update_store_and_like_by_pk?: Maybe<Store_And_Like>;
  /** update data of the table: "store_and_package" */
  update_store_and_package?: Maybe<Store_And_Package_Mutation_Response>;
  /** update single row of the table: "store_and_package" */
  update_store_and_package_by_pk?: Maybe<Store_And_Package>;
  /** update data of the table: "store_and_product" */
  update_store_and_product?: Maybe<Store_And_Product_Mutation_Response>;
  /** update single row of the table: "store_and_product" */
  update_store_and_product_by_pk?: Maybe<Store_And_Product>;
  /** update data of the table: "store_and_product_item" */
  update_store_and_product_item?: Maybe<Store_And_Product_Item_Mutation_Response>;
  /** update single row of the table: "store_and_product_item" */
  update_store_and_product_item_by_pk?: Maybe<Store_And_Product_Item>;
  /** update data of the table: "store_and_review" */
  update_store_and_review?: Maybe<Store_And_Review_Mutation_Response>;
  /** update single row of the table: "store_and_review" */
  update_store_and_review_by_pk?: Maybe<Store_And_Review>;
  /** update data of the table: "store_and_sole" */
  update_store_and_sole?: Maybe<Store_And_Sole_Mutation_Response>;
  /** update single row of the table: "store_and_sole" */
  update_store_and_sole_by_pk?: Maybe<Store_And_Sole>;
  /** update single row of the table: "store" */
  update_store_by_pk?: Maybe<Store>;
  /** update data of the table: "store_status" */
  update_store_status?: Maybe<Store_Status_Mutation_Response>;
  /** update single row of the table: "store_status" */
  update_store_status_by_pk?: Maybe<Store_Status>;
  /** update data of the table: "subscription" */
  update_subscription?: Maybe<Subscription_Mutation_Response>;
  /** update data of the table: "subscription_and_transaction" */
  update_subscription_and_transaction?: Maybe<Subscription_And_Transaction_Mutation_Response>;
  /** update single row of the table: "subscription_and_transaction" */
  update_subscription_and_transaction_by_pk?: Maybe<Subscription_And_Transaction>;
  /** update data of the table: "subscription_and_transfer" */
  update_subscription_and_transfer?: Maybe<Subscription_And_Transfer_Mutation_Response>;
  /** update single row of the table: "subscription_and_transfer" */
  update_subscription_and_transfer_by_pk?: Maybe<Subscription_And_Transfer>;
  /** update single row of the table: "subscription" */
  update_subscription_by_pk?: Maybe<Subscription>;
  /** update data of the table: "subscription_status" */
  update_subscription_status?: Maybe<Subscription_Status_Mutation_Response>;
  /** update single row of the table: "subscription_status" */
  update_subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** update data of the table: "transaction" */
  update_transaction?: Maybe<Transaction_Mutation_Response>;
  /** update single row of the table: "transaction" */
  update_transaction_by_pk?: Maybe<Transaction>;
  /** update data of the table: "transaction_type" */
  update_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** update single row of the table: "transaction_type" */
  update_transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** update data of the table: "transfer" */
  update_transfer?: Maybe<Transfer_Mutation_Response>;
  /** update single row of the table: "transfer" */
  update_transfer_by_pk?: Maybe<Transfer>;
  /** update data of the table: "transfer_type" */
  update_transfer_type?: Maybe<Transfer_Type_Mutation_Response>;
  /** update single row of the table: "transfer_type" */
  update_transfer_type_by_pk?: Maybe<Transfer_Type>;
  /** update data of the table: "user_actions_history" */
  update_user_actions_history?: Maybe<User_Actions_History_Mutation_Response>;
  /** update single row of the table: "user_actions_history" */
  update_user_actions_history_by_pk?: Maybe<User_Actions_History>;
  /** update data of the table: "view" */
  update_view?: Maybe<View_Mutation_Response>;
  /** update single row of the table: "view" */
  update_view_by_pk?: Maybe<View>;
  /** update data of the table: "view_package_store" */
  update_view_package_store?: Maybe<View_Package_Store_Mutation_Response>;
  /** update single row of the table: "view_package_store" */
  update_view_package_store_by_pk?: Maybe<View_Package_Store>;
  /** update data of the table: "view_store" */
  update_view_store?: Maybe<View_Store_Mutation_Response>;
  /** update single row of the table: "view_store" */
  update_view_store_by_pk?: Maybe<View_Store>;
  /** update data of the table: "view_store_gallery" */
  update_view_store_gallery?: Maybe<View_Store_Gallery_Mutation_Response>;
  /** update single row of the table: "view_store_gallery" */
  update_view_store_gallery_by_pk?: Maybe<View_Store_Gallery>;
};


/** mutation root */
export type Mutation_RootDelete_AccountArgs = {
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Account_TypeArgs = {
  where: Account_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_PayableArgs = {
  where: Accounts_Payable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Payable_And_AdjustmentArgs = {
  where: Accounts_Payable_And_Adjustment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Payable_And_Adjustment_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  adjustment: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Payable_And_PayoutArgs = {
  where: Accounts_Payable_And_Payout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Payable_And_Payout_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  payout: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Payable_And_RefundArgs = {
  where: Accounts_Payable_And_Refund_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Payable_And_Refund_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  refund: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Payable_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_ReceivableArgs = {
  where: Accounts_Receivable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Receivable_And_InvoiceArgs = {
  where: Accounts_Receivable_And_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Receivable_And_Invoice_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  invoice: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Receivable_And_MembershipArgs = {
  where: Accounts_Receivable_And_Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Receivable_And_Membership_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  membership: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Receivable_And_SubscriptionArgs = {
  where: Accounts_Receivable_And_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Receivable_And_Subscription_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  subscription: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Accounts_Receivable_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ActivityArgs = {
  where: Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Address_SidoArgs = {
  where: Address_Sido_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_Sido_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Address_SigunguArgs = {
  where: Address_Sigungu_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_Sigungu_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AdjustmentArgs = {
  where: Adjustment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Adjustment_And_TransferArgs = {
  where: Adjustment_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Adjustment_And_Transfer_By_PkArgs = {
  adjustment: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Adjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Adjustment_StatusArgs = {
  where: Adjustment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Adjustment_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Blog_PostArgs = {
  where: Blog_Post_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_Post_And_HashtagArgs = {
  where: Blog_Post_And_Hashtag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_Post_And_Hashtag_By_PkArgs = {
  blog_post: Scalars['uuid'];
  hashtag: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Blog_Post_And_ViewArgs = {
  where: Blog_Post_And_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_Post_And_View_By_PkArgs = {
  blog_post: Scalars['uuid'];
  view: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Blog_Post_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CarArgs = {
  where: Car_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Car_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Car_InspectionArgs = {
  where: Car_Inspection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Car_Inspection_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Car_Inspection_StatusArgs = {
  where: Car_Inspection_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Car_Inspection_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Car_MakerArgs = {
  where: Car_Maker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Car_Maker_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Car_OriginArgs = {
  where: Car_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Car_Origin_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Car_Sunroof_TypeArgs = {
  where: Car_Sunroof_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Car_Sunroof_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Car_TypeArgs = {
  where: Car_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Car_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Cart_ItemArgs = {
  where: Cart_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cart_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ChatArgs = {
  where: Chat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ConfigArgs = {
  where: Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Config_TypeArgs = {
  where: Config_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Config_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CouponArgs = {
  where: Coupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coupon_And_AccountArgs = {
  where: Coupon_And_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coupon_And_Account_By_PkArgs = {
  account: Scalars['uuid'];
  coupon: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Coupon_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Coupon_StatusArgs = {
  where: Coupon_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coupon_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CurationArgs = {
  where: Curation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Curation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fake_ReviewArgs = {
  where: Fake_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fake_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GalleryArgs = {
  where: Gallery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gallery_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Geometry_ColumnsArgs = {
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HashtagArgs = {
  where: Hashtag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hashtag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InventoryArgs = {
  where: Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Inventory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceArgs = {
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_And_TransactionArgs = {
  where: Invoice_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_And_Transaction_By_PkArgs = {
  invoice: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_And_TransferArgs = {
  where: Invoice_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_And_Transfer_By_PkArgs = {
  invoice: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_StatusArgs = {
  where: Invoice_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Kakao_And_AccountArgs = {
  where: Kakao_And_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kakao_And_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadArgs = {
  where: Lead_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lead_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lead_CommentArgs = {
  where: Lead_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lead_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lead_StatusArgs = {
  where: Lead_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lead_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_LikeArgs = {
  where: Like_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Like_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MembershipArgs = {
  where: Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Membership_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Membership_LogArgs = {
  where: Membership_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Membership_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Membership_TypeArgs = {
  where: Membership_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Membership_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_N_CartArgs = {
  where: N_Cart_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_N_Cart_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_N_OrderArgs = {
  where: N_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_N_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_And_ReviewArgs = {
  where: Order_And_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_And_Review_By_PkArgs = {
  order: Scalars['uuid'];
  review: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_And_TransactionArgs = {
  where: Order_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_And_Transaction_By_PkArgs = {
  order: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_And_TransferArgs = {
  where: Order_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_And_Transfer_By_PkArgs = {
  order: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_From_StoreArgs = {
  where: Order_From_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_From_Store_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_From_Store_StatusArgs = {
  where: Order_From_Store_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_From_Store_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Order_ItemArgs = {
  where: Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_StatusArgs = {
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PackageArgs = {
  where: Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Package_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Package_TypeArgs = {
  where: Package_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Package_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PayoutArgs = {
  where: Payout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payout_And_TransferArgs = {
  where: Payout_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payout_And_Transfer_By_PkArgs = {
  payout: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payout_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payout_StatusArgs = {
  where: Payout_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payout_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_And_LikeArgs = {
  where: Product_And_Like_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_And_Like_By_PkArgs = {
  like: Scalars['uuid'];
  product: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Product_BrandArgs = {
  where: Product_Brand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Brand_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Product_ItemArgs = {
  where: Product_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Item_And_ReviewArgs = {
  where: Product_Item_And_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Item_And_Review_By_PkArgs = {
  product_item: Scalars['uuid'];
  review: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Product_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Product_Item_Price_By_StoreArgs = {
  where: Product_Item_Price_By_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Item_Price_By_Store_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Product_TypeArgs = {
  where: Product_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Kr_CustomerArgs = {
  where: Profile_Kr_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Kr_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Kr_PartnerArgs = {
  where: Profile_Kr_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Kr_Partner_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_LanguageArgs = {
  where: Profile_Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Language_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_RiderdashArgs = {
  where: Profile_Riderdash_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Riderdash_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Riderdash_RoleArgs = {
  where: Profile_Riderdash_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Riderdash_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Us_CustomerArgs = {
  where: Profile_Us_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Us_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Us_PartnerArgs = {
  where: Profile_Us_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Us_Partner_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PromotionArgs = {
  where: Promotion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promotion_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_QuoteArgs = {
  where: Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quote_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Quote_ItemArgs = {
  where: Quote_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quote_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Quote_RequestArgs = {
  where: Quote_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quote_Request_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RefundArgs = {
  where: Refund_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Refund_And_TransactionArgs = {
  where: Refund_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Refund_And_Transaction_By_PkArgs = {
  refund: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Refund_And_TransferArgs = {
  where: Refund_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Refund_And_Transfer_By_PkArgs = {
  refund: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Refund_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Refund_StatusArgs = {
  where: Refund_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Refund_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewArgs = {
  where: Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Review_StatusArgs = {
  where: Review_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Review_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SessionArgs = {
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SlotArgs = {
  where: Slot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Slot_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SoleArgs = {
  where: Sole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sole_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_SysArgs = {
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StoreArgs = {
  where: Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Account_RoleArgs = {
  where: Store_Account_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Account_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_AccountArgs = {
  where: Store_And_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Account_By_PkArgs = {
  account: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_HashtagArgs = {
  where: Store_And_Hashtag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Hashtag_By_PkArgs = {
  hashtag: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_LikeArgs = {
  where: Store_And_Like_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Like_By_PkArgs = {
  like: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_PackageArgs = {
  where: Store_And_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Package_By_PkArgs = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_ProductArgs = {
  where: Store_And_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Product_By_PkArgs = {
  product: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Product_ItemArgs = {
  where: Store_And_Product_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Product_Item_By_PkArgs = {
  product_item: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_ReviewArgs = {
  where: Store_And_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Review_By_PkArgs = {
  review: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_And_SoleArgs = {
  where: Store_And_Sole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_And_Sole_By_PkArgs = {
  sole: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_StatusArgs = {
  where: Store_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionArgs = {
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_And_TransactionArgs = {
  where: Subscription_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_And_Transaction_By_PkArgs = {
  subscription: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_And_TransferArgs = {
  where: Subscription_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_And_Transfer_By_PkArgs = {
  subscription: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_StatusArgs = {
  where: Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionArgs = {
  where: Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Transaction_TypeArgs = {
  where: Transaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TransferArgs = {
  where: Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transfer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Transfer_TypeArgs = {
  where: Transfer_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transfer_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Actions_HistoryArgs = {
  where: User_Actions_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Actions_History_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ViewArgs = {
  where: View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_View_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_View_Package_StoreArgs = {
  where: View_Package_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_View_Package_Store_By_PkArgs = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_View_StoreArgs = {
  where: View_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_View_Store_By_PkArgs = {
  store: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_View_Store_GalleryArgs = {
  where: View_Store_Gallery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_View_Store_Gallery_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_AccountArgs = {
  objects: Array<Account_Insert_Input>;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_OneArgs = {
  object: Account_Insert_Input;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_TypeArgs = {
  objects: Array<Account_Type_Insert_Input>;
  on_conflict?: Maybe<Account_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Type_OneArgs = {
  object: Account_Type_Insert_Input;
  on_conflict?: Maybe<Account_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_PayableArgs = {
  objects: Array<Accounts_Payable_Insert_Input>;
  on_conflict?: Maybe<Accounts_Payable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Payable_And_AdjustmentArgs = {
  objects: Array<Accounts_Payable_And_Adjustment_Insert_Input>;
  on_conflict?: Maybe<Accounts_Payable_And_Adjustment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Payable_And_Adjustment_OneArgs = {
  object: Accounts_Payable_And_Adjustment_Insert_Input;
  on_conflict?: Maybe<Accounts_Payable_And_Adjustment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Payable_And_PayoutArgs = {
  objects: Array<Accounts_Payable_And_Payout_Insert_Input>;
  on_conflict?: Maybe<Accounts_Payable_And_Payout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Payable_And_Payout_OneArgs = {
  object: Accounts_Payable_And_Payout_Insert_Input;
  on_conflict?: Maybe<Accounts_Payable_And_Payout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Payable_And_RefundArgs = {
  objects: Array<Accounts_Payable_And_Refund_Insert_Input>;
  on_conflict?: Maybe<Accounts_Payable_And_Refund_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Payable_And_Refund_OneArgs = {
  object: Accounts_Payable_And_Refund_Insert_Input;
  on_conflict?: Maybe<Accounts_Payable_And_Refund_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Payable_OneArgs = {
  object: Accounts_Payable_Insert_Input;
  on_conflict?: Maybe<Accounts_Payable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_ReceivableArgs = {
  objects: Array<Accounts_Receivable_Insert_Input>;
  on_conflict?: Maybe<Accounts_Receivable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Receivable_And_InvoiceArgs = {
  objects: Array<Accounts_Receivable_And_Invoice_Insert_Input>;
  on_conflict?: Maybe<Accounts_Receivable_And_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Receivable_And_Invoice_OneArgs = {
  object: Accounts_Receivable_And_Invoice_Insert_Input;
  on_conflict?: Maybe<Accounts_Receivable_And_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Receivable_And_MembershipArgs = {
  objects: Array<Accounts_Receivable_And_Membership_Insert_Input>;
  on_conflict?: Maybe<Accounts_Receivable_And_Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Receivable_And_Membership_OneArgs = {
  object: Accounts_Receivable_And_Membership_Insert_Input;
  on_conflict?: Maybe<Accounts_Receivable_And_Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Receivable_And_SubscriptionArgs = {
  objects: Array<Accounts_Receivable_And_Subscription_Insert_Input>;
  on_conflict?: Maybe<Accounts_Receivable_And_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Receivable_And_Subscription_OneArgs = {
  object: Accounts_Receivable_And_Subscription_Insert_Input;
  on_conflict?: Maybe<Accounts_Receivable_And_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_Receivable_OneArgs = {
  object: Accounts_Receivable_Insert_Input;
  on_conflict?: Maybe<Accounts_Receivable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityArgs = {
  objects: Array<Activity_Insert_Input>;
  on_conflict?: Maybe<Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activity_OneArgs = {
  object: Activity_Insert_Input;
  on_conflict?: Maybe<Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_SidoArgs = {
  objects: Array<Address_Sido_Insert_Input>;
  on_conflict?: Maybe<Address_Sido_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_Sido_OneArgs = {
  object: Address_Sido_Insert_Input;
  on_conflict?: Maybe<Address_Sido_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_SigunguArgs = {
  objects: Array<Address_Sigungu_Insert_Input>;
  on_conflict?: Maybe<Address_Sigungu_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_Sigungu_OneArgs = {
  object: Address_Sigungu_Insert_Input;
  on_conflict?: Maybe<Address_Sigungu_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AdjustmentArgs = {
  objects: Array<Adjustment_Insert_Input>;
  on_conflict?: Maybe<Adjustment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Adjustment_And_TransferArgs = {
  objects: Array<Adjustment_And_Transfer_Insert_Input>;
  on_conflict?: Maybe<Adjustment_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Adjustment_And_Transfer_OneArgs = {
  object: Adjustment_And_Transfer_Insert_Input;
  on_conflict?: Maybe<Adjustment_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Adjustment_OneArgs = {
  object: Adjustment_Insert_Input;
  on_conflict?: Maybe<Adjustment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Adjustment_StatusArgs = {
  objects: Array<Adjustment_Status_Insert_Input>;
  on_conflict?: Maybe<Adjustment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Adjustment_Status_OneArgs = {
  object: Adjustment_Status_Insert_Input;
  on_conflict?: Maybe<Adjustment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_PostArgs = {
  objects: Array<Blog_Post_Insert_Input>;
  on_conflict?: Maybe<Blog_Post_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Post_And_HashtagArgs = {
  objects: Array<Blog_Post_And_Hashtag_Insert_Input>;
  on_conflict?: Maybe<Blog_Post_And_Hashtag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Post_And_Hashtag_OneArgs = {
  object: Blog_Post_And_Hashtag_Insert_Input;
  on_conflict?: Maybe<Blog_Post_And_Hashtag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Post_And_ViewArgs = {
  objects: Array<Blog_Post_And_View_Insert_Input>;
  on_conflict?: Maybe<Blog_Post_And_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Post_And_View_OneArgs = {
  object: Blog_Post_And_View_Insert_Input;
  on_conflict?: Maybe<Blog_Post_And_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Post_OneArgs = {
  object: Blog_Post_Insert_Input;
  on_conflict?: Maybe<Blog_Post_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CarArgs = {
  objects: Array<Car_Insert_Input>;
  on_conflict?: Maybe<Car_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_InspectionArgs = {
  objects: Array<Car_Inspection_Insert_Input>;
  on_conflict?: Maybe<Car_Inspection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Inspection_OneArgs = {
  object: Car_Inspection_Insert_Input;
  on_conflict?: Maybe<Car_Inspection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Inspection_StatusArgs = {
  objects: Array<Car_Inspection_Status_Insert_Input>;
  on_conflict?: Maybe<Car_Inspection_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Inspection_Status_OneArgs = {
  object: Car_Inspection_Status_Insert_Input;
  on_conflict?: Maybe<Car_Inspection_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_MakerArgs = {
  objects: Array<Car_Maker_Insert_Input>;
  on_conflict?: Maybe<Car_Maker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Maker_OneArgs = {
  object: Car_Maker_Insert_Input;
  on_conflict?: Maybe<Car_Maker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_OneArgs = {
  object: Car_Insert_Input;
  on_conflict?: Maybe<Car_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_OriginArgs = {
  objects: Array<Car_Origin_Insert_Input>;
  on_conflict?: Maybe<Car_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Origin_OneArgs = {
  object: Car_Origin_Insert_Input;
  on_conflict?: Maybe<Car_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Sunroof_TypeArgs = {
  objects: Array<Car_Sunroof_Type_Insert_Input>;
  on_conflict?: Maybe<Car_Sunroof_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Sunroof_Type_OneArgs = {
  object: Car_Sunroof_Type_Insert_Input;
  on_conflict?: Maybe<Car_Sunroof_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_TypeArgs = {
  objects: Array<Car_Type_Insert_Input>;
  on_conflict?: Maybe<Car_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Car_Type_OneArgs = {
  object: Car_Type_Insert_Input;
  on_conflict?: Maybe<Car_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cart_ItemArgs = {
  objects: Array<Cart_Item_Insert_Input>;
  on_conflict?: Maybe<Cart_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cart_Item_OneArgs = {
  object: Cart_Item_Insert_Input;
  on_conflict?: Maybe<Cart_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChatArgs = {
  objects: Array<Chat_Insert_Input>;
  on_conflict?: Maybe<Chat_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_OneArgs = {
  object: Chat_Insert_Input;
  on_conflict?: Maybe<Chat_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConfigArgs = {
  objects: Array<Config_Insert_Input>;
  on_conflict?: Maybe<Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Config_OneArgs = {
  object: Config_Insert_Input;
  on_conflict?: Maybe<Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Config_TypeArgs = {
  objects: Array<Config_Type_Insert_Input>;
  on_conflict?: Maybe<Config_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Config_Type_OneArgs = {
  object: Config_Type_Insert_Input;
  on_conflict?: Maybe<Config_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponArgs = {
  objects: Array<Coupon_Insert_Input>;
  on_conflict?: Maybe<Coupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_And_AccountArgs = {
  objects: Array<Coupon_And_Account_Insert_Input>;
  on_conflict?: Maybe<Coupon_And_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_And_Account_OneArgs = {
  object: Coupon_And_Account_Insert_Input;
  on_conflict?: Maybe<Coupon_And_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_OneArgs = {
  object: Coupon_Insert_Input;
  on_conflict?: Maybe<Coupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_StatusArgs = {
  objects: Array<Coupon_Status_Insert_Input>;
  on_conflict?: Maybe<Coupon_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_Status_OneArgs = {
  object: Coupon_Status_Insert_Input;
  on_conflict?: Maybe<Coupon_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CurationArgs = {
  objects: Array<Curation_Insert_Input>;
  on_conflict?: Maybe<Curation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Curation_OneArgs = {
  object: Curation_Insert_Input;
  on_conflict?: Maybe<Curation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fake_ReviewArgs = {
  objects: Array<Fake_Review_Insert_Input>;
  on_conflict?: Maybe<Fake_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fake_Review_OneArgs = {
  object: Fake_Review_Insert_Input;
  on_conflict?: Maybe<Fake_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GalleryArgs = {
  objects: Array<Gallery_Insert_Input>;
  on_conflict?: Maybe<Gallery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gallery_OneArgs = {
  object: Gallery_Insert_Input;
  on_conflict?: Maybe<Gallery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_ColumnsArgs = {
  objects: Array<Geometry_Columns_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_Columns_OneArgs = {
  object: Geometry_Columns_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_HashtagArgs = {
  objects: Array<Hashtag_Insert_Input>;
  on_conflict?: Maybe<Hashtag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hashtag_OneArgs = {
  object: Hashtag_Insert_Input;
  on_conflict?: Maybe<Hashtag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InventoryArgs = {
  objects: Array<Inventory_Insert_Input>;
  on_conflict?: Maybe<Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inventory_OneArgs = {
  object: Inventory_Insert_Input;
  on_conflict?: Maybe<Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceArgs = {
  objects: Array<Invoice_Insert_Input>;
  on_conflict?: Maybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_And_TransactionArgs = {
  objects: Array<Invoice_And_Transaction_Insert_Input>;
  on_conflict?: Maybe<Invoice_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_And_Transaction_OneArgs = {
  object: Invoice_And_Transaction_Insert_Input;
  on_conflict?: Maybe<Invoice_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_And_TransferArgs = {
  objects: Array<Invoice_And_Transfer_Insert_Input>;
  on_conflict?: Maybe<Invoice_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_And_Transfer_OneArgs = {
  object: Invoice_And_Transfer_Insert_Input;
  on_conflict?: Maybe<Invoice_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_OneArgs = {
  object: Invoice_Insert_Input;
  on_conflict?: Maybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_StatusArgs = {
  objects: Array<Invoice_Status_Insert_Input>;
  on_conflict?: Maybe<Invoice_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_Status_OneArgs = {
  object: Invoice_Status_Insert_Input;
  on_conflict?: Maybe<Invoice_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kakao_And_AccountArgs = {
  objects: Array<Kakao_And_Account_Insert_Input>;
  on_conflict?: Maybe<Kakao_And_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kakao_And_Account_OneArgs = {
  object: Kakao_And_Account_Insert_Input;
  on_conflict?: Maybe<Kakao_And_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadArgs = {
  objects: Array<Lead_Insert_Input>;
  on_conflict?: Maybe<Lead_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lead_CommentArgs = {
  objects: Array<Lead_Comment_Insert_Input>;
  on_conflict?: Maybe<Lead_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lead_Comment_OneArgs = {
  object: Lead_Comment_Insert_Input;
  on_conflict?: Maybe<Lead_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lead_OneArgs = {
  object: Lead_Insert_Input;
  on_conflict?: Maybe<Lead_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lead_StatusArgs = {
  objects: Array<Lead_Status_Insert_Input>;
  on_conflict?: Maybe<Lead_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lead_Status_OneArgs = {
  object: Lead_Status_Insert_Input;
  on_conflict?: Maybe<Lead_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LikeArgs = {
  objects: Array<Like_Insert_Input>;
  on_conflict?: Maybe<Like_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Like_OneArgs = {
  object: Like_Insert_Input;
  on_conflict?: Maybe<Like_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MembershipArgs = {
  objects: Array<Membership_Insert_Input>;
  on_conflict?: Maybe<Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_LogArgs = {
  objects: Array<Membership_Log_Insert_Input>;
  on_conflict?: Maybe<Membership_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_Log_OneArgs = {
  object: Membership_Log_Insert_Input;
  on_conflict?: Maybe<Membership_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_OneArgs = {
  object: Membership_Insert_Input;
  on_conflict?: Maybe<Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_TypeArgs = {
  objects: Array<Membership_Type_Insert_Input>;
  on_conflict?: Maybe<Membership_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_Type_OneArgs = {
  object: Membership_Type_Insert_Input;
  on_conflict?: Maybe<Membership_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_N_CartArgs = {
  objects: Array<N_Cart_Insert_Input>;
  on_conflict?: Maybe<N_Cart_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_N_Cart_OneArgs = {
  object: N_Cart_Insert_Input;
  on_conflict?: Maybe<N_Cart_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_N_OrderArgs = {
  objects: Array<N_Order_Insert_Input>;
  on_conflict?: Maybe<N_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_N_Order_OneArgs = {
  object: N_Order_Insert_Input;
  on_conflict?: Maybe<N_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: Array<Order_Insert_Input>;
  on_conflict?: Maybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_And_ReviewArgs = {
  objects: Array<Order_And_Review_Insert_Input>;
  on_conflict?: Maybe<Order_And_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_And_Review_OneArgs = {
  object: Order_And_Review_Insert_Input;
  on_conflict?: Maybe<Order_And_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_And_TransactionArgs = {
  objects: Array<Order_And_Transaction_Insert_Input>;
  on_conflict?: Maybe<Order_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_And_Transaction_OneArgs = {
  object: Order_And_Transaction_Insert_Input;
  on_conflict?: Maybe<Order_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_And_TransferArgs = {
  objects: Array<Order_And_Transfer_Insert_Input>;
  on_conflict?: Maybe<Order_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_And_Transfer_OneArgs = {
  object: Order_And_Transfer_Insert_Input;
  on_conflict?: Maybe<Order_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_From_StoreArgs = {
  objects: Array<Order_From_Store_Insert_Input>;
  on_conflict?: Maybe<Order_From_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_From_Store_OneArgs = {
  object: Order_From_Store_Insert_Input;
  on_conflict?: Maybe<Order_From_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_From_Store_StatusArgs = {
  objects: Array<Order_From_Store_Status_Insert_Input>;
  on_conflict?: Maybe<Order_From_Store_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_From_Store_Status_OneArgs = {
  object: Order_From_Store_Status_Insert_Input;
  on_conflict?: Maybe<Order_From_Store_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_ItemArgs = {
  objects: Array<Order_Item_Insert_Input>;
  on_conflict?: Maybe<Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Item_OneArgs = {
  object: Order_Item_Insert_Input;
  on_conflict?: Maybe<Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
  object: Order_Insert_Input;
  on_conflict?: Maybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StatusArgs = {
  objects: Array<Order_Status_Insert_Input>;
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Status_OneArgs = {
  object: Order_Status_Insert_Input;
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PackageArgs = {
  objects: Array<Package_Insert_Input>;
  on_conflict?: Maybe<Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Package_OneArgs = {
  object: Package_Insert_Input;
  on_conflict?: Maybe<Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Package_TypeArgs = {
  objects: Array<Package_Type_Insert_Input>;
  on_conflict?: Maybe<Package_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Package_Type_OneArgs = {
  object: Package_Type_Insert_Input;
  on_conflict?: Maybe<Package_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayoutArgs = {
  objects: Array<Payout_Insert_Input>;
  on_conflict?: Maybe<Payout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payout_And_TransferArgs = {
  objects: Array<Payout_And_Transfer_Insert_Input>;
  on_conflict?: Maybe<Payout_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payout_And_Transfer_OneArgs = {
  object: Payout_And_Transfer_Insert_Input;
  on_conflict?: Maybe<Payout_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payout_OneArgs = {
  object: Payout_Insert_Input;
  on_conflict?: Maybe<Payout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payout_StatusArgs = {
  objects: Array<Payout_Status_Insert_Input>;
  on_conflict?: Maybe<Payout_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payout_Status_OneArgs = {
  object: Payout_Status_Insert_Input;
  on_conflict?: Maybe<Payout_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_And_LikeArgs = {
  objects: Array<Product_And_Like_Insert_Input>;
  on_conflict?: Maybe<Product_And_Like_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_And_Like_OneArgs = {
  object: Product_And_Like_Insert_Input;
  on_conflict?: Maybe<Product_And_Like_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_BrandArgs = {
  objects: Array<Product_Brand_Insert_Input>;
  on_conflict?: Maybe<Product_Brand_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Brand_OneArgs = {
  object: Product_Brand_Insert_Input;
  on_conflict?: Maybe<Product_Brand_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_ItemArgs = {
  objects: Array<Product_Item_Insert_Input>;
  on_conflict?: Maybe<Product_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Item_And_ReviewArgs = {
  objects: Array<Product_Item_And_Review_Insert_Input>;
  on_conflict?: Maybe<Product_Item_And_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Item_And_Review_OneArgs = {
  object: Product_Item_And_Review_Insert_Input;
  on_conflict?: Maybe<Product_Item_And_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Item_OneArgs = {
  object: Product_Item_Insert_Input;
  on_conflict?: Maybe<Product_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Item_Price_By_StoreArgs = {
  objects: Array<Product_Item_Price_By_Store_Insert_Input>;
  on_conflict?: Maybe<Product_Item_Price_By_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Item_Price_By_Store_OneArgs = {
  object: Product_Item_Price_By_Store_Insert_Input;
  on_conflict?: Maybe<Product_Item_Price_By_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_TypeArgs = {
  objects: Array<Product_Type_Insert_Input>;
  on_conflict?: Maybe<Product_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Type_OneArgs = {
  object: Product_Type_Insert_Input;
  on_conflict?: Maybe<Product_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Kr_CustomerArgs = {
  objects: Array<Profile_Kr_Customer_Insert_Input>;
  on_conflict?: Maybe<Profile_Kr_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Kr_Customer_OneArgs = {
  object: Profile_Kr_Customer_Insert_Input;
  on_conflict?: Maybe<Profile_Kr_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Kr_PartnerArgs = {
  objects: Array<Profile_Kr_Partner_Insert_Input>;
  on_conflict?: Maybe<Profile_Kr_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Kr_Partner_OneArgs = {
  object: Profile_Kr_Partner_Insert_Input;
  on_conflict?: Maybe<Profile_Kr_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_LanguageArgs = {
  objects: Array<Profile_Language_Insert_Input>;
  on_conflict?: Maybe<Profile_Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Language_OneArgs = {
  object: Profile_Language_Insert_Input;
  on_conflict?: Maybe<Profile_Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_RiderdashArgs = {
  objects: Array<Profile_Riderdash_Insert_Input>;
  on_conflict?: Maybe<Profile_Riderdash_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Riderdash_OneArgs = {
  object: Profile_Riderdash_Insert_Input;
  on_conflict?: Maybe<Profile_Riderdash_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Riderdash_RoleArgs = {
  objects: Array<Profile_Riderdash_Role_Insert_Input>;
  on_conflict?: Maybe<Profile_Riderdash_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Riderdash_Role_OneArgs = {
  object: Profile_Riderdash_Role_Insert_Input;
  on_conflict?: Maybe<Profile_Riderdash_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Us_CustomerArgs = {
  objects: Array<Profile_Us_Customer_Insert_Input>;
  on_conflict?: Maybe<Profile_Us_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Us_Customer_OneArgs = {
  object: Profile_Us_Customer_Insert_Input;
  on_conflict?: Maybe<Profile_Us_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Us_PartnerArgs = {
  objects: Array<Profile_Us_Partner_Insert_Input>;
  on_conflict?: Maybe<Profile_Us_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Us_Partner_OneArgs = {
  object: Profile_Us_Partner_Insert_Input;
  on_conflict?: Maybe<Profile_Us_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionArgs = {
  objects: Array<Promotion_Insert_Input>;
  on_conflict?: Maybe<Promotion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promotion_OneArgs = {
  object: Promotion_Insert_Input;
  on_conflict?: Maybe<Promotion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuoteArgs = {
  objects: Array<Quote_Insert_Input>;
  on_conflict?: Maybe<Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quote_ItemArgs = {
  objects: Array<Quote_Item_Insert_Input>;
  on_conflict?: Maybe<Quote_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quote_Item_OneArgs = {
  object: Quote_Item_Insert_Input;
  on_conflict?: Maybe<Quote_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quote_OneArgs = {
  object: Quote_Insert_Input;
  on_conflict?: Maybe<Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quote_RequestArgs = {
  objects: Array<Quote_Request_Insert_Input>;
  on_conflict?: Maybe<Quote_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quote_Request_OneArgs = {
  object: Quote_Request_Insert_Input;
  on_conflict?: Maybe<Quote_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RefundArgs = {
  objects: Array<Refund_Insert_Input>;
  on_conflict?: Maybe<Refund_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_And_TransactionArgs = {
  objects: Array<Refund_And_Transaction_Insert_Input>;
  on_conflict?: Maybe<Refund_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_And_Transaction_OneArgs = {
  object: Refund_And_Transaction_Insert_Input;
  on_conflict?: Maybe<Refund_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_And_TransferArgs = {
  objects: Array<Refund_And_Transfer_Insert_Input>;
  on_conflict?: Maybe<Refund_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_And_Transfer_OneArgs = {
  object: Refund_And_Transfer_Insert_Input;
  on_conflict?: Maybe<Refund_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_OneArgs = {
  object: Refund_Insert_Input;
  on_conflict?: Maybe<Refund_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_StatusArgs = {
  objects: Array<Refund_Status_Insert_Input>;
  on_conflict?: Maybe<Refund_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_Status_OneArgs = {
  object: Refund_Status_Insert_Input;
  on_conflict?: Maybe<Refund_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewArgs = {
  objects: Array<Review_Insert_Input>;
  on_conflict?: Maybe<Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_OneArgs = {
  object: Review_Insert_Input;
  on_conflict?: Maybe<Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_StatusArgs = {
  objects: Array<Review_Status_Insert_Input>;
  on_conflict?: Maybe<Review_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_Status_OneArgs = {
  object: Review_Status_Insert_Input;
  on_conflict?: Maybe<Review_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionArgs = {
  objects: Array<Session_Insert_Input>;
  on_conflict?: Maybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_OneArgs = {
  object: Session_Insert_Input;
  on_conflict?: Maybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SlotArgs = {
  objects: Array<Slot_Insert_Input>;
  on_conflict?: Maybe<Slot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Slot_OneArgs = {
  object: Slot_Insert_Input;
  on_conflict?: Maybe<Slot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SoleArgs = {
  objects: Array<Sole_Insert_Input>;
  on_conflict?: Maybe<Sole_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sole_OneArgs = {
  object: Sole_Insert_Input;
  on_conflict?: Maybe<Sole_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_SysArgs = {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: Maybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_Sys_OneArgs = {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: Maybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StoreArgs = {
  objects: Array<Store_Insert_Input>;
  on_conflict?: Maybe<Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Account_RoleArgs = {
  objects: Array<Store_Account_Role_Insert_Input>;
  on_conflict?: Maybe<Store_Account_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Account_Role_OneArgs = {
  object: Store_Account_Role_Insert_Input;
  on_conflict?: Maybe<Store_Account_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_AccountArgs = {
  objects: Array<Store_And_Account_Insert_Input>;
  on_conflict?: Maybe<Store_And_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Account_OneArgs = {
  object: Store_And_Account_Insert_Input;
  on_conflict?: Maybe<Store_And_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_HashtagArgs = {
  objects: Array<Store_And_Hashtag_Insert_Input>;
  on_conflict?: Maybe<Store_And_Hashtag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Hashtag_OneArgs = {
  object: Store_And_Hashtag_Insert_Input;
  on_conflict?: Maybe<Store_And_Hashtag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_LikeArgs = {
  objects: Array<Store_And_Like_Insert_Input>;
  on_conflict?: Maybe<Store_And_Like_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Like_OneArgs = {
  object: Store_And_Like_Insert_Input;
  on_conflict?: Maybe<Store_And_Like_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_PackageArgs = {
  objects: Array<Store_And_Package_Insert_Input>;
  on_conflict?: Maybe<Store_And_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Package_OneArgs = {
  object: Store_And_Package_Insert_Input;
  on_conflict?: Maybe<Store_And_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_ProductArgs = {
  objects: Array<Store_And_Product_Insert_Input>;
  on_conflict?: Maybe<Store_And_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Product_ItemArgs = {
  objects: Array<Store_And_Product_Item_Insert_Input>;
  on_conflict?: Maybe<Store_And_Product_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Product_Item_OneArgs = {
  object: Store_And_Product_Item_Insert_Input;
  on_conflict?: Maybe<Store_And_Product_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Product_OneArgs = {
  object: Store_And_Product_Insert_Input;
  on_conflict?: Maybe<Store_And_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_ReviewArgs = {
  objects: Array<Store_And_Review_Insert_Input>;
  on_conflict?: Maybe<Store_And_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Review_OneArgs = {
  object: Store_And_Review_Insert_Input;
  on_conflict?: Maybe<Store_And_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_SoleArgs = {
  objects: Array<Store_And_Sole_Insert_Input>;
  on_conflict?: Maybe<Store_And_Sole_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_And_Sole_OneArgs = {
  object: Store_And_Sole_Insert_Input;
  on_conflict?: Maybe<Store_And_Sole_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_OneArgs = {
  object: Store_Insert_Input;
  on_conflict?: Maybe<Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_StatusArgs = {
  objects: Array<Store_Status_Insert_Input>;
  on_conflict?: Maybe<Store_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Status_OneArgs = {
  object: Store_Status_Insert_Input;
  on_conflict?: Maybe<Store_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionArgs = {
  objects: Array<Subscription_Insert_Input>;
  on_conflict?: Maybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_And_TransactionArgs = {
  objects: Array<Subscription_And_Transaction_Insert_Input>;
  on_conflict?: Maybe<Subscription_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_And_Transaction_OneArgs = {
  object: Subscription_And_Transaction_Insert_Input;
  on_conflict?: Maybe<Subscription_And_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_And_TransferArgs = {
  objects: Array<Subscription_And_Transfer_Insert_Input>;
  on_conflict?: Maybe<Subscription_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_And_Transfer_OneArgs = {
  object: Subscription_And_Transfer_Insert_Input;
  on_conflict?: Maybe<Subscription_And_Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_OneArgs = {
  object: Subscription_Insert_Input;
  on_conflict?: Maybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_StatusArgs = {
  objects: Array<Subscription_Status_Insert_Input>;
  on_conflict?: Maybe<Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_Status_OneArgs = {
  object: Subscription_Status_Insert_Input;
  on_conflict?: Maybe<Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionArgs = {
  objects: Array<Transaction_Insert_Input>;
  on_conflict?: Maybe<Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transaction_OneArgs = {
  object: Transaction_Insert_Input;
  on_conflict?: Maybe<Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transaction_TypeArgs = {
  objects: Array<Transaction_Type_Insert_Input>;
  on_conflict?: Maybe<Transaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transaction_Type_OneArgs = {
  object: Transaction_Type_Insert_Input;
  on_conflict?: Maybe<Transaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransferArgs = {
  objects: Array<Transfer_Insert_Input>;
  on_conflict?: Maybe<Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transfer_OneArgs = {
  object: Transfer_Insert_Input;
  on_conflict?: Maybe<Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transfer_TypeArgs = {
  objects: Array<Transfer_Type_Insert_Input>;
  on_conflict?: Maybe<Transfer_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transfer_Type_OneArgs = {
  object: Transfer_Type_Insert_Input;
  on_conflict?: Maybe<Transfer_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Actions_HistoryArgs = {
  objects: Array<User_Actions_History_Insert_Input>;
  on_conflict?: Maybe<User_Actions_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Actions_History_OneArgs = {
  object: User_Actions_History_Insert_Input;
  on_conflict?: Maybe<User_Actions_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ViewArgs = {
  objects: Array<View_Insert_Input>;
  on_conflict?: Maybe<View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_OneArgs = {
  object: View_Insert_Input;
  on_conflict?: Maybe<View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_Package_StoreArgs = {
  objects: Array<View_Package_Store_Insert_Input>;
  on_conflict?: Maybe<View_Package_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_Package_Store_OneArgs = {
  object: View_Package_Store_Insert_Input;
  on_conflict?: Maybe<View_Package_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_StoreArgs = {
  objects: Array<View_Store_Insert_Input>;
  on_conflict?: Maybe<View_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_Store_GalleryArgs = {
  objects: Array<View_Store_Gallery_Insert_Input>;
  on_conflict?: Maybe<View_Store_Gallery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_Store_Gallery_OneArgs = {
  object: View_Store_Gallery_Insert_Input;
  on_conflict?: Maybe<View_Store_Gallery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_Store_OneArgs = {
  object: View_Store_Insert_Input;
  on_conflict?: Maybe<View_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AccountArgs = {
  _set?: Maybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_By_PkArgs = {
  _set?: Maybe<Account_Set_Input>;
  pk_columns: Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_TypeArgs = {
  _set?: Maybe<Account_Type_Set_Input>;
  where: Account_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Type_By_PkArgs = {
  _set?: Maybe<Account_Type_Set_Input>;
  pk_columns: Account_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_PayableArgs = {
  _set?: Maybe<Accounts_Payable_Set_Input>;
  where: Accounts_Payable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Payable_And_AdjustmentArgs = {
  _set?: Maybe<Accounts_Payable_And_Adjustment_Set_Input>;
  where: Accounts_Payable_And_Adjustment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Payable_And_Adjustment_By_PkArgs = {
  _set?: Maybe<Accounts_Payable_And_Adjustment_Set_Input>;
  pk_columns: Accounts_Payable_And_Adjustment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Payable_And_PayoutArgs = {
  _set?: Maybe<Accounts_Payable_And_Payout_Set_Input>;
  where: Accounts_Payable_And_Payout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Payable_And_Payout_By_PkArgs = {
  _set?: Maybe<Accounts_Payable_And_Payout_Set_Input>;
  pk_columns: Accounts_Payable_And_Payout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Payable_And_RefundArgs = {
  _set?: Maybe<Accounts_Payable_And_Refund_Set_Input>;
  where: Accounts_Payable_And_Refund_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Payable_And_Refund_By_PkArgs = {
  _set?: Maybe<Accounts_Payable_And_Refund_Set_Input>;
  pk_columns: Accounts_Payable_And_Refund_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Payable_By_PkArgs = {
  _set?: Maybe<Accounts_Payable_Set_Input>;
  pk_columns: Accounts_Payable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_ReceivableArgs = {
  _set?: Maybe<Accounts_Receivable_Set_Input>;
  where: Accounts_Receivable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Receivable_And_InvoiceArgs = {
  _set?: Maybe<Accounts_Receivable_And_Invoice_Set_Input>;
  where: Accounts_Receivable_And_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Receivable_And_Invoice_By_PkArgs = {
  _set?: Maybe<Accounts_Receivable_And_Invoice_Set_Input>;
  pk_columns: Accounts_Receivable_And_Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Receivable_And_MembershipArgs = {
  _inc?: Maybe<Accounts_Receivable_And_Membership_Inc_Input>;
  _set?: Maybe<Accounts_Receivable_And_Membership_Set_Input>;
  where: Accounts_Receivable_And_Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Receivable_And_Membership_By_PkArgs = {
  _inc?: Maybe<Accounts_Receivable_And_Membership_Inc_Input>;
  _set?: Maybe<Accounts_Receivable_And_Membership_Set_Input>;
  pk_columns: Accounts_Receivable_And_Membership_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Receivable_And_SubscriptionArgs = {
  _set?: Maybe<Accounts_Receivable_And_Subscription_Set_Input>;
  where: Accounts_Receivable_And_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Receivable_And_Subscription_By_PkArgs = {
  _set?: Maybe<Accounts_Receivable_And_Subscription_Set_Input>;
  pk_columns: Accounts_Receivable_And_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_Receivable_By_PkArgs = {
  _set?: Maybe<Accounts_Receivable_Set_Input>;
  pk_columns: Accounts_Receivable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityArgs = {
  _append?: Maybe<Activity_Append_Input>;
  _delete_at_path?: Maybe<Activity_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Activity_Delete_Elem_Input>;
  _delete_key?: Maybe<Activity_Delete_Key_Input>;
  _inc?: Maybe<Activity_Inc_Input>;
  _prepend?: Maybe<Activity_Prepend_Input>;
  _set?: Maybe<Activity_Set_Input>;
  where: Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activity_By_PkArgs = {
  _append?: Maybe<Activity_Append_Input>;
  _delete_at_path?: Maybe<Activity_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Activity_Delete_Elem_Input>;
  _delete_key?: Maybe<Activity_Delete_Key_Input>;
  _inc?: Maybe<Activity_Inc_Input>;
  _prepend?: Maybe<Activity_Prepend_Input>;
  _set?: Maybe<Activity_Set_Input>;
  pk_columns: Activity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_SidoArgs = {
  _set?: Maybe<Address_Sido_Set_Input>;
  where: Address_Sido_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_Sido_By_PkArgs = {
  _set?: Maybe<Address_Sido_Set_Input>;
  pk_columns: Address_Sido_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_SigunguArgs = {
  _set?: Maybe<Address_Sigungu_Set_Input>;
  where: Address_Sigungu_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_Sigungu_By_PkArgs = {
  _set?: Maybe<Address_Sigungu_Set_Input>;
  pk_columns: Address_Sigungu_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdjustmentArgs = {
  _inc?: Maybe<Adjustment_Inc_Input>;
  _set?: Maybe<Adjustment_Set_Input>;
  where: Adjustment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Adjustment_And_TransferArgs = {
  _set?: Maybe<Adjustment_And_Transfer_Set_Input>;
  where: Adjustment_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Adjustment_And_Transfer_By_PkArgs = {
  _set?: Maybe<Adjustment_And_Transfer_Set_Input>;
  pk_columns: Adjustment_And_Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Adjustment_By_PkArgs = {
  _inc?: Maybe<Adjustment_Inc_Input>;
  _set?: Maybe<Adjustment_Set_Input>;
  pk_columns: Adjustment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Adjustment_StatusArgs = {
  _set?: Maybe<Adjustment_Status_Set_Input>;
  where: Adjustment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Adjustment_Status_By_PkArgs = {
  _set?: Maybe<Adjustment_Status_Set_Input>;
  pk_columns: Adjustment_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_PostArgs = {
  _append?: Maybe<Blog_Post_Append_Input>;
  _delete_at_path?: Maybe<Blog_Post_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Blog_Post_Delete_Elem_Input>;
  _delete_key?: Maybe<Blog_Post_Delete_Key_Input>;
  _prepend?: Maybe<Blog_Post_Prepend_Input>;
  _set?: Maybe<Blog_Post_Set_Input>;
  where: Blog_Post_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Post_And_HashtagArgs = {
  _set?: Maybe<Blog_Post_And_Hashtag_Set_Input>;
  where: Blog_Post_And_Hashtag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Post_And_Hashtag_By_PkArgs = {
  _set?: Maybe<Blog_Post_And_Hashtag_Set_Input>;
  pk_columns: Blog_Post_And_Hashtag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Post_And_ViewArgs = {
  _set?: Maybe<Blog_Post_And_View_Set_Input>;
  where: Blog_Post_And_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Post_And_View_By_PkArgs = {
  _set?: Maybe<Blog_Post_And_View_Set_Input>;
  pk_columns: Blog_Post_And_View_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Post_By_PkArgs = {
  _append?: Maybe<Blog_Post_Append_Input>;
  _delete_at_path?: Maybe<Blog_Post_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Blog_Post_Delete_Elem_Input>;
  _delete_key?: Maybe<Blog_Post_Delete_Key_Input>;
  _prepend?: Maybe<Blog_Post_Prepend_Input>;
  _set?: Maybe<Blog_Post_Set_Input>;
  pk_columns: Blog_Post_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CarArgs = {
  _inc?: Maybe<Car_Inc_Input>;
  _set?: Maybe<Car_Set_Input>;
  where: Car_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Car_By_PkArgs = {
  _inc?: Maybe<Car_Inc_Input>;
  _set?: Maybe<Car_Set_Input>;
  pk_columns: Car_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Car_InspectionArgs = {
  _append?: Maybe<Car_Inspection_Append_Input>;
  _delete_at_path?: Maybe<Car_Inspection_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Car_Inspection_Delete_Elem_Input>;
  _delete_key?: Maybe<Car_Inspection_Delete_Key_Input>;
  _prepend?: Maybe<Car_Inspection_Prepend_Input>;
  _set?: Maybe<Car_Inspection_Set_Input>;
  where: Car_Inspection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Inspection_By_PkArgs = {
  _append?: Maybe<Car_Inspection_Append_Input>;
  _delete_at_path?: Maybe<Car_Inspection_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Car_Inspection_Delete_Elem_Input>;
  _delete_key?: Maybe<Car_Inspection_Delete_Key_Input>;
  _prepend?: Maybe<Car_Inspection_Prepend_Input>;
  _set?: Maybe<Car_Inspection_Set_Input>;
  pk_columns: Car_Inspection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Inspection_StatusArgs = {
  _set?: Maybe<Car_Inspection_Status_Set_Input>;
  where: Car_Inspection_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Inspection_Status_By_PkArgs = {
  _set?: Maybe<Car_Inspection_Status_Set_Input>;
  pk_columns: Car_Inspection_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Car_MakerArgs = {
  _set?: Maybe<Car_Maker_Set_Input>;
  where: Car_Maker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Maker_By_PkArgs = {
  _set?: Maybe<Car_Maker_Set_Input>;
  pk_columns: Car_Maker_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Car_OriginArgs = {
  _set?: Maybe<Car_Origin_Set_Input>;
  where: Car_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Origin_By_PkArgs = {
  _set?: Maybe<Car_Origin_Set_Input>;
  pk_columns: Car_Origin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Sunroof_TypeArgs = {
  _set?: Maybe<Car_Sunroof_Type_Set_Input>;
  where: Car_Sunroof_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Sunroof_Type_By_PkArgs = {
  _set?: Maybe<Car_Sunroof_Type_Set_Input>;
  pk_columns: Car_Sunroof_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Car_TypeArgs = {
  _set?: Maybe<Car_Type_Set_Input>;
  where: Car_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Car_Type_By_PkArgs = {
  _set?: Maybe<Car_Type_Set_Input>;
  pk_columns: Car_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cart_ItemArgs = {
  _append?: Maybe<Cart_Item_Append_Input>;
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>;
  _inc?: Maybe<Cart_Item_Inc_Input>;
  _prepend?: Maybe<Cart_Item_Prepend_Input>;
  _set?: Maybe<Cart_Item_Set_Input>;
  where: Cart_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cart_Item_By_PkArgs = {
  _append?: Maybe<Cart_Item_Append_Input>;
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>;
  _inc?: Maybe<Cart_Item_Inc_Input>;
  _prepend?: Maybe<Cart_Item_Prepend_Input>;
  _set?: Maybe<Cart_Item_Set_Input>;
  pk_columns: Cart_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ChatArgs = {
  _inc?: Maybe<Chat_Inc_Input>;
  _set?: Maybe<Chat_Set_Input>;
  where: Chat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_By_PkArgs = {
  _inc?: Maybe<Chat_Inc_Input>;
  _set?: Maybe<Chat_Set_Input>;
  pk_columns: Chat_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ConfigArgs = {
  _append?: Maybe<Config_Append_Input>;
  _delete_at_path?: Maybe<Config_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Config_Delete_Elem_Input>;
  _delete_key?: Maybe<Config_Delete_Key_Input>;
  _prepend?: Maybe<Config_Prepend_Input>;
  _set?: Maybe<Config_Set_Input>;
  where: Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Config_By_PkArgs = {
  _append?: Maybe<Config_Append_Input>;
  _delete_at_path?: Maybe<Config_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Config_Delete_Elem_Input>;
  _delete_key?: Maybe<Config_Delete_Key_Input>;
  _prepend?: Maybe<Config_Prepend_Input>;
  _set?: Maybe<Config_Set_Input>;
  pk_columns: Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Config_TypeArgs = {
  _set?: Maybe<Config_Type_Set_Input>;
  where: Config_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Config_Type_By_PkArgs = {
  _set?: Maybe<Config_Type_Set_Input>;
  pk_columns: Config_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CouponArgs = {
  _inc?: Maybe<Coupon_Inc_Input>;
  _set?: Maybe<Coupon_Set_Input>;
  where: Coupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_And_AccountArgs = {
  _set?: Maybe<Coupon_And_Account_Set_Input>;
  where: Coupon_And_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_And_Account_By_PkArgs = {
  _set?: Maybe<Coupon_And_Account_Set_Input>;
  pk_columns: Coupon_And_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_By_PkArgs = {
  _inc?: Maybe<Coupon_Inc_Input>;
  _set?: Maybe<Coupon_Set_Input>;
  pk_columns: Coupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_StatusArgs = {
  _set?: Maybe<Coupon_Status_Set_Input>;
  where: Coupon_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_Status_By_PkArgs = {
  _set?: Maybe<Coupon_Status_Set_Input>;
  pk_columns: Coupon_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CurationArgs = {
  _append?: Maybe<Curation_Append_Input>;
  _delete_at_path?: Maybe<Curation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Curation_Delete_Elem_Input>;
  _delete_key?: Maybe<Curation_Delete_Key_Input>;
  _prepend?: Maybe<Curation_Prepend_Input>;
  _set?: Maybe<Curation_Set_Input>;
  where: Curation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Curation_By_PkArgs = {
  _append?: Maybe<Curation_Append_Input>;
  _delete_at_path?: Maybe<Curation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Curation_Delete_Elem_Input>;
  _delete_key?: Maybe<Curation_Delete_Key_Input>;
  _prepend?: Maybe<Curation_Prepend_Input>;
  _set?: Maybe<Curation_Set_Input>;
  pk_columns: Curation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fake_ReviewArgs = {
  _append?: Maybe<Fake_Review_Append_Input>;
  _delete_at_path?: Maybe<Fake_Review_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Fake_Review_Delete_Elem_Input>;
  _delete_key?: Maybe<Fake_Review_Delete_Key_Input>;
  _inc?: Maybe<Fake_Review_Inc_Input>;
  _prepend?: Maybe<Fake_Review_Prepend_Input>;
  _set?: Maybe<Fake_Review_Set_Input>;
  where: Fake_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fake_Review_By_PkArgs = {
  _append?: Maybe<Fake_Review_Append_Input>;
  _delete_at_path?: Maybe<Fake_Review_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Fake_Review_Delete_Elem_Input>;
  _delete_key?: Maybe<Fake_Review_Delete_Key_Input>;
  _inc?: Maybe<Fake_Review_Inc_Input>;
  _prepend?: Maybe<Fake_Review_Prepend_Input>;
  _set?: Maybe<Fake_Review_Set_Input>;
  pk_columns: Fake_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GalleryArgs = {
  _append?: Maybe<Gallery_Append_Input>;
  _delete_at_path?: Maybe<Gallery_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gallery_Delete_Elem_Input>;
  _delete_key?: Maybe<Gallery_Delete_Key_Input>;
  _inc?: Maybe<Gallery_Inc_Input>;
  _prepend?: Maybe<Gallery_Prepend_Input>;
  _set?: Maybe<Gallery_Set_Input>;
  where: Gallery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gallery_By_PkArgs = {
  _append?: Maybe<Gallery_Append_Input>;
  _delete_at_path?: Maybe<Gallery_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gallery_Delete_Elem_Input>;
  _delete_key?: Maybe<Gallery_Delete_Key_Input>;
  _inc?: Maybe<Gallery_Inc_Input>;
  _prepend?: Maybe<Gallery_Prepend_Input>;
  _set?: Maybe<Gallery_Set_Input>;
  pk_columns: Gallery_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_ColumnsArgs = {
  _inc?: Maybe<Geometry_Columns_Inc_Input>;
  _set?: Maybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HashtagArgs = {
  _set?: Maybe<Hashtag_Set_Input>;
  where: Hashtag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hashtag_By_PkArgs = {
  _set?: Maybe<Hashtag_Set_Input>;
  pk_columns: Hashtag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InventoryArgs = {
  _append?: Maybe<Inventory_Append_Input>;
  _delete_at_path?: Maybe<Inventory_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inventory_Delete_Elem_Input>;
  _delete_key?: Maybe<Inventory_Delete_Key_Input>;
  _prepend?: Maybe<Inventory_Prepend_Input>;
  _set?: Maybe<Inventory_Set_Input>;
  where: Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inventory_By_PkArgs = {
  _append?: Maybe<Inventory_Append_Input>;
  _delete_at_path?: Maybe<Inventory_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inventory_Delete_Elem_Input>;
  _delete_key?: Maybe<Inventory_Delete_Key_Input>;
  _prepend?: Maybe<Inventory_Prepend_Input>;
  _set?: Maybe<Inventory_Set_Input>;
  pk_columns: Inventory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceArgs = {
  _append?: Maybe<Invoice_Append_Input>;
  _delete_at_path?: Maybe<Invoice_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Invoice_Delete_Elem_Input>;
  _delete_key?: Maybe<Invoice_Delete_Key_Input>;
  _inc?: Maybe<Invoice_Inc_Input>;
  _prepend?: Maybe<Invoice_Prepend_Input>;
  _set?: Maybe<Invoice_Set_Input>;
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_And_TransactionArgs = {
  _set?: Maybe<Invoice_And_Transaction_Set_Input>;
  where: Invoice_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_And_Transaction_By_PkArgs = {
  _set?: Maybe<Invoice_And_Transaction_Set_Input>;
  pk_columns: Invoice_And_Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_And_TransferArgs = {
  _set?: Maybe<Invoice_And_Transfer_Set_Input>;
  where: Invoice_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_And_Transfer_By_PkArgs = {
  _set?: Maybe<Invoice_And_Transfer_Set_Input>;
  pk_columns: Invoice_And_Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_By_PkArgs = {
  _append?: Maybe<Invoice_Append_Input>;
  _delete_at_path?: Maybe<Invoice_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Invoice_Delete_Elem_Input>;
  _delete_key?: Maybe<Invoice_Delete_Key_Input>;
  _inc?: Maybe<Invoice_Inc_Input>;
  _prepend?: Maybe<Invoice_Prepend_Input>;
  _set?: Maybe<Invoice_Set_Input>;
  pk_columns: Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_StatusArgs = {
  _set?: Maybe<Invoice_Status_Set_Input>;
  where: Invoice_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Status_By_PkArgs = {
  _set?: Maybe<Invoice_Status_Set_Input>;
  pk_columns: Invoice_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kakao_And_AccountArgs = {
  _inc?: Maybe<Kakao_And_Account_Inc_Input>;
  _set?: Maybe<Kakao_And_Account_Set_Input>;
  where: Kakao_And_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kakao_And_Account_By_PkArgs = {
  _inc?: Maybe<Kakao_And_Account_Inc_Input>;
  _set?: Maybe<Kakao_And_Account_Set_Input>;
  pk_columns: Kakao_And_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadArgs = {
  _append?: Maybe<Lead_Append_Input>;
  _delete_at_path?: Maybe<Lead_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Lead_Delete_Elem_Input>;
  _delete_key?: Maybe<Lead_Delete_Key_Input>;
  _inc?: Maybe<Lead_Inc_Input>;
  _prepend?: Maybe<Lead_Prepend_Input>;
  _set?: Maybe<Lead_Set_Input>;
  where: Lead_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lead_By_PkArgs = {
  _append?: Maybe<Lead_Append_Input>;
  _delete_at_path?: Maybe<Lead_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Lead_Delete_Elem_Input>;
  _delete_key?: Maybe<Lead_Delete_Key_Input>;
  _inc?: Maybe<Lead_Inc_Input>;
  _prepend?: Maybe<Lead_Prepend_Input>;
  _set?: Maybe<Lead_Set_Input>;
  pk_columns: Lead_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lead_CommentArgs = {
  _set?: Maybe<Lead_Comment_Set_Input>;
  where: Lead_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lead_Comment_By_PkArgs = {
  _set?: Maybe<Lead_Comment_Set_Input>;
  pk_columns: Lead_Comment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lead_StatusArgs = {
  _set?: Maybe<Lead_Status_Set_Input>;
  where: Lead_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lead_Status_By_PkArgs = {
  _set?: Maybe<Lead_Status_Set_Input>;
  pk_columns: Lead_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LikeArgs = {
  _set?: Maybe<Like_Set_Input>;
  where: Like_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Like_By_PkArgs = {
  _set?: Maybe<Like_Set_Input>;
  pk_columns: Like_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipArgs = {
  _inc?: Maybe<Membership_Inc_Input>;
  _set?: Maybe<Membership_Set_Input>;
  where: Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_By_PkArgs = {
  _inc?: Maybe<Membership_Inc_Input>;
  _set?: Maybe<Membership_Set_Input>;
  pk_columns: Membership_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_LogArgs = {
  _inc?: Maybe<Membership_Log_Inc_Input>;
  _set?: Maybe<Membership_Log_Set_Input>;
  where: Membership_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_Log_By_PkArgs = {
  _inc?: Maybe<Membership_Log_Inc_Input>;
  _set?: Maybe<Membership_Log_Set_Input>;
  pk_columns: Membership_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_TypeArgs = {
  _set?: Maybe<Membership_Type_Set_Input>;
  where: Membership_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_Type_By_PkArgs = {
  _set?: Maybe<Membership_Type_Set_Input>;
  pk_columns: Membership_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_N_CartArgs = {
  _set?: Maybe<N_Cart_Set_Input>;
  where: N_Cart_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_N_Cart_By_PkArgs = {
  _set?: Maybe<N_Cart_Set_Input>;
  pk_columns: N_Cart_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_N_OrderArgs = {
  _set?: Maybe<N_Order_Set_Input>;
  where: N_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_N_Order_By_PkArgs = {
  _set?: Maybe<N_Order_Set_Input>;
  pk_columns: N_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _append?: Maybe<Order_Append_Input>;
  _delete_at_path?: Maybe<Order_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Delete_Key_Input>;
  _inc?: Maybe<Order_Inc_Input>;
  _prepend?: Maybe<Order_Prepend_Input>;
  _set?: Maybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_And_ReviewArgs = {
  _set?: Maybe<Order_And_Review_Set_Input>;
  where: Order_And_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_And_Review_By_PkArgs = {
  _set?: Maybe<Order_And_Review_Set_Input>;
  pk_columns: Order_And_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_And_TransactionArgs = {
  _set?: Maybe<Order_And_Transaction_Set_Input>;
  where: Order_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_And_Transaction_By_PkArgs = {
  _set?: Maybe<Order_And_Transaction_Set_Input>;
  pk_columns: Order_And_Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_And_TransferArgs = {
  _set?: Maybe<Order_And_Transfer_Set_Input>;
  where: Order_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_And_Transfer_By_PkArgs = {
  _set?: Maybe<Order_And_Transfer_Set_Input>;
  pk_columns: Order_And_Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _append?: Maybe<Order_Append_Input>;
  _delete_at_path?: Maybe<Order_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Delete_Key_Input>;
  _inc?: Maybe<Order_Inc_Input>;
  _prepend?: Maybe<Order_Prepend_Input>;
  _set?: Maybe<Order_Set_Input>;
  pk_columns: Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_From_StoreArgs = {
  _append?: Maybe<Order_From_Store_Append_Input>;
  _delete_at_path?: Maybe<Order_From_Store_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_From_Store_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_From_Store_Delete_Key_Input>;
  _inc?: Maybe<Order_From_Store_Inc_Input>;
  _prepend?: Maybe<Order_From_Store_Prepend_Input>;
  _set?: Maybe<Order_From_Store_Set_Input>;
  where: Order_From_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_From_Store_By_PkArgs = {
  _append?: Maybe<Order_From_Store_Append_Input>;
  _delete_at_path?: Maybe<Order_From_Store_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_From_Store_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_From_Store_Delete_Key_Input>;
  _inc?: Maybe<Order_From_Store_Inc_Input>;
  _prepend?: Maybe<Order_From_Store_Prepend_Input>;
  _set?: Maybe<Order_From_Store_Set_Input>;
  pk_columns: Order_From_Store_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_From_Store_StatusArgs = {
  _set?: Maybe<Order_From_Store_Status_Set_Input>;
  where: Order_From_Store_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_From_Store_Status_By_PkArgs = {
  _set?: Maybe<Order_From_Store_Status_Set_Input>;
  pk_columns: Order_From_Store_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ItemArgs = {
  _append?: Maybe<Order_Item_Append_Input>;
  _delete_at_path?: Maybe<Order_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Item_Delete_Key_Input>;
  _inc?: Maybe<Order_Item_Inc_Input>;
  _prepend?: Maybe<Order_Item_Prepend_Input>;
  _set?: Maybe<Order_Item_Set_Input>;
  where: Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Item_By_PkArgs = {
  _append?: Maybe<Order_Item_Append_Input>;
  _delete_at_path?: Maybe<Order_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Item_Delete_Key_Input>;
  _inc?: Maybe<Order_Item_Inc_Input>;
  _prepend?: Maybe<Order_Item_Prepend_Input>;
  _set?: Maybe<Order_Item_Set_Input>;
  pk_columns: Order_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_StatusArgs = {
  _set?: Maybe<Order_Status_Set_Input>;
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Status_By_PkArgs = {
  _set?: Maybe<Order_Status_Set_Input>;
  pk_columns: Order_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PackageArgs = {
  _append?: Maybe<Package_Append_Input>;
  _delete_at_path?: Maybe<Package_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Package_Delete_Elem_Input>;
  _delete_key?: Maybe<Package_Delete_Key_Input>;
  _inc?: Maybe<Package_Inc_Input>;
  _prepend?: Maybe<Package_Prepend_Input>;
  _set?: Maybe<Package_Set_Input>;
  where: Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Package_By_PkArgs = {
  _append?: Maybe<Package_Append_Input>;
  _delete_at_path?: Maybe<Package_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Package_Delete_Elem_Input>;
  _delete_key?: Maybe<Package_Delete_Key_Input>;
  _inc?: Maybe<Package_Inc_Input>;
  _prepend?: Maybe<Package_Prepend_Input>;
  _set?: Maybe<Package_Set_Input>;
  pk_columns: Package_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Package_TypeArgs = {
  _set?: Maybe<Package_Type_Set_Input>;
  where: Package_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Package_Type_By_PkArgs = {
  _set?: Maybe<Package_Type_Set_Input>;
  pk_columns: Package_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PayoutArgs = {
  _inc?: Maybe<Payout_Inc_Input>;
  _set?: Maybe<Payout_Set_Input>;
  where: Payout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payout_And_TransferArgs = {
  _set?: Maybe<Payout_And_Transfer_Set_Input>;
  where: Payout_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payout_And_Transfer_By_PkArgs = {
  _set?: Maybe<Payout_And_Transfer_Set_Input>;
  pk_columns: Payout_And_Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payout_By_PkArgs = {
  _inc?: Maybe<Payout_Inc_Input>;
  _set?: Maybe<Payout_Set_Input>;
  pk_columns: Payout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payout_StatusArgs = {
  _set?: Maybe<Payout_Status_Set_Input>;
  where: Payout_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payout_Status_By_PkArgs = {
  _set?: Maybe<Payout_Status_Set_Input>;
  pk_columns: Payout_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _append?: Maybe<Product_Append_Input>;
  _delete_at_path?: Maybe<Product_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Delete_Key_Input>;
  _prepend?: Maybe<Product_Prepend_Input>;
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_And_LikeArgs = {
  _set?: Maybe<Product_And_Like_Set_Input>;
  where: Product_And_Like_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_And_Like_By_PkArgs = {
  _set?: Maybe<Product_And_Like_Set_Input>;
  pk_columns: Product_And_Like_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_BrandArgs = {
  _set?: Maybe<Product_Brand_Set_Input>;
  where: Product_Brand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Brand_By_PkArgs = {
  _set?: Maybe<Product_Brand_Set_Input>;
  pk_columns: Product_Brand_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _append?: Maybe<Product_Append_Input>;
  _delete_at_path?: Maybe<Product_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Delete_Key_Input>;
  _prepend?: Maybe<Product_Prepend_Input>;
  _set?: Maybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ItemArgs = {
  _append?: Maybe<Product_Item_Append_Input>;
  _delete_at_path?: Maybe<Product_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Item_Delete_Key_Input>;
  _inc?: Maybe<Product_Item_Inc_Input>;
  _prepend?: Maybe<Product_Item_Prepend_Input>;
  _set?: Maybe<Product_Item_Set_Input>;
  where: Product_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Item_And_ReviewArgs = {
  _set?: Maybe<Product_Item_And_Review_Set_Input>;
  where: Product_Item_And_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Item_And_Review_By_PkArgs = {
  _set?: Maybe<Product_Item_And_Review_Set_Input>;
  pk_columns: Product_Item_And_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Item_By_PkArgs = {
  _append?: Maybe<Product_Item_Append_Input>;
  _delete_at_path?: Maybe<Product_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Item_Delete_Key_Input>;
  _inc?: Maybe<Product_Item_Inc_Input>;
  _prepend?: Maybe<Product_Item_Prepend_Input>;
  _set?: Maybe<Product_Item_Set_Input>;
  pk_columns: Product_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Item_Price_By_StoreArgs = {
  _append?: Maybe<Product_Item_Price_By_Store_Append_Input>;
  _delete_at_path?: Maybe<Product_Item_Price_By_Store_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Item_Price_By_Store_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Item_Price_By_Store_Delete_Key_Input>;
  _prepend?: Maybe<Product_Item_Price_By_Store_Prepend_Input>;
  _set?: Maybe<Product_Item_Price_By_Store_Set_Input>;
  where: Product_Item_Price_By_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Item_Price_By_Store_By_PkArgs = {
  _append?: Maybe<Product_Item_Price_By_Store_Append_Input>;
  _delete_at_path?: Maybe<Product_Item_Price_By_Store_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Item_Price_By_Store_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Item_Price_By_Store_Delete_Key_Input>;
  _prepend?: Maybe<Product_Item_Price_By_Store_Prepend_Input>;
  _set?: Maybe<Product_Item_Price_By_Store_Set_Input>;
  pk_columns: Product_Item_Price_By_Store_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_TypeArgs = {
  _set?: Maybe<Product_Type_Set_Input>;
  where: Product_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Type_By_PkArgs = {
  _set?: Maybe<Product_Type_Set_Input>;
  pk_columns: Product_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Kr_CustomerArgs = {
  _inc?: Maybe<Profile_Kr_Customer_Inc_Input>;
  _set?: Maybe<Profile_Kr_Customer_Set_Input>;
  where: Profile_Kr_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Kr_Customer_By_PkArgs = {
  _inc?: Maybe<Profile_Kr_Customer_Inc_Input>;
  _set?: Maybe<Profile_Kr_Customer_Set_Input>;
  pk_columns: Profile_Kr_Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Kr_PartnerArgs = {
  _inc?: Maybe<Profile_Kr_Partner_Inc_Input>;
  _set?: Maybe<Profile_Kr_Partner_Set_Input>;
  where: Profile_Kr_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Kr_Partner_By_PkArgs = {
  _inc?: Maybe<Profile_Kr_Partner_Inc_Input>;
  _set?: Maybe<Profile_Kr_Partner_Set_Input>;
  pk_columns: Profile_Kr_Partner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_LanguageArgs = {
  _set?: Maybe<Profile_Language_Set_Input>;
  where: Profile_Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Language_By_PkArgs = {
  _set?: Maybe<Profile_Language_Set_Input>;
  pk_columns: Profile_Language_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_RiderdashArgs = {
  _set?: Maybe<Profile_Riderdash_Set_Input>;
  where: Profile_Riderdash_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Riderdash_By_PkArgs = {
  _set?: Maybe<Profile_Riderdash_Set_Input>;
  pk_columns: Profile_Riderdash_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Riderdash_RoleArgs = {
  _set?: Maybe<Profile_Riderdash_Role_Set_Input>;
  where: Profile_Riderdash_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Riderdash_Role_By_PkArgs = {
  _set?: Maybe<Profile_Riderdash_Role_Set_Input>;
  pk_columns: Profile_Riderdash_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Us_CustomerArgs = {
  _set?: Maybe<Profile_Us_Customer_Set_Input>;
  where: Profile_Us_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Us_Customer_By_PkArgs = {
  _set?: Maybe<Profile_Us_Customer_Set_Input>;
  pk_columns: Profile_Us_Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Us_PartnerArgs = {
  _set?: Maybe<Profile_Us_Partner_Set_Input>;
  where: Profile_Us_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Us_Partner_By_PkArgs = {
  _set?: Maybe<Profile_Us_Partner_Set_Input>;
  pk_columns: Profile_Us_Partner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionArgs = {
  _inc?: Maybe<Promotion_Inc_Input>;
  _set?: Maybe<Promotion_Set_Input>;
  where: Promotion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promotion_By_PkArgs = {
  _inc?: Maybe<Promotion_Inc_Input>;
  _set?: Maybe<Promotion_Set_Input>;
  pk_columns: Promotion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuoteArgs = {
  _set?: Maybe<Quote_Set_Input>;
  where: Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_By_PkArgs = {
  _set?: Maybe<Quote_Set_Input>;
  pk_columns: Quote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_ItemArgs = {
  _append?: Maybe<Quote_Item_Append_Input>;
  _delete_at_path?: Maybe<Quote_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Quote_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Quote_Item_Delete_Key_Input>;
  _prepend?: Maybe<Quote_Item_Prepend_Input>;
  _set?: Maybe<Quote_Item_Set_Input>;
  where: Quote_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_Item_By_PkArgs = {
  _append?: Maybe<Quote_Item_Append_Input>;
  _delete_at_path?: Maybe<Quote_Item_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Quote_Item_Delete_Elem_Input>;
  _delete_key?: Maybe<Quote_Item_Delete_Key_Input>;
  _prepend?: Maybe<Quote_Item_Prepend_Input>;
  _set?: Maybe<Quote_Item_Set_Input>;
  pk_columns: Quote_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_RequestArgs = {
  _append?: Maybe<Quote_Request_Append_Input>;
  _delete_at_path?: Maybe<Quote_Request_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Quote_Request_Delete_Elem_Input>;
  _delete_key?: Maybe<Quote_Request_Delete_Key_Input>;
  _prepend?: Maybe<Quote_Request_Prepend_Input>;
  _set?: Maybe<Quote_Request_Set_Input>;
  where: Quote_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_Request_By_PkArgs = {
  _append?: Maybe<Quote_Request_Append_Input>;
  _delete_at_path?: Maybe<Quote_Request_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Quote_Request_Delete_Elem_Input>;
  _delete_key?: Maybe<Quote_Request_Delete_Key_Input>;
  _prepend?: Maybe<Quote_Request_Prepend_Input>;
  _set?: Maybe<Quote_Request_Set_Input>;
  pk_columns: Quote_Request_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RefundArgs = {
  _inc?: Maybe<Refund_Inc_Input>;
  _set?: Maybe<Refund_Set_Input>;
  where: Refund_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_And_TransactionArgs = {
  _set?: Maybe<Refund_And_Transaction_Set_Input>;
  where: Refund_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_And_Transaction_By_PkArgs = {
  _set?: Maybe<Refund_And_Transaction_Set_Input>;
  pk_columns: Refund_And_Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_And_TransferArgs = {
  _set?: Maybe<Refund_And_Transfer_Set_Input>;
  where: Refund_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_And_Transfer_By_PkArgs = {
  _set?: Maybe<Refund_And_Transfer_Set_Input>;
  pk_columns: Refund_And_Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_By_PkArgs = {
  _inc?: Maybe<Refund_Inc_Input>;
  _set?: Maybe<Refund_Set_Input>;
  pk_columns: Refund_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_StatusArgs = {
  _set?: Maybe<Refund_Status_Set_Input>;
  where: Refund_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_Status_By_PkArgs = {
  _set?: Maybe<Refund_Status_Set_Input>;
  pk_columns: Refund_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewArgs = {
  _append?: Maybe<Review_Append_Input>;
  _delete_at_path?: Maybe<Review_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Review_Delete_Elem_Input>;
  _delete_key?: Maybe<Review_Delete_Key_Input>;
  _inc?: Maybe<Review_Inc_Input>;
  _prepend?: Maybe<Review_Prepend_Input>;
  _set?: Maybe<Review_Set_Input>;
  where: Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Review_By_PkArgs = {
  _append?: Maybe<Review_Append_Input>;
  _delete_at_path?: Maybe<Review_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Review_Delete_Elem_Input>;
  _delete_key?: Maybe<Review_Delete_Key_Input>;
  _inc?: Maybe<Review_Inc_Input>;
  _prepend?: Maybe<Review_Prepend_Input>;
  _set?: Maybe<Review_Set_Input>;
  pk_columns: Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Review_StatusArgs = {
  _set?: Maybe<Review_Status_Set_Input>;
  where: Review_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Review_Status_By_PkArgs = {
  _set?: Maybe<Review_Status_Set_Input>;
  pk_columns: Review_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SessionArgs = {
  _append?: Maybe<Session_Append_Input>;
  _delete_at_path?: Maybe<Session_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Session_Delete_Elem_Input>;
  _delete_key?: Maybe<Session_Delete_Key_Input>;
  _prepend?: Maybe<Session_Prepend_Input>;
  _set?: Maybe<Session_Set_Input>;
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_By_PkArgs = {
  _append?: Maybe<Session_Append_Input>;
  _delete_at_path?: Maybe<Session_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Session_Delete_Elem_Input>;
  _delete_key?: Maybe<Session_Delete_Key_Input>;
  _prepend?: Maybe<Session_Prepend_Input>;
  _set?: Maybe<Session_Set_Input>;
  pk_columns: Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SlotArgs = {
  _inc?: Maybe<Slot_Inc_Input>;
  _set?: Maybe<Slot_Set_Input>;
  where: Slot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Slot_By_PkArgs = {
  _inc?: Maybe<Slot_Inc_Input>;
  _set?: Maybe<Slot_Set_Input>;
  pk_columns: Slot_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SoleArgs = {
  _set?: Maybe<Sole_Set_Input>;
  where: Sole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sole_By_PkArgs = {
  _set?: Maybe<Sole_Set_Input>;
  pk_columns: Sole_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_SysArgs = {
  _inc?: Maybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: Maybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs = {
  _inc?: Maybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: Maybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StoreArgs = {
  _append?: Maybe<Store_Append_Input>;
  _delete_at_path?: Maybe<Store_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Store_Delete_Elem_Input>;
  _delete_key?: Maybe<Store_Delete_Key_Input>;
  _prepend?: Maybe<Store_Prepend_Input>;
  _set?: Maybe<Store_Set_Input>;
  where: Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Account_RoleArgs = {
  _set?: Maybe<Store_Account_Role_Set_Input>;
  where: Store_Account_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Account_Role_By_PkArgs = {
  _set?: Maybe<Store_Account_Role_Set_Input>;
  pk_columns: Store_Account_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_AccountArgs = {
  _set?: Maybe<Store_And_Account_Set_Input>;
  where: Store_And_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Account_By_PkArgs = {
  _set?: Maybe<Store_And_Account_Set_Input>;
  pk_columns: Store_And_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_HashtagArgs = {
  _inc?: Maybe<Store_And_Hashtag_Inc_Input>;
  _set?: Maybe<Store_And_Hashtag_Set_Input>;
  where: Store_And_Hashtag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Hashtag_By_PkArgs = {
  _inc?: Maybe<Store_And_Hashtag_Inc_Input>;
  _set?: Maybe<Store_And_Hashtag_Set_Input>;
  pk_columns: Store_And_Hashtag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_LikeArgs = {
  _set?: Maybe<Store_And_Like_Set_Input>;
  where: Store_And_Like_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Like_By_PkArgs = {
  _set?: Maybe<Store_And_Like_Set_Input>;
  pk_columns: Store_And_Like_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_PackageArgs = {
  _set?: Maybe<Store_And_Package_Set_Input>;
  where: Store_And_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Package_By_PkArgs = {
  _set?: Maybe<Store_And_Package_Set_Input>;
  pk_columns: Store_And_Package_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_ProductArgs = {
  _inc?: Maybe<Store_And_Product_Inc_Input>;
  _set?: Maybe<Store_And_Product_Set_Input>;
  where: Store_And_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Product_By_PkArgs = {
  _inc?: Maybe<Store_And_Product_Inc_Input>;
  _set?: Maybe<Store_And_Product_Set_Input>;
  pk_columns: Store_And_Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Product_ItemArgs = {
  _set?: Maybe<Store_And_Product_Item_Set_Input>;
  where: Store_And_Product_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Product_Item_By_PkArgs = {
  _set?: Maybe<Store_And_Product_Item_Set_Input>;
  pk_columns: Store_And_Product_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_ReviewArgs = {
  _set?: Maybe<Store_And_Review_Set_Input>;
  where: Store_And_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Review_By_PkArgs = {
  _set?: Maybe<Store_And_Review_Set_Input>;
  pk_columns: Store_And_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_SoleArgs = {
  _set?: Maybe<Store_And_Sole_Set_Input>;
  where: Store_And_Sole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_And_Sole_By_PkArgs = {
  _set?: Maybe<Store_And_Sole_Set_Input>;
  pk_columns: Store_And_Sole_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_By_PkArgs = {
  _append?: Maybe<Store_Append_Input>;
  _delete_at_path?: Maybe<Store_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Store_Delete_Elem_Input>;
  _delete_key?: Maybe<Store_Delete_Key_Input>;
  _prepend?: Maybe<Store_Prepend_Input>;
  _set?: Maybe<Store_Set_Input>;
  pk_columns: Store_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_StatusArgs = {
  _set?: Maybe<Store_Status_Set_Input>;
  where: Store_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Status_By_PkArgs = {
  _set?: Maybe<Store_Status_Set_Input>;
  pk_columns: Store_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionArgs = {
  _inc?: Maybe<Subscription_Inc_Input>;
  _set?: Maybe<Subscription_Set_Input>;
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_And_TransactionArgs = {
  _set?: Maybe<Subscription_And_Transaction_Set_Input>;
  where: Subscription_And_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_And_Transaction_By_PkArgs = {
  _set?: Maybe<Subscription_And_Transaction_Set_Input>;
  pk_columns: Subscription_And_Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_And_TransferArgs = {
  _set?: Maybe<Subscription_And_Transfer_Set_Input>;
  where: Subscription_And_Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_And_Transfer_By_PkArgs = {
  _set?: Maybe<Subscription_And_Transfer_Set_Input>;
  pk_columns: Subscription_And_Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_By_PkArgs = {
  _inc?: Maybe<Subscription_Inc_Input>;
  _set?: Maybe<Subscription_Set_Input>;
  pk_columns: Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_StatusArgs = {
  _set?: Maybe<Subscription_Status_Set_Input>;
  where: Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_Status_By_PkArgs = {
  _set?: Maybe<Subscription_Status_Set_Input>;
  pk_columns: Subscription_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionArgs = {
  _append?: Maybe<Transaction_Append_Input>;
  _delete_at_path?: Maybe<Transaction_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Transaction_Delete_Elem_Input>;
  _delete_key?: Maybe<Transaction_Delete_Key_Input>;
  _inc?: Maybe<Transaction_Inc_Input>;
  _prepend?: Maybe<Transaction_Prepend_Input>;
  _set?: Maybe<Transaction_Set_Input>;
  where: Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_By_PkArgs = {
  _append?: Maybe<Transaction_Append_Input>;
  _delete_at_path?: Maybe<Transaction_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Transaction_Delete_Elem_Input>;
  _delete_key?: Maybe<Transaction_Delete_Key_Input>;
  _inc?: Maybe<Transaction_Inc_Input>;
  _prepend?: Maybe<Transaction_Prepend_Input>;
  _set?: Maybe<Transaction_Set_Input>;
  pk_columns: Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_TypeArgs = {
  _set?: Maybe<Transaction_Type_Set_Input>;
  where: Transaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_Type_By_PkArgs = {
  _set?: Maybe<Transaction_Type_Set_Input>;
  pk_columns: Transaction_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TransferArgs = {
  _inc?: Maybe<Transfer_Inc_Input>;
  _set?: Maybe<Transfer_Set_Input>;
  where: Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transfer_By_PkArgs = {
  _inc?: Maybe<Transfer_Inc_Input>;
  _set?: Maybe<Transfer_Set_Input>;
  pk_columns: Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transfer_TypeArgs = {
  _set?: Maybe<Transfer_Type_Set_Input>;
  where: Transfer_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transfer_Type_By_PkArgs = {
  _set?: Maybe<Transfer_Type_Set_Input>;
  pk_columns: Transfer_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Actions_HistoryArgs = {
  _append?: Maybe<User_Actions_History_Append_Input>;
  _delete_at_path?: Maybe<User_Actions_History_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Actions_History_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Actions_History_Delete_Key_Input>;
  _prepend?: Maybe<User_Actions_History_Prepend_Input>;
  _set?: Maybe<User_Actions_History_Set_Input>;
  where: User_Actions_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Actions_History_By_PkArgs = {
  _append?: Maybe<User_Actions_History_Append_Input>;
  _delete_at_path?: Maybe<User_Actions_History_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Actions_History_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Actions_History_Delete_Key_Input>;
  _prepend?: Maybe<User_Actions_History_Prepend_Input>;
  _set?: Maybe<User_Actions_History_Set_Input>;
  pk_columns: User_Actions_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ViewArgs = {
  _set?: Maybe<View_Set_Input>;
  where: View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_View_By_PkArgs = {
  _set?: Maybe<View_Set_Input>;
  pk_columns: View_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_View_Package_StoreArgs = {
  _inc?: Maybe<View_Package_Store_Inc_Input>;
  _set?: Maybe<View_Package_Store_Set_Input>;
  where: View_Package_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_View_Package_Store_By_PkArgs = {
  _inc?: Maybe<View_Package_Store_Inc_Input>;
  _set?: Maybe<View_Package_Store_Set_Input>;
  pk_columns: View_Package_Store_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_View_StoreArgs = {
  _inc?: Maybe<View_Store_Inc_Input>;
  _set?: Maybe<View_Store_Set_Input>;
  where: View_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_View_Store_By_PkArgs = {
  _inc?: Maybe<View_Store_Inc_Input>;
  _set?: Maybe<View_Store_Set_Input>;
  pk_columns: View_Store_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_View_Store_GalleryArgs = {
  _inc?: Maybe<View_Store_Gallery_Inc_Input>;
  _set?: Maybe<View_Store_Gallery_Set_Input>;
  where: View_Store_Gallery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_View_Store_Gallery_By_PkArgs = {
  _inc?: Maybe<View_Store_Gallery_Inc_Input>;
  _set?: Maybe<View_Store_Gallery_Set_Input>;
  pk_columns: View_Store_Gallery_Pk_Columns_Input;
};

/** columns and relationships of "n_cart" */
export type N_Cart = {
  __typename?: 'n_cart';
  account?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  accountByAccount?: Maybe<Account>;
  /** An array relationship */
  cart_items: Array<Cart_Item>;
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  session?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sessionBySession?: Maybe<Session>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "n_cart" */
export type N_CartCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/** columns and relationships of "n_cart" */
export type N_CartCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};

/** aggregated selection of "n_cart" */
export type N_Cart_Aggregate = {
  __typename?: 'n_cart_aggregate';
  aggregate?: Maybe<N_Cart_Aggregate_Fields>;
  nodes: Array<N_Cart>;
};

/** aggregate fields of "n_cart" */
export type N_Cart_Aggregate_Fields = {
  __typename?: 'n_cart_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<N_Cart_Max_Fields>;
  min?: Maybe<N_Cart_Min_Fields>;
};


/** aggregate fields of "n_cart" */
export type N_Cart_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<N_Cart_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "n_cart" */
export type N_Cart_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<N_Cart_Max_Order_By>;
  min?: Maybe<N_Cart_Min_Order_By>;
};

/** input type for inserting array relation for remote table "n_cart" */
export type N_Cart_Arr_Rel_Insert_Input = {
  data: Array<N_Cart_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<N_Cart_On_Conflict>;
};

/** Boolean expression to filter rows from the table "n_cart". All fields are combined with a logical 'AND'. */
export type N_Cart_Bool_Exp = {
  _and?: Maybe<Array<N_Cart_Bool_Exp>>;
  _not?: Maybe<N_Cart_Bool_Exp>;
  _or?: Maybe<Array<N_Cart_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  cart_items?: Maybe<Cart_Item_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  session?: Maybe<Uuid_Comparison_Exp>;
  sessionBySession?: Maybe<Session_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "n_cart" */
export enum N_Cart_Constraint {
  /** unique or primary key constraint */
  NCartPkey = 'n_cart_pkey'
}

/** input type for inserting data into table "n_cart" */
export type N_Cart_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  session?: Maybe<Scalars['uuid']>;
  sessionBySession?: Maybe<Session_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type N_Cart_Max_Fields = {
  __typename?: 'n_cart_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "n_cart" */
export type N_Cart_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type N_Cart_Min_Fields = {
  __typename?: 'n_cart_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "n_cart" */
export type N_Cart_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "n_cart" */
export type N_Cart_Mutation_Response = {
  __typename?: 'n_cart_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<N_Cart>;
};

/** input type for inserting object relation for remote table "n_cart" */
export type N_Cart_Obj_Rel_Insert_Input = {
  data: N_Cart_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<N_Cart_On_Conflict>;
};

/** on conflict condition type for table "n_cart" */
export type N_Cart_On_Conflict = {
  constraint: N_Cart_Constraint;
  update_columns?: Array<N_Cart_Update_Column>;
  where?: Maybe<N_Cart_Bool_Exp>;
};

/** Ordering options when selecting data from "n_cart". */
export type N_Cart_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  sessionBySession?: Maybe<Session_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: n_cart */
export type N_Cart_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "n_cart" */
export enum N_Cart_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Session = 'session',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "n_cart" */
export type N_Cart_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "n_cart" */
export enum N_Cart_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Session = 'session',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "n_order" */
export type N_Order = {
  __typename?: 'n_order';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  order_items: Array<Order_Item>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Item_Aggregate;
  phone: Scalars['String'];
  recipient_address?: Maybe<Scalars['String']>;
  recipient_address_detail?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  recipient_phone?: Maybe<Scalars['String']>;
  recipient_zip_code?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "n_order" */
export type N_OrderOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/** columns and relationships of "n_order" */
export type N_OrderOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};

/** aggregated selection of "n_order" */
export type N_Order_Aggregate = {
  __typename?: 'n_order_aggregate';
  aggregate?: Maybe<N_Order_Aggregate_Fields>;
  nodes: Array<N_Order>;
};

/** aggregate fields of "n_order" */
export type N_Order_Aggregate_Fields = {
  __typename?: 'n_order_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<N_Order_Max_Fields>;
  min?: Maybe<N_Order_Min_Fields>;
};


/** aggregate fields of "n_order" */
export type N_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<N_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "n_order" */
export type N_Order_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<N_Order_Max_Order_By>;
  min?: Maybe<N_Order_Min_Order_By>;
};

/** input type for inserting array relation for remote table "n_order" */
export type N_Order_Arr_Rel_Insert_Input = {
  data: Array<N_Order_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<N_Order_On_Conflict>;
};

/** Boolean expression to filter rows from the table "n_order". All fields are combined with a logical 'AND'. */
export type N_Order_Bool_Exp = {
  _and?: Maybe<Array<N_Order_Bool_Exp>>;
  _not?: Maybe<N_Order_Bool_Exp>;
  _or?: Maybe<Array<N_Order_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_items?: Maybe<Order_Item_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  recipient_address?: Maybe<String_Comparison_Exp>;
  recipient_address_detail?: Maybe<String_Comparison_Exp>;
  recipient_name?: Maybe<String_Comparison_Exp>;
  recipient_phone?: Maybe<String_Comparison_Exp>;
  recipient_zip_code?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "n_order" */
export enum N_Order_Constraint {
  /** unique or primary key constraint */
  NOrderPkey = 'n_order_pkey'
}

/** input type for inserting data into table "n_order" */
export type N_Order_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order_items?: Maybe<Order_Item_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  recipient_address?: Maybe<Scalars['String']>;
  recipient_address_detail?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  recipient_phone?: Maybe<Scalars['String']>;
  recipient_zip_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type N_Order_Max_Fields = {
  __typename?: 'n_order_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recipient_address?: Maybe<Scalars['String']>;
  recipient_address_detail?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  recipient_phone?: Maybe<Scalars['String']>;
  recipient_zip_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "n_order" */
export type N_Order_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  recipient_address?: Maybe<Order_By>;
  recipient_address_detail?: Maybe<Order_By>;
  recipient_name?: Maybe<Order_By>;
  recipient_phone?: Maybe<Order_By>;
  recipient_zip_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type N_Order_Min_Fields = {
  __typename?: 'n_order_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recipient_address?: Maybe<Scalars['String']>;
  recipient_address_detail?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  recipient_phone?: Maybe<Scalars['String']>;
  recipient_zip_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "n_order" */
export type N_Order_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  recipient_address?: Maybe<Order_By>;
  recipient_address_detail?: Maybe<Order_By>;
  recipient_name?: Maybe<Order_By>;
  recipient_phone?: Maybe<Order_By>;
  recipient_zip_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "n_order" */
export type N_Order_Mutation_Response = {
  __typename?: 'n_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<N_Order>;
};

/** input type for inserting object relation for remote table "n_order" */
export type N_Order_Obj_Rel_Insert_Input = {
  data: N_Order_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<N_Order_On_Conflict>;
};

/** on conflict condition type for table "n_order" */
export type N_Order_On_Conflict = {
  constraint: N_Order_Constraint;
  update_columns?: Array<N_Order_Update_Column>;
  where?: Maybe<N_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "n_order". */
export type N_Order_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_items_aggregate?: Maybe<Order_Item_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  recipient_address?: Maybe<Order_By>;
  recipient_address_detail?: Maybe<Order_By>;
  recipient_name?: Maybe<Order_By>;
  recipient_phone?: Maybe<Order_By>;
  recipient_zip_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: n_order */
export type N_Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "n_order" */
export enum N_Order_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  RecipientAddress = 'recipient_address',
  /** column name */
  RecipientAddressDetail = 'recipient_address_detail',
  /** column name */
  RecipientName = 'recipient_name',
  /** column name */
  RecipientPhone = 'recipient_phone',
  /** column name */
  RecipientZipCode = 'recipient_zip_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "n_order" */
export type N_Order_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recipient_address?: Maybe<Scalars['String']>;
  recipient_address_detail?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  recipient_phone?: Maybe<Scalars['String']>;
  recipient_zip_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "n_order" */
export enum N_Order_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  RecipientAddress = 'recipient_address',
  /** column name */
  RecipientAddressDetail = 'recipient_address_detail',
  /** column name */
  RecipientName = 'recipient_name',
  /** column name */
  RecipientPhone = 'recipient_phone',
  /** column name */
  RecipientZipCode = 'recipient_zip_code',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: Maybe<Scalars['name']>;
  _gt?: Maybe<Scalars['name']>;
  _gte?: Maybe<Scalars['name']>;
  _in?: Maybe<Array<Scalars['name']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['name']>;
  _lte?: Maybe<Scalars['name']>;
  _neq?: Maybe<Scalars['name']>;
  _nin?: Maybe<Array<Scalars['name']>>;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type Order = {
  __typename?: 'order';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  adjustments: Array<Adjustment>;
  /** An aggregate relationship */
  adjustments_aggregate: Adjustment_Aggregate;
  basic_price_payout: Scalars['numeric'];
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  carByCar?: Maybe<Car>;
  /** An object relationship */
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  commission_rate: Scalars['numeric'];
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  /** Confirmed datetime by admin */
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_option: Scalars['jsonb'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  installed_at?: Maybe<Scalars['timestamptz']>;
  is_777_order: Scalars['Boolean'];
  is_auto_order: Scalars['Boolean'];
  lead?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  leadByLead?: Maybe<Lead>;
  name: Scalars['String'];
  /** An array relationship */
  order_and_reviews: Array<Order_And_Review>;
  /** An aggregate relationship */
  order_and_reviews_aggregate: Order_And_Review_Aggregate;
  /** An array relationship */
  order_and_transactions: Array<Order_And_Transaction>;
  /** An aggregate relationship */
  order_and_transactions_aggregate: Order_And_Transaction_Aggregate;
  /** An array relationship */
  order_and_transfers: Array<Order_And_Transfer>;
  /** An aggregate relationship */
  order_and_transfers_aggregate: Order_And_Transfer_Aggregate;
  /** An object relationship */
  order_status: Order_Status;
  /** A computed field, executes function "order_package_info" */
  package_detail?: Maybe<Array<Package>>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  payout?: Maybe<Payout>;
  /** An array relationship */
  payouts: Array<Payout>;
  /** An aggregate relationship */
  payouts_aggregate: Payout_Aggregate;
  phone: Scalars['String'];
  price_changed_reason?: Maybe<Scalars['String']>;
  price_discount: Scalars['numeric'];
  price_extra: Scalars['numeric'];
  price_final: Scalars['numeric'];
  price_payout: Scalars['numeric'];
  price_payout_discount: Scalars['numeric'];
  price_payout_extra: Scalars['numeric'];
  price_payout_without_vat: Scalars['numeric'];
  price_product: Scalars['numeric'];
  product_item: Scalars['jsonb'];
  /** A computed field, executes function "order_product_item_detail" */
  product_item_detail?: Maybe<Array<Product_Item>>;
  /** An object relationship */
  quoteItemByQuoteItem?: Maybe<Quote_Item>;
  quote_item?: Maybe<Scalars['uuid']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  refunds: Array<Refund>;
  /** An aggregate relationship */
  refunds_aggregate: Refund_Aggregate;
  remark?: Maybe<Scalars['jsonb']>;
  status: Order_Status_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderAdjustmentsArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderAdjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderDate_Time_OptionArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderOrder_And_ReviewsArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderOrder_And_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderOrder_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderOrder_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderOrder_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderOrder_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderPackage_DetailArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderPayoutsArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderPayouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderProduct_ItemArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderProduct_Item_DetailArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderRefundsArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderRefunds_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


/**
 * Ordered list of users by their order checkout page
 *
 *
 * columns and relationships of "order"
 */
export type OrderRemarkArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order" */
export type Order_Aggregate = {
  __typename?: 'order_aggregate';
  aggregate?: Maybe<Order_Aggregate_Fields>;
  nodes: Array<Order>;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
  __typename?: 'order_aggregate_fields';
  avg?: Maybe<Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Max_Fields>;
  min?: Maybe<Order_Min_Fields>;
  stddev?: Maybe<Order_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sum_Fields>;
  var_pop?: Maybe<Order_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Var_Samp_Fields>;
  variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
  avg?: Maybe<Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Max_Order_By>;
  min?: Maybe<Order_Min_Order_By>;
  stddev?: Maybe<Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Sum_Order_By>;
  var_pop?: Maybe<Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Var_Samp_Order_By>;
  variance?: Maybe<Order_Variance_Order_By>;
};

/** columns and relationships of "order_and_review" */
export type Order_And_Review = {
  __typename?: 'order_and_review';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order: Scalars['uuid'];
  /** An object relationship */
  orderByOrder: Order;
  review: Scalars['uuid'];
  /** An object relationship */
  reviewByReview: Review;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "order_and_review" */
export type Order_And_Review_Aggregate = {
  __typename?: 'order_and_review_aggregate';
  aggregate?: Maybe<Order_And_Review_Aggregate_Fields>;
  nodes: Array<Order_And_Review>;
};

/** aggregate fields of "order_and_review" */
export type Order_And_Review_Aggregate_Fields = {
  __typename?: 'order_and_review_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_And_Review_Max_Fields>;
  min?: Maybe<Order_And_Review_Min_Fields>;
};


/** aggregate fields of "order_and_review" */
export type Order_And_Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_And_Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_and_review" */
export type Order_And_Review_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Order_And_Review_Max_Order_By>;
  min?: Maybe<Order_And_Review_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_and_review" */
export type Order_And_Review_Arr_Rel_Insert_Input = {
  data: Array<Order_And_Review_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_And_Review_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_and_review". All fields are combined with a logical 'AND'. */
export type Order_And_Review_Bool_Exp = {
  _and?: Maybe<Array<Order_And_Review_Bool_Exp>>;
  _not?: Maybe<Order_And_Review_Bool_Exp>;
  _or?: Maybe<Array<Order_And_Review_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  order?: Maybe<Uuid_Comparison_Exp>;
  orderByOrder?: Maybe<Order_Bool_Exp>;
  review?: Maybe<Uuid_Comparison_Exp>;
  reviewByReview?: Maybe<Review_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_and_review" */
export enum Order_And_Review_Constraint {
  /** unique or primary key constraint */
  OrderAndReviewPkey = 'order_and_review_pkey'
}

/** input type for inserting data into table "order_and_review" */
export type Order_And_Review_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  orderByOrder?: Maybe<Order_Obj_Rel_Insert_Input>;
  review?: Maybe<Scalars['uuid']>;
  reviewByReview?: Maybe<Review_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_And_Review_Max_Fields = {
  __typename?: 'order_and_review_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  review?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_and_review" */
export type Order_And_Review_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_And_Review_Min_Fields = {
  __typename?: 'order_and_review_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  review?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_and_review" */
export type Order_And_Review_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_and_review" */
export type Order_And_Review_Mutation_Response = {
  __typename?: 'order_and_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_And_Review>;
};

/** on conflict condition type for table "order_and_review" */
export type Order_And_Review_On_Conflict = {
  constraint: Order_And_Review_Constraint;
  update_columns?: Array<Order_And_Review_Update_Column>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "order_and_review". */
export type Order_And_Review_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  orderByOrder?: Maybe<Order_Order_By>;
  review?: Maybe<Order_By>;
  reviewByReview?: Maybe<Review_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_and_review */
export type Order_And_Review_Pk_Columns_Input = {
  order: Scalars['uuid'];
  review: Scalars['uuid'];
};

/** select columns of table "order_and_review" */
export enum Order_And_Review_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Order = 'order',
  /** column name */
  Review = 'review',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_and_review" */
export type Order_And_Review_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  review?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "order_and_review" */
export enum Order_And_Review_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Order = 'order',
  /** column name */
  Review = 'review',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "order_and_transaction" */
export type Order_And_Transaction = {
  __typename?: 'order_and_transaction';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order: Scalars['uuid'];
  /** An object relationship */
  orderByOrder: Order;
  transaction: Scalars['uuid'];
  /** An object relationship */
  transactionByTransaction: Transaction;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "order_and_transaction" */
export type Order_And_Transaction_Aggregate = {
  __typename?: 'order_and_transaction_aggregate';
  aggregate?: Maybe<Order_And_Transaction_Aggregate_Fields>;
  nodes: Array<Order_And_Transaction>;
};

/** aggregate fields of "order_and_transaction" */
export type Order_And_Transaction_Aggregate_Fields = {
  __typename?: 'order_and_transaction_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_And_Transaction_Max_Fields>;
  min?: Maybe<Order_And_Transaction_Min_Fields>;
};


/** aggregate fields of "order_and_transaction" */
export type Order_And_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_and_transaction" */
export type Order_And_Transaction_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Order_And_Transaction_Max_Order_By>;
  min?: Maybe<Order_And_Transaction_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_and_transaction" */
export type Order_And_Transaction_Arr_Rel_Insert_Input = {
  data: Array<Order_And_Transaction_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_And_Transaction_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_and_transaction". All fields are combined with a logical 'AND'. */
export type Order_And_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Order_And_Transaction_Bool_Exp>>;
  _not?: Maybe<Order_And_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Order_And_Transaction_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  order?: Maybe<Uuid_Comparison_Exp>;
  orderByOrder?: Maybe<Order_Bool_Exp>;
  transaction?: Maybe<Uuid_Comparison_Exp>;
  transactionByTransaction?: Maybe<Transaction_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_and_transaction" */
export enum Order_And_Transaction_Constraint {
  /** unique or primary key constraint */
  TransactionOrderPkey = 'transaction_order_pkey'
}

/** input type for inserting data into table "order_and_transaction" */
export type Order_And_Transaction_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  orderByOrder?: Maybe<Order_Obj_Rel_Insert_Input>;
  transaction?: Maybe<Scalars['uuid']>;
  transactionByTransaction?: Maybe<Transaction_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_And_Transaction_Max_Fields = {
  __typename?: 'order_and_transaction_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_and_transaction" */
export type Order_And_Transaction_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_And_Transaction_Min_Fields = {
  __typename?: 'order_and_transaction_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_and_transaction" */
export type Order_And_Transaction_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_and_transaction" */
export type Order_And_Transaction_Mutation_Response = {
  __typename?: 'order_and_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_And_Transaction>;
};

/** on conflict condition type for table "order_and_transaction" */
export type Order_And_Transaction_On_Conflict = {
  constraint: Order_And_Transaction_Constraint;
  update_columns?: Array<Order_And_Transaction_Update_Column>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "order_and_transaction". */
export type Order_And_Transaction_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  orderByOrder?: Maybe<Order_Order_By>;
  transaction?: Maybe<Order_By>;
  transactionByTransaction?: Maybe<Transaction_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_and_transaction */
export type Order_And_Transaction_Pk_Columns_Input = {
  order: Scalars['uuid'];
  transaction: Scalars['uuid'];
};

/** select columns of table "order_and_transaction" */
export enum Order_And_Transaction_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Order = 'order',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_and_transaction" */
export type Order_And_Transaction_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "order_and_transaction" */
export enum Order_And_Transaction_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Order = 'order',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "order_and_transfer" */
export type Order_And_Transfer = {
  __typename?: 'order_and_transfer';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order: Scalars['uuid'];
  /** An object relationship */
  orderByOrder: Order;
  transfer: Scalars['uuid'];
  /** An object relationship */
  transferByTransfer: Transfer;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "order_and_transfer" */
export type Order_And_Transfer_Aggregate = {
  __typename?: 'order_and_transfer_aggregate';
  aggregate?: Maybe<Order_And_Transfer_Aggregate_Fields>;
  nodes: Array<Order_And_Transfer>;
};

/** aggregate fields of "order_and_transfer" */
export type Order_And_Transfer_Aggregate_Fields = {
  __typename?: 'order_and_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_And_Transfer_Max_Fields>;
  min?: Maybe<Order_And_Transfer_Min_Fields>;
};


/** aggregate fields of "order_and_transfer" */
export type Order_And_Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_and_transfer" */
export type Order_And_Transfer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Order_And_Transfer_Max_Order_By>;
  min?: Maybe<Order_And_Transfer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_and_transfer" */
export type Order_And_Transfer_Arr_Rel_Insert_Input = {
  data: Array<Order_And_Transfer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_And_Transfer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_and_transfer". All fields are combined with a logical 'AND'. */
export type Order_And_Transfer_Bool_Exp = {
  _and?: Maybe<Array<Order_And_Transfer_Bool_Exp>>;
  _not?: Maybe<Order_And_Transfer_Bool_Exp>;
  _or?: Maybe<Array<Order_And_Transfer_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  order?: Maybe<Uuid_Comparison_Exp>;
  orderByOrder?: Maybe<Order_Bool_Exp>;
  transfer?: Maybe<Uuid_Comparison_Exp>;
  transferByTransfer?: Maybe<Transfer_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_and_transfer" */
export enum Order_And_Transfer_Constraint {
  /** unique or primary key constraint */
  TransferOrderPkey = 'transfer_order_pkey'
}

/** input type for inserting data into table "order_and_transfer" */
export type Order_And_Transfer_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  orderByOrder?: Maybe<Order_Obj_Rel_Insert_Input>;
  transfer?: Maybe<Scalars['uuid']>;
  transferByTransfer?: Maybe<Transfer_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_And_Transfer_Max_Fields = {
  __typename?: 'order_and_transfer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_and_transfer" */
export type Order_And_Transfer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_And_Transfer_Min_Fields = {
  __typename?: 'order_and_transfer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_and_transfer" */
export type Order_And_Transfer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_and_transfer" */
export type Order_And_Transfer_Mutation_Response = {
  __typename?: 'order_and_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_And_Transfer>;
};

/** on conflict condition type for table "order_and_transfer" */
export type Order_And_Transfer_On_Conflict = {
  constraint: Order_And_Transfer_Constraint;
  update_columns?: Array<Order_And_Transfer_Update_Column>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "order_and_transfer". */
export type Order_And_Transfer_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  orderByOrder?: Maybe<Order_Order_By>;
  transfer?: Maybe<Order_By>;
  transferByTransfer?: Maybe<Transfer_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_and_transfer */
export type Order_And_Transfer_Pk_Columns_Input = {
  order: Scalars['uuid'];
  transfer: Scalars['uuid'];
};

/** select columns of table "order_and_transfer" */
export enum Order_And_Transfer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Order = 'order',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_and_transfer" */
export type Order_And_Transfer_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "order_and_transfer" */
export enum Order_And_Transfer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Order = 'order',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Append_Input = {
  date_time_option?: Maybe<Scalars['jsonb']>;
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
  data: Array<Order_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  __typename?: 'order_avg_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: Maybe<Array<Order_Bool_Exp>>;
  _not?: Maybe<Order_Bool_Exp>;
  _or?: Maybe<Array<Order_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  adjustments?: Maybe<Adjustment_Bool_Exp>;
  basic_price_payout?: Maybe<Numeric_Comparison_Exp>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Bool_Exp>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum_Comparison_Exp>;
  commission_rate?: Maybe<Numeric_Comparison_Exp>;
  confirmed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_time?: Maybe<Timestamptz_Comparison_Exp>;
  date_time_option?: Maybe<Jsonb_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  installed_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_777_order?: Maybe<Boolean_Comparison_Exp>;
  is_auto_order?: Maybe<Boolean_Comparison_Exp>;
  lead?: Maybe<Uuid_Comparison_Exp>;
  leadByLead?: Maybe<Lead_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_and_reviews?: Maybe<Order_And_Review_Bool_Exp>;
  order_and_transactions?: Maybe<Order_And_Transaction_Bool_Exp>;
  order_and_transfers?: Maybe<Order_And_Transfer_Bool_Exp>;
  order_status?: Maybe<Order_Status_Bool_Exp>;
  package_detail?: Maybe<Package_Bool_Exp>;
  paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  partial_paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  payment_failed_at?: Maybe<Timestamptz_Comparison_Exp>;
  payment_link_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  payout?: Maybe<Payout_Bool_Exp>;
  payouts?: Maybe<Payout_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  price_changed_reason?: Maybe<String_Comparison_Exp>;
  price_discount?: Maybe<Numeric_Comparison_Exp>;
  price_extra?: Maybe<Numeric_Comparison_Exp>;
  price_final?: Maybe<Numeric_Comparison_Exp>;
  price_payout?: Maybe<Numeric_Comparison_Exp>;
  price_payout_discount?: Maybe<Numeric_Comparison_Exp>;
  price_payout_extra?: Maybe<Numeric_Comparison_Exp>;
  price_payout_without_vat?: Maybe<Numeric_Comparison_Exp>;
  price_product?: Maybe<Numeric_Comparison_Exp>;
  product_item?: Maybe<Jsonb_Comparison_Exp>;
  product_item_detail?: Maybe<Product_Item_Bool_Exp>;
  quoteItemByQuoteItem?: Maybe<Quote_Item_Bool_Exp>;
  quote_item?: Maybe<Uuid_Comparison_Exp>;
  refunded_at?: Maybe<Timestamptz_Comparison_Exp>;
  refunds?: Maybe<Refund_Bool_Exp>;
  remark?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Order_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint */
  OrderPkey = 'order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Delete_At_Path_Input = {
  date_time_option?: Maybe<Array<Scalars['String']>>;
  product_item?: Maybe<Array<Scalars['String']>>;
  remark?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Delete_Elem_Input = {
  date_time_option?: Maybe<Scalars['Int']>;
  product_item?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Delete_Key_Input = {
  date_time_option?: Maybe<Scalars['String']>;
  product_item?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

/** columns and relationships of "order_from_store" */
export type Order_From_Store = {
  __typename?: 'order_from_store';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commission_rate: Scalars['numeric'];
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  order_from_store_status: Order_From_Store_Status;
  paid_at?: Maybe<Scalars['timestamptz']>;
  price_discount: Scalars['numeric'];
  price_extra: Scalars['numeric'];
  price_final: Scalars['numeric'];
  price_payout: Scalars['numeric'];
  price_product: Scalars['numeric'];
  product_item: Scalars['jsonb'];
  refunded_at?: Maybe<Scalars['timestamptz']>;
  status: Order_From_Store_Status_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
  waiting: Scalars['Boolean'];
};


/** columns and relationships of "order_from_store" */
export type Order_From_StoreProduct_ItemArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order_from_store" */
export type Order_From_Store_Aggregate = {
  __typename?: 'order_from_store_aggregate';
  aggregate?: Maybe<Order_From_Store_Aggregate_Fields>;
  nodes: Array<Order_From_Store>;
};

/** aggregate fields of "order_from_store" */
export type Order_From_Store_Aggregate_Fields = {
  __typename?: 'order_from_store_aggregate_fields';
  avg?: Maybe<Order_From_Store_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_From_Store_Max_Fields>;
  min?: Maybe<Order_From_Store_Min_Fields>;
  stddev?: Maybe<Order_From_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Order_From_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_From_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Order_From_Store_Sum_Fields>;
  var_pop?: Maybe<Order_From_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Order_From_Store_Var_Samp_Fields>;
  variance?: Maybe<Order_From_Store_Variance_Fields>;
};


/** aggregate fields of "order_from_store" */
export type Order_From_Store_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_From_Store_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_from_store" */
export type Order_From_Store_Aggregate_Order_By = {
  avg?: Maybe<Order_From_Store_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_From_Store_Max_Order_By>;
  min?: Maybe<Order_From_Store_Min_Order_By>;
  stddev?: Maybe<Order_From_Store_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_From_Store_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_From_Store_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_From_Store_Sum_Order_By>;
  var_pop?: Maybe<Order_From_Store_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_From_Store_Var_Samp_Order_By>;
  variance?: Maybe<Order_From_Store_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_From_Store_Append_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order_from_store" */
export type Order_From_Store_Arr_Rel_Insert_Input = {
  data: Array<Order_From_Store_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_From_Store_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_From_Store_Avg_Fields = {
  __typename?: 'order_from_store_avg_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_from_store" */
export type Order_From_Store_Avg_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_from_store". All fields are combined with a logical 'AND'. */
export type Order_From_Store_Bool_Exp = {
  _and?: Maybe<Array<Order_From_Store_Bool_Exp>>;
  _not?: Maybe<Order_From_Store_Bool_Exp>;
  _or?: Maybe<Array<Order_From_Store_Bool_Exp>>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  commission_rate?: Maybe<Numeric_Comparison_Exp>;
  confirmed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order_from_store_status?: Maybe<Order_From_Store_Status_Bool_Exp>;
  paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  price_discount?: Maybe<Numeric_Comparison_Exp>;
  price_extra?: Maybe<Numeric_Comparison_Exp>;
  price_final?: Maybe<Numeric_Comparison_Exp>;
  price_payout?: Maybe<Numeric_Comparison_Exp>;
  price_product?: Maybe<Numeric_Comparison_Exp>;
  product_item?: Maybe<Jsonb_Comparison_Exp>;
  refunded_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Order_From_Store_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  waiting?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_from_store" */
export enum Order_From_Store_Constraint {
  /** unique or primary key constraint */
  InventoryRequestPkey = 'inventory_request_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_From_Store_Delete_At_Path_Input = {
  product_item?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_From_Store_Delete_Elem_Input = {
  product_item?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_From_Store_Delete_Key_Input = {
  product_item?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "order_from_store" */
export type Order_From_Store_Inc_Input = {
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order_from_store" */
export type Order_From_Store_Insert_Input = {
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order_from_store_status?: Maybe<Order_From_Store_Status_Obj_Rel_Insert_Input>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Order_From_Store_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waiting?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Order_From_Store_Max_Fields = {
  __typename?: 'order_from_store_max_fields';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_from_store" */
export type Order_From_Store_Max_Order_By = {
  canceled_at?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_From_Store_Min_Fields = {
  __typename?: 'order_from_store_min_fields';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_from_store" */
export type Order_From_Store_Min_Order_By = {
  canceled_at?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_from_store" */
export type Order_From_Store_Mutation_Response = {
  __typename?: 'order_from_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_From_Store>;
};

/** on conflict condition type for table "order_from_store" */
export type Order_From_Store_On_Conflict = {
  constraint: Order_From_Store_Constraint;
  update_columns?: Array<Order_From_Store_Update_Column>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "order_from_store". */
export type Order_From_Store_Order_By = {
  canceled_at?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_from_store_status?: Maybe<Order_From_Store_Status_Order_By>;
  paid_at?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
  waiting?: Maybe<Order_By>;
};

/** primary key columns input for table: order_from_store */
export type Order_From_Store_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_From_Store_Prepend_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "order_from_store" */
export enum Order_From_Store_Select_Column {
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Waiting = 'waiting'
}

/** input type for updating data in table "order_from_store" */
export type Order_From_Store_Set_Input = {
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Order_From_Store_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waiting?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "order_from_store_status" */
export type Order_From_Store_Status = {
  __typename?: 'order_from_store_status';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  order_from_stores: Array<Order_From_Store>;
  /** An aggregate relationship */
  order_from_stores_aggregate: Order_From_Store_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "order_from_store_status" */
export type Order_From_Store_StatusOrder_From_StoresArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};


/** columns and relationships of "order_from_store_status" */
export type Order_From_Store_StatusOrder_From_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};

/** aggregated selection of "order_from_store_status" */
export type Order_From_Store_Status_Aggregate = {
  __typename?: 'order_from_store_status_aggregate';
  aggregate?: Maybe<Order_From_Store_Status_Aggregate_Fields>;
  nodes: Array<Order_From_Store_Status>;
};

/** aggregate fields of "order_from_store_status" */
export type Order_From_Store_Status_Aggregate_Fields = {
  __typename?: 'order_from_store_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_From_Store_Status_Max_Fields>;
  min?: Maybe<Order_From_Store_Status_Min_Fields>;
};


/** aggregate fields of "order_from_store_status" */
export type Order_From_Store_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_From_Store_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_from_store_status". All fields are combined with a logical 'AND'. */
export type Order_From_Store_Status_Bool_Exp = {
  _and?: Maybe<Array<Order_From_Store_Status_Bool_Exp>>;
  _not?: Maybe<Order_From_Store_Status_Bool_Exp>;
  _or?: Maybe<Array<Order_From_Store_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  order_from_stores?: Maybe<Order_From_Store_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_from_store_status" */
export enum Order_From_Store_Status_Constraint {
  /** unique or primary key constraint */
  WholesaleOrderStatusTypePkey = 'wholesale_order_status_type_pkey'
}

export enum Order_From_Store_Status_Enum {
  /** 접수 완료 */
  Confirmed = 'confirmed',
  /** 접수 대기 */
  Created = 'created',
  /** 배송시작 */
  DeliverStart = 'deliver_start',
  /** 배송중 */
  Delivering = 'delivering',
  /** 배송 완료 */
  Finished = 'finished',
  /** 준비중 */
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "order_from_store_status_enum". All fields are combined with logical 'AND'. */
export type Order_From_Store_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Order_From_Store_Status_Enum>;
  _in?: Maybe<Array<Order_From_Store_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_From_Store_Status_Enum>;
  _nin?: Maybe<Array<Order_From_Store_Status_Enum>>;
};

/** input type for inserting data into table "order_from_store_status" */
export type Order_From_Store_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  order_from_stores?: Maybe<Order_From_Store_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_From_Store_Status_Max_Fields = {
  __typename?: 'order_from_store_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_From_Store_Status_Min_Fields = {
  __typename?: 'order_from_store_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_from_store_status" */
export type Order_From_Store_Status_Mutation_Response = {
  __typename?: 'order_from_store_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_From_Store_Status>;
};

/** input type for inserting object relation for remote table "order_from_store_status" */
export type Order_From_Store_Status_Obj_Rel_Insert_Input = {
  data: Order_From_Store_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Order_From_Store_Status_On_Conflict>;
};

/** on conflict condition type for table "order_from_store_status" */
export type Order_From_Store_Status_On_Conflict = {
  constraint: Order_From_Store_Status_Constraint;
  update_columns?: Array<Order_From_Store_Status_Update_Column>;
  where?: Maybe<Order_From_Store_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "order_from_store_status". */
export type Order_From_Store_Status_Order_By = {
  description?: Maybe<Order_By>;
  order_from_stores_aggregate?: Maybe<Order_From_Store_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: order_from_store_status */
export type Order_From_Store_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "order_from_store_status" */
export enum Order_From_Store_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_from_store_status" */
export type Order_From_Store_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "order_from_store_status" */
export enum Order_From_Store_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Order_From_Store_Stddev_Fields = {
  __typename?: 'order_from_store_stddev_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_from_store" */
export type Order_From_Store_Stddev_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_From_Store_Stddev_Pop_Fields = {
  __typename?: 'order_from_store_stddev_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_from_store" */
export type Order_From_Store_Stddev_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_From_Store_Stddev_Samp_Fields = {
  __typename?: 'order_from_store_stddev_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_from_store" */
export type Order_From_Store_Stddev_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_From_Store_Sum_Fields = {
  __typename?: 'order_from_store_sum_fields';
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "order_from_store" */
export type Order_From_Store_Sum_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** update columns of table "order_from_store" */
export enum Order_From_Store_Update_Column {
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Waiting = 'waiting'
}

/** aggregate var_pop on columns */
export type Order_From_Store_Var_Pop_Fields = {
  __typename?: 'order_from_store_var_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_from_store" */
export type Order_From_Store_Var_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_From_Store_Var_Samp_Fields = {
  __typename?: 'order_from_store_var_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_from_store" */
export type Order_From_Store_Var_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_From_Store_Variance_Fields = {
  __typename?: 'order_from_store_variance_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_from_store" */
export type Order_From_Store_Variance_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
  basic_price_payout?: Maybe<Scalars['numeric']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  adjustments?: Maybe<Adjustment_Arr_Rel_Insert_Input>;
  basic_price_payout?: Maybe<Scalars['numeric']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Obj_Rel_Insert_Input>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Confirmed datetime by admin */
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_option?: Maybe<Scalars['jsonb']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  is_777_order?: Maybe<Scalars['Boolean']>;
  is_auto_order?: Maybe<Scalars['Boolean']>;
  lead?: Maybe<Scalars['uuid']>;
  leadByLead?: Maybe<Lead_Obj_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  order_and_reviews?: Maybe<Order_And_Review_Arr_Rel_Insert_Input>;
  order_and_transactions?: Maybe<Order_And_Transaction_Arr_Rel_Insert_Input>;
  order_and_transfers?: Maybe<Order_And_Transfer_Arr_Rel_Insert_Input>;
  order_status?: Maybe<Order_Status_Obj_Rel_Insert_Input>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Payout_Obj_Rel_Insert_Input>;
  payouts?: Maybe<Payout_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  price_changed_reason?: Maybe<Scalars['String']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quoteItemByQuoteItem?: Maybe<Quote_Item_Obj_Rel_Insert_Input>;
  quote_item?: Maybe<Scalars['uuid']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  refunds?: Maybe<Refund_Arr_Rel_Insert_Input>;
  remark?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Order_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "order_item" */
export type Order_Item = {
  __typename?: 'order_item';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  carByCar?: Maybe<Car>;
  /** An object relationship */
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  commission_rate: Scalars['numeric'];
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  installed_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  n_order: N_Order;
  order: Scalars['uuid'];
  /** An object relationship */
  order_status: Order_Status;
  /** A computed field, executes function "order_item_package_info" */
  package_detail?: Maybe<Array<Package>>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  price_discount: Scalars['numeric'];
  price_extra: Scalars['numeric'];
  price_final: Scalars['numeric'];
  price_payout: Scalars['numeric'];
  price_payout_without_vat: Scalars['numeric'];
  price_product: Scalars['numeric'];
  product_item: Scalars['jsonb'];
  /** A computed field, executes function "order_item_detail" */
  product_item_detail?: Maybe<Array<Product_Item>>;
  quantity: Scalars['numeric'];
  refunded_at?: Maybe<Scalars['timestamptz']>;
  remark: Scalars['jsonb'];
  status: Order_Status_Enum;
  store?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  storeByStore?: Maybe<Store>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "order_item" */
export type Order_ItemPackage_DetailArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/** columns and relationships of "order_item" */
export type Order_ItemProduct_ItemArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "order_item" */
export type Order_ItemProduct_Item_DetailArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


/** columns and relationships of "order_item" */
export type Order_ItemRemarkArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order_item" */
export type Order_Item_Aggregate = {
  __typename?: 'order_item_aggregate';
  aggregate?: Maybe<Order_Item_Aggregate_Fields>;
  nodes: Array<Order_Item>;
};

/** aggregate fields of "order_item" */
export type Order_Item_Aggregate_Fields = {
  __typename?: 'order_item_aggregate_fields';
  avg?: Maybe<Order_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Item_Max_Fields>;
  min?: Maybe<Order_Item_Min_Fields>;
  stddev?: Maybe<Order_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Item_Sum_Fields>;
  var_pop?: Maybe<Order_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Item_Var_Samp_Fields>;
  variance?: Maybe<Order_Item_Variance_Fields>;
};


/** aggregate fields of "order_item" */
export type Order_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_item" */
export type Order_Item_Aggregate_Order_By = {
  avg?: Maybe<Order_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Item_Max_Order_By>;
  min?: Maybe<Order_Item_Min_Order_By>;
  stddev?: Maybe<Order_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Item_Sum_Order_By>;
  var_pop?: Maybe<Order_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Item_Var_Samp_Order_By>;
  variance?: Maybe<Order_Item_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Item_Append_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order_item" */
export type Order_Item_Arr_Rel_Insert_Input = {
  data: Array<Order_Item_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Item_Avg_Fields = {
  __typename?: 'order_item_avg_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_item" */
export type Order_Item_Avg_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_item". All fields are combined with a logical 'AND'. */
export type Order_Item_Bool_Exp = {
  _and?: Maybe<Array<Order_Item_Bool_Exp>>;
  _not?: Maybe<Order_Item_Bool_Exp>;
  _or?: Maybe<Array<Order_Item_Bool_Exp>>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Bool_Exp>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum_Comparison_Exp>;
  commission_rate?: Maybe<Numeric_Comparison_Exp>;
  confirmed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_time?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  installed_at?: Maybe<Timestamptz_Comparison_Exp>;
  n_order?: Maybe<N_Order_Bool_Exp>;
  order?: Maybe<Uuid_Comparison_Exp>;
  order_status?: Maybe<Order_Status_Bool_Exp>;
  package_detail?: Maybe<Package_Bool_Exp>;
  paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  partial_paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  payment_failed_at?: Maybe<Timestamptz_Comparison_Exp>;
  payment_link_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  price_discount?: Maybe<Numeric_Comparison_Exp>;
  price_extra?: Maybe<Numeric_Comparison_Exp>;
  price_final?: Maybe<Numeric_Comparison_Exp>;
  price_payout?: Maybe<Numeric_Comparison_Exp>;
  price_payout_without_vat?: Maybe<Numeric_Comparison_Exp>;
  price_product?: Maybe<Numeric_Comparison_Exp>;
  product_item?: Maybe<Jsonb_Comparison_Exp>;
  product_item_detail?: Maybe<Product_Item_Bool_Exp>;
  quantity?: Maybe<Numeric_Comparison_Exp>;
  refunded_at?: Maybe<Timestamptz_Comparison_Exp>;
  remark?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Order_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_item" */
export enum Order_Item_Constraint {
  /** unique or primary key constraint */
  OrderItemPkey = 'order_item_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Item_Delete_At_Path_Input = {
  product_item?: Maybe<Array<Scalars['String']>>;
  remark?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Item_Delete_Elem_Input = {
  product_item?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Item_Delete_Key_Input = {
  product_item?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "order_item" */
export type Order_Item_Inc_Input = {
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order_item" */
export type Order_Item_Insert_Input = {
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Obj_Rel_Insert_Input>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  n_order?: Maybe<N_Order_Obj_Rel_Insert_Input>;
  order?: Maybe<Scalars['uuid']>;
  order_status?: Maybe<Order_Status_Obj_Rel_Insert_Input>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quantity?: Maybe<Scalars['numeric']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Order_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Item_Max_Fields = {
  __typename?: 'order_item_max_fields';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_item" */
export type Order_Item_Max_Order_By = {
  canceled_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  installed_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  partial_paid_at?: Maybe<Order_By>;
  payment_failed_at?: Maybe<Order_By>;
  payment_link_sent_at?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Item_Min_Fields = {
  __typename?: 'order_item_min_fields';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_item" */
export type Order_Item_Min_Order_By = {
  canceled_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  installed_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  partial_paid_at?: Maybe<Order_By>;
  payment_failed_at?: Maybe<Order_By>;
  payment_link_sent_at?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_item" */
export type Order_Item_Mutation_Response = {
  __typename?: 'order_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Item>;
};

/** on conflict condition type for table "order_item" */
export type Order_Item_On_Conflict = {
  constraint: Order_Item_Constraint;
  update_columns?: Array<Order_Item_Update_Column>;
  where?: Maybe<Order_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "order_item". */
export type Order_Item_Order_By = {
  canceled_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Order_By>;
  car_sunroof_type?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  installed_at?: Maybe<Order_By>;
  n_order?: Maybe<N_Order_Order_By>;
  order?: Maybe<Order_By>;
  order_status?: Maybe<Order_Status_Order_By>;
  package_detail_aggregate?: Maybe<Package_Aggregate_Order_By>;
  paid_at?: Maybe<Order_By>;
  partial_paid_at?: Maybe<Order_By>;
  payment_failed_at?: Maybe<Order_By>;
  payment_link_sent_at?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  product_item_detail_aggregate?: Maybe<Product_Item_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  remark?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_item */
export type Order_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Item_Prepend_Input = {
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "order_item" */
export enum Order_Item_Select_Column {
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  Order = 'order',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PartialPaidAt = 'partial_paid_at',
  /** column name */
  PaymentFailedAt = 'payment_failed_at',
  /** column name */
  PaymentLinkSentAt = 'payment_link_sent_at',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_item" */
export type Order_Item_Set_Input = {
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quantity?: Maybe<Scalars['numeric']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Order_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Item_Stddev_Fields = {
  __typename?: 'order_item_stddev_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_item" */
export type Order_Item_Stddev_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Item_Stddev_Pop_Fields = {
  __typename?: 'order_item_stddev_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_item" */
export type Order_Item_Stddev_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Item_Stddev_Samp_Fields = {
  __typename?: 'order_item_stddev_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_item" */
export type Order_Item_Stddev_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Item_Sum_Fields = {
  __typename?: 'order_item_sum_fields';
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "order_item" */
export type Order_Item_Sum_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** update columns of table "order_item" */
export enum Order_Item_Update_Column {
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  Order = 'order',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PartialPaidAt = 'partial_paid_at',
  /** column name */
  PaymentFailedAt = 'payment_failed_at',
  /** column name */
  PaymentLinkSentAt = 'payment_link_sent_at',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Item_Var_Pop_Fields = {
  __typename?: 'order_item_var_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_item" */
export type Order_Item_Var_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Item_Var_Samp_Fields = {
  __typename?: 'order_item_var_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_item" */
export type Order_Item_Var_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Item_Variance_Fields = {
  __typename?: 'order_item_variance_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_item" */
export type Order_Item_Variance_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
  __typename?: 'order_max_fields';
  account?: Maybe<Scalars['uuid']>;
  basic_price_payout?: Maybe<Scalars['numeric']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Confirmed datetime by admin */
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  lead?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  price_changed_reason?: Maybe<Scalars['String']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quote_item?: Maybe<Scalars['uuid']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
  account?: Maybe<Order_By>;
  basic_price_payout?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Confirmed datetime by admin */
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  installed_at?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  partial_paid_at?: Maybe<Order_By>;
  payment_failed_at?: Maybe<Order_By>;
  payment_link_sent_at?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  price_changed_reason?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quote_item?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
  __typename?: 'order_min_fields';
  account?: Maybe<Scalars['uuid']>;
  basic_price_payout?: Maybe<Scalars['numeric']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Confirmed datetime by admin */
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  lead?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  price_changed_reason?: Maybe<Scalars['String']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  quote_item?: Maybe<Scalars['uuid']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
  account?: Maybe<Order_By>;
  basic_price_payout?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Confirmed datetime by admin */
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  installed_at?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  partial_paid_at?: Maybe<Order_By>;
  payment_failed_at?: Maybe<Order_By>;
  payment_link_sent_at?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  price_changed_reason?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  quote_item?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
  __typename?: 'order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** input type for inserting object relation for remote table "order" */
export type Order_Obj_Rel_Insert_Input = {
  data: Order_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Order_On_Conflict>;
};

/** on conflict condition type for table "order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint;
  update_columns?: Array<Order_Update_Column>;
  where?: Maybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  adjustments_aggregate?: Maybe<Adjustment_Aggregate_Order_By>;
  basic_price_payout?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Order_By>;
  car_sunroof_type?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  confirmed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  date_time_option?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  installed_at?: Maybe<Order_By>;
  is_777_order?: Maybe<Order_By>;
  is_auto_order?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  leadByLead?: Maybe<Lead_Order_By>;
  name?: Maybe<Order_By>;
  order_and_reviews_aggregate?: Maybe<Order_And_Review_Aggregate_Order_By>;
  order_and_transactions_aggregate?: Maybe<Order_And_Transaction_Aggregate_Order_By>;
  order_and_transfers_aggregate?: Maybe<Order_And_Transfer_Aggregate_Order_By>;
  order_status?: Maybe<Order_Status_Order_By>;
  package_detail_aggregate?: Maybe<Package_Aggregate_Order_By>;
  paid_at?: Maybe<Order_By>;
  partial_paid_at?: Maybe<Order_By>;
  payment_failed_at?: Maybe<Order_By>;
  payment_link_sent_at?: Maybe<Order_By>;
  payout?: Maybe<Payout_Order_By>;
  payouts_aggregate?: Maybe<Payout_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  price_changed_reason?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  product_item_detail_aggregate?: Maybe<Product_Item_Aggregate_Order_By>;
  quoteItemByQuoteItem?: Maybe<Quote_Item_Order_By>;
  quote_item?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  refunds_aggregate?: Maybe<Refund_Aggregate_Order_By>;
  remark?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Prepend_Input = {
  date_time_option?: Maybe<Scalars['jsonb']>;
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  BasicPricePayout = 'basic_price_payout',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DateTimeOption = 'date_time_option',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  Is_777Order = 'is_777_order',
  /** column name */
  IsAutoOrder = 'is_auto_order',
  /** column name */
  Lead = 'lead',
  /** column name */
  Name = 'name',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PartialPaidAt = 'partial_paid_at',
  /** column name */
  PaymentFailedAt = 'payment_failed_at',
  /** column name */
  PaymentLinkSentAt = 'payment_link_sent_at',
  /** column name */
  Phone = 'phone',
  /** column name */
  PriceChangedReason = 'price_changed_reason',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutDiscount = 'price_payout_discount',
  /** column name */
  PricePayoutExtra = 'price_payout_extra',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  QuoteItem = 'quote_item',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  basic_price_payout?: Maybe<Scalars['numeric']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  car?: Maybe<Scalars['uuid']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  commission_rate?: Maybe<Scalars['numeric']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Confirmed datetime by admin */
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_option?: Maybe<Scalars['jsonb']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installed_at?: Maybe<Scalars['timestamptz']>;
  is_777_order?: Maybe<Scalars['Boolean']>;
  is_auto_order?: Maybe<Scalars['Boolean']>;
  lead?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  partial_paid_at?: Maybe<Scalars['timestamptz']>;
  payment_failed_at?: Maybe<Scalars['timestamptz']>;
  payment_link_sent_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  price_changed_reason?: Maybe<Scalars['String']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quote_item?: Maybe<Scalars['uuid']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Order_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "order_status" */
export type Order_Status = {
  __typename?: 'order_status';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  order_items: Array<Order_Item>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Item_Aggregate;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "order_status" */
export type Order_StatusOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/** columns and relationships of "order_status" */
export type Order_StatusOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/** columns and relationships of "order_status" */
export type Order_StatusOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "order_status" */
export type Order_StatusOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};

/** aggregated selection of "order_status" */
export type Order_Status_Aggregate = {
  __typename?: 'order_status_aggregate';
  aggregate?: Maybe<Order_Status_Aggregate_Fields>;
  nodes: Array<Order_Status>;
};

/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_Fields = {
  __typename?: 'order_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_Status_Max_Fields>;
  min?: Maybe<Order_Status_Min_Fields>;
};


/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_status". All fields are combined with a logical 'AND'. */
export type Order_Status_Bool_Exp = {
  _and?: Maybe<Array<Order_Status_Bool_Exp>>;
  _not?: Maybe<Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Order_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  order_items?: Maybe<Order_Item_Bool_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_status" */
export enum Order_Status_Constraint {
  /** unique or primary key constraint */
  OrderStatusPkey = 'order_status_pkey'
}

export enum Order_Status_Enum {
  /** 취소 */
  Canceled = 'canceled',
  /** 예약 확정 */
  Confirmed = 'confirmed',
  /** 예약 진행중 */
  Created = 'created',
  /** 서비스 완료 */
  Finished = 'finished',
  /** 시공 완료 */
  Installed = 'installed',
  /** 결제 완료 */
  Paid = 'paid',
  /** 예약금 결제 완료 */
  PartialPaid = 'partial_paid',
  /** 결제 실패 */
  PaymentFailed = 'payment_failed',
  /** 결제 링크 전송 완료 */
  PaymentLinkSent = 'payment_link_sent',
  /** 환불 완료 */
  Refunded = 'refunded'
}

/** Boolean expression to compare columns of type "order_status_enum". All fields are combined with logical 'AND'. */
export type Order_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Order_Status_Enum>;
  _in?: Maybe<Array<Order_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_Status_Enum>;
  _nin?: Maybe<Array<Order_Status_Enum>>;
};

/** input type for inserting data into table "order_status" */
export type Order_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  order_items?: Maybe<Order_Item_Arr_Rel_Insert_Input>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Status_Max_Fields = {
  __typename?: 'order_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_Status_Min_Fields = {
  __typename?: 'order_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_status" */
export type Order_Status_Mutation_Response = {
  __typename?: 'order_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Status>;
};

/** input type for inserting object relation for remote table "order_status" */
export type Order_Status_Obj_Rel_Insert_Input = {
  data: Order_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};

/** on conflict condition type for table "order_status" */
export type Order_Status_On_Conflict = {
  constraint: Order_Status_Constraint;
  update_columns?: Array<Order_Status_Update_Column>;
  where?: Maybe<Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "order_status". */
export type Order_Status_Order_By = {
  description?: Maybe<Order_By>;
  order_items_aggregate?: Maybe<Order_Item_Aggregate_Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: order_status */
export type Order_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "order_status" */
export enum Order_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_status" */
export type Order_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "order_status" */
export enum Order_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  __typename?: 'order_stddev_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  __typename?: 'order_stddev_pop_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  __typename?: 'order_stddev_samp_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  __typename?: 'order_sum_fields';
  basic_price_payout?: Maybe<Scalars['numeric']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  price_discount?: Maybe<Scalars['numeric']>;
  price_extra?: Maybe<Scalars['numeric']>;
  price_final?: Maybe<Scalars['numeric']>;
  price_payout?: Maybe<Scalars['numeric']>;
  price_payout_discount?: Maybe<Scalars['numeric']>;
  price_payout_extra?: Maybe<Scalars['numeric']>;
  price_payout_without_vat?: Maybe<Scalars['numeric']>;
  price_product?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  BasicPricePayout = 'basic_price_payout',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Car = 'car',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DateTimeOption = 'date_time_option',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  Is_777Order = 'is_777_order',
  /** column name */
  IsAutoOrder = 'is_auto_order',
  /** column name */
  Lead = 'lead',
  /** column name */
  Name = 'name',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PartialPaidAt = 'partial_paid_at',
  /** column name */
  PaymentFailedAt = 'payment_failed_at',
  /** column name */
  PaymentLinkSentAt = 'payment_link_sent_at',
  /** column name */
  Phone = 'phone',
  /** column name */
  PriceChangedReason = 'price_changed_reason',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutDiscount = 'price_payout_discount',
  /** column name */
  PricePayoutExtra = 'price_payout_extra',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  QuoteItem = 'quote_item',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  __typename?: 'order_var_pop_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  __typename?: 'order_var_samp_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  __typename?: 'order_variance_fields';
  basic_price_payout?: Maybe<Scalars['Float']>;
  commission_rate?: Maybe<Scalars['Float']>;
  price_discount?: Maybe<Scalars['Float']>;
  price_extra?: Maybe<Scalars['Float']>;
  price_final?: Maybe<Scalars['Float']>;
  price_payout?: Maybe<Scalars['Float']>;
  price_payout_discount?: Maybe<Scalars['Float']>;
  price_payout_extra?: Maybe<Scalars['Float']>;
  price_payout_without_vat?: Maybe<Scalars['Float']>;
  price_product?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
};

/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type Package = {
  __typename?: 'package';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  add_on_product?: Maybe<Scalars['uuid']>;
  alias: Scalars['String'];
  attribute?: Maybe<Scalars['jsonb']>;
  commission_rate: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  /** A computed field, executes function "package_detail" */
  detail?: Maybe<Array<Product>>;
  id: Scalars['uuid'];
  image: Scalars['jsonb'];
  name: Scalars['String'];
  /** An object relationship */
  package_type: Package_Type;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  product: Scalars['jsonb'];
  /** An object relationship */
  productByAddOnProduct?: Maybe<Product>;
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  status: Scalars['Boolean'];
  /** An array relationship */
  store_and_packages: Array<Store_And_Package>;
  /** An aggregate relationship */
  store_and_packages_aggregate: Store_And_Package_Aggregate;
  type: Package_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  view_package_stores: Array<View_Package_Store>;
  /** An aggregate relationship */
  view_package_stores_aggregate: View_Package_Store_Aggregate;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageAttributeArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageDetailArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageImageArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackagePrice_DiscountArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackagePrice_PayoutArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackagePrice_ProductArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageProductArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageStore_And_PackagesArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageStore_And_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageView_Package_StoresArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


/**
 * General information about a package
 *
 *
 * columns and relationships of "package"
 */
export type PackageView_Package_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};

/** aggregated selection of "package" */
export type Package_Aggregate = {
  __typename?: 'package_aggregate';
  aggregate?: Maybe<Package_Aggregate_Fields>;
  nodes: Array<Package>;
};

/** aggregate fields of "package" */
export type Package_Aggregate_Fields = {
  __typename?: 'package_aggregate_fields';
  avg?: Maybe<Package_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Package_Max_Fields>;
  min?: Maybe<Package_Min_Fields>;
  stddev?: Maybe<Package_Stddev_Fields>;
  stddev_pop?: Maybe<Package_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Package_Stddev_Samp_Fields>;
  sum?: Maybe<Package_Sum_Fields>;
  var_pop?: Maybe<Package_Var_Pop_Fields>;
  var_samp?: Maybe<Package_Var_Samp_Fields>;
  variance?: Maybe<Package_Variance_Fields>;
};


/** aggregate fields of "package" */
export type Package_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Package_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "package" */
export type Package_Aggregate_Order_By = {
  avg?: Maybe<Package_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Package_Max_Order_By>;
  min?: Maybe<Package_Min_Order_By>;
  stddev?: Maybe<Package_Stddev_Order_By>;
  stddev_pop?: Maybe<Package_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Package_Stddev_Samp_Order_By>;
  sum?: Maybe<Package_Sum_Order_By>;
  var_pop?: Maybe<Package_Var_Pop_Order_By>;
  var_samp?: Maybe<Package_Var_Samp_Order_By>;
  variance?: Maybe<Package_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Package_Append_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  image?: Maybe<Scalars['jsonb']>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  product?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "package" */
export type Package_Arr_Rel_Insert_Input = {
  data: Array<Package_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Package_On_Conflict>;
};

/** aggregate avg on columns */
export type Package_Avg_Fields = {
  __typename?: 'package_avg_fields';
  commission_rate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "package" */
export type Package_Avg_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "package". All fields are combined with a logical 'AND'. */
export type Package_Bool_Exp = {
  _and?: Maybe<Array<Package_Bool_Exp>>;
  _not?: Maybe<Package_Bool_Exp>;
  _or?: Maybe<Array<Package_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  add_on_product?: Maybe<Uuid_Comparison_Exp>;
  alias?: Maybe<String_Comparison_Exp>;
  attribute?: Maybe<Jsonb_Comparison_Exp>;
  commission_rate?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  detail?: Maybe<Product_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  package_type?: Maybe<Package_Type_Bool_Exp>;
  price_discount?: Maybe<Jsonb_Comparison_Exp>;
  price_payout?: Maybe<Jsonb_Comparison_Exp>;
  price_product?: Maybe<Jsonb_Comparison_Exp>;
  product?: Maybe<Jsonb_Comparison_Exp>;
  productByAddOnProduct?: Maybe<Product_Bool_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  status?: Maybe<Boolean_Comparison_Exp>;
  store_and_packages?: Maybe<Store_And_Package_Bool_Exp>;
  type?: Maybe<Package_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  view_package_stores?: Maybe<View_Package_Store_Bool_Exp>;
};

/** unique or primary key constraints on table "package" */
export enum Package_Constraint {
  /** unique or primary key constraint */
  PackageAliasKey = 'package_alias_key',
  /** unique or primary key constraint */
  PackageIdKey = 'package_id_key',
  /** unique or primary key constraint */
  PackagePkey = 'package_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Package_Delete_At_Path_Input = {
  attribute?: Maybe<Array<Scalars['String']>>;
  image?: Maybe<Array<Scalars['String']>>;
  price_discount?: Maybe<Array<Scalars['String']>>;
  price_payout?: Maybe<Array<Scalars['String']>>;
  price_product?: Maybe<Array<Scalars['String']>>;
  product?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Package_Delete_Elem_Input = {
  attribute?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Int']>;
  price_discount?: Maybe<Scalars['Int']>;
  price_payout?: Maybe<Scalars['Int']>;
  price_product?: Maybe<Scalars['Int']>;
  product?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Package_Delete_Key_Input = {
  attribute?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  price_discount?: Maybe<Scalars['String']>;
  price_payout?: Maybe<Scalars['String']>;
  price_product?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "package" */
export type Package_Inc_Input = {
  commission_rate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "package" */
export type Package_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  add_on_product?: Maybe<Scalars['uuid']>;
  alias?: Maybe<Scalars['String']>;
  attribute?: Maybe<Scalars['jsonb']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  package_type?: Maybe<Package_Type_Obj_Rel_Insert_Input>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  product?: Maybe<Scalars['jsonb']>;
  productByAddOnProduct?: Maybe<Product_Obj_Rel_Insert_Input>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars['Boolean']>;
  store_and_packages?: Maybe<Store_And_Package_Arr_Rel_Insert_Input>;
  type?: Maybe<Package_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view_package_stores?: Maybe<View_Package_Store_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Package_Max_Fields = {
  __typename?: 'package_max_fields';
  account?: Maybe<Scalars['uuid']>;
  add_on_product?: Maybe<Scalars['uuid']>;
  alias?: Maybe<Scalars['String']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "package" */
export type Package_Max_Order_By = {
  account?: Maybe<Order_By>;
  add_on_product?: Maybe<Order_By>;
  alias?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Package_Min_Fields = {
  __typename?: 'package_min_fields';
  account?: Maybe<Scalars['uuid']>;
  add_on_product?: Maybe<Scalars['uuid']>;
  alias?: Maybe<Scalars['String']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "package" */
export type Package_Min_Order_By = {
  account?: Maybe<Order_By>;
  add_on_product?: Maybe<Order_By>;
  alias?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "package" */
export type Package_Mutation_Response = {
  __typename?: 'package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Package>;
};

/** input type for inserting object relation for remote table "package" */
export type Package_Obj_Rel_Insert_Input = {
  data: Package_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Package_On_Conflict>;
};

/** on conflict condition type for table "package" */
export type Package_On_Conflict = {
  constraint: Package_Constraint;
  update_columns?: Array<Package_Update_Column>;
  where?: Maybe<Package_Bool_Exp>;
};

/** Ordering options when selecting data from "package". */
export type Package_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  add_on_product?: Maybe<Order_By>;
  alias?: Maybe<Order_By>;
  attribute?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  detail_aggregate?: Maybe<Product_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  package_type?: Maybe<Package_Type_Order_By>;
  price_discount?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  productByAddOnProduct?: Maybe<Product_Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  store_and_packages_aggregate?: Maybe<Store_And_Package_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  view_package_stores_aggregate?: Maybe<View_Package_Store_Aggregate_Order_By>;
};

/** primary key columns input for table: package */
export type Package_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Package_Prepend_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  image?: Maybe<Scalars['jsonb']>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  product?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "package" */
export enum Package_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  AddOnProduct = 'add_on_product',
  /** column name */
  Alias = 'alias',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  Product = 'product',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "package" */
export type Package_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  add_on_product?: Maybe<Scalars['uuid']>;
  alias?: Maybe<Scalars['String']>;
  attribute?: Maybe<Scalars['jsonb']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  product?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Package_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Package_Stddev_Fields = {
  __typename?: 'package_stddev_fields';
  commission_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "package" */
export type Package_Stddev_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Package_Stddev_Pop_Fields = {
  __typename?: 'package_stddev_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "package" */
export type Package_Stddev_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Package_Stddev_Samp_Fields = {
  __typename?: 'package_stddev_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "package" */
export type Package_Stddev_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

export type Package_Store_Search_Args = {
  area?: Maybe<Scalars['String']>;
  borough?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Scalars['geography']>;
  packageid?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Package_Sum_Fields = {
  __typename?: 'package_sum_fields';
  commission_rate?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "package" */
export type Package_Sum_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

/** columns and relationships of "package_type" */
export type Package_Type = {
  __typename?: 'package_type';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  packages: Array<Package>;
  /** An aggregate relationship */
  packages_aggregate: Package_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "package_type" */
export type Package_TypePackagesArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/** columns and relationships of "package_type" */
export type Package_TypePackages_AggregateArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};

/** aggregated selection of "package_type" */
export type Package_Type_Aggregate = {
  __typename?: 'package_type_aggregate';
  aggregate?: Maybe<Package_Type_Aggregate_Fields>;
  nodes: Array<Package_Type>;
};

/** aggregate fields of "package_type" */
export type Package_Type_Aggregate_Fields = {
  __typename?: 'package_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Package_Type_Max_Fields>;
  min?: Maybe<Package_Type_Min_Fields>;
};


/** aggregate fields of "package_type" */
export type Package_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Package_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "package_type". All fields are combined with a logical 'AND'. */
export type Package_Type_Bool_Exp = {
  _and?: Maybe<Array<Package_Type_Bool_Exp>>;
  _not?: Maybe<Package_Type_Bool_Exp>;
  _or?: Maybe<Array<Package_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  packages?: Maybe<Package_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "package_type" */
export enum Package_Type_Constraint {
  /** unique or primary key constraint */
  PackageTypePkey = 'package_type_pkey'
}

export enum Package_Type_Enum {
  /** 맞춤 견적 */
  CustomPackage = 'custom_package',
  /** 신차패키지 */
  NewCarPackage = 'new_car_package'
}

/** Boolean expression to compare columns of type "package_type_enum". All fields are combined with logical 'AND'. */
export type Package_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Package_Type_Enum>;
  _in?: Maybe<Array<Package_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Package_Type_Enum>;
  _nin?: Maybe<Array<Package_Type_Enum>>;
};

/** input type for inserting data into table "package_type" */
export type Package_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  packages?: Maybe<Package_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Package_Type_Max_Fields = {
  __typename?: 'package_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Package_Type_Min_Fields = {
  __typename?: 'package_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "package_type" */
export type Package_Type_Mutation_Response = {
  __typename?: 'package_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Package_Type>;
};

/** input type for inserting object relation for remote table "package_type" */
export type Package_Type_Obj_Rel_Insert_Input = {
  data: Package_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Package_Type_On_Conflict>;
};

/** on conflict condition type for table "package_type" */
export type Package_Type_On_Conflict = {
  constraint: Package_Type_Constraint;
  update_columns?: Array<Package_Type_Update_Column>;
  where?: Maybe<Package_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "package_type". */
export type Package_Type_Order_By = {
  description?: Maybe<Order_By>;
  packages_aggregate?: Maybe<Package_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: package_type */
export type Package_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "package_type" */
export enum Package_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "package_type" */
export type Package_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "package_type" */
export enum Package_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "package" */
export enum Package_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  AddOnProduct = 'add_on_product',
  /** column name */
  Alias = 'alias',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  Product = 'product',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Package_Var_Pop_Fields = {
  __typename?: 'package_var_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "package" */
export type Package_Var_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Package_Var_Samp_Fields = {
  __typename?: 'package_var_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "package" */
export type Package_Var_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Package_Variance_Fields = {
  __typename?: 'package_variance_fields';
  commission_rate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "package" */
export type Package_Variance_Order_By = {
  commission_rate?: Maybe<Order_By>;
};

/**
 * Account payable - may need to store a transfer id or receipt
 *
 *
 * columns and relationships of "payout"
 */
export type Payout = {
  __typename?: 'payout';
  /** Account who created this row */
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  accounts_payable_and_payouts: Array<Accounts_Payable_And_Payout>;
  /** An aggregate relationship */
  accounts_payable_and_payouts_aggregate: Accounts_Payable_And_Payout_Aggregate;
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  order: Scalars['uuid'];
  /** An object relationship */
  orderByOrder: Order;
  /** An array relationship */
  payout_and_transfers: Array<Payout_And_Transfer>;
  /** An aggregate relationship */
  payout_and_transfers_aggregate: Payout_And_Transfer_Aggregate;
  /** An object relationship */
  payout_status: Payout_Status;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  status: Payout_Status_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  tax_invoice_issued_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  written_off_at?: Maybe<Scalars['timestamptz']>;
};


/**
 * Account payable - may need to store a transfer id or receipt
 *
 *
 * columns and relationships of "payout"
 */
export type PayoutAccounts_Payable_And_PayoutsArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


/**
 * Account payable - may need to store a transfer id or receipt
 *
 *
 * columns and relationships of "payout"
 */
export type PayoutAccounts_Payable_And_Payouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


/**
 * Account payable - may need to store a transfer id or receipt
 *
 *
 * columns and relationships of "payout"
 */
export type PayoutPayout_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};


/**
 * Account payable - may need to store a transfer id or receipt
 *
 *
 * columns and relationships of "payout"
 */
export type PayoutPayout_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};

/** aggregated selection of "payout" */
export type Payout_Aggregate = {
  __typename?: 'payout_aggregate';
  aggregate?: Maybe<Payout_Aggregate_Fields>;
  nodes: Array<Payout>;
};

/** aggregate fields of "payout" */
export type Payout_Aggregate_Fields = {
  __typename?: 'payout_aggregate_fields';
  avg?: Maybe<Payout_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payout_Max_Fields>;
  min?: Maybe<Payout_Min_Fields>;
  stddev?: Maybe<Payout_Stddev_Fields>;
  stddev_pop?: Maybe<Payout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payout_Stddev_Samp_Fields>;
  sum?: Maybe<Payout_Sum_Fields>;
  var_pop?: Maybe<Payout_Var_Pop_Fields>;
  var_samp?: Maybe<Payout_Var_Samp_Fields>;
  variance?: Maybe<Payout_Variance_Fields>;
};


/** aggregate fields of "payout" */
export type Payout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payout_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payout" */
export type Payout_Aggregate_Order_By = {
  avg?: Maybe<Payout_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payout_Max_Order_By>;
  min?: Maybe<Payout_Min_Order_By>;
  stddev?: Maybe<Payout_Stddev_Order_By>;
  stddev_pop?: Maybe<Payout_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payout_Stddev_Samp_Order_By>;
  sum?: Maybe<Payout_Sum_Order_By>;
  var_pop?: Maybe<Payout_Var_Pop_Order_By>;
  var_samp?: Maybe<Payout_Var_Samp_Order_By>;
  variance?: Maybe<Payout_Variance_Order_By>;
};

/** columns and relationships of "payout_and_transfer" */
export type Payout_And_Transfer = {
  __typename?: 'payout_and_transfer';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout: Scalars['uuid'];
  /** An object relationship */
  payoutByPayout: Payout;
  transfer: Scalars['uuid'];
  /** An object relationship */
  transferByTransfer: Transfer;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "payout_and_transfer" */
export type Payout_And_Transfer_Aggregate = {
  __typename?: 'payout_and_transfer_aggregate';
  aggregate?: Maybe<Payout_And_Transfer_Aggregate_Fields>;
  nodes: Array<Payout_And_Transfer>;
};

/** aggregate fields of "payout_and_transfer" */
export type Payout_And_Transfer_Aggregate_Fields = {
  __typename?: 'payout_and_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payout_And_Transfer_Max_Fields>;
  min?: Maybe<Payout_And_Transfer_Min_Fields>;
};


/** aggregate fields of "payout_and_transfer" */
export type Payout_And_Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payout_and_transfer" */
export type Payout_And_Transfer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Payout_And_Transfer_Max_Order_By>;
  min?: Maybe<Payout_And_Transfer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "payout_and_transfer" */
export type Payout_And_Transfer_Arr_Rel_Insert_Input = {
  data: Array<Payout_And_Transfer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Payout_And_Transfer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "payout_and_transfer". All fields are combined with a logical 'AND'. */
export type Payout_And_Transfer_Bool_Exp = {
  _and?: Maybe<Array<Payout_And_Transfer_Bool_Exp>>;
  _not?: Maybe<Payout_And_Transfer_Bool_Exp>;
  _or?: Maybe<Array<Payout_And_Transfer_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  payout?: Maybe<Uuid_Comparison_Exp>;
  payoutByPayout?: Maybe<Payout_Bool_Exp>;
  transfer?: Maybe<Uuid_Comparison_Exp>;
  transferByTransfer?: Maybe<Transfer_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payout_and_transfer" */
export enum Payout_And_Transfer_Constraint {
  /** unique or primary key constraint */
  TransferSettlementPkey = 'transfer_settlement_pkey'
}

/** input type for inserting data into table "payout_and_transfer" */
export type Payout_And_Transfer_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  payoutByPayout?: Maybe<Payout_Obj_Rel_Insert_Input>;
  transfer?: Maybe<Scalars['uuid']>;
  transferByTransfer?: Maybe<Transfer_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payout_And_Transfer_Max_Fields = {
  __typename?: 'payout_and_transfer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payout_and_transfer" */
export type Payout_And_Transfer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  payout?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payout_And_Transfer_Min_Fields = {
  __typename?: 'payout_and_transfer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payout_and_transfer" */
export type Payout_And_Transfer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  payout?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payout_and_transfer" */
export type Payout_And_Transfer_Mutation_Response = {
  __typename?: 'payout_and_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payout_And_Transfer>;
};

/** on conflict condition type for table "payout_and_transfer" */
export type Payout_And_Transfer_On_Conflict = {
  constraint: Payout_And_Transfer_Constraint;
  update_columns?: Array<Payout_And_Transfer_Update_Column>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "payout_and_transfer". */
export type Payout_And_Transfer_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  payout?: Maybe<Order_By>;
  payoutByPayout?: Maybe<Payout_Order_By>;
  transfer?: Maybe<Order_By>;
  transferByTransfer?: Maybe<Transfer_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: payout_and_transfer */
export type Payout_And_Transfer_Pk_Columns_Input = {
  payout: Scalars['uuid'];
  transfer: Scalars['uuid'];
};

/** select columns of table "payout_and_transfer" */
export enum Payout_And_Transfer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Payout = 'payout',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payout_and_transfer" */
export type Payout_And_Transfer_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payout?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "payout_and_transfer" */
export enum Payout_And_Transfer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Payout = 'payout',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting array relation for remote table "payout" */
export type Payout_Arr_Rel_Insert_Input = {
  data: Array<Payout_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Payout_On_Conflict>;
};

/** aggregate avg on columns */
export type Payout_Avg_Fields = {
  __typename?: 'payout_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payout" */
export type Payout_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payout". All fields are combined with a logical 'AND'. */
export type Payout_Bool_Exp = {
  _and?: Maybe<Array<Payout_Bool_Exp>>;
  _not?: Maybe<Payout_Bool_Exp>;
  _or?: Maybe<Array<Payout_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  accounts_payable_and_payouts?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Uuid_Comparison_Exp>;
  orderByOrder?: Maybe<Order_Bool_Exp>;
  payout_and_transfers?: Maybe<Payout_And_Transfer_Bool_Exp>;
  payout_status?: Maybe<Payout_Status_Bool_Exp>;
  reverted_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Payout_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  tax_invoice_issued_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  written_off_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payout" */
export enum Payout_Constraint {
  /** unique or primary key constraint */
  PayoutOrderKey = 'payout_order_key',
  /** unique or primary key constraint */
  SettlementPkey = 'settlement_pkey'
}

/** input type for incrementing numeric columns in table "payout" */
export type Payout_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "payout" */
export type Payout_Insert_Input = {
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  accounts_payable_and_payouts?: Maybe<Accounts_Payable_And_Payout_Arr_Rel_Insert_Input>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  orderByOrder?: Maybe<Order_Obj_Rel_Insert_Input>;
  payout_and_transfers?: Maybe<Payout_And_Transfer_Arr_Rel_Insert_Input>;
  payout_status?: Maybe<Payout_Status_Obj_Rel_Insert_Input>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Payout_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  tax_invoice_issued_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payout_Max_Fields = {
  __typename?: 'payout_max_fields';
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  tax_invoice_issued_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payout" */
export type Payout_Max_Order_By = {
  /** Account who created this row */
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  reverted_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  tax_invoice_issued_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  written_off_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payout_Min_Fields = {
  __typename?: 'payout_min_fields';
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  store?: Maybe<Scalars['uuid']>;
  tax_invoice_issued_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payout" */
export type Payout_Min_Order_By = {
  /** Account who created this row */
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  reverted_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  tax_invoice_issued_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  written_off_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payout" */
export type Payout_Mutation_Response = {
  __typename?: 'payout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payout>;
};

/** input type for inserting object relation for remote table "payout" */
export type Payout_Obj_Rel_Insert_Input = {
  data: Payout_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Payout_On_Conflict>;
};

/** on conflict condition type for table "payout" */
export type Payout_On_Conflict = {
  constraint: Payout_Constraint;
  update_columns?: Array<Payout_Update_Column>;
  where?: Maybe<Payout_Bool_Exp>;
};

/** Ordering options when selecting data from "payout". */
export type Payout_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  accounts_payable_and_payouts_aggregate?: Maybe<Accounts_Payable_And_Payout_Aggregate_Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  orderByOrder?: Maybe<Order_Order_By>;
  payout_and_transfers_aggregate?: Maybe<Payout_And_Transfer_Aggregate_Order_By>;
  payout_status?: Maybe<Payout_Status_Order_By>;
  reverted_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  tax_invoice_issued_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  written_off_at?: Maybe<Order_By>;
};

/** primary key columns input for table: payout */
export type Payout_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payout" */
export enum Payout_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RevertedAt = 'reverted_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  TaxInvoiceIssuedAt = 'tax_invoice_issued_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WrittenOffAt = 'written_off_at'
}

/** input type for updating data in table "payout" */
export type Payout_Set_Input = {
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reverted_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Payout_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  tax_invoice_issued_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  written_off_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "payout_status" */
export type Payout_Status = {
  __typename?: 'payout_status';
  description: Scalars['String'];
  /** An array relationship */
  payouts: Array<Payout>;
  /** An aggregate relationship */
  payouts_aggregate: Payout_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "payout_status" */
export type Payout_StatusPayoutsArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


/** columns and relationships of "payout_status" */
export type Payout_StatusPayouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};

/** aggregated selection of "payout_status" */
export type Payout_Status_Aggregate = {
  __typename?: 'payout_status_aggregate';
  aggregate?: Maybe<Payout_Status_Aggregate_Fields>;
  nodes: Array<Payout_Status>;
};

/** aggregate fields of "payout_status" */
export type Payout_Status_Aggregate_Fields = {
  __typename?: 'payout_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payout_Status_Max_Fields>;
  min?: Maybe<Payout_Status_Min_Fields>;
};


/** aggregate fields of "payout_status" */
export type Payout_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payout_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payout_status". All fields are combined with a logical 'AND'. */
export type Payout_Status_Bool_Exp = {
  _and?: Maybe<Array<Payout_Status_Bool_Exp>>;
  _not?: Maybe<Payout_Status_Bool_Exp>;
  _or?: Maybe<Array<Payout_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  payouts?: Maybe<Payout_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payout_status" */
export enum Payout_Status_Constraint {
  /** unique or primary key constraint */
  SettlementStatusPkey = 'settlement_status_pkey'
}

export enum Payout_Status_Enum {
  /** 정산건 발생 */
  Created = 'created',
  /** 정산 완료 */
  Finished = 'finished',
  /** 정산 취소 */
  Reverted = 'reverted',
  /** 세금계산서 발급 완료 */
  TaxInvoiceIssued = 'tax_invoice_issued',
  /** 부도 */
  WrittenOff = 'written_off'
}

/** Boolean expression to compare columns of type "payout_status_enum". All fields are combined with logical 'AND'. */
export type Payout_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Payout_Status_Enum>;
  _in?: Maybe<Array<Payout_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Payout_Status_Enum>;
  _nin?: Maybe<Array<Payout_Status_Enum>>;
};

/** input type for inserting data into table "payout_status" */
export type Payout_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  payouts?: Maybe<Payout_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payout_Status_Max_Fields = {
  __typename?: 'payout_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payout_Status_Min_Fields = {
  __typename?: 'payout_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payout_status" */
export type Payout_Status_Mutation_Response = {
  __typename?: 'payout_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payout_Status>;
};

/** input type for inserting object relation for remote table "payout_status" */
export type Payout_Status_Obj_Rel_Insert_Input = {
  data: Payout_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Payout_Status_On_Conflict>;
};

/** on conflict condition type for table "payout_status" */
export type Payout_Status_On_Conflict = {
  constraint: Payout_Status_Constraint;
  update_columns?: Array<Payout_Status_Update_Column>;
  where?: Maybe<Payout_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "payout_status". */
export type Payout_Status_Order_By = {
  description?: Maybe<Order_By>;
  payouts_aggregate?: Maybe<Payout_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: payout_status */
export type Payout_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "payout_status" */
export enum Payout_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payout_status" */
export type Payout_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "payout_status" */
export enum Payout_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Payout_Stddev_Fields = {
  __typename?: 'payout_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payout" */
export type Payout_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payout_Stddev_Pop_Fields = {
  __typename?: 'payout_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payout" */
export type Payout_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payout_Stddev_Samp_Fields = {
  __typename?: 'payout_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payout" */
export type Payout_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payout_Sum_Fields = {
  __typename?: 'payout_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "payout" */
export type Payout_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "payout" */
export enum Payout_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RevertedAt = 'reverted_at',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  TaxInvoiceIssuedAt = 'tax_invoice_issued_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WrittenOffAt = 'written_off_at'
}

/** aggregate var_pop on columns */
export type Payout_Var_Pop_Fields = {
  __typename?: 'payout_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payout" */
export type Payout_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payout_Var_Samp_Fields = {
  __typename?: 'payout_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payout" */
export type Payout_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payout_Variance_Fields = {
  __typename?: 'payout_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payout" */
export type Payout_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type Product = {
  __typename?: 'product';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  alias: Scalars['String'];
  attribute: Scalars['jsonb'];
  brand: Product_Brand_Enum;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  image: Scalars['jsonb'];
  name: Scalars['String'];
  /** An array relationship */
  packages: Array<Package>;
  /** An aggregate relationship */
  packages_aggregate: Package_Aggregate;
  /** An array relationship */
  product_and_likes: Array<Product_And_Like>;
  /** An aggregate relationship */
  product_and_likes_aggregate: Product_And_Like_Aggregate;
  /** An object relationship */
  product_brand: Product_Brand;
  /** An array relationship */
  product_items: Array<Product_Item>;
  /** An aggregate relationship */
  product_items_aggregate: Product_Item_Aggregate;
  /** An object relationship */
  product_type: Product_Type;
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  status: Scalars['Boolean'];
  /** An array relationship */
  store_and_products: Array<Store_And_Product>;
  /** An aggregate relationship */
  store_and_products_aggregate: Store_And_Product_Aggregate;
  type: Product_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductAttributeArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductImageArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductPackagesArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductPackages_AggregateArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductProduct_And_LikesArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductProduct_And_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductProduct_ItemsArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductProduct_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductStore_And_ProductsArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


/**
 * General information about a product
 *
 *
 * columns and relationships of "product"
 */
export type ProductStore_And_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Max_Order_By>;
  min?: Maybe<Product_Min_Order_By>;
};

/**
 * Like of the product - Relationship with product, like
 *
 *
 * columns and relationships of "product_and_like"
 */
export type Product_And_Like = {
  __typename?: 'product_and_like';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like: Scalars['uuid'];
  /** An object relationship */
  likeByLike: Like;
  product: Scalars['uuid'];
  /** An object relationship */
  productByProduct: Product;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "product_and_like" */
export type Product_And_Like_Aggregate = {
  __typename?: 'product_and_like_aggregate';
  aggregate?: Maybe<Product_And_Like_Aggregate_Fields>;
  nodes: Array<Product_And_Like>;
};

/** aggregate fields of "product_and_like" */
export type Product_And_Like_Aggregate_Fields = {
  __typename?: 'product_and_like_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_And_Like_Max_Fields>;
  min?: Maybe<Product_And_Like_Min_Fields>;
};


/** aggregate fields of "product_and_like" */
export type Product_And_Like_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_And_Like_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_and_like" */
export type Product_And_Like_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Product_And_Like_Max_Order_By>;
  min?: Maybe<Product_And_Like_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_and_like" */
export type Product_And_Like_Arr_Rel_Insert_Input = {
  data: Array<Product_And_Like_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Product_And_Like_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_and_like". All fields are combined with a logical 'AND'. */
export type Product_And_Like_Bool_Exp = {
  _and?: Maybe<Array<Product_And_Like_Bool_Exp>>;
  _not?: Maybe<Product_And_Like_Bool_Exp>;
  _or?: Maybe<Array<Product_And_Like_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  like?: Maybe<Uuid_Comparison_Exp>;
  likeByLike?: Maybe<Like_Bool_Exp>;
  product?: Maybe<Uuid_Comparison_Exp>;
  productByProduct?: Maybe<Product_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_and_like" */
export enum Product_And_Like_Constraint {
  /** unique or primary key constraint */
  ProductLikePkey = 'product_like_pkey'
}

/** input type for inserting data into table "product_and_like" */
export type Product_And_Like_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  likeByLike?: Maybe<Like_Obj_Rel_Insert_Input>;
  product?: Maybe<Scalars['uuid']>;
  productByProduct?: Maybe<Product_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_And_Like_Max_Fields = {
  __typename?: 'product_and_like_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  product?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_and_like" */
export type Product_And_Like_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  like?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_And_Like_Min_Fields = {
  __typename?: 'product_and_like_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  product?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_and_like" */
export type Product_And_Like_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  like?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_and_like" */
export type Product_And_Like_Mutation_Response = {
  __typename?: 'product_and_like_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_And_Like>;
};

/** on conflict condition type for table "product_and_like" */
export type Product_And_Like_On_Conflict = {
  constraint: Product_And_Like_Constraint;
  update_columns?: Array<Product_And_Like_Update_Column>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};

/** Ordering options when selecting data from "product_and_like". */
export type Product_And_Like_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  like?: Maybe<Order_By>;
  likeByLike?: Maybe<Like_Order_By>;
  product?: Maybe<Order_By>;
  productByProduct?: Maybe<Product_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: product_and_like */
export type Product_And_Like_Pk_Columns_Input = {
  like: Scalars['uuid'];
  product: Scalars['uuid'];
};

/** select columns of table "product_and_like" */
export enum Product_And_Like_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Like = 'like',
  /** column name */
  Product = 'product',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_and_like" */
export type Product_And_Like_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  product?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "product_and_like" */
export enum Product_And_Like_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Like = 'like',
  /** column name */
  Product = 'product',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Append_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  image?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: Maybe<Array<Product_Bool_Exp>>;
  _not?: Maybe<Product_Bool_Exp>;
  _or?: Maybe<Array<Product_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  alias?: Maybe<String_Comparison_Exp>;
  attribute?: Maybe<Jsonb_Comparison_Exp>;
  brand?: Maybe<Product_Brand_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  packages?: Maybe<Package_Bool_Exp>;
  product_and_likes?: Maybe<Product_And_Like_Bool_Exp>;
  product_brand?: Maybe<Product_Brand_Bool_Exp>;
  product_items?: Maybe<Product_Item_Bool_Exp>;
  product_type?: Maybe<Product_Type_Bool_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  status?: Maybe<Boolean_Comparison_Exp>;
  store_and_products?: Maybe<Store_And_Product_Bool_Exp>;
  type?: Maybe<Product_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "product_brand" */
export type Product_Brand = {
  __typename?: 'product_brand';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "product_brand" */
export type Product_BrandProductsArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** columns and relationships of "product_brand" */
export type Product_BrandProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** aggregated selection of "product_brand" */
export type Product_Brand_Aggregate = {
  __typename?: 'product_brand_aggregate';
  aggregate?: Maybe<Product_Brand_Aggregate_Fields>;
  nodes: Array<Product_Brand>;
};

/** aggregate fields of "product_brand" */
export type Product_Brand_Aggregate_Fields = {
  __typename?: 'product_brand_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Brand_Max_Fields>;
  min?: Maybe<Product_Brand_Min_Fields>;
};


/** aggregate fields of "product_brand" */
export type Product_Brand_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Brand_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_brand". All fields are combined with a logical 'AND'. */
export type Product_Brand_Bool_Exp = {
  _and?: Maybe<Array<Product_Brand_Bool_Exp>>;
  _not?: Maybe<Product_Brand_Bool_Exp>;
  _or?: Maybe<Array<Product_Brand_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Product_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_brand" */
export enum Product_Brand_Constraint {
  /** unique or primary key constraint */
  BrandPkey = 'brand_pkey'
}

export enum Product_Brand_Enum {
  /** 카라모라 */
  Caramora = 'caramora',
  /** 에코파워팩 */
  Ecopowerpack = 'ecopowerpack',
  /** 일렉트로포스 */
  Electroforce = 'electroforce',
  /** 파인뷰 */
  Finevu = 'finevu',
  /** 아이나비 */
  Inavi = 'inavi',
  /** 루마 */
  Llumar = 'llumar',
  /** 마이딘 */
  Mydean = 'mydean',
  /** 신차 패키지 차종별 추가 금액 */
  NewCarPackageAddOnCarType = 'new_car_package_add_on_car_type',
  /** 신차 패키지 매장별 추가 금액 */
  NewCarPackageAddOnStore = 'new_car_package_add_on_store',
  /** 신차 패키지 신차검수 */
  NewCarPackageInspection = 'new_car_package_inspection',
  /** 기본 */
  NoBrand = 'no_brand',
  /** 큐피온 */
  Qpon = 'qpon',
  /** 레인보우 */
  Rainbow = 'rainbow',
  /** 레이노 */
  Rayno = 'rayno',
  /** 솔라가드 */
  Solargard = 'solargard',
  /** 솔라가드 노블레스 */
  SolargardNoblesse = 'solargard_noblesse',
  /** 솔라가드 프리미엄 */
  SolargardPremium = 'solargard_premium',
  /** 소낙스 */
  Sonax = 'sonax',
  /** 테크쉴드 */
  Techshield = 'techshield',
  /** 브이쿨 */
  VKool = 'v_kool',
  /** 버텍스 */
  Vertex = 'vertex',
  /** 뷰게라 */
  Vugera = 'vugera'
}

/** Boolean expression to compare columns of type "product_brand_enum". All fields are combined with logical 'AND'. */
export type Product_Brand_Enum_Comparison_Exp = {
  _eq?: Maybe<Product_Brand_Enum>;
  _in?: Maybe<Array<Product_Brand_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Product_Brand_Enum>;
  _nin?: Maybe<Array<Product_Brand_Enum>>;
};

/** input type for inserting data into table "product_brand" */
export type Product_Brand_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  products?: Maybe<Product_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Brand_Max_Fields = {
  __typename?: 'product_brand_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Brand_Min_Fields = {
  __typename?: 'product_brand_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "product_brand" */
export type Product_Brand_Mutation_Response = {
  __typename?: 'product_brand_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Brand>;
};

/** input type for inserting object relation for remote table "product_brand" */
export type Product_Brand_Obj_Rel_Insert_Input = {
  data: Product_Brand_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Product_Brand_On_Conflict>;
};

/** on conflict condition type for table "product_brand" */
export type Product_Brand_On_Conflict = {
  constraint: Product_Brand_Constraint;
  update_columns?: Array<Product_Brand_Update_Column>;
  where?: Maybe<Product_Brand_Bool_Exp>;
};

/** Ordering options when selecting data from "product_brand". */
export type Product_Brand_Order_By = {
  description?: Maybe<Order_By>;
  products_aggregate?: Maybe<Product_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: product_brand */
export type Product_Brand_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "product_brand" */
export enum Product_Brand_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_brand" */
export type Product_Brand_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "product_brand" */
export enum Product_Brand_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint */
  ProductPkey = 'product_pkey',
  /** unique or primary key constraint */
  ProductSkuKey = 'product_sku_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Delete_At_Path_Input = {
  attribute?: Maybe<Array<Scalars['String']>>;
  image?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Delete_Elem_Input = {
  attribute?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Delete_Key_Input = {
  attribute?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  alias?: Maybe<Scalars['String']>;
  attribute?: Maybe<Scalars['jsonb']>;
  brand?: Maybe<Product_Brand_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  packages?: Maybe<Package_Arr_Rel_Insert_Input>;
  product_and_likes?: Maybe<Product_And_Like_Arr_Rel_Insert_Input>;
  product_brand?: Maybe<Product_Brand_Obj_Rel_Insert_Input>;
  product_items?: Maybe<Product_Item_Arr_Rel_Insert_Input>;
  product_type?: Maybe<Product_Type_Obj_Rel_Insert_Input>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars['Boolean']>;
  store_and_products?: Maybe<Store_And_Product_Arr_Rel_Insert_Input>;
  type?: Maybe<Product_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_Item = {
  __typename?: 'product_item';
  attribute: Scalars['jsonb'];
  commission_rate: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  inventories: Array<Inventory>;
  /** An aggregate relationship */
  inventories_aggregate: Inventory_Aggregate;
  price: Scalars['numeric'];
  product: Scalars['uuid'];
  /** An object relationship */
  productByProduct: Product;
  /** An array relationship */
  product_item_and_reviews: Array<Product_Item_And_Review>;
  /** An aggregate relationship */
  product_item_and_reviews_aggregate: Product_Item_And_Review_Aggregate;
  /** An array relationship */
  product_item_price_by_stores: Array<Product_Item_Price_By_Store>;
  /** An aggregate relationship */
  product_item_price_by_stores_aggregate: Product_Item_Price_By_Store_Aggregate;
  /** An array relationship */
  promotions: Array<Promotion>;
  /** An aggregate relationship */
  promotions_aggregate: Promotion_Aggregate;
  /** Must be human readable */
  sku: Scalars['String'];
  /** An array relationship */
  slots: Array<Slot>;
  /** An array relationship */
  slotsByTintingProductItemSideback: Array<Slot>;
  /** An aggregate relationship */
  slotsByTintingProductItemSideback_aggregate: Slot_Aggregate;
  /** An array relationship */
  slotsByTintingProductItemSunroof: Array<Slot>;
  /** An aggregate relationship */
  slotsByTintingProductItemSunroof_aggregate: Slot_Aggregate;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  /** An array relationship */
  store_and_packages: Array<Store_And_Package>;
  /** An aggregate relationship */
  store_and_packages_aggregate: Store_And_Package_Aggregate;
  /** An array relationship */
  store_and_product_items: Array<Store_And_Product_Item>;
  /** An aggregate relationship */
  store_and_product_items_aggregate: Store_And_Product_Item_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  view_package_stores: Array<View_Package_Store>;
  /** An aggregate relationship */
  view_package_stores_aggregate: View_Package_Store_Aggregate;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemAttributeArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemInventoriesArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemInventories_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemProduct_Item_And_ReviewsArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemProduct_Item_And_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemProduct_Item_Price_By_StoresArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemProduct_Item_Price_By_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemPromotionsArgs = {
  distinct_on?: Maybe<Array<Promotion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotion_Order_By>>;
  where?: Maybe<Promotion_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemPromotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Promotion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotion_Order_By>>;
  where?: Maybe<Promotion_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemSlotsByTintingProductItemSidebackArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemSlotsByTintingProductItemSideback_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemSlotsByTintingProductItemSunroofArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemSlotsByTintingProductItemSunroof_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemStore_And_PackagesArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemStore_And_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemStore_And_Product_ItemsArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemStore_And_Product_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemView_Package_StoresArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


/**
 * Specific Product information
 *
 *
 * columns and relationships of "product_item"
 */
export type Product_ItemView_Package_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};

/** aggregated selection of "product_item" */
export type Product_Item_Aggregate = {
  __typename?: 'product_item_aggregate';
  aggregate?: Maybe<Product_Item_Aggregate_Fields>;
  nodes: Array<Product_Item>;
};

/** aggregate fields of "product_item" */
export type Product_Item_Aggregate_Fields = {
  __typename?: 'product_item_aggregate_fields';
  avg?: Maybe<Product_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Item_Max_Fields>;
  min?: Maybe<Product_Item_Min_Fields>;
  stddev?: Maybe<Product_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Item_Sum_Fields>;
  var_pop?: Maybe<Product_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Item_Var_Samp_Fields>;
  variance?: Maybe<Product_Item_Variance_Fields>;
};


/** aggregate fields of "product_item" */
export type Product_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_item" */
export type Product_Item_Aggregate_Order_By = {
  avg?: Maybe<Product_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Item_Max_Order_By>;
  min?: Maybe<Product_Item_Min_Order_By>;
  stddev?: Maybe<Product_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Item_Sum_Order_By>;
  var_pop?: Maybe<Product_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Item_Var_Samp_Order_By>;
  variance?: Maybe<Product_Item_Variance_Order_By>;
};

/** columns and relationships of "product_item_and_review" */
export type Product_Item_And_Review = {
  __typename?: 'product_item_and_review';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  productItemByProductItem: Product_Item;
  product_item: Scalars['uuid'];
  review: Scalars['uuid'];
  /** An object relationship */
  reviewByReview: Review;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "product_item_and_review" */
export type Product_Item_And_Review_Aggregate = {
  __typename?: 'product_item_and_review_aggregate';
  aggregate?: Maybe<Product_Item_And_Review_Aggregate_Fields>;
  nodes: Array<Product_Item_And_Review>;
};

/** aggregate fields of "product_item_and_review" */
export type Product_Item_And_Review_Aggregate_Fields = {
  __typename?: 'product_item_and_review_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Item_And_Review_Max_Fields>;
  min?: Maybe<Product_Item_And_Review_Min_Fields>;
};


/** aggregate fields of "product_item_and_review" */
export type Product_Item_And_Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_item_and_review" */
export type Product_Item_And_Review_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Item_And_Review_Max_Order_By>;
  min?: Maybe<Product_Item_And_Review_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_item_and_review" */
export type Product_Item_And_Review_Arr_Rel_Insert_Input = {
  data: Array<Product_Item_And_Review_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Product_Item_And_Review_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_item_and_review". All fields are combined with a logical 'AND'. */
export type Product_Item_And_Review_Bool_Exp = {
  _and?: Maybe<Array<Product_Item_And_Review_Bool_Exp>>;
  _not?: Maybe<Product_Item_And_Review_Bool_Exp>;
  _or?: Maybe<Array<Product_Item_And_Review_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  productItemByProductItem?: Maybe<Product_Item_Bool_Exp>;
  product_item?: Maybe<Uuid_Comparison_Exp>;
  review?: Maybe<Uuid_Comparison_Exp>;
  reviewByReview?: Maybe<Review_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_item_and_review" */
export enum Product_Item_And_Review_Constraint {
  /** unique or primary key constraint */
  ProductItemAndReviewPkey = 'product_item_and_review_pkey'
}

/** input type for inserting data into table "product_item_and_review" */
export type Product_Item_And_Review_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  productItemByProductItem?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  product_item?: Maybe<Scalars['uuid']>;
  review?: Maybe<Scalars['uuid']>;
  reviewByReview?: Maybe<Review_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_Item_And_Review_Max_Fields = {
  __typename?: 'product_item_and_review_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  product_item?: Maybe<Scalars['uuid']>;
  review?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_item_and_review" */
export type Product_Item_And_Review_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Item_And_Review_Min_Fields = {
  __typename?: 'product_item_and_review_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  product_item?: Maybe<Scalars['uuid']>;
  review?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_item_and_review" */
export type Product_Item_And_Review_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_item_and_review" */
export type Product_Item_And_Review_Mutation_Response = {
  __typename?: 'product_item_and_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Item_And_Review>;
};

/** on conflict condition type for table "product_item_and_review" */
export type Product_Item_And_Review_On_Conflict = {
  constraint: Product_Item_And_Review_Constraint;
  update_columns?: Array<Product_Item_And_Review_Update_Column>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "product_item_and_review". */
export type Product_Item_And_Review_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  productItemByProductItem?: Maybe<Product_Item_Order_By>;
  product_item?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  reviewByReview?: Maybe<Review_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: product_item_and_review */
export type Product_Item_And_Review_Pk_Columns_Input = {
  product_item: Scalars['uuid'];
  review: Scalars['uuid'];
};

/** select columns of table "product_item_and_review" */
export enum Product_Item_And_Review_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Review = 'review',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_item_and_review" */
export type Product_Item_And_Review_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  product_item?: Maybe<Scalars['uuid']>;
  review?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "product_item_and_review" */
export enum Product_Item_And_Review_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Review = 'review',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Item_Append_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product_item" */
export type Product_Item_Arr_Rel_Insert_Input = {
  data: Array<Product_Item_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Product_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Item_Avg_Fields = {
  __typename?: 'product_item_avg_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_item" */
export type Product_Item_Avg_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_item". All fields are combined with a logical 'AND'. */
export type Product_Item_Bool_Exp = {
  _and?: Maybe<Array<Product_Item_Bool_Exp>>;
  _not?: Maybe<Product_Item_Bool_Exp>;
  _or?: Maybe<Array<Product_Item_Bool_Exp>>;
  attribute?: Maybe<Jsonb_Comparison_Exp>;
  commission_rate?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  inventories?: Maybe<Inventory_Bool_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  product?: Maybe<Uuid_Comparison_Exp>;
  productByProduct?: Maybe<Product_Bool_Exp>;
  product_item_and_reviews?: Maybe<Product_Item_And_Review_Bool_Exp>;
  product_item_price_by_stores?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
  promotions?: Maybe<Promotion_Bool_Exp>;
  sku?: Maybe<String_Comparison_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  slotsByTintingProductItemSideback?: Maybe<Slot_Bool_Exp>;
  slotsByTintingProductItemSunroof?: Maybe<Slot_Bool_Exp>;
  store_and_packages?: Maybe<Store_And_Package_Bool_Exp>;
  store_and_product_items?: Maybe<Store_And_Product_Item_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  view_package_stores?: Maybe<View_Package_Store_Bool_Exp>;
};

/** unique or primary key constraints on table "product_item" */
export enum Product_Item_Constraint {
  /** unique or primary key constraint */
  ProductItemPkey = 'product_item_pkey',
  /** unique or primary key constraint */
  ProductItemSkuKey = 'product_item_sku_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Item_Delete_At_Path_Input = {
  attribute?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Item_Delete_Elem_Input = {
  attribute?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Item_Delete_Key_Input = {
  attribute?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "product_item" */
export type Product_Item_Inc_Input = {
  commission_rate?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "product_item" */
export type Product_Item_Insert_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inventories?: Maybe<Inventory_Arr_Rel_Insert_Input>;
  price?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  productByProduct?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_item_and_reviews?: Maybe<Product_Item_And_Review_Arr_Rel_Insert_Input>;
  product_item_price_by_stores?: Maybe<Product_Item_Price_By_Store_Arr_Rel_Insert_Input>;
  promotions?: Maybe<Promotion_Arr_Rel_Insert_Input>;
  /** Must be human readable */
  sku?: Maybe<Scalars['String']>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  slotsByTintingProductItemSideback?: Maybe<Slot_Arr_Rel_Insert_Input>;
  slotsByTintingProductItemSunroof?: Maybe<Slot_Arr_Rel_Insert_Input>;
  store_and_packages?: Maybe<Store_And_Package_Arr_Rel_Insert_Input>;
  store_and_product_items?: Maybe<Store_And_Product_Item_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view_package_stores?: Maybe<View_Package_Store_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Item_Max_Fields = {
  __typename?: 'product_item_max_fields';
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  /** Must be human readable */
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_item" */
export type Product_Item_Max_Order_By = {
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  /** Must be human readable */
  sku?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Item_Min_Fields = {
  __typename?: 'product_item_min_fields';
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  /** Must be human readable */
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_item" */
export type Product_Item_Min_Order_By = {
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  /** Must be human readable */
  sku?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_item" */
export type Product_Item_Mutation_Response = {
  __typename?: 'product_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Item>;
};

/** input type for inserting object relation for remote table "product_item" */
export type Product_Item_Obj_Rel_Insert_Input = {
  data: Product_Item_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Product_Item_On_Conflict>;
};

/** on conflict condition type for table "product_item" */
export type Product_Item_On_Conflict = {
  constraint: Product_Item_Constraint;
  update_columns?: Array<Product_Item_Update_Column>;
  where?: Maybe<Product_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "product_item". */
export type Product_Item_Order_By = {
  attribute?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inventories_aggregate?: Maybe<Inventory_Aggregate_Order_By>;
  price?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  productByProduct?: Maybe<Product_Order_By>;
  product_item_and_reviews_aggregate?: Maybe<Product_Item_And_Review_Aggregate_Order_By>;
  product_item_price_by_stores_aggregate?: Maybe<Product_Item_Price_By_Store_Aggregate_Order_By>;
  promotions_aggregate?: Maybe<Promotion_Aggregate_Order_By>;
  sku?: Maybe<Order_By>;
  slotsByTintingProductItemSideback_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  slotsByTintingProductItemSunroof_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  store_and_packages_aggregate?: Maybe<Store_And_Package_Aggregate_Order_By>;
  store_and_product_items_aggregate?: Maybe<Store_And_Product_Item_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  view_package_stores_aggregate?: Maybe<View_Package_Store_Aggregate_Order_By>;
};

/** primary key columns input for table: product_item */
export type Product_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Item_Prepend_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "product_item_price_by_store" */
export type Product_Item_Price_By_Store = {
  __typename?: 'product_item_price_by_store';
  created_at: Scalars['timestamp'];
  default_prices: Scalars['jsonb'];
  id: Scalars['uuid'];
  prices: Scalars['jsonb'];
  /** An object relationship */
  product_item: Product_Item;
  product_item_id: Scalars['uuid'];
  /** An object relationship */
  store: Store;
  store_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "product_item_price_by_store" */
export type Product_Item_Price_By_StoreDefault_PricesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "product_item_price_by_store" */
export type Product_Item_Price_By_StorePricesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Aggregate = {
  __typename?: 'product_item_price_by_store_aggregate';
  aggregate?: Maybe<Product_Item_Price_By_Store_Aggregate_Fields>;
  nodes: Array<Product_Item_Price_By_Store>;
};

/** aggregate fields of "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Aggregate_Fields = {
  __typename?: 'product_item_price_by_store_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Item_Price_By_Store_Max_Fields>;
  min?: Maybe<Product_Item_Price_By_Store_Min_Fields>;
};


/** aggregate fields of "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Item_Price_By_Store_Max_Order_By>;
  min?: Maybe<Product_Item_Price_By_Store_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Item_Price_By_Store_Append_Input = {
  default_prices?: Maybe<Scalars['jsonb']>;
  prices?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Arr_Rel_Insert_Input = {
  data: Array<Product_Item_Price_By_Store_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Product_Item_Price_By_Store_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_item_price_by_store". All fields are combined with a logical 'AND'. */
export type Product_Item_Price_By_Store_Bool_Exp = {
  _and?: Maybe<Array<Product_Item_Price_By_Store_Bool_Exp>>;
  _not?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
  _or?: Maybe<Array<Product_Item_Price_By_Store_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  default_prices?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  prices?: Maybe<Jsonb_Comparison_Exp>;
  product_item?: Maybe<Product_Item_Bool_Exp>;
  product_item_id?: Maybe<Uuid_Comparison_Exp>;
  store?: Maybe<Store_Bool_Exp>;
  store_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_item_price_by_store" */
export enum Product_Item_Price_By_Store_Constraint {
  /** unique or primary key constraint */
  PricePkey = 'price_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Item_Price_By_Store_Delete_At_Path_Input = {
  default_prices?: Maybe<Array<Scalars['String']>>;
  prices?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Item_Price_By_Store_Delete_Elem_Input = {
  default_prices?: Maybe<Scalars['Int']>;
  prices?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Item_Price_By_Store_Delete_Key_Input = {
  default_prices?: Maybe<Scalars['String']>;
  prices?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  default_prices?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  prices?: Maybe<Scalars['jsonb']>;
  product_item?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  product_item_id?: Maybe<Scalars['uuid']>;
  store?: Maybe<Store_Obj_Rel_Insert_Input>;
  store_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_Item_Price_By_Store_Max_Fields = {
  __typename?: 'product_item_price_by_store_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  product_item_id?: Maybe<Scalars['uuid']>;
  store_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_item_id?: Maybe<Order_By>;
  store_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Item_Price_By_Store_Min_Fields = {
  __typename?: 'product_item_price_by_store_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  product_item_id?: Maybe<Scalars['uuid']>;
  store_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_item_id?: Maybe<Order_By>;
  store_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Mutation_Response = {
  __typename?: 'product_item_price_by_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Item_Price_By_Store>;
};

/** on conflict condition type for table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_On_Conflict = {
  constraint: Product_Item_Price_By_Store_Constraint;
  update_columns?: Array<Product_Item_Price_By_Store_Update_Column>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "product_item_price_by_store". */
export type Product_Item_Price_By_Store_Order_By = {
  created_at?: Maybe<Order_By>;
  default_prices?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  prices?: Maybe<Order_By>;
  product_item?: Maybe<Product_Item_Order_By>;
  product_item_id?: Maybe<Order_By>;
  store?: Maybe<Store_Order_By>;
  store_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: product_item_price_by_store */
export type Product_Item_Price_By_Store_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Item_Price_By_Store_Prepend_Input = {
  default_prices?: Maybe<Scalars['jsonb']>;
  prices?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "product_item_price_by_store" */
export enum Product_Item_Price_By_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultPrices = 'default_prices',
  /** column name */
  Id = 'id',
  /** column name */
  Prices = 'prices',
  /** column name */
  ProductItemId = 'product_item_id',
  /** column name */
  StoreId = 'store_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_item_price_by_store" */
export type Product_Item_Price_By_Store_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  default_prices?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  prices?: Maybe<Scalars['jsonb']>;
  product_item_id?: Maybe<Scalars['uuid']>;
  store_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "product_item_price_by_store" */
export enum Product_Item_Price_By_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultPrices = 'default_prices',
  /** column name */
  Id = 'id',
  /** column name */
  Prices = 'prices',
  /** column name */
  ProductItemId = 'product_item_id',
  /** column name */
  StoreId = 'store_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select columns of table "product_item" */
export enum Product_Item_Select_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Product = 'product',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_item" */
export type Product_Item_Set_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  /** Must be human readable */
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Product_Item_Stddev_Fields = {
  __typename?: 'product_item_stddev_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_item" */
export type Product_Item_Stddev_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Item_Stddev_Pop_Fields = {
  __typename?: 'product_item_stddev_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_item" */
export type Product_Item_Stddev_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Item_Stddev_Samp_Fields = {
  __typename?: 'product_item_stddev_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_item" */
export type Product_Item_Stddev_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Item_Sum_Fields = {
  __typename?: 'product_item_sum_fields';
  commission_rate?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "product_item" */
export type Product_Item_Sum_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** update columns of table "product_item" */
export enum Product_Item_Update_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Product = 'product',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Product_Item_Var_Pop_Fields = {
  __typename?: 'product_item_var_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_item" */
export type Product_Item_Var_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Item_Var_Samp_Fields = {
  __typename?: 'product_item_var_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_item" */
export type Product_Item_Var_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Item_Variance_Fields = {
  __typename?: 'product_item_variance_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_item" */
export type Product_Item_Variance_Order_By = {
  commission_rate?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  account?: Maybe<Scalars['uuid']>;
  alias?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  account?: Maybe<Order_By>;
  alias?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  account?: Maybe<Scalars['uuid']>;
  alias?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  account?: Maybe<Order_By>;
  alias?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** on conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: Maybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  alias?: Maybe<Order_By>;
  attribute?: Maybe<Order_By>;
  brand?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  packages_aggregate?: Maybe<Package_Aggregate_Order_By>;
  product_and_likes_aggregate?: Maybe<Product_And_Like_Aggregate_Order_By>;
  product_brand?: Maybe<Product_Brand_Order_By>;
  product_items_aggregate?: Maybe<Product_Item_Aggregate_Order_By>;
  product_type?: Maybe<Product_Type_Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  store_and_products_aggregate?: Maybe<Store_And_Product_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Prepend_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  image?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Alias = 'alias',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  alias?: Maybe<Scalars['String']>;
  attribute?: Maybe<Scalars['jsonb']>;
  brand?: Maybe<Product_Brand_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Product_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/**
 * Category of product item
 *
 *
 * columns and relationships of "product_type"
 */
export type Product_Type = {
  __typename?: 'product_type';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  value: Scalars['String'];
};


/**
 * Category of product item
 *
 *
 * columns and relationships of "product_type"
 */
export type Product_TypeProductsArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/**
 * Category of product item
 *
 *
 * columns and relationships of "product_type"
 */
export type Product_TypeProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** aggregated selection of "product_type" */
export type Product_Type_Aggregate = {
  __typename?: 'product_type_aggregate';
  aggregate?: Maybe<Product_Type_Aggregate_Fields>;
  nodes: Array<Product_Type>;
};

/** aggregate fields of "product_type" */
export type Product_Type_Aggregate_Fields = {
  __typename?: 'product_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Type_Max_Fields>;
  min?: Maybe<Product_Type_Min_Fields>;
};


/** aggregate fields of "product_type" */
export type Product_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_type". All fields are combined with a logical 'AND'. */
export type Product_Type_Bool_Exp = {
  _and?: Maybe<Array<Product_Type_Bool_Exp>>;
  _not?: Maybe<Product_Type_Bool_Exp>;
  _or?: Maybe<Array<Product_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Product_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_type" */
export enum Product_Type_Constraint {
  /** unique or primary key constraint */
  ProductTypePkey = 'product_type_pkey'
}

export enum Product_Type_Enum {
  /** 추가 금액 */
  AddOn = 'add_on',
  /** 애프터블로우 */
  AfterBlow = 'after_blow',
  /** 보조배터리 */
  AuxiliaryBattery = 'auxiliary_battery',
  /** 카모 키트 */
  CamoKit = 'camo_kit',
  /** 유리막 코팅 */
  CeramicCoating = 'ceramic_coating',
  /** 커넥티드 */
  Connected = 'connected',
  /** 블랙박스 */
  Dashcam = 'dashcam',
  /** 배송비 */
  DeliveryFee = 'delivery_fee',
  /** 필름제거 */
  FilmRemove = 'film_remove',
  /** 하이패스 */
  Hipass = 'hipass',
  /** 가죽코팅 */
  LeatherCoating = 'leather_coating',
  /** 신차검수 */
  NewCarInspection = 'new_car_inspection',
  /** PPF 필름 */
  Ppf = 'ppf',
  /** 방음 */
  Soundproofing = 'soundproofing',
  /** 썬팅 */
  Tinting = 'tinting',
  /** 언더코팅 */
  Undercoating = 'undercoating'
}

/** Boolean expression to compare columns of type "product_type_enum". All fields are combined with logical 'AND'. */
export type Product_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Product_Type_Enum>;
  _in?: Maybe<Array<Product_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Product_Type_Enum>;
  _nin?: Maybe<Array<Product_Type_Enum>>;
};

/** input type for inserting data into table "product_type" */
export type Product_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  products?: Maybe<Product_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Type_Max_Fields = {
  __typename?: 'product_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Type_Min_Fields = {
  __typename?: 'product_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "product_type" */
export type Product_Type_Mutation_Response = {
  __typename?: 'product_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Type>;
};

/** input type for inserting object relation for remote table "product_type" */
export type Product_Type_Obj_Rel_Insert_Input = {
  data: Product_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Product_Type_On_Conflict>;
};

/** on conflict condition type for table "product_type" */
export type Product_Type_On_Conflict = {
  constraint: Product_Type_Constraint;
  update_columns?: Array<Product_Type_Update_Column>;
  where?: Maybe<Product_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "product_type". */
export type Product_Type_Order_By = {
  description?: Maybe<Order_By>;
  products_aggregate?: Maybe<Product_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: product_type */
export type Product_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "product_type" */
export enum Product_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_type" */
export type Product_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "product_type" */
export enum Product_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Alias = 'alias',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Korea customer profile
 *
 *
 * columns and relationships of "profile_kr_customer"
 */
export type Profile_Kr_Customer = {
  __typename?: 'profile_kr_customer';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  birthday?: Maybe<Scalars['date']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  language: Profile_Language_Enum;
  name: Scalars['String'];
  no_of_chat: Scalars['Int'];
  no_of_dispute: Scalars['Int'];
  no_of_purchase: Scalars['Int'];
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile_language: Profile_Language;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  sum_of_purchase: Scalars['Int'];
  sum_of_refund: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_kr_customer" */
export type Profile_Kr_Customer_Aggregate = {
  __typename?: 'profile_kr_customer_aggregate';
  aggregate?: Maybe<Profile_Kr_Customer_Aggregate_Fields>;
  nodes: Array<Profile_Kr_Customer>;
};

/** aggregate fields of "profile_kr_customer" */
export type Profile_Kr_Customer_Aggregate_Fields = {
  __typename?: 'profile_kr_customer_aggregate_fields';
  avg?: Maybe<Profile_Kr_Customer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Kr_Customer_Max_Fields>;
  min?: Maybe<Profile_Kr_Customer_Min_Fields>;
  stddev?: Maybe<Profile_Kr_Customer_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Kr_Customer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Kr_Customer_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Kr_Customer_Sum_Fields>;
  var_pop?: Maybe<Profile_Kr_Customer_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Kr_Customer_Var_Samp_Fields>;
  variance?: Maybe<Profile_Kr_Customer_Variance_Fields>;
};


/** aggregate fields of "profile_kr_customer" */
export type Profile_Kr_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_kr_customer" */
export type Profile_Kr_Customer_Aggregate_Order_By = {
  avg?: Maybe<Profile_Kr_Customer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Kr_Customer_Max_Order_By>;
  min?: Maybe<Profile_Kr_Customer_Min_Order_By>;
  stddev?: Maybe<Profile_Kr_Customer_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Kr_Customer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Kr_Customer_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Kr_Customer_Sum_Order_By>;
  var_pop?: Maybe<Profile_Kr_Customer_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Kr_Customer_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Kr_Customer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profile_kr_customer" */
export type Profile_Kr_Customer_Arr_Rel_Insert_Input = {
  data: Array<Profile_Kr_Customer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Kr_Customer_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Kr_Customer_Avg_Fields = {
  __typename?: 'profile_kr_customer_avg_fields';
  no_of_chat?: Maybe<Scalars['Float']>;
  no_of_dispute?: Maybe<Scalars['Float']>;
  no_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_refund?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Avg_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile_kr_customer". All fields are combined with a logical 'AND'. */
export type Profile_Kr_Customer_Bool_Exp = {
  _and?: Maybe<Array<Profile_Kr_Customer_Bool_Exp>>;
  _not?: Maybe<Profile_Kr_Customer_Bool_Exp>;
  _or?: Maybe<Array<Profile_Kr_Customer_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  birthday?: Maybe<Date_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<Profile_Language_Enum_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  no_of_chat?: Maybe<Int_Comparison_Exp>;
  no_of_dispute?: Maybe<Int_Comparison_Exp>;
  no_of_purchase?: Maybe<Int_Comparison_Exp>;
  phone_home?: Maybe<String_Comparison_Exp>;
  phone_mobile?: Maybe<String_Comparison_Exp>;
  profile_language?: Maybe<Profile_Language_Bool_Exp>;
  rapyd_customer_id?: Maybe<String_Comparison_Exp>;
  self_signed_up_at?: Maybe<Timestamptz_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  sum_of_purchase?: Maybe<Int_Comparison_Exp>;
  sum_of_refund?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_kr_customer" */
export enum Profile_Kr_Customer_Constraint {
  /** unique or primary key constraint */
  ProfileCustomerPkey = 'profile_customer_pkey',
  /** unique or primary key constraint */
  ProfileKrCustomerAccountKey = 'profile_kr_customer_account_key'
}

/** input type for incrementing numeric columns in table "profile_kr_customer" */
export type Profile_Kr_Customer_Inc_Input = {
  no_of_chat?: Maybe<Scalars['Int']>;
  no_of_dispute?: Maybe<Scalars['Int']>;
  no_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_refund?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "profile_kr_customer" */
export type Profile_Kr_Customer_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  birthday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name?: Maybe<Scalars['String']>;
  no_of_chat?: Maybe<Scalars['Int']>;
  no_of_dispute?: Maybe<Scalars['Int']>;
  no_of_purchase?: Maybe<Scalars['Int']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  profile_language?: Maybe<Profile_Language_Obj_Rel_Insert_Input>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  sum_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_refund?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Kr_Customer_Max_Fields = {
  __typename?: 'profile_kr_customer_max_fields';
  account?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  no_of_chat?: Maybe<Scalars['Int']>;
  no_of_dispute?: Maybe<Scalars['Int']>;
  no_of_purchase?: Maybe<Scalars['Int']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  sum_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_refund?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Max_Order_By = {
  account?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  rapyd_customer_id?: Maybe<Order_By>;
  self_signed_up_at?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Kr_Customer_Min_Fields = {
  __typename?: 'profile_kr_customer_min_fields';
  account?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  no_of_chat?: Maybe<Scalars['Int']>;
  no_of_dispute?: Maybe<Scalars['Int']>;
  no_of_purchase?: Maybe<Scalars['Int']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  sum_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_refund?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Min_Order_By = {
  account?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  rapyd_customer_id?: Maybe<Order_By>;
  self_signed_up_at?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile_kr_customer" */
export type Profile_Kr_Customer_Mutation_Response = {
  __typename?: 'profile_kr_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Kr_Customer>;
};

/** input type for inserting object relation for remote table "profile_kr_customer" */
export type Profile_Kr_Customer_Obj_Rel_Insert_Input = {
  data: Profile_Kr_Customer_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Kr_Customer_On_Conflict>;
};

/** on conflict condition type for table "profile_kr_customer" */
export type Profile_Kr_Customer_On_Conflict = {
  constraint: Profile_Kr_Customer_Constraint;
  update_columns?: Array<Profile_Kr_Customer_Update_Column>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_kr_customer". */
export type Profile_Kr_Customer_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  birthday?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  profile_language?: Maybe<Profile_Language_Order_By>;
  rapyd_customer_id?: Maybe<Order_By>;
  self_signed_up_at?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_kr_customer */
export type Profile_Kr_Customer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profile_kr_customer" */
export enum Profile_Kr_Customer_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  NoOfChat = 'no_of_chat',
  /** column name */
  NoOfDispute = 'no_of_dispute',
  /** column name */
  NoOfPurchase = 'no_of_purchase',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  RapydCustomerId = 'rapyd_customer_id',
  /** column name */
  SelfSignedUpAt = 'self_signed_up_at',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  SumOfPurchase = 'sum_of_purchase',
  /** column name */
  SumOfRefund = 'sum_of_refund',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_kr_customer" */
export type Profile_Kr_Customer_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name?: Maybe<Scalars['String']>;
  no_of_chat?: Maybe<Scalars['Int']>;
  no_of_dispute?: Maybe<Scalars['Int']>;
  no_of_purchase?: Maybe<Scalars['Int']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  sum_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_refund?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Kr_Customer_Stddev_Fields = {
  __typename?: 'profile_kr_customer_stddev_fields';
  no_of_chat?: Maybe<Scalars['Float']>;
  no_of_dispute?: Maybe<Scalars['Float']>;
  no_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_refund?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Stddev_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Kr_Customer_Stddev_Pop_Fields = {
  __typename?: 'profile_kr_customer_stddev_pop_fields';
  no_of_chat?: Maybe<Scalars['Float']>;
  no_of_dispute?: Maybe<Scalars['Float']>;
  no_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_refund?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Stddev_Pop_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Kr_Customer_Stddev_Samp_Fields = {
  __typename?: 'profile_kr_customer_stddev_samp_fields';
  no_of_chat?: Maybe<Scalars['Float']>;
  no_of_dispute?: Maybe<Scalars['Float']>;
  no_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_refund?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Stddev_Samp_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Kr_Customer_Sum_Fields = {
  __typename?: 'profile_kr_customer_sum_fields';
  no_of_chat?: Maybe<Scalars['Int']>;
  no_of_dispute?: Maybe<Scalars['Int']>;
  no_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_purchase?: Maybe<Scalars['Int']>;
  sum_of_refund?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Sum_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** update columns of table "profile_kr_customer" */
export enum Profile_Kr_Customer_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  NoOfChat = 'no_of_chat',
  /** column name */
  NoOfDispute = 'no_of_dispute',
  /** column name */
  NoOfPurchase = 'no_of_purchase',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  RapydCustomerId = 'rapyd_customer_id',
  /** column name */
  SelfSignedUpAt = 'self_signed_up_at',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  SumOfPurchase = 'sum_of_purchase',
  /** column name */
  SumOfRefund = 'sum_of_refund',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Kr_Customer_Var_Pop_Fields = {
  __typename?: 'profile_kr_customer_var_pop_fields';
  no_of_chat?: Maybe<Scalars['Float']>;
  no_of_dispute?: Maybe<Scalars['Float']>;
  no_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_refund?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Var_Pop_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Kr_Customer_Var_Samp_Fields = {
  __typename?: 'profile_kr_customer_var_samp_fields';
  no_of_chat?: Maybe<Scalars['Float']>;
  no_of_dispute?: Maybe<Scalars['Float']>;
  no_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_refund?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Var_Samp_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Kr_Customer_Variance_Fields = {
  __typename?: 'profile_kr_customer_variance_fields';
  no_of_chat?: Maybe<Scalars['Float']>;
  no_of_dispute?: Maybe<Scalars['Float']>;
  no_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_purchase?: Maybe<Scalars['Float']>;
  sum_of_refund?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile_kr_customer" */
export type Profile_Kr_Customer_Variance_Order_By = {
  no_of_chat?: Maybe<Order_By>;
  no_of_dispute?: Maybe<Order_By>;
  no_of_purchase?: Maybe<Order_By>;
  sum_of_purchase?: Maybe<Order_By>;
  sum_of_refund?: Maybe<Order_By>;
};

/** columns and relationships of "profile_kr_partner" */
export type Profile_Kr_Partner = {
  __typename?: 'profile_kr_partner';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  language: Profile_Language_Enum;
  name: Scalars['String'];
  no_of_store: Scalars['Int'];
  opted_in_auto_payment_at?: Maybe<Scalars['timestamptz']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile_language: Profile_Language;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_kr_partner" */
export type Profile_Kr_Partner_Aggregate = {
  __typename?: 'profile_kr_partner_aggregate';
  aggregate?: Maybe<Profile_Kr_Partner_Aggregate_Fields>;
  nodes: Array<Profile_Kr_Partner>;
};

/** aggregate fields of "profile_kr_partner" */
export type Profile_Kr_Partner_Aggregate_Fields = {
  __typename?: 'profile_kr_partner_aggregate_fields';
  avg?: Maybe<Profile_Kr_Partner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Kr_Partner_Max_Fields>;
  min?: Maybe<Profile_Kr_Partner_Min_Fields>;
  stddev?: Maybe<Profile_Kr_Partner_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Kr_Partner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Kr_Partner_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Kr_Partner_Sum_Fields>;
  var_pop?: Maybe<Profile_Kr_Partner_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Kr_Partner_Var_Samp_Fields>;
  variance?: Maybe<Profile_Kr_Partner_Variance_Fields>;
};


/** aggregate fields of "profile_kr_partner" */
export type Profile_Kr_Partner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_kr_partner" */
export type Profile_Kr_Partner_Aggregate_Order_By = {
  avg?: Maybe<Profile_Kr_Partner_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Kr_Partner_Max_Order_By>;
  min?: Maybe<Profile_Kr_Partner_Min_Order_By>;
  stddev?: Maybe<Profile_Kr_Partner_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Kr_Partner_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Kr_Partner_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Kr_Partner_Sum_Order_By>;
  var_pop?: Maybe<Profile_Kr_Partner_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Kr_Partner_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Kr_Partner_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profile_kr_partner" */
export type Profile_Kr_Partner_Arr_Rel_Insert_Input = {
  data: Array<Profile_Kr_Partner_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Kr_Partner_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Kr_Partner_Avg_Fields = {
  __typename?: 'profile_kr_partner_avg_fields';
  no_of_store?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Avg_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile_kr_partner". All fields are combined with a logical 'AND'. */
export type Profile_Kr_Partner_Bool_Exp = {
  _and?: Maybe<Array<Profile_Kr_Partner_Bool_Exp>>;
  _not?: Maybe<Profile_Kr_Partner_Bool_Exp>;
  _or?: Maybe<Array<Profile_Kr_Partner_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<Profile_Language_Enum_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  no_of_store?: Maybe<Int_Comparison_Exp>;
  opted_in_auto_payment_at?: Maybe<Timestamptz_Comparison_Exp>;
  phone_home?: Maybe<String_Comparison_Exp>;
  phone_mobile?: Maybe<String_Comparison_Exp>;
  profile_language?: Maybe<Profile_Language_Bool_Exp>;
  rapyd_customer_id?: Maybe<String_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_kr_partner" */
export enum Profile_Kr_Partner_Constraint {
  /** unique or primary key constraint */
  ProfileKrPartnerAccountKey = 'profile_kr_partner_account_key',
  /** unique or primary key constraint */
  ProfilePartnerPkey = 'profile_partner_pkey'
}

/** input type for incrementing numeric columns in table "profile_kr_partner" */
export type Profile_Kr_Partner_Inc_Input = {
  no_of_store?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "profile_kr_partner" */
export type Profile_Kr_Partner_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name?: Maybe<Scalars['String']>;
  no_of_store?: Maybe<Scalars['Int']>;
  opted_in_auto_payment_at?: Maybe<Scalars['timestamptz']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  profile_language?: Maybe<Profile_Language_Obj_Rel_Insert_Input>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Kr_Partner_Max_Fields = {
  __typename?: 'profile_kr_partner_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  no_of_store?: Maybe<Scalars['Int']>;
  opted_in_auto_payment_at?: Maybe<Scalars['timestamptz']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  no_of_store?: Maybe<Order_By>;
  opted_in_auto_payment_at?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  rapyd_customer_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Kr_Partner_Min_Fields = {
  __typename?: 'profile_kr_partner_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  no_of_store?: Maybe<Scalars['Int']>;
  opted_in_auto_payment_at?: Maybe<Scalars['timestamptz']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  no_of_store?: Maybe<Order_By>;
  opted_in_auto_payment_at?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  rapyd_customer_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile_kr_partner" */
export type Profile_Kr_Partner_Mutation_Response = {
  __typename?: 'profile_kr_partner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Kr_Partner>;
};

/** input type for inserting object relation for remote table "profile_kr_partner" */
export type Profile_Kr_Partner_Obj_Rel_Insert_Input = {
  data: Profile_Kr_Partner_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Kr_Partner_On_Conflict>;
};

/** on conflict condition type for table "profile_kr_partner" */
export type Profile_Kr_Partner_On_Conflict = {
  constraint: Profile_Kr_Partner_Constraint;
  update_columns?: Array<Profile_Kr_Partner_Update_Column>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_kr_partner". */
export type Profile_Kr_Partner_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  no_of_store?: Maybe<Order_By>;
  opted_in_auto_payment_at?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  profile_language?: Maybe<Profile_Language_Order_By>;
  rapyd_customer_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_kr_partner */
export type Profile_Kr_Partner_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profile_kr_partner" */
export enum Profile_Kr_Partner_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  NoOfStore = 'no_of_store',
  /** column name */
  OptedInAutoPaymentAt = 'opted_in_auto_payment_at',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  RapydCustomerId = 'rapyd_customer_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_kr_partner" */
export type Profile_Kr_Partner_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name?: Maybe<Scalars['String']>;
  no_of_store?: Maybe<Scalars['Int']>;
  opted_in_auto_payment_at?: Maybe<Scalars['timestamptz']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  rapyd_customer_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Kr_Partner_Stddev_Fields = {
  __typename?: 'profile_kr_partner_stddev_fields';
  no_of_store?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Stddev_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Kr_Partner_Stddev_Pop_Fields = {
  __typename?: 'profile_kr_partner_stddev_pop_fields';
  no_of_store?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Stddev_Pop_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Kr_Partner_Stddev_Samp_Fields = {
  __typename?: 'profile_kr_partner_stddev_samp_fields';
  no_of_store?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Stddev_Samp_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Kr_Partner_Sum_Fields = {
  __typename?: 'profile_kr_partner_sum_fields';
  no_of_store?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Sum_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** update columns of table "profile_kr_partner" */
export enum Profile_Kr_Partner_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  NoOfStore = 'no_of_store',
  /** column name */
  OptedInAutoPaymentAt = 'opted_in_auto_payment_at',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  RapydCustomerId = 'rapyd_customer_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Kr_Partner_Var_Pop_Fields = {
  __typename?: 'profile_kr_partner_var_pop_fields';
  no_of_store?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Var_Pop_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Kr_Partner_Var_Samp_Fields = {
  __typename?: 'profile_kr_partner_var_samp_fields';
  no_of_store?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Var_Samp_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Kr_Partner_Variance_Fields = {
  __typename?: 'profile_kr_partner_variance_fields';
  no_of_store?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile_kr_partner" */
export type Profile_Kr_Partner_Variance_Order_By = {
  no_of_store?: Maybe<Order_By>;
};

/** columns and relationships of "profile_language" */
export type Profile_Language = {
  __typename?: 'profile_language';
  description: Scalars['String'];
  /** An array relationship */
  profile_kr_customers: Array<Profile_Kr_Customer>;
  /** An aggregate relationship */
  profile_kr_customers_aggregate: Profile_Kr_Customer_Aggregate;
  /** An array relationship */
  profile_kr_partners: Array<Profile_Kr_Partner>;
  /** An aggregate relationship */
  profile_kr_partners_aggregate: Profile_Kr_Partner_Aggregate;
  /** An array relationship */
  profile_us_customers: Array<Profile_Us_Customer>;
  /** An aggregate relationship */
  profile_us_customers_aggregate: Profile_Us_Customer_Aggregate;
  /** An array relationship */
  profile_us_partners: Array<Profile_Us_Partner>;
  /** An aggregate relationship */
  profile_us_partners_aggregate: Profile_Us_Partner_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Kr_CustomersArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Kr_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Kr_PartnersArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Kr_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Us_CustomersArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Us_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Us_PartnersArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};


/** columns and relationships of "profile_language" */
export type Profile_LanguageProfile_Us_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};

/** aggregated selection of "profile_language" */
export type Profile_Language_Aggregate = {
  __typename?: 'profile_language_aggregate';
  aggregate?: Maybe<Profile_Language_Aggregate_Fields>;
  nodes: Array<Profile_Language>;
};

/** aggregate fields of "profile_language" */
export type Profile_Language_Aggregate_Fields = {
  __typename?: 'profile_language_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Language_Max_Fields>;
  min?: Maybe<Profile_Language_Min_Fields>;
};


/** aggregate fields of "profile_language" */
export type Profile_Language_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Language_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profile_language". All fields are combined with a logical 'AND'. */
export type Profile_Language_Bool_Exp = {
  _and?: Maybe<Array<Profile_Language_Bool_Exp>>;
  _not?: Maybe<Profile_Language_Bool_Exp>;
  _or?: Maybe<Array<Profile_Language_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  profile_kr_customers?: Maybe<Profile_Kr_Customer_Bool_Exp>;
  profile_kr_partners?: Maybe<Profile_Kr_Partner_Bool_Exp>;
  profile_us_customers?: Maybe<Profile_Us_Customer_Bool_Exp>;
  profile_us_partners?: Maybe<Profile_Us_Partner_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_language" */
export enum Profile_Language_Constraint {
  /** unique or primary key constraint */
  ProfileLanguageTypePkey = 'profile_language_type_pkey'
}

export enum Profile_Language_Enum {
  /** Korean */
  Kr = 'kr',
  /** English(US) */
  Us = 'us'
}

/** Boolean expression to compare columns of type "profile_language_enum". All fields are combined with logical 'AND'. */
export type Profile_Language_Enum_Comparison_Exp = {
  _eq?: Maybe<Profile_Language_Enum>;
  _in?: Maybe<Array<Profile_Language_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Profile_Language_Enum>;
  _nin?: Maybe<Array<Profile_Language_Enum>>;
};

/** input type for inserting data into table "profile_language" */
export type Profile_Language_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  profile_kr_customers?: Maybe<Profile_Kr_Customer_Arr_Rel_Insert_Input>;
  profile_kr_partners?: Maybe<Profile_Kr_Partner_Arr_Rel_Insert_Input>;
  profile_us_customers?: Maybe<Profile_Us_Customer_Arr_Rel_Insert_Input>;
  profile_us_partners?: Maybe<Profile_Us_Partner_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Language_Max_Fields = {
  __typename?: 'profile_language_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Profile_Language_Min_Fields = {
  __typename?: 'profile_language_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "profile_language" */
export type Profile_Language_Mutation_Response = {
  __typename?: 'profile_language_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Language>;
};

/** input type for inserting object relation for remote table "profile_language" */
export type Profile_Language_Obj_Rel_Insert_Input = {
  data: Profile_Language_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Language_On_Conflict>;
};

/** on conflict condition type for table "profile_language" */
export type Profile_Language_On_Conflict = {
  constraint: Profile_Language_Constraint;
  update_columns?: Array<Profile_Language_Update_Column>;
  where?: Maybe<Profile_Language_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_language". */
export type Profile_Language_Order_By = {
  description?: Maybe<Order_By>;
  profile_kr_customers_aggregate?: Maybe<Profile_Kr_Customer_Aggregate_Order_By>;
  profile_kr_partners_aggregate?: Maybe<Profile_Kr_Partner_Aggregate_Order_By>;
  profile_us_customers_aggregate?: Maybe<Profile_Us_Customer_Aggregate_Order_By>;
  profile_us_partners_aggregate?: Maybe<Profile_Us_Partner_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_language */
export type Profile_Language_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "profile_language" */
export enum Profile_Language_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "profile_language" */
export type Profile_Language_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "profile_language" */
export enum Profile_Language_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "profile_riderdash" */
export type Profile_Riderdash = {
  __typename?: 'profile_riderdash';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  authorization: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  phone_mobile?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile_riderdash_role?: Maybe<Profile_Riderdash_Role>;
  role?: Maybe<Profile_Riderdash_Role_Enum>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_riderdash" */
export type Profile_Riderdash_Aggregate = {
  __typename?: 'profile_riderdash_aggregate';
  aggregate?: Maybe<Profile_Riderdash_Aggregate_Fields>;
  nodes: Array<Profile_Riderdash>;
};

/** aggregate fields of "profile_riderdash" */
export type Profile_Riderdash_Aggregate_Fields = {
  __typename?: 'profile_riderdash_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Riderdash_Max_Fields>;
  min?: Maybe<Profile_Riderdash_Min_Fields>;
};


/** aggregate fields of "profile_riderdash" */
export type Profile_Riderdash_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_riderdash" */
export type Profile_Riderdash_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Riderdash_Max_Order_By>;
  min?: Maybe<Profile_Riderdash_Min_Order_By>;
};

/** input type for inserting array relation for remote table "profile_riderdash" */
export type Profile_Riderdash_Arr_Rel_Insert_Input = {
  data: Array<Profile_Riderdash_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Riderdash_On_Conflict>;
};

/** Boolean expression to filter rows from the table "profile_riderdash". All fields are combined with a logical 'AND'. */
export type Profile_Riderdash_Bool_Exp = {
  _and?: Maybe<Array<Profile_Riderdash_Bool_Exp>>;
  _not?: Maybe<Profile_Riderdash_Bool_Exp>;
  _or?: Maybe<Array<Profile_Riderdash_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  authorization?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone_mobile?: Maybe<String_Comparison_Exp>;
  profile_riderdash_role?: Maybe<Profile_Riderdash_Role_Bool_Exp>;
  role?: Maybe<Profile_Riderdash_Role_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_riderdash" */
export enum Profile_Riderdash_Constraint {
  /** unique or primary key constraint */
  ProfileRiderdashAccountKey = 'profile_riderdash_account_key',
  /** unique or primary key constraint */
  ProfileRiderdashPkey = 'profile_riderdash_pkey'
}

/** input type for inserting data into table "profile_riderdash" */
export type Profile_Riderdash_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  authorization?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  profile_riderdash_role?: Maybe<Profile_Riderdash_Role_Obj_Rel_Insert_Input>;
  role?: Maybe<Profile_Riderdash_Role_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Riderdash_Max_Fields = {
  __typename?: 'profile_riderdash_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "profile_riderdash" */
export type Profile_Riderdash_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Riderdash_Min_Fields = {
  __typename?: 'profile_riderdash_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "profile_riderdash" */
export type Profile_Riderdash_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile_riderdash" */
export type Profile_Riderdash_Mutation_Response = {
  __typename?: 'profile_riderdash_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Riderdash>;
};

/** input type for inserting object relation for remote table "profile_riderdash" */
export type Profile_Riderdash_Obj_Rel_Insert_Input = {
  data: Profile_Riderdash_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Riderdash_On_Conflict>;
};

/** on conflict condition type for table "profile_riderdash" */
export type Profile_Riderdash_On_Conflict = {
  constraint: Profile_Riderdash_Constraint;
  update_columns?: Array<Profile_Riderdash_Update_Column>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_riderdash". */
export type Profile_Riderdash_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  authorization?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  profile_riderdash_role?: Maybe<Profile_Riderdash_Role_Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_riderdash */
export type Profile_Riderdash_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "profile_riderdash_role" */
export type Profile_Riderdash_Role = {
  __typename?: 'profile_riderdash_role';
  description: Scalars['String'];
  /** An array relationship */
  profile_riderdashes: Array<Profile_Riderdash>;
  /** An aggregate relationship */
  profile_riderdashes_aggregate: Profile_Riderdash_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "profile_riderdash_role" */
export type Profile_Riderdash_RoleProfile_RiderdashesArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};


/** columns and relationships of "profile_riderdash_role" */
export type Profile_Riderdash_RoleProfile_Riderdashes_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};

/** aggregated selection of "profile_riderdash_role" */
export type Profile_Riderdash_Role_Aggregate = {
  __typename?: 'profile_riderdash_role_aggregate';
  aggregate?: Maybe<Profile_Riderdash_Role_Aggregate_Fields>;
  nodes: Array<Profile_Riderdash_Role>;
};

/** aggregate fields of "profile_riderdash_role" */
export type Profile_Riderdash_Role_Aggregate_Fields = {
  __typename?: 'profile_riderdash_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Riderdash_Role_Max_Fields>;
  min?: Maybe<Profile_Riderdash_Role_Min_Fields>;
};


/** aggregate fields of "profile_riderdash_role" */
export type Profile_Riderdash_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Riderdash_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profile_riderdash_role". All fields are combined with a logical 'AND'. */
export type Profile_Riderdash_Role_Bool_Exp = {
  _and?: Maybe<Array<Profile_Riderdash_Role_Bool_Exp>>;
  _not?: Maybe<Profile_Riderdash_Role_Bool_Exp>;
  _or?: Maybe<Array<Profile_Riderdash_Role_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  profile_riderdashes?: Maybe<Profile_Riderdash_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_riderdash_role" */
export enum Profile_Riderdash_Role_Constraint {
  /** unique or primary key constraint */
  ProfileRiderdashRolePkey = 'profile_riderdash_role_pkey'
}

export enum Profile_Riderdash_Role_Enum {
  /** 관리자 */
  Administrator = 'administrator',
  /** 파트타이머 */
  Parttimer = 'parttimer',
  /** 사용자 */
  User = 'user'
}

/** Boolean expression to compare columns of type "profile_riderdash_role_enum". All fields are combined with logical 'AND'. */
export type Profile_Riderdash_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Profile_Riderdash_Role_Enum>;
  _in?: Maybe<Array<Profile_Riderdash_Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Profile_Riderdash_Role_Enum>;
  _nin?: Maybe<Array<Profile_Riderdash_Role_Enum>>;
};

/** input type for inserting data into table "profile_riderdash_role" */
export type Profile_Riderdash_Role_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  profile_riderdashes?: Maybe<Profile_Riderdash_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Riderdash_Role_Max_Fields = {
  __typename?: 'profile_riderdash_role_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Profile_Riderdash_Role_Min_Fields = {
  __typename?: 'profile_riderdash_role_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "profile_riderdash_role" */
export type Profile_Riderdash_Role_Mutation_Response = {
  __typename?: 'profile_riderdash_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Riderdash_Role>;
};

/** input type for inserting object relation for remote table "profile_riderdash_role" */
export type Profile_Riderdash_Role_Obj_Rel_Insert_Input = {
  data: Profile_Riderdash_Role_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Riderdash_Role_On_Conflict>;
};

/** on conflict condition type for table "profile_riderdash_role" */
export type Profile_Riderdash_Role_On_Conflict = {
  constraint: Profile_Riderdash_Role_Constraint;
  update_columns?: Array<Profile_Riderdash_Role_Update_Column>;
  where?: Maybe<Profile_Riderdash_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_riderdash_role". */
export type Profile_Riderdash_Role_Order_By = {
  description?: Maybe<Order_By>;
  profile_riderdashes_aggregate?: Maybe<Profile_Riderdash_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_riderdash_role */
export type Profile_Riderdash_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "profile_riderdash_role" */
export enum Profile_Riderdash_Role_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "profile_riderdash_role" */
export type Profile_Riderdash_Role_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "profile_riderdash_role" */
export enum Profile_Riderdash_Role_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** select columns of table "profile_riderdash" */
export enum Profile_Riderdash_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Authorization = 'authorization',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_riderdash" */
export type Profile_Riderdash_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  authorization?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  role?: Maybe<Profile_Riderdash_Role_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "profile_riderdash" */
export enum Profile_Riderdash_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Authorization = 'authorization',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * United States customer profile
 *
 *
 * columns and relationships of "profile_us_customer"
 */
export type Profile_Us_Customer = {
  __typename?: 'profile_us_customer';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  language: Profile_Language_Enum;
  name_first: Scalars['String'];
  name_last: Scalars['String'];
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile_language: Profile_Language;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_us_customer" */
export type Profile_Us_Customer_Aggregate = {
  __typename?: 'profile_us_customer_aggregate';
  aggregate?: Maybe<Profile_Us_Customer_Aggregate_Fields>;
  nodes: Array<Profile_Us_Customer>;
};

/** aggregate fields of "profile_us_customer" */
export type Profile_Us_Customer_Aggregate_Fields = {
  __typename?: 'profile_us_customer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Us_Customer_Max_Fields>;
  min?: Maybe<Profile_Us_Customer_Min_Fields>;
};


/** aggregate fields of "profile_us_customer" */
export type Profile_Us_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_us_customer" */
export type Profile_Us_Customer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Us_Customer_Max_Order_By>;
  min?: Maybe<Profile_Us_Customer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "profile_us_customer" */
export type Profile_Us_Customer_Arr_Rel_Insert_Input = {
  data: Array<Profile_Us_Customer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Us_Customer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "profile_us_customer". All fields are combined with a logical 'AND'. */
export type Profile_Us_Customer_Bool_Exp = {
  _and?: Maybe<Array<Profile_Us_Customer_Bool_Exp>>;
  _not?: Maybe<Profile_Us_Customer_Bool_Exp>;
  _or?: Maybe<Array<Profile_Us_Customer_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<Profile_Language_Enum_Comparison_Exp>;
  name_first?: Maybe<String_Comparison_Exp>;
  name_last?: Maybe<String_Comparison_Exp>;
  name_middle?: Maybe<String_Comparison_Exp>;
  phone_home?: Maybe<String_Comparison_Exp>;
  phone_mobile?: Maybe<String_Comparison_Exp>;
  profile_language?: Maybe<Profile_Language_Bool_Exp>;
  self_signed_up_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_us_customer" */
export enum Profile_Us_Customer_Constraint {
  /** unique or primary key constraint */
  ProfileUsCustomerAccountKey = 'profile_us_customer_account_key',
  /** unique or primary key constraint */
  ProfileUsCustomerPkey = 'profile_us_customer_pkey'
}

/** input type for inserting data into table "profile_us_customer" */
export type Profile_Us_Customer_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  profile_language?: Maybe<Profile_Language_Obj_Rel_Insert_Input>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Us_Customer_Max_Fields = {
  __typename?: 'profile_us_customer_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "profile_us_customer" */
export type Profile_Us_Customer_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  self_signed_up_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Us_Customer_Min_Fields = {
  __typename?: 'profile_us_customer_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "profile_us_customer" */
export type Profile_Us_Customer_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  self_signed_up_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile_us_customer" */
export type Profile_Us_Customer_Mutation_Response = {
  __typename?: 'profile_us_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Us_Customer>;
};

/** input type for inserting object relation for remote table "profile_us_customer" */
export type Profile_Us_Customer_Obj_Rel_Insert_Input = {
  data: Profile_Us_Customer_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Us_Customer_On_Conflict>;
};

/** on conflict condition type for table "profile_us_customer" */
export type Profile_Us_Customer_On_Conflict = {
  constraint: Profile_Us_Customer_Constraint;
  update_columns?: Array<Profile_Us_Customer_Update_Column>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_us_customer". */
export type Profile_Us_Customer_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  profile_language?: Maybe<Profile_Language_Order_By>;
  self_signed_up_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_us_customer */
export type Profile_Us_Customer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profile_us_customer" */
export enum Profile_Us_Customer_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  SelfSignedUpAt = 'self_signed_up_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_us_customer" */
export type Profile_Us_Customer_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  self_signed_up_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "profile_us_customer" */
export enum Profile_Us_Customer_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  SelfSignedUpAt = 'self_signed_up_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * United States customer profile for partner caramora
 *
 *
 * columns and relationships of "profile_us_partner"
 */
export type Profile_Us_Partner = {
  __typename?: 'profile_us_partner';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  language: Profile_Language_Enum;
  name_first: Scalars['String'];
  name_last: Scalars['String'];
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile_language: Profile_Language;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_us_partner" */
export type Profile_Us_Partner_Aggregate = {
  __typename?: 'profile_us_partner_aggregate';
  aggregate?: Maybe<Profile_Us_Partner_Aggregate_Fields>;
  nodes: Array<Profile_Us_Partner>;
};

/** aggregate fields of "profile_us_partner" */
export type Profile_Us_Partner_Aggregate_Fields = {
  __typename?: 'profile_us_partner_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Us_Partner_Max_Fields>;
  min?: Maybe<Profile_Us_Partner_Min_Fields>;
};


/** aggregate fields of "profile_us_partner" */
export type Profile_Us_Partner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_us_partner" */
export type Profile_Us_Partner_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Us_Partner_Max_Order_By>;
  min?: Maybe<Profile_Us_Partner_Min_Order_By>;
};

/** input type for inserting array relation for remote table "profile_us_partner" */
export type Profile_Us_Partner_Arr_Rel_Insert_Input = {
  data: Array<Profile_Us_Partner_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Us_Partner_On_Conflict>;
};

/** Boolean expression to filter rows from the table "profile_us_partner". All fields are combined with a logical 'AND'. */
export type Profile_Us_Partner_Bool_Exp = {
  _and?: Maybe<Array<Profile_Us_Partner_Bool_Exp>>;
  _not?: Maybe<Profile_Us_Partner_Bool_Exp>;
  _or?: Maybe<Array<Profile_Us_Partner_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<Profile_Language_Enum_Comparison_Exp>;
  name_first?: Maybe<String_Comparison_Exp>;
  name_last?: Maybe<String_Comparison_Exp>;
  name_middle?: Maybe<String_Comparison_Exp>;
  phone_home?: Maybe<String_Comparison_Exp>;
  phone_mobile?: Maybe<String_Comparison_Exp>;
  profile_language?: Maybe<Profile_Language_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_us_partner" */
export enum Profile_Us_Partner_Constraint {
  /** unique or primary key constraint */
  ProfileUsPartnerAccountKey = 'profile_us_partner_account_key',
  /** unique or primary key constraint */
  ProfileUsPartnerPkey = 'profile_us_partner_pkey'
}

/** input type for inserting data into table "profile_us_partner" */
export type Profile_Us_Partner_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  profile_language?: Maybe<Profile_Language_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Us_Partner_Max_Fields = {
  __typename?: 'profile_us_partner_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "profile_us_partner" */
export type Profile_Us_Partner_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Us_Partner_Min_Fields = {
  __typename?: 'profile_us_partner_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "profile_us_partner" */
export type Profile_Us_Partner_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile_us_partner" */
export type Profile_Us_Partner_Mutation_Response = {
  __typename?: 'profile_us_partner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Us_Partner>;
};

/** input type for inserting object relation for remote table "profile_us_partner" */
export type Profile_Us_Partner_Obj_Rel_Insert_Input = {
  data: Profile_Us_Partner_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Us_Partner_On_Conflict>;
};

/** on conflict condition type for table "profile_us_partner" */
export type Profile_Us_Partner_On_Conflict = {
  constraint: Profile_Us_Partner_Constraint;
  update_columns?: Array<Profile_Us_Partner_Update_Column>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_us_partner". */
export type Profile_Us_Partner_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  phone_home?: Maybe<Order_By>;
  phone_mobile?: Maybe<Order_By>;
  profile_language?: Maybe<Profile_Language_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_us_partner */
export type Profile_Us_Partner_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profile_us_partner" */
export enum Profile_Us_Partner_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_us_partner" */
export type Profile_Us_Partner_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Profile_Language_Enum>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  phone_home?: Maybe<Scalars['String']>;
  phone_mobile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "profile_us_partner" */
export enum Profile_Us_Partner_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  PhoneHome = 'phone_home',
  /** column name */
  PhoneMobile = 'phone_mobile',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "promotion" */
export type Promotion = {
  __typename?: 'promotion';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  on_going?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  productItemByProductItem: Product_Item;
  product_item: Scalars['uuid'];
  promotion_price: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "promotion" */
export type Promotion_Aggregate = {
  __typename?: 'promotion_aggregate';
  aggregate?: Maybe<Promotion_Aggregate_Fields>;
  nodes: Array<Promotion>;
};

/** aggregate fields of "promotion" */
export type Promotion_Aggregate_Fields = {
  __typename?: 'promotion_aggregate_fields';
  avg?: Maybe<Promotion_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Promotion_Max_Fields>;
  min?: Maybe<Promotion_Min_Fields>;
  stddev?: Maybe<Promotion_Stddev_Fields>;
  stddev_pop?: Maybe<Promotion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Promotion_Stddev_Samp_Fields>;
  sum?: Maybe<Promotion_Sum_Fields>;
  var_pop?: Maybe<Promotion_Var_Pop_Fields>;
  var_samp?: Maybe<Promotion_Var_Samp_Fields>;
  variance?: Maybe<Promotion_Variance_Fields>;
};


/** aggregate fields of "promotion" */
export type Promotion_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Promotion_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "promotion" */
export type Promotion_Aggregate_Order_By = {
  avg?: Maybe<Promotion_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Promotion_Max_Order_By>;
  min?: Maybe<Promotion_Min_Order_By>;
  stddev?: Maybe<Promotion_Stddev_Order_By>;
  stddev_pop?: Maybe<Promotion_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Promotion_Stddev_Samp_Order_By>;
  sum?: Maybe<Promotion_Sum_Order_By>;
  var_pop?: Maybe<Promotion_Var_Pop_Order_By>;
  var_samp?: Maybe<Promotion_Var_Samp_Order_By>;
  variance?: Maybe<Promotion_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "promotion" */
export type Promotion_Arr_Rel_Insert_Input = {
  data: Array<Promotion_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Promotion_On_Conflict>;
};

/** aggregate avg on columns */
export type Promotion_Avg_Fields = {
  __typename?: 'promotion_avg_fields';
  promotion_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "promotion" */
export type Promotion_Avg_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "promotion". All fields are combined with a logical 'AND'. */
export type Promotion_Bool_Exp = {
  _and?: Maybe<Array<Promotion_Bool_Exp>>;
  _not?: Maybe<Promotion_Bool_Exp>;
  _or?: Maybe<Array<Promotion_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  on_going?: Maybe<Boolean_Comparison_Exp>;
  productItemByProductItem?: Maybe<Product_Item_Bool_Exp>;
  product_item?: Maybe<Uuid_Comparison_Exp>;
  promotion_price?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "promotion" */
export enum Promotion_Constraint {
  /** unique or primary key constraint */
  PromotionPkey = 'promotion_pkey'
}

/** input type for incrementing numeric columns in table "promotion" */
export type Promotion_Inc_Input = {
  promotion_price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "promotion" */
export type Promotion_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  on_going?: Maybe<Scalars['Boolean']>;
  productItemByProductItem?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  product_item?: Maybe<Scalars['uuid']>;
  promotion_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Promotion_Max_Fields = {
  __typename?: 'promotion_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  product_item?: Maybe<Scalars['uuid']>;
  promotion_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "promotion" */
export type Promotion_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  promotion_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Promotion_Min_Fields = {
  __typename?: 'promotion_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  product_item?: Maybe<Scalars['uuid']>;
  promotion_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "promotion" */
export type Promotion_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  promotion_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "promotion" */
export type Promotion_Mutation_Response = {
  __typename?: 'promotion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promotion>;
};

/** on conflict condition type for table "promotion" */
export type Promotion_On_Conflict = {
  constraint: Promotion_Constraint;
  update_columns?: Array<Promotion_Update_Column>;
  where?: Maybe<Promotion_Bool_Exp>;
};

/** Ordering options when selecting data from "promotion". */
export type Promotion_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  on_going?: Maybe<Order_By>;
  productItemByProductItem?: Maybe<Product_Item_Order_By>;
  product_item?: Maybe<Order_By>;
  promotion_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: promotion */
export type Promotion_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "promotion" */
export enum Promotion_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnGoing = 'on_going',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  PromotionPrice = 'promotion_price',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "promotion" */
export type Promotion_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  on_going?: Maybe<Scalars['Boolean']>;
  product_item?: Maybe<Scalars['uuid']>;
  promotion_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Promotion_Stddev_Fields = {
  __typename?: 'promotion_stddev_fields';
  promotion_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "promotion" */
export type Promotion_Stddev_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Promotion_Stddev_Pop_Fields = {
  __typename?: 'promotion_stddev_pop_fields';
  promotion_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "promotion" */
export type Promotion_Stddev_Pop_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Promotion_Stddev_Samp_Fields = {
  __typename?: 'promotion_stddev_samp_fields';
  promotion_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "promotion" */
export type Promotion_Stddev_Samp_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Promotion_Sum_Fields = {
  __typename?: 'promotion_sum_fields';
  promotion_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "promotion" */
export type Promotion_Sum_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

/** update columns of table "promotion" */
export enum Promotion_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnGoing = 'on_going',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  PromotionPrice = 'promotion_price',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Promotion_Var_Pop_Fields = {
  __typename?: 'promotion_var_pop_fields';
  promotion_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "promotion" */
export type Promotion_Var_Pop_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Promotion_Var_Samp_Fields = {
  __typename?: 'promotion_var_samp_fields';
  promotion_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "promotion" */
export type Promotion_Var_Samp_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Promotion_Variance_Fields = {
  __typename?: 'promotion_variance_fields';
  promotion_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "promotion" */
export type Promotion_Variance_Order_By = {
  promotion_price?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_performance" */
  account_performance: Array<Account_Performance>;
  /** fetch aggregated fields from the table: "account_performance" */
  account_performance_aggregate: Account_Performance_Aggregate;
  /** fetch data from the table: "account_throughput" */
  account_throughput: Array<Account_Throughput>;
  /** fetch aggregated fields from the table: "account_throughput" */
  account_throughput_aggregate: Account_Throughput_Aggregate;
  /** fetch data from the table: "account_type" */
  account_type: Array<Account_Type>;
  /** fetch aggregated fields from the table: "account_type" */
  account_type_aggregate: Account_Type_Aggregate;
  /** fetch data from the table: "account_type" using primary key columns */
  account_type_by_pk?: Maybe<Account_Type>;
  /** fetch data from the table: "accounts_payable" */
  accounts_payable: Array<Accounts_Payable>;
  /** fetch aggregated fields from the table: "accounts_payable" */
  accounts_payable_aggregate: Accounts_Payable_Aggregate;
  /** fetch data from the table: "accounts_payable_and_adjustment" */
  accounts_payable_and_adjustment: Array<Accounts_Payable_And_Adjustment>;
  /** fetch aggregated fields from the table: "accounts_payable_and_adjustment" */
  accounts_payable_and_adjustment_aggregate: Accounts_Payable_And_Adjustment_Aggregate;
  /** fetch data from the table: "accounts_payable_and_adjustment" using primary key columns */
  accounts_payable_and_adjustment_by_pk?: Maybe<Accounts_Payable_And_Adjustment>;
  /** fetch data from the table: "accounts_payable_and_payout" */
  accounts_payable_and_payout: Array<Accounts_Payable_And_Payout>;
  /** fetch aggregated fields from the table: "accounts_payable_and_payout" */
  accounts_payable_and_payout_aggregate: Accounts_Payable_And_Payout_Aggregate;
  /** fetch data from the table: "accounts_payable_and_payout" using primary key columns */
  accounts_payable_and_payout_by_pk?: Maybe<Accounts_Payable_And_Payout>;
  /** fetch data from the table: "accounts_payable_and_refund" */
  accounts_payable_and_refund: Array<Accounts_Payable_And_Refund>;
  /** fetch aggregated fields from the table: "accounts_payable_and_refund" */
  accounts_payable_and_refund_aggregate: Accounts_Payable_And_Refund_Aggregate;
  /** fetch data from the table: "accounts_payable_and_refund" using primary key columns */
  accounts_payable_and_refund_by_pk?: Maybe<Accounts_Payable_And_Refund>;
  /** fetch data from the table: "accounts_payable" using primary key columns */
  accounts_payable_by_pk?: Maybe<Accounts_Payable>;
  /** fetch data from the table: "accounts_receivable" */
  accounts_receivable: Array<Accounts_Receivable>;
  /** fetch aggregated fields from the table: "accounts_receivable" */
  accounts_receivable_aggregate: Accounts_Receivable_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_invoice" */
  accounts_receivable_and_invoice: Array<Accounts_Receivable_And_Invoice>;
  /** fetch aggregated fields from the table: "accounts_receivable_and_invoice" */
  accounts_receivable_and_invoice_aggregate: Accounts_Receivable_And_Invoice_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_invoice" using primary key columns */
  accounts_receivable_and_invoice_by_pk?: Maybe<Accounts_Receivable_And_Invoice>;
  /** fetch data from the table: "accounts_receivable_and_membership" */
  accounts_receivable_and_membership: Array<Accounts_Receivable_And_Membership>;
  /** fetch aggregated fields from the table: "accounts_receivable_and_membership" */
  accounts_receivable_and_membership_aggregate: Accounts_Receivable_And_Membership_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_membership" using primary key columns */
  accounts_receivable_and_membership_by_pk?: Maybe<Accounts_Receivable_And_Membership>;
  /** fetch data from the table: "accounts_receivable_and_subscription" */
  accounts_receivable_and_subscription: Array<Accounts_Receivable_And_Subscription>;
  /** fetch aggregated fields from the table: "accounts_receivable_and_subscription" */
  accounts_receivable_and_subscription_aggregate: Accounts_Receivable_And_Subscription_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_subscription" using primary key columns */
  accounts_receivable_and_subscription_by_pk?: Maybe<Accounts_Receivable_And_Subscription>;
  /** fetch data from the table: "accounts_receivable" using primary key columns */
  accounts_receivable_by_pk?: Maybe<Accounts_Receivable>;
  /** fetch data from the table: "activity" */
  activity: Array<Activity>;
  /** fetch aggregated fields from the table: "activity" */
  activity_aggregate: Activity_Aggregate;
  /** fetch data from the table: "activity" using primary key columns */
  activity_by_pk?: Maybe<Activity>;
  /** fetch data from the table: "address_sido" */
  address_sido: Array<Address_Sido>;
  /** fetch aggregated fields from the table: "address_sido" */
  address_sido_aggregate: Address_Sido_Aggregate;
  /** fetch data from the table: "address_sido" using primary key columns */
  address_sido_by_pk?: Maybe<Address_Sido>;
  /** fetch data from the table: "address_sigungu" */
  address_sigungu: Array<Address_Sigungu>;
  /** fetch aggregated fields from the table: "address_sigungu" */
  address_sigungu_aggregate: Address_Sigungu_Aggregate;
  /** fetch data from the table: "address_sigungu" using primary key columns */
  address_sigungu_by_pk?: Maybe<Address_Sigungu>;
  /** fetch data from the table: "adjustment" */
  adjustment: Array<Adjustment>;
  /** fetch aggregated fields from the table: "adjustment" */
  adjustment_aggregate: Adjustment_Aggregate;
  /** fetch data from the table: "adjustment_and_transfer" */
  adjustment_and_transfer: Array<Adjustment_And_Transfer>;
  /** fetch aggregated fields from the table: "adjustment_and_transfer" */
  adjustment_and_transfer_aggregate: Adjustment_And_Transfer_Aggregate;
  /** fetch data from the table: "adjustment_and_transfer" using primary key columns */
  adjustment_and_transfer_by_pk?: Maybe<Adjustment_And_Transfer>;
  /** fetch data from the table: "adjustment" using primary key columns */
  adjustment_by_pk?: Maybe<Adjustment>;
  /** fetch data from the table: "adjustment_status" */
  adjustment_status: Array<Adjustment_Status>;
  /** fetch aggregated fields from the table: "adjustment_status" */
  adjustment_status_aggregate: Adjustment_Status_Aggregate;
  /** fetch data from the table: "adjustment_status" using primary key columns */
  adjustment_status_by_pk?: Maybe<Adjustment_Status>;
  /** fetch data from the table: "blog_post" */
  blog_post: Array<Blog_Post>;
  /** fetch aggregated fields from the table: "blog_post" */
  blog_post_aggregate: Blog_Post_Aggregate;
  /** fetch data from the table: "blog_post_and_hashtag" */
  blog_post_and_hashtag: Array<Blog_Post_And_Hashtag>;
  /** fetch aggregated fields from the table: "blog_post_and_hashtag" */
  blog_post_and_hashtag_aggregate: Blog_Post_And_Hashtag_Aggregate;
  /** fetch data from the table: "blog_post_and_hashtag" using primary key columns */
  blog_post_and_hashtag_by_pk?: Maybe<Blog_Post_And_Hashtag>;
  /** fetch data from the table: "blog_post_and_view" */
  blog_post_and_view: Array<Blog_Post_And_View>;
  /** fetch aggregated fields from the table: "blog_post_and_view" */
  blog_post_and_view_aggregate: Blog_Post_And_View_Aggregate;
  /** fetch data from the table: "blog_post_and_view" using primary key columns */
  blog_post_and_view_by_pk?: Maybe<Blog_Post_And_View>;
  /** fetch data from the table: "blog_post" using primary key columns */
  blog_post_by_pk?: Maybe<Blog_Post>;
  /** fetch data from the table: "car" */
  car: Array<Car>;
  /** fetch aggregated fields from the table: "car" */
  car_aggregate: Car_Aggregate;
  /** fetch data from the table: "car" using primary key columns */
  car_by_pk?: Maybe<Car>;
  /** fetch data from the table: "car_inspection" */
  car_inspection: Array<Car_Inspection>;
  /** fetch aggregated fields from the table: "car_inspection" */
  car_inspection_aggregate: Car_Inspection_Aggregate;
  /** fetch data from the table: "car_inspection" using primary key columns */
  car_inspection_by_pk?: Maybe<Car_Inspection>;
  /** fetch data from the table: "car_inspection_status" */
  car_inspection_status: Array<Car_Inspection_Status>;
  /** fetch aggregated fields from the table: "car_inspection_status" */
  car_inspection_status_aggregate: Car_Inspection_Status_Aggregate;
  /** fetch data from the table: "car_inspection_status" using primary key columns */
  car_inspection_status_by_pk?: Maybe<Car_Inspection_Status>;
  /** fetch data from the table: "car_maker" */
  car_maker: Array<Car_Maker>;
  /** fetch aggregated fields from the table: "car_maker" */
  car_maker_aggregate: Car_Maker_Aggregate;
  /** fetch data from the table: "car_maker" using primary key columns */
  car_maker_by_pk?: Maybe<Car_Maker>;
  /** fetch data from the table: "car_origin" */
  car_origin: Array<Car_Origin>;
  /** fetch aggregated fields from the table: "car_origin" */
  car_origin_aggregate: Car_Origin_Aggregate;
  /** fetch data from the table: "car_origin" using primary key columns */
  car_origin_by_pk?: Maybe<Car_Origin>;
  /** fetch data from the table: "car_sunroof_type" */
  car_sunroof_type: Array<Car_Sunroof_Type>;
  /** fetch aggregated fields from the table: "car_sunroof_type" */
  car_sunroof_type_aggregate: Car_Sunroof_Type_Aggregate;
  /** fetch data from the table: "car_sunroof_type" using primary key columns */
  car_sunroof_type_by_pk?: Maybe<Car_Sunroof_Type>;
  /** fetch data from the table: "car_type" */
  car_type: Array<Car_Type>;
  /** fetch aggregated fields from the table: "car_type" */
  car_type_aggregate: Car_Type_Aggregate;
  /** fetch data from the table: "car_type" using primary key columns */
  car_type_by_pk?: Maybe<Car_Type>;
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>;
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate;
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>;
  /** fetch data from the table: "chat" */
  chat: Array<Chat>;
  /** fetch aggregated fields from the table: "chat" */
  chat_aggregate: Chat_Aggregate;
  /** fetch data from the table: "chat" using primary key columns */
  chat_by_pk?: Maybe<Chat>;
  /** fetch data from the table: "config" */
  config: Array<Config>;
  /** fetch aggregated fields from the table: "config" */
  config_aggregate: Config_Aggregate;
  /** fetch data from the table: "config" using primary key columns */
  config_by_pk?: Maybe<Config>;
  /** fetch data from the table: "config_type" */
  config_type: Array<Config_Type>;
  /** fetch aggregated fields from the table: "config_type" */
  config_type_aggregate: Config_Type_Aggregate;
  /** fetch data from the table: "config_type" using primary key columns */
  config_type_by_pk?: Maybe<Config_Type>;
  /** fetch data from the table: "coupon" */
  coupon: Array<Coupon>;
  /** fetch aggregated fields from the table: "coupon" */
  coupon_aggregate: Coupon_Aggregate;
  /** fetch data from the table: "coupon_and_account" */
  coupon_and_account: Array<Coupon_And_Account>;
  /** fetch aggregated fields from the table: "coupon_and_account" */
  coupon_and_account_aggregate: Coupon_And_Account_Aggregate;
  /** fetch data from the table: "coupon_and_account" using primary key columns */
  coupon_and_account_by_pk?: Maybe<Coupon_And_Account>;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  /** fetch data from the table: "coupon_status" */
  coupon_status: Array<Coupon_Status>;
  /** fetch aggregated fields from the table: "coupon_status" */
  coupon_status_aggregate: Coupon_Status_Aggregate;
  /** fetch data from the table: "coupon_status" using primary key columns */
  coupon_status_by_pk?: Maybe<Coupon_Status>;
  /** fetch data from the table: "curation" */
  curation: Array<Curation>;
  /** fetch aggregated fields from the table: "curation" */
  curation_aggregate: Curation_Aggregate;
  /** fetch data from the table: "curation" using primary key columns */
  curation_by_pk?: Maybe<Curation>;
  /** fetch data from the table: "fake_review" */
  fake_review: Array<Fake_Review>;
  /** fetch aggregated fields from the table: "fake_review" */
  fake_review_aggregate: Fake_Review_Aggregate;
  /** fetch data from the table: "fake_review" using primary key columns */
  fake_review_by_pk?: Maybe<Fake_Review>;
  /** fetch data from the table: "gallery" */
  gallery: Array<Gallery>;
  /** fetch aggregated fields from the table: "gallery" */
  gallery_aggregate: Gallery_Aggregate;
  /** fetch data from the table: "gallery" using primary key columns */
  gallery_by_pk?: Maybe<Gallery>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** execute function "get_account_performance_by_used_car_status" which returns "account_performance" */
  get_account_performance_by_used_car_status: Array<Account_Performance>;
  /** execute function "get_account_performance_by_used_car_status" and query aggregates on result of table type "account_performance" */
  get_account_performance_by_used_car_status_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_chat" which returns "account_performance" */
  get_account_performance_for_chat: Array<Account_Performance>;
  /** execute function "get_account_performance_for_chat" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_chat_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_first_call" which returns "account_performance" */
  get_account_performance_for_first_call: Array<Account_Performance>;
  /** execute function "get_account_performance_for_first_call" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_first_call_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_match" which returns "account_performance" */
  get_account_performance_for_match: Array<Account_Performance>;
  /** execute function "get_account_performance_for_match" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_match_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_reservation" which returns "account_performance" */
  get_account_performance_for_reservation: Array<Account_Performance>;
  /** execute function "get_account_performance_for_reservation" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_reservation_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_support" which returns "account_performance" */
  get_account_performance_for_support: Array<Account_Performance>;
  /** execute function "get_account_performance_for_support" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_support_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_throughput" which returns "account_throughput" */
  get_account_throughput: Array<Account_Throughput>;
  /** execute function "get_account_throughput" and query aggregates on result of table type "account_throughput" */
  get_account_throughput_aggregate: Account_Throughput_Aggregate;
  /** execute function "get_lead_status_statistics" which returns "lead_status_statistics" */
  get_lead_status_statistics: Array<Lead_Status_Statistics>;
  /** execute function "get_lead_status_statistics" and query aggregates on result of table type "lead_status_statistics" */
  get_lead_status_statistics_aggregate: Lead_Status_Statistics_Aggregate;
  /** fetch data from the table: "hashtag" */
  hashtag: Array<Hashtag>;
  /** fetch aggregated fields from the table: "hashtag" */
  hashtag_aggregate: Hashtag_Aggregate;
  /** fetch data from the table: "hashtag" using primary key columns */
  hashtag_by_pk?: Maybe<Hashtag>;
  /** fetch data from the table: "inventory" */
  inventory: Array<Inventory>;
  /** fetch aggregated fields from the table: "inventory" */
  inventory_aggregate: Inventory_Aggregate;
  /** fetch data from the table: "inventory" using primary key columns */
  inventory_by_pk?: Maybe<Inventory>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice_and_transaction" */
  invoice_and_transaction: Array<Invoice_And_Transaction>;
  /** fetch aggregated fields from the table: "invoice_and_transaction" */
  invoice_and_transaction_aggregate: Invoice_And_Transaction_Aggregate;
  /** fetch data from the table: "invoice_and_transaction" using primary key columns */
  invoice_and_transaction_by_pk?: Maybe<Invoice_And_Transaction>;
  /** fetch data from the table: "invoice_and_transfer" */
  invoice_and_transfer: Array<Invoice_And_Transfer>;
  /** fetch aggregated fields from the table: "invoice_and_transfer" */
  invoice_and_transfer_aggregate: Invoice_And_Transfer_Aggregate;
  /** fetch data from the table: "invoice_and_transfer" using primary key columns */
  invoice_and_transfer_by_pk?: Maybe<Invoice_And_Transfer>;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "invoice_status" */
  invoice_status: Array<Invoice_Status>;
  /** fetch aggregated fields from the table: "invoice_status" */
  invoice_status_aggregate: Invoice_Status_Aggregate;
  /** fetch data from the table: "invoice_status" using primary key columns */
  invoice_status_by_pk?: Maybe<Invoice_Status>;
  /** fetch data from the table: "kakao_and_account" */
  kakao_and_account: Array<Kakao_And_Account>;
  /** fetch aggregated fields from the table: "kakao_and_account" */
  kakao_and_account_aggregate: Kakao_And_Account_Aggregate;
  /** fetch data from the table: "kakao_and_account" using primary key columns */
  kakao_and_account_by_pk?: Maybe<Kakao_And_Account>;
  /** fetch data from the table: "lead" */
  lead: Array<Lead>;
  /** fetch aggregated fields from the table: "lead" */
  lead_aggregate: Lead_Aggregate;
  /** fetch data from the table: "lead" using primary key columns */
  lead_by_pk?: Maybe<Lead>;
  /** fetch data from the table: "lead_comment" */
  lead_comment: Array<Lead_Comment>;
  /** fetch aggregated fields from the table: "lead_comment" */
  lead_comment_aggregate: Lead_Comment_Aggregate;
  /** fetch data from the table: "lead_comment" using primary key columns */
  lead_comment_by_pk?: Maybe<Lead_Comment>;
  /** fetch data from the table: "lead_status" */
  lead_status: Array<Lead_Status>;
  /** fetch aggregated fields from the table: "lead_status" */
  lead_status_aggregate: Lead_Status_Aggregate;
  /** fetch data from the table: "lead_status" using primary key columns */
  lead_status_by_pk?: Maybe<Lead_Status>;
  /** fetch data from the table: "lead_status_statistics" */
  lead_status_statistics: Array<Lead_Status_Statistics>;
  /** fetch aggregated fields from the table: "lead_status_statistics" */
  lead_status_statistics_aggregate: Lead_Status_Statistics_Aggregate;
  /** fetch data from the table: "like" */
  like: Array<Like>;
  /** fetch aggregated fields from the table: "like" */
  like_aggregate: Like_Aggregate;
  /** fetch data from the table: "like" using primary key columns */
  like_by_pk?: Maybe<Like>;
  /** fetch data from the table: "membership" */
  membership: Array<Membership>;
  /** fetch aggregated fields from the table: "membership" */
  membership_aggregate: Membership_Aggregate;
  /** fetch data from the table: "membership" using primary key columns */
  membership_by_pk?: Maybe<Membership>;
  /** fetch data from the table: "membership_log" */
  membership_log: Array<Membership_Log>;
  /** fetch aggregated fields from the table: "membership_log" */
  membership_log_aggregate: Membership_Log_Aggregate;
  /** fetch data from the table: "membership_log" using primary key columns */
  membership_log_by_pk?: Maybe<Membership_Log>;
  /** fetch data from the table: "membership_type" */
  membership_type: Array<Membership_Type>;
  /** fetch aggregated fields from the table: "membership_type" */
  membership_type_aggregate: Membership_Type_Aggregate;
  /** fetch data from the table: "membership_type" using primary key columns */
  membership_type_by_pk?: Maybe<Membership_Type>;
  /** fetch data from the table: "n_cart" */
  n_cart: Array<N_Cart>;
  /** fetch aggregated fields from the table: "n_cart" */
  n_cart_aggregate: N_Cart_Aggregate;
  /** fetch data from the table: "n_cart" using primary key columns */
  n_cart_by_pk?: Maybe<N_Cart>;
  /** fetch data from the table: "n_order" */
  n_order: Array<N_Order>;
  /** fetch aggregated fields from the table: "n_order" */
  n_order_aggregate: N_Order_Aggregate;
  /** fetch data from the table: "n_order" using primary key columns */
  n_order_by_pk?: Maybe<N_Order>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order_and_review" */
  order_and_review: Array<Order_And_Review>;
  /** fetch aggregated fields from the table: "order_and_review" */
  order_and_review_aggregate: Order_And_Review_Aggregate;
  /** fetch data from the table: "order_and_review" using primary key columns */
  order_and_review_by_pk?: Maybe<Order_And_Review>;
  /** fetch data from the table: "order_and_transaction" */
  order_and_transaction: Array<Order_And_Transaction>;
  /** fetch aggregated fields from the table: "order_and_transaction" */
  order_and_transaction_aggregate: Order_And_Transaction_Aggregate;
  /** fetch data from the table: "order_and_transaction" using primary key columns */
  order_and_transaction_by_pk?: Maybe<Order_And_Transaction>;
  /** fetch data from the table: "order_and_transfer" */
  order_and_transfer: Array<Order_And_Transfer>;
  /** fetch aggregated fields from the table: "order_and_transfer" */
  order_and_transfer_aggregate: Order_And_Transfer_Aggregate;
  /** fetch data from the table: "order_and_transfer" using primary key columns */
  order_and_transfer_by_pk?: Maybe<Order_And_Transfer>;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_from_store" */
  order_from_store: Array<Order_From_Store>;
  /** fetch aggregated fields from the table: "order_from_store" */
  order_from_store_aggregate: Order_From_Store_Aggregate;
  /** fetch data from the table: "order_from_store" using primary key columns */
  order_from_store_by_pk?: Maybe<Order_From_Store>;
  /** fetch data from the table: "order_from_store_status" */
  order_from_store_status: Array<Order_From_Store_Status>;
  /** fetch aggregated fields from the table: "order_from_store_status" */
  order_from_store_status_aggregate: Order_From_Store_Status_Aggregate;
  /** fetch data from the table: "order_from_store_status" using primary key columns */
  order_from_store_status_by_pk?: Maybe<Order_From_Store_Status>;
  /** fetch data from the table: "order_item" */
  order_item: Array<Order_Item>;
  /** fetch aggregated fields from the table: "order_item" */
  order_item_aggregate: Order_Item_Aggregate;
  /** fetch data from the table: "order_item" using primary key columns */
  order_item_by_pk?: Maybe<Order_Item>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table: "package" */
  package: Array<Package>;
  /** fetch aggregated fields from the table: "package" */
  package_aggregate: Package_Aggregate;
  /** fetch data from the table: "package" using primary key columns */
  package_by_pk?: Maybe<Package>;
  /** execute function "package_store_search" which returns "view_package_store" */
  package_store_search: Array<View_Package_Store>;
  /** execute function "package_store_search" and query aggregates on result of table type "view_package_store" */
  package_store_search_aggregate: View_Package_Store_Aggregate;
  /** fetch data from the table: "package_type" */
  package_type: Array<Package_Type>;
  /** fetch aggregated fields from the table: "package_type" */
  package_type_aggregate: Package_Type_Aggregate;
  /** fetch data from the table: "package_type" using primary key columns */
  package_type_by_pk?: Maybe<Package_Type>;
  /** fetch data from the table: "payout" */
  payout: Array<Payout>;
  /** fetch aggregated fields from the table: "payout" */
  payout_aggregate: Payout_Aggregate;
  /** fetch data from the table: "payout_and_transfer" */
  payout_and_transfer: Array<Payout_And_Transfer>;
  /** fetch aggregated fields from the table: "payout_and_transfer" */
  payout_and_transfer_aggregate: Payout_And_Transfer_Aggregate;
  /** fetch data from the table: "payout_and_transfer" using primary key columns */
  payout_and_transfer_by_pk?: Maybe<Payout_And_Transfer>;
  /** fetch data from the table: "payout" using primary key columns */
  payout_by_pk?: Maybe<Payout>;
  /** fetch data from the table: "payout_status" */
  payout_status: Array<Payout_Status>;
  /** fetch aggregated fields from the table: "payout_status" */
  payout_status_aggregate: Payout_Status_Aggregate;
  /** fetch data from the table: "payout_status" using primary key columns */
  payout_status_by_pk?: Maybe<Payout_Status>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product_and_like" */
  product_and_like: Array<Product_And_Like>;
  /** fetch aggregated fields from the table: "product_and_like" */
  product_and_like_aggregate: Product_And_Like_Aggregate;
  /** fetch data from the table: "product_and_like" using primary key columns */
  product_and_like_by_pk?: Maybe<Product_And_Like>;
  /** fetch data from the table: "product_brand" */
  product_brand: Array<Product_Brand>;
  /** fetch aggregated fields from the table: "product_brand" */
  product_brand_aggregate: Product_Brand_Aggregate;
  /** fetch data from the table: "product_brand" using primary key columns */
  product_brand_by_pk?: Maybe<Product_Brand>;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_item" */
  product_item: Array<Product_Item>;
  /** fetch aggregated fields from the table: "product_item" */
  product_item_aggregate: Product_Item_Aggregate;
  /** fetch data from the table: "product_item_and_review" */
  product_item_and_review: Array<Product_Item_And_Review>;
  /** fetch aggregated fields from the table: "product_item_and_review" */
  product_item_and_review_aggregate: Product_Item_And_Review_Aggregate;
  /** fetch data from the table: "product_item_and_review" using primary key columns */
  product_item_and_review_by_pk?: Maybe<Product_Item_And_Review>;
  /** fetch data from the table: "product_item" using primary key columns */
  product_item_by_pk?: Maybe<Product_Item>;
  /** fetch data from the table: "product_item_price_by_store" */
  product_item_price_by_store: Array<Product_Item_Price_By_Store>;
  /** fetch aggregated fields from the table: "product_item_price_by_store" */
  product_item_price_by_store_aggregate: Product_Item_Price_By_Store_Aggregate;
  /** fetch data from the table: "product_item_price_by_store" using primary key columns */
  product_item_price_by_store_by_pk?: Maybe<Product_Item_Price_By_Store>;
  /** fetch data from the table: "product_type" */
  product_type: Array<Product_Type>;
  /** fetch aggregated fields from the table: "product_type" */
  product_type_aggregate: Product_Type_Aggregate;
  /** fetch data from the table: "product_type" using primary key columns */
  product_type_by_pk?: Maybe<Product_Type>;
  /** fetch data from the table: "profile_kr_customer" */
  profile_kr_customer: Array<Profile_Kr_Customer>;
  /** fetch aggregated fields from the table: "profile_kr_customer" */
  profile_kr_customer_aggregate: Profile_Kr_Customer_Aggregate;
  /** fetch data from the table: "profile_kr_customer" using primary key columns */
  profile_kr_customer_by_pk?: Maybe<Profile_Kr_Customer>;
  /** fetch data from the table: "profile_kr_partner" */
  profile_kr_partner: Array<Profile_Kr_Partner>;
  /** fetch aggregated fields from the table: "profile_kr_partner" */
  profile_kr_partner_aggregate: Profile_Kr_Partner_Aggregate;
  /** fetch data from the table: "profile_kr_partner" using primary key columns */
  profile_kr_partner_by_pk?: Maybe<Profile_Kr_Partner>;
  /** fetch data from the table: "profile_language" */
  profile_language: Array<Profile_Language>;
  /** fetch aggregated fields from the table: "profile_language" */
  profile_language_aggregate: Profile_Language_Aggregate;
  /** fetch data from the table: "profile_language" using primary key columns */
  profile_language_by_pk?: Maybe<Profile_Language>;
  /** fetch data from the table: "profile_riderdash" */
  profile_riderdash: Array<Profile_Riderdash>;
  /** fetch aggregated fields from the table: "profile_riderdash" */
  profile_riderdash_aggregate: Profile_Riderdash_Aggregate;
  /** fetch data from the table: "profile_riderdash" using primary key columns */
  profile_riderdash_by_pk?: Maybe<Profile_Riderdash>;
  /** fetch data from the table: "profile_riderdash_role" */
  profile_riderdash_role: Array<Profile_Riderdash_Role>;
  /** fetch aggregated fields from the table: "profile_riderdash_role" */
  profile_riderdash_role_aggregate: Profile_Riderdash_Role_Aggregate;
  /** fetch data from the table: "profile_riderdash_role" using primary key columns */
  profile_riderdash_role_by_pk?: Maybe<Profile_Riderdash_Role>;
  /** fetch data from the table: "profile_us_customer" */
  profile_us_customer: Array<Profile_Us_Customer>;
  /** fetch aggregated fields from the table: "profile_us_customer" */
  profile_us_customer_aggregate: Profile_Us_Customer_Aggregate;
  /** fetch data from the table: "profile_us_customer" using primary key columns */
  profile_us_customer_by_pk?: Maybe<Profile_Us_Customer>;
  /** fetch data from the table: "profile_us_partner" */
  profile_us_partner: Array<Profile_Us_Partner>;
  /** fetch aggregated fields from the table: "profile_us_partner" */
  profile_us_partner_aggregate: Profile_Us_Partner_Aggregate;
  /** fetch data from the table: "profile_us_partner" using primary key columns */
  profile_us_partner_by_pk?: Maybe<Profile_Us_Partner>;
  /** fetch data from the table: "promotion" */
  promotion: Array<Promotion>;
  /** fetch aggregated fields from the table: "promotion" */
  promotion_aggregate: Promotion_Aggregate;
  /** fetch data from the table: "promotion" using primary key columns */
  promotion_by_pk?: Maybe<Promotion>;
  /** fetch data from the table: "quote" */
  quote: Array<Quote>;
  /** fetch aggregated fields from the table: "quote" */
  quote_aggregate: Quote_Aggregate;
  /** fetch data from the table: "quote" using primary key columns */
  quote_by_pk?: Maybe<Quote>;
  /** fetch data from the table: "quote_item" */
  quote_item: Array<Quote_Item>;
  /** fetch aggregated fields from the table: "quote_item" */
  quote_item_aggregate: Quote_Item_Aggregate;
  /** fetch data from the table: "quote_item" using primary key columns */
  quote_item_by_pk?: Maybe<Quote_Item>;
  /** fetch data from the table: "quote_request" */
  quote_request: Array<Quote_Request>;
  /** fetch aggregated fields from the table: "quote_request" */
  quote_request_aggregate: Quote_Request_Aggregate;
  /** fetch data from the table: "quote_request" using primary key columns */
  quote_request_by_pk?: Maybe<Quote_Request>;
  /** fetch data from the table: "raster_columns" */
  raster_columns: Array<Raster_Columns>;
  /** fetch aggregated fields from the table: "raster_columns" */
  raster_columns_aggregate: Raster_Columns_Aggregate;
  /** fetch data from the table: "raster_overviews" */
  raster_overviews: Array<Raster_Overviews>;
  /** fetch aggregated fields from the table: "raster_overviews" */
  raster_overviews_aggregate: Raster_Overviews_Aggregate;
  /** fetch data from the table: "refund" */
  refund: Array<Refund>;
  /** fetch aggregated fields from the table: "refund" */
  refund_aggregate: Refund_Aggregate;
  /** fetch data from the table: "refund_and_transaction" */
  refund_and_transaction: Array<Refund_And_Transaction>;
  /** fetch aggregated fields from the table: "refund_and_transaction" */
  refund_and_transaction_aggregate: Refund_And_Transaction_Aggregate;
  /** fetch data from the table: "refund_and_transaction" using primary key columns */
  refund_and_transaction_by_pk?: Maybe<Refund_And_Transaction>;
  /** fetch data from the table: "refund_and_transfer" */
  refund_and_transfer: Array<Refund_And_Transfer>;
  /** fetch aggregated fields from the table: "refund_and_transfer" */
  refund_and_transfer_aggregate: Refund_And_Transfer_Aggregate;
  /** fetch data from the table: "refund_and_transfer" using primary key columns */
  refund_and_transfer_by_pk?: Maybe<Refund_And_Transfer>;
  /** fetch data from the table: "refund" using primary key columns */
  refund_by_pk?: Maybe<Refund>;
  /** fetch data from the table: "refund_status" */
  refund_status: Array<Refund_Status>;
  /** fetch aggregated fields from the table: "refund_status" */
  refund_status_aggregate: Refund_Status_Aggregate;
  /** fetch data from the table: "refund_status" using primary key columns */
  refund_status_by_pk?: Maybe<Refund_Status>;
  /** fetch data from the table: "review" */
  review: Array<Review>;
  /** fetch aggregated fields from the table: "review" */
  review_aggregate: Review_Aggregate;
  /** fetch data from the table: "review" using primary key columns */
  review_by_pk?: Maybe<Review>;
  /** fetch data from the table: "review_status" */
  review_status: Array<Review_Status>;
  /** fetch aggregated fields from the table: "review_status" */
  review_status_aggregate: Review_Status_Aggregate;
  /** fetch data from the table: "review_status" using primary key columns */
  review_status_by_pk?: Maybe<Review_Status>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "slot" */
  slot: Array<Slot>;
  /** fetch aggregated fields from the table: "slot" */
  slot_aggregate: Slot_Aggregate;
  /** fetch data from the table: "slot" using primary key columns */
  slot_by_pk?: Maybe<Slot>;
  /** fetch data from the table: "sole" */
  sole: Array<Sole>;
  /** fetch aggregated fields from the table: "sole" */
  sole_aggregate: Sole_Aggregate;
  /** fetch data from the table: "sole" using primary key columns */
  sole_by_pk?: Maybe<Sole>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table: "store" */
  store: Array<Store>;
  /** fetch data from the table: "store_account_role" */
  store_account_role: Array<Store_Account_Role>;
  /** fetch aggregated fields from the table: "store_account_role" */
  store_account_role_aggregate: Store_Account_Role_Aggregate;
  /** fetch data from the table: "store_account_role" using primary key columns */
  store_account_role_by_pk?: Maybe<Store_Account_Role>;
  /** fetch aggregated fields from the table: "store" */
  store_aggregate: Store_Aggregate;
  /** fetch data from the table: "store_and_account" */
  store_and_account: Array<Store_And_Account>;
  /** fetch aggregated fields from the table: "store_and_account" */
  store_and_account_aggregate: Store_And_Account_Aggregate;
  /** fetch data from the table: "store_and_account" using primary key columns */
  store_and_account_by_pk?: Maybe<Store_And_Account>;
  /** fetch data from the table: "store_and_hashtag" */
  store_and_hashtag: Array<Store_And_Hashtag>;
  /** fetch aggregated fields from the table: "store_and_hashtag" */
  store_and_hashtag_aggregate: Store_And_Hashtag_Aggregate;
  /** fetch data from the table: "store_and_hashtag" using primary key columns */
  store_and_hashtag_by_pk?: Maybe<Store_And_Hashtag>;
  /** fetch data from the table: "store_and_like" */
  store_and_like: Array<Store_And_Like>;
  /** fetch aggregated fields from the table: "store_and_like" */
  store_and_like_aggregate: Store_And_Like_Aggregate;
  /** fetch data from the table: "store_and_like" using primary key columns */
  store_and_like_by_pk?: Maybe<Store_And_Like>;
  /** fetch data from the table: "store_and_package" */
  store_and_package: Array<Store_And_Package>;
  /** fetch aggregated fields from the table: "store_and_package" */
  store_and_package_aggregate: Store_And_Package_Aggregate;
  /** fetch data from the table: "store_and_package" using primary key columns */
  store_and_package_by_pk?: Maybe<Store_And_Package>;
  /** fetch data from the table: "store_and_product" */
  store_and_product: Array<Store_And_Product>;
  /** fetch aggregated fields from the table: "store_and_product" */
  store_and_product_aggregate: Store_And_Product_Aggregate;
  /** fetch data from the table: "store_and_product" using primary key columns */
  store_and_product_by_pk?: Maybe<Store_And_Product>;
  /** fetch data from the table: "store_and_product_item" */
  store_and_product_item: Array<Store_And_Product_Item>;
  /** fetch aggregated fields from the table: "store_and_product_item" */
  store_and_product_item_aggregate: Store_And_Product_Item_Aggregate;
  /** fetch data from the table: "store_and_product_item" using primary key columns */
  store_and_product_item_by_pk?: Maybe<Store_And_Product_Item>;
  /** fetch data from the table: "store_and_review" */
  store_and_review: Array<Store_And_Review>;
  /** fetch aggregated fields from the table: "store_and_review" */
  store_and_review_aggregate: Store_And_Review_Aggregate;
  /** fetch data from the table: "store_and_review" using primary key columns */
  store_and_review_by_pk?: Maybe<Store_And_Review>;
  /** fetch data from the table: "store_and_sole" */
  store_and_sole: Array<Store_And_Sole>;
  /** fetch aggregated fields from the table: "store_and_sole" */
  store_and_sole_aggregate: Store_And_Sole_Aggregate;
  /** fetch data from the table: "store_and_sole" using primary key columns */
  store_and_sole_by_pk?: Maybe<Store_And_Sole>;
  /** execute function "store_by_date_only" which returns "view_store_gallery" */
  store_by_date_only: Array<View_Store_Gallery>;
  /** execute function "store_by_date_only" and query aggregates on result of table type "view_store_gallery" */
  store_by_date_only_aggregate: View_Store_Gallery_Aggregate;
  /** fetch data from the table: "store" using primary key columns */
  store_by_pk?: Maybe<Store>;
  /** execute function "store_search_keyword" which returns "view_store" */
  store_search_keyword: Array<View_Store>;
  /** execute function "store_search_keyword" and query aggregates on result of table type "view_store" */
  store_search_keyword_aggregate: View_Store_Aggregate;
  /** execute function "store_search_polygon" which returns "view_store" */
  store_search_polygon: Array<View_Store>;
  /** execute function "store_search_polygon" and query aggregates on result of table type "view_store" */
  store_search_polygon_aggregate: View_Store_Aggregate;
  /** fetch data from the table: "store_status" */
  store_status: Array<Store_Status>;
  /** fetch aggregated fields from the table: "store_status" */
  store_status_aggregate: Store_Status_Aggregate;
  /** fetch data from the table: "store_status" using primary key columns */
  store_status_by_pk?: Maybe<Store_Status>;
  /** fetch data from the table: "store_with_rating" */
  store_with_rating: Array<Store_With_Rating>;
  /** fetch aggregated fields from the table: "store_with_rating" */
  store_with_rating_aggregate: Store_With_Rating_Aggregate;
  /** fetch data from the table: "store_with_rating_of_fake" */
  store_with_rating_of_fake: Array<Store_With_Rating_Of_Fake>;
  /** fetch aggregated fields from the table: "store_with_rating_of_fake" */
  store_with_rating_of_fake_aggregate: Store_With_Rating_Of_Fake_Aggregate;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription_and_transaction" */
  subscription_and_transaction: Array<Subscription_And_Transaction>;
  /** fetch aggregated fields from the table: "subscription_and_transaction" */
  subscription_and_transaction_aggregate: Subscription_And_Transaction_Aggregate;
  /** fetch data from the table: "subscription_and_transaction" using primary key columns */
  subscription_and_transaction_by_pk?: Maybe<Subscription_And_Transaction>;
  /** fetch data from the table: "subscription_and_transfer" */
  subscription_and_transfer: Array<Subscription_And_Transfer>;
  /** fetch aggregated fields from the table: "subscription_and_transfer" */
  subscription_and_transfer_aggregate: Subscription_And_Transfer_Aggregate;
  /** fetch data from the table: "subscription_and_transfer" using primary key columns */
  subscription_and_transfer_by_pk?: Maybe<Subscription_And_Transfer>;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table: "subscription_status" */
  subscription_status: Array<Subscription_Status>;
  /** fetch aggregated fields from the table: "subscription_status" */
  subscription_status_aggregate: Subscription_Status_Aggregate;
  /** fetch data from the table: "subscription_status" using primary key columns */
  subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<Transaction_Type>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: Transaction_Type_Aggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** fetch data from the table: "transfer" */
  transfer: Array<Transfer>;
  /** fetch aggregated fields from the table: "transfer" */
  transfer_aggregate: Transfer_Aggregate;
  /** fetch data from the table: "transfer" using primary key columns */
  transfer_by_pk?: Maybe<Transfer>;
  /** fetch data from the table: "transfer_type" */
  transfer_type: Array<Transfer_Type>;
  /** fetch aggregated fields from the table: "transfer_type" */
  transfer_type_aggregate: Transfer_Type_Aggregate;
  /** fetch data from the table: "transfer_type" using primary key columns */
  transfer_type_by_pk?: Maybe<Transfer_Type>;
  /** fetch data from the table: "user_actions_history" */
  user_actions_history: Array<User_Actions_History>;
  /** fetch aggregated fields from the table: "user_actions_history" */
  user_actions_history_aggregate: User_Actions_History_Aggregate;
  /** fetch data from the table: "user_actions_history" using primary key columns */
  user_actions_history_by_pk?: Maybe<User_Actions_History>;
  /** fetch data from the table: "view" */
  view: Array<View>;
  /** fetch aggregated fields from the table: "view" */
  view_aggregate: View_Aggregate;
  /** fetch data from the table: "view" using primary key columns */
  view_by_pk?: Maybe<View>;
  /** fetch data from the table: "view_package_store" */
  view_package_store: Array<View_Package_Store>;
  /** fetch aggregated fields from the table: "view_package_store" */
  view_package_store_aggregate: View_Package_Store_Aggregate;
  /** fetch data from the table: "view_package_store" using primary key columns */
  view_package_store_by_pk?: Maybe<View_Package_Store>;
  /** fetch data from the table: "view_store" */
  view_store: Array<View_Store>;
  /** fetch aggregated fields from the table: "view_store" */
  view_store_aggregate: View_Store_Aggregate;
  /** fetch data from the table: "view_store" using primary key columns */
  view_store_by_pk?: Maybe<View_Store>;
  /** fetch data from the table: "view_store_gallery" */
  view_store_gallery: Array<View_Store_Gallery>;
  /** fetch aggregated fields from the table: "view_store_gallery" */
  view_store_gallery_aggregate: View_Store_Gallery_Aggregate;
  /** fetch data from the table: "view_store_gallery" using primary key columns */
  view_store_gallery_by_pk?: Maybe<View_Store_Gallery>;
};


export type Query_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccount_PerformanceArgs = {
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootAccount_Performance_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootAccount_ThroughputArgs = {
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Query_RootAccount_Throughput_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Query_RootAccount_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Type_Order_By>>;
  where?: Maybe<Account_Type_Bool_Exp>;
};


export type Query_RootAccount_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Type_Order_By>>;
  where?: Maybe<Account_Type_Bool_Exp>;
};


export type Query_RootAccount_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAccounts_PayableArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_Order_By>>;
  where?: Maybe<Accounts_Payable_Bool_Exp>;
};


export type Query_RootAccounts_Payable_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_Order_By>>;
  where?: Maybe<Accounts_Payable_Bool_Exp>;
};


export type Query_RootAccounts_Payable_And_AdjustmentArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


export type Query_RootAccounts_Payable_And_Adjustment_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


export type Query_RootAccounts_Payable_And_Adjustment_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  adjustment: Scalars['uuid'];
};


export type Query_RootAccounts_Payable_And_PayoutArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


export type Query_RootAccounts_Payable_And_Payout_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


export type Query_RootAccounts_Payable_And_Payout_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  payout: Scalars['uuid'];
};


export type Query_RootAccounts_Payable_And_RefundArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};


export type Query_RootAccounts_Payable_And_Refund_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};


export type Query_RootAccounts_Payable_And_Refund_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  refund: Scalars['uuid'];
};


export type Query_RootAccounts_Payable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccounts_ReceivableArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_Order_By>>;
  where?: Maybe<Accounts_Receivable_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_Order_By>>;
  where?: Maybe<Accounts_Receivable_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_And_InvoiceArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_And_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_And_Invoice_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  invoice: Scalars['uuid'];
};


export type Query_RootAccounts_Receivable_And_MembershipArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_And_Membership_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_And_Membership_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  membership: Scalars['uuid'];
};


export type Query_RootAccounts_Receivable_And_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_And_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};


export type Query_RootAccounts_Receivable_And_Subscription_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  subscription: Scalars['uuid'];
};


export type Query_RootAccounts_Receivable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootActivityArgs = {
  distinct_on?: Maybe<Array<Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Order_By>>;
  where?: Maybe<Activity_Bool_Exp>;
};


export type Query_RootActivity_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Order_By>>;
  where?: Maybe<Activity_Bool_Exp>;
};


export type Query_RootActivity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAddress_SidoArgs = {
  distinct_on?: Maybe<Array<Address_Sido_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sido_Order_By>>;
  where?: Maybe<Address_Sido_Bool_Exp>;
};


export type Query_RootAddress_Sido_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Sido_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sido_Order_By>>;
  where?: Maybe<Address_Sido_Bool_Exp>;
};


export type Query_RootAddress_Sido_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAddress_SigunguArgs = {
  distinct_on?: Maybe<Array<Address_Sigungu_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sigungu_Order_By>>;
  where?: Maybe<Address_Sigungu_Bool_Exp>;
};


export type Query_RootAddress_Sigungu_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Sigungu_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sigungu_Order_By>>;
  where?: Maybe<Address_Sigungu_Bool_Exp>;
};


export type Query_RootAddress_Sigungu_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAdjustmentArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


export type Query_RootAdjustment_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


export type Query_RootAdjustment_And_TransferArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};


export type Query_RootAdjustment_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};


export type Query_RootAdjustment_And_Transfer_By_PkArgs = {
  adjustment: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Query_RootAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAdjustment_StatusArgs = {
  distinct_on?: Maybe<Array<Adjustment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Status_Order_By>>;
  where?: Maybe<Adjustment_Status_Bool_Exp>;
};


export type Query_RootAdjustment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Status_Order_By>>;
  where?: Maybe<Adjustment_Status_Bool_Exp>;
};


export type Query_RootAdjustment_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootBlog_PostArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


export type Query_RootBlog_Post_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


export type Query_RootBlog_Post_And_HashtagArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


export type Query_RootBlog_Post_And_Hashtag_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


export type Query_RootBlog_Post_And_Hashtag_By_PkArgs = {
  blog_post: Scalars['uuid'];
  hashtag: Scalars['uuid'];
};


export type Query_RootBlog_Post_And_ViewArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};


export type Query_RootBlog_Post_And_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};


export type Query_RootBlog_Post_And_View_By_PkArgs = {
  blog_post: Scalars['uuid'];
  view: Scalars['uuid'];
};


export type Query_RootBlog_Post_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCarArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


export type Query_RootCar_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


export type Query_RootCar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCar_InspectionArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


export type Query_RootCar_Inspection_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


export type Query_RootCar_Inspection_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCar_Inspection_StatusArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Status_Order_By>>;
  where?: Maybe<Car_Inspection_Status_Bool_Exp>;
};


export type Query_RootCar_Inspection_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Status_Order_By>>;
  where?: Maybe<Car_Inspection_Status_Bool_Exp>;
};


export type Query_RootCar_Inspection_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCar_MakerArgs = {
  distinct_on?: Maybe<Array<Car_Maker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Maker_Order_By>>;
  where?: Maybe<Car_Maker_Bool_Exp>;
};


export type Query_RootCar_Maker_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Maker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Maker_Order_By>>;
  where?: Maybe<Car_Maker_Bool_Exp>;
};


export type Query_RootCar_Maker_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCar_OriginArgs = {
  distinct_on?: Maybe<Array<Car_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Origin_Order_By>>;
  where?: Maybe<Car_Origin_Bool_Exp>;
};


export type Query_RootCar_Origin_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Origin_Order_By>>;
  where?: Maybe<Car_Origin_Bool_Exp>;
};


export type Query_RootCar_Origin_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCar_Sunroof_TypeArgs = {
  distinct_on?: Maybe<Array<Car_Sunroof_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Sunroof_Type_Order_By>>;
  where?: Maybe<Car_Sunroof_Type_Bool_Exp>;
};


export type Query_RootCar_Sunroof_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Sunroof_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Sunroof_Type_Order_By>>;
  where?: Maybe<Car_Sunroof_Type_Bool_Exp>;
};


export type Query_RootCar_Sunroof_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCar_TypeArgs = {
  distinct_on?: Maybe<Array<Car_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Type_Order_By>>;
  where?: Maybe<Car_Type_Bool_Exp>;
};


export type Query_RootCar_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Type_Order_By>>;
  where?: Maybe<Car_Type_Bool_Exp>;
};


export type Query_RootCar_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


export type Query_RootCart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


export type Query_RootCart_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootChatArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


export type Query_RootChat_AggregateArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


export type Query_RootChat_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConfigArgs = {
  distinct_on?: Maybe<Array<Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Order_By>>;
  where?: Maybe<Config_Bool_Exp>;
};


export type Query_RootConfig_AggregateArgs = {
  distinct_on?: Maybe<Array<Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Order_By>>;
  where?: Maybe<Config_Bool_Exp>;
};


export type Query_RootConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConfig_TypeArgs = {
  distinct_on?: Maybe<Array<Config_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Type_Order_By>>;
  where?: Maybe<Config_Type_Bool_Exp>;
};


export type Query_RootConfig_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Config_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Type_Order_By>>;
  where?: Maybe<Config_Type_Bool_Exp>;
};


export type Query_RootConfig_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCouponArgs = {
  distinct_on?: Maybe<Array<Coupon_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Order_By>>;
  where?: Maybe<Coupon_Bool_Exp>;
};


export type Query_RootCoupon_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Order_By>>;
  where?: Maybe<Coupon_Bool_Exp>;
};


export type Query_RootCoupon_And_AccountArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


export type Query_RootCoupon_And_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


export type Query_RootCoupon_And_Account_By_PkArgs = {
  account: Scalars['uuid'];
  coupon: Scalars['uuid'];
};


export type Query_RootCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCoupon_StatusArgs = {
  distinct_on?: Maybe<Array<Coupon_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Status_Order_By>>;
  where?: Maybe<Coupon_Status_Bool_Exp>;
};


export type Query_RootCoupon_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Status_Order_By>>;
  where?: Maybe<Coupon_Status_Bool_Exp>;
};


export type Query_RootCoupon_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCurationArgs = {
  distinct_on?: Maybe<Array<Curation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Curation_Order_By>>;
  where?: Maybe<Curation_Bool_Exp>;
};


export type Query_RootCuration_AggregateArgs = {
  distinct_on?: Maybe<Array<Curation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Curation_Order_By>>;
  where?: Maybe<Curation_Bool_Exp>;
};


export type Query_RootCuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFake_ReviewArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


export type Query_RootFake_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


export type Query_RootFake_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGalleryArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


export type Query_RootGallery_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


export type Query_RootGallery_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGeography_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeography_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeometry_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_By_Used_Car_StatusArgs = {
  args: Get_Account_Performance_By_Used_Car_Status_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_By_Used_Car_Status_AggregateArgs = {
  args: Get_Account_Performance_By_Used_Car_Status_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_ChatArgs = {
  args: Get_Account_Performance_For_Chat_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_Chat_AggregateArgs = {
  args: Get_Account_Performance_For_Chat_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_First_CallArgs = {
  args: Get_Account_Performance_For_First_Call_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_First_Call_AggregateArgs = {
  args: Get_Account_Performance_For_First_Call_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_MatchArgs = {
  args: Get_Account_Performance_For_Match_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_Match_AggregateArgs = {
  args: Get_Account_Performance_For_Match_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_ReservationArgs = {
  args: Get_Account_Performance_For_Reservation_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_Reservation_AggregateArgs = {
  args: Get_Account_Performance_For_Reservation_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_SupportArgs = {
  args: Get_Account_Performance_For_Support_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_Performance_For_Support_AggregateArgs = {
  args: Get_Account_Performance_For_Support_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Query_RootGet_Account_ThroughputArgs = {
  args: Get_Account_Throughput_Args;
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Query_RootGet_Account_Throughput_AggregateArgs = {
  args: Get_Account_Throughput_Args;
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Query_RootGet_Lead_Status_StatisticsArgs = {
  args: Get_Lead_Status_Statistics_Args;
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Query_RootGet_Lead_Status_Statistics_AggregateArgs = {
  args: Get_Lead_Status_Statistics_Args;
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Query_RootHashtagArgs = {
  distinct_on?: Maybe<Array<Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hashtag_Order_By>>;
  where?: Maybe<Hashtag_Bool_Exp>;
};


export type Query_RootHashtag_AggregateArgs = {
  distinct_on?: Maybe<Array<Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hashtag_Order_By>>;
  where?: Maybe<Hashtag_Bool_Exp>;
};


export type Query_RootHashtag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInventoryArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


export type Query_RootInventory_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


export type Query_RootInventory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


export type Query_RootInvoice_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


export type Query_RootInvoice_And_Transaction_By_PkArgs = {
  invoice: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Query_RootInvoice_And_TransferArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


export type Query_RootInvoice_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


export type Query_RootInvoice_And_Transfer_By_PkArgs = {
  invoice: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Query_RootInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoice_StatusArgs = {
  distinct_on?: Maybe<Array<Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Status_Order_By>>;
  where?: Maybe<Invoice_Status_Bool_Exp>;
};


export type Query_RootInvoice_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Status_Order_By>>;
  where?: Maybe<Invoice_Status_Bool_Exp>;
};


export type Query_RootInvoice_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootKakao_And_AccountArgs = {
  distinct_on?: Maybe<Array<Kakao_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kakao_And_Account_Order_By>>;
  where?: Maybe<Kakao_And_Account_Bool_Exp>;
};


export type Query_RootKakao_And_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Kakao_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kakao_And_Account_Order_By>>;
  where?: Maybe<Kakao_And_Account_Bool_Exp>;
};


export type Query_RootKakao_And_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


export type Query_RootLead_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


export type Query_RootLead_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLead_CommentArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


export type Query_RootLead_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


export type Query_RootLead_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLead_StatusArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Order_By>>;
  where?: Maybe<Lead_Status_Bool_Exp>;
};


export type Query_RootLead_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Order_By>>;
  where?: Maybe<Lead_Status_Bool_Exp>;
};


export type Query_RootLead_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLead_Status_StatisticsArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Query_RootLead_Status_Statistics_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Query_RootLikeArgs = {
  distinct_on?: Maybe<Array<Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Like_Order_By>>;
  where?: Maybe<Like_Bool_Exp>;
};


export type Query_RootLike_AggregateArgs = {
  distinct_on?: Maybe<Array<Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Like_Order_By>>;
  where?: Maybe<Like_Bool_Exp>;
};


export type Query_RootLike_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembershipArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};


export type Query_RootMembership_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};


export type Query_RootMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembership_LogArgs = {
  distinct_on?: Maybe<Array<Membership_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Log_Order_By>>;
  where?: Maybe<Membership_Log_Bool_Exp>;
};


export type Query_RootMembership_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Log_Order_By>>;
  where?: Maybe<Membership_Log_Bool_Exp>;
};


export type Query_RootMembership_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembership_TypeArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Query_RootMembership_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Query_RootMembership_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootN_CartArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


export type Query_RootN_Cart_AggregateArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


export type Query_RootN_Cart_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootN_OrderArgs = {
  distinct_on?: Maybe<Array<N_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Order_Order_By>>;
  where?: Maybe<N_Order_Bool_Exp>;
};


export type Query_RootN_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<N_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Order_Order_By>>;
  where?: Maybe<N_Order_Bool_Exp>;
};


export type Query_RootN_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrderArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Query_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Query_RootOrder_And_ReviewArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


export type Query_RootOrder_And_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


export type Query_RootOrder_And_Review_By_PkArgs = {
  order: Scalars['uuid'];
  review: Scalars['uuid'];
};


export type Query_RootOrder_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


export type Query_RootOrder_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


export type Query_RootOrder_And_Transaction_By_PkArgs = {
  order: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Query_RootOrder_And_TransferArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


export type Query_RootOrder_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


export type Query_RootOrder_And_Transfer_By_PkArgs = {
  order: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Query_RootOrder_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrder_From_StoreArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};


export type Query_RootOrder_From_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};


export type Query_RootOrder_From_Store_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrder_From_Store_StatusArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Status_Order_By>>;
  where?: Maybe<Order_From_Store_Status_Bool_Exp>;
};


export type Query_RootOrder_From_Store_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Status_Order_By>>;
  where?: Maybe<Order_From_Store_Status_Bool_Exp>;
};


export type Query_RootOrder_From_Store_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootOrder_ItemArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


export type Query_RootOrder_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


export type Query_RootOrder_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrder_StatusArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPackageArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


export type Query_RootPackage_AggregateArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


export type Query_RootPackage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPackage_Store_SearchArgs = {
  args: Package_Store_Search_Args;
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Query_RootPackage_Store_Search_AggregateArgs = {
  args: Package_Store_Search_Args;
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Query_RootPackage_TypeArgs = {
  distinct_on?: Maybe<Array<Package_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Type_Order_By>>;
  where?: Maybe<Package_Type_Bool_Exp>;
};


export type Query_RootPackage_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Package_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Type_Order_By>>;
  where?: Maybe<Package_Type_Bool_Exp>;
};


export type Query_RootPackage_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPayoutArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


export type Query_RootPayout_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


export type Query_RootPayout_And_TransferArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};


export type Query_RootPayout_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};


export type Query_RootPayout_And_Transfer_By_PkArgs = {
  payout: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Query_RootPayout_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayout_StatusArgs = {
  distinct_on?: Maybe<Array<Payout_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Status_Order_By>>;
  where?: Maybe<Payout_Status_Bool_Exp>;
};


export type Query_RootPayout_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Status_Order_By>>;
  where?: Maybe<Payout_Status_Bool_Exp>;
};


export type Query_RootPayout_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_And_LikeArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


export type Query_RootProduct_And_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


export type Query_RootProduct_And_Like_By_PkArgs = {
  like: Scalars['uuid'];
  product: Scalars['uuid'];
};


export type Query_RootProduct_BrandArgs = {
  distinct_on?: Maybe<Array<Product_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Brand_Order_By>>;
  where?: Maybe<Product_Brand_Bool_Exp>;
};


export type Query_RootProduct_Brand_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Brand_Order_By>>;
  where?: Maybe<Product_Brand_Bool_Exp>;
};


export type Query_RootProduct_Brand_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProduct_ItemArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


export type Query_RootProduct_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


export type Query_RootProduct_Item_And_ReviewArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


export type Query_RootProduct_Item_And_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


export type Query_RootProduct_Item_And_Review_By_PkArgs = {
  product_item: Scalars['uuid'];
  review: Scalars['uuid'];
};


export type Query_RootProduct_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProduct_Item_Price_By_StoreArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


export type Query_RootProduct_Item_Price_By_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


export type Query_RootProduct_Item_Price_By_Store_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProduct_TypeArgs = {
  distinct_on?: Maybe<Array<Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Type_Order_By>>;
  where?: Maybe<Product_Type_Bool_Exp>;
};


export type Query_RootProduct_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Type_Order_By>>;
  where?: Maybe<Product_Type_Bool_Exp>;
};


export type Query_RootProduct_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProfile_Kr_CustomerArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


export type Query_RootProfile_Kr_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


export type Query_RootProfile_Kr_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfile_Kr_PartnerArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


export type Query_RootProfile_Kr_Partner_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


export type Query_RootProfile_Kr_Partner_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfile_LanguageArgs = {
  distinct_on?: Maybe<Array<Profile_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Language_Order_By>>;
  where?: Maybe<Profile_Language_Bool_Exp>;
};


export type Query_RootProfile_Language_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Language_Order_By>>;
  where?: Maybe<Profile_Language_Bool_Exp>;
};


export type Query_RootProfile_Language_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProfile_RiderdashArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};


export type Query_RootProfile_Riderdash_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};


export type Query_RootProfile_Riderdash_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfile_Riderdash_RoleArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Role_Order_By>>;
  where?: Maybe<Profile_Riderdash_Role_Bool_Exp>;
};


export type Query_RootProfile_Riderdash_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Role_Order_By>>;
  where?: Maybe<Profile_Riderdash_Role_Bool_Exp>;
};


export type Query_RootProfile_Riderdash_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProfile_Us_CustomerArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


export type Query_RootProfile_Us_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


export type Query_RootProfile_Us_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfile_Us_PartnerArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};


export type Query_RootProfile_Us_Partner_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};


export type Query_RootProfile_Us_Partner_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPromotionArgs = {
  distinct_on?: Maybe<Array<Promotion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotion_Order_By>>;
  where?: Maybe<Promotion_Bool_Exp>;
};


export type Query_RootPromotion_AggregateArgs = {
  distinct_on?: Maybe<Array<Promotion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotion_Order_By>>;
  where?: Maybe<Promotion_Bool_Exp>;
};


export type Query_RootPromotion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuoteArgs = {
  distinct_on?: Maybe<Array<Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Order_By>>;
  where?: Maybe<Quote_Bool_Exp>;
};


export type Query_RootQuote_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Order_By>>;
  where?: Maybe<Quote_Bool_Exp>;
};


export type Query_RootQuote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuote_ItemArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};


export type Query_RootQuote_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};


export type Query_RootQuote_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuote_RequestArgs = {
  distinct_on?: Maybe<Array<Quote_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Request_Order_By>>;
  where?: Maybe<Quote_Request_Bool_Exp>;
};


export type Query_RootQuote_Request_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Request_Order_By>>;
  where?: Maybe<Quote_Request_Bool_Exp>;
};


export type Query_RootQuote_Request_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRaster_ColumnsArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Query_RootRaster_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Query_RootRaster_OverviewsArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Query_RootRaster_Overviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Query_RootRefundArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


export type Query_RootRefund_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


export type Query_RootRefund_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


export type Query_RootRefund_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


export type Query_RootRefund_And_Transaction_By_PkArgs = {
  refund: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Query_RootRefund_And_TransferArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};


export type Query_RootRefund_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};


export type Query_RootRefund_And_Transfer_By_PkArgs = {
  refund: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Query_RootRefund_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRefund_StatusArgs = {
  distinct_on?: Maybe<Array<Refund_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Status_Order_By>>;
  where?: Maybe<Refund_Status_Bool_Exp>;
};


export type Query_RootRefund_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Status_Order_By>>;
  where?: Maybe<Refund_Status_Bool_Exp>;
};


export type Query_RootRefund_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootReviewArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Query_RootReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Query_RootReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReview_StatusArgs = {
  distinct_on?: Maybe<Array<Review_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Status_Order_By>>;
  where?: Maybe<Review_Status_Bool_Exp>;
};


export type Query_RootReview_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Status_Order_By>>;
  where?: Maybe<Review_Status_Bool_Exp>;
};


export type Query_RootReview_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSlotArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


export type Query_RootSlot_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


export type Query_RootSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSoleArgs = {
  distinct_on?: Maybe<Array<Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sole_Order_By>>;
  where?: Maybe<Sole_Bool_Exp>;
};


export type Query_RootSole_AggregateArgs = {
  distinct_on?: Maybe<Array<Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sole_Order_By>>;
  where?: Maybe<Sole_Bool_Exp>;
};


export type Query_RootSole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSpatial_Ref_SysArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Query_RootStoreArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};


export type Query_RootStore_Account_RoleArgs = {
  distinct_on?: Maybe<Array<Store_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Account_Role_Order_By>>;
  where?: Maybe<Store_Account_Role_Bool_Exp>;
};


export type Query_RootStore_Account_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Account_Role_Order_By>>;
  where?: Maybe<Store_Account_Role_Bool_Exp>;
};


export type Query_RootStore_Account_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootStore_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};


export type Query_RootStore_And_AccountArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


export type Query_RootStore_And_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


export type Query_RootStore_And_Account_By_PkArgs = {
  account: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_And_HashtagArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};


export type Query_RootStore_And_Hashtag_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};


export type Query_RootStore_And_Hashtag_By_PkArgs = {
  hashtag: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_And_LikeArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};


export type Query_RootStore_And_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};


export type Query_RootStore_And_Like_By_PkArgs = {
  like: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_And_PackageArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


export type Query_RootStore_And_Package_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


export type Query_RootStore_And_Package_By_PkArgs = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_And_ProductArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


export type Query_RootStore_And_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


export type Query_RootStore_And_Product_By_PkArgs = {
  product: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_And_Product_ItemArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


export type Query_RootStore_And_Product_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


export type Query_RootStore_And_Product_Item_By_PkArgs = {
  product_item: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_And_ReviewArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};


export type Query_RootStore_And_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};


export type Query_RootStore_And_Review_By_PkArgs = {
  review: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_And_SoleArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};


export type Query_RootStore_And_Sole_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};


export type Query_RootStore_And_Sole_By_PkArgs = {
  sole: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootStore_By_Date_OnlyArgs = {
  args: Store_By_Date_Only_Args;
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Query_RootStore_By_Date_Only_AggregateArgs = {
  args: Store_By_Date_Only_Args;
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Query_RootStore_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStore_Search_KeywordArgs = {
  args: Store_Search_Keyword_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Query_RootStore_Search_Keyword_AggregateArgs = {
  args: Store_Search_Keyword_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Query_RootStore_Search_PolygonArgs = {
  args: Store_Search_Polygon_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Query_RootStore_Search_Polygon_AggregateArgs = {
  args: Store_Search_Polygon_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Query_RootStore_StatusArgs = {
  distinct_on?: Maybe<Array<Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Status_Order_By>>;
  where?: Maybe<Store_Status_Bool_Exp>;
};


export type Query_RootStore_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Status_Order_By>>;
  where?: Maybe<Store_Status_Bool_Exp>;
};


export type Query_RootStore_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootStore_With_RatingArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Order_By>>;
  where?: Maybe<Store_With_Rating_Bool_Exp>;
};


export type Query_RootStore_With_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Order_By>>;
  where?: Maybe<Store_With_Rating_Bool_Exp>;
};


export type Query_RootStore_With_Rating_Of_FakeArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Of_Fake_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Of_Fake_Order_By>>;
  where?: Maybe<Store_With_Rating_Of_Fake_Bool_Exp>;
};


export type Query_RootStore_With_Rating_Of_Fake_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Of_Fake_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Of_Fake_Order_By>>;
  where?: Maybe<Store_With_Rating_Of_Fake_Bool_Exp>;
};


export type Query_RootSubscriptionArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};


export type Query_RootSubscription_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};


export type Query_RootSubscription_And_Transaction_By_PkArgs = {
  subscription: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Query_RootSubscription_And_TransferArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};


export type Query_RootSubscription_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};


export type Query_RootSubscription_And_Transfer_By_PkArgs = {
  subscription: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Query_RootSubscription_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSubscription_StatusArgs = {
  distinct_on?: Maybe<Array<Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Status_Order_By>>;
  where?: Maybe<Subscription_Status_Bool_Exp>;
};


export type Query_RootSubscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Status_Order_By>>;
  where?: Maybe<Subscription_Status_Bool_Exp>;
};


export type Query_RootSubscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTransactionArgs = {
  distinct_on?: Maybe<Array<Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Order_By>>;
  where?: Maybe<Transaction_Bool_Exp>;
};


export type Query_RootTransaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Order_By>>;
  where?: Maybe<Transaction_Bool_Exp>;
};


export type Query_RootTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTransaction_TypeArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Query_RootTransaction_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Query_RootTransaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTransferArgs = {
  distinct_on?: Maybe<Array<Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Order_By>>;
  where?: Maybe<Transfer_Bool_Exp>;
};


export type Query_RootTransfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Order_By>>;
  where?: Maybe<Transfer_Bool_Exp>;
};


export type Query_RootTransfer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTransfer_TypeArgs = {
  distinct_on?: Maybe<Array<Transfer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Type_Order_By>>;
  where?: Maybe<Transfer_Type_Bool_Exp>;
};


export type Query_RootTransfer_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Transfer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Type_Order_By>>;
  where?: Maybe<Transfer_Type_Bool_Exp>;
};


export type Query_RootTransfer_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUser_Actions_HistoryArgs = {
  distinct_on?: Maybe<Array<User_Actions_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Actions_History_Order_By>>;
  where?: Maybe<User_Actions_History_Bool_Exp>;
};


export type Query_RootUser_Actions_History_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Actions_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Actions_History_Order_By>>;
  where?: Maybe<User_Actions_History_Bool_Exp>;
};


export type Query_RootUser_Actions_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootViewArgs = {
  distinct_on?: Maybe<Array<View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Order_By>>;
  where?: Maybe<View_Bool_Exp>;
};


export type Query_RootView_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Order_By>>;
  where?: Maybe<View_Bool_Exp>;
};


export type Query_RootView_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootView_Package_StoreArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Query_RootView_Package_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Query_RootView_Package_Store_By_PkArgs = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Query_RootView_StoreArgs = {
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Query_RootView_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Query_RootView_Store_By_PkArgs = {
  store: Scalars['uuid'];
};


export type Query_RootView_Store_GalleryArgs = {
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Query_RootView_Store_Gallery_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Query_RootView_Store_Gallery_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "quote" */
export type Quote = {
  __typename?: 'quote';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  lead: Scalars['uuid'];
  /** An object relationship */
  leadByLead: Lead;
  /** An array relationship */
  quote_items: Array<Quote_Item>;
  /** An aggregate relationship */
  quote_items_aggregate: Quote_Item_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "quote" */
export type QuoteQuote_ItemsArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};


/** columns and relationships of "quote" */
export type QuoteQuote_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};

/** aggregated selection of "quote" */
export type Quote_Aggregate = {
  __typename?: 'quote_aggregate';
  aggregate?: Maybe<Quote_Aggregate_Fields>;
  nodes: Array<Quote>;
};

/** aggregate fields of "quote" */
export type Quote_Aggregate_Fields = {
  __typename?: 'quote_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Quote_Max_Fields>;
  min?: Maybe<Quote_Min_Fields>;
};


/** aggregate fields of "quote" */
export type Quote_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Quote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quote" */
export type Quote_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Quote_Max_Order_By>;
  min?: Maybe<Quote_Min_Order_By>;
};

/** input type for inserting array relation for remote table "quote" */
export type Quote_Arr_Rel_Insert_Input = {
  data: Array<Quote_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Quote_On_Conflict>;
};

/** Boolean expression to filter rows from the table "quote". All fields are combined with a logical 'AND'. */
export type Quote_Bool_Exp = {
  _and?: Maybe<Array<Quote_Bool_Exp>>;
  _not?: Maybe<Quote_Bool_Exp>;
  _or?: Maybe<Array<Quote_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lead?: Maybe<Uuid_Comparison_Exp>;
  leadByLead?: Maybe<Lead_Bool_Exp>;
  quote_items?: Maybe<Quote_Item_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "quote" */
export enum Quote_Constraint {
  /** unique or primary key constraint */
  QuotePkey = 'quote_pkey'
}

/** input type for inserting data into table "quote" */
export type Quote_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  leadByLead?: Maybe<Lead_Obj_Rel_Insert_Input>;
  quote_items?: Maybe<Quote_Item_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "quote_item" */
export type Quote_Item = {
  __typename?: 'quote_item';
  basic_price_payout: Scalars['jsonb'];
  /** An array relationship */
  cart_items: Array<Cart_Item>;
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate;
  commission_rate: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  etc: Scalars['jsonb'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  /** A computed field, executes function "quote_item_package_info" */
  package_detail?: Maybe<Array<Package>>;
  price_discount: Scalars['jsonb'];
  price_extra: Scalars['jsonb'];
  price_final: Scalars['jsonb'];
  price_payout: Scalars['jsonb'];
  price_payout_discount: Scalars['jsonb'];
  price_payout_extra: Scalars['jsonb'];
  price_payout_without_vat: Scalars['jsonb'];
  price_product: Scalars['jsonb'];
  price_total: Scalars['jsonb'];
  product_item: Scalars['jsonb'];
  /** A computed field, executes function "quote_item_detail" */
  product_item_detail?: Maybe<Array<Product_Item>>;
  quote: Scalars['uuid'];
  /** An object relationship */
  quoteByQuote: Quote;
  remark: Scalars['jsonb'];
  sent_at: Scalars['jsonb'];
  store?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  storeByStore?: Maybe<Store>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "quote_item" */
export type Quote_ItemBasic_Price_PayoutArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemCommission_RateArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemEtcArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPackage_DetailArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_DiscountArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_ExtraArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_FinalArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_PayoutArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_Payout_DiscountArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_Payout_ExtraArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_Payout_Without_VatArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_ProductArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemPrice_TotalArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemProduct_ItemArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemProduct_Item_DetailArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemRemarkArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quote_item" */
export type Quote_ItemSent_AtArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "quote_item" */
export type Quote_Item_Aggregate = {
  __typename?: 'quote_item_aggregate';
  aggregate?: Maybe<Quote_Item_Aggregate_Fields>;
  nodes: Array<Quote_Item>;
};

/** aggregate fields of "quote_item" */
export type Quote_Item_Aggregate_Fields = {
  __typename?: 'quote_item_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Quote_Item_Max_Fields>;
  min?: Maybe<Quote_Item_Min_Fields>;
};


/** aggregate fields of "quote_item" */
export type Quote_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Quote_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quote_item" */
export type Quote_Item_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Quote_Item_Max_Order_By>;
  min?: Maybe<Quote_Item_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Quote_Item_Append_Input = {
  basic_price_payout?: Maybe<Scalars['jsonb']>;
  commission_rate?: Maybe<Scalars['jsonb']>;
  etc?: Maybe<Scalars['jsonb']>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_extra?: Maybe<Scalars['jsonb']>;
  price_final?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_payout_discount?: Maybe<Scalars['jsonb']>;
  price_payout_extra?: Maybe<Scalars['jsonb']>;
  price_payout_without_vat?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  price_total?: Maybe<Scalars['jsonb']>;
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "quote_item" */
export type Quote_Item_Arr_Rel_Insert_Input = {
  data: Array<Quote_Item_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Quote_Item_On_Conflict>;
};

/** Boolean expression to filter rows from the table "quote_item". All fields are combined with a logical 'AND'. */
export type Quote_Item_Bool_Exp = {
  _and?: Maybe<Array<Quote_Item_Bool_Exp>>;
  _not?: Maybe<Quote_Item_Bool_Exp>;
  _or?: Maybe<Array<Quote_Item_Bool_Exp>>;
  basic_price_payout?: Maybe<Jsonb_Comparison_Exp>;
  cart_items?: Maybe<Cart_Item_Bool_Exp>;
  commission_rate?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  etc?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  package_detail?: Maybe<Package_Bool_Exp>;
  price_discount?: Maybe<Jsonb_Comparison_Exp>;
  price_extra?: Maybe<Jsonb_Comparison_Exp>;
  price_final?: Maybe<Jsonb_Comparison_Exp>;
  price_payout?: Maybe<Jsonb_Comparison_Exp>;
  price_payout_discount?: Maybe<Jsonb_Comparison_Exp>;
  price_payout_extra?: Maybe<Jsonb_Comparison_Exp>;
  price_payout_without_vat?: Maybe<Jsonb_Comparison_Exp>;
  price_product?: Maybe<Jsonb_Comparison_Exp>;
  price_total?: Maybe<Jsonb_Comparison_Exp>;
  product_item?: Maybe<Jsonb_Comparison_Exp>;
  product_item_detail?: Maybe<Product_Item_Bool_Exp>;
  quote?: Maybe<Uuid_Comparison_Exp>;
  quoteByQuote?: Maybe<Quote_Bool_Exp>;
  remark?: Maybe<Jsonb_Comparison_Exp>;
  sent_at?: Maybe<Jsonb_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "quote_item" */
export enum Quote_Item_Constraint {
  /** unique or primary key constraint */
  QuoteItemPkey = 'quote_item_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Quote_Item_Delete_At_Path_Input = {
  basic_price_payout?: Maybe<Array<Scalars['String']>>;
  commission_rate?: Maybe<Array<Scalars['String']>>;
  etc?: Maybe<Array<Scalars['String']>>;
  price_discount?: Maybe<Array<Scalars['String']>>;
  price_extra?: Maybe<Array<Scalars['String']>>;
  price_final?: Maybe<Array<Scalars['String']>>;
  price_payout?: Maybe<Array<Scalars['String']>>;
  price_payout_discount?: Maybe<Array<Scalars['String']>>;
  price_payout_extra?: Maybe<Array<Scalars['String']>>;
  price_payout_without_vat?: Maybe<Array<Scalars['String']>>;
  price_product?: Maybe<Array<Scalars['String']>>;
  price_total?: Maybe<Array<Scalars['String']>>;
  product_item?: Maybe<Array<Scalars['String']>>;
  remark?: Maybe<Array<Scalars['String']>>;
  sent_at?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Quote_Item_Delete_Elem_Input = {
  basic_price_payout?: Maybe<Scalars['Int']>;
  commission_rate?: Maybe<Scalars['Int']>;
  etc?: Maybe<Scalars['Int']>;
  price_discount?: Maybe<Scalars['Int']>;
  price_extra?: Maybe<Scalars['Int']>;
  price_final?: Maybe<Scalars['Int']>;
  price_payout?: Maybe<Scalars['Int']>;
  price_payout_discount?: Maybe<Scalars['Int']>;
  price_payout_extra?: Maybe<Scalars['Int']>;
  price_payout_without_vat?: Maybe<Scalars['Int']>;
  price_product?: Maybe<Scalars['Int']>;
  price_total?: Maybe<Scalars['Int']>;
  product_item?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['Int']>;
  sent_at?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Quote_Item_Delete_Key_Input = {
  basic_price_payout?: Maybe<Scalars['String']>;
  commission_rate?: Maybe<Scalars['String']>;
  etc?: Maybe<Scalars['String']>;
  price_discount?: Maybe<Scalars['String']>;
  price_extra?: Maybe<Scalars['String']>;
  price_final?: Maybe<Scalars['String']>;
  price_payout?: Maybe<Scalars['String']>;
  price_payout_discount?: Maybe<Scalars['String']>;
  price_payout_extra?: Maybe<Scalars['String']>;
  price_payout_without_vat?: Maybe<Scalars['String']>;
  price_product?: Maybe<Scalars['String']>;
  price_total?: Maybe<Scalars['String']>;
  product_item?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "quote_item" */
export type Quote_Item_Insert_Input = {
  basic_price_payout?: Maybe<Scalars['jsonb']>;
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>;
  commission_rate?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  etc?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_extra?: Maybe<Scalars['jsonb']>;
  price_final?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_payout_discount?: Maybe<Scalars['jsonb']>;
  price_payout_extra?: Maybe<Scalars['jsonb']>;
  price_payout_without_vat?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  price_total?: Maybe<Scalars['jsonb']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quote?: Maybe<Scalars['uuid']>;
  quoteByQuote?: Maybe<Quote_Obj_Rel_Insert_Input>;
  remark?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['jsonb']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Quote_Item_Max_Fields = {
  __typename?: 'quote_item_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  quote?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "quote_item" */
export type Quote_Item_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Quote_Item_Min_Fields = {
  __typename?: 'quote_item_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  quote?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "quote_item" */
export type Quote_Item_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "quote_item" */
export type Quote_Item_Mutation_Response = {
  __typename?: 'quote_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Quote_Item>;
};

/** input type for inserting object relation for remote table "quote_item" */
export type Quote_Item_Obj_Rel_Insert_Input = {
  data: Quote_Item_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Quote_Item_On_Conflict>;
};

/** on conflict condition type for table "quote_item" */
export type Quote_Item_On_Conflict = {
  constraint: Quote_Item_Constraint;
  update_columns?: Array<Quote_Item_Update_Column>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "quote_item". */
export type Quote_Item_Order_By = {
  basic_price_payout?: Maybe<Order_By>;
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  etc?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  package_detail_aggregate?: Maybe<Package_Aggregate_Order_By>;
  price_discount?: Maybe<Order_By>;
  price_extra?: Maybe<Order_By>;
  price_final?: Maybe<Order_By>;
  price_payout?: Maybe<Order_By>;
  price_payout_discount?: Maybe<Order_By>;
  price_payout_extra?: Maybe<Order_By>;
  price_payout_without_vat?: Maybe<Order_By>;
  price_product?: Maybe<Order_By>;
  price_total?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  product_item_detail_aggregate?: Maybe<Product_Item_Aggregate_Order_By>;
  quote?: Maybe<Order_By>;
  quoteByQuote?: Maybe<Quote_Order_By>;
  remark?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: quote_item */
export type Quote_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Quote_Item_Prepend_Input = {
  basic_price_payout?: Maybe<Scalars['jsonb']>;
  commission_rate?: Maybe<Scalars['jsonb']>;
  etc?: Maybe<Scalars['jsonb']>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_extra?: Maybe<Scalars['jsonb']>;
  price_final?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_payout_discount?: Maybe<Scalars['jsonb']>;
  price_payout_extra?: Maybe<Scalars['jsonb']>;
  price_payout_without_vat?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  price_total?: Maybe<Scalars['jsonb']>;
  product_item?: Maybe<Scalars['jsonb']>;
  remark?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "quote_item" */
export enum Quote_Item_Select_Column {
  /** column name */
  BasicPricePayout = 'basic_price_payout',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Etc = 'etc',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutDiscount = 'price_payout_discount',
  /** column name */
  PricePayoutExtra = 'price_payout_extra',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  PriceTotal = 'price_total',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Quote = 'quote',
  /** column name */
  Remark = 'remark',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "quote_item" */
export type Quote_Item_Set_Input = {
  basic_price_payout?: Maybe<Scalars['jsonb']>;
  commission_rate?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  etc?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  price_discount?: Maybe<Scalars['jsonb']>;
  price_extra?: Maybe<Scalars['jsonb']>;
  price_final?: Maybe<Scalars['jsonb']>;
  price_payout?: Maybe<Scalars['jsonb']>;
  price_payout_discount?: Maybe<Scalars['jsonb']>;
  price_payout_extra?: Maybe<Scalars['jsonb']>;
  price_payout_without_vat?: Maybe<Scalars['jsonb']>;
  price_product?: Maybe<Scalars['jsonb']>;
  price_total?: Maybe<Scalars['jsonb']>;
  product_item?: Maybe<Scalars['jsonb']>;
  quote?: Maybe<Scalars['uuid']>;
  remark?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['jsonb']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "quote_item" */
export enum Quote_Item_Update_Column {
  /** column name */
  BasicPricePayout = 'basic_price_payout',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Etc = 'etc',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceExtra = 'price_extra',
  /** column name */
  PriceFinal = 'price_final',
  /** column name */
  PricePayout = 'price_payout',
  /** column name */
  PricePayoutDiscount = 'price_payout_discount',
  /** column name */
  PricePayoutExtra = 'price_payout_extra',
  /** column name */
  PricePayoutWithoutVat = 'price_payout_without_vat',
  /** column name */
  PriceProduct = 'price_product',
  /** column name */
  PriceTotal = 'price_total',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Quote = 'quote',
  /** column name */
  Remark = 'remark',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate max on columns */
export type Quote_Max_Fields = {
  __typename?: 'quote_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "quote" */
export type Quote_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Quote_Min_Fields = {
  __typename?: 'quote_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "quote" */
export type Quote_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "quote" */
export type Quote_Mutation_Response = {
  __typename?: 'quote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Quote>;
};

/** input type for inserting object relation for remote table "quote" */
export type Quote_Obj_Rel_Insert_Input = {
  data: Quote_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Quote_On_Conflict>;
};

/** on conflict condition type for table "quote" */
export type Quote_On_Conflict = {
  constraint: Quote_Constraint;
  update_columns?: Array<Quote_Update_Column>;
  where?: Maybe<Quote_Bool_Exp>;
};

/** Ordering options when selecting data from "quote". */
export type Quote_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead?: Maybe<Order_By>;
  leadByLead?: Maybe<Lead_Order_By>;
  quote_items_aggregate?: Maybe<Quote_Item_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: quote */
export type Quote_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "quote_request" */
export type Quote_Request = {
  __typename?: 'quote_request';
  account?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  phone_number?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  session: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "quote_request" */
export type Quote_RequestContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "quote_request" */
export type Quote_Request_Aggregate = {
  __typename?: 'quote_request_aggregate';
  aggregate?: Maybe<Quote_Request_Aggregate_Fields>;
  nodes: Array<Quote_Request>;
};

/** aggregate fields of "quote_request" */
export type Quote_Request_Aggregate_Fields = {
  __typename?: 'quote_request_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Quote_Request_Max_Fields>;
  min?: Maybe<Quote_Request_Min_Fields>;
};


/** aggregate fields of "quote_request" */
export type Quote_Request_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Quote_Request_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Quote_Request_Append_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "quote_request". All fields are combined with a logical 'AND'. */
export type Quote_Request_Bool_Exp = {
  _and?: Maybe<Array<Quote_Request_Bool_Exp>>;
  _not?: Maybe<Quote_Request_Bool_Exp>;
  _or?: Maybe<Array<Quote_Request_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  content?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  session?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "quote_request" */
export enum Quote_Request_Constraint {
  /** unique or primary key constraint */
  QuoteRequestPkey = 'quote_request_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Quote_Request_Delete_At_Path_Input = {
  content?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Quote_Request_Delete_Elem_Input = {
  content?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Quote_Request_Delete_Key_Input = {
  content?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "quote_request" */
export type Quote_Request_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Quote_Request_Max_Fields = {
  __typename?: 'quote_request_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Quote_Request_Min_Fields = {
  __typename?: 'quote_request_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "quote_request" */
export type Quote_Request_Mutation_Response = {
  __typename?: 'quote_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Quote_Request>;
};

/** on conflict condition type for table "quote_request" */
export type Quote_Request_On_Conflict = {
  constraint: Quote_Request_Constraint;
  update_columns?: Array<Quote_Request_Update_Column>;
  where?: Maybe<Quote_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "quote_request". */
export type Quote_Request_Order_By = {
  account?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: quote_request */
export type Quote_Request_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Quote_Request_Prepend_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "quote_request" */
export enum Quote_Request_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Session = 'session',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "quote_request" */
export type Quote_Request_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "quote_request" */
export enum Quote_Request_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Session = 'session',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select columns of table "quote" */
export enum Quote_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "quote" */
export type Quote_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "quote" */
export enum Quote_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "raster_columns" */
export type Raster_Columns = {
  __typename?: 'raster_columns';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  extent?: Maybe<Scalars['geometry']>;
  nodata_values?: Maybe<Scalars['_float8']>;
  num_bands?: Maybe<Scalars['Int']>;
  out_db?: Maybe<Scalars['_bool']>;
  pixel_types?: Maybe<Scalars['_text']>;
  r_raster_column?: Maybe<Scalars['name']>;
  r_table_catalog?: Maybe<Scalars['name']>;
  r_table_name?: Maybe<Scalars['name']>;
  r_table_schema?: Maybe<Scalars['name']>;
  regular_blocking?: Maybe<Scalars['Boolean']>;
  same_alignment?: Maybe<Scalars['Boolean']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  spatial_index?: Maybe<Scalars['Boolean']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "raster_columns" */
export type Raster_Columns_Aggregate = {
  __typename?: 'raster_columns_aggregate';
  aggregate?: Maybe<Raster_Columns_Aggregate_Fields>;
  nodes: Array<Raster_Columns>;
};

/** aggregate fields of "raster_columns" */
export type Raster_Columns_Aggregate_Fields = {
  __typename?: 'raster_columns_aggregate_fields';
  avg?: Maybe<Raster_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Raster_Columns_Max_Fields>;
  min?: Maybe<Raster_Columns_Min_Fields>;
  stddev?: Maybe<Raster_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Raster_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raster_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Raster_Columns_Sum_Fields>;
  var_pop?: Maybe<Raster_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Raster_Columns_Var_Samp_Fields>;
  variance?: Maybe<Raster_Columns_Variance_Fields>;
};


/** aggregate fields of "raster_columns" */
export type Raster_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Raster_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Raster_Columns_Avg_Fields = {
  __typename?: 'raster_columns_avg_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "raster_columns". All fields are combined with a logical 'AND'. */
export type Raster_Columns_Bool_Exp = {
  _and?: Maybe<Array<Raster_Columns_Bool_Exp>>;
  _not?: Maybe<Raster_Columns_Bool_Exp>;
  _or?: Maybe<Array<Raster_Columns_Bool_Exp>>;
  blocksize_x?: Maybe<Int_Comparison_Exp>;
  blocksize_y?: Maybe<Int_Comparison_Exp>;
  extent?: Maybe<Geometry_Comparison_Exp>;
  nodata_values?: Maybe<_Float8_Comparison_Exp>;
  num_bands?: Maybe<Int_Comparison_Exp>;
  out_db?: Maybe<_Bool_Comparison_Exp>;
  pixel_types?: Maybe<_Text_Comparison_Exp>;
  r_raster_column?: Maybe<Name_Comparison_Exp>;
  r_table_catalog?: Maybe<Name_Comparison_Exp>;
  r_table_name?: Maybe<Name_Comparison_Exp>;
  r_table_schema?: Maybe<Name_Comparison_Exp>;
  regular_blocking?: Maybe<Boolean_Comparison_Exp>;
  same_alignment?: Maybe<Boolean_Comparison_Exp>;
  scale_x?: Maybe<Float8_Comparison_Exp>;
  scale_y?: Maybe<Float8_Comparison_Exp>;
  spatial_index?: Maybe<Boolean_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Raster_Columns_Max_Fields = {
  __typename?: 'raster_columns_max_fields';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  num_bands?: Maybe<Scalars['Int']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Raster_Columns_Min_Fields = {
  __typename?: 'raster_columns_min_fields';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  num_bands?: Maybe<Scalars['Int']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "raster_columns". */
export type Raster_Columns_Order_By = {
  blocksize_x?: Maybe<Order_By>;
  blocksize_y?: Maybe<Order_By>;
  extent?: Maybe<Order_By>;
  nodata_values?: Maybe<Order_By>;
  num_bands?: Maybe<Order_By>;
  out_db?: Maybe<Order_By>;
  pixel_types?: Maybe<Order_By>;
  r_raster_column?: Maybe<Order_By>;
  r_table_catalog?: Maybe<Order_By>;
  r_table_name?: Maybe<Order_By>;
  r_table_schema?: Maybe<Order_By>;
  regular_blocking?: Maybe<Order_By>;
  same_alignment?: Maybe<Order_By>;
  scale_x?: Maybe<Order_By>;
  scale_y?: Maybe<Order_By>;
  spatial_index?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
};

/** select columns of table "raster_columns" */
export enum Raster_Columns_Select_Column {
  /** column name */
  BlocksizeX = 'blocksize_x',
  /** column name */
  BlocksizeY = 'blocksize_y',
  /** column name */
  Extent = 'extent',
  /** column name */
  NodataValues = 'nodata_values',
  /** column name */
  NumBands = 'num_bands',
  /** column name */
  OutDb = 'out_db',
  /** column name */
  PixelTypes = 'pixel_types',
  /** column name */
  RRasterColumn = 'r_raster_column',
  /** column name */
  RTableCatalog = 'r_table_catalog',
  /** column name */
  RTableName = 'r_table_name',
  /** column name */
  RTableSchema = 'r_table_schema',
  /** column name */
  RegularBlocking = 'regular_blocking',
  /** column name */
  SameAlignment = 'same_alignment',
  /** column name */
  ScaleX = 'scale_x',
  /** column name */
  ScaleY = 'scale_y',
  /** column name */
  SpatialIndex = 'spatial_index',
  /** column name */
  Srid = 'srid'
}

/** aggregate stddev on columns */
export type Raster_Columns_Stddev_Fields = {
  __typename?: 'raster_columns_stddev_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Raster_Columns_Stddev_Pop_Fields = {
  __typename?: 'raster_columns_stddev_pop_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Raster_Columns_Stddev_Samp_Fields = {
  __typename?: 'raster_columns_stddev_samp_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Raster_Columns_Sum_Fields = {
  __typename?: 'raster_columns_sum_fields';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  num_bands?: Maybe<Scalars['Int']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Raster_Columns_Var_Pop_Fields = {
  __typename?: 'raster_columns_var_pop_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Raster_Columns_Var_Samp_Fields = {
  __typename?: 'raster_columns_var_samp_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Raster_Columns_Variance_Fields = {
  __typename?: 'raster_columns_variance_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "raster_overviews" */
export type Raster_Overviews = {
  __typename?: 'raster_overviews';
  o_raster_column?: Maybe<Scalars['name']>;
  o_table_catalog?: Maybe<Scalars['name']>;
  o_table_name?: Maybe<Scalars['name']>;
  o_table_schema?: Maybe<Scalars['name']>;
  overview_factor?: Maybe<Scalars['Int']>;
  r_raster_column?: Maybe<Scalars['name']>;
  r_table_catalog?: Maybe<Scalars['name']>;
  r_table_name?: Maybe<Scalars['name']>;
  r_table_schema?: Maybe<Scalars['name']>;
};

/** aggregated selection of "raster_overviews" */
export type Raster_Overviews_Aggregate = {
  __typename?: 'raster_overviews_aggregate';
  aggregate?: Maybe<Raster_Overviews_Aggregate_Fields>;
  nodes: Array<Raster_Overviews>;
};

/** aggregate fields of "raster_overviews" */
export type Raster_Overviews_Aggregate_Fields = {
  __typename?: 'raster_overviews_aggregate_fields';
  avg?: Maybe<Raster_Overviews_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Raster_Overviews_Max_Fields>;
  min?: Maybe<Raster_Overviews_Min_Fields>;
  stddev?: Maybe<Raster_Overviews_Stddev_Fields>;
  stddev_pop?: Maybe<Raster_Overviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raster_Overviews_Stddev_Samp_Fields>;
  sum?: Maybe<Raster_Overviews_Sum_Fields>;
  var_pop?: Maybe<Raster_Overviews_Var_Pop_Fields>;
  var_samp?: Maybe<Raster_Overviews_Var_Samp_Fields>;
  variance?: Maybe<Raster_Overviews_Variance_Fields>;
};


/** aggregate fields of "raster_overviews" */
export type Raster_Overviews_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Raster_Overviews_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Raster_Overviews_Avg_Fields = {
  __typename?: 'raster_overviews_avg_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "raster_overviews". All fields are combined with a logical 'AND'. */
export type Raster_Overviews_Bool_Exp = {
  _and?: Maybe<Array<Raster_Overviews_Bool_Exp>>;
  _not?: Maybe<Raster_Overviews_Bool_Exp>;
  _or?: Maybe<Array<Raster_Overviews_Bool_Exp>>;
  o_raster_column?: Maybe<Name_Comparison_Exp>;
  o_table_catalog?: Maybe<Name_Comparison_Exp>;
  o_table_name?: Maybe<Name_Comparison_Exp>;
  o_table_schema?: Maybe<Name_Comparison_Exp>;
  overview_factor?: Maybe<Int_Comparison_Exp>;
  r_raster_column?: Maybe<Name_Comparison_Exp>;
  r_table_catalog?: Maybe<Name_Comparison_Exp>;
  r_table_name?: Maybe<Name_Comparison_Exp>;
  r_table_schema?: Maybe<Name_Comparison_Exp>;
};

/** aggregate max on columns */
export type Raster_Overviews_Max_Fields = {
  __typename?: 'raster_overviews_max_fields';
  overview_factor?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Raster_Overviews_Min_Fields = {
  __typename?: 'raster_overviews_min_fields';
  overview_factor?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "raster_overviews". */
export type Raster_Overviews_Order_By = {
  o_raster_column?: Maybe<Order_By>;
  o_table_catalog?: Maybe<Order_By>;
  o_table_name?: Maybe<Order_By>;
  o_table_schema?: Maybe<Order_By>;
  overview_factor?: Maybe<Order_By>;
  r_raster_column?: Maybe<Order_By>;
  r_table_catalog?: Maybe<Order_By>;
  r_table_name?: Maybe<Order_By>;
  r_table_schema?: Maybe<Order_By>;
};

/** select columns of table "raster_overviews" */
export enum Raster_Overviews_Select_Column {
  /** column name */
  ORasterColumn = 'o_raster_column',
  /** column name */
  OTableCatalog = 'o_table_catalog',
  /** column name */
  OTableName = 'o_table_name',
  /** column name */
  OTableSchema = 'o_table_schema',
  /** column name */
  OverviewFactor = 'overview_factor',
  /** column name */
  RRasterColumn = 'r_raster_column',
  /** column name */
  RTableCatalog = 'r_table_catalog',
  /** column name */
  RTableName = 'r_table_name',
  /** column name */
  RTableSchema = 'r_table_schema'
}

/** aggregate stddev on columns */
export type Raster_Overviews_Stddev_Fields = {
  __typename?: 'raster_overviews_stddev_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Raster_Overviews_Stddev_Pop_Fields = {
  __typename?: 'raster_overviews_stddev_pop_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Raster_Overviews_Stddev_Samp_Fields = {
  __typename?: 'raster_overviews_stddev_samp_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Raster_Overviews_Sum_Fields = {
  __typename?: 'raster_overviews_sum_fields';
  overview_factor?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Raster_Overviews_Var_Pop_Fields = {
  __typename?: 'raster_overviews_var_pop_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Raster_Overviews_Var_Samp_Fields = {
  __typename?: 'raster_overviews_var_samp_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Raster_Overviews_Variance_Fields = {
  __typename?: 'raster_overviews_variance_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/**
 * Account refunded list
 *
 *
 * columns and relationships of "refund"
 */
export type Refund = {
  __typename?: 'refund';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  accounts_payable_and_refunds: Array<Accounts_Payable_And_Refund>;
  /** An aggregate relationship */
  accounts_payable_and_refunds_aggregate: Accounts_Payable_And_Refund_Aggregate;
  amount?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  order: Scalars['uuid'];
  /** An object relationship */
  orderByOrder: Order;
  reason?: Maybe<Scalars['String']>;
  /** An array relationship */
  refund_and_transactions: Array<Refund_And_Transaction>;
  /** An aggregate relationship */
  refund_and_transactions_aggregate: Refund_And_Transaction_Aggregate;
  /** An array relationship */
  refund_and_transfers: Array<Refund_And_Transfer>;
  /** An aggregate relationship */
  refund_and_transfers_aggregate: Refund_And_Transfer_Aggregate;
  /** An object relationship */
  refund_status: Refund_Status;
  status: Refund_Status_Enum;
  transaction?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  transactionByTransaction?: Maybe<Transaction>;
  updated_at: Scalars['timestamptz'];
};


/**
 * Account refunded list
 *
 *
 * columns and relationships of "refund"
 */
export type RefundAccounts_Payable_And_RefundsArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};


/**
 * Account refunded list
 *
 *
 * columns and relationships of "refund"
 */
export type RefundAccounts_Payable_And_Refunds_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};


/**
 * Account refunded list
 *
 *
 * columns and relationships of "refund"
 */
export type RefundRefund_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


/**
 * Account refunded list
 *
 *
 * columns and relationships of "refund"
 */
export type RefundRefund_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


/**
 * Account refunded list
 *
 *
 * columns and relationships of "refund"
 */
export type RefundRefund_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};


/**
 * Account refunded list
 *
 *
 * columns and relationships of "refund"
 */
export type RefundRefund_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};

/** aggregated selection of "refund" */
export type Refund_Aggregate = {
  __typename?: 'refund_aggregate';
  aggregate?: Maybe<Refund_Aggregate_Fields>;
  nodes: Array<Refund>;
};

/** aggregate fields of "refund" */
export type Refund_Aggregate_Fields = {
  __typename?: 'refund_aggregate_fields';
  avg?: Maybe<Refund_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Refund_Max_Fields>;
  min?: Maybe<Refund_Min_Fields>;
  stddev?: Maybe<Refund_Stddev_Fields>;
  stddev_pop?: Maybe<Refund_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Refund_Stddev_Samp_Fields>;
  sum?: Maybe<Refund_Sum_Fields>;
  var_pop?: Maybe<Refund_Var_Pop_Fields>;
  var_samp?: Maybe<Refund_Var_Samp_Fields>;
  variance?: Maybe<Refund_Variance_Fields>;
};


/** aggregate fields of "refund" */
export type Refund_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Refund_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "refund" */
export type Refund_Aggregate_Order_By = {
  avg?: Maybe<Refund_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Refund_Max_Order_By>;
  min?: Maybe<Refund_Min_Order_By>;
  stddev?: Maybe<Refund_Stddev_Order_By>;
  stddev_pop?: Maybe<Refund_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Refund_Stddev_Samp_Order_By>;
  sum?: Maybe<Refund_Sum_Order_By>;
  var_pop?: Maybe<Refund_Var_Pop_Order_By>;
  var_samp?: Maybe<Refund_Var_Samp_Order_By>;
  variance?: Maybe<Refund_Variance_Order_By>;
};

/**
 * Refunded transaction - Relationship with refund
 *
 *
 * columns and relationships of "refund_and_transaction"
 */
export type Refund_And_Transaction = {
  __typename?: 'refund_and_transaction';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund: Scalars['uuid'];
  /** An object relationship */
  refundByRefund: Refund;
  transaction: Scalars['uuid'];
  /** An object relationship */
  transactionByTransaction: Transaction;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "refund_and_transaction" */
export type Refund_And_Transaction_Aggregate = {
  __typename?: 'refund_and_transaction_aggregate';
  aggregate?: Maybe<Refund_And_Transaction_Aggregate_Fields>;
  nodes: Array<Refund_And_Transaction>;
};

/** aggregate fields of "refund_and_transaction" */
export type Refund_And_Transaction_Aggregate_Fields = {
  __typename?: 'refund_and_transaction_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Refund_And_Transaction_Max_Fields>;
  min?: Maybe<Refund_And_Transaction_Min_Fields>;
};


/** aggregate fields of "refund_and_transaction" */
export type Refund_And_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "refund_and_transaction" */
export type Refund_And_Transaction_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Refund_And_Transaction_Max_Order_By>;
  min?: Maybe<Refund_And_Transaction_Min_Order_By>;
};

/** input type for inserting array relation for remote table "refund_and_transaction" */
export type Refund_And_Transaction_Arr_Rel_Insert_Input = {
  data: Array<Refund_And_Transaction_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Refund_And_Transaction_On_Conflict>;
};

/** Boolean expression to filter rows from the table "refund_and_transaction". All fields are combined with a logical 'AND'. */
export type Refund_And_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Refund_And_Transaction_Bool_Exp>>;
  _not?: Maybe<Refund_And_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Refund_And_Transaction_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  refund?: Maybe<Uuid_Comparison_Exp>;
  refundByRefund?: Maybe<Refund_Bool_Exp>;
  transaction?: Maybe<Uuid_Comparison_Exp>;
  transactionByTransaction?: Maybe<Transaction_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "refund_and_transaction" */
export enum Refund_And_Transaction_Constraint {
  /** unique or primary key constraint */
  RefundAndTransactionPkey = 'refund_and_transaction_pkey'
}

/** input type for inserting data into table "refund_and_transaction" */
export type Refund_And_Transaction_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  refundByRefund?: Maybe<Refund_Obj_Rel_Insert_Input>;
  transaction?: Maybe<Scalars['uuid']>;
  transactionByTransaction?: Maybe<Transaction_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Refund_And_Transaction_Max_Fields = {
  __typename?: 'refund_and_transaction_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "refund_and_transaction" */
export type Refund_And_Transaction_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Refund_And_Transaction_Min_Fields = {
  __typename?: 'refund_and_transaction_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "refund_and_transaction" */
export type Refund_And_Transaction_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "refund_and_transaction" */
export type Refund_And_Transaction_Mutation_Response = {
  __typename?: 'refund_and_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Refund_And_Transaction>;
};

/** on conflict condition type for table "refund_and_transaction" */
export type Refund_And_Transaction_On_Conflict = {
  constraint: Refund_And_Transaction_Constraint;
  update_columns?: Array<Refund_And_Transaction_Update_Column>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "refund_and_transaction". */
export type Refund_And_Transaction_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  refundByRefund?: Maybe<Refund_Order_By>;
  transaction?: Maybe<Order_By>;
  transactionByTransaction?: Maybe<Transaction_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: refund_and_transaction */
export type Refund_And_Transaction_Pk_Columns_Input = {
  refund: Scalars['uuid'];
  transaction: Scalars['uuid'];
};

/** select columns of table "refund_and_transaction" */
export enum Refund_And_Transaction_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Refund = 'refund',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "refund_and_transaction" */
export type Refund_And_Transaction_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "refund_and_transaction" */
export enum Refund_And_Transaction_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Refund = 'refund',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Refunded transfer - Relationship with refund
 *
 *
 * columns and relationships of "refund_and_transfer"
 */
export type Refund_And_Transfer = {
  __typename?: 'refund_and_transfer';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund: Scalars['uuid'];
  /** An object relationship */
  refundByRefund: Refund;
  transfer: Scalars['uuid'];
  /** An object relationship */
  transferByTransfer: Transfer;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "refund_and_transfer" */
export type Refund_And_Transfer_Aggregate = {
  __typename?: 'refund_and_transfer_aggregate';
  aggregate?: Maybe<Refund_And_Transfer_Aggregate_Fields>;
  nodes: Array<Refund_And_Transfer>;
};

/** aggregate fields of "refund_and_transfer" */
export type Refund_And_Transfer_Aggregate_Fields = {
  __typename?: 'refund_and_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Refund_And_Transfer_Max_Fields>;
  min?: Maybe<Refund_And_Transfer_Min_Fields>;
};


/** aggregate fields of "refund_and_transfer" */
export type Refund_And_Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "refund_and_transfer" */
export type Refund_And_Transfer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Refund_And_Transfer_Max_Order_By>;
  min?: Maybe<Refund_And_Transfer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "refund_and_transfer" */
export type Refund_And_Transfer_Arr_Rel_Insert_Input = {
  data: Array<Refund_And_Transfer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Refund_And_Transfer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "refund_and_transfer". All fields are combined with a logical 'AND'. */
export type Refund_And_Transfer_Bool_Exp = {
  _and?: Maybe<Array<Refund_And_Transfer_Bool_Exp>>;
  _not?: Maybe<Refund_And_Transfer_Bool_Exp>;
  _or?: Maybe<Array<Refund_And_Transfer_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  refund?: Maybe<Uuid_Comparison_Exp>;
  refundByRefund?: Maybe<Refund_Bool_Exp>;
  transfer?: Maybe<Uuid_Comparison_Exp>;
  transferByTransfer?: Maybe<Transfer_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "refund_and_transfer" */
export enum Refund_And_Transfer_Constraint {
  /** unique or primary key constraint */
  RefundAndTransferPkey = 'refund_and_transfer_pkey'
}

/** input type for inserting data into table "refund_and_transfer" */
export type Refund_And_Transfer_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  refundByRefund?: Maybe<Refund_Obj_Rel_Insert_Input>;
  transfer?: Maybe<Scalars['uuid']>;
  transferByTransfer?: Maybe<Transfer_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Refund_And_Transfer_Max_Fields = {
  __typename?: 'refund_and_transfer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "refund_and_transfer" */
export type Refund_And_Transfer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Refund_And_Transfer_Min_Fields = {
  __typename?: 'refund_and_transfer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "refund_and_transfer" */
export type Refund_And_Transfer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "refund_and_transfer" */
export type Refund_And_Transfer_Mutation_Response = {
  __typename?: 'refund_and_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Refund_And_Transfer>;
};

/** on conflict condition type for table "refund_and_transfer" */
export type Refund_And_Transfer_On_Conflict = {
  constraint: Refund_And_Transfer_Constraint;
  update_columns?: Array<Refund_And_Transfer_Update_Column>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "refund_and_transfer". */
export type Refund_And_Transfer_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  refund?: Maybe<Order_By>;
  refundByRefund?: Maybe<Refund_Order_By>;
  transfer?: Maybe<Order_By>;
  transferByTransfer?: Maybe<Transfer_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: refund_and_transfer */
export type Refund_And_Transfer_Pk_Columns_Input = {
  refund: Scalars['uuid'];
  transfer: Scalars['uuid'];
};

/** select columns of table "refund_and_transfer" */
export enum Refund_And_Transfer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Refund = 'refund',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "refund_and_transfer" */
export type Refund_And_Transfer_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  refund?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "refund_and_transfer" */
export enum Refund_And_Transfer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Refund = 'refund',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting array relation for remote table "refund" */
export type Refund_Arr_Rel_Insert_Input = {
  data: Array<Refund_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Refund_On_Conflict>;
};

/** aggregate avg on columns */
export type Refund_Avg_Fields = {
  __typename?: 'refund_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "refund" */
export type Refund_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "refund". All fields are combined with a logical 'AND'. */
export type Refund_Bool_Exp = {
  _and?: Maybe<Array<Refund_Bool_Exp>>;
  _not?: Maybe<Refund_Bool_Exp>;
  _or?: Maybe<Array<Refund_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  accounts_payable_and_refunds?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Uuid_Comparison_Exp>;
  orderByOrder?: Maybe<Order_Bool_Exp>;
  reason?: Maybe<String_Comparison_Exp>;
  refund_and_transactions?: Maybe<Refund_And_Transaction_Bool_Exp>;
  refund_and_transfers?: Maybe<Refund_And_Transfer_Bool_Exp>;
  refund_status?: Maybe<Refund_Status_Bool_Exp>;
  status?: Maybe<Refund_Status_Enum_Comparison_Exp>;
  transaction?: Maybe<Uuid_Comparison_Exp>;
  transactionByTransaction?: Maybe<Transaction_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "refund" */
export enum Refund_Constraint {
  /** unique or primary key constraint */
  RefundOrderKey = 'refund_order_key',
  /** unique or primary key constraint */
  RefundPkey = 'refund_pkey',
  /** unique or primary key constraint */
  RefundTransactionKey = 'refund_transaction_key'
}

/** input type for incrementing numeric columns in table "refund" */
export type Refund_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "refund" */
export type Refund_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  accounts_payable_and_refunds?: Maybe<Accounts_Payable_And_Refund_Arr_Rel_Insert_Input>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  orderByOrder?: Maybe<Order_Obj_Rel_Insert_Input>;
  reason?: Maybe<Scalars['String']>;
  refund_and_transactions?: Maybe<Refund_And_Transaction_Arr_Rel_Insert_Input>;
  refund_and_transfers?: Maybe<Refund_And_Transfer_Arr_Rel_Insert_Input>;
  refund_status?: Maybe<Refund_Status_Obj_Rel_Insert_Input>;
  status?: Maybe<Refund_Status_Enum>;
  transaction?: Maybe<Scalars['uuid']>;
  transactionByTransaction?: Maybe<Transaction_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Refund_Max_Fields = {
  __typename?: 'refund_max_fields';
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "refund" */
export type Refund_Max_Order_By = {
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Refund_Min_Fields = {
  __typename?: 'refund_min_fields';
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "refund" */
export type Refund_Min_Order_By = {
  account?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "refund" */
export type Refund_Mutation_Response = {
  __typename?: 'refund_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Refund>;
};

/** input type for inserting object relation for remote table "refund" */
export type Refund_Obj_Rel_Insert_Input = {
  data: Refund_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Refund_On_Conflict>;
};

/** on conflict condition type for table "refund" */
export type Refund_On_Conflict = {
  constraint: Refund_Constraint;
  update_columns?: Array<Refund_Update_Column>;
  where?: Maybe<Refund_Bool_Exp>;
};

/** Ordering options when selecting data from "refund". */
export type Refund_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  accounts_payable_and_refunds_aggregate?: Maybe<Accounts_Payable_And_Refund_Aggregate_Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  orderByOrder?: Maybe<Order_Order_By>;
  reason?: Maybe<Order_By>;
  refund_and_transactions_aggregate?: Maybe<Refund_And_Transaction_Aggregate_Order_By>;
  refund_and_transfers_aggregate?: Maybe<Refund_And_Transfer_Aggregate_Order_By>;
  refund_status?: Maybe<Refund_Status_Order_By>;
  status?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  transactionByTransaction?: Maybe<Transaction_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: refund */
export type Refund_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "refund" */
export enum Refund_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "refund" */
export type Refund_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Refund_Status_Enum>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "refund_status" */
export type Refund_Status = {
  __typename?: 'refund_status';
  description: Scalars['String'];
  /** An array relationship */
  refunds: Array<Refund>;
  /** An aggregate relationship */
  refunds_aggregate: Refund_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "refund_status" */
export type Refund_StatusRefundsArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


/** columns and relationships of "refund_status" */
export type Refund_StatusRefunds_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};

/** aggregated selection of "refund_status" */
export type Refund_Status_Aggregate = {
  __typename?: 'refund_status_aggregate';
  aggregate?: Maybe<Refund_Status_Aggregate_Fields>;
  nodes: Array<Refund_Status>;
};

/** aggregate fields of "refund_status" */
export type Refund_Status_Aggregate_Fields = {
  __typename?: 'refund_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Refund_Status_Max_Fields>;
  min?: Maybe<Refund_Status_Min_Fields>;
};


/** aggregate fields of "refund_status" */
export type Refund_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Refund_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "refund_status". All fields are combined with a logical 'AND'. */
export type Refund_Status_Bool_Exp = {
  _and?: Maybe<Array<Refund_Status_Bool_Exp>>;
  _not?: Maybe<Refund_Status_Bool_Exp>;
  _or?: Maybe<Array<Refund_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  refunds?: Maybe<Refund_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "refund_status" */
export enum Refund_Status_Constraint {
  /** unique or primary key constraint */
  RefundStatusPkey = 'refund_status_pkey'
}

export enum Refund_Status_Enum {
  /** 환불건 발생 */
  Created = 'created',
  /** 환불 완료 */
  Finished = 'finished'
}

/** Boolean expression to compare columns of type "refund_status_enum". All fields are combined with logical 'AND'. */
export type Refund_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Refund_Status_Enum>;
  _in?: Maybe<Array<Refund_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Refund_Status_Enum>;
  _nin?: Maybe<Array<Refund_Status_Enum>>;
};

/** input type for inserting data into table "refund_status" */
export type Refund_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  refunds?: Maybe<Refund_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Refund_Status_Max_Fields = {
  __typename?: 'refund_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Refund_Status_Min_Fields = {
  __typename?: 'refund_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "refund_status" */
export type Refund_Status_Mutation_Response = {
  __typename?: 'refund_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Refund_Status>;
};

/** input type for inserting object relation for remote table "refund_status" */
export type Refund_Status_Obj_Rel_Insert_Input = {
  data: Refund_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Refund_Status_On_Conflict>;
};

/** on conflict condition type for table "refund_status" */
export type Refund_Status_On_Conflict = {
  constraint: Refund_Status_Constraint;
  update_columns?: Array<Refund_Status_Update_Column>;
  where?: Maybe<Refund_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "refund_status". */
export type Refund_Status_Order_By = {
  description?: Maybe<Order_By>;
  refunds_aggregate?: Maybe<Refund_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: refund_status */
export type Refund_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "refund_status" */
export enum Refund_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "refund_status" */
export type Refund_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "refund_status" */
export enum Refund_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Refund_Stddev_Fields = {
  __typename?: 'refund_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "refund" */
export type Refund_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Refund_Stddev_Pop_Fields = {
  __typename?: 'refund_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "refund" */
export type Refund_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Refund_Stddev_Samp_Fields = {
  __typename?: 'refund_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "refund" */
export type Refund_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Refund_Sum_Fields = {
  __typename?: 'refund_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "refund" */
export type Refund_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "refund" */
export enum Refund_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Refund_Var_Pop_Fields = {
  __typename?: 'refund_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "refund" */
export type Refund_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Refund_Var_Samp_Fields = {
  __typename?: 'refund_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "refund" */
export type Refund_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Refund_Variance_Fields = {
  __typename?: 'refund_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "refund" */
export type Refund_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "review" */
export type Review = {
  __typename?: 'review';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  image: Scalars['jsonb'];
  /** An array relationship */
  order_and_reviews: Array<Order_And_Review>;
  /** An aggregate relationship */
  order_and_reviews_aggregate: Order_And_Review_Aggregate;
  /** An array relationship */
  product_item_and_reviews: Array<Product_Item_And_Review>;
  /** An aggregate relationship */
  product_item_and_reviews_aggregate: Product_Item_And_Review_Aggregate;
  rating: Scalars['numeric'];
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  review_status: Review_Status;
  status: Review_Status_Enum;
  /** An array relationship */
  store_and_reviews: Array<Store_And_Review>;
  /** An aggregate relationship */
  store_and_reviews_aggregate: Store_And_Review_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "review" */
export type ReviewImageArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "review" */
export type ReviewOrder_And_ReviewsArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


/** columns and relationships of "review" */
export type ReviewOrder_And_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


/** columns and relationships of "review" */
export type ReviewProduct_Item_And_ReviewsArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


/** columns and relationships of "review" */
export type ReviewProduct_Item_And_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


/** columns and relationships of "review" */
export type ReviewStore_And_ReviewsArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};


/** columns and relationships of "review" */
export type ReviewStore_And_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};

/** aggregated selection of "review" */
export type Review_Aggregate = {
  __typename?: 'review_aggregate';
  aggregate?: Maybe<Review_Aggregate_Fields>;
  nodes: Array<Review>;
};

/** aggregate fields of "review" */
export type Review_Aggregate_Fields = {
  __typename?: 'review_aggregate_fields';
  avg?: Maybe<Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Review_Max_Fields>;
  min?: Maybe<Review_Min_Fields>;
  stddev?: Maybe<Review_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Sum_Fields>;
  var_pop?: Maybe<Review_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Var_Samp_Fields>;
  variance?: Maybe<Review_Variance_Fields>;
};


/** aggregate fields of "review" */
export type Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "review" */
export type Review_Aggregate_Order_By = {
  avg?: Maybe<Review_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Review_Max_Order_By>;
  min?: Maybe<Review_Min_Order_By>;
  stddev?: Maybe<Review_Stddev_Order_By>;
  stddev_pop?: Maybe<Review_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Review_Stddev_Samp_Order_By>;
  sum?: Maybe<Review_Sum_Order_By>;
  var_pop?: Maybe<Review_Var_Pop_Order_By>;
  var_samp?: Maybe<Review_Var_Samp_Order_By>;
  variance?: Maybe<Review_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Review_Append_Input = {
  image?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "review" */
export type Review_Arr_Rel_Insert_Input = {
  data: Array<Review_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Review_On_Conflict>;
};

/** aggregate avg on columns */
export type Review_Avg_Fields = {
  __typename?: 'review_avg_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "review" */
export type Review_Avg_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "review". All fields are combined with a logical 'AND'. */
export type Review_Bool_Exp = {
  _and?: Maybe<Array<Review_Bool_Exp>>;
  _not?: Maybe<Review_Bool_Exp>;
  _or?: Maybe<Array<Review_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<Jsonb_Comparison_Exp>;
  order_and_reviews?: Maybe<Order_And_Review_Bool_Exp>;
  product_item_and_reviews?: Maybe<Product_Item_And_Review_Bool_Exp>;
  rating?: Maybe<Numeric_Comparison_Exp>;
  rating_customer_service?: Maybe<Numeric_Comparison_Exp>;
  rating_facility?: Maybe<Numeric_Comparison_Exp>;
  rating_installation?: Maybe<Numeric_Comparison_Exp>;
  rating_price?: Maybe<Numeric_Comparison_Exp>;
  review_status?: Maybe<Review_Status_Bool_Exp>;
  status?: Maybe<Review_Status_Enum_Comparison_Exp>;
  store_and_reviews?: Maybe<Store_And_Review_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "review" */
export enum Review_Constraint {
  /** unique or primary key constraint */
  ReviewPkey = 'review_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Review_Delete_At_Path_Input = {
  image?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Review_Delete_Elem_Input = {
  image?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Review_Delete_Key_Input = {
  image?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "review" */
export type Review_Inc_Input = {
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "review" */
export type Review_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  order_and_reviews?: Maybe<Order_And_Review_Arr_Rel_Insert_Input>;
  product_item_and_reviews?: Maybe<Product_Item_And_Review_Arr_Rel_Insert_Input>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  review_status?: Maybe<Review_Status_Obj_Rel_Insert_Input>;
  status?: Maybe<Review_Status_Enum>;
  store_and_reviews?: Maybe<Store_And_Review_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Review_Max_Fields = {
  __typename?: 'review_max_fields';
  account?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "review" */
export type Review_Max_Order_By = {
  account?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Min_Fields = {
  __typename?: 'review_min_fields';
  account?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "review" */
export type Review_Min_Order_By = {
  account?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "review" */
export type Review_Mutation_Response = {
  __typename?: 'review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Review>;
};

/** input type for inserting object relation for remote table "review" */
export type Review_Obj_Rel_Insert_Input = {
  data: Review_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Review_On_Conflict>;
};

/** on conflict condition type for table "review" */
export type Review_On_Conflict = {
  constraint: Review_Constraint;
  update_columns?: Array<Review_Update_Column>;
  where?: Maybe<Review_Bool_Exp>;
};

/** Ordering options when selecting data from "review". */
export type Review_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  order_and_reviews_aggregate?: Maybe<Order_And_Review_Aggregate_Order_By>;
  product_item_and_reviews_aggregate?: Maybe<Product_Item_And_Review_Aggregate_Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
  review_status?: Maybe<Review_Status_Order_By>;
  status?: Maybe<Order_By>;
  store_and_reviews_aggregate?: Maybe<Store_And_Review_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: review */
export type Review_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Review_Prepend_Input = {
  image?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "review" */
export enum Review_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCustomerService = 'rating_customer_service',
  /** column name */
  RatingFacility = 'rating_facility',
  /** column name */
  RatingInstallation = 'rating_installation',
  /** column name */
  RatingPrice = 'rating_price',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "review" */
export type Review_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
  status?: Maybe<Review_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "review_status" */
export type Review_Status = {
  __typename?: 'review_status';
  description: Scalars['String'];
  /** An array relationship */
  reviews: Array<Review>;
  /** An aggregate relationship */
  reviews_aggregate: Review_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "review_status" */
export type Review_StatusReviewsArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


/** columns and relationships of "review_status" */
export type Review_StatusReviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};

/** aggregated selection of "review_status" */
export type Review_Status_Aggregate = {
  __typename?: 'review_status_aggregate';
  aggregate?: Maybe<Review_Status_Aggregate_Fields>;
  nodes: Array<Review_Status>;
};

/** aggregate fields of "review_status" */
export type Review_Status_Aggregate_Fields = {
  __typename?: 'review_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Review_Status_Max_Fields>;
  min?: Maybe<Review_Status_Min_Fields>;
};


/** aggregate fields of "review_status" */
export type Review_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Review_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "review_status". All fields are combined with a logical 'AND'. */
export type Review_Status_Bool_Exp = {
  _and?: Maybe<Array<Review_Status_Bool_Exp>>;
  _not?: Maybe<Review_Status_Bool_Exp>;
  _or?: Maybe<Array<Review_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  reviews?: Maybe<Review_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_status" */
export enum Review_Status_Constraint {
  /** unique or primary key constraint */
  ReviewStatusPkey = 'review_status_pkey'
}

export enum Review_Status_Enum {
  /** 승인 처리 완료 */
  Approved = 'approved',
  /** 보류중 */
  Pending = 'pending',
  /** 미승인 처리 완료 */
  Unapproved = 'unapproved'
}

/** Boolean expression to compare columns of type "review_status_enum". All fields are combined with logical 'AND'. */
export type Review_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Review_Status_Enum>;
  _in?: Maybe<Array<Review_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Review_Status_Enum>;
  _nin?: Maybe<Array<Review_Status_Enum>>;
};

/** input type for inserting data into table "review_status" */
export type Review_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  reviews?: Maybe<Review_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Review_Status_Max_Fields = {
  __typename?: 'review_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Review_Status_Min_Fields = {
  __typename?: 'review_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "review_status" */
export type Review_Status_Mutation_Response = {
  __typename?: 'review_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Status>;
};

/** input type for inserting object relation for remote table "review_status" */
export type Review_Status_Obj_Rel_Insert_Input = {
  data: Review_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Review_Status_On_Conflict>;
};

/** on conflict condition type for table "review_status" */
export type Review_Status_On_Conflict = {
  constraint: Review_Status_Constraint;
  update_columns?: Array<Review_Status_Update_Column>;
  where?: Maybe<Review_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "review_status". */
export type Review_Status_Order_By = {
  description?: Maybe<Order_By>;
  reviews_aggregate?: Maybe<Review_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: review_status */
export type Review_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "review_status" */
export enum Review_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "review_status" */
export type Review_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "review_status" */
export enum Review_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Review_Stddev_Fields = {
  __typename?: 'review_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "review" */
export type Review_Stddev_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Review_Stddev_Pop_Fields = {
  __typename?: 'review_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "review" */
export type Review_Stddev_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Review_Stddev_Samp_Fields = {
  __typename?: 'review_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "review" */
export type Review_Stddev_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Review_Sum_Fields = {
  __typename?: 'review_sum_fields';
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "review" */
export type Review_Sum_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** update columns of table "review" */
export enum Review_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCustomerService = 'rating_customer_service',
  /** column name */
  RatingFacility = 'rating_facility',
  /** column name */
  RatingInstallation = 'rating_installation',
  /** column name */
  RatingPrice = 'rating_price',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Review_Var_Pop_Fields = {
  __typename?: 'review_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "review" */
export type Review_Var_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Review_Var_Samp_Fields = {
  __typename?: 'review_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "review" */
export type Review_Var_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Review_Variance_Fields = {
  __typename?: 'review_variance_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "review" */
export type Review_Variance_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type Session = {
  __typename?: 'session';
  account?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  accountByAccount?: Maybe<Account>;
  /** An array relationship */
  activities: Array<Activity>;
  /** An aggregate relationship */
  activities_aggregate: Activity_Aggregate;
  /** User's channel id for zendesk chat */
  chat_channel?: Maybe<Scalars['String']>;
  /** User's current chatbot state */
  chatbot_state?: Maybe<Scalars['String']>;
  /** An array relationship */
  chats: Array<Chat>;
  /** An aggregate relationship */
  chats_aggregate: Chat_Aggregate;
  created_at: Scalars['timestamptz'];
  firestore_session?: Maybe<Scalars['jsonb']>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  ip?: Maybe<Scalars['String']>;
  /** An array relationship */
  n_carts: Array<N_Cart>;
  /** An aggregate relationship */
  n_carts_aggregate: N_Cart_Aggregate;
  /** An object relationship */
  slot?: Maybe<Slot>;
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_actions_histories: Array<User_Actions_History>;
  /** An aggregate relationship */
  user_actions_histories_aggregate: User_Actions_History_Aggregate;
  user_agent?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionActivitiesArgs = {
  distinct_on?: Maybe<Array<Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Order_By>>;
  where?: Maybe<Activity_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Order_By>>;
  where?: Maybe<Activity_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionChatsArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionChats_AggregateArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionFirestore_SessionArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionHeadersArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionN_CartsArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionN_Carts_AggregateArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionUser_Actions_HistoriesArgs = {
  distinct_on?: Maybe<Array<User_Actions_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Actions_History_Order_By>>;
  where?: Maybe<User_Actions_History_Bool_Exp>;
};


/**
 * Session - device unique id
 *
 *
 * columns and relationships of "session"
 */
export type SessionUser_Actions_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Actions_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Actions_History_Order_By>>;
  where?: Maybe<User_Actions_History_Bool_Exp>;
};

/** aggregated selection of "session" */
export type Session_Aggregate = {
  __typename?: 'session_aggregate';
  aggregate?: Maybe<Session_Aggregate_Fields>;
  nodes: Array<Session>;
};

/** aggregate fields of "session" */
export type Session_Aggregate_Fields = {
  __typename?: 'session_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Session_Max_Fields>;
  min?: Maybe<Session_Min_Fields>;
};


/** aggregate fields of "session" */
export type Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "session" */
export type Session_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Session_Max_Order_By>;
  min?: Maybe<Session_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Session_Append_Input = {
  firestore_session?: Maybe<Scalars['jsonb']>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "session" */
export type Session_Arr_Rel_Insert_Input = {
  data: Array<Session_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** Boolean expression to filter rows from the table "session". All fields are combined with a logical 'AND'. */
export type Session_Bool_Exp = {
  _and?: Maybe<Array<Session_Bool_Exp>>;
  _not?: Maybe<Session_Bool_Exp>;
  _or?: Maybe<Array<Session_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  activities?: Maybe<Activity_Bool_Exp>;
  chat_channel?: Maybe<String_Comparison_Exp>;
  chatbot_state?: Maybe<String_Comparison_Exp>;
  chats?: Maybe<Chat_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  firestore_session?: Maybe<Jsonb_Comparison_Exp>;
  headers?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  ip?: Maybe<String_Comparison_Exp>;
  n_carts?: Maybe<N_Cart_Bool_Exp>;
  slot?: Maybe<Slot_Bool_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_actions_histories?: Maybe<User_Actions_History_Bool_Exp>;
  user_agent?: Maybe<String_Comparison_Exp>;
  utm_campaign?: Maybe<String_Comparison_Exp>;
  utm_content?: Maybe<String_Comparison_Exp>;
  utm_medium?: Maybe<String_Comparison_Exp>;
  utm_source?: Maybe<String_Comparison_Exp>;
  utm_term?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "session" */
export enum Session_Constraint {
  /** unique or primary key constraint */
  SessionPkey = 'session_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Session_Delete_At_Path_Input = {
  firestore_session?: Maybe<Array<Scalars['String']>>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Session_Delete_Elem_Input = {
  firestore_session?: Maybe<Scalars['Int']>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Session_Delete_Key_Input = {
  firestore_session?: Maybe<Scalars['String']>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "session" */
export type Session_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  activities?: Maybe<Activity_Arr_Rel_Insert_Input>;
  /** User's channel id for zendesk chat */
  chat_channel?: Maybe<Scalars['String']>;
  /** User's current chatbot state */
  chatbot_state?: Maybe<Scalars['String']>;
  chats?: Maybe<Chat_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  firestore_session?: Maybe<Scalars['jsonb']>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  n_carts?: Maybe<N_Cart_Arr_Rel_Insert_Input>;
  slot?: Maybe<Slot_Obj_Rel_Insert_Input>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_actions_histories?: Maybe<User_Actions_History_Arr_Rel_Insert_Input>;
  user_agent?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Session_Max_Fields = {
  __typename?: 'session_max_fields';
  account?: Maybe<Scalars['uuid']>;
  /** User's channel id for zendesk chat */
  chat_channel?: Maybe<Scalars['String']>;
  /** User's current chatbot state */
  chatbot_state?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "session" */
export type Session_Max_Order_By = {
  account?: Maybe<Order_By>;
  /** User's channel id for zendesk chat */
  chat_channel?: Maybe<Order_By>;
  /** User's current chatbot state */
  chatbot_state?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_agent?: Maybe<Order_By>;
  utm_campaign?: Maybe<Order_By>;
  utm_content?: Maybe<Order_By>;
  utm_medium?: Maybe<Order_By>;
  utm_source?: Maybe<Order_By>;
  utm_term?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Min_Fields = {
  __typename?: 'session_min_fields';
  account?: Maybe<Scalars['uuid']>;
  /** User's channel id for zendesk chat */
  chat_channel?: Maybe<Scalars['String']>;
  /** User's current chatbot state */
  chatbot_state?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "session" */
export type Session_Min_Order_By = {
  account?: Maybe<Order_By>;
  /** User's channel id for zendesk chat */
  chat_channel?: Maybe<Order_By>;
  /** User's current chatbot state */
  chatbot_state?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_agent?: Maybe<Order_By>;
  utm_campaign?: Maybe<Order_By>;
  utm_content?: Maybe<Order_By>;
  utm_medium?: Maybe<Order_By>;
  utm_source?: Maybe<Order_By>;
  utm_term?: Maybe<Order_By>;
};

/** response of any mutation on the table "session" */
export type Session_Mutation_Response = {
  __typename?: 'session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session>;
};

/** input type for inserting object relation for remote table "session" */
export type Session_Obj_Rel_Insert_Input = {
  data: Session_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** on conflict condition type for table "session" */
export type Session_On_Conflict = {
  constraint: Session_Constraint;
  update_columns?: Array<Session_Update_Column>;
  where?: Maybe<Session_Bool_Exp>;
};

/** Ordering options when selecting data from "session". */
export type Session_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  activities_aggregate?: Maybe<Activity_Aggregate_Order_By>;
  chat_channel?: Maybe<Order_By>;
  chatbot_state?: Maybe<Order_By>;
  chats_aggregate?: Maybe<Chat_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  firestore_session?: Maybe<Order_By>;
  headers?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip?: Maybe<Order_By>;
  n_carts_aggregate?: Maybe<N_Cart_Aggregate_Order_By>;
  slot?: Maybe<Slot_Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_actions_histories_aggregate?: Maybe<User_Actions_History_Aggregate_Order_By>;
  user_agent?: Maybe<Order_By>;
  utm_campaign?: Maybe<Order_By>;
  utm_content?: Maybe<Order_By>;
  utm_medium?: Maybe<Order_By>;
  utm_source?: Maybe<Order_By>;
  utm_term?: Maybe<Order_By>;
};

/** primary key columns input for table: session */
export type Session_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Session_Prepend_Input = {
  firestore_session?: Maybe<Scalars['jsonb']>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "session" */
export enum Session_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  ChatChannel = 'chat_channel',
  /** column name */
  ChatbotState = 'chatbot_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirestoreSession = 'firestore_session',
  /** column name */
  Headers = 'headers',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term'
}

/** input type for updating data in table "session" */
export type Session_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  /** User's channel id for zendesk chat */
  chat_channel?: Maybe<Scalars['String']>;
  /** User's current chatbot state */
  chatbot_state?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  firestore_session?: Maybe<Scalars['jsonb']>;
  /** Extra information such as IP, Browser, OS and etc */
  headers?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
};

/** update columns of table "session" */
export enum Session_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  ChatChannel = 'chat_channel',
  /** column name */
  ChatbotState = 'chatbot_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirestoreSession = 'firestore_session',
  /** column name */
  Headers = 'headers',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term'
}

/** columns and relationships of "slot" */
export type Slot = {
  __typename?: 'slot';
  /** An object relationship */
  addressSidoByAddressSido?: Maybe<Address_Sido>;
  /** An object relationship */
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu>;
  address_sido?: Maybe<Scalars['uuid']>;
  address_sido_score?: Maybe<Scalars['numeric']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  address_sigungu_score?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  carByCar?: Maybe<Car>;
  /** An object relationship */
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type>;
  car_score?: Maybe<Scalars['numeric']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  car_sunroof_type_score?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_score?: Maybe<Scalars['numeric']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  package?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  packageByPackage?: Maybe<Package>;
  package_score?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  productByTintingProduct?: Maybe<Product>;
  /** An object relationship */
  productItemByTintingProductItemFront?: Maybe<Product_Item>;
  /** An object relationship */
  productItemByTintingProductItemSideback?: Maybe<Product_Item>;
  /** An object relationship */
  productItemByTintingProductItemSunroof?: Maybe<Product_Item>;
  session: Scalars['uuid'];
  /** An object relationship */
  sessionBySession: Session;
  store?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  storeByStore?: Maybe<Store>;
  store_score?: Maybe<Scalars['numeric']>;
  tinting_product?: Maybe<Scalars['uuid']>;
  tinting_product_item_front?: Maybe<Scalars['uuid']>;
  tinting_product_item_front_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sideback?: Maybe<Scalars['uuid']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sunroof?: Maybe<Scalars['uuid']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['numeric']>;
  tinting_product_score?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  used_car_status?: Maybe<Scalars['Boolean']>;
  used_car_status_score?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "slot" */
export type Slot_Aggregate = {
  __typename?: 'slot_aggregate';
  aggregate?: Maybe<Slot_Aggregate_Fields>;
  nodes: Array<Slot>;
};

/** aggregate fields of "slot" */
export type Slot_Aggregate_Fields = {
  __typename?: 'slot_aggregate_fields';
  avg?: Maybe<Slot_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Slot_Max_Fields>;
  min?: Maybe<Slot_Min_Fields>;
  stddev?: Maybe<Slot_Stddev_Fields>;
  stddev_pop?: Maybe<Slot_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Slot_Stddev_Samp_Fields>;
  sum?: Maybe<Slot_Sum_Fields>;
  var_pop?: Maybe<Slot_Var_Pop_Fields>;
  var_samp?: Maybe<Slot_Var_Samp_Fields>;
  variance?: Maybe<Slot_Variance_Fields>;
};


/** aggregate fields of "slot" */
export type Slot_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Slot_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "slot" */
export type Slot_Aggregate_Order_By = {
  avg?: Maybe<Slot_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Slot_Max_Order_By>;
  min?: Maybe<Slot_Min_Order_By>;
  stddev?: Maybe<Slot_Stddev_Order_By>;
  stddev_pop?: Maybe<Slot_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Slot_Stddev_Samp_Order_By>;
  sum?: Maybe<Slot_Sum_Order_By>;
  var_pop?: Maybe<Slot_Var_Pop_Order_By>;
  var_samp?: Maybe<Slot_Var_Samp_Order_By>;
  variance?: Maybe<Slot_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "slot" */
export type Slot_Arr_Rel_Insert_Input = {
  data: Array<Slot_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Slot_On_Conflict>;
};

/** aggregate avg on columns */
export type Slot_Avg_Fields = {
  __typename?: 'slot_avg_fields';
  address_sido_score?: Maybe<Scalars['Float']>;
  address_sigungu_score?: Maybe<Scalars['Float']>;
  car_score?: Maybe<Scalars['Float']>;
  car_sunroof_type_score?: Maybe<Scalars['Float']>;
  date_time_score?: Maybe<Scalars['Float']>;
  package_score?: Maybe<Scalars['Float']>;
  store_score?: Maybe<Scalars['Float']>;
  tinting_product_item_front_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['Float']>;
  tinting_product_score?: Maybe<Scalars['Float']>;
  used_car_status_score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "slot" */
export type Slot_Avg_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "slot". All fields are combined with a logical 'AND'. */
export type Slot_Bool_Exp = {
  _and?: Maybe<Array<Slot_Bool_Exp>>;
  _not?: Maybe<Slot_Bool_Exp>;
  _or?: Maybe<Array<Slot_Bool_Exp>>;
  addressSidoByAddressSido?: Maybe<Address_Sido_Bool_Exp>;
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Bool_Exp>;
  address_sido?: Maybe<Uuid_Comparison_Exp>;
  address_sido_score?: Maybe<Numeric_Comparison_Exp>;
  address_sigungu?: Maybe<Uuid_Comparison_Exp>;
  address_sigungu_score?: Maybe<Numeric_Comparison_Exp>;
  car?: Maybe<Uuid_Comparison_Exp>;
  carByCar?: Maybe<Car_Bool_Exp>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Bool_Exp>;
  car_score?: Maybe<Numeric_Comparison_Exp>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum_Comparison_Exp>;
  car_sunroof_type_score?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_time?: Maybe<Timestamptz_Comparison_Exp>;
  date_time_score?: Maybe<Numeric_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  package?: Maybe<Uuid_Comparison_Exp>;
  packageByPackage?: Maybe<Package_Bool_Exp>;
  package_score?: Maybe<Numeric_Comparison_Exp>;
  productByTintingProduct?: Maybe<Product_Bool_Exp>;
  productItemByTintingProductItemFront?: Maybe<Product_Item_Bool_Exp>;
  productItemByTintingProductItemSideback?: Maybe<Product_Item_Bool_Exp>;
  productItemByTintingProductItemSunroof?: Maybe<Product_Item_Bool_Exp>;
  session?: Maybe<Uuid_Comparison_Exp>;
  sessionBySession?: Maybe<Session_Bool_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  store_score?: Maybe<Numeric_Comparison_Exp>;
  tinting_product?: Maybe<Uuid_Comparison_Exp>;
  tinting_product_item_front?: Maybe<Uuid_Comparison_Exp>;
  tinting_product_item_front_score?: Maybe<Numeric_Comparison_Exp>;
  tinting_product_item_sideback?: Maybe<Uuid_Comparison_Exp>;
  tinting_product_item_sideback_score?: Maybe<Numeric_Comparison_Exp>;
  tinting_product_item_sunroof?: Maybe<Uuid_Comparison_Exp>;
  tinting_product_item_sunroof_score?: Maybe<Numeric_Comparison_Exp>;
  tinting_product_score?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  used_car_status?: Maybe<Boolean_Comparison_Exp>;
  used_car_status_score?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "slot" */
export enum Slot_Constraint {
  /** unique or primary key constraint */
  SlotPkey = 'slot_pkey',
  /** unique or primary key constraint */
  SlotSessionKey = 'slot_session_key'
}

/** input type for incrementing numeric columns in table "slot" */
export type Slot_Inc_Input = {
  address_sido_score?: Maybe<Scalars['numeric']>;
  address_sigungu_score?: Maybe<Scalars['numeric']>;
  car_score?: Maybe<Scalars['numeric']>;
  car_sunroof_type_score?: Maybe<Scalars['numeric']>;
  date_time_score?: Maybe<Scalars['numeric']>;
  package_score?: Maybe<Scalars['numeric']>;
  store_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_front_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['numeric']>;
  tinting_product_score?: Maybe<Scalars['numeric']>;
  used_car_status_score?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "slot" */
export type Slot_Insert_Input = {
  addressSidoByAddressSido?: Maybe<Address_Sido_Obj_Rel_Insert_Input>;
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Obj_Rel_Insert_Input>;
  address_sido?: Maybe<Scalars['uuid']>;
  address_sido_score?: Maybe<Scalars['numeric']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  address_sigungu_score?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  carByCar?: Maybe<Car_Obj_Rel_Insert_Input>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Obj_Rel_Insert_Input>;
  car_score?: Maybe<Scalars['numeric']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  car_sunroof_type_score?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_score?: Maybe<Scalars['numeric']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  packageByPackage?: Maybe<Package_Obj_Rel_Insert_Input>;
  package_score?: Maybe<Scalars['numeric']>;
  productByTintingProduct?: Maybe<Product_Obj_Rel_Insert_Input>;
  productItemByTintingProductItemFront?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  productItemByTintingProductItemSideback?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  productItemByTintingProductItemSunroof?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  session?: Maybe<Scalars['uuid']>;
  sessionBySession?: Maybe<Session_Obj_Rel_Insert_Input>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  store_score?: Maybe<Scalars['numeric']>;
  tinting_product?: Maybe<Scalars['uuid']>;
  tinting_product_item_front?: Maybe<Scalars['uuid']>;
  tinting_product_item_front_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sideback?: Maybe<Scalars['uuid']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sunroof?: Maybe<Scalars['uuid']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['numeric']>;
  tinting_product_score?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_car_status?: Maybe<Scalars['Boolean']>;
  used_car_status_score?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Slot_Max_Fields = {
  __typename?: 'slot_max_fields';
  address_sido?: Maybe<Scalars['uuid']>;
  address_sido_score?: Maybe<Scalars['numeric']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  address_sigungu_score?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  car_score?: Maybe<Scalars['numeric']>;
  car_sunroof_type_score?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_score?: Maybe<Scalars['numeric']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  package_score?: Maybe<Scalars['numeric']>;
  session?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  store_score?: Maybe<Scalars['numeric']>;
  tinting_product?: Maybe<Scalars['uuid']>;
  tinting_product_item_front?: Maybe<Scalars['uuid']>;
  tinting_product_item_front_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sideback?: Maybe<Scalars['uuid']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sunroof?: Maybe<Scalars['uuid']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['numeric']>;
  tinting_product_score?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_car_status_score?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "slot" */
export type Slot_Max_Order_By = {
  address_sido?: Maybe<Order_By>;
  address_sido_score?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product?: Maybe<Order_By>;
  tinting_product_item_front?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Slot_Min_Fields = {
  __typename?: 'slot_min_fields';
  address_sido?: Maybe<Scalars['uuid']>;
  address_sido_score?: Maybe<Scalars['numeric']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  address_sigungu_score?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  car_score?: Maybe<Scalars['numeric']>;
  car_sunroof_type_score?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_score?: Maybe<Scalars['numeric']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  package_score?: Maybe<Scalars['numeric']>;
  session?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  store_score?: Maybe<Scalars['numeric']>;
  tinting_product?: Maybe<Scalars['uuid']>;
  tinting_product_item_front?: Maybe<Scalars['uuid']>;
  tinting_product_item_front_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sideback?: Maybe<Scalars['uuid']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sunroof?: Maybe<Scalars['uuid']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['numeric']>;
  tinting_product_score?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_car_status_score?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "slot" */
export type Slot_Min_Order_By = {
  address_sido?: Maybe<Order_By>;
  address_sido_score?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product?: Maybe<Order_By>;
  tinting_product_item_front?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** response of any mutation on the table "slot" */
export type Slot_Mutation_Response = {
  __typename?: 'slot_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Slot>;
};

/** input type for inserting object relation for remote table "slot" */
export type Slot_Obj_Rel_Insert_Input = {
  data: Slot_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Slot_On_Conflict>;
};

/** on conflict condition type for table "slot" */
export type Slot_On_Conflict = {
  constraint: Slot_Constraint;
  update_columns?: Array<Slot_Update_Column>;
  where?: Maybe<Slot_Bool_Exp>;
};

/** Ordering options when selecting data from "slot". */
export type Slot_Order_By = {
  addressSidoByAddressSido?: Maybe<Address_Sido_Order_By>;
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Order_By>;
  address_sido?: Maybe<Order_By>;
  address_sido_score?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car?: Maybe<Order_By>;
  carByCar?: Maybe<Car_Order_By>;
  carSunroofTypeByCarSunroofType?: Maybe<Car_Sunroof_Type_Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  packageByPackage?: Maybe<Package_Order_By>;
  package_score?: Maybe<Order_By>;
  productByTintingProduct?: Maybe<Product_Order_By>;
  productItemByTintingProductItemFront?: Maybe<Product_Item_Order_By>;
  productItemByTintingProductItemSideback?: Maybe<Product_Item_Order_By>;
  productItemByTintingProductItemSunroof?: Maybe<Product_Item_Order_By>;
  session?: Maybe<Order_By>;
  sessionBySession?: Maybe<Session_Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product?: Maybe<Order_By>;
  tinting_product_item_front?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used_car_status?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** primary key columns input for table: slot */
export type Slot_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "slot" */
export enum Slot_Select_Column {
  /** column name */
  AddressSido = 'address_sido',
  /** column name */
  AddressSidoScore = 'address_sido_score',
  /** column name */
  AddressSigungu = 'address_sigungu',
  /** column name */
  AddressSigunguScore = 'address_sigungu_score',
  /** column name */
  Car = 'car',
  /** column name */
  CarScore = 'car_score',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CarSunroofTypeScore = 'car_sunroof_type_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DateTimeScore = 'date_time_score',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Package = 'package',
  /** column name */
  PackageScore = 'package_score',
  /** column name */
  Session = 'session',
  /** column name */
  Store = 'store',
  /** column name */
  StoreScore = 'store_score',
  /** column name */
  TintingProduct = 'tinting_product',
  /** column name */
  TintingProductItemFront = 'tinting_product_item_front',
  /** column name */
  TintingProductItemFrontScore = 'tinting_product_item_front_score',
  /** column name */
  TintingProductItemSideback = 'tinting_product_item_sideback',
  /** column name */
  TintingProductItemSidebackScore = 'tinting_product_item_sideback_score',
  /** column name */
  TintingProductItemSunroof = 'tinting_product_item_sunroof',
  /** column name */
  TintingProductItemSunroofScore = 'tinting_product_item_sunroof_score',
  /** column name */
  TintingProductScore = 'tinting_product_score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedCarStatus = 'used_car_status',
  /** column name */
  UsedCarStatusScore = 'used_car_status_score'
}

/** input type for updating data in table "slot" */
export type Slot_Set_Input = {
  address_sido?: Maybe<Scalars['uuid']>;
  address_sido_score?: Maybe<Scalars['numeric']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  address_sigungu_score?: Maybe<Scalars['numeric']>;
  car?: Maybe<Scalars['uuid']>;
  car_score?: Maybe<Scalars['numeric']>;
  car_sunroof_type?: Maybe<Car_Sunroof_Type_Enum>;
  car_sunroof_type_score?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  date_time_score?: Maybe<Scalars['numeric']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  package_score?: Maybe<Scalars['numeric']>;
  session?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  store_score?: Maybe<Scalars['numeric']>;
  tinting_product?: Maybe<Scalars['uuid']>;
  tinting_product_item_front?: Maybe<Scalars['uuid']>;
  tinting_product_item_front_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sideback?: Maybe<Scalars['uuid']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sunroof?: Maybe<Scalars['uuid']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['numeric']>;
  tinting_product_score?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_car_status?: Maybe<Scalars['Boolean']>;
  used_car_status_score?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Slot_Stddev_Fields = {
  __typename?: 'slot_stddev_fields';
  address_sido_score?: Maybe<Scalars['Float']>;
  address_sigungu_score?: Maybe<Scalars['Float']>;
  car_score?: Maybe<Scalars['Float']>;
  car_sunroof_type_score?: Maybe<Scalars['Float']>;
  date_time_score?: Maybe<Scalars['Float']>;
  package_score?: Maybe<Scalars['Float']>;
  store_score?: Maybe<Scalars['Float']>;
  tinting_product_item_front_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['Float']>;
  tinting_product_score?: Maybe<Scalars['Float']>;
  used_car_status_score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "slot" */
export type Slot_Stddev_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Slot_Stddev_Pop_Fields = {
  __typename?: 'slot_stddev_pop_fields';
  address_sido_score?: Maybe<Scalars['Float']>;
  address_sigungu_score?: Maybe<Scalars['Float']>;
  car_score?: Maybe<Scalars['Float']>;
  car_sunroof_type_score?: Maybe<Scalars['Float']>;
  date_time_score?: Maybe<Scalars['Float']>;
  package_score?: Maybe<Scalars['Float']>;
  store_score?: Maybe<Scalars['Float']>;
  tinting_product_item_front_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['Float']>;
  tinting_product_score?: Maybe<Scalars['Float']>;
  used_car_status_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "slot" */
export type Slot_Stddev_Pop_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Slot_Stddev_Samp_Fields = {
  __typename?: 'slot_stddev_samp_fields';
  address_sido_score?: Maybe<Scalars['Float']>;
  address_sigungu_score?: Maybe<Scalars['Float']>;
  car_score?: Maybe<Scalars['Float']>;
  car_sunroof_type_score?: Maybe<Scalars['Float']>;
  date_time_score?: Maybe<Scalars['Float']>;
  package_score?: Maybe<Scalars['Float']>;
  store_score?: Maybe<Scalars['Float']>;
  tinting_product_item_front_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['Float']>;
  tinting_product_score?: Maybe<Scalars['Float']>;
  used_car_status_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "slot" */
export type Slot_Stddev_Samp_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Slot_Sum_Fields = {
  __typename?: 'slot_sum_fields';
  address_sido_score?: Maybe<Scalars['numeric']>;
  address_sigungu_score?: Maybe<Scalars['numeric']>;
  car_score?: Maybe<Scalars['numeric']>;
  car_sunroof_type_score?: Maybe<Scalars['numeric']>;
  date_time_score?: Maybe<Scalars['numeric']>;
  package_score?: Maybe<Scalars['numeric']>;
  store_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_front_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['numeric']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['numeric']>;
  tinting_product_score?: Maybe<Scalars['numeric']>;
  used_car_status_score?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "slot" */
export type Slot_Sum_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** update columns of table "slot" */
export enum Slot_Update_Column {
  /** column name */
  AddressSido = 'address_sido',
  /** column name */
  AddressSidoScore = 'address_sido_score',
  /** column name */
  AddressSigungu = 'address_sigungu',
  /** column name */
  AddressSigunguScore = 'address_sigungu_score',
  /** column name */
  Car = 'car',
  /** column name */
  CarScore = 'car_score',
  /** column name */
  CarSunroofType = 'car_sunroof_type',
  /** column name */
  CarSunroofTypeScore = 'car_sunroof_type_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DateTimeScore = 'date_time_score',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Package = 'package',
  /** column name */
  PackageScore = 'package_score',
  /** column name */
  Session = 'session',
  /** column name */
  Store = 'store',
  /** column name */
  StoreScore = 'store_score',
  /** column name */
  TintingProduct = 'tinting_product',
  /** column name */
  TintingProductItemFront = 'tinting_product_item_front',
  /** column name */
  TintingProductItemFrontScore = 'tinting_product_item_front_score',
  /** column name */
  TintingProductItemSideback = 'tinting_product_item_sideback',
  /** column name */
  TintingProductItemSidebackScore = 'tinting_product_item_sideback_score',
  /** column name */
  TintingProductItemSunroof = 'tinting_product_item_sunroof',
  /** column name */
  TintingProductItemSunroofScore = 'tinting_product_item_sunroof_score',
  /** column name */
  TintingProductScore = 'tinting_product_score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedCarStatus = 'used_car_status',
  /** column name */
  UsedCarStatusScore = 'used_car_status_score'
}

/** aggregate var_pop on columns */
export type Slot_Var_Pop_Fields = {
  __typename?: 'slot_var_pop_fields';
  address_sido_score?: Maybe<Scalars['Float']>;
  address_sigungu_score?: Maybe<Scalars['Float']>;
  car_score?: Maybe<Scalars['Float']>;
  car_sunroof_type_score?: Maybe<Scalars['Float']>;
  date_time_score?: Maybe<Scalars['Float']>;
  package_score?: Maybe<Scalars['Float']>;
  store_score?: Maybe<Scalars['Float']>;
  tinting_product_item_front_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['Float']>;
  tinting_product_score?: Maybe<Scalars['Float']>;
  used_car_status_score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "slot" */
export type Slot_Var_Pop_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Slot_Var_Samp_Fields = {
  __typename?: 'slot_var_samp_fields';
  address_sido_score?: Maybe<Scalars['Float']>;
  address_sigungu_score?: Maybe<Scalars['Float']>;
  car_score?: Maybe<Scalars['Float']>;
  car_sunroof_type_score?: Maybe<Scalars['Float']>;
  date_time_score?: Maybe<Scalars['Float']>;
  package_score?: Maybe<Scalars['Float']>;
  store_score?: Maybe<Scalars['Float']>;
  tinting_product_item_front_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['Float']>;
  tinting_product_score?: Maybe<Scalars['Float']>;
  used_car_status_score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "slot" */
export type Slot_Var_Samp_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Slot_Variance_Fields = {
  __typename?: 'slot_variance_fields';
  address_sido_score?: Maybe<Scalars['Float']>;
  address_sigungu_score?: Maybe<Scalars['Float']>;
  car_score?: Maybe<Scalars['Float']>;
  car_sunroof_type_score?: Maybe<Scalars['Float']>;
  date_time_score?: Maybe<Scalars['Float']>;
  package_score?: Maybe<Scalars['Float']>;
  store_score?: Maybe<Scalars['Float']>;
  tinting_product_item_front_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sideback_score?: Maybe<Scalars['Float']>;
  tinting_product_item_sunroof_score?: Maybe<Scalars['Float']>;
  tinting_product_score?: Maybe<Scalars['Float']>;
  used_car_status_score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "slot" */
export type Slot_Variance_Order_By = {
  address_sido_score?: Maybe<Order_By>;
  address_sigungu_score?: Maybe<Order_By>;
  car_score?: Maybe<Order_By>;
  car_sunroof_type_score?: Maybe<Order_By>;
  date_time_score?: Maybe<Order_By>;
  package_score?: Maybe<Order_By>;
  store_score?: Maybe<Order_By>;
  tinting_product_item_front_score?: Maybe<Order_By>;
  tinting_product_item_sideback_score?: Maybe<Order_By>;
  tinting_product_item_sunroof_score?: Maybe<Order_By>;
  tinting_product_score?: Maybe<Order_By>;
  used_car_status_score?: Maybe<Order_By>;
};

/** columns and relationships of "sole" */
export type Sole = {
  __typename?: 'sole';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  store_and_soles: Array<Store_And_Sole>;
  /** An aggregate relationship */
  store_and_soles_aggregate: Store_And_Sole_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "sole" */
export type SoleStore_And_SolesArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};


/** columns and relationships of "sole" */
export type SoleStore_And_Soles_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};

/** aggregated selection of "sole" */
export type Sole_Aggregate = {
  __typename?: 'sole_aggregate';
  aggregate?: Maybe<Sole_Aggregate_Fields>;
  nodes: Array<Sole>;
};

/** aggregate fields of "sole" */
export type Sole_Aggregate_Fields = {
  __typename?: 'sole_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sole_Max_Fields>;
  min?: Maybe<Sole_Min_Fields>;
};


/** aggregate fields of "sole" */
export type Sole_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sole_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sole". All fields are combined with a logical 'AND'. */
export type Sole_Bool_Exp = {
  _and?: Maybe<Array<Sole_Bool_Exp>>;
  _not?: Maybe<Sole_Bool_Exp>;
  _or?: Maybe<Array<Sole_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  store_and_soles?: Maybe<Store_And_Sole_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sole" */
export enum Sole_Constraint {
  /** unique or primary key constraint */
  SolePkey = 'sole_pkey'
}

/** input type for inserting data into table "sole" */
export type Sole_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  store_and_soles?: Maybe<Store_And_Sole_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sole_Max_Fields = {
  __typename?: 'sole_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sole_Min_Fields = {
  __typename?: 'sole_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sole" */
export type Sole_Mutation_Response = {
  __typename?: 'sole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sole>;
};

/** input type for inserting object relation for remote table "sole" */
export type Sole_Obj_Rel_Insert_Input = {
  data: Sole_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Sole_On_Conflict>;
};

/** on conflict condition type for table "sole" */
export type Sole_On_Conflict = {
  constraint: Sole_Constraint;
  update_columns?: Array<Sole_Update_Column>;
  where?: Maybe<Sole_Bool_Exp>;
};

/** Ordering options when selecting data from "sole". */
export type Sole_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  store_and_soles_aggregate?: Maybe<Store_And_Sole_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sole */
export type Sole_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sole" */
export enum Sole_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sole" */
export type Sole_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "sole" */
export enum Sole_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "spatial_ref_sys" */
export type Spatial_Ref_Sys = {
  __typename?: 'spatial_ref_sys';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid: Scalars['Int'];
  srtext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate = {
  __typename?: 'spatial_ref_sys_aggregate';
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_Fields = {
  __typename?: 'spatial_ref_sys_aggregate_fields';
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
};


/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spatial_Ref_Sys_Avg_Fields = {
  __typename?: 'spatial_ref_sys_avg_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type Spatial_Ref_Sys_Bool_Exp = {
  _and?: Maybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: Maybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: Maybe<String_Comparison_Exp>;
  auth_srid?: Maybe<Int_Comparison_Exp>;
  proj4text?: Maybe<String_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  srtext?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Constraint {
  /** unique or primary key constraint */
  SpatialRefSysPkey = 'spatial_ref_sys_pkey'
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Inc_Input = {
  auth_srid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Insert_Input = {
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Spatial_Ref_Sys_Max_Fields = {
  __typename?: 'spatial_ref_sys_max_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spatial_Ref_Sys_Min_Fields = {
  __typename?: 'spatial_ref_sys_min_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Mutation_Response = {
  __typename?: 'spatial_ref_sys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
};

/** on conflict condition type for table "spatial_ref_sys" */
export type Spatial_Ref_Sys_On_Conflict = {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns?: Array<Spatial_Ref_Sys_Update_Column>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type Spatial_Ref_Sys_Order_By = {
  auth_name?: Maybe<Order_By>;
  auth_srid?: Maybe<Order_By>;
  proj4text?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  srtext?: Maybe<Order_By>;
};

/** primary key columns input for table: spatial_ref_sys */
export type Spatial_Ref_Sys_Pk_Columns_Input = {
  srid: Scalars['Int'];
};

/** select columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Select_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** input type for updating data in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Set_Input = {
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Spatial_Ref_Sys_Stddev_Fields = {
  __typename?: 'spatial_ref_sys_stddev_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spatial_Ref_Sys_Stddev_Pop_Fields = {
  __typename?: 'spatial_ref_sys_stddev_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spatial_Ref_Sys_Stddev_Samp_Fields = {
  __typename?: 'spatial_ref_sys_stddev_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Spatial_Ref_Sys_Sum_Fields = {
  __typename?: 'spatial_ref_sys_sum_fields';
  auth_srid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** update columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Update_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** aggregate var_pop on columns */
export type Spatial_Ref_Sys_Var_Pop_Fields = {
  __typename?: 'spatial_ref_sys_var_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spatial_Ref_Sys_Var_Samp_Fields = {
  __typename?: 'spatial_ref_sys_var_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spatial_Ref_Sys_Variance_Fields = {
  __typename?: 'spatial_ref_sys_variance_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type Store = {
  __typename?: 'store';
  /** An object relationship */
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu>;
  /** Dong and below */
  address_detail?: Maybe<Scalars['String']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  adjustments: Array<Adjustment>;
  /** An aggregate relationship */
  adjustments_aggregate: Adjustment_Aggregate;
  approved_at?: Maybe<Scalars['timestamptz']>;
  attribute?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  blog_posts: Array<Blog_Post>;
  /** An aggregate relationship */
  blog_posts_aggregate: Blog_Post_Aggregate;
  /** An array relationship */
  car_inspections: Array<Car_Inspection>;
  /** An aggregate relationship */
  car_inspections_aggregate: Car_Inspection_Aggregate;
  caramora_moracano?: Maybe<Scalars['String']>;
  /** An array relationship */
  cart_items: Array<Cart_Item>;
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** An array relationship */
  fake_reviews: Array<Fake_Review>;
  /** An aggregate relationship */
  fake_reviews_aggregate: Fake_Review_Aggregate;
  /** An array relationship */
  galleries: Array<Gallery>;
  /** An aggregate relationship */
  galleries_aggregate: Gallery_Aggregate;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  inventories: Array<Inventory>;
  /** An aggregate relationship */
  inventories_aggregate: Inventory_Aggregate;
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  is_representative_store: Scalars['Boolean'];
  llumar_store_name?: Maybe<Scalars['String']>;
  llumar_store_type?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geography']>;
  /** An object relationship */
  membership?: Maybe<Membership>;
  /** An array relationship */
  memberships: Array<Membership>;
  /** An aggregate relationship */
  memberships_aggregate: Membership_Aggregate;
  name: Scalars['String'];
  name_business: Scalars['String'];
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  /** An array relationship */
  order_from_stores: Array<Order_From_Store>;
  /** An aggregate relationship */
  order_from_stores_aggregate: Order_From_Store_Aggregate;
  /** An array relationship */
  order_items: Array<Order_Item>;
  /** An aggregate relationship */
  order_items_aggregate: Order_Item_Aggregate;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate;
  /** An array relationship */
  payouts: Array<Payout>;
  /** An aggregate relationship */
  payouts_aggregate: Payout_Aggregate;
  phone_business?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  product_item_price_by_stores: Array<Product_Item_Price_By_Store>;
  /** An aggregate relationship */
  product_item_price_by_stores_aggregate: Product_Item_Price_By_Store_Aggregate;
  /** An array relationship */
  quote_items: Array<Quote_Item>;
  /** An aggregate relationship */
  quote_items_aggregate: Quote_Item_Aggregate;
  /** A computed field, executes function "get_store_rating" */
  rating?: Maybe<Array<Store_With_Rating>>;
  /** A computed field, executes function "get_store_rating_of_fake" */
  rating_of_fake?: Maybe<Array<Store_With_Rating_Of_Fake>>;
  /** An array relationship */
  slots: Array<Slot>;
  /** An aggregate relationship */
  slots_aggregate: Slot_Aggregate;
  status?: Maybe<Store_Status_Enum>;
  /** An array relationship */
  store_and_accounts: Array<Store_And_Account>;
  /** An aggregate relationship */
  store_and_accounts_aggregate: Store_And_Account_Aggregate;
  /** An array relationship */
  store_and_hashtags: Array<Store_And_Hashtag>;
  /** An aggregate relationship */
  store_and_hashtags_aggregate: Store_And_Hashtag_Aggregate;
  /** An array relationship */
  store_and_likes: Array<Store_And_Like>;
  /** An aggregate relationship */
  store_and_likes_aggregate: Store_And_Like_Aggregate;
  /** An array relationship */
  store_and_packages: Array<Store_And_Package>;
  /** An aggregate relationship */
  store_and_packages_aggregate: Store_And_Package_Aggregate;
  /** An array relationship */
  store_and_product_items: Array<Store_And_Product_Item>;
  /** An aggregate relationship */
  store_and_product_items_aggregate: Store_And_Product_Item_Aggregate;
  /** An array relationship */
  store_and_products: Array<Store_And_Product>;
  /** An aggregate relationship */
  store_and_products_aggregate: Store_And_Product_Aggregate;
  /** An array relationship */
  store_and_reviews: Array<Store_And_Review>;
  /** An aggregate relationship */
  store_and_reviews_aggregate: Store_And_Review_Aggregate;
  /** An array relationship */
  store_and_soles: Array<Store_And_Sole>;
  /** An aggregate relationship */
  store_and_soles_aggregate: Store_And_Sole_Aggregate;
  /** An object relationship */
  store_status?: Maybe<Store_Status>;
  /** An array relationship */
  subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscription_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  view_package_stores: Array<View_Package_Store>;
  /** An aggregate relationship */
  view_package_stores_aggregate: View_Package_Store_Aggregate;
  /** An array relationship */
  view_stores: Array<View_Store>;
  /** An aggregate relationship */
  view_stores_aggregate: View_Store_Aggregate;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreAdjustmentsArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreAdjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreAttributeArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreBlog_PostsArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreBlog_Posts_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreCar_InspectionsArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreCar_Inspections_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreFake_ReviewsArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreFake_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreGalleriesArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreGalleries_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreImageArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreInventoriesArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreInventories_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreMembershipsArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreMemberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreOrder_From_StoresArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreOrder_From_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreOrder_ItemsArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreOrder_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StorePayoutsArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StorePayouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreProduct_Item_Price_By_StoresArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreProduct_Item_Price_By_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreQuote_ItemsArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreQuote_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreRatingArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Order_By>>;
  where?: Maybe<Store_With_Rating_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreRating_Of_FakeArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Of_Fake_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Of_Fake_Order_By>>;
  where?: Maybe<Store_With_Rating_Of_Fake_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreSlotsArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_AccountsArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_HashtagsArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Hashtags_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_LikesArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_PackagesArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Packages_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Product_ItemsArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Product_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_ProductsArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_ReviewsArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_SolesArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreStore_And_Soles_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreSubscriptionsArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreSubscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreView_Package_StoresArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreView_Package_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreView_StoresArgs = {
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


/**
 * General information about a store
 *
 *
 * columns and relationships of "store"
 */
export type StoreView_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};

/**
 * Store representative's position
 *
 *
 * columns and relationships of "store_account_role"
 */
export type Store_Account_Role = {
  __typename?: 'store_account_role';
  description: Scalars['String'];
  /** An array relationship */
  store_and_accounts: Array<Store_And_Account>;
  /** An aggregate relationship */
  store_and_accounts_aggregate: Store_And_Account_Aggregate;
  value: Scalars['String'];
};


/**
 * Store representative's position
 *
 *
 * columns and relationships of "store_account_role"
 */
export type Store_Account_RoleStore_And_AccountsArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


/**
 * Store representative's position
 *
 *
 * columns and relationships of "store_account_role"
 */
export type Store_Account_RoleStore_And_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};

/** aggregated selection of "store_account_role" */
export type Store_Account_Role_Aggregate = {
  __typename?: 'store_account_role_aggregate';
  aggregate?: Maybe<Store_Account_Role_Aggregate_Fields>;
  nodes: Array<Store_Account_Role>;
};

/** aggregate fields of "store_account_role" */
export type Store_Account_Role_Aggregate_Fields = {
  __typename?: 'store_account_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Account_Role_Max_Fields>;
  min?: Maybe<Store_Account_Role_Min_Fields>;
};


/** aggregate fields of "store_account_role" */
export type Store_Account_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_Account_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_account_role". All fields are combined with a logical 'AND'. */
export type Store_Account_Role_Bool_Exp = {
  _and?: Maybe<Array<Store_Account_Role_Bool_Exp>>;
  _not?: Maybe<Store_Account_Role_Bool_Exp>;
  _or?: Maybe<Array<Store_Account_Role_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  store_and_accounts?: Maybe<Store_And_Account_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_account_role" */
export enum Store_Account_Role_Constraint {
  /** unique or primary key constraint */
  StoreAccountRolePkey = 'store_account_role_pkey'
}

export enum Store_Account_Role_Enum {
  /** 파트너샵 관리자 */
  Administrator = 'administrator',
  /**  파트너샵 고객 */
  Customer = 'customer',
  /** 파트너샵 대표 */
  Owner = 'owner'
}

/** Boolean expression to compare columns of type "store_account_role_enum". All fields are combined with logical 'AND'. */
export type Store_Account_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Store_Account_Role_Enum>;
  _in?: Maybe<Array<Store_Account_Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Store_Account_Role_Enum>;
  _nin?: Maybe<Array<Store_Account_Role_Enum>>;
};

/** input type for inserting data into table "store_account_role" */
export type Store_Account_Role_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  store_and_accounts?: Maybe<Store_And_Account_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Account_Role_Max_Fields = {
  __typename?: 'store_account_role_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Account_Role_Min_Fields = {
  __typename?: 'store_account_role_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_account_role" */
export type Store_Account_Role_Mutation_Response = {
  __typename?: 'store_account_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Account_Role>;
};

/** input type for inserting object relation for remote table "store_account_role" */
export type Store_Account_Role_Obj_Rel_Insert_Input = {
  data: Store_Account_Role_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Store_Account_Role_On_Conflict>;
};

/** on conflict condition type for table "store_account_role" */
export type Store_Account_Role_On_Conflict = {
  constraint: Store_Account_Role_Constraint;
  update_columns?: Array<Store_Account_Role_Update_Column>;
  where?: Maybe<Store_Account_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "store_account_role". */
export type Store_Account_Role_Order_By = {
  description?: Maybe<Order_By>;
  store_and_accounts_aggregate?: Maybe<Store_And_Account_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: store_account_role */
export type Store_Account_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "store_account_role" */
export enum Store_Account_Role_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "store_account_role" */
export type Store_Account_Role_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "store_account_role" */
export enum Store_Account_Role_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregated selection of "store" */
export type Store_Aggregate = {
  __typename?: 'store_aggregate';
  aggregate?: Maybe<Store_Aggregate_Fields>;
  nodes: Array<Store>;
};

/** aggregate fields of "store" */
export type Store_Aggregate_Fields = {
  __typename?: 'store_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Max_Fields>;
  min?: Maybe<Store_Min_Fields>;
};


/** aggregate fields of "store" */
export type Store_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store" */
export type Store_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Store_Max_Order_By>;
  min?: Maybe<Store_Min_Order_By>;
};

/**
 * Store representative's account
 *
 *
 * columns and relationships of "store_and_account"
 */
export type Store_And_Account = {
  __typename?: 'store_and_account';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['String']>;
  role: Store_Account_Role_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  /** An object relationship */
  store_account_role: Store_Account_Role;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_and_account" */
export type Store_And_Account_Aggregate = {
  __typename?: 'store_and_account_aggregate';
  aggregate?: Maybe<Store_And_Account_Aggregate_Fields>;
  nodes: Array<Store_And_Account>;
};

/** aggregate fields of "store_and_account" */
export type Store_And_Account_Aggregate_Fields = {
  __typename?: 'store_and_account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_And_Account_Max_Fields>;
  min?: Maybe<Store_And_Account_Min_Fields>;
};


/** aggregate fields of "store_and_account" */
export type Store_And_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_account" */
export type Store_And_Account_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Account_Max_Order_By>;
  min?: Maybe<Store_And_Account_Min_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_account" */
export type Store_And_Account_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Account_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Account_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store_and_account". All fields are combined with a logical 'AND'. */
export type Store_And_Account_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Account_Bool_Exp>>;
  _not?: Maybe<Store_And_Account_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Account_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  remark?: Maybe<String_Comparison_Exp>;
  role?: Maybe<Store_Account_Role_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  store_account_role?: Maybe<Store_Account_Role_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_and_account" */
export enum Store_And_Account_Constraint {
  /** unique or primary key constraint */
  StoreAndAccountPkey = 'store_and_account_pkey'
}

/** input type for inserting data into table "store_and_account" */
export type Store_And_Account_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['String']>;
  role?: Maybe<Store_Account_Role_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  store_account_role?: Maybe<Store_Account_Role_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_And_Account_Max_Fields = {
  __typename?: 'store_and_account_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_and_account" */
export type Store_And_Account_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  remark?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Account_Min_Fields = {
  __typename?: 'store_and_account_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_and_account" */
export type Store_And_Account_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  remark?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_account" */
export type Store_And_Account_Mutation_Response = {
  __typename?: 'store_and_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Account>;
};

/** on conflict condition type for table "store_and_account" */
export type Store_And_Account_On_Conflict = {
  constraint: Store_And_Account_Constraint;
  update_columns?: Array<Store_And_Account_Update_Column>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_account". */
export type Store_And_Account_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  remark?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  store_account_role?: Maybe<Store_Account_Role_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: store_and_account */
export type Store_And_Account_Pk_Columns_Input = {
  account: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_account" */
export enum Store_And_Account_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  Role = 'role',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store_and_account" */
export type Store_And_Account_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  remark?: Maybe<Scalars['String']>;
  role?: Maybe<Store_Account_Role_Enum>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "store_and_account" */
export enum Store_And_Account_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  Role = 'role',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Hashtags on the store
 *
 *
 * columns and relationships of "store_and_hashtag"
 */
export type Store_And_Hashtag = {
  __typename?: 'store_and_hashtag';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag: Scalars['uuid'];
  /** An object relationship */
  hashtagByHashtag: Hashtag;
  /** made for arrange hashtags as priority. */
  order: Scalars['Int'];
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_and_hashtag" */
export type Store_And_Hashtag_Aggregate = {
  __typename?: 'store_and_hashtag_aggregate';
  aggregate?: Maybe<Store_And_Hashtag_Aggregate_Fields>;
  nodes: Array<Store_And_Hashtag>;
};

/** aggregate fields of "store_and_hashtag" */
export type Store_And_Hashtag_Aggregate_Fields = {
  __typename?: 'store_and_hashtag_aggregate_fields';
  avg?: Maybe<Store_And_Hashtag_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_And_Hashtag_Max_Fields>;
  min?: Maybe<Store_And_Hashtag_Min_Fields>;
  stddev?: Maybe<Store_And_Hashtag_Stddev_Fields>;
  stddev_pop?: Maybe<Store_And_Hashtag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_And_Hashtag_Stddev_Samp_Fields>;
  sum?: Maybe<Store_And_Hashtag_Sum_Fields>;
  var_pop?: Maybe<Store_And_Hashtag_Var_Pop_Fields>;
  var_samp?: Maybe<Store_And_Hashtag_Var_Samp_Fields>;
  variance?: Maybe<Store_And_Hashtag_Variance_Fields>;
};


/** aggregate fields of "store_and_hashtag" */
export type Store_And_Hashtag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_hashtag" */
export type Store_And_Hashtag_Aggregate_Order_By = {
  avg?: Maybe<Store_And_Hashtag_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Hashtag_Max_Order_By>;
  min?: Maybe<Store_And_Hashtag_Min_Order_By>;
  stddev?: Maybe<Store_And_Hashtag_Stddev_Order_By>;
  stddev_pop?: Maybe<Store_And_Hashtag_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Store_And_Hashtag_Stddev_Samp_Order_By>;
  sum?: Maybe<Store_And_Hashtag_Sum_Order_By>;
  var_pop?: Maybe<Store_And_Hashtag_Var_Pop_Order_By>;
  var_samp?: Maybe<Store_And_Hashtag_Var_Samp_Order_By>;
  variance?: Maybe<Store_And_Hashtag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_hashtag" */
export type Store_And_Hashtag_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Hashtag_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Hashtag_On_Conflict>;
};

/** aggregate avg on columns */
export type Store_And_Hashtag_Avg_Fields = {
  __typename?: 'store_and_hashtag_avg_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Avg_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_and_hashtag". All fields are combined with a logical 'AND'. */
export type Store_And_Hashtag_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Hashtag_Bool_Exp>>;
  _not?: Maybe<Store_And_Hashtag_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Hashtag_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  hashtag?: Maybe<Uuid_Comparison_Exp>;
  hashtagByHashtag?: Maybe<Hashtag_Bool_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_and_hashtag" */
export enum Store_And_Hashtag_Constraint {
  /** unique or primary key constraint */
  StoreHashtagPkey = 'store_hashtag_pkey'
}

/** input type for incrementing numeric columns in table "store_and_hashtag" */
export type Store_And_Hashtag_Inc_Input = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "store_and_hashtag" */
export type Store_And_Hashtag_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  hashtagByHashtag?: Maybe<Hashtag_Obj_Rel_Insert_Input>;
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Int']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_And_Hashtag_Max_Fields = {
  __typename?: 'store_and_hashtag_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Int']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  hashtag?: Maybe<Order_By>;
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Hashtag_Min_Fields = {
  __typename?: 'store_and_hashtag_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Int']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  hashtag?: Maybe<Order_By>;
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_hashtag" */
export type Store_And_Hashtag_Mutation_Response = {
  __typename?: 'store_and_hashtag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Hashtag>;
};

/** on conflict condition type for table "store_and_hashtag" */
export type Store_And_Hashtag_On_Conflict = {
  constraint: Store_And_Hashtag_Constraint;
  update_columns?: Array<Store_And_Hashtag_Update_Column>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_hashtag". */
export type Store_And_Hashtag_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  hashtag?: Maybe<Order_By>;
  hashtagByHashtag?: Maybe<Hashtag_Order_By>;
  order?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: store_and_hashtag */
export type Store_And_Hashtag_Pk_Columns_Input = {
  hashtag: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_hashtag" */
export enum Store_And_Hashtag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Hashtag = 'hashtag',
  /** column name */
  Order = 'order',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store_and_hashtag" */
export type Store_And_Hashtag_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hashtag?: Maybe<Scalars['uuid']>;
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Int']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_And_Hashtag_Stddev_Fields = {
  __typename?: 'store_and_hashtag_stddev_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Stddev_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_And_Hashtag_Stddev_Pop_Fields = {
  __typename?: 'store_and_hashtag_stddev_pop_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Stddev_Pop_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_And_Hashtag_Stddev_Samp_Fields = {
  __typename?: 'store_and_hashtag_stddev_samp_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Stddev_Samp_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Store_And_Hashtag_Sum_Fields = {
  __typename?: 'store_and_hashtag_sum_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Sum_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/** update columns of table "store_and_hashtag" */
export enum Store_And_Hashtag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Hashtag = 'hashtag',
  /** column name */
  Order = 'order',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Store_And_Hashtag_Var_Pop_Fields = {
  __typename?: 'store_and_hashtag_var_pop_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Var_Pop_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_And_Hashtag_Var_Samp_Fields = {
  __typename?: 'store_and_hashtag_var_samp_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Var_Samp_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_And_Hashtag_Variance_Fields = {
  __typename?: 'store_and_hashtag_variance_fields';
  /** made for arrange hashtags as priority. */
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_and_hashtag" */
export type Store_And_Hashtag_Variance_Order_By = {
  /** made for arrange hashtags as priority. */
  order?: Maybe<Order_By>;
};

/**
 * Likes on the store
 *
 *
 * columns and relationships of "store_and_like"
 */
export type Store_And_Like = {
  __typename?: 'store_and_like';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like: Scalars['uuid'];
  /** An object relationship */
  likeByLike: Like;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_and_like" */
export type Store_And_Like_Aggregate = {
  __typename?: 'store_and_like_aggregate';
  aggregate?: Maybe<Store_And_Like_Aggregate_Fields>;
  nodes: Array<Store_And_Like>;
};

/** aggregate fields of "store_and_like" */
export type Store_And_Like_Aggregate_Fields = {
  __typename?: 'store_and_like_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_And_Like_Max_Fields>;
  min?: Maybe<Store_And_Like_Min_Fields>;
};


/** aggregate fields of "store_and_like" */
export type Store_And_Like_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Like_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_like" */
export type Store_And_Like_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Like_Max_Order_By>;
  min?: Maybe<Store_And_Like_Min_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_like" */
export type Store_And_Like_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Like_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Like_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store_and_like". All fields are combined with a logical 'AND'. */
export type Store_And_Like_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Like_Bool_Exp>>;
  _not?: Maybe<Store_And_Like_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Like_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  like?: Maybe<Uuid_Comparison_Exp>;
  likeByLike?: Maybe<Like_Bool_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_and_like" */
export enum Store_And_Like_Constraint {
  /** unique or primary key constraint */
  StoreLikePkey = 'store_like_pkey'
}

/** input type for inserting data into table "store_and_like" */
export type Store_And_Like_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  likeByLike?: Maybe<Like_Obj_Rel_Insert_Input>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_And_Like_Max_Fields = {
  __typename?: 'store_and_like_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_and_like" */
export type Store_And_Like_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  like?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Like_Min_Fields = {
  __typename?: 'store_and_like_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_and_like" */
export type Store_And_Like_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  like?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_like" */
export type Store_And_Like_Mutation_Response = {
  __typename?: 'store_and_like_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Like>;
};

/** on conflict condition type for table "store_and_like" */
export type Store_And_Like_On_Conflict = {
  constraint: Store_And_Like_Constraint;
  update_columns?: Array<Store_And_Like_Update_Column>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_like". */
export type Store_And_Like_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  like?: Maybe<Order_By>;
  likeByLike?: Maybe<Like_Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: store_and_like */
export type Store_And_Like_Pk_Columns_Input = {
  like: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_like" */
export enum Store_And_Like_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Like = 'like',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store_and_like" */
export type Store_And_Like_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  like?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "store_and_like" */
export enum Store_And_Like_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Like = 'like',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * 지역별 신차패키지 추가 금액
 *
 *
 * columns and relationships of "store_and_package"
 */
export type Store_And_Package = {
  __typename?: 'store_and_package';
  add_on_product_item?: Maybe<Scalars['uuid']>;
  package: Scalars['uuid'];
  /** An object relationship */
  packageByPackage: Package;
  /** An object relationship */
  product_item?: Maybe<Product_Item>;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
};

/** aggregated selection of "store_and_package" */
export type Store_And_Package_Aggregate = {
  __typename?: 'store_and_package_aggregate';
  aggregate?: Maybe<Store_And_Package_Aggregate_Fields>;
  nodes: Array<Store_And_Package>;
};

/** aggregate fields of "store_and_package" */
export type Store_And_Package_Aggregate_Fields = {
  __typename?: 'store_and_package_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_And_Package_Max_Fields>;
  min?: Maybe<Store_And_Package_Min_Fields>;
};


/** aggregate fields of "store_and_package" */
export type Store_And_Package_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Package_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_package" */
export type Store_And_Package_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Package_Max_Order_By>;
  min?: Maybe<Store_And_Package_Min_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_package" */
export type Store_And_Package_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Package_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Package_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store_and_package". All fields are combined with a logical 'AND'. */
export type Store_And_Package_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Package_Bool_Exp>>;
  _not?: Maybe<Store_And_Package_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Package_Bool_Exp>>;
  add_on_product_item?: Maybe<Uuid_Comparison_Exp>;
  package?: Maybe<Uuid_Comparison_Exp>;
  packageByPackage?: Maybe<Package_Bool_Exp>;
  product_item?: Maybe<Product_Item_Bool_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
};

/** unique or primary key constraints on table "store_and_package" */
export enum Store_And_Package_Constraint {
  /** unique or primary key constraint */
  StoreAndPackagePkey = 'store_and_package_pkey'
}

/** input type for inserting data into table "store_and_package" */
export type Store_And_Package_Insert_Input = {
  add_on_product_item?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  packageByPackage?: Maybe<Package_Obj_Rel_Insert_Input>;
  product_item?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Store_And_Package_Max_Fields = {
  __typename?: 'store_and_package_max_fields';
  add_on_product_item?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "store_and_package" */
export type Store_And_Package_Max_Order_By = {
  add_on_product_item?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Package_Min_Fields = {
  __typename?: 'store_and_package_min_fields';
  add_on_product_item?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "store_and_package" */
export type Store_And_Package_Min_Order_By = {
  add_on_product_item?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_package" */
export type Store_And_Package_Mutation_Response = {
  __typename?: 'store_and_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Package>;
};

/** on conflict condition type for table "store_and_package" */
export type Store_And_Package_On_Conflict = {
  constraint: Store_And_Package_Constraint;
  update_columns?: Array<Store_And_Package_Update_Column>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_package". */
export type Store_And_Package_Order_By = {
  add_on_product_item?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  packageByPackage?: Maybe<Package_Order_By>;
  product_item?: Maybe<Product_Item_Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
};

/** primary key columns input for table: store_and_package */
export type Store_And_Package_Pk_Columns_Input = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_package" */
export enum Store_And_Package_Select_Column {
  /** column name */
  AddOnProductItem = 'add_on_product_item',
  /** column name */
  Package = 'package',
  /** column name */
  Store = 'store'
}

/** input type for updating data in table "store_and_package" */
export type Store_And_Package_Set_Input = {
  add_on_product_item?: Maybe<Scalars['uuid']>;
  package?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
};

/** update columns of table "store_and_package" */
export enum Store_And_Package_Update_Column {
  /** column name */
  AddOnProductItem = 'add_on_product_item',
  /** column name */
  Package = 'package',
  /** column name */
  Store = 'store'
}

/**
 * 매장별 제품 취급 여부 및 할인율
 *
 *
 * columns and relationships of "store_and_product"
 */
export type Store_And_Product = {
  __typename?: 'store_and_product';
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  created_at: Scalars['timestamptz'];
  discount_rate?: Maybe<Scalars['numeric']>;
  product: Scalars['uuid'];
  /** An object relationship */
  productByProduct: Product;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_and_product" */
export type Store_And_Product_Aggregate = {
  __typename?: 'store_and_product_aggregate';
  aggregate?: Maybe<Store_And_Product_Aggregate_Fields>;
  nodes: Array<Store_And_Product>;
};

/** aggregate fields of "store_and_product" */
export type Store_And_Product_Aggregate_Fields = {
  __typename?: 'store_and_product_aggregate_fields';
  avg?: Maybe<Store_And_Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_And_Product_Max_Fields>;
  min?: Maybe<Store_And_Product_Min_Fields>;
  stddev?: Maybe<Store_And_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Store_And_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_And_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Store_And_Product_Sum_Fields>;
  var_pop?: Maybe<Store_And_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Store_And_Product_Var_Samp_Fields>;
  variance?: Maybe<Store_And_Product_Variance_Fields>;
};


/** aggregate fields of "store_and_product" */
export type Store_And_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_product" */
export type Store_And_Product_Aggregate_Order_By = {
  avg?: Maybe<Store_And_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Product_Max_Order_By>;
  min?: Maybe<Store_And_Product_Min_Order_By>;
  stddev?: Maybe<Store_And_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Store_And_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Store_And_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Store_And_Product_Sum_Order_By>;
  var_pop?: Maybe<Store_And_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Store_And_Product_Var_Samp_Order_By>;
  variance?: Maybe<Store_And_Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_product" */
export type Store_And_Product_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Product_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Store_And_Product_Avg_Fields = {
  __typename?: 'store_and_product_avg_fields';
  discount_rate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_and_product" */
export type Store_And_Product_Avg_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_and_product". All fields are combined with a logical 'AND'. */
export type Store_And_Product_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Product_Bool_Exp>>;
  _not?: Maybe<Store_And_Product_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Product_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  discount_rate?: Maybe<Numeric_Comparison_Exp>;
  product?: Maybe<Uuid_Comparison_Exp>;
  productByProduct?: Maybe<Product_Bool_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_and_product" */
export enum Store_And_Product_Constraint {
  /** unique or primary key constraint */
  StoreAndProductPkey = 'store_and_product_pkey'
}

/** input type for incrementing numeric columns in table "store_and_product" */
export type Store_And_Product_Inc_Input = {
  discount_rate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "store_and_product" */
export type Store_And_Product_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_rate?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  productByProduct?: Maybe<Product_Obj_Rel_Insert_Input>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/**
 * Products associated with a store
 *
 *
 * columns and relationships of "store_and_product_item"
 */
export type Store_And_Product_Item = {
  __typename?: 'store_and_product_item';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  productItemByProductItem: Product_Item;
  product_item: Scalars['uuid'];
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_and_product_item" */
export type Store_And_Product_Item_Aggregate = {
  __typename?: 'store_and_product_item_aggregate';
  aggregate?: Maybe<Store_And_Product_Item_Aggregate_Fields>;
  nodes: Array<Store_And_Product_Item>;
};

/** aggregate fields of "store_and_product_item" */
export type Store_And_Product_Item_Aggregate_Fields = {
  __typename?: 'store_and_product_item_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_And_Product_Item_Max_Fields>;
  min?: Maybe<Store_And_Product_Item_Min_Fields>;
};


/** aggregate fields of "store_and_product_item" */
export type Store_And_Product_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_product_item" */
export type Store_And_Product_Item_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Product_Item_Max_Order_By>;
  min?: Maybe<Store_And_Product_Item_Min_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_product_item" */
export type Store_And_Product_Item_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Product_Item_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Product_Item_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store_and_product_item". All fields are combined with a logical 'AND'. */
export type Store_And_Product_Item_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Product_Item_Bool_Exp>>;
  _not?: Maybe<Store_And_Product_Item_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Product_Item_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  productItemByProductItem?: Maybe<Product_Item_Bool_Exp>;
  product_item?: Maybe<Uuid_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_and_product_item" */
export enum Store_And_Product_Item_Constraint {
  /** unique or primary key constraint */
  StoreProductPkey = 'store_product_pkey'
}

/** input type for inserting data into table "store_and_product_item" */
export type Store_And_Product_Item_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  productItemByProductItem?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_And_Product_Item_Max_Fields = {
  __typename?: 'store_and_product_item_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_and_product_item" */
export type Store_And_Product_Item_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Product_Item_Min_Fields = {
  __typename?: 'store_and_product_item_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_and_product_item" */
export type Store_And_Product_Item_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  product_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_product_item" */
export type Store_And_Product_Item_Mutation_Response = {
  __typename?: 'store_and_product_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Product_Item>;
};

/** on conflict condition type for table "store_and_product_item" */
export type Store_And_Product_Item_On_Conflict = {
  constraint: Store_And_Product_Item_Constraint;
  update_columns?: Array<Store_And_Product_Item_Update_Column>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_product_item". */
export type Store_And_Product_Item_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  productItemByProductItem?: Maybe<Product_Item_Order_By>;
  product_item?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: store_and_product_item */
export type Store_And_Product_Item_Pk_Columns_Input = {
  product_item: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_product_item" */
export enum Store_And_Product_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store_and_product_item" */
export type Store_And_Product_Item_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  product_item?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "store_and_product_item" */
export enum Store_And_Product_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ProductItem = 'product_item',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate max on columns */
export type Store_And_Product_Max_Fields = {
  __typename?: 'store_and_product_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_rate?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_and_product" */
export type Store_And_Product_Max_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discount_rate?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Product_Min_Fields = {
  __typename?: 'store_and_product_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_rate?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_and_product" */
export type Store_And_Product_Min_Order_By = {
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discount_rate?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_product" */
export type Store_And_Product_Mutation_Response = {
  __typename?: 'store_and_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Product>;
};

/** on conflict condition type for table "store_and_product" */
export type Store_And_Product_On_Conflict = {
  constraint: Store_And_Product_Constraint;
  update_columns?: Array<Store_And_Product_Update_Column>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_product". */
export type Store_And_Product_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  created_at?: Maybe<Order_By>;
  discount_rate?: Maybe<Order_By>;
  product?: Maybe<Order_By>;
  productByProduct?: Maybe<Product_Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: store_and_product */
export type Store_And_Product_Pk_Columns_Input = {
  product: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_product" */
export enum Store_And_Product_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscountRate = 'discount_rate',
  /** column name */
  Product = 'product',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store_and_product" */
export type Store_And_Product_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_rate?: Maybe<Scalars['numeric']>;
  product?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_And_Product_Stddev_Fields = {
  __typename?: 'store_and_product_stddev_fields';
  discount_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_and_product" */
export type Store_And_Product_Stddev_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_And_Product_Stddev_Pop_Fields = {
  __typename?: 'store_and_product_stddev_pop_fields';
  discount_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_and_product" */
export type Store_And_Product_Stddev_Pop_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_And_Product_Stddev_Samp_Fields = {
  __typename?: 'store_and_product_stddev_samp_fields';
  discount_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_and_product" */
export type Store_And_Product_Stddev_Samp_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Store_And_Product_Sum_Fields = {
  __typename?: 'store_and_product_sum_fields';
  discount_rate?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "store_and_product" */
export type Store_And_Product_Sum_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** update columns of table "store_and_product" */
export enum Store_And_Product_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscountRate = 'discount_rate',
  /** column name */
  Product = 'product',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Store_And_Product_Var_Pop_Fields = {
  __typename?: 'store_and_product_var_pop_fields';
  discount_rate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_and_product" */
export type Store_And_Product_Var_Pop_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_And_Product_Var_Samp_Fields = {
  __typename?: 'store_and_product_var_samp_fields';
  discount_rate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_and_product" */
export type Store_And_Product_Var_Samp_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_And_Product_Variance_Fields = {
  __typename?: 'store_and_product_variance_fields';
  discount_rate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_and_product" */
export type Store_And_Product_Variance_Order_By = {
  discount_rate?: Maybe<Order_By>;
};

/** columns and relationships of "store_and_review" */
export type Store_And_Review = {
  __typename?: 'store_and_review';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  review: Scalars['uuid'];
  /** An object relationship */
  reviewByReview: Review;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_and_review" */
export type Store_And_Review_Aggregate = {
  __typename?: 'store_and_review_aggregate';
  aggregate?: Maybe<Store_And_Review_Aggregate_Fields>;
  nodes: Array<Store_And_Review>;
};

/** aggregate fields of "store_and_review" */
export type Store_And_Review_Aggregate_Fields = {
  __typename?: 'store_and_review_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_And_Review_Max_Fields>;
  min?: Maybe<Store_And_Review_Min_Fields>;
};


/** aggregate fields of "store_and_review" */
export type Store_And_Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_review" */
export type Store_And_Review_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Review_Max_Order_By>;
  min?: Maybe<Store_And_Review_Min_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_review" */
export type Store_And_Review_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Review_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Review_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store_and_review". All fields are combined with a logical 'AND'. */
export type Store_And_Review_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Review_Bool_Exp>>;
  _not?: Maybe<Store_And_Review_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Review_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  review?: Maybe<Uuid_Comparison_Exp>;
  reviewByReview?: Maybe<Review_Bool_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_and_review" */
export enum Store_And_Review_Constraint {
  /** unique or primary key constraint */
  StoreAndReviewPkey = 'store_and_review_pkey'
}

/** input type for inserting data into table "store_and_review" */
export type Store_And_Review_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  review?: Maybe<Scalars['uuid']>;
  reviewByReview?: Maybe<Review_Obj_Rel_Insert_Input>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_And_Review_Max_Fields = {
  __typename?: 'store_and_review_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  review?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_and_review" */
export type Store_And_Review_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Review_Min_Fields = {
  __typename?: 'store_and_review_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  review?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_and_review" */
export type Store_And_Review_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_review" */
export type Store_And_Review_Mutation_Response = {
  __typename?: 'store_and_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Review>;
};

/** on conflict condition type for table "store_and_review" */
export type Store_And_Review_On_Conflict = {
  constraint: Store_And_Review_Constraint;
  update_columns?: Array<Store_And_Review_Update_Column>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_review". */
export type Store_And_Review_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  review?: Maybe<Order_By>;
  reviewByReview?: Maybe<Review_Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: store_and_review */
export type Store_And_Review_Pk_Columns_Input = {
  review: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_review" */
export enum Store_And_Review_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Review = 'review',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store_and_review" */
export type Store_And_Review_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  review?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "store_and_review" */
export enum Store_And_Review_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Review = 'review',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "store_and_sole" */
export type Store_And_Sole = {
  __typename?: 'store_and_sole';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  sole: Scalars['uuid'];
  /** An object relationship */
  soleBySole: Sole;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_and_sole" */
export type Store_And_Sole_Aggregate = {
  __typename?: 'store_and_sole_aggregate';
  aggregate?: Maybe<Store_And_Sole_Aggregate_Fields>;
  nodes: Array<Store_And_Sole>;
};

/** aggregate fields of "store_and_sole" */
export type Store_And_Sole_Aggregate_Fields = {
  __typename?: 'store_and_sole_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_And_Sole_Max_Fields>;
  min?: Maybe<Store_And_Sole_Min_Fields>;
};


/** aggregate fields of "store_and_sole" */
export type Store_And_Sole_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_And_Sole_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_and_sole" */
export type Store_And_Sole_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Store_And_Sole_Max_Order_By>;
  min?: Maybe<Store_And_Sole_Min_Order_By>;
};

/** input type for inserting array relation for remote table "store_and_sole" */
export type Store_And_Sole_Arr_Rel_Insert_Input = {
  data: Array<Store_And_Sole_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_And_Sole_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store_and_sole". All fields are combined with a logical 'AND'. */
export type Store_And_Sole_Bool_Exp = {
  _and?: Maybe<Array<Store_And_Sole_Bool_Exp>>;
  _not?: Maybe<Store_And_Sole_Bool_Exp>;
  _or?: Maybe<Array<Store_And_Sole_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  sole?: Maybe<Uuid_Comparison_Exp>;
  soleBySole?: Maybe<Sole_Bool_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_and_sole" */
export enum Store_And_Sole_Constraint {
  /** unique or primary key constraint */
  StoreAndSolePkey = 'store_and_sole_pkey',
  /** unique or primary key constraint */
  StoreAndSoleStoreKey = 'store_and_sole_store_key'
}

/** input type for inserting data into table "store_and_sole" */
export type Store_And_Sole_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  sole?: Maybe<Scalars['uuid']>;
  soleBySole?: Maybe<Sole_Obj_Rel_Insert_Input>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_And_Sole_Max_Fields = {
  __typename?: 'store_and_sole_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  sole?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_and_sole" */
export type Store_And_Sole_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  sole?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_And_Sole_Min_Fields = {
  __typename?: 'store_and_sole_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  sole?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_and_sole" */
export type Store_And_Sole_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  sole?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store_and_sole" */
export type Store_And_Sole_Mutation_Response = {
  __typename?: 'store_and_sole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_And_Sole>;
};

/** on conflict condition type for table "store_and_sole" */
export type Store_And_Sole_On_Conflict = {
  constraint: Store_And_Sole_Constraint;
  update_columns?: Array<Store_And_Sole_Update_Column>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};

/** Ordering options when selecting data from "store_and_sole". */
export type Store_And_Sole_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  sole?: Maybe<Order_By>;
  soleBySole?: Maybe<Sole_Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: store_and_sole */
export type Store_And_Sole_Pk_Columns_Input = {
  sole: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "store_and_sole" */
export enum Store_And_Sole_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Sole = 'sole',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store_and_sole" */
export type Store_And_Sole_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  sole?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "store_and_sole" */
export enum Store_And_Sole_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Sole = 'sole',
  /** column name */
  Store = 'store',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Store_Append_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  image?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "store" */
export type Store_Arr_Rel_Insert_Input = {
  data: Array<Store_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Store_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store". All fields are combined with a logical 'AND'. */
export type Store_Bool_Exp = {
  _and?: Maybe<Array<Store_Bool_Exp>>;
  _not?: Maybe<Store_Bool_Exp>;
  _or?: Maybe<Array<Store_Bool_Exp>>;
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Bool_Exp>;
  address_detail?: Maybe<String_Comparison_Exp>;
  address_sigungu?: Maybe<Uuid_Comparison_Exp>;
  adjustments?: Maybe<Adjustment_Bool_Exp>;
  approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  attribute?: Maybe<Jsonb_Comparison_Exp>;
  blog_posts?: Maybe<Blog_Post_Bool_Exp>;
  car_inspections?: Maybe<Car_Inspection_Bool_Exp>;
  caramora_moracano?: Maybe<String_Comparison_Exp>;
  cart_items?: Maybe<Cart_Item_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  fake_reviews?: Maybe<Fake_Review_Bool_Exp>;
  galleries?: Maybe<Gallery_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<Jsonb_Comparison_Exp>;
  inventories?: Maybe<Inventory_Bool_Exp>;
  invoices?: Maybe<Invoice_Bool_Exp>;
  is_representative_store?: Maybe<Boolean_Comparison_Exp>;
  llumar_store_name?: Maybe<String_Comparison_Exp>;
  llumar_store_type?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Geography_Comparison_Exp>;
  membership?: Maybe<Membership_Bool_Exp>;
  memberships?: Maybe<Membership_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_business?: Maybe<String_Comparison_Exp>;
  operation_gallery_link?: Maybe<String_Comparison_Exp>;
  operation_last_contact?: Maybe<Timestamptz_Comparison_Exp>;
  operation_memo?: Maybe<String_Comparison_Exp>;
  order_from_stores?: Maybe<Order_From_Store_Bool_Exp>;
  order_items?: Maybe<Order_Item_Bool_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  payouts?: Maybe<Payout_Bool_Exp>;
  phone_business?: Maybe<String_Comparison_Exp>;
  postal_code?: Maybe<String_Comparison_Exp>;
  product_item_price_by_stores?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
  quote_items?: Maybe<Quote_Item_Bool_Exp>;
  rating?: Maybe<Store_With_Rating_Bool_Exp>;
  rating_of_fake?: Maybe<Store_With_Rating_Of_Fake_Bool_Exp>;
  slots?: Maybe<Slot_Bool_Exp>;
  status?: Maybe<Store_Status_Enum_Comparison_Exp>;
  store_and_accounts?: Maybe<Store_And_Account_Bool_Exp>;
  store_and_hashtags?: Maybe<Store_And_Hashtag_Bool_Exp>;
  store_and_likes?: Maybe<Store_And_Like_Bool_Exp>;
  store_and_packages?: Maybe<Store_And_Package_Bool_Exp>;
  store_and_product_items?: Maybe<Store_And_Product_Item_Bool_Exp>;
  store_and_products?: Maybe<Store_And_Product_Bool_Exp>;
  store_and_reviews?: Maybe<Store_And_Review_Bool_Exp>;
  store_and_soles?: Maybe<Store_And_Sole_Bool_Exp>;
  store_status?: Maybe<Store_Status_Bool_Exp>;
  subscriptions?: Maybe<Subscription_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  view_package_stores?: Maybe<View_Package_Store_Bool_Exp>;
  view_stores?: Maybe<View_Store_Bool_Exp>;
};

export type Store_By_Date_Only_Args = {
  created_sort?: Maybe<Scalars['Boolean']>;
  second_sort_col?: Maybe<Scalars['String']>;
  sole_filter?: Maybe<Scalars['uuid']>;
};

/** unique or primary key constraints on table "store" */
export enum Store_Constraint {
  /** unique or primary key constraint */
  StorePkey = 'store_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Store_Delete_At_Path_Input = {
  attribute?: Maybe<Array<Scalars['String']>>;
  image?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Store_Delete_Elem_Input = {
  attribute?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Store_Delete_Key_Input = {
  attribute?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "store" */
export type Store_Insert_Input = {
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Obj_Rel_Insert_Input>;
  /** Dong and below */
  address_detail?: Maybe<Scalars['String']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  adjustments?: Maybe<Adjustment_Arr_Rel_Insert_Input>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  attribute?: Maybe<Scalars['jsonb']>;
  blog_posts?: Maybe<Blog_Post_Arr_Rel_Insert_Input>;
  car_inspections?: Maybe<Car_Inspection_Arr_Rel_Insert_Input>;
  caramora_moracano?: Maybe<Scalars['String']>;
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fake_reviews?: Maybe<Fake_Review_Arr_Rel_Insert_Input>;
  galleries?: Maybe<Gallery_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  inventories?: Maybe<Inventory_Arr_Rel_Insert_Input>;
  invoices?: Maybe<Invoice_Arr_Rel_Insert_Input>;
  is_representative_store?: Maybe<Scalars['Boolean']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  llumar_store_type?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geography']>;
  membership?: Maybe<Membership_Obj_Rel_Insert_Input>;
  memberships?: Maybe<Membership_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  name_business?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  order_from_stores?: Maybe<Order_From_Store_Arr_Rel_Insert_Input>;
  order_items?: Maybe<Order_Item_Arr_Rel_Insert_Input>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  payouts?: Maybe<Payout_Arr_Rel_Insert_Input>;
  phone_business?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  product_item_price_by_stores?: Maybe<Product_Item_Price_By_Store_Arr_Rel_Insert_Input>;
  quote_items?: Maybe<Quote_Item_Arr_Rel_Insert_Input>;
  slots?: Maybe<Slot_Arr_Rel_Insert_Input>;
  status?: Maybe<Store_Status_Enum>;
  store_and_accounts?: Maybe<Store_And_Account_Arr_Rel_Insert_Input>;
  store_and_hashtags?: Maybe<Store_And_Hashtag_Arr_Rel_Insert_Input>;
  store_and_likes?: Maybe<Store_And_Like_Arr_Rel_Insert_Input>;
  store_and_packages?: Maybe<Store_And_Package_Arr_Rel_Insert_Input>;
  store_and_product_items?: Maybe<Store_And_Product_Item_Arr_Rel_Insert_Input>;
  store_and_products?: Maybe<Store_And_Product_Arr_Rel_Insert_Input>;
  store_and_reviews?: Maybe<Store_And_Review_Arr_Rel_Insert_Input>;
  store_and_soles?: Maybe<Store_And_Sole_Arr_Rel_Insert_Input>;
  store_status?: Maybe<Store_Status_Obj_Rel_Insert_Input>;
  subscriptions?: Maybe<Subscription_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view_package_stores?: Maybe<View_Package_Store_Arr_Rel_Insert_Input>;
  view_stores?: Maybe<View_Store_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Store_Max_Fields = {
  __typename?: 'store_max_fields';
  /** Dong and below */
  address_detail?: Maybe<Scalars['String']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  caramora_moracano?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  llumar_store_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_business?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  phone_business?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store" */
export type Store_Max_Order_By = {
  /** Dong and below */
  address_detail?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  approved_at?: Maybe<Order_By>;
  caramora_moracano?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  llumar_store_name?: Maybe<Order_By>;
  llumar_store_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_business?: Maybe<Order_By>;
  operation_gallery_link?: Maybe<Order_By>;
  operation_last_contact?: Maybe<Order_By>;
  operation_memo?: Maybe<Order_By>;
  phone_business?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_Min_Fields = {
  __typename?: 'store_min_fields';
  /** Dong and below */
  address_detail?: Maybe<Scalars['String']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  caramora_moracano?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  llumar_store_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_business?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  phone_business?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store" */
export type Store_Min_Order_By = {
  /** Dong and below */
  address_detail?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  approved_at?: Maybe<Order_By>;
  caramora_moracano?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  llumar_store_name?: Maybe<Order_By>;
  llumar_store_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_business?: Maybe<Order_By>;
  operation_gallery_link?: Maybe<Order_By>;
  operation_last_contact?: Maybe<Order_By>;
  operation_memo?: Maybe<Order_By>;
  phone_business?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "store" */
export type Store_Mutation_Response = {
  __typename?: 'store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store>;
};

/** input type for inserting object relation for remote table "store" */
export type Store_Obj_Rel_Insert_Input = {
  data: Store_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Store_On_Conflict>;
};

/** on conflict condition type for table "store" */
export type Store_On_Conflict = {
  constraint: Store_Constraint;
  update_columns?: Array<Store_Update_Column>;
  where?: Maybe<Store_Bool_Exp>;
};

/** Ordering options when selecting data from "store". */
export type Store_Order_By = {
  addressSigunguByAddressSigungu?: Maybe<Address_Sigungu_Order_By>;
  address_detail?: Maybe<Order_By>;
  address_sigungu?: Maybe<Order_By>;
  adjustments_aggregate?: Maybe<Adjustment_Aggregate_Order_By>;
  approved_at?: Maybe<Order_By>;
  attribute?: Maybe<Order_By>;
  blog_posts_aggregate?: Maybe<Blog_Post_Aggregate_Order_By>;
  car_inspections_aggregate?: Maybe<Car_Inspection_Aggregate_Order_By>;
  caramora_moracano?: Maybe<Order_By>;
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fake_reviews_aggregate?: Maybe<Fake_Review_Aggregate_Order_By>;
  galleries_aggregate?: Maybe<Gallery_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  inventories_aggregate?: Maybe<Inventory_Aggregate_Order_By>;
  invoices_aggregate?: Maybe<Invoice_Aggregate_Order_By>;
  is_representative_store?: Maybe<Order_By>;
  llumar_store_name?: Maybe<Order_By>;
  llumar_store_type?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  membership?: Maybe<Membership_Order_By>;
  memberships_aggregate?: Maybe<Membership_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  name_business?: Maybe<Order_By>;
  operation_gallery_link?: Maybe<Order_By>;
  operation_last_contact?: Maybe<Order_By>;
  operation_memo?: Maybe<Order_By>;
  order_from_stores_aggregate?: Maybe<Order_From_Store_Aggregate_Order_By>;
  order_items_aggregate?: Maybe<Order_Item_Aggregate_Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  payouts_aggregate?: Maybe<Payout_Aggregate_Order_By>;
  phone_business?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  product_item_price_by_stores_aggregate?: Maybe<Product_Item_Price_By_Store_Aggregate_Order_By>;
  quote_items_aggregate?: Maybe<Quote_Item_Aggregate_Order_By>;
  rating_aggregate?: Maybe<Store_With_Rating_Aggregate_Order_By>;
  rating_of_fake_aggregate?: Maybe<Store_With_Rating_Of_Fake_Aggregate_Order_By>;
  slots_aggregate?: Maybe<Slot_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  store_and_accounts_aggregate?: Maybe<Store_And_Account_Aggregate_Order_By>;
  store_and_hashtags_aggregate?: Maybe<Store_And_Hashtag_Aggregate_Order_By>;
  store_and_likes_aggregate?: Maybe<Store_And_Like_Aggregate_Order_By>;
  store_and_packages_aggregate?: Maybe<Store_And_Package_Aggregate_Order_By>;
  store_and_product_items_aggregate?: Maybe<Store_And_Product_Item_Aggregate_Order_By>;
  store_and_products_aggregate?: Maybe<Store_And_Product_Aggregate_Order_By>;
  store_and_reviews_aggregate?: Maybe<Store_And_Review_Aggregate_Order_By>;
  store_and_soles_aggregate?: Maybe<Store_And_Sole_Aggregate_Order_By>;
  store_status?: Maybe<Store_Status_Order_By>;
  subscriptions_aggregate?: Maybe<Subscription_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  view_package_stores_aggregate?: Maybe<View_Package_Store_Aggregate_Order_By>;
  view_stores_aggregate?: Maybe<View_Store_Aggregate_Order_By>;
};

/** primary key columns input for table: store */
export type Store_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Store_Prepend_Input = {
  attribute?: Maybe<Scalars['jsonb']>;
  image?: Maybe<Scalars['jsonb']>;
};

export type Store_Search_Keyword_Args = {
  area?: Maybe<Scalars['String']>;
  borough?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Scalars['geography']>;
  search?: Maybe<Scalars['String']>;
};

export type Store_Search_Polygon_Args = {
  area?: Maybe<Scalars['String']>;
  borough?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Scalars['geography']>;
  polygon?: Maybe<Scalars['geometry']>;
  search?: Maybe<Scalars['String']>;
};

/** select columns of table "store" */
export enum Store_Select_Column {
  /** column name */
  AddressDetail = 'address_detail',
  /** column name */
  AddressSigungu = 'address_sigungu',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CaramoraMoracano = 'caramora_moracano',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsRepresentativeStore = 'is_representative_store',
  /** column name */
  LlumarStoreName = 'llumar_store_name',
  /** column name */
  LlumarStoreType = 'llumar_store_type',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  NameBusiness = 'name_business',
  /** column name */
  OperationGalleryLink = 'operation_gallery_link',
  /** column name */
  OperationLastContact = 'operation_last_contact',
  /** column name */
  OperationMemo = 'operation_memo',
  /** column name */
  PhoneBusiness = 'phone_business',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "store" */
export type Store_Set_Input = {
  /** Dong and below */
  address_detail?: Maybe<Scalars['String']>;
  address_sigungu?: Maybe<Scalars['uuid']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  attribute?: Maybe<Scalars['jsonb']>;
  caramora_moracano?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['jsonb']>;
  is_representative_store?: Maybe<Scalars['Boolean']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  llumar_store_type?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geography']>;
  name?: Maybe<Scalars['String']>;
  name_business?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  phone_business?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  status?: Maybe<Store_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/**
 * Caramora approval stages
 *
 *
 * columns and relationships of "store_status"
 */
export type Store_Status = {
  __typename?: 'store_status';
  description: Scalars['String'];
  /** An array relationship */
  stores: Array<Store>;
  /** An aggregate relationship */
  stores_aggregate: Store_Aggregate;
  value: Scalars['String'];
};


/**
 * Caramora approval stages
 *
 *
 * columns and relationships of "store_status"
 */
export type Store_StatusStoresArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};


/**
 * Caramora approval stages
 *
 *
 * columns and relationships of "store_status"
 */
export type Store_StatusStores_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};

/** aggregated selection of "store_status" */
export type Store_Status_Aggregate = {
  __typename?: 'store_status_aggregate';
  aggregate?: Maybe<Store_Status_Aggregate_Fields>;
  nodes: Array<Store_Status>;
};

/** aggregate fields of "store_status" */
export type Store_Status_Aggregate_Fields = {
  __typename?: 'store_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Status_Max_Fields>;
  min?: Maybe<Store_Status_Min_Fields>;
};


/** aggregate fields of "store_status" */
export type Store_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_status". All fields are combined with a logical 'AND'. */
export type Store_Status_Bool_Exp = {
  _and?: Maybe<Array<Store_Status_Bool_Exp>>;
  _not?: Maybe<Store_Status_Bool_Exp>;
  _or?: Maybe<Array<Store_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  stores?: Maybe<Store_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_status" */
export enum Store_Status_Constraint {
  /** unique or primary key constraint */
  StoreStatusPkey = 'store_status_pkey'
}

export enum Store_Status_Enum {
  /** 승인 완료 */
  Approved = 'approved',
  /** 입점 신청 완료 */
  Created = 'created',
  /** 미승인 처리 완료 */
  Unapproved = 'unapproved'
}

/** Boolean expression to compare columns of type "store_status_enum". All fields are combined with logical 'AND'. */
export type Store_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Store_Status_Enum>;
  _in?: Maybe<Array<Store_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Store_Status_Enum>;
  _nin?: Maybe<Array<Store_Status_Enum>>;
};

/** input type for inserting data into table "store_status" */
export type Store_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  stores?: Maybe<Store_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Status_Max_Fields = {
  __typename?: 'store_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Status_Min_Fields = {
  __typename?: 'store_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_status" */
export type Store_Status_Mutation_Response = {
  __typename?: 'store_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Status>;
};

/** input type for inserting object relation for remote table "store_status" */
export type Store_Status_Obj_Rel_Insert_Input = {
  data: Store_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Store_Status_On_Conflict>;
};

/** on conflict condition type for table "store_status" */
export type Store_Status_On_Conflict = {
  constraint: Store_Status_Constraint;
  update_columns?: Array<Store_Status_Update_Column>;
  where?: Maybe<Store_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "store_status". */
export type Store_Status_Order_By = {
  description?: Maybe<Order_By>;
  stores_aggregate?: Maybe<Store_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: store_status */
export type Store_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "store_status" */
export enum Store_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "store_status" */
export type Store_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "store_status" */
export enum Store_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "store" */
export enum Store_Update_Column {
  /** column name */
  AddressDetail = 'address_detail',
  /** column name */
  AddressSigungu = 'address_sigungu',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  CaramoraMoracano = 'caramora_moracano',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsRepresentativeStore = 'is_representative_store',
  /** column name */
  LlumarStoreName = 'llumar_store_name',
  /** column name */
  LlumarStoreType = 'llumar_store_type',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  NameBusiness = 'name_business',
  /** column name */
  OperationGalleryLink = 'operation_gallery_link',
  /** column name */
  OperationLastContact = 'operation_last_contact',
  /** column name */
  OperationMemo = 'operation_memo',
  /** column name */
  PhoneBusiness = 'phone_business',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "store_with_rating" */
export type Store_With_Rating = {
  __typename?: 'store_with_rating';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "store_with_rating" */
export type Store_With_Rating_Aggregate = {
  __typename?: 'store_with_rating_aggregate';
  aggregate?: Maybe<Store_With_Rating_Aggregate_Fields>;
  nodes: Array<Store_With_Rating>;
};

/** aggregate fields of "store_with_rating" */
export type Store_With_Rating_Aggregate_Fields = {
  __typename?: 'store_with_rating_aggregate_fields';
  avg?: Maybe<Store_With_Rating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_With_Rating_Max_Fields>;
  min?: Maybe<Store_With_Rating_Min_Fields>;
  stddev?: Maybe<Store_With_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Store_With_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_With_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Store_With_Rating_Sum_Fields>;
  var_pop?: Maybe<Store_With_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Store_With_Rating_Var_Samp_Fields>;
  variance?: Maybe<Store_With_Rating_Variance_Fields>;
};


/** aggregate fields of "store_with_rating" */
export type Store_With_Rating_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_With_Rating_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_with_rating" */
export type Store_With_Rating_Aggregate_Order_By = {
  avg?: Maybe<Store_With_Rating_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Store_With_Rating_Max_Order_By>;
  min?: Maybe<Store_With_Rating_Min_Order_By>;
  stddev?: Maybe<Store_With_Rating_Stddev_Order_By>;
  stddev_pop?: Maybe<Store_With_Rating_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Store_With_Rating_Stddev_Samp_Order_By>;
  sum?: Maybe<Store_With_Rating_Sum_Order_By>;
  var_pop?: Maybe<Store_With_Rating_Var_Pop_Order_By>;
  var_samp?: Maybe<Store_With_Rating_Var_Samp_Order_By>;
  variance?: Maybe<Store_With_Rating_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Store_With_Rating_Avg_Fields = {
  __typename?: 'store_with_rating_avg_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_with_rating" */
export type Store_With_Rating_Avg_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_with_rating". All fields are combined with a logical 'AND'. */
export type Store_With_Rating_Bool_Exp = {
  _and?: Maybe<Array<Store_With_Rating_Bool_Exp>>;
  _not?: Maybe<Store_With_Rating_Bool_Exp>;
  _or?: Maybe<Array<Store_With_Rating_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  rating?: Maybe<Numeric_Comparison_Exp>;
  rating_customer_service?: Maybe<Numeric_Comparison_Exp>;
  rating_facility?: Maybe<Numeric_Comparison_Exp>;
  rating_installation?: Maybe<Numeric_Comparison_Exp>;
  rating_price?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Store_With_Rating_Max_Fields = {
  __typename?: 'store_with_rating_max_fields';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "store_with_rating" */
export type Store_With_Rating_Max_Order_By = {
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_With_Rating_Min_Fields = {
  __typename?: 'store_with_rating_min_fields';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "store_with_rating" */
export type Store_With_Rating_Min_Order_By = {
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** columns and relationships of "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake = {
  __typename?: 'store_with_rating_of_fake';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Aggregate = {
  __typename?: 'store_with_rating_of_fake_aggregate';
  aggregate?: Maybe<Store_With_Rating_Of_Fake_Aggregate_Fields>;
  nodes: Array<Store_With_Rating_Of_Fake>;
};

/** aggregate fields of "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Aggregate_Fields = {
  __typename?: 'store_with_rating_of_fake_aggregate_fields';
  avg?: Maybe<Store_With_Rating_Of_Fake_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_With_Rating_Of_Fake_Max_Fields>;
  min?: Maybe<Store_With_Rating_Of_Fake_Min_Fields>;
  stddev?: Maybe<Store_With_Rating_Of_Fake_Stddev_Fields>;
  stddev_pop?: Maybe<Store_With_Rating_Of_Fake_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_With_Rating_Of_Fake_Stddev_Samp_Fields>;
  sum?: Maybe<Store_With_Rating_Of_Fake_Sum_Fields>;
  var_pop?: Maybe<Store_With_Rating_Of_Fake_Var_Pop_Fields>;
  var_samp?: Maybe<Store_With_Rating_Of_Fake_Var_Samp_Fields>;
  variance?: Maybe<Store_With_Rating_Of_Fake_Variance_Fields>;
};


/** aggregate fields of "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Store_With_Rating_Of_Fake_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Aggregate_Order_By = {
  avg?: Maybe<Store_With_Rating_Of_Fake_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Store_With_Rating_Of_Fake_Max_Order_By>;
  min?: Maybe<Store_With_Rating_Of_Fake_Min_Order_By>;
  stddev?: Maybe<Store_With_Rating_Of_Fake_Stddev_Order_By>;
  stddev_pop?: Maybe<Store_With_Rating_Of_Fake_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Store_With_Rating_Of_Fake_Stddev_Samp_Order_By>;
  sum?: Maybe<Store_With_Rating_Of_Fake_Sum_Order_By>;
  var_pop?: Maybe<Store_With_Rating_Of_Fake_Var_Pop_Order_By>;
  var_samp?: Maybe<Store_With_Rating_Of_Fake_Var_Samp_Order_By>;
  variance?: Maybe<Store_With_Rating_Of_Fake_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Store_With_Rating_Of_Fake_Avg_Fields = {
  __typename?: 'store_with_rating_of_fake_avg_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Avg_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_with_rating_of_fake". All fields are combined with a logical 'AND'. */
export type Store_With_Rating_Of_Fake_Bool_Exp = {
  _and?: Maybe<Array<Store_With_Rating_Of_Fake_Bool_Exp>>;
  _not?: Maybe<Store_With_Rating_Of_Fake_Bool_Exp>;
  _or?: Maybe<Array<Store_With_Rating_Of_Fake_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  rating?: Maybe<Numeric_Comparison_Exp>;
  rating_customer_service?: Maybe<Numeric_Comparison_Exp>;
  rating_facility?: Maybe<Numeric_Comparison_Exp>;
  rating_installation?: Maybe<Numeric_Comparison_Exp>;
  rating_price?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Store_With_Rating_Of_Fake_Max_Fields = {
  __typename?: 'store_with_rating_of_fake_max_fields';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Max_Order_By = {
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Store_With_Rating_Of_Fake_Min_Fields = {
  __typename?: 'store_with_rating_of_fake_min_fields';
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Min_Order_By = {
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "store_with_rating_of_fake". */
export type Store_With_Rating_Of_Fake_Order_By = {
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** select columns of table "store_with_rating_of_fake" */
export enum Store_With_Rating_Of_Fake_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCustomerService = 'rating_customer_service',
  /** column name */
  RatingFacility = 'rating_facility',
  /** column name */
  RatingInstallation = 'rating_installation',
  /** column name */
  RatingPrice = 'rating_price'
}

/** aggregate stddev on columns */
export type Store_With_Rating_Of_Fake_Stddev_Fields = {
  __typename?: 'store_with_rating_of_fake_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Stddev_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_With_Rating_Of_Fake_Stddev_Pop_Fields = {
  __typename?: 'store_with_rating_of_fake_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Stddev_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_With_Rating_Of_Fake_Stddev_Samp_Fields = {
  __typename?: 'store_with_rating_of_fake_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Stddev_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Store_With_Rating_Of_Fake_Sum_Fields = {
  __typename?: 'store_with_rating_of_fake_sum_fields';
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Sum_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Store_With_Rating_Of_Fake_Var_Pop_Fields = {
  __typename?: 'store_with_rating_of_fake_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Var_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_With_Rating_Of_Fake_Var_Samp_Fields = {
  __typename?: 'store_with_rating_of_fake_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Var_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_With_Rating_Of_Fake_Variance_Fields = {
  __typename?: 'store_with_rating_of_fake_variance_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_with_rating_of_fake" */
export type Store_With_Rating_Of_Fake_Variance_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "store_with_rating". */
export type Store_With_Rating_Order_By = {
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** select columns of table "store_with_rating" */
export enum Store_With_Rating_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCustomerService = 'rating_customer_service',
  /** column name */
  RatingFacility = 'rating_facility',
  /** column name */
  RatingInstallation = 'rating_installation',
  /** column name */
  RatingPrice = 'rating_price'
}

/** aggregate stddev on columns */
export type Store_With_Rating_Stddev_Fields = {
  __typename?: 'store_with_rating_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_with_rating" */
export type Store_With_Rating_Stddev_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_With_Rating_Stddev_Pop_Fields = {
  __typename?: 'store_with_rating_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_with_rating" */
export type Store_With_Rating_Stddev_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_With_Rating_Stddev_Samp_Fields = {
  __typename?: 'store_with_rating_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_with_rating" */
export type Store_With_Rating_Stddev_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Store_With_Rating_Sum_Fields = {
  __typename?: 'store_with_rating_sum_fields';
  rating?: Maybe<Scalars['numeric']>;
  rating_customer_service?: Maybe<Scalars['numeric']>;
  rating_facility?: Maybe<Scalars['numeric']>;
  rating_installation?: Maybe<Scalars['numeric']>;
  rating_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "store_with_rating" */
export type Store_With_Rating_Sum_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Store_With_Rating_Var_Pop_Fields = {
  __typename?: 'store_with_rating_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_with_rating" */
export type Store_With_Rating_Var_Pop_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_With_Rating_Var_Samp_Fields = {
  __typename?: 'store_with_rating_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_with_rating" */
export type Store_With_Rating_Var_Samp_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_With_Rating_Variance_Fields = {
  __typename?: 'store_with_rating_variance_fields';
  rating?: Maybe<Scalars['Float']>;
  rating_customer_service?: Maybe<Scalars['Float']>;
  rating_facility?: Maybe<Scalars['Float']>;
  rating_installation?: Maybe<Scalars['Float']>;
  rating_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_with_rating" */
export type Store_With_Rating_Variance_Order_By = {
  rating?: Maybe<Order_By>;
  rating_customer_service?: Maybe<Order_By>;
  rating_facility?: Maybe<Order_By>;
  rating_installation?: Maybe<Order_By>;
  rating_price?: Maybe<Order_By>;
};

/**
 * Stores that requested a subscription
 *
 *
 * columns and relationships of "subscription"
 */
export type Subscription = {
  __typename?: 'subscription';
  /** Account who created this row */
  account: Scalars['uuid'];
  /** An object relationship */
  accountByAccount: Account;
  /** An array relationship */
  accounts_receivable_and_subscriptions: Array<Accounts_Receivable_And_Subscription>;
  /** An aggregate relationship */
  accounts_receivable_and_subscriptions_aggregate: Accounts_Receivable_And_Subscription_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  price: Scalars['numeric'];
  rapyd_subscription_id?: Maybe<Scalars['String']>;
  status: Subscription_Status_Enum;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  subscription_and_transactions: Array<Subscription_And_Transaction>;
  /** An aggregate relationship */
  subscription_and_transactions_aggregate: Subscription_And_Transaction_Aggregate;
  /** An array relationship */
  subscription_and_transfers: Array<Subscription_And_Transfer>;
  /** An aggregate relationship */
  subscription_and_transfers_aggregate: Subscription_And_Transfer_Aggregate;
  /** An object relationship */
  subscription_status: Subscription_Status;
  updated_at: Scalars['timestamptz'];
};


/**
 * Stores that requested a subscription
 *
 *
 * columns and relationships of "subscription"
 */
export type SubscriptionAccounts_Receivable_And_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};


/**
 * Stores that requested a subscription
 *
 *
 * columns and relationships of "subscription"
 */
export type SubscriptionAccounts_Receivable_And_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};


/**
 * Stores that requested a subscription
 *
 *
 * columns and relationships of "subscription"
 */
export type SubscriptionSubscription_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};


/**
 * Stores that requested a subscription
 *
 *
 * columns and relationships of "subscription"
 */
export type SubscriptionSubscription_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};


/**
 * Stores that requested a subscription
 *
 *
 * columns and relationships of "subscription"
 */
export type SubscriptionSubscription_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};


/**
 * Stores that requested a subscription
 *
 *
 * columns and relationships of "subscription"
 */
export type SubscriptionSubscription_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};

/** aggregated selection of "subscription" */
export type Subscription_Aggregate = {
  __typename?: 'subscription_aggregate';
  aggregate?: Maybe<Subscription_Aggregate_Fields>;
  nodes: Array<Subscription>;
};

/** aggregate fields of "subscription" */
export type Subscription_Aggregate_Fields = {
  __typename?: 'subscription_aggregate_fields';
  avg?: Maybe<Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subscription_Max_Fields>;
  min?: Maybe<Subscription_Min_Fields>;
  stddev?: Maybe<Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Sum_Fields>;
  var_pop?: Maybe<Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Variance_Fields>;
};


/** aggregate fields of "subscription" */
export type Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Subscription_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subscription" */
export type Subscription_Aggregate_Order_By = {
  avg?: Maybe<Subscription_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Subscription_Max_Order_By>;
  min?: Maybe<Subscription_Min_Order_By>;
  stddev?: Maybe<Subscription_Stddev_Order_By>;
  stddev_pop?: Maybe<Subscription_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Subscription_Stddev_Samp_Order_By>;
  sum?: Maybe<Subscription_Sum_Order_By>;
  var_pop?: Maybe<Subscription_Var_Pop_Order_By>;
  var_samp?: Maybe<Subscription_Var_Samp_Order_By>;
  variance?: Maybe<Subscription_Variance_Order_By>;
};

/**
 * Stores that paid subscription fee via card
 *
 *
 * columns and relationships of "subscription_and_transaction"
 */
export type Subscription_And_Transaction = {
  __typename?: 'subscription_and_transaction';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription: Scalars['uuid'];
  /** An object relationship */
  subscriptionBySubscription: Subscription;
  transaction: Scalars['uuid'];
  /** An object relationship */
  transactionByTransaction: Transaction;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "subscription_and_transaction" */
export type Subscription_And_Transaction_Aggregate = {
  __typename?: 'subscription_and_transaction_aggregate';
  aggregate?: Maybe<Subscription_And_Transaction_Aggregate_Fields>;
  nodes: Array<Subscription_And_Transaction>;
};

/** aggregate fields of "subscription_and_transaction" */
export type Subscription_And_Transaction_Aggregate_Fields = {
  __typename?: 'subscription_and_transaction_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subscription_And_Transaction_Max_Fields>;
  min?: Maybe<Subscription_And_Transaction_Min_Fields>;
};


/** aggregate fields of "subscription_and_transaction" */
export type Subscription_And_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subscription_and_transaction" */
export type Subscription_And_Transaction_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Subscription_And_Transaction_Max_Order_By>;
  min?: Maybe<Subscription_And_Transaction_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_and_transaction" */
export type Subscription_And_Transaction_Arr_Rel_Insert_Input = {
  data: Array<Subscription_And_Transaction_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Subscription_And_Transaction_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_and_transaction". All fields are combined with a logical 'AND'. */
export type Subscription_And_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Subscription_And_Transaction_Bool_Exp>>;
  _not?: Maybe<Subscription_And_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Subscription_And_Transaction_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  subscription?: Maybe<Uuid_Comparison_Exp>;
  subscriptionBySubscription?: Maybe<Subscription_Bool_Exp>;
  transaction?: Maybe<Uuid_Comparison_Exp>;
  transactionByTransaction?: Maybe<Transaction_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_and_transaction" */
export enum Subscription_And_Transaction_Constraint {
  /** unique or primary key constraint */
  TransactionSubscriptionPkey = 'transaction_subscription_pkey'
}

/** input type for inserting data into table "subscription_and_transaction" */
export type Subscription_And_Transaction_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  subscriptionBySubscription?: Maybe<Subscription_Obj_Rel_Insert_Input>;
  transaction?: Maybe<Scalars['uuid']>;
  transactionByTransaction?: Maybe<Transaction_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Subscription_And_Transaction_Max_Fields = {
  __typename?: 'subscription_and_transaction_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "subscription_and_transaction" */
export type Subscription_And_Transaction_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_And_Transaction_Min_Fields = {
  __typename?: 'subscription_and_transaction_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "subscription_and_transaction" */
export type Subscription_And_Transaction_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "subscription_and_transaction" */
export type Subscription_And_Transaction_Mutation_Response = {
  __typename?: 'subscription_and_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_And_Transaction>;
};

/** on conflict condition type for table "subscription_and_transaction" */
export type Subscription_And_Transaction_On_Conflict = {
  constraint: Subscription_And_Transaction_Constraint;
  update_columns?: Array<Subscription_And_Transaction_Update_Column>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_and_transaction". */
export type Subscription_And_Transaction_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  subscriptionBySubscription?: Maybe<Subscription_Order_By>;
  transaction?: Maybe<Order_By>;
  transactionByTransaction?: Maybe<Transaction_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: subscription_and_transaction */
export type Subscription_And_Transaction_Pk_Columns_Input = {
  subscription: Scalars['uuid'];
  transaction: Scalars['uuid'];
};

/** select columns of table "subscription_and_transaction" */
export enum Subscription_And_Transaction_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "subscription_and_transaction" */
export type Subscription_And_Transaction_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "subscription_and_transaction" */
export enum Subscription_And_Transaction_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Stores that paid subscription fee via bank wire
 *
 *
 * columns and relationships of "subscription_and_transfer"
 */
export type Subscription_And_Transfer = {
  __typename?: 'subscription_and_transfer';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription: Scalars['uuid'];
  /** An object relationship */
  subscriptionBySubscription: Subscription;
  transfer: Scalars['uuid'];
  /** An object relationship */
  transferByTransfer: Transfer;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "subscription_and_transfer" */
export type Subscription_And_Transfer_Aggregate = {
  __typename?: 'subscription_and_transfer_aggregate';
  aggregate?: Maybe<Subscription_And_Transfer_Aggregate_Fields>;
  nodes: Array<Subscription_And_Transfer>;
};

/** aggregate fields of "subscription_and_transfer" */
export type Subscription_And_Transfer_Aggregate_Fields = {
  __typename?: 'subscription_and_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subscription_And_Transfer_Max_Fields>;
  min?: Maybe<Subscription_And_Transfer_Min_Fields>;
};


/** aggregate fields of "subscription_and_transfer" */
export type Subscription_And_Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subscription_and_transfer" */
export type Subscription_And_Transfer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Subscription_And_Transfer_Max_Order_By>;
  min?: Maybe<Subscription_And_Transfer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_and_transfer" */
export type Subscription_And_Transfer_Arr_Rel_Insert_Input = {
  data: Array<Subscription_And_Transfer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Subscription_And_Transfer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscription_and_transfer". All fields are combined with a logical 'AND'. */
export type Subscription_And_Transfer_Bool_Exp = {
  _and?: Maybe<Array<Subscription_And_Transfer_Bool_Exp>>;
  _not?: Maybe<Subscription_And_Transfer_Bool_Exp>;
  _or?: Maybe<Array<Subscription_And_Transfer_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  subscription?: Maybe<Uuid_Comparison_Exp>;
  subscriptionBySubscription?: Maybe<Subscription_Bool_Exp>;
  transfer?: Maybe<Uuid_Comparison_Exp>;
  transferByTransfer?: Maybe<Transfer_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_and_transfer" */
export enum Subscription_And_Transfer_Constraint {
  /** unique or primary key constraint */
  TransferSubscriptionPkey = 'transfer_subscription_pkey'
}

/** input type for inserting data into table "subscription_and_transfer" */
export type Subscription_And_Transfer_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  subscriptionBySubscription?: Maybe<Subscription_Obj_Rel_Insert_Input>;
  transfer?: Maybe<Scalars['uuid']>;
  transferByTransfer?: Maybe<Transfer_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Subscription_And_Transfer_Max_Fields = {
  __typename?: 'subscription_and_transfer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "subscription_and_transfer" */
export type Subscription_And_Transfer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_And_Transfer_Min_Fields = {
  __typename?: 'subscription_and_transfer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "subscription_and_transfer" */
export type Subscription_And_Transfer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  transfer?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "subscription_and_transfer" */
export type Subscription_And_Transfer_Mutation_Response = {
  __typename?: 'subscription_and_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_And_Transfer>;
};

/** on conflict condition type for table "subscription_and_transfer" */
export type Subscription_And_Transfer_On_Conflict = {
  constraint: Subscription_And_Transfer_Constraint;
  update_columns?: Array<Subscription_And_Transfer_Update_Column>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_and_transfer". */
export type Subscription_And_Transfer_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  subscriptionBySubscription?: Maybe<Subscription_Order_By>;
  transfer?: Maybe<Order_By>;
  transferByTransfer?: Maybe<Transfer_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: subscription_and_transfer */
export type Subscription_And_Transfer_Pk_Columns_Input = {
  subscription: Scalars['uuid'];
  transfer: Scalars['uuid'];
};

/** select columns of table "subscription_and_transfer" */
export enum Subscription_And_Transfer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "subscription_and_transfer" */
export type Subscription_And_Transfer_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  subscription?: Maybe<Scalars['uuid']>;
  transfer?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "subscription_and_transfer" */
export enum Subscription_And_Transfer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  Transfer = 'transfer',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting array relation for remote table "subscription" */
export type Subscription_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Subscription_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscription_Avg_Fields = {
  __typename?: 'subscription_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "subscription" */
export type Subscription_Avg_Order_By = {
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscription". All fields are combined with a logical 'AND'. */
export type Subscription_Bool_Exp = {
  _and?: Maybe<Array<Subscription_Bool_Exp>>;
  _not?: Maybe<Subscription_Bool_Exp>;
  _or?: Maybe<Array<Subscription_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  accountByAccount?: Maybe<Account_Bool_Exp>;
  accounts_receivable_and_subscriptions?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  rapyd_subscription_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Subscription_Status_Enum_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
  stripe_subscription_id?: Maybe<String_Comparison_Exp>;
  subscription_and_transactions?: Maybe<Subscription_And_Transaction_Bool_Exp>;
  subscription_and_transfers?: Maybe<Subscription_And_Transfer_Bool_Exp>;
  subscription_status?: Maybe<Subscription_Status_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription" */
export enum Subscription_Constraint {
  /** unique or primary key constraint */
  SubscriptionPkey = 'subscription_pkey'
}

/** input type for incrementing numeric columns in table "subscription" */
export type Subscription_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "subscription" */
export type Subscription_Insert_Input = {
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  accountByAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  accounts_receivable_and_subscriptions?: Maybe<Accounts_Receivable_And_Subscription_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  rapyd_subscription_id?: Maybe<Scalars['String']>;
  status?: Maybe<Subscription_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  subscription_and_transactions?: Maybe<Subscription_And_Transaction_Arr_Rel_Insert_Input>;
  subscription_and_transfers?: Maybe<Subscription_And_Transfer_Arr_Rel_Insert_Input>;
  subscription_status?: Maybe<Subscription_Status_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Subscription_Max_Fields = {
  __typename?: 'subscription_max_fields';
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  rapyd_subscription_id?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['uuid']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "subscription" */
export type Subscription_Max_Order_By = {
  /** Account who created this row */
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  rapyd_subscription_id?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Min_Fields = {
  __typename?: 'subscription_min_fields';
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  rapyd_subscription_id?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['uuid']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "subscription" */
export type Subscription_Min_Order_By = {
  /** Account who created this row */
  account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  rapyd_subscription_id?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "subscription" */
export type Subscription_Mutation_Response = {
  __typename?: 'subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription>;
};

/** input type for inserting object relation for remote table "subscription" */
export type Subscription_Obj_Rel_Insert_Input = {
  data: Subscription_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Subscription_On_Conflict>;
};

/** on conflict condition type for table "subscription" */
export type Subscription_On_Conflict = {
  constraint: Subscription_Constraint;
  update_columns?: Array<Subscription_Update_Column>;
  where?: Maybe<Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription". */
export type Subscription_Order_By = {
  account?: Maybe<Order_By>;
  accountByAccount?: Maybe<Account_Order_By>;
  accounts_receivable_and_subscriptions_aggregate?: Maybe<Accounts_Receivable_And_Subscription_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  rapyd_subscription_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  subscription_and_transactions_aggregate?: Maybe<Subscription_And_Transaction_Aggregate_Order_By>;
  subscription_and_transfers_aggregate?: Maybe<Subscription_And_Transfer_Aggregate_Order_By>;
  subscription_status?: Maybe<Subscription_Status_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: subscription */
export type Subscription_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_performance" */
  account_performance: Array<Account_Performance>;
  /** fetch aggregated fields from the table: "account_performance" */
  account_performance_aggregate: Account_Performance_Aggregate;
  /** fetch data from the table: "account_throughput" */
  account_throughput: Array<Account_Throughput>;
  /** fetch aggregated fields from the table: "account_throughput" */
  account_throughput_aggregate: Account_Throughput_Aggregate;
  /** fetch data from the table: "account_type" */
  account_type: Array<Account_Type>;
  /** fetch aggregated fields from the table: "account_type" */
  account_type_aggregate: Account_Type_Aggregate;
  /** fetch data from the table: "account_type" using primary key columns */
  account_type_by_pk?: Maybe<Account_Type>;
  /** fetch data from the table: "accounts_payable" */
  accounts_payable: Array<Accounts_Payable>;
  /** fetch aggregated fields from the table: "accounts_payable" */
  accounts_payable_aggregate: Accounts_Payable_Aggregate;
  /** fetch data from the table: "accounts_payable_and_adjustment" */
  accounts_payable_and_adjustment: Array<Accounts_Payable_And_Adjustment>;
  /** fetch aggregated fields from the table: "accounts_payable_and_adjustment" */
  accounts_payable_and_adjustment_aggregate: Accounts_Payable_And_Adjustment_Aggregate;
  /** fetch data from the table: "accounts_payable_and_adjustment" using primary key columns */
  accounts_payable_and_adjustment_by_pk?: Maybe<Accounts_Payable_And_Adjustment>;
  /** fetch data from the table: "accounts_payable_and_payout" */
  accounts_payable_and_payout: Array<Accounts_Payable_And_Payout>;
  /** fetch aggregated fields from the table: "accounts_payable_and_payout" */
  accounts_payable_and_payout_aggregate: Accounts_Payable_And_Payout_Aggregate;
  /** fetch data from the table: "accounts_payable_and_payout" using primary key columns */
  accounts_payable_and_payout_by_pk?: Maybe<Accounts_Payable_And_Payout>;
  /** fetch data from the table: "accounts_payable_and_refund" */
  accounts_payable_and_refund: Array<Accounts_Payable_And_Refund>;
  /** fetch aggregated fields from the table: "accounts_payable_and_refund" */
  accounts_payable_and_refund_aggregate: Accounts_Payable_And_Refund_Aggregate;
  /** fetch data from the table: "accounts_payable_and_refund" using primary key columns */
  accounts_payable_and_refund_by_pk?: Maybe<Accounts_Payable_And_Refund>;
  /** fetch data from the table: "accounts_payable" using primary key columns */
  accounts_payable_by_pk?: Maybe<Accounts_Payable>;
  /** fetch data from the table: "accounts_receivable" */
  accounts_receivable: Array<Accounts_Receivable>;
  /** fetch aggregated fields from the table: "accounts_receivable" */
  accounts_receivable_aggregate: Accounts_Receivable_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_invoice" */
  accounts_receivable_and_invoice: Array<Accounts_Receivable_And_Invoice>;
  /** fetch aggregated fields from the table: "accounts_receivable_and_invoice" */
  accounts_receivable_and_invoice_aggregate: Accounts_Receivable_And_Invoice_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_invoice" using primary key columns */
  accounts_receivable_and_invoice_by_pk?: Maybe<Accounts_Receivable_And_Invoice>;
  /** fetch data from the table: "accounts_receivable_and_membership" */
  accounts_receivable_and_membership: Array<Accounts_Receivable_And_Membership>;
  /** fetch aggregated fields from the table: "accounts_receivable_and_membership" */
  accounts_receivable_and_membership_aggregate: Accounts_Receivable_And_Membership_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_membership" using primary key columns */
  accounts_receivable_and_membership_by_pk?: Maybe<Accounts_Receivable_And_Membership>;
  /** fetch data from the table: "accounts_receivable_and_subscription" */
  accounts_receivable_and_subscription: Array<Accounts_Receivable_And_Subscription>;
  /** fetch aggregated fields from the table: "accounts_receivable_and_subscription" */
  accounts_receivable_and_subscription_aggregate: Accounts_Receivable_And_Subscription_Aggregate;
  /** fetch data from the table: "accounts_receivable_and_subscription" using primary key columns */
  accounts_receivable_and_subscription_by_pk?: Maybe<Accounts_Receivable_And_Subscription>;
  /** fetch data from the table: "accounts_receivable" using primary key columns */
  accounts_receivable_by_pk?: Maybe<Accounts_Receivable>;
  /** fetch data from the table: "activity" */
  activity: Array<Activity>;
  /** fetch aggregated fields from the table: "activity" */
  activity_aggregate: Activity_Aggregate;
  /** fetch data from the table: "activity" using primary key columns */
  activity_by_pk?: Maybe<Activity>;
  /** fetch data from the table: "address_sido" */
  address_sido: Array<Address_Sido>;
  /** fetch aggregated fields from the table: "address_sido" */
  address_sido_aggregate: Address_Sido_Aggregate;
  /** fetch data from the table: "address_sido" using primary key columns */
  address_sido_by_pk?: Maybe<Address_Sido>;
  /** fetch data from the table: "address_sigungu" */
  address_sigungu: Array<Address_Sigungu>;
  /** fetch aggregated fields from the table: "address_sigungu" */
  address_sigungu_aggregate: Address_Sigungu_Aggregate;
  /** fetch data from the table: "address_sigungu" using primary key columns */
  address_sigungu_by_pk?: Maybe<Address_Sigungu>;
  /** fetch data from the table: "adjustment" */
  adjustment: Array<Adjustment>;
  /** fetch aggregated fields from the table: "adjustment" */
  adjustment_aggregate: Adjustment_Aggregate;
  /** fetch data from the table: "adjustment_and_transfer" */
  adjustment_and_transfer: Array<Adjustment_And_Transfer>;
  /** fetch aggregated fields from the table: "adjustment_and_transfer" */
  adjustment_and_transfer_aggregate: Adjustment_And_Transfer_Aggregate;
  /** fetch data from the table: "adjustment_and_transfer" using primary key columns */
  adjustment_and_transfer_by_pk?: Maybe<Adjustment_And_Transfer>;
  /** fetch data from the table: "adjustment" using primary key columns */
  adjustment_by_pk?: Maybe<Adjustment>;
  /** fetch data from the table: "adjustment_status" */
  adjustment_status: Array<Adjustment_Status>;
  /** fetch aggregated fields from the table: "adjustment_status" */
  adjustment_status_aggregate: Adjustment_Status_Aggregate;
  /** fetch data from the table: "adjustment_status" using primary key columns */
  adjustment_status_by_pk?: Maybe<Adjustment_Status>;
  /** fetch data from the table: "blog_post" */
  blog_post: Array<Blog_Post>;
  /** fetch aggregated fields from the table: "blog_post" */
  blog_post_aggregate: Blog_Post_Aggregate;
  /** fetch data from the table: "blog_post_and_hashtag" */
  blog_post_and_hashtag: Array<Blog_Post_And_Hashtag>;
  /** fetch aggregated fields from the table: "blog_post_and_hashtag" */
  blog_post_and_hashtag_aggregate: Blog_Post_And_Hashtag_Aggregate;
  /** fetch data from the table: "blog_post_and_hashtag" using primary key columns */
  blog_post_and_hashtag_by_pk?: Maybe<Blog_Post_And_Hashtag>;
  /** fetch data from the table: "blog_post_and_view" */
  blog_post_and_view: Array<Blog_Post_And_View>;
  /** fetch aggregated fields from the table: "blog_post_and_view" */
  blog_post_and_view_aggregate: Blog_Post_And_View_Aggregate;
  /** fetch data from the table: "blog_post_and_view" using primary key columns */
  blog_post_and_view_by_pk?: Maybe<Blog_Post_And_View>;
  /** fetch data from the table: "blog_post" using primary key columns */
  blog_post_by_pk?: Maybe<Blog_Post>;
  /** fetch data from the table: "car" */
  car: Array<Car>;
  /** fetch aggregated fields from the table: "car" */
  car_aggregate: Car_Aggregate;
  /** fetch data from the table: "car" using primary key columns */
  car_by_pk?: Maybe<Car>;
  /** fetch data from the table: "car_inspection" */
  car_inspection: Array<Car_Inspection>;
  /** fetch aggregated fields from the table: "car_inspection" */
  car_inspection_aggregate: Car_Inspection_Aggregate;
  /** fetch data from the table: "car_inspection" using primary key columns */
  car_inspection_by_pk?: Maybe<Car_Inspection>;
  /** fetch data from the table: "car_inspection_status" */
  car_inspection_status: Array<Car_Inspection_Status>;
  /** fetch aggregated fields from the table: "car_inspection_status" */
  car_inspection_status_aggregate: Car_Inspection_Status_Aggregate;
  /** fetch data from the table: "car_inspection_status" using primary key columns */
  car_inspection_status_by_pk?: Maybe<Car_Inspection_Status>;
  /** fetch data from the table: "car_maker" */
  car_maker: Array<Car_Maker>;
  /** fetch aggregated fields from the table: "car_maker" */
  car_maker_aggregate: Car_Maker_Aggregate;
  /** fetch data from the table: "car_maker" using primary key columns */
  car_maker_by_pk?: Maybe<Car_Maker>;
  /** fetch data from the table: "car_origin" */
  car_origin: Array<Car_Origin>;
  /** fetch aggregated fields from the table: "car_origin" */
  car_origin_aggregate: Car_Origin_Aggregate;
  /** fetch data from the table: "car_origin" using primary key columns */
  car_origin_by_pk?: Maybe<Car_Origin>;
  /** fetch data from the table: "car_sunroof_type" */
  car_sunroof_type: Array<Car_Sunroof_Type>;
  /** fetch aggregated fields from the table: "car_sunroof_type" */
  car_sunroof_type_aggregate: Car_Sunroof_Type_Aggregate;
  /** fetch data from the table: "car_sunroof_type" using primary key columns */
  car_sunroof_type_by_pk?: Maybe<Car_Sunroof_Type>;
  /** fetch data from the table: "car_type" */
  car_type: Array<Car_Type>;
  /** fetch aggregated fields from the table: "car_type" */
  car_type_aggregate: Car_Type_Aggregate;
  /** fetch data from the table: "car_type" using primary key columns */
  car_type_by_pk?: Maybe<Car_Type>;
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>;
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate;
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>;
  /** fetch data from the table: "chat" */
  chat: Array<Chat>;
  /** fetch aggregated fields from the table: "chat" */
  chat_aggregate: Chat_Aggregate;
  /** fetch data from the table: "chat" using primary key columns */
  chat_by_pk?: Maybe<Chat>;
  /** fetch data from the table: "config" */
  config: Array<Config>;
  /** fetch aggregated fields from the table: "config" */
  config_aggregate: Config_Aggregate;
  /** fetch data from the table: "config" using primary key columns */
  config_by_pk?: Maybe<Config>;
  /** fetch data from the table: "config_type" */
  config_type: Array<Config_Type>;
  /** fetch aggregated fields from the table: "config_type" */
  config_type_aggregate: Config_Type_Aggregate;
  /** fetch data from the table: "config_type" using primary key columns */
  config_type_by_pk?: Maybe<Config_Type>;
  /** fetch data from the table: "coupon" */
  coupon: Array<Coupon>;
  /** fetch aggregated fields from the table: "coupon" */
  coupon_aggregate: Coupon_Aggregate;
  /** fetch data from the table: "coupon_and_account" */
  coupon_and_account: Array<Coupon_And_Account>;
  /** fetch aggregated fields from the table: "coupon_and_account" */
  coupon_and_account_aggregate: Coupon_And_Account_Aggregate;
  /** fetch data from the table: "coupon_and_account" using primary key columns */
  coupon_and_account_by_pk?: Maybe<Coupon_And_Account>;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  /** fetch data from the table: "coupon_status" */
  coupon_status: Array<Coupon_Status>;
  /** fetch aggregated fields from the table: "coupon_status" */
  coupon_status_aggregate: Coupon_Status_Aggregate;
  /** fetch data from the table: "coupon_status" using primary key columns */
  coupon_status_by_pk?: Maybe<Coupon_Status>;
  /** fetch data from the table: "curation" */
  curation: Array<Curation>;
  /** fetch aggregated fields from the table: "curation" */
  curation_aggregate: Curation_Aggregate;
  /** fetch data from the table: "curation" using primary key columns */
  curation_by_pk?: Maybe<Curation>;
  /** fetch data from the table: "fake_review" */
  fake_review: Array<Fake_Review>;
  /** fetch aggregated fields from the table: "fake_review" */
  fake_review_aggregate: Fake_Review_Aggregate;
  /** fetch data from the table: "fake_review" using primary key columns */
  fake_review_by_pk?: Maybe<Fake_Review>;
  /** fetch data from the table: "gallery" */
  gallery: Array<Gallery>;
  /** fetch aggregated fields from the table: "gallery" */
  gallery_aggregate: Gallery_Aggregate;
  /** fetch data from the table: "gallery" using primary key columns */
  gallery_by_pk?: Maybe<Gallery>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** execute function "get_account_performance_by_used_car_status" which returns "account_performance" */
  get_account_performance_by_used_car_status: Array<Account_Performance>;
  /** execute function "get_account_performance_by_used_car_status" and query aggregates on result of table type "account_performance" */
  get_account_performance_by_used_car_status_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_chat" which returns "account_performance" */
  get_account_performance_for_chat: Array<Account_Performance>;
  /** execute function "get_account_performance_for_chat" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_chat_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_first_call" which returns "account_performance" */
  get_account_performance_for_first_call: Array<Account_Performance>;
  /** execute function "get_account_performance_for_first_call" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_first_call_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_match" which returns "account_performance" */
  get_account_performance_for_match: Array<Account_Performance>;
  /** execute function "get_account_performance_for_match" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_match_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_reservation" which returns "account_performance" */
  get_account_performance_for_reservation: Array<Account_Performance>;
  /** execute function "get_account_performance_for_reservation" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_reservation_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_performance_for_support" which returns "account_performance" */
  get_account_performance_for_support: Array<Account_Performance>;
  /** execute function "get_account_performance_for_support" and query aggregates on result of table type "account_performance" */
  get_account_performance_for_support_aggregate: Account_Performance_Aggregate;
  /** execute function "get_account_throughput" which returns "account_throughput" */
  get_account_throughput: Array<Account_Throughput>;
  /** execute function "get_account_throughput" and query aggregates on result of table type "account_throughput" */
  get_account_throughput_aggregate: Account_Throughput_Aggregate;
  /** execute function "get_lead_status_statistics" which returns "lead_status_statistics" */
  get_lead_status_statistics: Array<Lead_Status_Statistics>;
  /** execute function "get_lead_status_statistics" and query aggregates on result of table type "lead_status_statistics" */
  get_lead_status_statistics_aggregate: Lead_Status_Statistics_Aggregate;
  /** fetch data from the table: "hashtag" */
  hashtag: Array<Hashtag>;
  /** fetch aggregated fields from the table: "hashtag" */
  hashtag_aggregate: Hashtag_Aggregate;
  /** fetch data from the table: "hashtag" using primary key columns */
  hashtag_by_pk?: Maybe<Hashtag>;
  /** fetch data from the table: "inventory" */
  inventory: Array<Inventory>;
  /** fetch aggregated fields from the table: "inventory" */
  inventory_aggregate: Inventory_Aggregate;
  /** fetch data from the table: "inventory" using primary key columns */
  inventory_by_pk?: Maybe<Inventory>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice_and_transaction" */
  invoice_and_transaction: Array<Invoice_And_Transaction>;
  /** fetch aggregated fields from the table: "invoice_and_transaction" */
  invoice_and_transaction_aggregate: Invoice_And_Transaction_Aggregate;
  /** fetch data from the table: "invoice_and_transaction" using primary key columns */
  invoice_and_transaction_by_pk?: Maybe<Invoice_And_Transaction>;
  /** fetch data from the table: "invoice_and_transfer" */
  invoice_and_transfer: Array<Invoice_And_Transfer>;
  /** fetch aggregated fields from the table: "invoice_and_transfer" */
  invoice_and_transfer_aggregate: Invoice_And_Transfer_Aggregate;
  /** fetch data from the table: "invoice_and_transfer" using primary key columns */
  invoice_and_transfer_by_pk?: Maybe<Invoice_And_Transfer>;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "invoice_status" */
  invoice_status: Array<Invoice_Status>;
  /** fetch aggregated fields from the table: "invoice_status" */
  invoice_status_aggregate: Invoice_Status_Aggregate;
  /** fetch data from the table: "invoice_status" using primary key columns */
  invoice_status_by_pk?: Maybe<Invoice_Status>;
  /** fetch data from the table: "kakao_and_account" */
  kakao_and_account: Array<Kakao_And_Account>;
  /** fetch aggregated fields from the table: "kakao_and_account" */
  kakao_and_account_aggregate: Kakao_And_Account_Aggregate;
  /** fetch data from the table: "kakao_and_account" using primary key columns */
  kakao_and_account_by_pk?: Maybe<Kakao_And_Account>;
  /** fetch data from the table: "lead" */
  lead: Array<Lead>;
  /** fetch aggregated fields from the table: "lead" */
  lead_aggregate: Lead_Aggregate;
  /** fetch data from the table: "lead" using primary key columns */
  lead_by_pk?: Maybe<Lead>;
  /** fetch data from the table: "lead_comment" */
  lead_comment: Array<Lead_Comment>;
  /** fetch aggregated fields from the table: "lead_comment" */
  lead_comment_aggregate: Lead_Comment_Aggregate;
  /** fetch data from the table: "lead_comment" using primary key columns */
  lead_comment_by_pk?: Maybe<Lead_Comment>;
  /** fetch data from the table: "lead_status" */
  lead_status: Array<Lead_Status>;
  /** fetch aggregated fields from the table: "lead_status" */
  lead_status_aggregate: Lead_Status_Aggregate;
  /** fetch data from the table: "lead_status" using primary key columns */
  lead_status_by_pk?: Maybe<Lead_Status>;
  /** fetch data from the table: "lead_status_statistics" */
  lead_status_statistics: Array<Lead_Status_Statistics>;
  /** fetch aggregated fields from the table: "lead_status_statistics" */
  lead_status_statistics_aggregate: Lead_Status_Statistics_Aggregate;
  /** fetch data from the table: "like" */
  like: Array<Like>;
  /** fetch aggregated fields from the table: "like" */
  like_aggregate: Like_Aggregate;
  /** fetch data from the table: "like" using primary key columns */
  like_by_pk?: Maybe<Like>;
  /** fetch data from the table: "membership" */
  membership: Array<Membership>;
  /** fetch aggregated fields from the table: "membership" */
  membership_aggregate: Membership_Aggregate;
  /** fetch data from the table: "membership" using primary key columns */
  membership_by_pk?: Maybe<Membership>;
  /** fetch data from the table: "membership_log" */
  membership_log: Array<Membership_Log>;
  /** fetch aggregated fields from the table: "membership_log" */
  membership_log_aggregate: Membership_Log_Aggregate;
  /** fetch data from the table: "membership_log" using primary key columns */
  membership_log_by_pk?: Maybe<Membership_Log>;
  /** fetch data from the table: "membership_type" */
  membership_type: Array<Membership_Type>;
  /** fetch aggregated fields from the table: "membership_type" */
  membership_type_aggregate: Membership_Type_Aggregate;
  /** fetch data from the table: "membership_type" using primary key columns */
  membership_type_by_pk?: Maybe<Membership_Type>;
  /** fetch data from the table: "n_cart" */
  n_cart: Array<N_Cart>;
  /** fetch aggregated fields from the table: "n_cart" */
  n_cart_aggregate: N_Cart_Aggregate;
  /** fetch data from the table: "n_cart" using primary key columns */
  n_cart_by_pk?: Maybe<N_Cart>;
  /** fetch data from the table: "n_order" */
  n_order: Array<N_Order>;
  /** fetch aggregated fields from the table: "n_order" */
  n_order_aggregate: N_Order_Aggregate;
  /** fetch data from the table: "n_order" using primary key columns */
  n_order_by_pk?: Maybe<N_Order>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order_and_review" */
  order_and_review: Array<Order_And_Review>;
  /** fetch aggregated fields from the table: "order_and_review" */
  order_and_review_aggregate: Order_And_Review_Aggregate;
  /** fetch data from the table: "order_and_review" using primary key columns */
  order_and_review_by_pk?: Maybe<Order_And_Review>;
  /** fetch data from the table: "order_and_transaction" */
  order_and_transaction: Array<Order_And_Transaction>;
  /** fetch aggregated fields from the table: "order_and_transaction" */
  order_and_transaction_aggregate: Order_And_Transaction_Aggregate;
  /** fetch data from the table: "order_and_transaction" using primary key columns */
  order_and_transaction_by_pk?: Maybe<Order_And_Transaction>;
  /** fetch data from the table: "order_and_transfer" */
  order_and_transfer: Array<Order_And_Transfer>;
  /** fetch aggregated fields from the table: "order_and_transfer" */
  order_and_transfer_aggregate: Order_And_Transfer_Aggregate;
  /** fetch data from the table: "order_and_transfer" using primary key columns */
  order_and_transfer_by_pk?: Maybe<Order_And_Transfer>;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_from_store" */
  order_from_store: Array<Order_From_Store>;
  /** fetch aggregated fields from the table: "order_from_store" */
  order_from_store_aggregate: Order_From_Store_Aggregate;
  /** fetch data from the table: "order_from_store" using primary key columns */
  order_from_store_by_pk?: Maybe<Order_From_Store>;
  /** fetch data from the table: "order_from_store_status" */
  order_from_store_status: Array<Order_From_Store_Status>;
  /** fetch aggregated fields from the table: "order_from_store_status" */
  order_from_store_status_aggregate: Order_From_Store_Status_Aggregate;
  /** fetch data from the table: "order_from_store_status" using primary key columns */
  order_from_store_status_by_pk?: Maybe<Order_From_Store_Status>;
  /** fetch data from the table: "order_item" */
  order_item: Array<Order_Item>;
  /** fetch aggregated fields from the table: "order_item" */
  order_item_aggregate: Order_Item_Aggregate;
  /** fetch data from the table: "order_item" using primary key columns */
  order_item_by_pk?: Maybe<Order_Item>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table: "package" */
  package: Array<Package>;
  /** fetch aggregated fields from the table: "package" */
  package_aggregate: Package_Aggregate;
  /** fetch data from the table: "package" using primary key columns */
  package_by_pk?: Maybe<Package>;
  /** execute function "package_store_search" which returns "view_package_store" */
  package_store_search: Array<View_Package_Store>;
  /** execute function "package_store_search" and query aggregates on result of table type "view_package_store" */
  package_store_search_aggregate: View_Package_Store_Aggregate;
  /** fetch data from the table: "package_type" */
  package_type: Array<Package_Type>;
  /** fetch aggregated fields from the table: "package_type" */
  package_type_aggregate: Package_Type_Aggregate;
  /** fetch data from the table: "package_type" using primary key columns */
  package_type_by_pk?: Maybe<Package_Type>;
  /** fetch data from the table: "payout" */
  payout: Array<Payout>;
  /** fetch aggregated fields from the table: "payout" */
  payout_aggregate: Payout_Aggregate;
  /** fetch data from the table: "payout_and_transfer" */
  payout_and_transfer: Array<Payout_And_Transfer>;
  /** fetch aggregated fields from the table: "payout_and_transfer" */
  payout_and_transfer_aggregate: Payout_And_Transfer_Aggregate;
  /** fetch data from the table: "payout_and_transfer" using primary key columns */
  payout_and_transfer_by_pk?: Maybe<Payout_And_Transfer>;
  /** fetch data from the table: "payout" using primary key columns */
  payout_by_pk?: Maybe<Payout>;
  /** fetch data from the table: "payout_status" */
  payout_status: Array<Payout_Status>;
  /** fetch aggregated fields from the table: "payout_status" */
  payout_status_aggregate: Payout_Status_Aggregate;
  /** fetch data from the table: "payout_status" using primary key columns */
  payout_status_by_pk?: Maybe<Payout_Status>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product_and_like" */
  product_and_like: Array<Product_And_Like>;
  /** fetch aggregated fields from the table: "product_and_like" */
  product_and_like_aggregate: Product_And_Like_Aggregate;
  /** fetch data from the table: "product_and_like" using primary key columns */
  product_and_like_by_pk?: Maybe<Product_And_Like>;
  /** fetch data from the table: "product_brand" */
  product_brand: Array<Product_Brand>;
  /** fetch aggregated fields from the table: "product_brand" */
  product_brand_aggregate: Product_Brand_Aggregate;
  /** fetch data from the table: "product_brand" using primary key columns */
  product_brand_by_pk?: Maybe<Product_Brand>;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_item" */
  product_item: Array<Product_Item>;
  /** fetch aggregated fields from the table: "product_item" */
  product_item_aggregate: Product_Item_Aggregate;
  /** fetch data from the table: "product_item_and_review" */
  product_item_and_review: Array<Product_Item_And_Review>;
  /** fetch aggregated fields from the table: "product_item_and_review" */
  product_item_and_review_aggregate: Product_Item_And_Review_Aggregate;
  /** fetch data from the table: "product_item_and_review" using primary key columns */
  product_item_and_review_by_pk?: Maybe<Product_Item_And_Review>;
  /** fetch data from the table: "product_item" using primary key columns */
  product_item_by_pk?: Maybe<Product_Item>;
  /** fetch data from the table: "product_item_price_by_store" */
  product_item_price_by_store: Array<Product_Item_Price_By_Store>;
  /** fetch aggregated fields from the table: "product_item_price_by_store" */
  product_item_price_by_store_aggregate: Product_Item_Price_By_Store_Aggregate;
  /** fetch data from the table: "product_item_price_by_store" using primary key columns */
  product_item_price_by_store_by_pk?: Maybe<Product_Item_Price_By_Store>;
  /** fetch data from the table: "product_type" */
  product_type: Array<Product_Type>;
  /** fetch aggregated fields from the table: "product_type" */
  product_type_aggregate: Product_Type_Aggregate;
  /** fetch data from the table: "product_type" using primary key columns */
  product_type_by_pk?: Maybe<Product_Type>;
  /** fetch data from the table: "profile_kr_customer" */
  profile_kr_customer: Array<Profile_Kr_Customer>;
  /** fetch aggregated fields from the table: "profile_kr_customer" */
  profile_kr_customer_aggregate: Profile_Kr_Customer_Aggregate;
  /** fetch data from the table: "profile_kr_customer" using primary key columns */
  profile_kr_customer_by_pk?: Maybe<Profile_Kr_Customer>;
  /** fetch data from the table: "profile_kr_partner" */
  profile_kr_partner: Array<Profile_Kr_Partner>;
  /** fetch aggregated fields from the table: "profile_kr_partner" */
  profile_kr_partner_aggregate: Profile_Kr_Partner_Aggregate;
  /** fetch data from the table: "profile_kr_partner" using primary key columns */
  profile_kr_partner_by_pk?: Maybe<Profile_Kr_Partner>;
  /** fetch data from the table: "profile_language" */
  profile_language: Array<Profile_Language>;
  /** fetch aggregated fields from the table: "profile_language" */
  profile_language_aggregate: Profile_Language_Aggregate;
  /** fetch data from the table: "profile_language" using primary key columns */
  profile_language_by_pk?: Maybe<Profile_Language>;
  /** fetch data from the table: "profile_riderdash" */
  profile_riderdash: Array<Profile_Riderdash>;
  /** fetch aggregated fields from the table: "profile_riderdash" */
  profile_riderdash_aggregate: Profile_Riderdash_Aggregate;
  /** fetch data from the table: "profile_riderdash" using primary key columns */
  profile_riderdash_by_pk?: Maybe<Profile_Riderdash>;
  /** fetch data from the table: "profile_riderdash_role" */
  profile_riderdash_role: Array<Profile_Riderdash_Role>;
  /** fetch aggregated fields from the table: "profile_riderdash_role" */
  profile_riderdash_role_aggregate: Profile_Riderdash_Role_Aggregate;
  /** fetch data from the table: "profile_riderdash_role" using primary key columns */
  profile_riderdash_role_by_pk?: Maybe<Profile_Riderdash_Role>;
  /** fetch data from the table: "profile_us_customer" */
  profile_us_customer: Array<Profile_Us_Customer>;
  /** fetch aggregated fields from the table: "profile_us_customer" */
  profile_us_customer_aggregate: Profile_Us_Customer_Aggregate;
  /** fetch data from the table: "profile_us_customer" using primary key columns */
  profile_us_customer_by_pk?: Maybe<Profile_Us_Customer>;
  /** fetch data from the table: "profile_us_partner" */
  profile_us_partner: Array<Profile_Us_Partner>;
  /** fetch aggregated fields from the table: "profile_us_partner" */
  profile_us_partner_aggregate: Profile_Us_Partner_Aggregate;
  /** fetch data from the table: "profile_us_partner" using primary key columns */
  profile_us_partner_by_pk?: Maybe<Profile_Us_Partner>;
  /** fetch data from the table: "promotion" */
  promotion: Array<Promotion>;
  /** fetch aggregated fields from the table: "promotion" */
  promotion_aggregate: Promotion_Aggregate;
  /** fetch data from the table: "promotion" using primary key columns */
  promotion_by_pk?: Maybe<Promotion>;
  /** fetch data from the table: "quote" */
  quote: Array<Quote>;
  /** fetch aggregated fields from the table: "quote" */
  quote_aggregate: Quote_Aggregate;
  /** fetch data from the table: "quote" using primary key columns */
  quote_by_pk?: Maybe<Quote>;
  /** fetch data from the table: "quote_item" */
  quote_item: Array<Quote_Item>;
  /** fetch aggregated fields from the table: "quote_item" */
  quote_item_aggregate: Quote_Item_Aggregate;
  /** fetch data from the table: "quote_item" using primary key columns */
  quote_item_by_pk?: Maybe<Quote_Item>;
  /** fetch data from the table: "quote_request" */
  quote_request: Array<Quote_Request>;
  /** fetch aggregated fields from the table: "quote_request" */
  quote_request_aggregate: Quote_Request_Aggregate;
  /** fetch data from the table: "quote_request" using primary key columns */
  quote_request_by_pk?: Maybe<Quote_Request>;
  /** fetch data from the table: "raster_columns" */
  raster_columns: Array<Raster_Columns>;
  /** fetch aggregated fields from the table: "raster_columns" */
  raster_columns_aggregate: Raster_Columns_Aggregate;
  /** fetch data from the table: "raster_overviews" */
  raster_overviews: Array<Raster_Overviews>;
  /** fetch aggregated fields from the table: "raster_overviews" */
  raster_overviews_aggregate: Raster_Overviews_Aggregate;
  /** fetch data from the table: "refund" */
  refund: Array<Refund>;
  /** fetch aggregated fields from the table: "refund" */
  refund_aggregate: Refund_Aggregate;
  /** fetch data from the table: "refund_and_transaction" */
  refund_and_transaction: Array<Refund_And_Transaction>;
  /** fetch aggregated fields from the table: "refund_and_transaction" */
  refund_and_transaction_aggregate: Refund_And_Transaction_Aggregate;
  /** fetch data from the table: "refund_and_transaction" using primary key columns */
  refund_and_transaction_by_pk?: Maybe<Refund_And_Transaction>;
  /** fetch data from the table: "refund_and_transfer" */
  refund_and_transfer: Array<Refund_And_Transfer>;
  /** fetch aggregated fields from the table: "refund_and_transfer" */
  refund_and_transfer_aggregate: Refund_And_Transfer_Aggregate;
  /** fetch data from the table: "refund_and_transfer" using primary key columns */
  refund_and_transfer_by_pk?: Maybe<Refund_And_Transfer>;
  /** fetch data from the table: "refund" using primary key columns */
  refund_by_pk?: Maybe<Refund>;
  /** fetch data from the table: "refund_status" */
  refund_status: Array<Refund_Status>;
  /** fetch aggregated fields from the table: "refund_status" */
  refund_status_aggregate: Refund_Status_Aggregate;
  /** fetch data from the table: "refund_status" using primary key columns */
  refund_status_by_pk?: Maybe<Refund_Status>;
  /** fetch data from the table: "review" */
  review: Array<Review>;
  /** fetch aggregated fields from the table: "review" */
  review_aggregate: Review_Aggregate;
  /** fetch data from the table: "review" using primary key columns */
  review_by_pk?: Maybe<Review>;
  /** fetch data from the table: "review_status" */
  review_status: Array<Review_Status>;
  /** fetch aggregated fields from the table: "review_status" */
  review_status_aggregate: Review_Status_Aggregate;
  /** fetch data from the table: "review_status" using primary key columns */
  review_status_by_pk?: Maybe<Review_Status>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "slot" */
  slot: Array<Slot>;
  /** fetch aggregated fields from the table: "slot" */
  slot_aggregate: Slot_Aggregate;
  /** fetch data from the table: "slot" using primary key columns */
  slot_by_pk?: Maybe<Slot>;
  /** fetch data from the table: "sole" */
  sole: Array<Sole>;
  /** fetch aggregated fields from the table: "sole" */
  sole_aggregate: Sole_Aggregate;
  /** fetch data from the table: "sole" using primary key columns */
  sole_by_pk?: Maybe<Sole>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table: "store" */
  store: Array<Store>;
  /** fetch data from the table: "store_account_role" */
  store_account_role: Array<Store_Account_Role>;
  /** fetch aggregated fields from the table: "store_account_role" */
  store_account_role_aggregate: Store_Account_Role_Aggregate;
  /** fetch data from the table: "store_account_role" using primary key columns */
  store_account_role_by_pk?: Maybe<Store_Account_Role>;
  /** fetch aggregated fields from the table: "store" */
  store_aggregate: Store_Aggregate;
  /** fetch data from the table: "store_and_account" */
  store_and_account: Array<Store_And_Account>;
  /** fetch aggregated fields from the table: "store_and_account" */
  store_and_account_aggregate: Store_And_Account_Aggregate;
  /** fetch data from the table: "store_and_account" using primary key columns */
  store_and_account_by_pk?: Maybe<Store_And_Account>;
  /** fetch data from the table: "store_and_hashtag" */
  store_and_hashtag: Array<Store_And_Hashtag>;
  /** fetch aggregated fields from the table: "store_and_hashtag" */
  store_and_hashtag_aggregate: Store_And_Hashtag_Aggregate;
  /** fetch data from the table: "store_and_hashtag" using primary key columns */
  store_and_hashtag_by_pk?: Maybe<Store_And_Hashtag>;
  /** fetch data from the table: "store_and_like" */
  store_and_like: Array<Store_And_Like>;
  /** fetch aggregated fields from the table: "store_and_like" */
  store_and_like_aggregate: Store_And_Like_Aggregate;
  /** fetch data from the table: "store_and_like" using primary key columns */
  store_and_like_by_pk?: Maybe<Store_And_Like>;
  /** fetch data from the table: "store_and_package" */
  store_and_package: Array<Store_And_Package>;
  /** fetch aggregated fields from the table: "store_and_package" */
  store_and_package_aggregate: Store_And_Package_Aggregate;
  /** fetch data from the table: "store_and_package" using primary key columns */
  store_and_package_by_pk?: Maybe<Store_And_Package>;
  /** fetch data from the table: "store_and_product" */
  store_and_product: Array<Store_And_Product>;
  /** fetch aggregated fields from the table: "store_and_product" */
  store_and_product_aggregate: Store_And_Product_Aggregate;
  /** fetch data from the table: "store_and_product" using primary key columns */
  store_and_product_by_pk?: Maybe<Store_And_Product>;
  /** fetch data from the table: "store_and_product_item" */
  store_and_product_item: Array<Store_And_Product_Item>;
  /** fetch aggregated fields from the table: "store_and_product_item" */
  store_and_product_item_aggregate: Store_And_Product_Item_Aggregate;
  /** fetch data from the table: "store_and_product_item" using primary key columns */
  store_and_product_item_by_pk?: Maybe<Store_And_Product_Item>;
  /** fetch data from the table: "store_and_review" */
  store_and_review: Array<Store_And_Review>;
  /** fetch aggregated fields from the table: "store_and_review" */
  store_and_review_aggregate: Store_And_Review_Aggregate;
  /** fetch data from the table: "store_and_review" using primary key columns */
  store_and_review_by_pk?: Maybe<Store_And_Review>;
  /** fetch data from the table: "store_and_sole" */
  store_and_sole: Array<Store_And_Sole>;
  /** fetch aggregated fields from the table: "store_and_sole" */
  store_and_sole_aggregate: Store_And_Sole_Aggregate;
  /** fetch data from the table: "store_and_sole" using primary key columns */
  store_and_sole_by_pk?: Maybe<Store_And_Sole>;
  /** execute function "store_by_date_only" which returns "view_store_gallery" */
  store_by_date_only: Array<View_Store_Gallery>;
  /** execute function "store_by_date_only" and query aggregates on result of table type "view_store_gallery" */
  store_by_date_only_aggregate: View_Store_Gallery_Aggregate;
  /** fetch data from the table: "store" using primary key columns */
  store_by_pk?: Maybe<Store>;
  /** execute function "store_search_keyword" which returns "view_store" */
  store_search_keyword: Array<View_Store>;
  /** execute function "store_search_keyword" and query aggregates on result of table type "view_store" */
  store_search_keyword_aggregate: View_Store_Aggregate;
  /** execute function "store_search_polygon" which returns "view_store" */
  store_search_polygon: Array<View_Store>;
  /** execute function "store_search_polygon" and query aggregates on result of table type "view_store" */
  store_search_polygon_aggregate: View_Store_Aggregate;
  /** fetch data from the table: "store_status" */
  store_status: Array<Store_Status>;
  /** fetch aggregated fields from the table: "store_status" */
  store_status_aggregate: Store_Status_Aggregate;
  /** fetch data from the table: "store_status" using primary key columns */
  store_status_by_pk?: Maybe<Store_Status>;
  /** fetch data from the table: "store_with_rating" */
  store_with_rating: Array<Store_With_Rating>;
  /** fetch aggregated fields from the table: "store_with_rating" */
  store_with_rating_aggregate: Store_With_Rating_Aggregate;
  /** fetch data from the table: "store_with_rating_of_fake" */
  store_with_rating_of_fake: Array<Store_With_Rating_Of_Fake>;
  /** fetch aggregated fields from the table: "store_with_rating_of_fake" */
  store_with_rating_of_fake_aggregate: Store_With_Rating_Of_Fake_Aggregate;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription_and_transaction" */
  subscription_and_transaction: Array<Subscription_And_Transaction>;
  /** fetch aggregated fields from the table: "subscription_and_transaction" */
  subscription_and_transaction_aggregate: Subscription_And_Transaction_Aggregate;
  /** fetch data from the table: "subscription_and_transaction" using primary key columns */
  subscription_and_transaction_by_pk?: Maybe<Subscription_And_Transaction>;
  /** fetch data from the table: "subscription_and_transfer" */
  subscription_and_transfer: Array<Subscription_And_Transfer>;
  /** fetch aggregated fields from the table: "subscription_and_transfer" */
  subscription_and_transfer_aggregate: Subscription_And_Transfer_Aggregate;
  /** fetch data from the table: "subscription_and_transfer" using primary key columns */
  subscription_and_transfer_by_pk?: Maybe<Subscription_And_Transfer>;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table: "subscription_status" */
  subscription_status: Array<Subscription_Status>;
  /** fetch aggregated fields from the table: "subscription_status" */
  subscription_status_aggregate: Subscription_Status_Aggregate;
  /** fetch data from the table: "subscription_status" using primary key columns */
  subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<Transaction_Type>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: Transaction_Type_Aggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** fetch data from the table: "transfer" */
  transfer: Array<Transfer>;
  /** fetch aggregated fields from the table: "transfer" */
  transfer_aggregate: Transfer_Aggregate;
  /** fetch data from the table: "transfer" using primary key columns */
  transfer_by_pk?: Maybe<Transfer>;
  /** fetch data from the table: "transfer_type" */
  transfer_type: Array<Transfer_Type>;
  /** fetch aggregated fields from the table: "transfer_type" */
  transfer_type_aggregate: Transfer_Type_Aggregate;
  /** fetch data from the table: "transfer_type" using primary key columns */
  transfer_type_by_pk?: Maybe<Transfer_Type>;
  /** fetch data from the table: "user_actions_history" */
  user_actions_history: Array<User_Actions_History>;
  /** fetch aggregated fields from the table: "user_actions_history" */
  user_actions_history_aggregate: User_Actions_History_Aggregate;
  /** fetch data from the table: "user_actions_history" using primary key columns */
  user_actions_history_by_pk?: Maybe<User_Actions_History>;
  /** fetch data from the table: "view" */
  view: Array<View>;
  /** fetch aggregated fields from the table: "view" */
  view_aggregate: View_Aggregate;
  /** fetch data from the table: "view" using primary key columns */
  view_by_pk?: Maybe<View>;
  /** fetch data from the table: "view_package_store" */
  view_package_store: Array<View_Package_Store>;
  /** fetch aggregated fields from the table: "view_package_store" */
  view_package_store_aggregate: View_Package_Store_Aggregate;
  /** fetch data from the table: "view_package_store" using primary key columns */
  view_package_store_by_pk?: Maybe<View_Package_Store>;
  /** fetch data from the table: "view_store" */
  view_store: Array<View_Store>;
  /** fetch aggregated fields from the table: "view_store" */
  view_store_aggregate: View_Store_Aggregate;
  /** fetch data from the table: "view_store" using primary key columns */
  view_store_by_pk?: Maybe<View_Store>;
  /** fetch data from the table: "view_store_gallery" */
  view_store_gallery: Array<View_Store_Gallery>;
  /** fetch aggregated fields from the table: "view_store_gallery" */
  view_store_gallery_aggregate: View_Store_Gallery_Aggregate;
  /** fetch data from the table: "view_store_gallery" using primary key columns */
  view_store_gallery_by_pk?: Maybe<View_Store_Gallery>;
};


export type Subscription_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_PerformanceArgs = {
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootAccount_Performance_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootAccount_ThroughputArgs = {
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Subscription_RootAccount_Throughput_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Subscription_RootAccount_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Type_Order_By>>;
  where?: Maybe<Account_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Type_Order_By>>;
  where?: Maybe<Account_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAccounts_PayableArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_Order_By>>;
  where?: Maybe<Accounts_Payable_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_Order_By>>;
  where?: Maybe<Accounts_Payable_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_And_AdjustmentArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_And_Adjustment_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Adjustment_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Adjustment_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_And_Adjustment_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  adjustment: Scalars['uuid'];
};


export type Subscription_RootAccounts_Payable_And_PayoutArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_And_Payout_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Payout_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Payout_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_And_Payout_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  payout: Scalars['uuid'];
};


export type Subscription_RootAccounts_Payable_And_RefundArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_And_Refund_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Payable_And_Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Payable_And_Refund_Order_By>>;
  where?: Maybe<Accounts_Payable_And_Refund_Bool_Exp>;
};


export type Subscription_RootAccounts_Payable_And_Refund_By_PkArgs = {
  accounts_payable: Scalars['uuid'];
  refund: Scalars['uuid'];
};


export type Subscription_RootAccounts_Payable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccounts_ReceivableArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_Order_By>>;
  where?: Maybe<Accounts_Receivable_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_Order_By>>;
  where?: Maybe<Accounts_Receivable_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_And_InvoiceArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_And_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Invoice_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Invoice_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_And_Invoice_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  invoice: Scalars['uuid'];
};


export type Subscription_RootAccounts_Receivable_And_MembershipArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_And_Membership_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Membership_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Membership_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_And_Membership_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  membership: Scalars['uuid'];
};


export type Subscription_RootAccounts_Receivable_And_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_And_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Receivable_And_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Receivable_And_Subscription_Order_By>>;
  where?: Maybe<Accounts_Receivable_And_Subscription_Bool_Exp>;
};


export type Subscription_RootAccounts_Receivable_And_Subscription_By_PkArgs = {
  accounts_receivable: Scalars['uuid'];
  subscription: Scalars['uuid'];
};


export type Subscription_RootAccounts_Receivable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootActivityArgs = {
  distinct_on?: Maybe<Array<Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Order_By>>;
  where?: Maybe<Activity_Bool_Exp>;
};


export type Subscription_RootActivity_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Order_By>>;
  where?: Maybe<Activity_Bool_Exp>;
};


export type Subscription_RootActivity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAddress_SidoArgs = {
  distinct_on?: Maybe<Array<Address_Sido_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sido_Order_By>>;
  where?: Maybe<Address_Sido_Bool_Exp>;
};


export type Subscription_RootAddress_Sido_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Sido_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sido_Order_By>>;
  where?: Maybe<Address_Sido_Bool_Exp>;
};


export type Subscription_RootAddress_Sido_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAddress_SigunguArgs = {
  distinct_on?: Maybe<Array<Address_Sigungu_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sigungu_Order_By>>;
  where?: Maybe<Address_Sigungu_Bool_Exp>;
};


export type Subscription_RootAddress_Sigungu_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Sigungu_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Sigungu_Order_By>>;
  where?: Maybe<Address_Sigungu_Bool_Exp>;
};


export type Subscription_RootAddress_Sigungu_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAdjustmentArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


export type Subscription_RootAdjustment_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Order_By>>;
  where?: Maybe<Adjustment_Bool_Exp>;
};


export type Subscription_RootAdjustment_And_TransferArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};


export type Subscription_RootAdjustment_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};


export type Subscription_RootAdjustment_And_Transfer_By_PkArgs = {
  adjustment: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Subscription_RootAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAdjustment_StatusArgs = {
  distinct_on?: Maybe<Array<Adjustment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Status_Order_By>>;
  where?: Maybe<Adjustment_Status_Bool_Exp>;
};


export type Subscription_RootAdjustment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_Status_Order_By>>;
  where?: Maybe<Adjustment_Status_Bool_Exp>;
};


export type Subscription_RootAdjustment_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootBlog_PostArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


export type Subscription_RootBlog_Post_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_Order_By>>;
  where?: Maybe<Blog_Post_Bool_Exp>;
};


export type Subscription_RootBlog_Post_And_HashtagArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


export type Subscription_RootBlog_Post_And_Hashtag_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_Hashtag_Order_By>>;
  where?: Maybe<Blog_Post_And_Hashtag_Bool_Exp>;
};


export type Subscription_RootBlog_Post_And_Hashtag_By_PkArgs = {
  blog_post: Scalars['uuid'];
  hashtag: Scalars['uuid'];
};


export type Subscription_RootBlog_Post_And_ViewArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};


export type Subscription_RootBlog_Post_And_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};


export type Subscription_RootBlog_Post_And_View_By_PkArgs = {
  blog_post: Scalars['uuid'];
  view: Scalars['uuid'];
};


export type Subscription_RootBlog_Post_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCarArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


export type Subscription_RootCar_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Order_By>>;
  where?: Maybe<Car_Bool_Exp>;
};


export type Subscription_RootCar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCar_InspectionArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


export type Subscription_RootCar_Inspection_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Order_By>>;
  where?: Maybe<Car_Inspection_Bool_Exp>;
};


export type Subscription_RootCar_Inspection_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCar_Inspection_StatusArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Status_Order_By>>;
  where?: Maybe<Car_Inspection_Status_Bool_Exp>;
};


export type Subscription_RootCar_Inspection_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Inspection_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Inspection_Status_Order_By>>;
  where?: Maybe<Car_Inspection_Status_Bool_Exp>;
};


export type Subscription_RootCar_Inspection_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCar_MakerArgs = {
  distinct_on?: Maybe<Array<Car_Maker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Maker_Order_By>>;
  where?: Maybe<Car_Maker_Bool_Exp>;
};


export type Subscription_RootCar_Maker_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Maker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Maker_Order_By>>;
  where?: Maybe<Car_Maker_Bool_Exp>;
};


export type Subscription_RootCar_Maker_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCar_OriginArgs = {
  distinct_on?: Maybe<Array<Car_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Origin_Order_By>>;
  where?: Maybe<Car_Origin_Bool_Exp>;
};


export type Subscription_RootCar_Origin_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Origin_Order_By>>;
  where?: Maybe<Car_Origin_Bool_Exp>;
};


export type Subscription_RootCar_Origin_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCar_Sunroof_TypeArgs = {
  distinct_on?: Maybe<Array<Car_Sunroof_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Sunroof_Type_Order_By>>;
  where?: Maybe<Car_Sunroof_Type_Bool_Exp>;
};


export type Subscription_RootCar_Sunroof_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Sunroof_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Sunroof_Type_Order_By>>;
  where?: Maybe<Car_Sunroof_Type_Bool_Exp>;
};


export type Subscription_RootCar_Sunroof_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCar_TypeArgs = {
  distinct_on?: Maybe<Array<Car_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Type_Order_By>>;
  where?: Maybe<Car_Type_Bool_Exp>;
};


export type Subscription_RootCar_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Car_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Car_Type_Order_By>>;
  where?: Maybe<Car_Type_Bool_Exp>;
};


export type Subscription_RootCar_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


export type Subscription_RootCart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cart_Item_Order_By>>;
  where?: Maybe<Cart_Item_Bool_Exp>;
};


export type Subscription_RootCart_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootChatArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


export type Subscription_RootChat_AggregateArgs = {
  distinct_on?: Maybe<Array<Chat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chat_Order_By>>;
  where?: Maybe<Chat_Bool_Exp>;
};


export type Subscription_RootChat_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConfigArgs = {
  distinct_on?: Maybe<Array<Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Order_By>>;
  where?: Maybe<Config_Bool_Exp>;
};


export type Subscription_RootConfig_AggregateArgs = {
  distinct_on?: Maybe<Array<Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Order_By>>;
  where?: Maybe<Config_Bool_Exp>;
};


export type Subscription_RootConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConfig_TypeArgs = {
  distinct_on?: Maybe<Array<Config_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Type_Order_By>>;
  where?: Maybe<Config_Type_Bool_Exp>;
};


export type Subscription_RootConfig_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Config_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Config_Type_Order_By>>;
  where?: Maybe<Config_Type_Bool_Exp>;
};


export type Subscription_RootConfig_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCouponArgs = {
  distinct_on?: Maybe<Array<Coupon_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Order_By>>;
  where?: Maybe<Coupon_Bool_Exp>;
};


export type Subscription_RootCoupon_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Order_By>>;
  where?: Maybe<Coupon_Bool_Exp>;
};


export type Subscription_RootCoupon_And_AccountArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


export type Subscription_RootCoupon_And_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_And_Account_Order_By>>;
  where?: Maybe<Coupon_And_Account_Bool_Exp>;
};


export type Subscription_RootCoupon_And_Account_By_PkArgs = {
  account: Scalars['uuid'];
  coupon: Scalars['uuid'];
};


export type Subscription_RootCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCoupon_StatusArgs = {
  distinct_on?: Maybe<Array<Coupon_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Status_Order_By>>;
  where?: Maybe<Coupon_Status_Bool_Exp>;
};


export type Subscription_RootCoupon_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Status_Order_By>>;
  where?: Maybe<Coupon_Status_Bool_Exp>;
};


export type Subscription_RootCoupon_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCurationArgs = {
  distinct_on?: Maybe<Array<Curation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Curation_Order_By>>;
  where?: Maybe<Curation_Bool_Exp>;
};


export type Subscription_RootCuration_AggregateArgs = {
  distinct_on?: Maybe<Array<Curation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Curation_Order_By>>;
  where?: Maybe<Curation_Bool_Exp>;
};


export type Subscription_RootCuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFake_ReviewArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


export type Subscription_RootFake_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Fake_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fake_Review_Order_By>>;
  where?: Maybe<Fake_Review_Bool_Exp>;
};


export type Subscription_RootFake_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGalleryArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


export type Subscription_RootGallery_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Order_By>>;
  where?: Maybe<Gallery_Bool_Exp>;
};


export type Subscription_RootGallery_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGeography_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_By_Used_Car_StatusArgs = {
  args: Get_Account_Performance_By_Used_Car_Status_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_By_Used_Car_Status_AggregateArgs = {
  args: Get_Account_Performance_By_Used_Car_Status_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_ChatArgs = {
  args: Get_Account_Performance_For_Chat_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_Chat_AggregateArgs = {
  args: Get_Account_Performance_For_Chat_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_First_CallArgs = {
  args: Get_Account_Performance_For_First_Call_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_First_Call_AggregateArgs = {
  args: Get_Account_Performance_For_First_Call_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_MatchArgs = {
  args: Get_Account_Performance_For_Match_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_Match_AggregateArgs = {
  args: Get_Account_Performance_For_Match_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_ReservationArgs = {
  args: Get_Account_Performance_For_Reservation_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_Reservation_AggregateArgs = {
  args: Get_Account_Performance_For_Reservation_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_SupportArgs = {
  args: Get_Account_Performance_For_Support_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_Performance_For_Support_AggregateArgs = {
  args: Get_Account_Performance_For_Support_Args;
  distinct_on?: Maybe<Array<Account_Performance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Performance_Order_By>>;
  where?: Maybe<Account_Performance_Bool_Exp>;
};


export type Subscription_RootGet_Account_ThroughputArgs = {
  args: Get_Account_Throughput_Args;
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Subscription_RootGet_Account_Throughput_AggregateArgs = {
  args: Get_Account_Throughput_Args;
  distinct_on?: Maybe<Array<Account_Throughput_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Throughput_Order_By>>;
  where?: Maybe<Account_Throughput_Bool_Exp>;
};


export type Subscription_RootGet_Lead_Status_StatisticsArgs = {
  args: Get_Lead_Status_Statistics_Args;
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Subscription_RootGet_Lead_Status_Statistics_AggregateArgs = {
  args: Get_Lead_Status_Statistics_Args;
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Subscription_RootHashtagArgs = {
  distinct_on?: Maybe<Array<Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hashtag_Order_By>>;
  where?: Maybe<Hashtag_Bool_Exp>;
};


export type Subscription_RootHashtag_AggregateArgs = {
  distinct_on?: Maybe<Array<Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hashtag_Order_By>>;
  where?: Maybe<Hashtag_Bool_Exp>;
};


export type Subscription_RootHashtag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInventoryArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


export type Subscription_RootInventory_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inventory_Order_By>>;
  where?: Maybe<Inventory_Bool_Exp>;
};


export type Subscription_RootInventory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Order_By>>;
  where?: Maybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


export type Subscription_RootInvoice_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


export type Subscription_RootInvoice_And_Transaction_By_PkArgs = {
  invoice: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Subscription_RootInvoice_And_TransferArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


export type Subscription_RootInvoice_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


export type Subscription_RootInvoice_And_Transfer_By_PkArgs = {
  invoice: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Subscription_RootInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoice_StatusArgs = {
  distinct_on?: Maybe<Array<Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Status_Order_By>>;
  where?: Maybe<Invoice_Status_Bool_Exp>;
};


export type Subscription_RootInvoice_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Status_Order_By>>;
  where?: Maybe<Invoice_Status_Bool_Exp>;
};


export type Subscription_RootInvoice_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootKakao_And_AccountArgs = {
  distinct_on?: Maybe<Array<Kakao_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kakao_And_Account_Order_By>>;
  where?: Maybe<Kakao_And_Account_Bool_Exp>;
};


export type Subscription_RootKakao_And_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Kakao_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kakao_And_Account_Order_By>>;
  where?: Maybe<Kakao_And_Account_Bool_Exp>;
};


export type Subscription_RootKakao_And_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


export type Subscription_RootLead_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Order_By>>;
  where?: Maybe<Lead_Bool_Exp>;
};


export type Subscription_RootLead_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLead_CommentArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


export type Subscription_RootLead_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Comment_Order_By>>;
  where?: Maybe<Lead_Comment_Bool_Exp>;
};


export type Subscription_RootLead_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLead_StatusArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Order_By>>;
  where?: Maybe<Lead_Status_Bool_Exp>;
};


export type Subscription_RootLead_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Order_By>>;
  where?: Maybe<Lead_Status_Bool_Exp>;
};


export type Subscription_RootLead_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLead_Status_StatisticsArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Subscription_RootLead_Status_Statistics_AggregateArgs = {
  distinct_on?: Maybe<Array<Lead_Status_Statistics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lead_Status_Statistics_Order_By>>;
  where?: Maybe<Lead_Status_Statistics_Bool_Exp>;
};


export type Subscription_RootLikeArgs = {
  distinct_on?: Maybe<Array<Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Like_Order_By>>;
  where?: Maybe<Like_Bool_Exp>;
};


export type Subscription_RootLike_AggregateArgs = {
  distinct_on?: Maybe<Array<Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Like_Order_By>>;
  where?: Maybe<Like_Bool_Exp>;
};


export type Subscription_RootLike_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembershipArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};


export type Subscription_RootMembership_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Order_By>>;
  where?: Maybe<Membership_Bool_Exp>;
};


export type Subscription_RootMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembership_LogArgs = {
  distinct_on?: Maybe<Array<Membership_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Log_Order_By>>;
  where?: Maybe<Membership_Log_Bool_Exp>;
};


export type Subscription_RootMembership_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Log_Order_By>>;
  where?: Maybe<Membership_Log_Bool_Exp>;
};


export type Subscription_RootMembership_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembership_TypeArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Subscription_RootMembership_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Subscription_RootMembership_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootN_CartArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


export type Subscription_RootN_Cart_AggregateArgs = {
  distinct_on?: Maybe<Array<N_Cart_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Cart_Order_By>>;
  where?: Maybe<N_Cart_Bool_Exp>;
};


export type Subscription_RootN_Cart_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootN_OrderArgs = {
  distinct_on?: Maybe<Array<N_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Order_Order_By>>;
  where?: Maybe<N_Order_Bool_Exp>;
};


export type Subscription_RootN_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<N_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<N_Order_Order_By>>;
  where?: Maybe<N_Order_Bool_Exp>;
};


export type Subscription_RootN_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrderArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_And_ReviewArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


export type Subscription_RootOrder_And_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Review_Order_By>>;
  where?: Maybe<Order_And_Review_Bool_Exp>;
};


export type Subscription_RootOrder_And_Review_By_PkArgs = {
  order: Scalars['uuid'];
  review: Scalars['uuid'];
};


export type Subscription_RootOrder_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


export type Subscription_RootOrder_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


export type Subscription_RootOrder_And_Transaction_By_PkArgs = {
  order: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Subscription_RootOrder_And_TransferArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


export type Subscription_RootOrder_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


export type Subscription_RootOrder_And_Transfer_By_PkArgs = {
  order: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_From_StoreArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};


export type Subscription_RootOrder_From_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Order_By>>;
  where?: Maybe<Order_From_Store_Bool_Exp>;
};


export type Subscription_RootOrder_From_Store_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_From_Store_StatusArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Status_Order_By>>;
  where?: Maybe<Order_From_Store_Status_Bool_Exp>;
};


export type Subscription_RootOrder_From_Store_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_From_Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_From_Store_Status_Order_By>>;
  where?: Maybe<Order_From_Store_Status_Bool_Exp>;
};


export type Subscription_RootOrder_From_Store_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootOrder_ItemArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


export type Subscription_RootOrder_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Item_Order_By>>;
  where?: Maybe<Order_Item_Bool_Exp>;
};


export type Subscription_RootOrder_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrder_StatusArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPackageArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


export type Subscription_RootPackage_AggregateArgs = {
  distinct_on?: Maybe<Array<Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Order_By>>;
  where?: Maybe<Package_Bool_Exp>;
};


export type Subscription_RootPackage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPackage_Store_SearchArgs = {
  args: Package_Store_Search_Args;
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Subscription_RootPackage_Store_Search_AggregateArgs = {
  args: Package_Store_Search_Args;
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Subscription_RootPackage_TypeArgs = {
  distinct_on?: Maybe<Array<Package_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Type_Order_By>>;
  where?: Maybe<Package_Type_Bool_Exp>;
};


export type Subscription_RootPackage_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Package_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Package_Type_Order_By>>;
  where?: Maybe<Package_Type_Bool_Exp>;
};


export type Subscription_RootPackage_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPayoutArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


export type Subscription_RootPayout_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Order_By>>;
  where?: Maybe<Payout_Bool_Exp>;
};


export type Subscription_RootPayout_And_TransferArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};


export type Subscription_RootPayout_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};


export type Subscription_RootPayout_And_Transfer_By_PkArgs = {
  payout: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Subscription_RootPayout_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayout_StatusArgs = {
  distinct_on?: Maybe<Array<Payout_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Status_Order_By>>;
  where?: Maybe<Payout_Status_Bool_Exp>;
};


export type Subscription_RootPayout_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_Status_Order_By>>;
  where?: Maybe<Payout_Status_Bool_Exp>;
};


export type Subscription_RootPayout_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_And_LikeArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


export type Subscription_RootProduct_And_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_And_Like_Order_By>>;
  where?: Maybe<Product_And_Like_Bool_Exp>;
};


export type Subscription_RootProduct_And_Like_By_PkArgs = {
  like: Scalars['uuid'];
  product: Scalars['uuid'];
};


export type Subscription_RootProduct_BrandArgs = {
  distinct_on?: Maybe<Array<Product_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Brand_Order_By>>;
  where?: Maybe<Product_Brand_Bool_Exp>;
};


export type Subscription_RootProduct_Brand_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Brand_Order_By>>;
  where?: Maybe<Product_Brand_Bool_Exp>;
};


export type Subscription_RootProduct_Brand_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProduct_ItemArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


export type Subscription_RootProduct_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Order_By>>;
  where?: Maybe<Product_Item_Bool_Exp>;
};


export type Subscription_RootProduct_Item_And_ReviewArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


export type Subscription_RootProduct_Item_And_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_And_Review_Order_By>>;
  where?: Maybe<Product_Item_And_Review_Bool_Exp>;
};


export type Subscription_RootProduct_Item_And_Review_By_PkArgs = {
  product_item: Scalars['uuid'];
  review: Scalars['uuid'];
};


export type Subscription_RootProduct_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProduct_Item_Price_By_StoreArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


export type Subscription_RootProduct_Item_Price_By_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Item_Price_By_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Item_Price_By_Store_Order_By>>;
  where?: Maybe<Product_Item_Price_By_Store_Bool_Exp>;
};


export type Subscription_RootProduct_Item_Price_By_Store_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProduct_TypeArgs = {
  distinct_on?: Maybe<Array<Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Type_Order_By>>;
  where?: Maybe<Product_Type_Bool_Exp>;
};


export type Subscription_RootProduct_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Type_Order_By>>;
  where?: Maybe<Product_Type_Bool_Exp>;
};


export type Subscription_RootProduct_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProfile_Kr_CustomerArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


export type Subscription_RootProfile_Kr_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Customer_Order_By>>;
  where?: Maybe<Profile_Kr_Customer_Bool_Exp>;
};


export type Subscription_RootProfile_Kr_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_Kr_PartnerArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


export type Subscription_RootProfile_Kr_Partner_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Kr_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Kr_Partner_Order_By>>;
  where?: Maybe<Profile_Kr_Partner_Bool_Exp>;
};


export type Subscription_RootProfile_Kr_Partner_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_LanguageArgs = {
  distinct_on?: Maybe<Array<Profile_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Language_Order_By>>;
  where?: Maybe<Profile_Language_Bool_Exp>;
};


export type Subscription_RootProfile_Language_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Language_Order_By>>;
  where?: Maybe<Profile_Language_Bool_Exp>;
};


export type Subscription_RootProfile_Language_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProfile_RiderdashArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};


export type Subscription_RootProfile_Riderdash_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Order_By>>;
  where?: Maybe<Profile_Riderdash_Bool_Exp>;
};


export type Subscription_RootProfile_Riderdash_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_Riderdash_RoleArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Role_Order_By>>;
  where?: Maybe<Profile_Riderdash_Role_Bool_Exp>;
};


export type Subscription_RootProfile_Riderdash_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Riderdash_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Riderdash_Role_Order_By>>;
  where?: Maybe<Profile_Riderdash_Role_Bool_Exp>;
};


export type Subscription_RootProfile_Riderdash_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProfile_Us_CustomerArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


export type Subscription_RootProfile_Us_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Customer_Order_By>>;
  where?: Maybe<Profile_Us_Customer_Bool_Exp>;
};


export type Subscription_RootProfile_Us_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_Us_PartnerArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};


export type Subscription_RootProfile_Us_Partner_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Us_Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Us_Partner_Order_By>>;
  where?: Maybe<Profile_Us_Partner_Bool_Exp>;
};


export type Subscription_RootProfile_Us_Partner_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPromotionArgs = {
  distinct_on?: Maybe<Array<Promotion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotion_Order_By>>;
  where?: Maybe<Promotion_Bool_Exp>;
};


export type Subscription_RootPromotion_AggregateArgs = {
  distinct_on?: Maybe<Array<Promotion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotion_Order_By>>;
  where?: Maybe<Promotion_Bool_Exp>;
};


export type Subscription_RootPromotion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuoteArgs = {
  distinct_on?: Maybe<Array<Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Order_By>>;
  where?: Maybe<Quote_Bool_Exp>;
};


export type Subscription_RootQuote_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Order_By>>;
  where?: Maybe<Quote_Bool_Exp>;
};


export type Subscription_RootQuote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuote_ItemArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};


export type Subscription_RootQuote_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Item_Order_By>>;
  where?: Maybe<Quote_Item_Bool_Exp>;
};


export type Subscription_RootQuote_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuote_RequestArgs = {
  distinct_on?: Maybe<Array<Quote_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Request_Order_By>>;
  where?: Maybe<Quote_Request_Bool_Exp>;
};


export type Subscription_RootQuote_Request_AggregateArgs = {
  distinct_on?: Maybe<Array<Quote_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quote_Request_Order_By>>;
  where?: Maybe<Quote_Request_Bool_Exp>;
};


export type Subscription_RootQuote_Request_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRaster_ColumnsArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Subscription_RootRaster_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Subscription_RootRaster_OverviewsArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Subscription_RootRaster_Overviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Subscription_RootRefundArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


export type Subscription_RootRefund_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


export type Subscription_RootRefund_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


export type Subscription_RootRefund_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


export type Subscription_RootRefund_And_Transaction_By_PkArgs = {
  refund: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Subscription_RootRefund_And_TransferArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};


export type Subscription_RootRefund_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};


export type Subscription_RootRefund_And_Transfer_By_PkArgs = {
  refund: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Subscription_RootRefund_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRefund_StatusArgs = {
  distinct_on?: Maybe<Array<Refund_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Status_Order_By>>;
  where?: Maybe<Refund_Status_Bool_Exp>;
};


export type Subscription_RootRefund_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Status_Order_By>>;
  where?: Maybe<Refund_Status_Bool_Exp>;
};


export type Subscription_RootRefund_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootReviewArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Subscription_RootReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Subscription_RootReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReview_StatusArgs = {
  distinct_on?: Maybe<Array<Review_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Status_Order_By>>;
  where?: Maybe<Review_Status_Bool_Exp>;
};


export type Subscription_RootReview_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Status_Order_By>>;
  where?: Maybe<Review_Status_Bool_Exp>;
};


export type Subscription_RootReview_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSlotArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


export type Subscription_RootSlot_AggregateArgs = {
  distinct_on?: Maybe<Array<Slot_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Slot_Order_By>>;
  where?: Maybe<Slot_Bool_Exp>;
};


export type Subscription_RootSlot_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSoleArgs = {
  distinct_on?: Maybe<Array<Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sole_Order_By>>;
  where?: Maybe<Sole_Bool_Exp>;
};


export type Subscription_RootSole_AggregateArgs = {
  distinct_on?: Maybe<Array<Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sole_Order_By>>;
  where?: Maybe<Sole_Bool_Exp>;
};


export type Subscription_RootSole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSpatial_Ref_SysArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Subscription_RootStoreArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};


export type Subscription_RootStore_Account_RoleArgs = {
  distinct_on?: Maybe<Array<Store_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Account_Role_Order_By>>;
  where?: Maybe<Store_Account_Role_Bool_Exp>;
};


export type Subscription_RootStore_Account_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Account_Role_Order_By>>;
  where?: Maybe<Store_Account_Role_Bool_Exp>;
};


export type Subscription_RootStore_Account_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootStore_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Order_By>>;
  where?: Maybe<Store_Bool_Exp>;
};


export type Subscription_RootStore_And_AccountArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


export type Subscription_RootStore_And_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Account_Order_By>>;
  where?: Maybe<Store_And_Account_Bool_Exp>;
};


export type Subscription_RootStore_And_Account_By_PkArgs = {
  account: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_And_HashtagArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};


export type Subscription_RootStore_And_Hashtag_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Hashtag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Hashtag_Order_By>>;
  where?: Maybe<Store_And_Hashtag_Bool_Exp>;
};


export type Subscription_RootStore_And_Hashtag_By_PkArgs = {
  hashtag: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_And_LikeArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};


export type Subscription_RootStore_And_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Like_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Like_Order_By>>;
  where?: Maybe<Store_And_Like_Bool_Exp>;
};


export type Subscription_RootStore_And_Like_By_PkArgs = {
  like: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_And_PackageArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


export type Subscription_RootStore_And_Package_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Package_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Package_Order_By>>;
  where?: Maybe<Store_And_Package_Bool_Exp>;
};


export type Subscription_RootStore_And_Package_By_PkArgs = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_And_ProductArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


export type Subscription_RootStore_And_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Order_By>>;
  where?: Maybe<Store_And_Product_Bool_Exp>;
};


export type Subscription_RootStore_And_Product_By_PkArgs = {
  product: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_And_Product_ItemArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


export type Subscription_RootStore_And_Product_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Product_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Product_Item_Order_By>>;
  where?: Maybe<Store_And_Product_Item_Bool_Exp>;
};


export type Subscription_RootStore_And_Product_Item_By_PkArgs = {
  product_item: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_And_ReviewArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};


export type Subscription_RootStore_And_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Review_Order_By>>;
  where?: Maybe<Store_And_Review_Bool_Exp>;
};


export type Subscription_RootStore_And_Review_By_PkArgs = {
  review: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_And_SoleArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};


export type Subscription_RootStore_And_Sole_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_And_Sole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_And_Sole_Order_By>>;
  where?: Maybe<Store_And_Sole_Bool_Exp>;
};


export type Subscription_RootStore_And_Sole_By_PkArgs = {
  sole: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootStore_By_Date_OnlyArgs = {
  args: Store_By_Date_Only_Args;
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Subscription_RootStore_By_Date_Only_AggregateArgs = {
  args: Store_By_Date_Only_Args;
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Subscription_RootStore_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStore_Search_KeywordArgs = {
  args: Store_Search_Keyword_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Subscription_RootStore_Search_Keyword_AggregateArgs = {
  args: Store_Search_Keyword_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Subscription_RootStore_Search_PolygonArgs = {
  args: Store_Search_Polygon_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Subscription_RootStore_Search_Polygon_AggregateArgs = {
  args: Store_Search_Polygon_Args;
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Subscription_RootStore_StatusArgs = {
  distinct_on?: Maybe<Array<Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Status_Order_By>>;
  where?: Maybe<Store_Status_Bool_Exp>;
};


export type Subscription_RootStore_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_Status_Order_By>>;
  where?: Maybe<Store_Status_Bool_Exp>;
};


export type Subscription_RootStore_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootStore_With_RatingArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Order_By>>;
  where?: Maybe<Store_With_Rating_Bool_Exp>;
};


export type Subscription_RootStore_With_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Order_By>>;
  where?: Maybe<Store_With_Rating_Bool_Exp>;
};


export type Subscription_RootStore_With_Rating_Of_FakeArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Of_Fake_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Of_Fake_Order_By>>;
  where?: Maybe<Store_With_Rating_Of_Fake_Bool_Exp>;
};


export type Subscription_RootStore_With_Rating_Of_Fake_AggregateArgs = {
  distinct_on?: Maybe<Array<Store_With_Rating_Of_Fake_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Store_With_Rating_Of_Fake_Order_By>>;
  where?: Maybe<Store_With_Rating_Of_Fake_Bool_Exp>;
};


export type Subscription_RootSubscriptionArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_And_TransactionArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};


export type Subscription_RootSubscription_And_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};


export type Subscription_RootSubscription_And_Transaction_By_PkArgs = {
  subscription: Scalars['uuid'];
  transaction: Scalars['uuid'];
};


export type Subscription_RootSubscription_And_TransferArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};


export type Subscription_RootSubscription_And_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};


export type Subscription_RootSubscription_And_Transfer_By_PkArgs = {
  subscription: Scalars['uuid'];
  transfer: Scalars['uuid'];
};


export type Subscription_RootSubscription_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSubscription_StatusArgs = {
  distinct_on?: Maybe<Array<Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Status_Order_By>>;
  where?: Maybe<Subscription_Status_Bool_Exp>;
};


export type Subscription_RootSubscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Status_Order_By>>;
  where?: Maybe<Subscription_Status_Bool_Exp>;
};


export type Subscription_RootSubscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTransactionArgs = {
  distinct_on?: Maybe<Array<Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Order_By>>;
  where?: Maybe<Transaction_Bool_Exp>;
};


export type Subscription_RootTransaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Order_By>>;
  where?: Maybe<Transaction_Bool_Exp>;
};


export type Subscription_RootTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTransaction_TypeArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Subscription_RootTransaction_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Subscription_RootTransaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTransferArgs = {
  distinct_on?: Maybe<Array<Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Order_By>>;
  where?: Maybe<Transfer_Bool_Exp>;
};


export type Subscription_RootTransfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Order_By>>;
  where?: Maybe<Transfer_Bool_Exp>;
};


export type Subscription_RootTransfer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTransfer_TypeArgs = {
  distinct_on?: Maybe<Array<Transfer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Type_Order_By>>;
  where?: Maybe<Transfer_Type_Bool_Exp>;
};


export type Subscription_RootTransfer_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Transfer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Type_Order_By>>;
  where?: Maybe<Transfer_Type_Bool_Exp>;
};


export type Subscription_RootTransfer_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUser_Actions_HistoryArgs = {
  distinct_on?: Maybe<Array<User_Actions_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Actions_History_Order_By>>;
  where?: Maybe<User_Actions_History_Bool_Exp>;
};


export type Subscription_RootUser_Actions_History_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Actions_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Actions_History_Order_By>>;
  where?: Maybe<User_Actions_History_Bool_Exp>;
};


export type Subscription_RootUser_Actions_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootViewArgs = {
  distinct_on?: Maybe<Array<View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Order_By>>;
  where?: Maybe<View_Bool_Exp>;
};


export type Subscription_RootView_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Order_By>>;
  where?: Maybe<View_Bool_Exp>;
};


export type Subscription_RootView_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootView_Package_StoreArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Subscription_RootView_Package_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Package_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Package_Store_Order_By>>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};


export type Subscription_RootView_Package_Store_By_PkArgs = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};


export type Subscription_RootView_StoreArgs = {
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Subscription_RootView_Store_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Store_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Order_By>>;
  where?: Maybe<View_Store_Bool_Exp>;
};


export type Subscription_RootView_Store_By_PkArgs = {
  store: Scalars['uuid'];
};


export type Subscription_RootView_Store_GalleryArgs = {
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Subscription_RootView_Store_Gallery_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<View_Store_Gallery_Order_By>>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};


export type Subscription_RootView_Store_Gallery_By_PkArgs = {
  id: Scalars['uuid'];
};

/** select columns of table "subscription" */
export enum Subscription_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  RapydSubscriptionId = 'rapyd_subscription_id',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "subscription" */
export type Subscription_Set_Input = {
  /** Account who created this row */
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  rapyd_subscription_id?: Maybe<Scalars['String']>;
  status?: Maybe<Subscription_Status_Enum>;
  store?: Maybe<Scalars['uuid']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "subscription_status" */
export type Subscription_Status = {
  __typename?: 'subscription_status';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscription_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "subscription_status" */
export type Subscription_StatusSubscriptionsArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "subscription_status" */
export type Subscription_StatusSubscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_Order_By>>;
  where?: Maybe<Subscription_Bool_Exp>;
};

/** aggregated selection of "subscription_status" */
export type Subscription_Status_Aggregate = {
  __typename?: 'subscription_status_aggregate';
  aggregate?: Maybe<Subscription_Status_Aggregate_Fields>;
  nodes: Array<Subscription_Status>;
};

/** aggregate fields of "subscription_status" */
export type Subscription_Status_Aggregate_Fields = {
  __typename?: 'subscription_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subscription_Status_Max_Fields>;
  min?: Maybe<Subscription_Status_Min_Fields>;
};


/** aggregate fields of "subscription_status" */
export type Subscription_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Subscription_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subscription_status". All fields are combined with a logical 'AND'. */
export type Subscription_Status_Bool_Exp = {
  _and?: Maybe<Array<Subscription_Status_Bool_Exp>>;
  _not?: Maybe<Subscription_Status_Bool_Exp>;
  _or?: Maybe<Array<Subscription_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  subscriptions?: Maybe<Subscription_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_status" */
export enum Subscription_Status_Constraint {
  /** unique or primary key constraint */
  SubscriptionStatusPkey = 'subscription_status_pkey'
}

export enum Subscription_Status_Enum {
  /** 활성됨 */
  Active = 'active',
  /** 구독 취소 */
  Canceled = 'canceled',
  /** 만료되어 지불 전 상태 */
  Incomplete = 'incomplete',
  /** 구독 만료 */
  IncompleteExpired = 'incomplete_expired',
  /** 없음 */
  None = 'none',
  /** 자동 결제 실패 */
  PastDue = 'past_due',
  /** 무료 체험 중 */
  Trialing = 'trialing',
  /** 미결제 */
  Unpaid = 'unpaid'
}

/** Boolean expression to compare columns of type "subscription_status_enum". All fields are combined with logical 'AND'. */
export type Subscription_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Subscription_Status_Enum>;
  _in?: Maybe<Array<Subscription_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Subscription_Status_Enum>;
  _nin?: Maybe<Array<Subscription_Status_Enum>>;
};

/** input type for inserting data into table "subscription_status" */
export type Subscription_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Subscription_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Subscription_Status_Max_Fields = {
  __typename?: 'subscription_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subscription_Status_Min_Fields = {
  __typename?: 'subscription_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "subscription_status" */
export type Subscription_Status_Mutation_Response = {
  __typename?: 'subscription_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Status>;
};

/** input type for inserting object relation for remote table "subscription_status" */
export type Subscription_Status_Obj_Rel_Insert_Input = {
  data: Subscription_Status_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Subscription_Status_On_Conflict>;
};

/** on conflict condition type for table "subscription_status" */
export type Subscription_Status_On_Conflict = {
  constraint: Subscription_Status_Constraint;
  update_columns?: Array<Subscription_Status_Update_Column>;
  where?: Maybe<Subscription_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_status". */
export type Subscription_Status_Order_By = {
  description?: Maybe<Order_By>;
  subscriptions_aggregate?: Maybe<Subscription_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: subscription_status */
export type Subscription_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "subscription_status" */
export enum Subscription_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "subscription_status" */
export type Subscription_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "subscription_status" */
export enum Subscription_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Subscription_Stddev_Fields = {
  __typename?: 'subscription_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "subscription" */
export type Subscription_Stddev_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Stddev_Pop_Fields = {
  __typename?: 'subscription_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "subscription" */
export type Subscription_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Stddev_Samp_Fields = {
  __typename?: 'subscription_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "subscription" */
export type Subscription_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Subscription_Sum_Fields = {
  __typename?: 'subscription_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "subscription" */
export type Subscription_Sum_Order_By = {
  price?: Maybe<Order_By>;
};

/** update columns of table "subscription" */
export enum Subscription_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  RapydSubscriptionId = 'rapyd_subscription_id',
  /** column name */
  Status = 'status',
  /** column name */
  Store = 'store',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Subscription_Var_Pop_Fields = {
  __typename?: 'subscription_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "subscription" */
export type Subscription_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscription_Var_Samp_Fields = {
  __typename?: 'subscription_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "subscription" */
export type Subscription_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscription_Variance_Fields = {
  __typename?: 'subscription_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "subscription" */
export type Subscription_Variance_Order_By = {
  price?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type Transaction = {
  __typename?: 'transaction';
  amount: Scalars['numeric'];
  bootpay_payment_intent_id?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  /** Internal transaction ID */
  id: Scalars['uuid'];
  /** An array relationship */
  invoice_and_transactions: Array<Invoice_And_Transaction>;
  /** An aggregate relationship */
  invoice_and_transactions_aggregate: Invoice_And_Transaction_Aggregate;
  /** An array relationship */
  order_and_transactions: Array<Order_And_Transaction>;
  /** An aggregate relationship */
  order_and_transactions_aggregate: Order_And_Transaction_Aggregate;
  rapyd_payment_intent_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  refund?: Maybe<Refund>;
  /** An array relationship */
  refund_and_transactions: Array<Refund_And_Transaction>;
  /** An aggregate relationship */
  refund_and_transactions_aggregate: Refund_And_Transaction_Aggregate;
  /** An array relationship */
  refunds: Array<Refund>;
  /** An aggregate relationship */
  refunds_aggregate: Refund_Aggregate;
  result?: Maybe<Scalars['jsonb']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_receipt_url?: Maybe<Scalars['String']>;
  submitted_at: Scalars['timestamptz'];
  /** An array relationship */
  subscription_and_transactions: Array<Subscription_And_Transaction>;
  /** An aggregate relationship */
  subscription_and_transactions_aggregate: Subscription_And_Transaction_Aggregate;
  /** An object relationship */
  transaction_type: Transaction_Type;
  type: Transaction_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionInvoice_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionInvoice_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transaction_Order_By>>;
  where?: Maybe<Invoice_And_Transaction_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionOrder_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionOrder_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transaction_Order_By>>;
  where?: Maybe<Order_And_Transaction_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionRefund_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionRefund_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transaction_Order_By>>;
  where?: Maybe<Refund_And_Transaction_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionRefundsArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionRefunds_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_Order_By>>;
  where?: Maybe<Refund_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionResultArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionSubscription_And_TransactionsArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};


/**
 * Transaction Information to Stripe
 *
 *
 * columns and relationships of "transaction"
 */
export type TransactionSubscription_And_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transaction_Order_By>>;
  where?: Maybe<Subscription_And_Transaction_Bool_Exp>;
};

/** aggregated selection of "transaction" */
export type Transaction_Aggregate = {
  __typename?: 'transaction_aggregate';
  aggregate?: Maybe<Transaction_Aggregate_Fields>;
  nodes: Array<Transaction>;
};

/** aggregate fields of "transaction" */
export type Transaction_Aggregate_Fields = {
  __typename?: 'transaction_aggregate_fields';
  avg?: Maybe<Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transaction_Max_Fields>;
  min?: Maybe<Transaction_Min_Fields>;
  stddev?: Maybe<Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Transaction_Sum_Fields>;
  var_pop?: Maybe<Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Transaction_Var_Samp_Fields>;
  variance?: Maybe<Transaction_Variance_Fields>;
};


/** aggregate fields of "transaction" */
export type Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transaction" */
export type Transaction_Aggregate_Order_By = {
  avg?: Maybe<Transaction_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Transaction_Max_Order_By>;
  min?: Maybe<Transaction_Min_Order_By>;
  stddev?: Maybe<Transaction_Stddev_Order_By>;
  stddev_pop?: Maybe<Transaction_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Transaction_Stddev_Samp_Order_By>;
  sum?: Maybe<Transaction_Sum_Order_By>;
  var_pop?: Maybe<Transaction_Var_Pop_Order_By>;
  var_samp?: Maybe<Transaction_Var_Samp_Order_By>;
  variance?: Maybe<Transaction_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Append_Input = {
  result?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "transaction" */
export type Transaction_Arr_Rel_Insert_Input = {
  data: Array<Transaction_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Transaction_On_Conflict>;
};

/** aggregate avg on columns */
export type Transaction_Avg_Fields = {
  __typename?: 'transaction_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transaction" */
export type Transaction_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type Transaction_Bool_Exp = {
  _and?: Maybe<Array<Transaction_Bool_Exp>>;
  _not?: Maybe<Transaction_Bool_Exp>;
  _or?: Maybe<Array<Transaction_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  bootpay_payment_intent_id?: Maybe<String_Comparison_Exp>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  completed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  failed_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoice_and_transactions?: Maybe<Invoice_And_Transaction_Bool_Exp>;
  order_and_transactions?: Maybe<Order_And_Transaction_Bool_Exp>;
  rapyd_payment_intent_id?: Maybe<String_Comparison_Exp>;
  refund?: Maybe<Refund_Bool_Exp>;
  refund_and_transactions?: Maybe<Refund_And_Transaction_Bool_Exp>;
  refunds?: Maybe<Refund_Bool_Exp>;
  result?: Maybe<Jsonb_Comparison_Exp>;
  stripe_payment_intent_id?: Maybe<String_Comparison_Exp>;
  stripe_receipt_url?: Maybe<String_Comparison_Exp>;
  submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  subscription_and_transactions?: Maybe<Subscription_And_Transaction_Bool_Exp>;
  transaction_type?: Maybe<Transaction_Type_Bool_Exp>;
  type?: Maybe<Transaction_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transaction" */
export enum Transaction_Constraint {
  /** unique or primary key constraint */
  TransactionIdKey = 'transaction_id_key',
  /** unique or primary key constraint */
  TransactionPkey = 'transaction_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transaction_Delete_At_Path_Input = {
  result?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transaction_Delete_Elem_Input = {
  result?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transaction_Delete_Key_Input = {
  result?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "transaction" */
export type Transaction_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "transaction" */
export type Transaction_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  bootpay_payment_intent_id?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  /** Internal transaction ID */
  id?: Maybe<Scalars['uuid']>;
  invoice_and_transactions?: Maybe<Invoice_And_Transaction_Arr_Rel_Insert_Input>;
  order_and_transactions?: Maybe<Order_And_Transaction_Arr_Rel_Insert_Input>;
  rapyd_payment_intent_id?: Maybe<Scalars['String']>;
  refund?: Maybe<Refund_Obj_Rel_Insert_Input>;
  refund_and_transactions?: Maybe<Refund_And_Transaction_Arr_Rel_Insert_Input>;
  refunds?: Maybe<Refund_Arr_Rel_Insert_Input>;
  result?: Maybe<Scalars['jsonb']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_receipt_url?: Maybe<Scalars['String']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  subscription_and_transactions?: Maybe<Subscription_And_Transaction_Arr_Rel_Insert_Input>;
  transaction_type?: Maybe<Transaction_Type_Obj_Rel_Insert_Input>;
  type?: Maybe<Transaction_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transaction_Max_Fields = {
  __typename?: 'transaction_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  bootpay_payment_intent_id?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  /** Internal transaction ID */
  id?: Maybe<Scalars['uuid']>;
  rapyd_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_receipt_url?: Maybe<Scalars['String']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transaction" */
export type Transaction_Max_Order_By = {
  amount?: Maybe<Order_By>;
  bootpay_payment_intent_id?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  failed_at?: Maybe<Order_By>;
  /** Internal transaction ID */
  id?: Maybe<Order_By>;
  rapyd_payment_intent_id?: Maybe<Order_By>;
  stripe_payment_intent_id?: Maybe<Order_By>;
  stripe_receipt_url?: Maybe<Order_By>;
  submitted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Transaction_Min_Fields = {
  __typename?: 'transaction_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  bootpay_payment_intent_id?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  /** Internal transaction ID */
  id?: Maybe<Scalars['uuid']>;
  rapyd_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_receipt_url?: Maybe<Scalars['String']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transaction" */
export type Transaction_Min_Order_By = {
  amount?: Maybe<Order_By>;
  bootpay_payment_intent_id?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  failed_at?: Maybe<Order_By>;
  /** Internal transaction ID */
  id?: Maybe<Order_By>;
  rapyd_payment_intent_id?: Maybe<Order_By>;
  stripe_payment_intent_id?: Maybe<Order_By>;
  stripe_receipt_url?: Maybe<Order_By>;
  submitted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "transaction" */
export type Transaction_Mutation_Response = {
  __typename?: 'transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction>;
};

/** input type for inserting object relation for remote table "transaction" */
export type Transaction_Obj_Rel_Insert_Input = {
  data: Transaction_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Transaction_On_Conflict>;
};

/** on conflict condition type for table "transaction" */
export type Transaction_On_Conflict = {
  constraint: Transaction_Constraint;
  update_columns?: Array<Transaction_Update_Column>;
  where?: Maybe<Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction". */
export type Transaction_Order_By = {
  amount?: Maybe<Order_By>;
  bootpay_payment_intent_id?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  failed_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_and_transactions_aggregate?: Maybe<Invoice_And_Transaction_Aggregate_Order_By>;
  order_and_transactions_aggregate?: Maybe<Order_And_Transaction_Aggregate_Order_By>;
  rapyd_payment_intent_id?: Maybe<Order_By>;
  refund?: Maybe<Refund_Order_By>;
  refund_and_transactions_aggregate?: Maybe<Refund_And_Transaction_Aggregate_Order_By>;
  refunds_aggregate?: Maybe<Refund_Aggregate_Order_By>;
  result?: Maybe<Order_By>;
  stripe_payment_intent_id?: Maybe<Order_By>;
  stripe_receipt_url?: Maybe<Order_By>;
  submitted_at?: Maybe<Order_By>;
  subscription_and_transactions_aggregate?: Maybe<Subscription_And_Transaction_Aggregate_Order_By>;
  transaction_type?: Maybe<Transaction_Type_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: transaction */
export type Transaction_Pk_Columns_Input = {
  /** Internal transaction ID */
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Prepend_Input = {
  result?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "transaction" */
export enum Transaction_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BootpayPaymentIntentId = 'bootpay_payment_intent_id',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  Id = 'id',
  /** column name */
  RapydPaymentIntentId = 'rapyd_payment_intent_id',
  /** column name */
  Result = 'result',
  /** column name */
  StripePaymentIntentId = 'stripe_payment_intent_id',
  /** column name */
  StripeReceiptUrl = 'stripe_receipt_url',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "transaction" */
export type Transaction_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  bootpay_payment_intent_id?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  /** Internal transaction ID */
  id?: Maybe<Scalars['uuid']>;
  rapyd_payment_intent_id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['jsonb']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_receipt_url?: Maybe<Scalars['String']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Transaction_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transaction_Stddev_Fields = {
  __typename?: 'transaction_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transaction" */
export type Transaction_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transaction_Stddev_Pop_Fields = {
  __typename?: 'transaction_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transaction" */
export type Transaction_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transaction_Stddev_Samp_Fields = {
  __typename?: 'transaction_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transaction" */
export type Transaction_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Transaction_Sum_Fields = {
  __typename?: 'transaction_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "transaction" */
export type Transaction_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "transaction_type" */
export type Transaction_Type = {
  __typename?: 'transaction_type';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactions_aggregate: Transaction_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "transaction_type" */
export type Transaction_TypeTransactionsArgs = {
  distinct_on?: Maybe<Array<Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Order_By>>;
  where?: Maybe<Transaction_Bool_Exp>;
};


/** columns and relationships of "transaction_type" */
export type Transaction_TypeTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Order_By>>;
  where?: Maybe<Transaction_Bool_Exp>;
};

/** aggregated selection of "transaction_type" */
export type Transaction_Type_Aggregate = {
  __typename?: 'transaction_type_aggregate';
  aggregate?: Maybe<Transaction_Type_Aggregate_Fields>;
  nodes: Array<Transaction_Type>;
};

/** aggregate fields of "transaction_type" */
export type Transaction_Type_Aggregate_Fields = {
  __typename?: 'transaction_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Transaction_Type_Max_Fields>;
  min?: Maybe<Transaction_Type_Min_Fields>;
};


/** aggregate fields of "transaction_type" */
export type Transaction_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transaction_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "transaction_type". All fields are combined with a logical 'AND'. */
export type Transaction_Type_Bool_Exp = {
  _and?: Maybe<Array<Transaction_Type_Bool_Exp>>;
  _not?: Maybe<Transaction_Type_Bool_Exp>;
  _or?: Maybe<Array<Transaction_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  transactions?: Maybe<Transaction_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "transaction_type" */
export enum Transaction_Type_Constraint {
  /** unique or primary key constraint */
  TransactionTypePkey = 'transaction_type_pkey'
}

export enum Transaction_Type_Enum {
  /** 구매 */
  Purchase = 'purchase',
  /** 환불 */
  Refund = 'refund'
}

/** Boolean expression to compare columns of type "transaction_type_enum". All fields are combined with logical 'AND'. */
export type Transaction_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Transaction_Type_Enum>;
  _in?: Maybe<Array<Transaction_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Transaction_Type_Enum>;
  _nin?: Maybe<Array<Transaction_Type_Enum>>;
};

/** input type for inserting data into table "transaction_type" */
export type Transaction_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  transactions?: Maybe<Transaction_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Transaction_Type_Max_Fields = {
  __typename?: 'transaction_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transaction_Type_Min_Fields = {
  __typename?: 'transaction_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "transaction_type" */
export type Transaction_Type_Mutation_Response = {
  __typename?: 'transaction_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction_Type>;
};

/** input type for inserting object relation for remote table "transaction_type" */
export type Transaction_Type_Obj_Rel_Insert_Input = {
  data: Transaction_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Transaction_Type_On_Conflict>;
};

/** on conflict condition type for table "transaction_type" */
export type Transaction_Type_On_Conflict = {
  constraint: Transaction_Type_Constraint;
  update_columns?: Array<Transaction_Type_Update_Column>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction_type". */
export type Transaction_Type_Order_By = {
  description?: Maybe<Order_By>;
  transactions_aggregate?: Maybe<Transaction_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: transaction_type */
export type Transaction_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "transaction_type" */
export enum Transaction_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "transaction_type" */
export type Transaction_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "transaction_type" */
export enum Transaction_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "transaction" */
export enum Transaction_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BootpayPaymentIntentId = 'bootpay_payment_intent_id',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  Id = 'id',
  /** column name */
  RapydPaymentIntentId = 'rapyd_payment_intent_id',
  /** column name */
  Result = 'result',
  /** column name */
  StripePaymentIntentId = 'stripe_payment_intent_id',
  /** column name */
  StripeReceiptUrl = 'stripe_receipt_url',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Transaction_Var_Pop_Fields = {
  __typename?: 'transaction_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transaction" */
export type Transaction_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transaction_Var_Samp_Fields = {
  __typename?: 'transaction_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transaction" */
export type Transaction_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Transaction_Variance_Fields = {
  __typename?: 'transaction_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transaction" */
export type Transaction_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type Transfer = {
  __typename?: 'transfer';
  /** An array relationship */
  adjustment_and_transfers: Array<Adjustment_And_Transfer>;
  /** An aggregate relationship */
  adjustment_and_transfers_aggregate: Adjustment_And_Transfer_Aggregate;
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  invoice_and_transfers: Array<Invoice_And_Transfer>;
  /** An aggregate relationship */
  invoice_and_transfers_aggregate: Invoice_And_Transfer_Aggregate;
  /** An array relationship */
  order_and_transfers: Array<Order_And_Transfer>;
  /** An aggregate relationship */
  order_and_transfers_aggregate: Order_And_Transfer_Aggregate;
  /** An array relationship */
  payout_and_transfers: Array<Payout_And_Transfer>;
  /** An aggregate relationship */
  payout_and_transfers_aggregate: Payout_And_Transfer_Aggregate;
  /** An array relationship */
  refund_and_transfers: Array<Refund_And_Transfer>;
  /** An aggregate relationship */
  refund_and_transfers_aggregate: Refund_And_Transfer_Aggregate;
  /** An array relationship */
  subscription_and_transfers: Array<Subscription_And_Transfer>;
  /** An aggregate relationship */
  subscription_and_transfers_aggregate: Subscription_And_Transfer_Aggregate;
  to: Scalars['String'];
  /** An object relationship */
  transfer_type: Transfer_Type;
  type: Transfer_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferAdjustment_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferAdjustment_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Adjustment_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Adjustment_And_Transfer_Order_By>>;
  where?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferInvoice_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferInvoice_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_And_Transfer_Order_By>>;
  where?: Maybe<Invoice_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferOrder_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferOrder_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_And_Transfer_Order_By>>;
  where?: Maybe<Order_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferPayout_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferPayout_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Payout_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payout_And_Transfer_Order_By>>;
  where?: Maybe<Payout_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferRefund_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferRefund_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Refund_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refund_And_Transfer_Order_By>>;
  where?: Maybe<Refund_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferSubscription_And_TransfersArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};


/**
 * Bank transfer
 *
 *
 * columns and relationships of "transfer"
 */
export type TransferSubscription_And_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscription_And_Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscription_And_Transfer_Order_By>>;
  where?: Maybe<Subscription_And_Transfer_Bool_Exp>;
};

/** aggregated selection of "transfer" */
export type Transfer_Aggregate = {
  __typename?: 'transfer_aggregate';
  aggregate?: Maybe<Transfer_Aggregate_Fields>;
  nodes: Array<Transfer>;
};

/** aggregate fields of "transfer" */
export type Transfer_Aggregate_Fields = {
  __typename?: 'transfer_aggregate_fields';
  avg?: Maybe<Transfer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transfer_Max_Fields>;
  min?: Maybe<Transfer_Min_Fields>;
  stddev?: Maybe<Transfer_Stddev_Fields>;
  stddev_pop?: Maybe<Transfer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transfer_Stddev_Samp_Fields>;
  sum?: Maybe<Transfer_Sum_Fields>;
  var_pop?: Maybe<Transfer_Var_Pop_Fields>;
  var_samp?: Maybe<Transfer_Var_Samp_Fields>;
  variance?: Maybe<Transfer_Variance_Fields>;
};


/** aggregate fields of "transfer" */
export type Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transfer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transfer" */
export type Transfer_Aggregate_Order_By = {
  avg?: Maybe<Transfer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Transfer_Max_Order_By>;
  min?: Maybe<Transfer_Min_Order_By>;
  stddev?: Maybe<Transfer_Stddev_Order_By>;
  stddev_pop?: Maybe<Transfer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Transfer_Stddev_Samp_Order_By>;
  sum?: Maybe<Transfer_Sum_Order_By>;
  var_pop?: Maybe<Transfer_Var_Pop_Order_By>;
  var_samp?: Maybe<Transfer_Var_Samp_Order_By>;
  variance?: Maybe<Transfer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transfer" */
export type Transfer_Arr_Rel_Insert_Input = {
  data: Array<Transfer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Transfer_On_Conflict>;
};

/** aggregate avg on columns */
export type Transfer_Avg_Fields = {
  __typename?: 'transfer_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transfer" */
export type Transfer_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transfer". All fields are combined with a logical 'AND'. */
export type Transfer_Bool_Exp = {
  _and?: Maybe<Array<Transfer_Bool_Exp>>;
  _not?: Maybe<Transfer_Bool_Exp>;
  _or?: Maybe<Array<Transfer_Bool_Exp>>;
  adjustment_and_transfers?: Maybe<Adjustment_And_Transfer_Bool_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  from?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoice_and_transfers?: Maybe<Invoice_And_Transfer_Bool_Exp>;
  order_and_transfers?: Maybe<Order_And_Transfer_Bool_Exp>;
  payout_and_transfers?: Maybe<Payout_And_Transfer_Bool_Exp>;
  refund_and_transfers?: Maybe<Refund_And_Transfer_Bool_Exp>;
  subscription_and_transfers?: Maybe<Subscription_And_Transfer_Bool_Exp>;
  to?: Maybe<String_Comparison_Exp>;
  transfer_type?: Maybe<Transfer_Type_Bool_Exp>;
  type?: Maybe<Transfer_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transfer" */
export enum Transfer_Constraint {
  /** unique or primary key constraint */
  TransferPkey = 'transfer_pkey'
}

/** input type for incrementing numeric columns in table "transfer" */
export type Transfer_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "transfer" */
export type Transfer_Insert_Input = {
  adjustment_and_transfers?: Maybe<Adjustment_And_Transfer_Arr_Rel_Insert_Input>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_and_transfers?: Maybe<Invoice_And_Transfer_Arr_Rel_Insert_Input>;
  order_and_transfers?: Maybe<Order_And_Transfer_Arr_Rel_Insert_Input>;
  payout_and_transfers?: Maybe<Payout_And_Transfer_Arr_Rel_Insert_Input>;
  refund_and_transfers?: Maybe<Refund_And_Transfer_Arr_Rel_Insert_Input>;
  subscription_and_transfers?: Maybe<Subscription_And_Transfer_Arr_Rel_Insert_Input>;
  to?: Maybe<Scalars['String']>;
  transfer_type?: Maybe<Transfer_Type_Obj_Rel_Insert_Input>;
  type?: Maybe<Transfer_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transfer_Max_Fields = {
  __typename?: 'transfer_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transfer" */
export type Transfer_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Transfer_Min_Fields = {
  __typename?: 'transfer_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transfer" */
export type Transfer_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "transfer" */
export type Transfer_Mutation_Response = {
  __typename?: 'transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transfer>;
};

/** input type for inserting object relation for remote table "transfer" */
export type Transfer_Obj_Rel_Insert_Input = {
  data: Transfer_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Transfer_On_Conflict>;
};

/** on conflict condition type for table "transfer" */
export type Transfer_On_Conflict = {
  constraint: Transfer_Constraint;
  update_columns?: Array<Transfer_Update_Column>;
  where?: Maybe<Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "transfer". */
export type Transfer_Order_By = {
  adjustment_and_transfers_aggregate?: Maybe<Adjustment_And_Transfer_Aggregate_Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_and_transfers_aggregate?: Maybe<Invoice_And_Transfer_Aggregate_Order_By>;
  order_and_transfers_aggregate?: Maybe<Order_And_Transfer_Aggregate_Order_By>;
  payout_and_transfers_aggregate?: Maybe<Payout_And_Transfer_Aggregate_Order_By>;
  refund_and_transfers_aggregate?: Maybe<Refund_And_Transfer_Aggregate_Order_By>;
  subscription_and_transfers_aggregate?: Maybe<Subscription_And_Transfer_Aggregate_Order_By>;
  to?: Maybe<Order_By>;
  transfer_type?: Maybe<Transfer_Type_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: transfer */
export type Transfer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "transfer" */
export enum Transfer_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "transfer" */
export type Transfer_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  type?: Maybe<Transfer_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transfer_Stddev_Fields = {
  __typename?: 'transfer_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transfer" */
export type Transfer_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transfer_Stddev_Pop_Fields = {
  __typename?: 'transfer_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transfer" */
export type Transfer_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transfer_Stddev_Samp_Fields = {
  __typename?: 'transfer_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transfer" */
export type Transfer_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Transfer_Sum_Fields = {
  __typename?: 'transfer_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "transfer" */
export type Transfer_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "transfer_type" */
export type Transfer_Type = {
  __typename?: 'transfer_type';
  description: Scalars['String'];
  /** An array relationship */
  transfers: Array<Transfer>;
  /** An aggregate relationship */
  transfers_aggregate: Transfer_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "transfer_type" */
export type Transfer_TypeTransfersArgs = {
  distinct_on?: Maybe<Array<Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Order_By>>;
  where?: Maybe<Transfer_Bool_Exp>;
};


/** columns and relationships of "transfer_type" */
export type Transfer_TypeTransfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Transfer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transfer_Order_By>>;
  where?: Maybe<Transfer_Bool_Exp>;
};

/** aggregated selection of "transfer_type" */
export type Transfer_Type_Aggregate = {
  __typename?: 'transfer_type_aggregate';
  aggregate?: Maybe<Transfer_Type_Aggregate_Fields>;
  nodes: Array<Transfer_Type>;
};

/** aggregate fields of "transfer_type" */
export type Transfer_Type_Aggregate_Fields = {
  __typename?: 'transfer_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Transfer_Type_Max_Fields>;
  min?: Maybe<Transfer_Type_Min_Fields>;
};


/** aggregate fields of "transfer_type" */
export type Transfer_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transfer_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "transfer_type". All fields are combined with a logical 'AND'. */
export type Transfer_Type_Bool_Exp = {
  _and?: Maybe<Array<Transfer_Type_Bool_Exp>>;
  _not?: Maybe<Transfer_Type_Bool_Exp>;
  _or?: Maybe<Array<Transfer_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  transfers?: Maybe<Transfer_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "transfer_type" */
export enum Transfer_Type_Constraint {
  /** unique or primary key constraint */
  TransferTypePkey = 'transfer_type_pkey'
}

export enum Transfer_Type_Enum {
  /** 입금 */
  Receive = 'receive',
  /** 출금 */
  Send = 'send'
}

/** Boolean expression to compare columns of type "transfer_type_enum". All fields are combined with logical 'AND'. */
export type Transfer_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Transfer_Type_Enum>;
  _in?: Maybe<Array<Transfer_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Transfer_Type_Enum>;
  _nin?: Maybe<Array<Transfer_Type_Enum>>;
};

/** input type for inserting data into table "transfer_type" */
export type Transfer_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  transfers?: Maybe<Transfer_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Transfer_Type_Max_Fields = {
  __typename?: 'transfer_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transfer_Type_Min_Fields = {
  __typename?: 'transfer_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "transfer_type" */
export type Transfer_Type_Mutation_Response = {
  __typename?: 'transfer_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transfer_Type>;
};

/** input type for inserting object relation for remote table "transfer_type" */
export type Transfer_Type_Obj_Rel_Insert_Input = {
  data: Transfer_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Transfer_Type_On_Conflict>;
};

/** on conflict condition type for table "transfer_type" */
export type Transfer_Type_On_Conflict = {
  constraint: Transfer_Type_Constraint;
  update_columns?: Array<Transfer_Type_Update_Column>;
  where?: Maybe<Transfer_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "transfer_type". */
export type Transfer_Type_Order_By = {
  description?: Maybe<Order_By>;
  transfers_aggregate?: Maybe<Transfer_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: transfer_type */
export type Transfer_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "transfer_type" */
export enum Transfer_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "transfer_type" */
export type Transfer_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "transfer_type" */
export enum Transfer_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "transfer" */
export enum Transfer_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Transfer_Var_Pop_Fields = {
  __typename?: 'transfer_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transfer" */
export type Transfer_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transfer_Var_Samp_Fields = {
  __typename?: 'transfer_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transfer" */
export type Transfer_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Transfer_Variance_Fields = {
  __typename?: 'transfer_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transfer" */
export type Transfer_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "user_actions_history" */
export type User_Actions_History = {
  __typename?: 'user_actions_history';
  actions: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  query_string?: Maybe<Scalars['String']>;
  session: Scalars['uuid'];
  /** An object relationship */
  sessionBySession: Session;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};


/** columns and relationships of "user_actions_history" */
export type User_Actions_HistoryActionsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_actions_history" */
export type User_Actions_History_Aggregate = {
  __typename?: 'user_actions_history_aggregate';
  aggregate?: Maybe<User_Actions_History_Aggregate_Fields>;
  nodes: Array<User_Actions_History>;
};

/** aggregate fields of "user_actions_history" */
export type User_Actions_History_Aggregate_Fields = {
  __typename?: 'user_actions_history_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Actions_History_Max_Fields>;
  min?: Maybe<User_Actions_History_Min_Fields>;
};


/** aggregate fields of "user_actions_history" */
export type User_Actions_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Actions_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_actions_history" */
export type User_Actions_History_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Actions_History_Max_Order_By>;
  min?: Maybe<User_Actions_History_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Actions_History_Append_Input = {
  actions?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_actions_history" */
export type User_Actions_History_Arr_Rel_Insert_Input = {
  data: Array<User_Actions_History_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<User_Actions_History_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_actions_history". All fields are combined with a logical 'AND'. */
export type User_Actions_History_Bool_Exp = {
  _and?: Maybe<Array<User_Actions_History_Bool_Exp>>;
  _not?: Maybe<User_Actions_History_Bool_Exp>;
  _or?: Maybe<Array<User_Actions_History_Bool_Exp>>;
  actions?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  query_string?: Maybe<String_Comparison_Exp>;
  session?: Maybe<Uuid_Comparison_Exp>;
  sessionBySession?: Maybe<Session_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_actions_history" */
export enum User_Actions_History_Constraint {
  /** unique or primary key constraint */
  UserActionsHistoryPkey = 'user_actions_history_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Actions_History_Delete_At_Path_Input = {
  actions?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Actions_History_Delete_Elem_Input = {
  actions?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Actions_History_Delete_Key_Input = {
  actions?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "user_actions_history" */
export type User_Actions_History_Insert_Input = {
  actions?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  query_string?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['uuid']>;
  sessionBySession?: Maybe<Session_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Actions_History_Max_Fields = {
  __typename?: 'user_actions_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  query_string?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_actions_history" */
export type User_Actions_History_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  query_string?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Actions_History_Min_Fields = {
  __typename?: 'user_actions_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  query_string?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_actions_history" */
export type User_Actions_History_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  query_string?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_actions_history" */
export type User_Actions_History_Mutation_Response = {
  __typename?: 'user_actions_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Actions_History>;
};

/** on conflict condition type for table "user_actions_history" */
export type User_Actions_History_On_Conflict = {
  constraint: User_Actions_History_Constraint;
  update_columns?: Array<User_Actions_History_Update_Column>;
  where?: Maybe<User_Actions_History_Bool_Exp>;
};

/** Ordering options when selecting data from "user_actions_history". */
export type User_Actions_History_Order_By = {
  actions?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  query_string?: Maybe<Order_By>;
  session?: Maybe<Order_By>;
  sessionBySession?: Maybe<Session_Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: user_actions_history */
export type User_Actions_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Actions_History_Prepend_Input = {
  actions?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "user_actions_history" */
export enum User_Actions_History_Select_Column {
  /** column name */
  Actions = 'actions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  QueryString = 'query_string',
  /** column name */
  Session = 'session',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "user_actions_history" */
export type User_Actions_History_Set_Input = {
  actions?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  query_string?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "user_actions_history" */
export enum User_Actions_History_Update_Column {
  /** column name */
  Actions = 'actions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  QueryString = 'query_string',
  /** column name */
  Session = 'session',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/**
 * Cumulative page views
 *
 *
 * columns and relationships of "view"
 */
export type View = {
  __typename?: 'view';
  account: Scalars['uuid'];
  /** An array relationship */
  blog_post_and_views: Array<Blog_Post_And_View>;
  /** An aggregate relationship */
  blog_post_and_views_aggregate: Blog_Post_And_View_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/**
 * Cumulative page views
 *
 *
 * columns and relationships of "view"
 */
export type ViewBlog_Post_And_ViewsArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};


/**
 * Cumulative page views
 *
 *
 * columns and relationships of "view"
 */
export type ViewBlog_Post_And_Views_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Post_And_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Post_And_View_Order_By>>;
  where?: Maybe<Blog_Post_And_View_Bool_Exp>;
};

/** aggregated selection of "view" */
export type View_Aggregate = {
  __typename?: 'view_aggregate';
  aggregate?: Maybe<View_Aggregate_Fields>;
  nodes: Array<View>;
};

/** aggregate fields of "view" */
export type View_Aggregate_Fields = {
  __typename?: 'view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<View_Max_Fields>;
  min?: Maybe<View_Min_Fields>;
};


/** aggregate fields of "view" */
export type View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "view". All fields are combined with a logical 'AND'. */
export type View_Bool_Exp = {
  _and?: Maybe<Array<View_Bool_Exp>>;
  _not?: Maybe<View_Bool_Exp>;
  _or?: Maybe<Array<View_Bool_Exp>>;
  account?: Maybe<Uuid_Comparison_Exp>;
  blog_post_and_views?: Maybe<Blog_Post_And_View_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "view" */
export enum View_Constraint {
  /** unique or primary key constraint */
  ViewPkey = 'view_pkey'
}

/** input type for inserting data into table "view" */
export type View_Insert_Input = {
  account?: Maybe<Scalars['uuid']>;
  blog_post_and_views?: Maybe<Blog_Post_And_View_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type View_Max_Fields = {
  __typename?: 'view_max_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type View_Min_Fields = {
  __typename?: 'view_min_fields';
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "view" */
export type View_Mutation_Response = {
  __typename?: 'view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<View>;
};

/** input type for inserting object relation for remote table "view" */
export type View_Obj_Rel_Insert_Input = {
  data: View_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<View_On_Conflict>;
};

/** on conflict condition type for table "view" */
export type View_On_Conflict = {
  constraint: View_Constraint;
  update_columns?: Array<View_Update_Column>;
  where?: Maybe<View_Bool_Exp>;
};

/** Ordering options when selecting data from "view". */
export type View_Order_By = {
  account?: Maybe<Order_By>;
  blog_post_and_views_aggregate?: Maybe<Blog_Post_And_View_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "view_package_store" */
export type View_Package_Store = {
  __typename?: 'view_package_store';
  add_on_product_item: Scalars['uuid'];
  distance?: Maybe<Scalars['float8']>;
  package: Scalars['uuid'];
  /** An object relationship */
  packageByPackage: Package;
  /** An object relationship */
  product_item: Product_Item;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
};

/** aggregated selection of "view_package_store" */
export type View_Package_Store_Aggregate = {
  __typename?: 'view_package_store_aggregate';
  aggregate?: Maybe<View_Package_Store_Aggregate_Fields>;
  nodes: Array<View_Package_Store>;
};

/** aggregate fields of "view_package_store" */
export type View_Package_Store_Aggregate_Fields = {
  __typename?: 'view_package_store_aggregate_fields';
  avg?: Maybe<View_Package_Store_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<View_Package_Store_Max_Fields>;
  min?: Maybe<View_Package_Store_Min_Fields>;
  stddev?: Maybe<View_Package_Store_Stddev_Fields>;
  stddev_pop?: Maybe<View_Package_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View_Package_Store_Stddev_Samp_Fields>;
  sum?: Maybe<View_Package_Store_Sum_Fields>;
  var_pop?: Maybe<View_Package_Store_Var_Pop_Fields>;
  var_samp?: Maybe<View_Package_Store_Var_Samp_Fields>;
  variance?: Maybe<View_Package_Store_Variance_Fields>;
};


/** aggregate fields of "view_package_store" */
export type View_Package_Store_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<View_Package_Store_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_package_store" */
export type View_Package_Store_Aggregate_Order_By = {
  avg?: Maybe<View_Package_Store_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<View_Package_Store_Max_Order_By>;
  min?: Maybe<View_Package_Store_Min_Order_By>;
  stddev?: Maybe<View_Package_Store_Stddev_Order_By>;
  stddev_pop?: Maybe<View_Package_Store_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<View_Package_Store_Stddev_Samp_Order_By>;
  sum?: Maybe<View_Package_Store_Sum_Order_By>;
  var_pop?: Maybe<View_Package_Store_Var_Pop_Order_By>;
  var_samp?: Maybe<View_Package_Store_Var_Samp_Order_By>;
  variance?: Maybe<View_Package_Store_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "view_package_store" */
export type View_Package_Store_Arr_Rel_Insert_Input = {
  data: Array<View_Package_Store_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<View_Package_Store_On_Conflict>;
};

/** aggregate avg on columns */
export type View_Package_Store_Avg_Fields = {
  __typename?: 'view_package_store_avg_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_package_store" */
export type View_Package_Store_Avg_Order_By = {
  distance?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "view_package_store". All fields are combined with a logical 'AND'. */
export type View_Package_Store_Bool_Exp = {
  _and?: Maybe<Array<View_Package_Store_Bool_Exp>>;
  _not?: Maybe<View_Package_Store_Bool_Exp>;
  _or?: Maybe<Array<View_Package_Store_Bool_Exp>>;
  add_on_product_item?: Maybe<Uuid_Comparison_Exp>;
  distance?: Maybe<Float8_Comparison_Exp>;
  package?: Maybe<Uuid_Comparison_Exp>;
  packageByPackage?: Maybe<Package_Bool_Exp>;
  product_item?: Maybe<Product_Item_Bool_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
};

/** unique or primary key constraints on table "view_package_store" */
export enum View_Package_Store_Constraint {
  /** unique or primary key constraint */
  ViewPackageStorePkey = 'view_package_store_pkey'
}

/** input type for incrementing numeric columns in table "view_package_store" */
export type View_Package_Store_Inc_Input = {
  distance?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "view_package_store" */
export type View_Package_Store_Insert_Input = {
  add_on_product_item?: Maybe<Scalars['uuid']>;
  distance?: Maybe<Scalars['float8']>;
  package?: Maybe<Scalars['uuid']>;
  packageByPackage?: Maybe<Package_Obj_Rel_Insert_Input>;
  product_item?: Maybe<Product_Item_Obj_Rel_Insert_Input>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type View_Package_Store_Max_Fields = {
  __typename?: 'view_package_store_max_fields';
  add_on_product_item?: Maybe<Scalars['uuid']>;
  distance?: Maybe<Scalars['float8']>;
  package?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "view_package_store" */
export type View_Package_Store_Max_Order_By = {
  add_on_product_item?: Maybe<Order_By>;
  distance?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type View_Package_Store_Min_Fields = {
  __typename?: 'view_package_store_min_fields';
  add_on_product_item?: Maybe<Scalars['uuid']>;
  distance?: Maybe<Scalars['float8']>;
  package?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "view_package_store" */
export type View_Package_Store_Min_Order_By = {
  add_on_product_item?: Maybe<Order_By>;
  distance?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
};

/** response of any mutation on the table "view_package_store" */
export type View_Package_Store_Mutation_Response = {
  __typename?: 'view_package_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<View_Package_Store>;
};

/** on conflict condition type for table "view_package_store" */
export type View_Package_Store_On_Conflict = {
  constraint: View_Package_Store_Constraint;
  update_columns?: Array<View_Package_Store_Update_Column>;
  where?: Maybe<View_Package_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "view_package_store". */
export type View_Package_Store_Order_By = {
  add_on_product_item?: Maybe<Order_By>;
  distance?: Maybe<Order_By>;
  package?: Maybe<Order_By>;
  packageByPackage?: Maybe<Package_Order_By>;
  product_item?: Maybe<Product_Item_Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
};

/** primary key columns input for table: view_package_store */
export type View_Package_Store_Pk_Columns_Input = {
  package: Scalars['uuid'];
  store: Scalars['uuid'];
};

/** select columns of table "view_package_store" */
export enum View_Package_Store_Select_Column {
  /** column name */
  AddOnProductItem = 'add_on_product_item',
  /** column name */
  Distance = 'distance',
  /** column name */
  Package = 'package',
  /** column name */
  Store = 'store'
}

/** input type for updating data in table "view_package_store" */
export type View_Package_Store_Set_Input = {
  add_on_product_item?: Maybe<Scalars['uuid']>;
  distance?: Maybe<Scalars['float8']>;
  package?: Maybe<Scalars['uuid']>;
  store?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type View_Package_Store_Stddev_Fields = {
  __typename?: 'view_package_store_stddev_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_package_store" */
export type View_Package_Store_Stddev_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type View_Package_Store_Stddev_Pop_Fields = {
  __typename?: 'view_package_store_stddev_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_package_store" */
export type View_Package_Store_Stddev_Pop_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type View_Package_Store_Stddev_Samp_Fields = {
  __typename?: 'view_package_store_stddev_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_package_store" */
export type View_Package_Store_Stddev_Samp_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type View_Package_Store_Sum_Fields = {
  __typename?: 'view_package_store_sum_fields';
  distance?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "view_package_store" */
export type View_Package_Store_Sum_Order_By = {
  distance?: Maybe<Order_By>;
};

/** update columns of table "view_package_store" */
export enum View_Package_Store_Update_Column {
  /** column name */
  AddOnProductItem = 'add_on_product_item',
  /** column name */
  Distance = 'distance',
  /** column name */
  Package = 'package',
  /** column name */
  Store = 'store'
}

/** aggregate var_pop on columns */
export type View_Package_Store_Var_Pop_Fields = {
  __typename?: 'view_package_store_var_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_package_store" */
export type View_Package_Store_Var_Pop_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type View_Package_Store_Var_Samp_Fields = {
  __typename?: 'view_package_store_var_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_package_store" */
export type View_Package_Store_Var_Samp_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type View_Package_Store_Variance_Fields = {
  __typename?: 'view_package_store_variance_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_package_store" */
export type View_Package_Store_Variance_Order_By = {
  distance?: Maybe<Order_By>;
};

/** primary key columns input for table: view */
export type View_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "view" */
export enum View_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "view" */
export type View_Set_Input = {
  account?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "view_store" */
export type View_Store = {
  __typename?: 'view_store';
  distance?: Maybe<Scalars['float8']>;
  store: Scalars['uuid'];
  /** An object relationship */
  storeByStore: Store;
};

/** aggregated selection of "view_store" */
export type View_Store_Aggregate = {
  __typename?: 'view_store_aggregate';
  aggregate?: Maybe<View_Store_Aggregate_Fields>;
  nodes: Array<View_Store>;
};

/** aggregate fields of "view_store" */
export type View_Store_Aggregate_Fields = {
  __typename?: 'view_store_aggregate_fields';
  avg?: Maybe<View_Store_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<View_Store_Max_Fields>;
  min?: Maybe<View_Store_Min_Fields>;
  stddev?: Maybe<View_Store_Stddev_Fields>;
  stddev_pop?: Maybe<View_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View_Store_Stddev_Samp_Fields>;
  sum?: Maybe<View_Store_Sum_Fields>;
  var_pop?: Maybe<View_Store_Var_Pop_Fields>;
  var_samp?: Maybe<View_Store_Var_Samp_Fields>;
  variance?: Maybe<View_Store_Variance_Fields>;
};


/** aggregate fields of "view_store" */
export type View_Store_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<View_Store_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_store" */
export type View_Store_Aggregate_Order_By = {
  avg?: Maybe<View_Store_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<View_Store_Max_Order_By>;
  min?: Maybe<View_Store_Min_Order_By>;
  stddev?: Maybe<View_Store_Stddev_Order_By>;
  stddev_pop?: Maybe<View_Store_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<View_Store_Stddev_Samp_Order_By>;
  sum?: Maybe<View_Store_Sum_Order_By>;
  var_pop?: Maybe<View_Store_Var_Pop_Order_By>;
  var_samp?: Maybe<View_Store_Var_Samp_Order_By>;
  variance?: Maybe<View_Store_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "view_store" */
export type View_Store_Arr_Rel_Insert_Input = {
  data: Array<View_Store_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<View_Store_On_Conflict>;
};

/** aggregate avg on columns */
export type View_Store_Avg_Fields = {
  __typename?: 'view_store_avg_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_store" */
export type View_Store_Avg_Order_By = {
  distance?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "view_store". All fields are combined with a logical 'AND'. */
export type View_Store_Bool_Exp = {
  _and?: Maybe<Array<View_Store_Bool_Exp>>;
  _not?: Maybe<View_Store_Bool_Exp>;
  _or?: Maybe<Array<View_Store_Bool_Exp>>;
  distance?: Maybe<Float8_Comparison_Exp>;
  store?: Maybe<Uuid_Comparison_Exp>;
  storeByStore?: Maybe<Store_Bool_Exp>;
};

/** unique or primary key constraints on table "view_store" */
export enum View_Store_Constraint {
  /** unique or primary key constraint */
  ViewStorePkey = 'view_store_pkey'
}

/** columns and relationships of "view_store_gallery" */
export type View_Store_Gallery = {
  __typename?: 'view_store_gallery';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_created_at: Scalars['timestamptz'];
  gallery_posts_hidden?: Maybe<Scalars['bigint']>;
  gallery_posts_shown?: Maybe<Scalars['bigint']>;
  id: Scalars['uuid'];
  llumar_store_name: Scalars['String'];
  name: Scalars['String'];
  operation_gallery_link: Scalars['String'];
  operation_last_contact: Scalars['timestamptz'];
  operation_memo: Scalars['String'];
  phone_business: Scalars['String'];
  sole?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_store_gallery" */
export type View_Store_Gallery_Aggregate = {
  __typename?: 'view_store_gallery_aggregate';
  aggregate?: Maybe<View_Store_Gallery_Aggregate_Fields>;
  nodes: Array<View_Store_Gallery>;
};

/** aggregate fields of "view_store_gallery" */
export type View_Store_Gallery_Aggregate_Fields = {
  __typename?: 'view_store_gallery_aggregate_fields';
  avg?: Maybe<View_Store_Gallery_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<View_Store_Gallery_Max_Fields>;
  min?: Maybe<View_Store_Gallery_Min_Fields>;
  stddev?: Maybe<View_Store_Gallery_Stddev_Fields>;
  stddev_pop?: Maybe<View_Store_Gallery_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View_Store_Gallery_Stddev_Samp_Fields>;
  sum?: Maybe<View_Store_Gallery_Sum_Fields>;
  var_pop?: Maybe<View_Store_Gallery_Var_Pop_Fields>;
  var_samp?: Maybe<View_Store_Gallery_Var_Samp_Fields>;
  variance?: Maybe<View_Store_Gallery_Variance_Fields>;
};


/** aggregate fields of "view_store_gallery" */
export type View_Store_Gallery_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<View_Store_Gallery_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type View_Store_Gallery_Avg_Fields = {
  __typename?: 'view_store_gallery_avg_fields';
  gallery_posts_hidden?: Maybe<Scalars['Float']>;
  gallery_posts_shown?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "view_store_gallery". All fields are combined with a logical 'AND'. */
export type View_Store_Gallery_Bool_Exp = {
  _and?: Maybe<Array<View_Store_Gallery_Bool_Exp>>;
  _not?: Maybe<View_Store_Gallery_Bool_Exp>;
  _or?: Maybe<Array<View_Store_Gallery_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  gallery_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  gallery_posts_hidden?: Maybe<Bigint_Comparison_Exp>;
  gallery_posts_shown?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  llumar_store_name?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  operation_gallery_link?: Maybe<String_Comparison_Exp>;
  operation_last_contact?: Maybe<Timestamptz_Comparison_Exp>;
  operation_memo?: Maybe<String_Comparison_Exp>;
  phone_business?: Maybe<String_Comparison_Exp>;
  sole?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "view_store_gallery" */
export enum View_Store_Gallery_Constraint {
  /** unique or primary key constraint */
  ViewStoreGalleryPkey = 'view_store_gallery_pkey'
}

/** input type for incrementing numeric columns in table "view_store_gallery" */
export type View_Store_Gallery_Inc_Input = {
  gallery_posts_hidden?: Maybe<Scalars['bigint']>;
  gallery_posts_shown?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "view_store_gallery" */
export type View_Store_Gallery_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_created_at?: Maybe<Scalars['timestamptz']>;
  gallery_posts_hidden?: Maybe<Scalars['bigint']>;
  gallery_posts_shown?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  phone_business?: Maybe<Scalars['String']>;
  sole?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type View_Store_Gallery_Max_Fields = {
  __typename?: 'view_store_gallery_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_created_at?: Maybe<Scalars['timestamptz']>;
  gallery_posts_hidden?: Maybe<Scalars['bigint']>;
  gallery_posts_shown?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  phone_business?: Maybe<Scalars['String']>;
  sole?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Store_Gallery_Min_Fields = {
  __typename?: 'view_store_gallery_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_created_at?: Maybe<Scalars['timestamptz']>;
  gallery_posts_hidden?: Maybe<Scalars['bigint']>;
  gallery_posts_shown?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  phone_business?: Maybe<Scalars['String']>;
  sole?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "view_store_gallery" */
export type View_Store_Gallery_Mutation_Response = {
  __typename?: 'view_store_gallery_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<View_Store_Gallery>;
};

/** on conflict condition type for table "view_store_gallery" */
export type View_Store_Gallery_On_Conflict = {
  constraint: View_Store_Gallery_Constraint;
  update_columns?: Array<View_Store_Gallery_Update_Column>;
  where?: Maybe<View_Store_Gallery_Bool_Exp>;
};

/** Ordering options when selecting data from "view_store_gallery". */
export type View_Store_Gallery_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gallery_created_at?: Maybe<Order_By>;
  gallery_posts_hidden?: Maybe<Order_By>;
  gallery_posts_shown?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  llumar_store_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  operation_gallery_link?: Maybe<Order_By>;
  operation_last_contact?: Maybe<Order_By>;
  operation_memo?: Maybe<Order_By>;
  phone_business?: Maybe<Order_By>;
  sole?: Maybe<Order_By>;
};

/** primary key columns input for table: view_store_gallery */
export type View_Store_Gallery_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "view_store_gallery" */
export enum View_Store_Gallery_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GalleryCreatedAt = 'gallery_created_at',
  /** column name */
  GalleryPostsHidden = 'gallery_posts_hidden',
  /** column name */
  GalleryPostsShown = 'gallery_posts_shown',
  /** column name */
  Id = 'id',
  /** column name */
  LlumarStoreName = 'llumar_store_name',
  /** column name */
  Name = 'name',
  /** column name */
  OperationGalleryLink = 'operation_gallery_link',
  /** column name */
  OperationLastContact = 'operation_last_contact',
  /** column name */
  OperationMemo = 'operation_memo',
  /** column name */
  PhoneBusiness = 'phone_business',
  /** column name */
  Sole = 'sole'
}

/** input type for updating data in table "view_store_gallery" */
export type View_Store_Gallery_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_created_at?: Maybe<Scalars['timestamptz']>;
  gallery_posts_hidden?: Maybe<Scalars['bigint']>;
  gallery_posts_shown?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  llumar_store_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operation_gallery_link?: Maybe<Scalars['String']>;
  operation_last_contact?: Maybe<Scalars['timestamptz']>;
  operation_memo?: Maybe<Scalars['String']>;
  phone_business?: Maybe<Scalars['String']>;
  sole?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type View_Store_Gallery_Stddev_Fields = {
  __typename?: 'view_store_gallery_stddev_fields';
  gallery_posts_hidden?: Maybe<Scalars['Float']>;
  gallery_posts_shown?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type View_Store_Gallery_Stddev_Pop_Fields = {
  __typename?: 'view_store_gallery_stddev_pop_fields';
  gallery_posts_hidden?: Maybe<Scalars['Float']>;
  gallery_posts_shown?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type View_Store_Gallery_Stddev_Samp_Fields = {
  __typename?: 'view_store_gallery_stddev_samp_fields';
  gallery_posts_hidden?: Maybe<Scalars['Float']>;
  gallery_posts_shown?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type View_Store_Gallery_Sum_Fields = {
  __typename?: 'view_store_gallery_sum_fields';
  gallery_posts_hidden?: Maybe<Scalars['bigint']>;
  gallery_posts_shown?: Maybe<Scalars['bigint']>;
};

/** update columns of table "view_store_gallery" */
export enum View_Store_Gallery_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GalleryCreatedAt = 'gallery_created_at',
  /** column name */
  GalleryPostsHidden = 'gallery_posts_hidden',
  /** column name */
  GalleryPostsShown = 'gallery_posts_shown',
  /** column name */
  Id = 'id',
  /** column name */
  LlumarStoreName = 'llumar_store_name',
  /** column name */
  Name = 'name',
  /** column name */
  OperationGalleryLink = 'operation_gallery_link',
  /** column name */
  OperationLastContact = 'operation_last_contact',
  /** column name */
  OperationMemo = 'operation_memo',
  /** column name */
  PhoneBusiness = 'phone_business',
  /** column name */
  Sole = 'sole'
}

/** aggregate var_pop on columns */
export type View_Store_Gallery_Var_Pop_Fields = {
  __typename?: 'view_store_gallery_var_pop_fields';
  gallery_posts_hidden?: Maybe<Scalars['Float']>;
  gallery_posts_shown?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type View_Store_Gallery_Var_Samp_Fields = {
  __typename?: 'view_store_gallery_var_samp_fields';
  gallery_posts_hidden?: Maybe<Scalars['Float']>;
  gallery_posts_shown?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type View_Store_Gallery_Variance_Fields = {
  __typename?: 'view_store_gallery_variance_fields';
  gallery_posts_hidden?: Maybe<Scalars['Float']>;
  gallery_posts_shown?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "view_store" */
export type View_Store_Inc_Input = {
  distance?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "view_store" */
export type View_Store_Insert_Input = {
  distance?: Maybe<Scalars['float8']>;
  store?: Maybe<Scalars['uuid']>;
  storeByStore?: Maybe<Store_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type View_Store_Max_Fields = {
  __typename?: 'view_store_max_fields';
  distance?: Maybe<Scalars['float8']>;
  store?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "view_store" */
export type View_Store_Max_Order_By = {
  distance?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type View_Store_Min_Fields = {
  __typename?: 'view_store_min_fields';
  distance?: Maybe<Scalars['float8']>;
  store?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "view_store" */
export type View_Store_Min_Order_By = {
  distance?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
};

/** response of any mutation on the table "view_store" */
export type View_Store_Mutation_Response = {
  __typename?: 'view_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<View_Store>;
};

/** on conflict condition type for table "view_store" */
export type View_Store_On_Conflict = {
  constraint: View_Store_Constraint;
  update_columns?: Array<View_Store_Update_Column>;
  where?: Maybe<View_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "view_store". */
export type View_Store_Order_By = {
  distance?: Maybe<Order_By>;
  store?: Maybe<Order_By>;
  storeByStore?: Maybe<Store_Order_By>;
};

/** primary key columns input for table: view_store */
export type View_Store_Pk_Columns_Input = {
  store: Scalars['uuid'];
};

/** select columns of table "view_store" */
export enum View_Store_Select_Column {
  /** column name */
  Distance = 'distance',
  /** column name */
  Store = 'store'
}

/** input type for updating data in table "view_store" */
export type View_Store_Set_Input = {
  distance?: Maybe<Scalars['float8']>;
  store?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type View_Store_Stddev_Fields = {
  __typename?: 'view_store_stddev_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_store" */
export type View_Store_Stddev_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type View_Store_Stddev_Pop_Fields = {
  __typename?: 'view_store_stddev_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_store" */
export type View_Store_Stddev_Pop_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type View_Store_Stddev_Samp_Fields = {
  __typename?: 'view_store_stddev_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_store" */
export type View_Store_Stddev_Samp_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type View_Store_Sum_Fields = {
  __typename?: 'view_store_sum_fields';
  distance?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "view_store" */
export type View_Store_Sum_Order_By = {
  distance?: Maybe<Order_By>;
};

/** update columns of table "view_store" */
export enum View_Store_Update_Column {
  /** column name */
  Distance = 'distance',
  /** column name */
  Store = 'store'
}

/** aggregate var_pop on columns */
export type View_Store_Var_Pop_Fields = {
  __typename?: 'view_store_var_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_store" */
export type View_Store_Var_Pop_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type View_Store_Var_Samp_Fields = {
  __typename?: 'view_store_var_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_store" */
export type View_Store_Var_Samp_Order_By = {
  distance?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type View_Store_Variance_Fields = {
  __typename?: 'view_store_variance_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_store" */
export type View_Store_Variance_Order_By = {
  distance?: Maybe<Order_By>;
};

/** update columns of table "view" */
export enum View_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Get_Lead_With_UtmQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Lead_With_UtmQuery = (
  { __typename?: 'query_root' }
  & { lead: Array<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id' | 'key' | 'created_at' | 'utm' | 'status' | 'created_from'>
  )> }
);

export type Get_Accounts_PayableQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Accounts_PayableQuery = (
  { __typename?: 'query_root' }
  & { accounts_payable: Array<(
    { __typename?: 'accounts_payable' }
    & Pick<Accounts_Payable, 'id' | 'created_at' | 'updated_at'>
    & { accounts_payable_and_payouts: Array<(
      { __typename?: 'accounts_payable_and_payout' }
      & { payoutByPayout: (
        { __typename?: 'payout' }
        & Pick<Payout, 'order' | 'status' | 'finished_at' | 'reverted_at'>
        & { storeByStore: (
          { __typename?: 'store' }
          & Pick<Store, 'name'>
        ), orderByOrder: (
          { __typename?: 'order' }
          & Pick<Order, 'price_payout' | 'name' | 'email' | 'commission_rate'>
        ) }
      ) }
    )>, accounts_payable_and_refunds: Array<(
      { __typename?: 'accounts_payable_and_refund' }
      & { refundByRefund: (
        { __typename?: 'refund' }
        & Pick<Refund, 'order' | 'status'>
        & { orderByOrder: (
          { __typename?: 'order' }
          & Pick<Order, 'price_payout' | 'email' | 'name'>
        ), transactionByTransaction?: Maybe<(
          { __typename?: 'transaction' }
          & Pick<Transaction, 'stripe_receipt_url'>
        )> }
      ) }
    )>, accounts_payable_and_adjustments: Array<(
      { __typename?: 'accounts_payable_and_adjustment' }
      & { adjustmentByAdjustment?: Maybe<(
        { __typename?: 'adjustment' }
        & Pick<Adjustment, 'order' | 'status' | 'finished_at' | 'reverted_at'>
        & { orderByOrder: (
          { __typename?: 'order' }
          & Pick<Order, 'price_payout' | 'email' | 'name'>
        ), storeByStore: (
          { __typename?: 'store' }
          & Pick<Store, 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type Update_Accounts_Payable_And_PayoutMutationVariables = Exact<{
  accountPayableId: Scalars['uuid'];
  timeStamp: Scalars['timestamptz'];
}>;


export type Update_Accounts_Payable_And_PayoutMutation = (
  { __typename?: 'mutation_root' }
  & { update_accounts_payable?: Maybe<(
    { __typename?: 'accounts_payable_mutation_response' }
    & Pick<Accounts_Payable_Mutation_Response, 'affected_rows'>
  )>, update_accounts_payable_and_payout?: Maybe<(
    { __typename?: 'accounts_payable_and_payout_mutation_response' }
    & Pick<Accounts_Payable_And_Payout_Mutation_Response, 'affected_rows'>
  )>, update_payout?: Maybe<(
    { __typename?: 'payout_mutation_response' }
    & Pick<Payout_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_Accounts_Payable_And_AdjustmentMutationVariables = Exact<{
  accountPayableId: Scalars['uuid'];
  timeStamp: Scalars['timestamptz'];
}>;


export type Update_Accounts_Payable_And_AdjustmentMutation = (
  { __typename?: 'mutation_root' }
  & { update_accounts_payable?: Maybe<(
    { __typename?: 'accounts_payable_mutation_response' }
    & Pick<Accounts_Payable_Mutation_Response, 'affected_rows'>
  )>, update_accounts_payable_and_adjustment?: Maybe<(
    { __typename?: 'accounts_payable_and_adjustment_mutation_response' }
    & Pick<Accounts_Payable_And_Adjustment_Mutation_Response, 'affected_rows'>
  )>, update_adjustment?: Maybe<(
    { __typename?: 'adjustment_mutation_response' }
    & Pick<Adjustment_Mutation_Response, 'affected_rows'>
  )> }
);

export type Revert_Accounts_Payable_And_PayoutMutationVariables = Exact<{
  accountPayableId: Scalars['uuid'];
  timeStamp: Scalars['timestamptz'];
}>;


export type Revert_Accounts_Payable_And_PayoutMutation = (
  { __typename?: 'mutation_root' }
  & { update_accounts_payable?: Maybe<(
    { __typename?: 'accounts_payable_mutation_response' }
    & Pick<Accounts_Payable_Mutation_Response, 'affected_rows'>
  )>, update_accounts_payable_and_payout?: Maybe<(
    { __typename?: 'accounts_payable_and_payout_mutation_response' }
    & Pick<Accounts_Payable_And_Payout_Mutation_Response, 'affected_rows'>
  )>, update_payout?: Maybe<(
    { __typename?: 'payout_mutation_response' }
    & Pick<Payout_Mutation_Response, 'affected_rows'>
  )> }
);

export type Revert_Accounts_Payable_And_AdjustmentMutationVariables = Exact<{
  accountPayableId: Scalars['uuid'];
  timeStamp: Scalars['timestamptz'];
}>;


export type Revert_Accounts_Payable_And_AdjustmentMutation = (
  { __typename?: 'mutation_root' }
  & { update_accounts_payable?: Maybe<(
    { __typename?: 'accounts_payable_mutation_response' }
    & Pick<Accounts_Payable_Mutation_Response, 'affected_rows'>
  )>, update_accounts_payable_and_adjustment?: Maybe<(
    { __typename?: 'accounts_payable_and_adjustment_mutation_response' }
    & Pick<Accounts_Payable_And_Adjustment_Mutation_Response, 'affected_rows'>
  )>, update_adjustment?: Maybe<(
    { __typename?: 'adjustment_mutation_response' }
    & Pick<Adjustment_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Accounts_ReceivableQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Accounts_ReceivableQuery = (
  { __typename?: 'query_root' }
  & { accounts_receivable: Array<(
    { __typename?: 'accounts_receivable' }
    & Pick<Accounts_Receivable, 'id' | 'created_at' | 'updated_at'>
    & { accounts_receivable_and_memberships: Array<(
      { __typename?: 'accounts_receivable_and_membership' }
      & { membershipByMembership: (
        { __typename?: 'membership' }
        & Pick<Membership, 'price' | 'type'>
        & { storeByStore: (
          { __typename?: 'store' }
          & Pick<Store, 'name'>
        ) }
      ) }
    )>, accounts_receivable_and_invoices: Array<(
      { __typename?: 'accounts_receivable_and_invoice' }
      & { invoiceByInvoice: (
        { __typename?: 'invoice' }
        & Pick<Invoice, 'price' | 'status'>
        & { storeByStore: (
          { __typename?: 'store' }
          & Pick<Store, 'name'>
        ) }
      ) }
    )> }
  )> }
);

export type Get_MajorsQueryVariables = Exact<{
  sessionFilter?: Maybe<Uuid_Comparison_Exp>;
}>;


export type Get_MajorsQuery = (
  { __typename?: 'query_root' }
  & { session: Array<(
    { __typename?: 'session' }
    & Pick<Session, 'id'>
    & { activities: Array<(
      { __typename?: 'activity' }
      & Pick<Activity, 'action' | 'context' | 'rank'>
    )> }
  )> }
);

export type Get_CarsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_CarsQuery = (
  { __typename?: 'query_root' }
  & { cars: Array<(
    { __typename?: 'car' }
    & Pick<Car, 'id' | 'model' | 'row' | 'type' | 'prefix' | 'postfix' | 'year' | 'maker' | 'llumar_car_type'>
    & { car_maker: (
      { __typename?: 'car_maker' }
      & Pick<Car_Maker, 'name_kr' | 'name_us' | 'origin'>
      & { car_origin: (
        { __typename?: 'car_origin' }
        & Pick<Car_Origin, 'name_kr' | 'name_us'>
      ) }
    ), car_type: (
      { __typename?: 'car_type' }
      & Pick<Car_Type, 'value' | 'description'>
    ) }
  )> }
);

export type Get_CarQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type Get_CarQuery = (
  { __typename?: 'query_root' }
  & { car: Array<(
    { __typename?: 'car' }
    & Pick<Car, 'id' | 'model' | 'row' | 'type' | 'prefix' | 'postfix' | 'year' | 'maker' | 'llumar_car_type'>
  )> }
);

export type Get_Car_Makers_And_Car_TypesQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type Get_Car_Makers_And_Car_TypesQuery = (
  { __typename?: 'query_root' }
  & { car_maker: Array<(
    { __typename?: 'car_maker' }
    & Pick<Car_Maker, 'id' | 'name_kr'>
  )>, car_type: Array<(
    { __typename?: 'car_type' }
    & Pick<Car_Type, 'value' | 'description'>
  )> }
);

export type Update_CarMutationVariables = Exact<{
  carObject: Array<Car_Insert_Input> | Car_Insert_Input;
}>;


export type Update_CarMutation = (
  { __typename?: 'mutation_root' }
  & { insert_car?: Maybe<(
    { __typename?: 'car_mutation_response' }
    & Pick<Car_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_CarMutationVariables = Exact<{
  carObject: Array<Car_Insert_Input> | Car_Insert_Input;
}>;


export type Insert_CarMutation = (
  { __typename?: 'mutation_root' }
  & { insert_car?: Maybe<(
    { __typename?: 'car_mutation_response' }
    & { returning: Array<(
      { __typename?: 'car' }
      & Pick<Car, 'id'>
    )> }
  )> }
);

export type Get_Car_MakerQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Car_MakerQuery = (
  { __typename?: 'query_root' }
  & { car_makers: Array<(
    { __typename?: 'car_maker' }
    & Pick<Car_Maker, 'id' | 'name_kr' | 'name_us' | 'origin'>
    & { car_origin: (
      { __typename?: 'car_origin' }
      & Pick<Car_Origin, 'name_kr' | 'name_us'>
    ) }
  )> }
);

export type Get_Car_Maker_DetailQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type Get_Car_Maker_DetailQuery = (
  { __typename?: 'query_root' }
  & { car_maker: Array<(
    { __typename?: 'car_maker' }
    & Pick<Car_Maker, 'id' | 'name_kr' | 'name_us' | 'origin'>
    & { car_origin: (
      { __typename?: 'car_origin' }
      & Pick<Car_Origin, 'name_kr' | 'name_us'>
    ) }
  )> }
);

export type Get_Car_OriginQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Car_OriginQuery = (
  { __typename?: 'query_root' }
  & { car_origin: Array<(
    { __typename?: 'car_origin' }
    & Pick<Car_Origin, 'id' | 'name_kr' | 'name_us'>
  )> }
);

export type Update_Car_MakerMutationVariables = Exact<{
  carMakerId: Scalars['uuid'];
  newKrBrand?: Maybe<Scalars['String']>;
  newUsBrand?: Maybe<Scalars['String']>;
  newOrigin?: Maybe<Scalars['uuid']>;
}>;


export type Update_Car_MakerMutation = (
  { __typename?: 'mutation_root' }
  & { update_car_maker_by_pk?: Maybe<(
    { __typename?: 'car_maker' }
    & Pick<Car_Maker, 'name_kr' | 'name_us' | 'origin'>
  )> }
);

export type Insert_Car_MakerMutationVariables = Exact<{
  newKrBrand?: Maybe<Scalars['String']>;
  newUsBrand?: Maybe<Scalars['String']>;
  newOrigin?: Maybe<Scalars['uuid']>;
}>;


export type Insert_Car_MakerMutation = (
  { __typename?: 'mutation_root' }
  & { insert_car_maker_one?: Maybe<(
    { __typename?: 'car_maker' }
    & Pick<Car_Maker, 'id'>
  )> }
);

export type _Check_Cart_ExistenceQueryVariables = Exact<{ [key: string]: never; }>;


export type _Check_Cart_ExistenceQuery = (
  { __typename?: 'query_root' }
  & { n_cart: Array<(
    { __typename?: 'n_cart' }
    & Pick<N_Cart, 'id'>
  )> }
);

export type _Create_CartMutationVariables = Exact<{
  cartObject: N_Cart_Insert_Input;
}>;


export type _Create_CartMutation = (
  { __typename?: 'mutation_root' }
  & { insert_n_cart_one?: Maybe<(
    { __typename?: 'n_cart' }
    & Pick<N_Cart, 'id'>
  )> }
);

export type _Get_CartQueryVariables = Exact<{
  cartId: Scalars['uuid'];
}>;


export type _Get_CartQuery = (
  { __typename?: 'query_root' }
  & { n_cart_by_pk?: Maybe<(
    { __typename?: 'n_cart' }
    & Pick<N_Cart, 'id' | 'session' | 'account'>
    & { cart_items: Array<(
      { __typename?: 'cart_item' }
      & Pick<Cart_Item, 'id' | 'created_at' | 'updated_at' | 'car' | 'car_sunroof_type' | 'checked' | 'commission_rate' | 'date_time' | 'deleted_at' | 'price_discount' | 'price_extra' | 'price_final' | 'price_payout' | 'price_payout_without_vat' | 'price_product' | 'product_item' | 'remark' | 'store' | 'updated_by'>
      & { carByCar?: Maybe<(
        { __typename?: 'car' }
        & Pick<Car, 'model'>
        & { car_maker: (
          { __typename?: 'car_maker' }
          & Pick<Car_Maker, 'name_kr'>
        ) }
      )>, storeByStore?: Maybe<(
        { __typename?: 'store' }
        & Pick<Store, 'name' | 'phone_business'>
      )>, package_detail?: Maybe<Array<(
        { __typename?: 'package' }
        & Pick<Package, 'name'>
        & { package_type: (
          { __typename?: 'package_type' }
          & Pick<Package_Type, 'description'>
        ) }
      )>>, product_item_detail?: Maybe<Array<(
        { __typename?: 'product_item' }
        & Pick<Product_Item, 'attribute' | 'price'>
        & { productByProduct: (
          { __typename?: 'product' }
          & Pick<Product, 'name'>
          & { product_brand: (
            { __typename?: 'product_brand' }
            & Pick<Product_Brand, 'description'>
          ), product_type: (
            { __typename?: 'product_type' }
            & Pick<Product_Type, 'value' | 'description'>
          ) }
        ) }
      )>> }
    )> }
  )> }
);

export type _Remove_Cart_Item_From_CartMutationVariables = Exact<{
  deletedAt: Scalars['timestamptz'];
}>;


export type _Remove_Cart_Item_From_CartMutation = (
  { __typename?: 'mutation_root' }
  & { update_cart_item?: Maybe<(
    { __typename?: 'cart_item_mutation_response' }
    & Pick<Cart_Item_Mutation_Response, 'affected_rows'>
  )> }
);

export type _Get_Cart_ItemQueryVariables = Exact<{
  cartItemId: Scalars['uuid'];
}>;


export type _Get_Cart_ItemQuery = (
  { __typename?: 'query_root' }
  & { cart_item_by_pk?: Maybe<(
    { __typename?: 'cart_item' }
    & Pick<Cart_Item, 'id' | 'created_at' | 'updated_at' | 'car' | 'car_sunroof_type' | 'checked' | 'commission_rate' | 'date_time' | 'deleted_at' | 'price_discount' | 'price_extra' | 'price_final' | 'price_payout' | 'price_payout_without_vat' | 'price_product' | 'product_item' | 'remark' | 'store' | 'updated_by'>
    & { carByCar?: Maybe<(
      { __typename?: 'car' }
      & Pick<Car, 'id' | 'model' | 'type'>
      & { car_maker: (
        { __typename?: 'car_maker' }
        & Pick<Car_Maker, 'name_kr' | 'name_us'>
        & { car_origin: (
          { __typename?: 'car_origin' }
          & Pick<Car_Origin, 'name_kr' | 'name_us'>
        ) }
      ) }
    )>, storeByStore?: Maybe<(
      { __typename?: 'store' }
      & Pick<Store, 'name'>
    )>, package_detail?: Maybe<Array<(
      { __typename?: 'package' }
      & Pick<Package, 'id' | 'name'>
      & { package_type: (
        { __typename?: 'package_type' }
        & Pick<Package_Type, 'description'>
      ) }
    )>>, product_item_detail?: Maybe<Array<(
      { __typename?: 'product_item' }
      & Pick<Product_Item, 'id' | 'attribute' | 'price'>
      & { productByProduct: (
        { __typename?: 'product' }
        & Pick<Product, 'name'>
        & { product_brand: (
          { __typename?: 'product_brand' }
          & Pick<Product_Brand, 'description'>
        ), product_type: (
          { __typename?: 'product_type' }
          & Pick<Product_Type, 'value' | 'description'>
        ) }
      ) }
    )>> }
  )> }
);

export type _Update_Cart_Item_To_CartMutationVariables = Exact<{
  cartItemObject: Cart_Item_Insert_Input;
}>;


export type _Update_Cart_Item_To_CartMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cart_item_one?: Maybe<(
    { __typename?: 'cart_item' }
    & Pick<Cart_Item, 'id'>
  )> }
);

export type _Add_Cart_Item_To_CartMutationVariables = Exact<{
  cartItemObject: Cart_Item_Insert_Input;
}>;


export type _Add_Cart_Item_To_CartMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cart_item_one?: Maybe<(
    { __typename?: 'cart_item' }
    & Pick<Cart_Item, 'id'>
  )> }
);

export type All_Product_And_Product_ItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type All_Product_And_Product_ItemsQuery = (
  { __typename?: 'query_root' }
  & { product: Array<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'name'>
    & { product_brand: (
      { __typename?: 'product_brand' }
      & Pick<Product_Brand, 'value' | 'description'>
    ), product_type: (
      { __typename?: 'product_type' }
      & Pick<Product_Type, 'value' | 'description'>
    ) }
  )>, product_type: Array<(
    { __typename?: 'product_type' }
    & Pick<Product_Type, 'value' | 'description'>
  )>, product_item: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku'>
  )> }
);

export type Get_CurationQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_CurationQuery = (
  { __typename?: 'query_root' }
  & { curation: Array<(
    { __typename?: 'curation' }
    & Pick<Curation, 'id' | 'list'>
  )> }
);

export type Get_BlogQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_BlogQuery = (
  { __typename?: 'query_root' }
  & { gallery: Array<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
    & { blogPostByBlogPost?: Maybe<(
      { __typename?: 'blog_post' }
      & Pick<Blog_Post, 'title' | 'id'>
    )> }
  )>, store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'name' | 'id'>
  )> }
);

export type Update_CurationMutationVariables = Exact<{
  id: Scalars['uuid'];
  account: Scalars['uuid'];
  list: Scalars['jsonb'];
}>;


export type Update_CurationMutation = (
  { __typename?: 'mutation_root' }
  & { update_curation?: Maybe<(
    { __typename?: 'curation_mutation_response' }
    & Pick<Curation_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Account_Performance_For_ReservationQueryVariables = Exact<{
  start_date?: Maybe<Scalars['timestamp']>;
  end_date?: Maybe<Scalars['timestamp']>;
}>;


export type Get_Account_Performance_For_ReservationQuery = (
  { __typename?: 'query_root' }
  & { get_account_performance_for_reservation: Array<(
    { __typename?: 'account_performance' }
    & Pick<Account_Performance, 'account' | 'count' | 'email' | 'name' | 'role' | 'status'>
  )> }
);

export type Get_Account_Performance_For_First_CallQueryVariables = Exact<{
  start_date?: Maybe<Scalars['timestamp']>;
  end_date?: Maybe<Scalars['timestamp']>;
}>;


export type Get_Account_Performance_For_First_CallQuery = (
  { __typename?: 'query_root' }
  & { get_account_performance_for_first_call: Array<(
    { __typename?: 'account_performance' }
    & Pick<Account_Performance, 'account' | 'count' | 'email' | 'name' | 'role' | 'status'>
  )> }
);

export type Get_Account_Performance_By_Used_Car_StatusQueryVariables = Exact<{
  start_date?: Maybe<Scalars['timestamp']>;
  end_date?: Maybe<Scalars['timestamp']>;
}>;


export type Get_Account_Performance_By_Used_Car_StatusQuery = (
  { __typename?: 'query_root' }
  & { get_account_performance_by_used_car_status: Array<(
    { __typename?: 'account_performance' }
    & Pick<Account_Performance, 'account' | 'count' | 'email' | 'name' | 'role' | 'status' | 'used_car_status'>
  )> }
);

export type _Get_OrderQueryVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type _Get_OrderQuery = (
  { __typename?: 'query_root' }
  & { order_by_pk?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id' | 'created_at' | 'updated_at' | 'account' | 'name' | 'email' | 'phone' | 'status' | 'car' | 'car_sunroof_type' | 'commission_rate' | 'date_time' | 'deleted_at' | 'price_discount' | 'price_extra' | 'price_final' | 'price_payout' | 'price_payout_without_vat' | 'price_product' | 'product_item' | 'remark' | 'store'>
    & { carByCar?: Maybe<(
      { __typename?: 'car' }
      & Pick<Car, 'id' | 'model' | 'type'>
      & { car_maker: (
        { __typename?: 'car_maker' }
        & Pick<Car_Maker, 'name_kr' | 'name_us'>
        & { car_origin: (
          { __typename?: 'car_origin' }
          & Pick<Car_Origin, 'name_kr' | 'name_us'>
        ) }
      ) }
    )>, storeByStore: (
      { __typename?: 'store' }
      & Pick<Store, 'name'>
    ), package_detail?: Maybe<Array<(
      { __typename?: 'package' }
      & Pick<Package, 'id' | 'name'>
      & { package_type: (
        { __typename?: 'package_type' }
        & Pick<Package_Type, 'description'>
      ) }
    )>>, product_item_detail?: Maybe<Array<(
      { __typename?: 'product_item' }
      & Pick<Product_Item, 'id' | 'attribute' | 'price'>
      & { productByProduct: (
        { __typename?: 'product' }
        & Pick<Product, 'name'>
        & { product_brand: (
          { __typename?: 'product_brand' }
          & Pick<Product_Brand, 'description'>
        ), product_type: (
          { __typename?: 'product_type' }
          & Pick<Product_Type, 'value' | 'description'>
        ) }
      ) }
    )>> }
  )> }
);

export type _Update_OrderMutationVariables = Exact<{
  orderObject: Order_Insert_Input;
}>;


export type _Update_OrderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_order_one?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id'>
  )> }
);

export type Get_GalleryQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  keyword: Scalars['String'];
}>;


export type Get_GalleryQuery = (
  { __typename?: 'query_root' }
  & { gallery: Array<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id' | 'created_at' | 'deleted_at' | 'status' | 'store' | 'price' | 'sent_at'>
    & { carByCar?: Maybe<(
      { __typename?: 'car' }
      & Pick<Car, 'model'>
      & { car_maker: (
        { __typename?: 'car_maker' }
        & Pick<Car_Maker, 'name_kr'>
        & { car_origin: (
          { __typename?: 'car_origin' }
          & Pick<Car_Origin, 'name_kr'>
        ) }
      ) }
    )>, storeByStore?: Maybe<(
      { __typename?: 'store' }
      & Pick<Store, 'name' | 'phone_business'>
    )>, blogPostByBlogPost?: Maybe<(
      { __typename?: 'blog_post' }
      & Pick<Blog_Post, 'title'>
    )> }
  )>, gallerys_count: (
    { __typename?: 'gallery_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'gallery_aggregate_fields' }
      & Pick<Gallery_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Delete_GalleryMutationVariables = Exact<{
  id: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Delete_GalleryMutation = (
  { __typename?: 'mutation_root' }
  & { update_gallery_by_pk?: Maybe<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
  )> }
);

export type Update_Gallery_Sent_AtMutationVariables = Exact<{
  id: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Update_Gallery_Sent_AtMutation = (
  { __typename?: 'mutation_root' }
  & { update_gallery_by_pk?: Maybe<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
  )> }
);

export type Insert_GalleryMutationVariables = Exact<{
  object: Gallery_Insert_Input;
}>;


export type Insert_GalleryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_gallery_one?: Maybe<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
  )> }
);

export type Update_GalleryMutationVariables = Exact<{
  galleryId: Scalars['uuid'];
  blogId: Scalars['uuid'];
  galleryObject?: Maybe<Gallery_Set_Input>;
  blogObject?: Maybe<Blog_Post_Set_Input>;
}>;


export type Update_GalleryMutation = (
  { __typename?: 'mutation_root' }
  & { update_gallery_by_pk?: Maybe<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
  )>, update_blog_post_by_pk?: Maybe<(
    { __typename?: 'blog_post' }
    & Pick<Blog_Post, 'id'>
  )> }
);

export type Hide_GalleryMutationVariables = Exact<{
  id: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Hide_GalleryMutation = (
  { __typename?: 'mutation_root' }
  & { update_gallery_by_pk?: Maybe<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
  )> }
);

export type Show_GalleryMutationVariables = Exact<{
  id: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Show_GalleryMutation = (
  { __typename?: 'mutation_root' }
  & { update_gallery_by_pk?: Maybe<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
  )> }
);

export type Get_Gallery_ProductQueryVariables = Exact<{
  carOrigin?: Maybe<Scalars['String']>;
  carType?: Maybe<Scalars['String']>;
}>;


export type Get_Gallery_ProductQuery = (
  { __typename?: 'query_root' }
  & { product_front: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )>, product_sideback: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )> }
);

export type Get_Gallery_Detail_InfoQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Gallery_Detail_InfoQuery = (
  { __typename?: 'query_root' }
  & { car_maker: Array<(
    { __typename?: 'car_maker' }
    & Pick<Car_Maker, 'name_kr'>
  )>, car: Array<(
    { __typename?: 'car' }
    & Pick<Car, 'id' | 'model'>
    & { car_maker: (
      { __typename?: 'car_maker' }
      & Pick<Car_Maker, 'name_kr'>
      & { car_origin: (
        { __typename?: 'car_origin' }
        & Pick<Car_Origin, 'name_kr'>
      ) }
    ), car_type: (
      { __typename?: 'car_type' }
      & Pick<Car_Type, 'value'>
    ) }
  )>, store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'name'>
  )>, carSunroofType: Array<(
    { __typename?: 'car_sunroof_type' }
    & Pick<Car_Sunroof_Type, 'value' | 'description'>
  )>, product_front: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )>, product_sideback: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )>, product_sunroof: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )> }
);

export type Get_Gallery_Car_ModelQueryVariables = Exact<{
  carMaker?: Maybe<Scalars['String']>;
}>;


export type Get_Gallery_Car_ModelQuery = (
  { __typename?: 'query_root' }
  & { car: Array<(
    { __typename?: 'car' }
    & Pick<Car, 'id' | 'model'>
    & { car_maker: (
      { __typename?: 'car_maker' }
      & Pick<Car_Maker, 'name_kr'>
      & { car_origin: (
        { __typename?: 'car_origin' }
        & Pick<Car_Origin, 'name_kr'>
      ) }
    ), car_type: (
      { __typename?: 'car_type' }
      & Pick<Car_Type, 'value'>
    ) }
  )> }
);

export type Get_Gallery_DetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Gallery_DetailQuery = (
  { __typename?: 'query_root' }
  & { car_maker: Array<(
    { __typename?: 'car_maker' }
    & Pick<Car_Maker, 'name_kr'>
  )>, gallery: Array<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id' | 'created_at' | 'status' | 'product_item' | 'price' | 'car_sunroof_type'>
    & { carByCar?: Maybe<(
      { __typename?: 'car' }
      & Pick<Car, 'id' | 'type' | 'model' | 'row' | 'prefix' | 'postfix'>
      & { car_maker: (
        { __typename?: 'car_maker' }
        & Pick<Car_Maker, 'name_kr'>
        & { car_origin: (
          { __typename?: 'car_origin' }
          & Pick<Car_Origin, 'name_kr'>
        ) }
      ) }
    )>, storeByStore?: Maybe<(
      { __typename?: 'store' }
      & Pick<Store, 'id' | 'name' | 'description' | 'location' | 'attribute' | 'address_detail'>
      & { addressSigunguByAddressSigungu?: Maybe<(
        { __typename?: 'address_sigungu' }
        & Pick<Address_Sigungu, 'name'>
        & { address_sido: (
          { __typename?: 'address_sido' }
          & Pick<Address_Sido, 'name'>
        ) }
      )>, store_and_hashtags: Array<(
        { __typename?: 'store_and_hashtag' }
        & { hashtagByHashtag: (
          { __typename?: 'hashtag' }
          & Pick<Hashtag, 'name'>
        ) }
      )> }
    )>, blogPostByBlogPost?: Maybe<(
      { __typename?: 'blog_post' }
      & Pick<Blog_Post, 'id' | 'title' | 'content' | 'image' | 'posting' | 'thumbnail'>
    )> }
  )>, car: Array<(
    { __typename?: 'car' }
    & Pick<Car, 'id' | 'model'>
    & { car_maker: (
      { __typename?: 'car_maker' }
      & Pick<Car_Maker, 'name_kr'>
      & { car_origin: (
        { __typename?: 'car_origin' }
        & Pick<Car_Origin, 'name_kr'>
      ) }
    ), car_type: (
      { __typename?: 'car_type' }
      & Pick<Car_Type, 'value'>
    ) }
  )>, store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'name'>
  )>, carSunroofType: Array<(
    { __typename?: 'car_sunroof_type' }
    & Pick<Car_Sunroof_Type, 'value' | 'description'>
  )>, product_front: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )>, product_sideback: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )>, product_sunroof: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )> }
);

export type Search_Store_By_NameQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
}>;


export type Search_Store_By_NameQuery = (
  { __typename?: 'query_root' }
  & { stores: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'name'>
  )> }
);

export type Get_Gallery_By_StoreQueryVariables = Exact<{
  pageSize: Scalars['Int'];
  pageOffset: Scalars['Int'];
}>;


export type Get_Gallery_By_StoreQuery = (
  { __typename?: 'query_root' }
  & { store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'created_at' | 'id' | 'name' | 'llumar_store_name' | 'phone_business' | 'operation_memo' | 'operation_last_contact' | 'operation_gallery_link'>
    & { galleries_aggregate: (
      { __typename?: 'gallery_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'gallery_aggregate_fields' }
        & { max?: Maybe<(
          { __typename?: 'gallery_max_fields' }
          & Pick<Gallery_Max_Fields, 'created_at'>
        )> }
      )> }
    ), galleriesByShown: (
      { __typename?: 'gallery_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'gallery_aggregate_fields' }
        & Pick<Gallery_Aggregate_Fields, 'count'>
        & { max?: Maybe<(
          { __typename?: 'gallery_max_fields' }
          & Pick<Gallery_Max_Fields, 'created_at'>
        )> }
      )> }
    ), galleriesByHidden: (
      { __typename?: 'gallery_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'gallery_aggregate_fields' }
        & Pick<Gallery_Aggregate_Fields, 'count'>
      )> }
    ), galleriesByHumanShown: (
      { __typename?: 'gallery_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'gallery_aggregate_fields' }
        & Pick<Gallery_Aggregate_Fields, 'count'>
        & { max?: Maybe<(
          { __typename?: 'gallery_max_fields' }
          & Pick<Gallery_Max_Fields, 'created_at'>
        )> }
      )> }
    ), galleriesByHumanHidden: (
      { __typename?: 'gallery_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'gallery_aggregate_fields' }
        & Pick<Gallery_Aggregate_Fields, 'count'>
      )> }
    ), store_and_soles: Array<(
      { __typename?: 'store_and_sole' }
      & { soleBySole: (
        { __typename?: 'sole' }
        & Pick<Sole, 'id'>
      ) }
    )> }
  )>, store_aggregate: (
    { __typename?: 'store_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'store_aggregate_fields' }
      & Pick<Store_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Get_Store_And_SolesQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Store_And_SolesQuery = (
  { __typename?: 'query_root' }
  & { store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'name' | 'llumar_store_name' | 'id'>
  )>, sole: Array<(
    { __typename?: 'sole' }
    & Pick<Sole, 'name' | 'id'>
  )> }
);

export type Update_Store_OperationMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Scalars['timestamptz'];
}>;


export type Update_Store_OperationMutation = (
  { __typename?: 'mutation_root' }
  & { update_store?: Maybe<(
    { __typename?: 'store_mutation_response' }
    & Pick<Store_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_Store_Op_MemoMutationVariables = Exact<{
  id: Scalars['uuid'];
  memo: Scalars['String'];
}>;


export type Update_Store_Op_MemoMutation = (
  { __typename?: 'mutation_root' }
  & { update_store?: Maybe<(
    { __typename?: 'store_mutation_response' }
    & Pick<Store_Mutation_Response, 'affected_rows'>
  )> }
);

export type Upsert_Store_SoleMutationVariables = Exact<{
  id: Scalars['uuid'];
  sole: Scalars['uuid'];
}>;


export type Upsert_Store_SoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_store_and_sole?: Maybe<(
    { __typename?: 'store_and_sole_mutation_response' }
    & Pick<Store_And_Sole_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Store_Op_DataQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Store_Op_DataQuery = (
  { __typename?: 'query_root' }
  & { store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'name' | 'operation_memo' | 'operation_last_contact' | 'operation_gallery_link'>
  )> }
);

export type Update_Store_Op_Data_MemoMutationVariables = Exact<{
  id: Scalars['uuid'];
  memo?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  link?: Maybe<Scalars['String']>;
}>;


export type Update_Store_Op_Data_MemoMutation = (
  { __typename?: 'mutation_root' }
  & { update_store?: Maybe<(
    { __typename?: 'store_mutation_response' }
    & Pick<Store_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_HashtagQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_HashtagQuery = (
  { __typename?: 'query_root' }
  & { hashtags: Array<(
    { __typename?: 'hashtag' }
    & Pick<Hashtag, 'id' | 'name' | 'account'>
  )> }
);

export type Insert_HashtagMutationVariables = Exact<{
  newHashtagObject: Array<Hashtag_Insert_Input> | Hashtag_Insert_Input;
}>;


export type Insert_HashtagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_hashtag?: Maybe<(
    { __typename?: 'hashtag_mutation_response' }
    & Pick<Hashtag_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_HashtagMutationVariables = Exact<{
  id: Scalars['uuid'];
  newHashtagName: Scalars['String'];
}>;


export type Update_HashtagMutation = (
  { __typename?: 'mutation_root' }
  & { update_hashtag?: Maybe<(
    { __typename?: 'hashtag_mutation_response' }
    & Pick<Hashtag_Mutation_Response, 'affected_rows'>
  )> }
);

export type Delete_HashtagMutationVariables = Exact<{
  id: Scalars['uuid'];
  deletedAt: Scalars['timestamptz'];
}>;


export type Delete_HashtagMutation = (
  { __typename?: 'mutation_root' }
  & { update_hashtag?: Maybe<(
    { __typename?: 'hashtag_mutation_response' }
    & Pick<Hashtag_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Product_Items_With_SkuQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Product_Items_With_SkuQuery = (
  { __typename?: 'query_root' }
  & { productItems: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'attribute' | 'price' | 'sku'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'type' | 'attribute'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ) }
    ) }
  )> }
);

export type Get_OrdersQueryVariables = Exact<{
  page: Scalars['Int'];
  rowsPerPage: Scalars['Int'];
}>;


export type Get_OrdersQuery = (
  { __typename?: 'query_root' }
  & { order: Array<(
    { __typename?: 'order' }
    & Pick<Order, 'id' | 'status' | 'created_at' | 'account' | 'is_777_order' | 'product_item' | 'car' | 'car_sunroof_type' | 'price_product' | 'price_final' | 'price_discount' | 'price_extra' | 'date_time' | 'date_time_option' | 'name' | 'email' | 'phone' | 'remark' | 'paid_at' | 'finished_at' | 'payment_link_sent_at' | 'refunded_at' | 'installed_at' | 'price_payout' | 'price_payout_without_vat'>
    & { accountByAccount: (
      { __typename?: 'account' }
      & Pick<Account, 'created_at'>
      & { profile_kr_customer?: Maybe<(
        { __typename?: 'profile_kr_customer' }
        & Pick<Profile_Kr_Customer, 'stripe_customer_id' | 'rapyd_customer_id'>
      )> }
    ), leadByLead?: Maybe<(
      { __typename?: 'lead' }
      & Pick<Lead, 'id' | 'status'>
      & { accountBySupportAssignee?: Maybe<(
        { __typename?: 'account' }
        & { profile_riderdashes: Array<(
          { __typename?: 'profile_riderdash' }
          & Pick<Profile_Riderdash, 'name'>
        )> }
      )>, accountByShopAssignee?: Maybe<(
        { __typename?: 'account' }
        & { profile_riderdashes: Array<(
          { __typename?: 'profile_riderdash' }
          & Pick<Profile_Riderdash, 'name'>
        )> }
      )>, chats: Array<(
        { __typename?: 'chat' }
        & Pick<Chat, 'id'>
        & { accountByChatAssignee?: Maybe<(
          { __typename?: 'account' }
          & { profile_riderdashes: Array<(
            { __typename?: 'profile_riderdash' }
            & Pick<Profile_Riderdash, 'name'>
          )> }
        )> }
      )> }
    )>, package_detail?: Maybe<Array<(
      { __typename?: 'package' }
      & Pick<Package, 'name'>
      & { package_type: (
        { __typename?: 'package_type' }
        & Pick<Package_Type, 'description'>
      ) }
    )>>, product_item_detail?: Maybe<Array<(
      { __typename?: 'product_item' }
      & Pick<Product_Item, 'attribute' | 'price'>
      & { productByProduct: (
        { __typename?: 'product' }
        & Pick<Product, 'name'>
        & { product_brand: (
          { __typename?: 'product_brand' }
          & Pick<Product_Brand, 'description'>
        ), product_type: (
          { __typename?: 'product_type' }
          & Pick<Product_Type, 'value' | 'description'>
        ) }
      ) }
    )>>, carByCar?: Maybe<(
      { __typename?: 'car' }
      & Pick<Car, 'model'>
      & { car_maker: (
        { __typename?: 'car_maker' }
        & Pick<Car_Maker, 'name_kr'>
      ) }
    )>, carSunroofTypeByCarSunroofType?: Maybe<(
      { __typename?: 'car_sunroof_type' }
      & Pick<Car_Sunroof_Type, 'description'>
    )>, order_status: (
      { __typename?: 'order_status' }
      & Pick<Order_Status, 'value' | 'description'>
    ), storeByStore: (
      { __typename?: 'store' }
      & Pick<Store, 'id' | 'name' | 'address_detail' | 'location' | 'phone_business'>
      & { addressSigunguByAddressSigungu?: Maybe<(
        { __typename?: 'address_sigungu' }
        & Pick<Address_Sigungu, 'name'>
        & { address_sido: (
          { __typename?: 'address_sido' }
          & Pick<Address_Sido, 'name'>
        ) }
      )> }
    ), order_and_transactions: Array<(
      { __typename?: 'order_and_transaction' }
      & Pick<Order_And_Transaction, 'transaction'>
      & { transactionByTransaction: (
        { __typename?: 'transaction' }
        & Pick<Transaction, 'stripe_payment_intent_id' | 'rapyd_payment_intent_id' | 'bootpay_payment_intent_id'>
      ) }
    )> }
  )>, order_aggregate: (
    { __typename?: 'order_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'order_aggregate_fields' }
      & Pick<Order_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Get_All_StoresQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_All_StoresQuery = (
  { __typename?: 'query_root' }
  & { store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'name'>
  )> }
);

export type Get_Order_ProductQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Order_ProductQuery = (
  { __typename?: 'query_root' }
  & { order: Array<(
    { __typename?: 'order' }
    & Pick<Order, 'product_item'>
  )> }
);

export type Get_Package_DetailQueryVariables = Exact<{
  newCarPackageId: Scalars['uuid'];
}>;


export type Get_Package_DetailQuery = (
  { __typename?: 'query_root' }
  & { package: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'alias' | 'image' | 'status' | 'price_product' | 'price_discount' | 'attribute'>
    & { productByAddOnProduct?: Maybe<(
      { __typename?: 'product' }
      & { product_items: Array<(
        { __typename?: 'product_item' }
        & Pick<Product_Item, 'id' | 'attribute' | 'price'>
      )> }
    )> }
  )> }
);

export type Get_Order_Product_ItemQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Order_Product_ItemQuery = (
  { __typename?: 'query_root' }
  & { product_item: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'attribute' | 'price'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ) }
    ) }
  )> }
);

export type Update_OrderMutationVariables = Exact<{
  id: Scalars['uuid'];
  status: Order_Status_Enum;
  accountsPayableAndPayOutObject: Accounts_Payable_And_Payout_Insert_Input;
  date: Scalars['timestamptz'];
}>;


export type Update_OrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_order?: Maybe<(
    { __typename?: 'order_mutation_response' }
    & Pick<Order_Mutation_Response, 'affected_rows'>
  )>, insert_accounts_payable_and_payout_one?: Maybe<(
    { __typename?: 'accounts_payable_and_payout' }
    & Pick<Accounts_Payable_And_Payout, 'accounts_payable' | 'payout'>
  )> }
);

export type Update_Order_ScheduleMutationVariables = Exact<{
  id: Scalars['uuid'];
  date: Scalars['timestamptz'];
}>;


export type Update_Order_ScheduleMutation = (
  { __typename?: 'mutation_root' }
  & { update_order?: Maybe<(
    { __typename?: 'order_mutation_response' }
    & Pick<Order_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_Order_StatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  date: Scalars['timestamptz'];
}>;


export type Update_Order_StatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_order?: Maybe<(
    { __typename?: 'order_mutation_response' }
    & Pick<Order_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Order_DetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Order_DetailQuery = (
  { __typename?: 'query_root' }
  & { order: Array<(
    { __typename?: 'order' }
    & Pick<Order, 'id' | 'car' | 'product_item' | 'price_discount' | 'price_product' | 'price_final' | 'date_time' | 'price_extra' | 'commission_rate' | 'remark' | 'name' | 'email' | 'phone'>
    & { accountByAccount: (
      { __typename?: 'account' }
      & Pick<Account, 'id'>
    ), carByCar?: Maybe<(
      { __typename?: 'car' }
      & Pick<Car, 'model' | 'prefix' | 'postfix'>
      & { car_maker: (
        { __typename?: 'car_maker' }
        & Pick<Car_Maker, 'name_kr'>
        & { car_origin: (
          { __typename?: 'car_origin' }
          & Pick<Car_Origin, 'name_kr'>
        ) }
      ), car_type: (
        { __typename?: 'car_type' }
        & Pick<Car_Type, 'value' | 'description'>
      ) }
    )>, carSunroofTypeByCarSunroofType?: Maybe<(
      { __typename?: 'car_sunroof_type' }
      & Pick<Car_Sunroof_Type, 'value' | 'description'>
    )>, order_status: (
      { __typename?: 'order_status' }
      & Pick<Order_Status, 'value' | 'description'>
    ), storeByStore: (
      { __typename?: 'store' }
      & Pick<Store, 'id' | 'address_detail' | 'name'>
    ) }
  )>, packages: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name'>
  )> }
);

export type Update_Order_ContactMutationVariables = Exact<{
  id: Scalars['uuid'];
  newOrderDetail?: Maybe<Order_Set_Input>;
}>;


export type Update_Order_ContactMutation = (
  { __typename?: 'mutation_root' }
  & { update_order?: Maybe<(
    { __typename?: 'order_mutation_response' }
    & Pick<Order_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_All_SidoQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_All_SidoQuery = (
  { __typename?: 'query_root' }
  & { address_sido: Array<(
    { __typename?: 'address_sido' }
    & Pick<Address_Sido, 'name'>
    & { address_sigungus: Array<(
      { __typename?: 'address_sigungu' }
      & Pick<Address_Sigungu, 'name'>
    )> }
  )> }
);

export type Get_Order_PricesQueryVariables = Exact<{
  startDate?: Maybe<Scalars['timestamptz']>;
  sido?: Maybe<Scalars['String']>;
  sigungu?: Maybe<Scalars['String']>;
}>;


export type Get_Order_PricesQuery = (
  { __typename?: 'query_root' }
  & { order: Array<(
    { __typename?: 'order' }
    & Pick<Order, 'price_discount' | 'price_extra' | 'price_final' | 'price_product' | 'status' | 'date_time' | 'confirmed_at' | 'finished_at' | 'canceled_at' | 'refunded_at'>
  )> }
);

export type _Get_Package_ContentsQueryVariables = Exact<{
  packageId: Scalars['uuid'];
}>;


export type _Get_Package_ContentsQuery = (
  { __typename?: 'query_root' }
  & { package: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'price_discount' | 'price_payout' | 'price_product' | 'product'>
  )>, car_type: Array<(
    { __typename?: 'car_type' }
    & Pick<Car_Type, 'value' | 'description'>
  )>, car_origin: Array<(
    { __typename?: 'car_origin' }
    & Pick<Car_Origin, 'name_kr' | 'name_us'>
  )> }
);

export type _Update_Package_By_PkMutationVariables = Exact<{
  id: Scalars['uuid'];
  priceDiscount?: Maybe<Scalars['jsonb']>;
  pricePayout?: Maybe<Scalars['jsonb']>;
  priceProduct?: Maybe<Scalars['jsonb']>;
  product?: Maybe<Scalars['jsonb']>;
}>;


export type _Update_Package_By_PkMutation = (
  { __typename?: 'mutation_root' }
  & { update_package_by_pk?: Maybe<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'price_discount' | 'price_payout' | 'price_product' | 'product'>
  )> }
);

export type Get_Account_ListQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Account_ListQuery = (
  { __typename?: 'query_root' }
  & { accountContent: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'id' | 'created_at'>
    & { profile_kr_partner?: Maybe<(
      { __typename?: 'profile_kr_partner' }
      & Pick<Profile_Kr_Partner, 'id' | 'name' | 'email'>
    )>, profile_riderdash?: Maybe<(
      { __typename?: 'profile_riderdash' }
      & Pick<Profile_Riderdash, 'id'>
    )> }
  )> }
);

export type Insert_Profile_Riderdash_ParttimerMutationVariables = Exact<{
  object: Profile_Riderdash_Insert_Input;
}>;


export type Insert_Profile_Riderdash_ParttimerMutation = (
  { __typename?: 'mutation_root' }
  & { insert_profile_riderdash_one?: Maybe<(
    { __typename?: 'profile_riderdash' }
    & Pick<Profile_Riderdash, 'id'>
  )> }
);

export type Get_ProductQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_ProductQuery = (
  { __typename?: 'query_root' }
  & { products: Array<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'status' | 'name' | 'type' | 'attribute' | 'brand' | 'image'>
    & { product_brand: (
      { __typename?: 'product_brand' }
      & Pick<Product_Brand, 'description'>
    ), product_items: Array<(
      { __typename?: 'product_item' }
      & Pick<Product_Item, 'sku' | 'price'>
    )> }
  )> }
);

export type Get_Product_BrandQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Product_BrandQuery = (
  { __typename?: 'query_root' }
  & { product_brand: Array<(
    { __typename?: 'product_brand' }
    & Pick<Product_Brand, 'value' | 'description'>
  )> }
);

export type Insert_Product_BrandMutationVariables = Exact<{
  productBrandObject: Product_Brand_Insert_Input;
}>;


export type Insert_Product_BrandMutation = (
  { __typename?: 'mutation_root' }
  & { insert_product_brand_one?: Maybe<(
    { __typename?: 'product_brand' }
    & Pick<Product_Brand, 'value'>
  )> }
);

export type Get_Product_By_PkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Product_By_PkQuery = (
  { __typename?: 'query_root' }
  & { product_by_pk?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'name' | 'type' | 'brand' | 'description' | 'status' | 'alias' | 'account'>
  )> }
);

export type Upsert_ProductMutationVariables = Exact<{
  productObject: Product_Insert_Input;
}>;


export type Upsert_ProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_product_one?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id'>
  )> }
);

export type Get_Product_ItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Product_ItemsQuery = (
  { __typename?: 'query_root' }
  & { productItems: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price' | 'commission_rate'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'name' | 'brand' | 'type'>
    ) }
  )> }
);

export type Get_Product_Item_DetailQueryVariables = Exact<{
  productItemId: Scalars['uuid'];
}>;


export type Get_Product_Item_DetailQuery = (
  { __typename?: 'query_root' }
  & { product_item: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price' | 'commission_rate'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'name' | 'brand' | 'type'>
    ) }
  )> }
);

export type Update_Product_Item_DetailMutationVariables = Exact<{
  productItemId: Scalars['uuid'];
  price: Scalars['numeric'];
  commissionRate: Scalars['numeric'];
}>;


export type Update_Product_Item_DetailMutation = (
  { __typename?: 'mutation_root' }
  & { update_product_item?: Maybe<(
    { __typename?: 'product_item_mutation_response' }
    & Pick<Product_Item_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Product_Items_By_Product_IdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Product_Items_By_Product_IdQuery = (
  { __typename?: 'query_root' }
  & { product_by_pk?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'type'>
    & { product_items: Array<(
      { __typename?: 'product_item' }
      & Pick<Product_Item, 'id' | 'attribute' | 'product' | 'price' | 'sku' | 'commission_rate'>
      & { productByProduct: (
        { __typename?: 'product' }
        & Pick<Product, 'name'>
        & { product_brand: (
          { __typename?: 'product_brand' }
          & Pick<Product_Brand, 'description'>
        ), product_type: (
          { __typename?: 'product_type' }
          & Pick<Product_Type, 'value' | 'description'>
        ) }
      ) }
    )> }
  )> }
);

export type Upsert_Product_ItemsMutationVariables = Exact<{
  productObject: Array<Product_Item_Insert_Input> | Product_Item_Insert_Input;
}>;


export type Upsert_Product_ItemsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_product_item?: Maybe<(
    { __typename?: 'product_item_mutation_response' }
    & Pick<Product_Item_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Product_TypeQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Product_TypeQuery = (
  { __typename?: 'query_root' }
  & { product_type: Array<(
    { __typename?: 'product_type' }
    & Pick<Product_Type, 'value' | 'description'>
  )> }
);

export type Insert_Product_TypeMutationVariables = Exact<{
  productTypeObject: Product_Type_Insert_Input;
}>;


export type Insert_Product_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_product_type_one?: Maybe<(
    { __typename?: 'product_type' }
    & Pick<Product_Type, 'value'>
  )> }
);

export type Get_Profile_CustomerQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
}>;


export type Get_Profile_CustomerQuery = (
  { __typename?: 'query_root' }
  & { profile_customer: Array<(
    { __typename?: 'profile_kr_customer' }
    & Pick<Profile_Kr_Customer, 'id' | 'name' | 'email' | 'created_at' | 'phone_mobile' | 'account'>
    & { accountByAccount: (
      { __typename?: 'account' }
      & { account_type?: Maybe<(
        { __typename?: 'account_type' }
        & Pick<Account_Type, 'value'>
      )> }
    ) }
  )> }
);

export type Get_Profile_Customer_DetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Profile_Customer_DetailQuery = (
  { __typename?: 'query_root' }
  & { profile_customer: Array<(
    { __typename?: 'profile_kr_customer' }
    & Pick<Profile_Kr_Customer, 'created_at' | 'name' | 'email' | 'email_verified_at' | 'phone_mobile' | 'birthday' | 'account'>
    & { accountByAccount: (
      { __typename?: 'account' }
      & Pick<Account, 'firebase_user_uid'>
      & { account_type?: Maybe<(
        { __typename?: 'account_type' }
        & Pick<Account_Type, 'value'>
      )>, orders: Array<(
        { __typename?: 'order' }
        & Pick<Order, 'id' | 'status' | 'product_item' | 'car_sunroof_type' | 'price_final' | 'date_time'>
        & { carSunroofTypeByCarSunroofType?: Maybe<(
          { __typename?: 'car_sunroof_type' }
          & Pick<Car_Sunroof_Type, 'description'>
        )>, order_status: (
          { __typename?: 'order_status' }
          & Pick<Order_Status, 'value' | 'description'>
        ), storeByStore: (
          { __typename?: 'store' }
          & Pick<Store, 'name'>
        ) }
      )>, sessions: Array<(
        { __typename?: 'session' }
        & Pick<Session, 'id'>
        & { activities: Array<(
          { __typename?: 'activity' }
          & Pick<Activity, 'action' | 'context' | 'rank'>
        )> }
      )> }
    ) }
  )> }
);

export type Get_Partner_ProfilesQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type Get_Partner_ProfilesQuery = (
  { __typename?: 'query_root' }
  & { profile_kr_partner: Array<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'id'>
  )> }
);

export type Delete_Customer_AccountMutationVariables = Exact<{
  profileId: Scalars['uuid'];
  accountId: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Delete_Customer_AccountMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile_kr_customer_by_pk?: Maybe<(
    { __typename?: 'profile_kr_customer' }
    & Pick<Profile_Kr_Customer, 'id'>
  )>, update_account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'id'>
  )> }
);

export type Delete_Customer_And_Partner_AccountMutationVariables = Exact<{
  profileId: Scalars['uuid'];
  partnerProfileId: Scalars['uuid'];
  accountId: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Delete_Customer_And_Partner_AccountMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile_kr_customer_by_pk?: Maybe<(
    { __typename?: 'profile_kr_customer' }
    & Pick<Profile_Kr_Customer, 'id'>
  )>, update_profile_kr_partner_by_pk?: Maybe<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'id'>
  )>, update_account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'id'>
  )> }
);

export type Update_Customer_Name_Phone_NumberMutationVariables = Exact<{
  profileId: Scalars['uuid'];
  newName?: Maybe<Scalars['String']>;
  newPhoneNumber?: Maybe<Scalars['String']>;
}>;


export type Update_Customer_Name_Phone_NumberMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile_kr_customer_by_pk?: Maybe<(
    { __typename?: 'profile_kr_customer' }
    & Pick<Profile_Kr_Customer, 'name' | 'phone_mobile'>
  )> }
);

export type Get_Profile_SearchQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchKeyword?: Maybe<Scalars['String']>;
}>;


export type Get_Profile_SearchQuery = (
  { __typename?: 'query_root' }
  & { profile_partner: Array<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'id' | 'created_at' | 'name' | 'email' | 'phone_mobile' | 'account'>
    & { accountByAccount: (
      { __typename?: 'account' }
      & { account_type?: Maybe<(
        { __typename?: 'account_type' }
        & Pick<Account_Type, 'value'>
      )>, store_and_accounts: Array<(
        { __typename?: 'store_and_account' }
        & { storeByStore: (
          { __typename?: 'store' }
          & Pick<Store, 'email' | 'phone_business' | 'name'>
        ) }
      )> }
    ) }
  )>, profile_partner_count: (
    { __typename?: 'profile_kr_partner_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'profile_kr_partner_aggregate_fields' }
      & Pick<Profile_Kr_Partner_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Get_Profile_Partner_DetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Profile_Partner_DetailQuery = (
  { __typename?: 'query_root' }
  & { profile_partner: Array<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'created_at' | 'name' | 'email' | 'email_verified_at' | 'phone_mobile' | 'stripe_customer_id' | 'rapyd_customer_id' | 'account'>
    & { accountByAccount: (
      { __typename?: 'account' }
      & Pick<Account, 'firebase_user_uid'>
      & { account_type?: Maybe<(
        { __typename?: 'account_type' }
        & Pick<Account_Type, 'value'>
      )>, subscriptions: Array<(
        { __typename?: 'subscription' }
        & { storeByStore: (
          { __typename?: 'store' }
          & Pick<Store, 'id' | 'email' | 'phone_business' | 'name'>
        ) }
      )> }
    ) }
  )> }
);

export type Get_Customer_ProfileQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type Get_Customer_ProfileQuery = (
  { __typename?: 'query_root' }
  & { profile_kr_customer: Array<(
    { __typename?: 'profile_kr_customer' }
    & Pick<Profile_Kr_Customer, 'id'>
  )> }
);

export type Delete_Partner_AccountMutationVariables = Exact<{
  profileId: Scalars['uuid'];
  accountId: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Delete_Partner_AccountMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile_kr_partner_by_pk?: Maybe<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'id'>
  )>, update_account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'id'>
  )> }
);

export type Delete_Partner_And_Customer_AccountMutationVariables = Exact<{
  profileId: Scalars['uuid'];
  customerProfileId: Scalars['uuid'];
  accountId: Scalars['uuid'];
  now?: Maybe<Scalars['timestamptz']>;
}>;


export type Delete_Partner_And_Customer_AccountMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile_kr_partner_by_pk?: Maybe<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'id'>
  )>, update_profile_kr_customer_by_pk?: Maybe<(
    { __typename?: 'profile_kr_customer' }
    & Pick<Profile_Kr_Customer, 'id'>
  )>, update_account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'id'>
  )> }
);

export type Update_Profile_PartnerMutationVariables = Exact<{
  account: Scalars['uuid'];
  newEmail: Scalars['String'];
  newName: Scalars['String'];
  phoneMobile: Scalars['String'];
}>;


export type Update_Profile_PartnerMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile_kr_partner?: Maybe<(
    { __typename?: 'profile_kr_partner_mutation_response' }
    & Pick<Profile_Kr_Partner_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_All_ProfileMutationVariables = Exact<{
  account: Scalars['uuid'];
  newEmail: Scalars['String'];
  newName: Scalars['String'];
  phoneMobile: Scalars['String'];
}>;


export type Update_All_ProfileMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile_kr_partner?: Maybe<(
    { __typename?: 'profile_kr_partner_mutation_response' }
    & Pick<Profile_Kr_Partner_Mutation_Response, 'affected_rows'>
  )>, update_profile_kr_customer?: Maybe<(
    { __typename?: 'profile_kr_customer_mutation_response' }
    & Pick<Profile_Kr_Customer_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Quote_RequestQueryVariables = Exact<{
  offset: Scalars['Int'];
  startDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Order_By>;
}>;


export type Get_Quote_RequestQuery = (
  { __typename?: 'query_root' }
  & { quote_request: Array<(
    { __typename?: 'quote_request' }
    & Pick<Quote_Request, 'id' | 'created_at' | 'session' | 'account' | 'content' | 'sent_at' | 'phone_number'>
  )>, request_count: (
    { __typename?: 'quote_request_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'quote_request_aggregate_fields' }
      & Pick<Quote_Request_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Update_Quote_RequestMutationVariables = Exact<{
  phoneNumber?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
}>;


export type Update_Quote_RequestMutation = (
  { __typename?: 'mutation_root' }
  & { update_quote_request?: Maybe<(
    { __typename?: 'quote_request_mutation_response' }
    & Pick<Quote_Request_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_All_StoreQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_All_StoreQuery = (
  { __typename?: 'query_root' }
  & { store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'name' | 'llumar_store_name'>
  )> }
);

export type Get_ReviewQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_ReviewQuery = (
  { __typename?: 'query_root' }
  & { review: Array<(
    { __typename?: 'review' }
    & Pick<Review, 'id' | 'created_at' | 'status' | 'comment' | 'rating'>
    & { review_status: (
      { __typename?: 'review_status' }
      & Pick<Review_Status, 'description'>
    ), store_and_reviews: Array<(
      { __typename?: 'store_and_review' }
      & { storeByStore: (
        { __typename?: 'store' }
        & Pick<Store, 'name'>
      ) }
    )>, order_and_reviews: Array<(
      { __typename?: 'order_and_review' }
      & { orderByOrder: (
        { __typename?: 'order' }
        & Pick<Order, 'phone'>
        & { leadByLead?: Maybe<(
          { __typename?: 'lead' }
          & Pick<Lead, 'phone'>
        )> }
      ) }
    )> }
  )> }
);

export type Update_Review_StatusMutationVariables = Exact<{
  reviewId: Scalars['uuid'];
  status: Review_Status_Enum;
}>;


export type Update_Review_StatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_review?: Maybe<(
    { __typename?: 'review_mutation_response' }
    & Pick<Review_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_Select_Review_StatusMutationVariables = Exact<{
  status: Review_Status_Enum;
}>;


export type Update_Select_Review_StatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_review?: Maybe<(
    { __typename?: 'review_mutation_response' }
    & Pick<Review_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Page_IdQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Page_IdQuery = (
  { __typename?: 'query_root' }
  & { gallery: Array<(
    { __typename?: 'gallery' }
    & Pick<Gallery, 'id'>
  )>, store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id'>
  )>, product: Array<(
    { __typename?: 'product' }
    & Pick<Product, 'id'>
  )> }
);

export type Get_Store_SearchQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type Get_Store_SearchQuery = (
  { __typename?: 'query_root' }
  & { stores: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'status' | 'id' | 'name' | 'email' | 'llumar_store_name' | 'address_detail'>
    & { addressSigunguByAddressSigungu?: Maybe<(
      { __typename?: 'address_sigungu' }
      & Pick<Address_Sigungu, 'id' | 'name'>
      & { address_sido: (
        { __typename?: 'address_sido' }
        & Pick<Address_Sido, 'name'>
      ) }
    )>, membership?: Maybe<(
      { __typename?: 'membership' }
      & Pick<Membership, 'id' | 'store' | 'type' | 'start_date' | 'end_date' | 'price'>
      & { membership_type: (
        { __typename?: 'membership_type' }
        & Pick<Membership_Type, 'value'>
      ) }
    )>, subscriptions: Array<(
      { __typename?: 'subscription' }
      & Pick<Subscription, 'status'>
    )> }
  )>, stores_count: (
    { __typename?: 'store_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'store_aggregate_fields' }
      & Pick<Store_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type Delete_StoreMutationVariables = Exact<{
  id: Scalars['uuid'];
  deletedAt: Scalars['timestamptz'];
}>;


export type Delete_StoreMutation = (
  { __typename?: 'mutation_root' }
  & { update_store?: Maybe<(
    { __typename?: 'store_mutation_response' }
    & Pick<Store_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_PackagesQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_PackagesQuery = (
  { __typename?: 'query_root' }
  & { normalPackages: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'alias' | 'image' | 'status' | 'price_product' | 'price_discount' | 'price_payout' | 'attribute'>
  )>, bestPackages: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'alias' | 'image' | 'status' | 'price_product' | 'price_discount' | 'price_payout' | 'attribute'>
  )>, excellentPackages: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'alias' | 'image' | 'status' | 'price_product' | 'price_discount' | 'price_payout' | 'attribute'>
  )>, economicPackages: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'alias' | 'image' | 'status' | 'price_product' | 'price_discount' | 'price_payout' | 'attribute'>
  )>, teslaPacakges: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'alias' | 'image' | 'status' | 'price_product' | 'price_discount' | 'price_payout' | 'attribute'>
  )> }
);

export type Get_Package_ProductQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Package_ProductQuery = (
  { __typename?: 'query_root' }
  & { product: Array<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { product_brand: (
      { __typename?: 'product_brand' }
      & Pick<Product_Brand, 'description'>
    ) }
  )> }
);

export type Get_Store_And_PackageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Store_And_PackageQuery = (
  { __typename?: 'query_root' }
  & { package: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'name'>
  )>, store_add_on_product_item: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'commission_rate'>
  )>, store_and_package: Array<(
    { __typename?: 'store_and_package' }
    & { storeByStore: (
      { __typename?: 'store' }
      & Pick<Store, 'id'>
    ) }
  )>, stores: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'name' | 'llumar_store_name' | 'address_detail'>
    & { addressSigunguByAddressSigungu?: Maybe<(
      { __typename?: 'address_sigungu' }
      & Pick<Address_Sigungu, 'name'>
      & { address_sido: (
        { __typename?: 'address_sido' }
        & Pick<Address_Sido, 'name'>
      ) }
    )>, store_and_packages: Array<(
      { __typename?: 'store_and_package' }
      & Pick<Store_And_Package, 'add_on_product_item'>
    )> }
  )>, address: Array<(
    { __typename?: 'address_sido' }
    & Pick<Address_Sido, 'id' | 'name'>
    & { address_sigungus: Array<(
      { __typename?: 'address_sigungu' }
      & Pick<Address_Sigungu, 'id' | 'name'>
    )> }
  )> }
);

export type Delete_All_And_Insert_Store_And_PackageMutationVariables = Exact<{
  packageId: Scalars['uuid'];
  selectedStore: Array<Store_And_Package_Insert_Input> | Store_And_Package_Insert_Input;
}>;


export type Delete_All_And_Insert_Store_And_PackageMutation = (
  { __typename?: 'mutation_root' }
  & { delete_store_and_package?: Maybe<(
    { __typename?: 'store_and_package_mutation_response' }
    & Pick<Store_And_Package_Mutation_Response, 'affected_rows'>
  )>, insert_store_and_package?: Maybe<(
    { __typename?: 'store_and_package_mutation_response' }
    & Pick<Store_And_Package_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Store_DetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Store_DetailQuery = (
  { __typename?: 'query_root' }
  & { store: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'status' | 'name' | 'email' | 'image' | 'description' | 'location' | 'attribute' | 'address_sigungu' | 'phone_business' | 'name_business' | 'address_detail'>
    & { addressSigunguByAddressSigungu?: Maybe<(
      { __typename?: 'address_sigungu' }
      & Pick<Address_Sigungu, 'id' | 'name'>
      & { address_sido: (
        { __typename?: 'address_sido' }
        & Pick<Address_Sido, 'name'>
      ) }
    )>, store_and_hashtags: Array<(
      { __typename?: 'store_and_hashtag' }
      & Pick<Store_And_Hashtag, 'order'>
      & { hashtagByHashtag: (
        { __typename?: 'hashtag' }
        & Pick<Hashtag, 'id' | 'name'>
      ) }
    )>, store_and_accounts: Array<(
      { __typename?: 'store_and_account' }
      & { accountByAccount: (
        { __typename?: 'account' }
        & { profile_kr_partner?: Maybe<(
          { __typename?: 'profile_kr_partner' }
          & Pick<Profile_Kr_Partner, 'name' | 'email'>
        )> }
      ) }
    )> }
  )>, hashtag: Array<(
    { __typename?: 'hashtag' }
    & Pick<Hashtag, 'id' | 'name'>
  )>, address: Array<(
    { __typename?: 'address_sigungu' }
    & Pick<Address_Sigungu, 'id' | 'name'>
    & { address_sido: (
      { __typename?: 'address_sido' }
      & Pick<Address_Sido, 'id' | 'name'>
    ) }
  )>, account: Array<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'email' | 'name' | 'account'>
  )>, storeAccount: Array<(
    { __typename?: 'store_and_account' }
    & { accountByAccount: (
      { __typename?: 'account' }
      & { profile_kr_partner?: Maybe<(
        { __typename?: 'profile_kr_partner' }
        & Pick<Profile_Kr_Partner, 'email' | 'name' | 'account'>
      )> }
    ) }
  )> }
);

export type Get_Address_And_HashtagQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Address_And_HashtagQuery = (
  { __typename?: 'query_root' }
  & { address: Array<(
    { __typename?: 'address_sigungu' }
    & Pick<Address_Sigungu, 'id' | 'name'>
    & { address_sido: (
      { __typename?: 'address_sido' }
      & Pick<Address_Sido, 'id' | 'name'>
    ) }
  )>, hashtag: Array<(
    { __typename?: 'hashtag' }
    & Pick<Hashtag, 'id' | 'name'>
  )>, account: Array<(
    { __typename?: 'profile_kr_partner' }
    & Pick<Profile_Kr_Partner, 'email' | 'name' | 'account'>
  )> }
);

export type Update_StoreMutationVariables = Exact<{
  id: Scalars['uuid'];
  newStoreDetail?: Maybe<Store_Set_Input>;
  newStoreAccount?: Maybe<Store_And_Account_Set_Input>;
}>;


export type Update_StoreMutation = (
  { __typename?: 'mutation_root' }
  & { update_store?: Maybe<(
    { __typename?: 'store_mutation_response' }
    & Pick<Store_Mutation_Response, 'affected_rows'>
  )>, update_store_and_account?: Maybe<(
    { __typename?: 'store_and_account_mutation_response' }
    & Pick<Store_And_Account_Mutation_Response, 'affected_rows'>
  )> }
);

export type Delete_All_Store_HashtagMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Delete_All_Store_HashtagMutation = (
  { __typename?: 'mutation_root' }
  & { delete_store_and_hashtag?: Maybe<(
    { __typename?: 'store_and_hashtag_mutation_response' }
    & Pick<Store_And_Hashtag_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_Store_And_HashtagMutationVariables = Exact<{
  selectedHashtagArray: Array<Store_And_Hashtag_Insert_Input> | Store_And_Hashtag_Insert_Input;
}>;


export type Insert_Store_And_HashtagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_store_and_hashtag?: Maybe<(
    { __typename?: 'store_and_hashtag_mutation_response' }
    & Pick<Store_And_Hashtag_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_StoreMutationVariables = Exact<{
  insertObject: Store_Insert_Input;
}>;


export type Insert_StoreMutation = (
  { __typename?: 'mutation_root' }
  & { insert_store_one?: Maybe<(
    { __typename?: 'store' }
    & Pick<Store, 'id'>
  )> }
);

export type Get_Subscription_And_TransactionQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Subscription_And_TransactionQuery = (
  { __typename?: 'query_root' }
  & { subscription_and_transaction: Array<(
    { __typename?: 'subscription_and_transaction' }
    & Pick<Subscription_And_Transaction, 'created_at'>
    & { subscriptionBySubscription: (
      { __typename?: 'subscription' }
      & Pick<Subscription, 'id'>
      & { storeByStore: (
        { __typename?: 'store' }
        & Pick<Store, 'name' | 'phone_business' | 'email'>
      ) }
    ), transactionByTransaction: (
      { __typename?: 'transaction' }
      & Pick<Transaction, 'id' | 'amount' | 'stripe_payment_intent_id' | 'rapyd_payment_intent_id'>
      & { transaction_type: (
        { __typename?: 'transaction_type' }
        & Pick<Transaction_Type, 'value' | 'description'>
      ), refund?: Maybe<(
        { __typename?: 'refund' }
        & Pick<Refund, 'id'>
      )> }
    ) }
  )> }
);

export type Get_Product_DetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Product_DetailQuery = (
  { __typename?: 'query_root' }
  & { product: Array<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'status' | 'name' | 'type' | 'attribute' | 'brand' | 'image' | 'description' | 'alias'>
  )>, product_brand: Array<(
    { __typename?: 'product_brand' }
    & Pick<Product_Brand, 'value' | 'description'>
  )>, product_item: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'sku' | 'price'>
  )> }
);

export type Update_ProductMutationVariables = Exact<{
  productId: Scalars['uuid'];
  name: Scalars['String'];
  account: Scalars['uuid'];
  attribute: Scalars['jsonb'];
  image: Scalars['jsonb'];
  description: Scalars['String'];
  status: Scalars['Boolean'];
  alias: Scalars['String'];
}>;


export type Update_ProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_product?: Maybe<(
    { __typename?: 'product_mutation_response' }
    & Pick<Product_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_ProductMutationVariables = Exact<{
  productObject: Product_Insert_Input;
}>;


export type Insert_ProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_product_one?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id'>
  )> }
);

export type Get_PromotionQueryVariables = Exact<{
  productName: Scalars['jsonb'];
}>;


export type Get_PromotionQuery = (
  { __typename?: 'query_root' }
  & { promotion: Array<(
    { __typename?: 'promotion' }
    & Pick<Promotion, 'id' | 'name' | 'on_going' | 'deleted_at'>
  )> }
);

export type Insert_PromotionMutationVariables = Exact<{
  promotionObjects: Array<Promotion_Insert_Input> | Promotion_Insert_Input;
}>;


export type Insert_PromotionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_promotion?: Maybe<(
    { __typename?: 'promotion_mutation_response' }
    & Pick<Promotion_Mutation_Response, 'affected_rows'>
  )> }
);

export type Delete_PromotionMutationVariables = Exact<{
  productId: Scalars['uuid'];
  deletedAt: Scalars['timestamptz'];
}>;


export type Delete_PromotionMutation = (
  { __typename?: 'mutation_root' }
  & { update_promotion?: Maybe<(
    { __typename?: 'promotion_mutation_response' }
    & Pick<Promotion_Mutation_Response, 'affected_rows'>
  )> }
);

export type Get_Stores_And_SolesQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Stores_And_SolesQuery = (
  { __typename?: 'query_root' }
  & { stores: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'email' | 'name' | 'name_business' | 'llumar_store_name'>
    & { store_and_accounts: Array<(
      { __typename?: 'store_and_account' }
      & { accountByAccount: (
        { __typename?: 'account' }
        & { profile_kr_partner?: Maybe<(
          { __typename?: 'profile_kr_partner' }
          & Pick<Profile_Kr_Partner, 'email'>
        )> }
      ) }
    )> }
  )>, sole: Array<(
    { __typename?: 'sole' }
    & Pick<Sole, 'id' | 'name'>
  )> }
);

export type Update_StoresMutationVariables = Exact<{
  objects: Array<Store_Insert_Input> | Store_Insert_Input;
}>;


export type Update_StoresMutation = (
  { __typename?: 'mutation_root' }
  & { insert_store?: Maybe<(
    { __typename?: 'store_mutation_response' }
    & Pick<Store_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_Sole_OneMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type Insert_Sole_OneMutation = (
  { __typename?: 'mutation_root' }
  & { sole?: Maybe<(
    { __typename?: 'sole' }
    & Pick<Sole, 'id'>
  )> }
);

export type Upsert_Store_And_SoleMutationVariables = Exact<{
  objects: Array<Store_And_Sole_Insert_Input> | Store_And_Sole_Insert_Input;
}>;


export type Upsert_Store_And_SoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_store_and_sole?: Maybe<(
    { __typename?: 'store_and_sole_mutation_response' }
    & Pick<Store_And_Sole_Mutation_Response, 'affected_rows'>
  )> }
);

export type _Get_Search_StoresQueryVariables = Exact<{
  storeSearchKeyword: Scalars['String'];
}>;


export type _Get_Search_StoresQuery = (
  { __typename?: 'query_root' }
  & { searchStores: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'status' | 'name' | 'location' | 'address_detail'>
    & { subscriptions: Array<(
      { __typename?: 'subscription' }
      & Pick<Subscription, 'status'>
    )> }
  )> }
);

export type _Get_Search_CarsQueryVariables = Exact<{
  carSearchKeyword: Scalars['String'];
}>;


export type _Get_Search_CarsQuery = (
  { __typename?: 'query_root' }
  & { searchCars: Array<(
    { __typename?: 'car' }
    & Pick<Car, 'id' | 'model' | 'type'>
    & { car_maker: (
      { __typename?: 'car_maker' }
      & Pick<Car_Maker, 'name_kr' | 'name_us'>
      & { car_origin: (
        { __typename?: 'car_origin' }
        & Pick<Car_Origin, 'name_kr' | 'name_us'>
      ) }
    ) }
  )> }
);

export type _Get_Select_ProductsQueryVariables = Exact<{
  carOrigin: Scalars['String'];
  carType: Scalars['String'];
  carSunroofType: Scalars['String'];
}>;


export type _Get_Select_ProductsQuery = (
  { __typename?: 'query_root' }
  & { packages: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'description' | 'add_on_product' | 'price_product' | 'price_discount' | 'price_payout' | 'attribute'>
  )>, custom_package: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id'>
  )>, front_tinting_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, sideback_tinting_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, side_tinting_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, back_tinting_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, sunroof_tinting_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, front_film_remove_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, side_film_remove_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, back_film_remove_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, sunroof_film_remove_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, dashcam_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, ceramic_coating_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, ppf_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, connected_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, auxiliary_battery_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, hipass_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, after_blow_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, undercoating_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, leather_coating_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, soundproofing_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, new_car_inspection_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, camo_kit_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )>, delivery_fee_product_items: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'price' | 'sku' | 'attribute'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
      & { product_brand: (
        { __typename?: 'product_brand' }
        & Pick<Product_Brand, 'description'>
      ), product_type: (
        { __typename?: 'product_type' }
        & Pick<Product_Type, 'value' | 'description'>
      ) }
    ) }
  )> }
);

export type _Get_Select_OptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type _Get_Select_OptionsQuery = (
  { __typename?: 'query_root' }
  & { address: Array<(
    { __typename?: 'address_sido' }
    & Pick<Address_Sido, 'id' | 'name'>
    & { address_sigungus: Array<(
      { __typename?: 'address_sigungu' }
      & Pick<Address_Sigungu, 'id' | 'name'>
    )> }
  )>, carSunroofTypes: Array<(
    { __typename?: 'car_sunroof_type' }
    & Pick<Car_Sunroof_Type, 'value' | 'description'>
  )>, carModels: Array<(
    { __typename?: 'car' }
    & Pick<Car, 'id' | 'model' | 'type'>
    & { car_maker: (
      { __typename?: 'car_maker' }
      & Pick<Car_Maker, 'name_kr' | 'name_us'>
      & { car_origin: (
        { __typename?: 'car_origin' }
        & Pick<Car_Origin, 'name_kr' | 'name_us'>
      ) }
    ) }
  )>, stores: Array<(
    { __typename?: 'store' }
    & Pick<Store, 'id' | 'status' | 'name' | 'location' | 'address_detail'>
    & { addressSigunguByAddressSigungu?: Maybe<(
      { __typename?: 'address_sigungu' }
      & Pick<Address_Sigungu, 'name'>
      & { address_sido: (
        { __typename?: 'address_sido' }
        & Pick<Address_Sido, 'name'>
      ) }
    )>, subscriptions: Array<(
      { __typename?: 'subscription' }
      & Pick<Subscription, 'status'>
    )> }
  )>, chat_assignees: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'id'>
    & { profile_riderdash?: Maybe<(
      { __typename?: 'profile_riderdash' }
      & Pick<Profile_Riderdash, 'id' | 'name' | 'email' | 'role'>
    )> }
  )>, operators: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'id'>
    & { profile_riderdash?: Maybe<(
      { __typename?: 'profile_riderdash' }
      & Pick<Profile_Riderdash, 'id' | 'name' | 'role'>
    )> }
  )>, profileCustomers: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'id'>
    & { profile_kr_customer?: Maybe<(
      { __typename?: 'profile_kr_customer' }
      & Pick<Profile_Kr_Customer, 'id' | 'name' | 'email'>
    )> }
  )> }
);

export type _Get_Product_Item_Price_By_StoreQueryVariables = Exact<{
  storeId: Scalars['uuid'];
}>;


export type _Get_Product_Item_Price_By_StoreQuery = (
  { __typename?: 'query_root' }
  & { product_item_price_by_store: Array<(
    { __typename?: 'product_item_price_by_store' }
    & Pick<Product_Item_Price_By_Store, 'id' | 'product_item_id' | 'store_id' | 'prices' | 'default_prices'>
  )> }
);

export type Get_Default_Discount_Rate_TableQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Default_Discount_Rate_TableQuery = (
  { __typename?: 'query_root' }
  & { config: Array<(
    { __typename?: 'config' }
    & Pick<Config, 'id' | 'csv_content' | 'updated_at'>
  )> }
);

export type Insert_New_Config_RateMutationVariables = Exact<{
  object: Config_Insert_Input;
}>;


export type Insert_New_Config_RateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_config_one?: Maybe<(
    { __typename?: 'config' }
    & Pick<Config, 'id' | 'updated_at'>
  )> }
);

export type Subscription_LeadSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Subscription_LeadSubscription = (
  { __typename?: 'subscription_root' }
  & { lead_by_pk?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id' | 'key' | 'created_at' | 'updated_at' | 'phone' | 'customer' | 'status' | 'used_car_status' | 'start_drop_off_date_time' | 'end_drop_off_date_time' | 'due_date' | 'car_sunroof_type' | 'etc' | 'film_need_to_removed'>
    & { carByCar?: Maybe<(
      { __typename?: 'car' }
      & Pick<Car, 'id' | 'model' | 'type'>
      & { car_maker: (
        { __typename?: 'car_maker' }
        & Pick<Car_Maker, 'name_kr' | 'name_us'>
        & { car_origin: (
          { __typename?: 'car_origin' }
          & Pick<Car_Origin, 'name_kr' | 'name_us'>
        ) }
      ) }
    )>, chats: Array<(
      { __typename?: 'chat' }
      & Pick<Chat, 'id' | 'key' | 'chat_assignee' | 'zendesk_chat_name'>
      & { accountByChatAssignee?: Maybe<(
        { __typename?: 'account' }
        & Pick<Account, 'id'>
        & { profile_riderdash?: Maybe<(
          { __typename?: 'profile_riderdash' }
          & Pick<Profile_Riderdash, 'name'>
        )> }
      )> }
    )>, quotes: Array<(
      { __typename?: 'quote' }
      & Pick<Quote, 'id' | 'lead'>
      & { quote_items: Array<(
        { __typename?: 'quote_item' }
        & Pick<Quote_Item, 'id' | 'is_active' | 'remark' | 'store' | 'price_discount' | 'price_extra' | 'price_final' | 'price_payout' | 'price_payout_extra' | 'price_payout_discount' | 'price_product' | 'product_item' | 'deleted_at' | 'sent_at'>
        & { storeByStore?: Maybe<(
          { __typename?: 'store' }
          & Pick<Store, 'name_business' | 'phone_business'>
        )>, package_detail?: Maybe<Array<(
          { __typename?: 'package' }
          & Pick<Package, 'id' | 'name'>
          & { package_type: (
            { __typename?: 'package_type' }
            & Pick<Package_Type, 'description'>
          ) }
        )>>, product_item_detail?: Maybe<Array<(
          { __typename?: 'product_item' }
          & Pick<Product_Item, 'id' | 'attribute' | 'price'>
          & { productByProduct: (
            { __typename?: 'product' }
            & Pick<Product, 'name'>
            & { product_brand: (
              { __typename?: 'product_brand' }
              & Pick<Product_Brand, 'description'>
            ), product_type: (
              { __typename?: 'product_type' }
              & Pick<Product_Type, 'value' | 'description'>
            ) }
          ) }
        )>> }
      )> }
    )>, accountBySupportAssignee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id'>
      & { profile_riderdash?: Maybe<(
        { __typename?: 'profile_riderdash' }
        & Pick<Profile_Riderdash, 'id' | 'name'>
      )> }
    )>, accountByShopAssignee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id'>
      & { profile_riderdash?: Maybe<(
        { __typename?: 'profile_riderdash' }
        & Pick<Profile_Riderdash, 'name' | 'email'>
      )> }
    )>, accountByCustomer?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id'>
      & { profile_kr_customer?: Maybe<(
        { __typename?: 'profile_kr_customer' }
        & Pick<Profile_Kr_Customer, 'name' | 'email'>
      )> }
    )>, lead_comments: Array<(
      { __typename?: 'lead_comment' }
      & Pick<Lead_Comment, 'id' | 'account' | 'comment' | 'created_at' | 'updated_at'>
      & { accountByAccount: (
        { __typename?: 'account' }
        & Pick<Account, 'id'>
        & { profile_riderdash?: Maybe<(
          { __typename?: 'profile_riderdash' }
          & Pick<Profile_Riderdash, 'name' | 'email'>
        )>, profile_kr_customer?: Maybe<(
          { __typename?: 'profile_kr_customer' }
          & Pick<Profile_Kr_Customer, 'name' | 'email'>
        )> }
      ) }
    )>, orders: Array<(
      { __typename?: 'order' }
      & Pick<Order, 'id' | 'created_at' | 'store' | 'basic_price_payout' | 'price_discount' | 'price_extra' | 'price_final' | 'price_payout' | 'price_product' | 'price_payout_extra' | 'price_payout_discount' | 'product_item' | 'remark' | 'is_auto_order'>
      & { storeByStore: (
        { __typename?: 'store' }
        & Pick<Store, 'name_business' | 'phone_business'>
      ), package_detail?: Maybe<Array<(
        { __typename?: 'package' }
        & Pick<Package, 'id' | 'name'>
        & { package_type: (
          { __typename?: 'package_type' }
          & Pick<Package_Type, 'description'>
        ) }
      )>>, product_item_detail?: Maybe<Array<(
        { __typename?: 'product_item' }
        & Pick<Product_Item, 'id' | 'attribute' | 'price'>
        & { productByProduct: (
          { __typename?: 'product' }
          & Pick<Product, 'name'>
          & { product_brand: (
            { __typename?: 'product_brand' }
            & Pick<Product_Brand, 'description'>
          ), product_type: (
            { __typename?: 'product_type' }
            & Pick<Product_Type, 'value' | 'description'>
          ) }
        ) }
      )>>, order_and_transactions: Array<(
        { __typename?: 'order_and_transaction' }
        & { transactionByTransaction: (
          { __typename?: 'transaction' }
          & Pick<Transaction, 'bootpay_payment_intent_id' | 'stripe_payment_intent_id'>
        ) }
      )>, quoteItemByQuoteItem?: Maybe<(
        { __typename?: 'quote_item' }
        & Pick<Quote_Item, 'sent_at'>
      )> }
    )> }
  )> }
);

export type Subscription_LeadsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type Subscription_LeadsSubscription = (
  { __typename?: 'subscription_root' }
  & { lead: Array<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id' | 'status'>
  )> }
);

export type Insert_LeadMutationVariables = Exact<{
  leadObject: Lead_Insert_Input;
}>;


export type Insert_LeadMutation = (
  { __typename?: 'mutation_root' }
  & { insert_lead_one?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )> }
);

export type Update_Lead_By_PkMutationVariables = Exact<{
  leadId: Scalars['uuid'];
  leadObject: Lead_Set_Input;
}>;


export type Update_Lead_By_PkMutation = (
  { __typename?: 'mutation_root' }
  & { update_lead_by_pk?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )> }
);

export type Update_Lead_And_QuoteMutationVariables = Exact<{
  leadId: Scalars['uuid'];
  leadObject: Lead_Set_Input;
  quoteObject: Quote_Insert_Input;
}>;


export type Update_Lead_And_QuoteMutation = (
  { __typename?: 'mutation_root' }
  & { update_lead_by_pk?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )>, insert_quote_one?: Maybe<(
    { __typename?: 'quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type Update_Chat_And_Lead_And_QuoteMutationVariables = Exact<{
  chatId: Scalars['uuid'];
  leadId: Scalars['uuid'];
  chatObject: Chat_Set_Input;
  leadObject: Lead_Set_Input;
  quoteObject: Quote_Insert_Input;
}>;


export type Update_Chat_And_Lead_And_QuoteMutation = (
  { __typename?: 'mutation_root' }
  & { update_chat_by_pk?: Maybe<(
    { __typename?: 'chat' }
    & Pick<Chat, 'id'>
  )>, update_lead_by_pk?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )>, insert_quote_one?: Maybe<(
    { __typename?: 'quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type Update_Lead_And_Quote_And_OrderMutationVariables = Exact<{
  leadId: Scalars['uuid'];
  orderId: Scalars['uuid'];
  leadObject: Lead_Set_Input;
  orderObject: Order_Set_Input;
  quoteObject: Quote_Insert_Input;
}>;


export type Update_Lead_And_Quote_And_OrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_lead_by_pk?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )>, update_order_by_pk?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id'>
  )>, insert_quote_one?: Maybe<(
    { __typename?: 'quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type Update_Chat_And_Lead_And_Quote_And_OrderMutationVariables = Exact<{
  chatId: Scalars['uuid'];
  leadId: Scalars['uuid'];
  orderId: Scalars['uuid'];
  chatObject: Chat_Set_Input;
  leadObject: Lead_Set_Input;
  orderObject: Order_Set_Input;
  quoteObject: Quote_Insert_Input;
}>;


export type Update_Chat_And_Lead_And_Quote_And_OrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_chat_by_pk?: Maybe<(
    { __typename?: 'chat' }
    & Pick<Chat, 'id'>
  )>, update_lead_by_pk?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )>, update_order_by_pk?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id'>
  )>, insert_quote_one?: Maybe<(
    { __typename?: 'quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type Update_Lead_StatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  now: Scalars['timestamptz'];
}>;


export type Update_Lead_StatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_lead_by_pk?: Maybe<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )> }
);

export type Check_Lead_ExistenceQueryVariables = Exact<{
  phone: Scalars['String'];
}>;


export type Check_Lead_ExistenceQuery = (
  { __typename?: 'query_root' }
  & { lead: Array<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id'>
  )> }
);

export type Search_LeadQueryVariables = Exact<{
  chatId: Scalars['Int'];
  leadId: Scalars['Int'];
  email: Scalars['String'];
  phone: Scalars['String'];
  zendeskChatName: Scalars['String'];
}>;


export type Search_LeadQuery = (
  { __typename?: 'query_root' }
  & { leads: Array<(
    { __typename?: 'lead' }
    & Pick<Lead, 'id' | 'key' | 'created_at' | 'updated_at' | 'phone' | 'status' | 'due_date'>
    & { chats: Array<(
      { __typename?: 'chat' }
      & Pick<Chat, 'key'>
    )>, accountByCustomer?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id'>
      & { profile_kr_customer?: Maybe<(
        { __typename?: 'profile_kr_customer' }
        & Pick<Profile_Kr_Customer, 'email'>
      )> }
    )> }
  )> }
);

export type Insert_Lead_CommentMutationVariables = Exact<{
  leadComment: Lead_Comment_Insert_Input;
}>;


export type Insert_Lead_CommentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_lead_comment_one?: Maybe<(
    { __typename?: 'lead_comment' }
    & Pick<Lead_Comment, 'id' | 'lead'>
    & { leadByLead: (
      { __typename?: 'lead' }
      & Pick<Lead, 'id'>
    ), accountByAccount: (
      { __typename?: 'account' }
      & Pick<Account, 'id'>
      & { profile_riderdash?: Maybe<(
        { __typename?: 'profile_riderdash' }
        & Pick<Profile_Riderdash, 'name' | 'email'>
      )> }
    ) }
  )> }
);

export type Update_Lead_CommentMutationVariables = Exact<{
  id: Scalars['uuid'];
  comment: Scalars['String'];
}>;


export type Update_Lead_CommentMutation = (
  { __typename?: 'mutation_root' }
  & { update_lead_comment?: Maybe<(
    { __typename?: 'lead_comment_mutation_response' }
    & Pick<Lead_Comment_Mutation_Response, 'affected_rows'>
  )> }
);

export type Update_Order_With_LeadMutationVariables = Exact<{
  orderId: Scalars['uuid'];
  orderObject: Order_Set_Input;
}>;


export type Update_Order_With_LeadMutation = (
  { __typename?: 'mutation_root' }
  & { update_order_by_pk?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id'>
  )> }
);

export type Get_PackageQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_PackageQuery = (
  { __typename?: 'query_root' }
  & { package: Array<(
    { __typename?: 'package' }
    & Pick<Package, 'id' | 'name' | 'product' | 'description' | 'add_on_product' | 'price_product' | 'price_discount' | 'price_payout' | 'attribute'>
  )> }
);

export type Get_Product_ItemQueryVariables = Exact<{
  id: Scalars['uuid'];
  carType?: Maybe<Scalars['String']>;
  carOrigin?: Maybe<Scalars['String']>;
}>;


export type Get_Product_ItemQuery = (
  { __typename?: 'query_root' }
  & { product_item: Array<(
    { __typename?: 'product_item' }
    & Pick<Product_Item, 'id' | 'attribute' | 'sku'>
    & { productByProduct: (
      { __typename?: 'product' }
      & Pick<Product, 'name'>
    ) }
  )> }
);


export const Get_Lead_With_UtmDocument = gql`
    query GET_LEAD_WITH_UTM {
  lead(
    where: {lead_at: {_is_null: false}, utm: {_neq: {}}}
    order_by: {created_at: desc}
  ) {
    id
    key
    created_at
    utm
    status
    created_from
  }
}
    `;

/**
 * __useGet_Lead_With_UtmQuery__
 *
 * To run a query within a React component, call `useGet_Lead_With_UtmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Lead_With_UtmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Lead_With_UtmQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Lead_With_UtmQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Lead_With_UtmQuery, Get_Lead_With_UtmQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Lead_With_UtmQuery, Get_Lead_With_UtmQueryVariables>(Get_Lead_With_UtmDocument, baseOptions);
      }
export function useGet_Lead_With_UtmLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Lead_With_UtmQuery, Get_Lead_With_UtmQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Lead_With_UtmQuery, Get_Lead_With_UtmQueryVariables>(Get_Lead_With_UtmDocument, baseOptions);
        }
export type Get_Lead_With_UtmQueryHookResult = ReturnType<typeof useGet_Lead_With_UtmQuery>;
export type Get_Lead_With_UtmLazyQueryHookResult = ReturnType<typeof useGet_Lead_With_UtmLazyQuery>;
export type Get_Lead_With_UtmQueryResult = ApolloReactCommon.QueryResult<Get_Lead_With_UtmQuery, Get_Lead_With_UtmQueryVariables>;
export const Get_Accounts_PayableDocument = gql`
    query GET_ACCOUNTS_PAYABLE {
  accounts_payable(order_by: {created_at: desc}) {
    id
    created_at
    updated_at
    accounts_payable_and_payouts {
      payoutByPayout {
        order
        status
        finished_at
        reverted_at
        storeByStore {
          name
        }
        orderByOrder {
          price_payout
          name
          email
          commission_rate
        }
      }
    }
    accounts_payable_and_refunds {
      refundByRefund {
        order
        status
        orderByOrder {
          price_payout
          email
          name
        }
        transactionByTransaction {
          stripe_receipt_url
        }
      }
    }
    accounts_payable_and_adjustments {
      adjustmentByAdjustment {
        order
        status
        finished_at
        reverted_at
        orderByOrder {
          price_payout
          email
          name
        }
        storeByStore {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGet_Accounts_PayableQuery__
 *
 * To run a query within a React component, call `useGet_Accounts_PayableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Accounts_PayableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Accounts_PayableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Accounts_PayableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Accounts_PayableQuery, Get_Accounts_PayableQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Accounts_PayableQuery, Get_Accounts_PayableQueryVariables>(Get_Accounts_PayableDocument, baseOptions);
      }
export function useGet_Accounts_PayableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Accounts_PayableQuery, Get_Accounts_PayableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Accounts_PayableQuery, Get_Accounts_PayableQueryVariables>(Get_Accounts_PayableDocument, baseOptions);
        }
export type Get_Accounts_PayableQueryHookResult = ReturnType<typeof useGet_Accounts_PayableQuery>;
export type Get_Accounts_PayableLazyQueryHookResult = ReturnType<typeof useGet_Accounts_PayableLazyQuery>;
export type Get_Accounts_PayableQueryResult = ApolloReactCommon.QueryResult<Get_Accounts_PayableQuery, Get_Accounts_PayableQueryVariables>;
export const Update_Accounts_Payable_And_PayoutDocument = gql`
    mutation UPDATE_ACCOUNTS_PAYABLE_AND_PAYOUT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
  update_accounts_payable(
    where: {id: {_eq: $accountPayableId}}
    _set: {deleted_at: $timeStamp}
  ) {
    affected_rows
  }
  update_accounts_payable_and_payout(
    where: {accounts_payable: {_eq: $accountPayableId}}
    _set: {deleted_at: $timeStamp}
  ) {
    affected_rows
  }
  update_payout(
    where: {accounts_payable_and_payouts: {accounts_payable: {_eq: $accountPayableId}}}
    _set: {status: finished, finished_at: $timeStamp}
  ) {
    affected_rows
  }
}
    `;
export type Update_Accounts_Payable_And_PayoutMutationFn = ApolloReactCommon.MutationFunction<Update_Accounts_Payable_And_PayoutMutation, Update_Accounts_Payable_And_PayoutMutationVariables>;

/**
 * __useUpdate_Accounts_Payable_And_PayoutMutation__
 *
 * To run a mutation, you first call `useUpdate_Accounts_Payable_And_PayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Accounts_Payable_And_PayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountsPayableAndPayoutMutation, { data, loading, error }] = useUpdate_Accounts_Payable_And_PayoutMutation({
 *   variables: {
 *      accountPayableId: // value for 'accountPayableId'
 *      timeStamp: // value for 'timeStamp'
 *   },
 * });
 */
export function useUpdate_Accounts_Payable_And_PayoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Accounts_Payable_And_PayoutMutation, Update_Accounts_Payable_And_PayoutMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Accounts_Payable_And_PayoutMutation, Update_Accounts_Payable_And_PayoutMutationVariables>(Update_Accounts_Payable_And_PayoutDocument, baseOptions);
      }
export type Update_Accounts_Payable_And_PayoutMutationHookResult = ReturnType<typeof useUpdate_Accounts_Payable_And_PayoutMutation>;
export type Update_Accounts_Payable_And_PayoutMutationResult = ApolloReactCommon.MutationResult<Update_Accounts_Payable_And_PayoutMutation>;
export type Update_Accounts_Payable_And_PayoutMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Accounts_Payable_And_PayoutMutation, Update_Accounts_Payable_And_PayoutMutationVariables>;
export const Update_Accounts_Payable_And_AdjustmentDocument = gql`
    mutation UPDATE_ACCOUNTS_PAYABLE_AND_ADJUSTMENT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
  update_accounts_payable(
    where: {id: {_eq: $accountPayableId}}
    _set: {deleted_at: $timeStamp}
  ) {
    affected_rows
  }
  update_accounts_payable_and_adjustment(
    where: {accounts_payable: {_eq: $accountPayableId}}
    _set: {deleted_at: $timeStamp}
  ) {
    affected_rows
  }
  update_adjustment(
    where: {accounts_payable_and_adjustments: {accounts_payable: {_eq: $accountPayableId}}}
    _set: {status: finished, finished_at: $timeStamp}
  ) {
    affected_rows
  }
}
    `;
export type Update_Accounts_Payable_And_AdjustmentMutationFn = ApolloReactCommon.MutationFunction<Update_Accounts_Payable_And_AdjustmentMutation, Update_Accounts_Payable_And_AdjustmentMutationVariables>;

/**
 * __useUpdate_Accounts_Payable_And_AdjustmentMutation__
 *
 * To run a mutation, you first call `useUpdate_Accounts_Payable_And_AdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Accounts_Payable_And_AdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountsPayableAndAdjustmentMutation, { data, loading, error }] = useUpdate_Accounts_Payable_And_AdjustmentMutation({
 *   variables: {
 *      accountPayableId: // value for 'accountPayableId'
 *      timeStamp: // value for 'timeStamp'
 *   },
 * });
 */
export function useUpdate_Accounts_Payable_And_AdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Accounts_Payable_And_AdjustmentMutation, Update_Accounts_Payable_And_AdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Accounts_Payable_And_AdjustmentMutation, Update_Accounts_Payable_And_AdjustmentMutationVariables>(Update_Accounts_Payable_And_AdjustmentDocument, baseOptions);
      }
export type Update_Accounts_Payable_And_AdjustmentMutationHookResult = ReturnType<typeof useUpdate_Accounts_Payable_And_AdjustmentMutation>;
export type Update_Accounts_Payable_And_AdjustmentMutationResult = ApolloReactCommon.MutationResult<Update_Accounts_Payable_And_AdjustmentMutation>;
export type Update_Accounts_Payable_And_AdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Accounts_Payable_And_AdjustmentMutation, Update_Accounts_Payable_And_AdjustmentMutationVariables>;
export const Revert_Accounts_Payable_And_PayoutDocument = gql`
    mutation REVERT_ACCOUNTS_PAYABLE_AND_PAYOUT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
  update_accounts_payable(
    where: {id: {_eq: $accountPayableId}}
    _set: {deleted_at: null}
  ) {
    affected_rows
  }
  update_accounts_payable_and_payout(
    where: {accounts_payable: {_eq: $accountPayableId}}
    _set: {deleted_at: null}
  ) {
    affected_rows
  }
  update_payout(
    where: {accounts_payable_and_payouts: {accounts_payable: {_eq: $accountPayableId}}}
    _set: {status: reverted, reverted_at: $timeStamp, finished_at: null}
  ) {
    affected_rows
  }
}
    `;
export type Revert_Accounts_Payable_And_PayoutMutationFn = ApolloReactCommon.MutationFunction<Revert_Accounts_Payable_And_PayoutMutation, Revert_Accounts_Payable_And_PayoutMutationVariables>;

/**
 * __useRevert_Accounts_Payable_And_PayoutMutation__
 *
 * To run a mutation, you first call `useRevert_Accounts_Payable_And_PayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevert_Accounts_Payable_And_PayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAccountsPayableAndPayoutMutation, { data, loading, error }] = useRevert_Accounts_Payable_And_PayoutMutation({
 *   variables: {
 *      accountPayableId: // value for 'accountPayableId'
 *      timeStamp: // value for 'timeStamp'
 *   },
 * });
 */
export function useRevert_Accounts_Payable_And_PayoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Revert_Accounts_Payable_And_PayoutMutation, Revert_Accounts_Payable_And_PayoutMutationVariables>) {
        return ApolloReactHooks.useMutation<Revert_Accounts_Payable_And_PayoutMutation, Revert_Accounts_Payable_And_PayoutMutationVariables>(Revert_Accounts_Payable_And_PayoutDocument, baseOptions);
      }
export type Revert_Accounts_Payable_And_PayoutMutationHookResult = ReturnType<typeof useRevert_Accounts_Payable_And_PayoutMutation>;
export type Revert_Accounts_Payable_And_PayoutMutationResult = ApolloReactCommon.MutationResult<Revert_Accounts_Payable_And_PayoutMutation>;
export type Revert_Accounts_Payable_And_PayoutMutationOptions = ApolloReactCommon.BaseMutationOptions<Revert_Accounts_Payable_And_PayoutMutation, Revert_Accounts_Payable_And_PayoutMutationVariables>;
export const Revert_Accounts_Payable_And_AdjustmentDocument = gql`
    mutation REVERT_ACCOUNTS_PAYABLE_AND_ADJUSTMENT($accountPayableId: uuid!, $timeStamp: timestamptz!) {
  update_accounts_payable(
    where: {id: {_eq: $accountPayableId}}
    _set: {deleted_at: null}
  ) {
    affected_rows
  }
  update_accounts_payable_and_adjustment(
    where: {accounts_payable: {_eq: $accountPayableId}}
    _set: {deleted_at: null}
  ) {
    affected_rows
  }
  update_adjustment(
    where: {accounts_payable_and_adjustments: {accounts_payable: {_eq: $accountPayableId}}}
    _set: {status: reverted, reverted_at: $timeStamp, finished_at: null}
  ) {
    affected_rows
  }
}
    `;
export type Revert_Accounts_Payable_And_AdjustmentMutationFn = ApolloReactCommon.MutationFunction<Revert_Accounts_Payable_And_AdjustmentMutation, Revert_Accounts_Payable_And_AdjustmentMutationVariables>;

/**
 * __useRevert_Accounts_Payable_And_AdjustmentMutation__
 *
 * To run a mutation, you first call `useRevert_Accounts_Payable_And_AdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevert_Accounts_Payable_And_AdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAccountsPayableAndAdjustmentMutation, { data, loading, error }] = useRevert_Accounts_Payable_And_AdjustmentMutation({
 *   variables: {
 *      accountPayableId: // value for 'accountPayableId'
 *      timeStamp: // value for 'timeStamp'
 *   },
 * });
 */
export function useRevert_Accounts_Payable_And_AdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Revert_Accounts_Payable_And_AdjustmentMutation, Revert_Accounts_Payable_And_AdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<Revert_Accounts_Payable_And_AdjustmentMutation, Revert_Accounts_Payable_And_AdjustmentMutationVariables>(Revert_Accounts_Payable_And_AdjustmentDocument, baseOptions);
      }
export type Revert_Accounts_Payable_And_AdjustmentMutationHookResult = ReturnType<typeof useRevert_Accounts_Payable_And_AdjustmentMutation>;
export type Revert_Accounts_Payable_And_AdjustmentMutationResult = ApolloReactCommon.MutationResult<Revert_Accounts_Payable_And_AdjustmentMutation>;
export type Revert_Accounts_Payable_And_AdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<Revert_Accounts_Payable_And_AdjustmentMutation, Revert_Accounts_Payable_And_AdjustmentMutationVariables>;
export const Get_Accounts_ReceivableDocument = gql`
    query GET_ACCOUNTS_RECEIVABLE {
  accounts_receivable(
    where: {deleted_at: {_is_null: true}}
    order_by: {created_at: desc}
  ) {
    id
    created_at
    updated_at
    accounts_receivable_and_memberships {
      membershipByMembership {
        price
        type
        storeByStore {
          name
        }
      }
    }
    accounts_receivable_and_invoices {
      invoiceByInvoice {
        price
        status
        storeByStore {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGet_Accounts_ReceivableQuery__
 *
 * To run a query within a React component, call `useGet_Accounts_ReceivableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Accounts_ReceivableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Accounts_ReceivableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Accounts_ReceivableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Accounts_ReceivableQuery, Get_Accounts_ReceivableQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Accounts_ReceivableQuery, Get_Accounts_ReceivableQueryVariables>(Get_Accounts_ReceivableDocument, baseOptions);
      }
export function useGet_Accounts_ReceivableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Accounts_ReceivableQuery, Get_Accounts_ReceivableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Accounts_ReceivableQuery, Get_Accounts_ReceivableQueryVariables>(Get_Accounts_ReceivableDocument, baseOptions);
        }
export type Get_Accounts_ReceivableQueryHookResult = ReturnType<typeof useGet_Accounts_ReceivableQuery>;
export type Get_Accounts_ReceivableLazyQueryHookResult = ReturnType<typeof useGet_Accounts_ReceivableLazyQuery>;
export type Get_Accounts_ReceivableQueryResult = ApolloReactCommon.QueryResult<Get_Accounts_ReceivableQuery, Get_Accounts_ReceivableQueryVariables>;
export const Get_MajorsDocument = gql`
    query GET_MAJORS($sessionFilter: uuid_comparison_exp) {
  session(
    where: {activities: {rank: {_eq: 1}}}
    limit: 100
    order_by: {created_at: desc}
  ) {
    id
    activities(order_by: {created_at: asc}, where: {rank: {_eq: 1}}) {
      action
      context
      rank
    }
  }
}
    `;

/**
 * __useGet_MajorsQuery__
 *
 * To run a query within a React component, call `useGet_MajorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_MajorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_MajorsQuery({
 *   variables: {
 *      sessionFilter: // value for 'sessionFilter'
 *   },
 * });
 */
export function useGet_MajorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_MajorsQuery, Get_MajorsQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_MajorsQuery, Get_MajorsQueryVariables>(Get_MajorsDocument, baseOptions);
      }
export function useGet_MajorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_MajorsQuery, Get_MajorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_MajorsQuery, Get_MajorsQueryVariables>(Get_MajorsDocument, baseOptions);
        }
export type Get_MajorsQueryHookResult = ReturnType<typeof useGet_MajorsQuery>;
export type Get_MajorsLazyQueryHookResult = ReturnType<typeof useGet_MajorsLazyQuery>;
export type Get_MajorsQueryResult = ApolloReactCommon.QueryResult<Get_MajorsQuery, Get_MajorsQueryVariables>;
export const Get_CarsDocument = gql`
    query GET_CARS {
  cars: car(order_by: {car_maker: {name_kr: asc}, model: asc}) {
    id
    model
    row
    type
    prefix
    postfix
    year
    maker
    llumar_car_type
    car_maker {
      name_kr
      name_us
      origin
      car_origin {
        name_kr
        name_us
      }
    }
    car_type {
      value
      description
    }
  }
}
    `;

/**
 * __useGet_CarsQuery__
 *
 * To run a query within a React component, call `useGet_CarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_CarsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_CarsQuery, Get_CarsQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_CarsQuery, Get_CarsQueryVariables>(Get_CarsDocument, baseOptions);
      }
export function useGet_CarsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_CarsQuery, Get_CarsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_CarsQuery, Get_CarsQueryVariables>(Get_CarsDocument, baseOptions);
        }
export type Get_CarsQueryHookResult = ReturnType<typeof useGet_CarsQuery>;
export type Get_CarsLazyQueryHookResult = ReturnType<typeof useGet_CarsLazyQuery>;
export type Get_CarsQueryResult = ApolloReactCommon.QueryResult<Get_CarsQuery, Get_CarsQueryVariables>;
export const Get_CarDocument = gql`
    query GET_CAR($id: uuid) {
  car(where: {id: {_eq: $id}}) {
    id
    model
    row
    type
    prefix
    postfix
    year
    maker
    llumar_car_type
  }
}
    `;

/**
 * __useGet_CarQuery__
 *
 * To run a query within a React component, call `useGet_CarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_CarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_CarQuery, Get_CarQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_CarQuery, Get_CarQueryVariables>(Get_CarDocument, baseOptions);
      }
export function useGet_CarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_CarQuery, Get_CarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_CarQuery, Get_CarQueryVariables>(Get_CarDocument, baseOptions);
        }
export type Get_CarQueryHookResult = ReturnType<typeof useGet_CarQuery>;
export type Get_CarLazyQueryHookResult = ReturnType<typeof useGet_CarLazyQuery>;
export type Get_CarQueryResult = ApolloReactCommon.QueryResult<Get_CarQuery, Get_CarQueryVariables>;
export const Get_Car_Makers_And_Car_TypesDocument = gql`
    query GET_CAR_MAKERS_AND_CAR_TYPES($id: uuid) {
  car_maker {
    id
    name_kr
  }
  car_type {
    value
    description
  }
}
    `;

/**
 * __useGet_Car_Makers_And_Car_TypesQuery__
 *
 * To run a query within a React component, call `useGet_Car_Makers_And_Car_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Car_Makers_And_Car_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Car_Makers_And_Car_TypesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Car_Makers_And_Car_TypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Car_Makers_And_Car_TypesQuery, Get_Car_Makers_And_Car_TypesQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Car_Makers_And_Car_TypesQuery, Get_Car_Makers_And_Car_TypesQueryVariables>(Get_Car_Makers_And_Car_TypesDocument, baseOptions);
      }
export function useGet_Car_Makers_And_Car_TypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Car_Makers_And_Car_TypesQuery, Get_Car_Makers_And_Car_TypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Car_Makers_And_Car_TypesQuery, Get_Car_Makers_And_Car_TypesQueryVariables>(Get_Car_Makers_And_Car_TypesDocument, baseOptions);
        }
export type Get_Car_Makers_And_Car_TypesQueryHookResult = ReturnType<typeof useGet_Car_Makers_And_Car_TypesQuery>;
export type Get_Car_Makers_And_Car_TypesLazyQueryHookResult = ReturnType<typeof useGet_Car_Makers_And_Car_TypesLazyQuery>;
export type Get_Car_Makers_And_Car_TypesQueryResult = ApolloReactCommon.QueryResult<Get_Car_Makers_And_Car_TypesQuery, Get_Car_Makers_And_Car_TypesQueryVariables>;
export const Update_CarDocument = gql`
    mutation UPDATE_CAR($carObject: [car_insert_input!]!) {
  insert_car(
    objects: $carObject
    on_conflict: {constraint: car_pkey, update_columns: [model, row, type, prefix, postfix, year, maker, account]}
  ) {
    affected_rows
  }
}
    `;
export type Update_CarMutationFn = ApolloReactCommon.MutationFunction<Update_CarMutation, Update_CarMutationVariables>;

/**
 * __useUpdate_CarMutation__
 *
 * To run a mutation, you first call `useUpdate_CarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_CarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarMutation, { data, loading, error }] = useUpdate_CarMutation({
 *   variables: {
 *      carObject: // value for 'carObject'
 *   },
 * });
 */
export function useUpdate_CarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_CarMutation, Update_CarMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_CarMutation, Update_CarMutationVariables>(Update_CarDocument, baseOptions);
      }
export type Update_CarMutationHookResult = ReturnType<typeof useUpdate_CarMutation>;
export type Update_CarMutationResult = ApolloReactCommon.MutationResult<Update_CarMutation>;
export type Update_CarMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_CarMutation, Update_CarMutationVariables>;
export const Insert_CarDocument = gql`
    mutation INSERT_CAR($carObject: [car_insert_input!]!) {
  insert_car(objects: $carObject) {
    returning {
      id
    }
  }
}
    `;
export type Insert_CarMutationFn = ApolloReactCommon.MutationFunction<Insert_CarMutation, Insert_CarMutationVariables>;

/**
 * __useInsert_CarMutation__
 *
 * To run a mutation, you first call `useInsert_CarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_CarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCarMutation, { data, loading, error }] = useInsert_CarMutation({
 *   variables: {
 *      carObject: // value for 'carObject'
 *   },
 * });
 */
export function useInsert_CarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_CarMutation, Insert_CarMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_CarMutation, Insert_CarMutationVariables>(Insert_CarDocument, baseOptions);
      }
export type Insert_CarMutationHookResult = ReturnType<typeof useInsert_CarMutation>;
export type Insert_CarMutationResult = ApolloReactCommon.MutationResult<Insert_CarMutation>;
export type Insert_CarMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_CarMutation, Insert_CarMutationVariables>;
export const Get_Car_MakerDocument = gql`
    query GET_CAR_MAKER {
  car_makers: car_maker {
    id
    name_kr
    name_us
    origin
    car_origin {
      name_kr
      name_us
    }
  }
}
    `;

/**
 * __useGet_Car_MakerQuery__
 *
 * To run a query within a React component, call `useGet_Car_MakerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Car_MakerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Car_MakerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Car_MakerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Car_MakerQuery, Get_Car_MakerQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Car_MakerQuery, Get_Car_MakerQueryVariables>(Get_Car_MakerDocument, baseOptions);
      }
export function useGet_Car_MakerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Car_MakerQuery, Get_Car_MakerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Car_MakerQuery, Get_Car_MakerQueryVariables>(Get_Car_MakerDocument, baseOptions);
        }
export type Get_Car_MakerQueryHookResult = ReturnType<typeof useGet_Car_MakerQuery>;
export type Get_Car_MakerLazyQueryHookResult = ReturnType<typeof useGet_Car_MakerLazyQuery>;
export type Get_Car_MakerQueryResult = ApolloReactCommon.QueryResult<Get_Car_MakerQuery, Get_Car_MakerQueryVariables>;
export const Get_Car_Maker_DetailDocument = gql`
    query GET_CAR_MAKER_DETAIL($id: uuid) {
  car_maker(where: {id: {_eq: $id}}) {
    id
    name_kr
    name_us
    origin
    car_origin {
      name_kr
      name_us
    }
  }
}
    `;

/**
 * __useGet_Car_Maker_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Car_Maker_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Car_Maker_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Car_Maker_DetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Car_Maker_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Car_Maker_DetailQuery, Get_Car_Maker_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Car_Maker_DetailQuery, Get_Car_Maker_DetailQueryVariables>(Get_Car_Maker_DetailDocument, baseOptions);
      }
export function useGet_Car_Maker_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Car_Maker_DetailQuery, Get_Car_Maker_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Car_Maker_DetailQuery, Get_Car_Maker_DetailQueryVariables>(Get_Car_Maker_DetailDocument, baseOptions);
        }
export type Get_Car_Maker_DetailQueryHookResult = ReturnType<typeof useGet_Car_Maker_DetailQuery>;
export type Get_Car_Maker_DetailLazyQueryHookResult = ReturnType<typeof useGet_Car_Maker_DetailLazyQuery>;
export type Get_Car_Maker_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Car_Maker_DetailQuery, Get_Car_Maker_DetailQueryVariables>;
export const Get_Car_OriginDocument = gql`
    query GET_CAR_ORIGIN {
  car_origin {
    id
    name_kr
    name_us
  }
}
    `;

/**
 * __useGet_Car_OriginQuery__
 *
 * To run a query within a React component, call `useGet_Car_OriginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Car_OriginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Car_OriginQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Car_OriginQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Car_OriginQuery, Get_Car_OriginQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Car_OriginQuery, Get_Car_OriginQueryVariables>(Get_Car_OriginDocument, baseOptions);
      }
export function useGet_Car_OriginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Car_OriginQuery, Get_Car_OriginQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Car_OriginQuery, Get_Car_OriginQueryVariables>(Get_Car_OriginDocument, baseOptions);
        }
export type Get_Car_OriginQueryHookResult = ReturnType<typeof useGet_Car_OriginQuery>;
export type Get_Car_OriginLazyQueryHookResult = ReturnType<typeof useGet_Car_OriginLazyQuery>;
export type Get_Car_OriginQueryResult = ApolloReactCommon.QueryResult<Get_Car_OriginQuery, Get_Car_OriginQueryVariables>;
export const Update_Car_MakerDocument = gql`
    mutation UPDATE_CAR_MAKER($carMakerId: uuid!, $newKrBrand: String, $newUsBrand: String, $newOrigin: uuid) {
  update_car_maker_by_pk(
    pk_columns: {id: $carMakerId}
    _set: {name_kr: $newKrBrand, name_us: $newUsBrand, origin: $newOrigin}
  ) {
    name_kr
    name_us
    origin
  }
}
    `;
export type Update_Car_MakerMutationFn = ApolloReactCommon.MutationFunction<Update_Car_MakerMutation, Update_Car_MakerMutationVariables>;

/**
 * __useUpdate_Car_MakerMutation__
 *
 * To run a mutation, you first call `useUpdate_Car_MakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Car_MakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarMakerMutation, { data, loading, error }] = useUpdate_Car_MakerMutation({
 *   variables: {
 *      carMakerId: // value for 'carMakerId'
 *      newKrBrand: // value for 'newKrBrand'
 *      newUsBrand: // value for 'newUsBrand'
 *      newOrigin: // value for 'newOrigin'
 *   },
 * });
 */
export function useUpdate_Car_MakerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Car_MakerMutation, Update_Car_MakerMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Car_MakerMutation, Update_Car_MakerMutationVariables>(Update_Car_MakerDocument, baseOptions);
      }
export type Update_Car_MakerMutationHookResult = ReturnType<typeof useUpdate_Car_MakerMutation>;
export type Update_Car_MakerMutationResult = ApolloReactCommon.MutationResult<Update_Car_MakerMutation>;
export type Update_Car_MakerMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Car_MakerMutation, Update_Car_MakerMutationVariables>;
export const Insert_Car_MakerDocument = gql`
    mutation INSERT_CAR_MAKER($newKrBrand: String, $newUsBrand: String, $newOrigin: uuid) {
  insert_car_maker_one(
    object: {name_kr: $newKrBrand, name_us: $newUsBrand, origin: $newOrigin}
  ) {
    id
  }
}
    `;
export type Insert_Car_MakerMutationFn = ApolloReactCommon.MutationFunction<Insert_Car_MakerMutation, Insert_Car_MakerMutationVariables>;

/**
 * __useInsert_Car_MakerMutation__
 *
 * To run a mutation, you first call `useInsert_Car_MakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Car_MakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCarMakerMutation, { data, loading, error }] = useInsert_Car_MakerMutation({
 *   variables: {
 *      newKrBrand: // value for 'newKrBrand'
 *      newUsBrand: // value for 'newUsBrand'
 *      newOrigin: // value for 'newOrigin'
 *   },
 * });
 */
export function useInsert_Car_MakerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_Car_MakerMutation, Insert_Car_MakerMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_Car_MakerMutation, Insert_Car_MakerMutationVariables>(Insert_Car_MakerDocument, baseOptions);
      }
export type Insert_Car_MakerMutationHookResult = ReturnType<typeof useInsert_Car_MakerMutation>;
export type Insert_Car_MakerMutationResult = ApolloReactCommon.MutationResult<Insert_Car_MakerMutation>;
export type Insert_Car_MakerMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_Car_MakerMutation, Insert_Car_MakerMutationVariables>;
export const _Check_Cart_ExistenceDocument = gql`
    query _CHECK_CART_EXISTENCE {
  n_cart {
    id
  }
}
    `;

/**
 * __use_Check_Cart_ExistenceQuery__
 *
 * To run a query within a React component, call `use_Check_Cart_ExistenceQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Check_Cart_ExistenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Check_Cart_ExistenceQuery({
 *   variables: {
 *   },
 * });
 */
export function use_Check_Cart_ExistenceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Check_Cart_ExistenceQuery, _Check_Cart_ExistenceQueryVariables>) {
        return ApolloReactHooks.useQuery<_Check_Cart_ExistenceQuery, _Check_Cart_ExistenceQueryVariables>(_Check_Cart_ExistenceDocument, baseOptions);
      }
export function use_Check_Cart_ExistenceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Check_Cart_ExistenceQuery, _Check_Cart_ExistenceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Check_Cart_ExistenceQuery, _Check_Cart_ExistenceQueryVariables>(_Check_Cart_ExistenceDocument, baseOptions);
        }
export type _Check_Cart_ExistenceQueryHookResult = ReturnType<typeof use_Check_Cart_ExistenceQuery>;
export type _Check_Cart_ExistenceLazyQueryHookResult = ReturnType<typeof use_Check_Cart_ExistenceLazyQuery>;
export type _Check_Cart_ExistenceQueryResult = ApolloReactCommon.QueryResult<_Check_Cart_ExistenceQuery, _Check_Cart_ExistenceQueryVariables>;
export const _Create_CartDocument = gql`
    mutation _CREATE_CART($cartObject: n_cart_insert_input!) {
  insert_n_cart_one(object: $cartObject) {
    id
  }
}
    `;
export type _Create_CartMutationFn = ApolloReactCommon.MutationFunction<_Create_CartMutation, _Create_CartMutationVariables>;

/**
 * __use_Create_CartMutation__
 *
 * To run a mutation, you first call `use_Create_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_Create_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = use_Create_CartMutation({
 *   variables: {
 *      cartObject: // value for 'cartObject'
 *   },
 * });
 */
export function use_Create_CartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_Create_CartMutation, _Create_CartMutationVariables>) {
        return ApolloReactHooks.useMutation<_Create_CartMutation, _Create_CartMutationVariables>(_Create_CartDocument, baseOptions);
      }
export type _Create_CartMutationHookResult = ReturnType<typeof use_Create_CartMutation>;
export type _Create_CartMutationResult = ApolloReactCommon.MutationResult<_Create_CartMutation>;
export type _Create_CartMutationOptions = ApolloReactCommon.BaseMutationOptions<_Create_CartMutation, _Create_CartMutationVariables>;
export const _Get_CartDocument = gql`
    query _GET_CART($cartId: uuid!) {
  n_cart_by_pk(id: $cartId) {
    id
    session
    account
    cart_items(where: {deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
      id
      created_at
      updated_at
      car
      carByCar {
        model
        car_maker {
          name_kr
        }
      }
      car_sunroof_type
      checked
      commission_rate
      date_time
      deleted_at
      price_discount
      price_extra
      price_final
      price_payout
      price_payout_without_vat
      price_product
      product_item
      remark
      store
      storeByStore {
        name
        phone_business
      }
      updated_by
      package_detail {
        name
        package_type {
          description
        }
      }
      product_item_detail {
        attribute
        price
        productByProduct {
          product_brand {
            description
          }
          name
          product_type {
            value
            description
          }
        }
      }
    }
  }
}
    `;

/**
 * __use_Get_CartQuery__
 *
 * To run a query within a React component, call `use_Get_CartQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_CartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_CartQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function use_Get_CartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_CartQuery, _Get_CartQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_CartQuery, _Get_CartQueryVariables>(_Get_CartDocument, baseOptions);
      }
export function use_Get_CartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_CartQuery, _Get_CartQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_CartQuery, _Get_CartQueryVariables>(_Get_CartDocument, baseOptions);
        }
export type _Get_CartQueryHookResult = ReturnType<typeof use_Get_CartQuery>;
export type _Get_CartLazyQueryHookResult = ReturnType<typeof use_Get_CartLazyQuery>;
export type _Get_CartQueryResult = ApolloReactCommon.QueryResult<_Get_CartQuery, _Get_CartQueryVariables>;
export const _Remove_Cart_Item_From_CartDocument = gql`
    mutation _REMOVE_CART_ITEM_FROM_CART($deletedAt: timestamptz!) {
  update_cart_item(where: {_or: []}, _set: {deleted_at: $deletedAt}) {
    affected_rows
  }
}
    `;
export type _Remove_Cart_Item_From_CartMutationFn = ApolloReactCommon.MutationFunction<_Remove_Cart_Item_From_CartMutation, _Remove_Cart_Item_From_CartMutationVariables>;

/**
 * __use_Remove_Cart_Item_From_CartMutation__
 *
 * To run a mutation, you first call `use_Remove_Cart_Item_From_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_Remove_Cart_Item_From_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCartItemFromCartMutation, { data, loading, error }] = use_Remove_Cart_Item_From_CartMutation({
 *   variables: {
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function use_Remove_Cart_Item_From_CartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_Remove_Cart_Item_From_CartMutation, _Remove_Cart_Item_From_CartMutationVariables>) {
        return ApolloReactHooks.useMutation<_Remove_Cart_Item_From_CartMutation, _Remove_Cart_Item_From_CartMutationVariables>(_Remove_Cart_Item_From_CartDocument, baseOptions);
      }
export type _Remove_Cart_Item_From_CartMutationHookResult = ReturnType<typeof use_Remove_Cart_Item_From_CartMutation>;
export type _Remove_Cart_Item_From_CartMutationResult = ApolloReactCommon.MutationResult<_Remove_Cart_Item_From_CartMutation>;
export type _Remove_Cart_Item_From_CartMutationOptions = ApolloReactCommon.BaseMutationOptions<_Remove_Cart_Item_From_CartMutation, _Remove_Cart_Item_From_CartMutationVariables>;
export const _Get_Cart_ItemDocument = gql`
    query _GET_CART_ITEM($cartItemId: uuid!) {
  cart_item_by_pk(id: $cartItemId) {
    id
    created_at
    updated_at
    car
    carByCar {
      id
      model
      type
      car_maker {
        name_kr
        name_us
        car_origin {
          name_kr
          name_us
        }
      }
    }
    car_sunroof_type
    checked
    commission_rate
    date_time
    deleted_at
    price_discount
    price_extra
    price_final
    price_payout
    price_payout_without_vat
    price_product
    product_item
    remark
    store
    storeByStore {
      name
    }
    updated_by
    package_detail {
      id
      name
      package_type {
        description
      }
    }
    product_item_detail {
      id
      attribute
      price
      productByProduct {
        product_brand {
          description
        }
        name
        product_type {
          value
          description
        }
      }
    }
  }
}
    `;

/**
 * __use_Get_Cart_ItemQuery__
 *
 * To run a query within a React component, call `use_Get_Cart_ItemQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_Cart_ItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_Cart_ItemQuery({
 *   variables: {
 *      cartItemId: // value for 'cartItemId'
 *   },
 * });
 */
export function use_Get_Cart_ItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_Cart_ItemQuery, _Get_Cart_ItemQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_Cart_ItemQuery, _Get_Cart_ItemQueryVariables>(_Get_Cart_ItemDocument, baseOptions);
      }
export function use_Get_Cart_ItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_Cart_ItemQuery, _Get_Cart_ItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_Cart_ItemQuery, _Get_Cart_ItemQueryVariables>(_Get_Cart_ItemDocument, baseOptions);
        }
export type _Get_Cart_ItemQueryHookResult = ReturnType<typeof use_Get_Cart_ItemQuery>;
export type _Get_Cart_ItemLazyQueryHookResult = ReturnType<typeof use_Get_Cart_ItemLazyQuery>;
export type _Get_Cart_ItemQueryResult = ApolloReactCommon.QueryResult<_Get_Cart_ItemQuery, _Get_Cart_ItemQueryVariables>;
export const _Update_Cart_Item_To_CartDocument = gql`
    mutation _UPDATE_CART_ITEM_TO_CART($cartItemObject: cart_item_insert_input!) {
  insert_cart_item_one(
    object: $cartItemObject
    on_conflict: {constraint: cart_item_pkey, update_columns: [car, car_sunroof_type, store, price_product, price_discount, price_extra, price_final, price_payout, price_payout_without_vat, commission_rate, product_item, updated_by, date_time, quantity, remark]}
  ) {
    id
  }
}
    `;
export type _Update_Cart_Item_To_CartMutationFn = ApolloReactCommon.MutationFunction<_Update_Cart_Item_To_CartMutation, _Update_Cart_Item_To_CartMutationVariables>;

/**
 * __use_Update_Cart_Item_To_CartMutation__
 *
 * To run a mutation, you first call `use_Update_Cart_Item_To_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_Update_Cart_Item_To_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemToCartMutation, { data, loading, error }] = use_Update_Cart_Item_To_CartMutation({
 *   variables: {
 *      cartItemObject: // value for 'cartItemObject'
 *   },
 * });
 */
export function use_Update_Cart_Item_To_CartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_Update_Cart_Item_To_CartMutation, _Update_Cart_Item_To_CartMutationVariables>) {
        return ApolloReactHooks.useMutation<_Update_Cart_Item_To_CartMutation, _Update_Cart_Item_To_CartMutationVariables>(_Update_Cart_Item_To_CartDocument, baseOptions);
      }
export type _Update_Cart_Item_To_CartMutationHookResult = ReturnType<typeof use_Update_Cart_Item_To_CartMutation>;
export type _Update_Cart_Item_To_CartMutationResult = ApolloReactCommon.MutationResult<_Update_Cart_Item_To_CartMutation>;
export type _Update_Cart_Item_To_CartMutationOptions = ApolloReactCommon.BaseMutationOptions<_Update_Cart_Item_To_CartMutation, _Update_Cart_Item_To_CartMutationVariables>;
export const _Add_Cart_Item_To_CartDocument = gql`
    mutation _ADD_CART_ITEM_TO_CART($cartItemObject: cart_item_insert_input!) {
  insert_cart_item_one(object: $cartItemObject) {
    id
  }
}
    `;
export type _Add_Cart_Item_To_CartMutationFn = ApolloReactCommon.MutationFunction<_Add_Cart_Item_To_CartMutation, _Add_Cart_Item_To_CartMutationVariables>;

/**
 * __use_Add_Cart_Item_To_CartMutation__
 *
 * To run a mutation, you first call `use_Add_Cart_Item_To_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_Add_Cart_Item_To_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemToCartMutation, { data, loading, error }] = use_Add_Cart_Item_To_CartMutation({
 *   variables: {
 *      cartItemObject: // value for 'cartItemObject'
 *   },
 * });
 */
export function use_Add_Cart_Item_To_CartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_Add_Cart_Item_To_CartMutation, _Add_Cart_Item_To_CartMutationVariables>) {
        return ApolloReactHooks.useMutation<_Add_Cart_Item_To_CartMutation, _Add_Cart_Item_To_CartMutationVariables>(_Add_Cart_Item_To_CartDocument, baseOptions);
      }
export type _Add_Cart_Item_To_CartMutationHookResult = ReturnType<typeof use_Add_Cart_Item_To_CartMutation>;
export type _Add_Cart_Item_To_CartMutationResult = ApolloReactCommon.MutationResult<_Add_Cart_Item_To_CartMutation>;
export type _Add_Cart_Item_To_CartMutationOptions = ApolloReactCommon.BaseMutationOptions<_Add_Cart_Item_To_CartMutation, _Add_Cart_Item_To_CartMutationVariables>;
export const All_Product_And_Product_ItemsDocument = gql`
    query ALL_PRODUCT_AND_PRODUCT_ITEMS {
  product(order_by: {name: asc}) {
    id
    name
    product_brand {
      value
      description
    }
    product_type {
      value
      description
    }
  }
  product_type(order_by: {description: asc}) {
    value
    description
  }
  product_item {
    id
    sku
  }
}
    `;

/**
 * __useAll_Product_And_Product_ItemsQuery__
 *
 * To run a query within a React component, call `useAll_Product_And_Product_ItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAll_Product_And_Product_ItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAll_Product_And_Product_ItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAll_Product_And_Product_ItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<All_Product_And_Product_ItemsQuery, All_Product_And_Product_ItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<All_Product_And_Product_ItemsQuery, All_Product_And_Product_ItemsQueryVariables>(All_Product_And_Product_ItemsDocument, baseOptions);
      }
export function useAll_Product_And_Product_ItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<All_Product_And_Product_ItemsQuery, All_Product_And_Product_ItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<All_Product_And_Product_ItemsQuery, All_Product_And_Product_ItemsQueryVariables>(All_Product_And_Product_ItemsDocument, baseOptions);
        }
export type All_Product_And_Product_ItemsQueryHookResult = ReturnType<typeof useAll_Product_And_Product_ItemsQuery>;
export type All_Product_And_Product_ItemsLazyQueryHookResult = ReturnType<typeof useAll_Product_And_Product_ItemsLazyQuery>;
export type All_Product_And_Product_ItemsQueryResult = ApolloReactCommon.QueryResult<All_Product_And_Product_ItemsQuery, All_Product_And_Product_ItemsQueryVariables>;
export const Get_CurationDocument = gql`
    query GET_CURATION {
  curation {
    id
    list
  }
}
    `;

/**
 * __useGet_CurationQuery__
 *
 * To run a query within a React component, call `useGet_CurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_CurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_CurationQuery, Get_CurationQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_CurationQuery, Get_CurationQueryVariables>(Get_CurationDocument, baseOptions);
      }
export function useGet_CurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_CurationQuery, Get_CurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_CurationQuery, Get_CurationQueryVariables>(Get_CurationDocument, baseOptions);
        }
export type Get_CurationQueryHookResult = ReturnType<typeof useGet_CurationQuery>;
export type Get_CurationLazyQueryHookResult = ReturnType<typeof useGet_CurationLazyQuery>;
export type Get_CurationQueryResult = ApolloReactCommon.QueryResult<Get_CurationQuery, Get_CurationQueryVariables>;
export const Get_BlogDocument = gql`
    query GET_BLOG {
  gallery {
    id
    blogPostByBlogPost {
      title
      id
    }
  }
  store {
    name
    id
  }
}
    `;

/**
 * __useGet_BlogQuery__
 *
 * To run a query within a React component, call `useGet_BlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_BlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_BlogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_BlogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_BlogQuery, Get_BlogQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_BlogQuery, Get_BlogQueryVariables>(Get_BlogDocument, baseOptions);
      }
export function useGet_BlogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_BlogQuery, Get_BlogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_BlogQuery, Get_BlogQueryVariables>(Get_BlogDocument, baseOptions);
        }
export type Get_BlogQueryHookResult = ReturnType<typeof useGet_BlogQuery>;
export type Get_BlogLazyQueryHookResult = ReturnType<typeof useGet_BlogLazyQuery>;
export type Get_BlogQueryResult = ApolloReactCommon.QueryResult<Get_BlogQuery, Get_BlogQueryVariables>;
export const Update_CurationDocument = gql`
    mutation UPDATE_CURATION($id: uuid!, $account: uuid!, $list: jsonb!) {
  update_curation(where: {id: {_eq: $id}}, _set: {list: $list, account: $account}) {
    affected_rows
  }
}
    `;
export type Update_CurationMutationFn = ApolloReactCommon.MutationFunction<Update_CurationMutation, Update_CurationMutationVariables>;

/**
 * __useUpdate_CurationMutation__
 *
 * To run a mutation, you first call `useUpdate_CurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_CurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurationMutation, { data, loading, error }] = useUpdate_CurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      account: // value for 'account'
 *      list: // value for 'list'
 *   },
 * });
 */
export function useUpdate_CurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_CurationMutation, Update_CurationMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_CurationMutation, Update_CurationMutationVariables>(Update_CurationDocument, baseOptions);
      }
export type Update_CurationMutationHookResult = ReturnType<typeof useUpdate_CurationMutation>;
export type Update_CurationMutationResult = ApolloReactCommon.MutationResult<Update_CurationMutation>;
export type Update_CurationMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_CurationMutation, Update_CurationMutationVariables>;
export const Get_Account_Performance_For_ReservationDocument = gql`
    query GET_ACCOUNT_PERFORMANCE_FOR_RESERVATION($start_date: timestamp, $end_date: timestamp) {
  get_account_performance_for_reservation(
    args: {end_date: $end_date, start_date: $start_date}
  ) {
    account
    count
    email
    name
    role
    status
  }
}
    `;

/**
 * __useGet_Account_Performance_For_ReservationQuery__
 *
 * To run a query within a React component, call `useGet_Account_Performance_For_ReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Account_Performance_For_ReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Account_Performance_For_ReservationQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useGet_Account_Performance_For_ReservationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Account_Performance_For_ReservationQuery, Get_Account_Performance_For_ReservationQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Account_Performance_For_ReservationQuery, Get_Account_Performance_For_ReservationQueryVariables>(Get_Account_Performance_For_ReservationDocument, baseOptions);
      }
export function useGet_Account_Performance_For_ReservationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Account_Performance_For_ReservationQuery, Get_Account_Performance_For_ReservationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Account_Performance_For_ReservationQuery, Get_Account_Performance_For_ReservationQueryVariables>(Get_Account_Performance_For_ReservationDocument, baseOptions);
        }
export type Get_Account_Performance_For_ReservationQueryHookResult = ReturnType<typeof useGet_Account_Performance_For_ReservationQuery>;
export type Get_Account_Performance_For_ReservationLazyQueryHookResult = ReturnType<typeof useGet_Account_Performance_For_ReservationLazyQuery>;
export type Get_Account_Performance_For_ReservationQueryResult = ApolloReactCommon.QueryResult<Get_Account_Performance_For_ReservationQuery, Get_Account_Performance_For_ReservationQueryVariables>;
export const Get_Account_Performance_For_First_CallDocument = gql`
    query GET_ACCOUNT_PERFORMANCE_FOR_FIRST_CALL($start_date: timestamp, $end_date: timestamp) {
  get_account_performance_for_first_call(
    args: {end_date: $end_date, start_date: $start_date}
  ) {
    account
    count
    email
    name
    role
    status
  }
}
    `;

/**
 * __useGet_Account_Performance_For_First_CallQuery__
 *
 * To run a query within a React component, call `useGet_Account_Performance_For_First_CallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Account_Performance_For_First_CallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Account_Performance_For_First_CallQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useGet_Account_Performance_For_First_CallQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Account_Performance_For_First_CallQuery, Get_Account_Performance_For_First_CallQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Account_Performance_For_First_CallQuery, Get_Account_Performance_For_First_CallQueryVariables>(Get_Account_Performance_For_First_CallDocument, baseOptions);
      }
export function useGet_Account_Performance_For_First_CallLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Account_Performance_For_First_CallQuery, Get_Account_Performance_For_First_CallQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Account_Performance_For_First_CallQuery, Get_Account_Performance_For_First_CallQueryVariables>(Get_Account_Performance_For_First_CallDocument, baseOptions);
        }
export type Get_Account_Performance_For_First_CallQueryHookResult = ReturnType<typeof useGet_Account_Performance_For_First_CallQuery>;
export type Get_Account_Performance_For_First_CallLazyQueryHookResult = ReturnType<typeof useGet_Account_Performance_For_First_CallLazyQuery>;
export type Get_Account_Performance_For_First_CallQueryResult = ApolloReactCommon.QueryResult<Get_Account_Performance_For_First_CallQuery, Get_Account_Performance_For_First_CallQueryVariables>;
export const Get_Account_Performance_By_Used_Car_StatusDocument = gql`
    query GET_ACCOUNT_PERFORMANCE_BY_USED_CAR_STATUS($start_date: timestamp, $end_date: timestamp) {
  get_account_performance_by_used_car_status(
    args: {end_date: $end_date, start_date: $start_date}
  ) {
    account
    count
    email
    name
    role
    status
    used_car_status
  }
}
    `;

/**
 * __useGet_Account_Performance_By_Used_Car_StatusQuery__
 *
 * To run a query within a React component, call `useGet_Account_Performance_By_Used_Car_StatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Account_Performance_By_Used_Car_StatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Account_Performance_By_Used_Car_StatusQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useGet_Account_Performance_By_Used_Car_StatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Account_Performance_By_Used_Car_StatusQuery, Get_Account_Performance_By_Used_Car_StatusQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Account_Performance_By_Used_Car_StatusQuery, Get_Account_Performance_By_Used_Car_StatusQueryVariables>(Get_Account_Performance_By_Used_Car_StatusDocument, baseOptions);
      }
export function useGet_Account_Performance_By_Used_Car_StatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Account_Performance_By_Used_Car_StatusQuery, Get_Account_Performance_By_Used_Car_StatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Account_Performance_By_Used_Car_StatusQuery, Get_Account_Performance_By_Used_Car_StatusQueryVariables>(Get_Account_Performance_By_Used_Car_StatusDocument, baseOptions);
        }
export type Get_Account_Performance_By_Used_Car_StatusQueryHookResult = ReturnType<typeof useGet_Account_Performance_By_Used_Car_StatusQuery>;
export type Get_Account_Performance_By_Used_Car_StatusLazyQueryHookResult = ReturnType<typeof useGet_Account_Performance_By_Used_Car_StatusLazyQuery>;
export type Get_Account_Performance_By_Used_Car_StatusQueryResult = ApolloReactCommon.QueryResult<Get_Account_Performance_By_Used_Car_StatusQuery, Get_Account_Performance_By_Used_Car_StatusQueryVariables>;
export const _Get_OrderDocument = gql`
    query _GET_ORDER($orderId: uuid!) {
  order_by_pk(id: $orderId) {
    id
    created_at
    updated_at
    account
    name
    email
    phone
    status
    car
    carByCar {
      id
      model
      type
      car_maker {
        name_kr
        name_us
        car_origin {
          name_kr
          name_us
        }
      }
    }
    car_sunroof_type
    commission_rate
    date_time
    deleted_at
    price_discount
    price_extra
    price_final
    price_payout
    price_payout_without_vat
    price_product
    product_item
    remark
    store
    storeByStore {
      name
    }
    package_detail {
      id
      name
      package_type {
        description
      }
    }
    product_item_detail {
      id
      attribute
      price
      productByProduct {
        product_brand {
          description
        }
        name
        product_type {
          value
          description
        }
      }
    }
  }
}
    `;

/**
 * __use_Get_OrderQuery__
 *
 * To run a query within a React component, call `use_Get_OrderQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_OrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_OrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function use_Get_OrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_OrderQuery, _Get_OrderQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_OrderQuery, _Get_OrderQueryVariables>(_Get_OrderDocument, baseOptions);
      }
export function use_Get_OrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_OrderQuery, _Get_OrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_OrderQuery, _Get_OrderQueryVariables>(_Get_OrderDocument, baseOptions);
        }
export type _Get_OrderQueryHookResult = ReturnType<typeof use_Get_OrderQuery>;
export type _Get_OrderLazyQueryHookResult = ReturnType<typeof use_Get_OrderLazyQuery>;
export type _Get_OrderQueryResult = ApolloReactCommon.QueryResult<_Get_OrderQuery, _Get_OrderQueryVariables>;
export const _Update_OrderDocument = gql`
    mutation _UPDATE_ORDER($orderObject: order_insert_input!) {
  insert_order_one(
    object: $orderObject
    on_conflict: {constraint: order_pkey, update_columns: [car, car_sunroof_type, store, price_product, price_discount, price_extra, price_final, price_payout, price_payout_without_vat, commission_rate, product_item, date_time, remark]}
  ) {
    id
  }
}
    `;
export type _Update_OrderMutationFn = ApolloReactCommon.MutationFunction<_Update_OrderMutation, _Update_OrderMutationVariables>;

/**
 * __use_Update_OrderMutation__
 *
 * To run a mutation, you first call `use_Update_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_Update_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = use_Update_OrderMutation({
 *   variables: {
 *      orderObject: // value for 'orderObject'
 *   },
 * });
 */
export function use_Update_OrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_Update_OrderMutation, _Update_OrderMutationVariables>) {
        return ApolloReactHooks.useMutation<_Update_OrderMutation, _Update_OrderMutationVariables>(_Update_OrderDocument, baseOptions);
      }
export type _Update_OrderMutationHookResult = ReturnType<typeof use_Update_OrderMutation>;
export type _Update_OrderMutationResult = ApolloReactCommon.MutationResult<_Update_OrderMutation>;
export type _Update_OrderMutationOptions = ApolloReactCommon.BaseMutationOptions<_Update_OrderMutation, _Update_OrderMutationVariables>;
export const Get_GalleryDocument = gql`
    query GET_GALLERY($offset: Int!, $limit: Int!, $keyword: String!) {
  gallery: gallery(
    where: {_and: [{deleted_at: {_is_null: true}}, {_or: [{blogPostByBlogPost: {title: {_like: $keyword}}}, {storeByStore: {name: {_like: $keyword}}}, {storeByStore: {llumar_store_name: {_like: $keyword}}}, {carByCar: {model: {_like: $keyword}}}, {carByCar: {car_maker: {name_kr: {_like: $keyword}}}}, {carByCar: {car_maker: {name_us: {_like: $keyword}}}}]}]}
    order_by: {created_at: desc}
    offset: $offset
    limit: $limit
  ) {
    id
    created_at
    deleted_at
    status
    carByCar {
      car_maker {
        name_kr
        car_origin {
          name_kr
        }
      }
      model
    }
    store
    storeByStore {
      name
      phone_business
    }
    price
    blogPostByBlogPost {
      title
    }
    sent_at
  }
  gallerys_count: gallery_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_GalleryQuery__
 *
 * To run a query within a React component, call `useGet_GalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_GalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_GalleryQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGet_GalleryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_GalleryQuery, Get_GalleryQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_GalleryQuery, Get_GalleryQueryVariables>(Get_GalleryDocument, baseOptions);
      }
export function useGet_GalleryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_GalleryQuery, Get_GalleryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_GalleryQuery, Get_GalleryQueryVariables>(Get_GalleryDocument, baseOptions);
        }
export type Get_GalleryQueryHookResult = ReturnType<typeof useGet_GalleryQuery>;
export type Get_GalleryLazyQueryHookResult = ReturnType<typeof useGet_GalleryLazyQuery>;
export type Get_GalleryQueryResult = ApolloReactCommon.QueryResult<Get_GalleryQuery, Get_GalleryQueryVariables>;
export const Delete_GalleryDocument = gql`
    mutation DELETE_GALLERY($id: uuid!, $now: timestamptz) {
  update_gallery_by_pk(pk_columns: {id: $id}, _set: {deleted_at: $now}) {
    id
  }
}
    `;
export type Delete_GalleryMutationFn = ApolloReactCommon.MutationFunction<Delete_GalleryMutation, Delete_GalleryMutationVariables>;

/**
 * __useDelete_GalleryMutation__
 *
 * To run a mutation, you first call `useDelete_GalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_GalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGalleryMutation, { data, loading, error }] = useDelete_GalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useDelete_GalleryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_GalleryMutation, Delete_GalleryMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_GalleryMutation, Delete_GalleryMutationVariables>(Delete_GalleryDocument, baseOptions);
      }
export type Delete_GalleryMutationHookResult = ReturnType<typeof useDelete_GalleryMutation>;
export type Delete_GalleryMutationResult = ApolloReactCommon.MutationResult<Delete_GalleryMutation>;
export type Delete_GalleryMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_GalleryMutation, Delete_GalleryMutationVariables>;
export const Update_Gallery_Sent_AtDocument = gql`
    mutation UPDATE_GALLERY_SENT_AT($id: uuid!, $now: timestamptz) {
  update_gallery_by_pk(pk_columns: {id: $id}, _set: {sent_at: $now}) {
    id
  }
}
    `;
export type Update_Gallery_Sent_AtMutationFn = ApolloReactCommon.MutationFunction<Update_Gallery_Sent_AtMutation, Update_Gallery_Sent_AtMutationVariables>;

/**
 * __useUpdate_Gallery_Sent_AtMutation__
 *
 * To run a mutation, you first call `useUpdate_Gallery_Sent_AtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Gallery_Sent_AtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGallerySentAtMutation, { data, loading, error }] = useUpdate_Gallery_Sent_AtMutation({
 *   variables: {
 *      id: // value for 'id'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useUpdate_Gallery_Sent_AtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Gallery_Sent_AtMutation, Update_Gallery_Sent_AtMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Gallery_Sent_AtMutation, Update_Gallery_Sent_AtMutationVariables>(Update_Gallery_Sent_AtDocument, baseOptions);
      }
export type Update_Gallery_Sent_AtMutationHookResult = ReturnType<typeof useUpdate_Gallery_Sent_AtMutation>;
export type Update_Gallery_Sent_AtMutationResult = ApolloReactCommon.MutationResult<Update_Gallery_Sent_AtMutation>;
export type Update_Gallery_Sent_AtMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Gallery_Sent_AtMutation, Update_Gallery_Sent_AtMutationVariables>;
export const Insert_GalleryDocument = gql`
    mutation INSERT_GALLERY($object: gallery_insert_input!) {
  insert_gallery_one(object: $object) {
    id
  }
}
    `;
export type Insert_GalleryMutationFn = ApolloReactCommon.MutationFunction<Insert_GalleryMutation, Insert_GalleryMutationVariables>;

/**
 * __useInsert_GalleryMutation__
 *
 * To run a mutation, you first call `useInsert_GalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_GalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGalleryMutation, { data, loading, error }] = useInsert_GalleryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_GalleryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_GalleryMutation, Insert_GalleryMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_GalleryMutation, Insert_GalleryMutationVariables>(Insert_GalleryDocument, baseOptions);
      }
export type Insert_GalleryMutationHookResult = ReturnType<typeof useInsert_GalleryMutation>;
export type Insert_GalleryMutationResult = ApolloReactCommon.MutationResult<Insert_GalleryMutation>;
export type Insert_GalleryMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_GalleryMutation, Insert_GalleryMutationVariables>;
export const Update_GalleryDocument = gql`
    mutation UPDATE_GALLERY($galleryId: uuid!, $blogId: uuid!, $galleryObject: gallery_set_input, $blogObject: blog_post_set_input) {
  update_gallery_by_pk(pk_columns: {id: $galleryId}, _set: $galleryObject) {
    id
  }
  update_blog_post_by_pk(pk_columns: {id: $blogId}, _set: $blogObject) {
    id
  }
}
    `;
export type Update_GalleryMutationFn = ApolloReactCommon.MutationFunction<Update_GalleryMutation, Update_GalleryMutationVariables>;

/**
 * __useUpdate_GalleryMutation__
 *
 * To run a mutation, you first call `useUpdate_GalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_GalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGalleryMutation, { data, loading, error }] = useUpdate_GalleryMutation({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *      blogId: // value for 'blogId'
 *      galleryObject: // value for 'galleryObject'
 *      blogObject: // value for 'blogObject'
 *   },
 * });
 */
export function useUpdate_GalleryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_GalleryMutation, Update_GalleryMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_GalleryMutation, Update_GalleryMutationVariables>(Update_GalleryDocument, baseOptions);
      }
export type Update_GalleryMutationHookResult = ReturnType<typeof useUpdate_GalleryMutation>;
export type Update_GalleryMutationResult = ApolloReactCommon.MutationResult<Update_GalleryMutation>;
export type Update_GalleryMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_GalleryMutation, Update_GalleryMutationVariables>;
export const Hide_GalleryDocument = gql`
    mutation HIDE_GALLERY($id: uuid!, $now: timestamptz) {
  update_gallery_by_pk(pk_columns: {id: $id}, _set: {status: false}) {
    id
  }
}
    `;
export type Hide_GalleryMutationFn = ApolloReactCommon.MutationFunction<Hide_GalleryMutation, Hide_GalleryMutationVariables>;

/**
 * __useHide_GalleryMutation__
 *
 * To run a mutation, you first call `useHide_GalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHide_GalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideGalleryMutation, { data, loading, error }] = useHide_GalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useHide_GalleryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Hide_GalleryMutation, Hide_GalleryMutationVariables>) {
        return ApolloReactHooks.useMutation<Hide_GalleryMutation, Hide_GalleryMutationVariables>(Hide_GalleryDocument, baseOptions);
      }
export type Hide_GalleryMutationHookResult = ReturnType<typeof useHide_GalleryMutation>;
export type Hide_GalleryMutationResult = ApolloReactCommon.MutationResult<Hide_GalleryMutation>;
export type Hide_GalleryMutationOptions = ApolloReactCommon.BaseMutationOptions<Hide_GalleryMutation, Hide_GalleryMutationVariables>;
export const Show_GalleryDocument = gql`
    mutation SHOW_GALLERY($id: uuid!, $now: timestamptz) {
  update_gallery_by_pk(pk_columns: {id: $id}, _set: {status: true}) {
    id
  }
}
    `;
export type Show_GalleryMutationFn = ApolloReactCommon.MutationFunction<Show_GalleryMutation, Show_GalleryMutationVariables>;

/**
 * __useShow_GalleryMutation__
 *
 * To run a mutation, you first call `useShow_GalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShow_GalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [showGalleryMutation, { data, loading, error }] = useShow_GalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useShow_GalleryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Show_GalleryMutation, Show_GalleryMutationVariables>) {
        return ApolloReactHooks.useMutation<Show_GalleryMutation, Show_GalleryMutationVariables>(Show_GalleryDocument, baseOptions);
      }
export type Show_GalleryMutationHookResult = ReturnType<typeof useShow_GalleryMutation>;
export type Show_GalleryMutationResult = ApolloReactCommon.MutationResult<Show_GalleryMutation>;
export type Show_GalleryMutationOptions = ApolloReactCommon.BaseMutationOptions<Show_GalleryMutation, Show_GalleryMutationVariables>;
export const Get_Gallery_ProductDocument = gql`
    query GET_GALLERY_PRODUCT($carOrigin: String = "%", $carType: String = "%") {
  product_front: product_item(
    order_by: {sku: asc}
    where: {_and: [{sku: {_like: "%front%"}}, {sku: {_like: $carType}}, {sku: {_like: $carOrigin}}]}
  ) {
    id
    sku
    price
  }
  product_sideback: product_item(
    order_by: {sku: asc}
    where: {_and: [{sku: {_nlike: "%front%"}}, {sku: {_nlike: "%sunroof%"}}, {sku: {_like: $carType}}, {sku: {_like: $carOrigin}}]}
  ) {
    id
    sku
    price
  }
}
    `;

/**
 * __useGet_Gallery_ProductQuery__
 *
 * To run a query within a React component, call `useGet_Gallery_ProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Gallery_ProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Gallery_ProductQuery({
 *   variables: {
 *      carOrigin: // value for 'carOrigin'
 *      carType: // value for 'carType'
 *   },
 * });
 */
export function useGet_Gallery_ProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Gallery_ProductQuery, Get_Gallery_ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Gallery_ProductQuery, Get_Gallery_ProductQueryVariables>(Get_Gallery_ProductDocument, baseOptions);
      }
export function useGet_Gallery_ProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Gallery_ProductQuery, Get_Gallery_ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Gallery_ProductQuery, Get_Gallery_ProductQueryVariables>(Get_Gallery_ProductDocument, baseOptions);
        }
export type Get_Gallery_ProductQueryHookResult = ReturnType<typeof useGet_Gallery_ProductQuery>;
export type Get_Gallery_ProductLazyQueryHookResult = ReturnType<typeof useGet_Gallery_ProductLazyQuery>;
export type Get_Gallery_ProductQueryResult = ApolloReactCommon.QueryResult<Get_Gallery_ProductQuery, Get_Gallery_ProductQueryVariables>;
export const Get_Gallery_Detail_InfoDocument = gql`
    query GET_GALLERY_DETAIL_INFO {
  car_maker: car_maker(distinct_on: name_kr) {
    name_kr
  }
  car: car(order_by: {car_maker: {name_kr: asc}, model: asc}) {
    id
    car_maker {
      name_kr
      car_origin {
        name_kr
      }
    }
    car_type {
      value
    }
    model
  }
  store: store {
    id
    name
  }
  carSunroofType: car_sunroof_type {
    value
    description
  }
  product_front: product_item(
    order_by: {sku: asc}
    where: {sku: {_like: "%front%"}}
  ) {
    id
    sku
    price
  }
  product_sideback: product_item(
    order_by: {sku: asc}
    where: {_and: [{sku: {_nlike: "%front%"}}, {sku: {_nlike: "%sunroof%"}}]}
  ) {
    id
    sku
    price
  }
  product_sunroof: product_item(
    order_by: {sku: asc}
    where: {sku: {_like: "%sunroof%"}}
  ) {
    id
    sku
    price
  }
}
    `;

/**
 * __useGet_Gallery_Detail_InfoQuery__
 *
 * To run a query within a React component, call `useGet_Gallery_Detail_InfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Gallery_Detail_InfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Gallery_Detail_InfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Gallery_Detail_InfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Gallery_Detail_InfoQuery, Get_Gallery_Detail_InfoQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Gallery_Detail_InfoQuery, Get_Gallery_Detail_InfoQueryVariables>(Get_Gallery_Detail_InfoDocument, baseOptions);
      }
export function useGet_Gallery_Detail_InfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Gallery_Detail_InfoQuery, Get_Gallery_Detail_InfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Gallery_Detail_InfoQuery, Get_Gallery_Detail_InfoQueryVariables>(Get_Gallery_Detail_InfoDocument, baseOptions);
        }
export type Get_Gallery_Detail_InfoQueryHookResult = ReturnType<typeof useGet_Gallery_Detail_InfoQuery>;
export type Get_Gallery_Detail_InfoLazyQueryHookResult = ReturnType<typeof useGet_Gallery_Detail_InfoLazyQuery>;
export type Get_Gallery_Detail_InfoQueryResult = ApolloReactCommon.QueryResult<Get_Gallery_Detail_InfoQuery, Get_Gallery_Detail_InfoQueryVariables>;
export const Get_Gallery_Car_ModelDocument = gql`
    query GET_GALLERY_CAR_MODEL($carMaker: String) {
  car: car(
    order_by: {model: asc}
    where: {car_maker: {name_kr: {_like: $carMaker}}}
    distinct_on: model
  ) {
    id
    car_maker {
      name_kr
      car_origin {
        name_kr
      }
    }
    car_type {
      value
    }
    model
  }
}
    `;

/**
 * __useGet_Gallery_Car_ModelQuery__
 *
 * To run a query within a React component, call `useGet_Gallery_Car_ModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Gallery_Car_ModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Gallery_Car_ModelQuery({
 *   variables: {
 *      carMaker: // value for 'carMaker'
 *   },
 * });
 */
export function useGet_Gallery_Car_ModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Gallery_Car_ModelQuery, Get_Gallery_Car_ModelQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Gallery_Car_ModelQuery, Get_Gallery_Car_ModelQueryVariables>(Get_Gallery_Car_ModelDocument, baseOptions);
      }
export function useGet_Gallery_Car_ModelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Gallery_Car_ModelQuery, Get_Gallery_Car_ModelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Gallery_Car_ModelQuery, Get_Gallery_Car_ModelQueryVariables>(Get_Gallery_Car_ModelDocument, baseOptions);
        }
export type Get_Gallery_Car_ModelQueryHookResult = ReturnType<typeof useGet_Gallery_Car_ModelQuery>;
export type Get_Gallery_Car_ModelLazyQueryHookResult = ReturnType<typeof useGet_Gallery_Car_ModelLazyQuery>;
export type Get_Gallery_Car_ModelQueryResult = ApolloReactCommon.QueryResult<Get_Gallery_Car_ModelQuery, Get_Gallery_Car_ModelQueryVariables>;
export const Get_Gallery_DetailDocument = gql`
    query GET_GALLERY_DETAIL($id: uuid!) {
  car_maker: car_maker(distinct_on: name_kr) {
    name_kr
  }
  gallery: gallery(where: {id: {_eq: $id}}) {
    id
    created_at
    status
    carByCar {
      id
      type
      car_maker {
        name_kr
        car_origin {
          name_kr
        }
      }
      model
      row
      prefix
      postfix
    }
    storeByStore {
      id
      name
      description
      location
      attribute
      addressSigunguByAddressSigungu {
        name
        address_sido {
          name
        }
      }
      address_detail
      store_and_hashtags {
        hashtagByHashtag {
          name
        }
      }
    }
    product_item
    price
    car_sunroof_type
    blogPostByBlogPost {
      id
      title
      content
      image
      posting
      thumbnail
    }
  }
  car: car(order_by: {car_maker: {name_kr: asc}, model: asc}) {
    id
    car_maker {
      name_kr
      car_origin {
        name_kr
      }
    }
    model
    car_type {
      value
    }
  }
  store: store {
    id
    name
  }
  carSunroofType: car_sunroof_type {
    value
    description
  }
  product_front: product_item(
    order_by: {sku: asc}
    where: {sku: {_like: "%front%"}}
  ) {
    id
    sku
    price
  }
  product_sideback: product_item(
    order_by: {sku: asc}
    where: {_and: [{sku: {_nlike: "%front%"}}, {sku: {_nlike: "%sunroof%"}}]}
  ) {
    id
    sku
    price
  }
  product_sunroof: product_item(
    order_by: {sku: asc}
    where: {sku: {_like: "%sunroof%"}}
  ) {
    id
    sku
    price
  }
}
    `;

/**
 * __useGet_Gallery_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Gallery_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Gallery_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Gallery_DetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Gallery_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Gallery_DetailQuery, Get_Gallery_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Gallery_DetailQuery, Get_Gallery_DetailQueryVariables>(Get_Gallery_DetailDocument, baseOptions);
      }
export function useGet_Gallery_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Gallery_DetailQuery, Get_Gallery_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Gallery_DetailQuery, Get_Gallery_DetailQueryVariables>(Get_Gallery_DetailDocument, baseOptions);
        }
export type Get_Gallery_DetailQueryHookResult = ReturnType<typeof useGet_Gallery_DetailQuery>;
export type Get_Gallery_DetailLazyQueryHookResult = ReturnType<typeof useGet_Gallery_DetailLazyQuery>;
export type Get_Gallery_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Gallery_DetailQuery, Get_Gallery_DetailQueryVariables>;
export const Search_Store_By_NameDocument = gql`
    query SEARCH_STORE_BY_NAME($keyword: String = "%") {
  stores: store(
    order_by: {name: asc}
    where: {_and: [{attribute: {_is_null: false}}, {_or: [{name: {_like: $keyword}}, {address_detail: {_like: $keyword}}]}]}
  ) {
    id
    name
  }
}
    `;

/**
 * __useSearch_Store_By_NameQuery__
 *
 * To run a query within a React component, call `useSearch_Store_By_NameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_Store_By_NameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_Store_By_NameQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearch_Store_By_NameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Search_Store_By_NameQuery, Search_Store_By_NameQueryVariables>) {
        return ApolloReactHooks.useQuery<Search_Store_By_NameQuery, Search_Store_By_NameQueryVariables>(Search_Store_By_NameDocument, baseOptions);
      }
export function useSearch_Store_By_NameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Search_Store_By_NameQuery, Search_Store_By_NameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Search_Store_By_NameQuery, Search_Store_By_NameQueryVariables>(Search_Store_By_NameDocument, baseOptions);
        }
export type Search_Store_By_NameQueryHookResult = ReturnType<typeof useSearch_Store_By_NameQuery>;
export type Search_Store_By_NameLazyQueryHookResult = ReturnType<typeof useSearch_Store_By_NameLazyQuery>;
export type Search_Store_By_NameQueryResult = ApolloReactCommon.QueryResult<Search_Store_By_NameQuery, Search_Store_By_NameQueryVariables>;
export const Get_Gallery_By_StoreDocument = gql`
    query GET_GALLERY_BY_STORE($pageSize: Int!, $pageOffset: Int!) {
  store(
    limit: $pageSize
    offset: $pageOffset
    order_by: []
    where: {deleted_at: {_is_null: true}, _and: []}
  ) {
    created_at
    id
    name
    llumar_store_name
    phone_business
    operation_memo
    operation_last_contact
    operation_gallery_link
    galleries_aggregate {
      aggregate {
        max {
          created_at
        }
      }
    }
    galleriesByShown: galleries_aggregate(where: {status: {_eq: true}}) {
      aggregate {
        count
        max {
          created_at
        }
      }
    }
    galleriesByHidden: galleries_aggregate(where: {status: {_eq: false}}) {
      aggregate {
        count
      }
    }
    galleriesByHumanShown: galleries_aggregate(
      where: {_and: [{status: {_eq: true}}, {scraped: {_eq: false}}]}
    ) {
      aggregate {
        count
        max {
          created_at
        }
      }
    }
    galleriesByHumanHidden: galleries_aggregate(
      where: {_and: [{status: {_eq: false}}, {scraped: {_eq: false}}]}
    ) {
      aggregate {
        count
      }
    }
    store_and_soles {
      soleBySole {
        id
      }
    }
  }
  store_aggregate(where: {deleted_at: {_is_null: true}, _and: []}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Gallery_By_StoreQuery__
 *
 * To run a query within a React component, call `useGet_Gallery_By_StoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Gallery_By_StoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Gallery_By_StoreQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageOffset: // value for 'pageOffset'
 *   },
 * });
 */
export function useGet_Gallery_By_StoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Gallery_By_StoreQuery, Get_Gallery_By_StoreQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Gallery_By_StoreQuery, Get_Gallery_By_StoreQueryVariables>(Get_Gallery_By_StoreDocument, baseOptions);
      }
export function useGet_Gallery_By_StoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Gallery_By_StoreQuery, Get_Gallery_By_StoreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Gallery_By_StoreQuery, Get_Gallery_By_StoreQueryVariables>(Get_Gallery_By_StoreDocument, baseOptions);
        }
export type Get_Gallery_By_StoreQueryHookResult = ReturnType<typeof useGet_Gallery_By_StoreQuery>;
export type Get_Gallery_By_StoreLazyQueryHookResult = ReturnType<typeof useGet_Gallery_By_StoreLazyQuery>;
export type Get_Gallery_By_StoreQueryResult = ApolloReactCommon.QueryResult<Get_Gallery_By_StoreQuery, Get_Gallery_By_StoreQueryVariables>;
export const Get_Store_And_SolesDocument = gql`
    query GET_STORE_AND_SOLES {
  store {
    name
    llumar_store_name
    id
  }
  sole {
    name
    id
  }
}
    `;

/**
 * __useGet_Store_And_SolesQuery__
 *
 * To run a query within a React component, call `useGet_Store_And_SolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Store_And_SolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Store_And_SolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Store_And_SolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Store_And_SolesQuery, Get_Store_And_SolesQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Store_And_SolesQuery, Get_Store_And_SolesQueryVariables>(Get_Store_And_SolesDocument, baseOptions);
      }
export function useGet_Store_And_SolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Store_And_SolesQuery, Get_Store_And_SolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Store_And_SolesQuery, Get_Store_And_SolesQueryVariables>(Get_Store_And_SolesDocument, baseOptions);
        }
export type Get_Store_And_SolesQueryHookResult = ReturnType<typeof useGet_Store_And_SolesQuery>;
export type Get_Store_And_SolesLazyQueryHookResult = ReturnType<typeof useGet_Store_And_SolesLazyQuery>;
export type Get_Store_And_SolesQueryResult = ApolloReactCommon.QueryResult<Get_Store_And_SolesQuery, Get_Store_And_SolesQueryVariables>;
export const Update_Store_OperationDocument = gql`
    mutation UPDATE_STORE_OPERATION($id: uuid!, $data: timestamptz!) {
  update_store(where: {id: {_eq: $id}}, _set: {operation_last_contact: $data}) {
    affected_rows
  }
}
    `;
export type Update_Store_OperationMutationFn = ApolloReactCommon.MutationFunction<Update_Store_OperationMutation, Update_Store_OperationMutationVariables>;

/**
 * __useUpdate_Store_OperationMutation__
 *
 * To run a mutation, you first call `useUpdate_Store_OperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Store_OperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreOperationMutation, { data, loading, error }] = useUpdate_Store_OperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdate_Store_OperationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Store_OperationMutation, Update_Store_OperationMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Store_OperationMutation, Update_Store_OperationMutationVariables>(Update_Store_OperationDocument, baseOptions);
      }
export type Update_Store_OperationMutationHookResult = ReturnType<typeof useUpdate_Store_OperationMutation>;
export type Update_Store_OperationMutationResult = ApolloReactCommon.MutationResult<Update_Store_OperationMutation>;
export type Update_Store_OperationMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Store_OperationMutation, Update_Store_OperationMutationVariables>;
export const Update_Store_Op_MemoDocument = gql`
    mutation UPDATE_STORE_OP_MEMO($id: uuid!, $memo: String!) {
  update_store(where: {id: {_eq: $id}}, _set: {operation_memo: $memo}) {
    affected_rows
  }
}
    `;
export type Update_Store_Op_MemoMutationFn = ApolloReactCommon.MutationFunction<Update_Store_Op_MemoMutation, Update_Store_Op_MemoMutationVariables>;

/**
 * __useUpdate_Store_Op_MemoMutation__
 *
 * To run a mutation, you first call `useUpdate_Store_Op_MemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Store_Op_MemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreOpMemoMutation, { data, loading, error }] = useUpdate_Store_Op_MemoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memo: // value for 'memo'
 *   },
 * });
 */
export function useUpdate_Store_Op_MemoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Store_Op_MemoMutation, Update_Store_Op_MemoMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Store_Op_MemoMutation, Update_Store_Op_MemoMutationVariables>(Update_Store_Op_MemoDocument, baseOptions);
      }
export type Update_Store_Op_MemoMutationHookResult = ReturnType<typeof useUpdate_Store_Op_MemoMutation>;
export type Update_Store_Op_MemoMutationResult = ApolloReactCommon.MutationResult<Update_Store_Op_MemoMutation>;
export type Update_Store_Op_MemoMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Store_Op_MemoMutation, Update_Store_Op_MemoMutationVariables>;
export const Upsert_Store_SoleDocument = gql`
    mutation UPSERT_STORE_SOLE($id: uuid!, $sole: uuid!) {
  insert_store_and_sole(
    objects: {sole: $sole, store: $id}
    on_conflict: {constraint: store_and_sole_store_key, update_columns: sole}
  ) {
    affected_rows
  }
}
    `;
export type Upsert_Store_SoleMutationFn = ApolloReactCommon.MutationFunction<Upsert_Store_SoleMutation, Upsert_Store_SoleMutationVariables>;

/**
 * __useUpsert_Store_SoleMutation__
 *
 * To run a mutation, you first call `useUpsert_Store_SoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_Store_SoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStoreSoleMutation, { data, loading, error }] = useUpsert_Store_SoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sole: // value for 'sole'
 *   },
 * });
 */
export function useUpsert_Store_SoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Upsert_Store_SoleMutation, Upsert_Store_SoleMutationVariables>) {
        return ApolloReactHooks.useMutation<Upsert_Store_SoleMutation, Upsert_Store_SoleMutationVariables>(Upsert_Store_SoleDocument, baseOptions);
      }
export type Upsert_Store_SoleMutationHookResult = ReturnType<typeof useUpsert_Store_SoleMutation>;
export type Upsert_Store_SoleMutationResult = ApolloReactCommon.MutationResult<Upsert_Store_SoleMutation>;
export type Upsert_Store_SoleMutationOptions = ApolloReactCommon.BaseMutationOptions<Upsert_Store_SoleMutation, Upsert_Store_SoleMutationVariables>;
export const Get_Store_Op_DataDocument = gql`
    query GET_STORE_OP_DATA($id: uuid!) {
  store(where: {id: {_eq: $id}}) {
    name
    operation_memo
    operation_last_contact
    operation_gallery_link
  }
}
    `;

/**
 * __useGet_Store_Op_DataQuery__
 *
 * To run a query within a React component, call `useGet_Store_Op_DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Store_Op_DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Store_Op_DataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Store_Op_DataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Store_Op_DataQuery, Get_Store_Op_DataQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Store_Op_DataQuery, Get_Store_Op_DataQueryVariables>(Get_Store_Op_DataDocument, baseOptions);
      }
export function useGet_Store_Op_DataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Store_Op_DataQuery, Get_Store_Op_DataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Store_Op_DataQuery, Get_Store_Op_DataQueryVariables>(Get_Store_Op_DataDocument, baseOptions);
        }
export type Get_Store_Op_DataQueryHookResult = ReturnType<typeof useGet_Store_Op_DataQuery>;
export type Get_Store_Op_DataLazyQueryHookResult = ReturnType<typeof useGet_Store_Op_DataLazyQuery>;
export type Get_Store_Op_DataQueryResult = ApolloReactCommon.QueryResult<Get_Store_Op_DataQuery, Get_Store_Op_DataQueryVariables>;
export const Update_Store_Op_Data_MemoDocument = gql`
    mutation UPDATE_STORE_OP_DATA_MEMO($id: uuid!, $memo: String, $date: timestamptz, $link: String) {
  update_store(
    where: {id: {_eq: $id}}
    _set: {operation_memo: $memo, operation_last_contact: $date, operation_gallery_link: $link}
  ) {
    affected_rows
  }
}
    `;
export type Update_Store_Op_Data_MemoMutationFn = ApolloReactCommon.MutationFunction<Update_Store_Op_Data_MemoMutation, Update_Store_Op_Data_MemoMutationVariables>;

/**
 * __useUpdate_Store_Op_Data_MemoMutation__
 *
 * To run a mutation, you first call `useUpdate_Store_Op_Data_MemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Store_Op_Data_MemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreOpDataMemoMutation, { data, loading, error }] = useUpdate_Store_Op_Data_MemoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memo: // value for 'memo'
 *      date: // value for 'date'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdate_Store_Op_Data_MemoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Store_Op_Data_MemoMutation, Update_Store_Op_Data_MemoMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Store_Op_Data_MemoMutation, Update_Store_Op_Data_MemoMutationVariables>(Update_Store_Op_Data_MemoDocument, baseOptions);
      }
export type Update_Store_Op_Data_MemoMutationHookResult = ReturnType<typeof useUpdate_Store_Op_Data_MemoMutation>;
export type Update_Store_Op_Data_MemoMutationResult = ApolloReactCommon.MutationResult<Update_Store_Op_Data_MemoMutation>;
export type Update_Store_Op_Data_MemoMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Store_Op_Data_MemoMutation, Update_Store_Op_Data_MemoMutationVariables>;
export const Get_HashtagDocument = gql`
    query GET_HASHTAG {
  hashtags: hashtag(where: {deleted_at: {_is_null: true}}, order_by: {name: asc}) {
    id
    name
    account
  }
}
    `;

/**
 * __useGet_HashtagQuery__
 *
 * To run a query within a React component, call `useGet_HashtagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_HashtagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_HashtagQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_HashtagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_HashtagQuery, Get_HashtagQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_HashtagQuery, Get_HashtagQueryVariables>(Get_HashtagDocument, baseOptions);
      }
export function useGet_HashtagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_HashtagQuery, Get_HashtagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_HashtagQuery, Get_HashtagQueryVariables>(Get_HashtagDocument, baseOptions);
        }
export type Get_HashtagQueryHookResult = ReturnType<typeof useGet_HashtagQuery>;
export type Get_HashtagLazyQueryHookResult = ReturnType<typeof useGet_HashtagLazyQuery>;
export type Get_HashtagQueryResult = ApolloReactCommon.QueryResult<Get_HashtagQuery, Get_HashtagQueryVariables>;
export const Insert_HashtagDocument = gql`
    mutation INSERT_HASHTAG($newHashtagObject: [hashtag_insert_input!]!) {
  insert_hashtag(objects: $newHashtagObject) {
    affected_rows
  }
}
    `;
export type Insert_HashtagMutationFn = ApolloReactCommon.MutationFunction<Insert_HashtagMutation, Insert_HashtagMutationVariables>;

/**
 * __useInsert_HashtagMutation__
 *
 * To run a mutation, you first call `useInsert_HashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_HashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHashtagMutation, { data, loading, error }] = useInsert_HashtagMutation({
 *   variables: {
 *      newHashtagObject: // value for 'newHashtagObject'
 *   },
 * });
 */
export function useInsert_HashtagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_HashtagMutation, Insert_HashtagMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_HashtagMutation, Insert_HashtagMutationVariables>(Insert_HashtagDocument, baseOptions);
      }
export type Insert_HashtagMutationHookResult = ReturnType<typeof useInsert_HashtagMutation>;
export type Insert_HashtagMutationResult = ApolloReactCommon.MutationResult<Insert_HashtagMutation>;
export type Insert_HashtagMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_HashtagMutation, Insert_HashtagMutationVariables>;
export const Update_HashtagDocument = gql`
    mutation UPDATE_HASHTAG($id: uuid!, $newHashtagName: String!) {
  update_hashtag(where: {id: {_eq: $id}}, _set: {name: $newHashtagName}) {
    affected_rows
  }
}
    `;
export type Update_HashtagMutationFn = ApolloReactCommon.MutationFunction<Update_HashtagMutation, Update_HashtagMutationVariables>;

/**
 * __useUpdate_HashtagMutation__
 *
 * To run a mutation, you first call `useUpdate_HashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_HashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHashtagMutation, { data, loading, error }] = useUpdate_HashtagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newHashtagName: // value for 'newHashtagName'
 *   },
 * });
 */
export function useUpdate_HashtagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_HashtagMutation, Update_HashtagMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_HashtagMutation, Update_HashtagMutationVariables>(Update_HashtagDocument, baseOptions);
      }
export type Update_HashtagMutationHookResult = ReturnType<typeof useUpdate_HashtagMutation>;
export type Update_HashtagMutationResult = ApolloReactCommon.MutationResult<Update_HashtagMutation>;
export type Update_HashtagMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_HashtagMutation, Update_HashtagMutationVariables>;
export const Delete_HashtagDocument = gql`
    mutation DELETE_HASHTAG($id: uuid!, $deletedAt: timestamptz!) {
  update_hashtag(where: {id: {_eq: $id}}, _set: {deleted_at: $deletedAt}) {
    affected_rows
  }
}
    `;
export type Delete_HashtagMutationFn = ApolloReactCommon.MutationFunction<Delete_HashtagMutation, Delete_HashtagMutationVariables>;

/**
 * __useDelete_HashtagMutation__
 *
 * To run a mutation, you first call `useDelete_HashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_HashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHashtagMutation, { data, loading, error }] = useDelete_HashtagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useDelete_HashtagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_HashtagMutation, Delete_HashtagMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_HashtagMutation, Delete_HashtagMutationVariables>(Delete_HashtagDocument, baseOptions);
      }
export type Delete_HashtagMutationHookResult = ReturnType<typeof useDelete_HashtagMutation>;
export type Delete_HashtagMutationResult = ApolloReactCommon.MutationResult<Delete_HashtagMutation>;
export type Delete_HashtagMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_HashtagMutation, Delete_HashtagMutationVariables>;
export const Get_Product_Items_With_SkuDocument = gql`
    query GET_PRODUCT_ITEMS_WITH_SKU {
  productItems: product_item(where: {_or: []}) {
    attribute
    price
    sku
    productByProduct {
      id
      name
      type
      product_brand {
        description
      }
      attribute
    }
  }
}
    `;

/**
 * __useGet_Product_Items_With_SkuQuery__
 *
 * To run a query within a React component, call `useGet_Product_Items_With_SkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_Items_With_SkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_Items_With_SkuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Product_Items_With_SkuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_Items_With_SkuQuery, Get_Product_Items_With_SkuQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_Items_With_SkuQuery, Get_Product_Items_With_SkuQueryVariables>(Get_Product_Items_With_SkuDocument, baseOptions);
      }
export function useGet_Product_Items_With_SkuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_Items_With_SkuQuery, Get_Product_Items_With_SkuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_Items_With_SkuQuery, Get_Product_Items_With_SkuQueryVariables>(Get_Product_Items_With_SkuDocument, baseOptions);
        }
export type Get_Product_Items_With_SkuQueryHookResult = ReturnType<typeof useGet_Product_Items_With_SkuQuery>;
export type Get_Product_Items_With_SkuLazyQueryHookResult = ReturnType<typeof useGet_Product_Items_With_SkuLazyQuery>;
export type Get_Product_Items_With_SkuQueryResult = ApolloReactCommon.QueryResult<Get_Product_Items_With_SkuQuery, Get_Product_Items_With_SkuQueryVariables>;
export const Get_OrdersDocument = gql`
    query GET_ORDERS($page: Int!, $rowsPerPage: Int!) {
  order(limit: $rowsPerPage, offset: $page, order_by: [], where: {_and: []}) {
    id
    status
    created_at
    account
    accountByAccount {
      created_at
      profile_kr_customer {
        stripe_customer_id
        rapyd_customer_id
      }
    }
    leadByLead {
      id
      status
      accountBySupportAssignee {
        profile_riderdashes {
          name
        }
      }
      accountByShopAssignee {
        profile_riderdashes {
          name
        }
      }
      chats {
        id
        accountByChatAssignee {
          profile_riderdashes {
            name
          }
        }
      }
    }
    is_777_order
    product_item
    car
    car_sunroof_type
    price_product
    price_final
    price_discount
    price_extra
    date_time
    date_time_option
    name
    email
    phone
    remark
    paid_at
    finished_at
    payment_link_sent_at
    refunded_at
    installed_at
    package_detail {
      name
      package_type {
        description
      }
    }
    product_item_detail {
      attribute
      price
      productByProduct {
        product_brand {
          description
        }
        name
        product_type {
          value
          description
        }
      }
    }
    price_payout
    price_payout_without_vat
    carByCar {
      car_maker {
        name_kr
      }
      model
    }
    carSunroofTypeByCarSunroofType {
      description
    }
    order_status {
      value
      description
    }
    storeByStore {
      id
      name
      address_detail
      location
      phone_business
      addressSigunguByAddressSigungu {
        name
        address_sido {
          name
        }
      }
    }
    order_and_transactions(
      where: {transactionByTransaction: {type: {_eq: purchase}}}
    ) {
      transaction
      transactionByTransaction {
        stripe_payment_intent_id
        rapyd_payment_intent_id
        bootpay_payment_intent_id
      }
    }
  }
  order_aggregate(order_by: [], where: {_and: []}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_OrdersQuery__
 *
 * To run a query within a React component, call `useGet_OrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_OrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_OrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      rowsPerPage: // value for 'rowsPerPage'
 *   },
 * });
 */
export function useGet_OrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_OrdersQuery, Get_OrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_OrdersQuery, Get_OrdersQueryVariables>(Get_OrdersDocument, baseOptions);
      }
export function useGet_OrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_OrdersQuery, Get_OrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_OrdersQuery, Get_OrdersQueryVariables>(Get_OrdersDocument, baseOptions);
        }
export type Get_OrdersQueryHookResult = ReturnType<typeof useGet_OrdersQuery>;
export type Get_OrdersLazyQueryHookResult = ReturnType<typeof useGet_OrdersLazyQuery>;
export type Get_OrdersQueryResult = ApolloReactCommon.QueryResult<Get_OrdersQuery, Get_OrdersQueryVariables>;
export const Get_All_StoresDocument = gql`
    query GET_ALL_STORES {
  store {
    name
  }
}
    `;

/**
 * __useGet_All_StoresQuery__
 *
 * To run a query within a React component, call `useGet_All_StoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_StoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_StoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_All_StoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_All_StoresQuery, Get_All_StoresQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_All_StoresQuery, Get_All_StoresQueryVariables>(Get_All_StoresDocument, baseOptions);
      }
export function useGet_All_StoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_All_StoresQuery, Get_All_StoresQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_All_StoresQuery, Get_All_StoresQueryVariables>(Get_All_StoresDocument, baseOptions);
        }
export type Get_All_StoresQueryHookResult = ReturnType<typeof useGet_All_StoresQuery>;
export type Get_All_StoresLazyQueryHookResult = ReturnType<typeof useGet_All_StoresLazyQuery>;
export type Get_All_StoresQueryResult = ApolloReactCommon.QueryResult<Get_All_StoresQuery, Get_All_StoresQueryVariables>;
export const Get_Order_ProductDocument = gql`
    query GET_ORDER_PRODUCT($id: uuid!) {
  order(where: {id: {_eq: $id}}) {
    product_item
  }
}
    `;

/**
 * __useGet_Order_ProductQuery__
 *
 * To run a query within a React component, call `useGet_Order_ProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_ProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_ProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Order_ProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Order_ProductQuery, Get_Order_ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Order_ProductQuery, Get_Order_ProductQueryVariables>(Get_Order_ProductDocument, baseOptions);
      }
export function useGet_Order_ProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Order_ProductQuery, Get_Order_ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Order_ProductQuery, Get_Order_ProductQueryVariables>(Get_Order_ProductDocument, baseOptions);
        }
export type Get_Order_ProductQueryHookResult = ReturnType<typeof useGet_Order_ProductQuery>;
export type Get_Order_ProductLazyQueryHookResult = ReturnType<typeof useGet_Order_ProductLazyQuery>;
export type Get_Order_ProductQueryResult = ApolloReactCommon.QueryResult<Get_Order_ProductQuery, Get_Order_ProductQueryVariables>;
export const Get_Package_DetailDocument = gql`
    query GET_PACKAGE_DETAIL($newCarPackageId: uuid!) {
  package(where: {id: {_eq: $newCarPackageId}}) {
    id
    name
    product
    alias
    image
    name
    status
    price_product
    price_discount
    attribute
    productByAddOnProduct {
      product_items {
        id
        attribute
        price
      }
    }
  }
}
    `;

/**
 * __useGet_Package_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Package_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Package_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Package_DetailQuery({
 *   variables: {
 *      newCarPackageId: // value for 'newCarPackageId'
 *   },
 * });
 */
export function useGet_Package_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Package_DetailQuery, Get_Package_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Package_DetailQuery, Get_Package_DetailQueryVariables>(Get_Package_DetailDocument, baseOptions);
      }
export function useGet_Package_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Package_DetailQuery, Get_Package_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Package_DetailQuery, Get_Package_DetailQueryVariables>(Get_Package_DetailDocument, baseOptions);
        }
export type Get_Package_DetailQueryHookResult = ReturnType<typeof useGet_Package_DetailQuery>;
export type Get_Package_DetailLazyQueryHookResult = ReturnType<typeof useGet_Package_DetailLazyQuery>;
export type Get_Package_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Package_DetailQuery, Get_Package_DetailQueryVariables>;
export const Get_Order_Product_ItemDocument = gql`
    query GET_ORDER_PRODUCT_ITEM {
  product_item(where: {_or: []}) {
    attribute
    price
    productByProduct {
      id
      name
      product_brand {
        description
      }
    }
  }
}
    `;

/**
 * __useGet_Order_Product_ItemQuery__
 *
 * To run a query within a React component, call `useGet_Order_Product_ItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_Product_ItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_Product_ItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Order_Product_ItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Order_Product_ItemQuery, Get_Order_Product_ItemQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Order_Product_ItemQuery, Get_Order_Product_ItemQueryVariables>(Get_Order_Product_ItemDocument, baseOptions);
      }
export function useGet_Order_Product_ItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Order_Product_ItemQuery, Get_Order_Product_ItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Order_Product_ItemQuery, Get_Order_Product_ItemQueryVariables>(Get_Order_Product_ItemDocument, baseOptions);
        }
export type Get_Order_Product_ItemQueryHookResult = ReturnType<typeof useGet_Order_Product_ItemQuery>;
export type Get_Order_Product_ItemLazyQueryHookResult = ReturnType<typeof useGet_Order_Product_ItemLazyQuery>;
export type Get_Order_Product_ItemQueryResult = ApolloReactCommon.QueryResult<Get_Order_Product_ItemQuery, Get_Order_Product_ItemQueryVariables>;
export const Update_OrderDocument = gql`
    mutation UPDATE_ORDER($id: uuid!, $status: order_status_enum!, $accountsPayableAndPayOutObject: accounts_payable_and_payout_insert_input!, $date: timestamptz!) {
  update_order(
    where: {id: {_eq: $id}}
    _set: {status: $status, finished_at: $date}
  ) {
    affected_rows
  }
  insert_accounts_payable_and_payout_one(object: $accountsPayableAndPayOutObject) {
    accounts_payable
    payout
  }
}
    `;
export type Update_OrderMutationFn = ApolloReactCommon.MutationFunction<Update_OrderMutation, Update_OrderMutationVariables>;

/**
 * __useUpdate_OrderMutation__
 *
 * To run a mutation, you first call `useUpdate_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdate_OrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      accountsPayableAndPayOutObject: // value for 'accountsPayableAndPayOutObject'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdate_OrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_OrderMutation, Update_OrderMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_OrderMutation, Update_OrderMutationVariables>(Update_OrderDocument, baseOptions);
      }
export type Update_OrderMutationHookResult = ReturnType<typeof useUpdate_OrderMutation>;
export type Update_OrderMutationResult = ApolloReactCommon.MutationResult<Update_OrderMutation>;
export type Update_OrderMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_OrderMutation, Update_OrderMutationVariables>;
export const Update_Order_ScheduleDocument = gql`
    mutation UPDATE_ORDER_SCHEDULE($id: uuid!, $date: timestamptz!) {
  update_order(where: {id: {_eq: $id}}, _set: {date_time: $date}) {
    affected_rows
  }
}
    `;
export type Update_Order_ScheduleMutationFn = ApolloReactCommon.MutationFunction<Update_Order_ScheduleMutation, Update_Order_ScheduleMutationVariables>;

/**
 * __useUpdate_Order_ScheduleMutation__
 *
 * To run a mutation, you first call `useUpdate_Order_ScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Order_ScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderScheduleMutation, { data, loading, error }] = useUpdate_Order_ScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdate_Order_ScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Order_ScheduleMutation, Update_Order_ScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Order_ScheduleMutation, Update_Order_ScheduleMutationVariables>(Update_Order_ScheduleDocument, baseOptions);
      }
export type Update_Order_ScheduleMutationHookResult = ReturnType<typeof useUpdate_Order_ScheduleMutation>;
export type Update_Order_ScheduleMutationResult = ApolloReactCommon.MutationResult<Update_Order_ScheduleMutation>;
export type Update_Order_ScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Order_ScheduleMutation, Update_Order_ScheduleMutationVariables>;
export const Update_Order_StatusDocument = gql`
    mutation UPDATE_ORDER_STATUS($id: uuid!, $date: timestamptz!) {
  update_order(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type Update_Order_StatusMutationFn = ApolloReactCommon.MutationFunction<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>;

/**
 * __useUpdate_Order_StatusMutation__
 *
 * To run a mutation, you first call `useUpdate_Order_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Order_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdate_Order_StatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdate_Order_StatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>(Update_Order_StatusDocument, baseOptions);
      }
export type Update_Order_StatusMutationHookResult = ReturnType<typeof useUpdate_Order_StatusMutation>;
export type Update_Order_StatusMutationResult = ApolloReactCommon.MutationResult<Update_Order_StatusMutation>;
export type Update_Order_StatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Order_StatusMutation, Update_Order_StatusMutationVariables>;
export const Get_Order_DetailDocument = gql`
    query GET_ORDER_DETAIL($id: uuid!) {
  order: order(where: {id: {_eq: $id}}) {
    id
    car
    product_item
    price_discount
    price_product
    price_final
    date_time
    price_extra
    commission_rate
    remark
    accountByAccount {
      id
    }
    name
    email
    phone
    carByCar {
      model
      prefix
      postfix
      car_maker {
        car_origin {
          name_kr
        }
        name_kr
      }
      car_type {
        value
        description
      }
    }
    carSunroofTypeByCarSunroofType {
      value
      description
    }
    order_status {
      value
      description
    }
    storeByStore {
      id
      address_detail
      name
    }
  }
  packages: package(order_by: {name: asc}) {
    id
    name
  }
}
    `;

/**
 * __useGet_Order_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Order_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_DetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Order_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Order_DetailQuery, Get_Order_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Order_DetailQuery, Get_Order_DetailQueryVariables>(Get_Order_DetailDocument, baseOptions);
      }
export function useGet_Order_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Order_DetailQuery, Get_Order_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Order_DetailQuery, Get_Order_DetailQueryVariables>(Get_Order_DetailDocument, baseOptions);
        }
export type Get_Order_DetailQueryHookResult = ReturnType<typeof useGet_Order_DetailQuery>;
export type Get_Order_DetailLazyQueryHookResult = ReturnType<typeof useGet_Order_DetailLazyQuery>;
export type Get_Order_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Order_DetailQuery, Get_Order_DetailQueryVariables>;
export const Update_Order_ContactDocument = gql`
    mutation UPDATE_ORDER_CONTACT($id: uuid!, $newOrderDetail: order_set_input) {
  update_order(where: {id: {_eq: $id}}, _set: $newOrderDetail) {
    affected_rows
  }
}
    `;
export type Update_Order_ContactMutationFn = ApolloReactCommon.MutationFunction<Update_Order_ContactMutation, Update_Order_ContactMutationVariables>;

/**
 * __useUpdate_Order_ContactMutation__
 *
 * To run a mutation, you first call `useUpdate_Order_ContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Order_ContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderContactMutation, { data, loading, error }] = useUpdate_Order_ContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newOrderDetail: // value for 'newOrderDetail'
 *   },
 * });
 */
export function useUpdate_Order_ContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Order_ContactMutation, Update_Order_ContactMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Order_ContactMutation, Update_Order_ContactMutationVariables>(Update_Order_ContactDocument, baseOptions);
      }
export type Update_Order_ContactMutationHookResult = ReturnType<typeof useUpdate_Order_ContactMutation>;
export type Update_Order_ContactMutationResult = ApolloReactCommon.MutationResult<Update_Order_ContactMutation>;
export type Update_Order_ContactMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Order_ContactMutation, Update_Order_ContactMutationVariables>;
export const Get_All_SidoDocument = gql`
    query GET_ALL_SIDO {
  address_sido {
    name
    address_sigungus {
      name
    }
  }
}
    `;

/**
 * __useGet_All_SidoQuery__
 *
 * To run a query within a React component, call `useGet_All_SidoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_SidoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_SidoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_All_SidoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_All_SidoQuery, Get_All_SidoQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_All_SidoQuery, Get_All_SidoQueryVariables>(Get_All_SidoDocument, baseOptions);
      }
export function useGet_All_SidoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_All_SidoQuery, Get_All_SidoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_All_SidoQuery, Get_All_SidoQueryVariables>(Get_All_SidoDocument, baseOptions);
        }
export type Get_All_SidoQueryHookResult = ReturnType<typeof useGet_All_SidoQuery>;
export type Get_All_SidoLazyQueryHookResult = ReturnType<typeof useGet_All_SidoLazyQuery>;
export type Get_All_SidoQueryResult = ApolloReactCommon.QueryResult<Get_All_SidoQuery, Get_All_SidoQueryVariables>;
export const Get_Order_PricesDocument = gql`
    query GET_ORDER_PRICES($startDate: timestamptz, $sido: String, $sigungu: String) {
  order(
    where: {_or: [{date_time: {_gte: $startDate}}, {confirmed_at: {_gte: $startDate}}, {finished_at: {_gte: $startDate}}, {canceled_at: {_gte: $startDate}}, {refunded_at: {_gte: $startDate}}], storeByStore: {addressSigunguByAddressSigungu: {name: {_similar: $sigungu}, address_sido: {name: {_similar: $sido}}}}}
  ) {
    price_discount
    price_extra
    price_final
    price_product
    status
    date_time
    confirmed_at
    finished_at
    canceled_at
    refunded_at
  }
}
    `;

/**
 * __useGet_Order_PricesQuery__
 *
 * To run a query within a React component, call `useGet_Order_PricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_PricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_PricesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      sido: // value for 'sido'
 *      sigungu: // value for 'sigungu'
 *   },
 * });
 */
export function useGet_Order_PricesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Order_PricesQuery, Get_Order_PricesQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Order_PricesQuery, Get_Order_PricesQueryVariables>(Get_Order_PricesDocument, baseOptions);
      }
export function useGet_Order_PricesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Order_PricesQuery, Get_Order_PricesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Order_PricesQuery, Get_Order_PricesQueryVariables>(Get_Order_PricesDocument, baseOptions);
        }
export type Get_Order_PricesQueryHookResult = ReturnType<typeof useGet_Order_PricesQuery>;
export type Get_Order_PricesLazyQueryHookResult = ReturnType<typeof useGet_Order_PricesLazyQuery>;
export type Get_Order_PricesQueryResult = ApolloReactCommon.QueryResult<Get_Order_PricesQuery, Get_Order_PricesQueryVariables>;
export const _Get_Package_ContentsDocument = gql`
    query _GET_PACKAGE_CONTENTS($packageId: uuid!) {
  package(where: {id: {_eq: $packageId}}) {
    id
    name
    price_discount
    price_payout
    price_product
    product
  }
  car_type {
    value
    description
  }
  car_origin(where: {_or: [{name_us: {_eq: "korea"}}, {name_us: {_eq: "other"}}]}) {
    name_kr
    name_us
  }
}
    `;

/**
 * __use_Get_Package_ContentsQuery__
 *
 * To run a query within a React component, call `use_Get_Package_ContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_Package_ContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_Package_ContentsQuery({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function use_Get_Package_ContentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_Package_ContentsQuery, _Get_Package_ContentsQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_Package_ContentsQuery, _Get_Package_ContentsQueryVariables>(_Get_Package_ContentsDocument, baseOptions);
      }
export function use_Get_Package_ContentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_Package_ContentsQuery, _Get_Package_ContentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_Package_ContentsQuery, _Get_Package_ContentsQueryVariables>(_Get_Package_ContentsDocument, baseOptions);
        }
export type _Get_Package_ContentsQueryHookResult = ReturnType<typeof use_Get_Package_ContentsQuery>;
export type _Get_Package_ContentsLazyQueryHookResult = ReturnType<typeof use_Get_Package_ContentsLazyQuery>;
export type _Get_Package_ContentsQueryResult = ApolloReactCommon.QueryResult<_Get_Package_ContentsQuery, _Get_Package_ContentsQueryVariables>;
export const _Update_Package_By_PkDocument = gql`
    mutation _UPDATE_PACKAGE_BY_PK($id: uuid!, $priceDiscount: jsonb, $pricePayout: jsonb, $priceProduct: jsonb, $product: jsonb) {
  update_package_by_pk(
    pk_columns: {id: $id}
    _set: {price_discount: $priceDiscount, price_payout: $pricePayout, price_product: $priceProduct, product: $product}
  ) {
    id
    price_discount
    price_payout
    price_product
    product
  }
}
    `;
export type _Update_Package_By_PkMutationFn = ApolloReactCommon.MutationFunction<_Update_Package_By_PkMutation, _Update_Package_By_PkMutationVariables>;

/**
 * __use_Update_Package_By_PkMutation__
 *
 * To run a mutation, you first call `use_Update_Package_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_Update_Package_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageByPkMutation, { data, loading, error }] = use_Update_Package_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      priceDiscount: // value for 'priceDiscount'
 *      pricePayout: // value for 'pricePayout'
 *      priceProduct: // value for 'priceProduct'
 *      product: // value for 'product'
 *   },
 * });
 */
export function use_Update_Package_By_PkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_Update_Package_By_PkMutation, _Update_Package_By_PkMutationVariables>) {
        return ApolloReactHooks.useMutation<_Update_Package_By_PkMutation, _Update_Package_By_PkMutationVariables>(_Update_Package_By_PkDocument, baseOptions);
      }
export type _Update_Package_By_PkMutationHookResult = ReturnType<typeof use_Update_Package_By_PkMutation>;
export type _Update_Package_By_PkMutationResult = ApolloReactCommon.MutationResult<_Update_Package_By_PkMutation>;
export type _Update_Package_By_PkMutationOptions = ApolloReactCommon.BaseMutationOptions<_Update_Package_By_PkMutation, _Update_Package_By_PkMutationVariables>;
export const Get_Account_ListDocument = gql`
    query GET_ACCOUNT_LIST {
  accountContent: account(order_by: {created_at: desc}) {
    id
    created_at
    profile_kr_partner {
      id
      name
      email
    }
    profile_riderdash {
      id
    }
  }
}
    `;

/**
 * __useGet_Account_ListQuery__
 *
 * To run a query within a React component, call `useGet_Account_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Account_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Account_ListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Account_ListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Account_ListQuery, Get_Account_ListQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Account_ListQuery, Get_Account_ListQueryVariables>(Get_Account_ListDocument, baseOptions);
      }
export function useGet_Account_ListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Account_ListQuery, Get_Account_ListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Account_ListQuery, Get_Account_ListQueryVariables>(Get_Account_ListDocument, baseOptions);
        }
export type Get_Account_ListQueryHookResult = ReturnType<typeof useGet_Account_ListQuery>;
export type Get_Account_ListLazyQueryHookResult = ReturnType<typeof useGet_Account_ListLazyQuery>;
export type Get_Account_ListQueryResult = ApolloReactCommon.QueryResult<Get_Account_ListQuery, Get_Account_ListQueryVariables>;
export const Insert_Profile_Riderdash_ParttimerDocument = gql`
    mutation INSERT_PROFILE_RIDERDASH_PARTTIMER($object: profile_riderdash_insert_input!) {
  insert_profile_riderdash_one(object: $object) {
    id
  }
}
    `;
export type Insert_Profile_Riderdash_ParttimerMutationFn = ApolloReactCommon.MutationFunction<Insert_Profile_Riderdash_ParttimerMutation, Insert_Profile_Riderdash_ParttimerMutationVariables>;

/**
 * __useInsert_Profile_Riderdash_ParttimerMutation__
 *
 * To run a mutation, you first call `useInsert_Profile_Riderdash_ParttimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Profile_Riderdash_ParttimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProfileRiderdashParttimerMutation, { data, loading, error }] = useInsert_Profile_Riderdash_ParttimerMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_Profile_Riderdash_ParttimerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_Profile_Riderdash_ParttimerMutation, Insert_Profile_Riderdash_ParttimerMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_Profile_Riderdash_ParttimerMutation, Insert_Profile_Riderdash_ParttimerMutationVariables>(Insert_Profile_Riderdash_ParttimerDocument, baseOptions);
      }
export type Insert_Profile_Riderdash_ParttimerMutationHookResult = ReturnType<typeof useInsert_Profile_Riderdash_ParttimerMutation>;
export type Insert_Profile_Riderdash_ParttimerMutationResult = ApolloReactCommon.MutationResult<Insert_Profile_Riderdash_ParttimerMutation>;
export type Insert_Profile_Riderdash_ParttimerMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_Profile_Riderdash_ParttimerMutation, Insert_Profile_Riderdash_ParttimerMutationVariables>;
export const Get_ProductDocument = gql`
    query GET_PRODUCT {
  products: product {
    id
    status
    name
    type
    attribute
    brand
    image
    product_brand {
      description
    }
    product_items {
      sku
      price
    }
  }
}
    `;

/**
 * __useGet_ProductQuery__
 *
 * To run a query within a React component, call `useGet_ProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_ProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_ProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_ProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_ProductQuery, Get_ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_ProductQuery, Get_ProductQueryVariables>(Get_ProductDocument, baseOptions);
      }
export function useGet_ProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_ProductQuery, Get_ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_ProductQuery, Get_ProductQueryVariables>(Get_ProductDocument, baseOptions);
        }
export type Get_ProductQueryHookResult = ReturnType<typeof useGet_ProductQuery>;
export type Get_ProductLazyQueryHookResult = ReturnType<typeof useGet_ProductLazyQuery>;
export type Get_ProductQueryResult = ApolloReactCommon.QueryResult<Get_ProductQuery, Get_ProductQueryVariables>;
export const Get_Product_BrandDocument = gql`
    query GET_PRODUCT_BRAND {
  product_brand {
    value
    description
  }
}
    `;

/**
 * __useGet_Product_BrandQuery__
 *
 * To run a query within a React component, call `useGet_Product_BrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_BrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_BrandQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Product_BrandQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_BrandQuery, Get_Product_BrandQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_BrandQuery, Get_Product_BrandQueryVariables>(Get_Product_BrandDocument, baseOptions);
      }
export function useGet_Product_BrandLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_BrandQuery, Get_Product_BrandQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_BrandQuery, Get_Product_BrandQueryVariables>(Get_Product_BrandDocument, baseOptions);
        }
export type Get_Product_BrandQueryHookResult = ReturnType<typeof useGet_Product_BrandQuery>;
export type Get_Product_BrandLazyQueryHookResult = ReturnType<typeof useGet_Product_BrandLazyQuery>;
export type Get_Product_BrandQueryResult = ApolloReactCommon.QueryResult<Get_Product_BrandQuery, Get_Product_BrandQueryVariables>;
export const Insert_Product_BrandDocument = gql`
    mutation INSERT_PRODUCT_BRAND($productBrandObject: product_brand_insert_input!) {
  insert_product_brand_one(object: $productBrandObject) {
    value
  }
}
    `;
export type Insert_Product_BrandMutationFn = ApolloReactCommon.MutationFunction<Insert_Product_BrandMutation, Insert_Product_BrandMutationVariables>;

/**
 * __useInsert_Product_BrandMutation__
 *
 * To run a mutation, you first call `useInsert_Product_BrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Product_BrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductBrandMutation, { data, loading, error }] = useInsert_Product_BrandMutation({
 *   variables: {
 *      productBrandObject: // value for 'productBrandObject'
 *   },
 * });
 */
export function useInsert_Product_BrandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_Product_BrandMutation, Insert_Product_BrandMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_Product_BrandMutation, Insert_Product_BrandMutationVariables>(Insert_Product_BrandDocument, baseOptions);
      }
export type Insert_Product_BrandMutationHookResult = ReturnType<typeof useInsert_Product_BrandMutation>;
export type Insert_Product_BrandMutationResult = ApolloReactCommon.MutationResult<Insert_Product_BrandMutation>;
export type Insert_Product_BrandMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_Product_BrandMutation, Insert_Product_BrandMutationVariables>;
export const Get_Product_By_PkDocument = gql`
    query GET_PRODUCT_BY_PK($id: uuid!) {
  product_by_pk(id: $id) {
    id
    name
    type
    brand
    description
    status
    alias
    account
  }
}
    `;

/**
 * __useGet_Product_By_PkQuery__
 *
 * To run a query within a React component, call `useGet_Product_By_PkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_By_PkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_By_PkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Product_By_PkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_By_PkQuery, Get_Product_By_PkQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_By_PkQuery, Get_Product_By_PkQueryVariables>(Get_Product_By_PkDocument, baseOptions);
      }
export function useGet_Product_By_PkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_By_PkQuery, Get_Product_By_PkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_By_PkQuery, Get_Product_By_PkQueryVariables>(Get_Product_By_PkDocument, baseOptions);
        }
export type Get_Product_By_PkQueryHookResult = ReturnType<typeof useGet_Product_By_PkQuery>;
export type Get_Product_By_PkLazyQueryHookResult = ReturnType<typeof useGet_Product_By_PkLazyQuery>;
export type Get_Product_By_PkQueryResult = ApolloReactCommon.QueryResult<Get_Product_By_PkQuery, Get_Product_By_PkQueryVariables>;
export const Upsert_ProductDocument = gql`
    mutation UPSERT_PRODUCT($productObject: product_insert_input!) {
  insert_product_one(
    object: $productObject
    on_conflict: {constraint: product_pkey, update_columns: [account, brand, type, name, description, status, alias]}
  ) {
    id
  }
}
    `;
export type Upsert_ProductMutationFn = ApolloReactCommon.MutationFunction<Upsert_ProductMutation, Upsert_ProductMutationVariables>;

/**
 * __useUpsert_ProductMutation__
 *
 * To run a mutation, you first call `useUpsert_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductMutation, { data, loading, error }] = useUpsert_ProductMutation({
 *   variables: {
 *      productObject: // value for 'productObject'
 *   },
 * });
 */
export function useUpsert_ProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Upsert_ProductMutation, Upsert_ProductMutationVariables>) {
        return ApolloReactHooks.useMutation<Upsert_ProductMutation, Upsert_ProductMutationVariables>(Upsert_ProductDocument, baseOptions);
      }
export type Upsert_ProductMutationHookResult = ReturnType<typeof useUpsert_ProductMutation>;
export type Upsert_ProductMutationResult = ApolloReactCommon.MutationResult<Upsert_ProductMutation>;
export type Upsert_ProductMutationOptions = ApolloReactCommon.BaseMutationOptions<Upsert_ProductMutation, Upsert_ProductMutationVariables>;
export const Get_Product_ItemsDocument = gql`
    query GET_PRODUCT_ITEMS {
  productItems: product_item(order_by: {sku: asc}) {
    id
    sku
    price
    commission_rate
    productByProduct {
      name
      brand
      type
    }
  }
}
    `;

/**
 * __useGet_Product_ItemsQuery__
 *
 * To run a query within a React component, call `useGet_Product_ItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_ItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_ItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Product_ItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_ItemsQuery, Get_Product_ItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_ItemsQuery, Get_Product_ItemsQueryVariables>(Get_Product_ItemsDocument, baseOptions);
      }
export function useGet_Product_ItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_ItemsQuery, Get_Product_ItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_ItemsQuery, Get_Product_ItemsQueryVariables>(Get_Product_ItemsDocument, baseOptions);
        }
export type Get_Product_ItemsQueryHookResult = ReturnType<typeof useGet_Product_ItemsQuery>;
export type Get_Product_ItemsLazyQueryHookResult = ReturnType<typeof useGet_Product_ItemsLazyQuery>;
export type Get_Product_ItemsQueryResult = ApolloReactCommon.QueryResult<Get_Product_ItemsQuery, Get_Product_ItemsQueryVariables>;
export const Get_Product_Item_DetailDocument = gql`
    query GET_PRODUCT_ITEM_DETAIL($productItemId: uuid!) {
  product_item(where: {id: {_eq: $productItemId}}) {
    id
    sku
    price
    commission_rate
    productByProduct {
      name
      brand
      type
    }
  }
}
    `;

/**
 * __useGet_Product_Item_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Product_Item_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_Item_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_Item_DetailQuery({
 *   variables: {
 *      productItemId: // value for 'productItemId'
 *   },
 * });
 */
export function useGet_Product_Item_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_Item_DetailQuery, Get_Product_Item_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_Item_DetailQuery, Get_Product_Item_DetailQueryVariables>(Get_Product_Item_DetailDocument, baseOptions);
      }
export function useGet_Product_Item_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_Item_DetailQuery, Get_Product_Item_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_Item_DetailQuery, Get_Product_Item_DetailQueryVariables>(Get_Product_Item_DetailDocument, baseOptions);
        }
export type Get_Product_Item_DetailQueryHookResult = ReturnType<typeof useGet_Product_Item_DetailQuery>;
export type Get_Product_Item_DetailLazyQueryHookResult = ReturnType<typeof useGet_Product_Item_DetailLazyQuery>;
export type Get_Product_Item_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Product_Item_DetailQuery, Get_Product_Item_DetailQueryVariables>;
export const Update_Product_Item_DetailDocument = gql`
    mutation UPDATE_PRODUCT_ITEM_DETAIL($productItemId: uuid!, $price: numeric!, $commissionRate: numeric!) {
  update_product_item(
    where: {id: {_eq: $productItemId}}
    _set: {price: $price, commission_rate: $commissionRate}
  ) {
    affected_rows
  }
}
    `;
export type Update_Product_Item_DetailMutationFn = ApolloReactCommon.MutationFunction<Update_Product_Item_DetailMutation, Update_Product_Item_DetailMutationVariables>;

/**
 * __useUpdate_Product_Item_DetailMutation__
 *
 * To run a mutation, you first call `useUpdate_Product_Item_DetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Product_Item_DetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductItemDetailMutation, { data, loading, error }] = useUpdate_Product_Item_DetailMutation({
 *   variables: {
 *      productItemId: // value for 'productItemId'
 *      price: // value for 'price'
 *      commissionRate: // value for 'commissionRate'
 *   },
 * });
 */
export function useUpdate_Product_Item_DetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Product_Item_DetailMutation, Update_Product_Item_DetailMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Product_Item_DetailMutation, Update_Product_Item_DetailMutationVariables>(Update_Product_Item_DetailDocument, baseOptions);
      }
export type Update_Product_Item_DetailMutationHookResult = ReturnType<typeof useUpdate_Product_Item_DetailMutation>;
export type Update_Product_Item_DetailMutationResult = ApolloReactCommon.MutationResult<Update_Product_Item_DetailMutation>;
export type Update_Product_Item_DetailMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Product_Item_DetailMutation, Update_Product_Item_DetailMutationVariables>;
export const Get_Product_Items_By_Product_IdDocument = gql`
    query GET_PRODUCT_ITEMS_BY_PRODUCT_ID($id: uuid!) {
  product_by_pk(id: $id) {
    type
    product_items {
      id
      attribute
      product
      price
      sku
      commission_rate
      productByProduct {
        product_brand {
          description
        }
        name
        product_type {
          value
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGet_Product_Items_By_Product_IdQuery__
 *
 * To run a query within a React component, call `useGet_Product_Items_By_Product_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_Items_By_Product_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_Items_By_Product_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Product_Items_By_Product_IdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_Items_By_Product_IdQuery, Get_Product_Items_By_Product_IdQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_Items_By_Product_IdQuery, Get_Product_Items_By_Product_IdQueryVariables>(Get_Product_Items_By_Product_IdDocument, baseOptions);
      }
export function useGet_Product_Items_By_Product_IdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_Items_By_Product_IdQuery, Get_Product_Items_By_Product_IdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_Items_By_Product_IdQuery, Get_Product_Items_By_Product_IdQueryVariables>(Get_Product_Items_By_Product_IdDocument, baseOptions);
        }
export type Get_Product_Items_By_Product_IdQueryHookResult = ReturnType<typeof useGet_Product_Items_By_Product_IdQuery>;
export type Get_Product_Items_By_Product_IdLazyQueryHookResult = ReturnType<typeof useGet_Product_Items_By_Product_IdLazyQuery>;
export type Get_Product_Items_By_Product_IdQueryResult = ApolloReactCommon.QueryResult<Get_Product_Items_By_Product_IdQuery, Get_Product_Items_By_Product_IdQueryVariables>;
export const Upsert_Product_ItemsDocument = gql`
    mutation UPSERT_PRODUCT_ITEMS($productObject: [product_item_insert_input!]!) {
  insert_product_item(
    objects: $productObject
    on_conflict: {constraint: product_item_pkey, update_columns: [sku, price, commission_rate, attribute]}
  ) {
    affected_rows
  }
}
    `;
export type Upsert_Product_ItemsMutationFn = ApolloReactCommon.MutationFunction<Upsert_Product_ItemsMutation, Upsert_Product_ItemsMutationVariables>;

/**
 * __useUpsert_Product_ItemsMutation__
 *
 * To run a mutation, you first call `useUpsert_Product_ItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_Product_ItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductItemsMutation, { data, loading, error }] = useUpsert_Product_ItemsMutation({
 *   variables: {
 *      productObject: // value for 'productObject'
 *   },
 * });
 */
export function useUpsert_Product_ItemsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Upsert_Product_ItemsMutation, Upsert_Product_ItemsMutationVariables>) {
        return ApolloReactHooks.useMutation<Upsert_Product_ItemsMutation, Upsert_Product_ItemsMutationVariables>(Upsert_Product_ItemsDocument, baseOptions);
      }
export type Upsert_Product_ItemsMutationHookResult = ReturnType<typeof useUpsert_Product_ItemsMutation>;
export type Upsert_Product_ItemsMutationResult = ApolloReactCommon.MutationResult<Upsert_Product_ItemsMutation>;
export type Upsert_Product_ItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<Upsert_Product_ItemsMutation, Upsert_Product_ItemsMutationVariables>;
export const Get_Product_TypeDocument = gql`
    query GET_PRODUCT_TYPE {
  product_type {
    value
    description
  }
}
    `;

/**
 * __useGet_Product_TypeQuery__
 *
 * To run a query within a React component, call `useGet_Product_TypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_TypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_TypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Product_TypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_TypeQuery, Get_Product_TypeQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_TypeQuery, Get_Product_TypeQueryVariables>(Get_Product_TypeDocument, baseOptions);
      }
export function useGet_Product_TypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_TypeQuery, Get_Product_TypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_TypeQuery, Get_Product_TypeQueryVariables>(Get_Product_TypeDocument, baseOptions);
        }
export type Get_Product_TypeQueryHookResult = ReturnType<typeof useGet_Product_TypeQuery>;
export type Get_Product_TypeLazyQueryHookResult = ReturnType<typeof useGet_Product_TypeLazyQuery>;
export type Get_Product_TypeQueryResult = ApolloReactCommon.QueryResult<Get_Product_TypeQuery, Get_Product_TypeQueryVariables>;
export const Insert_Product_TypeDocument = gql`
    mutation INSERT_PRODUCT_TYPE($productTypeObject: product_type_insert_input!) {
  insert_product_type_one(object: $productTypeObject) {
    value
  }
}
    `;
export type Insert_Product_TypeMutationFn = ApolloReactCommon.MutationFunction<Insert_Product_TypeMutation, Insert_Product_TypeMutationVariables>;

/**
 * __useInsert_Product_TypeMutation__
 *
 * To run a mutation, you first call `useInsert_Product_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Product_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductTypeMutation, { data, loading, error }] = useInsert_Product_TypeMutation({
 *   variables: {
 *      productTypeObject: // value for 'productTypeObject'
 *   },
 * });
 */
export function useInsert_Product_TypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_Product_TypeMutation, Insert_Product_TypeMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_Product_TypeMutation, Insert_Product_TypeMutationVariables>(Insert_Product_TypeDocument, baseOptions);
      }
export type Insert_Product_TypeMutationHookResult = ReturnType<typeof useInsert_Product_TypeMutation>;
export type Insert_Product_TypeMutationResult = ApolloReactCommon.MutationResult<Insert_Product_TypeMutation>;
export type Insert_Product_TypeMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_Product_TypeMutation, Insert_Product_TypeMutationVariables>;
export const Get_Profile_CustomerDocument = gql`
    query GET_PROFILE_CUSTOMER($offset: Int = 0) {
  profile_customer: profile_kr_customer(
    where: {}
    order_by: {created_at: desc}
    offset: $offset
  ) {
    id
    name
    email
    created_at
    phone_mobile
    account
    accountByAccount {
      account_type {
        value
      }
    }
  }
}
    `;

/**
 * __useGet_Profile_CustomerQuery__
 *
 * To run a query within a React component, call `useGet_Profile_CustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Profile_CustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Profile_CustomerQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGet_Profile_CustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Profile_CustomerQuery, Get_Profile_CustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Profile_CustomerQuery, Get_Profile_CustomerQueryVariables>(Get_Profile_CustomerDocument, baseOptions);
      }
export function useGet_Profile_CustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Profile_CustomerQuery, Get_Profile_CustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Profile_CustomerQuery, Get_Profile_CustomerQueryVariables>(Get_Profile_CustomerDocument, baseOptions);
        }
export type Get_Profile_CustomerQueryHookResult = ReturnType<typeof useGet_Profile_CustomerQuery>;
export type Get_Profile_CustomerLazyQueryHookResult = ReturnType<typeof useGet_Profile_CustomerLazyQuery>;
export type Get_Profile_CustomerQueryResult = ApolloReactCommon.QueryResult<Get_Profile_CustomerQuery, Get_Profile_CustomerQueryVariables>;
export const Get_Profile_Customer_DetailDocument = gql`
    query GET_PROFILE_CUSTOMER_DETAIL($id: uuid!) {
  profile_customer: profile_kr_customer(where: {id: {_eq: $id}}) {
    created_at
    name
    email
    email_verified_at
    phone_mobile
    birthday
    account
    accountByAccount {
      firebase_user_uid
      account_type {
        value
      }
      orders {
        id
        status
        product_item
        car_sunroof_type
        price_final
        date_time
        carSunroofTypeByCarSunroofType {
          description
        }
        order_status {
          value
          description
        }
        storeByStore {
          name
        }
      }
      sessions(where: {activities: {rank: {_eq: 1}}}, order_by: {created_at: desc}) {
        id
        activities(order_by: {created_at: asc}, where: {rank: {_eq: 1}}) {
          action
          context
          rank
        }
      }
    }
  }
}
    `;

/**
 * __useGet_Profile_Customer_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Profile_Customer_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Profile_Customer_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Profile_Customer_DetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Profile_Customer_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Profile_Customer_DetailQuery, Get_Profile_Customer_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Profile_Customer_DetailQuery, Get_Profile_Customer_DetailQueryVariables>(Get_Profile_Customer_DetailDocument, baseOptions);
      }
export function useGet_Profile_Customer_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Profile_Customer_DetailQuery, Get_Profile_Customer_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Profile_Customer_DetailQuery, Get_Profile_Customer_DetailQueryVariables>(Get_Profile_Customer_DetailDocument, baseOptions);
        }
export type Get_Profile_Customer_DetailQueryHookResult = ReturnType<typeof useGet_Profile_Customer_DetailQuery>;
export type Get_Profile_Customer_DetailLazyQueryHookResult = ReturnType<typeof useGet_Profile_Customer_DetailLazyQuery>;
export type Get_Profile_Customer_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Profile_Customer_DetailQuery, Get_Profile_Customer_DetailQueryVariables>;
export const Get_Partner_ProfilesDocument = gql`
    query GET_PARTNER_PROFILES($uid: String!) {
  profile_kr_partner(where: {accountByAccount: {firebase_user_uid: {_eq: $uid}}}) {
    id
  }
}
    `;

/**
 * __useGet_Partner_ProfilesQuery__
 *
 * To run a query within a React component, call `useGet_Partner_ProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Partner_ProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Partner_ProfilesQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGet_Partner_ProfilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Partner_ProfilesQuery, Get_Partner_ProfilesQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Partner_ProfilesQuery, Get_Partner_ProfilesQueryVariables>(Get_Partner_ProfilesDocument, baseOptions);
      }
export function useGet_Partner_ProfilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Partner_ProfilesQuery, Get_Partner_ProfilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Partner_ProfilesQuery, Get_Partner_ProfilesQueryVariables>(Get_Partner_ProfilesDocument, baseOptions);
        }
export type Get_Partner_ProfilesQueryHookResult = ReturnType<typeof useGet_Partner_ProfilesQuery>;
export type Get_Partner_ProfilesLazyQueryHookResult = ReturnType<typeof useGet_Partner_ProfilesLazyQuery>;
export type Get_Partner_ProfilesQueryResult = ApolloReactCommon.QueryResult<Get_Partner_ProfilesQuery, Get_Partner_ProfilesQueryVariables>;
export const Delete_Customer_AccountDocument = gql`
    mutation DELETE_CUSTOMER_ACCOUNT($profileId: uuid!, $accountId: uuid!, $now: timestamptz) {
  update_profile_kr_customer_by_pk(
    pk_columns: {id: $profileId}
    _set: {deleted_at: $now}
  ) {
    id
  }
  update_account_by_pk(pk_columns: {id: $accountId}, _set: {deleted_at: $now}) {
    id
  }
}
    `;
export type Delete_Customer_AccountMutationFn = ApolloReactCommon.MutationFunction<Delete_Customer_AccountMutation, Delete_Customer_AccountMutationVariables>;

/**
 * __useDelete_Customer_AccountMutation__
 *
 * To run a mutation, you first call `useDelete_Customer_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Customer_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAccountMutation, { data, loading, error }] = useDelete_Customer_AccountMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      accountId: // value for 'accountId'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useDelete_Customer_AccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Customer_AccountMutation, Delete_Customer_AccountMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Customer_AccountMutation, Delete_Customer_AccountMutationVariables>(Delete_Customer_AccountDocument, baseOptions);
      }
export type Delete_Customer_AccountMutationHookResult = ReturnType<typeof useDelete_Customer_AccountMutation>;
export type Delete_Customer_AccountMutationResult = ApolloReactCommon.MutationResult<Delete_Customer_AccountMutation>;
export type Delete_Customer_AccountMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Customer_AccountMutation, Delete_Customer_AccountMutationVariables>;
export const Delete_Customer_And_Partner_AccountDocument = gql`
    mutation DELETE_CUSTOMER_AND_PARTNER_ACCOUNT($profileId: uuid!, $partnerProfileId: uuid!, $accountId: uuid!, $now: timestamptz) {
  update_profile_kr_customer_by_pk(
    pk_columns: {id: $profileId}
    _set: {deleted_at: $now}
  ) {
    id
  }
  update_profile_kr_partner_by_pk(
    pk_columns: {id: $partnerProfileId}
    _set: {deleted_at: $now}
  ) {
    id
  }
  update_account_by_pk(pk_columns: {id: $accountId}, _set: {deleted_at: $now}) {
    id
  }
}
    `;
export type Delete_Customer_And_Partner_AccountMutationFn = ApolloReactCommon.MutationFunction<Delete_Customer_And_Partner_AccountMutation, Delete_Customer_And_Partner_AccountMutationVariables>;

/**
 * __useDelete_Customer_And_Partner_AccountMutation__
 *
 * To run a mutation, you first call `useDelete_Customer_And_Partner_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Customer_And_Partner_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAndPartnerAccountMutation, { data, loading, error }] = useDelete_Customer_And_Partner_AccountMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      partnerProfileId: // value for 'partnerProfileId'
 *      accountId: // value for 'accountId'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useDelete_Customer_And_Partner_AccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Customer_And_Partner_AccountMutation, Delete_Customer_And_Partner_AccountMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Customer_And_Partner_AccountMutation, Delete_Customer_And_Partner_AccountMutationVariables>(Delete_Customer_And_Partner_AccountDocument, baseOptions);
      }
export type Delete_Customer_And_Partner_AccountMutationHookResult = ReturnType<typeof useDelete_Customer_And_Partner_AccountMutation>;
export type Delete_Customer_And_Partner_AccountMutationResult = ApolloReactCommon.MutationResult<Delete_Customer_And_Partner_AccountMutation>;
export type Delete_Customer_And_Partner_AccountMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Customer_And_Partner_AccountMutation, Delete_Customer_And_Partner_AccountMutationVariables>;
export const Update_Customer_Name_Phone_NumberDocument = gql`
    mutation UPDATE_CUSTOMER_NAME_PHONE_NUMBER($profileId: uuid!, $newName: String, $newPhoneNumber: String) {
  update_profile_kr_customer_by_pk(
    pk_columns: {id: $profileId}
    _set: {name: $newName, phone_mobile: $newPhoneNumber}
  ) {
    name
    phone_mobile
  }
}
    `;
export type Update_Customer_Name_Phone_NumberMutationFn = ApolloReactCommon.MutationFunction<Update_Customer_Name_Phone_NumberMutation, Update_Customer_Name_Phone_NumberMutationVariables>;

/**
 * __useUpdate_Customer_Name_Phone_NumberMutation__
 *
 * To run a mutation, you first call `useUpdate_Customer_Name_Phone_NumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Customer_Name_Phone_NumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerNamePhoneNumberMutation, { data, loading, error }] = useUpdate_Customer_Name_Phone_NumberMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      newName: // value for 'newName'
 *      newPhoneNumber: // value for 'newPhoneNumber'
 *   },
 * });
 */
export function useUpdate_Customer_Name_Phone_NumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Customer_Name_Phone_NumberMutation, Update_Customer_Name_Phone_NumberMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Customer_Name_Phone_NumberMutation, Update_Customer_Name_Phone_NumberMutationVariables>(Update_Customer_Name_Phone_NumberDocument, baseOptions);
      }
export type Update_Customer_Name_Phone_NumberMutationHookResult = ReturnType<typeof useUpdate_Customer_Name_Phone_NumberMutation>;
export type Update_Customer_Name_Phone_NumberMutationResult = ApolloReactCommon.MutationResult<Update_Customer_Name_Phone_NumberMutation>;
export type Update_Customer_Name_Phone_NumberMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Customer_Name_Phone_NumberMutation, Update_Customer_Name_Phone_NumberMutationVariables>;
export const Get_Profile_SearchDocument = gql`
    query GET_PROFILE_SEARCH($limit: Int = 1000000, $offset: Int = 0, $searchKeyword: String = "%") {
  profile_partner: profile_kr_partner(
    where: {}
    order_by: {created_at: desc}
    limit: $limit
    offset: $offset
  ) {
    id
    created_at
    name
    email
    phone_mobile
    account
    accountByAccount {
      account_type {
        value
      }
      store_and_accounts {
        storeByStore {
          email
          phone_business
          name
        }
      }
    }
  }
  profile_partner_count: profile_kr_partner_aggregate(where: {}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Profile_SearchQuery__
 *
 * To run a query within a React component, call `useGet_Profile_SearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Profile_SearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Profile_SearchQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      searchKeyword: // value for 'searchKeyword'
 *   },
 * });
 */
export function useGet_Profile_SearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Profile_SearchQuery, Get_Profile_SearchQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Profile_SearchQuery, Get_Profile_SearchQueryVariables>(Get_Profile_SearchDocument, baseOptions);
      }
export function useGet_Profile_SearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Profile_SearchQuery, Get_Profile_SearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Profile_SearchQuery, Get_Profile_SearchQueryVariables>(Get_Profile_SearchDocument, baseOptions);
        }
export type Get_Profile_SearchQueryHookResult = ReturnType<typeof useGet_Profile_SearchQuery>;
export type Get_Profile_SearchLazyQueryHookResult = ReturnType<typeof useGet_Profile_SearchLazyQuery>;
export type Get_Profile_SearchQueryResult = ApolloReactCommon.QueryResult<Get_Profile_SearchQuery, Get_Profile_SearchQueryVariables>;
export const Get_Profile_Partner_DetailDocument = gql`
    query GET_PROFILE_PARTNER_DETAIL($id: uuid!) {
  profile_partner: profile_kr_partner(where: {id: {_eq: $id}}) {
    created_at
    name
    email
    email_verified_at
    phone_mobile
    email_verified_at
    stripe_customer_id
    rapyd_customer_id
    account
    accountByAccount {
      firebase_user_uid
      account_type {
        value
      }
      subscriptions {
        storeByStore {
          id
          email
          phone_business
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGet_Profile_Partner_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Profile_Partner_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Profile_Partner_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Profile_Partner_DetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Profile_Partner_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Profile_Partner_DetailQuery, Get_Profile_Partner_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Profile_Partner_DetailQuery, Get_Profile_Partner_DetailQueryVariables>(Get_Profile_Partner_DetailDocument, baseOptions);
      }
export function useGet_Profile_Partner_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Profile_Partner_DetailQuery, Get_Profile_Partner_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Profile_Partner_DetailQuery, Get_Profile_Partner_DetailQueryVariables>(Get_Profile_Partner_DetailDocument, baseOptions);
        }
export type Get_Profile_Partner_DetailQueryHookResult = ReturnType<typeof useGet_Profile_Partner_DetailQuery>;
export type Get_Profile_Partner_DetailLazyQueryHookResult = ReturnType<typeof useGet_Profile_Partner_DetailLazyQuery>;
export type Get_Profile_Partner_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Profile_Partner_DetailQuery, Get_Profile_Partner_DetailQueryVariables>;
export const Get_Customer_ProfileDocument = gql`
    query GET_CUSTOMER_PROFILE($uid: String!) {
  profile_kr_customer(where: {accountByAccount: {firebase_user_uid: {_eq: $uid}}}) {
    id
  }
}
    `;

/**
 * __useGet_Customer_ProfileQuery__
 *
 * To run a query within a React component, call `useGet_Customer_ProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Customer_ProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Customer_ProfileQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGet_Customer_ProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Customer_ProfileQuery, Get_Customer_ProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Customer_ProfileQuery, Get_Customer_ProfileQueryVariables>(Get_Customer_ProfileDocument, baseOptions);
      }
export function useGet_Customer_ProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Customer_ProfileQuery, Get_Customer_ProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Customer_ProfileQuery, Get_Customer_ProfileQueryVariables>(Get_Customer_ProfileDocument, baseOptions);
        }
export type Get_Customer_ProfileQueryHookResult = ReturnType<typeof useGet_Customer_ProfileQuery>;
export type Get_Customer_ProfileLazyQueryHookResult = ReturnType<typeof useGet_Customer_ProfileLazyQuery>;
export type Get_Customer_ProfileQueryResult = ApolloReactCommon.QueryResult<Get_Customer_ProfileQuery, Get_Customer_ProfileQueryVariables>;
export const Delete_Partner_AccountDocument = gql`
    mutation DELETE_PARTNER_ACCOUNT($profileId: uuid!, $accountId: uuid!, $now: timestamptz) {
  update_profile_kr_partner_by_pk(
    pk_columns: {id: $profileId}
    _set: {deleted_at: $now}
  ) {
    id
  }
  update_account_by_pk(pk_columns: {id: $accountId}, _set: {deleted_at: $now}) {
    id
  }
}
    `;
export type Delete_Partner_AccountMutationFn = ApolloReactCommon.MutationFunction<Delete_Partner_AccountMutation, Delete_Partner_AccountMutationVariables>;

/**
 * __useDelete_Partner_AccountMutation__
 *
 * To run a mutation, you first call `useDelete_Partner_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Partner_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerAccountMutation, { data, loading, error }] = useDelete_Partner_AccountMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      accountId: // value for 'accountId'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useDelete_Partner_AccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Partner_AccountMutation, Delete_Partner_AccountMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Partner_AccountMutation, Delete_Partner_AccountMutationVariables>(Delete_Partner_AccountDocument, baseOptions);
      }
export type Delete_Partner_AccountMutationHookResult = ReturnType<typeof useDelete_Partner_AccountMutation>;
export type Delete_Partner_AccountMutationResult = ApolloReactCommon.MutationResult<Delete_Partner_AccountMutation>;
export type Delete_Partner_AccountMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Partner_AccountMutation, Delete_Partner_AccountMutationVariables>;
export const Delete_Partner_And_Customer_AccountDocument = gql`
    mutation DELETE_PARTNER_AND_CUSTOMER_ACCOUNT($profileId: uuid!, $customerProfileId: uuid!, $accountId: uuid!, $now: timestamptz) {
  update_profile_kr_partner_by_pk(
    pk_columns: {id: $profileId}
    _set: {deleted_at: $now}
  ) {
    id
  }
  update_profile_kr_customer_by_pk(
    pk_columns: {id: $customerProfileId}
    _set: {deleted_at: $now}
  ) {
    id
  }
  update_account_by_pk(pk_columns: {id: $accountId}, _set: {deleted_at: $now}) {
    id
  }
}
    `;
export type Delete_Partner_And_Customer_AccountMutationFn = ApolloReactCommon.MutationFunction<Delete_Partner_And_Customer_AccountMutation, Delete_Partner_And_Customer_AccountMutationVariables>;

/**
 * __useDelete_Partner_And_Customer_AccountMutation__
 *
 * To run a mutation, you first call `useDelete_Partner_And_Customer_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Partner_And_Customer_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerAndCustomerAccountMutation, { data, loading, error }] = useDelete_Partner_And_Customer_AccountMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      customerProfileId: // value for 'customerProfileId'
 *      accountId: // value for 'accountId'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useDelete_Partner_And_Customer_AccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Partner_And_Customer_AccountMutation, Delete_Partner_And_Customer_AccountMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Partner_And_Customer_AccountMutation, Delete_Partner_And_Customer_AccountMutationVariables>(Delete_Partner_And_Customer_AccountDocument, baseOptions);
      }
export type Delete_Partner_And_Customer_AccountMutationHookResult = ReturnType<typeof useDelete_Partner_And_Customer_AccountMutation>;
export type Delete_Partner_And_Customer_AccountMutationResult = ApolloReactCommon.MutationResult<Delete_Partner_And_Customer_AccountMutation>;
export type Delete_Partner_And_Customer_AccountMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Partner_And_Customer_AccountMutation, Delete_Partner_And_Customer_AccountMutationVariables>;
export const Update_Profile_PartnerDocument = gql`
    mutation UPDATE_PROFILE_PARTNER($account: uuid!, $newEmail: String!, $newName: String!, $phoneMobile: String!) {
  update_profile_kr_partner(
    where: {account: {_eq: $account}}
    _set: {email: $newEmail, name: $newName, phone_mobile: $phoneMobile}
  ) {
    affected_rows
  }
}
    `;
export type Update_Profile_PartnerMutationFn = ApolloReactCommon.MutationFunction<Update_Profile_PartnerMutation, Update_Profile_PartnerMutationVariables>;

/**
 * __useUpdate_Profile_PartnerMutation__
 *
 * To run a mutation, you first call `useUpdate_Profile_PartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Profile_PartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePartnerMutation, { data, loading, error }] = useUpdate_Profile_PartnerMutation({
 *   variables: {
 *      account: // value for 'account'
 *      newEmail: // value for 'newEmail'
 *      newName: // value for 'newName'
 *      phoneMobile: // value for 'phoneMobile'
 *   },
 * });
 */
export function useUpdate_Profile_PartnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Profile_PartnerMutation, Update_Profile_PartnerMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Profile_PartnerMutation, Update_Profile_PartnerMutationVariables>(Update_Profile_PartnerDocument, baseOptions);
      }
export type Update_Profile_PartnerMutationHookResult = ReturnType<typeof useUpdate_Profile_PartnerMutation>;
export type Update_Profile_PartnerMutationResult = ApolloReactCommon.MutationResult<Update_Profile_PartnerMutation>;
export type Update_Profile_PartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Profile_PartnerMutation, Update_Profile_PartnerMutationVariables>;
export const Update_All_ProfileDocument = gql`
    mutation UPDATE_ALL_PROFILE($account: uuid!, $newEmail: String!, $newName: String!, $phoneMobile: String!) {
  update_profile_kr_partner(
    where: {account: {_eq: $account}}
    _set: {email: $newEmail, name: $newName, phone_mobile: $phoneMobile}
  ) {
    affected_rows
  }
  update_profile_kr_customer(
    where: {account: {_eq: $account}}
    _set: {email: $newEmail, name: $newName, phone_mobile: $phoneMobile}
  ) {
    affected_rows
  }
}
    `;
export type Update_All_ProfileMutationFn = ApolloReactCommon.MutationFunction<Update_All_ProfileMutation, Update_All_ProfileMutationVariables>;

/**
 * __useUpdate_All_ProfileMutation__
 *
 * To run a mutation, you first call `useUpdate_All_ProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_All_ProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllProfileMutation, { data, loading, error }] = useUpdate_All_ProfileMutation({
 *   variables: {
 *      account: // value for 'account'
 *      newEmail: // value for 'newEmail'
 *      newName: // value for 'newName'
 *      phoneMobile: // value for 'phoneMobile'
 *   },
 * });
 */
export function useUpdate_All_ProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_All_ProfileMutation, Update_All_ProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_All_ProfileMutation, Update_All_ProfileMutationVariables>(Update_All_ProfileDocument, baseOptions);
      }
export type Update_All_ProfileMutationHookResult = ReturnType<typeof useUpdate_All_ProfileMutation>;
export type Update_All_ProfileMutationResult = ApolloReactCommon.MutationResult<Update_All_ProfileMutation>;
export type Update_All_ProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_All_ProfileMutation, Update_All_ProfileMutationVariables>;
export const Get_Quote_RequestDocument = gql`
    query GET_QUOTE_REQUEST($offset: Int!, $startDate: timestamptz, $endDate: timestamptz, $createdAt: order_by = desc) {
  quote_request: quote_request(
    where: {_and: {deleted_at: {_is_null: true}, created_at: {_gt: $startDate, _lt: $endDate}}}
    offset: $offset
    limit: 100
    order_by: {created_at: $createdAt}
  ) {
    id
    created_at
    session
    account
    content
    sent_at
    phone_number
  }
  request_count: quote_request_aggregate(
    where: {_and: {deleted_at: {_is_null: true}, created_at: {_gt: $startDate, _lt: $endDate}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Quote_RequestQuery__
 *
 * To run a query within a React component, call `useGet_Quote_RequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Quote_RequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Quote_RequestQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useGet_Quote_RequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Quote_RequestQuery, Get_Quote_RequestQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Quote_RequestQuery, Get_Quote_RequestQueryVariables>(Get_Quote_RequestDocument, baseOptions);
      }
export function useGet_Quote_RequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Quote_RequestQuery, Get_Quote_RequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Quote_RequestQuery, Get_Quote_RequestQueryVariables>(Get_Quote_RequestDocument, baseOptions);
        }
export type Get_Quote_RequestQueryHookResult = ReturnType<typeof useGet_Quote_RequestQuery>;
export type Get_Quote_RequestLazyQueryHookResult = ReturnType<typeof useGet_Quote_RequestLazyQuery>;
export type Get_Quote_RequestQueryResult = ApolloReactCommon.QueryResult<Get_Quote_RequestQuery, Get_Quote_RequestQueryVariables>;
export const Update_Quote_RequestDocument = gql`
    mutation UPDATE_QUOTE_REQUEST($phoneNumber: String, $date: timestamptz) {
  update_quote_request(
    where: {phone_number: {_eq: $phoneNumber}}
    _set: {sent_at: $date}
  ) {
    affected_rows
  }
}
    `;
export type Update_Quote_RequestMutationFn = ApolloReactCommon.MutationFunction<Update_Quote_RequestMutation, Update_Quote_RequestMutationVariables>;

/**
 * __useUpdate_Quote_RequestMutation__
 *
 * To run a mutation, you first call `useUpdate_Quote_RequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Quote_RequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteRequestMutation, { data, loading, error }] = useUpdate_Quote_RequestMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdate_Quote_RequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Quote_RequestMutation, Update_Quote_RequestMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Quote_RequestMutation, Update_Quote_RequestMutationVariables>(Update_Quote_RequestDocument, baseOptions);
      }
export type Update_Quote_RequestMutationHookResult = ReturnType<typeof useUpdate_Quote_RequestMutation>;
export type Update_Quote_RequestMutationResult = ApolloReactCommon.MutationResult<Update_Quote_RequestMutation>;
export type Update_Quote_RequestMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Quote_RequestMutation, Update_Quote_RequestMutationVariables>;
export const Get_All_StoreDocument = gql`
    query GET_ALL_STORE {
  store(where: {deleted_at: {_is_null: true}}) {
    id
    name
    llumar_store_name
  }
}
    `;

/**
 * __useGet_All_StoreQuery__
 *
 * To run a query within a React component, call `useGet_All_StoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_StoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_StoreQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_All_StoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_All_StoreQuery, Get_All_StoreQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_All_StoreQuery, Get_All_StoreQueryVariables>(Get_All_StoreDocument, baseOptions);
      }
export function useGet_All_StoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_All_StoreQuery, Get_All_StoreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_All_StoreQuery, Get_All_StoreQueryVariables>(Get_All_StoreDocument, baseOptions);
        }
export type Get_All_StoreQueryHookResult = ReturnType<typeof useGet_All_StoreQuery>;
export type Get_All_StoreLazyQueryHookResult = ReturnType<typeof useGet_All_StoreLazyQuery>;
export type Get_All_StoreQueryResult = ApolloReactCommon.QueryResult<Get_All_StoreQuery, Get_All_StoreQueryVariables>;
export const Get_ReviewDocument = gql`
    query GET_REVIEW {
  review(order_by: {created_at: desc}) {
    id
    created_at
    status
    review_status {
      description
    }
    comment
    rating
    store_and_reviews {
      storeByStore {
        name
      }
    }
    order_and_reviews {
      orderByOrder {
        phone
        leadByLead {
          phone
        }
      }
    }
  }
}
    `;

/**
 * __useGet_ReviewQuery__
 *
 * To run a query within a React component, call `useGet_ReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_ReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_ReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_ReviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_ReviewQuery, Get_ReviewQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_ReviewQuery, Get_ReviewQueryVariables>(Get_ReviewDocument, baseOptions);
      }
export function useGet_ReviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_ReviewQuery, Get_ReviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_ReviewQuery, Get_ReviewQueryVariables>(Get_ReviewDocument, baseOptions);
        }
export type Get_ReviewQueryHookResult = ReturnType<typeof useGet_ReviewQuery>;
export type Get_ReviewLazyQueryHookResult = ReturnType<typeof useGet_ReviewLazyQuery>;
export type Get_ReviewQueryResult = ApolloReactCommon.QueryResult<Get_ReviewQuery, Get_ReviewQueryVariables>;
export const Update_Review_StatusDocument = gql`
    mutation UPDATE_REVIEW_STATUS($reviewId: uuid!, $status: review_status_enum!) {
  update_review(where: {id: {_eq: $reviewId}}, _set: {status: $status}) {
    affected_rows
  }
}
    `;
export type Update_Review_StatusMutationFn = ApolloReactCommon.MutationFunction<Update_Review_StatusMutation, Update_Review_StatusMutationVariables>;

/**
 * __useUpdate_Review_StatusMutation__
 *
 * To run a mutation, you first call `useUpdate_Review_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Review_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewStatusMutation, { data, loading, error }] = useUpdate_Review_StatusMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdate_Review_StatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Review_StatusMutation, Update_Review_StatusMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Review_StatusMutation, Update_Review_StatusMutationVariables>(Update_Review_StatusDocument, baseOptions);
      }
export type Update_Review_StatusMutationHookResult = ReturnType<typeof useUpdate_Review_StatusMutation>;
export type Update_Review_StatusMutationResult = ApolloReactCommon.MutationResult<Update_Review_StatusMutation>;
export type Update_Review_StatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Review_StatusMutation, Update_Review_StatusMutationVariables>;
export const Update_Select_Review_StatusDocument = gql`
    mutation UPDATE_SELECT_REVIEW_STATUS($status: review_status_enum!) {
  update_review(where: {_or: []}, _set: {status: $status}) {
    affected_rows
  }
}
    `;
export type Update_Select_Review_StatusMutationFn = ApolloReactCommon.MutationFunction<Update_Select_Review_StatusMutation, Update_Select_Review_StatusMutationVariables>;

/**
 * __useUpdate_Select_Review_StatusMutation__
 *
 * To run a mutation, you first call `useUpdate_Select_Review_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Select_Review_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectReviewStatusMutation, { data, loading, error }] = useUpdate_Select_Review_StatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdate_Select_Review_StatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Select_Review_StatusMutation, Update_Select_Review_StatusMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Select_Review_StatusMutation, Update_Select_Review_StatusMutationVariables>(Update_Select_Review_StatusDocument, baseOptions);
      }
export type Update_Select_Review_StatusMutationHookResult = ReturnType<typeof useUpdate_Select_Review_StatusMutation>;
export type Update_Select_Review_StatusMutationResult = ApolloReactCommon.MutationResult<Update_Select_Review_StatusMutation>;
export type Update_Select_Review_StatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Select_Review_StatusMutation, Update_Select_Review_StatusMutationVariables>;
export const Get_Page_IdDocument = gql`
    query GET_PAGE_ID {
  gallery {
    id
  }
  store {
    id
  }
  product {
    id
  }
}
    `;

/**
 * __useGet_Page_IdQuery__
 *
 * To run a query within a React component, call `useGet_Page_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Page_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Page_IdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Page_IdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Page_IdQuery, Get_Page_IdQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Page_IdQuery, Get_Page_IdQueryVariables>(Get_Page_IdDocument, baseOptions);
      }
export function useGet_Page_IdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Page_IdQuery, Get_Page_IdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Page_IdQuery, Get_Page_IdQueryVariables>(Get_Page_IdDocument, baseOptions);
        }
export type Get_Page_IdQueryHookResult = ReturnType<typeof useGet_Page_IdQuery>;
export type Get_Page_IdLazyQueryHookResult = ReturnType<typeof useGet_Page_IdLazyQuery>;
export type Get_Page_IdQueryResult = ApolloReactCommon.QueryResult<Get_Page_IdQuery, Get_Page_IdQueryVariables>;
export const Get_Store_SearchDocument = gql`
    query GET_STORE_SEARCH($limit: Int = 20, $offset: Int = 0) {
  stores: store(
    where: {}
    order_by: {created_at: desc}
    limit: $limit
    offset: $offset
  ) {
    status
    id
    name
    email
    llumar_store_name
    address_detail
    addressSigunguByAddressSigungu {
      id
      name
      address_sido {
        name
      }
    }
    membership {
      id
      store
      type
      start_date
      end_date
      price
      membership_type {
        value
      }
    }
    subscriptions {
      status
    }
  }
  stores_count: store_aggregate(where: {}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Store_SearchQuery__
 *
 * To run a query within a React component, call `useGet_Store_SearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Store_SearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Store_SearchQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGet_Store_SearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Store_SearchQuery, Get_Store_SearchQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Store_SearchQuery, Get_Store_SearchQueryVariables>(Get_Store_SearchDocument, baseOptions);
      }
export function useGet_Store_SearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Store_SearchQuery, Get_Store_SearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Store_SearchQuery, Get_Store_SearchQueryVariables>(Get_Store_SearchDocument, baseOptions);
        }
export type Get_Store_SearchQueryHookResult = ReturnType<typeof useGet_Store_SearchQuery>;
export type Get_Store_SearchLazyQueryHookResult = ReturnType<typeof useGet_Store_SearchLazyQuery>;
export type Get_Store_SearchQueryResult = ApolloReactCommon.QueryResult<Get_Store_SearchQuery, Get_Store_SearchQueryVariables>;
export const Delete_StoreDocument = gql`
    mutation DELETE_STORE($id: uuid!, $deletedAt: timestamptz!) {
  update_store(where: {id: {_eq: $id}}, _set: {deleted_at: $deletedAt}) {
    affected_rows
  }
}
    `;
export type Delete_StoreMutationFn = ApolloReactCommon.MutationFunction<Delete_StoreMutation, Delete_StoreMutationVariables>;

/**
 * __useDelete_StoreMutation__
 *
 * To run a mutation, you first call `useDelete_StoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_StoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreMutation, { data, loading, error }] = useDelete_StoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useDelete_StoreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_StoreMutation, Delete_StoreMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_StoreMutation, Delete_StoreMutationVariables>(Delete_StoreDocument, baseOptions);
      }
export type Delete_StoreMutationHookResult = ReturnType<typeof useDelete_StoreMutation>;
export type Delete_StoreMutationResult = ApolloReactCommon.MutationResult<Delete_StoreMutation>;
export type Delete_StoreMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_StoreMutation, Delete_StoreMutationVariables>;
export const Get_PackagesDocument = gql`
    query GET_PACKAGES {
  normalPackages: package(
    where: {_and: [{attribute: {_contains: {grade: "normal"}}}, {status: {_eq: true}}]}
  ) {
    id
    name
    product
    alias
    image
    name
    status
    price_product
    price_discount
    price_payout
    attribute
  }
  bestPackages: package(
    where: {_and: [{attribute: {_contains: {grade: "best"}}}, {status: {_eq: true}}]}
  ) {
    id
    name
    product
    alias
    image
    name
    status
    price_product
    price_discount
    price_payout
    attribute
  }
  excellentPackages: package(
    where: {_and: [{attribute: {_contains: {grade: "excellent"}}}, {status: {_eq: true}}]}
  ) {
    id
    name
    product
    alias
    image
    name
    status
    price_product
    price_discount
    price_payout
    attribute
  }
  economicPackages: package(
    where: {_and: [{attribute: {_contains: {grade: "economic"}}}, {status: {_eq: true}}]}
  ) {
    id
    name
    product
    alias
    image
    name
    status
    price_product
    price_discount
    price_payout
    attribute
  }
  teslaPacakges: package(
    where: {_and: [{attribute: {_contains: {grade: "tesla_premium"}}}, {status: {_eq: true}}]}
  ) {
    id
    name
    product
    alias
    image
    name
    status
    price_product
    price_discount
    price_payout
    attribute
  }
}
    `;

/**
 * __useGet_PackagesQuery__
 *
 * To run a query within a React component, call `useGet_PackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_PackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_PackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_PackagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_PackagesQuery, Get_PackagesQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_PackagesQuery, Get_PackagesQueryVariables>(Get_PackagesDocument, baseOptions);
      }
export function useGet_PackagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_PackagesQuery, Get_PackagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_PackagesQuery, Get_PackagesQueryVariables>(Get_PackagesDocument, baseOptions);
        }
export type Get_PackagesQueryHookResult = ReturnType<typeof useGet_PackagesQuery>;
export type Get_PackagesLazyQueryHookResult = ReturnType<typeof useGet_PackagesLazyQuery>;
export type Get_PackagesQueryResult = ApolloReactCommon.QueryResult<Get_PackagesQuery, Get_PackagesQueryVariables>;
export const Get_Package_ProductDocument = gql`
    query GET_PACKAGE_PRODUCT {
  product(where: {_or: []}) {
    id
    name
    type
    product_brand {
      description
    }
  }
}
    `;

/**
 * __useGet_Package_ProductQuery__
 *
 * To run a query within a React component, call `useGet_Package_ProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Package_ProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Package_ProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Package_ProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Package_ProductQuery, Get_Package_ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Package_ProductQuery, Get_Package_ProductQueryVariables>(Get_Package_ProductDocument, baseOptions);
      }
export function useGet_Package_ProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Package_ProductQuery, Get_Package_ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Package_ProductQuery, Get_Package_ProductQueryVariables>(Get_Package_ProductDocument, baseOptions);
        }
export type Get_Package_ProductQueryHookResult = ReturnType<typeof useGet_Package_ProductQuery>;
export type Get_Package_ProductLazyQueryHookResult = ReturnType<typeof useGet_Package_ProductLazyQuery>;
export type Get_Package_ProductQueryResult = ApolloReactCommon.QueryResult<Get_Package_ProductQuery, Get_Package_ProductQueryVariables>;
export const Get_Store_And_PackageDocument = gql`
    query GET_STORE_AND_PACKAGE($id: uuid!) {
  package(where: {id: {_eq: $id}}) {
    name
  }
  store_add_on_product_item: product_item(
    where: {sku: {_like: "%package_add_on_store%"}}
    order_by: {price: desc}
  ) {
    id
    price
    commission_rate
  }
  store_and_package(where: {package: {_eq: $id}}) {
    storeByStore {
      id
    }
  }
  stores: store(
    where: {deleted_at: {_is_null: true}}
    order_by: {addressSigunguByAddressSigungu: {address_sido: {name: asc}}}
  ) {
    id
    name
    llumar_store_name
    address_detail
    addressSigunguByAddressSigungu {
      name
      address_sido {
        name
      }
    }
    store_and_packages(where: {package: {_eq: $id}}) {
      add_on_product_item
    }
  }
  address: address_sido {
    id
    name
    address_sigungus {
      id
      name
    }
  }
}
    `;

/**
 * __useGet_Store_And_PackageQuery__
 *
 * To run a query within a React component, call `useGet_Store_And_PackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Store_And_PackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Store_And_PackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Store_And_PackageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Store_And_PackageQuery, Get_Store_And_PackageQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Store_And_PackageQuery, Get_Store_And_PackageQueryVariables>(Get_Store_And_PackageDocument, baseOptions);
      }
export function useGet_Store_And_PackageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Store_And_PackageQuery, Get_Store_And_PackageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Store_And_PackageQuery, Get_Store_And_PackageQueryVariables>(Get_Store_And_PackageDocument, baseOptions);
        }
export type Get_Store_And_PackageQueryHookResult = ReturnType<typeof useGet_Store_And_PackageQuery>;
export type Get_Store_And_PackageLazyQueryHookResult = ReturnType<typeof useGet_Store_And_PackageLazyQuery>;
export type Get_Store_And_PackageQueryResult = ApolloReactCommon.QueryResult<Get_Store_And_PackageQuery, Get_Store_And_PackageQueryVariables>;
export const Delete_All_And_Insert_Store_And_PackageDocument = gql`
    mutation DELETE_ALL_AND_INSERT_STORE_AND_PACKAGE($packageId: uuid!, $selectedStore: [store_and_package_insert_input!]!) {
  delete_store_and_package(where: {package: {_eq: $packageId}}) {
    affected_rows
  }
  insert_store_and_package(objects: $selectedStore) {
    affected_rows
  }
}
    `;
export type Delete_All_And_Insert_Store_And_PackageMutationFn = ApolloReactCommon.MutationFunction<Delete_All_And_Insert_Store_And_PackageMutation, Delete_All_And_Insert_Store_And_PackageMutationVariables>;

/**
 * __useDelete_All_And_Insert_Store_And_PackageMutation__
 *
 * To run a mutation, you first call `useDelete_All_And_Insert_Store_And_PackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_All_And_Insert_Store_And_PackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllAndInsertStoreAndPackageMutation, { data, loading, error }] = useDelete_All_And_Insert_Store_And_PackageMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *      selectedStore: // value for 'selectedStore'
 *   },
 * });
 */
export function useDelete_All_And_Insert_Store_And_PackageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_All_And_Insert_Store_And_PackageMutation, Delete_All_And_Insert_Store_And_PackageMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_All_And_Insert_Store_And_PackageMutation, Delete_All_And_Insert_Store_And_PackageMutationVariables>(Delete_All_And_Insert_Store_And_PackageDocument, baseOptions);
      }
export type Delete_All_And_Insert_Store_And_PackageMutationHookResult = ReturnType<typeof useDelete_All_And_Insert_Store_And_PackageMutation>;
export type Delete_All_And_Insert_Store_And_PackageMutationResult = ApolloReactCommon.MutationResult<Delete_All_And_Insert_Store_And_PackageMutation>;
export type Delete_All_And_Insert_Store_And_PackageMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_All_And_Insert_Store_And_PackageMutation, Delete_All_And_Insert_Store_And_PackageMutationVariables>;
export const Get_Store_DetailDocument = gql`
    query GET_STORE_DETAIL($id: uuid!) {
  store(where: {id: {_eq: $id}}) {
    id
    status
    name
    email
    image
    description
    location
    attribute
    address_sigungu
    phone_business
    name_business
    addressSigunguByAddressSigungu {
      id
      name
      address_sido {
        name
      }
    }
    address_detail
    store_and_hashtags(
      where: {hashtagByHashtag: {deleted_at: {_is_null: true}}}
      order_by: {order: asc}
    ) {
      order
      hashtagByHashtag {
        id
        name
      }
    }
    store_and_accounts(where: {store_account_role: {value: {_eq: "owner"}}}) {
      accountByAccount {
        profile_kr_partner {
          name
          email
        }
      }
    }
  }
  hashtag: hashtag(where: {deleted_at: {_is_null: true}}) {
    id
    name
  }
  address: address_sigungu {
    id
    name
    address_sido {
      id
      name
    }
  }
  account: profile_kr_partner {
    email
    name
    account
  }
  storeAccount: store_and_account(where: {store: {_eq: $id}}) {
    accountByAccount {
      profile_kr_partner {
        email
        name
        account
      }
    }
  }
}
    `;

/**
 * __useGet_Store_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Store_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Store_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Store_DetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Store_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Store_DetailQuery, Get_Store_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Store_DetailQuery, Get_Store_DetailQueryVariables>(Get_Store_DetailDocument, baseOptions);
      }
export function useGet_Store_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Store_DetailQuery, Get_Store_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Store_DetailQuery, Get_Store_DetailQueryVariables>(Get_Store_DetailDocument, baseOptions);
        }
export type Get_Store_DetailQueryHookResult = ReturnType<typeof useGet_Store_DetailQuery>;
export type Get_Store_DetailLazyQueryHookResult = ReturnType<typeof useGet_Store_DetailLazyQuery>;
export type Get_Store_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Store_DetailQuery, Get_Store_DetailQueryVariables>;
export const Get_Address_And_HashtagDocument = gql`
    query GET_ADDRESS_AND_HASHTAG {
  address: address_sigungu {
    id
    name
    address_sido {
      id
      name
    }
  }
  hashtag: hashtag(where: {deleted_at: {_is_null: true}}) {
    id
    name
  }
  account: profile_kr_partner {
    email
    name
    account
  }
}
    `;

/**
 * __useGet_Address_And_HashtagQuery__
 *
 * To run a query within a React component, call `useGet_Address_And_HashtagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Address_And_HashtagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Address_And_HashtagQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Address_And_HashtagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Address_And_HashtagQuery, Get_Address_And_HashtagQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Address_And_HashtagQuery, Get_Address_And_HashtagQueryVariables>(Get_Address_And_HashtagDocument, baseOptions);
      }
export function useGet_Address_And_HashtagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Address_And_HashtagQuery, Get_Address_And_HashtagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Address_And_HashtagQuery, Get_Address_And_HashtagQueryVariables>(Get_Address_And_HashtagDocument, baseOptions);
        }
export type Get_Address_And_HashtagQueryHookResult = ReturnType<typeof useGet_Address_And_HashtagQuery>;
export type Get_Address_And_HashtagLazyQueryHookResult = ReturnType<typeof useGet_Address_And_HashtagLazyQuery>;
export type Get_Address_And_HashtagQueryResult = ApolloReactCommon.QueryResult<Get_Address_And_HashtagQuery, Get_Address_And_HashtagQueryVariables>;
export const Update_StoreDocument = gql`
    mutation UPDATE_STORE($id: uuid!, $newStoreDetail: store_set_input, $newStoreAccount: store_and_account_set_input) {
  update_store(where: {id: {_eq: $id}}, _set: $newStoreDetail) {
    affected_rows
  }
  update_store_and_account(where: {store: {_eq: $id}}, _set: $newStoreAccount) {
    affected_rows
  }
}
    `;
export type Update_StoreMutationFn = ApolloReactCommon.MutationFunction<Update_StoreMutation, Update_StoreMutationVariables>;

/**
 * __useUpdate_StoreMutation__
 *
 * To run a mutation, you first call `useUpdate_StoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_StoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdate_StoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newStoreDetail: // value for 'newStoreDetail'
 *      newStoreAccount: // value for 'newStoreAccount'
 *   },
 * });
 */
export function useUpdate_StoreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_StoreMutation, Update_StoreMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_StoreMutation, Update_StoreMutationVariables>(Update_StoreDocument, baseOptions);
      }
export type Update_StoreMutationHookResult = ReturnType<typeof useUpdate_StoreMutation>;
export type Update_StoreMutationResult = ApolloReactCommon.MutationResult<Update_StoreMutation>;
export type Update_StoreMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_StoreMutation, Update_StoreMutationVariables>;
export const Delete_All_Store_HashtagDocument = gql`
    mutation DELETE_ALL_STORE_HASHTAG($id: uuid!) {
  delete_store_and_hashtag(where: {store: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type Delete_All_Store_HashtagMutationFn = ApolloReactCommon.MutationFunction<Delete_All_Store_HashtagMutation, Delete_All_Store_HashtagMutationVariables>;

/**
 * __useDelete_All_Store_HashtagMutation__
 *
 * To run a mutation, you first call `useDelete_All_Store_HashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_All_Store_HashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllStoreHashtagMutation, { data, loading, error }] = useDelete_All_Store_HashtagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_All_Store_HashtagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_All_Store_HashtagMutation, Delete_All_Store_HashtagMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_All_Store_HashtagMutation, Delete_All_Store_HashtagMutationVariables>(Delete_All_Store_HashtagDocument, baseOptions);
      }
export type Delete_All_Store_HashtagMutationHookResult = ReturnType<typeof useDelete_All_Store_HashtagMutation>;
export type Delete_All_Store_HashtagMutationResult = ApolloReactCommon.MutationResult<Delete_All_Store_HashtagMutation>;
export type Delete_All_Store_HashtagMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_All_Store_HashtagMutation, Delete_All_Store_HashtagMutationVariables>;
export const Insert_Store_And_HashtagDocument = gql`
    mutation INSERT_STORE_AND_HASHTAG($selectedHashtagArray: [store_and_hashtag_insert_input!]!) {
  insert_store_and_hashtag(objects: $selectedHashtagArray) {
    affected_rows
  }
}
    `;
export type Insert_Store_And_HashtagMutationFn = ApolloReactCommon.MutationFunction<Insert_Store_And_HashtagMutation, Insert_Store_And_HashtagMutationVariables>;

/**
 * __useInsert_Store_And_HashtagMutation__
 *
 * To run a mutation, you first call `useInsert_Store_And_HashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Store_And_HashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStoreAndHashtagMutation, { data, loading, error }] = useInsert_Store_And_HashtagMutation({
 *   variables: {
 *      selectedHashtagArray: // value for 'selectedHashtagArray'
 *   },
 * });
 */
export function useInsert_Store_And_HashtagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_Store_And_HashtagMutation, Insert_Store_And_HashtagMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_Store_And_HashtagMutation, Insert_Store_And_HashtagMutationVariables>(Insert_Store_And_HashtagDocument, baseOptions);
      }
export type Insert_Store_And_HashtagMutationHookResult = ReturnType<typeof useInsert_Store_And_HashtagMutation>;
export type Insert_Store_And_HashtagMutationResult = ApolloReactCommon.MutationResult<Insert_Store_And_HashtagMutation>;
export type Insert_Store_And_HashtagMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_Store_And_HashtagMutation, Insert_Store_And_HashtagMutationVariables>;
export const Insert_StoreDocument = gql`
    mutation INSERT_STORE($insertObject: store_insert_input!) {
  insert_store_one(object: $insertObject) {
    id
  }
}
    `;
export type Insert_StoreMutationFn = ApolloReactCommon.MutationFunction<Insert_StoreMutation, Insert_StoreMutationVariables>;

/**
 * __useInsert_StoreMutation__
 *
 * To run a mutation, you first call `useInsert_StoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_StoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStoreMutation, { data, loading, error }] = useInsert_StoreMutation({
 *   variables: {
 *      insertObject: // value for 'insertObject'
 *   },
 * });
 */
export function useInsert_StoreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_StoreMutation, Insert_StoreMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_StoreMutation, Insert_StoreMutationVariables>(Insert_StoreDocument, baseOptions);
      }
export type Insert_StoreMutationHookResult = ReturnType<typeof useInsert_StoreMutation>;
export type Insert_StoreMutationResult = ApolloReactCommon.MutationResult<Insert_StoreMutation>;
export type Insert_StoreMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_StoreMutation, Insert_StoreMutationVariables>;
export const Get_Subscription_And_TransactionDocument = gql`
    query GET_SUBSCRIPTION_AND_TRANSACTION {
  subscription_and_transaction(order_by: {created_at: desc}) {
    created_at
    subscriptionBySubscription {
      id
      storeByStore {
        name
        phone_business
        email
      }
    }
    transactionByTransaction {
      id
      amount
      stripe_payment_intent_id
      rapyd_payment_intent_id
      transaction_type {
        value
        description
      }
      refund {
        id
      }
    }
  }
}
    `;

/**
 * __useGet_Subscription_And_TransactionQuery__
 *
 * To run a query within a React component, call `useGet_Subscription_And_TransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Subscription_And_TransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Subscription_And_TransactionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Subscription_And_TransactionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Subscription_And_TransactionQuery, Get_Subscription_And_TransactionQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Subscription_And_TransactionQuery, Get_Subscription_And_TransactionQueryVariables>(Get_Subscription_And_TransactionDocument, baseOptions);
      }
export function useGet_Subscription_And_TransactionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Subscription_And_TransactionQuery, Get_Subscription_And_TransactionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Subscription_And_TransactionQuery, Get_Subscription_And_TransactionQueryVariables>(Get_Subscription_And_TransactionDocument, baseOptions);
        }
export type Get_Subscription_And_TransactionQueryHookResult = ReturnType<typeof useGet_Subscription_And_TransactionQuery>;
export type Get_Subscription_And_TransactionLazyQueryHookResult = ReturnType<typeof useGet_Subscription_And_TransactionLazyQuery>;
export type Get_Subscription_And_TransactionQueryResult = ApolloReactCommon.QueryResult<Get_Subscription_And_TransactionQuery, Get_Subscription_And_TransactionQueryVariables>;
export const Get_Product_DetailDocument = gql`
    query GET_PRODUCT_DETAIL($id: uuid!) {
  product(where: {id: {_eq: $id}}) {
    id
    status
    name
    type
    attribute
    brand
    image
    description
    alias
  }
  product_brand {
    value
    description
  }
  product_item(where: {product: {_eq: $id}}) {
    id
    sku
    price
  }
}
    `;

/**
 * __useGet_Product_DetailQuery__
 *
 * To run a query within a React component, call `useGet_Product_DetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_DetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_DetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Product_DetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_DetailQuery, Get_Product_DetailQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_DetailQuery, Get_Product_DetailQueryVariables>(Get_Product_DetailDocument, baseOptions);
      }
export function useGet_Product_DetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_DetailQuery, Get_Product_DetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_DetailQuery, Get_Product_DetailQueryVariables>(Get_Product_DetailDocument, baseOptions);
        }
export type Get_Product_DetailQueryHookResult = ReturnType<typeof useGet_Product_DetailQuery>;
export type Get_Product_DetailLazyQueryHookResult = ReturnType<typeof useGet_Product_DetailLazyQuery>;
export type Get_Product_DetailQueryResult = ApolloReactCommon.QueryResult<Get_Product_DetailQuery, Get_Product_DetailQueryVariables>;
export const Update_ProductDocument = gql`
    mutation UPDATE_PRODUCT($productId: uuid!, $name: String!, $account: uuid!, $attribute: jsonb!, $image: jsonb!, $description: String!, $status: Boolean!, $alias: String!) {
  update_product(where: {id: {_eq: $productId}}) {
    affected_rows
  }
}
    `;
export type Update_ProductMutationFn = ApolloReactCommon.MutationFunction<Update_ProductMutation, Update_ProductMutationVariables>;

/**
 * __useUpdate_ProductMutation__
 *
 * To run a mutation, you first call `useUpdate_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdate_ProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      name: // value for 'name'
 *      account: // value for 'account'
 *      attribute: // value for 'attribute'
 *      image: // value for 'image'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useUpdate_ProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_ProductMutation, Update_ProductMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_ProductMutation, Update_ProductMutationVariables>(Update_ProductDocument, baseOptions);
      }
export type Update_ProductMutationHookResult = ReturnType<typeof useUpdate_ProductMutation>;
export type Update_ProductMutationResult = ApolloReactCommon.MutationResult<Update_ProductMutation>;
export type Update_ProductMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_ProductMutation, Update_ProductMutationVariables>;
export const Insert_ProductDocument = gql`
    mutation INSERT_PRODUCT($productObject: product_insert_input!) {
  insert_product_one(object: $productObject) {
    id
  }
}
    `;
export type Insert_ProductMutationFn = ApolloReactCommon.MutationFunction<Insert_ProductMutation, Insert_ProductMutationVariables>;

/**
 * __useInsert_ProductMutation__
 *
 * To run a mutation, you first call `useInsert_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductMutation, { data, loading, error }] = useInsert_ProductMutation({
 *   variables: {
 *      productObject: // value for 'productObject'
 *   },
 * });
 */
export function useInsert_ProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_ProductMutation, Insert_ProductMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_ProductMutation, Insert_ProductMutationVariables>(Insert_ProductDocument, baseOptions);
      }
export type Insert_ProductMutationHookResult = ReturnType<typeof useInsert_ProductMutation>;
export type Insert_ProductMutationResult = ApolloReactCommon.MutationResult<Insert_ProductMutation>;
export type Insert_ProductMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_ProductMutation, Insert_ProductMutationVariables>;
export const Get_PromotionDocument = gql`
    query GET_PROMOTION($productName: jsonb!) {
  promotion(
    where: {_and: [{productItemByProductItem: {attribute: {_contains: $productName}}}, {deleted_at: {_is_null: true}}]}
  ) {
    id
    name
    on_going
    deleted_at
  }
}
    `;

/**
 * __useGet_PromotionQuery__
 *
 * To run a query within a React component, call `useGet_PromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_PromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_PromotionQuery({
 *   variables: {
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useGet_PromotionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_PromotionQuery, Get_PromotionQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_PromotionQuery, Get_PromotionQueryVariables>(Get_PromotionDocument, baseOptions);
      }
export function useGet_PromotionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_PromotionQuery, Get_PromotionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_PromotionQuery, Get_PromotionQueryVariables>(Get_PromotionDocument, baseOptions);
        }
export type Get_PromotionQueryHookResult = ReturnType<typeof useGet_PromotionQuery>;
export type Get_PromotionLazyQueryHookResult = ReturnType<typeof useGet_PromotionLazyQuery>;
export type Get_PromotionQueryResult = ApolloReactCommon.QueryResult<Get_PromotionQuery, Get_PromotionQueryVariables>;
export const Insert_PromotionDocument = gql`
    mutation INSERT_PROMOTION($promotionObjects: [promotion_insert_input!]!) {
  insert_promotion(
    objects: $promotionObjects
    on_conflict: {constraint: promotion_pkey, update_columns: updated_at}
  ) {
    affected_rows
  }
}
    `;
export type Insert_PromotionMutationFn = ApolloReactCommon.MutationFunction<Insert_PromotionMutation, Insert_PromotionMutationVariables>;

/**
 * __useInsert_PromotionMutation__
 *
 * To run a mutation, you first call `useInsert_PromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_PromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPromotionMutation, { data, loading, error }] = useInsert_PromotionMutation({
 *   variables: {
 *      promotionObjects: // value for 'promotionObjects'
 *   },
 * });
 */
export function useInsert_PromotionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_PromotionMutation, Insert_PromotionMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_PromotionMutation, Insert_PromotionMutationVariables>(Insert_PromotionDocument, baseOptions);
      }
export type Insert_PromotionMutationHookResult = ReturnType<typeof useInsert_PromotionMutation>;
export type Insert_PromotionMutationResult = ApolloReactCommon.MutationResult<Insert_PromotionMutation>;
export type Insert_PromotionMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_PromotionMutation, Insert_PromotionMutationVariables>;
export const Delete_PromotionDocument = gql`
    mutation DELETE_PROMOTION($productId: uuid!, $deletedAt: timestamptz!) {
  update_promotion(
    where: {productItemByProductItem: {productByProduct: {id: {_eq: $productId}}}}
    _set: {deleted_at: $deletedAt}
  ) {
    affected_rows
  }
}
    `;
export type Delete_PromotionMutationFn = ApolloReactCommon.MutationFunction<Delete_PromotionMutation, Delete_PromotionMutationVariables>;

/**
 * __useDelete_PromotionMutation__
 *
 * To run a mutation, you first call `useDelete_PromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_PromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromotionMutation, { data, loading, error }] = useDelete_PromotionMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useDelete_PromotionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_PromotionMutation, Delete_PromotionMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_PromotionMutation, Delete_PromotionMutationVariables>(Delete_PromotionDocument, baseOptions);
      }
export type Delete_PromotionMutationHookResult = ReturnType<typeof useDelete_PromotionMutation>;
export type Delete_PromotionMutationResult = ApolloReactCommon.MutationResult<Delete_PromotionMutation>;
export type Delete_PromotionMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_PromotionMutation, Delete_PromotionMutationVariables>;
export const Get_Stores_And_SolesDocument = gql`
    query GET_STORES_AND_SOLES {
  stores: store {
    id
    email
    name
    name_business
    llumar_store_name
    store_and_accounts {
      accountByAccount {
        profile_kr_partner {
          email
        }
      }
    }
  }
  sole {
    id
    name
  }
}
    `;

/**
 * __useGet_Stores_And_SolesQuery__
 *
 * To run a query within a React component, call `useGet_Stores_And_SolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Stores_And_SolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Stores_And_SolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Stores_And_SolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Stores_And_SolesQuery, Get_Stores_And_SolesQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Stores_And_SolesQuery, Get_Stores_And_SolesQueryVariables>(Get_Stores_And_SolesDocument, baseOptions);
      }
export function useGet_Stores_And_SolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Stores_And_SolesQuery, Get_Stores_And_SolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Stores_And_SolesQuery, Get_Stores_And_SolesQueryVariables>(Get_Stores_And_SolesDocument, baseOptions);
        }
export type Get_Stores_And_SolesQueryHookResult = ReturnType<typeof useGet_Stores_And_SolesQuery>;
export type Get_Stores_And_SolesLazyQueryHookResult = ReturnType<typeof useGet_Stores_And_SolesLazyQuery>;
export type Get_Stores_And_SolesQueryResult = ApolloReactCommon.QueryResult<Get_Stores_And_SolesQuery, Get_Stores_And_SolesQueryVariables>;
export const Update_StoresDocument = gql`
    mutation UPDATE_STORES($objects: [store_insert_input!]!) {
  insert_store(
    objects: $objects
    on_conflict: {constraint: store_pkey, update_columns: [llumar_store_name, llumar_store_type]}
  ) {
    affected_rows
  }
}
    `;
export type Update_StoresMutationFn = ApolloReactCommon.MutationFunction<Update_StoresMutation, Update_StoresMutationVariables>;

/**
 * __useUpdate_StoresMutation__
 *
 * To run a mutation, you first call `useUpdate_StoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_StoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoresMutation, { data, loading, error }] = useUpdate_StoresMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdate_StoresMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_StoresMutation, Update_StoresMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_StoresMutation, Update_StoresMutationVariables>(Update_StoresDocument, baseOptions);
      }
export type Update_StoresMutationHookResult = ReturnType<typeof useUpdate_StoresMutation>;
export type Update_StoresMutationResult = ApolloReactCommon.MutationResult<Update_StoresMutation>;
export type Update_StoresMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_StoresMutation, Update_StoresMutationVariables>;
export const Insert_Sole_OneDocument = gql`
    mutation INSERT_SOLE_ONE($name: String) {
  sole: insert_sole_one(object: {name: $name}) {
    id
  }
}
    `;
export type Insert_Sole_OneMutationFn = ApolloReactCommon.MutationFunction<Insert_Sole_OneMutation, Insert_Sole_OneMutationVariables>;

/**
 * __useInsert_Sole_OneMutation__
 *
 * To run a mutation, you first call `useInsert_Sole_OneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Sole_OneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSoleOneMutation, { data, loading, error }] = useInsert_Sole_OneMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsert_Sole_OneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_Sole_OneMutation, Insert_Sole_OneMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_Sole_OneMutation, Insert_Sole_OneMutationVariables>(Insert_Sole_OneDocument, baseOptions);
      }
export type Insert_Sole_OneMutationHookResult = ReturnType<typeof useInsert_Sole_OneMutation>;
export type Insert_Sole_OneMutationResult = ApolloReactCommon.MutationResult<Insert_Sole_OneMutation>;
export type Insert_Sole_OneMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_Sole_OneMutation, Insert_Sole_OneMutationVariables>;
export const Upsert_Store_And_SoleDocument = gql`
    mutation UPSERT_STORE_AND_SOLE($objects: [store_and_sole_insert_input!]!) {
  insert_store_and_sole(
    objects: $objects
    on_conflict: {constraint: store_and_sole_pkey, update_columns: [store, sole]}
  ) {
    affected_rows
  }
}
    `;
export type Upsert_Store_And_SoleMutationFn = ApolloReactCommon.MutationFunction<Upsert_Store_And_SoleMutation, Upsert_Store_And_SoleMutationVariables>;

/**
 * __useUpsert_Store_And_SoleMutation__
 *
 * To run a mutation, you first call `useUpsert_Store_And_SoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_Store_And_SoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStoreAndSoleMutation, { data, loading, error }] = useUpsert_Store_And_SoleMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsert_Store_And_SoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Upsert_Store_And_SoleMutation, Upsert_Store_And_SoleMutationVariables>) {
        return ApolloReactHooks.useMutation<Upsert_Store_And_SoleMutation, Upsert_Store_And_SoleMutationVariables>(Upsert_Store_And_SoleDocument, baseOptions);
      }
export type Upsert_Store_And_SoleMutationHookResult = ReturnType<typeof useUpsert_Store_And_SoleMutation>;
export type Upsert_Store_And_SoleMutationResult = ApolloReactCommon.MutationResult<Upsert_Store_And_SoleMutation>;
export type Upsert_Store_And_SoleMutationOptions = ApolloReactCommon.BaseMutationOptions<Upsert_Store_And_SoleMutation, Upsert_Store_And_SoleMutationVariables>;
export const _Get_Search_StoresDocument = gql`
    query _GET_SEARCH_STORES($storeSearchKeyword: String!) {
  searchStores: store(
    order_by: {address_detail: asc}
    where: {_and: [{deleted_at: {_is_null: true}}, {_or: [{name: {_ilike: $storeSearchKeyword}}, {llumar_store_name: {_ilike: $storeSearchKeyword}}, {address_detail: {_ilike: $storeSearchKeyword}}, {addressSigunguByAddressSigungu: {name: {_ilike: $storeSearchKeyword}}}, {addressSigunguByAddressSigungu: {address_sido: {name: {_ilike: $storeSearchKeyword}}}}]}]}
  ) {
    id
    status
    name
    location
    address_detail
    subscriptions {
      status
    }
  }
}
    `;

/**
 * __use_Get_Search_StoresQuery__
 *
 * To run a query within a React component, call `use_Get_Search_StoresQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_Search_StoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_Search_StoresQuery({
 *   variables: {
 *      storeSearchKeyword: // value for 'storeSearchKeyword'
 *   },
 * });
 */
export function use_Get_Search_StoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_Search_StoresQuery, _Get_Search_StoresQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_Search_StoresQuery, _Get_Search_StoresQueryVariables>(_Get_Search_StoresDocument, baseOptions);
      }
export function use_Get_Search_StoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_Search_StoresQuery, _Get_Search_StoresQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_Search_StoresQuery, _Get_Search_StoresQueryVariables>(_Get_Search_StoresDocument, baseOptions);
        }
export type _Get_Search_StoresQueryHookResult = ReturnType<typeof use_Get_Search_StoresQuery>;
export type _Get_Search_StoresLazyQueryHookResult = ReturnType<typeof use_Get_Search_StoresLazyQuery>;
export type _Get_Search_StoresQueryResult = ApolloReactCommon.QueryResult<_Get_Search_StoresQuery, _Get_Search_StoresQueryVariables>;
export const _Get_Search_CarsDocument = gql`
    query _GET_SEARCH_CARS($carSearchKeyword: String!) {
  searchCars: car(
    where: {_or: [{car_maker: {name_kr: {_ilike: $carSearchKeyword}}}, {model: {_ilike: $carSearchKeyword}}]}
    distinct_on: model
  ) {
    id
    model
    type
    car_maker {
      name_kr
      name_us
      car_origin {
        name_kr
        name_us
      }
    }
  }
}
    `;

/**
 * __use_Get_Search_CarsQuery__
 *
 * To run a query within a React component, call `use_Get_Search_CarsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_Search_CarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_Search_CarsQuery({
 *   variables: {
 *      carSearchKeyword: // value for 'carSearchKeyword'
 *   },
 * });
 */
export function use_Get_Search_CarsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_Search_CarsQuery, _Get_Search_CarsQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_Search_CarsQuery, _Get_Search_CarsQueryVariables>(_Get_Search_CarsDocument, baseOptions);
      }
export function use_Get_Search_CarsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_Search_CarsQuery, _Get_Search_CarsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_Search_CarsQuery, _Get_Search_CarsQueryVariables>(_Get_Search_CarsDocument, baseOptions);
        }
export type _Get_Search_CarsQueryHookResult = ReturnType<typeof use_Get_Search_CarsQuery>;
export type _Get_Search_CarsLazyQueryHookResult = ReturnType<typeof use_Get_Search_CarsLazyQuery>;
export type _Get_Search_CarsQueryResult = ApolloReactCommon.QueryResult<_Get_Search_CarsQuery, _Get_Search_CarsQueryVariables>;
export const _Get_Select_ProductsDocument = gql`
    query _GET_SELECT_PRODUCTS($carOrigin: String!, $carType: String!, $carSunroofType: String!) {
  packages: package(where: {package_type: {value: {_neq: "custom_package"}}}) {
    id
    name
    product
    description
    add_on_product
    price_product
    price_discount
    price_payout
    attribute
  }
  custom_package: package(where: {package_type: {value: {_eq: "custom_package"}}}) {
    id
  }
  front_tinting_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {attribute: {_contains: {part: "front"}}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {productByProduct: {status: {_eq: true}}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  sideback_tinting_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {attribute: {_contains: {part: "all"}}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {productByProduct: {status: {_eq: true}}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  side_tinting_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "tinting"}}}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {sku: {_ilike: "%_piece%"}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  back_tinting_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "tinting"}}}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {sku: {_ilike: "%_back_%"}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  sunroof_tinting_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {sku: {_ilike: $carSunroofType}}, {sku: {_ilike: $carOrigin}}, {productByProduct: {status: {_eq: true}}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  front_film_remove_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {sku: {_ilike: "%front%"}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {productByProduct: {product_type: {value: {_eq: "film_remove"}}}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  side_film_remove_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {sku: {_ilike: "%_side_%"}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {productByProduct: {product_type: {value: {_eq: "film_remove"}}}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  back_film_remove_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {sku: {_ilike: "%_back_%"}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {productByProduct: {product_type: {value: {_eq: "film_remove"}}}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  sunroof_film_remove_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {sku: {_ilike: $carSunroofType}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}, {productByProduct: {product_type: {value: {_eq: "film_remove"}}}}]}
    order_by: {sku: desc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  dashcam_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "dashcam"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  ceramic_coating_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "ceramic_coating"}}}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  ppf_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "ppf"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  connected_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "connected"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  auxiliary_battery_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "auxiliary_battery"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  hipass_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "hipass"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  after_blow_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "after_blow"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  undercoating_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "undercoating"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  leather_coating_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "leather_coating"}}}}, {sku: {_ilike: $carType}}, {sku: {_ilike: $carOrigin}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  soundproofing_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "soundproofing"}}}}]}
    order_by: {sku: asc}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  new_car_inspection_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "new_car_inspection"}}}}]}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  camo_kit_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "camo_kit"}}}}]}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
  delivery_fee_product_items: product_item(
    where: {_and: [{deleted_at: {_is_null: true}}, {productByProduct: {product_type: {value: {_eq: "delivery_fee"}}}}]}
  ) {
    id
    price
    sku
    attribute
    productByProduct {
      id
      name
      product_brand {
        description
      }
      product_type {
        value
        description
      }
    }
  }
}
    `;

/**
 * __use_Get_Select_ProductsQuery__
 *
 * To run a query within a React component, call `use_Get_Select_ProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_Select_ProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_Select_ProductsQuery({
 *   variables: {
 *      carOrigin: // value for 'carOrigin'
 *      carType: // value for 'carType'
 *      carSunroofType: // value for 'carSunroofType'
 *   },
 * });
 */
export function use_Get_Select_ProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_Select_ProductsQuery, _Get_Select_ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_Select_ProductsQuery, _Get_Select_ProductsQueryVariables>(_Get_Select_ProductsDocument, baseOptions);
      }
export function use_Get_Select_ProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_Select_ProductsQuery, _Get_Select_ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_Select_ProductsQuery, _Get_Select_ProductsQueryVariables>(_Get_Select_ProductsDocument, baseOptions);
        }
export type _Get_Select_ProductsQueryHookResult = ReturnType<typeof use_Get_Select_ProductsQuery>;
export type _Get_Select_ProductsLazyQueryHookResult = ReturnType<typeof use_Get_Select_ProductsLazyQuery>;
export type _Get_Select_ProductsQueryResult = ApolloReactCommon.QueryResult<_Get_Select_ProductsQuery, _Get_Select_ProductsQueryVariables>;
export const _Get_Select_OptionsDocument = gql`
    query _GET_SELECT_OPTIONS {
  address: address_sido(order_by: {name: asc}) {
    id
    name
    address_sigungus(order_by: {name: asc}) {
      id
      name
    }
  }
  carSunroofTypes: car_sunroof_type {
    value
    description
  }
  carModels: car(where: {model: {_neq: "해당없음"}}) {
    id
    model
    type
    car_maker {
      name_kr
      name_us
      car_origin {
        name_kr
        name_us
      }
    }
  }
  stores: store(
    where: {deleted_at: {_is_null: true}}
    order_by: {address_detail: asc}
  ) {
    id
    status
    name
    location
    address_detail
    addressSigunguByAddressSigungu {
      name
      address_sido {
        name
      }
    }
    subscriptions {
      status
    }
  }
  chat_assignees: account(
    where: {profile_riderdash: {deleted_at: {_is_null: true}}}
  ) {
    id
    profile_riderdash {
      id
      name
      email
      role
    }
  }
  operators: account(
    where: {profile_riderdash: {role: {_neq: parttimer}, deleted_at: {_is_null: true}}}
  ) {
    id
    profile_riderdash {
      id
      name
      role
    }
  }
  profileCustomers: account(
    where: {profile_kr_customers: {id: {_is_null: false}}}
  ) {
    id
    profile_kr_customer {
      id
      name
      email
    }
  }
}
    `;

/**
 * __use_Get_Select_OptionsQuery__
 *
 * To run a query within a React component, call `use_Get_Select_OptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_Select_OptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_Select_OptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function use_Get_Select_OptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_Select_OptionsQuery, _Get_Select_OptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_Select_OptionsQuery, _Get_Select_OptionsQueryVariables>(_Get_Select_OptionsDocument, baseOptions);
      }
export function use_Get_Select_OptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_Select_OptionsQuery, _Get_Select_OptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_Select_OptionsQuery, _Get_Select_OptionsQueryVariables>(_Get_Select_OptionsDocument, baseOptions);
        }
export type _Get_Select_OptionsQueryHookResult = ReturnType<typeof use_Get_Select_OptionsQuery>;
export type _Get_Select_OptionsLazyQueryHookResult = ReturnType<typeof use_Get_Select_OptionsLazyQuery>;
export type _Get_Select_OptionsQueryResult = ApolloReactCommon.QueryResult<_Get_Select_OptionsQuery, _Get_Select_OptionsQueryVariables>;
export const _Get_Product_Item_Price_By_StoreDocument = gql`
    query _GET_PRODUCT_ITEM_PRICE_BY_STORE($storeId: uuid!) @cached {
  product_item_price_by_store(where: {store_id: {_eq: $storeId}}) {
    id
    product_item_id
    store_id
    prices
    default_prices
  }
}
    `;

/**
 * __use_Get_Product_Item_Price_By_StoreQuery__
 *
 * To run a query within a React component, call `use_Get_Product_Item_Price_By_StoreQuery` and pass it any options that fit your needs.
 * When your component renders, `use_Get_Product_Item_Price_By_StoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_Get_Product_Item_Price_By_StoreQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function use_Get_Product_Item_Price_By_StoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_Get_Product_Item_Price_By_StoreQuery, _Get_Product_Item_Price_By_StoreQueryVariables>) {
        return ApolloReactHooks.useQuery<_Get_Product_Item_Price_By_StoreQuery, _Get_Product_Item_Price_By_StoreQueryVariables>(_Get_Product_Item_Price_By_StoreDocument, baseOptions);
      }
export function use_Get_Product_Item_Price_By_StoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_Get_Product_Item_Price_By_StoreQuery, _Get_Product_Item_Price_By_StoreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<_Get_Product_Item_Price_By_StoreQuery, _Get_Product_Item_Price_By_StoreQueryVariables>(_Get_Product_Item_Price_By_StoreDocument, baseOptions);
        }
export type _Get_Product_Item_Price_By_StoreQueryHookResult = ReturnType<typeof use_Get_Product_Item_Price_By_StoreQuery>;
export type _Get_Product_Item_Price_By_StoreLazyQueryHookResult = ReturnType<typeof use_Get_Product_Item_Price_By_StoreLazyQuery>;
export type _Get_Product_Item_Price_By_StoreQueryResult = ApolloReactCommon.QueryResult<_Get_Product_Item_Price_By_StoreQuery, _Get_Product_Item_Price_By_StoreQueryVariables>;
export const Get_Default_Discount_Rate_TableDocument = gql`
    query GET_DEFAULT_DISCOUNT_RATE_TABLE {
  config(where: {}, order_by: {created_at: desc}, limit: 1) {
    id
    csv_content
    updated_at
  }
}
    `;

/**
 * __useGet_Default_Discount_Rate_TableQuery__
 *
 * To run a query within a React component, call `useGet_Default_Discount_Rate_TableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Default_Discount_Rate_TableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Default_Discount_Rate_TableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Default_Discount_Rate_TableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Default_Discount_Rate_TableQuery, Get_Default_Discount_Rate_TableQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Default_Discount_Rate_TableQuery, Get_Default_Discount_Rate_TableQueryVariables>(Get_Default_Discount_Rate_TableDocument, baseOptions);
      }
export function useGet_Default_Discount_Rate_TableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Default_Discount_Rate_TableQuery, Get_Default_Discount_Rate_TableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Default_Discount_Rate_TableQuery, Get_Default_Discount_Rate_TableQueryVariables>(Get_Default_Discount_Rate_TableDocument, baseOptions);
        }
export type Get_Default_Discount_Rate_TableQueryHookResult = ReturnType<typeof useGet_Default_Discount_Rate_TableQuery>;
export type Get_Default_Discount_Rate_TableLazyQueryHookResult = ReturnType<typeof useGet_Default_Discount_Rate_TableLazyQuery>;
export type Get_Default_Discount_Rate_TableQueryResult = ApolloReactCommon.QueryResult<Get_Default_Discount_Rate_TableQuery, Get_Default_Discount_Rate_TableQueryVariables>;
export const Insert_New_Config_RateDocument = gql`
    mutation INSERT_NEW_CONFIG_RATE($object: config_insert_input!) {
  insert_config_one(object: $object) {
    id
    updated_at
  }
}
    `;
export type Insert_New_Config_RateMutationFn = ApolloReactCommon.MutationFunction<Insert_New_Config_RateMutation, Insert_New_Config_RateMutationVariables>;

/**
 * __useInsert_New_Config_RateMutation__
 *
 * To run a mutation, you first call `useInsert_New_Config_RateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_New_Config_RateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNewConfigRateMutation, { data, loading, error }] = useInsert_New_Config_RateMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsert_New_Config_RateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_New_Config_RateMutation, Insert_New_Config_RateMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_New_Config_RateMutation, Insert_New_Config_RateMutationVariables>(Insert_New_Config_RateDocument, baseOptions);
      }
export type Insert_New_Config_RateMutationHookResult = ReturnType<typeof useInsert_New_Config_RateMutation>;
export type Insert_New_Config_RateMutationResult = ApolloReactCommon.MutationResult<Insert_New_Config_RateMutation>;
export type Insert_New_Config_RateMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_New_Config_RateMutation, Insert_New_Config_RateMutationVariables>;
export const Subscription_LeadDocument = gql`
    subscription SUBSCRIPTION_LEAD($id: uuid!) {
  lead_by_pk(id: $id) {
    id
    key
    created_at
    updated_at
    phone
    customer
    status
    used_car_status
    start_drop_off_date_time
    end_drop_off_date_time
    due_date
    car_sunroof_type
    carByCar {
      id
      model
      type
      car_maker {
        name_kr
        name_us
        car_origin {
          name_kr
          name_us
        }
      }
    }
    etc
    film_need_to_removed
    chats {
      id
      key
      chat_assignee
      zendesk_chat_name
      accountByChatAssignee {
        id
        profile_riderdash {
          name
        }
      }
    }
    quotes(limit: 1, order_by: {created_at: desc}) {
      id
      lead
      quote_items(where: {deleted_at: {_is_null: true}}, order_by: {created_at: asc}) {
        id
        is_active
        remark
        store
        storeByStore {
          name_business
          phone_business
        }
        price_discount
        price_extra
        price_final
        price_payout
        price_payout_extra
        price_payout_discount
        price_product
        product_item
        deleted_at
        sent_at
        package_detail {
          id
          name
          package_type {
            description
          }
        }
        product_item_detail {
          id
          attribute
          price
          productByProduct {
            product_brand {
              description
            }
            name
            product_type {
              value
              description
            }
          }
        }
      }
    }
    accountBySupportAssignee {
      id
      profile_riderdash {
        id
        name
      }
    }
    accountByShopAssignee {
      id
      profile_riderdash {
        name
        email
      }
    }
    accountByCustomer {
      id
      profile_kr_customer {
        name
        email
      }
    }
    lead_comments(order_by: {created_at: asc}) {
      id
      account
      comment
      created_at
      updated_at
      accountByAccount {
        id
        profile_riderdash {
          name
          email
        }
        profile_kr_customer {
          name
          email
        }
      }
    }
    orders(where: {status: {_neq: canceled}}) {
      id
      created_at
      store
      storeByStore {
        name_business
        phone_business
      }
      basic_price_payout
      price_discount
      price_extra
      price_final
      price_payout
      price_product
      price_payout_extra
      price_payout_discount
      product_item
      remark
      package_detail {
        id
        name
        package_type {
          description
        }
      }
      product_item_detail {
        id
        attribute
        price
        productByProduct {
          product_brand {
            description
          }
          name
          product_type {
            value
            description
          }
        }
      }
      order_and_transactions {
        transactionByTransaction {
          bootpay_payment_intent_id
          stripe_payment_intent_id
        }
      }
      quoteItemByQuoteItem {
        sent_at
      }
      is_auto_order
    }
  }
}
    `;

/**
 * __useSubscription_LeadSubscription__
 *
 * To run a query within a React component, call `useSubscription_LeadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscription_LeadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscription_LeadSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscription_LeadSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Subscription_LeadSubscription, Subscription_LeadSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<Subscription_LeadSubscription, Subscription_LeadSubscriptionVariables>(Subscription_LeadDocument, baseOptions);
      }
export type Subscription_LeadSubscriptionHookResult = ReturnType<typeof useSubscription_LeadSubscription>;
export type Subscription_LeadSubscriptionResult = ApolloReactCommon.SubscriptionResult<Subscription_LeadSubscription>;
export const Subscription_LeadsDocument = gql`
    subscription SUBSCRIPTION_LEADS {
  lead {
    id
    status
  }
}
    `;

/**
 * __useSubscription_LeadsSubscription__
 *
 * To run a query within a React component, call `useSubscription_LeadsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscription_LeadsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscription_LeadsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscription_LeadsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Subscription_LeadsSubscription, Subscription_LeadsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<Subscription_LeadsSubscription, Subscription_LeadsSubscriptionVariables>(Subscription_LeadsDocument, baseOptions);
      }
export type Subscription_LeadsSubscriptionHookResult = ReturnType<typeof useSubscription_LeadsSubscription>;
export type Subscription_LeadsSubscriptionResult = ApolloReactCommon.SubscriptionResult<Subscription_LeadsSubscription>;
export const Insert_LeadDocument = gql`
    mutation INSERT_LEAD($leadObject: lead_insert_input!) {
  insert_lead_one(object: $leadObject) {
    id
  }
}
    `;
export type Insert_LeadMutationFn = ApolloReactCommon.MutationFunction<Insert_LeadMutation, Insert_LeadMutationVariables>;

/**
 * __useInsert_LeadMutation__
 *
 * To run a mutation, you first call `useInsert_LeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_LeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadMutation, { data, loading, error }] = useInsert_LeadMutation({
 *   variables: {
 *      leadObject: // value for 'leadObject'
 *   },
 * });
 */
export function useInsert_LeadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_LeadMutation, Insert_LeadMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_LeadMutation, Insert_LeadMutationVariables>(Insert_LeadDocument, baseOptions);
      }
export type Insert_LeadMutationHookResult = ReturnType<typeof useInsert_LeadMutation>;
export type Insert_LeadMutationResult = ApolloReactCommon.MutationResult<Insert_LeadMutation>;
export type Insert_LeadMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_LeadMutation, Insert_LeadMutationVariables>;
export const Update_Lead_By_PkDocument = gql`
    mutation UPDATE_LEAD_BY_PK($leadId: uuid!, $leadObject: lead_set_input!) {
  update_lead_by_pk(pk_columns: {id: $leadId}, _set: $leadObject) {
    id
  }
}
    `;
export type Update_Lead_By_PkMutationFn = ApolloReactCommon.MutationFunction<Update_Lead_By_PkMutation, Update_Lead_By_PkMutationVariables>;

/**
 * __useUpdate_Lead_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Lead_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Lead_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadByPkMutation, { data, loading, error }] = useUpdate_Lead_By_PkMutation({
 *   variables: {
 *      leadId: // value for 'leadId'
 *      leadObject: // value for 'leadObject'
 *   },
 * });
 */
export function useUpdate_Lead_By_PkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Lead_By_PkMutation, Update_Lead_By_PkMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Lead_By_PkMutation, Update_Lead_By_PkMutationVariables>(Update_Lead_By_PkDocument, baseOptions);
      }
export type Update_Lead_By_PkMutationHookResult = ReturnType<typeof useUpdate_Lead_By_PkMutation>;
export type Update_Lead_By_PkMutationResult = ApolloReactCommon.MutationResult<Update_Lead_By_PkMutation>;
export type Update_Lead_By_PkMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Lead_By_PkMutation, Update_Lead_By_PkMutationVariables>;
export const Update_Lead_And_QuoteDocument = gql`
    mutation UPDATE_LEAD_AND_QUOTE($leadId: uuid!, $leadObject: lead_set_input!, $quoteObject: quote_insert_input!) {
  update_lead_by_pk(pk_columns: {id: $leadId}, _set: $leadObject) {
    id
  }
  insert_quote_one(
    object: $quoteObject
    on_conflict: {constraint: quote_pkey, update_columns: [lead]}
  ) {
    id
  }
}
    `;
export type Update_Lead_And_QuoteMutationFn = ApolloReactCommon.MutationFunction<Update_Lead_And_QuoteMutation, Update_Lead_And_QuoteMutationVariables>;

/**
 * __useUpdate_Lead_And_QuoteMutation__
 *
 * To run a mutation, you first call `useUpdate_Lead_And_QuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Lead_And_QuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadAndQuoteMutation, { data, loading, error }] = useUpdate_Lead_And_QuoteMutation({
 *   variables: {
 *      leadId: // value for 'leadId'
 *      leadObject: // value for 'leadObject'
 *      quoteObject: // value for 'quoteObject'
 *   },
 * });
 */
export function useUpdate_Lead_And_QuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Lead_And_QuoteMutation, Update_Lead_And_QuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Lead_And_QuoteMutation, Update_Lead_And_QuoteMutationVariables>(Update_Lead_And_QuoteDocument, baseOptions);
      }
export type Update_Lead_And_QuoteMutationHookResult = ReturnType<typeof useUpdate_Lead_And_QuoteMutation>;
export type Update_Lead_And_QuoteMutationResult = ApolloReactCommon.MutationResult<Update_Lead_And_QuoteMutation>;
export type Update_Lead_And_QuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Lead_And_QuoteMutation, Update_Lead_And_QuoteMutationVariables>;
export const Update_Chat_And_Lead_And_QuoteDocument = gql`
    mutation UPDATE_CHAT_AND_LEAD_AND_QUOTE($chatId: uuid!, $leadId: uuid!, $chatObject: chat_set_input!, $leadObject: lead_set_input!, $quoteObject: quote_insert_input!) {
  update_chat_by_pk(pk_columns: {id: $chatId}, _set: $chatObject) {
    id
  }
  update_lead_by_pk(pk_columns: {id: $leadId}, _set: $leadObject) {
    id
  }
  insert_quote_one(
    object: $quoteObject
    on_conflict: {constraint: quote_pkey, update_columns: [lead]}
  ) {
    id
  }
}
    `;
export type Update_Chat_And_Lead_And_QuoteMutationFn = ApolloReactCommon.MutationFunction<Update_Chat_And_Lead_And_QuoteMutation, Update_Chat_And_Lead_And_QuoteMutationVariables>;

/**
 * __useUpdate_Chat_And_Lead_And_QuoteMutation__
 *
 * To run a mutation, you first call `useUpdate_Chat_And_Lead_And_QuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Chat_And_Lead_And_QuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatAndLeadAndQuoteMutation, { data, loading, error }] = useUpdate_Chat_And_Lead_And_QuoteMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      leadId: // value for 'leadId'
 *      chatObject: // value for 'chatObject'
 *      leadObject: // value for 'leadObject'
 *      quoteObject: // value for 'quoteObject'
 *   },
 * });
 */
export function useUpdate_Chat_And_Lead_And_QuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Chat_And_Lead_And_QuoteMutation, Update_Chat_And_Lead_And_QuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Chat_And_Lead_And_QuoteMutation, Update_Chat_And_Lead_And_QuoteMutationVariables>(Update_Chat_And_Lead_And_QuoteDocument, baseOptions);
      }
export type Update_Chat_And_Lead_And_QuoteMutationHookResult = ReturnType<typeof useUpdate_Chat_And_Lead_And_QuoteMutation>;
export type Update_Chat_And_Lead_And_QuoteMutationResult = ApolloReactCommon.MutationResult<Update_Chat_And_Lead_And_QuoteMutation>;
export type Update_Chat_And_Lead_And_QuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Chat_And_Lead_And_QuoteMutation, Update_Chat_And_Lead_And_QuoteMutationVariables>;
export const Update_Lead_And_Quote_And_OrderDocument = gql`
    mutation UPDATE_LEAD_AND_QUOTE_AND_ORDER($leadId: uuid!, $orderId: uuid!, $leadObject: lead_set_input!, $orderObject: order_set_input!, $quoteObject: quote_insert_input!) {
  update_lead_by_pk(pk_columns: {id: $leadId}, _set: $leadObject) {
    id
  }
  update_order_by_pk(pk_columns: {id: $orderId}, _set: $orderObject) {
    id
  }
  insert_quote_one(
    object: $quoteObject
    on_conflict: {constraint: quote_pkey, update_columns: [lead]}
  ) {
    id
  }
}
    `;
export type Update_Lead_And_Quote_And_OrderMutationFn = ApolloReactCommon.MutationFunction<Update_Lead_And_Quote_And_OrderMutation, Update_Lead_And_Quote_And_OrderMutationVariables>;

/**
 * __useUpdate_Lead_And_Quote_And_OrderMutation__
 *
 * To run a mutation, you first call `useUpdate_Lead_And_Quote_And_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Lead_And_Quote_And_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadAndQuoteAndOrderMutation, { data, loading, error }] = useUpdate_Lead_And_Quote_And_OrderMutation({
 *   variables: {
 *      leadId: // value for 'leadId'
 *      orderId: // value for 'orderId'
 *      leadObject: // value for 'leadObject'
 *      orderObject: // value for 'orderObject'
 *      quoteObject: // value for 'quoteObject'
 *   },
 * });
 */
export function useUpdate_Lead_And_Quote_And_OrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Lead_And_Quote_And_OrderMutation, Update_Lead_And_Quote_And_OrderMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Lead_And_Quote_And_OrderMutation, Update_Lead_And_Quote_And_OrderMutationVariables>(Update_Lead_And_Quote_And_OrderDocument, baseOptions);
      }
export type Update_Lead_And_Quote_And_OrderMutationHookResult = ReturnType<typeof useUpdate_Lead_And_Quote_And_OrderMutation>;
export type Update_Lead_And_Quote_And_OrderMutationResult = ApolloReactCommon.MutationResult<Update_Lead_And_Quote_And_OrderMutation>;
export type Update_Lead_And_Quote_And_OrderMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Lead_And_Quote_And_OrderMutation, Update_Lead_And_Quote_And_OrderMutationVariables>;
export const Update_Chat_And_Lead_And_Quote_And_OrderDocument = gql`
    mutation UPDATE_CHAT_AND_LEAD_AND_QUOTE_AND_ORDER($chatId: uuid!, $leadId: uuid!, $orderId: uuid!, $chatObject: chat_set_input!, $leadObject: lead_set_input!, $orderObject: order_set_input!, $quoteObject: quote_insert_input!) {
  update_chat_by_pk(pk_columns: {id: $chatId}, _set: $chatObject) {
    id
  }
  update_lead_by_pk(pk_columns: {id: $leadId}, _set: $leadObject) {
    id
  }
  update_order_by_pk(pk_columns: {id: $orderId}, _set: $orderObject) {
    id
  }
  insert_quote_one(
    object: $quoteObject
    on_conflict: {constraint: quote_pkey, update_columns: [lead]}
  ) {
    id
  }
}
    `;
export type Update_Chat_And_Lead_And_Quote_And_OrderMutationFn = ApolloReactCommon.MutationFunction<Update_Chat_And_Lead_And_Quote_And_OrderMutation, Update_Chat_And_Lead_And_Quote_And_OrderMutationVariables>;

/**
 * __useUpdate_Chat_And_Lead_And_Quote_And_OrderMutation__
 *
 * To run a mutation, you first call `useUpdate_Chat_And_Lead_And_Quote_And_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Chat_And_Lead_And_Quote_And_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatAndLeadAndQuoteAndOrderMutation, { data, loading, error }] = useUpdate_Chat_And_Lead_And_Quote_And_OrderMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      leadId: // value for 'leadId'
 *      orderId: // value for 'orderId'
 *      chatObject: // value for 'chatObject'
 *      leadObject: // value for 'leadObject'
 *      orderObject: // value for 'orderObject'
 *      quoteObject: // value for 'quoteObject'
 *   },
 * });
 */
export function useUpdate_Chat_And_Lead_And_Quote_And_OrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Chat_And_Lead_And_Quote_And_OrderMutation, Update_Chat_And_Lead_And_Quote_And_OrderMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Chat_And_Lead_And_Quote_And_OrderMutation, Update_Chat_And_Lead_And_Quote_And_OrderMutationVariables>(Update_Chat_And_Lead_And_Quote_And_OrderDocument, baseOptions);
      }
export type Update_Chat_And_Lead_And_Quote_And_OrderMutationHookResult = ReturnType<typeof useUpdate_Chat_And_Lead_And_Quote_And_OrderMutation>;
export type Update_Chat_And_Lead_And_Quote_And_OrderMutationResult = ApolloReactCommon.MutationResult<Update_Chat_And_Lead_And_Quote_And_OrderMutation>;
export type Update_Chat_And_Lead_And_Quote_And_OrderMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Chat_And_Lead_And_Quote_And_OrderMutation, Update_Chat_And_Lead_And_Quote_And_OrderMutationVariables>;
export const Update_Lead_StatusDocument = gql`
    mutation UPDATE_LEAD_STATUS($id: uuid!, $now: timestamptz!) {
  update_lead_by_pk(pk_columns: {id: $id}) {
    id
  }
}
    `;
export type Update_Lead_StatusMutationFn = ApolloReactCommon.MutationFunction<Update_Lead_StatusMutation, Update_Lead_StatusMutationVariables>;

/**
 * __useUpdate_Lead_StatusMutation__
 *
 * To run a mutation, you first call `useUpdate_Lead_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Lead_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadStatusMutation, { data, loading, error }] = useUpdate_Lead_StatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useUpdate_Lead_StatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Lead_StatusMutation, Update_Lead_StatusMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Lead_StatusMutation, Update_Lead_StatusMutationVariables>(Update_Lead_StatusDocument, baseOptions);
      }
export type Update_Lead_StatusMutationHookResult = ReturnType<typeof useUpdate_Lead_StatusMutation>;
export type Update_Lead_StatusMutationResult = ApolloReactCommon.MutationResult<Update_Lead_StatusMutation>;
export type Update_Lead_StatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Lead_StatusMutation, Update_Lead_StatusMutationVariables>;
export const Check_Lead_ExistenceDocument = gql`
    query CHECK_LEAD_EXISTENCE($phone: String!) {
  lead(
    where: {_and: [{_or: [{status: {_eq: lead}}, {status: {_eq: quote}}, {status: {_eq: match}}]}, {phone: {_eq: $phone}}]}
  ) {
    id
  }
}
    `;

/**
 * __useCheck_Lead_ExistenceQuery__
 *
 * To run a query within a React component, call `useCheck_Lead_ExistenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheck_Lead_ExistenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheck_Lead_ExistenceQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCheck_Lead_ExistenceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Check_Lead_ExistenceQuery, Check_Lead_ExistenceQueryVariables>) {
        return ApolloReactHooks.useQuery<Check_Lead_ExistenceQuery, Check_Lead_ExistenceQueryVariables>(Check_Lead_ExistenceDocument, baseOptions);
      }
export function useCheck_Lead_ExistenceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Check_Lead_ExistenceQuery, Check_Lead_ExistenceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Check_Lead_ExistenceQuery, Check_Lead_ExistenceQueryVariables>(Check_Lead_ExistenceDocument, baseOptions);
        }
export type Check_Lead_ExistenceQueryHookResult = ReturnType<typeof useCheck_Lead_ExistenceQuery>;
export type Check_Lead_ExistenceLazyQueryHookResult = ReturnType<typeof useCheck_Lead_ExistenceLazyQuery>;
export type Check_Lead_ExistenceQueryResult = ApolloReactCommon.QueryResult<Check_Lead_ExistenceQuery, Check_Lead_ExistenceQueryVariables>;
export const Search_LeadDocument = gql`
    query SEARCH_LEAD($chatId: Int!, $leadId: Int!, $email: String!, $phone: String!, $zendeskChatName: String!) {
  leads: lead(
    where: {_or: [{chats: {key: {_eq: $chatId}}}, {key: {_eq: $leadId}}, {accountByCustomer: {profile_kr_customer: {email: {_ilike: $email}}}}, {phone: {_ilike: $phone}}, {chats: {zendesk_chat_name: {_ilike: $zendeskChatName}}}]}
  ) {
    id
    key
    chats {
      key
    }
    created_at
    updated_at
    phone
    status
    due_date
    accountByCustomer {
      id
      profile_kr_customer {
        email
      }
    }
  }
}
    `;

/**
 * __useSearch_LeadQuery__
 *
 * To run a query within a React component, call `useSearch_LeadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_LeadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_LeadQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      leadId: // value for 'leadId'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      zendeskChatName: // value for 'zendeskChatName'
 *   },
 * });
 */
export function useSearch_LeadQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Search_LeadQuery, Search_LeadQueryVariables>) {
        return ApolloReactHooks.useQuery<Search_LeadQuery, Search_LeadQueryVariables>(Search_LeadDocument, baseOptions);
      }
export function useSearch_LeadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Search_LeadQuery, Search_LeadQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Search_LeadQuery, Search_LeadQueryVariables>(Search_LeadDocument, baseOptions);
        }
export type Search_LeadQueryHookResult = ReturnType<typeof useSearch_LeadQuery>;
export type Search_LeadLazyQueryHookResult = ReturnType<typeof useSearch_LeadLazyQuery>;
export type Search_LeadQueryResult = ApolloReactCommon.QueryResult<Search_LeadQuery, Search_LeadQueryVariables>;
export const Insert_Lead_CommentDocument = gql`
    mutation INSERT_LEAD_COMMENT($leadComment: lead_comment_insert_input!) {
  insert_lead_comment_one(object: $leadComment) {
    id
    lead
    leadByLead {
      id
    }
    accountByAccount {
      id
      profile_riderdash {
        name
        email
      }
    }
  }
}
    `;
export type Insert_Lead_CommentMutationFn = ApolloReactCommon.MutationFunction<Insert_Lead_CommentMutation, Insert_Lead_CommentMutationVariables>;

/**
 * __useInsert_Lead_CommentMutation__
 *
 * To run a mutation, you first call `useInsert_Lead_CommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Lead_CommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadCommentMutation, { data, loading, error }] = useInsert_Lead_CommentMutation({
 *   variables: {
 *      leadComment: // value for 'leadComment'
 *   },
 * });
 */
export function useInsert_Lead_CommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_Lead_CommentMutation, Insert_Lead_CommentMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_Lead_CommentMutation, Insert_Lead_CommentMutationVariables>(Insert_Lead_CommentDocument, baseOptions);
      }
export type Insert_Lead_CommentMutationHookResult = ReturnType<typeof useInsert_Lead_CommentMutation>;
export type Insert_Lead_CommentMutationResult = ApolloReactCommon.MutationResult<Insert_Lead_CommentMutation>;
export type Insert_Lead_CommentMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_Lead_CommentMutation, Insert_Lead_CommentMutationVariables>;
export const Update_Lead_CommentDocument = gql`
    mutation UPDATE_LEAD_COMMENT($id: uuid!, $comment: String!) {
  update_lead_comment(where: {id: {_eq: $id}}, _set: {comment: $comment}) {
    affected_rows
  }
}
    `;
export type Update_Lead_CommentMutationFn = ApolloReactCommon.MutationFunction<Update_Lead_CommentMutation, Update_Lead_CommentMutationVariables>;

/**
 * __useUpdate_Lead_CommentMutation__
 *
 * To run a mutation, you first call `useUpdate_Lead_CommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Lead_CommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadCommentMutation, { data, loading, error }] = useUpdate_Lead_CommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdate_Lead_CommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Lead_CommentMutation, Update_Lead_CommentMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Lead_CommentMutation, Update_Lead_CommentMutationVariables>(Update_Lead_CommentDocument, baseOptions);
      }
export type Update_Lead_CommentMutationHookResult = ReturnType<typeof useUpdate_Lead_CommentMutation>;
export type Update_Lead_CommentMutationResult = ApolloReactCommon.MutationResult<Update_Lead_CommentMutation>;
export type Update_Lead_CommentMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Lead_CommentMutation, Update_Lead_CommentMutationVariables>;
export const Update_Order_With_LeadDocument = gql`
    mutation UPDATE_ORDER_WITH_LEAD($orderId: uuid!, $orderObject: order_set_input!) {
  update_order_by_pk(pk_columns: {id: $orderId}, _set: $orderObject) {
    id
  }
}
    `;
export type Update_Order_With_LeadMutationFn = ApolloReactCommon.MutationFunction<Update_Order_With_LeadMutation, Update_Order_With_LeadMutationVariables>;

/**
 * __useUpdate_Order_With_LeadMutation__
 *
 * To run a mutation, you first call `useUpdate_Order_With_LeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Order_With_LeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderWithLeadMutation, { data, loading, error }] = useUpdate_Order_With_LeadMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      orderObject: // value for 'orderObject'
 *   },
 * });
 */
export function useUpdate_Order_With_LeadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Order_With_LeadMutation, Update_Order_With_LeadMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Order_With_LeadMutation, Update_Order_With_LeadMutationVariables>(Update_Order_With_LeadDocument, baseOptions);
      }
export type Update_Order_With_LeadMutationHookResult = ReturnType<typeof useUpdate_Order_With_LeadMutation>;
export type Update_Order_With_LeadMutationResult = ApolloReactCommon.MutationResult<Update_Order_With_LeadMutation>;
export type Update_Order_With_LeadMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Order_With_LeadMutation, Update_Order_With_LeadMutationVariables>;
export const Get_PackageDocument = gql`
    query GET_PACKAGE {
  package(where: {package_type: {value: {_neq: "custom_package"}}}) {
    id
    name
    product
    description
    add_on_product
    price_product
    price_discount
    price_payout
    attribute
  }
}
    `;

/**
 * __useGet_PackageQuery__
 *
 * To run a query within a React component, call `useGet_PackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_PackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_PackageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_PackageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_PackageQuery, Get_PackageQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_PackageQuery, Get_PackageQueryVariables>(Get_PackageDocument, baseOptions);
      }
export function useGet_PackageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_PackageQuery, Get_PackageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_PackageQuery, Get_PackageQueryVariables>(Get_PackageDocument, baseOptions);
        }
export type Get_PackageQueryHookResult = ReturnType<typeof useGet_PackageQuery>;
export type Get_PackageLazyQueryHookResult = ReturnType<typeof useGet_PackageLazyQuery>;
export type Get_PackageQueryResult = ApolloReactCommon.QueryResult<Get_PackageQuery, Get_PackageQueryVariables>;
export const Get_Product_ItemDocument = gql`
    query GET_PRODUCT_ITEM($id: uuid!, $carType: String = "%", $carOrigin: String = "%") {
  product_item(
    where: {_and: [{id: {_eq: $id}}, {sku: {_like: $carType}}, {sku: {_like: $carOrigin}}]}
  ) {
    id
    attribute
    sku
    productByProduct {
      name
    }
  }
}
    `;

/**
 * __useGet_Product_ItemQuery__
 *
 * To run a query within a React component, call `useGet_Product_ItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Product_ItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Product_ItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      carType: // value for 'carType'
 *      carOrigin: // value for 'carOrigin'
 *   },
 * });
 */
export function useGet_Product_ItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Product_ItemQuery, Get_Product_ItemQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Product_ItemQuery, Get_Product_ItemQueryVariables>(Get_Product_ItemDocument, baseOptions);
      }
export function useGet_Product_ItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Product_ItemQuery, Get_Product_ItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Product_ItemQuery, Get_Product_ItemQueryVariables>(Get_Product_ItemDocument, baseOptions);
        }
export type Get_Product_ItemQueryHookResult = ReturnType<typeof useGet_Product_ItemQuery>;
export type Get_Product_ItemLazyQueryHookResult = ReturnType<typeof useGet_Product_ItemLazyQuery>;
export type Get_Product_ItemQueryResult = ApolloReactCommon.QueryResult<Get_Product_ItemQuery, Get_Product_ItemQueryVariables>;