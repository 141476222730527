import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import React from 'react'

import Select, { TypeSelectBox } from '../../../components/atoms/Select'
import { SearchKeywordStructure, SelectedValuesStructure } from '../index'

interface StoreSectionProps {
  storeSearchKeyword: string
  searchStoreArray: Array<TypeSelectBox>
  storeId: string
  storeArray: Array<TypeSelectBox>
  dateTime: string
  setSearchKeyword: (object: SearchKeywordStructure) => void
  setSeletedValeus: (object: SelectedValuesStructure) => void
}

const App: React.FunctionComponent<StoreSectionProps> = ({
  storeSearchKeyword,
  searchStoreArray,
  storeId,
  storeArray,
  dateTime,
  setSearchKeyword,
  setSeletedValeus,
}: StoreSectionProps) => {
  return (
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: 'gainsboro' }}>
        <TableRow>
          <TableCell>매장</TableCell>
          <TableCell>시공 예약 일정</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <div style={{ marginBottom: 10, display: 'flex' }}>
              <TextField
                style={{ marginRight: 10 }}
                value={storeSearchKeyword}
                label={'주소 또는 매장명 검색'}
                variant={'outlined'}
                onChange={(e) =>
                  setSearchKeyword({
                    storeSearchKeyword: e.target.value,
                  })
                }
              ></TextField>

              <Select
                value={storeSearchKeyword}
                label={'검색 결과'}
                options={searchStoreArray}
                changeHandler={(event): void => {
                  setSearchKeyword({
                    storeSearchKeyword: '',
                  })
                  setSeletedValeus({ storeId: event.target.value as string })
                }}
              ></Select>
            </div>
            <Select
              value={storeId}
              label={'매장'}
              options={storeArray}
              changeHandler={(event): void => setSeletedValeus({ storeId: event.target.value as string })}
            ></Select>
          </TableCell>
          <TableCell>
            <TextField
              id="datetime-local"
              type="datetime-local"
              value={dateTime}
              onChange={(event): void =>
                setSeletedValeus({ dateTime: `${event.target.value.substr(0, event.target.value.length - 2)}00` })
              }
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
