import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import {
  Gallery,
  Get_BlogQuery as GetBlogQuery,
  Get_CurationQuery as GetCurationQuery,
  Store,
} from '../../assets/graphql/graphql'
import ValueSetter from '../../components/organism/TableValueSetter'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import styles from './index.module.scss'

export const GET_CURATION = gql`
  query GET_CURATION {
    curation {
      id
      list
    }
  }
`

const GET_BLOGS = gql`
  query GET_BLOG {
    gallery {
      id
      blogPostByBlogPost {
        title
        id
      }
    }
    store {
      name
      id
    }
  }
`

export const UPDATE_CURATION = gql`
  mutation UPDATE_CURATION($id: uuid!, $account: uuid!, $list: jsonb!) {
    update_curation(where: { id: { _eq: $id } }, _set: { list: $list, account: $account }) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { accountId, changeSnackbarAlertContent } = useRootData(({ authStore, appStore }) => ({
    accountId: authStore.accountId.get(),
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [bestCuration, setBestCuration] = useState('')
  const [storeCuration, setStoreCuration] = useState('')
  const [koreanSuvCuration, setKoreanSuvCuration] = useState('')
  const [importedSuvCuration, setImportedSuvCuration] = useState('')
  const [koreanSedanCuration, setKoreanSedanCuration] = useState('')
  const [importedSedanCuration, setImportedSedanCuration] = useState('')

  const [getBData, { data: bdata, error: getBlogError }] = useLazyQuery<GetBlogQuery>(GET_BLOGS, {
    fetchPolicy: 'no-cache',
  })

  const [getCData, { data: cdata, error: getCurationError }] = useLazyQuery<GetCurationQuery>(GET_CURATION, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getBData()
    getCData()
  }, [])

  if (getBlogError || getCurationError) {
    const error = getBlogError || getCurationError
    Sentry.captureException(error)
  }
  if (!cdata || !bdata) return null

  const updateCuration = async () => {
    try {
      await client.mutate({
        mutation: UPDATE_CURATION,
        variables: {
          id: cdata.curation[0].id,
          account: accountId,
          list: {
            best: bestCuration
              ? bestCuration
                  .split(/\s*,\s*/)
                  .filter((el: string) => el)
                  .map((el: string) => el.trim())
              : cdata.curation[0].list.best,
            importedSedan: importedSedanCuration
              ? importedSedanCuration
                  .split(/\s*,\s*/)
                  .filter((el: string) => el)
                  .map((el: string) => el.trim())
              : cdata.curation[0].list.importedSedan,
            importedSuv: importedSuvCuration
              ? importedSuvCuration
                  .split(/\s*,\s*/)
                  .filter((el: string) => el)
                  .map((el: string) => el.trim())
              : cdata.curation[0].list.importedSuv,
            koreanSedan: koreanSedanCuration
              ? koreanSedanCuration
                  .split(/\s*,\s*/)
                  .filter((el: string) => el)
                  .map((el: string) => el.trim())
              : cdata.curation[0].list.koreanSedan,
            koreanSuv: koreanSuvCuration
              ? koreanSuvCuration
                  .split(/\s*,\s*/)
                  .filter((el: string) => el)
                  .map((el: string) => el.trim())
              : cdata.curation[0].list.koreanSuv,
            stores: storeCuration
              ? storeCuration
                  .split(/\s*,\s*/)
                  .filter((el: string) => el)
                  .map((el: string) => el.trim())
              : cdata.curation[0].list.stores,
          },
        },
      })
      changeSnackbarAlertContent({ severity: 'success', content: '저장되었습니다!' })
      getCData()
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '실패했습니다!' })
    }
  }

  const findTitle = (id: string) => {
    let tit: string
    const searchGal = bdata.gallery.find((gal: Gallery) => gal.id === id)
    if (!searchGal) {
      const searchStore = bdata.store.find((str: Store) => str.id === id)
      tit = searchStore ? searchStore.name : ''
    } else tit = searchGal.blogPostByBlogPost.title

    return tit
  }

  return (
    <div className="body">
      <div>
        <div className={styles.title}>랜딩 큐레이션</div>
        <div>
          &quot;,&quot; 로 id를 띄어서 넣고, 저장버튼을 누르면 해당 큐레이션이 업데이트 됩니다! 블로그 순서는 랜덤으로
          정해지니 유의해주시길 바랍니다! 마지막으로 가능하시면 6개 이상 넣어주세요~ 웹사이트 상에선 문제가 없지만,
          보기가 좀 그렇습니다.
        </div>
        <br />

        <button className={styles.button} onClick={() => updateCuration()}>
          저장
        </button>

        <ValueSetter
          title="추천 베스트"
          list={cdata.curation[0].list.best.map((item: string) => {
            return { id: item, display: findTitle(item) }
          })}
          setter={setBestCuration}
        />
        <ValueSetter
          title="프로모션 진행중인 매장"
          list={cdata.curation[0].list.stores.map((item: string) => {
            return { id: item, display: findTitle(item) }
          })}
          setter={setStoreCuration}
        />
        <ValueSetter
          title="국산 SUV 추천"
          list={cdata.curation[0].list.koreanSuv.map((item: string) => {
            return { id: item, display: findTitle(item) }
          })}
          setter={setKoreanSuvCuration}
        />
        <ValueSetter
          title="수입 SUV 추천"
          list={cdata.curation[0].list.importedSuv.map((item: string) => {
            return { id: item, display: findTitle(item) }
          })}
          setter={setImportedSuvCuration}
        />
        <ValueSetter
          title="국산 세단 추천"
          list={cdata.curation[0].list.koreanSedan.map((item: string) => {
            return { id: item, display: findTitle(item) }
          })}
          setter={setKoreanSedanCuration}
        />
        <ValueSetter
          title="수입 세단 추천"
          list={cdata.curation[0].list.importedSedan.map((item: string) => {
            return { id: item, display: findTitle(item) }
          })}
          setter={setImportedSedanCuration}
        />
        <button className={styles.button} onClick={() => updateCuration()}>
          저장
        </button>
        <br />

        <table>
          <thead className={styles.scrollAdjust}>
            <tr>
              <th colSpan={2}>Galleries</th>
            </tr>
            <tr>
              <th>Title</th>
              <th>id</th>
            </tr>
          </thead>
          <tbody className={styles.scroll}>
            {bdata.gallery.map((item: Gallery, index: number) => (
              <tr key={index} className={styles.scrollAdjust}>
                <td>{item.blogPostByBlogPost.title}</td>
                <td>{item.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />

        <table>
          <thead className={styles.scrollAdjust}>
            <tr>
              <th colSpan={2}>Stores</th>
            </tr>
            <tr>
              <th>Name</th>
              <th>id</th>
            </tr>
          </thead>
          <tbody className={styles.scroll}>
            {bdata.store.map((item: Store, index: number) => (
              <tr key={index} className={styles.scrollAdjust}>
                <td>{item.name}</td>
                <td>{item.id}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <br />
      </div>
    </div>
  )
}
export default React.memo(App)
