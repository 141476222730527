// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2ZMf9 {\n  margin: 0 auto;\n  margin-top: 25%;\n  width: 400px;\n  height: 250px;\n  background-color: white;\n  text-align: center; }\n  .index-module__container___2ZMf9 .index-module__selectBox___3LXsG {\n    margin-top: 50px; }\n  .index-module__container___2ZMf9 .index-module__btnArea___2Ivth {\n    text-align: -webkit-center;\n    margin-top: 30px; }\n    .index-module__container___2ZMf9 .index-module__btnArea___2Ivth .index-module__btnFrame___3CayL {\n      display: flex;\n      justify-content: space-between;\n      width: 160px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Quote/CalculatorTable/SelectProductItemModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,kBAAkB,EAAA;EANpB;IAQI,gBAAgB,EAAA;EARpB;IAYI,0BAA0B;IAC1B,gBAAgB,EAAA;IAbpB;MAeM,aAAa;MACb,8BAA8B;MAC9B,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin: 0 auto;\n  margin-top: 25%;\n  width: 400px;\n  height: 250px;\n  background-color: white;\n  text-align: center;\n  .selectBox {\n    margin-top: 50px;\n  }\n\n  .btnArea {\n    text-align: -webkit-center;\n    margin-top: 30px;\n    .btnFrame {\n      display: flex;\n      justify-content: space-between;\n      width: 160px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2ZMf9",
	"selectBox": "index-module__selectBox___3LXsG",
	"btnArea": "index-module__btnArea___2Ivth",
	"btnFrame": "index-module__btnFrame___3CayL"
};
module.exports = exports;
