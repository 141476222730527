import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import Phonenumber from 'awesome-phonenumber'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'

import {
  Get_Order_DetailQuery as GetOrderDetailQuery,
  Get_Order_DetailQueryVariables as GetOrderDetailQueryVariables,
  Get_Product_Items_With_SkuQuery as GetProductItemsWithSkuQuery,
  Get_Product_Items_With_SkuQueryVariables as GetProductItemsWithSkuQueryVariables,
  Order_Status_Enum as orderStatusEnum,
  Product_Item as ProductItem,
  Update_OrderMutation as UpdateOrderMutation,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { autoHypenPhoneNumber, parseQueryString } from '../../utils/utility'
import { GET_PRODUCT_ITEMS_WITH_SKU } from '../Order/query'
import { typePart } from '../Order/type'
import styles from './index.module.scss'
import { GET_ORDER_DETAIL, UPDATE_ORDER_CONTACT } from './query'

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id } = parseQueryString()

  const partType = ['front', 'sunroof', 'sideback']
  const partTypeKr: {
    [key: string]: string
  } = {
    front: '전면',
    all: '측후면',
    sunroof: '썬루프',
  }

  const [editOrderClicked, setEditOrderClicked] = useState(!id)
  const [newOrderDetail, setNewOrderDetail] = useState(null)
  const [productItemsArray, setProductItemsArray] = useState<Array<ProductItem>>(null)

  const [getOrderDetail, { data: orderDetailContents, error }] = useLazyQuery<
    GetOrderDetailQuery,
    GetOrderDetailQueryVariables
  >(GET_ORDER_DETAIL(), {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getOrderDetail()
  }, [])

  // eslint-disable-next-line camelcase
  const isTinting = !!orderDetailContents?.order[0]?.product_item?.tinting

  useEffect(() => {
    if (orderDetailContents) {
      if (id) {
        const orderDetail = orderDetailContents.order[0]
        setNewOrderDetail({
          car: orderDetail.car,
          product_item: _.cloneDeep(orderDetail.product_item),
          car_sunroof_type: orderDetail.carSunroofTypeByCarSunroofType.value,
          price_discount: orderDetail.price_discount,
          price_product: orderDetail.price_product,
          price_final: orderDetail.price_final,
          store: orderDetail.storeByStore.id,
          name: orderDetail.name,
          email: orderDetail.email,
          phone: autoHypenPhoneNumber(new Phonenumber(orderDetail.phone).getNumber('national')),
          status: orderDetail.order_status.value,
          price_extra: orderDetail.price_extra,
          account: orderDetail.accountByAccount.id,
          commission_rate: orderDetail.commission_rate,
          remark: orderDetail.remark,
        })
      }
    }
  }, [orderDetailContents])

  useEffect(() => {
    const getProductItems = async () => {
      let items = ''
      const order = orderDetailContents.order[0]

      if (isTinting) {
        partType.forEach((part) => {
          if (orderDetailContents.order[0].product_item.tinting[part as typePart]) {
            items += `{id: { _eq: "${orderDetailContents.order[0].product_item.tinting[part as typePart]}"}}`
          }
        })
      } else {
        Object.values(order.product_item.package[Object.keys(order.product_item.package)[0]]).forEach(
          (item: string | { front?: string; sideback?: string; sunroof?: string }) => {
            if (typeof item === 'string') {
              items += `{id: { _eq: "${item}"}}`

              return
            }

            Object.values(item).forEach((tintItem) => {
              items += `{id: { _eq: "${tintItem}"}}`
            })
          },
        )
      }

      const {
        data: { productItems },
      } = await client.query<GetProductItemsWithSkuQuery, GetProductItemsWithSkuQueryVariables>({
        query: GET_PRODUCT_ITEMS_WITH_SKU(items),
        variables: {},
        fetchPolicy: 'no-cache',
      })

      setProductItemsArray(productItems as Array<ProductItem>)
    }

    if (orderDetailContents) {
      getProductItems()
    }
  }, [orderDetailContents])

  if (error) {
    Sentry.captureException(error)
  }

  if (!orderDetailContents || !newOrderDetail || productItemsArray === null) return null

  const order = orderDetailContents.order[0]
  const { packages } = orderDetailContents

  const PackageType = ['tinting', 'dashcam', 'ppf', 'ceramic_coating']

  const modifiableStatus =
    !editOrderClicked &&
    (order.order_status.value === orderStatusEnum.Created ||
      order.order_status.value === orderStatusEnum.Confirmed ||
      order.order_status.value === orderStatusEnum.PaymentLinkSent ||
      order.order_status.value === orderStatusEnum.PaymentFailed)

  return (
    <div className="body">
      <div className={styles.container}>
        시공 예약 상세
        <div className={styles.editButtonArea}>
          <div style={{ color: '#742dd2' }}>* 시공이 완료된 이후에는 고객 정보 수정이 불가능합니다!</div>

          {modifiableStatus && (
            <button
              className={styles.addStoreButton}
              onClick={(): void => {
                setEditOrderClicked(true)
              }}
            >
              시공 예약 고객 정보 수정하기
            </button>
          )}

          {editOrderClicked && (
            <div>
              <button
                className={styles.button}
                onClick={async (): Promise<void> => {
                  try {
                    if (id) {
                      if (orderStatusEnum.Created || orderStatusEnum.Confirmed) {
                        await client.mutate<UpdateOrderMutation>({
                          mutation: UPDATE_ORDER_CONTACT(),
                          variables: {
                            id,
                            newOrderDetail: {
                              ...newOrderDetail,
                              phone: new Phonenumber(newOrderDetail.phone.replace(/-/gi, ''), 'KR').getNumber('e164'),
                            },
                          },
                        })

                        setEditOrderClicked(false)
                        changeSnackbarAlertContent({
                          severity: 'success',
                          content: '고객 정보 수정 완료',
                        })

                        getOrderDetail()
                      } else {
                        changeSnackbarAlertContent({
                          severity: 'warning',
                          content: '서비스 완료 상태의 시공건은 고객 정보 수정이 불가합니다.',
                        })

                        return
                      }
                    }
                  } catch (err) {
                    changeSnackbarAlertContent({ severity: 'error', content: `업데이트 실패\n${err}` })
                    Sentry.captureException(err)
                  }
                }}
              >
                저장하기
              </button>
            </div>
          )}
        </div>
        <div className={styles.dataField}>예약상태 : {order.order_status.value}</div>
        <div className={styles.infoFrame}>
          <div className={styles.dataField}>◎ 고객 정보</div>

          <div className={styles.dataField}>
            이름 :
            <input
              style={{ width: 200 }}
              className={editOrderClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editOrderClicked ? order.name : newOrderDetail.name}
              onChange={(e): void => {
                setNewOrderDetail({ ...newOrderDetail, name: e.target.value })
              }}
              disabled={!editOrderClicked}
            />
          </div>

          <div className={styles.dataField}>
            이메일 :
            <input
              style={{ width: 200 }}
              className={editOrderClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editOrderClicked ? order.email : newOrderDetail.email}
              onChange={(e): void => {
                setNewOrderDetail({ ...newOrderDetail, email: e.target.value })
              }}
              disabled={!editOrderClicked}
            />
          </div>

          <div className={styles.dataField}>
            전화번호 :
            <input
              style={{ width: 200 }}
              className={editOrderClicked ? styles.inputEditing : styles.inputDefault}
              value={!editOrderClicked ? new Phonenumber(order.phone).getNumber('national') : newOrderDetail.phone}
              onChange={(e): void => {
                setNewOrderDetail({ ...newOrderDetail, phone: autoHypenPhoneNumber(e.target.value) })
              }}
              disabled={!editOrderClicked}
            />
          </div>

          <div className={styles.dataField}>◎ 매장 소개</div>
          <div className={styles.dataField}>
            시공점 :
            <input
              className={styles.inputDefault}
              defaultValue={`${order.storeByStore.name} (주소 : ${order.storeByStore.address_detail})`}
              disabled={true}
            />
          </div>

          <div className={styles.dataField}>◎ 시공 차량 정보</div>

          <div className={styles.dataField}>
            차량 :
            <input
              className={styles.inputDefault}
              defaultValue={`${order.carByCar.car_maker.car_origin.name_kr} ${order.carByCar.car_maker.name_kr} ${`${
                order.carByCar.prefix || ''
              } ${order.carByCar.model} ${order.carByCar.postfix || ''}`}`}
              disabled={true}
            />
          </div>

          <div className={styles.dataField}>차량 유형 : {order.carByCar.car_type.description}</div>

          <div className={styles.dataField}>썬루프 : {order.carSunroofTypeByCarSunroofType.description}</div>

          <div className={styles.dataField}>◎ 시공 제품 정보</div>

          {newOrderDetail.product_item.package ? (
            <div>
              <div className={styles.dataField}>
                <div className={styles.packageName}>패키지 : </div>
                {packages.find((pack) => pack.id === Object.keys(order.product_item.package)[0]).name}
              </div>
              <div className={styles.dataField}>
                <div className={styles.packageName}>{'제품 : '}</div>
              </div>

              {productItemsArray
                .filter((productItem) => PackageType.includes(productItem.productByProduct.type))
                .sort((a, b) => {
                  return PackageType.indexOf(a.productByProduct.type) - PackageType.indexOf(b.productByProduct.type)
                })
                .map((productItem, index) => {
                  return (
                    <Fragment key={index}>
                      <div className={styles.dataField}>
                        {`- ${index + 1}) ${productItem.productByProduct.type} (${
                          productItem.productByProduct.name
                        }) : ${productItem.sku} `}

                        <div className={styles.priceData}>{`(${Number(productItem.price).toLocaleString()} 원)`}</div>
                      </div>
                    </Fragment>
                  )
                })}
              <div className={styles.dataField}>
                <div className={styles.packageName}>add_on</div>
              </div>
              {productItemsArray
                .filter((productItem) => productItem.productByProduct.type === 'add_on')
                .map((productItem, index) => {
                  return (
                    <Fragment key={index}>
                      <div className={styles.dataField}>
                        {`- ${productItem.productByProduct.name} : `}
                        <div className={styles.priceData}> {`${Number(productItem.price).toLocaleString()} 원`}</div>
                        <br />
                      </div>
                    </Fragment>
                  )
                })}
              <div className={styles.dataField}>
                <div className={styles.packageName}>new_car_inspection</div>
              </div>
              {productItemsArray
                .filter((productItem) => productItem.productByProduct.type === 'new_car_inspection')
                .map((productItem, index) => {
                  return (
                    <Fragment key={index}>
                      <div className={styles.dataField}>
                        {`- ${productItem.productByProduct.name} : `}
                        <div className={styles.priceData}> {`${Number(productItem.price).toLocaleString()} 원`}</div>
                      </div>
                    </Fragment>
                  )
                })}
            </div>
          ) : (
            <Fragment>
              <div>
                {productItemsArray.map((productItem, index) => {
                  return (
                    <div key={index} className={styles.dataField}>
                      <div>
                        {partTypeKr[productItem.attribute.part]} : {productItem.sku}
                      </div>
                      <div className={styles.priceData}>({Number(productItem.price).toLocaleString()}원)</div>
                    </div>
                  )
                })}
              </div>
            </Fragment>
          )}

          <div className={styles.dataField}>◎ 가격</div>

          <div className={styles.dataField}>
            제품 금액 :
            <input
              style={{ width: 200 }}
              className={editOrderClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={Number(order.price_product).toLocaleString()}
              disabled={true}
            />
            원
          </div>

          <div className={styles.dataField}>
            할인 적용 금액 :
            <input
              style={{ width: 200 }}
              className={editOrderClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={Number(order.price_discount).toLocaleString()}
              disabled={true}
            />
            원
          </div>

          <div className={styles.dataField}>
            최종결제금액 :
            <input
              style={{ width: 200 }}
              className={editOrderClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={Number(order.price_final).toLocaleString()}
              disabled={true}
            />
            원
          </div>

          <div className={styles.dataField}>◎ 비고</div>
          <div className={styles.dataField}>
            시공 제품 비고란 :
            <textarea
              style={{ width: '80%' }}
              rows={5}
              value={newOrderDetail.remark && newOrderDetail.remark.product_remark}
              disabled={!editOrderClicked}
            />
          </div>
          <div className={styles.dataField}>
            예상 시공일 비고란 :
            <textarea
              style={{ width: '80%' }}
              rows={5}
              value={newOrderDetail.remark && newOrderDetail.remark.date_time_remark}
              disabled={!editOrderClicked}
            />
          </div>
          <div className={styles.dataField}>
            예상 견적가 비고란 :
            <textarea
              style={{ width: '80%' }}
              rows={5}
              value={newOrderDetail.remark && newOrderDetail.remark.price_remark}
              disabled={!editOrderClicked}
            />
          </div>
          <div className={styles.dataField}>
            고객 카트 비고란 :
            <textarea
              style={{ width: '80%' }}
              rows={5}
              value={newOrderDetail.remark && newOrderDetail.remark.customer_remark}
              disabled={!editOrderClicked}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
