import {
  IconButton,
  MenuItem,
  Modal,
  Select as SelectM,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { Pageview } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import Select from '../../../../components/atoms/Select'
import useRootData from '../../../../hooks/useRootData'
import styles from './index.module.scss'
import StoreInfoModal from './StoreInfoModal'

const App: React.FunctionComponent<{ idx: number }> = ({ idx }: { idx: number }) => {
  const {
    // values
    selectedStore,
    selectedPackageObject,

    // options
    storeArray,
    packageArray,

    // setter
    changeSnackbarAlertContent,
    changeSelectedStore,
    changeSearchStore,
    changeSelectedPackageObject,
  } = useRootData(({ appStore, calculatorStore }) => ({
    selectedStore: calculatorStore.selectedStore.get()[idx],

    storeArray: calculatorStore.storeArray,
    selectedPackageObject: calculatorStore.selectedPackageObject.get()[idx],

    packageArray: calculatorStore.packageArray,

    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    changeSelectedPackageObject: calculatorStore.changeSelectedPackageObject,
    changeSelectedStore: calculatorStore.changeSelectedStore,
    changeSearchStore: calculatorStore.changeSearchStore,
  }))

  const [isStoreOpen, setIsStoreOpen] = useState(false)
  const [query, setQuery] = useState('')

  const [modalStoreInfo, setModalStoreInfo] = useState<boolean>(false)
  const hideModalHandler = () => {
    setModalStoreInfo(false)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      changeSearchStore(query, idx)
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [query])

  return (
    <>
      {modalStoreInfo && (
        <Modal
          open={modalStoreInfo}
          onClose={(): void => hideModalHandler()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <StoreInfoModal hideModalHandler={hideModalHandler} storeId={selectedStore?.id} />
        </Modal>
      )}
      <Table className={styles.Table} size="small" aria-label="a dense table">
        <TableHead className={styles.head}>
          <TableRow>
            <TableCell className={styles.cell}>매장</TableCell>
            <TableCell className={styles.cell}>패키지</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.body}>
          <TableRow>
            <TableCell className={styles.storeCell}>
              <TextField
                className={styles.input}
                size="small"
                variant="outlined"
                placeholder={'초성검색 및 광역시/도 기준'}
                onChange={(e) => setQuery(e.target.value)}
                onKeyPress={(e) => {
                  const { key } = e
                  const handler = () => {
                    setIsStoreOpen(true)
                  }

                  if (key === 'Enter') setTimeout(handler, 300)
                }}
              />
              <SelectM
                value={selectedStore ? (selectedStore.id as string) : ''}
                onChange={(e): void => {
                  changeSelectedStore(e.target.value as string, idx)
                  setIsStoreOpen(false)
                }}
                open={isStoreOpen}
                onClick={() => setIsStoreOpen(!isStoreOpen)}
              >
                {storeArray[idx].map((store, index) => (
                  <MenuItem key={index} value={store.value}>
                    {store.text}
                  </MenuItem>
                ))}
              </SelectM>
              {selectedStore && (
                <IconButton color="primary" onClick={() => setModalStoreInfo(true)}>
                  <Pageview />
                </IconButton>
              )}
            </TableCell>
            <TableCell>
              <Select
                value={selectedPackageObject ? selectedPackageObject.id : ''}
                options={packageArray}
                changeHandler={(event): void => {
                  changeSelectedPackageObject(event.target.value as string, idx)

                  if (!event.target.value) {
                    changeSnackbarAlertContent({
                      severity: 'warning',
                      content: '"패키지가"가 이전 선택하신 패키지 기준으로 나타나게 됩니다.',
                    })
                  }
                }}
                disabled={packageArray.length === 1}
              ></Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default React.memo(App)
