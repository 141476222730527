import gql from 'graphql-tag'

export const GET_STORE_DETAIL = gql`
  query GET_STORE_DETAIL($id: uuid!) {
    store(where: { id: { _eq: $id } }) {
      id
      status
      name
      email
      image
      description
      location
      attribute
      address_sigungu
      phone_business
      name_business
      addressSigunguByAddressSigungu {
        id
        name
        address_sido {
          name
        }
      }
      address_detail
      store_and_hashtags(where: { hashtagByHashtag: { deleted_at: { _is_null: true } } }, order_by: { order: asc }) {
        order
        hashtagByHashtag {
          id
          name
        }
      }
      store_and_accounts(where: { store_account_role: { value: { _eq: "owner" } } }) {
        accountByAccount {
          profile_kr_partner {
            name
            email
          }
        }
      }
    }
    hashtag: hashtag(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
    address: address_sigungu {
      id
      name
      address_sido {
        id
        name
      }
    }
    account: profile_kr_partner {
      email
      name
      account
    }
    storeAccount: store_and_account(where: { store: { _eq: $id } }) {
      accountByAccount {
        profile_kr_partner {
          email
          name
          account
        }
      }
    }
  }
`

export const GET_ADDRESS_AND_HASHTAG = gql`
  query GET_ADDRESS_AND_HASHTAG {
    address: address_sigungu {
      id
      name
      address_sido {
        id
        name
      }
    }
    hashtag: hashtag(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
    account: profile_kr_partner {
      email
      name
      account
    }
  }
`

export const UPDATE_STORE = gql`
  mutation UPDATE_STORE($id: uuid!, $newStoreDetail: store_set_input, $newStoreAccount: store_and_account_set_input) {
    update_store(where: { id: { _eq: $id } }, _set: $newStoreDetail) {
      affected_rows
    }
    update_store_and_account(where: { store: { _eq: $id } }, _set: $newStoreAccount) {
      affected_rows
    }
  }
`

export const DELETE_ALL_STORE_HASHTAG = gql`
  mutation DELETE_ALL_STORE_HASHTAG($id: uuid!) {
    delete_store_and_hashtag(where: { store: { _eq: $id } }) {
      affected_rows
    }
  }
`

export const INSERT_STORE_AND_HASHTAG = gql`
  mutation INSERT_STORE_AND_HASHTAG($selectedHashtagArray: [store_and_hashtag_insert_input!]!) {
    insert_store_and_hashtag(objects: $selectedHashtagArray) {
      affected_rows
    }
  }
`

export const INSERT_STORE = gql`
  mutation INSERT_STORE($insertObject: store_insert_input!) {
    insert_store_one(object: $insertObject) {
      id
    }
  }
`
