import { Button, Modal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Lead_Status_Enum as LeadStatusEnum } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import { LEAD_COL } from '../../stores/lead/type'
import { parseQueryString } from '../../utils/utility'
import LeadSearch from '../LeadDetail/Menu/LeadSearch'
import CreateLeadPopup from './CreateLeadPopup'
import FilterDateTable from './FilterDateTable'
import LeadListTable from './LeadListTable'

export const tableCol: {
  [key: string]: Array<LEAD_COL>
} = {
  [LeadStatusEnum.Chat]: [
    LEAD_COL.CHAT_ID,
    LEAD_COL.LEAD_ID,
    LEAD_COL.ZENDESK_CHAT_NAME,
    LEAD_COL.CHAT_ASSIGNEE,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.STATUS,
  ],
  [LeadStatusEnum.Lead]: [
    LEAD_COL.LEAD_ID,
    LEAD_COL.CALL_REQUEST_SAME_DAY_ORDER,
    LEAD_COL.CREATED_FROM,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.SELECTED_NEW_CAR,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.DUE_DATE,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.SHOP,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
  ],
  [LeadStatusEnum.Quote]: [
    LEAD_COL.SELECTED_NEW_CAR,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.DUE_DATE,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.CAN_NOT_MATCH,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.SHOP,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
  ],
  [LeadStatusEnum.Match]: [
    LEAD_COL.UPDATED_AT,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.DUE_DATE,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.SHOP,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CREATED_AT,
  ],
  [LeadStatusEnum.OrderPending]: [
    LEAD_COL.UPDATED_AT,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.DUE_DATE,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.SHOP,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CREATED_AT,
  ],
  [LeadStatusEnum.RsvPending]: [
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.CAR,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_NAME,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
  ],
  [LeadStatusEnum.RsvConfirmed]: [
    LEAD_COL.CREATED_FROM,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.CAR,
    LEAD_COL.PRICE_PAYOUT,
    LEAD_COL.MATERIAL_PRICE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_NAME,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.RSV_COMFIRMED_AT,
  ],
  [LeadStatusEnum.PaymentPending]: [
    LEAD_COL.PAYMENT_LINK_SENT_AT,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.PRICE_PAYOUT,
    LEAD_COL.MATERIAL_PRICE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
  ],
  [LeadStatusEnum.VatRequested]: [
    LEAD_COL.PAID_AT,
    LEAD_COL.PAYMENT_LINK_SENT_AT,
    LEAD_COL.VAT_REQUESTED_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.PRICE_PAYOUT,
    LEAD_COL.MATERIAL_PRICE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.CREATED_AT,
  ],
  [LeadStatusEnum.SettlementPending]: [
    LEAD_COL.PAID_AT,
    LEAD_COL.PAYMENT_LINK_SENT_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.PRICE_PAYOUT,
    LEAD_COL.MATERIAL_PRICE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.CREATED_AT,
  ],
  [LeadStatusEnum.Done]: [
    LEAD_COL.CREATED_FROM,
    LEAD_COL.FINISHED_AT,
    LEAD_COL.PAID_AT,
    LEAD_COL.PAYMENT_LINK_SENT_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.PRICE_PAYOUT,
    LEAD_COL.MATERIAL_PRICE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.LEAD_ID,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.CREATED_AT,
  ],
  [LeadStatusEnum.Duplicate]: [
    LEAD_COL.CHAT_ID,
    LEAD_COL.LEAD_ID,
    LEAD_COL.ZENDESK_CHAT_NAME,
    LEAD_COL.CHAT_ASSIGNEE,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.STATUS,
  ],
  [LeadStatusEnum.Cancel]: [
    LEAD_COL.LEAD_ID,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.PAYMENT_LINK_SENT_AT,
    LEAD_COL.RSV_COMFIRMED_AT,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
  ],
  [LeadStatusEnum.Refunded]: [
    LEAD_COL.LEAD_ID,
    LEAD_COL.PAYMENT_LINK_SENT_AT,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.SHOP,
    LEAD_COL.SHOP_PHONE,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.MATCH_MAKER,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
  ],
  [LeadStatusEnum.Missed]: [
    LEAD_COL.CHAT_ID,
    LEAD_COL.LEAD_ID,
    LEAD_COL.ZENDESK_CHAT_NAME,
    LEAD_COL.CHAT_ASSIGNEE,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.STATUS,
  ],
  [LeadStatusEnum.WillNotDo]: [
    LEAD_COL.CHAT_ID,
    LEAD_COL.LEAD_ID,
    LEAD_COL.ZENDESK_CHAT_NAME,
    LEAD_COL.CHAT_ASSIGNEE,
    LEAD_COL.SUPPORT_ASSIGNEE,
    LEAD_COL.CAN_NOT_MATCH,
    LEAD_COL.START_DROP_OFF_DATE,
    LEAD_COL.END_DROP_OFF_DATE,
    LEAD_COL.SHOP,
    LEAD_COL.CARAMORA_ID,
    LEAD_COL.CUSTOMER_PHONE,
    LEAD_COL.CREATED_AT,
    LEAD_COL.UPDATED_AT,
    LEAD_COL.STATUS,
  ],
}

export const tableColDefaultOrder: {
  [key: string]: { order: 'desc' | 'asc'; col: LEAD_COL }
} = {
  [LeadStatusEnum.Missed]: { order: 'desc', col: LEAD_COL.CREATED_AT },
  [LeadStatusEnum.Chat]: { order: 'desc', col: LEAD_COL.CREATED_AT },
  [LeadStatusEnum.Lead]: { order: 'asc', col: LEAD_COL.DUE_DATE },
  [LeadStatusEnum.Quote]: { order: 'asc', col: LEAD_COL.DUE_DATE },
  [LeadStatusEnum.Match]: { order: 'asc', col: LEAD_COL.DUE_DATE },
  [LeadStatusEnum.OrderPending]: { order: 'asc', col: LEAD_COL.DUE_DATE },
  [LeadStatusEnum.RsvPending]: { order: 'asc', col: LEAD_COL.START_DROP_OFF_DATE },
  [LeadStatusEnum.RsvConfirmed]: { order: 'asc', col: LEAD_COL.START_DROP_OFF_DATE },
  [LeadStatusEnum.PaymentPending]: { order: 'asc', col: LEAD_COL.PAYMENT_LINK_SENT_AT },
  [LeadStatusEnum.VatRequested]: { order: 'asc', col: LEAD_COL.PAID_AT },
  [LeadStatusEnum.SettlementPending]: { order: 'asc', col: LEAD_COL.PAID_AT },
  [LeadStatusEnum.Done]: { order: 'desc', col: LEAD_COL.FINISHED_AT },
  [LeadStatusEnum.Duplicate]: { order: 'desc', col: LEAD_COL.UPDATED_AT },
  [LeadStatusEnum.Cancel]: { order: 'desc', col: LEAD_COL.UPDATED_AT },
  [LeadStatusEnum.Refunded]: { order: 'desc', col: LEAD_COL.UPDATED_AT },
  [LeadStatusEnum.WillNotDo]: { order: 'desc', col: LEAD_COL.UPDATED_AT },
}

const App: React.FunctionComponent = () => {
  const {
    statusFilterValue,
    leadOrder,
    leadOrderBy,

    changeStatus,
    changeLeadOrder,
    changeLeadOrderBy,
  } = useRootData(({ leadStore }) => ({
    statusFilterValue: leadStore.statusFilterValue.get(),
    leadOrder: leadStore.leadOrder.get(),
    leadOrderBy: leadStore.leadOrderBy.get(),

    changeStatus: leadStore.changeStatus,
    changeLeadOrder: leadStore.changeLeadOrder,
    changeLeadOrderBy: leadStore.changeLeadOrderBy,
  }))

  const { status } = parseQueryString()

  const [leadSearchPopupStatus, setLeadSearchPopupStatus] = useState<boolean>(false)
  const [createLeadPopupStatus, setCreateLeadPopupStatus] = useState<boolean>(false)
  const [filterDatePopupStatus, setFilterDatePopupStatus] = useState<boolean>(false)

  useEffect(() => {
    changeLeadOrder(leadOrder || tableColDefaultOrder[status].order)
    changeLeadOrderBy(leadOrderBy || tableColDefaultOrder[status].col)
  }, [status])

  useEffect(() => {
    changeStatus(status as LeadStatusEnum)
  }, [status])

  if (!statusFilterValue) return null

  return (
    <div>
      <Modal
        open={leadSearchPopupStatus}
        onClose={(): void => setLeadSearchPopupStatus(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <LeadSearch />
      </Modal>
      <Modal
        open={createLeadPopupStatus}
        onClose={(): void => setCreateLeadPopupStatus(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CreateLeadPopup />
      </Modal>
      <Modal
        open={filterDatePopupStatus}
        onClose={(): void => setFilterDatePopupStatus(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <FilterDateTable />
      </Modal>
      <Button variant="outlined" onClick={(): void => setLeadSearchPopupStatus(true)}>
        검색
      </Button>
      {statusFilterValue === LeadStatusEnum.Lead && (
        <Button variant="outlined" onClick={(): void => setCreateLeadPopupStatus(true)}>
          수동 추가
        </Button>
      )}
      <Button variant="outlined" onClick={(): void => setFilterDatePopupStatus(true)}>
        Date Setting
      </Button>

      <LeadListTable />
    </div>
  )
}
export default React.memo(App)
