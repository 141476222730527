import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'

import { Get_PackagesQuery as GetPackagesQuery, Package, Product } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import { packageToPackageCardArray } from '../../utils/utility'
import styles from './index.module.scss'
import { GET_PACKAGES } from './query'

export interface PackageCardObject {
  id: string
  name?: string
  product?: Array<Product>
  brand?: string
  alias?: string
  price?: { discountPrice: number; originalPrice: number; totalPrice: number }
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
  }))

  const [normalPackageCardArray, setNomalPackageCardArray] = useState<PackageCardObject[]>([])
  const [excellentPackageCardArray, setExcellentPackageCardArray] = useState<PackageCardObject[]>([])
  const [bestPackageCardArray, setBestPackageCardArray] = useState<PackageCardObject[]>([])
  const [economicPackageCardArray, setEconomicPackageCardArray] = useState<PackageCardObject[]>([])
  const [teslaPackageArray, setTeslaPackageArray] = useState<PackageCardObject[]>([])

  const [getPackages, { data: packageContents, error }] = useLazyQuery<GetPackagesQuery>(GET_PACKAGES(), {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getPackages()
  }, [])

  useEffect(() => {
    if (packageContents) {
      ;(async () => {
        const tempNormalPackagesArray = await packageToPackageCardArray(
          packageContents.normalPackages as Array<Package>,
        )
        const tempExcellentPackagesArray = await packageToPackageCardArray(
          packageContents.excellentPackages as Array<Package>,
        )
        const tempBestPackagesArray = await packageToPackageCardArray(packageContents.bestPackages as Array<Package>)
        const tempEconomicPackagesArray = await packageToPackageCardArray(
          packageContents.economicPackages as Array<Package>,
        )
        const tempTeslaPacakgesArray = await packageToPackageCardArray(packageContents.teslaPacakges as Array<Package>)

        setNomalPackageCardArray(tempNormalPackagesArray)
        setExcellentPackageCardArray(tempExcellentPackagesArray)
        setBestPackageCardArray(tempBestPackagesArray)
        setEconomicPackageCardArray(tempEconomicPackagesArray)
        setTeslaPackageArray(tempTeslaPacakgesArray)
      })()
    }
  }, [packageContents])

  if (error) {
    Sentry.captureException(error)
  }

  if (!packageContents) return null

  return (
    <div className="body">
      <div className={styles.container}>
        <div style={{ marginBottom: 40 }}>신차 패키지 판매 매장 관리 목록</div>

        <div>실속 모라줌 패키지</div>
        <table className={styles.tableContainer}>
          <tbody>
            <tr>
              <th>이름</th>
              <th>제품</th>
              <th>정가 가격(국산 세단)</th>
              <th></th>
            </tr>
            {normalPackageCardArray
              .sort((a: Package, b: Package) => {
                if (a.name < b.name) {
                  return -1
                }

                return 1
              })
              .map((_package, index) => {
                return (
                  <tr key={index}>
                    <td>{_package.name}</td>
                    <td style={{ display: 'flex' }}>
                      {_package.product.map((_product, productIndex) => {
                        const isLastIndex = _package.product.length - 1

                        return (
                          <p key={productIndex}>
                            {_product.name} {productIndex !== isLastIndex && ' + '}
                          </p>
                        )
                      })}
                    </td>
                    <td>{_package.price.originalPrice} 원</td>
                    <td>
                      <button
                        onClick={(): void => {
                          changeMainMenu(`/store-and-package-detail?id=${_package.id}&type=${_package.product.length}`)
                        }}
                      >
                        상세 보기
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        <div>고급 모라줌 패키지</div>
        <table className={styles.tableContainer}>
          <tbody>
            <tr>
              <th>이름</th>
              <th>제품</th>
              <th>정가 가격(국산 세단)</th>
              <th></th>
            </tr>
            {bestPackageCardArray
              .sort((a: Package, b: Package) => {
                if (a.name < b.name) {
                  return -1
                }

                return 1
              })
              .map((_package, index) => {
                return (
                  <tr key={index}>
                    <td>{_package.name}</td>
                    <td style={{ display: 'flex' }}>
                      {_package.product.map((_product, productIndex) => {
                        const isLastIndex = _package.product.length - 1

                        return (
                          <p key={productIndex}>
                            {_product.name} {productIndex !== isLastIndex && '+ '}
                          </p>
                        )
                      })}
                    </td>
                    <td>{_package.price.originalPrice} 원</td>
                    <td>
                      <button
                        onClick={(): void => {
                          changeMainMenu(`/store-and-package-detail?id=${_package.id}&type=${_package.product.length}`)
                        }}
                      >
                        상세 보기
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        <div>럭셔리 모라줌 패키지</div>
        <table className={styles.tableContainer}>
          <tbody>
            <tr>
              <th>이름</th>
              <th>제품</th>
              <th>정가 가격(국산 세단)</th>
              <th></th>
            </tr>
            {excellentPackageCardArray
              .sort((a: Package, b: Package) => {
                if (a.name < b.name) {
                  return -1
                }

                return 1
              })
              .map((_package, index) => {
                return (
                  <tr key={index}>
                    <td>{_package.name}</td>
                    <td style={{ display: 'flex' }}>
                      {_package.product.map((_product, productIndex) => {
                        const isLastIndex = _package.product.length - 1

                        return (
                          <p key={productIndex}>
                            {_product.name} {productIndex !== isLastIndex && '+ '}
                          </p>
                        )
                      })}
                    </td>
                    <td>{_package.price.originalPrice} 원</td>
                    <td>
                      <button
                        onClick={(): void => {
                          changeMainMenu(`/store-and-package-detail?id=${_package.id}&type=${_package.product.length}`)
                        }}
                      >
                        상세 보기
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        <div>갓성비 패키지</div>
        <table className={styles.tableContainer}>
          <tbody>
            <tr>
              <th>이름</th>
              <th>제품</th>
              <th>정가 가격(국산 세단)</th>
              <th></th>
            </tr>
            {economicPackageCardArray
              .sort((a: Package, b: Package) => {
                if (a.name < b.name) {
                  return -1
                }

                return 1
              })
              .map((_package, index) => {
                return (
                  <tr key={index}>
                    <td>{_package.name}</td>
                    <td style={{ display: 'flex' }}>
                      {_package.product.map((_product, productIndex) => {
                        const isLastIndex = _package.product.length - 1

                        return (
                          <p key={productIndex}>
                            {_product.name} {productIndex !== isLastIndex && ' + '}
                          </p>
                        )
                      })}
                    </td>
                    <td>{_package.price.originalPrice} 원</td>
                    <td>
                      <button
                        onClick={(): void => {
                          changeMainMenu(`/store-and-package-detail?id=${_package.id}&type=${_package.product.length}`)
                        }}
                      >
                        상세 보기
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        <div>테슬라 모델 3 패키지</div>
        <table className={styles.tableContainer}>
          <tbody>
            <tr>
              <th>이름</th>
              <th>제품</th>
              <th>정가 가격(국산 세단)</th>
              <th></th>
            </tr>
            {teslaPackageArray.map((_package, index) => {
              return (
                <tr key={index}>
                  <td>{_package.name}</td>
                  <td style={{ display: 'flex' }}>
                    {_package.product.map((_product, productIndex) => {
                      const isLastIndex = _package.product.length - 1

                      return (
                        <p key={productIndex}>
                          {_product.name} {productIndex !== isLastIndex && ' + '}
                        </p>
                      )
                    })}
                  </td>
                  <td>{_package.price.originalPrice} 원</td>
                  <td>
                    <button
                      onClick={(): void => {
                        changeMainMenu(`/store-and-package-detail?id=${_package.id}&type=${_package.product.length}`)
                      }}
                    >
                      상세 보기
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
