import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import {
  Button,
  Menu as MaterialMenu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import Select, { OptionsType } from 'react-select'

import {
  Get_All_StoresQuery as GetAllStoresQuery,
  Get_OrdersQuery as GetOrdersQuery,
  Order,
  Order_Status_Enum as OrderStatusEnum,
  Store,
} from '../../assets/graphql/graphql'
import config from '../../configs/index'
import useOrderFunctions from '../../hooks/useOrderFunctions'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'
import { GET_ALL_STORES, GET_ORDERS } from './query'

interface DateEditBoxProps {
  orderItem: Order
  changeToNewSchedule: (item: Order, schedule: string) => Promise<void>
}

interface OrderMenuProps {
  orderItem: Order
  isPartTimer: boolean
  confirmDate: (item: Order) => Promise<void>
  sendOrderConfirmKakao: (item: Order, to: string) => Promise<void>
  changeSnackbarAlertContent: (data: { content: string; severity: 'error' | 'success' | 'info' | 'warning' }) => void
  changeLoadingSpinnerVisibility: (data: boolean) => void
}

const DateEditBox: React.FunctionComponent<DateEditBoxProps> = ({
  orderItem,
  changeToNewSchedule,
}: DateEditBoxProps) => {
  const [newSchedule, changeNewSchedule] = useState('')
  const [editDisabled, setEditDisabled] = useState(true)

  const orderNotCompleted =
    orderItem.order_status &&
    !(
      orderItem.order_status.value === OrderStatusEnum.Canceled ||
      orderItem.order_status.value === OrderStatusEnum.Refunded ||
      orderItem.order_status.value === OrderStatusEnum.Finished
    )

  useEffect(() => {
    changeNewSchedule(orderItem.date_time ? moment(orderItem.date_time).local().format('YYYY-MM-DDTHH:00') : '')
  }, [orderItem])

  return (
    <>
      {!orderItem.date_time && <div className={styles.warning}>고객과 시공 예약 일정 확인 필요!!!</div>}
      {!orderItem.date_time && orderItem.date_time_option.length === 0 && '시간대 미정 (출고 대기)'}
      {!orderItem.date_time &&
        orderItem.date_time_option.map((date: string, _index: number) => {
          return <div key={_index}>{`${_index + 1}. ${moment(date).local().format('YYYY-MM-DD HH시')}`}</div>
        })}
      <input
        type={'datetime-local'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          const dateTime = e.currentTarget.value

          changeNewSchedule(
            moment(`${dateTime.substr(0, dateTime.length - 2)}00`)
              .local()
              .format('YYYY-MM-DDTHH:00'),
          )
        }}
        defaultValue={newSchedule}
        disabled={!orderNotCompleted || editDisabled}
      />
      {orderNotCompleted && (
        <button
          onClick={async () => {
            if (editDisabled) {
              setEditDisabled(false)
            } else {
              setEditDisabled(true)
              await changeToNewSchedule(orderItem, newSchedule)
            }
          }}
        >
          {editDisabled ? '수정' : '예약 일자 변경'}
        </button>
      )}
    </>
  )
}

const OrderMenu: React.FunctionComponent<OrderMenuProps> = ({
  orderItem,
  isPartTimer,
  confirmDate,
  sendOrderConfirmKakao,
  changeSnackbarAlertContent,
  changeLoadingSpinnerVisibility,
}: OrderMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isPaid =
    orderItem.order_status &&
    (orderItem.order_status.value === OrderStatusEnum.Created || orderItem.order_status.value === OrderStatusEnum.Paid)

  const isConfirmed = orderItem.order_status && orderItem.order_status.value === OrderStatusEnum.Confirmed

  const sendQuoteHandler = async () => {
    try {
      changeLoadingSpinnerVisibility(true)

      await axios.post(`${config.backendEndPoint}/send/send-quote-to-store-kakao`, {
        data: orderItem,
      })
      changeSnackbarAlertContent({ severity: 'success', content: '전송 성공' })
      changeLoadingSpinnerVisibility(false)
    } catch (err) {
      changeSnackbarAlertContent({ severity: 'error', content: '전송 실패' })
      Sentry.captureException(err)
      changeLoadingSpinnerVisibility(false)
    }
  }

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Open Menu
      </Button>
      <MaterialMenu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          disabled={isPartTimer || !isPaid}
          onClick={async (): Promise<void> => {
            if (!orderItem.date_time) {
              changeSnackbarAlertContent({
                severity: 'warning',
                content: '예약 일자를 선택해주세요.',
              })
            } else if (!orderItem.phone) {
              changeSnackbarAlertContent({
                severity: 'warning',
                content: '고객 휴대폰 번호가 없습니다. 잠시후 다시 시도 해주세요.',
              })
            } else if (!orderItem.storeByStore.phone_business) {
              changeSnackbarAlertContent({
                severity: 'warning',
                content: '매장 휴대폰 번호가 없습니다. 잠시후 다시 시도 해주세요.',
              })
              // eslint-disable-next-line no-alert
            } else if (window.confirm(`해당 시공의 예약 일자를 확정하시겠습니까?`)) {
              confirmDate(orderItem)
            }
          }}
        >
          예약 일자 확정
        </MenuItem>
        <MenuItem disabled={isPartTimer || isPaid || !isConfirmed} onClick={() => sendQuoteHandler()}>
          매장에 견적서 보내기
        </MenuItem>

        <MenuItem
          disabled={isPartTimer || isPaid || !isConfirmed}
          onClick={(): void => {
            // eslint-disable-next-line no-alert
            if (window.confirm(`고객에게 예약 확정 알림톡을 보내시겠습니까?`)) {
              sendOrderConfirmKakao(orderItem, 'customer')
            }
          }}
        >
          (고객)예약확정카톡
        </MenuItem>
        <MenuItem
          disabled={isPartTimer || isPaid || !isConfirmed}
          onClick={(): void => {
            // eslint-disable-next-line no-alert
            if (window.confirm(`매장에게 예약 확정 알림톡을 보내시겠습니까?`)) {
              sendOrderConfirmKakao(orderItem, 'store')
            }
          }}
        >
          (매장)예약확정카톡
        </MenuItem>
      </MaterialMenu>
    </>
  )
}

const headers = [
  { label: 'Order ID', key: 'id' },
  { label: '시공 종류', key: 'type' },
  { label: '생성 일자', key: 'created_at_kst' },
  { label: '예약상태', key: 'status' },
  { label: 'lead Status', key: 'lead_status' },
  { label: 'Chat assignee', key: 'chat_assignee' },
  { label: 'Support assignee', key: 'support_assignee' },
  { label: 'Match maker', key: 'match_maker' },
  { label: '예약일자', key: 'date_time' },
  { label: '시공점', key: 'store' },
  { label: '고객 이메일', key: 'email' },
  { label: '고객 이름', key: 'name' },
  { label: '결제 금액', key: 'price_final' },
  { label: '매장 이체 금액', key: 'price_payout' },
  { label: '고객 번호', key: 'phone' },
  { label: '매장 번호', key: 'store_phone' },
  { label: '결제 링크 전송 시각', key: 'payment_link_sent_at' },
  { label: '결제 시각', key: 'paid_at' },
]

const App: React.FunctionComponent = () => {
  const {
    authorization,
    isPartTimer,
    changeMainMenu,
    changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility,
  } = useRootData(({ appStore, authStore }) => ({
    authorization: authStore.authorization.get(),
    isPartTimer: authStore.isPartTimer.get(),
    changeMainMenu: appStore.changeMainMenu,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    changeSnackbarVisibility: appStore.changeSnackbarVisibility,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(300)
  const [sortMode, setSortMode] = useState<['created_at' | 'date_time', 'asc_nulls_first' | 'desc_nulls_last']>([
    'created_at',
    'desc_nulls_last',
  ])
  const [createdRange, setCreatedRange] = useState<[string, string]>(['', ''])
  const [storeSearch, setStoreSearch] = useState<{ value: string; label: string }>(null)
  const [statusSearch, setStatusSearch] = useState<OptionsType<{ value: OrderStatusEnum; label: string }>>([])
  const [order777Search, setOrder777Search] = useState(null)

  const [orderData, setOrderData] = useState<GetOrdersQuery>(null)

  const toSortQueryString = () => {
    return `{ ${sortMode[0]} : ${sortMode[1]} }`
  }

  const toFilterQueryString = () => {
    let queryString = ''

    if (createdRange[0]) queryString += `{ created_at: { _gt: "${moment(createdRange[0]).utc().format()}" } }`
    if (createdRange[1])
      queryString += `{ created_at: { _lt: "${moment(createdRange[1]).add(1, 'day').utc().format()}" } }`

    if (storeSearch && storeSearch.value) queryString += `{ storeByStore: { name: { _eq: "${storeSearch.value}" } } }`

    if (statusSearch && statusSearch.length) {
      queryString += '{ _or: ['
      statusSearch.forEach((status) => {
        queryString += `{ status: { _eq: ${status.value} } }`
      })
      queryString += '] }'
    }

    if (order777Search) {
      queryString += `{ is_777_order: { _eq: ${order777Search.value} } }`
    }

    return queryString
  }

  const [getData, { data: orderContents, loading, error }] = useLazyQuery<GetOrdersQuery>(
    GET_ORDERS(toSortQueryString(), toFilterQueryString()),
    {
      variables: { page, rowsPerPage },
      fetchPolicy: 'no-cache',
    },
  )

  const { data: storeData } = useQuery<GetAllStoresQuery>(GET_ALL_STORES, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    setPage(0)
  }, [createdRange, storeSearch, statusSearch])

  useEffect(() => {
    getData({
      variables: { page: page * rowsPerPage, rowsPerPage },
    })
  }, [page, rowsPerPage])

  useEffect(() => {
    if (loading) {
      changeLoadingSpinnerVisibility(true)
    } else {
      if (orderContents) {
        setOrderData(orderContents)
      }
      changeLoadingSpinnerVisibility(false)
    }
  }, [loading])

  const {
    sendOrderConfirmKakao,
    confirmDate,
    changeToNewSchedule,
    finishOrder,
    installOrder,
    refundOrder,
    cancelOrder,
    sendPaymentLink,
  } = useOrderFunctions(getData)

  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>
          <div className={styles.header}>
            <div>
              생성일자 Start Date:{' '}
              <input
                type="date"
                value={createdRange[0]}
                onChange={(e) => setCreatedRange([e.currentTarget.value, createdRange[1]])}
              />
              End Date:{' '}
              <input
                type="date"
                value={createdRange[1]}
                onChange={(e) => setCreatedRange([createdRange[0], e.currentTarget.value])}
              />
              <button onClick={() => setCreatedRange(['', ''])}>Clear Date Selection</button>
            </div>
            <div>
              {orderData && (
                <CSVLink
                  data={orderData.order.map((item: Order) => {
                    const hasPackage = item.product_item?.package

                    return {
                      id: `${item.is_777_order ? '[777] ' : ''} ${item.id}`,
                      type: `${hasPackage ? '패키지' : '일반 틴팅'}`,
                      created_at_kst: moment(item.created_at).format('YY/MM/DD HH:mm'),
                      status: item.order_status.description,
                      lead_status: item.leadByLead && item.leadByLead.status,
                      chat_assignee:
                        item.leadByLead &&
                        item.leadByLead.chats &&
                        item.leadByLead.chats.length > 0 &&
                        item.leadByLead.chats[0] &&
                        item.leadByLead.chats[0].accountByChatAssignee &&
                        item.leadByLead.chats[0].accountByChatAssignee.profile_riderdashes[0].name,
                      support_assignee:
                        item.leadByLead &&
                        item.leadByLead.accountBySupportAssignee &&
                        item.leadByLead.accountBySupportAssignee.profile_riderdashes[0].name,
                      match_maker:
                        item.leadByLead &&
                        item.leadByLead.accountByShopAssignee &&
                        item.leadByLead.accountByShopAssignee.profile_riderdashes[0].name,
                      date_time: item.date_time
                        ? moment(`${item.date_time.substr(0, item.date_time.length - 2)}00`)
                            .local()
                            .format('YYYY-MM-DDTHH:00')
                        : '',
                      store: item.storeByStore.name,
                      email: item.email,
                      name: item.name,
                      price_final: item.price_final,
                      price_payout: item.price_payout,
                      phone: `${item.phone ? new PhoneNumber(item.phone, 'KR').getNumberFrom('KR') : ''}`,
                      store_phone: `${
                        item.storeByStore.phone_business
                          ? new PhoneNumber(item.storeByStore.phone_business, 'KR').getNumberFrom('KR')
                          : ''
                      }`,
                      payment_link_sent_at: item.payment_link_sent_at
                        ? moment(item.payment_link_sent_at).format('YY/MM/DD HH:mm')
                        : '',
                      paid_at: item.paid_at ? moment(item.paid_at).format('YY/MM/DD HH:mm') : '',
                    }
                  })}
                  filename={`시공 예약 목록 리스트`}
                  headers={headers}
                >
                  엑셀 파일로 다운로드
                </CSVLink>
              )}
            </div>
          </div>
          <Select
            placeholder={'예약상태 (다중선택)...'}
            value={statusSearch}
            clearValue={() => setStatusSearch([])}
            onChange={setStatusSearch}
            isClearable
            isMulti
            options={[
              { value: OrderStatusEnum.Created, label: '예약 진행중' },
              { value: OrderStatusEnum.Confirmed, label: '예약 확정' },
              { value: OrderStatusEnum.Paid, label: '결제 완료' },
              { value: OrderStatusEnum.Finished, label: '서비스 완료' },
              { value: OrderStatusEnum.PaymentLinkSent, label: '결제 링크 전송 완료' },
              { value: OrderStatusEnum.PaymentFailed, label: '결제 실패' },
              { value: OrderStatusEnum.Refunded, label: '환불 완료' },
              { value: OrderStatusEnum.Canceled, label: '취소' },
              { value: OrderStatusEnum.Installed, label: '시공 완료' },
            ]}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
          <Select
            placeholder={'시공점...'}
            value={storeSearch}
            onChange={setStoreSearch}
            isClearable
            isSearchable
            options={
              storeData &&
              storeData.store.map((store: Store) => {
                return { value: store.name, label: store.name }
              })
            }
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
          <Select
            placeholder={'777...'}
            value={order777Search}
            onChange={setOrder777Search}
            isClearable
            options={[
              { value: false, label: '일반 주문' },
              { value: true, label: '777 주문' },
            ]}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
        </div>

        <Paper>
          <Typography className={styles.title} variant="h6" id="tableTitle" component="div">
            {'시공 예약 목록'}
          </Typography>
          <TableContainer className={styles.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {[
                    { label: 'No.', minWidth: '20px' },
                    { label: 'Order ID', minWidth: '120px' },
                    { label: '시공 종류', minWidth: '120px' },
                    { label: '생성 일자', minWidth: '120px', orderBy: 'created_at' },
                    { label: '예약상태', minWidth: '100px' },
                    { label: 'lead Status', minWidth: '100px' },
                    { label: 'Chat assignee', minWidth: '100px' },
                    { label: 'Support assignee', minWidth: '100px' },
                    { label: 'Match maker', minWidth: '100px' },
                    { label: '예약일자', minWidth: '170px', orderBy: 'date_time' },
                    { label: '시공점', minWidth: '120px' },
                    { label: '고객 이메일', minWidth: '120px' },
                    { label: '고객 이름', minWidth: '120px' },
                    { label: '결제금액', minWidth: '120px' },
                    { label: '매장이체금액(VAT포함)', minWidth: '120px' },
                    { label: '고객 번호', minWidth: '120px' },
                    { label: '매장 번호', minWidth: '120px' },
                    { label: '결제상태', minWidth: '20px' },
                    { label: '예약 일자 확정', minWidth: '20px' },
                    { label: '예약 취소', minWidth: '20px' },
                    { label: '시공 완료', minWidth: '20px' },
                    { label: '상세보기', minWidth: '20px' },
                    { label: '주문 수정', minWidth: '20px' },
                  ].map((headerRow, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: headerRow.minWidth }}
                      sortDirection={sortMode[0] === headerRow.orderBy ? 'desc' : false}
                    >
                      {headerRow.orderBy ? (
                        <TableSortLabel
                          active={sortMode[0] === headerRow.orderBy}
                          direction={sortMode[1] === 'asc_nulls_first' ? 'asc' : 'desc'}
                          onClick={() => {
                            const isAsc = sortMode[0] !== headerRow.orderBy || sortMode[1] === 'asc_nulls_first'
                            setSortMode([
                              headerRow.orderBy as typeof sortMode[0],
                              isAsc ? 'desc_nulls_last' : 'asc_nulls_first',
                            ])
                          }}
                        >
                          {headerRow.label}
                        </TableSortLabel>
                      ) : (
                        headerRow.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData &&
                  orderData.order.map((item: Order, index: number) => {
                    const isStripePayment =
                      item.order_and_transactions[0] &&
                      item.order_and_transactions[0].transactionByTransaction.stripe_payment_intent_id
                    const isManualPayment =
                      item.order_and_transactions[0] &&
                      !item.order_and_transactions[0].transactionByTransaction.stripe_payment_intent_id &&
                      !item.order_and_transactions[0].transactionByTransaction.rapyd_payment_intent_id

                    let paymentSucceeded: boolean = null
                    if (item.order_status.value === OrderStatusEnum.PaymentFailed) {
                      paymentSucceeded = false
                    } else if (item.order_status.value === OrderStatusEnum.Finished) {
                      paymentSucceeded = true
                    }
                    const hasPackage = item.product_item?.package

                    const orderNotCompleted =
                      item.order_status &&
                      !(
                        item.order_status.value === OrderStatusEnum.Canceled ||
                        item.order_status.value === OrderStatusEnum.Refunded ||
                        item.order_status.value === OrderStatusEnum.Finished
                      )

                    return (
                      <TableRow key={index}>
                        {/* No. */}
                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                        {/* Order ID */}
                        <TableCell>
                          {item.is_777_order ? '[777] ' : ''}
                          {item.id}
                        </TableCell>
                        {/* 신차패키지 || 일반 틴팅 */}
                        <TableCell>{hasPackage ? '패키지' : '일반 틴팅'}</TableCell>
                        {/* 생성 일자 */}
                        <TableCell>{moment(item.created_at).format('YY/MM/DD HH:mm')}</TableCell>
                        {/* 예약상태 */}
                        <TableCell>{item.order_status.description}</TableCell>
                        {/* lead status */}
                        <TableCell>{item.leadByLead && item.leadByLead.status}</TableCell>
                        {/* Chat assignee */}
                        <TableCell>
                          {item.leadByLead &&
                            item.leadByLead.chats &&
                            item.leadByLead.chats.length > 0 &&
                            item.leadByLead.chats[0] &&
                            item.leadByLead.chats[0].accountByChatAssignee &&
                            item.leadByLead.chats[0].accountByChatAssignee.profile_riderdashes[0].name}
                        </TableCell>
                        {/* Support assignee */}
                        <TableCell>
                          {item.leadByLead &&
                            item.leadByLead.accountBySupportAssignee &&
                            item.leadByLead.accountBySupportAssignee.profile_riderdashes[0].name}
                        </TableCell>
                        {/* Match maker */}
                        <TableCell>
                          {item.leadByLead &&
                            item.leadByLead.accountByShopAssignee &&
                            item.leadByLead.accountByShopAssignee.profile_riderdashes[0].name}
                        </TableCell>
                        {/* 예약일자 */}
                        <TableCell>
                          <DateEditBox orderItem={item} changeToNewSchedule={changeToNewSchedule} />
                        </TableCell>
                        {/* 시공점 */}
                        <TableCell>{item.storeByStore.name}</TableCell>
                        {/* 고객 이메일 */}
                        <TableCell>{item.email}</TableCell>
                        {/* 고객 이름 */}
                        <TableCell>{item.name}</TableCell>
                        {/* 결제금액 */}
                        <TableCell>{`${Number(item.price_final).toLocaleString()}원`}</TableCell>
                        {/* 매장이체금액(VAT포함) */}
                        <TableCell>{`${Number(item.price_payout).toLocaleString()}원`}</TableCell>
                        <TableCell>{item.phone ? new PhoneNumber(item.phone, 'KR').getNumberFrom('KR') : ''}</TableCell>
                        {/* 매장 번호 */}
                        <TableCell>
                          {item.storeByStore.phone_business
                            ? new PhoneNumber(item.storeByStore.phone_business, 'KR').getNumberFrom('KR')
                            : ''}
                        </TableCell>
                        {/* 결제상태 */}
                        <TableCell>
                          {/* If the payment link of this order was sent... */}
                          {(item.installed_at || item.payment_link_sent_at) &&
                            !item.paid_at &&
                            item.order_status.value !== OrderStatusEnum.Canceled &&
                            item.order_status.value !== OrderStatusEnum.Finished && (
                              <div>
                                <button
                                  onClick={() => {
                                    finishOrder(item.id)
                                  }}
                                >
                                  현장 결제
                                </button>
                                결제 링크 전달 시간
                                {item.payment_link_sent_at
                                  ? moment(item.payment_link_sent_at).format('YY/MM/DD HH:mm')
                                  : '미전송'}
                                <button
                                  onClick={() => {
                                    sendPaymentLink(item.id, 'stripe')
                                  }}
                                >
                                  Stripe 전송
                                </button>
                                <a href={`${config.frontendUrl}/order-payment?key=${item.id}`}>Stripe Link</a>
                                <button
                                  onClick={() => {
                                    sendPaymentLink(item.id, 'rapyd')
                                  }}
                                >
                                  Rapyd 전송
                                </button>
                                <a href={`${config.frontendUrl}/order-payment?key=${item.id}&gateway=rapyd`}>
                                  Rapyd Link
                                </a>
                                <button
                                  onClick={() => {
                                    sendPaymentLink(item.id, 'bootpay')
                                  }}
                                >
                                  Bootpay 전송
                                </button>
                                <a href={`${config.frontendUrl}/order-payment?key=${item.id}&gateway=bootpay`}>
                                  Bootpay Link
                                </a>
                              </div>
                            )}

                          {/* If the payment link of this order was sent, and the payment was done */}
                          {item.payment_link_sent_at && item.paid_at && (
                            <div>
                              결제 링크 전달 시간
                              <br />
                              {moment(item.payment_link_sent_at).format('YY/MM/DD HH:mm')}
                              <br />
                              결제 완료
                              <br />
                              {moment(item.paid_at).format('YY/MM/DD HH:mm')}
                            </div>
                          )}

                          {/* If this order was created with Stripe */}
                          {(item.order_status.value === OrderStatusEnum.PaymentFailed ||
                            item.order_status.value === OrderStatusEnum.Finished) &&
                            item.order_and_transactions &&
                            item.order_and_transactions[0] &&
                            item.order_and_transactions[0].transactionByTransaction.stripe_payment_intent_id && (
                              <div>
                                <button
                                  onClick={(): void => {
                                    if (!paymentSucceeded) {
                                      window.open(
                                        `https://dashboard.stripe.com/customers/${item.accountByAccount.profile_kr_customer.stripe_customer_id}`,
                                      )
                                    }
                                  }}
                                  disabled={paymentSucceeded}
                                >
                                  {paymentSucceeded ? '결제 성공' : '결제 실패'}
                                </button>
                                {isStripePayment && (
                                  <a
                                    target={'_blank'}
                                    href={`https://dashboard.stripe.com/payments/${item.order_and_transactions[0].transactionByTransaction.stripe_payment_intent_id}`}
                                    rel={'noreferrer'}
                                  >
                                    stripe에서 확인
                                  </a>
                                )}
                                {isManualPayment && <div>{paymentSucceeded ? '수동 결제건' : ''}</div>}
                              </div>
                            )}

                          {/* If this order was created with Rapyd */}
                          {(item.order_status.value === OrderStatusEnum.PaymentFailed ||
                            item.order_status.value === OrderStatusEnum.Finished) &&
                            item.order_and_transactions &&
                            item.order_and_transactions[0] &&
                            item.order_and_transactions[0].transactionByTransaction.rapyd_payment_intent_id && (
                              <div>
                                <a
                                  target={'_blank'}
                                  href={`https://dashboard.rapyd.net/collect/payments/details/${item.order_and_transactions[0].transactionByTransaction.rapyd_payment_intent_id}`}
                                  rel={'noreferrer'}
                                >
                                  rapyd에서 확인
                                </a>
                                {isManualPayment && <div>{paymentSucceeded ? '수동 결제건' : ''}</div>}
                              </div>
                            )}

                          {item.order_status.value === OrderStatusEnum.Finished &&
                            item.finished_at &&
                            !item.paid_at && <div>현장 결제건</div>}

                          {/* Refund */}
                          {!item.refunded_at && item.paid_at && item.order_and_transactions[0] && authorization && (
                            <button
                              onClick={() => {
                                // eslint-disable-next-line no-alert
                                const reason = prompt('환불 이유를 입력해주세요.', '')

                                if (reason) {
                                  refundOrder(item, reason)
                                }
                              }}
                            >
                              환불하기
                            </button>
                          )}
                          {item.refunded_at && (
                            <div>
                              환불완료
                              <br />
                              {moment(item.refunded_at).format('YY/MM/DD HH:mm')}
                            </div>
                          )}
                        </TableCell>
                        {/* 예약 일자 확정 */}
                        <TableCell>
                          <OrderMenu
                            orderItem={item}
                            isPartTimer={isPartTimer}
                            confirmDate={confirmDate}
                            sendOrderConfirmKakao={sendOrderConfirmKakao}
                            changeSnackbarAlertContent={changeSnackbarAlertContent}
                            changeLoadingSpinnerVisibility={changeLoadingSpinnerVisibility}
                          />
                        </TableCell>
                        {/* 예약 취소 */}
                        <TableCell>
                          {/* Restrict parttimer permission to click this button */}
                          {!isPartTimer && orderNotCompleted && (
                            <button
                              onClick={() => {
                                // eslint-disable-next-line no-alert
                                if (window.confirm(`해당 시공을 취소하시겠습니까?`)) cancelOrder(item)
                              }}
                            >
                              예약 취소
                            </button>
                          )}
                        </TableCell>
                        {/* 시공 완료 */}
                        <TableCell>
                          {/* Restrict parttimer permission to click this button */}
                          {!isPartTimer &&
                            item.order_status &&
                            !(
                              item.order_status.value === OrderStatusEnum.Finished ||
                              item.order_status.value === OrderStatusEnum.Canceled ||
                              item.order_status.value === OrderStatusEnum.Installed ||
                              item.order_status.value === OrderStatusEnum.PaymentLinkSent ||
                              item.order_status.value === OrderStatusEnum.Refunded
                            ) && (
                              <button
                                onClick={() => {
                                  if (!item.price_payout_without_vat) {
                                    changeSnackbarAlertContent({
                                      severity: 'error',
                                      content:
                                        '매장 이체 금액이 누락됨!! 주문 수정가서 매장 이체 금액을 입력해 수정 후 다시 시도해주세요.',
                                    })

                                    return
                                  }

                                  // eslint-disable-next-line no-alert
                                  const finish = window.confirm(
                                    `${moment(item.date_time).format('YYYY-MM-DD (ddd) ・ hh:mm A')} ${
                                      item.storeByStore.name
                                    } ( ${item.price_final}원 )에 대한 \n시공을 완료하시겠습니까?`,
                                  )
                                  if (!finish) return

                                  if (item.order_status.value === OrderStatusEnum.Confirmed) {
                                    installOrder(item.id)
                                  } else {
                                    changeSnackbarAlertContent({
                                      severity: 'error',
                                      content: '시공 예약 일자가 확정되지 않은 예약건입니다.',
                                    })
                                  }
                                }}
                              >
                                시공완료
                              </button>
                            )}
                        </TableCell>
                        {/* 상세보기 */}
                        <TableCell>
                          <button onClick={(): void => changeMainMenu(`/order-detail?id=${item.id}`)}>상세보기</button>
                        </TableCell>
                        {/* 주문 수정 */}
                        <TableCell>
                          <button onClick={() => changeMainMenu(`/edit-order?orderId=${item.id}`)}>주문 수정</button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 300, 500]}
            component="div"
            count={orderData ? orderData.order_aggregate.aggregate.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRowsPerPage(+event.target.value)
              setPage(0)
            }}
          />
        </Paper>
      </div>
    </div>
  )
}
export default React.memo(App)
