import { useLazyQuery } from '@apollo/react-hooks'
import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import { Store } from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

const requestURL = `${config.backendEndPoint}/order/create-777-order`

const insertCharAtPosition = (str: string, pos: number, char: string) => {
  return `${str.substring(0, pos)}${char}${str.substring(pos)}`
}

const makeFullValue = (value: string, maxLength: number, indexes: number[], char: string) => {
  let ret = value.substring(0, maxLength).replace(/[^0-9]/gi, '')

  indexes.forEach((index) => {
    if (ret.length > index) ret = insertCharAtPosition(ret, index, char)
  })

  return ret
}

function numberWithCommas(x: string) {
  return x
    .toString()
    .replace(/[^0-9]/gi, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const App: React.FunctionComponent = () => {
  const { sendAxios, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    sendAxios: appStore.sendAxios,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [formData, setFormData] = useState({
    store: '',
    email: '',
    phoneNumber: '',
    finalPrice: '',
    gateway: 'rapyd',
  })

  const [getStore, { data: storeList }] = useLazyQuery(
    gql`
      query GET_ALL_STORE {
        store(where: { deleted_at: { _is_null: true } }) {
          id
          name
          llumar_store_name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  )

  useEffect(() => {
    getStore()
  }, [])

  if (!storeList) return null

  return (
    <div>
      <table className={styles.tableContainer}>
        <tbody>
          <tr>
            <td>매장</td>
            <td>
              <Select
                variant="outlined"
                defaultValue=""
                onChange={(e) => setFormData({ ...formData, store: e.target.value as string })}
              >
                {storeList.store.map((store: Store) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name} - {store.llumar_store_name}
                  </MenuItem>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <td>고객 이메일</td>
            <td>
              {' '}
              <TextField
                variant="outlined"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              ></TextField>
            </td>
          </tr>
          <tr>
            <td>고객 전화번호</td>
            <td>
              <TextField
                variant="outlined"
                value={formData.phoneNumber}
                onChange={(e) =>
                  setFormData({ ...formData, phoneNumber: makeFullValue(e.target.value, 13, [3, 8], '-') })
                }
              ></TextField>
            </td>
          </tr>
          <tr>
            <td>최종 결제 금액</td>
            <td>
              <TextField
                variant="outlined"
                value={formData.finalPrice}
                onChange={(e) => setFormData({ ...formData, finalPrice: numberWithCommas(e.target.value) })}
              ></TextField>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={styles.submitButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const result = await sendAxios.post(requestURL, {
              ...formData,
              phoneNumber: formData.phoneNumber.replace(/[^0-9]/gi, ''),
              finalPrice: formData.finalPrice.replace(/[^0-9]/gi, ''),
            })

            if (result) {
              changeSnackbarAlertContent({ severity: 'info', content: `전송 성공 \n` })
            } else {
              changeSnackbarAlertContent({ severity: 'error', content: `전송 실패 \n` })
            }
          }}
        >
          고객에게 결제 링크 전송
        </Button>
      </div>
      <div>
        시공 예약 목록에 <b>[결제 링크 전송 완료]</b> 상태로 추가 됩니다.
      </div>
    </div>
  )
}

export default React.memo(App)
