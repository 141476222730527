import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import React from 'react'

import Text from '../../../components/atoms/Text'
import { PriceValuesStructure } from '../index'

interface PriceSectionProps {
  priceProduct: number
  priceExtra: number
  priceDiscount: number
  pricePayout: number
  pricePayoutWithoutVat: number
  priceFinal: number
  commissionRate: number
  setPriceValues: (object: PriceValuesStructure) => void
}

const App: React.FunctionComponent<PriceSectionProps> = ({
  priceProduct,
  priceExtra,
  priceDiscount,
  pricePayout,
  pricePayoutWithoutVat,
  priceFinal,
  commissionRate,
  setPriceValues,
}: PriceSectionProps) => {
  return (
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: 'gainsboro' }}>
        <TableRow>
          <TableCell>제품 금액</TableCell>
          <TableCell>추가 발생 금액</TableCell>
          <TableCell>할인 금액</TableCell>
          <TableCell>고객 최종 결제 금액</TableCell>
          <TableCell>매장 이체 금액</TableCell>
          <TableCell>매장 이체 금액(VAT 제외)</TableCell>
          <TableCell>카라모라 마진율(%)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <TextField disabled={true} value={priceProduct} variant="outlined" />
            <Text text={`${priceProduct.toLocaleString()}원`}></Text>
          </TableCell>
          <TableCell>
            <TextField
              value={priceExtra}
              variant="outlined"
              onChange={(e) => setPriceValues({ priceExtra: parseInt(e.target.value, 10) || 0 })}
            />
            <Text text={`${priceExtra.toLocaleString()}원`}></Text>
          </TableCell>
          <TableCell>
            <TextField
              value={priceDiscount}
              variant="outlined"
              onChange={(e) =>
                setPriceValues({
                  priceDiscount: parseInt(e.target.value, 10) || 0,
                })
              }
            />
            <Text text={`${priceDiscount.toLocaleString()}원`}></Text>
          </TableCell>
          <TableCell>
            <TextField disabled={true} value={priceFinal} variant="outlined" />
            <Text text={`${priceFinal.toLocaleString()}원`}></Text>
          </TableCell>
          <TableCell>
            <TextField
              value={pricePayout}
              variant="outlined"
              onChange={(e) =>
                setPriceValues({
                  pricePayout: parseInt(e.target.value, 10) || 0,
                })
              }
            />
            <Text text={`${pricePayout.toLocaleString()}원`}></Text>
          </TableCell>
          <TableCell>
            <TextField disabled={true} value={pricePayoutWithoutVat} variant="outlined" />
            <Text text={`${pricePayoutWithoutVat.toLocaleString()}원`}></Text>
          </TableCell>
          <TableCell>
            <TextField disabled={true} value={commissionRate} variant="outlined" />
            <Text text={`${commissionRate}%`}></Text>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
