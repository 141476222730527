// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__row___2hsCy .index-module__col___O7O5n {\n  font-weight: 500; }\n  .index-module__row___2hsCy .index-module__col___O7O5n .index-module__edit___2809U {\n    width: 30px;\n    text-decoration: underline; }\n\n.index-module__row___2hsCy:nth-child(2n) {\n  background-color: rgba(0, 0, 0, 0.03); }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadList/LeadListTable/LeadRow/index.module.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB,EAAA;EAFpB;IAKM,WAAW;IACX,0BAA0B,EAAA;;AAIhC;EACE,qCAAqC,EAAA","file":"index.module.scss","sourcesContent":[".row {\n  .col {\n    font-weight: 500;\n\n    .edit {\n      width: 30px;\n      text-decoration: underline;\n    }\n  }\n}\n.row:nth-child(2n) {\n  background-color: rgba(0, 0, 0, 0.03);\n}\n"]}]);
// Exports
exports.locals = {
	"row": "index-module__row___2hsCy",
	"col": "index-module__col___O7O5n",
	"edit": "index-module__edit___2809U"
};
module.exports = exports;
