import React from 'react'

import { PriceObject, PriceUpdateObject } from '../../../../../stores/calculator/type'
import CalculatorRow, { RowType } from '../CalculatorRow'

export interface FinalPriceRowsProps {
  idx: number
  extraDiscountPrice: Partial<PriceObject>[]
  extraPrice: Partial<PriceObject>[]
  totalPrice: Partial<PriceObject>
  finalPrice: Partial<PriceObject>
  prevFinalPrice: PriceObject
  isAvalableSentQuote: boolean
  sentAt: {
    price: boolean
    premium: boolean
    package: boolean
    lowest: boolean
  }
  isOrder: boolean
  changeExtraDiscountPrice: (object: PriceUpdateObject, idx: number) => void
  changeExtraPrice: (object: PriceUpdateObject, idx: number) => void
}

const App: React.FunctionComponent<FinalPriceRowsProps> = ({
  idx,
  extraDiscountPrice,
  extraPrice,
  finalPrice,
  totalPrice,
  prevFinalPrice,
  sentAt,
  isOrder,
  isAvalableSentQuote,
  changeExtraDiscountPrice,
  changeExtraPrice,
}: FinalPriceRowsProps) => {
  return (
    <>
      {/* 추가 할인 */}
      <CalculatorRow
        rowType={RowType.InputRow}
        rowTitle={'➖ (추가 할인)'}
        prices={extraDiscountPrice[idx]}
        changeExtraPricesHandler={changeExtraDiscountPrice}
        idx={idx}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
      />
      {/* 추가 금액 */}
      <CalculatorRow
        rowType={RowType.InputRow}
        rowTitle={'➕ (추가 금액)'}
        prices={extraPrice[idx]}
        changeExtraPricesHandler={changeExtraPrice}
        idx={idx}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
      />
      {/* 최종 금액 */}
      <CalculatorRow
        rowType={RowType.FinalRow}
        rowTitle={'최종 금액'}
        prices={finalPrice}
        prevFinalPrice={prevFinalPrice}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
        priceProduct={totalPrice.price}
      />
    </>
  )
}

export default React.memo(App)
