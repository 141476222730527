import copy from 'copy-text-to-clipboard'
import _ from 'lodash'
import { observable, toJS } from 'mobx'

import { Quote, Quote_Item as QuoteItem } from '../../assets/graphql/graphql'
import configs from '../../configs'
import client from '../../utils/graphql'
import { generateComputedFieldInQuoteItem, getProductDetailTypeAndTextArray } from '../../utils/utility'
import calculatorStore from '../calculator'
import leadStore from '../lead'
import { pickedColumns, UPSERT_QUOTE_WITH_LEAD } from './query'
import fillQuoteFromCalculator from './quoteFromCalculator'

export interface Remark {
  // eslint-disable-next-line camelcase
  customer_remark?: string
  // eslint-disable-next-line camelcase
  date_time_remark?: string
}

const createStore = (): typeof quoteStore => {
  const quoteStore = {
    // Quote information
    quoteObject: observable.box<Quote>(null),
    clearQuoteObject: () => quoteStore.quoteObject.set(null),
    setQuoteObject: (data: Quote) => quoteStore.quoteObject.set(data),
    // Utility
    get numberOfQuoteItems() {
      const quoteObject = quoteStore.quoteObject.get()
      if (!quoteObject) return null

      return quoteStore.quoteObject.get().quote_items.filter((item) => !item.deleted_at).length
    },

    // Add or remove a quote from store
    addQuote: async (index?: number) => {
      if (quoteStore.numberOfQuoteItems > 4) return

      calculatorStore.cloneStore(index)

      let newQuoteItem: Partial<QuoteItem> = {
        package_detail: [],
        product_item_detail: [],
      }
      if (index > -1) {
        // If it's a duplicate...
        newQuoteItem = {
          ...quoteStore.quoteObject.get().quote_items[index],
          sent_at: undefined,
          id: undefined,
          is_active: undefined,
          // TODO : Need to be updated date_time_remark to store_remark
          remark: { customer_remark: '', date_time_remark: '' },
        }
      } else {
        // if it's a new item...
        pickedColumns.forEach((key) => {
          newQuoteItem[key] = undefined
        })
      }

      const currentQuoteItemsObject = quoteStore.quoteObject.get().quote_items

      currentQuoteItemsObject.push(newQuoteItem as QuoteItem)
      quoteStore.quoteObject.set({
        ...quoteStore.quoteObject.get(),
        quote_items: [...currentQuoteItemsObject],
      })

      await leadStore.updateLeadByPK()
    },
    removeQuote: async (index: number) => {
      if (quoteStore.numberOfQuoteItems < 2) return

      const quoteItems = quoteStore.quoteObject.get().quote_items

      quoteItems[index] = { ...quoteItems[index], deleted_at: new Date() }

      quoteStore.quoteObject.set({
        ...quoteStore.quoteObject.get(),
        quote_items: [...quoteItems],
      })

      await leadStore.updateLeadByPK()
    },
    // Generate text of product list
    copySelectedProductToClipboard: async (idx: number) => {
      let text = '[제품 요약]\n'

      const selectedPackageObject = calculatorStore.selectedPackageObject.get()[idx + 1]

      if (selectedPackageObject) {
        text += `• 패키지 : ${selectedPackageObject.name}\n`
      }

      const { product_item_detail: productItemDetail, product_item: productItem } = generateComputedFieldInQuoteItem(
        quoteStore.quoteObject.get().quote_items[idx],
        idx,
      )

      getProductDetailTypeAndTextArray(productItemDetail, productItem).forEach(({ productDetailTypeAndText }) => {
        const [productDetailTypeText, productDetailText] = productDetailTypeAndText

        if (productDetailText === '[서비스 항목] 배송비') return

        text += `• ${productDetailTypeText} : \n ${productDetailText}\n`
      })

      copy(text.trim())

      await leadStore.updateLeadByPK()
    },
    copyQuoteComparisionLink: async (idx: number) => {
      const text = `견적 보러 가기 : ${configs.frontendUrl}/quote-comparison?quoteItemId=${
        quoteStore.quoteObject.get().quote_items[idx].id
      }`

      copy(text.trim())

      await leadStore.updateLeadByPK()
    },
    openQuoteComparisonNewTab: async (idx: number) => {
      await leadStore.updateLeadByPK()

      window.open(
        `${configs.frontendUrl}/quote-comparison?quoteItemId=${
          quoteStore.quoteObject.get().quote_items[idx].id
        }&admin=true`,
      )
    },
    toggleActivationQuote: async (index: number) => {
      const isActive =
        quoteStore.quoteObject.get().quote_items[index].is_active ||
        quoteStore.quoteObject.get().quote_items[index].is_active === undefined

      const newQuoteItem = { ...quoteStore.quoteObject.get().quote_items[index] }
      newQuoteItem.is_active = !isActive

      const newQuoteItems = [...quoteStore.quoteObject.get().quote_items]
      newQuoteItems[index] = newQuoteItem

      quoteStore.setQuoteObject({ ...quoteStore.quoteObject.get(), quote_items: newQuoteItems })

      await leadStore.updateLeadByPK()
    },
    writeRemarkQuote: async (index: number, remark: Remark) => {
      const newQuoteItem = { ...quoteStore.quoteObject.get().quote_items[index] }
      newQuoteItem.remark = {
        ...newQuoteItem.remark,
        ...remark,
      }

      const newQuoteItems = [...quoteStore.quoteObject.get().quote_items]
      newQuoteItems[index] = newQuoteItem

      quoteStore.setQuoteObject({ ...quoteStore.quoteObject.get(), quote_items: newQuoteItems })
    },

    // Convert between calculator and quote stores
    fillQuoteFromCalculator,

    // Upsert to quote table
    upsertQuote: async () => {
      const { id: quoteId, lead, quote_items: quoteItems } = quoteStore.quoteObject.get()

      await client.mutate({
        mutation: UPSERT_QUOTE_WITH_LEAD,
        variables: {
          quoteObject: {
            id: quoteId,
            lead,
            quote_items: {
              data: toJS(quoteItems).map((quoteItem) => _.pick(quoteItem, ...pickedColumns)),
              on_conflict: {
                constraint: 'quote_item_pkey',
                update_columns: pickedColumns,
              },
            },
          },
        },
      })
    },
  }

  return quoteStore
}

const store = createStore()
export default store
