import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import { _Get_Select_ProductsQuery as GetSelectProductsQuery, Package } from '../../../assets/graphql/graphql'
import Select, { TypeSelectBox } from '../../../components/atoms/Select'
import { PriceValuesStructure, SelectedValuesStructure } from '../index'

interface PackageSectionProps {
  carOrigin: string
  carType: string
  packageId: string
  packageArray: Array<TypeSelectBox>
  selectProductsContents: GetSelectProductsQuery
  setSeletedValeus: (object: SelectedValuesStructure) => void
  setPriceValues: (object: PriceValuesStructure) => void
}

const App: React.FunctionComponent<PackageSectionProps> = ({
  carOrigin,
  carType,
  packageId,
  packageArray,
  selectProductsContents,
  setSeletedValeus,
  setPriceValues,
}: PackageSectionProps) => {
  return (
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: 'gainsboro' }}>
        <TableRow>
          <TableCell>패키지 선택</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Select
              value={packageId}
              label={'패키지'}
              options={packageArray}
              changeHandler={(event): void => {
                const packageByPackage = selectProductsContents
                  ? selectProductsContents.packages.filter((item: Package) => item.id === event.target.value)[0]
                  : null

                if (packageByPackage) {
                  setSeletedValeus({
                    packageId: packageByPackage.id,
                    frontTintingProductItemId: '',
                    sidebackTintingProductItemId: '',
                    sunroofTintingProductItemId: '',
                    ceramicCoatingProductItemId: '',
                    dashcamProductItemId: packageByPackage.attribute.selection.dashcam,
                    ppfProductItemId: packageByPackage.attribute.selection.ppf,
                    newCarInspectionProductId: packageByPackage.attribute.selection.new_car_inspection,
                  })

                  setPriceValues({
                    priceProduct:
                      packageByPackage.price_product[`${carOrigin === 'korea' ? 'korea' : 'other'}_${carType}`],
                    priceDiscount:
                      packageByPackage.price_discount[`${carOrigin === 'korea' ? 'korea' : 'other'}_${carType}`],
                    pricePayout:
                      packageByPackage.price_payout[`${carOrigin === 'korea' ? 'korea' : 'other'}_${carType}`],
                  })
                } else {
                  setSeletedValeus({
                    packageId: '',
                  })
                  setPriceValues({
                    priceExtra: 0,
                    priceDiscount: 0,
                    pricePayout: 0,
                  })
                }
              }}
            ></Select>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
