import React from 'react'

import { PriceObject } from '../../../../../stores/calculator/type'
import CalculatorRow, { RowType } from '../CalculatorRow'

export interface FinalPriceRowsProps {
  materialPrice: Partial<PriceObject>
  isAvalableSentQuote: boolean
  sentAt: {
    price: boolean
    premium: boolean
    package: boolean
    lowest: boolean
  }
  isOrder: boolean
}

const App: React.FunctionComponent<FinalPriceRowsProps> = ({
  materialPrice,
  sentAt,
  isOrder,
  isAvalableSentQuote,
}: FinalPriceRowsProps) => {
  return (
    <>
      {/* 재료비 */}
      <CalculatorRow
        rowType={RowType.PaymentRow}
        rowTitle={'재료비 (카라모라 수수료)'}
        prices={materialPrice}
        sentAt={sentAt}
        isOrder={isOrder}
        isAvalableSentQuote={isAvalableSentQuote}
      />
    </>
  )
}

export default React.memo(App)
