import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import React from 'react'

import useRootData from '../../../../hooks/useRootData'
import { Remark } from '../../../../stores/quote'
import styles from './index.module.scss'

interface PropsRemark {
  idx: number
  isOrder: boolean
}

const App: React.FunctionComponent<PropsRemark> = ({ idx, isOrder }: PropsRemark) => {
  const { quoteObject, orderObject, writeRemarkQuote, writeRemarkOrder } = useRootData(
    ({ quoteStore, orderStore }) => ({
      quoteObject: quoteStore.quoteObject.get(),
      orderObject: orderStore.orderObject.get(),
      writeRemarkQuote: quoteStore.writeRemarkQuote,
      writeRemarkOrder: orderStore.writeRemarkOrder,
    }),
  )

  const item = isOrder ? orderObject : quoteObject.quote_items[idx]

  if (!item) return null

  const writeRemarkHandler = (remark: Remark) => {
    if (isOrder) {
      writeRemarkOrder(remark)
    } else {
      writeRemarkQuote(idx, remark)
    }
  }

  return (
    <Table size="small" aria-label="a dense table">
      <TableHead className={styles.tableHeadContainer}>
        <TableRow className={styles.row}>
          <TableCell className={styles.col}>고객 전송 비고란</TableCell>
          <TableCell className={styles.col}>매장 전송 비고란</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <TextField
              id="outlined-multiline-static"
              label="Multiline"
              multiline
              rows={4}
              defaultValue={(item.remark && item.remark.customer_remark) || ''}
              variant="outlined"
              onChange={(event): void => {
                writeRemarkHandler({
                  customer_remark: event.target.value,
                })
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              id="outlined-multiline-static"
              label="Multiline"
              multiline
              rows={4}
              defaultValue={(item.remark && item.remark.date_time_remark) || ''}
              variant="outlined"
              onChange={(event): void =>
                writeRemarkHandler({
                  date_time_remark: event.target.value,
                })
              }
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default React.memo(App)
