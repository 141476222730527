import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import Select from '../../../components/atoms/Select'
import useRootData from '../../../hooks/useRootData'
import { LEAD_COL } from '../../../stores/lead/type'
import { getLeadColValueByKey } from '../../../utils/utility'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const {
    accountId,
    // values
    selectedLead,
    selectedChatAssignee,

    // options
    chatAsigneeArray,

    // setter
    changeSelectedChatAssignee,
  } = useRootData(({ authStore, leadStore }) => ({
    accountId: authStore.accountId.get(),
    selectedLead: leadStore.selectedLead.get(),
    selectedChatAssignee: leadStore.selectedChatAssignee.get(),
    chatAsigneeArray: leadStore.chatAsigneeArray,
    changeSelectedChatAssignee: leadStore.changeSelectedChatAssignee,
  }))

  return (
    <TableContainer component={Paper} className={styles.chatContainer}>
      <Table className={styles.chatTable} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {[LEAD_COL.CHAT_ID, LEAD_COL.CHAT_ASSIGNEE, LEAD_COL.ZENDESK_CHAT_NAME].map((col, index) => {
              return <TableCell key={index}>{col}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{getLeadColValueByKey(selectedLead, LEAD_COL.CHAT_ID)}</TableCell>
            <TableCell>
              <div style={{ display: 'flex' }}>
                <Select
                  value={selectedChatAssignee ? selectedChatAssignee.id : ''}
                  options={chatAsigneeArray}
                  changeHandler={(event): void => changeSelectedChatAssignee(event.target.value as string)}
                />
                <Button variant="contained" onClick={() => changeSelectedChatAssignee(accountId)}>
                  {'본인'}
                </Button>
              </div>
            </TableCell>
            <TableCell>
              {getLeadColValueByKey(selectedLead, LEAD_COL.ZENDESK_CHAT_NAME)}
              <Button
                style={{ marginLeft: '5px' }}
                onClick={() =>
                  window.open(
                    `http://chatbotv2.caramora.com:8088/test/conversionPredictor?displayName=${getLeadColValueByKey(
                      selectedLead,
                      LEAD_COL.ZENDESK_CHAT_NAME,
                    )}`,
                  )
                }
                variant={'outlined'}
              >
                Prediction
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(App)
