import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'
import PhoneNumber from 'awesome-phonenumber'
import React, { useEffect, useState } from 'react'

import client from '../../../../../utils/graphql'
import { autoHypenPhoneNumber } from '../../../../../utils/utility'
import { GET_STORE_DETAIL } from '../../../../StoreDetail/query'
import styles from './index.module.scss'

interface PropsStoreInfoModal {
  hideModalHandler: () => void
  storeId: string
}

const App: React.FunctionComponent<PropsStoreInfoModal> = ({ hideModalHandler, storeId }: PropsStoreInfoModal) => {
  const [storeObject, setStoreObject] = useState(null)

  useEffect(() => {
    ;(async () => {
      const {
        data: { store },
      } = await client.query({
        query: GET_STORE_DETAIL,
        variables: {
          id: storeId,
        },
      })

      setStoreObject(store[0])
    })()
  }, [])

  return (
    <div className={styles.container}>
      <h3 style={{ marginTop: '20px' }}>매장 간략 정보</h3>
      {storeObject && (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  매장명
                </TableCell>
                <TableCell>{storeObject.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  사업자명
                </TableCell>
                <TableCell>{storeObject.name_business}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  이메일
                </TableCell>
                <TableCell>{storeObject.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  지역
                </TableCell>
                {
                  // eslint-disable-next-line camelcase
                  <TableCell>{storeObject.addressSigunguByAddressSigungu?.address_sido.name}</TableCell>
                }
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  매장 주소
                </TableCell>
                <TableCell>{storeObject.address_detail}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  매장 전화번호
                </TableCell>
                <TableCell>
                  {autoHypenPhoneNumber(new PhoneNumber(storeObject.phone_business).getNumber('national'))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <IconButton
        style={{ marginTop: '10px', display: 'flex' }}
        size="small"
        color="primary"
        onClick={() => {
          window.open(`/store-detail?id=${storeId}`)
        }}
      >
        <OpenInNew />
        <div>더 자세히 보기</div>
      </IconButton>

      <div className={styles.btnArea}>
        <Button variant="contained" color="secondary" onClick={hideModalHandler}>
          닫기
        </Button>
      </div>
    </div>
  )
}

export default React.memo(App)
