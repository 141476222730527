import appStore from '../app'
import calculatorStore, { initPriceObject } from '../calculator'
import { ProductCategory, ProductDisplayNames } from '../calculator/type'
import quoteStore from '.'

export default (): boolean => {
  const quoteObject = quoteStore.quoteObject.get()

  if (!quoteObject) return true

  let error = false

  const { calculatedPrices, productItemArrays } = calculatorStore
  // calculatorStore 각종 array들의 0번째 인덱스는 order를 위한 자리.
  const selectedProductIds = calculatorStore.selectedProductIds.get()
  const customProducts = calculatorStore.customProducts.get()

  const selectedPackageObject = calculatorStore.selectedPackageObject.get()
  const customPackageId = calculatorStore.selectProductsContent.get().custom_package[0].id
  const selectedStore = calculatorStore.selectedStore.get()
  const extraDiscountPrice = calculatorStore.extraDiscountPrice.get()
  const extraPrice = calculatorStore.extraPrice.get()
  const extraDiscountPayoutPrice = calculatorStore.extraDiscountPayoutPrice.get()
  const extraPayoutPrice = calculatorStore.extraPayoutPrice.get()

  const tempQuoteObject = []

  for (let index = 0; index < quoteObject.quote_items.length; index += 1) {
    const quoteItem = quoteObject.quote_items[index]
    let productItem = {}

    const newIndex = index + 1

    if (selectedPackageObject[newIndex]) {
      const errors = Object.values(ProductCategory)
        .filter(
          (key) =>
            ['side_tinting_product_items', 'back_tinting_product_items', '_film_remove_product_items'].indexOf(key) ===
            -1,
        )
        .map((key) => {
          if (productItemArrays[key][newIndex].length > 1 && !selectedProductIds[newIndex][key]) {
            return `${ProductDisplayNames[key]} 제품을 골라주세요!`
          }

          return null
        })
        .filter((item) => item !== null)

      if (errors.length) {
        appStore.changeSnackbarAlertContent({
          severity: 'error',
          content: errors[0],
        })

        error = true

        return error
      }
    }

    const tempTintingObject = {
      tinting: {
        ...(selectedProductIds[newIndex][ProductCategory.FrontTintingProduct]
          ? { front: selectedProductIds[newIndex][ProductCategory.FrontTintingProduct] }
          : {}),
        ...(selectedProductIds[newIndex][ProductCategory.SidebackTintingProduct]
          ? { sideback: selectedProductIds[newIndex][ProductCategory.SidebackTintingProduct] }
          : {}),
        ...(selectedProductIds[newIndex][ProductCategory.SunroofTintingProduct]
          ? { sunroof: selectedProductIds[newIndex][ProductCategory.SunroofTintingProduct] }
          : {}),
      },
    }

    const otherProductObject = Object.assign(
      {},
      ...Object.values(ProductCategory)
        .filter(
          (key) =>
            ['front_tinting_product_items', 'sideback_tinting_product_items', 'sunroof_tinting_product_items'].indexOf(
              key,
            ) === -1,
        )
        .map((key) =>
          selectedProductIds[newIndex][key]
            ? { [key.replace(/_product_items$/g, '')]: selectedProductIds[newIndex][key] }
            : {},
        ),
    )

    const customProductObject = {
      customProducts: [...customProducts[newIndex]],
    }

    // package
    if (selectedPackageObject[newIndex]) {
      const tempProductItem = {
        package: {
          [selectedPackageObject[newIndex].id]: {
            ...tempTintingObject,
            ...otherProductObject,
          },
        },
        ...customProductObject,
      }

      productItem = tempProductItem
    }
    // custom package
    else if (
      Object.values(ProductCategory)
        .filter(
          (key) =>
            ['front_tinting_product_items', 'sideback_tinting_product_items', 'sunroof_tinting_product_items'].indexOf(
              key,
            ) === -1,
        )
        .reduce((isSelected: boolean, key: ProductCategory): boolean => {
          return isSelected || !!selectedProductIds[newIndex][key]
        }, false) ||
      !!customProducts[newIndex]
    ) {
      const tempProductItem = {
        package: {
          [customPackageId]: {
            ...tempTintingObject,
            ...otherProductObject,
          },
        },
        ...customProductObject,
      }

      productItem = tempProductItem
    }
    // tinting
    else {
      const tempProductItem = {
        ...tempTintingObject,
      }

      productItem = tempProductItem
    }

    tempQuoteObject.push({
      ...quoteItem,
      store: selectedStore[newIndex] ? selectedStore[newIndex].id : null,
      price_discount: extraDiscountPrice[newIndex] || { ...initPriceObject },
      price_extra: extraPrice[newIndex] || { ...initPriceObject },
      product_item: productItem,
      price_product: {
        price: (calculatedPrices[newIndex] && calculatedPrices[newIndex].totalPrice.price) || 0,
        premium: (calculatedPrices[newIndex] && calculatedPrices[newIndex].totalPrice.price) || 0,
        package: (calculatedPrices[newIndex] && calculatedPrices[newIndex].totalPrice.price) || 0,
        lowest: (calculatedPrices[newIndex] && calculatedPrices[newIndex].totalPrice.price) || 0,
      },
      price_total: (calculatedPrices[newIndex] && calculatedPrices[newIndex].totalPrice) || { ...initPriceObject },
      price_final: (calculatedPrices[newIndex] && calculatedPrices[newIndex].finalPrice) || { ...initPriceObject },
      basic_price_payout: (calculatedPrices[newIndex] && calculatedPrices[newIndex].basicPayoutPrice) || {
        ...initPriceObject,
      },
      price_payout_extra: extraPayoutPrice[newIndex] || { ...initPriceObject },
      price_payout_discount: extraDiscountPayoutPrice[newIndex] || { ...initPriceObject },
      price_payout: (calculatedPrices[newIndex] && calculatedPrices[newIndex].finalPayoutPrice) || {
        ...initPriceObject,
      },
    })
  }

  quoteStore.quoteObject.set({
    ...quoteObject,
    quote_items: tempQuoteObject,
  })

  return error
}
