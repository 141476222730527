// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___mZD-o {\n  padding: 20px;\n  margin-bottom: 40px; }\n  .index-module__container___mZD-o .index-module__inputBox___jmZBb {\n    margin-bottom: 35px;\n    padding: 10px;\n    border: 1px dotted black; }\n    .index-module__container___mZD-o .index-module__inputBox___jmZBb textarea {\n      width: 50%;\n      height: 200px;\n      margin-bottom: 5px; }\n    .index-module__container___mZD-o .index-module__inputBox___jmZBb input {\n      width: 200px;\n      height: 25px;\n      margin-bottom: 5px; }\n    .index-module__container___mZD-o .index-module__inputBox___jmZBb .index-module__largeInput___337Hf {\n      width: 400px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/GalleryDisplayDetail/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAKI,mBAAmB;IACnB,aAAa;IACb,wBAAwB,EAAA;IAP5B;MAUM,UAAU;MACV,aAAa;MACb,kBAAkB,EAAA;IAZxB;MAgBM,YAAY;MACZ,YAAY;MACZ,kBAAkB,EAAA;IAlBxB;MAsBM,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  margin-bottom: 40px;\n\n  .inputBox {\n    margin-bottom: 35px;\n    padding: 10px;\n    border: 1px dotted black;\n\n    textarea {\n      width: 50%;\n      height: 200px;\n      margin-bottom: 5px;\n    }\n\n    input {\n      width: 200px;\n      height: 25px;\n      margin-bottom: 5px;\n    }\n\n    .largeInput {\n      width: 400px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___mZD-o",
	"inputBox": "index-module__inputBox___jmZBb",
	"largeInput": "index-module__largeInput___337Hf"
};
module.exports = exports;
