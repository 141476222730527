import { Order, Package, Quote_Item as QuoteItem, Store } from '../../assets/graphql/graphql'
import { getCarOriginTypeKey, matchingProductItem } from '../../utils/utility'
import leadStore from '../lead'
import orderStore from '../order'
import quoteStore from '../quote'
import calculatorStore, { initPriceObject, initSelectedProductIds, UPPER_LIMIT_CNT } from '.'

export default (options?: {
  selectedStore?: boolean
  selectedPackageObject?: boolean
  selectedRecentPackageObject?: boolean
  selectedProductIds?: boolean
  customProducts?: boolean
  floorPrice?: boolean
  ceilPrice?: boolean
  extraDiscountPrice?: boolean
  extraPrice?: boolean
  extraDiscountPayoutPrice?: boolean
  extraPayoutPrice?: boolean
  prevFinalPrice?: boolean
  prevFinalPayoutPrice?: boolean
}): void => {
  const selectOptionsContent = calculatorStore.selectOptionsContent.get()
  const selectProductsContent = calculatorStore.selectProductsContent.get()

  const selectedStore = Array(UPPER_LIMIT_CNT).fill(null)
  const selectedPackageObject = Array(UPPER_LIMIT_CNT).fill(null)
  const selectedRecentPackageObject = Array(UPPER_LIMIT_CNT).fill(null)

  const selectedProductIds = Array(UPPER_LIMIT_CNT).fill({ ...initSelectedProductIds })
  const customProducts = Array(UPPER_LIMIT_CNT).fill([])

  const floorPrice = Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })
  const ceilPrice = Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })
  const extraDiscountPrice = Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })
  const extraPrice = Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })
  const extraDiscountPayoutPrice = Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })
  const extraPayoutPrice = Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })
  const prevFinalPrice = Array(UPPER_LIMIT_CNT).fill(null)
  const prevFinalPayoutPrice = Array(UPPER_LIMIT_CNT).fill(null)

  const orderObject = orderStore.orderObject.get()
  const quoteObject = quoteStore.quoteObject.get()

  const setStoreValues = (object: Order | QuoteItem, index: number) => {
    if (!object) return

    const isOrder = index === 0

    const {
      store,
      package_detail: packageDetail,
      product_item_detail: productItemDetail,
      product_item: productItem,
      price_discount: priceDiscount,
      price_extra: priceExtra,
      price_payout_discount: pricePayoutDiscount,
      price_payout_extra: pricePayoutExtra,
      price_final: priceFinal,
      price_payout: pricePayout,
    } = object

    if (store && selectOptionsContent)
      selectedStore[index] = selectOptionsContent.stores.filter((item) => item.id === store)[0] as Store

    /* eslint-disable max-depth */

    if (packageDetail) {
      const packageObject = packageDetail.length > 0 ? packageDetail[0] : null
      if (packageObject && selectProductsContent) {
        const filterResult = selectProductsContent.packages.filter((item) => item.id === packageObject.id)[0] as Package
        if (filterResult) {
          selectedPackageObject[index] = filterResult
          selectedRecentPackageObject[index] = filterResult

          const carKey = getCarOriginTypeKey(leadStore.selectedCar.get())
          const priceKey = isOrder ? 'price' : 'package'

          /* eslint-disable camelcase */
          if (filterResult.price_discount?.floor_price?.[carKey]) {
            floorPrice[index] = {
              ...floorPrice[index],
              [priceKey]: filterResult.price_discount.floor_price[carKey],
            }
          } else {
            floorPrice[index] = {
              ...floorPrice[index],
              [priceKey]: 0,
            }
          }

          if (filterResult?.price_discount?.ceil_price?.[carKey]) {
            ceilPrice[index] = {
              ...ceilPrice[index],
              [priceKey]: filterResult.price_discount.ceil_price[carKey],
            }
          } else {
            ceilPrice[index] = {
              ...ceilPrice[index],
              [priceKey]: 0,
            }
          }
          /* eslint-disable camelcase */
        }
      }
      /* eslint-disable max-depth */
    }

    if (productItemDetail) selectedProductIds[index] = matchingProductItem(productItemDetail)

    if (productItem?.customProducts) customProducts[index] = productItem.customProducts

    if (priceDiscount) {
      if (isOrder) {
        extraDiscountPrice[index] = {
          ...extraDiscountPrice[index],
          price: priceDiscount,
        }
      } else if (Object.keys(priceDiscount).length) {
        extraDiscountPrice[index] = priceDiscount
      }
    }

    if (priceExtra) {
      if (isOrder) {
        extraPrice[index] = {
          ...extraPrice[index],
          price: priceExtra,
        }
      } else if (Object.keys(priceExtra).length) {
        extraPrice[index] = priceExtra
      }
    }

    if (pricePayoutDiscount) {
      if (isOrder) {
        extraDiscountPayoutPrice[index] = {
          ...extraDiscountPayoutPrice[index],
          price: pricePayoutDiscount,
        }
      } else if (Object.keys(pricePayoutDiscount).length) {
        extraDiscountPayoutPrice[index] = pricePayoutDiscount
      }
    }

    if (pricePayoutExtra) {
      if (isOrder) {
        extraPayoutPrice[index] = {
          ...extraPayoutPrice[index],
          price: pricePayoutExtra,
        }
      } else if (Object.keys(pricePayoutExtra).length) {
        extraPayoutPrice[index] = pricePayoutExtra
      }
    }

    if (priceFinal) {
      if (isOrder)
        prevFinalPrice[index] = { price: priceFinal, premium: priceFinal, package: priceFinal, lowest: priceFinal }
      else prevFinalPrice[index] = priceFinal
    }

    if (pricePayout) {
      if (isOrder)
        prevFinalPayoutPrice[index] = {
          price: pricePayout,
          premium: pricePayout,
          package: pricePayout,
          lowest: pricePayout,
        }
      else prevFinalPayoutPrice[index] = pricePayout
    }
  }

  // fill calculator from order
  if (orderObject) setStoreValues(orderObject, 0)

  // fill calculator from quote
  if (quoteObject) quoteObject.quote_items.forEach((quoteItem, index) => setStoreValues(quoteItem, index + 1))

  if (options) {
    calculatorStore.setStore({
      ...(options.selectedStore ? { selectedStore } : {}),
      ...(options.selectedPackageObject ? { selectedPackageObject } : {}),
      ...(options.selectedRecentPackageObject ? { selectedRecentPackageObject } : {}),
      ...(options.selectedProductIds ? { selectedProductIds } : {}),
      ...(options.customProducts ? { customProducts } : {}),
      ...(options.floorPrice ? { floorPrice } : {}),
      ...(options.ceilPrice ? { ceilPrice } : {}),
      ...(options.extraDiscountPrice ? { extraDiscountPrice } : {}),
      ...(options.extraPrice ? { extraPrice } : {}),
      ...(options.extraDiscountPayoutPrice ? { extraDiscountPayoutPrice } : {}),
      ...(options.extraPayoutPrice ? { extraPayoutPrice } : {}),
      ...(options.prevFinalPrice ? { prevFinalPrice } : {}),
      ...(options.prevFinalPayoutPrice ? { prevFinalPayoutPrice } : {}),
    })
  } else {
    calculatorStore.setStore({
      selectedStore,
      selectedPackageObject,
      selectedRecentPackageObject,
      selectedProductIds,
      customProducts,
      floorPrice,
      ceilPrice,
      extraDiscountPrice,
      extraPrice,
      extraDiscountPayoutPrice,
      extraPayoutPrice,
      prevFinalPrice,
      prevFinalPayoutPrice,
    })
  }
}
