// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3BXtY {\n  padding: 20px;\n  margin-bottom: 40px; }\n  .index-module__container___3BXtY .index-module__packageName___3L2aw {\n    font-size: 16px;\n    margin-bottom: 10px; }\n  .index-module__container___3BXtY .index-module__shortcutBox___2Kw6Z {\n    border: 1px solid #000000; }\n    .index-module__container___3BXtY .index-module__shortcutBox___2Kw6Z .index-module__title___1jUME {\n      color: purple; }\n    .index-module__container___3BXtY .index-module__shortcutBox___2Kw6Z .index-module__status___2j97T {\n      display: flex;\n      flex-wrap: wrap; }\n    .index-module__container___3BXtY .index-module__shortcutBox___2Kw6Z .index-module__address___iAQ1V {\n      display: flex;\n      flex-wrap: wrap; }\n  .index-module__container___3BXtY .index-module__button___2-r7_ {\n    float: right;\n    margin-bottom: 20px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/StoreAndPackageDetail/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAKI,eAAe;IACf,mBAAmB,EAAA;EANvB;IAUI,yBAAyB,EAAA;IAV7B;MAYM,aAAa,EAAA;IAZnB;MAeM,aAAa;MACb,eAAe,EAAA;IAhBrB;MAmBM,aAAa;MACb,eAAe,EAAA;EApBrB;IAyBI,YAAY;IACZ,mBAAmB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  margin-bottom: 40px;\n\n  .packageName {\n    font-size: 16px;\n    margin-bottom: 10px;\n  }\n\n  .shortcutBox {\n    border: 1px solid #000000;\n    .title {\n      color: purple;\n    }\n    .status {\n      display: flex;\n      flex-wrap: wrap;\n    }\n    .address {\n      display: flex;\n      flex-wrap: wrap;\n    }\n  }\n\n  .button {\n    float: right;\n    margin-bottom: 20px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3BXtY",
	"packageName": "index-module__packageName___3L2aw",
	"shortcutBox": "index-module__shortcutBox___2Kw6Z",
	"title": "index-module__title___1jUME",
	"status": "index-module__status___2j97T",
	"address": "index-module__address___iAQ1V",
	"button": "index-module__button___2-r7_"
};
module.exports = exports;
