import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import {
  Car,
  Car_Maker as CarMaker,
  Car_Type as CarType,
  Car_Type_Enum as CarTypeEnum,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const GET_CAR = gql`
  query GET_CAR($id: uuid) {
    car(where: { id: { _eq: $id } }) {
      id
      model
      row
      type
      prefix
      postfix
      year
      maker
      llumar_car_type
    }
  }
`

const GET_CAR_MAKERS_AND_CAR_TYPES = gql`
  query GET_CAR_MAKERS_AND_CAR_TYPES($id: uuid) {
    car_maker {
      id
      name_kr
    }
    car_type {
      value
      description
    }
  }
`

const UPSERT_CAR = gql`
  mutation UPDATE_CAR($carObject: [car_insert_input!]!) {
    insert_car(
      objects: $carObject
      on_conflict: { constraint: car_pkey, update_columns: [model, row, type, prefix, postfix, year, maker, account] }
    ) {
      affected_rows
    }
  }
`

const INSERT_CAR = gql`
  mutation INSERT_CAR($carObject: [car_insert_input!]!) {
    insert_car(objects: $carObject) {
      returning {
        id
      }
    }
  }
`

const App: React.FunctionComponent = () => {
  const { accountId, changeMainMenu } = useRootData(({ appStore, authStore }) => ({
    accountId: authStore.accountId.get(),
    changeMainMenu: appStore.changeMainMenu,
  }))

  const { id } = parseQueryString()

  const llumarCarTypes = ['sedan', 'suv', 'van', 'onerowtruck', 'tworowtruck']

  const [carObject, setCarObject] = useState<Partial<Car>>(
    id
      ? null
      : {
        model: '',
        row: -1,
        type: CarTypeEnum.Coupe,
        prefix: '',
        postfix: '',
        year: '[1900, 3000)',
        maker: '',
        llumar_car_type: 'sedan',
      },
  )
  const [editCarClicked, setEditCarClicked] = useState(!id)

  const [getData, { data: carData }] = useLazyQuery(GET_CAR, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })

  const [getOptions, { data: optionsData, loading, error }] = useLazyQuery(GET_CAR_MAKERS_AND_CAR_TYPES, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (id) {
      getData()
    }
    getOptions()
  }, [])

  useEffect(() => {
    if (carData) {
      if (id) {
        const car = carData.car[0]

        // eslint-disable-next-line no-underscore-dangle
        delete car.__typename
        setCarObject(car as typeof carObject)
      }
    }
  }, [carData])

  if (loading || !carObject || (id && !carData) || !optionsData)
    return (
      <div className="body">
        <div className={styles.container}>...로딩중...ლ( ╹ ◡ ╹ ლ)</div>
      </div>
    )

  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>자동차 세부 정보</div>
        <button
          onClick={async (): Promise<void> => {
            if (editCarClicked) {
              if (id) {
                await client.mutate({
                  mutation: UPSERT_CAR,
                  variables: {
                    carObject: {
                      ...carObject,
                      account: accountId,
                    },
                  },
                })
                getData()
              } else {
                await client.mutate({
                  mutation: INSERT_CAR,
                  variables: {
                    carObject: {
                      ...carObject,
                      account: accountId,
                    },
                  },
                })

                changeMainMenu('/car?alert')
              }
            }

            setEditCarClicked(!editCarClicked)
          }}
        >
          {editCarClicked ? '저장하기' : '수정하기'}
        </button>

        <table>
          <tbody>
            <tr>
              <td>제조사</td>
              <td>
                <select
                  disabled={!editCarClicked}
                  value={carObject.maker}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      maker: e.target.value,
                    })
                  }}
                >
                  {[{ id: '', name_kr: '선택 안함' }, ...optionsData.car_maker].map(
                    (maker: CarMaker, index: React.Key) => {
                      return (
                        <option key={index} value={maker.id}>
                          {maker.name_kr}
                        </option>
                      )
                    },
                  )}
                </select>
              </td>
            </tr>
            <tr>
              <td>prefix</td>
              <td>
                <input
                  disabled={!editCarClicked}
                  defaultValue={carObject.prefix}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      prefix: e.target.value,
                    })
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>model</td>
              <td>
                <input
                  disabled={!editCarClicked}
                  defaultValue={carObject.model}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      model: e.target.value,
                    })
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>postfix</td>
              <td>
                <input
                  disabled={!editCarClicked}
                  defaultValue={carObject.postfix}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      postfix: e.target.value,
                    })
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>열</td>
              <td>
                <input
                  disabled={!editCarClicked}
                  defaultValue={carObject.row}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      row: parseInt(e.target.value, 10),
                    })
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>타입</td>
              <td>
                <select
                  disabled={!editCarClicked}
                  value={carObject.type}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      type: e.target.value as CarTypeEnum,
                    })
                  }}
                >
                  {optionsData.car_type.map((type: CarType, index: React.Key) => {
                    return (
                      <option key={index} value={type.value}>
                        {type.description}
                      </option>
                    )
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>루마고 타입</td>
              <td>
                <select
                  disabled={!editCarClicked}
                  value={carObject.llumar_car_type}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      llumar_car_type: e.target.value,
                    })
                  }}
                >
                  {llumarCarTypes.map((type: string, index: React.Key) => {
                    return (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    )
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>연식</td>
              <td>
                <input
                  disabled={!editCarClicked}
                  defaultValue={carObject.year}
                  onChange={(e): void => {
                    setCarObject({
                      ...carObject,
                      year: e.target.value,
                    })
                  }}
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
