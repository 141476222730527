/* eslint-disable camelcase */
import { Product_Type_Enum as ProductTypeEnum } from '../../assets/graphql/graphql'

export interface TitingAttr {
  part: string
  type: string
  brand: string
  density: string
  car_type: string
  car_origin: string
  product_name: string
}

export interface DashcamAttr {
  type: string
  brand: string
  channel: string
  storage: string
  product_name: string
}

export interface CeramicCoatingAttr {
  type: string
  brand: string
  density: string
  car_type: string
  car_origin: string
  product_name: string
}

export interface PpfAttr {
  type: string
  brand: string
  product_name: string
}

export interface UndercoatingAttr {
  type: string
}

export interface SoundProofingAttr {
  part: string
}

export interface FilmRemoveAttr {
  part: string
  type: string
  brand: string
  car_type: string
  car_origin: string
  product_name: string
}

export interface MappingAttr {
  [key: string]: unknown
  [ProductTypeEnum.AddOn]: null
  [ProductTypeEnum.AfterBlow]: null
  [ProductTypeEnum.AuxiliaryBattery]: null
  [ProductTypeEnum.CamoKit]: null
  [ProductTypeEnum.CeramicCoating]: CeramicCoatingAttr
  [ProductTypeEnum.Connected]: null
  [ProductTypeEnum.Dashcam]: DashcamAttr
  [ProductTypeEnum.DeliveryFee]: null
  [ProductTypeEnum.FilmRemove]: FilmRemoveAttr
  [ProductTypeEnum.Hipass]: null
  [ProductTypeEnum.LeatherCoating]: null
  [ProductTypeEnum.NewCarInspection]: null
  [ProductTypeEnum.Ppf]: PpfAttr
  [ProductTypeEnum.Soundproofing]: SoundProofingAttr
  [ProductTypeEnum.Tinting]: TitingAttr
  [ProductTypeEnum.Undercoating]: UndercoatingAttr
}

export const initAttrObject: MappingAttr = {
  [ProductTypeEnum.AddOn]: null,
  [ProductTypeEnum.AfterBlow]: null,
  [ProductTypeEnum.AuxiliaryBattery]: null,
  [ProductTypeEnum.CamoKit]: null,
  [ProductTypeEnum.CeramicCoating]: {
    type: '',
    brand: '',
    density: '',
    car_type: '',
    car_origin: '',
    product_name: '',
  },
  [ProductTypeEnum.Connected]: null,
  [ProductTypeEnum.Dashcam]: {
    type: '',
    brand: '',
    channel: '',
    storage: '',
    product_name: '',
  },
  [ProductTypeEnum.DeliveryFee]: null,
  [ProductTypeEnum.FilmRemove]: {
    part: '',
    type: '',
    brand: '',
    car_type: '',
    car_origin: '',
    product_name: '',
  },
  [ProductTypeEnum.Hipass]: null,
  [ProductTypeEnum.LeatherCoating]: null,
  [ProductTypeEnum.NewCarInspection]: null,
  [ProductTypeEnum.Ppf]: {
    type: '',
    brand: '',
    product_name: '',
  },
  [ProductTypeEnum.Soundproofing]: {
    part: '',
  },
  [ProductTypeEnum.Tinting]: {
    part: '',
    type: '',
    brand: '',
    density: '',
    car_type: '',
    car_origin: '',
    product_name: '',
  },
  [ProductTypeEnum.Undercoating]: {
    type: '',
  },
}
