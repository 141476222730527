import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import {
  MenuItem,
  Paper,
  Select as MuiSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Select, { OptionsType } from 'react-select'

/* eslint-disable camelcase */
import {
  Get_Gallery_By_StoreQuery as GalleryByStoreType,
  Get_Gallery_By_StoreQueryVariables as GalleryByStoreVariableType,
  Get_Store_And_SolesQuery as GetStoreAndSolesQuery,
} from '../../assets/graphql/graphql'
/* eslint-enable camelcase */
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import styles from './index.module.scss'
import {
  GET_GALLERY_BY_STORE,
  GET_STORE_AND_SOLES,
  UPDATE_STORE_OP_MEMO,
  UPDATE_STORE_OPERATION,
  UPSERT_STORE_SOLE,
} from './query'

interface MemoEditBoxProps {
  storeItem: GalleryByStoreType['store'][0]
  upsertMemo: (memo: string, id: string) => Promise<void>
}

interface SoleEditSelectProps {
  value: string
  options: GetStoreAndSolesQuery['sole']
  onChange: (
    event: React.ChangeEvent<{
      name?: string
      value: string
    }>,
  ) => void
}

const MemoEditBox: React.FunctionComponent<MemoEditBoxProps> = ({ storeItem, upsertMemo }: MemoEditBoxProps) => {
  const [memo, changeMemo] = useState('')
  const [editDisabled, setEditDisabled] = useState(true)

  useEffect(() => {
    changeMemo(storeItem.operation_memo || '')
  }, [storeItem])

  return (
    <>
      <textarea
        disabled={editDisabled}
        onChange={(e) => {
          changeMemo(e.currentTarget.value)
        }}
        defaultValue={storeItem.operation_memo || ''}
      />
      <button
        onClick={() => {
          if (editDisabled) {
            setEditDisabled(false)
          } else {
            upsertMemo(memo, storeItem.id)
            setEditDisabled(true)
          }
        }}
      >
        {editDisabled ? '수정' : '저장'}
      </button>
    </>
  )
}

const SoleEditSelect: React.FunctionComponent<SoleEditSelectProps> = ({
  value,
  options,
  onChange,
}: SoleEditSelectProps) => {
  return (
    <MuiSelect value={value} onChange={onChange}>
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {options.map((sole: GetStoreAndSolesQuery['sole'][0], index) => (
        <MenuItem key={index} value={sole.id}>
          {sole.name}
        </MenuItem>
      ))}
    </MuiSelect>
  )
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeLoadingSpinnerVisibility, changeSnackbarAlertContent } = useRootData(
    ({ appStore }) => ({
      changeMainMenu: appStore.changeMainMenu,
      changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
      changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    }),
  )

  const [galleryContentState, setGalleryContentStateArray] = useState<GalleryByStoreType>(null)
  const [soleSearch, setSoleSearch] = useState<OptionsType<{ value: string; label: string }>>(null)
  const [storeSearch, setStoreSearch] = useState<OptionsType<{ value: string; label: string }>>(null)
  const [pageSize, setPageSize] = useState(100)
  const [page, setPage] = useState(0)
  const [sortMode, setSortMode] = useState<[string, 'asc_nulls_first' | 'desc_nulls_last']>([
    'created_at',
    'desc_nulls_last',
  ])

  const toSortQueryString = () => {
    if (sortMode[0] === 'galleryCount') {
      return `{ galleries_aggregate: { count: ${sortMode[1]} } }`
    }

    if (sortMode[0] === 'maxCreatedAt') {
      return `{ galleries_aggregate: { max: { created_at: ${sortMode[1]} } } }`
    }

    return `{ ${sortMode[0]} : ${sortMode[1]} }`
  }

  const toFilterQueryString = () => {
    let queryString = ''
    if (soleSearch && soleSearch.length) {
      queryString += '{ _or: ['
      soleSearch.forEach((sole) => {
        if (sole.value) {
          queryString += `{ store_and_soles: { soleBySole: { id: { _eq: "${sole.value}" } } } }`
        } else {
          queryString += `{_not: {store_and_soles: {}}}`
        }
      })
      queryString += '] }'
    }

    if (storeSearch && storeSearch.length) {
      queryString += '{ _or: ['
      storeSearch.forEach((store) => {
        queryString += `{ id: { _eq: "${store.value}" } }`
      })
      queryString += '] }'
    }

    return queryString
  }

  const [getGalleryByStore, { data: galleryContent, loading }] = useLazyQuery<
    GalleryByStoreType,
    GalleryByStoreVariableType
  >(GET_GALLERY_BY_STORE(toSortQueryString(), toFilterQueryString()), {
    variables: {
      pageSize,
      pageOffset: page * pageSize,
    },
    fetchPolicy: 'no-cache',
  })

  const { data: storeAndSoleData } = useQuery<GetStoreAndSolesQuery>(GET_STORE_AND_SOLES, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getGalleryByStore({
      variables: {
        pageSize,
        pageOffset: page * pageSize,
      },
    })
  }, [pageSize, page])

  useEffect(() => {
    if (loading) {
      changeLoadingSpinnerVisibility(true)
    } else {
      if (galleryContent) {
        setGalleryContentStateArray(galleryContent)
      }
      changeLoadingSpinnerVisibility(false)
    }
  }, [loading])

  const upsertMemo = async (memo: string, id: string): Promise<void> => {
    try {
      await client.mutate({
        mutation: UPDATE_STORE_OP_MEMO,
        fetchPolicy: 'no-cache',
        variables: {
          id,
          memo,
        },
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  /*
   * this stores the previous value of the date input,
   * so that if the date change gets cancelled the input can
   * get set to the previous value.
   */
  let prevDate = ''

  return (
    <div className="body">
      <div className={styles.container}>
        <Select
          placeholder={'총판 (다중선택)...'}
          value={soleSearch}
          clearValue={() => setSoleSearch([])}
          onChange={setSoleSearch}
          isClearable
          isMulti
          options={
            storeAndSoleData
              ? storeAndSoleData.sole
                  .map((sole: GetStoreAndSolesQuery['sole'][0]) => ({
                    value: sole.id,
                    label: sole.name,
                  }))
                  .concat({ value: '', label: '없음' })
              : []
          }
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />

        <Select
          placeholder={'매장 (다중선택)...'}
          value={storeSearch}
          clearValue={() => setStoreSearch([])}
          onChange={setStoreSearch}
          isClearable
          isSearchable
          isMulti
          options={
            storeAndSoleData
              ? storeAndSoleData.store.map((store: GetStoreAndSolesQuery['store'][0]) => ({
                  value: store.id,
                  label: store.name + (store.llumar_store_name ? ` (${store.llumar_store_name})` : ''),
                }))
              : []
          }
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />

        <br />

        <Paper>
          <Typography className={styles.title} variant="h6" id="tableTitle" component="div">
            {'매장별 블로그 현황'}
          </Typography>
          <TableContainer className={styles.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {[
                    { label: 'No.', minWidth: '10px' },
                    { label: '입점일', minWidth: '60px', orderBy: 'created_at' },
                    { label: '매장명', minWidth: '120px', orderBy: 'name' },
                    { label: '루마 매장명', minWidth: '120px' },
                    { label: '갤러리 등록 수', minWidth: '150px', orderBy: 'galleryCount' },
                    { label: '라이더대시 직원 갤러리 등록 수', minWidth: '150px' },
                    { label: '최근 갤러리 등록일', minWidth: '150px', orderBy: 'maxCreatedAt' },
                    { label: '매장 블로그 링크', minWidth: '120px', orderBy: 'operation_gallery_link' },
                    { label: '연락처', minWidth: '120px' },
                    { label: '총판', minWidth: '120px' },
                    { label: '연락일 (월/일/연도)', minWidth: '120px', orderBy: 'operation_last_contact' },
                    { label: '메모란', minWidth: '200px' },
                    { label: '상세 수정', minWidth: '20px' },
                  ].map((headerRow, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: headerRow.minWidth }}
                      sortDirection={sortMode[0] === headerRow.orderBy ? 'desc' : false}
                    >
                      {headerRow.orderBy ? (
                        <TableSortLabel
                          active={sortMode[0] === headerRow.orderBy}
                          direction={sortMode[1] === 'asc_nulls_first' ? 'asc' : 'desc'}
                          onClick={() => {
                            const isAsc = sortMode[0] !== headerRow.orderBy || sortMode[1] === 'asc_nulls_first'
                            setSortMode([
                              headerRow.orderBy as typeof sortMode[0],
                              isAsc ? 'desc_nulls_last' : 'asc_nulls_first',
                            ])
                          }}
                        >
                          {headerRow.label}
                        </TableSortLabel>
                      ) : (
                        headerRow.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {galleryContentState &&
                  galleryContentState.store.map((store, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1 + page * pageSize}</TableCell>
                        <TableCell>{moment(store.created_at).format('YYYY/MM/DD')}</TableCell>
                        <TableCell>{store.name}</TableCell>
                        <TableCell>{store.llumar_store_name || '-'}</TableCell>
                        <TableCell>
                          {store.galleriesByShown.aggregate.count}
                          {store.galleriesByHidden ? ` (숨김: ${store.galleriesByHidden.aggregate.count})` : '-'}
                        </TableCell>
                        <TableCell>
                          {store.galleriesByHumanShown.aggregate.count}
                          {store.galleriesByHumanHidden
                            ? ` (숨김: ${store.galleriesByHumanHidden.aggregate.count})`
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {store.galleries_aggregate.aggregate.max.created_at
                            ? moment(store.galleries_aggregate.aggregate.max.created_at).format('YYYY/MM/DD')
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {store.operation_gallery_link ? (
                            <a href={store.operation_gallery_link} target={'_blank'} rel={'noreferrer'}>
                              link
                            </a>
                          ) : (
                            '없음'
                          )}
                        </TableCell>
                        <TableCell>
                          {store.phone_business
                            ? new PhoneNumber(store.phone_business, 'KR').getNumber('national')
                            : '없음'}
                        </TableCell>
                        <TableCell>
                          <SoleEditSelect
                            value={store.store_and_soles[0] ? store.store_and_soles[0].soleBySole.id : ''}
                            options={storeAndSoleData && storeAndSoleData.sole}
                            onChange={async (e) => {
                              try {
                                moment.locale('ko')
                                // eslint-disable-next-line no-alert
                                const changeSole = window.confirm(`해당 매장의 총판을 변경하시겠습니까?`)
                                if (changeSole) {
                                  await client.mutate({
                                    mutation: UPSERT_STORE_SOLE,
                                    fetchPolicy: 'no-cache',
                                    variables: { id: store.id, sole: e.target.value },
                                  })
                                  changeSnackbarAlertContent({ severity: 'success', content: '변경 완료' })
                                }
                              } catch (err) {
                                changeSnackbarAlertContent({ severity: 'error', content: `변경 실패\n${err}` })
                                Sentry.captureException(err)
                              }
                              getGalleryByStore()
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {/* Update Last Contact Date */}
                          <input
                            value={
                              store.operation_last_contact
                                ? moment(store.operation_last_contact).format('YYYY-MM-DD')
                                : ''
                            }
                            type={'date'}
                            onKeyDown={(e) => e.preventDefault()}
                            onFocus={(e) => {
                              prevDate = e.currentTarget.value
                            }}
                            onChange={async (e) => {
                              try {
                                moment.locale('ko')
                                // eslint-disable-next-line no-alert
                                const changeDate = window.confirm(
                                  `해당 매장 연락 일자를 ${e.currentTarget.value}로 변경하시겠습니까?`,
                                )
                                if (changeDate) {
                                  await client.mutate({
                                    mutation: UPDATE_STORE_OPERATION,
                                    fetchPolicy: 'no-cache',
                                    variables: { id: store.id, data: moment(e.currentTarget.value).utc().format() },
                                  })
                                  changeSnackbarAlertContent({ severity: 'success', content: '변경 완료' })
                                } else {
                                  e.currentTarget.value = prevDate
                                }
                              } catch (err) {
                                changeSnackbarAlertContent({ severity: 'error', content: `변경 실패\n${err}` })
                                Sentry.captureException(err)
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <MemoEditBox storeItem={store} upsertMemo={upsertMemo} />
                        </TableCell>
                        <TableCell>
                          <button onClick={() => changeMainMenu(`/gallery-display-detail?id=${store.id}`)}>
                            수정하기
                          </button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              100,
              200,
              500,
              { value: galleryContentState ? galleryContentState.store_aggregate.aggregate.count : 0, label: 'All' },
            ]}
            component="div"
            count={galleryContentState ? galleryContentState.store_aggregate.aggregate.count : 0}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPageSize(+event.target.value)
              setPage(0)
            }}
          />
        </Paper>
      </div>
    </div>
  )
}

export default React.memo(App)
