import 'firebase/auth'
import 'firebase/database'

import * as Sentry from '@sentry/react'
import AWS from 'aws-sdk'
import axios from 'axios'
import * as firebase from 'firebase/app'
import { observable } from 'mobx'

import config from '../../configs'
import appStore from '../app'

// Intialize Firebase

firebase.default.initializeApp(config.firebaseConfig)

// Initialize AWS
export const s3 = new AWS.S3({
  accessKeyId: config.awsConfig.accessKeyId,
  secretAccessKey: config.awsConfig.secretAccessKeyId,
  region: config.awsConfig.regionName,
  params: {
    Bucket: config.awsConfig.imageBucketName,
  },
})

const createStore = (): typeof authStore => {
  // Temporary variables for sign up
  const tempName = ''
  const accountType = ''
  // Temporary variable for loading
  let previousMainMenu = ''

  const authStore = {
    // Variables
    sessionId: observable.box(''),
    accountId: observable.box(''),
    idToken: observable.box(''),
    email: observable.box(''),
    phoneMobile: observable.box(''),
    name: observable.box(''),
    isSignedIn: observable.box(false),
    isVerified: observable.box(false),
    isReady: observable.box(false),
    isPartTimer: observable.box(false),
    authorization: observable.box(false),

    updateIsPartTimer: (data: boolean): void => {
      authStore.isPartTimer.set(data)
    },

    // Methods
    updateSession: async (): Promise<void> => {
      try {
        previousMainMenu = appStore.currentMainMenu.get()
        authStore.isReady.set(false)

        // Set session ID
        const sessionData = await axios.get(`${config.backendEndPoint}/auth/update-session`)
        authStore.sessionId.set(sessionData.data.id)

        // Faster check token
        authStore.callbackAuth(localStorage.getItem('firebaseIdToken'), true)
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    callbackAuth: async (idToken: string, forceProceed = false): Promise<void> => {
      try {
        // If token is null, don't do anything!
        if (!idToken) {
          appStore.changeLoadingSpinnerVisibility(false)

          return
        }

        // If this token is not changed, don't do anything!
        if (!forceProceed && idToken && idToken === localStorage.getItem('firebaseIdToken')) {
          appStore.changeLoadingSpinnerVisibility(false)

          return
        }

        appStore.changeLoadingSpinnerVisibility(true)

        // Force refresh to pick up the latest custom claims changes.
        const accountData = await axios.post(`${config.backendEndPoint}/auth/check-token-admin-web`, {
          idToken,
          tempName,
          accountType,
          clientReqURL: appStore.urlOrigin.get(),
        })

        // If there is no proper return values...
        if (!accountData.data.accountByAccount) return
        // Firebase ID token has expired.
        if (accountData.data?.err?.code === 'auth/id-token-expired') return

        authStore.accountId.set(accountData.data.accountByAccount.id)
        authStore.idToken.set(idToken)
        localStorage.setItem('firebaseIdToken', idToken)
        authStore.email.set(accountData.data.email)
        authStore.phoneMobile.set(accountData.data.phone_mobile)
        authStore.name.set(accountData.data.name)
        authStore.isVerified.set(accountData.data.email ? !!accountData.data.email_verified_at : false)
        authStore.authorization.set(accountData.data.role === 'administrator')

        authStore.isSignedIn.set(true)

        if (accountData.data.role === 'parttimer') {
          authStore.isPartTimer.set(true)
        }

        authStore.isReady.set(true)
        appStore.changeLoadingSpinnerVisibility(false)
      } catch (err) {
        Sentry.captureException(err)
        authStore.isReady.set(true)
        appStore.changeLoadingSpinnerVisibility(false)
      }
    },
    handlerOnAuthStateChanged: async (user: firebase.default.User) => {
      try {
        // If signed in to Firebase
        if (user) {
          // store user object
          const idToken = await user?.getIdToken(false)
          authStore.callbackAuth(idToken)
        } else {
          // Not sigined in
          appStore.changeMainMenu(previousMainMenu)
          authStore.isReady.set(true)
        }
      } catch (err) {
        authStore.isReady.set(true)
      }
    },
    updateAuthInfo: (): void => {
      // sign-in, sign-out, and refresh token
      firebase.default.auth().onIdTokenChanged((user) => authStore.handlerOnAuthStateChanged(user))
    },
    signIn: (email: string, password: string): Promise<firebase.default.auth.UserCredential> => {
      // Change isReady to false
      authStore.isReady.set(false)

      if (firebase.default.apps.length === 0) return null

      return firebase.default
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch((err) => {
          authStore.isReady.set(true)

          throw err
        })
    },
    signOut: (): Promise<void> => {
      authStore.accountId.set('')
      authStore.idToken.set('')
      localStorage.removeItem('firebaseIdToken')
      authStore.email.set('')
      authStore.name.set('')
      authStore.isSignedIn.set(false)
      authStore.isVerified.set(false)
      authStore.isReady.set(false)

      return firebase.default.auth().signOut()
    },
    changeIsReady(data: boolean): void {
      authStore.isReady.set(data)
    },
  }

  return authStore
}

const store = createStore()
export default store
