// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2EvOw {\n  width: 100%; }\n\n.index-module__table___2qI4p {\n  max-height: calc(100vh - 270px); }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/GalleryDisplay/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;;AAGb;EACE,+BAA+B,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  width: 100%;\n}\n\n.table {\n  max-height: calc(100vh - 270px);\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2EvOw",
	"table": "index-module__table___2qI4p"
};
module.exports = exports;
