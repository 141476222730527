// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___18LSF {\n  margin: 0 auto;\n  margin-top: 15%;\n  width: 400px;\n  height: 350px;\n  background-color: white;\n  text-align: center; }\n  .index-module__container___18LSF .index-module__textBox___8M0s0 {\n    margin-top: 40px; }\n  .index-module__container___18LSF .index-module__selectBox___DgVjp {\n    margin-top: 20px; }\n  .index-module__container___18LSF .index-module__btnArea___3YOfc {\n    text-align: -webkit-center;\n    margin-top: 30px; }\n    .index-module__container___18LSF .index-module__btnArea___3YOfc .index-module__btnFrame___2RGo1 {\n      display: flex;\n      justify-content: space-between;\n      width: 160px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Menu/ReservationPopupStatus/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,kBAAkB,EAAA;EANpB;IASI,gBAAgB,EAAA;EATpB;IAaI,gBAAgB,EAAA;EAbpB;IAiBI,0BAA0B;IAC1B,gBAAgB,EAAA;IAlBpB;MAoBM,aAAa;MACb,8BAA8B;MAC9B,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin: 0 auto;\n  margin-top: 15%;\n  width: 400px;\n  height: 350px;\n  background-color: white;\n  text-align: center;\n\n  .textBox {\n    margin-top: 40px;\n  }\n\n  .selectBox {\n    margin-top: 20px;\n  }\n\n  .btnArea {\n    text-align: -webkit-center;\n    margin-top: 30px;\n    .btnFrame {\n      display: flex;\n      justify-content: space-between;\n      width: 160px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___18LSF",
	"textBox": "index-module__textBox___8M0s0",
	"selectBox": "index-module__selectBox___DgVjp",
	"btnArea": "index-module__btnArea___3YOfc",
	"btnFrame": "index-module__btnFrame___2RGo1"
};
module.exports = exports;
