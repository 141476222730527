import { ApolloQueryResult } from '@apollo/client'
import PhoneNumber from 'awesome-phonenumber'
import gql from 'graphql-tag'
import * as Hangul from 'hangul-js'
import _ from 'lodash'
import moment from 'moment-timezone'

import {
  Car,
  Get_Package_ProductQuery as GetPackageProductQuery,
  Lead,
  Lead_Comment as LeadComment,
  Order,
  Package,
  Product,
  Product_Item as ProductItem,
  Product_Type_Enum as ProductTypeEnum,
  Quote_Item as QuoteItem,
  Scalars,
  Store,
} from '../assets/graphql/graphql'
import { GET_PACKAGE_DETAIL, GET_PRODUCT_ITEMS_WITH_SKU } from '../pages/Order/query'
import { typePart, typeProduct, typeProductType } from '../pages/Order/type'
import { PackageCardObject } from '../pages/StoreAndPackage'
import { GET_PACKAGE_PRODUCT } from '../pages/StoreAndPackage/query'
import calculatorStore, { initSelectedProductIds } from '../stores/calculator'
import { ProductCategory, SelectedProductIds, TypeSelectOptionsProductItemContentsKey } from '../stores/calculator/type'
import { LEAD_COL } from '../stores/lead/type'
import quoteStore from '../stores/quote'
import client from './graphql'
import history from './history'

export interface QueryVariables {
  offset?: number
  limit?: number
  keyword?: string
  carMaker?: string
  carOrigin?: string
  carType?: string
  sunroofType?: string
}

/* eslint-disable camelcase */
export interface PackageStoreType {
  add_on_product_item?: string
  product_item?: { price: number }
  storeByStore: Store
}

export interface ProductDetailTypeAndTextAndPrice {
  productDetailTypeAndText: string[]
  originalPrice: number
}

export const GET_PACKAGE = gql`
  query GET_PACKAGE {
    package(where: { package_type: { value: { _neq: "custom_package" } } }) {
      id
      name
      product
      description
      add_on_product
      price_product
      price_discount
      price_payout
      attribute
    }
  }
`

export const GET_PRODUCT_ITEM = gql`
  query GET_PRODUCT_ITEM($id: uuid!, $carType: String = "%", $carOrigin: String = "%") {
    product_item(
      where: { _and: [{ id: { _eq: $id } }, { sku: { _like: $carType } }, { sku: { _like: $carOrigin } }] }
    ) {
      id
      attribute
      sku
      productByProduct {
        name
      }
    }
  }
`

export const formatDate = (): string => {
  const d = new Date()
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return `${year}-${month}-${day}T10:00`
}

export const pricePayoutWithoutVat = (pricePayout: number): number => {
  return Math.floor(pricePayout * 0.91)
}

export const camelToSnake = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  camelObejct: Record<string, string | number | object>,
  // eslint-disable-next-line @typescript-eslint/ban-types
): Record<string, string | number | object> => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const snakeCaseObejct: Record<string, string | number | object> = {}
  Object.entries(camelObejct).forEach((x) => {
    const [key, value] = x
    const snakeCaseKey = _.snakeCase(key)

    snakeCaseObejct[snakeCaseKey] = value
  })

  return snakeCaseObejct
}

export const parseQueryString = (): Record<string, string> => {
  const queryString = window.location.search.trim()

  const queryObject: Record<string, string> = {}
  // Return empty obejct, when do not have querystring
  if (queryString === '') return queryObject

  queryString
    .split('?')[1]
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=')
      queryObject[key] = decodeURI(value)
    })

  return queryObject
}

export const deleteQueryString = (param: string): void => {
  const path = window.location.pathname.substring(1)
  const queryString = window.location.search.trim()

  const params = new URLSearchParams(queryString)
  params.delete(param)
  history.replace(`${path}?${params.toString()}`)
}

export const autoHypenPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber) {
    const str = phoneNumber.replace(/[^0-9]/g, '')
    let hypenPhoneNumber = ''
    if (str.length < 4) {
      return str
    }
    if (str.length < 7) {
      hypenPhoneNumber += str.substr(0, 3)
      hypenPhoneNumber += '-'
      hypenPhoneNumber += str.substr(3)

      return hypenPhoneNumber
    }
    if (str.length < 11) {
      hypenPhoneNumber += str.substr(0, 3)
      hypenPhoneNumber += '-'
      hypenPhoneNumber += str.substr(3, 3)
      hypenPhoneNumber += '-'
      hypenPhoneNumber += str.substr(6)

      return hypenPhoneNumber
    }
    hypenPhoneNumber += str.substr(0, 3)
    hypenPhoneNumber += '-'
    hypenPhoneNumber += str.substr(3, 4)
    hypenPhoneNumber += '-'
    hypenPhoneNumber += str.substr(7)

    return hypenPhoneNumber.substr(0, 13)
  }

  return ''
}

export const checkIsValidEmail = (value: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(value).toLowerCase())
}

export const checkIsValiedPhoneNumber = (value: string): boolean => {
  const isValidPhoneNumber = new PhoneNumber(autoHypenPhoneNumber(value), 'KR').isMobile()

  return isValidPhoneNumber
}
export const checkIsNumber = (value: string): boolean => {
  const re = /\b([0-9]){1,}\b/

  return re.test(value)
}
export const checkIsUUID = (value: string): boolean => {
  const re = /^([0-9a-zA-Z]){8}-([0-9a-zA-Z]){4}-([0-9a-zA-Z]){4}-([0-9a-zA-Z]){4}-([0-9a-zA-Z]){12}$/

  return re.test(value)
}

export const storeToLocalStorage = (value: string | number | Array<string> = null, type: string = null): void => {
  if (value) {
    window.localStorage.setItem(type, JSON.stringify(value))
  }
}

export const extractProductItemId = (productItem: typeProduct): string => {
  const partType = ['front', 'sunroof', 'sideback']
  let productItemId = ''

  // case of package
  if (productItem.package) {
    const [packageId] = Object.keys(productItem.package)

    Object.keys(productItem.package[packageId]).forEach((productTypeKey) => {
      // case of tinting
      if (productTypeKey === 'tinting') {
        Object.keys(productItem.package[packageId].tinting).forEach((partKey) => {
          productItemId += `{id: { _eq: "${productItem.package[packageId].tinting[partKey as typePart]}"}}`
        })
      }
      // case of other product item: dashcam, ppf, ceramic_coating
      else {
        productItemId += `{id: { _eq: "${productItem.package[packageId][productTypeKey as typeProductType]}"}}`
      }
    })
  }
  // case of tinting
  else {
    partType.forEach((part) => {
      if (productItem.tinting[part as typePart]) {
        productItemId += `{id: { _eq: "${productItem.tinting[part as typePart]}"}}`
      }
    })
  }

  return productItemId
}

// eslint-disable-next-line camelcase
export const getProductItem = async (product_item: typeProduct): Promise<Array<ProductItem>> => {
  const productItemId = extractProductItemId(product_item)

  // get product item
  const productItemContent = await client.query({
    query: GET_PRODUCT_ITEMS_WITH_SKU(productItemId),
    variables: {},
    fetchPolicy: 'no-cache',
  })

  return productItemContent.data.productItems
}

// return [category, products] for amplitude user property
// eslint-disable-next-line camelcase
export const getProductCategoryAndproduct = async (product_item: typeProduct): Promise<Array<Array<string>>> => {
  const productItemId = extractProductItemId(product_item)

  // get product item
  const productItemContent = await client.query({
    query: GET_PRODUCT_ITEMS_WITH_SKU(productItemId),
    variables: {},
    fetchPolicy: 'no-cache',
  })

  const productCategorySet = new Set()
  const productSet = new Set()

  productItemContent.data.productItems.forEach((productItem: ProductItem): void => {
    if (
      productItem &&
      productItem.productByProduct &&
      productItem.productByProduct.attribute &&
      productItem.productByProduct.attribute.grade
    ) {
      productCategorySet.add(productItem.productByProduct.attribute.grade)
    }

    if (productItem && productItem.sku) {
      productSet.add(productItem.sku)
    }
  })

  return [Array.from(productCategorySet) as Array<string>, Array.from(productSet) as Array<string>]
}

export const packageToPackageCardArray = async (packageArray: Array<Package>): Promise<Array<PackageCardObject>> => {
  const packageCardArray: Array<PackageCardObject> = []
  const promiseArray: Array<Promise<void | ApolloQueryResult<GetPackageProductQuery>>> = []

  packageArray.forEach((packageObject) => {
    const displayCarType = packageObject.alias === 'tesla_package' ? 'other_sedan' : 'korea_sedan'
    const packageProduct = packageObject.product.package
    let productsId = ''

    // get chunk of product_id by iterating product column(JSONB) of Package
    Object.keys(packageProduct).forEach((productKey) => {
      // in case of 'tinting': should dive in 1 more depth
      if (productKey === 'tinting') {
        // iterating part: 'front', 'sideback'
        Object.keys(packageProduct[productKey]).forEach((partKey) => {
          if (packageProduct[productKey][partKey].product_id) {
            productsId += `{id: { _eq: "${packageProduct[productKey][partKey].product_id}"}}`
          }
        })
      } else if (packageProduct[productKey].product_id) {
        productsId += `{id: { _eq: "${packageProduct[productKey].product_id}"}}`
      }
    })

    // push Promise to array for parameter of Promise.all
    promiseArray.push(
      client
        .query<GetPackageProductQuery>({
          query: GET_PACKAGE_PRODUCT(productsId),
          fetchPolicy: 'no-cache',
        })
        .then((packageProductContents) => {
          const productArrayArranged: Array<Product> = []
          const tintingProductArray: Product[] = []

          if (packageProductContents && packageProductContents.data.product.length > 0) {
            packageProductContents.data.product.forEach((product: Product) => {
              if (product.type === 'tinting') {
                tintingProductArray.push(product)
              } else {
                productArrayArranged.push({
                  ...product,
                  // if ppf, add ppf information to product.name from package.attribute
                  ...(product.type === 'ppf' && { name: product.name.concat(' ', packageObject.attribute.ppf) }),
                })
              }
            })

            const tempTintingNameArray = []
            for (let i = 0; i < tintingProductArray.length; i += 1) {
              tempTintingNameArray.push(tintingProductArray[i].name)
            }

            // arrange position of tinting in front of product array
            productArrayArranged.unshift({
              name: tempTintingNameArray.join(' + '),
              product_brand: tintingProductArray[0].product_brand,
              type: 'tinting' as ProductTypeEnum,
            } as Product)

            packageCardArray.push({
              ...packageObject,
              product: productArrayArranged,
              price: {
                discountPrice: packageObject.price_discount[displayCarType],
                originalPrice: packageObject.price_product[displayCarType],
                totalPrice: packageObject.price_payout[displayCarType],
              },
            })
          }
        }),
    )
  })

  await Promise.all(promiseArray)

  return packageCardArray
}

export const getPackageName = async (packageId: string): Promise<string> => {
  const countableProductType = ['tinting', 'dashcam', 'ppf', 'ceramic_coating']

  const { data: packageDetailContents } = await client.query({
    query: GET_PACKAGE_DETAIL(),
    variables: { newCarPackageId: packageId },
    fetchPolicy: 'no-cache',
  })

  // count is based(used) on packageName like '2종' 가성비 패키지, not based on product_item count in cartObject JSONB.
  if (packageDetailContents.package[0].product.package) {
    const packageProductCount = Object.keys(packageDetailContents.package[0].product.package).filter((item) =>
      countableProductType.indexOf(item),
    )

    return `[${packageProductCount.length.toString()}종] ${packageDetailContents.package[0].name} 패키지`
  }

  return `맞춤 견적`
}

export const productItemSortbyProductType: Array<string> = [
  ProductTypeEnum.Tinting,
  ProductTypeEnum.FilmRemove,
  ProductTypeEnum.Dashcam,
  ProductTypeEnum.CeramicCoating,
  ProductTypeEnum.Ppf,
  ProductTypeEnum.Connected,
  ProductTypeEnum.AuxiliaryBattery,
  ProductTypeEnum.Hipass,
  ProductTypeEnum.AfterBlow,
  ProductTypeEnum.Undercoating,
  ProductTypeEnum.LeatherCoating,
  ProductTypeEnum.Soundproofing,
  ProductTypeEnum.NewCarInspection,
  ProductTypeEnum.CamoKit,
  ProductTypeEnum.DeliveryFee,
]

export const getSortProductItemDetail = (productItemDetail: Array<ProductItem>): Array<ProductItem> => {
  return [...productItemDetail].sort((a, b) => {
    const aIndex =
      productItemSortbyProductType.indexOf(a.productByProduct.product_type.value) !== -1
        ? productItemSortbyProductType.indexOf(a.productByProduct.product_type.value)
        : 9999
    const bIndex =
      productItemSortbyProductType.indexOf(b.productByProduct.product_type.value) !== -1
        ? productItemSortbyProductType.indexOf(b.productByProduct.product_type.value)
        : 9999
    if (aIndex > bIndex) return 1
    if (aIndex === bIndex) return 0

    return -1
  })
}

export const getCustomProductDetailTypeAndText = (customProduct: Scalars['jsonb']): Array<string> => {
  const productDetailTypeText = '기타제품'
  const productDetailText = `${customProduct.productName}`

  return [productDetailTypeText, productDetailText]
}

export const getProductDetailTypeAndText = (productItemObject: ProductItem): Array<string> => {
  const { attribute, productByProduct } = productItemObject

  let productDetailTypeText = productByProduct.product_type.description
  let productDetailText = ''

  const tintingPartDescription: {
    [key: string]: string
  } = {
    front: '전면',
    all: '측후면',
    side: '측면',
    back: '후면',
    sunroof_normal: '썬루프',
    sunroof_panorama: '파노라마 썬루프',
  }

  switch (productByProduct.product_type.value) {
    case ProductTypeEnum.Tinting:
      productDetailTypeText += ` (${tintingPartDescription[attribute?.part]})`
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}-${attribute?.density}%`
      if (attribute?.part === 'side') {
        productDetailText += ` / ${attribute?.num_of_pieces} 장`
      }
      break
    case ProductTypeEnum.FilmRemove:
      productDetailTypeText += ` (${tintingPartDescription[attribute?.part]})`
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}`
      if (attribute?.part === 'side') {
        productDetailText += ` / ${attribute?.num_of_pieces} 장`
      }
      break
    case ProductTypeEnum.Dashcam:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name} / ${attribute?.storage} / ${attribute?.channel}`
      break
    case ProductTypeEnum.CeramicCoating:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}`
      break
    case ProductTypeEnum.Ppf:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name} ${
        attribute?.product_name.split('_')[1]
      }종`
      break
    case ProductTypeEnum.Connected:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}`
      break
    case ProductTypeEnum.AuxiliaryBattery:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}`
      break
    case ProductTypeEnum.Hipass:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}`
      break
    case ProductTypeEnum.AfterBlow:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}`
      break
    case ProductTypeEnum.Undercoating:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name} / ${attribute?.type}`
      break
    case ProductTypeEnum.LeatherCoating:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name}`
      break
    case ProductTypeEnum.Soundproofing:
      productDetailText += `[${productByProduct.product_brand.description}] ${productByProduct.name} / ${attribute?.part}`
      break
    case ProductTypeEnum.NewCarInspection:
      productDetailText += `[서비스 항목] 신차검수`
      break
    case ProductTypeEnum.CamoKit:
      productDetailText += `[서비스 항목] 카모 키트`
      break
    case ProductTypeEnum.DeliveryFee:
      productDetailText += `[서비스 항목] 배송비`
      break
    default:
  }

  return [productDetailTypeText, productDetailText]
}

export const getProductDetailTypeAndTextArray = (
  productItemDetail: Array<ProductItem>,
  productItem: Scalars['jsonb'],
): Array<ProductDetailTypeAndTextAndPrice> => {
  return [
    ...(productItemDetail
      ? getSortProductItemDetail(productItemDetail).map((productItem_: ProductItem) => ({
          productDetailTypeAndText: getProductDetailTypeAndText(productItem_),
          originalPrice: productItem_.price,
        }))
      : []),
    ...(productItem && productItem.customProducts
      ? productItem.customProducts.map((customProduct: Scalars['jsonb']) => ({
          productDetailTypeAndText: getCustomProductDetailTypeAndText(customProduct),
          originalPrice: customProduct.productPrice.price,
        }))
      : []),
  ]
}

export const searchChosung = (_searchKeyword: string, _searchSpace: string): boolean => {
  const searchKeyword = _searchKeyword.toLowerCase()
  const searchChosungKeyword = Hangul.disassemble(searchKeyword).join('')

  const searchSpace = _searchSpace.toLowerCase().replace(' ', '')

  const dis = Hangul.disassemble(searchSpace, true)
  const chosung = dis.reduce((prev, elem) => prev + (elem[0] ? elem[0] : elem), '')

  return chosung.includes(searchChosungKeyword) || searchSpace.includes(searchKeyword)
}

export const getLeadColValueByKey = (rowData: Lead, col: LEAD_COL): string => {
  if (!rowData) return ''

  const lead = rowData || null
  const chat = (lead.chats && lead.chats.length > 0 && lead.chats[0]) || null
  const quoteItems = (lead.quotes && lead.quotes.length && lead.quotes[0].quote_items) || []
  const order = (lead.orders && lead.orders.length && lead.orders[0]) || null
  const payout = (order && order.payouts && order.payouts.length && order.payouts[0]) || null

  const getStoreName = (data: Order | QuoteItem) => {
    if (!data.storeByStore) return ''

    return `[${
      data.storeByStore && data.storeByStore.subscriptions.length > 0 ? data.storeByStore.subscriptions[0].status : ''
    }] ${data.storeByStore && data.storeByStore.name} (${
      data.storeByStore && data.storeByStore.address_detail ? data.storeByStore.address_detail : ''
    })`
  }

  const getVatRequestedAt = (): string => {
    let res = null

    lead.lead_comments.forEach((leadComment: LeadComment) => {
      const { comment, created_at } = leadComment

      if (
        comment.indexOf('시공점으로 전자 세금계산서 요청 문자 전송 성공') !== -1 ||
        comment.indexOf('시공점으로 세금계산서 전송 성공') !== -1
      )
        res = moment(created_at).format('YYYY-MM-DD')
    })

    return res
  }

  const isSameDayRequest = (): boolean => {
    let res = false

    if (!lead.lead_comments) return res

    lead.lead_comments.forEach((leadComment: LeadComment) => {
      const { comment } = leadComment
      if (comment.indexOf('오늘 시공을 원하는 고객입니다') !== -1 || comment.indexOf('시공 예정일 : 당일') !== -1)
        res = true
    })

    return res
  }

  switch (col) {
    case LEAD_COL.CHAT_ID:
      return chat ? `${chat.key}` : ''
    case LEAD_COL.LEAD_ID:
      return lead.key ? `${lead.key}` : ''
    case LEAD_COL.ZENDESK_CHAT_NAME:
      return chat ? chat.zendesk_chat_name : ''
    case LEAD_COL.CHAT_ASSIGNEE:
      return chat && chat.accountByChatAssignee ? chat.accountByChatAssignee.profile_riderdash.name : ''
    case LEAD_COL.SUPPORT_ASSIGNEE:
      return lead.accountBySupportAssignee ? lead.accountBySupportAssignee.profile_riderdash.name : ''
    case LEAD_COL.MATCH_MAKER:
      return lead.accountByShopAssignee ? lead.accountByShopAssignee.profile_riderdash.name : ''
    case LEAD_COL.SELECTED_NEW_CAR:
      return lead && lead.used_car_status === null ? '' : `${lead.used_car_status ? '재시공' : '신차'}`
    case LEAD_COL.START_DROP_OFF_DATE:
      return lead && lead.start_drop_off_date_time ? moment(lead.start_drop_off_date_time).format('YYYY-MM-DD') : ''
    case LEAD_COL.END_DROP_OFF_DATE:
      return lead && lead.end_drop_off_date_time ? moment(lead.end_drop_off_date_time).format('YYYY-MM-DD') : ''
    // (기본값: Start drop-off date과 오늘까지 남은 기간이 2주 이하면 1/3 날짜. 2주 초과면 1/2 날짜. 보수적으로 소수점은 당겨진 날짜로 처리.)
    // Start drop-off date이 지난 경우, 신차일 경우 +1일, 재시공일 경우 +1일 날짜.
    case LEAD_COL.DUE_DATE:
      return lead && lead.due_date ? moment(lead.due_date).format('YYYY-MM-DD') : ''
    case LEAD_COL.SHOP:
      return order
        ? getStoreName(order)
        : _.uniq(
            quoteItems
              .map((quoteItem: QuoteItem) => {
                return getStoreName(quoteItem)
              })
              .filter((a) => a !== ''),
          )
            .toString()
            .replace(/,/gi, ', ')

    case LEAD_COL.SHOP_PHONE:
      return order && order.storeByStore && order.storeByStore.phone_business
        ? new PhoneNumber(order.storeByStore.phone_business, 'KR').getNumber('national')
        : ''
    case LEAD_COL.CAR:
      return lead.carByCar ? `[${lead.carByCar.car_maker.name_kr}] ${lead.carByCar.model}` : ''
    case LEAD_COL.CARAMORA_ID:
      return lead.accountByCustomer ? lead.accountByCustomer.profile_kr_customer.email : ''
    case LEAD_COL.CUSTOMER_NAME:
      return lead.accountByCustomer ? lead.accountByCustomer.profile_kr_customer.name : ''
    case LEAD_COL.CUSTOMER_PHONE:
      return lead.phone ? new PhoneNumber(lead.phone, 'KR').getNumber('national') : ''
    case LEAD_COL.CREATED_AT:
      return moment(lead.created_at).locale('ko').format('YYYY-MM-DD HH:mm:ss')
    case LEAD_COL.UPDATED_AT:
      return moment(lead.updated_at).locale('ko').format('YYYY-MM-DD HH:mm:ss')
    case LEAD_COL.CAN_NOT_MATCH:
      return lead.match_at && moment(lead.match_at).unix() < moment(lead.quote_at).unix() ? '!!!' : ''
    case LEAD_COL.PAYMENT_LINK_SENT_AT:
      return order && order.payment_link_sent_at
        ? moment(order.payment_link_sent_at).locale('ko').format('YYYY-MM-DD HH:mm:ss')
        : ''
    case LEAD_COL.PAID_AT:
      return order && order.paid_at ? moment(order.paid_at).locale('ko').format('YYYY-MM-DD HH:mm:ss') : ''
    case LEAD_COL.PRICE_PAYOUT:
      return order && order.price_payout ? `${order.price_payout.toLocaleString()}원` : ''
    case LEAD_COL.MATERIAL_PRICE:
      return order && order.price_payout && order.price_final
        ? `${Math.round(order.price_final - order.price_payout).toLocaleString()}원`
        : ''
    case LEAD_COL.PRICE_PAYOUT_WITHOUT_VAT:
      return order && order.price_payout_without_vat ? `${order.price_payout_without_vat.toLocaleString()}원` : ''
    case LEAD_COL.FINISHED_AT:
      return payout && payout.finished_at ? moment(payout.finished_at).locale('ko').format('YYYY-MM-DD HH:mm:ss') : ''
    case LEAD_COL.STATUS:
      return lead.status
    case LEAD_COL.VAT_REQUESTED_AT:
      return getVatRequestedAt() || ''
    case LEAD_COL.RSV_COMFIRMED_AT:
      return moment(lead.rsv_confirmed_at).locale('ko').format('YYYY-MM-DD HH:mm:ss')
    case LEAD_COL.CREATED_FROM:
      return lead.created_from || ''

    case LEAD_COL.CALL_REQUEST_SAME_DAY_ORDER:
      return isSameDayRequest() ? '!!!' : ''
    default:
      break
  }

  return ''
}

export const parseCSVString = (csvString: string): Array<Array<string>> => {
  const lines = csvString.split('\n')

  return lines.map((line) => line.split(','))
}

export const getTheClosestWeekday = (date: string): string => {
  const dateMoment = moment(date)

  switch (dateMoment.day()) {
    case 6:
      return dateMoment.add(2, 'days').format('YYYY-MM-DD')
    case 0:
      return dateMoment.add(1, 'days').format('YYYY-MM-DD')
    default:
      return date
  }
}

export const setDueDate = (startDropOffDateTime: string): string => {
  const currentTime = Math.floor(new Date().getTime() / 1000)
  const timeDifference = moment(startDropOffDateTime).unix() - currentTime
  const within2Weeks = timeDifference / 60 / 60 / 24 <= 14

  let dueDate

  if (within2Weeks) {
    dueDate = `${getTheClosestWeekday(moment.unix(timeDifference / 3 + currentTime).format('YYYY-MM-DD'))}`
  } else {
    dueDate = `${getTheClosestWeekday(moment.unix(timeDifference / 2 + currentTime).format('YYYY-MM-DD'))}`
  }

  return dueDate
}

export const matchingProductItem = (productItems: ProductItem[]): SelectedProductIds => {
  const selectedProductIds = { ...initSelectedProductIds }

  productItems.forEach((item: ProductItem) => {
    if (item) {
      const { id: productItemId, attribute, productByProduct } = item

      switch (productByProduct.product_type.value) {
        case 'tinting':
          switch (attribute.part) {
            case 'front':
              selectedProductIds[ProductCategory.FrontTintingProduct] = productItemId
              break
            case 'all':
              selectedProductIds[ProductCategory.SidebackTintingProduct] = productItemId
              break
            case 'side':
              selectedProductIds[ProductCategory.SideTintingProduct] = productItemId
              break
            case 'back':
              selectedProductIds[ProductCategory.BackTintingProduct] = productItemId
              break
            case 'sunroof_normal':
            case 'sunroof_panorama':
              selectedProductIds[ProductCategory.SunroofTintingProduct] = productItemId
              break
            default:
          }
          break
        case 'film_remove':
          switch (attribute.part) {
            case 'front':
              selectedProductIds[ProductCategory.FrontFilmRemoveProduct] = productItemId
              break
            case 'side':
              selectedProductIds[ProductCategory.SideFilmRemoveProduct] = productItemId
              break
            case 'back':
              selectedProductIds[ProductCategory.BackFilmRemoveProduct] = productItemId
              break
            case 'sunroof_normal':
            case 'sunroof_panorama':
              selectedProductIds[ProductCategory.SunroofFilmRemoveProduct] = productItemId
              break
            default:
          }
          break
        case 'dashcam':
          selectedProductIds[ProductCategory.DashcamProduct] = productItemId
          break
        case 'ceramic_coating':
          selectedProductIds[ProductCategory.CeramicCoatingProduct] = productItemId
          break
        case 'ppf':
          selectedProductIds[ProductCategory.PpfProduct] = productItemId
          break
        case 'connected':
          selectedProductIds[ProductCategory.ConnectedProduct] = productItemId
          break
        case 'auxiliary_battery':
          selectedProductIds[ProductCategory.AuxiliaryBatteryProduct] = productItemId
          break
        case 'hipass':
          selectedProductIds[ProductCategory.HipassProduct] = productItemId
          break
        case 'after_blow':
          selectedProductIds[ProductCategory.AfterBlowProduct] = productItemId
          break
        case 'undercoating':
          selectedProductIds[ProductCategory.UndercoatingProduct] = productItemId
          break
        case 'leather_coating':
          selectedProductIds[ProductCategory.LeatherCoatingProduct] = productItemId
          break
        case 'soundproofing':
          selectedProductIds[ProductCategory.SoundproofingProduct] = productItemId
          break
        case 'new_car_inspection':
          selectedProductIds[ProductCategory.NewCarInspectionProduct] = productItemId
          break
        case 'camo_kit':
          selectedProductIds[ProductCategory.CamoKitProduct] = productItemId
          break
        case 'delivery_fee':
          selectedProductIds[ProductCategory.DeliveryFeeProduct] = productItemId
          break
        default:
      }
    }
  })

  return selectedProductIds
}

export const getDistanceFromLatLonInKm = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
  const deg2rad = (deg: number): number => {
    return deg * (Math.PI / 180)
  }

  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lng2 - lng1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km

  return d
}

export const getNearbyStoreIds = (seletedStore: Store, offset = 10, maxDistance = 100): Array<string> => {
  const { storeTree } = calculatorStore
  const {
    location: { coordinates: targetCoordinates },
  } = seletedStore

  return storeTree
    .nearest({ x: targetCoordinates[0], y: targetCoordinates[1] }, offset, maxDistance)
    .map((item: [{ id: string }, number]) => item[0].id)
}

export const checkAvailabilityByStores = (key: string, seletedStore: Store): boolean => {
  const filmInventory = calculatorStore.filmInventory.get()

  if (!filmInventory || !seletedStore || !seletedStore.location) return false

  let isOkay = false

  const storeIds = getNearbyStoreIds(seletedStore)

  storeIds.some((id) => {
    if (filmInventory[id] && filmInventory[id][key] !== '0') isOkay = true

    return isOkay === true
  })

  return isOkay
}

export const getCarOriginTypeKey = (carByCar: Car): string => {
  const carOrigin = carByCar ? carByCar.car_maker.car_origin.name_us : ''
  const carType = carByCar ? carByCar.type : ''
  const key = `${carOrigin === 'korea' ? carOrigin : 'other'}_${carType}`

  return key
}

export const generateComputedFieldInQuoteItem = (data: QuoteItem, idx: number): QuoteItem => {
  const selectProductsContent = calculatorStore.selectProductsContent.get()
  const selectedProductIds = calculatorStore.selectedProductIds.get()[idx + 1]
  const customProducts = calculatorStore.customProducts.get()[idx + 1]
  const selectedPackageObject = calculatorStore.selectedPackageObject.get()[idx + 1]

  const productItemDetail: ProductItem[] = []
  const packageDetail: Package[] = []

  Object.values(ProductCategory).forEach((key: ProductCategory) => {
    if (selectedProductIds[key]) {
      productItemDetail.push(
        selectProductsContent[key as TypeSelectOptionsProductItemContentsKey].filter(
          (item) => item.id === selectedProductIds[key],
        )[0] as ProductItem,
      )
    }
  })

  if (selectedPackageObject) {
    packageDetail.push(
      selectProductsContent.packages.filter((item) => item.id === selectedPackageObject.id)[0] as Package,
    )
  }

  const quoteItems = quoteStore.quoteObject.get().quote_items

  quoteItems[idx] = {
    ...data,
    product_item_detail: _.uniqBy(productItemDetail, 'id'),
    package_detail: _.uniqBy(packageDetail, 'id'),
  }

  quoteItems[idx].product_item = {
    ...quoteItems[idx].product_item,
    customProducts,
  }

  quoteStore.quoteObject.set({
    ...quoteStore.quoteObject.get(),
    quote_items: [...quoteItems],
  })

  return {
    ...quoteItems[idx],
  }
}
