import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Get_PromotionQuery as GetPromotionQuery,
  Get_PromotionQueryVariables as GetPromotionQueryVariables,
  Product_Brand as ProductBrand,
  Product_Item as ProductItem,
} from '../../assets/graphql/graphql'
import UploadImage from '../../components/organism/UploadImage'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import styles from './index.module.scss'

const gradeValues = ['premium', 'deluxe', 'general']

const performanceObject: { [key: string]: string } = {
  기능성: 'functional',
  가격대: 'price_range',
  전파간섭도: 'radio_interference',
  보증기간: 'warranty',
}
const perfromanceValues = ['exxcellent', 'best', 'good', 'normal']

type comparisonSummaryType = 'warranty' | 'radio_interference' | 'price_range' | 'functional'

const tagTexts = ['ONLY', 'PICK', '추가 할인', '추천 상품']

const productSpecObject: {
  [key: string]: string
} = {
  하이패스: 'hi_pass',
  보증기간: 'warranty',
  'RF 주차태그, 리모콘, TPMS': 'rf_reader',
  필름컬러: 'film_color',
  금속성분여부: 'exist_metal',
  필름요약: 'film_summary',
  '터널 무아레': 'tunnel_moire',
  '라디오 글라스 안테나': 'glass_antenna',
  'GPS 네비게이션': 'gps_navigation',
  '추천 부위': 'recommend_part',
  필름테크놀로지: 'film_technology',
  '필름컬러 설명': 'film_color_about',
  '필름컬러 설명 요약': 'film_color_summary',
  '필름테크놀로지 설명': 'film_technology_about',
}

type productSpecType =
  | 'hi_pass'
  | 'warranty'
  | 'rf_reader'
  | 'film_color'
  | 'exist_metal'
  | 'film_summary'
  | 'tunnel_moire'
  | 'glass_antenna'
  | 'gps_navigation'
  | 'recommend_part'
  | 'film_technology'
  | 'film_color_about'
  | 'film_color_summary'
  | 'film_technology_about'

const GET_PRODUCT_DETAIL = gql`
  query GET_PRODUCT_DETAIL($id: uuid!) {
    product(where: { id: { _eq: $id } }) {
      id
      status
      name
      type
      attribute
      brand
      image
      description
      alias
    }
    product_brand {
      value
      description
    }
    product_item(where: { product: { _eq: $id } }) {
      id
      sku
      price
    }
  }
`

const GET_PRODUCT_BRAND = gql`
  query GET_PRODUCT_BRAND {
    product_brand {
      value
      description
    }
  }
`

const UPDATE_PRODUCT = (brand: string): DocumentNode => {
  let setQuery = ``
  switch (brand) {
    case 'vertex':
      setQuery = `_set: { brand: vertex, name: $name, account: $account, attribute: $attribute, image: $image, description: $description, status: $status, alias: $alias }`

      break
    case 'llumar':
      setQuery = `_set: { brand: llumar, name: $name, account: $account, attribute: $attribute, image: $image, description: $description, status: $status, alias: $alias }`
      break

    default:
      break
  }

  return gql`
    mutation UPDATE_PRODUCT($productId: uuid!, $name: String!, $account: uuid!, $attribute: jsonb!, $image: jsonb!, $description: String!, $status: Boolean!, $alias: String!) {
      update_product(where: { id: { _eq: $productId } }, ${setQuery}) {
        affected_rows
      }
    }
  `
}

const INSERT_PRODUCT = gql`
  mutation INSERT_PRODUCT($productObject: product_insert_input!) {
    insert_product_one(object: $productObject) {
      id
    }
  }
`

const GET_PROMOTION = gql`
  query GET_PROMOTION($productName: jsonb!) {
    promotion(
      where: {
        _and: [
          { productItemByProductItem: { attribute: { _contains: $productName } } }
          { deleted_at: { _is_null: true } }
        ]
      }
    ) {
      id
      name
      on_going
      deleted_at
    }
  }
`

const INSERT_PROMOTION = gql`
  mutation INSERT_PROMOTION($promotionObjects: [promotion_insert_input!]!) {
    insert_promotion(
      objects: $promotionObjects
      on_conflict: { constraint: promotion_pkey, update_columns: updated_at }
    ) {
      affected_rows
    }
  }
`

const DELETE_PROMOTION = gql`
  mutation DELETE_PROMOTION($productId: uuid!, $deletedAt: timestamptz!) {
    update_promotion(
      where: { productItemByProductItem: { productByProduct: { id: { _eq: $productId } } } }
      _set: { deleted_at: $deletedAt }
    ) {
      affected_rows
    }
  }
`

const App: React.FunctionComponent = () => {
  const { accountId, changeMainMenu, changeSnackbarAlertContent } = useRootData(({ authStore, appStore }) => ({
    accountId: authStore.accountId.get(),
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))
  const { id } = parseQueryString()

  const [getProductData, { data: productContents, error }] = useLazyQuery(id ? GET_PRODUCT_DETAIL : GET_PRODUCT_BRAND, {
    variables: { id },
    fetchPolicy: 'no-cache',
  })

  const [editProductClicked, setEditProductClicked] = useState(!id)
  const [newProductDetail, setNewProductDetail] = useState(
    !id
      ? {
          account: accountId,
          status: true,
          name: '',
          type: 'tinting',
          attribute: {
            tag: '',
            grade: 'premium',
            film_density: {},
            comparison_summary: {
              warranty: 'excellent',
              functional: 'excellent',
              price_range: 'excellent',
              radio_interference: 'excellent',
            },
            performance_detail: {
              hi_pass: '',
              warranty: '',
              rf_reader: '',
              film_color: '',
              exist_metal: '',
              film_summary: '',
              tunnel_moire: '',
              glass_antenna: '',
              gps_navigation: '',
              recommend_part: '',
              film_technology: '',
              film_color_about: '',
              film_color_summary: '',
              film_technology_about: '',
            },
          },
          brand: 'vertex',
          description: '',
          image: {
            product: {
              mobile: [
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
              ],
              desktop: [
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
                {
                  jpeg_aws: '',
                  thumb_aws: '',
                  original_aws: '',
                },
              ],
            },
            landing_banner: {
              mobile: {
                jpeg_aws: '',
                thumb_aws: '',
                original_aws: '',
              },
              desktop: {
                jpeg_aws: '',
                thumb_aws: '',
                original_aws: '',
              },
            },
            product_guide: {
              vlt: 0,
              responsive: {
                jpeg_aws: '',
                thumb_aws: '',
                original_aws: '',
              },
            },
          },
          alias: '',
        }
      : null,
  )

  const [filmDensity, setFilmDensity] = useState([])
  const [filmDensityCnt, setFilmDensityCnt] = useState(0)

  const [promotionStatus, setPromotionStatus] = useState(null)

  const [promotion, setPromotion] = useState(null)
  const [promotionObjects, setPromotionObjects] = useState([])

  const getPromotion = async (productName: string): Promise<void> => {
    const promotionContents = await client.query<GetPromotionQuery, GetPromotionQueryVariables>({
      query: GET_PROMOTION,
      variables: { productName: { product_name: productName || '' } },
      fetchPolicy: 'no-cache',
    })

    setPromotionStatus(promotionContents.data.promotion.length > 0)
    setPromotion(promotionContents.data.promotion)
  }

  useEffect(() => {
    getProductData()
  }, [])

  useEffect(() => {
    if (productContents) {
      if (id) {
        const isTinting = productContents.product[0].type === 'tinting'

        setNewProductDetail(productContents.product[0])

        if (!isTinting) return

        setFilmDensityCnt(Object.keys(productContents.product[0].attribute.film_density).length)

        const tempFilmDensity = []

        for (let i = 0; i < Object.keys(productContents.product[0].attribute.film_density).length; i += 1) {
          const density = Object.keys(productContents.product[0].attribute.film_density)[i]
          const values = productContents.product[0].attribute.film_density[density]

          tempFilmDensity.push({
            density,
            uvr: values.uvr,
            vlr: values.vlr,
            vlt: values.vlt,
            tser: values.tser,
          })
        }

        setFilmDensity(tempFilmDensity)

        getPromotion(productContents.product[0].alias)

        // eslint-disable-next-line camelcase
        const tempProductItemArray: Array<{ product_item: string; promotion_price: number }> = []
        productContents.product_item.map((productItem: ProductItem) => {
          return tempProductItemArray.push({
            product_item: productItem.id,
            promotion_price: productItem.price,
          })
        })

        setPromotionObjects(tempProductItemArray)
      }
    }
  }, [productContents])

  if (error) {
    Sentry.captureException(error)
  }

  if (!productContents) return null

  const productDetail = id ? productContents.product[0] : {}
  const productItemDetail = id ? productContents.product_item : {}
  const productBrandDetail = productContents.product_brand

  return (
    <div className="body">
      <div className={styles.container}>
        <div className={styles.editButtonArea}>
          {!editProductClicked ? (
            <button
              className={styles.button}
              onClick={(): void => {
                setEditProductClicked(true)
              }}
            >
              제품 정보 수정
            </button>
          ) : null}

          {editProductClicked ? (
            <div>
              <button
                className={styles.button}
                onClick={async (): Promise<void> => {
                  try {
                    const { name, brand, attribute, image, description, status, alias } = newProductDetail

                    if (id) {
                      const filmDensityFormat = {}

                      filmDensity.forEach((item) => {
                        Object.assign(filmDensityFormat, {
                          [item.density]: {
                            uvr: parseFloat(item.uvr),
                            vlr: parseFloat(item.vlr),
                            vlt: parseFloat(item.vlt),
                            tser: parseFloat(item.tser),
                          },
                        })
                      })

                      attribute.film_density = filmDensityFormat

                      await client.mutate({
                        mutation: UPDATE_PRODUCT(brand),
                        variables: {
                          productId: id,
                          name,
                          attribute,
                          account: accountId,
                          image,
                          description,
                          status,
                          alias,
                        },
                      })
                      setEditProductClicked(false)

                      if (promotion.length > 0 && !promotionStatus) {
                        let timeStamp = null
                        timeStamp = moment().format()

                        await client.mutate({
                          mutation: DELETE_PROMOTION,
                          variables: {
                            productId: id,
                            deletedAt: timeStamp,
                          },
                        })
                      } else if (promotion.length === 0 && promotionStatus) {
                        await client.mutate({
                          mutation: INSERT_PROMOTION,
                          variables: {
                            promotionObjects,
                          },
                        })
                      }
                    } else {
                      const filmDensityFormat = {}

                      filmDensity.forEach((item) => {
                        Object.assign(filmDensityFormat, {
                          [item.density]: {
                            uvr: parseFloat(item.uvr),
                            vlr: parseFloat(item.vlr),
                            vlt: parseFloat(item.vlt),
                            tser: parseFloat(item.tser),
                          },
                        })
                      })

                      newProductDetail.attribute.film_density = filmDensityFormat

                      await client.mutate({
                        mutation: INSERT_PRODUCT,
                        variables: {
                          productObject: newProductDetail,
                        },
                      })

                      changeMainMenu('/product')
                    }
                    changeSnackbarAlertContent({ severity: 'success', content: '저장이 완료되었습니다' })
                    getProductData()
                  } catch (err) {
                    changeSnackbarAlertContent({ severity: 'error', content: `저장 실패${err}` })

                    Sentry.captureException(err)
                  }
                }}
              >
                저장하기
              </button>
            </div>
          ) : null}
        </div>
        {editProductClicked && <div className={styles.editing}>제품정보 수정중</div>}
        {/* 브랜드 */}
        <div className={styles.infoFrame}>
          <div className={styles.dataField}>
            노출 여부:
            <input
              type="checkbox"
              defaultChecked={!editProductClicked ? productDetail.status : newProductDetail.status}
              onChange={(e): void => {
                setNewProductDetail({ ...newProductDetail, status: e.target.checked })
              }}
              disabled={!editProductClicked}
            />
          </div>
          {/*  프로모션 */}
          <div className={styles.dataField}>
            프로모션 여부
            <input
              type="checkbox"
              checked={promotionStatus}
              onChange={(e): void => {
                setPromotionStatus(e.target.checked)
              }}
              disabled={!editProductClicked}
            />
          </div>
          {/*  */}
          <div className={styles.dataField}>
            브랜드 :
            <select
              className={!editProductClicked ? styles.selectEditDisabled : styles.selectEdit}
              disabled={!editProductClicked}
              onChange={(e): void => {
                setNewProductDetail({ ...newProductDetail, brand: e.target.value })
              }}
              defaultValue={!editProductClicked ? productDetail.brand : newProductDetail.brand}
            >
              {productBrandDetail.map((item: ProductBrand, index: React.Key) => {
                return (
                  <option key={index} defaultValue={item.value}>
                    {item.value}
                  </option>
                )
              })}
            </select>
          </div>
          <div className={styles.dataField}>
            alias :
            <input
              className={editProductClicked ? styles.inputEditing : styles.inputDefault}
              placeholder={'ex) vertex_1100, super, power'}
              defaultValue={!editProductClicked ? productDetail.alias : newProductDetail.alias}
              disabled={!editProductClicked}
              onChange={(e): void => {
                setNewProductDetail({ ...newProductDetail, alias: e.target.value })
              }}
            />
          </div>
          {/* 제품명 */}
          <div className={styles.dataField}>
            제품명 :
            <input
              className={editProductClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editProductClicked ? productDetail.name : newProductDetail.name}
              disabled={!editProductClicked}
              onChange={(e): void => {
                setNewProductDetail({ ...newProductDetail, name: e.target.value })
              }}
            />
          </div>
          {/* 제품 설명 */}
          <div className={styles.dataField}>
            제품 설명 :
            <input
              className={editProductClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editProductClicked ? productDetail.description : newProductDetail.description}
              disabled={!editProductClicked}
              onChange={(e): void => {
                setNewProductDetail({ ...newProductDetail, description: e.target.value })
              }}
            />
          </div>

          {id && productContents.product[0].type === 'tinting' && productContents.product[0].status && (
            <div>
              {/* 태그 */}
              <div className={styles.dataField}>
                태그 :
                <select
                  className={!editProductClicked ? styles.selectEditDisabled : styles.selectEdit}
                  disabled={!editProductClicked}
                  defaultValue={!editProductClicked ? productDetail.attribute.tag : newProductDetail.attribute.tag}
                  onChange={(e): void => {
                    const tempAttribute = newProductDetail.attribute
                    tempAttribute.tag = e.target.value
                    setNewProductDetail({ ...newProductDetail, attribute: tempAttribute })
                  }}
                >
                  {tagTexts.map((value, index: React.Key) => {
                    return (
                      <option key={index} defaultValue={value}>
                        {value}
                      </option>
                    )
                  })}
                </select>
              </div>
              {/* 제품 등급 */}
              <div className={styles.dataField}>
                제품 등급 :
                <select
                  className={!editProductClicked ? styles.selectEditDisabled : styles.selectEdit}
                  disabled={!editProductClicked}
                  defaultValue={!editProductClicked ? productDetail.attribute.grade : newProductDetail.attribute.grade}
                  onChange={(e): void => {
                    const tempAttribute = newProductDetail.attribute
                    tempAttribute.grade = e.target.value
                    setNewProductDetail({ ...newProductDetail, attribute: tempAttribute })
                  }}
                >
                  {gradeValues.map((value, index: React.Key) => {
                    return (
                      <option key={index} defaultValue={value}>
                        {value}
                      </option>
                    )
                  })}
                </select>
              </div>
              {/* 동일 제품군 대비 성능 비교 */}
              <div className={styles.dataField} />
              동일 제품군 대비 성능 비교
              <div>
                {Object.keys(performanceObject).map((performanceKey, index) => {
                  return (
                    <div key={index}>
                      ◎ {performanceKey} :
                      <select
                        className={!editProductClicked ? styles.selectEditDisabled : styles.selectEdit}
                        disabled={!editProductClicked}
                        defaultValue={
                          !editProductClicked
                            ? productDetail.attribute.comparison_summary[performanceObject[performanceKey]]
                            : newProductDetail.attribute.comparison_summary[
                                performanceObject[performanceKey] as comparisonSummaryType
                              ]
                        }
                        onChange={(e): void => {
                          const tempAttribute = newProductDetail.attribute
                          tempAttribute.comparison_summary[performanceObject[performanceKey] as comparisonSummaryType] =
                            e.target.value
                          setNewProductDetail({ ...newProductDetail, attribute: tempAttribute })
                        }}
                      >
                        {perfromanceValues.map((value, index1: React.Key) => {
                          return (
                            <option key={index1} defaultValue={value}>
                              {value}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  )
                })}
              </div>
              {/* 테크놀러지 */}
              <div className={styles.dataField} />
              테크놀러지
              <div>
                {Object.keys(productSpecObject).map((productSpecKey, index) => {
                  return (
                    <div key={index}>
                      ◎ {productSpecKey} :
                      <input
                        className={editProductClicked ? styles.inputEditing : styles.inputDefault}
                        defaultValue={
                          !editProductClicked
                            ? productDetail.attribute.performance_detail[productSpecObject[productSpecKey]]
                            : newProductDetail.attribute.performance_detail[
                                productSpecObject[productSpecKey] as productSpecType
                              ]
                        }
                        disabled={!editProductClicked}
                        onChange={(e): void => {
                          const tempAttribute = newProductDetail.attribute
                          if (productSpecKey === '보증기간') {
                            tempAttribute.performance_detail[productSpecObject[productSpecKey] as productSpecType] =
                              e.target.value
                          } else {
                            tempAttribute.performance_detail[productSpecObject[productSpecKey] as productSpecType] =
                              e.target.value
                          }
                          setNewProductDetail({ ...newProductDetail, attribute: tempAttribute })
                        }}
                      />
                    </div>
                  )
                })}
              </div>
              {/* 제품 이미지 */}
              <div className={styles.dataField}>제품 이미지</div>
              <div className={styles.dataField}> /product, /product_detail에 나오는 제품 사진 - Desktop </div>
              {[0, 1, 2, 3, 4, 5, 6].map((index) => {
                if (editProductClicked) {
                  return (
                    <UploadImage
                      key={index}
                      currentImage={
                        !editProductClicked
                          ? productDetail.image.product.desktop[index].thumb_aws
                          : newProductDetail.image.product.desktop[index].thumb_aws
                      }
                      onChangeHandler={(
                        // eslint-disable-next-line camelcase
                        jpeg_aws: string,
                        // eslint-disable-next-line camelcase
                        thumb_aws: string,
                        // eslint-disable-next-line camelcase
                        original_aws: string,
                      ): void => {
                        const tempImage = newProductDetail.image
                        tempImage.product.desktop[index] = {
                          jpeg_aws,
                          thumb_aws,
                          original_aws,
                        }
                        setNewProductDetail({ ...newProductDetail, image: tempImage })
                      }}
                    ></UploadImage>
                  )
                }

                return (
                  <div key={index}>
                    {productDetail.image.product.desktop[index].jpeg_aws ? (
                      <img
                        onClick={(): Window =>
                          window.open(
                            productDetail.image.product.desktop[index].original_aws
                              ? productDetail.image.product.desktop[index].original_aws
                              : productDetail.image.product.desktop[index].jpeg_aws,
                          )
                        }
                        src={productDetail.image.product.desktop[index].thumb_aws}
                      ></img>
                    ) : (
                      '저정된 사진이 없습니다!'
                    )}
                  </div>
                )
              })}
              <div className={styles.dataField}> /product, /product_detail에 나오는 제품 사진 - Mobile </div>
              {[0, 1, 2, 3, 4, 5, 6].map((index) => {
                if (editProductClicked) {
                  return (
                    <UploadImage
                      key={index}
                      currentImage={
                        !editProductClicked
                          ? productDetail.image.product.mobile[index].thumb_aws
                          : newProductDetail.image.product.mobile[index].thumb_aws
                      }
                      onChangeHandler={(
                        // eslint-disable-next-line camelcase
                        jpeg_aws: string,
                        // eslint-disable-next-line camelcase
                        thumb_aws: string,
                        // eslint-disable-next-line camelcase
                        original_aws: string,
                      ): void => {
                        const tempImage = newProductDetail.image
                        tempImage.product.mobile[index] = {
                          jpeg_aws,
                          thumb_aws,
                          original_aws,
                        }
                        setNewProductDetail({ ...newProductDetail, image: tempImage })
                      }}
                    ></UploadImage>
                  )
                }

                return (
                  <div key={index}>
                    {productDetail.image.product.mobile[index].jpeg_aws ? (
                      <img
                        onClick={(): Window =>
                          window.open(
                            productDetail.image.product.mobile[index].original_aws
                              ? productDetail.image.product.mobile[index].original_aws
                              : productDetail.image.product.mobile[index].jpeg_aws,
                          )
                        }
                        src={productDetail.image.product.mobile[index].thumb_aws}
                      ></img>
                    ) : (
                      '저정된 사진이 없습니다!'
                    )}
                  </div>
                )
              })}
              <div className={styles.dataField}>/landing banner에 나오는 사진 - Desktop</div>
              {editProductClicked ? (
                <UploadImage
                  currentImage={
                    !editProductClicked
                      ? productDetail.image.landing_banner.desktop.thumb_aws
                      : newProductDetail.image.landing_banner.desktop.thumb_aws
                  }
                  onChangeHandler={(
                    // eslint-disable-next-line camelcase
                    jpeg_aws: string,
                    // eslint-disable-next-line camelcase
                    thumb_aws: string,
                    // eslint-disable-next-line camelcase
                    original_aws: string,
                  ): void => {
                    const tempImage = newProductDetail.image
                    tempImage.landing_banner.desktop = {
                      jpeg_aws,
                      thumb_aws,
                      original_aws,
                    }
                    setNewProductDetail({ ...newProductDetail, image: tempImage })
                  }}
                ></UploadImage>
              ) : (
                <div>
                  {productDetail.image.landing_banner.desktop.jpeg_aws ? (
                    <img
                      onClick={(): Window =>
                        window.open(
                          productDetail.image.landing_banner.desktop.original_aws
                            ? productDetail.image.landing_banner.desktop.original_aws
                            : productDetail.image.landing_banner.desktop.jpeg_aws,
                        )
                      }
                      src={productDetail.image.landing_banner.desktop.thumb_aws}
                    ></img>
                  ) : (
                    '저정된 사진이 없습니다!'
                  )}
                </div>
              )}
              <div className={styles.dataField}>/landing banner에 나오는 사진 - Mobile</div>
              {editProductClicked ? (
                <UploadImage
                  currentImage={
                    !editProductClicked
                      ? productDetail.image.landing_banner.mobile.thumb_aws
                      : newProductDetail.image.landing_banner.mobile.thumb_aws
                  }
                  onChangeHandler={(
                    // eslint-disable-next-line camelcase
                    jpeg_aws: string,
                    // eslint-disable-next-line camelcase
                    thumb_aws: string,
                    // eslint-disable-next-line camelcase
                    original_aws: string,
                  ): void => {
                    const tempImage = newProductDetail.image
                    tempImage.landing_banner.mobile = {
                      jpeg_aws,
                      thumb_aws,
                      original_aws,
                    }
                    setNewProductDetail({ ...newProductDetail, image: tempImage })
                  }}
                ></UploadImage>
              ) : (
                <div>
                  {productDetail.image.landing_banner.mobile.jpeg_aws ? (
                    <img
                      onClick={(): Window =>
                        window.open(
                          productDetail.image.landing_banner.mobile.original_aws
                            ? productDetail.image.landing_banner.mobile.original_aws
                            : productDetail.image.landing_banner.mobile.jpeg_aws,
                        )
                      }
                      src={productDetail.image.landing_banner.mobile.thumb_aws}
                    ></img>
                  ) : (
                    '저정된 사진이 없습니다!'
                  )}
                </div>
              )}
              <div className={styles.dataField}>/product-guide에 나오는 필름 사진</div>
              {editProductClicked ? (
                <UploadImage
                  currentImage={
                    !editProductClicked
                      ? productDetail.image.product_guide.responsive.thumb_aws
                      : newProductDetail.image.product_guide.responsive.thumb_aws
                  }
                  onChangeHandler={(
                    // eslint-disable-next-line camelcase
                    jpeg_aws: string,
                    // eslint-disable-next-line camelcase
                    thumb_aws: string,
                    // eslint-disable-next-line camelcase
                    original_aws: string,
                  ): void => {
                    const tempImage = newProductDetail.image
                    tempImage.product_guide.responsive = {
                      jpeg_aws,
                      thumb_aws,
                      original_aws,
                    }
                    setNewProductDetail({ ...newProductDetail, image: tempImage })
                  }}
                ></UploadImage>
              ) : (
                <div>
                  {productDetail.image.product_guide.responsive.jpeg_aws ? (
                    <img
                      onClick={(): Window =>
                        window.open(
                          productDetail.image.product_guide.responsive.original_aws
                            ? productDetail.image.product_guide.responsive.original_aws
                            : productDetail.image.product_guide.responsive.jpeg_aws,
                        )
                      }
                      src={productDetail.image.product_guide.responsive.thumb_aws}
                    ></img>
                  ) : (
                    '저정된 사진이 없습니다!'
                  )}
                </div>
              )}
              <div className={styles.dataField}>/product-guide에 나오는 필름 사진의 농도</div>
              <input
                className={editProductClicked ? styles.inputEditing : styles.inputDefault}
                defaultValue={
                  !editProductClicked ? productDetail.image.product_guide.vlt : newProductDetail.image.product_guide.vlt
                }
                disabled={!editProductClicked}
                onChange={(e): void => {
                  const tempImage = newProductDetail.image
                  tempImage.product_guide.vlt = parseFloat(e.target.value)
                  setNewProductDetail({ ...newProductDetail, image: tempImage })
                }}
              />
              {/* 필름농도 */}
              {!editProductClicked ? (
                <div>
                  <div className={styles.dataField}>
                    필름농도 :
                    <input
                      className={styles.inputDefault}
                      defaultValue={Object.keys(productDetail.attribute.film_density)}
                      disabled={true}
                    />
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <th>농도</th>
                        <th>uvr</th>
                        <th>vlr</th>
                        <th>vlt</th>
                        <th>tser</th>
                      </tr>
                      {Object.keys(productDetail.attribute.film_density).map((density, index: React.Key) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input defaultValue={density} disabled={true} />
                            </td>
                            <td>
                              <input
                                defaultValue={productDetail.attribute.film_density[density].uvr}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempAttribute = productDetail.attribute
                                  tempAttribute.film_density[density].uvr = e.target.value
                                  setNewProductDetail({ ...newProductDetail, attribute: tempAttribute })
                                }}
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={productDetail.attribute.film_density[density].vlr}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempAttribute = productDetail.attribute
                                  tempAttribute.film_density[density].vlr = e.target.value
                                  setNewProductDetail({ ...productDetail, attribute: tempAttribute })
                                }}
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={productDetail.attribute.film_density[density].vlt}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempAttribute = productDetail.attribute
                                  tempAttribute.film_density[density].vlt = e.target.value
                                  setNewProductDetail({ ...productDetail, attribute: tempAttribute })
                                }}
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={productDetail.attribute.film_density[density].tser}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempAttribute = productDetail.attribute
                                  tempAttribute.film_density[density].tser = e.target.value
                                  setNewProductDetail({ ...productDetail, attribute: tempAttribute })
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <div className={styles.dataField}>
                    필름 농도 수(자동으로 행이 생성됨)
                    <input
                      type={'number'}
                      className={styles.inputEditing}
                      value={filmDensityCnt}
                      disabled={!editProductClicked}
                      onChange={(e): void => {
                        const tempFilmDensity = []

                        if (id) {
                          for (let i = 0; i < parseFloat(e.target.value); i += 1) {
                            if (i < Object.keys(productContents.product[0].attribute.film_density).length) {
                              const density = Object.keys(productContents.product[0].attribute.film_density)[i]
                              const values = productContents.product[0].attribute.film_density[density]
                              tempFilmDensity.push({
                                density,
                                uvr: values.uvr,
                                vlr: values.vlr,
                                vlt: values.vlt,
                                tser: values.tser,
                              })
                            } else {
                              tempFilmDensity.push({
                                density: 0,
                                uvr: 0,
                                vlr: 0,
                                vlt: 0,
                                tser: 0,
                              })
                            }
                          }
                        } else {
                          for (let i = 0; i < parseFloat(e.target.value); i += 1) {
                            tempFilmDensity.push({
                              density: 0,
                              uvr: 0,
                              vlr: 0,
                              vlt: 0,
                              tser: 0,
                            })
                          }
                        }

                        setFilmDensityCnt(parseFloat(e.target.value))

                        setFilmDensity(tempFilmDensity)
                      }}
                    />
                  </div>

                  <table>
                    <tbody>
                      <tr>
                        <th>농도</th>
                        <th>uvr</th>
                        <th>vlr</th>
                        <th>vlt</th>
                        <th>tser</th>
                      </tr>
                      {filmDensity.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                defaultValue={item.density}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempFilmDensity = filmDensity
                                  tempFilmDensity[index].density = e.target.value
                                  setFilmDensity(tempFilmDensity)
                                }}
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={item.uvr}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempFilmDensity = filmDensity
                                  tempFilmDensity[index].uvr = e.target.value
                                  setFilmDensity(tempFilmDensity)
                                }}
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={item.vlr}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempFilmDensity = filmDensity
                                  tempFilmDensity[index].vlr = e.target.value
                                  setFilmDensity(tempFilmDensity)
                                }}
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={item.vlt}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempFilmDensity = filmDensity
                                  tempFilmDensity[index].vlt = e.target.value
                                  setFilmDensity(tempFilmDensity)
                                }}
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={item.tser}
                                disabled={!editProductClicked}
                                onChange={(e): void => {
                                  const tempFilmDensity = filmDensity
                                  tempFilmDensity[index].tser = e.target.value
                                  setFilmDensity(tempFilmDensity)
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
          {!editProductClicked && (
            <div className={styles.priceArea}>
              <table className={styles.tableFrame}>
                <tbody>
                  <tr>
                    <th className={styles.tableTitle}>시공 부위/ 제품</th>
                    <th className={styles.films}>{productDetail.name}</th>
                  </tr>

                  {productItemDetail.map((item: ProductItem, index: React.Key) => {
                    return (
                      <tr key={index}>
                        <td>
                          <input defaultValue={item.sku} disabled={true} />
                        </td>
                        <td>
                          <input defaultValue={item.price} disabled={true} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
