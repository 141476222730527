// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3nJUN {\n  margin: 0 auto;\n  margin-top: 15%;\n  width: 400px;\n  height: 500px;\n  background-color: white;\n  text-align: center; }\n  .index-module__container___3nJUN .index-module__selectBox___3oZVe {\n    margin-top: 50px; }\n  .index-module__container___3nJUN .index-module__btnArea___2nlVM {\n    text-align: -webkit-center;\n    margin-top: 30px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Quote/StoreAndPackagePresetTable/StoreInfoModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,kBAAkB,EAAA;EANpB;IAQI,gBAAgB,EAAA;EARpB;IAYI,0BAA0B;IAC1B,gBAAgB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  margin: 0 auto;\n  margin-top: 15%;\n  width: 400px;\n  height: 500px;\n  background-color: white;\n  text-align: center;\n  .selectBox {\n    margin-top: 50px;\n  }\n\n  .btnArea {\n    text-align: -webkit-center;\n    margin-top: 30px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3nJUN",
	"selectBox": "index-module__selectBox___3oZVe",
	"btnArea": "index-module__btnArea___2nlVM"
};
module.exports = exports;
