import { Button, ButtonGroup, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'
import React from 'react'

import { Cart_Item as CartItem, Order_Item as OrderItem } from '../../../assets/graphql/graphql'
import { getProductDetailTypeAndTextArray } from '../../../utils/utility'
import Text from '../../atoms/Text'
import stylesDesktopDefault from './DesktopDefault.module.scss'

export interface PropsCartItemBox {
  cartItemObject?: CartItem
  orderItemObject?: OrderItem
  detailButtonClickHandler?: () => void
  deleteButtonClickHandler?: () => void
  sendQuoteButtonClickHandler?: () => void
}

const App: React.FunctionComponent<PropsCartItemBox> = ({
  cartItemObject,
  orderItemObject,
  detailButtonClickHandler,
  deleteButtonClickHandler,
  sendQuoteButtonClickHandler,
}: PropsCartItemBox) => {
  const styles = stylesDesktopDefault

  const {
    product_item: productItem,
    product_item_detail: productItemDetail,
    package_detail: packageDetail,
    carByCar,
    storeByStore,
    date_time: dateTime,
    price_final: priceFinal,
    price_extra: pridceExtra,
    price_discount: priceDiscount,
    price_product: priceProduct,
    price_payout: pricePayout,
    price_payout_without_vat: pricePayoutWithoutVat,
    commission_rate: commissionRate,
  } = cartItemObject || orderItemObject

  const isPackage = packageDetail.length > 0
  // 매장, 날짜가 선택이 안되어있으면 상담 필요!
  const chatRequired = !storeByStore || !dateTime

  const productTypeText = isPackage
    ? packageDetail[0].package_type.description
    : productItemDetail[0].productByProduct.product_type.description

  const priceRow = (description: string, value: string): JSX.Element => {
    return (
      <TableRow>
        <TableCell>
          <Text style={{ textAlign: 'left', width: 200 }} className={styles.priceText} text={description}></Text>
        </TableCell>
        <TableCell>
          <Text style={{ textAlign: 'right' }} className={styles.priceText} text={value}></Text>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.productContainer}>
        <div className={styles.header}>
          <div className={styles.leftSection}>
            <Text className={styles.productTypeText} text={productTypeText}></Text>
          </div>
          {/* <div className={styles.rightSection}>
            {deleteButtonClickHandler && (
              <Image className={styles.closeIconImage} clickHandler={deleteButtonClickHandler}></Image>
            )}
          </div> */}
        </div>
        <div className={styles.middle}>
          {isPackage && (
            <div className={styles.upperSection}>
              <Text className={styles.brandText} text={'[카라모라]'}></Text>
              <Text className={styles.productItemNameText} text={packageDetail[0].name}></Text>
            </div>
          )}

          <Text className={styles.carText} text={`선택 차량: ${carByCar.car_maker.name_kr} ${carByCar.model}`}></Text>

          {getProductDetailTypeAndTextArray(productItemDetail, productItem).map(
            ({ productDetailTypeAndText, originalPrice }, index: number) => {
              const [productDetailTypeText, productDetailText] = productDetailTypeAndText

              if (!productDetailTypeText || !productDetailText) return null

              return (
                <div className={styles.productItemBox} key={index}>
                  <Text className={styles.productTypeText} text={productDetailTypeText}></Text>
                  <div className={styles.productDetailSection}>
                    <Text className={styles.productDetailText} text={productDetailText}></Text>
                    <Text className={styles.productPriceText} text={`${originalPrice.toLocaleString()}원`}></Text>
                  </div>
                </div>
              )
            },
          )}
        </div>
        <div className={styles.bottom}>
          {storeByStore && <Text className={styles.storeNameText} text={`매장: ${storeByStore.name}`}></Text>}
          {dateTime && (
            <Text
              className={styles.dateTimeNameText}
              text={`시공 희망일:${moment(dateTime).format('YYYY-MM-DD (ddd)')}`}
            ></Text>
          )}
          {chatRequired && <Text className={styles.notificationText} text={'매장, 시공일 선택필요함!!'}></Text>}
        </div>
      </div>
      <div className={styles.priceContainer}>
        <Text className={styles.header} text={'가격 관련'}></Text>
        <Table style={{ marginBottom: 10 }}>
          <TableBody>
            {priceRow('제품 금액', `${priceProduct.toLocaleString()}원`)}
            {priceRow('추가 발생 금액', `${pridceExtra.toLocaleString()}원`)}
            {priceRow('할인 금액', `${priceDiscount.toLocaleString()}원`)}
            {priceRow('고객 최종 결제 금액', `${priceFinal.toLocaleString()}원`)}
            {priceRow('매장 이체 금액', `${pricePayout.toLocaleString()}원`)}
            {priceRow('매장 이체 금액(VAT 제외)', `${pricePayoutWithoutVat.toLocaleString()}원`)}
            {priceRow('카라모라 마진율(%)', `${commissionRate.toLocaleString()}%`)}
          </TableBody>
        </Table>

        <ButtonGroup
          size="large"
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
        >
          <Button onClick={(): void => detailButtonClickHandler()}>수정하기 (상세보기)</Button>
          <Button onClick={(): void => sendQuoteButtonClickHandler()}>매장에 견적서 보내기</Button>
          <Button onClick={(): void => deleteButtonClickHandler()}>삭제하기</Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default React.memo(App)
