// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__inputContainer___2lbVX {\n  transition: box-shadow 0.3s;\n  margin: 25px 5% 25px 5%;\n  padding: 12px;\n  display: inline-block;\n  background-color: #f0efeb;\n  border: 1px solid #8d99ae;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }\n\n.index-module__title___JNIkD {\n  text-align: center;\n  font-size: 25px;\n  font-weight: 300;\n  line-height: 40px;\n  padding: 5px;\n  margin-top: 30px; }\n\n.index-module__sidoContainer___2PGcj {\n  display: flex;\n  flex-wrap: wrap; }\n  .index-module__sidoContainer___2PGcj .index-module__sidoInput___2Jp1b {\n    display: table-cell;\n    width: 130px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/components/molecule/OrderVisualFilter/index.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,uBAAuB;EACvB,aAAa;EACb,qBAAqB;EACrB,yBAAyB;EACzB,yBAAyB;EACzB,4EAA4E,EAAA;;AAG9E;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB,EAAA;;AAGlB;EACE,aAAa;EACb,eAAe,EAAA;EAFjB;IAKI,mBAAmB;IACnB,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".inputContainer {\n  transition: box-shadow 0.3s;\n  margin: 25px 5% 25px 5%;\n  padding: 12px;\n  display: inline-block;\n  background-color: #f0efeb;\n  border: 1px solid #8d99ae;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n\n.title {\n  text-align: center;\n  font-size: 25px;\n  font-weight: 300;\n  line-height: 40px;\n  padding: 5px;\n  margin-top: 30px;\n}\n\n.sidoContainer {\n  display: flex;\n  flex-wrap: wrap;\n\n  .sidoInput {\n    display: table-cell;\n    width: 130px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"inputContainer": "index-module__inputContainer___2lbVX",
	"title": "index-module__title___JNIkD",
	"sidoContainer": "index-module__sidoContainer___2PGcj",
	"sidoInput": "index-module__sidoInput___2Jp1b"
};
module.exports = exports;
