import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Modal } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

import {
  Address_Sigungu as AddressSigungu,
  Get_Store_DetailQuery as GetStoreDetailQuery,
  Hashtag,
  Membership_Type_Enum as MembershipTypeEnum,
  Profile_Kr_Partner as ProfileKrPartner,
  Store_Account_Role_Enum as StoreAccountRoleEnum,
  Store_And_Hashtag as StoreAndHashtag,
  Subscription_Status_Enum as SubscriptionStatusEnum,
} from '../../assets/graphql/graphql'
import UploadImage from '../../components/organism/UploadImage'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { autoHypenPhoneNumber, checkIsValiedPhoneNumber, parseQueryString } from '../../utils/utility'
import { StoreObject } from '../Store/index'
import CreatePartnerAccountModal from './CreatePartnerAccountModal'
import styles from './index.module.scss'
import {
  DELETE_ALL_STORE_HASHTAG,
  GET_ADDRESS_AND_HASHTAG,
  GET_STORE_DETAIL,
  INSERT_STORE,
  INSERT_STORE_AND_HASHTAG,
  UPDATE_STORE,
} from './query'

interface TypeHashtag {
  store: string
  hashtag: string
  order: number
  // eslint-disable-next-line camelcase
}

interface TypeCheckboxHashtag {
  checked: boolean
  name: string
  id: string
}

const App: React.FunctionComponent = () => {
  const { changeMainMenu, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const { id, accountId: partnerAccountId } = parseQueryString()

  const statusOptions = ['created', 'approved', 'unapproved']
  const [editStoreClicked, setEditStoreClicked] = useState(!id)
  const [storeImageArray, setStoreImageArray] = useState([])
  const [storeImageCnt, setStoreImageCnt] = useState(0)
  const [hashtagObject, setHashtagObject] = useState<Record<string, TypeCheckboxHashtag>>({})

  const selectedHashtagArray: Array<TypeHashtag> = []

  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [accountId, setAccountId] = useState('')
  const [newStoreDetail, setNewStoreDetail] = useState<StoreObject>(
    !id
      ? {
          status: 'created',
          name: '',
          address_detail: '',
          description: '',
          location: {
            type: 'Point',
            crs: {
              type: 'name',
              properties: {
                name: 'urn:ogc:def:crs:EPSG::4326',
              },
            },
            coordinates: [0, 0],
          },
          attribute: {
            hours: {
              weekday_open_hour: 0,
              weekday_open_minute: 0,
              weekday_close_hour: 0,
              weekday_close_minute: 0,
              weekday_day_off: false,
              weekday_only_reservation: false,
              saturday_open_hour: 0,
              saturday_open_minute: 0,
              saturday_close_hour: 0,
              saturday_close_minute: 0,
              saturday_day_off: false,
              saturday_only_reservation: false,
              sunday_open_hour: 0,
              sunday_open_minute: 0,
              sunday_close_hour: 0,
              sunday_close_minute: 0,
              sunday_day_off: true,
              sunday_only_reservation: false,
              holiday_open_hour: 0,
              holiday_open_minute: 0,
              holiday_close_hour: 0,
              holiday_close_minute: 0,
              holiday_day_off: true,
              holiday_only_reservation: false,
            },
            maestro: false,
            promotion: false,
            grandOpening: false,
            electronicWarranty: false,
          },
          email: '',
          image: [
            {
              jpeg_aws: '',
              thumb_aws: '',
              original_aws: '',
            },
          ],
          address_sigungu: '',
          name_business: '',
          phone_business: '',
        }
      : null,
  )

  const [createPartnerAccountModal, setCreatePartnerAccountModal] = useState<boolean>(false)

  const [getData, { data: storeDetailContents, error }] = useLazyQuery(
    id ? GET_STORE_DETAIL : GET_ADDRESS_AND_HASHTAG,
    {
      variables: { id },
      fetchPolicy: 'no-cache',
    },
  )

  useEffect(() => {
    getData()
  }, [createPartnerAccountModal])

  useEffect(() => {
    if (storeDetailContents) {
      if (id) {
        const storeDetail = (storeDetailContents as GetStoreDetailQuery).store[0]
        const length = storeDetail.image ? storeDetail.image.length : 0
        setStoreImageCnt(length)

        const tempHashtagObject: Record<string, TypeCheckboxHashtag> = {}

        storeDetail.store_and_hashtags.forEach((hashtag: StoreAndHashtag) => {
          tempHashtagObject[hashtag.hashtagByHashtag.id] = {
            checked: true,
            name: hashtag.hashtagByHashtag.name,
            id: hashtag.hashtagByHashtag.id,
          }
        })

        storeDetailContents.hashtag.forEach((hashtag: Hashtag) => {
          if (!tempHashtagObject[hashtag.id]) {
            tempHashtagObject[hashtag.id] = {
              checked: false,
              name: hashtag.name,
              id: hashtag.id,
            }
          }
        })

        setHashtagObject(tempHashtagObject)

        const tempStoreImage = []
        for (let i = 0; i < length; i += 1) {
          tempStoreImage.push({
            jpeg_aws: storeDetail.image[i].jpeg_aws,
            thumb_aws: storeDetail.image[i].thumb_aws,
            original_aws: storeDetail.image[i].original_aws,
          })
        }

        setNewStoreDetail({
          status: storeDetail.status,
          name: storeDetail.name,
          address_detail: storeDetail.address_detail,
          description: storeDetail.description,
          location: storeDetail.location
            ? {
                type: 'Point',
                crs: {
                  type: 'name',
                  properties: {
                    name: 'urn:ogc:def:crs:EPSG::4326',
                  },
                },
                coordinates: [storeDetail.location.coordinates[0], storeDetail.location.coordinates[1]],
              }
            : {
                type: 'Point',
                crs: {
                  type: 'name',
                  properties: {
                    name: 'urn:ogc:def:crs:EPSG::4326',
                  },
                },
                coordinates: [0, 0],
              },
          attribute: {
            hours: {
              weekday_open_hour:
                storeDetail.attribute && storeDetail.attribute.hours.weekday_open_hour
                  ? storeDetail.attribute.hours.weekday_open_hour
                  : 0,
              weekday_open_minute:
                storeDetail.attribute && storeDetail.attribute.hours.weekday_open_minute
                  ? storeDetail.attribute.hours.weekday_open_minute
                  : 0,
              weekday_close_hour:
                storeDetail.attribute && storeDetail.attribute.hours.weekday_close_hour
                  ? storeDetail.attribute.hours.weekday_close_hour
                  : 0,
              weekday_close_minute:
                storeDetail.attribute && storeDetail.attribute.hours.weekday_close_minute
                  ? storeDetail.attribute.hours.weekday_close_minute
                  : 0,
              weekday_day_off:
                storeDetail.attribute && storeDetail.attribute.hours.weekday_day_off
                  ? storeDetail.attribute.hours.weekday_day_off
                  : false,
              weekday_only_reservation:
                storeDetail.attribute && storeDetail.attribute.hours.weekday_only_reservation
                  ? storeDetail.attribute.hours.weekday_only_reservation
                  : false,
              saturday_open_hour:
                storeDetail.attribute && storeDetail.attribute.hours.saturday_open_hour
                  ? storeDetail.attribute.hours.saturday_open_hour
                  : 0,
              saturday_open_minute:
                storeDetail.attribute && storeDetail.attribute.hours.saturday_open_minute
                  ? storeDetail.attribute.hours.saturday_open_minute
                  : 0,
              saturday_close_hour:
                storeDetail.attribute && storeDetail.attribute.hours.saturday_close_hour
                  ? storeDetail.attribute.hours.saturday_close_hour
                  : 0,
              saturday_close_minute:
                storeDetail.attribute && storeDetail.attribute.hours.saturday_close_minute
                  ? storeDetail.attribute.hours.saturday_close_minute
                  : 0,
              saturday_day_off:
                storeDetail.attribute && typeof storeDetail.attribute.hours.saturday_day_off !== 'undefined'
                  ? storeDetail.attribute.hours.saturday_day_off
                  : false,
              saturday_only_reservation:
                storeDetail.attribute && typeof storeDetail.attribute.hours.saturday_only_reservation !== 'undefined'
                  ? storeDetail.attribute.hours.saturday_only_reservation
                  : false,
              sunday_open_hour:
                storeDetail.attribute && storeDetail.attribute.hours.sunday_open_hour
                  ? storeDetail.attribute.hours.sunday_open_hour
                  : 0,
              sunday_open_minute:
                storeDetail.attribute && storeDetail.attribute.hours.sunday_open_minute
                  ? storeDetail.attribute.hours.sunday_open_minute
                  : 0,
              sunday_close_hour:
                storeDetail.attribute && storeDetail.attribute.hours.sunday_close_hour
                  ? storeDetail.attribute.hours.sunday_close_hour
                  : 0,
              sunday_close_minute:
                storeDetail.attribute && storeDetail.attribute.hours.sunday_close_minute
                  ? storeDetail.attribute.hours.sunday_close_minute
                  : 0,
              sunday_day_off:
                storeDetail.attribute && typeof storeDetail.attribute.hours.sunday_day_off !== 'undefined'
                  ? storeDetail.attribute.hours.sunday_day_off
                  : true,
              sunday_only_reservation:
                storeDetail.attribute && typeof storeDetail.attribute.hours.sunday_only_reservation !== 'undefined'
                  ? storeDetail.attribute.hours.sunday_only_reservation
                  : false,
              holiday_open_hour:
                storeDetail.attribute && storeDetail.attribute.hours.holiday_open_hour
                  ? storeDetail.attribute.hours.holiday_open_hour
                  : 0,
              holiday_open_minute:
                storeDetail.attribute && storeDetail.attribute.hours.holiday_open_minute
                  ? storeDetail.attribute.hours.holiday_open_minute
                  : 0,
              holiday_close_hour:
                storeDetail.attribute && storeDetail.attribute.hours.holiday_close_hour
                  ? storeDetail.attribute.hours.holiday_close_hour
                  : 0,
              holiday_close_minute:
                storeDetail.attribute && storeDetail.attribute.hours.holiday_close_minute
                  ? storeDetail.attribute.hours.holiday_close_minute
                  : 0,
              holiday_day_off:
                storeDetail.attribute && typeof storeDetail.attribute.hours.holiday_day_off !== 'undefined'
                  ? storeDetail.attribute.hours.holiday_day_off
                  : true,
              holiday_only_reservation:
                storeDetail.attribute && typeof storeDetail.attribute.hours.holiday_only_reservation !== 'undefined'
                  ? storeDetail.attribute.hours.holiday_only_reservation
                  : false,
            },
            maestro: storeDetail.attribute ? storeDetail.attribute.maestro : false,
            promotion: storeDetail.attribute ? storeDetail.attribute.promotion : false,
            grandOpening: storeDetail.attribute ? storeDetail.attribute.grandOpening : false,
            electronicWarranty: storeDetail.attribute ? storeDetail.attribute.electronicWarranty : false,
          },
          email: storeDetail.email,
          image:
            length === 1
              ? [
                  {
                    jpeg_aws: storeDetail.image[0].jpeg_aws,
                    thumb_aws: storeDetail.image[0].thumb_aws,
                    original_aws: storeDetail.image[0].original_aws,
                  },
                ]
              : tempStoreImage,
          address_sigungu: storeDetail.address_sigungu ? storeDetail.address_sigungu : '',
          name_business: storeDetail.name_business,
          phone_business: autoHypenPhoneNumber(new PhoneNumber(storeDetail.phone_business).getNumber('national')),
        })

        setStoreImageArray(tempStoreImage)

        const storeDetailStoreAccount = (storeDetailContents as GetStoreDetailQuery).storeAccount[0]
        setAccountId(
          storeDetailStoreAccount &&
            storeDetailStoreAccount.accountByAccount &&
            storeDetailStoreAccount.accountByAccount.profile_kr_partner
            ? storeDetailStoreAccount.accountByAccount.profile_kr_partner.account
            : '',
        )
      }
    }

    // insert postpaid store
    if (partnerAccountId) {
      setAccountId(partnerAccountId)
    }
  }, [storeDetailContents])

  if (error) {
    Sentry.captureException(error)
  }
  if (!newStoreDetail || !storeDetailContents) return null

  const store = newStoreDetail
  const { address, account } = storeDetailContents

  const accountOption = account.sort((a: ProfileKrPartner, b: ProfileKrPartner) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }

    return 0
  })

  const updateStoreClickHandler = async () => {
    try {
      await client.mutate({
        mutation: UPDATE_STORE,
        variables: {
          id,
          newStoreDetail: {
            ...newStoreDetail,
            image: storeImageArray,
            address_sigungu: newStoreDetail.address_sigungu === '' ? null : newStoreDetail.address_sigungu,
            phone_business: new PhoneNumber(newStoreDetail.phone_business.replace(/-/gi, ''), 'KR').getNumber('e164'),
            approved_at: newStoreDetail.status === 'approved' ? new Date().toUTCString() : null,
          },
          newStoreAccount: {
            account: accountId,
          },
        },
      })
      Object.keys(hashtagObject).forEach((hashtagKeyId: string, index) => {
        if (hashtagObject[hashtagKeyId].checked) {
          selectedHashtagArray.push({
            store: id,
            hashtag: hashtagObject[hashtagKeyId].id,
            order: index + 1,
          })
        }
      })

      await client.mutate({
        mutation: DELETE_ALL_STORE_HASHTAG,
        variables: { id },
      })

      await client.mutate({
        mutation: INSERT_STORE_AND_HASHTAG,
        variables: { selectedHashtagArray },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '수정 성공' })

      getData()
      setEditStoreClicked(false)
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '수정 실패' })
    }
  }

  const insertStoreClickHandler = async () => {
    if (!newStoreDetail.name) {
      changeSnackbarAlertContent({ severity: 'warning', content: '매장명을 입력해주세요!!' })

      return
    }

    if (!accountId) {
      changeSnackbarAlertContent({
        severity: 'warning',
        content: '매장에 연결될 계정을 선택해주세요!!',
      })

      return
    }

    if (!newStoreDetail.phone_business || !checkIsValiedPhoneNumber(newStoreDetail.phone_business)) {
      changeSnackbarAlertContent({
        severity: 'warning',
        content: '휴대폰 번호를 정확히 입력해주세요!!',
      })

      return
    }

    if (!newStoreDetail.address_sigungu) {
      changeSnackbarAlertContent({ severity: 'warning', content: '지역을 선택해주세요!!' })

      return
    }
    if (!newStoreDetail.location.coordinates[0] || !newStoreDetail.location.coordinates[1]) {
      changeSnackbarAlertContent({
        severity: 'warning',
        content: '위도와 경도를 제대로 입력했는지 확인해주세요!',
      })

      return
    }

    try {
      const insertObject = {
        ...newStoreDetail,
        phone_business: new PhoneNumber(newStoreDetail.phone_business.replace(/-/gi, ''), 'KR').getNumber('e164'),
        store_and_accounts: {
          data: {
            account: accountId,
            role: StoreAccountRoleEnum.Owner,
          },
        },
        memberships: {
          data: {
            type: MembershipTypeEnum.Bronze,
            start_date: moment(Date.now()).utc().toISOString(),
            end_date: moment(new Date('9999-12-31')).utc().toISOString(),
            price: 0,
          },
        },
        subscriptions: {
          data: {
            account: accountId,
            status: SubscriptionStatusEnum.None,
            price: 0,
          },
        },
      }

      const {
        data: { insert_store_one: newStore },
      } = await client.mutate({
        mutation: INSERT_STORE,
        variables: {
          insertObject,
        },
      })

      changeSnackbarAlertContent({ severity: 'success', content: '매장 추가 성공!!' })
      changeMainMenu(`/store-detail?id=${newStore.id}`)
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '매장 추가 실패!!' })
    }
  }

  return (
    <div className="body">
      <Modal
        open={createPartnerAccountModal}
        onClose={(): void => setCreatePartnerAccountModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CreatePartnerAccountModal />
      </Modal>
      <div className={styles.container}>
        매장 상세
        <div className={styles.editButtonArea}>
          {!editStoreClicked && (
            <button
              className={styles.addStoreButton}
              onClick={(): void => {
                setEditStoreClicked(true)
              }}
            >
              매장 정보 수정
            </button>
          )}

          {editStoreClicked && (
            <div>
              <button
                className={styles.button}
                onClick={async (): Promise<void> => {
                  if (id) {
                    updateStoreClickHandler()
                  } else {
                    insertStoreClickHandler()
                  }
                }}
              >
                저장하기
              </button>
            </div>
          )}
        </div>
        승인여부 :{' '}
        <select
          className={styles.selectBox}
          value={!editStoreClicked ? store.status : newStoreDetail.status}
          onChange={(e): void => {
            setNewStoreDetail({ ...newStoreDetail, status: e.target.value })
          }}
          disabled={!editStoreClicked || !id}
        >
          {statusOptions.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            )
          })}
        </select>
        <div className={styles.infoFrame}>
          계정 연결 :
          <ReactSelect
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            isDisabled={!editStoreClicked}
            value={
              accountOption.filter((item: ProfileKrPartner) => item.account === accountId).length > 0
                ? {
                    value: accountId,
                    label: `${
                      accountOption.filter((item: ProfileKrPartner) => item.account === accountId)[0].name
                    }  /  ${accountOption.filter((item: ProfileKrPartner) => item.account === accountId)[0].email}`,
                  }
                : {
                    value: '',
                    label: '',
                  }
            }
            onChange={(data) => setAccountId(data.value)}
            isSearchable
            options={[
              { value: '', label: '선택 안함' },
              ...accountOption.map((item: ProfileKrPartner) => {
                return {
                  value: item.account,
                  label: `${item.name}  /  ${item.email}`,
                }
              }),
            ]}
          />
          {!id && (
            <Button variant="contained" color="primary" onClick={() => setCreatePartnerAccountModal(true)}>
              계정 추가
            </Button>
          )}
        </div>
        <div className={styles.infoFrame}>
          <div className={styles.dataField}>
            매장명 :
            <input
              className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editStoreClicked ? store.name : newStoreDetail.name}
              onChange={(e): void => {
                setNewStoreDetail({ ...newStoreDetail, name: e.target.value })
              }}
              disabled={!editStoreClicked}
            />
          </div>

          <div className={styles.dataField}>
            사업자명 :
            <input
              className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editStoreClicked ? store.name_business : newStoreDetail.name_business}
              onChange={(e): void => {
                setNewStoreDetail({ ...newStoreDetail, name_business: e.target.value })
              }}
              disabled={!editStoreClicked}
            />
          </div>

          <div className={styles.dataField}>
            이메일 :
            <input
              className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editStoreClicked ? store.email : newStoreDetail.email}
              onChange={(e): void => {
                setNewStoreDetail({ ...newStoreDetail, email: e.target.value })
              }}
              disabled={!editStoreClicked}
            />
          </div>

          <div className={styles.dataField}>
            휴대폰 번호 :
            <input
              className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
              value={!editStoreClicked ? store.phone_business : newStoreDetail.phone_business}
              onChange={(e): void => {
                setNewStoreDetail({ ...newStoreDetail, phone_business: autoHypenPhoneNumber(e.target.value) })
              }}
              disabled={!editStoreClicked}
            />
          </div>
          <div className={styles.dataField}>
            지역 :
            <select
              className={styles.selectBox}
              value={!editStoreClicked ? store.address_sigungu : newStoreDetail.address_sigungu}
              onChange={(e): void => {
                setNewStoreDetail({ ...newStoreDetail, address_sigungu: e.target.value })
              }}
              disabled={!editStoreClicked}
            >
              <option>지역 선택</option>
              {address
                .sort((a: AddressSigungu, b: AddressSigungu) => {
                  if (a.name < b.name) {
                    return -1
                  }
                  if (a.name > b.name) {
                    return 1
                  }

                  return 0
                })
                .sort((a: AddressSigungu, b: AddressSigungu) => {
                  // eslint-disable-next-line no-self-compare
                  if (a.address_sido.name < b.address_sido.name) {
                    return -1
                  }
                  // eslint-disable-next-line no-self-compare
                  if (a.address_sido.name > b.address_sido.name) {
                    return 1
                  }

                  return 0
                })
                .map((area: AddressSigungu, index: number) => {
                  return (
                    <option key={index} value={area.id}>
                      {`${area.address_sido.name}    ${area.name}`}
                    </option>
                  )
                })}
            </select>
          </div>
          <div className={styles.dataField}>
            매장 주소 :
            <input
              className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editStoreClicked ? store.address_detail : newStoreDetail.address_detail}
              onChange={(e): void => {
                setNewStoreDetail({ ...newStoreDetail, address_detail: e.target.value })
              }}
              disabled={!editStoreClicked}
            />
          </div>
          <div className={styles.dataField}>
            위도 :
            <input
              className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editStoreClicked ? store.location.coordinates[1].toString() : latitude}
              placeholder={'위도 ex) 32.1242'}
              type={'number'}
              onChange={(e): void => {
                setLatitude(e.target.value)
                setNewStoreDetail({
                  ...newStoreDetail,
                  location: {
                    ...newStoreDetail.location,
                    coordinates: [newStoreDetail.location.coordinates[0], parseFloat(e.target.value)],
                  },
                })
              }}
              disabled={!editStoreClicked}
            />
          </div>
          <div className={styles.dataField}>
            경도 :
            <input
              className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
              defaultValue={!editStoreClicked ? store.location.coordinates[0].toString() : longitude}
              placeholder={'경도 ex) 123.4531'}
              type={'number'}
              onChange={(e): void => {
                setLongitude(e.target.value)
                setNewStoreDetail({
                  ...newStoreDetail,
                  location: {
                    ...newStoreDetail.location,
                    coordinates: [parseFloat(e.target.value) as number, newStoreDetail.location.coordinates[1]],
                  },
                })
              }}
              disabled={!editStoreClicked}
            />
          </div>

          {id && (
            <div>
              <div className={styles.dataField}>매장 이미지</div>

              {editStoreClicked ? (
                <div>
                  <input
                    type={'number'}
                    value={storeImageCnt}
                    disabled={!editStoreClicked}
                    onChange={(e): void => {
                      let tempStoreImageArray: Array<Record<string, string>> = []

                      if (id) {
                        if (parseInt(e.target.value, 10) === 0) {
                          tempStoreImageArray = [{ jpeg: '', thumb: '', jpeg_aws: '', thumb_aws: '' }]
                        }
                        for (let i = 0; i < parseInt(e.target.value, 10); i += 1) {
                          if (i < storeImageArray.length && storeImageArray[i].jpeg_aws) {
                            tempStoreImageArray.push({
                              jpeg: storeImageArray[i].jpeg,
                              thumb: storeImageArray[i].thumb,
                              jpeg_aws: storeImageArray[i].jpeg_aws,
                              thumb_aws: storeImageArray[i].thumb_aws,
                            })
                          } else {
                            tempStoreImageArray.push({
                              jpeg: '',
                              thumb: '',
                              jpeg_aws: '',
                              thumb_aws: '',
                            })
                          }
                        }
                      } else {
                        for (let i = 0; i < parseInt(e.target.value, 10); i += 1) {
                          tempStoreImageArray.push({
                            jpeg: '',
                            thumb: '',
                            jpeg_aws: '',
                            thumb_aws: '',
                          })
                        }
                      }

                      setStoreImageCnt(parseInt(e.target.value, 10))
                      setStoreImageArray(tempStoreImageArray)
                    }}
                  />

                  <div>
                    {storeImageCnt === 0 ? null : (
                      <div>
                        {storeImageArray.map((item, index) => {
                          return (
                            <div className={styles.dataField} key={index}>
                              <button
                                value={index}
                                onClick={(): void => {
                                  if (index <= 0) return

                                  const tempStoreImageArray: Array<Record<string, string>> = [...storeImageArray]

                                  const tmp = tempStoreImageArray[index]
                                  tempStoreImageArray[index] = tempStoreImageArray[index - 1]
                                  tempStoreImageArray[index - 1] = tmp
                                  setStoreImageArray(tempStoreImageArray)
                                }}
                              >
                                위로
                              </button>
                              <button
                                value={index}
                                onClick={(): void => {
                                  if (index >= storeImageArray.length - 1) return

                                  const tempStoreImageArray: Array<Record<string, string>> = [...storeImageArray]

                                  const tmp = tempStoreImageArray[index]
                                  tempStoreImageArray[index] = tempStoreImageArray[index + 1]
                                  tempStoreImageArray[index + 1] = tmp
                                  setStoreImageArray(tempStoreImageArray)
                                }}
                              >
                                아래로
                              </button>
                              <UploadImage
                                currentImage={item ? item.jpeg_aws : ''}
                                onChangeHandler={(
                                  // eslint-disable-next-line camelcase
                                  jpeg_aws: string,
                                  // eslint-disable-next-line camelcase
                                  thumb_aws: string,
                                  // eslint-disable-next-line camelcase
                                  original_aws: string,
                                ): void => {
                                  const tempImage = storeImageArray
                                  tempImage[index] = {
                                    jpeg_aws,
                                    thumb_aws,
                                    original_aws,
                                  }

                                  setNewStoreDetail({ ...newStoreDetail, image: tempImage })
                                  setStoreImageArray(tempImage)
                                }}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {store.image.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>{index + 1}번 째 매장 이미지</div>
                        {item.jpeg_aws !== undefined && item.jpeg_aws ? (
                          <img
                            onClick={(): Window => window.open(item.original_aws ? item.original_aws : item.jpeg_aws)}
                            style={{ width: 220, height: 150 }}
                            src={item.thumb_aws}
                          />
                        ) : (
                          '지정된 사진이 없습니다!'
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

              <div className={styles.dataField}>매장 소개</div>
              <textarea
                className={editStoreClicked ? styles.textareaEditing : styles.textareaDefault}
                defaultValue={!editStoreClicked ? store.description : newStoreDetail.description}
                onChange={(e): void => {
                  setNewStoreDetail({ ...newStoreDetail, description: e.target.value })
                }}
                disabled={!editStoreClicked}
              />
              <div className={styles.dataField}>◎ 운영시간</div>
              <div className={styles.data}> - 24H 를 사용해주세요. ex) 9시 ~ 17시</div>
              <div className={styles.data}>
                평일 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.weekday_open_hour
                      ? store.attribute.hours.weekday_open_hour.toString()
                      : newStoreDetail.attribute.hours.weekday_open_hour.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          weekday_open_hour: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked}
                />
                시{' '}
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.weekday_open_minute
                      ? store.attribute.hours.weekday_open_minute.toString()
                      : newStoreDetail.attribute.hours.weekday_open_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          weekday_open_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked}
                />
                분 ~{' '}
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.weekday_close_hour
                      ? store.attribute.hours.weekday_close_hour.toString()
                      : newStoreDetail.attribute.hours.weekday_close_hour.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          weekday_close_hour: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  placeholder={'13 or 14'}
                  disabled={!editStoreClicked}
                />
                시
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.weekday_close_minute
                      ? store.attribute.hours.weekday_close_minute.toString()
                      : newStoreDetail.attribute.hours.weekday_close_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          weekday_close_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked}
                />
                분
              </div>
              <div className={styles.data}>
                토요일 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.saturday_open_hour
                      ? store.attribute.hours.saturday_open_hour.toString()
                      : newStoreDetail.attribute.hours.saturday_open_hour.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          saturday_open_hour: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.saturday_day_off ||
                    newStoreDetail.attribute.hours.saturday_only_reservation
                  }
                />
                시{' '}
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.saturday_open_minute
                      ? store.attribute.hours.saturday_open_minute.toString()
                      : newStoreDetail.attribute.hours.saturday_open_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          saturday_open_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.saturday_day_off ||
                    newStoreDetail.attribute.hours.saturday_only_reservation
                  }
                />
                분 ~
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.saturday_close_hour
                      ? store.attribute.hours.saturday_close_hour.toString()
                      : newStoreDetail.attribute.hours.saturday_close_hour.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          saturday_close_hour: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.saturday_day_off ||
                    newStoreDetail.attribute.hours.saturday_only_reservation
                  }
                />
                시
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.saturday_close_minute
                      ? store.attribute.hours.saturday_close_minute.toString()
                      : newStoreDetail.attribute.hours.saturday_close_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          saturday_close_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.saturday_day_off ||
                    newStoreDetail.attribute.hours.saturday_only_reservation
                  }
                />
                분 &nbsp;&nbsp;&nbsp;휴무
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type={'checkbox'}
                  defaultChecked={store.attribute && store.attribute && store.attribute.hours.saturday_day_off}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          saturday_day_off: e.target.checked,
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked || newStoreDetail.attribute.hours.saturday_only_reservation}
                />
                &nbsp;&nbsp;&nbsp;예약시공만 진행
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type={'checkbox'}
                  defaultChecked={store.attribute && store.attribute && store.attribute.hours.saturday_only_reservation}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          saturday_only_reservation: e.target.checked,
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked || newStoreDetail.attribute.hours.saturday_day_off}
                />
              </div>
              <div className={styles.data}>
                일요일 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.sunday_open_hour
                      ? store.attribute.hours.sunday_open_hour.toString()
                      : newStoreDetail.attribute.hours.sunday_open_hour.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          sunday_open_hour: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.sunday_day_off ||
                    newStoreDetail.attribute.hours.sunday_only_reservation
                  }
                />
                시{' '}
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.sunday_open_minute
                      ? store.attribute.hours.sunday_open_minute.toString()
                      : newStoreDetail.attribute.hours.sunday_open_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          sunday_open_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.sunday_day_off ||
                    newStoreDetail.attribute.hours.sunday_only_reservation
                  }
                />
                분 ~
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.sunday_close_minute
                      ? store.attribute.hours.sunday_close_minute.toString()
                      : newStoreDetail.attribute.hours.sunday_close_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          sunday_close_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.sunday_day_off ||
                    newStoreDetail.attribute.hours.sunday_only_reservation
                  }
                />
                시
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.sunday_close_minute
                      ? store.attribute.hours.sunday_close_minute.toString()
                      : newStoreDetail.attribute.hours.sunday_close_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          sunday_close_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.sunday_day_off ||
                    newStoreDetail.attribute.hours.sunday_only_reservation
                  }
                />
                분 &nbsp;&nbsp;&nbsp;휴무
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type={'checkbox'}
                  defaultChecked={store.attribute && store.attribute && store.attribute.hours.sunday_day_off}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          sunday_day_off: e.target.checked,
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked || newStoreDetail.attribute.hours.sunday_only_reservation}
                />
                &nbsp;&nbsp;&nbsp;예약시공만 진행
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type={'checkbox'}
                  defaultChecked={store.attribute && store.attribute && store.attribute.hours.sunday_only_reservation}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          sunday_only_reservation: e.target.checked,
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked || newStoreDetail.attribute.hours.sunday_day_off}
                />
              </div>
              <div className={styles.data}>
                공휴일 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.holiday_open_hour
                      ? store.attribute.hours.holiday_open_hour.toString()
                      : newStoreDetail.attribute.hours.holiday_open_hour.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          holiday_open_hour: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.holiday_day_off ||
                    newStoreDetail.attribute.hours.holiday_only_reservation
                  }
                />
                시{' '}
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.holiday_open_minute
                      ? store.attribute.hours.holiday_open_minute.toString()
                      : newStoreDetail.attribute.hours.holiday_open_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          holiday_open_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.holiday_day_off ||
                    newStoreDetail.attribute.hours.holiday_only_reservation
                  }
                />
                분 ~
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.holiday_close_hour
                      ? store.attribute.hours.holiday_close_hour.toString()
                      : newStoreDetail.attribute.hours.holiday_close_hour.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          holiday_close_hour: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.holiday_day_off ||
                    newStoreDetail.attribute.hours.holiday_only_reservation
                  }
                />
                시
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  defaultValue={
                    !editStoreClicked && store.attribute && store.attribute.hours.holiday_close_minute
                      ? store.attribute.hours.holiday_close_minute.toString()
                      : newStoreDetail.attribute.hours.holiday_close_minute.toString()
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          holiday_close_minute: e.target.value === '' ? 0 : parseInt(e.target.value, 10),
                        },
                      },
                    })
                  }}
                  disabled={
                    !editStoreClicked ||
                    newStoreDetail.attribute.hours.holiday_day_off ||
                    newStoreDetail.attribute.hours.holiday_only_reservation
                  }
                />
                분 &nbsp;&nbsp;&nbsp;휴무
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type={'checkbox'}
                  defaultChecked={store.attribute && store.attribute && store.attribute.hours.holiday_day_off}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          holiday_day_off: e.target.checked,
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked || newStoreDetail.attribute.hours.holiday_only_reservation}
                />
                &nbsp;&nbsp;&nbsp;예약시공만 진행
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type={'checkbox'}
                  defaultChecked={store.attribute && store.attribute && store.attribute.hours.holiday_only_reservation}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        hours: {
                          ...newStoreDetail.attribute.hours,
                          holiday_only_reservation: e.target.checked,
                        },
                      },
                    })
                  }}
                  disabled={!editStoreClicked || newStoreDetail.attribute.hours.holiday_day_off}
                />
              </div>

              <div className={styles.dataField}>◎ 매장 특징</div>
              <div className={styles.data}>
                전자 보증서 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type="checkbox"
                  checked={
                    !editStoreClicked ? store.attribute.electronicWarranty : newStoreDetail.attribute.electronicWarranty
                  }
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        electronicWarranty: e.target.checked,
                      },
                    })
                  }}
                  disabled={!editStoreClicked}
                />
              </div>
              <div className={styles.data}>
                신규 시공점 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type="checkbox"
                  checked={!editStoreClicked ? store.attribute.grandOpening : newStoreDetail.attribute.grandOpening}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        grandOpening: e.target.checked,
                      },
                    })
                  }}
                  disabled={!editStoreClicked}
                />
              </div>
              <div className={styles.data}>
                특가 진행중 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type="checkbox"
                  checked={!editStoreClicked ? store.attribute.promotion : newStoreDetail.attribute.promotion}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        promotion: e.target.checked,
                      },
                    })
                  }}
                  disabled={!editStoreClicked}
                />
              </div>

              <div className={styles.data}>
                명장의 집 :
                <input
                  className={editStoreClicked ? styles.inputEditing : styles.inputDefault}
                  type="checkbox"
                  checked={!editStoreClicked ? store.attribute.maestro : newStoreDetail.attribute.maestro}
                  onChange={(e): void => {
                    setNewStoreDetail({
                      ...newStoreDetail,
                      attribute: {
                        ...newStoreDetail.attribute,
                        maestro: e.target.checked,
                      },
                    })
                  }}
                  disabled={!editStoreClicked}
                />
              </div>

              <div>
                <div className={styles.dataField}>
                  ◎ 해시태그 (선택된 해시 태그 중에 위쪽부터 우선순위가 부여됩니다.) :
                </div>
                <table>
                  <tbody>
                    <tr>
                      <th>No.</th>
                      {editStoreClicked && <th className={styles.priorityColumn}>우선순위 변경</th>}
                      <th className={styles.checkedColumn}>선택</th>
                      <th>해시 태그</th>
                    </tr>
                    {Object.keys(hashtagObject).map((hashtagKeyId: string, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          {editStoreClicked && (
                            <td className={styles.priorityColumn}>
                              <button
                                value={index}
                                onClick={(): void => {
                                  if (index <= 0) return

                                  const hashtagObjectKeys = Object.keys(hashtagObject)

                                  const tempHashtagObjectKeys: Array<string> = [...hashtagObjectKeys]

                                  tempHashtagObjectKeys[index] = hashtagObjectKeys[index - 1]
                                  tempHashtagObjectKeys[index - 1] = hashtagObjectKeys[index]

                                  const tempHashtagObject: Record<string, TypeCheckboxHashtag> = {}

                                  tempHashtagObjectKeys.forEach((key) => {
                                    const { checked, name, id: hashtagId } = hashtagObject[key]

                                    tempHashtagObject[key] = {
                                      checked,
                                      name,
                                      id: hashtagId,
                                    }
                                  })

                                  setHashtagObject(tempHashtagObject)
                                }}
                              >
                                위로
                              </button>
                              <button
                                value={index}
                                onClick={(): void => {
                                  const hashtagObjectKeys = Object.keys(hashtagObject)

                                  if (index >= hashtagObjectKeys.length - 1) return

                                  const tempHashtagObjectKeys: Array<string> = [...hashtagObjectKeys]

                                  tempHashtagObjectKeys[index] = hashtagObjectKeys[index + 1]
                                  tempHashtagObjectKeys[index + 1] = hashtagObjectKeys[index]

                                  const tempHashtagObject: Record<string, TypeCheckboxHashtag> = {}

                                  tempHashtagObjectKeys.forEach((key) => {
                                    const { checked, name, id: hashtagId } = hashtagObject[key]

                                    tempHashtagObject[key] = {
                                      checked,
                                      name,
                                      id: hashtagId,
                                    }
                                  })
                                  setHashtagObject(tempHashtagObject)
                                }}
                              >
                                아래로
                              </button>
                            </td>
                          )}
                          <td>
                            <input
                              type="checkbox"
                              checked={hashtagObject[hashtagKeyId].checked}
                              className={styles.checkedColumn}
                              onChange={(): void => {
                                const tempHashtagObject: Record<string, TypeCheckboxHashtag> = { ...hashtagObject }
                                tempHashtagObject[hashtagKeyId].checked = !tempHashtagObject[hashtagKeyId].checked

                                setHashtagObject(tempHashtagObject)
                              }}
                              disabled={!editStoreClicked}
                            />
                          </td>

                          <td>
                            <label>{hashtagObject[hashtagKeyId].name}</label>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
