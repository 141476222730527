import { FormControlLabel, Switch } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import Text from '../../../components/atoms/Text'
import useRootData from '../../../hooks/useRootData'
import ChatBalloon from './ChatBalloon'
import styles from './index.module.scss'
import InputArea from './InputArea'

const App: React.FunctionComponent = () => {
  const { selectedLead } = useRootData(({ leadStore }) => ({
    selectedLead: leadStore.selectedLead.get(),
  }))

  const componentRef = useRef(null)

  const [isAlarmTalkHide, setIsAlarmTalkHide] = useState<boolean>(false)

  const comments = selectedLead ? selectedLead.lead_comments : []

  useEffect(() => {
    if (selectedLead) {
      componentRef.current.scrollTop = componentRef.current.scrollHeight
    }
  }, [selectedLead])

  return (
    <div className={styles.commentsContainer}>
      <div className={styles.header}>
        <Text className={styles.title} text={'Comment'}></Text>
        <FormControlLabel
          control={
            <Switch
              checked={isAlarmTalkHide}
              onChange={(e): void => setIsAlarmTalkHide(e.target.checked)}
              color="primary"
            />
          }
          label="알람톡 내용 안보기"
        />
      </div>
      <div ref={componentRef} className={styles.scrollArea}>
        <div className={styles.scrollableArea}>
          {comments.length > 0 ? (
            comments
              .filter((item) => {
                const rex = /<.*성공.*>/g
                const parseComment = rex.exec(item.comment)

                return !(parseComment && isAlarmTalkHide)
              })
              .map((item) => {
                return <ChatBalloon key={item.id} commentObject={item}></ChatBalloon>
              })
          ) : (
            <div className={styles.noCommentResult}>새로운 코멘트를 남겨주세요.</div>
          )}
        </div>
      </div>
      <InputArea />
    </div>
  )
}

export default React.memo(App)
