// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__tableContainer___2R486 {\n  margin: 100px auto;\n  max-width: 800px; }\n  .index-module__tableContainer___2R486 .index-module__header___2qLI9 .index-module__inputTextField___qIHlB {\n    width: 100%; }\n  .index-module__tableContainer___2R486 .index-module__body___pbJIc .index-module__rowTitle___CCdaN {\n    background-color: #b388ff; }\n    .index-module__tableContainer___2R486 .index-module__body___pbJIc .index-module__rowTitle___CCdaN .index-module__col___1V4Im {\n      color: #ffffff;\n      font-weight: bold; }\n  .index-module__tableContainer___2R486 .index-module__body___pbJIc .index-module__leadRow___5uBGA:nth-child(2n - 1) {\n    background-color: rgba(0, 0, 0, 0.03); }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadDetail/Menu/LeadSearch/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB,EAAA;EAFlB;IAKM,WAAW,EAAA;EALjB;IAUM,yBAAyB,EAAA;IAV/B;MAYQ,cAAc;MACd,iBAAiB,EAAA;EAbzB;IAiBM,qCAAqC,EAAA","file":"index.module.scss","sourcesContent":[".tableContainer {\n  margin: 100px auto;\n  max-width: 800px;\n  .header {\n    .inputTextField {\n      width: 100%;\n    }\n  }\n  .body {\n    .rowTitle {\n      background-color: #b388ff;\n      .col {\n        color: #ffffff;\n        font-weight: bold;\n      }\n    }\n    .leadRow:nth-child(2n - 1) {\n      background-color: rgba(0, 0, 0, 0.03);\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"tableContainer": "index-module__tableContainer___2R486",
	"header": "index-module__header___2qLI9",
	"inputTextField": "index-module__inputTextField___qIHlB",
	"body": "index-module__body___pbJIc",
	"rowTitle": "index-module__rowTitle___CCdaN",
	"col": "index-module__col___1V4Im",
	"leadRow": "index-module__leadRow___5uBGA"
};
module.exports = exports;
