import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import { Config_Type_Enum as ConfigTypeEnum } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import {
  _GET_CONFIG_RATE as GET_CONFIG_RATE,
  _INSERT_NEW_CONFIG_RATE as INSERT_NEW_COMMISSION_RATE,
} from '../../stores/calculator/query'
import client from '../../utils/graphql'
import styles from './index.module.scss'

const App: React.FunctionComponent = () => {
  const { accountId, changeSnackbarAlertContent, getDefaultRates } = useRootData(
    ({ appStore, authStore, calculatorStore }) => ({
      accountId: authStore.accountId.get(),
      changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
      getDefaultRates: calculatorStore.getDefaultRates,
    }),
  )

  const [messageTemplate, setMessageTemplate] = useState(null)
  const [updatedAt, setUpdatedAt] = useState(null)

  useEffect(() => {
    ;(async () => {
      const { csv_content: messageTemplateObject, updated_at: updatedAtData } = (
        await client.query({
          query: GET_CONFIG_RATE(ConfigTypeEnum.RecentMessageTemplate),
        })
      ).data.config[0]

      setUpdatedAt(updatedAtData)
      setMessageTemplate(messageTemplateObject)
    })()
  }, [])

  const saveNewMessageTemplate = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('새로운 템플릿을 저장하겠습니까?')) return

    try {
      const {
        insert_config_one: { updated_at: updatedAtData },
      } = (
        await client.mutate({
          mutation: INSERT_NEW_COMMISSION_RATE,
          variables: {
            object: {
              account: accountId,
              type: ConfigTypeEnum.RecentMessageTemplate,
              csv_content: {
                remind_quote: messageTemplate.remind_quote,
                value_proposition: messageTemplate.value_proposition,
                quote_comparison: messageTemplate.quote_comparison,
                match_to_store: messageTemplate.match_to_store,
                match_to_customer: messageTemplate.match_to_customer,
                leave_request: messageTemplate.leave_request,
                quote_to_store: messageTemplate.quote_to_store,
                order_created_to_customer: messageTemplate.order_created_to_customer,
                resevation_confirmed_to_customer: messageTemplate.resevation_confirmed_to_customer,
                resevation_confirmed_to_store: messageTemplate.resevation_confirmed_to_store,
                payment_link_to_customer: messageTemplate.payment_link_to_customer,
                e_tax_invoice: messageTemplate.e_tax_invoice,
                aftercare: messageTemplate.aftercare,
              },
            },
          },
        })
      ).data

      setUpdatedAt(updatedAtData)

      changeSnackbarAlertContent({ severity: 'success', content: '저장 성공' })
      getDefaultRates()
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '저장(수정) 실패' })
    }
  }

  if (!messageTemplate) return null

  return (
    <div>
      <Button variant="contained" color="primary" onClick={async (): Promise<void> => saveNewMessageTemplate()}>
        새로운 템플릿 저장
      </Button>
      <p>편집할 cell을 선택하세요</p>
      <p>마지막 업데이트: {moment(updatedAt).toLocaleString()}</p>
      <TableContainer className={styles.container} component={Paper}>
        <Table className={styles.table} size="small" aria-label="a dense table">
          <TableHead className={styles.tableHeadContainer}>
            <TableRow className={styles.rowTitle}>
              {[
                '전화 요청 전송시\n[고객]에게',
                '리드 생성시\n[고객]에게',
                '비교 견적 전송시\n[고객]에게',
                '매장 매칭시\n매장에게',
                '매장 매칭시\n고객에게',
                '리퀘스트 남김\n[고객]에게',
                '견적 요청시\n[매장]에게 ',
                '주문 완료시\n[고객]에게',
                '예약 확정시\n[고객]에게',
                '예약 확정시\n[매장]에게',
                '시공 완료시\n[고객]에게',
                '전자세금계산서요청시\n[매장]에게',
                '결제 완료시\n[고객]에게',
              ].map((col, index) => {
                return (
                  <TableCell className={styles.col} key={index} align="center">
                    {col}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBodyContainer}>
            <TableRow className={styles.row}>
              {[
                'remind_quote',
                'value_proposition',
                'quote_comparison',
                'match_to_store',
                'match_to_customer',
                'leave_request',
                'quote_to_store',
                'order_created_to_customer',
                'resevation_confirmed_to_customer',
                'resevation_confirmed_to_store',
                'payment_link_to_customer',
                'e_tax_invoice',
                'aftercare',
              ].map((col, index) => {
                return (
                  <TableCell
                    key={index}
                    className={styles.col}
                    align="center"
                    onClick={() => {
                      // eslint-disable-next-line no-alert
                      const ret = prompt('수정', messageTemplate[col])
                      if (ret) {
                        setMessageTemplate({
                          ...messageTemplate,
                          [col]: ret,
                        })
                      }
                    }}
                  >
                    {`${messageTemplate[col]}`}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default React.memo(App)
