import { CytoscapeOptions } from 'cytoscape'
import React, { useEffect, useState } from 'react'

import { Session } from '../../../assets/graphql/graphql'
import useRootData from '../../../hooks/useRootData'
import styles from './index.module.scss'

type CytoscapeProps = {
  list: Array<Session>
  options?: CytoscapeOptions
}

const App: React.FunctionComponent<CytoscapeProps> = ({ list, options }: CytoscapeProps) => {
  const { init, toggleC } = useRootData(({ graphStore }) => ({
    init: graphStore.init,
    toggleC: graphStore.toggleCentrality,
  }))

  const [session, setSession] = useState('')

  const getOptions = (): CytoscapeOptions => {
    return {
      container: document.getElementById('cy'),
      style: [
        {
          selector: 'node',
          style: {
            'background-color': '#666',
            'border-color': 'black',
            'border-width': '2px',
            label: 'data(id)',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 3,
            content: session ? 'data(order)' : 'data(weight)',
            'line-color': `	#C0C0C0`,
            'target-arrow-color': `	#C0C0C0`,
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
          },
        },
        {
          selector: 'node.semitransp',
          style: { opacity: 0.5 },
        },
        {
          selector: 'node.target',
          style: { color: 'red' },
        },
        {
          selector: 'node.start',
          style: {
            'background-color': 'green',
          },
        },
        {
          selector: 'node.dcni',
          style: {
            'background-color': 'mapData(dcni, 0, 1, #fff, #78281F)',
            'border-color': 'black',
            'border-width': '2px',
          },
        },
        {
          selector: 'node.dcno',
          style: {
            'background-color': 'mapData(dcno, 0, 1, #fff, #78281F)',
            'border-color': 'black',
            'border-width': '2px',
          },
        },
        {
          selector: 'node.bc',
          style: {
            'background-color': 'mapData(bc, 0, 1, #fff, #78281F)',
            'border-color': 'black',
            'border-width': '2px',
          },
        },
        {
          selector: 'node.pr',
          style: {
            'background-color': 'mapData(bc, 0, 1, #fff, #78281F)',
            'border-color': 'black',
            'border-width': '2px',
          },
        },
        {
          selector: 'edge.highlight',
          style: { 'mid-target-arrow-color': '#FFF' },
        },
        { selector: 'edge.semitransp', style: { opacity: 0.2 } },
      ],
      ...options,
    }
  }

  const getSessionData = (sessionId: string) => {
    if (list.find((s) => s.id === sessionId)) {
      return [list.find((s) => s.id === sessionId)]
    }

    return list
  }

  useEffect(() => {
    if (!(list === undefined || list.length === 0)) {
      init(getSessionData(session), getOptions(), {
        name: 'cose',
        fit: true,
        padding: 30,
        randomize: true,
        animationDuration: 10000,

        componentSpacing: 80,

        nodeOverlap: 40,

        nodeRepulsion() {
          return 100000
        },

        // Ideal edge (non nested) length
        idealEdgeLength() {
          return 120
        },

        // Divisor to compute edge forces
        edgeElasticity() {
          return 160
        },

        // Nesting factor (multiplier) to compute ideal edge length for nested edges
        nestingFactor: 100,
      })
    }
  }, [session])

  if (list === undefined || list.length === 0)
    return <div>해당 유저는 볼수 있는 session이 없거나, 데이터 집계가 원활하게 되지 않았습니다!</div>

  return (
    <div className={styles.cy}>
      <button className={styles.button} onClick={() => toggleC('bc')}>
        Betweenness
      </button>
      <button className={styles.button} onClick={() => toggleC('dcni')}>
        Indegree
      </button>
      <button className={styles.button} onClick={() => toggleC('dcno')}>
        Outdegree
      </button>
      <button className={styles.button} onClick={() => toggleC('pr')}>
        PageRank
      </button>
      <select
        value={session}
        onChange={(e): void => {
          setSession(e.target.value)
        }}
        className={styles.select}
      >
        <option value="">All sessions</option>
        {list.map((item: Session, index: number) => {
          return (
            <option key={index} value={item.id}>
              {item.id}
            </option>
          )
        })}
      </select>
      <div id="cy" className={styles.cy} />
    </div>
  )
}
export default React.memo(App)
