import axios from 'axios'
import React, { useEffect, useState } from 'react'

const App: React.FunctionComponent = () => {
  const [iframeUrl, setIframeUrl] = useState('')

  const getContent = (url: string) => {
    const header = { 'Account-Email': 'u31AxVf0QBP8uzkAqLRIs8wzyAk2' }
    axios
      .get(url, { headers: header })
      .then((response) => {
        setIframeUrl(response.data)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  }

  useEffect(() => {
    getContent('https://api.riderdash.com/admin/kibanaIframeCaramora')
  }, [])

  return (
    <div className="body">
      <iframe src={iframeUrl} style={{ minHeight: '900px', width: '100%' }}></iframe>
    </div>
  )
}
export default React.memo(App)
