import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'

import { Account, Get_Account_ListQuery as GetAccountListQuery } from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import styles from './index.module.scss'

const GET_ACCOUNT_LIST = gql`
  query GET_ACCOUNT_LIST {
    accountContent: account(order_by: { created_at: desc }) {
      id
      created_at
      profile_kr_partner {
        id
        name
        email
      }
      profile_riderdash {
        id
      }
    }
  }
`

const INSERT_PROFILE_RIDERDASH_PARTTIMER = gql`
  mutation INSERT_PROFILE_RIDERDASH_PARTTIMER($object: profile_riderdash_insert_input!) {
    insert_profile_riderdash_one(object: $object) {
      id
    }
  }
`

const App: React.FunctionComponent = () => {
  const { sendAxios, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    sendAxios: appStore.sendAxios,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [getData, { data, error }] = useLazyQuery<GetAccountListQuery>(GET_ACCOUNT_LIST, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getData()
  }, [])

  if (!data) return null
  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>파트타이머 계정 관리</div>
        <br />

        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>account Id</th>
              <th className={styles.uuid}>profile_kr_partner Id</th>
              <th className={styles.headerText}>이메일</th>
              <th className={styles.headerText}>이름</th>
              <th></th>
            </tr>
            {data.accountContent
              .filter((account: Account) => {
                return account.profile_kr_partner !== null
              })
              .map((item: Account, index: number) => (
                <tr key={index}>
                  <td>
                    <input defaultValue={data.accountContent.length - index} disabled={true} />
                  </td>
                  <td>
                    <input defaultValue={item.id} disabled={true} />
                  </td>
                  <td>
                    <input defaultValue={item.profile_kr_partner && item.profile_kr_partner.id} disabled={true} />
                  </td>
                  <td>
                    <input defaultValue={item.profile_kr_partner && item.profile_kr_partner.email} disabled={true} />
                  </td>
                  <td>
                    <input defaultValue={item.profile_kr_partner && item.profile_kr_partner.name} disabled={true} />
                  </td>
                  <td>
                    {item.profile_riderdash === null && (
                      <button
                        onClick={async (): Promise<void> => {
                          try {
                            // eslint-disable-next-line no-alert
                            if (window.confirm('해당 계정을 파트타이머 계정으로 전환하시겠습니까?')) {
                              const parttimerObject = {
                                account: item.id,
                                name: item.profile_kr_partner.name,
                                email: item.profile_kr_partner.email,
                                role: 'parttimer',
                              }
                              await client.mutate({
                                mutation: INSERT_PROFILE_RIDERDASH_PARTTIMER,
                                variables: {
                                  object: parttimerObject,
                                },
                              })
                              await sendAxios.post(`${config.backendEndPoint}/auth/update-parttimer-authority`, {
                                email: item.profile_kr_partner.email,
                              })

                              changeSnackbarAlertContent({
                                severity: 'success',
                                content: '파트타이머로 계정 변경 성공',
                              })

                              getData()
                            }
                          } catch (err) {
                            Sentry.captureException(err)
                            changeSnackbarAlertContent({ severity: 'error', content: '변경 실패!!!' })
                          }
                        }}
                      >
                        파트타이머로 전환
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
