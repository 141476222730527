// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___26TNB {\n  padding: 20px; }\n  .index-module__container___26TNB .index-module__number___1_-ai {\n    width: 30px; }\n\n.index-module__paginationWrapper___1Br2l {\n  text-align: center;\n  margin: 10px; }\n\n.index-module__pagination___XGRCp {\n  display: inline-block; }\n\n.index-module__pagination___XGRCp a {\n  color: black;\n  float: left;\n  padding: 8px 16px;\n  text-decoration: none; }\n\n.index-module__pagination___XGRCp a.index-module__active___1g17w {\n  background-color: #ffa516;\n  color: white; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/ProfilePartner/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAGI,WAAW,EAAA;;AAIf;EACE,kBAAkB;EAClB,YAAY,EAAA;;AAGd;EACE,qBAAqB,EAAA;;AAGvB;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,qBAAqB,EAAA;;AAGvB;EACE,yBAAyB;EACzB,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  .number {\n    width: 30px;\n  }\n}\n\n.paginationWrapper {\n  text-align: center;\n  margin: 10px;\n}\n\n.pagination {\n  display: inline-block;\n}\n\n.pagination a {\n  color: black;\n  float: left;\n  padding: 8px 16px;\n  text-decoration: none;\n}\n\n.pagination a.active {\n  background-color: #ffa516;\n  color: white;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___26TNB",
	"number": "index-module__number___1_-ai",
	"paginationWrapper": "index-module__paginationWrapper___1Br2l",
	"pagination": "index-module__pagination___XGRCp",
	"active": "index-module__active___1g17w"
};
module.exports = exports;
