import * as Sentry from '@sentry/react'
import axios from 'axios'

import config from '../configs'
import useRootData from './useRootData'

export default (): {
  sendQuoteToCustomer: (quoteItemId: string, selectedPrice: string) => Promise<void>
  sendQuoteToStore: (quoteItemId: string, selectedPrice: string) => Promise<void>
  resendConfirmToStore: () => Promise<void>
} => {
  const {
    selectedLead,
    orderObject,
    accountId,
    isPartTimer,
    changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility,
  } = useRootData(({ leadStore, orderStore, appStore, authStore }) => ({
    selectedLead: leadStore.selectedLead.get(),
    orderObject: orderStore.orderObject.get(),
    accountId: authStore.accountId.get(),
    isPartTimer: authStore.isPartTimer.get(),

    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
  }))

  return {
    sendQuoteToCustomer: async (quoteItemId: string, selectedPrice: string): Promise<void> => {
      try {
        changeLoadingSpinnerVisibility(true)
        await axios.post(`${config.backendEndPoint}/send/send-quote-customer`, {
          leadId: selectedLead.id,
          quoteItemId,
          selectedPrice,
          accountId,
          isPartTimer,
        })

        changeSnackbarAlertContent({ severity: 'success', content: '카카오톡 전송을 성공하였습니다.' })
      } catch (err) {
        Sentry.captureException(err)
        changeSnackbarAlertContent({ severity: 'error', content: `카카오톡 전송 실패.\n ${err}` })
        changeLoadingSpinnerVisibility(false)
      }
    },
    sendQuoteToStore: async (quoteItemId: string, selectedPrice: string): Promise<void> => {
      try {
        changeLoadingSpinnerVisibility(true)
        await axios.post(`${config.backendEndPoint}/send/send-quote-store`, {
          leadId: selectedLead.id,
          quoteItemId,
          selectedPrice,
          accountId,
        })

        changeSnackbarAlertContent({ severity: 'success', content: '카카오톡 전송을 성공하였습니다.' })
      } catch (err) {
        Sentry.captureException(err)
        changeSnackbarAlertContent({ severity: 'error', content: `카카오톡 전송 실패.\n ${err}` })
        changeLoadingSpinnerVisibility(false)
      }
    },
    resendConfirmToStore: async (): Promise<void> => {
      try {
        changeLoadingSpinnerVisibility(true)

        await axios.post(`${config.backendEndPoint}/send/send-confirm-store`, {
          leadId: selectedLead.id,
          orderId: orderObject.id,
          accountId,
        })

        changeSnackbarAlertContent({ severity: 'success', content: '카카오톡 전송을 성공하였습니다.' })
      } catch (err) {
        Sentry.captureException(err)
        changeSnackbarAlertContent({ severity: 'error', content: `카카오톡 전송 실패.\n ${err}` })
        changeLoadingSpinnerVisibility(false)
      }
    },
  }
}
