import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'

import {
  Accounts_Receivable as AccountsReceivable,
  Get_Accounts_ReceivableQuery as GetAccountsReceivableQuery,
} from '../../assets/graphql/graphql'
import styles from './index.module.scss'

const GET_ACCOUNTS_RECEIVABLE = gql`
  query GET_ACCOUNTS_RECEIVABLE {
    accounts_receivable(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
      id
      created_at
      updated_at
      accounts_receivable_and_memberships {
        membershipByMembership {
          price
          type
          storeByStore {
            name
          }
        }
      }
      accounts_receivable_and_invoices {
        invoiceByInvoice {
          price
          status
          storeByStore {
            name
          }
        }
      }
    }
  }
`
const App: React.FunctionComponent = () => {
  const { data, loading, error } = useQuery<GetAccountsReceivableQuery>(GET_ACCOUNTS_RECEIVABLE, {
    fetchPolicy: 'no-cache',
  })

  if (loading) return null
  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>미수금 목록</div>
        <br />

        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th>타입</th>
              <th className={styles.uuid}>uuid</th>
              <th>생성일자</th>
              <th>업데이트일자</th>
              <th>상태</th>
              <th>금액</th>
              <th>가게명</th>
            </tr>
            {data &&
              data.accounts_receivable.map((item: AccountsReceivable, index: number) => {
                let keys = ''
                let itemsByKeys = ['']
                type tables = 'accounts_receivable_and_invoices' | 'accounts_receivable_and_memberships'
                Object.keys(item).forEach((key) => {
                  if (item[key as tables].length >= 1 && key === 'accounts_receivable_and_memberships') {
                    keys = 'membership'
                    const itemData = item.accounts_receivable_and_memberships[0].membershipByMembership
                    itemsByKeys = [itemData.type, itemData.price, itemData.storeByStore.name]
                  } else if (item[key as tables].length >= 1 && key === 'accounts_receivable_and_invoices') {
                    keys = 'invoice'
                    const itemData = item.accounts_receivable_and_invoices[0].invoiceByInvoice
                    itemsByKeys = [itemData.status, itemData.price, itemData.storeByStore.name]
                  }
                })

                return (
                  <tr key={index}>
                    <td>{data.accounts_receivable.length - index}</td>
                    <td>{keys}</td>
                    <td>{item.id}</td>
                    <td>{moment(item.created_at).format()}</td>
                    <td>{moment(item.updated_at).format()}</td>
                    {(keys && (
                      <React.Fragment>
                        <td>{itemsByKeys[0]}</td>
                        <td>{itemsByKeys[1]}</td>
                        <td>{itemsByKeys[2]}</td>
                      </React.Fragment>
                    )) || (
                      <React.Fragment>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
