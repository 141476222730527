import { TableCell, TextField } from '@material-ui/core'
import React from 'react'

import Text from '../../../../../components/atoms/Text'
import useRootData from '../../../../../hooks/useRootData'
import { LEAD_COL } from '../../../../../stores/lead/type'
import { mappingStyle } from '../../LeadRow'

export interface DateFilterProps {
  col: LEAD_COL
}

const App: React.FunctionComponent<DateFilterProps> = ({ col }: DateFilterProps) => {
  const { searchInputValues, _changeSearchInputValues } = useRootData(({ leadStore }) => ({
    searchInputValues: leadStore.searchInputValues.get(),
    _changeSearchInputValues: leadStore.changeSearchInputValues,
  }))

  const changeSearchInputValues = (
    event: React.ChangeEvent<
      | HTMLTextAreaElement
      | HTMLInputElement
      | {
          name?: string
          value: unknown
        }
    >,
    key: LEAD_COL,
  ) => {
    _changeSearchInputValues({ [key]: event ? (event.target.value as string) : '' })
  }

  return (
    <TableCell
      style={{
        ...mappingStyle[col],
        display: searchInputValues[col] ? 'flex' : null,
        marginRight: searchInputValues[col] ? '5px' : null,
      }}
    >
      <TextField
        style={searchInputValues[col] ? { display: 'flex' } : null}
        type="date"
        value={searchInputValues[col]}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event): void => changeSearchInputValues(event, col)}
      />
      {searchInputValues[col] && (
        <Text
          style={{ alignSelf: 'center' }}
          text={'X'}
          clickHandler={(): void => {
            changeSearchInputValues(null, col)
          }}
        />
      )}
    </TableCell>
  )
}
export default React.memo(App)
