import { IconButton, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core'
import { Add, Delete, Queue, Warning } from '@material-ui/icons'
import React from 'react'

import { Lead_Status_Enum as LeadStatusEnum } from '../../../../../assets/graphql/graphql'
import Select, { TypeSelectBox } from '../../../../../components/atoms/Select'
import useRootData from '../../../../../hooks/useRootData'
import { PriceKey, PriceObject, PriceUpdateObject } from '../../../../../stores/calculator/type'
import styles from './index.module.scss'

export enum RowType {
  ProductRow = 'productRow',
  TotalRow = 'totalRow',
  InputRow = 'inputRow',
  FinalRow = 'finalRow',
  PayoutRow = 'payoutRow',
  FinalPayoutRow = 'finalPayoutRow',
  CustomProductRow = 'customProductRow',
  AppendCustomProductRow = 'appendCustomProductRow',
  ReadOnlyRow = 'readOnlyRow',
  PaymentRow = 'paymentRow',
}

export interface CalculatorTableProps {
  rowType: RowType
  rowTitle: string
  prices?: Partial<PriceObject>
  prevFinalPrice?: Partial<PriceObject>
  idx?: number
  value?: string
  productOptions?: Array<TypeSelectBox>
  customProductIdx?: number
  changeHandler?: (id: string, idx: number) => void
  changeExtraPricesHandler?: (object: PriceUpdateObject, idx: number) => void
  appendCustomProductHandler?: (idx: number) => void
  deleteCustomProductHandler?: (customProductIdx: number, idx: number) => void
  changeCustomProductName?: (productName: string, customProductIdx: number, idx: number) => void
  changeCustomProductPrice?: (object: PriceUpdateObject, customProductIdx: number, idx: number) => void
  forkProductToCustomProductHandler?: () => void
  isPackage?: boolean
  sentAt: {
    price: boolean
    premium: boolean
    package: boolean
    lowest: boolean
  }
  isOrder: boolean
  isAvalableSentQuote: boolean
  priceProduct?: number
}

const App: React.FunctionComponent<CalculatorTableProps> = ({
  rowType,
  rowTitle,
  value,
  productOptions,
  customProductIdx,
  changeHandler,
  changeExtraPricesHandler,
  appendCustomProductHandler,
  deleteCustomProductHandler,
  changeCustomProductName,
  changeCustomProductPrice,
  forkProductToCustomProductHandler,
  prices,
  prevFinalPrice,
  isPackage,
  idx,
  sentAt,
  isOrder,
  isAvalableSentQuote,
  priceProduct,
}: CalculatorTableProps) => {
  const { selectedLead } = useRootData(({ leadStore }) => ({
    selectedLead: leadStore.selectedLead.get(),
  }))

  const getClassName = () => {
    switch (rowType) {
      case RowType.TotalRow:
        return styles.totalPriceRow
      case RowType.FinalRow:
        return styles.finalPriceRow
      case RowType.PayoutRow:
        return styles.payoutRow
      case RowType.FinalPayoutRow:
        return styles.finalPayoutRow
      case RowType.PaymentRow:
        return styles.paymentRow
      default:
    }

    return null
  }

  const getColSpan = () => {
    switch (rowType) {
      case RowType.ProductRow:
      case RowType.CustomProductRow:
        return 1
      case RowType.AppendCustomProductRow:
        return 6
      default:
    }

    return 2
  }

  return (
    <TableRow className={getClassName()} key={rowTitle}>
      <TableCell colSpan={getColSpan()} className={styles.titleCellText} component="th" scope="row">
        {rowType === RowType.ProductRow && (
          <IconButton
            style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px' }}
            color="primary"
            onClick={(): void => forkProductToCustomProductHandler()}
          >
            <Queue />
          </IconButton>
        )}
        {rowTitle}
        {rowType === RowType.CustomProductRow && (
          <IconButton
            style={{ paddingTop: '0px', paddingBottom: '0px' }}
            color="secondary"
            onClick={(): void => deleteCustomProductHandler(customProductIdx, idx)}
          >
            <Delete />
          </IconButton>
        )}
        {rowType === RowType.AppendCustomProductRow && (
          <IconButton
            style={{ paddingTop: '0px', paddingBottom: '0px' }}
            color="primary"
            onClick={(): void => appendCustomProductHandler(idx)}
          >
            <Add />
          </IconButton>
        )}
      </TableCell>
      {rowType !== RowType.AppendCustomProductRow && (
        <>
          {rowType === RowType.ProductRow && (
            <TableCell align={isOrder ? 'left' : 'right'}>
              <Select
                className={styles.selectOption}
                value={value}
                options={productOptions}
                changeHandler={(event): void => changeHandler(event.target.value as string, idx)}
                disabled={productOptions.length === 1}
              ></Select>
            </TableCell>
          )}
          {rowType === RowType.CustomProductRow && (
            <TableCell align={isOrder ? 'left' : 'right'}>
              <React.Fragment>
                <TextField
                  className={styles.inputProductNameField}
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={(event): void =>
                    changeCustomProductName(event.target.value as string, customProductIdx, idx)
                  }
                />
              </React.Fragment>
            </TableCell>
          )}
          {['price', 'premium', 'package', 'lowest'].slice(0, isOrder ? 1 : 4).map((key, index) => {
            const isEditable =
              isOrder || (sentAt && sentAt[key as PriceKey] && selectedLead.status !== LeadStatusEnum.Done)

            const sentKey = ['price', 'premium', 'package', 'lowest'].filter((item) => !!sentAt[item as PriceKey])

            const priceDiffDetected =
              prevFinalPrice && prevFinalPrice[key] && prevFinalPrice[key] !== Number(prices[key] ? prices[key] : 0)

            const backgroundColor = isEditable ? '#eeeeee' : ''
            const opacity = isEditable ? '1' : '0.3'
            const pointerEvents = isEditable ? 'all' : 'none'
            const color = priceDiffDetected ? 'red' : 'black'
            const fontWeight = priceDiffDetected ? 'bold' : 'normal'

            return (
              <TableCell
                className={styles.cellText}
                key={index}
                align={isOrder ? 'left' : 'right'}
                style={
                  isAvalableSentQuote
                    ? { backgroundColor, opacity, pointerEvents, color, fontWeight }
                    : { color, fontWeight }
                }
              >
                {isOrder && [RowType.InputRow, RowType.CustomProductRow].indexOf(rowType) === -1 && (
                  <>
                    {`${Number(prices[key] ? prices[key] : 0).toLocaleString()}원${
                      RowType.ProductRow === rowType
                        ? ` (${Number(
                            sentKey.length > 0 && prices[sentKey[sentKey.length - 1]]
                              ? prices[sentKey[sentKey.length - 1]]
                              : 0,
                          ).toLocaleString()}원)`
                        : ''
                    }`}
                    {rowType !== RowType.ReadOnlyRow
                      ? ` ${
                          priceProduct
                            ? `(${(((priceProduct - prices[key]) / priceProduct) * 100).toFixed(2)}% 할인)`
                            : ''
                        }`
                      : ''}
                    {priceDiffDetected ? (
                      <Tooltip title={`저장된 가격과 다릅니다: ${prevFinalPrice[key].toLocaleString()}`} arrow>
                        <Warning style={{ verticalAlign: 'middle' }} color="secondary" />
                      </Tooltip>
                    ) : null}
                  </>
                )}
                {!isOrder &&
                  [RowType.InputRow, RowType.CustomProductRow].indexOf(rowType) === -1 &&
                  (rowType === RowType.ProductRow && isPackage && ['premium', 'lowest'].indexOf(key) !== -1 ? (
                    '-'
                  ) : (
                    <>
                      {`${Number(prices[key] ? prices[key] : 0).toLocaleString()}원`}
                      {rowType !== RowType.ReadOnlyRow
                        ? ` (${
                            prices.price ? (((prices.price - prices[key]) / prices.price) * 100).toFixed(2) : '-'
                          }% 할인)`
                        : ''}
                      {priceDiffDetected ? (
                        <Tooltip title={`저장된 가격과 다릅니다: ${prevFinalPrice[key].toLocaleString()}`} arrow>
                          <Warning style={{ verticalAlign: 'middle' }} color="secondary" />
                        </Tooltip>
                      ) : null}
                    </>
                  ))}
                {[RowType.InputRow, RowType.CustomProductRow].indexOf(rowType) !== -1 && (
                  <React.Fragment>
                    <TextField
                      key={index}
                      value={Number(prices[key]).toLocaleString()}
                      className={styles.inputPriceField}
                      variant="outlined"
                      size="small"
                      onChange={(e) =>
                        rowType === RowType.CustomProductRow
                          ? changeCustomProductPrice(
                              {
                                [key]: (parseInt(e.target.value.replace(new RegExp(',', 'g'), ''), 10) as number) || 0,
                              },
                              customProductIdx,
                              idx,
                            )
                          : changeExtraPricesHandler(
                              {
                                [key]: (parseInt(e.target.value.replace(new RegExp(',', 'g'), ''), 10) as number) || 0,
                              },
                              idx,
                            )
                      }
                    />
                  </React.Fragment>
                )}
              </TableCell>
            )
          })}
        </>
      )}
    </TableRow>
  )
}

export default React.memo(App)
