// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2P8jE {\n  width: 100%; }\n  .index-module__container___2P8jE .index-module__warning___28Osy {\n    color: red; }\n  .index-module__container___2P8jE .index-module__header___xRPua {\n    display: flex;\n    justify-content: space-between; }\n\n.index-module__table___1_vSn {\n  max-height: calc(100vh - 270px); }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/Order/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;EADb;IAGI,UAAU,EAAA;EAHd;IAMI,aAAa;IACb,8BAA8B,EAAA;;AAIlC;EACE,+BAA+B,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  width: 100%;\n  .warning {\n    color: red;\n  }\n  .header {\n    display: flex;\n    justify-content: space-between;\n  }\n}\n\n.table {\n  max-height: calc(100vh - 270px);\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2P8jE",
	"warning": "index-module__warning___28Osy",
	"header": "index-module__header___xRPua",
	"table": "index-module__table___1_vSn"
};
module.exports = exports;
