// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__title___2R9c8 {\n  font-size: 20px;\n  text-align: center; }\n\n.index-module__scroll___3LC88 {\n  display: block;\n  width: 100%;\n  height: 500px;\n  overflow-y: auto;\n  overflow-x: hidden; }\n\n.index-module__scrollAdjust___1izkq {\n  display: table;\n  width: 100%;\n  table-layout: fixed; }\n\n.index-module__button___3iRWp {\n  margin: auto;\n  display: block;\n  font-size: 16px;\n  padding: 5px 20px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/Curation/index.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB,EAAA;;AAGpB;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,kBAAkB,EAAA;;AAGpB;EACE,cAAc;EACd,WAAW;EACX,mBAAmB,EAAA;;AAGrB;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,iBAAiB,EAAA","file":"index.module.scss","sourcesContent":[".title {\n  font-size: 20px;\n  text-align: center;\n}\n\n.scroll {\n  display: block;\n  width: 100%;\n  height: 500px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.scrollAdjust {\n  display: table;\n  width: 100%;\n  table-layout: fixed;\n}\n\n.button {\n  margin: auto;\n  display: block;\n  font-size: 16px;\n  padding: 5px 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"title": "index-module__title___2R9c8",
	"scroll": "index-module__scroll___3LC88",
	"scrollAdjust": "index-module__scrollAdjust___1izkq",
	"button": "index-module__button___3iRWp"
};
module.exports = exports;
