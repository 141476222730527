export interface NabusType {
  convTypeId: string
  convKey: string
  convCa: string
  convDetName: string
  convDetCost: string
  convDetCnt: string
  convDetCategory: string
}

export enum EnumNabusType {
  RESERVATION = 'E6433C03', // 수동 예약 시, 직접 예약 시
  DONE = 'C01',
}
