import { Button, Card, CardActions, CardContent, TextField, Typography } from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'

import { Lead_Comment as LeadComment } from '../../../../assets/graphql/graphql'
import useRootData from '../../../../hooks/useRootData'
import styles from './index.module.scss'

interface PropsChatBalloon {
  commentObject: LeadComment
}

const App: React.FunctionComponent<PropsChatBalloon> = ({ commentObject }: PropsChatBalloon) => {
  const { accountId, updateLeadComment } = useRootData(({ authStore, leadStore }) => ({
    accountId: authStore.accountId.get(),
    updateLeadComment: leadStore.updateLeadComment,
  }))

  const [editStatus, setEditStatus] = useState(false)
  const [editedComment, setEditedComment] = useState('')

  const editButtonClickHandler = async () => {
    if (editStatus) {
      await updateLeadComment(commentObject.id, editedComment)
    }

    setEditStatus(!editStatus)
  }

  const {
    comment,
    created_at: createdAt,
    updated_at: updatedAt,
    accountByAccount: { id: author, profile_riderdash: profileRiderdash, profile_kr_customer: profileCustomer },
  } = commentObject

  const isOwner = accountId === author

  const name = profileRiderdash?.name || profileCustomer?.name || ''
  const email = profileRiderdash?.email || profileCustomer?.email || ''

  return (
    <Card className={`${styles.card} ${isOwner ? styles.myCard : ''}`} variant="outlined">
      <CardContent>
        <TextField
          className={styles.author}
          label={`${name} (${email})`}
          multiline
          defaultValue={comment}
          InputProps={{
            readOnly: !editStatus,
          }}
          onChange={(e) => setEditedComment(e.target.value)}
          disabled={!isOwner || !editStatus}
        />
        <Typography className={styles.createdTime} color="textSecondary">
          {moment(createdAt).format('YYYY-MM-DD HH:mm')}
        </Typography>
      </CardContent>
      <CardActions className={styles.cardActions}>
        <Typography variant="caption" display="block" gutterBottom className={styles.editedTime}>
          {createdAt !== updatedAt && `${moment(updatedAt).format('YYYY-MM-DD HH:mm')}에 수정됨`}
        </Typography>
        {isOwner && (
          <Button size="small" className={styles.updateButton} onClick={(): Promise<void> => editButtonClickHandler()}>
            {!editStatus ? '수정' : '저장'}
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default React.memo(App)
