import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React from 'react'

import { Car_Maker as CarMaker, Get_Car_MakerQuery as GetCarMakerQuery } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

const GET_CAR_MAKER = gql`
  query GET_CAR_MAKER {
    car_makers: car_maker {
      id
      name_kr
      name_us
      origin
      car_origin {
        name_kr
        name_us
      }
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
  }))

  const { data, loading, error } = useQuery<GetCarMakerQuery>(GET_CAR_MAKER, {
    fetchPolicy: 'no-cache',
  })

  if (loading) return null
  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>제조사 목록</div>
        <button
          onClick={(): void => {
            changeMainMenu(`/car-maker-detail?insert_car_maker`)
          }}
        >
          추가하기
        </button>
        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>uuid</th>
              <th className={styles.headerText}>브랜드</th>
              <th className={styles.headerText}>Brand</th>
              <th className={styles.headerText}>국가</th>
              <th className={styles.headerText}>Nation</th>
              <th></th>
            </tr>
            {data.car_makers.map((item: CarMaker, index: number) => (
              <tr key={index}>
                <td className={styles.inputCenter}>{data.car_makers.length - index}</td>
                <td className={styles.inputCenter}>{item.id}</td>
                <td className={styles.inputCenter}>{item.name_kr}</td>
                <td className={styles.inputCenter}>{item.name_us}</td>
                <td className={styles.inputCenter}>{item.car_origin.name_kr}</td>
                <td className={styles.inputCenter}>{item.car_origin.name_us}</td>
                <td>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/car-maker-detail?id=${item.id}`)
                    }}
                  >
                    상세보기
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
