import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const GET_GALLERY_BY_STORE = (sortMode: string, filterMode: string): DocumentNode => {
  return gql`
    query GET_GALLERY_BY_STORE($pageSize: Int!, $pageOffset: Int!) {
      store(
        limit: $pageSize
        offset: $pageOffset
        order_by: [${sortMode}], where: { deleted_at: { _is_null: true }, _and: [${filterMode}] }
      ) {
        created_at
        id
        name
        llumar_store_name
        phone_business
        operation_memo
        operation_last_contact
        operation_gallery_link
        galleries_aggregate {
           aggregate {
             max {
               created_at
             }
           }
        }
        galleriesByShown: galleries_aggregate(where: { status: { _eq: true } }) {
           aggregate {
             count
             max {
               created_at
             }
           }
        }
        galleriesByHidden: galleries_aggregate(where: { status: { _eq: false } }) {
          aggregate {
            count
          }
        }
        galleriesByHumanShown: galleries_aggregate(where: { _and: [ { status: { _eq: true } }, { scraped: { _eq: false } } ] }) {
           aggregate {
             count
             max {
               created_at
             }
           }
        }
        galleriesByHumanHidden: galleries_aggregate(where: { _and: [ { status: { _eq: false } }, { scraped: { _eq: false } }] }) {
          aggregate {
            count
          }
        }
        store_and_soles {
          soleBySole {
            id
          }
        }
      }
      store_aggregate(where: { deleted_at: { _is_null: true }, _and: [${filterMode}] }) {
        aggregate {
          count
        }
      }
    }
  `
}

export const GET_STORE_AND_SOLES = gql`
  query GET_STORE_AND_SOLES {
    store {
      name
      llumar_store_name
      id
    }
    sole {
      name
      id
    }
  }
`

export const UPDATE_STORE_OPERATION = gql`
  mutation UPDATE_STORE_OPERATION($id: uuid!, $data: timestamptz!) {
    update_store(where: { id: { _eq: $id } }, _set: { operation_last_contact: $data }) {
      affected_rows
    }
  }
`

export const UPDATE_STORE_OP_MEMO = gql`
  mutation UPDATE_STORE_OP_MEMO($id: uuid!, $memo: String!) {
    update_store(where: { id: { _eq: $id } }, _set: { operation_memo: $memo }) {
      affected_rows
    }
  }
`

export const UPSERT_STORE_SOLE = gql`
  mutation UPSERT_STORE_SOLE($id: uuid!, $sole: uuid!) {
    insert_store_and_sole(
      objects: { sole: $sole, store: $id }
      on_conflict: { constraint: store_and_sole_store_key, update_columns: sole }
    ) {
      affected_rows
    }
  }
`
