import {
  Address_Sido as AddressSido,
  Address_Sigungu as AddressSigungu,
  Package,
  Product_Item as ProductItem,
  Product_Item_Price_By_Store as ProductItemPriceByStore,
} from '../../assets/graphql/graphql'
import { getCarOriginTypeKey } from '../../utils/utility'
import leadStore from '../lead'
import { PriceObject } from './type'

export default class Calculator {
  selectedAddressSido: AddressSido

  selectedAddressSigungu: AddressSigungu

  addressNameToAreaName: Map<string, string>

  areaNameToSoleName: Map<string, string>

  defaultLowestDiscountRates: Map<string, number>

  defaultPremiumDiscountRates: Map<string, number>

  productPricesByStore: Map<string, ProductItemPriceByStore>

  selectedRecentPackageObject: Package

  constructor(
    selectedAddressSido: AddressSido,
    selectedAddressSigungu: AddressSigungu,
    addressNameToAreaName: Map<string, string>,
    areaNameToSoleName: Map<string, string>,
    defaultLowestDiscountRates: Map<string, number>,
    defaultPremiumDiscountRates: Map<string, number>,
    productPricesByStore: Map<string, ProductItemPriceByStore>,
    selectedRecentPackageObject: Package,
  ) {
    this.selectedAddressSido = selectedAddressSido
    this.selectedAddressSigungu = selectedAddressSigungu
    this.addressNameToAreaName = addressNameToAreaName
    this.areaNameToSoleName = areaNameToSoleName
    this.defaultLowestDiscountRates = defaultLowestDiscountRates
    this.defaultPremiumDiscountRates = defaultPremiumDiscountRates
    this.productPricesByStore = productPricesByStore
    this.selectedRecentPackageObject = selectedRecentPackageObject
  }

  getDiscountRate(productItemObject: ProductItem): Partial<PriceObject> {
    const addressName = `${this.selectedAddressSido.name} ${this.selectedAddressSigungu.name}`
    const areaName = this.addressNameToAreaName.has(addressName) ? this.addressNameToAreaName.get(addressName) : ''
    const soleName = this.areaNameToSoleName.has(areaName) ? this.areaNameToSoleName.get(areaName) : ''

    const keyByProduct = `${areaName},${soleName},p,${productItemObject.productByProduct.id}`
    const keyByProductItem = `${areaName},${soleName},i,${productItemObject.id}`

    const ret = {
      lowest: this.defaultLowestDiscountRates.has(keyByProduct) ? this.defaultLowestDiscountRates.get(keyByProduct) : 0,
      premium: this.defaultPremiumDiscountRates.has(keyByProduct)
        ? this.defaultPremiumDiscountRates.get(keyByProduct)
        : 0,
    }

    if (this.defaultLowestDiscountRates.has(keyByProductItem))
      ret.lowest = this.defaultLowestDiscountRates.get(keyByProductItem)
    if (this.defaultPremiumDiscountRates.has(keyByProductItem))
      ret.premium = this.defaultPremiumDiscountRates.get(keyByProductItem)

    return ret
  }

  static getPrice(productItemObject: ProductItem): number {
    return productItemObject.price
  }

  getPremiumPrice(productItemObject: ProductItem): number {
    const { premium: premiumDiscountRate } = this.getDiscountRate(productItemObject)

    const price = Calculator.getPrice(productItemObject)

    const productPrices = this.productPricesByStore ? this.productPricesByStore.get(productItemObject.id) : null
    if (productPrices && productPrices.prices && productPrices.prices.premium) return productPrices.prices.premium

    return price * (1 - premiumDiscountRate / 100)
  }

  getLowestPrice(productItemObject: ProductItem): number {
    const { lowest: lowestDiscountRate } = this.getDiscountRate(productItemObject)

    const price = Calculator.getPrice(productItemObject)

    const productPrices = this.productPricesByStore ? this.productPricesByStore.get(productItemObject.id) : null
    if (productPrices && productPrices.prices && productPrices.prices.lowest) return productPrices.prices.lowest

    return price * (1 - lowestDiscountRate / 100)
  }

  getPackagePrice(productItemObject: ProductItem): number {
    if (!this.selectedRecentPackageObject) return 0
    const { attribute } = productItemObject
    const tintingPartValue: {
      [key: string]: string
    } = {
      front: 'front',
      all: 'sideback',
      sunroof_normal: 'sunroof',
      sunroof_panorama: 'sunroof',
    }

    const key = getCarOriginTypeKey(leadStore.selectedCar.get())

    const productPrices = this.productPricesByStore ? this.productPricesByStore.get(productItemObject.id) : null
    if (productPrices && productPrices.prices && productPrices.prices.package) return productPrices.prices.package

    if (
      tintingPartValue[attribute.part] &&
      this.selectedRecentPackageObject.product.package[productItemObject.productByProduct.product_type.value] &&
      this.selectedRecentPackageObject.product.package[productItemObject.productByProduct.product_type.value][
        tintingPartValue[attribute.part]
      ]
    ) {
      return (
        this.selectedRecentPackageObject.product.package[productItemObject.productByProduct.product_type.value][
          tintingPartValue[attribute.part]
        ][key] || 0
      )
    }

    if (this.selectedRecentPackageObject.product.package[productItemObject.productByProduct.product_type.value]) {
      return (
        this.selectedRecentPackageObject.product.package[productItemObject.productByProduct.product_type.value][key] ||
        0
      )
    }

    return 0
  }

  getPriceObject(productItemObject: ProductItem): PriceObject {
    // Do not calculate the price if car isn't selected.
    const carByCar = leadStore.selectedCar.get()

    const ret: PriceObject = {
      price: 0,
      premium: 0,
      package: 0,
      lowest: 0,
    }

    if (!productItemObject || !carByCar) return ret

    return {
      price: Calculator.getPrice(productItemObject),
      premium: this.getPremiumPrice(productItemObject),
      package: this.getPackagePrice(productItemObject),
      lowest: this.getLowestPrice(productItemObject),
    }
  }
}
