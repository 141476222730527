/* eslint-disable no-alert */
import axios from 'axios'
import { kdTree as KdTree } from 'kd-tree-javascript'
import _ from 'lodash'
import { observable } from 'mobx'

import {
  _Get_Select_OptionsQuery as GetSelectOptionsQuery,
  _Get_Select_ProductsQuery as GetSelectProductsQuery,
  Account,
  Config_Type_Enum as ConfigTypeEnum,
  Package,
  Product_Item as ProductItem,
  Product_Item_Price_By_Store as ProductItemPriceByStore,
  Store,
} from '../../assets/graphql/graphql'
import { TypeSelectBox } from '../../components/atoms/Select'
import config from '../../configs'
import client from '../../utils/graphql'
import {
  checkAvailabilityByStores,
  getCarOriginTypeKey,
  getDistanceFromLatLonInKm,
  getProductDetailTypeAndText,
  parseCSVString,
  searchChosung,
} from '../../utils/utility'
import appStore from '../app'
import leadStore from '../lead'
import orderStore from '../order'
import quoteStore from '../quote'
import Calculator from './calculator'
import calculatorFromQuoteAndOrder from './calculatorFromQuoteAndOrder'
import {
  _GET_CONFIG_RATE as GET_CONFIG_RATE,
  _GET_PRODUCT_ITEM_PRICE_BY_STORE_ID as GET_PRODUCT_ITEM_PRICE_BY_STORE_ID,
  _GET_SELECT_OPTIONS as GET_SELECT_OPTIONS,
  _GET_SELECT_PRODUCTS as GET_SELECT_PRODUCTS,
} from './query'
import {
  CalculatedPrices,
  CalculatorValues,
  CustomProductObject,
  DefaultCommissionRate,
  Inventory,
  PriceObject,
  PriceUpdateObject,
  ProductCategory,
  SelectedProductIds,
  TypeSelectOptionsAccountContentsKey,
  TypeSelectOptionsProductItemContentsKey,
} from './type'

export const UPPER_LIMIT_CNT = 10

export const initPriceObject = {
  price: 0,
  premium: 0,
  package: 0,
  lowest: 0,
}

export const initSelectedProductIds: SelectedProductIds = Object.assign(
  {},
  ...Object.values(ProductCategory).map((key) => ({ [key]: '' })),
)

const getProductItemKeyAndId = (data: { selectedPackageObject: Package; key: string }) => {
  let productItemKey = ''
  let productItemId = ''

  const { selectedPackageObject, key } = data

  switch (key) {
    case ProductCategory.FrontTintingProduct:
      productItemKey = selectedPackageObject.product.package.tinting.front
      productItemId = productItemKey && selectedPackageObject.product.package.tinting.front.product_id
      break
    case ProductCategory.SidebackTintingProduct:
      productItemKey = selectedPackageObject.product.package.tinting.sideback
      productItemId = productItemKey && selectedPackageObject.product.package.tinting.sideback.product_id
      break
    case ProductCategory.SunroofTintingProduct:
      productItemKey = selectedPackageObject.product.package.tinting.sunroof
      productItemId = productItemKey && selectedPackageObject.product.package.tinting.sunroof.product_id
      break
    case ProductCategory.DashcamProduct:
      productItemKey = selectedPackageObject.product.package.dashcam
      productItemId = productItemKey && selectedPackageObject.product.package.dashcam.product_id
      break
    case ProductCategory.CeramicCoatingProduct:
      productItemKey = selectedPackageObject.product.package.ceramic_coating
      productItemId = productItemKey && selectedPackageObject.product.package.ceramic_coating.product_id
      break
    case ProductCategory.PpfProduct:
      productItemKey = selectedPackageObject.product.package.ppf
      productItemId = productItemKey && selectedPackageObject.product.package.ppf.product_id
      break
    case ProductCategory.NewCarInspectionProduct:
      productItemKey = selectedPackageObject.product.package.new_car_inspection
      productItemId = productItemKey && selectedPackageObject.product.package.new_car_inspection.product_id
      break
    case ProductCategory.CamoKitProduct:
      productItemKey = selectedPackageObject.product.package.camo_kit
      productItemId = productItemKey && selectedPackageObject.product.package.camo_kit.product_id
      break
    case ProductCategory.DeliveryFeeProduct:
      productItemKey = selectedPackageObject.product.package.delivery_fee
      productItemId = productItemKey && selectedPackageObject.product.package.delivery_fee.product_id
      break
    default:
      break
  }

  return [productItemKey, productItemId]
}

const createStore = (): typeof calculatorStore => {
  const calculatorStore = {
    // last get seleted products contents at
    lastGetSeletedProdcutsContentsAt: new Date().getTime() as number,

    // Data from DB
    selectOptionsContent: observable.box<GetSelectOptionsQuery>(null),
    productPricesByStore: observable.box<Map<string, ProductItemPriceByStore>>(null),
    defaultLowestDiscountRates: observable.box<Map<string, number>>(null),
    defaultPremiumDiscountRates: observable.box<Map<string, number>>(null),
    addressNameToAreaName: observable.box<Map<string, string>>(null),
    areaNameToSoleName: observable.box<Map<string, string>>(null),
    selectProductsContent: observable.box<GetSelectProductsQuery>(null),
    defaultCommissionRates: observable.box<DefaultCommissionRate>(null),
    filmInventory: observable.box(null),
    storeTree: null as KdTree<unknown>,

    // Store information
    searchStore: observable.box<string[]>(Array(UPPER_LIMIT_CNT).fill('')),
    selectedStore: observable.box<Store[]>(Array(UPPER_LIMIT_CNT).fill(null)),

    // Package and product
    selectedPackageObject: observable.box<Package[]>(Array(UPPER_LIMIT_CNT).fill(null)),
    selectedRecentPackageObject: observable.box<Package[]>(Array(UPPER_LIMIT_CNT).fill(null)),

    selectedProductIds: observable.box<SelectedProductIds[]>(
      Array(UPPER_LIMIT_CNT).fill({ ...initSelectedProductIds }),
    ),
    customProducts: observable.box<CustomProductObject[][]>(Array(UPPER_LIMIT_CNT).fill([])),

    // Price
    floorPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })),
    ceilPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })),
    extraDiscountPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })),
    extraPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })),
    extraDiscountPayoutPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })),
    extraPayoutPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject })),

    // Prev Price
    prevFinalPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill(null)),
    prevFinalPayoutPrice: observable.box<PriceObject[]>(Array(UPPER_LIMIT_CNT).fill(null)),

    /* Getter */
    get storeArray(): Array<TypeSelectBox[]> {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()
      const searchKeywordSet = calculatorStore.searchStore.get()

      return searchKeywordSet.map((keyword) => {
        const searchKeyword = keyword.toLowerCase()

        if (!selectOptionsContent) return [{ value: '', text: '' }]

        return [
          { value: '', text: '선택 안함' },
          ...selectOptionsContent.stores
            .map((store) => {
              return {
                value: store.id,
                text: `[${store.subscriptions.length > 0 ? store.subscriptions[0].status : ''}] ${store.name} (${
                  store.address_detail ? store.address_detail : ''
                })`,
              }
            })
            .filter((item) => searchChosung(searchKeyword, item.text))
            .map((item) => ({
              value: item.value,
              text: item.text,
            }))
            .sort((a, b) => {
              if (a.text > b.text) return 1

              return b.text > a.text ? -1 : 0
            }),
        ]
      })
    },
    get packageArray(): Array<TypeSelectBox> {
      const selectProductsContent = calculatorStore.selectProductsContent.get()
      const selectedCar = leadStore.selectedCar.get()

      if (!selectProductsContent) return [{ value: '', text: '' }]

      return [
        { value: '', text: '선택 안함' },
        ...selectProductsContent.packages
          .map((packageObject: Package) => {
            return {
              value: packageObject.id,
              text: packageObject.name,
            }
          })
          .filter((item) =>
            selectedCar && selectedCar.model === '모델 3'
              ? item.text.indexOf('모델 3') !== -1
              : item.text.indexOf('모델 3') === -1,
          ),
      ]
    },

    get productItemArrays(): { [key in ProductCategory]: Array<TypeSelectBox[]> } {
      return Object.assign(
        {},
        ...Object.values(ProductCategory).map((key) => ({
          [key]: Array(UPPER_LIMIT_CNT)
            .fill(null)
            .map((__, i) => i)
            .map((item) => {
              return calculatorStore.generateOptions(key, item)
            }),
        })),
      )
    },
    get detectDiffPrice(): Array<boolean> {
      const calculatedPrices = [...calculatorStore.calculatedPrices]
      const quoteObject = quoteStore.quoteObject.get()
      const orderObject = orderStore.orderObject.get()
      const prevFinalPrice = calculatorStore.prevFinalPrice.get()

      if (!quoteObject || quoteObject.quote_items.length === 0) return null

      const orderPrices = calculatedPrices[0] ? calculatedPrices[0].finalPrice : null

      return [
        orderObject && orderPrices
          ? prevFinalPrice &&
            prevFinalPrice[0] &&
            prevFinalPrice[0].price &&
            prevFinalPrice[0].price !== Number(orderPrices.price ? orderPrices.price : 0)
          : null,
        ...quoteObject.quote_items.map((quoteItem, index) => {
          if (quoteItem.deleted_at) return null

          const prices = calculatedPrices[index + 1] ? calculatedPrices[index + 1].finalPrice : null

          return prices
            ? ['price', 'premium', 'package', 'lowest']
                .map(
                  (key: string) =>
                    prevFinalPrice &&
                    prevFinalPrice[index + 1] &&
                    prevFinalPrice[index + 1][key] &&
                    prevFinalPrice[index + 1][key] !== Number(prices[key] ? prices[key] : 0),
                )
                .reduce((prev, current) => prev || current, false)
            : false
        }),
      ]
    },
    get calculatedPrices(): CalculatedPrices[] {
      return Array(UPPER_LIMIT_CNT)
        .fill(null)
        .map((__, i) => i)
        .map((idx) => {
          const defaultLowestDiscountRates = calculatorStore.defaultLowestDiscountRates.get()
          const defaultPremiumDiscountRates = calculatorStore.defaultPremiumDiscountRates.get()
          const defaultCommissionRates = calculatorStore.defaultCommissionRates.get()

          const selectedAddressSido = leadStore.selectedAddressSido.get()
          const selectedAddressSigungu = leadStore.selectedAddressSigungu.get()

          const orderObject = orderStore.orderObject.get()

          if (
            !(selectedAddressSido && selectedAddressSigungu) ||
            !defaultLowestDiscountRates ||
            !defaultPremiumDiscountRates
          ) {
            return null
          }

          const productItemObjects: { [key in ProductCategory]: ProductItem } = Object.assign(
            {},
            ...Object.values(ProductCategory).map((key) => ({
              [key]: calculatorStore.getSelectedProductItemObjectById(
                key,
                calculatorStore.selectedProductIds.get()[idx][key],
              ),
            })),
          )
          const customProducts = calculatorStore.customProducts.get()[idx]

          const selectedPackageObject = calculatorStore.selectedPackageObject.get()[idx]

          const calculator = calculatorStore.getCalculatorObject(idx)

          if (!calculator) return null

          const floorPrice = calculatorStore.floorPrice.get()[idx]
          const ceilPrice = calculatorStore.ceilPrice.get()[idx]

          let totalPrice = { ...initPriceObject }

          totalPrice = {
            price: Math.round(
              Object.values(
                Object.values(productItemObjects).map((object) => calculator.getPriceObject(object).price),
              ).reduce((a, b) => a + b) +
                customProducts.map((customProduct) => customProduct.productPrice.price).reduce((a, b) => a + b, 0) -
                floorPrice.price +
                ceilPrice.price,
            ),
            premium: Math.round(
              Object.values(
                Object.values(productItemObjects).map((object) => calculator.getPriceObject(object).premium),
              ).reduce((a, b) => a + b) +
                customProducts.map((customProduct) => customProduct.productPrice.premium).reduce((a, b) => a + b, 0) -
                floorPrice.premium +
                ceilPrice.premium,
            ),
            package: Math.round(
              Object.values(
                Object.values(productItemObjects).map((object) => calculator.getPriceObject(object).package),
              ).reduce((a, b) => a + b) +
                customProducts.map((customProduct) => customProduct.productPrice.package).reduce((a, b) => a + b, 0) -
                floorPrice.package +
                ceilPrice.package,
            ),
            lowest: Math.round(
              Object.values(
                Object.values(productItemObjects).map((object) => calculator.getPriceObject(object).lowest),
              ).reduce((a, b) => a + b) +
                customProducts.map((customProduct) => customProduct.productPrice.lowest).reduce((a, b) => a + b, 0) -
                floorPrice.lowest +
                ceilPrice.lowest,
            ),
          }

          if (selectedPackageObject) {
            const key = getCarOriginTypeKey(leadStore.selectedCar.get())

            totalPrice.price = Math.round(selectedPackageObject.price_product[key])
            totalPrice.premium = 0
            totalPrice.lowest = 0
          }

          const extraPrice = calculatorStore.extraPrice.get()[idx]
          const extraDiscountPrice = calculatorStore.extraDiscountPrice.get()[idx]

          const finalPrice: PriceObject = {
            price:
              idx === 0 && orderObject && orderObject.is_auto_order
                ? orderObject.price_final
                : Math.round(totalPrice.price + extraPrice.price - extraDiscountPrice.price),
            premium: Math.round(totalPrice.premium + extraPrice.premium - extraDiscountPrice.premium),
            package: Math.round(totalPrice.package + extraPrice.package - extraDiscountPrice.package),
            lowest: Math.round(totalPrice.lowest + extraPrice.lowest - extraDiscountPrice.lowest),
          }

          const significantFigures = 1

          const basicPayoutPrice: PriceObject = {
            price:
              // idx === 0 is order
              idx === 0
                ? (orderObject && orderObject.basic_price_payout) || 0
                : Math.ceil(
                    (finalPrice.price * (100 - defaultCommissionRates.default_commission_rate)) /
                      100 /
                      significantFigures,
                  ) * significantFigures,
            premium:
              Math.ceil(
                (finalPrice.premium * (100 - defaultCommissionRates.default_commission_rate)) /
                  100 /
                  significantFigures,
              ) * significantFigures,
            // idx === 0 is order
            package:
              idx === 0
                ? 0
                : Math.ceil(
                    (finalPrice.package * (100 - defaultCommissionRates.default_commission_rate)) /
                      100 /
                      significantFigures,
                  ) * significantFigures,
            lowest:
              Math.ceil(
                (finalPrice.lowest * (100 - defaultCommissionRates.default_commission_rate)) / 100 / significantFigures,
              ) * significantFigures,
          }

          if (selectedPackageObject) {
            const key = getCarOriginTypeKey(leadStore.selectedCar.get())

            basicPayoutPrice.package =
              selectedPackageObject.price_payout[key] +
              Math.ceil(
                (customProducts.map((customProduct) => customProduct.productPrice.package).reduce((a, b) => a + b, 0) *
                  (100 - defaultCommissionRates.default_commission_rate)) /
                  100 /
                  10,
              ) *
                10
          }
          const extraPayoutPrice = calculatorStore.extraPayoutPrice.get()[idx]
          const extraDiscountPayoutPrice = calculatorStore.extraDiscountPayoutPrice.get()[idx]

          const finalPayoutPrice: PriceObject = {
            price: Math.round(basicPayoutPrice.price + extraPayoutPrice.price - extraDiscountPayoutPrice.price),
            premium: Math.round(basicPayoutPrice.premium + extraPayoutPrice.premium - extraDiscountPayoutPrice.premium),
            package: Math.round(basicPayoutPrice.package + extraPayoutPrice.package - extraDiscountPayoutPrice.package),
            lowest: Math.round(basicPayoutPrice.lowest + extraPayoutPrice.lowest - extraDiscountPayoutPrice.lowest),
          }

          const materialPrice: PriceObject = {
            price: Math.round(finalPrice.price - finalPayoutPrice.price),
            premium: Math.round(finalPrice.premium - finalPayoutPrice.premium),
            package: Math.round(finalPrice.package - finalPayoutPrice.package),
            lowest: Math.round(finalPrice.lowest - finalPayoutPrice.lowest),
          }

          return {
            productPrices: Object.assign(
              {},
              ...Object.values(ProductCategory).map((key) => ({
                [key]: calculator.getPriceObject(productItemObjects[key]),
              })),
            ),
            totalPrice,
            finalPrice,
            basicPayoutPrice,
            finalPayoutPrice,
            materialPrice,
          }
        })
    },

    /* Local Func */
    generateOptions(key: string, idx: number, ignorePackage = false) {
      const selectProductsContent = calculatorStore.selectProductsContent.get()
      const selectedPackageObject = calculatorStore.selectedPackageObject.get()[idx]
      const selectedStore = calculatorStore.selectedStore.get()[idx]

      if (!selectProductsContent) return [{ value: '', text: '', price: null }]

      const [productItemKey, productItemId] = selectedPackageObject
        ? getProductItemKeyAndId({
            selectedPackageObject,
            key,
          })
        : [null, null]

      const calculator = calculatorStore.getCalculatorObject(idx)

      return [
        { value: '', text: '선택 안함', price: null },
        ...selectProductsContent[key as TypeSelectOptionsProductItemContentsKey]
          .filter((item: ProductItem) => {
            if (!ignorePackage && selectedPackageObject) {
              if (productItemKey) return item.productByProduct.id === productItemId

              return false
            }

            return true
          })
          .map((productItemObject: ProductItem) => {
            let text = getProductDetailTypeAndText(productItemObject)[1]

            if (productItemObject.sku.indexOf('only_tesla') !== -1) {
              text += '(모델3 전용)'
            }

            // 주변 10개 매장 재고 유무 체크
            if (key.indexOf('tinting') !== -1) {
              text += checkAvailabilityByStores(
                `${productItemObject.productByProduct.name}-${productItemObject.attribute.density}`,
                selectedStore,
              )
                ? ''
                : '(재고 X)'
            }

            return {
              value: productItemObject.id,
              text,
              price: calculator ? calculator.getPriceObject(productItemObject) : null,
            }
          })
          .sort((a, b) => {
            if (a.text > b.text) return 1

            return b.text > a.text ? -1 : 0
          }),
      ]
    },
    getCalculatorObject(idx: number) {
      const productPricesByStore = calculatorStore.productPricesByStore.get()
      const defaultLowestDiscountRates = calculatorStore.defaultLowestDiscountRates.get()
      const defaultPremiumDiscountRates = calculatorStore.defaultPremiumDiscountRates.get()
      const addressNameToAreaName = calculatorStore.addressNameToAreaName.get()
      const areaNameToSoleName = calculatorStore.areaNameToSoleName.get()

      const selectedAddressSido = leadStore.selectedAddressSido.get()
      const selectedAddressSigungu = leadStore.selectedAddressSigungu.get()

      const selectedRecentPackageObject = calculatorStore.selectedRecentPackageObject.get()[idx]

      if (
        !(selectedAddressSido && selectedAddressSigungu) ||
        !defaultLowestDiscountRates ||
        !defaultPremiumDiscountRates
      ) {
        return null
      }

      const calculator = new Calculator(
        selectedAddressSido,
        selectedAddressSigungu,
        addressNameToAreaName,
        areaNameToSoleName,
        defaultLowestDiscountRates,
        defaultPremiumDiscountRates,
        productPricesByStore,
        selectedRecentPackageObject,
      )

      return calculator
    },
    getSelectedProductItemObject(key: string, id: string, setter: (data: ProductItem) => void) {
      const selectProductsContent = calculatorStore.selectProductsContent.get()

      if (!selectProductsContent) return

      setter(
        selectProductsContent[key as TypeSelectOptionsProductItemContentsKey].filter(
          (item) => item.id === id,
        )[0] as ProductItem,
      )
    },
    getSelectedProductItemObjectById(key: string, id: string): ProductItem {
      const selectProductsContent = calculatorStore.selectProductsContent.get()

      if (!selectProductsContent) return null

      return selectProductsContent[key as TypeSelectOptionsProductItemContentsKey].filter(
        (item) => item.id === id,
      )[0] as ProductItem
    },
    getSelectedAccountObject(key: string, id: string, setter: (data: Account) => void): Account {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return null

      const accountObject = selectOptionsContent[key as TypeSelectOptionsAccountContentsKey].filter(
        (item) => item.id === id,
      )[0]

      if (setter) {
        setter(accountObject as Account)
      }

      return accountObject as Account
    },

    /* Setter */
    setStore(calculatorValues: CalculatorValues) {
      const {
        searchStore,
        selectedStore,
        selectedPackageObject,
        selectedRecentPackageObject,
        selectedProductIds,
        customProducts,
        floorPrice,
        ceilPrice,
        extraDiscountPrice,
        extraPrice,
        extraDiscountPayoutPrice,
        extraPayoutPrice,
        prevFinalPrice,
        prevFinalPayoutPrice,
      } = calculatorValues

      if (searchStore) calculatorStore.searchStore.set(searchStore)
      if (selectedStore) calculatorStore.selectedStore.set(selectedStore)

      if (selectedPackageObject) calculatorStore.selectedPackageObject.set(selectedPackageObject)
      if (selectedRecentPackageObject) calculatorStore.selectedRecentPackageObject.set(selectedRecentPackageObject)

      if (selectedProductIds) calculatorStore.selectedProductIds.set(selectedProductIds)
      if (customProducts) calculatorStore.customProducts.set(customProducts)

      if (floorPrice) calculatorStore.floorPrice.set(floorPrice)
      if (ceilPrice) calculatorStore.ceilPrice.set(ceilPrice)

      if (extraDiscountPrice) calculatorStore.extraDiscountPrice.set(extraDiscountPrice)
      if (extraPrice) calculatorStore.extraPrice.set(extraPrice)
      if (extraDiscountPayoutPrice) calculatorStore.extraDiscountPayoutPrice.set(extraDiscountPayoutPrice)
      if (extraPayoutPrice) calculatorStore.extraPayoutPrice.set(extraPayoutPrice)

      if (prevFinalPrice) calculatorStore.prevFinalPrice.set(prevFinalPrice)
      if (prevFinalPayoutPrice) calculatorStore.prevFinalPayoutPrice.set(prevFinalPayoutPrice)
    },
    initStore() {
      calculatorStore.setStore({
        searchStore: Array(UPPER_LIMIT_CNT).fill(''),
        selectedStore: Array(UPPER_LIMIT_CNT).fill(null),
        selectedPackageObject: Array(UPPER_LIMIT_CNT).fill(null),
        selectedRecentPackageObject: Array(UPPER_LIMIT_CNT).fill(null),
        selectedProductIds: Array(UPPER_LIMIT_CNT).fill({ ...initSelectedProductIds }),
        customProducts: Array(UPPER_LIMIT_CNT).fill([]),
        floorPrice: Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject }),
        ceilPrice: Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject }),
        extraDiscountPrice: Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject }),
        extraPrice: Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject }),
        extraDiscountPayoutPrice: Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject }),
        extraPayoutPrice: Array(UPPER_LIMIT_CNT).fill({ ...initPriceObject }),
        prevFinalPrice: Array(UPPER_LIMIT_CNT).fill(null),
        prevFinalPayoutPrice: Array(UPPER_LIMIT_CNT).fill(null),
      })
    },
    cloneStore(idx: number) {
      if (idx > -1) {
        const { numberOfQuoteItems } = quoteStore

        const searchStore = [...calculatorStore.searchStore.get()]
        const selectedStore = [...calculatorStore.selectedStore.get()]

        const selectedPackageObject = [...calculatorStore.selectedPackageObject.get()]
        const selectedRecentPackageObject = [...calculatorStore.selectedRecentPackageObject.get()]

        const selectedProductIds = [...calculatorStore.selectedProductIds.get()]
        const customProducts = [...calculatorStore.customProducts.get()]

        const floorPrice = [...calculatorStore.floorPrice.get()]
        const ceilPrice = [...calculatorStore.ceilPrice.get()]

        const extraDiscountPrice = [...calculatorStore.extraDiscountPrice.get()]
        const extraPrice = [...calculatorStore.extraPrice.get()]
        const extraDiscountPayoutPrice = [...calculatorStore.extraDiscountPayoutPrice.get()]
        const extraPayoutPrice = [...calculatorStore.extraPayoutPrice.get()]

        const prevFinalPrice = [...calculatorStore.prevFinalPrice.get()]
        const prevFinalPayoutPrice = [...calculatorStore.prevFinalPayoutPrice.get()]

        searchStore[numberOfQuoteItems + 1] = _.cloneDeep(searchStore[idx + 1])
        selectedStore[numberOfQuoteItems + 1] = _.cloneDeep(selectedStore[idx + 1])

        selectedPackageObject[numberOfQuoteItems + 1] = _.cloneDeep(selectedPackageObject[idx + 1])
        selectedRecentPackageObject[numberOfQuoteItems + 1] = _.cloneDeep(selectedRecentPackageObject[idx + 1])

        selectedProductIds[numberOfQuoteItems + 1] = _.cloneDeep(selectedProductIds[idx + 1])
        customProducts[numberOfQuoteItems + 1] = _.cloneDeep(customProducts[idx + 1])

        floorPrice[idx + 1] = _.cloneDeep(floorPrice[idx + 1])
        ceilPrice[idx + 1] = _.cloneDeep(ceilPrice[idx + 1])

        extraDiscountPrice[numberOfQuoteItems + 1] = _.cloneDeep(extraDiscountPrice[idx + 1])
        extraPrice[numberOfQuoteItems + 1] = _.cloneDeep(extraPrice[idx + 1])
        extraDiscountPayoutPrice[numberOfQuoteItems + 1] = _.cloneDeep(extraDiscountPayoutPrice[idx + 1])
        extraPayoutPrice[numberOfQuoteItems + 1] = _.cloneDeep(extraPayoutPrice[idx + 1])

        prevFinalPrice[numberOfQuoteItems + 1] = _.cloneDeep(prevFinalPrice[idx + 1])
        prevFinalPayoutPrice[numberOfQuoteItems + 1] = _.cloneDeep(prevFinalPayoutPrice[idx + 1])

        calculatorStore.setStore({
          searchStore,
          selectedStore,
          selectedPackageObject,
          selectedRecentPackageObject,
          selectedProductIds,
          customProducts,
          floorPrice,
          ceilPrice,
          extraDiscountPrice,
          extraPrice,
          extraDiscountPayoutPrice,
          extraPayoutPrice,
          prevFinalPrice,
          prevFinalPayoutPrice,
        })
      }
    },
    validateDiscountPrice(key: string, value: number, idx: number): number {
      const totalPrice = calculatorStore?.calculatedPrices[idx]?.totalPrice?.[key]

      // 오더 주문건 같은 경우는 무시 (idx === 0)
      if (!totalPrice || idx === 0) return value

      if (value / totalPrice > calculatorStore.defaultCommissionRates.get().maximum_discount_rate / 100) {
        appStore.changeSnackbarAlertContent({
          content: 'Limit',
          severity: 'warning',
        })

        return 0
      }

      return value
    },
    changeFloorPrice(object: PriceUpdateObject, idx: number) {
      const floorPrice = [...calculatorStore.floorPrice.get()]
      floorPrice[idx] = {
        ...floorPrice[idx],
        ...object,
      }

      calculatorStore.floorPrice.set(floorPrice)
    },
    changeCeilPrice(object: PriceUpdateObject, idx: number) {
      const ceilPrice = [...calculatorStore.ceilPrice.get()]
      ceilPrice[idx] = {
        ...ceilPrice[idx],
        ...object,
      }

      calculatorStore.ceilPrice.set(ceilPrice)
    },
    changeExtraDiscountPrice(object: PriceUpdateObject, idx: number) {
      const key = Object.keys(object)[0]

      const value = calculatorStore.validateDiscountPrice(key, object[key], idx)

      const extraDiscountPrice = [...calculatorStore.extraDiscountPrice.get()]
      extraDiscountPrice[idx] = {
        ...extraDiscountPrice[idx],
        [key]: value,
      }

      calculatorStore.extraDiscountPrice.set(extraDiscountPrice)
    },
    changeExtraPrice(object: PriceUpdateObject, idx: number) {
      const extraPrice = [...calculatorStore.extraPrice.get()]
      extraPrice[idx] = {
        ...extraPrice[idx],
        ...object,
      }

      calculatorStore.extraPrice.set(extraPrice)
    },
    validatePayoutPrice(key: string, value: number, idx: number): number {
      const finalPrice =
        calculatorStore.calculatedPrices[idx] && calculatorStore.calculatedPrices[idx].finalPrice
          ? calculatorStore.calculatedPrices[idx].finalPrice[key]
          : 0

      if (finalPrice === 0) return value
      const defaultPayoutPrice =
        (finalPrice *
          (calculatorStore.defaultCommissionRates.get().default_commission_rate > 0
            ? calculatorStore.defaultCommissionRates.get().default_commission_rate
            : 1)) /
        100

      if (value > 0) {
        if (
          (defaultPayoutPrice + value) / finalPrice >
          calculatorStore.defaultCommissionRates.get().maximum_commission_rate / 100
        ) {
          appStore.changeSnackbarAlertContent({
            content: 'Limit',
            severity: 'warning',
          })

          return 0
        }
      } else if (
        (defaultPayoutPrice + value) / finalPrice <
        calculatorStore.defaultCommissionRates.get().minimum_commission_rate / 100
      ) {
        appStore.changeSnackbarAlertContent({
          content: 'Limit',
          severity: 'warning',
        })

        return 0
      }

      return value
    },
    changeExtraDiscountPayoutPrice(object: PriceUpdateObject, idx: number) {
      const key = Object.keys(object)[0]
      const value = calculatorStore.validatePayoutPrice(key, object[key], idx)

      const extraDiscountPayoutPrice = [...calculatorStore.extraDiscountPayoutPrice.get()]
      extraDiscountPayoutPrice[idx] = {
        ...extraDiscountPayoutPrice[idx],
        [key]: value,
      }
      calculatorStore.extraDiscountPayoutPrice.set(extraDiscountPayoutPrice)
    },
    changeExtraPayoutPrice(object: PriceUpdateObject, idx: number) {
      const key = Object.keys(object)[0]

      const value = calculatorStore.validatePayoutPrice(key, -object[key], idx)

      const extraPayoutPrice = [...calculatorStore.extraPayoutPrice.get()]
      extraPayoutPrice[idx] = {
        ...extraPayoutPrice[idx],
        [key]: -value,
      }
      calculatorStore.extraPayoutPrice.set(extraPayoutPrice)
    },
    changeProductPricesByStore(data: Map<string, ProductItemPriceByStore>) {
      calculatorStore.productPricesByStore.set(data)
    },
    changeSearchStore(data: string, idx: number) {
      const searchStore = [...calculatorStore.searchStore.get()]
      searchStore[idx] = data

      calculatorStore.searchStore.set(searchStore)
    },
    changeSelectedStore(id: string, idx: number) {
      const selectOptionsContent = calculatorStore.selectOptionsContent.get()

      if (!selectOptionsContent) return

      const selectedStore = [...calculatorStore.selectedStore.get()]
      selectedStore[idx] = selectOptionsContent.stores.filter((item) => item.id === id)[0] as Store
      calculatorStore.selectedStore.set(selectedStore)

      calculatorStore.getProductPricesByStoreId(idx)
    },
    changeSelectOptionsContents(data: GetSelectOptionsQuery) {
      calculatorStore.selectOptionsContent.set(data)
    },
    changeSelectProductsContents(data: GetSelectProductsQuery, now: number) {
      if (calculatorStore.lastGetSeletedProdcutsContentsAt < now) {
        calculatorStore.selectProductsContent.set(data)
        calculatorStore.lastGetSeletedProdcutsContentsAt = now
      }
    },
    changeSelectedPackageObject(id: string, idx: number) {
      const isOrder = idx === 0

      const selectProductsContent = calculatorStore.selectProductsContent.get()

      if (!selectProductsContent) return

      const packageObject = selectProductsContent.packages.filter((item) => item.id === id)[0] as Package

      const selectedPackageObject = [...calculatorStore.selectedPackageObject.get()]
      selectedPackageObject[idx] = packageObject || null
      calculatorStore.selectedPackageObject.set(selectedPackageObject)

      // Store only valid object for future calculation
      if (packageObject) {
        // init seletedProductIds
        const selectedProductIds = [...calculatorStore.selectedProductIds.get()]
        selectedProductIds[idx] = { ...initSelectedProductIds }
        calculatorStore.selectedProductIds.set(selectedProductIds)
      }
      if (packageObject) calculatorStore.selectedRecentPackageObject.set(selectedPackageObject)

      if (packageObject) {
        const key = getCarOriginTypeKey(leadStore.selectedCar.get())
        const priceKey = isOrder ? 'price' : 'package'

        // eslint-disable-next-line camelcase
        if (packageObject?.price_discount?.floor_price?.[key]) {
          calculatorStore.changeFloorPrice({ [priceKey]: packageObject.price_discount.floor_price[key] }, idx)
        } else calculatorStore.changeFloorPrice({ [priceKey]: 0 }, idx)
        // eslint-disable-next-line camelcase
        if (packageObject?.price_discount?.ceil_price?.[key]) {
          calculatorStore.changeCeilPrice({ [priceKey]: packageObject.price_discount.ceil_price[key] }, idx)
        } else calculatorStore.changeCeilPrice({ [priceKey]: 0 }, idx)

        const selectedProductIds = [...calculatorStore.selectedProductIds.get()]
        selectedProductIds[idx][ProductCategory.FrontTintingProduct] = ''
        selectedProductIds[idx][ProductCategory.SidebackTintingProduct] = ''
        selectedProductIds[idx][ProductCategory.SunroofTintingProduct] = ''
        selectedProductIds[idx][ProductCategory.CeramicCoatingProduct] = ''
        calculatorStore.selectedProductIds.set(selectedProductIds)

        const ceramicCoatingProductItemArray =
          calculatorStore.productItemArrays[ProductCategory.CeramicCoatingProduct][idx]

        if (ceramicCoatingProductItemArray.length === 2) {
          calculatorStore.changeSelectedProductId(
            ProductCategory.CeramicCoatingProduct,
            ceramicCoatingProductItemArray[1].value,
            idx,
          )
        }

        calculatorStore.changeSelectedProductId(
          ProductCategory.DashcamProduct,
          packageObject.attribute.selection.dashcam,
          idx,
        )
        calculatorStore.changeSelectedProductId(ProductCategory.PpfProduct, packageObject.attribute.selection.ppf, idx)
        calculatorStore.changeSelectedProductId(
          ProductCategory.NewCarInspectionProduct,
          packageObject.attribute.selection.new_car_inspection,
          idx,
        )
        calculatorStore.changeSelectedProductId(
          ProductCategory.CamoKitProduct,
          packageObject.attribute.selection.camo_kit,
          idx,
        )
        calculatorStore.changeSelectedProductId(
          ProductCategory.DeliveryFeeProduct,
          packageObject.attribute.selection.delivery_fee,
          idx,
        )
      }
    },
    changeSelectedProductId(product: ProductCategory, id: string, idx: number) {
      const selectedProductIds = [...calculatorStore.selectedProductIds.get()]
      selectedProductIds[idx][product] = id

      calculatorStore.selectedProductIds.set(selectedProductIds)
    },
    appendCustomProduct(idx: number, productName = '', productPrice = { ...initPriceObject }) {
      const customProducts = [...calculatorStore.customProducts.get()]
      customProducts[idx].push({
        id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        productName,
        productPrice,
      })
      calculatorStore.customProducts.set(customProducts)
    },
    deleteCustomProduct(customProductIdx: number, idx: number) {
      const customProducts = [...calculatorStore.customProducts.get()]
      customProducts[idx] = [...customProducts[idx].filter((item, index) => index !== customProductIdx)]
      calculatorStore.customProducts.set(customProducts)
    },
    changeCustomProductName(productName: string, customProductIdx: number, idx: number) {
      const customProducts = [...calculatorStore.customProducts.get()]
      customProducts[idx][customProductIdx].productName = productName
      calculatorStore.customProducts.set(customProducts)
    },
    changeCustomProductPrice(object: PriceUpdateObject, customProductIdx: number, idx: number) {
      const customProducts = [...calculatorStore.customProducts.get()]
      customProducts[idx][customProductIdx].productPrice = {
        ...customProducts[idx][customProductIdx].productPrice,
        ...object,
      }
      calculatorStore.customProducts.set(customProducts)
    },

    /* Actions */
    getFlimStock: async () => {
      const {
        data: { inventory },
      } = await axios.post(
        `${config.msgCenterURL}/admin/inventory`,
        {},
        {
          headers: {
            'Account-Email': config.msgCenterSecureKey,
          },
        },
      )

      const object: {
        [key: string]: Inventory
      } = {}

      inventory.forEach((data: Inventory) => {
        const { caramora_id: storeId } = data

        object[storeId] = data
      })

      calculatorStore.filmInventory.set(object)
    },
    getSelectionOptions: async () => {
      const { changeSelectOptionsContents } = calculatorStore

      const { data: selectOptionsContent } = await client.query({
        query: GET_SELECT_OPTIONS,
        fetchPolicy: 'no-cache',
      })

      changeSelectOptionsContents(selectOptionsContent)
      calculatorStore.getDefaultRates()

      // Make kd-tree for stores
      const { stores }: { stores: Store[] } = selectOptionsContent
      calculatorStore.storeTree = new KdTree(
        stores
          .filter((store) => !!store.location && !!store.location.coordinates[0] && !!store.location.coordinates[1])
          .map((store) => {
            return { x: store.location.coordinates[0], y: store.location.coordinates[1], id: store.id }
          }),
        (a, b) => {
          return getDistanceFromLatLonInKm(a.x, a.y, b.x, b.y)
        },
        ['x', 'y'],
      )
    },
    getSelectionProducts: async () => {
      const now = new Date().getTime()
      const carByCar = leadStore.selectedCar.get()
      const carOrigin = carByCar ? carByCar.car_maker.car_origin.name_us : ''
      const carType = carByCar ? carByCar.type : ''
      const carSunroofType = leadStore.selectedCarSunroofType.get()
      const { changeSelectProductsContents } = calculatorStore

      const { data: selectProductsContent } = await client.query({
        query: GET_SELECT_PRODUCTS,
        variables: {
          carOrigin: carOrigin === 'korea' ? '%korea%' : '%other%',
          carType: `%${carType}%`,
          carSunroofType: `%${carSunroofType}%`,
        },
        fetchPolicy: 'no-cache',
      })

      changeSelectProductsContents(selectProductsContent, now)
    },
    getDefaultRates: async () => {
      // Commission rates
      const commissionRateObject = (
        await client.query({
          query: GET_CONFIG_RATE(ConfigTypeEnum.DefaultCommissionRate),
          fetchPolicy: 'no-cache',
        })
      ).data.config[0].csv_content
      calculatorStore.defaultCommissionRates.set(commissionRateObject)

      // Discount rates
      const defaultDiscountRateTable = (
        await client.query({
          query: GET_CONFIG_RATE(ConfigTypeEnum.DefaultDiscountRate),
          fetchPolicy: 'no-cache',
        })
      ).data.config[0].csv_content

      // Area by Sido Sigungu
      const areaBySidoSigungu = (
        await client.query({
          query: GET_CONFIG_RATE(ConfigTypeEnum.AreaBySidoSigungu),
          fetchPolicy: 'no-cache',
        })
      ).data.config[0].csv_content

      // Sole Name by Area
      const soleNameByArea = (
        await client.query({
          query: GET_CONFIG_RATE(ConfigTypeEnum.SoleNameByArea),
          fetchPolicy: 'no-cache',
        })
      ).data.config[0].csv_content

      const defaultDiscountRateCSVTable = parseCSVString(defaultDiscountRateTable.csv)
      const defaultSkuLevelDiscountRateCSVTable = parseCSVString(defaultDiscountRateTable.sku_level_csv)

      const addressNameToAreaName = new Map(
        parseCSVString(areaBySidoSigungu.csv)
          .slice(1)
          .map((rowItem) => [`${rowItem[0]} ${rowItem[1]}`, rowItem[2]]),
      )
      const areaNameToSoleName = new Map(
        parseCSVString(soleNameByArea.csv)
          .slice(1)
          .map((rowItem) => [rowItem[0], rowItem[1]]),
      )

      calculatorStore.addressNameToAreaName.set(addressNameToAreaName)
      calculatorStore.areaNameToSoleName.set(areaNameToSoleName)

      const defaultLowestDiscountRates = new Map<string, number>()
      const defaultPremiumDiscountRates = new Map<string, number>()
      const productIds = defaultDiscountRateCSVTable[0].slice(2)
      defaultDiscountRateCSVTable.slice(1).forEach((rowItem) => {
        const soleName = rowItem[0]
        const areaName = rowItem[1]
        rowItem.slice(2).forEach((discountRate, index) => {
          const productId = productIds[index]

          const lowestDiscountRate = parseFloat(discountRate.split(':')[0])
          const premiumDiscountRate = parseFloat(discountRate.split(':')[1])

          const key = `${areaName},${soleName},p,${productId}`

          defaultLowestDiscountRates.set(key, lowestDiscountRate)
          defaultPremiumDiscountRates.set(key, premiumDiscountRate)
        })
      })
      const productItemIds = defaultSkuLevelDiscountRateCSVTable[0].slice(2)
      defaultSkuLevelDiscountRateCSVTable.slice(1).forEach((rowItem) => {
        const soleName = rowItem[0]
        const areaName = rowItem[1]
        rowItem.slice(2).forEach((discountRate, index) => {
          const productItemId = productItemIds[index]

          const lowestDiscountRate = parseFloat(discountRate.split(':')[0])
          const premiumDiscountRate = parseFloat(discountRate.split(':')[1])

          const key = `${areaName},${soleName},i,${productItemId}`

          defaultLowestDiscountRates.set(key, lowestDiscountRate)
          defaultPremiumDiscountRates.set(key, premiumDiscountRate)
        })
      })

      calculatorStore.defaultLowestDiscountRates.set(defaultLowestDiscountRates)
      calculatorStore.defaultPremiumDiscountRates.set(defaultPremiumDiscountRates)
    },
    getProductPricesByStoreId: async (idx: number) => {
      const selectedStore = calculatorStore.selectedStore.get()[idx]

      if (!selectedStore) return

      const {
        data: { product_item_price_by_store: productPrices },
      } = await client.query({
        query: GET_PRODUCT_ITEM_PRICE_BY_STORE_ID,
        variables: {
          storeId: selectedStore.id,
        },
      })

      const indexedMap = new Map<string, ProductItemPriceByStore>()

      for (let i = 0; i < productPrices.length; i += 1) {
        const item = productPrices[i]
        const { product_item_id: productItemId } = item
        indexedMap.set(productItemId, item)
      }

      calculatorStore.changeProductPricesByStore(indexedMap)
    },
    calculatorFromQuoteAndOrder,
  }

  return calculatorStore
}

const store = createStore()
export default store
