import { Button, Card, Modal, Paper, TableContainer } from '@material-ui/core'
import React, { useState } from 'react'

import useLeadFunctions from '../../../hooks/useLeadFunctions'
import useRootData from '../../../hooks/useRootData'
import { CalculatedPrices, PriceObject } from '../../../stores/calculator/type'
import { generateComputedFieldInQuoteItem } from '../../../utils/utility'
import CalculatorTable from './CalculatorTable'
import styles from './index.module.scss'
import RemarkTable from './RemarkTable'
import SendQuoteModal from './SelectQuotePriceModal'
import StoreAndPackagePresetTable from './StoreAndPackagePresetTable'

enum QuoteButtonEnum {
  STORE = 'store',
  CUSTOMER = 'customer',
  COPY_QUOTE_LINK = 'saveQuoteLink',
}

const App: React.FunctionComponent<{ idx: number; isOrder: boolean }> = ({
  idx,
  isOrder,
}: {
  idx: number
  isOrder: boolean
}) => {
  const {
    calculatedPrices: { finalPrice = {} as PriceObject, materialPrice = {} as PriceObject },
    quoteObject,
    customerPhoneNumber,
    selectedCar,
    selectedStore,
    selectedAddressSido,
    selectedAddressSigungu,
    isPartTimer,

    addQuote,
    changeSnackbarAlertContent,
    toggleActivationQuote,
    updateLeadByPK,
    copySelectedProductToClipboard,
    copyQuoteComparisionLink,
    openQuoteComparisonNewTab,
  } = useRootData(({ appStore, quoteStore, leadStore, calculatorStore, authStore }) => ({
    calculatedPrices: calculatorStore.calculatedPrices[idx + 1] || ({} as Partial<CalculatedPrices>),
    quoteObject: quoteStore.quoteObject.get(),
    customerPhoneNumber: leadStore.customerPhoneNumber.get(),
    selectedCar: leadStore.selectedCar.get(),
    selectedStore: calculatorStore.selectedStore.get()[idx + 1],
    selectedAddressSido: leadStore.selectedAddressSido.get(),
    selectedAddressSigungu: leadStore.selectedAddressSigungu.get(),
    isPartTimer: authStore.isPartTimer.get(),

    addQuote: quoteStore.addQuote,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    toggleActivationQuote: quoteStore.toggleActivationQuote,
    updateLeadByPK: leadStore.updateLeadByPK,
    copySelectedProductToClipboard: quoteStore.copySelectedProductToClipboard,
    copyQuoteComparisionLink: quoteStore.copyQuoteComparisionLink,
    openQuoteComparisonNewTab: quoteStore.openQuoteComparisonNewTab,
  }))

  const quoteItem = !isOrder && quoteObject.quote_items.length > 0 && quoteObject.quote_items[idx]

  const sentAt =
    !isOrder && quoteItem && quoteItem.sent_at
      ? quoteItem.sent_at
      : { price: undefined, premium: undefined, package: undefined, lowest: undefined }

  const isAvalableSentQuote =
    Object.keys(sentAt)
      .map((key) => sentAt[key])
      .filter((value) => !!value === true).length > 0

  const { sendQuoteToCustomer, sendQuoteToStore, resendConfirmToStore } = useLeadFunctions()

  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const [sendTo, setSendTo] = useState('')

  const modalStatusHandler = () => {
    setModalStatus(!modalStatus)
  }

  const checkerBeforeSending = (type: QuoteButtonEnum) => {
    if (!selectedCar) {
      changeSnackbarAlertContent({ severity: 'error', content: '차종을 골라주세요!' })

      return false
    }

    if (type === QuoteButtonEnum.STORE && !selectedStore) {
      changeSnackbarAlertContent({ severity: 'error', content: '매장을 골라주세요!' })

      return false
    }

    if (!selectedAddressSido || !selectedAddressSigungu) {
      changeSnackbarAlertContent({ severity: 'error', content: '지역이 설정되지 않았습니다!' })

      return false
    }

    if (!finalPrice.price) {
      changeSnackbarAlertContent({ severity: 'error', content: '가격이 설정되지 않았습니다!' })

      return false
    }

    if (type !== QuoteButtonEnum.COPY_QUOTE_LINK && !customerPhoneNumber) {
      changeSnackbarAlertContent({ severity: 'error', content: '고객 전화번호 미입력!' })

      return false
    }

    return true
  }

  const sendQuoteHandler = async (selectedPrice: string): Promise<void> => {
    if (sendTo === QuoteButtonEnum.CUSTOMER) {
      if (!customerPhoneNumber) {
        changeSnackbarAlertContent({ severity: 'error', content: '고객 전화번호 미입력!' })

        return
      }

      if (!selectedPrice) {
        changeSnackbarAlertContent({ severity: 'error', content: '고객에게 전송할 가격을 선택해주세요!' })

        return
      }

      // 재료비가 있는 제품이 있는지 확인
      const { product_item_detail: productItemDetail } = generateComputedFieldInQuoteItem(quoteItem, idx)

      const errors = productItemDetail
        .map((item) => {
          if (!item) return null

          if (item.attribute?.isMaterialPrice && materialPrice[selectedPrice] === 0) {
            return `재료비가 필요한 제품이 있습니다. (정산 감액으로 재료비를 추가해주세요!)`
          }

          return null
        })
        .filter((item) => item !== null)

      if (errors.length) {
        changeSnackbarAlertContent({
          severity: 'error',
          content: errors[0],
        })

        return
      }
      await updateLeadByPK()
      await sendQuoteToCustomer(quoteItem.id, selectedPrice)
    } else {
      await updateLeadByPK()
      await sendQuoteToStore(quoteItem.id, selectedPrice)
    }
    modalStatusHandler()
  }

  const sendConfirmToStoreHandler = async (): Promise<void> => {
    await updateLeadByPK()
    resendConfirmToStore()
  }

  const copyProductToClipboardClickHandler = () => {
    copySelectedProductToClipboard(idx)

    changeSnackbarAlertContent({ severity: 'success', content: '채팅창에 Ctrl + V' })
  }

  const copyQuoteComparisionLinkClickHandler = () => {
    copyQuoteComparisionLink(idx)

    changeSnackbarAlertContent({ severity: 'success', content: '채팅창에 Ctrl + V' })
  }

  return (
    <div className={styles.quoteContainer}>
      <div>
        {modalStatus && (
          <Modal
            open={modalStatus}
            onClose={(): void => setModalStatus(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <SendQuoteModal
              modalStatusHandler={modalStatusHandler}
              sendQuoteHandler={sendQuoteHandler}
              isAvalableSentQuote={isAvalableSentQuote}
              sentAt={sentAt}
              quoteIdx={idx + 1}
            />
          </Modal>
        )}
      </div>
      <div className={styles.quoteCardContainer}>
        <Card key={idx} className={styles.quoteCard}>
          {!isOrder ? (
            <div>
              <Button
                variant="contained"
                onClick={(): void => {
                  if (!selectedCar) {
                    changeSnackbarAlertContent({ severity: 'error', content: '차종을 골라주세요!' })

                    return
                  }
                  addQuote(idx)
                }}
              >
                이 견적 복사하기
              </Button>

              <Button variant="contained" onClick={(): Promise<void> => toggleActivationQuote(idx)}>
                {(quoteItem && quoteItem.is_active) || (quoteItem && quoteItem.is_active === undefined)
                  ? '견적 비활성화 하기'
                  : '견적 활성화 하기'}
              </Button>
              <Button
                variant="contained"
                onClick={async (): Promise<void> => {
                  if (!checkerBeforeSending(QuoteButtonEnum.CUSTOMER)) return

                  modalStatusHandler()
                  setSendTo(QuoteButtonEnum.CUSTOMER)
                }}
              >
                고객에게 비교 견적 전송
              </Button>

              {!isPartTimer && (
                <Button
                  variant="contained"
                  onClick={async (): Promise<void> => {
                    if (!checkerBeforeSending(QuoteButtonEnum.STORE)) return

                    modalStatusHandler()
                    setSendTo(QuoteButtonEnum.STORE)
                  }}
                >
                  매장에 견적 전송
                </Button>
              )}

              <Button
                variant="contained"
                onClick={async (): Promise<void> => {
                  if (!checkerBeforeSending(QuoteButtonEnum.COPY_QUOTE_LINK)) return

                  copyQuoteComparisionLinkClickHandler()
                }}
              >
                비교 견적 링크 복사
              </Button>

              <Button
                variant="contained"
                onClick={async (): Promise<void> => {
                  copyProductToClipboardClickHandler()
                }}
              >
                제품 요약 클립보드에 저장
              </Button>

              <Button
                variant="contained"
                onClick={async (): Promise<void> => {
                  if (!checkerBeforeSending(QuoteButtonEnum.COPY_QUOTE_LINK)) return

                  openQuoteComparisonNewTab(idx)
                }}
              >
                비교 견적 페이지 확인
              </Button>
            </div>
          ) : (
            <Button variant="contained" onClick={sendConfirmToStoreHandler}>
              매장에 예약 확정톡 재전송
            </Button>
          )}

          <TableContainer className={styles.containerQuote} component={Paper}>
            <StoreAndPackagePresetTable idx={isOrder ? 0 : idx + 1}></StoreAndPackagePresetTable>
            <CalculatorTable idx={isOrder ? 0 : idx + 1} isOrder={isOrder}></CalculatorTable>
            <RemarkTable idx={idx} isOrder={isOrder} />
          </TableContainer>
        </Card>
      </div>
    </div>
  )
}

export default React.memo(App)
