import cytoscape from 'cytoscape'

import { Session } from '../../assets/graphql/graphql'

const createStore = (): typeof graphStore => {
  const graphStore = {
    graph: cytoscape(),

    addNode: (name: string) => {
      const searchedNode = graphStore.graph.$id(name)
      if (!searchedNode.length) {
        return graphStore.graph.add({
          group: 'nodes',
          data: {
            id: name,
          },
        })
      }

      return searchedNode
    },

    addEdge: (source: string, target: string, order: number) => {
      const pair = graphStore.graph.$id(`${source + target}`)

      if (pair.length) {
        pair.data('weight', pair.data('weight') + 1)
        pair.data('order', `${pair.data('order')}, ${order.toString()}`)
      } else {
        graphStore.graph.add({
          group: 'edges',
          data: {
            id: `${source + target}`,
            source,
            target,
            weight: 1,
            order: order.toString(),
          },
        })
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activityToString: (data: Record<string, any>): string => {
      if (data.action === 'pageLoad') {
        return `pageLoad::${data.context.page}`
      }
      if (data.action === 'click') {
        return `click::${data.context.component.atom.content}`
      }
      if (data.action === 'event') {
        return `event::${data.context.content}`
      }

      return null
    },

    setLayout: (layout: cytoscape.LayoutOptions) => {
      const l = graphStore.graph.elements().layout(layout)
      l.run()
    },

    toggleCentrality: (c: string) => {
      if (c === 'dcni') {
        graphStore.graph.nodes().removeClass('dcno bc pr')
      } else if (c === 'dcno') {
        graphStore.graph.nodes().removeClass('dcni bc pr')
      } else if (c === 'bc') {
        graphStore.graph.nodes().removeClass('dcni dcno pr')
      } else if (c === 'pr') {
        graphStore.graph.nodes().removeClass('dcni dcno bc')
      }
      graphStore.graph.nodes().toggleClass(c)
    },

    setDCN: (core: cytoscape.Core): void => {
      const dcn = <cytoscape.SearchDegreeCentralityNormalizedResultDirected>core.elements().degreeCentralityNormalized({
        weight: (e) => {
          return e.data('weight')
        },
        alpha: 1,
        directed: true,
      })

      core.nodes().forEach((n) => {
        n.data('dcni', dcn.indegree(n))
        n.data('dcno', dcn.outdegree(n))
      })
    },

    setBC: (core: cytoscape.Core): void => {
      const bc = core.elements().betweennessCentrality({
        weight: (e) => {
          return e.data('weight')
        },
        directed: true,
      })

      core.nodes().forEach((n) => {
        n.data('bc', bc.betweennessNormalised(n))
      })
    },

    setPR: (core: cytoscape.Core): void => {
      const pr = core.elements().pageRank({})

      core.nodes().forEach((n) => {
        n.data('pr', pr.rank(n))
      })
    },

    init: (list: Array<Session>, options: cytoscape.CytoscapeOptions, layout: cytoscape.LayoutOptions) => {
      graphStore.graph = cytoscape(options)

      graphStore.graph.batch(() => {
        list.forEach((s: Session) => {
          const firstnode = graphStore.addNode(graphStore.activityToString(s.activities[0]))
          if (list.length === 1) {
            firstnode.addClass('start')
          }
          for (let w = 1; w < s.activities.length; w += 1) {
            const source = graphStore.activityToString(s.activities[w - 1])
            const target = graphStore.activityToString(s.activities[w])

            graphStore.addNode(source)

            if (source === target) return

            graphStore.addNode(target)

            graphStore.addEdge(source, target, w)
          }
        })

        graphStore.setDCN(graphStore.graph)
        graphStore.setBC(graphStore.graph)
        graphStore.setPR(graphStore.graph)

        graphStore.graph.on('mouseover', 'node', (e) => {
          const sel = e.target
          graphStore.graph.elements().difference(sel.outgoers()).not(sel).addClass('semitransp')
          sel.addClass('highlight').outgoers().addClass('highlight')
        })
        graphStore.graph.on('mouseout', 'node', (e) => {
          const sel = e.target
          graphStore.graph.elements().removeClass('semitransp')
          sel.removeClass('highlight').outgoers().removeClass('highlight')
        })
      })

      graphStore.setLayout(layout)
    },
  }

  return graphStore
}

const store = createStore()
export default store
