import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import _ from 'lodash'

import { Lead_Status_Enum as LeadStatusEnum } from '../../assets/graphql/graphql'
import { LEAD_COL } from './type'

export const SUBSCRIPTION_LEAD = gql`
  subscription SUBSCRIPTION_LEAD($id: uuid!) {
    lead_by_pk(id: $id) {
      id
      key
      created_at
      updated_at
      phone
      customer
      status
      used_car_status
      start_drop_off_date_time
      end_drop_off_date_time
      due_date
      car_sunroof_type
      carByCar {
        id
        model
        type
        car_maker {
          name_kr
          name_us
          car_origin {
            name_kr
            name_us
          }
        }
      }
      etc
      film_need_to_removed
      chats {
        id
        key
        chat_assignee
        zendesk_chat_name
        accountByChatAssignee {
          id
          profile_riderdash {
            name
          }
        }
      }
      quotes(limit: 1, order_by: { created_at: desc }) {
        id
        lead
        quote_items(where: { deleted_at: { _is_null: true } }, order_by: { created_at: asc }) {
          id
          is_active
          remark
          store
          storeByStore {
            name_business
            phone_business
          }
          price_discount
          price_extra
          price_final
          price_payout
          price_payout_extra
          price_payout_discount
          price_product
          product_item
          deleted_at
          sent_at
          package_detail {
            id
            name
            package_type {
              description
            }
          }
          product_item_detail {
            id
            attribute
            price
            productByProduct {
              product_brand {
                description
              }
              name
              product_type {
                value
                description
              }
            }
          }
        }
      }
      accountBySupportAssignee {
        id
        profile_riderdash {
          id
          name
        }
      }
      accountByShopAssignee {
        id
        profile_riderdash {
          name
          email
        }
      }
      accountByCustomer {
        id
        profile_kr_customer {
          name
          email
        }
      }
      lead_comments(order_by: { created_at: asc }) {
        id
        account
        comment
        created_at
        updated_at
        accountByAccount {
          id
          profile_riderdash {
            name
            email
          }
          profile_kr_customer {
            name
            email
          }
        }
      }
      orders(where: { status: { _neq: canceled } }) {
        id
        created_at
        store
        storeByStore {
          name_business
          phone_business
        }
        basic_price_payout
        price_discount
        price_extra
        price_final
        price_payout
        price_product
        price_payout_extra
        price_payout_discount
        product_item
        remark
        package_detail {
          id
          name
          package_type {
            description
          }
        }
        product_item_detail {
          id
          attribute
          price
          productByProduct {
            product_brand {
              description
            }
            name
            product_type {
              value
              description
            }
          }
        }
        order_and_transactions {
          transactionByTransaction {
            bootpay_payment_intent_id
            stripe_payment_intent_id
          }
        }
        quoteItemByQuoteItem {
          sent_at
        }
        is_auto_order
      }
    }
  }
`

export const SUBSCRIPTION_LEADS = (
  status: LeadStatusEnum,
  startCreatedAt: string,
  endCreatedAt: string,
  endDueDate: string,
  columns: LEAD_COL[],
): DocumentNode => {
  const condition = `(order_by: {created_at: asc_nulls_last, due_date: asc_nulls_first}, 
    where: {
      _and: [
        {status: {_eq: ${status}}}
        {_or: [
          {due_date: {_lt: "${endDueDate}"}}
          {due_date: {_is_null: true}}   
        ]}
        {_and: [
          {created_at: {_gt: "${startCreatedAt}"}}
          {created_at: {_lt: "${endCreatedAt}"}}
        ]}
      ]})`

  const quoteAndOrderGetter = `quotes {
    quote_items(where: { deleted_at: { _is_null: true } }) {
      storeByStore {
        address_detail
        name
        phone_business
        subscriptions {
          status
        }
      }
    }
  }
  orders (where: { status: { _neq: canceled } }) {
    payment_link_sent_at
    paid_at
    price_final
    price_payout
    price_payout_without_vat
    payouts {
      finished_at
    }
    storeByStore {
      address_detail
      name
      phone_business
      subscriptions {
        status
      }
    }
  }`

  const chatGetter = `chats {
    key
    zendesk_chat_name
    accountByChatAssignee {
      profile_riderdash {
        name
      }
    }
  }`

  const customerGetter = `accountByCustomer {
    profile_kr_customer {
      name
      email
    }
  }`

  const canNotMatchGetter = `
    quote_at
    match_at
  `

  const getterByLeadCol: { [key: string]: string } = {
    [LEAD_COL.CHAT_ID]: chatGetter,
    [LEAD_COL.LEAD_ID]: 'key',
    [LEAD_COL.ZENDESK_CHAT_NAME]: chatGetter,
    [LEAD_COL.CHAT_ASSIGNEE]: chatGetter,
    [LEAD_COL.SUPPORT_ASSIGNEE]: `accountBySupportAssignee {
      profile_riderdash {
        name
      }
    }`,
    [LEAD_COL.MATCH_MAKER]: `accountByShopAssignee {
      profile_riderdash {
        name
      }
    }`,
    [LEAD_COL.SELECTED_NEW_CAR]: 'used_car_status',
    [LEAD_COL.START_DROP_OFF_DATE]: 'start_drop_off_date_time',
    [LEAD_COL.END_DROP_OFF_DATE]: 'end_drop_off_date_time',
    [LEAD_COL.DUE_DATE]: 'due_date',
    [LEAD_COL.SHOP]: quoteAndOrderGetter,
    [LEAD_COL.SHOP_PHONE]: quoteAndOrderGetter,
    [LEAD_COL.CARAMORA_ID]: customerGetter,
    [LEAD_COL.CAR]: `carByCar {
      model
      car_maker {
        name_kr
      }
    }`,
    [LEAD_COL.CUSTOMER_NAME]: customerGetter,
    [LEAD_COL.CUSTOMER_PHONE]: 'phone',
    [LEAD_COL.CREATED_AT]: 'created_at',
    [LEAD_COL.UPDATED_AT]: 'updated_at',
    [LEAD_COL.CAN_NOT_MATCH]: canNotMatchGetter,
    [LEAD_COL.PAYMENT_LINK_SENT_AT]: quoteAndOrderGetter,
    [LEAD_COL.PAID_AT]: quoteAndOrderGetter,
    [LEAD_COL.CALL_REQUEST_SAME_DAY_ORDER]: `lead_comments(order_by: { created_at: asc }) {
      comment
      created_at
      updated_at
    }`,
    [LEAD_COL.VAT_REQUESTED_AT]: `lead_comments(order_by: { created_at: asc }) {
      comment
      created_at
      updated_at
    }`,
    [LEAD_COL.PRICE_PAYOUT]: quoteAndOrderGetter,
    [LEAD_COL.PRICE_PAYOUT_WITHOUT_VAT]: quoteAndOrderGetter,
    [LEAD_COL.FINISHED_AT]: quoteAndOrderGetter,
    [LEAD_COL.RSV_COMFIRMED_AT]: 'rsv_confirmed_at',
    [LEAD_COL.CREATED_FROM]: 'created_from',
  }

  const getter = _.uniq(columns.map((col) => getterByLeadCol[col])).join('\n')

  return gql`
    subscription SUBSCRIPTION_LEADS {
      lead ${condition} {
        id
        ${getter}
        status
      }
    }
  `
}

export const INSERT_LEAD = gql`
  mutation INSERT_LEAD($leadObject: lead_insert_input!) {
    insert_lead_one(object: $leadObject) {
      id
    }
  }
`

export const UPDATE_LEAD_BY_PK = gql`
  mutation UPDATE_LEAD_BY_PK($leadId: uuid!, $leadObject: lead_set_input!) {
    update_lead_by_pk(pk_columns: { id: $leadId }, _set: $leadObject) {
      id
    }
  }
`

export const UPDATE_LEAD_AND_QUOTE = gql`
  mutation UPDATE_LEAD_AND_QUOTE($leadId: uuid!, $leadObject: lead_set_input!, $quoteObject: quote_insert_input!) {
    update_lead_by_pk(pk_columns: { id: $leadId }, _set: $leadObject) {
      id
    }
    insert_quote_one(object: $quoteObject, on_conflict: { constraint: quote_pkey, update_columns: [lead] }) {
      id
    }
  }
`

export const UPDATE_CHAT_AND_LEAD_AND_QUOTE = gql`
  mutation UPDATE_CHAT_AND_LEAD_AND_QUOTE(
    $chatId: uuid!
    $leadId: uuid!
    $chatObject: chat_set_input!
    $leadObject: lead_set_input!
    $quoteObject: quote_insert_input!
  ) {
    update_chat_by_pk(pk_columns: { id: $chatId }, _set: $chatObject) {
      id
    }
    update_lead_by_pk(pk_columns: { id: $leadId }, _set: $leadObject) {
      id
    }
    insert_quote_one(object: $quoteObject, on_conflict: { constraint: quote_pkey, update_columns: [lead] }) {
      id
    }
  }
`

export const UPDATE_LEAD_AND_QUOTE_AND_ORDER = gql`
  mutation UPDATE_LEAD_AND_QUOTE_AND_ORDER(
    $leadId: uuid!
    $orderId: uuid!
    $leadObject: lead_set_input!
    $orderObject: order_set_input!
    $quoteObject: quote_insert_input!
  ) {
    update_lead_by_pk(pk_columns: { id: $leadId }, _set: $leadObject) {
      id
    }
    update_order_by_pk(pk_columns: { id: $orderId }, _set: $orderObject) {
      id
    }
    insert_quote_one(object: $quoteObject, on_conflict: { constraint: quote_pkey, update_columns: [lead] }) {
      id
    }
  }
`

export const UPDATE_CHAT_AND_LEAD_AND_QUOTE_AND_ORDER = gql`
  mutation UPDATE_CHAT_AND_LEAD_AND_QUOTE_AND_ORDER(
    $chatId: uuid!
    $leadId: uuid!
    $orderId: uuid!
    $chatObject: chat_set_input!
    $leadObject: lead_set_input!
    $orderObject: order_set_input!
    $quoteObject: quote_insert_input!
  ) {
    update_chat_by_pk(pk_columns: { id: $chatId }, _set: $chatObject) {
      id
    }
    update_lead_by_pk(pk_columns: { id: $leadId }, _set: $leadObject) {
      id
    }
    update_order_by_pk(pk_columns: { id: $orderId }, _set: $orderObject) {
      id
    }
    insert_quote_one(object: $quoteObject, on_conflict: { constraint: quote_pkey, update_columns: [lead] }) {
      id
    }
  }
`

export const UPDATE_LEAD_STATUS = (status: string): DocumentNode => {
  const setter = `_set: { status: ${status}, ${status}_at: $now }`

  return gql`
    mutation UPDATE_LEAD_STATUS($id: uuid!, $now: timestamptz!) {
      update_lead_by_pk(pk_columns: { id: $id }, ${setter}) {
        id
      }
    }
  `
}

export const CHECK_LEAD_EXISTENCE = gql`
  query CHECK_LEAD_EXISTENCE($phone: String!) {
    lead(
      where: {
        _and: [
          { _or: [{ status: { _eq: lead } }, { status: { _eq: quote } }, { status: { _eq: match } }] }
          { phone: { _eq: $phone } }
        ]
      }
    ) {
      id
    }
  }
`

export const SEARCH_LEAD = gql`
  query SEARCH_LEAD($chatId: Int!, $leadId: Int!, $email: String!, $phone: String!, $zendeskChatName: String!) {
    leads: lead(
      where: {
        _or: [
          { chats: { key: { _eq: $chatId } } }
          { key: { _eq: $leadId } }
          { accountByCustomer: { profile_kr_customer: { email: { _ilike: $email } } } }
          { phone: { _ilike: $phone } }
          { chats: { zendesk_chat_name: { _ilike: $zendeskChatName } } }
        ]
      }
    ) {
      id
      key
      chats {
        key
      }
      created_at
      updated_at
      phone
      status
      due_date
      accountByCustomer {
        id
        profile_kr_customer {
          email
        }
      }
    }
  }
`

export const INSERT_LEAD_COMMENT = gql`
  mutation INSERT_LEAD_COMMENT($leadComment: lead_comment_insert_input!) {
    insert_lead_comment_one(object: $leadComment) {
      id
      lead
      leadByLead {
        id
      }
      accountByAccount {
        id
        profile_riderdash {
          name
          email
        }
      }
    }
  }
`

export const UPDATE_LEAD_COMMENT = gql`
  mutation UPDATE_LEAD_COMMENT($id: uuid!, $comment: String!) {
    update_lead_comment(where: { id: { _eq: $id } }, _set: { comment: $comment }) {
      affected_rows
    }
  }
`
