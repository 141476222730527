import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const GET_ORDER_DETAIL = (): DocumentNode => {
  return gql`
    query GET_ORDER_DETAIL($id: uuid!) {
      order: order(where: { id: { _eq: $id } }) {
        id
        car
        product_item
        price_discount
        price_product
        price_final
        date_time
        price_extra
        commission_rate
        remark
        accountByAccount {
          id
        }
        name
        email
        phone
        carByCar {
          model
          prefix
          postfix
          car_maker {
            car_origin {
              name_kr
            }
            name_kr
          }
          car_type {
            value
            description
          }
        }
        carSunroofTypeByCarSunroofType {
          value
          description
        }
        order_status {
          value
          description
        }
        storeByStore {
          id
          address_detail
          name
        }
      }
      packages: package(order_by: { name: asc }) {
        id
        name
      }
    }
  `
}

export const UPDATE_ORDER_CONTACT = (): DocumentNode => {
  return gql`
    mutation UPDATE_ORDER_CONTACT($id: uuid!, $newOrderDetail: order_set_input) {
      update_order(where: { id: { _eq: $id } }, _set: $newOrderDetail) {
        affected_rows
      }
    }
  `
}
