// For Production

export default {
  // Firebase
  firebaseConfig: {
    apiKey: 'AIzaSyALZ_WDqaepAw8ncGR6LKMnoIFe4TqB33A',
    authDomain: 'caramora-web-v1.firebaseapp.com',
    databaseURL: 'https://caramora-web-v1.firebaseio.com',
    projectId: 'caramora-web-v1',
    storageBucket: 'caramora-web-v1.appspot.com',
    messagingSenderId: '1084123577646',
    appId: '1:1084123577646:web:9a7baa1686406882730ebf',
    measurementId: 'G-53FGBBD1H4',
  },
  // AWS
  awsConfig: {
    accessKeyId: 'AKIAUGXAMOK7JJIEFCML',
    secretAccessKeyId: '3St2dYn2Dpl7ggJDAXvJpn1BNt6GsmgneXtAvUEh',
    imageBucketName: 'caramora-web-images',
    regionName: 'ap-northeast-2',
    databaseURL: 'https://images.caramora.com',
  },
  // Stripe
  stripePublicApiKey: 'pk_live_83D4OvfN98RfbfdkcpWRECZQ00uirJuixj',
  // Zendesk
  zendeskConfig: {
    account_key: 'F6QSy30CSi1rLtDYZ4mx5umt67yxAy5s',
  },
  // GraphQL
  graphqlEndPointHTTPS: 'https://api.caramora.com/v1/graphql',
  graphqlEndPointWS: 'wss://api.caramora.com/v1/graphql',
  /* MsgCenter */
  msgCenterSecureKey: 'JdxFo39uhZRm6v65krtVS7pH65lhxcyT',
  // msgCenterURL: 'http://localhost:3000',
  msgCenterURL: 'https://api.riderdash.com',
  // Hasura
  hasuraURL: 'https://api.caramora.com/v1/graphql',
  /* Functions */
  // Firebase
  // backendEndPoint: 'https://asia-northeast1-caramora-web-v1.cloudfunctions.net/backend',
  // AWS
  backendEndPoint: 'https://9opl3xiuzg.execute-api.ap-northeast-2.amazonaws.com/prod',
  frontendUrl: 'https://www.caramora.com',
  camoKitProductItemId: '8cb0b7bb-1506-467d-a1f6-3d133d38cb74',
  deliveryFeeProductItemId: 'ba58d447-bd0e-4e90-9b32-0ef202972d86',
}
