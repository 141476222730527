import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Matrix } from 'ml-matrix'
import { PCA } from 'ml-pca'
import React, { useEffect } from 'react'
import { CartesianGrid, Legend, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts'

import {
  Activity,
  Get_MajorsQuery as GetMajorsQuery,
  Get_MajorsQueryVariables as GetMajorsQueryVariables,
  Session,
} from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

interface Coordinate {
  x: number
  y: number
  z: number
}

const GET_MAJORS = gql`
  query GET_MAJORS($sessionFilter: uuid_comparison_exp) {
    session(where: { activities: { rank: { _eq: 1 } } }, limit: 100, order_by: { created_at: desc }) {
      id
      activities(order_by: { created_at: asc }, where: { rank: { _eq: 1 } }) {
        action
        context
        rank
      }
    }
  }
`

const App: React.FunctionComponent = () => {
  const { activityToString } = useRootData(({ graphStore }) => ({
    activityToString: graphStore.activityToString,
  }))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [getData, { data }] = useLazyQuery<GetMajorsQuery, GetMajorsQueryVariables>(GET_MAJORS, {
    fetchPolicy: 'no-cache',
  })

  // outputs a dictionary, with keys as activities and values as arrays of users (each index corresponds to 1 user)
  // this is done so that each row represents a feature, and each column represents a user when converted to a matrix
  const usersToFeatures = (sessionData: GetMajorsQuery): Record<string, Array<number>> => {
    const featureDict: Record<string, Array<number>> = {}
    // get all possible sessions and save them as keys.
    sessionData.session.forEach((sess: Session) => {
      sess.activities.forEach((activity: Activity) => {
        const event = activityToString(activity)
        if (!featureDict[event]) {
          featureDict[event] = new Array<number>(sessionData.session.length).fill(0)
        }
      })
    })

    // append users to said keys
    let index = 0
    data.session.forEach((sess: Session) => {
      sess.activities.forEach((activity: Activity) => {
        featureDict[activityToString(activity)][index] += 1
      })
      index += 1
    })

    return featureDict
  }

  useEffect(() => {
    getData()
  }, [])

  if (!data) return null

  const featureDict = usersToFeatures(data)
  const keys = Object.keys(featureDict)
  // transposing of featureDict is required since we're trying to inspect users in a feature space, not features in user space.
  const transposedValues = new Matrix(Object.values(featureDict)).transpose()
  const pca = new PCA(transposedValues)
  const variance = pca.getExplainedVariance()
  const eigenvec = pca.getEigenvectors().abs()

  const vecToFeatureRank = (vec: number[]) => {
    return vec
      .map((value, index) => {
        return { feature: keys[index], value }
      })
      .sort((a, b) => {
        return b.value - a.value
      })
  }

  // rank features in terms of importance to each component
  const componentRank = [
    vecToFeatureRank(eigenvec.getRow(0)),
    vecToFeatureRank(eigenvec.getRow(1)),
    vecToFeatureRank(eigenvec.getRow(2)),
  ]

  const ClickChatUser: Array<Coordinate> = []
  const AddCartUser: Array<Coordinate> = []
  const NormalUser: Array<Coordinate> = []

  pca
    .predict(transposedValues)
    .to2DArray()
    .forEach((values, index) => {
      if (featureDict['click::실시간 상담'][index]) {
        ClickChatUser.push({ x: values[0], y: values[1], z: values[2] })
      } else if (featureDict['event::Add to Cart'][index]) {
        AddCartUser.push({ x: values[0], y: values[1], z: values[2] })
      } else NormalUser.push({ x: values[0], y: values[1], z: values[2] })
    })

  return (
    <div className="body">
      <div>
        <div>PCA Chart of Users</div>
        <div>3 dimensions provide {((variance[0] + variance[1] + variance[2]) * 100).toPrecision(4)}% accuracy.</div>
        <div>
          PC1 Variance Ratio: <b>{(variance[0] * 100).toPrecision(4)}%</b>. PC2 Variance Ratio:{' '}
          <b>{(variance[1] * 100).toPrecision(4)}%</b>. PC3 Variance Ratio: <b>{(variance[2] * 100).toPrecision(4)}%</b>
          .
        </div>
        <ScatterChart
          className={styles.plot}
          width={730}
          height={500}
          margin={{ top: 20, right: 20, bottom: 10, left: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" name="pc1" />
          <YAxis dataKey="y" name="pc2" />
          <ZAxis dataKey="z" range={[60, 400]} name="pc3" />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Legend />
          <Scatter name="click::실시간 상담" data={ClickChatUser} fill="#8884d8" />
          <Scatter name="event::Add to Cart" data={AddCartUser} fill="#d88884" />
          <Scatter name="user" data={NormalUser} fill="#84d888" />
        </ScatterChart>
        <div>
          {componentRank.map((ranking, index) => (
            <table key={index} className={styles.rankTable}>
              <thead>
                <tr>
                  <th colSpan={2}>Principal Component {index + 1}</th>
                </tr>
                <tr>
                  <th>index</th>
                  <th>Feature Name</th>
                </tr>
              </thead>
              <tbody>
                {ranking.slice(0, 10).map((featureObj, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{ind + 1}</td>
                      <td>{featureObj.feature}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
