import { useLazyQuery } from '@apollo/react-hooks'
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'

import { Review, Review_Status_Enum as ReviewStatusEnum } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { GET_REVIEW, UPDATE_REVIEW_STATUS, UPDATE_SELECT_REVIEW_STATUS } from './query'
import ReviewRow from './ReviewRow'

const App: React.FunctionComponent = () => {
  const { changeSnackbarAlertContent, changeLoadingSpinnerVisibility } = useRootData(({ appStore }) => ({
    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))

  const [reviews, setReviews] = useState(null)
  const [selected, setSelected] = React.useState<string[]>([])

  const [getReviews, { data: reviewContents, loading, error }] = useLazyQuery(GET_REVIEW, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getReviews()
  }, [])

  useEffect(() => {
    if (loading) {
      changeLoadingSpinnerVisibility(true)
    } else {
      if (reviewContents) {
        setReviews(reviewContents.review)
      }
      changeLoadingSpinnerVisibility(false)
    }
  }, [loading])

  if (error) {
    Sentry.captureException(error)
  }

  if (!reviews) return null

  const clickAllHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = reviews.map((item: Review) => item.id)
      setSelected(newSelecteds)

      return
    }
    setSelected([])
  }

  const checkboxClickHandler = (reviewId: string) => {
    const selectedIndex = selected.indexOf(reviewId)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, reviewId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const updateReviewStatusClickHandler = async (status: ReviewStatusEnum, review: Review) => {
    try {
      await client.mutate({
        mutation: UPDATE_REVIEW_STATUS,
        variables: {
          reviewId: review.id,
          status,
        },
      })

      changeSnackbarAlertContent({
        severity: 'success',
        content: '업데이트 완료',
      })

      getReviews()
      setSelected([])
    } catch (err) {
      Sentry.captureException(error)
    }
  }

  const updateSelectedReviewStatusClickHandler = async (status: ReviewStatusEnum) => {
    try {
      let reviewId = ''

      selected.forEach((id) => {
        reviewId += `{id: { _eq: "${id}"}}`
      })

      await client.mutate({
        mutation: UPDATE_SELECT_REVIEW_STATUS(reviewId),
        variables: {
          status,
        },
      })

      changeSnackbarAlertContent({
        severity: 'success',
        content: '업데이트 완료',
      })

      getReviews()
      setSelected([])
    } catch (err) {
      Sentry.captureException(error)
    }
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
        <div>
          <Typography variant="h6" id="tableTitle" component="div">
            {'리뷰 관리'}
          </Typography>
        </div>
        {selected.length > 0 && (
          <div>
            <Button
              variant="contained"
              color={'primary'}
              onClick={(): Promise<void> => updateSelectedReviewStatusClickHandler(ReviewStatusEnum.Approved)}
            >
              {'선택된 리뷰 승인'}
            </Button>
            <Button
              variant="contained"
              color={'secondary'}
              onClick={(): Promise<void> => updateSelectedReviewStatusClickHandler(ReviewStatusEnum.Unapproved)}
            >
              {'선택된 리뷰 미승인'}
            </Button>
          </div>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                { label: '' },
                { label: 'No.' },
                { label: 'created_at' },
                { label: 'Review ID' },
                { label: '상태', orderBy: '' },
                { label: '매장명', orderBy: '' },
                { label: '고객 전화번호', orderBy: '' },
                { label: '평점', orderBy: '' },
                { label: 'Comment' },
                { label: '' },
              ].map((head, index) => {
                if (index === 0) {
                  return (
                    <TableCell padding="checkbox">
                      <Checkbox inputProps={{ 'aria-labelledby': '' }} onChange={clickAllHandler} />
                    </TableCell>
                  )
                }

                return (
                  <TableCell key={index} style={{ whiteSpace: 'nowrap' }}>
                    <div>{head.label}</div>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {reviews.map((review: Review, index: number) => {
              return (
                <ReviewRow
                  key={index}
                  index={index}
                  length={reviews.length}
                  review={review}
                  isSelected={isSelected}
                  clickHandler={{
                    checkboxClickHandler,
                    updateReviewStatusClickHandler,
                  }}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default React.memo(App)
