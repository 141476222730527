import React from 'react'

import styles from './index.module.scss'

type ValueSetterProps = {
  title: string
  list: Array<{ id: string; display: string }>
  setter: React.Dispatch<React.SetStateAction<string>>
}

const App: React.FunctionComponent<ValueSetterProps> = ({ title, list, setter }: ValueSetterProps) => {
  return (
    <div className={styles.tvs}>
      <table>
        <tbody>
          <tr>
            <th colSpan={2} className={styles.title}>
              {title}
            </th>
          </tr>
          {list.map((item: { id: string; display: string }, index: number) => {
            return (
              <tr key={index}>
                <td>{item.display ? item.display : 'null'}</td>
                <td>{item.id}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <textarea
        className={styles.text}
        defaultValue={list
          .map((item: { id: string; display: string }) => {
            return item.id
          })
          .join(',\n')}
        onChange={(e): void => {
          setter(e.target.value)
        }}
      />
      <br />
    </div>
  )
}
export default React.memo(App)
