// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__colNameRow___1PXRN {\n  background-color: #b388ff; }\n  .index-module__colNameRow___1PXRN .index-module__reset___3nrfc {\n    max-width: 30px;\n    min-width: 40px;\n    color: #ffffff;\n    text-decoration: underline; }\n  .index-module__colNameRow___1PXRN .index-module__reset___3nrfc:hover {\n    max-width: 15px;\n    color: #000;\n    background-color: #efefef; }\n  .index-module__colNameRow___1PXRN .index-module__col___QwZvy {\n    font-weight: bold;\n    cursor: pointer;\n    color: #ffffff; }\n    .index-module__colNameRow___1PXRN .index-module__col___QwZvy .index-module__arrow___2mCNz {\n      width: 5px;\n      height: 10px; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/LeadList/LeadListTable/LeadOrderByRow/index.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAA;EAD3B;IAII,eAAe;IACf,eAAe;IACf,cAAc;IACd,0BAA0B,EAAA;EAP9B;IAWI,eAAe;IACf,WAAW;IACX,yBAAyB,EAAA;EAb7B;IAiBI,iBAAiB;IACjB,eAAe;IACf,cAAc,EAAA;IAnBlB;MAsBM,UAAU;MACV,YAAY,EAAA","file":"index.module.scss","sourcesContent":[".colNameRow {\n  background-color: #b388ff;\n\n  .reset {\n    max-width: 30px;\n    min-width: 40px;\n    color: #ffffff;\n    text-decoration: underline;\n  }\n\n  .reset:hover {\n    max-width: 15px;\n    color: #000;\n    background-color: #efefef;\n  }\n\n  .col {\n    font-weight: bold;\n    cursor: pointer;\n    color: #ffffff;\n\n    .arrow {\n      width: 5px;\n      height: 10px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"colNameRow": "index-module__colNameRow___1PXRN",
	"reset": "index-module__reset___3nrfc",
	"col": "index-module__col___QwZvy",
	"arrow": "index-module__arrow___2mCNz"
};
module.exports = exports;
