import { Button, MenuItem, TextField } from '@material-ui/core'
import React, { useState } from 'react'

import useRootData from '../../../../../hooks/useRootData'
import { ProductCategory, ProductDisplayNames } from '../../../../../stores/calculator/type'
import styles from './index.module.scss'

interface PropsSelectProductItemModal {
  hideModalHandler: () => void
  quoteIdx: number
  productCategory: ProductCategory
}

const App: React.FunctionComponent<PropsSelectProductItemModal> = ({
  hideModalHandler,
  quoteIdx,
  productCategory,
}: PropsSelectProductItemModal) => {
  const {
    changeSnackbarAlertContent,
    generateOptions,
    appendCustomProduct,
    selectedCar,
    selectedAddressSigungu,
  } = useRootData(({ appStore, calculatorStore, leadStore }) => ({
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    generateOptions: calculatorStore.generateOptions,
    appendCustomProduct: calculatorStore.appendCustomProduct,
    selectedCar: leadStore.selectedCar.get(),
    selectedAddressSigungu: leadStore.selectedAddressSigungu.get(),
  }))

  const productItems = generateOptions(productCategory, quoteIdx, true)

  const [selectedProductItem, setSelectedProductItem] = useState<string>('')

  const forkSelectedProductItem = () => {
    const [productItem] = productItems.filter((item) => item.value === selectedProductItem)
    if (!productItem?.value) return
    if (!productItem.price) {
      if (!selectedAddressSigungu) changeSnackbarAlertContent({ severity: 'warning', content: '지역을 선택해주세요' })
      else if (!selectedCar) changeSnackbarAlertContent({ severity: 'warning', content: '차종을 선택해주세요' })
      else changeSnackbarAlertContent({ severity: 'warning', content: '가격을 불러올 수 없습니다!' })

      return
    }
    appendCustomProduct(
      quoteIdx,
      `${ProductDisplayNames[productCategory]} - ${
        productItem.text.indexOf('(재고') === -1 ? productItem.text : productItem.text.split('(재고')[0]
      }`,
      {
        ...productItem.price,
        // if package price doesn't defined, it use lowest price as a package price
        ...(productItem.price.package === 0 ? { package: productItem.price.lowest } : {}),
      },
    )
    hideModalHandler()
  }

  return (
    <div className={styles.container}>
      <TextField
        className={styles.selectBox}
        select
        label="Select"
        value={selectedProductItem}
        onChange={(e) => setSelectedProductItem(e.target.value)}
        helperText="복사할 제품을 선택하세요"
        variant="filled"
      >
        {productItems.map((productItem) => (
          <MenuItem key={productItem.value} value={productItem.value}>
            {productItem.text}
          </MenuItem>
        ))}
      </TextField>

      <div className={styles.btnArea}>
        <div className={styles.btnFrame}>
          <Button variant="contained" color="primary" onClick={() => forkSelectedProductItem()}>
            제품 복사
          </Button>
          <Button variant="contained" color="secondary" onClick={hideModalHandler}>
            취소
          </Button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(App)
