import { AppBar, Tab, Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Warning } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import Close from '../../../assets/images/close_white.svg'
import Image from '../../../components/atoms/Image'
import Text from '../../../components/atoms/Text'
import useRootData from '../../../hooks/useRootData'
import Quote from '../Quote'
import styles from './index.module.scss'

let setTargetAsOrder = false
let setTargetAsActiveQuote = false
let timeOutId: NodeJS.Timeout

const App: React.FunctionComponent = () => {
  const {
    orderObject,
    quoteObject,
    numberOfQuoteItems,
    detectDiffPrice,
    selectedLead,
    changeLoadingSpinnerVisibility,
    removeQuote,
    addQuote,
  } = useRootData(({ appStore, orderStore, quoteStore, leadStore, calculatorStore }) => ({
    orderObject: orderStore.orderObject.get(),
    quoteObject: quoteStore.quoteObject.get(),
    numberOfQuoteItems: quoteStore.numberOfQuoteItems,
    detectDiffPrice: calculatorStore.detectDiffPrice,
    selectedLead: leadStore.selectedLead.get(),

    changeLoadingSpinnerVisibility: appStore.changeLoadingSpinnerVisibility,
    removeQuote: quoteStore.removeQuote,
    addQuote: quoteStore.addQuote,
  }))

  const [target, setTarget] = React.useState(0)
  const [deleyRenderFlag, setDeleyRenderFlag] = useState(true)

  useEffect(() => {
    changeLoadingSpinnerVisibility(deleyRenderFlag)
  }, [deleyRenderFlag])

  useEffect(() => {
    return () => {
      setTargetAsOrder = false
      clearTimeout(timeOutId)
    }
  }, [])

  useEffect(() => {
    clearTimeout(timeOutId)
    setDeleyRenderFlag(true)

    // Delays due to rendering issues
    timeOutId = setTimeout(() => {
      setDeleyRenderFlag(false)
    }, 1000)
  }, [selectedLead.id])

  useEffect(() => {
    if (orderObject) {
      if (!setTargetAsOrder) {
        setTarget(numberOfQuoteItems)
        setTargetAsOrder = true
      }
    } else {
      setTarget(0)
    }
  }, [orderObject])

  useEffect(() => {
    if (quoteObject && !setTargetAsOrder && !setTargetAsActiveQuote) {
      const index = quoteObject.quote_items
        .map((quoteItem) => quoteItem.is_active || quoteItem.is_active === undefined)
        .indexOf(true)
      if (index !== -1) setTarget(index)
      setTargetAsActiveQuote = true
    }
  }, [quoteObject])

  if (!(quoteObject && quoteObject.quote_items && quoteObject.quote_items.length > 0)) return null

  const quoteClickHandler = (index: number) => {
    setTarget(index)
  }

  const addQuoteHandler = () => {
    addQuote()
    setTarget(numberOfQuoteItems)
  }

  const removeQuoteHandler = async (index: number) => {
    if (numberOfQuoteItems === 1) return

    if (index === numberOfQuoteItems - 1) {
      setTarget(index - 1)
    } else if (index === numberOfQuoteItems - 2) {
      setTarget(index)
    }
    await removeQuote(index)
  }

  const a11yProps = (index: number) => {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    }
  }

  return (
    <div>
      <AppBar style={{ position: 'relative', zIndex: 0 }}>
        <Tabs className={styles.tabs} value={target}>
          {quoteObject &&
            quoteObject.quote_items.length > 0 &&
            quoteObject.quote_items.map((quoteItem, index) => {
              const isActive = quoteItem.is_active || quoteItem.is_active === undefined

              const priceDiffDetected = detectDiffPrice[index + 1]

              const StyledTab = withStyles(() => ({
                root: {},
              }))(() => (
                <div
                  style={{ display: quoteItem.deleted_at ? 'none' : null }}
                  className={isActive ? styles.isActiveTab : styles.isPassiveTab}
                >
                  <Tab
                    disableRipple
                    key={index}
                    value={index}
                    label={`견적${index + 1}${isActive ? '' : '(비활성)'}`}
                    wrapped
                    {...a11yProps(index)}
                    onClick={(): void => quoteClickHandler(index)}
                  />
                  {priceDiffDetected ? (
                    <Warning style={{ verticalAlign: 'middle', alignSelf: 'center' }} color="secondary" />
                  ) : null}
                  <Image
                    className={styles.removeBtn}
                    src={Close}
                    clickHandler={(): Promise<void> => removeQuoteHandler(index)}
                  />
                </div>
              ))

              return <StyledTab key={index}></StyledTab>
            })}
          <Text text={'+'} className={styles.addQuoteButton} clickHandler={addQuoteHandler} />

          {orderObject && (
            <Tab
              className={styles.tab}
              disableRipple
              key={numberOfQuoteItems}
              value={numberOfQuoteItems}
              label={
                <span>
                  {'주문 건'}
                  {detectDiffPrice[0] ? (
                    <Warning
                      style={{ verticalAlign: 'middle', alignSelf: 'center', marginLeft: '10px' }}
                      color="secondary"
                    />
                  ) : null}
                </span>
              }
              wrapped
              {...a11yProps(numberOfQuoteItems)}
              onClick={(): void => quoteClickHandler(numberOfQuoteItems)}
            />
          )}
        </Tabs>
      </AppBar>

      {!deleyRenderFlag ? <Quote idx={target} isOrder={numberOfQuoteItems === target} /> : '...계산기 불러오는 중'}
    </div>
  )
}

export default React.memo(App)
