/* eslint-disable camelcase */
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

import { Quote, Quote_Item_Select_Column as QuoteItemSelectColumn } from '../../assets/graphql/graphql'
import { Remark } from '.'

export const pickedColumns: QuoteItemSelectColumn[] = [
  QuoteItemSelectColumn.Id,
  QuoteItemSelectColumn.Store,
  QuoteItemSelectColumn.DeletedAt,
  QuoteItemSelectColumn.PriceDiscount,
  QuoteItemSelectColumn.PriceExtra,
  QuoteItemSelectColumn.PriceFinal,
  QuoteItemSelectColumn.PriceProduct,
  QuoteItemSelectColumn.ProductItem,
  QuoteItemSelectColumn.BasicPricePayout,
  QuoteItemSelectColumn.PriceTotal,
  QuoteItemSelectColumn.PricePayoutExtra,
  QuoteItemSelectColumn.PricePayoutDiscount,
  QuoteItemSelectColumn.PricePayout,
  QuoteItemSelectColumn.IsActive,
  QuoteItemSelectColumn.Remark,
]

export const UPSERT_QUOTE_WITH_LEAD = gql`
  mutation UPSERT_QUOTE_WITH_LEAD($quoteObject: quote_insert_input!) {
    insert_quote_one(object: $quoteObject, on_conflict: { constraint: quote_pkey }) {
      id
    }
  }
`

export const UPDATE_CART_ITEM_REMARK = (quoteObject: Quote): DocumentNode => {
  const generateQuery = (index: number, quoteItemId: string, remark: Remark) => {
    return `update_cart_item${index}: update_cart_item(where: { quote_item: { _eq: "${quoteItemId}" } }, _set: {remark: {
      customer_remark: "${
        remark?.customer_remark ? remark.customer_remark.split('\n').join('\\n').replace(/"/g, '\\"') : ''
      }",
      date_time_remark: "${
        remark?.date_time_remark ? remark.date_time_remark.split('\n').join('\\n').replace(/"/g, '\\"') : ''
      }",
    }}) {
      affected_rows
    }`
  }

  const query = quoteObject.quote_items
    .map((quoteItem, index) => {
      const { id, remark } = quoteItem

      if (!id) return null

      return generateQuery(index, id, remark)
    })
    .join('\n')

  return gql`
    mutation UPDATE_CART_ITEM_REMARK {
      ${query}
    }
  `
}
