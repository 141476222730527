// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___wQoS_ {\n  padding: 20px; }\n  .index-module__container___wQoS_ .index-module__uuid___1T3aJ {\n    width: 300px; }\n  .index-module__container___wQoS_ table tbody tr:nth-child(2n) {\n    background-color: #efefef; }\n", "",{"version":3,"sources":["/Users/dkim/Documents/caramora-web-admin-v1/src/pages/Subscription/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAGI,YAAY,EAAA;EAHhB;IAQQ,yBAAyB,EAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 20px;\n  .uuid {\n    width: 300px;\n  }\n  table {\n    tbody {\n      tr:nth-child(2n) {\n        background-color: #efefef;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___wQoS_",
	"uuid": "index-module__uuid___1T3aJ"
};
module.exports = exports;
