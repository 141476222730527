import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React from 'react'

import { Get_ProductQuery as GetProductQuery, Product } from '../../assets/graphql/graphql'
import useRootData from '../../hooks/useRootData'
import styles from './index.module.scss'

const GET_PRODUCT = gql`
  query GET_PRODUCT {
    products: product {
      id
      status
      name
      type
      attribute
      brand
      image
      product_brand {
        description
      }
      product_items {
        sku
        price
      }
    }
  }
`

const App: React.FunctionComponent = () => {
  const { changeMainMenu } = useRootData(({ appStore }) => ({
    changeMainMenu: appStore.changeMainMenu,
  }))

  const { data, loading, error } = useQuery<GetProductQuery>(GET_PRODUCT, {
    fetchPolicy: 'no-cache',
  })

  if (loading) return null
  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>제품 목록</div>
        <button
          className={styles.addButton}
          onClick={(): void => {
            changeMainMenu(`/product-detail`)
          }}
        >
          제품 추가
        </button>

        <table>
          <tbody>
            <tr>
              <th className={styles.number}>No.</th>
              <th className={styles.uuid}>uuid</th>
              <th className={styles.number}>노출여부</th>
              <th className={styles.headerText}>제품종류</th>
              <th className={styles.headerText}>브랜드</th>
              <th className={styles.headerText}>제품명</th>
              <th className={styles.headerText}>필름 농도</th>
              <th></th>
            </tr>
            {data.products.map((item: Product, index: number) => (
              <tr key={index}>
                <td>{data.products.length - index}</td>
                <td>{item.id}</td>
                <td>{item.status ? 'O' : 'X'}</td>
                <td>{item.type}</td>
                <td>{item.brand}</td>
                <td>{item.name}</td>
                <td>{item.attribute.film_density && Object.keys(item.attribute.film_density)}</td>
                <td>
                  <button
                    onClick={(): void => {
                      if (item.type === 'tinting') {
                        changeMainMenu(`/tinting-product-detail?id=${item.id}`)
                      } else {
                        changeMainMenu(`/product-detail?id=${item.id}`)
                      }
                    }}
                  >
                    상세보기
                  </button>
                  <button
                    onClick={(): void => {
                      changeMainMenu(`/product-item-list?id=${item.id}`)
                    }}
                  >
                    sku수정
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default React.memo(App)
