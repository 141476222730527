import './editor.scss'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'

import { ApolloQueryResult } from '@apollo/client'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import gql from 'graphql-tag'
import React, { Fragment, useEffect, useState } from 'react'

import {
  Car,
  Car_Maker as carMaker,
  Car_Sunroof_Type as carSunroofType,
  Get_Gallery_Car_ModelQuery as GetGalleryCarModelQuery,
  Get_Gallery_Car_ModelQueryVariables as GetGalleryCarModelQueryVariables,
  Get_Gallery_Detail_InfoQuery as GetGalleryDetailInfoQuery,
  Get_Gallery_DetailQuery as GetGalleryDetailQuery,
  Get_Gallery_ProductQuery as GetGalleryProductQuery,
  Get_Gallery_ProductQueryVariables as GetGalleryProductQueryVariables,
  Product_Item as productItem,
  Search_Store_By_NameQuery as SearchStoreByNameQuery,
  Search_Store_By_NameQueryVariables as SearchStoreByNameQueryVariables,
  Store,
} from '../../assets/graphql/graphql'
import UploadImage from '../../components/organism/UploadImage'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { parseQueryString } from '../../utils/utility'
import { GET_CURATION, UPDATE_CURATION } from '../Curation'
import styles from './index.module.scss'

export interface GalleryObejctType {
  id: string
  car: string
  store: string
  // eslint-disable-next-line camelcase
  product_item: {
    tinting: {
      front: string
      sideback: string
      sunroof: string
    }
  }
  account: string
  // eslint-disable-next-line camelcase
  car_sunroof_type: string
  price: number
}

export interface BlogObejctType {
  id: string
  title: string
  content: string
  account: string
  store: string
  image: {
    // eslint-disable-next-line camelcase
    jpeg_aws: string
    // eslint-disable-next-line camelcase
    thumb_aws: string
  }[]
  thumbnail: {
    square: {
      // eslint-disable-next-line camelcase
      jpeg_aws: string
      // eslint-disable-next-line camelcase
      thumb_aws: string
    }
    rectangle: {
      // eslint-disable-next-line camelcase
      jpeg_aws: string
      // eslint-disable-next-line camelcase
      thumb_aws: string
    }
  }
}

interface typeCurationQueryResult {
  curation: Array<{
    id: string
    list: {
      best: []
      stores: []
      koreanSuv: []
      importedSuv: []
      koreanSedan: []
      importedSedan: []
    }
  }>
}

// Check all input field is filled or not
const checkInput = (
  galleryObject: GalleryObejctType,
  blogObject: BlogObejctType,
  selectStore: boolean,
  selectCarAndProduct: boolean,
  blogContentArray: { type: string; content: string }[],
): boolean => {
  const {
    car,
    store,
    // eslint-disable-next-line camelcase
    car_sunroof_type,
  } = galleryObject

  const { title } = blogObject

  const blogContentVoid = blogContentArray.filter((content: { type: string; content: string }) => {
    return content.type === 'text' && (content.content === undefined || content.content === '')
  })

  return !!(
    (selectCarAndProduct ? car : false) &&
    (selectStore ? store : false) &&
    // eslint-disable-next-line camelcase
    (selectCarAndProduct ? car_sunroof_type : false) &&
    blogContentVoid.length === 0 &&
    title
  )
}

const INSERT_GALLERY = gql`
  mutation INSERT_GALLERY($object: gallery_insert_input!) {
    insert_gallery_one(object: $object) {
      id
    }
  }
`

const UPDATE_GALLERY = gql`
  mutation UPDATE_GALLERY(
    $galleryId: uuid!
    $blogId: uuid!
    $galleryObject: gallery_set_input
    $blogObject: blog_post_set_input
  ) {
    update_gallery_by_pk(pk_columns: { id: $galleryId }, _set: $galleryObject) {
      id
    }
    update_blog_post_by_pk(pk_columns: { id: $blogId }, _set: $blogObject) {
      id
    }
  }
`

const DELETE_GALLERY = gql`
  mutation DELETE_GALLERY($id: uuid!, $now: timestamptz) {
    update_gallery_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $now }) {
      id
    }
  }
`

const HIDE_GALLERY = gql`
  mutation HIDE_GALLERY($id: uuid!, $now: timestamptz) {
    update_gallery_by_pk(pk_columns: { id: $id }, _set: { status: false }) {
      id
    }
  }
`

const SHOW_GALLERY = gql`
  mutation SHOW_GALLERY($id: uuid!, $now: timestamptz) {
    update_gallery_by_pk(pk_columns: { id: $id }, _set: { status: true }) {
      id
    }
  }
`

const GET_GALLERY_PRODUCT = gql`
  query GET_GALLERY_PRODUCT($carOrigin: String = "%", $carType: String = "%") {
    product_front: product_item(
      order_by: { sku: asc }
      where: { _and: [{ sku: { _like: "%front%" } }, { sku: { _like: $carType } }, { sku: { _like: $carOrigin } }] }
    ) {
      id
      sku
      price
    }
    product_sideback: product_item(
      order_by: { sku: asc }
      where: {
        _and: [
          { sku: { _nlike: "%front%" } }
          { sku: { _nlike: "%sunroof%" } }
          { sku: { _like: $carType } }
          { sku: { _like: $carOrigin } }
        ]
      }
    ) {
      id
      sku
      price
    }
  }
`
const GET_GALLERY_DETAIL_INFO = gql`
  query GET_GALLERY_DETAIL_INFO {
    car_maker: car_maker(distinct_on: name_kr) {
      name_kr
    }
    car: car(order_by: { car_maker: { name_kr: asc }, model: asc }) {
      id
      car_maker {
        name_kr
        car_origin {
          name_kr
        }
      }
      car_type {
        value
      }
      model
    }
    store: store {
      id
      name
    }
    carSunroofType: car_sunroof_type {
      value
      description
    }

    product_front: product_item(order_by: { sku: asc }, where: { sku: { _like: "%front%" } }) {
      id
      sku
      price
    }
    product_sideback: product_item(
      order_by: { sku: asc }
      where: { _and: [{ sku: { _nlike: "%front%" } }, { sku: { _nlike: "%sunroof%" } }] }
    ) {
      id
      sku
      price
    }
    product_sunroof: product_item(order_by: { sku: asc }, where: { sku: { _like: "%sunroof%" } }) {
      id
      sku
      price
    }
  }
`

const GET_GALLERY_CAR_MODEL = gql`
  query GET_GALLERY_CAR_MODEL($carMaker: String) {
    car: car(order_by: { model: asc }, where: { car_maker: { name_kr: { _like: $carMaker } } }, distinct_on: model) {
      id
      car_maker {
        name_kr
        car_origin {
          name_kr
        }
      }
      car_type {
        value
      }
      model
    }
  }
`

const GET_GALLERY_DETAIL = gql`
  query GET_GALLERY_DETAIL($id: uuid!) {
    car_maker: car_maker(distinct_on: name_kr) {
      name_kr
    }
    gallery: gallery(where: { id: { _eq: $id } }) {
      id
      created_at
      status
      carByCar {
        id
        type
        car_maker {
          name_kr
          car_origin {
            name_kr
          }
        }
        model
        row
        prefix
        postfix
      }
      storeByStore {
        id
        name
        description
        location
        attribute
        addressSigunguByAddressSigungu {
          name
          address_sido {
            name
          }
        }
        address_detail
        store_and_hashtags {
          hashtagByHashtag {
            name
          }
        }
      }
      product_item
      price
      car_sunroof_type
      blogPostByBlogPost {
        id
        title
        content
        image
        posting
        thumbnail
      }
    }
    car: car(order_by: { car_maker: { name_kr: asc }, model: asc }) {
      id
      car_maker {
        name_kr
        car_origin {
          name_kr
        }
      }
      model
      car_type {
        value
      }
    }
    store: store {
      id
      name
    }
    carSunroofType: car_sunroof_type {
      value
      description
    }

    product_front: product_item(order_by: { sku: asc }, where: { sku: { _like: "%front%" } }) {
      id
      sku
      price
    }
    product_sideback: product_item(
      order_by: { sku: asc }
      where: { _and: [{ sku: { _nlike: "%front%" } }, { sku: { _nlike: "%sunroof%" } }] }
    ) {
      id
      sku
      price
    }
    product_sunroof: product_item(order_by: { sku: asc }, where: { sku: { _like: "%sunroof%" } }) {
      id
      sku
      price
    }
  }
`

// query for search store
// search in name or address_detail with a keyword
const SEARCH_STORE_BY_NAME = gql`
  query SEARCH_STORE_BY_NAME($keyword: String = "%") {
    stores: store(
      order_by: { name: asc }
      where: {
        _and: [
          { attribute: { _is_null: false } }
          { _or: [{ name: { _like: $keyword } }, { address_detail: { _like: $keyword } }] }
        ]
      }
    ) {
      id
      name
    }
  }
`

const toThousands = (price: number) => {
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'}원`
}

const toWon = (price: number) => {
  let pts = Math.abs(price)
  const money = ['만 ', '억 ', '조 ', '경 ', '해 ']

  let won = `${(pts % 10000).toString()}원`
  let i = 0
  pts = Math.floor(pts / 10000)
  while (pts > 0) {
    won = (pts % 10000).toString() + money[i] + won
    i += 1
    if (i > money.length) won = '표기불가'
    pts = Math.floor(pts / 10000)
  }

  return price > 0 ? won : `-${won}`
}

const App: React.FunctionComponent = () => {
  const { id } = parseQueryString()

  const { accountId, changeMainMenu, ganBlog, changeGanBlog, sendAxios, changeSnackbarAlertContent } = useRootData(
    ({ appStore, authStore }) => ({
      accountId: authStore.accountId.get(),
      changeMainMenu: appStore.changeMainMenu,
      ganBlog: appStore.ganBlog.get(),
      changeGanBlog: appStore.changeGanBlog,
      sendAxios: appStore.sendAxios,
      changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
    }),
  )
  const [getProductData, { data: productData }] = useLazyQuery<GetGalleryProductQuery, GetGalleryProductQueryVariables>(
    GET_GALLERY_PRODUCT,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const [getCarModelData, { data: carModelData }] = useLazyQuery<
    GetGalleryCarModelQuery,
    GetGalleryCarModelQueryVariables
  >(GET_GALLERY_CAR_MODEL, {
    fetchPolicy: 'no-cache',
  })

  const { data: galleryDetailContent, loading, error } = useQuery<GetGalleryDetailQuery | GetGalleryDetailInfoQuery>(
    id ? GET_GALLERY_DETAIL : GET_GALLERY_DETAIL_INFO,
    {
      variables: { id },
      fetchPolicy: 'no-cache',
    },
  )

  // add loading as storeLoading to prevent error
  const [getStoreData, { data: storeData }] = useLazyQuery<SearchStoreByNameQuery, SearchStoreByNameQueryVariables>(
    SEARCH_STORE_BY_NAME,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const currnetGalleryObject = {
    id: '',
    car: '',
    store: '',
    maker: '',
    model: '',
    origin: '',
    type: '',
    product_item: {
      tinting: {
        front: '',
        sideback: '',
        sunroof: '',
      },
    },
    account: '',
    // eslint-disable-next-line camelcase
    car_sunroof_type: 'none',
    price: 0,
    status: true,
  }

  const currentBlogObject = {
    id: '',
    title: '',
    content: '',
    account: '',
    store: '',
    image: [
      {
        jpeg_aws: '',
        thumb_aws: '',
        original_aws: '',
      },
    ],
    thumbnail: {
      square: {
        jpeg_aws: '',
        thumb_aws: '',
        original_aws: '',
      },
      rectangle: {
        jpeg_aws: '',
        thumb_aws: '',
        original_aws: '',
      },
    },
  }

  const [status, setStatus] = useState(true)
  const [blogCrawlingStatus, setBlogCrawlingStatus] = useState(false)

  const removeGallery = async (): Promise<void> => {
    try {
      // eslint-disable-next-line no-alert
      if (window.confirm('정말 블로그를 삭제하시겠습니까?')) {
        const mutationObject = {
          mutation: DELETE_GALLERY,
          variables: {
            id,
            now: new Date().toUTCString(),
          },
        }
        await client.mutate(mutationObject)

        changeSnackbarAlertContent({ severity: 'success', content: '블로그를 성공적으로 삭제했습니다.' })
        changeMainMenu(`/gallery`)
      }
    } catch (err) {
      Sentry.captureException(err)

      changeSnackbarAlertContent({ severity: 'error', content: '블로그 삭제에 실패했습니다.' })
    }
  }

  const [galleryObject, setGalleryObject] = useState(currnetGalleryObject)
  const [blogObject, setBlogObject] = useState(currentBlogObject)
  const [updateGalleryClicked, setUpdateGalleryClicked] = useState(false)

  const [blogContentArray, setBlogContentArray] = useState([])

  const [invisible, setInvisible] = useState(false)

  const [selectStore, setSelectStore] = useState(false)
  const [selectCarAndProduct, setSelectCarAndProduct] = useState(false)

  const [blogUrl, setBlogUrl] = useState('')

  const [curationType, setCurationType] = useState<keyof typeCurationQueryResult['curation'][0]['list']>(null)

  const showGallery = async (): Promise<void> => {
    try {
      // eslint-disable-next-line no-alert
      if (window.confirm('정말 블로그의 숨김 상태를 해제하시겠습니까?')) {
        const mutationObject = {
          mutation: SHOW_GALLERY,
          variables: {
            id,
          },
        }
        await client.mutate(mutationObject)

        setGalleryObject({
          ...galleryObject,
          status: true,
        })

        changeSnackbarAlertContent({ severity: 'success', content: '숨김 상태를 해제했습니다' })
        setStatus(true)
      }
    } catch (err) {
      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '숨김 상태의 해제에 실패했습니다.' })
    }
  }

  const hideGallery = async (): Promise<void> => {
    try {
      // eslint-disable-next-line no-alert
      if (window.confirm('정말 블로그를 노출을 숨기시겠습니까?')) {
        const mutationObject = {
          mutation: HIDE_GALLERY,
          variables: {
            id,
          },
        }

        await client.mutate(mutationObject)

        setGalleryObject({
          ...galleryObject,
          status: false,
        })

        changeSnackbarAlertContent({ severity: 'success', content: '숨김 상태로 변경했습니다.' })
        setStatus(false)
      } else {
        changeSnackbarAlertContent({ severity: 'info', content: '취소했습니다.' })
      }
    } catch (err) {
      Sentry.captureException(err)

      // eslint-disable-next-line no-alert
      changeSnackbarAlertContent({ severity: 'error', content: '숨김 상태로의 변경이 실패했습니다.' })
    }
  }

  const naverBlogCrawler = async () => {
    try {
      setBlogCrawlingStatus(true)
      const result = await sendAxios.get(`${config.backendEndPoint}/admin/naver-blog-crawling`, {
        params: {
          url: blogUrl,
        },
      })

      let thumbnail = ''

      const { title, blogContentArray: naverBlogContentArray } = result.data

      for (let i = 0; i < naverBlogContentArray.length; i += 1) {
        if (naverBlogContentArray[i].type === 'image') {
          thumbnail = naverBlogContentArray[i].content.original_aws
          break
        }
      }

      setBlogObject({
        ...blogObject,
        title,
        thumbnail: {
          square: {
            jpeg_aws: thumbnail,
            thumb_aws: thumbnail,
            original_aws: thumbnail,
          },
          rectangle: {
            jpeg_aws: thumbnail,
            thumb_aws: thumbnail,
            original_aws: thumbnail,
          },
        },
      })
      setBlogContentArray(naverBlogContentArray)
      setBlogCrawlingStatus(false)
    } catch (err) {
      setBlogCrawlingStatus(false)

      Sentry.captureException(err)
      changeSnackbarAlertContent({ severity: 'error', content: '블로그를 가져오는데 실패하였습니다.' })
    }
  }

  useEffect(() => {
    if (id) {
      if (galleryDetailContent) {
        const gallery = (galleryDetailContent as GetGalleryDetailQuery).gallery[0]

        // Set gallery object
        setGalleryObject({
          id: gallery.id,
          store: gallery.storeByStore ? gallery.storeByStore.id : '',
          car: gallery.carByCar ? gallery.carByCar.id : '',
          product_item: gallery.product_item,
          account: accountId,
          // eslint-disable-next-line camelcase
          car_sunroof_type: gallery.car_sunroof_type,
          price: gallery.price,
          status: gallery.status,
          origin: gallery.carByCar ? gallery.carByCar.car_maker.car_origin.name_kr : '',
          type: gallery.carByCar ? gallery.carByCar.type : '',
          maker: gallery.carByCar ? gallery.carByCar.car_maker.name_kr : 'smallSUV',
          model: gallery.carByCar ? gallery.carByCar.model : '',
        })

        // set gallery visible
        setStatus(gallery.status)

        // Set blog object
        setBlogObject({
          id: gallery.blogPostByBlogPost.id,
          title: gallery.blogPostByBlogPost.title,
          content: gallery.blogPostByBlogPost.content,
          account: accountId,
          store: gallery.storeByStore ? gallery.storeByStore.id : '',
          image: gallery.blogPostByBlogPost.image,
          thumbnail: gallery.blogPostByBlogPost.thumbnail,
        })

        if (gallery.blogPostByBlogPost.posting) {
          setBlogContentArray(gallery.blogPostByBlogPost.posting)
        }

        if (gallery.storeByStore && gallery.storeByStore.id) {
          setSelectStore(true)
        }

        if (gallery.carByCar && gallery.carByCar.id) {
          setSelectCarAndProduct(true)

          getCarModelData({
            variables: {
              carMaker: gallery.carByCar.car_maker.name_kr,
            },
          })
        }
      }
    } else {
      setUpdateGalleryClicked(true)
    }
  }, [galleryDetailContent])

  const [selectGan, setSelectGan] = useState(false)
  const [ganField, setGanField] = useState(null)
  // variable for store search keyword
  const [storeSearchKeyword, setStoreSearchKeyword] = useState('')
  const ganFields: { [key: string]: string } = {
    인사말: 'header',
    시공전: 'before',
    시공후: 'after',
    시인성: 'visibility',
    열차단: 'insulation',
    프라이버시: 'privacy',
    마스킹: 'masking',
    열성형: 'thermoforming',
    마무리말: 'footer',
    '버텍스 시인성': 'vertex-visibility',
    '버텍스 열차단': 'vertex-insulation',
    '버텍스 프라이버시': 'vertex-privacy',
  }
  useEffect(() => {
    if (ganBlog) {
      sendAxios
        .get('https://ganblog.caramora.com:8080/blog/')
        .then((response) => {
          if (response.status === 200) {
            setBlogContentArray(response.data.data) // set blog contents
          } else {
            changeSnackbarAlertContent({ severity: 'error', content: 'GAN 블로그 서버와 연결에 실패했습니다.' })
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
      changeGanBlog(false)
    }
    // get store datas one time when mounted to get all stores in a select tag
    getStoreData()
  }, [])

  if (loading) return null
  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div className={styles.title}>{id ? '블로그 수정' : '블로그 추가'}</div>
        <div className={styles.buttons}>
          {id && (
            <button
              className={styles.editButton}
              onClick={(): void => {
                setUpdateGalleryClicked(!updateGalleryClicked)
              }}
            >
              {!updateGalleryClicked ? '수정하기' : '취소'}
            </button>
          )}
          {id && (
            <button
              className={styles.removeButton}
              onClick={(): void => {
                removeGallery()
              }}
            >
              삭제하기
            </button>
          )}
        </div>
        <div className={styles.labelSection}>
          {id && (
            <button
              className={styles.label}
              onClick={(): void => {
                if (status) hideGallery()
                else showGallery()
              }}
            >
              {status ? '숨기기' : '표시하기'}
            </button>
          )}
        </div>
        {!id && (
          <div className={styles.naverBlogCrawler}>
            <div>네이버 블로그 가져오기</div>
            {blogCrawlingStatus && <div>긁어오는중...</div>}
            <input
              value={blogUrl}
              onChange={(e) => {
                setBlogUrl(e.target.value.trim())
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  naverBlogCrawler()
                }
              }}
              disabled={blogCrawlingStatus}
            ></input>
            {!blogCrawlingStatus && <button onClick={() => naverBlogCrawler()}>긁어오기(최대 5초 걸림)</button>}
          </div>
        )}

        <div className={styles.gan}>
          <button
            className={styles.activateBtn}
            onClick={(): void => {
              setSelectGan(!selectGan)
            }}
          >
            {!selectGan ? 'GAN 문장 가져오기' : '닫기'}
          </button>
          {selectGan && (
            <select
              className={styles.fieldSelecter}
              value={ganField}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                setGanField(e.target.value)
              }}
              disabled={!updateGalleryClicked}
            >
              <option>생성할 문장 분류 선택</option>
              {Object.keys(ganFields).map((field: string, index: number) => {
                return (
                  <option key={index} value={ganFields[field]}>
                    {field}
                  </option>
                )
              })}
            </select>
          )}
          {selectGan && (
            <button
              className={styles.submitBtn}
              onClick={(): void => {
                sendAxios
                  .post('https://ganblog.caramora.com:8080/blog/', { field: [ganField] })
                  .then((response) => {
                    setBlogContentArray(blogContentArray.concat(response.data.data))
                  })
                  .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err)
                  })
              }}
            >
              생성
            </button>
          )}
        </div>
        <div className={styles.field}>
          <div className={styles.labelSection}>
            <button
              className={styles.label}
              onClick={(): void => {
                setSelectStore(!selectStore)
              }}
            >
              {!selectStore ? '매장 선택' : '매장 선택 취소'}
            </button>
            {selectStore && (
              <div className={styles.label}>
                <select
                  className={styles.storeSelect}
                  value={galleryObject.store}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    setGalleryObject({ ...galleryObject, store: e.target.value })
                    setBlogObject({ ...blogObject, store: e.target.value })
                  }}
                  disabled={!updateGalleryClicked}
                >
                  {storeData && <option>매장 선택</option>}
                  {storeData &&
                    storeData.stores
                      .sort((x: Store, y: Store) => {
                        return x.name > y.name ? 1 : -1
                      })
                      .map((store: Store, index: number) => {
                        return (
                          <option key={index} value={store.id}>
                            {store.name}
                          </option>
                        )
                      })}
                </select>

                <input
                  defaultValue={storeSearchKeyword}
                  className={styles.searchBox}
                  onChange={(e) => {
                    setStoreSearchKeyword(e.target.value)
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>): void => {
                    if (e.key === 'Enter') {
                      getStoreData({
                        variables: {
                          keyword: `%${storeSearchKeyword}%`,
                        },
                      })
                    }
                  }}
                  onKeyUp={(e): void => {
                    if ((e.which === 8 || e.which === 46) && storeSearchKeyword === '') {
                      getStoreData()
                    }
                  }}
                />
                <button
                  className={styles.searchButton}
                  onClick={() => {
                    getStoreData({
                      variables: {
                        keyword: `%${storeSearchKeyword}%`,
                      },
                    })
                  }}
                >
                  찾기
                </button>
              </div>
            )}
          </div>
          <div className={styles.labelSection}>
            <button
              className={styles.label}
              onClick={(): void => {
                setSelectCarAndProduct(!selectCarAndProduct)
              }}
            >
              {!selectCarAndProduct ? '차량 & 제품 선택' : '차량 & 제품 선택 취소'}
            </button>
            {selectCarAndProduct && (
              <React.Fragment>
                <select
                  className={styles.carBrandSelect}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    getCarModelData({
                      variables: {
                        carMaker: `${e.target.value}`,
                      },
                    })
                    setGalleryObject({ ...galleryObject, maker: e.target.value })
                  }}
                  disabled={!updateGalleryClicked}
                  value={galleryObject.maker}
                >
                  <option>브랜드 선택</option>

                  {galleryDetailContent.car_maker.map((car: carMaker, index: number) => {
                    return (
                      <option key={index} value={`${car.name_kr}`}>
                        {`${car.name_kr}`}
                      </option>
                    )
                  })}
                </select>

                <select
                  className={styles.carModelSelect}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    setGalleryObject({
                      ...galleryObject,
                      car: e.target.value.split('・')[0],
                      origin: e.target.value.split('・')[1],
                      type: e.target.value.split('・')[2],
                      model: e.target.value.split('・')[3],
                    })

                    getProductData({
                      variables: {
                        carOrigin: `${e.target.value.split('・')[1]}` === '대한민국' ? `%korea%` : `%other%`,

                        carType: `%${e.target.value.split('・')[2]}%`,
                      },
                    })
                  }}
                  disabled={!updateGalleryClicked}
                  value={`${galleryObject.car}・${galleryObject.origin}・${galleryObject.type}・${galleryObject.model}`}
                >
                  <option>차량 선택</option>

                  {carModelData !== undefined
                    ? carModelData.car.map((car: Car, index: number) => {
                        return (
                          <option
                            key={index}
                            value={`${car.id}・${car.car_maker.car_origin.name_kr}・${car.car_type.value}・${galleryObject.model}`}
                          >
                            {`${car.model}`}
                          </option>
                        )
                      })
                    : ``}
                </select>
              </React.Fragment>
            )}
          </div>
          {selectCarAndProduct && (
            <div>
              <div className={styles.title}>썬팅 제품 선택</div>
              <div className={styles.labelSection}>
                <div className={styles.label}>전면 제품 선택</div>
                <div className={styles.label}>측후면 제품 선택</div>
              </div>
              {/* 전면 제품 선택 */}
              <select
                className={styles.select}
                value={galleryObject.product_item.tinting.front}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  const currentProduct = galleryDetailContent.product_front.find(
                    (product: productItem) => product.id === galleryObject.product_item.tinting.front,
                  )

                  setGalleryObject({
                    ...galleryObject,
                    product_item: {
                      tinting: {
                        ...galleryObject.product_item.tinting,
                        front: e.target.value,
                      },
                    },
                    price:
                      galleryObject.price -
                      (currentProduct ? currentProduct.price : 0) +
                      galleryDetailContent.product_front.find((product: productItem) => product.id === e.target.value)
                        .price,
                  })
                }}
                disabled={!updateGalleryClicked}
              >
                <option>전면 제품 선택</option>
                {(productData ? productData.product_front : galleryDetailContent.product_front).map(
                  (product: productItem, index: number) => {
                    return (
                      <option key={index} value={product.id}>
                        {product.sku}
                      </option>
                    )
                  },
                )}
              </select>

              {/* 측후면 제품 선택 */}
              <select
                className={styles.select}
                value={galleryObject.product_item.tinting.sideback}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  const currentProduct = galleryDetailContent.product_sideback.find(
                    (product: productItem) => product.id === galleryObject.product_item.tinting.sideback,
                  )

                  setGalleryObject({
                    ...galleryObject,
                    product_item: {
                      tinting: {
                        ...galleryObject.product_item.tinting,
                        sideback: e.target.value,
                      },
                    },
                    price:
                      galleryObject.price -
                      (currentProduct ? currentProduct.price : 0) +
                      galleryDetailContent.product_sideback.find(
                        (product: productItem) => product.id === e.target.value,
                      ).price,
                  })
                }}
                disabled={!updateGalleryClicked}
              >
                <option>측후면 제품 선택</option>
                {(productData ? productData.product_sideback : galleryDetailContent.product_sideback).map(
                  (product: productItem, index: number) => {
                    return (
                      <option key={index} value={product.id}>
                        {product.sku}
                      </option>
                    )
                  },
                )}
              </select>

              <div className={styles.labelSection}>
                <div className={styles.label}>썬루프 타입 선택</div>
                {!(
                  galleryObject.car_sunroof_type === '' ||
                  galleryObject.car_sunroof_type === 'none' ||
                  galleryObject.car_sunroof_type === '썬루프 타입 선택'
                ) && <div className={styles.label}>썬루프 제품 선택</div>}
              </div>

              {/* 썬루프 타입 선택 */}
              <select
                className={styles.select}
                value={galleryObject.car_sunroof_type}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  const currentProduct = galleryDetailContent.product_sunroof.find(
                    (product: productItem) => product.id === galleryObject.product_item.tinting.sunroof,
                  )

                  if (e.target.value === 'none' || e.target.value === '썬루프 타입 선택') {
                    setGalleryObject({
                      ...galleryObject,
                      car_sunroof_type: 'none',
                      product_item: {
                        tinting: {
                          ...galleryObject.product_item.tinting,
                          sunroof: '',
                        },
                      },
                      price: galleryObject.price - (currentProduct ? currentProduct.price : 0),
                    })
                  } else {
                    setGalleryObject({ ...galleryObject, car_sunroof_type: e.target.value })
                  }
                }}
                disabled={!updateGalleryClicked}
              >
                <option>썬루프 타입 선택</option>
                {galleryDetailContent.carSunroofType.map((type: carSunroofType, index: number) => {
                  return (
                    <option key={index} value={type.value}>
                      {type.description}
                    </option>
                  )
                })}
              </select>

              {/* 썬루프 제품 선택 */}
              {!(
                galleryObject.car_sunroof_type === '' ||
                galleryObject.car_sunroof_type === 'none' ||
                galleryObject.car_sunroof_type === '썬루프 타입 선택'
              ) && (
                <select
                  className={styles.select}
                  value={galleryObject.product_item.tinting.sunroof}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    const currentProduct = galleryDetailContent.product_sunroof.find(
                      (product: productItem) => product.id === galleryObject.product_item.tinting.sunroof,
                    )

                    setGalleryObject({
                      ...galleryObject,
                      product_item: {
                        ...galleryObject.product_item,
                        tinting: {
                          ...galleryObject.product_item.tinting,
                          sunroof: e.target.value,
                        },
                      },
                      price:
                        galleryObject.price -
                        (currentProduct ? currentProduct.price : 0) +
                        galleryDetailContent.product_sunroof.find(
                          (product: productItem) => product.id === e.target.value,
                        ).price,
                    })
                  }}
                  disabled={!updateGalleryClicked}
                >
                  <option>썬루프 제품 선택</option>
                  {galleryDetailContent.product_sunroof.map((product: productItem, index: number) => {
                    return (
                      <option key={index} value={product.id}>
                        {product.sku}
                      </option>
                    )
                  })}
                </select>
              )}
            </div>
          )}

          <div className={styles.price}>
            <div>시공 가격</div>
            <input
              className={styles.nameInput}
              type={'text'}
              placeholder={'시공 가격 입력'}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): boolean => {
                if (event.keyCode < 48 || event.keyCode > 57) return false

                return true
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setGalleryObject({
                  ...galleryObject,
                  price: e.target.value ? parseInt(e.target.value, 10) : 0,
                })
              }}
              value={galleryObject.price}
              disabled={!updateGalleryClicked}
            />
            원
            <div>
              {toThousands(galleryObject.price)} || {toWon(galleryObject.price)}
            </div>
          </div>
          <div>
            <div className={styles.blogContainer}>
              <div className={styles.titleArea}>
                제목 :
                <textarea
                  className={styles.blogTitle}
                  value={blogObject.title}
                  onChange={(e): void => {
                    setBlogObject({
                      ...blogObject,
                      title: e.target.value,
                    })
                  }}
                  disabled={!updateGalleryClicked}
                />
              </div>
              <div className={styles.blogThumbnail}>
                블로그 썸네일 업로드
                <div>
                  <div className={styles.thumbnail}>정사각형 썸네일 업로드 (1:1 비율) (750 X 750 이상) </div>
                  {updateGalleryClicked ? (
                    <UploadImage
                      currentImage={
                        blogObject.thumbnail && blogObject.thumbnail.square ? blogObject.thumbnail.square.thumb_aws : ''
                      }
                      onChangeHandler={(
                        // eslint-disable-next-line camelcase
                        jpeg_aws: string,
                        // eslint-disable-next-line camelcase
                        thumb_aws: string,
                        // eslint-disable-next-line camelcase
                        original_aws: string,
                      ): void => {
                        setBlogObject({
                          ...blogObject,
                          thumbnail: {
                            ...blogObject.thumbnail,
                            square: { jpeg_aws, thumb_aws, original_aws },
                          },
                        })
                      }}
                    />
                  ) : (
                    <img
                      onClick={(): void => {
                        if (blogObject.thumbnail && blogObject.thumbnail.square) {
                          window.open(
                            blogObject.thumbnail.square.original_aws
                              ? blogObject.thumbnail.square.original_aws
                              : blogObject.thumbnail.square.jpeg_aws,
                          )
                        }
                      }}
                      src={
                        blogObject.thumbnail && blogObject.thumbnail.square ? blogObject.thumbnail.square.thumb_aws : ''
                      }
                    ></img>
                  )}

                  <div className={styles.thumbnail}>세로로 긴 직사각형 썸네일 업로드 (3:4 비율) (540 X 720 이상)</div>
                </div>
                {updateGalleryClicked ? (
                  <UploadImage
                    currentImage={
                      blogObject.thumbnail && blogObject.thumbnail.rectangle
                        ? blogObject.thumbnail.rectangle.thumb_aws
                        : ''
                    }
                    onChangeHandler={(
                      // eslint-disable-next-line camelcase
                      jpeg_aws: string,
                      // eslint-disable-next-line camelcase
                      thumb_aws: string,
                      // eslint-disable-next-line camelcase
                      original_aws: string,
                    ): void => {
                      setBlogObject({
                        ...blogObject,
                        thumbnail: {
                          ...blogObject.thumbnail,
                          rectangle: { jpeg_aws, thumb_aws, original_aws },
                        },
                      })
                    }}
                  />
                ) : (
                  <img
                    onClick={(): void => {
                      if (blogObject.thumbnail && blogObject.thumbnail.rectangle) {
                        window.open(
                          blogObject.thumbnail.square.original_aws
                            ? blogObject.thumbnail.square.original_aws
                            : blogObject.thumbnail.square.jpeg_aws,
                        )
                      }
                    }}
                    src={
                      blogObject.thumbnail && blogObject.thumbnail.rectangle
                        ? blogObject.thumbnail.rectangle.thumb_aws
                        : ''
                    }
                  ></img>
                )}
              </div>
              <div className={styles.dataField}>블로그 내용 추가</div>
              <div className={styles.blogContentContainer}>
                {blogContentArray.map((content, index: number) => {
                  const { type } = content

                  return (
                    <div key={index} className={styles.blogContentInput}>
                      {updateGalleryClicked && (
                        <Fragment>
                          <button
                            value={index}
                            onClick={(): void => {
                              if (index <= 0) return

                              const tempBlogContentArray: Array<Record<string, string>> = [...blogContentArray]

                              const tmp = tempBlogContentArray[index]
                              tempBlogContentArray[index] = tempBlogContentArray[index - 1]
                              tempBlogContentArray[index - 1] = tmp
                              setBlogContentArray(tempBlogContentArray)
                            }}
                          >
                            위로
                          </button>
                          <button
                            value={index}
                            onClick={(): void => {
                              if (index >= blogContentArray.length - 1) return

                              const tempBlogContentArray: Array<Record<string, string>> = [...blogContentArray]

                              const tmp = tempBlogContentArray[index]
                              tempBlogContentArray[index] = tempBlogContentArray[index + 1]
                              tempBlogContentArray[index + 1] = tmp
                              setBlogContentArray(tempBlogContentArray)
                            }}
                          >
                            아래로
                          </button>
                        </Fragment>
                      )}

                      {type === 'text' && (
                        <div>
                          <textarea
                            key={index}
                            className={styles.blogText}
                            placeholder={'텍스트를 입력하세요.'}
                            value={blogContentArray[index].content}
                            onChange={(e): void => {
                              const tempArray: Record<string, unknown>[] = []

                              blogContentArray.forEach((textContent) => {
                                tempArray.push(textContent)
                              })

                              tempArray[index].content = e.target.value

                              setBlogContentArray(tempArray)
                            }}
                            disabled={!updateGalleryClicked}
                          />
                          <div
                            className={styles.blogContentText}
                            dangerouslySetInnerHTML={{
                              __html: blogContentArray[index].content,
                            }}
                          ></div>
                        </div>
                      )}

                      {type === 'image' && (
                        <div>
                          {updateGalleryClicked ? (
                            <UploadImage
                              currentImage={
                                blogContentArray[index].content ? blogContentArray[index].content.jpeg_aws : ''
                              }
                              onChangeHandler={(
                                // eslint-disable-next-line camelcase
                                jpeg_aws: string,
                                // eslint-disable-next-line camelcase
                                thumb_aws: string,
                                // eslint-disable-next-line camelcase
                                original_aws: string,
                              ): void => {
                                const tempArray: Record<string, unknown>[] = []
                                blogContentArray.forEach((imageContent) => {
                                  tempArray.push(imageContent)
                                })

                                tempArray[index].content = {
                                  jpeg_aws,
                                  thumb_aws,
                                  original_aws,
                                }

                                setBlogContentArray(tempArray)
                                setInvisible(false)
                              }}
                              setInvisible={setInvisible}
                            />
                          ) : (
                            <img
                              onClick={(): void => {
                                if (blogContentArray[index].content) {
                                  window.open(
                                    blogContentArray[index].content.original_aws
                                      ? blogContentArray[index].content.original_aws
                                      : blogContentArray[index].content.jpeg_aws,
                                  )
                                }
                              }}
                              src={blogContentArray[index].content ? blogContentArray[index].content.thumb_aws : ''}
                            ></img>
                          )}
                        </div>
                      )}
                      {updateGalleryClicked && (
                        <div
                          className={styles.deleteButton}
                          onClick={(): void => {
                            const tempArray: Record<string, unknown>[] = []
                            blogContentArray.splice(index, 1)
                            blogContentArray.forEach((remaincontent) => {
                              tempArray.push(remaincontent)
                            })

                            setBlogContentArray(tempArray)
                          }}
                        >
                          X
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>

              <div>
                {updateGalleryClicked && (
                  <Fragment>
                    <div className={styles.addContentMenu}>
                      {!invisible && (
                        <div
                          className={styles.addContentButton}
                          onClick={(): void => {
                            const tempArray = []
                            blogContentArray.forEach((content) => {
                              tempArray.push(content)
                            })
                            tempArray.push({ type: 'text' })

                            setBlogContentArray(tempArray)
                          }}
                        >
                          텍스트 추가
                        </div>
                      )}
                      {!invisible && (
                        <div
                          className={styles.addContentButton}
                          onClick={(): void => {
                            const tempArray = []
                            blogContentArray.forEach((content) => {
                              tempArray.push(content)
                            })
                            tempArray.push({ type: 'image' })

                            setBlogContentArray(tempArray)
                          }}
                        >
                          이미지 추가
                        </div>
                      )}
                    </div>
                    <br />
                    <Fragment>
                      <div>
                        자동 큐레이션 추가 (밑에 차 타입을 선택하면 [추천 베스트] + [ (선택한 차 타입)추천]에 이
                        블로그가 자동으로 추가됩니다.)
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="koreanSuv"
                          checked={curationType === 'koreanSuv'}
                          onChange={(): void => setCurationType('koreanSuv')}
                        />
                        <label>국산 SUV 추천</label>
                        <input
                          type="radio"
                          value="importedSuv"
                          checked={curationType === 'importedSuv'}
                          onChange={(): void => setCurationType('importedSuv')}
                        />
                        <label>수입 SUV 추천</label>
                        <input
                          type="radio"
                          value="koreanSedan"
                          checked={curationType === 'koreanSedan'}
                          onChange={(): void => setCurationType('koreanSedan')}
                        />
                        <label>국산 세단 추천</label>
                        <input
                          type="radio"
                          value="importedSedan"
                          checked={curationType === 'importedSedan'}
                          onChange={(): void => setCurationType('importedSedan')}
                        />
                        <label>수입 세단 추천</label>
                        <div className={styles.cancelButton} onClick={(): void => setCurationType(null)}>
                          선택 취소
                        </div>
                      </div>
                    </Fragment>
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          <div>
            {updateGalleryClicked && (
              <button
                className={styles.completeButton}
                onClick={async (): Promise<void> => {
                  const tempGalleryObject = { ...galleryObject }
                  delete tempGalleryObject.origin
                  delete tempGalleryObject.type
                  delete tempGalleryObject.model
                  delete tempGalleryObject.maker

                  if (id) {
                    try {
                      // update query
                      await client.mutate({
                        mutation: UPDATE_GALLERY,
                        variables: {
                          galleryId: tempGalleryObject.id,
                          blogId: blogObject.id,
                          galleryObject: {
                            ...tempGalleryObject,
                            car: selectCarAndProduct ? tempGalleryObject.car : null,
                            store: selectStore ? tempGalleryObject.store : null,
                          },
                          blogObject: {
                            ...blogObject,
                            store: selectStore ? blogObject.store : null,
                            posting: blogContentArray,
                          },
                        },
                      })
                      changeSnackbarAlertContent({
                        severity: 'success',
                        content: '블로그를 성공적으로 수정했습니다.',
                      })
                      changeMainMenu('/gallery')
                    } catch (err) {
                      Sentry.captureException(err)
                      changeSnackbarAlertContent({
                        severity: 'error',
                        content: '입력사항 오류로 블로그 수정을 실패했습니다.',
                      })
                    }
                  }
                  // insert query
                  else if (
                    checkInput(tempGalleryObject, blogObject, selectStore, selectCarAndProduct, blogContentArray)
                  ) {
                    try {
                      const insertObject = {
                        car: selectCarAndProduct ? tempGalleryObject.car : null,
                        store: selectStore ? tempGalleryObject.store : null,
                        product_item: selectCarAndProduct
                          ? tempGalleryObject.product_item
                          : {
                              tinting: {
                                front: '',
                                sideback: '',
                                sunroof: '',
                              },
                            },
                        account: accountId,
                        // eslint-disable-next-line camelcase
                        car_sunroof_type: selectCarAndProduct ? tempGalleryObject.car_sunroof_type : 'none',
                        price: tempGalleryObject.price,
                        blogPostByBlogPost: {
                          data: {
                            title: blogObject.title,
                            content: blogObject.content,
                            account: accountId,
                            store: selectStore ? blogObject.store : null,
                            image: blogObject.image,
                            posting: blogContentArray,
                            thumbnail: blogObject.thumbnail,
                          },
                        },
                      }

                      const galleryInfo = await client.mutate({
                        mutation: INSERT_GALLERY,
                        variables: {
                          object: insertObject,
                        },
                      })

                      if (curationType) {
                        const cdata: ApolloQueryResult<typeCurationQueryResult> = await client.query({
                          query: GET_CURATION,
                        })

                        const newBestArray: string[] = cdata.data.curation[0].list.best
                        newBestArray.push(galleryInfo.data.insert_gallery_one.id)

                        const newCarTypeArray: string[] = cdata.data.curation[0].list[curationType]
                        newCarTypeArray.push(galleryInfo.data.insert_gallery_one.id)

                        await client.mutate({
                          mutation: UPDATE_CURATION,
                          variables: {
                            id: cdata.data.curation[0].id,
                            account: accountId,
                            list: {
                              ...cdata.data.curation[0].list,
                              best: newBestArray,
                              [curationType]: newCarTypeArray,
                            },
                          },
                        })
                      }

                      changeSnackbarAlertContent({
                        severity: 'success',
                        content: '블로그를 성공적으로 추가했습니다.',
                      })
                      changeMainMenu('/gallery')
                    } catch (err) {
                      Sentry.captureException(err)
                      changeSnackbarAlertContent({
                        severity: 'error',
                        content: '입력사항 오류로 블로그 추가를 실패했습니다.',
                      })
                    }
                  } else {
                    changeSnackbarAlertContent({
                      severity: 'warning',
                      content: '입력 사항이 올바르지 않습니다.',
                    })
                  }
                }}
              >
                완료
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
