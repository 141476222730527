import gql from 'graphql-tag'

export const GET_ACCOUNT_PERFORMANCE_FOR_RESERVATION = gql`
  query GET_ACCOUNT_PERFORMANCE_FOR_RESERVATION($start_date: timestamp, $end_date: timestamp) {
    get_account_performance_for_reservation(args: { end_date: $end_date, start_date: $start_date }) {
      account
      count
      email
      name
      role
      status
    }
  }
`

export const GET_ACCOUNT_PERFORMANCE_FOR_FIRST_CALL = gql`
  query GET_ACCOUNT_PERFORMANCE_FOR_FIRST_CALL($start_date: timestamp, $end_date: timestamp) {
    get_account_performance_for_first_call(args: { end_date: $end_date, start_date: $start_date }) {
      account
      count
      email
      name
      role
      status
    }
  }
`

export const GET_ACCOUNT_PERFORMANCE_BY_USED_CAR_STATUS = gql`
  query GET_ACCOUNT_PERFORMANCE_BY_USED_CAR_STATUS($start_date: timestamp, $end_date: timestamp) {
    get_account_performance_by_used_car_status(args: { end_date: $end_date, start_date: $start_date }) {
      account
      count
      email
      name
      role
      status
      used_car_status
    }
  }
`
