import gql from 'graphql-tag'

const ALL_PRODUCT_AND_PRODUCT_ITEMS = gql`
  query ALL_PRODUCT_AND_PRODUCT_ITEMS {
    product(order_by: { name: asc }) {
      id
      name
      product_brand {
        value
        description
      }
      product_type {
        value
        description
      }
    }
    product_type(order_by: { description: asc }) {
      value
      description
    }
    product_item {
      id
      sku
    }
  }
`

export default ALL_PRODUCT_AND_PRODUCT_ITEMS
