import { useEffect } from 'react'

import useRootData from './useRootData'

export default (): void => {
  const { currentMainMenu, isSignedIn, isReady, changeMainMenu } = useRootData(({ authStore, appStore }) => ({
    currentMainMenu: appStore.currentMainMenu.get(),
    isSignedIn: authStore.isSignedIn.get(),
    isReady: authStore.isReady.get(),
    changeMainMenu: appStore.changeMainMenu,
  }))

  useEffect(() => {
    if (isReady && isSignedIn) {
      changeMainMenu(currentMainMenu)
    }
  }, [isSignedIn, isReady])
}
