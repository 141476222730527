import React from 'react'

export interface ImageObject {
  // eslint-disable-next-line camelcase
  thumb_aws: string
  // eslint-disable-next-line camelcase
  jpeg_aws: string
  // eslint-disable-next-line camelcase
  original_aws: string
}

export interface PropsImage {
  style?: React.CSSProperties
  className?: string
  src?: string
  image?: ImageObject
  clickHandler?: () => void
  onMouseOverHandler?: () => void
  onMouseLeaveHandler?: () => void
  useMap?: string
  withoutPolyfill?: boolean
  content?: string
  withoutLazyLoad?: boolean
  noCache?: boolean
}

const buttonStyle: React.CSSProperties = {
  cursor: 'pointer',
}

const imageFitRatio: React.CSSProperties = {
  objectFit: 'cover',
  fontFamily: 'object-fit: cover',
}

const App: React.FunctionComponent<PropsImage> = ({
  style,
  className,
  clickHandler,
  src,
  useMap,
  withoutPolyfill,
}: PropsImage) => {
  let newStyle = clickHandler ? { ...buttonStyle, ...style } : { ...style }

  newStyle = withoutPolyfill ? { ...newStyle } : { ...newStyle, ...imageFitRatio }

  return (
    <img
      style={{ ...newStyle }}
      className={className}
      onClick={() => {
        if (clickHandler) clickHandler()
      }}
      src={src}
      useMap={useMap}
    ></img>
  )
}

export default React.memo(App)
