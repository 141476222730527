import { useLazyQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  Get_Quote_RequestQuery as GetQuoteRequestQuery,
  Get_Quote_RequestQueryVariables as GetQuoteRequestQueryVariables,
  Order_By as OrderBy,
  Quote_Request as QuoteRequest,
} from '../../assets/graphql/graphql'
import config from '../../configs'
import useRootData from '../../hooks/useRootData'
import client from '../../utils/graphql'
import { autoHypenPhoneNumber } from '../../utils/utility'
import styles from './index.module.scss'
import { GET_QUOTE_REQUEST, UPDATE_QUOTE_REQUEST } from './query'

const quoteRequestTemplate = `
[Caramora]
상담원이 대기 중입니다.

현재 상담가능한 상담사가 있습니다.
아래 링크를 눌러 상담을 진행해주세요.

✔️ 상담 진행하기!
https://caramora.com/follow-up?phone={phone}&requestId={requestId}

✔️ 상담가능시간 : 9:00 ~ 익일 2:00
`

const App: React.FunctionComponent = () => {
  const { sendAxios, changeSnackbarAlertContent } = useRootData(({ appStore }) => ({
    sendAxios: appStore.sendAxios,
    changeSnackbarAlertContent: appStore.changeSnackbarAlertContent,
  }))
  const [currentIndex, setCurrentIndex] = useState(0)
  const [sortArrow, setSortArrow] = useState(OrderBy.Desc)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [filter, setFilter] = useState({
    startDate: moment(moment(Date.now()).format('YYYY-MM-DD')).subtract(2, 'weeks').toISOString(),
    endDate: moment(moment(Date.now()).format('YYYY-MM-DD')).add(1, 'days').toISOString(),
    searchKeyword: '',
  })

  // #marketplace_request data
  const [getData, { data: quoteRequestContents, error }] = useLazyQuery<
    GetQuoteRequestQuery,
    GetQuoteRequestQueryVariables
  >(GET_QUOTE_REQUEST, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getData({
      variables: {
        offset: currentIndex * 100,
        startDate: filter.startDate,
        endDate: filter.endDate,
      },
    })
  }, [])

  if (error) {
    Sentry.captureException(error)
  }

  if (!quoteRequestContents) return null

  const {
    quote_request: quoteRequest,
    request_count: {
      aggregate: { count: requestCount },
    },
  } = quoteRequestContents

  const pageList = Array.from(Array(Math.ceil(requestCount / 100)), (_, i) => i + 1)
  let start = currentIndex - 2
  let end = currentIndex + 2

  if (pageList.length <= 5) {
    start = 1
    end = pageList.length
  } else if (currentIndex - 2 <= 0) {
    start = 1
    end = 5
  } else if (currentIndex + 2 > pageList.length) {
    start = pageList.length - 4
    end = pageList.length
  }

  const getDataWithVariable = (
    offset: number,
    startDateToString: string,
    endDateToString: string,
    createdAt: OrderBy,
  ): void => {
    setCurrentIndex(0)
    getData({
      variables: {
        offset,
        startDate: startDateToString,
        endDate: endDateToString,
        createdAt,
      },
    })
  }

  return (
    <div className="body">
      <div className={styles.container}>
        <div>#marketplace_request 목록</div>
        <br />
        <div>slack #marketplace_request 채널에 견적 or 상담을 위해 전화번호를 남긴 고객 목록입니다.</div>
        <br />
        <input
          type={'date'}
          value={startDate}
          onChange={(e): void => {
            setStartDate(e.target.value)
          }}
        />
        &nbsp;~&nbsp;
        <input
          type={'date'}
          value={endDate}
          onChange={(e): void => {
            setEndDate(e.target.value)
          }}
        />
        <button
          className={styles.addButton}
          onClick={async (): Promise<void> => {
            setCurrentIndex(0)
            setFilter({
              ...filter,
              startDate: moment(startDate).toISOString(),
              endDate: moment(endDate).add(1, 'days').toISOString(),
            })
            getDataWithVariable(
              currentIndex * 100,
              moment(startDate).toISOString(),
              moment(endDate).add(1, 'days').toISOString(),
              sortArrow,
            )
          }}
        >
          기간 검색
        </button>
        <br />
        <br />
        <div className={styles.templatePreview}>
          <span>알림톡 미리보기</span>
          <div>
            {quoteRequestTemplate.split('\n').map((text: string, index: number) => {
              return (
                <div className={styles.contentText} key={index}>
                  {text || <br />}
                </div>
              )
            })}
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <th>id</th>
              <th>No.</th>
              <th>
                <button
                  className={styles.date}
                  onClick={() => {
                    if (sortArrow === OrderBy.Desc) {
                      setSortArrow(OrderBy.Asc)
                    } else {
                      setSortArrow(OrderBy.Desc)
                    }
                    getDataWithVariable(currentIndex * 100, filter.startDate, filter.endDate, sortArrow)
                  }}
                >
                  견적 요청 시간(KST){sortArrow === OrderBy.Desc ? '▼' : '▲'}
                </button>
              </th>
              <th>session ID</th>
              <th>account ID</th>
              <th>전화번호</th>
              <th>email</th>
              <th>차량</th>
              <th>시공 지역</th>
              <th>출고일</th>
              <th>비고</th>
              <th>매장</th>
              <th>마지막 알림톡 전송일(KST)</th>
              <th></th>
            </tr>
            {quoteRequest.map((item: QuoteRequest, index: number) => {
              const {
                id,
                created_at: createdAt,
                session,
                account,
                sent_at: sentAt,
                content: { email_account: email, car, location, release_date: releaseDate, remark, store },
                phone_number: phoneNumber,
              } = item

              const phone = new PhoneNumber(phoneNumber, 'KR').getNumberFrom('KR')

              return (
                <tr key={index}>
                  <td>{id}</td>
                  <td>{quoteRequest.length - index}</td>
                  <td>{moment(createdAt).local().format('YYYY-MM-DD A h:mm')}</td>
                  <td>{session}</td>
                  <td>{account}</td>
                  <td>{phone}</td>
                  <td>{email}</td>
                  <td>{car}</td>
                  <td>{location}</td>
                  <td>{releaseDate}</td>
                  <td>{remark}</td>
                  <td>{store}</td>
                  <td>{sentAt ? moment(sentAt).local().format('YYYY-MM-DD A h:mm') : ''}</td>
                  <td>
                    <button
                      onClick={async (): Promise<void> => {
                        // eslint-disable-next-line no-alert
                        const confirmRequest = window.confirm('해당 고객에게 알림톡을 발송하시겠습니까?')

                        if (confirmRequest) {
                          const result = await sendAxios.get(`${config.backendEndPoint}/send/kakaotalk`, {
                            params: {
                              phoneNumber: autoHypenPhoneNumber(phone),
                              templateCode: 'quote_request_followup',
                              requestId: id,
                            },
                          })

                          // TODO : 전송 실패 에러 캐치 필요
                          if (result.status === 200) {
                            changeSnackbarAlertContent({
                              severity: 'success',
                              content: '알림이 정상적으로 발송 되었습니다.',
                            })
                            await client.mutate({
                              mutation: UPDATE_QUOTE_REQUEST,
                              variables: {
                                phoneNumber,
                                date: new Date(),
                              },
                            })

                            getData({
                              variables: {
                                offset: currentIndex * 100,
                                startDate: filter.startDate,
                                endDate: filter.endDate,
                              },
                            })
                          } else {
                            changeSnackbarAlertContent({ severity: 'error', content: '알림톡 발송 실패!!' })
                          }
                        }
                      }}
                    >
                      알림톡 보내기
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className={styles.paginationWrapper}>
          <div className={styles.pagination}>
            <a
              onClick={() => {
                if (currentIndex > 0) {
                  getDataWithVariable((currentIndex - 1) * 100, filter.startDate, filter.endDate, sortArrow)
                  setCurrentIndex(currentIndex - 1)
                }
              }}
            >
              &nbsp; &lt;
            </a>
            {pageList.slice(start - 1, end).map((item) => {
              return (
                <a
                  key={item - 1}
                  className={currentIndex === item - 1 ? styles.active : undefined}
                  onClick={() => {
                    getDataWithVariable((item - 1) * 100, filter.startDate, filter.endDate, sortArrow)
                    setCurrentIndex(item - 1)
                  }}
                >
                  &nbsp;{item}&nbsp;
                </a>
              )
            })}
            <a
              onClick={() => {
                if (currentIndex < Math.ceil(requestCount / 100) - 1) {
                  getDataWithVariable((currentIndex + 1) * 100, filter.startDate, filter.endDate, sortArrow)
                  setCurrentIndex(currentIndex + 1)
                }
              }}
            >
              &gt;&nbsp;
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(App)
